import React from "react";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import { MiceType, MiceTypeLink } from "./Typography";
import WillfulLogoSymbol from "./icons/WillfulLogoSymbol";
import { footerProps } from "../../selectors/layout";
import { PageWrapper } from "../../containers/Layout";

const Footer = ({ translations, isDesktop, hasStickyButton }) => {
  const {
    copyrightText,
    tosLinkText,
    policyLinkText,
    termsOfService,
    privacyPolicy,
  } = translations;
  let footerHeight = 88;
  let footerPaddingBottom = 0;
  if (!isDesktop && hasStickyButton) {
    // for mobile and tablets in the pages that require sticky button, increase footer
    // height and bottom padding to account for the sticky footer
    footerHeight = 140;
    footerPaddingBottom = 4;
  }
  return (
    <Box
      component="footer"
      height={footerHeight}
      paddingBottom={footerPaddingBottom}
      bgcolor="willfulGrey.main"
      width="100%"
      display="flex"
      justifyContent="center"
      zIndex={!isDesktop && hasStickyButton ? 0 : 1} // change made to help bottom sticky button to be on top of the footer component
    >
      <PageWrapper display="flex" alignItems="center">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box pt="10px" mr={1.5}>
            <WillfulLogoSymbol />
          </Box>
          <Box display="flex" alignItems="center">
            <Box component="span">
              <Box component="span" mr={1}>
                <MiceType
                  dangerouslySetInnerHTML={{
                    __html: copyrightText.replace(
                      "INSERT_YEAR",
                      new Date().getFullYear(),
                    ),
                  }}
                />
              </Box>
              <Box component="span" mr={1}>
                <MiceTypeLink url={termsOfService} text={tosLinkText} />
              </Box>
              <Box component="span" display="inline-block">
                <MiceTypeLink url={privacyPolicy} text={policyLinkText} />
              </Box>
            </Box>
          </Box>
        </Box>
      </PageWrapper>
    </Box>
  );
};

export default connect(footerProps)(Footer);
