import React from "react";
import styled from "styled-components";
import { Box, useMediaQuery } from "@material-ui/core";
import { withTheme, useTheme } from "@material-ui/styles";

import WillfulLogo from "./icons/WillfulLogo";
import AppointeesIcon from "./icons/AppointeesIcon";
import { H3, Body, H5, MiceType } from "./Typography";
import { ExternalButtonLink } from "./Button";

// Override to handle responsive text since this element does not currently support it
const StyledExternalButtonLink = styled(ExternalButtonLink)`
  ${({ $isMobile }) => $isMobile && "font-size: 16px;"}
`;

const EmailPreview = ({ translations, analyticsClickExternalLink }) => {
  const handleLinkClick = () => {
    analyticsClickExternalLink(translations.learnMoreLink);
  };

  const theme = useTheme();
  const isMinTablet = useMediaQuery(theme.breakpoints.up("md"));
  const appointeesIconSize = isMinTablet ? 120 : 60;
  const mobileAppointeesIconPositioning = {
    bottom: "-25px",
    right: "16px",
    width: "60px",
    height: "60px",
  };
  const tabletUpAppointeesIconPositioning = {
    bottom: "-50px",
    right: "32px",
    width: "120px",
    height: "120px",
  };
  const appointeesIconPositioning = isMinTablet
    ? tabletUpAppointeesIconPositioning
    : mobileAppointeesIconPositioning;

  return (
    <Box pt={2}>
      <Box
        height={isMinTablet ? "200px" : "100px"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="willfulBlue.main"
        position="relative"
        mb={isMinTablet ? "82px" : "40px"}
      >
        <Box width={isMinTablet ? "150px" : "75px"}>
          <WillfulLogo />
        </Box>
        <Box position="absolute" {...appointeesIconPositioning}>
          <AppointeesIcon
            width={appointeesIconSize}
            height={appointeesIconSize}
          />
        </Box>
      </Box>
      <Box mb={1}>
        <H3 align="left">{translations.subject}</H3>
      </Box>
      <Body>{translations.roleIntro}</Body>
      <Box mt={isMinTablet ? 2 : 1} mb={1}>
        <H3 align="left" color="willfulBlue">
          {translations.whatNextTitle}
        </H3>
      </Box>
      <Body>{translations.whatNextBody}</Body>
      <Box my={isMinTablet ? 2 : 1}>
        <StyledExternalButtonLink
          text={translations.learnMore}
          align="center"
          onClick={handleLinkClick}
          href={translations.learnMoreLink}
          $isMobile={!isMinTablet}
          displayArrowRight
          fullWidth
        />
      </Box>
      <H5 align="left">{translations.whyReceivingTitle}</H5>
      <MiceType color="willfulGrey" as="p" display="block">
        {translations.whyReceivingBodyPt1}
      </MiceType>
      <MiceType color="willfulGrey" as="p" display="block">
        {translations.whyReceivingBodyPt2}
      </MiceType>
    </Box>
  );
};

export default withTheme(EmailPreview);
