import React from "react";

import SvgIcon from "./SvgIcon";

const BirthdayIcon = () => {
  return (
    <SvgIcon width="100px" viewBox="10 20 270 245">
      <ellipse cx="160.5" cy="165.5" rx="116.5" ry="77.5" fill="#C6E9F8" />
      <path
        d="M128.12,80.83l19.41,11.22L145,72.51ZM161.6,200.44l35,1.3h0l67.11-42.53L147.53,92.05Z"
        fill="#2f455f"
        fillRule="evenodd"
      />
      <polygon
        points="85 156.72 114.24 173.43 121.17 177.43 161.26 200.56 219.25 166.89 219.25 104.84 85 94.67 85 156.72"
        fill="#e1f3f9"
        fillRule="evenodd"
      />
      <polygon
        points="142.99 61 85 94.67 114.24 111.39 121.17 115.38 161.26 138.51 219.25 104.84 179.15 81.71 164.81 73.44 142.99 61"
        fill="#abe0f6"
        fillRule="evenodd"
      />
      <polygon
        points="114.7 111.75 114.7 173.79 129.05 182.07 129.05 120.03 114.7 111.75"
        fill="#71adee"
        fillRule="evenodd"
      />
      <polygon
        points="161.26 138.51 161.27 200.56 219.25 166.89 219.25 104.84 161.26 138.51"
        fill="#71adee"
        fillRule="evenodd"
      />
      <polygon
        points="187.04 86.36 172.69 78.08 165.99 81.97 155.92 87.81 131.47 102.01 121.4 107.86 114.7 111.75 129.05 120.03 135.75 116.13 145.69 110.36 170.39 96.02 180.33 90.25 187.04 86.36"
        fill="#71adee"
        fillRule="evenodd"
      />
      <polygon
        points="197.68 117.37 172.28 103.03 162.15 97.31 137.53 83.39 127.4 77.67 120.65 73.86 106.35 82.28 127.5 94.22 137.51 99.88 162.38 113.93 172.39 119.58 183.28 125.73 197.68 117.37"
        fill="#71adee"
        fillRule="evenodd"
      />
      <polygon
        points="183.28 125.73 183.28 187.78 197.68 179.42 197.68 117.37 183.28 125.73"
        fill="#0064dc"
        fillRule="evenodd"
      />
      <path
        d="M150.87,107.06s22.18-11,29.88-3.07-17.5,11.12-29.88,3.07"
        fill="#bc7f35"
        fillRule="evenodd"
      />
      <path
        d="M197.27,98.19l-15.09,8.23s-2.38-7.48-14.91-4.85c-13,2.71-16.4,5.49-16.4,5.49L166,97.89s17.16-8.83,26.58-5.16c5.41,2.11,4.73,5.46,4.73,5.46"
        fill="#ffdc1e"
        fillRule="evenodd"
      />
      <path
        d="M197.27,98.19l-15.09,8.23s.34,2-2.49,3.44l15.15-7.8s2.67-2.16,2.43-3.87"
        fill="#e2a03b"
        fillRule="evenodd"
      />
      <path
        d="M136.75,99.05s-9.5-28.85-16.67-26.18C109.7,76.73,124,91,136.75,99.05"
        fill="#bc7f35"
        fillRule="evenodd"
      />
      <path
        d="M132.6,65.55l-14.4,8.28s5.44-1.66,11,10.58c5.4,11.9,7.54,14.64,7.54,14.64l14.12-8.21s-2.94-17.19-11-23.35c-4.6-3.54-7.31-1.94-7.31-1.94"
        fill="#ffdc1e"
        fillRule="evenodd"
      />
      <path
        d="M150.87,107S128.69,96,121,103.92s17.49,11.13,29.88,3.08"
        fill="#bc7f35"
        fillRule="evenodd"
      />
      <path
        d="M104.46,98.13l15.09,8.23s2.39-7.48,14.92-4.86c12.95,2.72,16.4,5.5,16.4,5.5l-15.09-9.17S118.62,89,109.2,92.67c-5.41,2.11-4.74,5.46-4.74,5.46"
        fill="#ffdc1e"
        fillRule="evenodd"
      />
      <path
        d="M104.46,98.13l15.09,8.23s-.33,2,2.49,3.44L106.9,102s-2.68-2.16-2.44-3.87"
        fill="#e2a03b"
        fillRule="evenodd"
      />
      <path
        d="M165,99.45s9.49-28.86,16.67-26.18C192,77.13,177.73,91.42,165,99.45"
        fill="#bc7f35"
        fillRule="evenodd"
      />
      <path
        d="M169.14,66l14.39,8.28s-5.44-1.66-11,10.58C167.12,96.7,165,99.45,165,99.45l-14.12-8.21s2.93-17.19,10.95-23.35c4.6-3.54,7.32-1.94,7.32-1.94"
        fill="#ffdc1e"
        fillRule="evenodd"
      />
      <polygon
        points="164.99 99.34 151.05 90.64 136.75 99.05 150.59 107.06 164.99 99.34"
        fill="#e2a03b"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default BirthdayIcon;
