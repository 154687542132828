import React, { useState } from "react";
import styled from "styled-components";

import { withTheme } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import { H3, Body, SmallTextLink, TextLink, SectionTitle } from "./Typography";
import ProgressBar from "./ProgressBar";
import StatusLabel from "./StatusLabel";
import FlashMessage from "./FlashMessage";

import { ArrowBlueUpIcon, ArrowBlueDownIcon } from "./icons/ArrowIcons";

const Button = styled.button`
  background: none;
  border-right: none;
  border-left: none;
  border-top: 1px solid ${(props) => props.palette.border.main};
  border-bottom: 1px solid ${(props) => props.palette.border.main};
  width: 100%;
  cursor: pointer;
  outline: none;

  &:hover {
    background: ${(props) => props.palette.willfulxxxLightBlue.main};
  }
`;

const FormSummaries = ({
  translations,
  formSummaries,
  sectionKey,
  analyticsClickedEdit = () => {},
}) => {
  const numberOfForms = formSummaries.length - 1 || 0;
  return (
    <Box pb={1}>
      {formSummaries.map(({ path, fields }, index) => (
        <Box key={path}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              {fields &&
                fields.map(({ label, value }) => {
                  return (
                    <Box key={label} pt={1}>
                      <Body>
                        {label}{" "}
                        <Body data-hj-suppress component="span" ml>
                          {value}
                        </Body>
                      </Body>
                    </Box>
                  );
                })}
            </Box>
            <Box display="flex" pl={0.5} alignItems="center">
              <SmallTextLink
                onClick={() => analyticsClickedEdit(sectionKey, path)}
                to={path}
                text={translations.edit}
              />
            </Box>
          </Box>
          {index < numberOfForms && (
            <Box bgcolor="border.main" height={1.01} width={1} />
          )}
        </Box>
      ))}
    </Box>
  );
};

const StepBox = styled(Box)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const SectionCard = ({
  showStepLabel,
  showProgressBar,
  allowAccordion,
  fullWidth,
  progress,
  isComplete,
  isWithinNav,
  onClose,
  qaLabel,
  theme,
  disabled = false,
  continuePath,
  formSummaries,
  sectionKey,
  translations,
  analyticsClickedEdit,
}) => {
  const [isOpen, setIsOpen] = useState(!allowAccordion);
  const {
    title,
    description,
    progressState,
    continueLabel,
    stepLabel,
  } = translations;
  const hasSummary = formSummaries && formSummaries.length > 0;
  const shouldDisplayContinue =
    !isComplete && !disabled && continuePath && continueLabel;
  const handleOnClose = () => {
    if (isWithinNav) onClose();
  };

  return (
    <Box className={`qa-section-card-${qaLabel}`}>
      {showStepLabel && (
        <StepBox
          bgcolor="yellow.main"
          display="flex"
          justifyContent="center"
          py={0.5}
          px={2}
          width="fit-content"
        >
          <SectionTitle>{stepLabel}</SectionTitle>
        </StepBox>
      )}
      <Box
        mb={2}
        bgcolor={disabled ? "background.main" : "white"}
        borderRadius={0.2}
        border={1}
        borderColor="border.main"
        maxWidth={fullWidth ? "none" : 680}
      >
        <Box px={2}>
          <Box pb={1} pt={2} display="flex" justifyContent="space-between">
            <Box width={1} display="flex" justifyContent="space-between">
              <Box>
                <H3>{title}</H3>
              </Box>
              <Box display="flex" alignItems="flex-end" ml={0.5} pt={0.25}>
                <StatusLabel
                  status={progressState}
                  translations={translations}
                />
              </Box>
            </Box>
          </Box>
          <Box bgcolor="border.main" height={1.01} width={1} />
          <Box>
            {!isOpen && (
              <Box pt={1}>
                <Body>{description}</Body>
                {shouldDisplayContinue && (
                  <Box pb={2}>
                    <TextLink
                      displayArrowRight
                      text={continueLabel}
                      onClick={() => handleOnClose()}
                      to={continuePath}
                    />
                  </Box>
                )}
              </Box>
            )}
            {isOpen && hasSummary && (
              <>
                <FormSummaries
                  translations={translations}
                  formSummaries={formSummaries}
                  sectionKey={sectionKey}
                  analyticsClickedEdit={analyticsClickedEdit}
                />
                {shouldDisplayContinue && (
                  <Box pb={2}>
                    <TextLink
                      onClick={() => handleOnClose()}
                      to={continuePath}
                      text={continueLabel}
                      displayArrowRight
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
        {!disabled && hasSummary && allowAccordion && (
          <Box>
            <Button
              className="qa-details"
              palette={theme.palette}
              type="button"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p={0.5}
                >
                  <Box mr={0.5}>
                    <Body component="span">{translations.details}</Body>
                  </Box>
                  <ArrowBlueDownIcon />
                </Box>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p={0.5}
                >
                  <Box mr={0.5}>
                    <Body component="span">{translations.details}</Body>
                  </Box>
                  <ArrowBlueUpIcon />
                </Box>
              )}
            </Button>
          </Box>
        )}
        {disabled && (
          <Box m={1} mt={0}>
            <FlashMessage
              type="disabled"
              text={translations.disabledFlashMessage}
            />
          </Box>
        )}
        {!!progress && showProgressBar && <ProgressBar progress={progress} />}
      </Box>
    </Box>
  );
};

export default withTheme(SectionCard);
