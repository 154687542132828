import React, { useEffect } from "react";
import { connect } from "react-redux";

import PrimaryBeneficiaryForm from "../../../components/forms/your-estate/PrimaryBeneficiaryForm";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { primaryBeneficiaryPageProps } from "../../../selectors/allocations";
import { ALLOCATIONS_GUIDANCE_PATH } from "../../../constants/routes";
import { PRIMARY_BENEFICIARY_FORM_ID } from "../../../constants/forms";
import { updatePrimaryBeneficiary } from "../../../actions/allocations";
import { getFormData } from "../../../actions/forms";

const PrimaryBeneficiaryPage = ({
  getFormData,
  updatePrimaryBeneficiary,
  hasLoaded,
  ...restOfProps
}) => {
  useEffect(() => {
    getFormData(PRIMARY_BENEFICIARY_FORM_ID);
  }, []);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <PrimaryBeneficiaryForm
      backLink={ALLOCATIONS_GUIDANCE_PATH}
      onSubmit={() => updatePrimaryBeneficiary()}
      {...restOfProps}
    />
  );
};

export default connect(primaryBeneficiaryPageProps, {
  getFormData,
  updatePrimaryBeneficiary,
})(PrimaryBeneficiaryPage);
