import React from "react";

import SvgIcon from "./SvgIcon";

const InsuranceAssetIcon = ({ isDesktop }) => {
  return (
    <SvgIcon fill="none" width={isDesktop ? 100 : 72} viewBox="0 0 100 74.12">
      <g clipPath="url(#clip0_8353_84739)">
        <path
          d="M50 74.1203C77.6142 74.1203 100 59.2285 100 40.8585C100 22.4885 77.6142 7.59668 50 7.59668C22.3858 7.59668 0 22.4885 0 40.8585C0 59.2285 22.3858 74.1203 50 74.1203Z"
          fill="#F6F6F6"
        />
        <path
          d="M61.3734 43.7337C52.3605 44.5062 45.1502 46.5663 45.279 48.3259C45.3219 49.0985 46.8241 49.6993 49.2704 50.0427L33.8627 54.4204C33.1331 54.635 33.4335 55.7079 34.1631 55.5792C34.1631 55.5792 51.8884 51.5448 56.7382 50.429C58.3262 50.386 60.0429 50.3002 61.8026 50.1285C70.8155 49.356 78.0258 47.2959 77.897 45.5362C77.7683 43.7337 70.3863 42.9182 61.3734 43.7337Z"
          fill="#2F455F"
        />
        <path
          d="M76.2661 38.7983C82.2318 26.1802 75.0215 10.3004 60.1717 3.30469C66.9958 6.52357 66.2661 22.3176 58.7983 39.0558C63.2619 34.9356 73.0902 36.3519 76.2661 38.7983Z"
          fill="#004EB6"
        />
        <path
          d="M60.1718 3.30471C45.3649 -3.691 28.4979 0.815439 22.5752 13.3905C27.2962 14.7639 33.0473 20.7296 33.4336 27.0815C41.631 10.6438 53.3906 0.0858242 60.1718 3.30471Z"
          fill="#004EB6"
        />
        <path
          d="M60.1718 3.30486C53.3907 0.0859802 41.631 10.6439 33.4336 27.0817C42.5752 25.0645 56.5237 32.704 58.7984 39.0559C66.2662 22.3177 66.9958 6.52375 60.1718 3.30486Z"
          fill="#2F455F"
        />
        <path
          d="M57.9977 6.57713L35.9443 52.0742L37.2189 52.692L59.2722 7.19492L57.9977 6.57713Z"
          fill="#FFDC1E"
        />
        <path
          d="M55.7082 13.6911C55.6224 13.6911 55.4936 13.6482 55.4078 13.6052C55.0644 13.4336 54.8927 13.0044 55.0644 12.661L60.5151 1.28765C60.6867 0.944302 61.1159 0.772628 61.4593 0.944302C61.8026 1.11598 61.9743 1.54516 61.8026 1.88851L56.352 13.3048C56.2232 13.5623 55.9657 13.6911 55.7082 13.6911Z"
          fill="#E39F3A"
        />
        <path
          d="M76.2663 38.7983C82.232 26.1802 75.0217 10.3004 60.1719 3.30469C64.85 5.53645 66.1805 13.6051 64.4637 24.4635C76.1375 26.6952 77.7684 35.6223 76.2663 38.7983Z"
          fill="#3989E5"
        />
        <path
          d="M60.1718 3.30471C45.3649 -3.691 28.4979 0.815439 22.5752 13.3905C24.0773 10.2575 33.8627 5.92273 41.1589 13.4335C48.455 5.19312 55.4936 1.07295 60.1718 3.30471Z"
          fill="#3989E5"
        />
        <path
          d="M60.1718 3.30455C55.4936 1.07279 46.6954 4.07708 41.116 13.4333C49.6567 11.8024 60.7297 16.8239 64.4636 24.4633C68.5838 11.502 64.8928 5.5363 60.1718 3.30455Z"
          fill="#71ADEE"
        />
        <path
          d="M42.0172 43.7338C42.103 43.5621 41.7167 43.1759 41.2017 42.9183C40.6438 42.6608 40.1288 42.575 40.0429 42.7896H40L35.5794 51.9312C35.279 52.575 34.721 53.0471 34.0773 53.2617C33.3906 53.4763 32.7039 53.4334 32.0601 53.1329C31.4163 52.8325 30.9442 52.2746 30.7296 51.6308C30.515 50.9441 30.558 50.2574 30.8584 49.6136L31.8455 47.5965H31.8026C31.8884 47.4248 31.5022 47.0385 30.9871 46.781C30.4292 46.5235 29.9142 46.4377 29.8283 46.6523L28.8412 48.6694C27.6824 51.1158 28.6695 54.0342 31.1159 55.2359C31.8026 55.5793 32.5322 55.708 33.2618 55.708C33.8198 55.708 34.3348 55.6222 34.8927 55.4505C36.1374 55.0214 37.1245 54.1201 37.6824 52.9613L42.0172 43.7338Z"
          fill="#E39F3A"
        />
      </g>
      <defs>
        <clipPath id="clip0_8353_84739">
          <rect width="100" height="74.1202" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default InsuranceAssetIcon;
