import React from "react";
import R from "ramda";

import PremiumProductIcon from "../ui/icons/PremiumProductIcon";
import EssentialsProductIcon from "../ui/icons/EssentialsProductIcon";
import BundleProductIcon from "../ui/icons/BundleProductIcon";

export const productConstants = {
  essentials: {
    textColor: "primary",
    secondaryTextColor: "secondary",
    backgroundColor: "gray.main",
    secondaryBackgroundColor: "willfulWhite.main",
    icon: <EssentialsProductIcon width={110} height={110} />,
    iconSmall: <EssentialsProductIcon width={88} height={88} />,
    iconExtraSmall: <EssentialsProductIcon width={60} height={60} />,
    cartIcon: <EssentialsProductIcon width={48} height={48} />,
  },
  premium: {
    textColor: "willfulxxxLightBlue",
    secondaryTextColor: "white",
    backgroundColor: "willfulBlue.main",
    secondaryBackgroundColor: "willfulxxxLightBlue.main",
    icon: <PremiumProductIcon width={120} height={120} />,
    iconSmall: <PremiumProductIcon width={88} height={88} />,
    iconExtraSmall: <PremiumProductIcon width={60} height={60} />,
    cartIcon: <PremiumProductIcon width={48} height={48} />,
  },
  bundle: {
    textColor: "willfulLightGrey",
    secondaryTextColor: "white",
    backgroundColor: "willfulGrey.main",
    secondaryBackgroundColor: "gray.main",
    icon: <BundleProductIcon width={110} height={110} />,
    iconSmall: <BundleProductIcon width={88} height={88} />,
    iconExtraSmall: <BundleProductIcon width={60} height={60} />,
    cartIcon: <BundleProductIcon width={48} height={48} />,
  },
  couples: {
    textColor: "willfulLightGrey",
    secondaryTextColor: "white",
    backgroundColor: "willfulGrey.main",
    icon: <BundleProductIcon width={110} height={110} />,
    iconSmall: <BundleProductIcon width={88} height={88} />,
    iconExtraSmall: <BundleProductIcon width={60} height={60} />,
    cartIcon: <BundleProductIcon width={48} height={48} />,
  },
  notarial: {
    textColor: "willfulxxxLightBlue",
    secondaryTextColor: "white",
    backgroundColor: "willfulBlue.main",
    secondaryBackgroundColor: "willfulxxxLightBlue.main",
    icon: <PremiumProductIcon width={110} height={110} />,
    iconSmall: <PremiumProductIcon width={88} height={88} />,
    iconExtraSmall: <PremiumProductIcon width={60} height={60} />,
    cartIcon: <PremiumProductIcon width={48} height={48} />,
  },
};

export const notarizationStatuses = {
  unavailable: "unavailable",
  unscheduled: "unscheduled",
  scheduled: "scheduled",
  completed: "completed",
};

export const inviteStatuses = {
  available: "available",
  invited: "invited",
  consumed: "consumed",
};

export const plansTableData = {
  essentials: {
    will: true,
    poa: false,
    asset: false,
    notarization: false,
    freeUpdatesDeprecated: true,
    registerWill: true,
    education: true,
    bookcallDeprecated: true,
    support: true,
    emailInvitesDeprecated: false,
    multiplePlansDeprecated: false,
    discountedRatesDeprecated: false,
    save: false,
    execution: false,
    willRegistry: false,
    probate: false,
  },
  premium: {
    will: true,
    poa: true,
    asset: true,
    freeUpdatesDeprecated: true,
    registerWill: true,
    education: true,
    bookcallDeprecated: true,
    support: true,
    emailInvitesDeprecated: false,
    multiplePlansDeprecated: false,
    discountedRatesDeprecated: false,
    save: false,
  },
  bundle: {
    will: true,
    poa: true,
    asset: true,
    freeUpdatesDeprecated: true,
    registerWill: true,
    education: true,
    bookcallDeprecated: true,
    support: true,
    emailInvitesDeprecated: true,
    multiplePlansDeprecated: true,
    discountedRatesDeprecated: true,
    save: true,
  },
  notarial: {
    will: true,
    notarization: true,
    freeUpdatesDeprecated: true,
    registerWill: true,
    support: true,
    education: true,
    bookcallDeprecated: true,
  },
};

const lastWillRowOrder = {
  title: "will",
  leadIn: "willLeadIn",
  rows: ["childGuardians", "petGuardians", "appointees", "distribute", "gifts"],
  tooltips: ["childGuardians"],
};

const lastWillRowOrderQC = {
  title: "will",
  rows: [
    "personalizedWill",
    "appointees",
    "distribute",
    "gifts",
    "childGuardians",
    "petGuardians",
  ],
  tooltips: ["childGuardians"],
};

const poaRowOrder = {
  title: "poa",
  leadIn: "poaLeadIn",
  rows: ["attorneyProperty", "attorneyHealthcare"],
  tooltips: ["attorneyProperty", "attorneyHealthcare"],
};

const assetListRowOrder = {
  title: "asset",
  leadIn: "assetsLeadIn",
  rows: ["listAssets", "storeInformation", "minimizeGuess"],
  tooltips: [],
};

const benefitsRowOrder = {
  title: "benefits",
  rows: [
    "freeUpdatesDeprecated",
    "registerWill",
    "support",
    "education",
    "bookcallDeprecated",
    "emailInvitesDeprecated",
    "multiplePlansDeprecated",
    "discountedRatesDeprecated",
  ],
  tooltips: ["freeUpdatesDeprecated", "registerWill"],
};

const benefitsRowOrderQC = {
  title: "benefits",
  rows: ["freeUpdatesDeprecated", "support", "education", "bookcallDeprecated"],
};

const notarizationRowOrder = {
  title: "notarization",
  rows: ["execution", "willRegistry", "probate"],
};

export const plansTableOrdering = (isQuebec) => {
  const order = isQuebec
    ? [lastWillRowOrderQC, notarizationRowOrder, benefitsRowOrderQC]
    : [lastWillRowOrder, poaRowOrder, assetListRowOrder, benefitsRowOrder];

  return R.flatten(order);
};
