import { createSelector, createStructuredSelector } from "reselect";

import { selectTranslations } from "./translations";
import { selectHasLoadedDataById } from "./requests";
import { selectNextPathAfterPlansGuidancePages } from "./navigation";
import {
  selectAvailableProducts,
  selectEssentialsPrice,
  selectEssentialsStrikeThroughPrice,
  selectShowExclusiveOffer,
} from "./plans";
import { RECOMMENDATION_FORM_ID } from "../constants/forms";
import { selectIsFrenchSelected } from "./language";
import { PREMIUM_PLAN } from "../constants/plans";

const essentialsPlanGuidancePageTranslations = selectTranslations([
  "global",
  "plansCards",
  "premiumAndEssentialsPlanGuidancePage",
]);

const selectEssentailsPlanGuidancePageTranslations = createSelector(
  essentialsPlanGuidancePageTranslations,
  (baseTranslations) => {
    return {
      ...baseTranslations,
    };
  },
);

const selectIsPremiumPlanAvailable = createSelector(
  selectAvailableProducts,
  (availableProducts) => {
    return availableProducts.find((product) => product.symbol === PREMIUM_PLAN);
  },
);

export const essentialsPlanGuidanceProps = createStructuredSelector({
  translations: selectEssentailsPlanGuidancePageTranslations,
  hasLoaded: selectHasLoadedDataById(RECOMMENDATION_FORM_ID),
  price: selectEssentialsPrice,
  strikeThroughPrice: selectEssentialsStrikeThroughPrice,
  nextSectionPath: selectNextPathAfterPlansGuidancePages,
  isFrenchSelected: selectIsFrenchSelected,
  showExclusiveOffer: selectShowExclusiveOffer,
  isPremiumPlabAvailable: selectIsPremiumPlanAvailable,
});
