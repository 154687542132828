import styled from "styled-components";
import { media, colorPalette } from "../../../../config/Theme";

const borderColor = (props) => {
  if (props.error) return colorPalette.error;
  if (props.isOpen) return colorPalette.willfulBlue;
  return colorPalette.willfulGrey2;
};

export const DropdownSelectWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .dropdown-label {
    margin-bottom: 8px;
  }

  .dropdown-toggle {
    padding: 16px;
    cursor: pointer;
    margin: 0;
    border: 1px solid ${(props) => borderColor(props)};
    border-bottom: ${(props) => props.isOpen && "none"};
    border-radius: 2px;
    background-color: white;

    /* this component should match styles of Body
    TODO: contain these styles in a constant that
    we can keep aligned between both components
   */
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.01em;

    ${media.desktop} {
      font-size: 20px;
    }

    &:focus {
      border: 1px solid ${colorPalette.willfulBlue};
      border-bottom: ${(props) => props.isOpen && "none"};
      outline: none;
      box-shadow: none;
    }

    &:hover {
      border: 1px solid ${colorPalette.willfulBlue};
      border-bottom: ${(props) => props.isOpen && "none"};
      outline: none;
      box-shadow: none;
    }
  }

  .dropdown-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: left;

    font-family: "TT Norms";
    color: ${(props) =>
      props.selectedOption
        ? colorPalette.willfulGrey
        : colorPalette.willfulGrey1};
    font-weight: ${(props) => props.selectedOption && "700"};
  }

  .dropdown-panel {
    position: ${(props) => (props.contentPush ? "static" : "absolute")};
    z-index: 25;
    top: 85px;
    display: ${(props) => (props.isOpen ? "block" : "none")};
    background: white;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    width: 100%;
    max-height: 350px;
    overflow: scroll;
    border: 1px solid ${colorPalette.willfulBlue};
    border-top: none;
    outline: none;
    box-shadow: none;

    :focus {
      outline: none;
      box-shadow: none;
    }

    li {
      display: flex;
      align-items: center;
      list-style: none;
      width: 100%;
      padding: 16px;
      color: ${colorPalette.willfulGrey};

      &:hover {
        background: ${colorPalette.willfulxxxLightBlue};
      }
    }
  }
`;
