import { getFormValues } from "redux-form";
import { createSelector } from "reselect";
/* Currently, when we remove a dynamic field, we keep the field in state
 * but hide it visually by adding a _destory flag. the _destroy flag is used
 * to signify to rails that the resource is to be deleted. This makes
 * FE code that relies on indexes a bit challenging. This selector returns a map
 * of the fields of the provided form and maps over each field adding a level property
 * which correlates to its visual index.
 * TODO: phase out _destory flags and do not store removed fields in redux-form state.
 */
const DEFAULT_APPOINT_LEVEL = { total: 0 };

export const selectAppointeeLevelIndexs = (formName) =>
  createSelector(getFormValues(formName), (formValues = {}) => {
    if (!formValues || !formValues[formName]) return DEFAULT_APPOINT_LEVEL;

    return formValues[formName].reduce(
      (allFormValues, currentValue = {}, index) => {
        const previousValue = allFormValues[index - 1];
        const previousTotal = previousValue ? previousValue.removedTotal : 0;
        if (currentValue._destroy) {
          return {
            ...allFormValues,
            [index]: {
              level: "",
              removedTotal: previousTotal + 1,
            },
          };
        }
        const newLevelTotal = index - previousTotal;
        return {
          ...allFormValues,
          total: newLevelTotal,
          [index]: {
            level: newLevelTotal,
            removedTotal: previousTotal,
          },
        };
      },
      DEFAULT_APPOINT_LEVEL,
    );
  });
