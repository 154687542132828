import React from "react";

import { reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";

import { PrimaryButton } from "../../ui/Button";
import { ErrorMessage } from "../../ui/Error";
import {
  CHILDREN_GUARDIANS_MODAL_FORM_ID,
  CHILDREN_GUARDIANS_FIELD_ID,
} from "../../../constants/forms";
import CoupleNameFields from "../../ui/inputs/CoupleNameFields";

const ChildrenGuardiansModal = ({
  error,
  handleSubmit,
  isLoading,
  translations,
  invalid,
  submitting,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" justifyContent="center" pt={2}>
        <CoupleNameFields
          useTheir
          fieldName={CHILDREN_GUARDIANS_FIELD_ID}
          translations={translations}
          formId={CHILDREN_GUARDIANS_MODAL_FORM_ID}
        />
        <PrimaryButton
          fullWidth
          isLoading={isLoading}
          text={translations.button.submit}
          opacity={invalid ? 0.5 : 1}
          isDisabled={submitting}
        />
        <ErrorMessage error={error} />
      </Box>
    </form>
  );
};

export default reduxForm({
  form: CHILDREN_GUARDIANS_MODAL_FORM_ID,
})(ChildrenGuardiansModal);
