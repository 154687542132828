import React from "react";

const EssentialsPreviewImage = () => {
  return (
    <img
      width="100%"
      src="/images/doc_preview_essentials.png"
      alt="document preview"
    />
  );
};

export default EssentialsPreviewImage;
