import React, { useState } from "react";
import styled, { css } from "styled-components";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Body, SmallBody } from "../Typography";
import { colorPalette } from "../../../config/Theme";
import CheckmarkGreen from "../icons/CheckmarkGreen";

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const IconWrapper = styled.label`
  cursor: pointer;
  padding: ${(props) => (props.isMobile ? "8px" : "16px")};
  height: ${(props) => (props.isMobile ? "64px" : "320px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "row" : "column")};
  align-items: center;
  justify-content: center;
  background-color: ${colorPalette.white};

  border: 1px solid
    ${(props) =>
      props.checked ? colorPalette.willfulBlue : colorPalette.willfulGrey2};

  ${HiddenCheckbox}:focus-visible + & {
    outline: 2px solid ${colorPalette.willfulBlue};
    outline-offset: 8px;
  }
`;

const CheckmarkWrapper = styled(Box)`
  opacity: ${(props) => (props.checked ? 1 : 0.1)};
  transition: opacity 0.2s ease-in-out;
  ${(props) =>
    props.isMobile &&
    css`
      padding-right: 4px;
      padding-bottom: 4px;
    `}
`;

const CheckboxIconInput = ({
  value,
  isChecked,
  label,
  iconComponent,
  isTabletDown,
  onChange,
  isMobile,
}) => {
  const [checked, setChecked] = useState(isChecked);

  const handleChange = (checkboxValue) => {
    const newChecked = !checked;
    setChecked(newChecked);
    if (onChange) {
      onChange(checkboxValue, newChecked);
    }
  };

  const LabelComponent = isTabletDown ? Body : SmallBody;

  const LabelComponentWrapper = styled(LabelComponent)`
    margin-bottom: 0;
    height: 100%;
  `;

  return (
    <Box>
      <HiddenCheckbox
        type="checkbox"
        id={value}
        name={value}
        checked={isChecked}
        onChange={() => handleChange(value)}
      />
      <IconWrapper htmlFor={value} checked={checked} isMobile={isMobile}>
        <Box
          minHeight="64px"
          justifyContent="center"
          alignContent="center"
          paddingTop="8px"
        >
          {iconComponent}
        </Box>
        <Box
          mt={isMobile ? 0 : 2}
          minHeight="64px"
          paddingX="10px"
          width="100%"
          alignItems="center"
          display="flex"
        >
          <LabelComponentWrapper align={isMobile ? "left" : "center"}>
            {label}
          </LabelComponentWrapper>
        </Box>
        <CheckmarkWrapper
          mt={1}
          pr={1}
          display="flex"
          alignSelf="flex-end"
          checked={checked}
          isMobile={isMobile}
        >
          <CheckmarkGreen isMobile={isMobile} />
        </CheckmarkWrapper>
      </IconWrapper>
    </Box>
  );
};

const CheckboxIconGrid = ({
  defaultValues = [],
  optionList = [],
  onChange = () => {},
  isTabletDown,
  isMobile,
}) => {
  return (
    <>
      <Grid container spacing={isMobile ? 1 : 2}>
        {optionList.map((option) => {
          const iconComponent = option.iconComponent || null;
          return (
            <Grid
              item
              sm={12}
              md={6}
              lg={3}
              key={option.label}
              className={`qa-checkbox-${option.value}`}
            >
              <CheckboxIconInput
                label={option.label}
                value={option.value}
                isTabletDown={isTabletDown}
                iconComponent={iconComponent}
                isChecked={defaultValues.includes(option.value)}
                onChange={onChange}
                isMobile={isMobile}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default CheckboxIconGrid;
