export const updateExperiments = ({ experimentId, variationId }) => ({
  type: UPDATE_EXPERIMENTS_TYPE,
  payload: { experimentId, variationId },
});

export const initializeExperiments = () => ({
  type: INITIALIZE_EXPERIMENTS_TYPE,
});

export const UPDATE_EXPERIMENTS_TYPE = "UPDATE_EXPERIMENTS";
export const INITIALIZE_EXPERIMENTS_TYPE = "INITIALIZE_EXPERIMENTS";
