import React from "react";
import { Box } from "@material-ui/core";
import { H4, SmallBody, SmallTextLink } from "./Typography";
import { CHECKOUT_PATH } from "../../constants/routes";

const CheckoutNowCard = ({ translations, analyticsClickedCheckoutNow }) => {
  return (
    <Box
      bgcolor="white"
      border={1}
      borderColor="border.main"
      px={1.5}
      py={1.5}
      borderRadius="4px"
      marginBottom={2}
    >
      <Box mb={1.5}>
        <Box mb={0.5}>
          <H4 align="left">{translations.notReadyToComplete}</H4>
        </Box>
        <SmallBody>{translations.checkoutNowComeBackLater}</SmallBody>
      </Box>
      <Box display="flex">
        <SmallTextLink
          onClick={() => analyticsClickedCheckoutNow()}
          to={CHECKOUT_PATH}
          text={translations.checkoutNow}
          displayArrowRight
        />
      </Box>
    </Box>
  );
};

export default CheckoutNowCard;
