import { takeEvery, call, put } from "redux-saga/effects";

import {
  notifiedAppointee,
  NOTIFY_APPOINTEE_TYPE,
} from "../actions/appointees";
import { submitAppointeeNotification } from "../api/notifications";
import { submitFormRequest } from "./forms";
import { NOTIFICATIONS_FORM_ID } from "../constants/forms";

// Map the expected FE roles to the BE role names
const backendRoleMap = {
  executor: "executor",
  petGuardian: "pet_guardian",
  childrenGuardian: "guardian",
  propertyAttorneys: "property_attorney",
  personalAttorneys: "personal_attorney",
};

function* notifyAppointee(action) {
  const { email, ccUser, role, id, isSpouse } = action.payload;

  yield call(submitFormRequest, {
    apiCall: submitAppointeeNotification,
    formId: NOTIFICATIONS_FORM_ID,
    values: {
      email,
      role: backendRoleMap[role],
      appointeeId: id,
      copyMe: ccUser,
    },
    isSpouseValues: isSpouse,
  });
  const appointeeKey = `${role}-${id}-${isSpouse}`;
  yield put(notifiedAppointee({ key: appointeeKey, shouldClose: true }));
}

export function* watchNotifyAppointee() {
  yield takeEvery(NOTIFY_APPOINTEE_TYPE, notifyAppointee);
}
