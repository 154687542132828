import { combineReducers } from "redux";
import {
  DISPLAY_EMAIL_PREVIEW_MODAL_TYPE,
  CLOSE_EMAIL_PREVIEW_MODAL_TYPE,
} from "../actions/email-preview-modal";

const visible = (state = false, { type }) => {
  switch (type) {
    case DISPLAY_EMAIL_PREVIEW_MODAL_TYPE:
      return true;
    case CLOSE_EMAIL_PREVIEW_MODAL_TYPE:
      return false;
    default:
      return state;
  }
};

const key = (state = null, { type, payload }) => {
  switch (type) {
    case DISPLAY_EMAIL_PREVIEW_MODAL_TYPE:
      return payload.key;
    case CLOSE_EMAIL_PREVIEW_MODAL_TYPE:
      return null;
    default:
      return state;
  }
};

const appointeeFirstName = (state = null, { type, payload }) => {
  switch (type) {
    case DISPLAY_EMAIL_PREVIEW_MODAL_TYPE:
      return payload.appointeeFirstName;
    case CLOSE_EMAIL_PREVIEW_MODAL_TYPE:
      return null;
    default:
      return state;
  }
};

const appointeeLevel = (state = null, { type, payload }) => {
  switch (type) {
    case DISPLAY_EMAIL_PREVIEW_MODAL_TYPE:
      return payload.appointeeLevel;
    case CLOSE_EMAIL_PREVIEW_MODAL_TYPE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  visible,
  key,
  appointeeFirstName,
  appointeeLevel,
});
