import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, withTheme } from "@material-ui/styles";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Hidden } from "@material-ui/core";
import { PageLayout } from "../../components/ui/PageLayout";
import { PageWrapper } from "../Layout";
import { Body, H2, SectionTitle } from "../../components/ui/Typography";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { fetchDashboard } from "../../actions/dashboard";
import { BackupsGuidanceProps } from "../../selectors/backups-guidance";
import BackupsGuidancePageIcon from "../../components/ui/icons/BackupsGuidancePageIcon";
import { ButtonLink, PreviousLink } from "../../components/ui/Button";
import { BACKUPS_PATH, PREDECEASE_PATH } from "../../constants/routes";
import { useStickyBottomBoxStyle } from "../../components/ui/utils/sticky-bottom-box";

const BackupsGuidancePage = ({ translations, hasLoaded, fetchDashboard }) => {
  useEffect(() => {
    if (!hasLoaded) {
      fetchDashboard();
    }
  }, [fetchDashboard, hasLoaded]);

  const theme = useTheme();
  const isLargeDesktop = useMediaQuery(theme.breakpoints.only("xl"));
  const isDesktop = useMediaQuery(theme.breakpoints.only("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const stickyButtonStyle = useStickyBottomBoxStyle();
  if (!hasLoaded)
    return (
      <Box flexGrow="1">
        <LoadingSpinner />
      </Box>
    );

  return (
    <Box
      width="100%"
      height="100%"
      bgcolor="willfulxxxLightBlue.main"
      flex="1"
      display="flex"
      flexDirection="column"
      alignItems="center"
      flexGrow={1}
    >
      <PageWrapper flexGrow={1}>
        <PageLayout>
          <Grid container>
            <Grid item xl={1} lg={0} md={1} />
            <Grid item xl={10} lg={12} md={10} xs={12}>
              <Grid item container xl={12} lg={12} md={12} xs={12}>
                <Grid item xl={6} lg={6} md={12} xs={12}>
                  <Box mb={isLargeDesktop || isDesktop ? 4.5 : 3}>
                    <Hidden lgUp>
                      <Box align="center" mb={2}>
                        <BackupsGuidancePageIcon isDesktop={false} />
                      </Box>
                    </Hidden>
                    <Box mb={1}>
                      <Box mb={1}>
                        <SectionTitle color="willfulBlue" align="left">
                          {translations.upNext}
                        </SectionTitle>
                      </Box>
                      <H2 align="left" marginBottom="0">
                        {translations.title}
                      </H2>
                    </Box>
                    <Box>
                      <Body>{translations.paragraph1}</Body>
                    </Box>
                    <Box>
                      <Body component="span" bold={false}>
                        {translations.paragraph2}
                      </Body>
                    </Box>
                    <Box mt={2} display="inline-block">
                      <Box>
                        <ButtonLink
                          className={
                            isMobile
                              ? `${stickyButtonStyle.stickyBottomBox} qa-continue`
                              : "qa-continue"
                          }
                          fullWidth={isMobile}
                          to={BACKUPS_PATH}
                          text={translations.continueButton}
                          displayArrowRight
                        />
                      </Box>
                      <Box mt={1.5} textAlign="center">
                        <PreviousLink
                          className="qa-back"
                          to={PREDECEASE_PATH}
                          text={translations.previousButton}
                          displayArrowLeft
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Hidden mdDown>
                  <Grid item xl={6} lg={6}>
                    <Box align="right">
                      <BackupsGuidancePageIcon isDesktop />
                    </Box>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
            <Grid item xl={1} lg={0} md={1} />
          </Grid>
        </PageLayout>
      </PageWrapper>
    </Box>
  );
};

export default connect(BackupsGuidanceProps, {
  fetchDashboard,
})(withTheme(BackupsGuidancePage));
