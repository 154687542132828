import { createStructuredSelector } from "reselect";

import { selectTranslations } from "./translations";
import { selectHasLoadedDataById } from "./requests";
import { selectHasSpouse } from "./spouse";

export const allocationsGuidanceProps = createStructuredSelector({
  translations: selectTranslations(["global", "allocationsGuidancePage"]),
  hasSpouse: selectHasSpouse,
  hasLoaded: selectHasLoadedDataById("dashboard"),
});
