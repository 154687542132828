import React, { useEffect } from "react";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";

import { plansPageProps } from "../../selectors/plans";
import {
  fetchPlans,
  updatePlans,
  setLoadingPlan,
  expandCollapseTableRow,
} from "../../actions/plans";
import { analyticsClickExternalLink } from "../../actions/analytics";
import {
  DASHBOARD_PATH,
  ESSENTIALS_PLAN_GUIDANCE_PATH,
} from "../../constants/routes";
import { PRODUCTS_FORM_ID } from "../../constants/forms";

import PlansForm from "../../components/forms/PlansForm";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { displayTooltipModal } from "../../actions/tooltip-modal";

const PlansPage = ({
  fetchPlans,
  hasLoaded,
  updatePlans,
  analyticsClickExternalLink,
  setLoadingPlan,
  displayTooltipModal,
  ...restOfProps
}) => {
  useEffect(() => {
    fetchPlans(PRODUCTS_FORM_ID);
  }, [fetchPlans]);

  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <PlansForm
      updatePlans={updatePlans}
      continueLink={ESSENTIALS_PLAN_GUIDANCE_PATH}
      analyticsClickExternalLink={analyticsClickExternalLink}
      setLoadingPlan={setLoadingPlan}
      displayTooltipModal={displayTooltipModal}
      expandCollapseTableRow={expandCollapseTableRow}
      {...restOfProps}
    />
  );
};

export default connect(plansPageProps, {
  updatePlans,
  fetchPlans,
  analyticsClickExternalLink,
  setLoadingPlan,
  displayTooltipModal,
  goBack,
  expandCollapseTableRow,
})(PlansPage);
