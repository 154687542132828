import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import PetsForm from "../../../components/forms/your-family/PetsForm";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { petsPageProps } from "../../../selectors/pets";
import { PETS_FORM_ID } from "../../../constants/forms";
import { getFormData } from "../../../actions/forms";
import { petsNextPage } from "../../../actions/pets";

const PetsPage = ({ getFormData, hasLoaded, backLink, ...restOfProps }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    getFormData(PETS_FORM_ID);
  }, [getFormData]);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <PetsForm
      backLink={backLink}
      onSubmit={() => dispatch(petsNextPage())}
      {...restOfProps}
    />
  );
};

export default connect(petsPageProps, {
  getFormData,
})(PetsPage);
