import React from "react";
import styled from "styled-components";

import { withTheme } from "@material-ui/styles";
import Box from "@material-ui/core/Box";

import StatusLabel from "./StatusLabel";
import { SecondaryButton, SmallTextButton } from "./Button";
import { ArrowBlueIcon } from "./icons/ArrowIcons";
import { H3, Body, SmallBody } from "./Typography";

import PhysicalAssetIcon from "./icons/PhysicalAssetIcon";
import InsuranceAssetIcon from "./icons/InsuranceAssetIcon";
import FinancialAssetIcon from "./icons/FinancialAssetIcon";
import FlashMessage from "./FlashMessage";

const AssetIcon = ({ assetKey, isDesktop }) => {
  switch (assetKey) {
    case "physicalAssets":
      return <PhysicalAssetIcon isDesktop={isDesktop} />;
    case "insuranceAssets":
      return <InsuranceAssetIcon isDesktop={isDesktop} />;
    case "financialAssets":
      return <FinancialAssetIcon isDesktop={isDesktop} />;
    default:
      return null;
  }
};

const buildAssetLabel = (numberOfAssets, singularLabel, pluralLabel) => {
  if (numberOfAssets === 1) {
    return singularLabel.replace("X", 1);
  }
  return pluralLabel.replace("X", numberOfAssets);
};

const Button = styled.button`
  background: none;
  border-right: none;
  border-left: none;
  border-top: 1px solid ${(props) => props.palette.border.main};
  border-bottom: none;
  width: 100%;
  cursor: pointer;
  outline: none;

  &:hover {
    background: ${(props) => props.palette.willfulxxxLightBlue.main};
  }
`;

const AssetListCard = ({
  translations,
  theme,
  isCardExpanded,
  setExpanded,
  handleEditAsset,
  handleAddAsset,
  isDesktop,
  assets,
  assetKey,
  onDisplayWarningModal,
  hasPropertyAssets = false,
}) => {
  const assetCategory = translations[assetKey];
  const hasAssets = assets.length > 0;
  return (
    <Box
      mb={2}
      bgcolor="white"
      borderRadius={4}
      border={1}
      borderColor="border.main"
    >
      <Box p={2}>
        <Box display="flex">
          <Box>
            <Box display="flex" flexWrap="wrap">
              <Box mr={1} mb={0.5}>
                <H3 align="left">{assetCategory.title}</H3>
              </Box>
              {hasAssets && (
                <Box mb={1}>
                  <StatusLabel
                    label={buildAssetLabel(
                      assets.length,
                      translations.assetsLabelSingular,
                      translations.assetsLabelPlural,
                    )}
                    status="complete"
                  />
                </Box>
              )}
            </Box>
            <Box mb={2}>
              <Body>{assetCategory.summary}</Body>
            </Box>
          </Box>
          <Box ml={1}>
            <AssetIcon assetKey={assetKey} isDesktop={isDesktop} />
          </Box>
        </Box>
        {isCardExpanded &&
          assets.map(({ assetId, description, assetType }) => (
            <Box
              key={assetId}
              mb={1.5}
              p={1.5}
              bgcolor="background.main"
              borderRadius={4}
              border={1}
              borderColor="border.main"
              display="flex"
              justifyContent="space-between"
            >
              <Box>
                <Box mb={0.5}>
                  <Body component="span" bold data-hj-suppress>
                    {description}
                  </Body>
                </Box>
                <SmallBody data-hj-suppress>
                  {translations.assetTypes[assetType]}
                </SmallBody>
              </Box>
              <SmallTextButton
                display="block"
                className="qa-asset-edit-btn"
                text={translations.button.edit}
                onClick={() => handleEditAsset(assetId)}
              />
            </Box>
          ))}
        {isCardExpanded && hasPropertyAssets && (
          <Box mt={2} mb={2}>
            <FlashMessage type="warning">
              <OutOfProvinceWarningContent
                translations={translations.physicalAssets}
                onDisplayWarningModal={onDisplayWarningModal}
              />
            </FlashMessage>
          </Box>
        )}
        <SecondaryButton
          displayPlusIcon
          fullWidth
          text={translations.button.addAsset}
          onClick={handleAddAsset}
        />
      </Box>
      {hasAssets && (
        <Box>
          <Button
            className="qa-details"
            palette={theme.palette}
            type="button"
            onClick={() => setExpanded(!isCardExpanded)}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={0.5}
            >
              <Box mr={0.5}>
                <Body component="span" color="willfulBlue">
                  {translations.details}
                </Body>
              </Box>
              <ArrowBlueIcon up={!isCardExpanded} />
            </Box>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default withTheme(AssetListCard);

const OutOfProvinceWarningContent = ({
  translations,
  onDisplayWarningModal,
}) => {
  return (
    <Box display="flex">
      <SmallBody>
        {translations.property.outOfProvinceWarning.text}{" "}
        <SmallTextButton
          display="contents"
          text={translations.property.outOfProvinceWarning.linkText}
          onClick={onDisplayWarningModal}
        />
      </SmallBody>
    </Box>
  );
};
