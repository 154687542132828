import React from "react";
import { reduxForm } from "redux-form";
import { useMediaQuery, useTheme } from "@material-ui/core";

import { Form } from "../../ui/Form";

import BinaryRadioField from "../../ui/inputs/BinaryRadioField";
import { useValidateGenderMemo } from "../../ui/inputs/validations";

import { GENDER_FIELD_ID, GENDER_FORM_ID } from "../../../constants/forms";
import { Body } from "../../ui/Typography";

const GenderForm = ({
  handleSubmit,
  backLink,
  isLoading,
  translations,
  showFormTitle = false,
  showSectionHeading = true,
  isMiniForm = false,
  showInfoBoxAboveButtons = false,
}) => {
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Form
      isLoading={isLoading}
      onSubmit={handleSubmit}
      backLink={backLink}
      translations={translations}
      showFormTitle={showFormTitle}
      showSectionHeading={showSectionHeading}
      reverseWrap={isTabletDown}
      isMiniForm={isMiniForm}
      showInfoBoxAboveButtons={showInfoBoxAboveButtons}
    >
      {showFormTitle && <Body bold>{translations.formTitle}</Body>}
      {/* // TODO: Make Gender field a reusable component */}
      <BinaryRadioField
        name={GENDER_FIELD_ID}
        label={translations.optionListLabel}
        validate={useValidateGenderMemo(translations)}
        translations={translations}
      />
    </Form>
  );
};

export default reduxForm({
  form: GENDER_FORM_ID,
})(GenderForm);
