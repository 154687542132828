import React from "react";
import Box from "@material-ui/core/Box";
import { useMediaQuery, useTheme } from "@material-ui/core";
import PartnerDiscountIcon from "./icons/PartnerDiscountIcon";
import { Body, SmallBody } from "./Typography";

const VolumePartnerDiscountReminderCard = ({ translations }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      bgcolor="willfulxxxLightBlue.main"
      borderColor="willfulxxLightBlue.main"
      color="willfulBlue.main"
      p={1.5}
    >
      <Box display="flex" flexWrap={isMobile ? "wrap" : "nowrap"}>
        <PartnerDiscountIcon />
        <Box
          ml={1.5}
          display="flex"
          alignItems="center"
          textAlign="center"
          flexGrow={1}
          width={isMobile ? "calc(100% - 70px)" : "min-content"}
        >
          <SmallBody dangerouslySetInnerHTML={{ __html: translations.body }} />
        </Box>
        <Box
          display="flex"
          alignItems="right"
          ml="auto"
          width={isMobile ? "100%" : "auto"}
          justifyContent="right"
          mt={isMobile && 1}
        >
          <Box
            display="inline-flex"
            textAlign="center"
            borderColor="willfulBlue.main"
            py={1}
            px={2}
            border="1px solid"
            alignItems="center"
          >
            <Body component="span" color="main">
              {translations.button}
            </Body>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VolumePartnerDiscountReminderCard;
