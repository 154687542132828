import React, { useEffect } from "react";
import { connect } from "react-redux";

import MaritalStatusForm from "../../../components/forms/your-family/MaritalStatusForm";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { maritalStatusPageProps } from "../../../selectors/spouse";
import { DASHBOARD_PATH } from "../../../constants/routes";
import { getFormData } from "../../../actions/forms";
import { updateUser } from "../../../actions/user";

import { MARITAL_STATUS_FORM_ID } from "../../../constants/forms";

const MaritalStatusPage = ({
  getFormData,
  updateUser,
  hasLoaded,
  ...restOfProps
}) => {
  useEffect(() => {
    getFormData(MARITAL_STATUS_FORM_ID);
  }, []);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <MaritalStatusForm
      backLink={DASHBOARD_PATH}
      onSubmit={() => updateUser(MARITAL_STATUS_FORM_ID)}
      {...restOfProps}
    />
  );
};

export default connect(maritalStatusPageProps, { getFormData, updateUser })(
  MaritalStatusPage
);
