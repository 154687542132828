import React from "react";

import { connect } from "react-redux";

import { submitForgotPassword } from "../../actions/auth";
import { forgotPasswordPageProps } from "../../selectors/auth";
import { LOGIN_PATH, SIGNUP_PATH } from "../../constants/routes";

import ForgotPasswordForm from "../../components/forms/ForgotPasswordForm";

const ForgotPasswordPage = ({ submitForgotPassword, ...restOfProps }) => (
  <ForgotPasswordForm
    onSubmit={submitForgotPassword}
    loginLink={LOGIN_PATH}
    getStartedLink={SIGNUP_PATH}
    {...restOfProps}
  />
);

export default connect(forgotPasswordPageProps, { submitForgotPassword })(
  ForgotPasswordPage,
);
