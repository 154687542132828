import React, { useEffect } from "react";
import { connect } from "react-redux";

import { childrenGuardiansPageProps } from "../../../selectors/children";
import { CHILDREN_PATH } from "../../../constants/routes";
import { getFormData } from "../../../actions/forms";
import { childrenGuardiansnNextPage } from "../../../actions/children-guardians";
import { CHILDREN_GUARDIANS_FORM_ID } from "../../../constants/forms";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import ChildrenGuardiansFormDeprecated from "../../../components/forms/your-family/ChildrenGuardiansFormDeprecated";

const ChildrenGuardiansPageDeprecated = ({
  getFormData,
  hasLoaded,
  childrenGuardiansnNextPage,
  ...restOfProps
}) => {
  useEffect(() => {
    getFormData(CHILDREN_GUARDIANS_FORM_ID);
  }, [getFormData]);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <ChildrenGuardiansFormDeprecated
      backLink={CHILDREN_PATH}
      onSubmit={() => childrenGuardiansnNextPage()}
      {...restOfProps}
    />
  );
};

export default connect(childrenGuardiansPageProps, {
  getFormData,
  childrenGuardiansnNextPage,
})(ChildrenGuardiansPageDeprecated);
