import "whatwg-fetch";

import { SPOUSE_QUERY_PARAM } from "../constants/routes";

export const postRequest = (endpoint, config) =>
  request(endpoint, "POST", config);
export const putRequest = (endpoint, config) =>
  request(endpoint, "PUT", config);
export const getRequest = (endpoint, config) =>
  request(endpoint, "GET", config);
export const deleteRequest = (endpoint, config) =>
  request(endpoint, "DELETE", config);

export const request = (endpoint, method, config = {}) => {
  const { authHeaders, isLoggedIn, isSpouse, data, experiments } = config;
  const url = isSpouse ? `${endpoint}${SPOUSE_QUERY_PARAM}` : endpoint;

  const baseHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    experiments: JSON.stringify(experiments),
  };

  const headers = isLoggedIn
    ? {
        ...baseHeaders,
        ...authHeaders,
      }
    : baseHeaders;

  // eslint-disable-next-line no-undef
  return fetch(url, {
    headers,
    method,
    body: data ? JSON.stringify(data) : undefined,
  }).then(handleResponse);
};

const handleResponse = (response) =>
  isJson(response)
    ? response
    : Promise.reject(new Error("Invalid response: not a JSON"));

const isJson = (response) => {
  const contentType = response.headers.get("content-type");
  return (
    contentType && contentType.includes("application/json") && response.json
  );
};
