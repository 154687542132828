import R from "ramda";
import React from "react";
import { reduxForm, FieldArray } from "redux-form";
import { useDispatch } from "react-redux";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import { Form } from "../../ui/Form";
import BinaryRadioField from "../../ui/inputs/BinaryRadioField";
import { ErrorMessage } from "../../ui/Error";
import { Body } from "../../ui/Typography";
import { useValidateSpouseIsPropertyAttorneyMemo } from "../../ui/inputs/validations";
import {
  PROPERTY_ATTORNEYS_FORM_ID,
  PROPERTY_ATTORNEYS_FIELD_ID,
  SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
} from "../../../constants/forms";
import {
  openModalPropertyAttorney,
  removePropertyAttorney,
} from "../../../actions/property-attorneys";
import { orderedFillColors } from "../../../utilities/allocations";
import PeopleCard from "../../ui/PeopleCard";
import { getAppointeeLevelTranslation } from "../../../utilities/appointees";
import { SecondaryButton } from "../../ui/Button";
import H2WithUnderlineLink from "../../ui/H2WithUnderlineLink";
import { displayTooltipModal } from "../../../actions/tooltip-modal";

export const PropertyAttorneysCardFieldArray = ({
  fields,
  translations,
  handleChange,
  hasSpouse,
  spouseIsPrimary,
  appointeeLevelIndexs,
  canAddMore,
  shouldShowCTAButton,
}) => {
  const dispatch = useDispatch();
  const hasAppointeeTitle = R.has("total")(appointeeLevelIndexs);
  const allFields = fields.getAll() || [];
  const addLabel =
    allFields.filter((field) => field._destroy !== true).length === 0
      ? translations.button.addLabel
      : translations.button.addMoreLabel;
  let colorIndex = 0;

  let subQuestionText = "";
  if (hasSpouse && shouldShowCTAButton) {
    subQuestionText = spouseIsPrimary
      ? translations.subQuestionOptional
      : translations.subQuestion;
  }
  let subQuestionOptionalText = "";
  if (!hasSpouse || subQuestionText === translations.subQuestion) {
    subQuestionOptionalText = translations.subQuestionOptional;
  }

  return (
    <Box>
      {subQuestionText && (
        <Box mb={2}>
          <Body
            dangerouslySetInnerHTML={{
              __html: subQuestionText,
            }}
          />
        </Box>
      )}

      {fields.map((fieldId, index) => {
        const field = fields.get(index);
        const isFirstCard = colorIndex === 0;
        if (field && field._destroy === true) {
          return null;
        }
        const color = orderedFillColors[colorIndex % orderedFillColors.length];
        colorIndex += 1;
        const propertyAttorneyName = `${field.firstName} ${
          field.middleName || ""
        } ${field.lastName}`;
        const propertyAttorneyLevelText = getAppointeeLevelTranslation(
          appointeeLevelIndexs,
          index,
          spouseIsPrimary,
          translations,
        );
        const subText = hasAppointeeTitle
          ? `${propertyAttorneyLevelText} ${translations.appointeeTitle.toLowerCase()}`
          : "";

        return (
          <Box mb={2.5} key={`${index + 1}-${fieldId}`}>
            <PeopleCard
              id={field.id}
              fieldId={fieldId}
              translations={translations}
              text={propertyAttorneyName}
              subText={subText}
              color={color}
              onEdit={() =>
                dispatch(openModalPropertyAttorney({ ...field, index }))
              }
              onRemove={() => {
                dispatch(removePropertyAttorney(field));
                return handleChange(`${fieldId}._destroy`, true);
              }}
              {...field}
            />
            {subQuestionOptionalText && isFirstCard && (
              <Box my={2}>
                <Body
                  dangerouslySetInnerHTML={{
                    __html: subQuestionOptionalText,
                  }}
                />
              </Box>
            )}
          </Box>
        );
      })}

      {shouldShowCTAButton && canAddMore && (
        <SecondaryButton
          text={addLabel}
          fullWidth
          displayPlusIcon
          className="qa-add"
          type="button"
          onClick={() => dispatch(openModalPropertyAttorney(null))}
        />
      )}
    </Box>
  );
};

const PropertyAttorneysFormDeprecated = ({
  error,
  handleSubmit,
  backLink,
  change,
  hasSpouse,
  appointeeLevelIndexs,
  spouseIsPropertyAttorney,
  isLoading,
  translations,
  tooltipKey,
  canAddMore,
  shouldShowCTAButton,
}) => {
  const dispatch = useDispatch();
  const {
    userSpousePropertyAttorneyQuestion,
    pageTitle,
    pageTitleUnderlineLink,
    pageTitleModalKey,
  } = translations;
  const validateSpouseIsPropertyAttorney = useValidateSpouseIsPropertyAttorneyMemo(
    translations,
  );

  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));
  const shouldShowUnderlineLink = pageTitle.includes(pageTitleUnderlineLink);
  const headerWithLink = shouldShowUnderlineLink ? (
    <H2WithUnderlineLink
      text={pageTitle}
      underlineText={pageTitleUnderlineLink}
      onClick={() => dispatch(displayTooltipModal(pageTitleModalKey))}
    />
  ) : null;

  return (
    <Form
      isLoading={isLoading}
      onSubmit={handleSubmit}
      backLink={backLink}
      translations={translations}
      tooltipKey={tooltipKey}
      reverseWrap={isTabletDown}
      customHeaderComponent={headerWithLink}
    >
      {hasSpouse && (
        <>
          <Body data-hj-suppress>{userSpousePropertyAttorneyQuestion}</Body>
          <BinaryRadioField
            name={SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID}
            validate={validateSpouseIsPropertyAttorney}
            translations={translations}
          />
        </>
      )}
      <FieldArray
        rerenderOnEveryChange
        name={PROPERTY_ATTORNEYS_FIELD_ID}
        handleChange={change}
        component={PropertyAttorneysCardFieldArray}
        hasSpouse={hasSpouse}
        spouseIsPrimary={spouseIsPropertyAttorney}
        appointeeLevelIndexs={appointeeLevelIndexs}
        translations={translations}
        canAddMore={canAddMore}
        shouldShowCTAButton={shouldShowCTAButton}
      />
      <Box mb={2}>
        <ErrorMessage error={error} />
      </Box>
    </Form>
  );
};

export default reduxForm({
  form: PROPERTY_ATTORNEYS_FORM_ID,
})(PropertyAttorneysFormDeprecated);
