import React from "react";
import { reduxForm, InjectedFormProps } from "redux-form";
import { useSelector, TypedUseSelectorHook, RootStateOrAny } from "react-redux";
import { Box } from "@material-ui/core";
import { PrimaryButton } from "../../ui/Button";
import { Body } from "../../ui/Typography";
import { CONTACT_MODAL_FORM_ID } from "../../../constants/forms";
import {
  selectContactModalDeleteTranslations,
  selectLastSelectedContactData,
} from "../../../selectors/contacts";
const useTypedSelector: TypedUseSelectorHook<RootStateOrAny> = useSelector;

const RemoveContact = ({ translations, submitting }: RemoveContactProps) => {
  return (
    <Box mt={1}>
      <Body>{translations.paragraph1}</Body>
      <Box mt={2}>
        <PrimaryButton
          fullWidth
          destructive
          text={translations.button.submit}
          isDisabled={submitting}
        />
      </Box>
    </Box>
  );
};

const UnableToRemoveContact = ({
  translations,
}: UnableToRemoveContactProps) => {
  return (
    <Box mt={1}>
      <Body>{translations.paragraph1}</Body>
      <Box mt={1}>
        <Body>{translations.paragraph2}</Body>
      </Box>
    </Box>
  );
};

const ContactDeleteModal = ({
  handleSubmit,
  submitting,
}: InjectedFormProps & ContactModalProps) => {
  const modalTranslations = useTypedSelector(
    selectContactModalDeleteTranslations,
  );
  const contactData: ContactModalData = useTypedSelector(
    selectLastSelectedContactData,
  );

  return (
    <form onSubmit={handleSubmit}>
      <Box mt={1}>
        {contactData.hasRoles ? (
          <UnableToRemoveContact translations={modalTranslations} />
        ) : (
          <RemoveContact
            translations={modalTranslations}
            submitting={submitting}
          />
        )}
      </Box>
    </form>
  );
};

export default reduxForm<{}, ContactModalProps>({
  form: CONTACT_MODAL_FORM_ID,
})(ContactDeleteModal);
