import React from "react";

import { ButtonLink } from "../Button";
import { DASHBOARD_PATH, CHECKOUT_PATH } from "../../../constants/routes";

const NextSectionButton = ({
  isPlanComplete,
  nextOverviewPath,
  translations,
  hasPaid,
}) => {
  if (!isPlanComplete) {
    return (
      <ButtonLink
        className="qa-submit qa-overview-continue"
        to={nextOverviewPath}
        displayArrowRight
        text={translations.button.inProgress}
      />
    );
  }

  if (!hasPaid) {
    return (
      <ButtonLink
        className="qa-submit qa-dashboard-continue"
        to={CHECKOUT_PATH}
        displayArrowRight
        text={translations.button.completedUnpaid}
      />
    );
  }

  return (
    <ButtonLink
      className="qa-submit qa-dashboard-continue"
      to={DASHBOARD_PATH}
      displayArrowRight
      text={translations.button.completed}
    />
  );
};

export default NextSectionButton;
