import {
  ANALYTICS_SIGNUP_TYPE,
  ANALYTICS_CHECKOUT_PURCHASE_TYPE,
  ANALYTICS_VIEW_PAGE_TYPE,
} from "../../actions/analytics";
import { isTaboolaEnabled } from "../../utilities/analytics";

export const TaboolaAnalytics = () => {
  if (typeof window === "undefined") {
    return () => {};
  }

  if (typeof window._tfa === "undefined") {
    console.warn(
      "Taboola analytics are not being tracked. window._tfa is not found",
    );
    return () => {};
  }

  const taboolaId = window.env.TABOOLA_ID;

  if (isTaboolaEnabled() !== true) {
    console.warn("Taboola analytics are not being tracked");
    return () => {};
  }

  return function target(events) {
    events.forEach((event) => {
      switch (event.type) {
        case "pageview":
          window._tfa.push({
            notify: "event",
            name: "page_view",
            id: taboolaId,
          });
          break;
        case "signup":
          window._tfa.push({
            notify: "event",
            name: "account_signup",
            id: taboolaId,
          });
          break;
        case "purchase":
          window._tfa.push({
            notify: "event",
            name: "make_purchase",
            id: taboolaId,
          });
          break;
        default:
          break;
      }
    });
  };
};

export const TABOOLA_CONFIG = {
  [ANALYTICS_VIEW_PAGE_TYPE]: () => ({
    type: "pageview",
  }),
  [ANALYTICS_SIGNUP_TYPE]: () => ({
    type: "signup",
  }),
  [ANALYTICS_CHECKOUT_PURCHASE_TYPE]: () => ({
    type: "purchase",
  }),
};
