import React from "react";

import SvgIcon from "./SvgIcon";

const AssetListIcon = ({ isDesktop, disabled }) => {
  if (disabled) return <AssetListIconDisabled isDesktop={isDesktop} />;

  return (
    <SvgIcon fill="none" width={isDesktop ? 200 : 100} viewBox="0 0 120 98.93">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60 98.9254C93.1371 98.9254 120 81.0982 120 59.1072C120 37.1163 93.1371 19.2891 60 19.2891C26.8629 19.2891 0 37.1163 0 59.1072C0 81.0982 26.8629 98.9254 60 98.9254Z"
        fill="#F6F6F6"
      />
      <g>
        <path
          d="M61.1352 51.2188L92.1551 72.8227C93.3871 73.7027 93.3431 75.5507 92.0671 76.3427L74.5991 87.0787L38.4312 59.7987L61.1352 51.2188Z"
          fill="#2F455F"
        />
        <path
          d="M72.356 88.0008L74.6 87.0768L82.52 27.6328L80.276 28.5128L72.356 88.0008Z"
          fill="#AAD2F6"
        />
        <path
          d="M80.2758 28.512L82.5199 27.632L46.3959 0L44.1519 0.968L80.2758 28.512Z"
          fill="white"
        />
        <path
          d="M44.1519 0.96875L36.2759 60.4567L72.3559 88.0007L80.2759 28.5127L44.1519 0.96875Z"
          fill="#E2F6FF"
        />
        <path
          d="M43.8438 24.2891L44.2837 21.1211L46.5718 22.8811L46.1758 26.0491L43.8438 24.2891Z"
          fill="white"
        />
        <path
          d="M44.3715 21.4285L46.3955 22.9685L46.0435 25.7845L44.0195 24.2445L44.3715 21.4285ZM44.1515 20.8125L43.6675 24.3765L46.2635 26.3565L46.7475 22.7925L44.1515 20.8125Z"
          fill="#0064DC"
        />
        <path
          d="M42.8315 31.9464L43.2715 28.7344L45.5595 30.4944L45.1635 33.7064L42.8315 31.9464Z"
          fill="white"
        />
        <path
          d="M43.4038 29.0418L45.4278 30.5818L45.0758 33.3978L43.0518 31.8578L43.4038 29.0418ZM43.1398 28.4258L42.6558 31.9898L45.2518 33.9698L45.7358 30.4058L43.1398 28.4258Z"
          fill="#0064DC"
        />
        <path
          d="M41.8638 39.5157L42.2598 36.3477L44.5918 38.1077L44.1518 41.2757L41.8638 39.5157Z"
          fill="white"
        />
        <path
          d="M42.3915 36.6551L44.3715 38.1951L44.0195 41.0111L42.0395 39.4711L42.3915 36.6551ZM42.1275 36.0391L41.6875 39.6031L44.2835 41.5831L44.7675 38.0191L42.1275 36.0391Z"
          fill="#0064DC"
        />
        <path
          d="M40.8076 47.1261L41.2476 43.9141L43.5796 45.7181L43.1396 48.8861L40.8076 47.1261Z"
          fill="white"
        />
        <path
          d="M41.3793 44.2204L43.4033 45.7604L43.0513 48.5764L41.0273 47.0364L41.3793 44.2204ZM41.1153 43.6484L40.6313 47.2124L43.2713 49.2364L43.7553 45.6724L41.1153 43.6484Z"
          fill="#0064DC"
        />
        <path
          d="M39.7959 54.7393L40.1919 51.5273L42.5679 53.3313L42.1279 56.5433L39.7959 54.7393Z"
          fill="white"
        />
        <path
          d="M40.3237 51.8348L42.3917 53.4188L42.0397 56.2348L39.9717 54.6507L40.3237 51.8348ZM40.0597 51.2188L39.5757 54.7828L42.2157 56.8068L42.6997 53.2428L40.0597 51.2188Z"
          fill="#0064DC"
        />
        <path
          d="M49.4313 10.5607L48.7713 9.98869H48.7272L48.1112 9.46069H48.0672L47.5832 10.3407L47.4072 8.93269C47.4072 8.93269 47.4072 8.88869 47.3632 8.88869L46.8792 8.53669H46.8352L46.3512 9.41669L46.2192 7.92069C46.2192 7.92069 46.2192 7.87669 46.1752 7.87669L45.4712 7.30469H45.4272L45.8232 10.1207C45.8232 10.1207 45.8232 10.1647 45.8672 10.1647L46.4392 10.6047H46.4832L47.0112 9.76869L47.2312 11.1327C47.2312 11.1327 47.2312 11.1767 47.2752 11.1767L47.8472 11.6167H47.8912L48.7713 10.1647L48.5072 12.1007C48.5072 12.1007 48.5073 12.1447 48.5513 12.1447L49.2113 12.7167H49.2552L49.5192 10.5167C49.4752 10.6047 49.5193 10.5607 49.4313 10.5607Z"
          fill="#0064DC"
        />
        <path
          d="M49.4996 10.1698C49.6483 10.0873 49.635 9.77919 49.4699 9.48175C49.3048 9.18431 49.0503 9.01012 48.9016 9.09268C48.7529 9.17525 48.7662 9.48331 48.9313 9.78076C49.0965 10.0782 49.3509 10.2524 49.4996 10.1698Z"
          fill="#0064DC"
        />
        <path
          d="M50.5751 10.3845L49.9151 9.8125H49.8711L49.4751 13.0245C49.4751 13.0245 49.4751 13.0685 49.5191 13.0685L50.1791 13.6405H50.2231L50.6191 10.4285C50.6191 10.3845 50.5751 10.3845 50.5751 10.3845Z"
          fill="#0064DC"
        />
        <path
          d="M56.3834 17.7766C56.1634 17.6006 55.9434 17.6446 55.8994 17.8646C55.8554 18.1286 56.0314 18.4366 56.2514 18.6566C56.4714 18.8326 56.6914 18.7886 56.7354 18.5686C56.7794 18.3046 56.6474 17.9966 56.3834 17.7766Z"
          fill="#0064DC"
        />
        <path
          d="M51.5434 11.2204L50.8834 10.6484H50.8394L50.4434 13.8604C50.4434 13.8604 50.4434 13.9044 50.4874 13.9044L51.1474 14.4764H51.1914L51.5874 11.2644C51.5874 11.2204 51.5434 11.2204 51.5434 11.2204Z"
          fill="#0064DC"
        />
        <path
          d="M56.0751 14.8298L55.4151 14.2578H55.3711L54.9751 17.4698C54.9751 17.4698 54.9751 17.5138 55.0191 17.5138L55.6791 18.0858H55.7231L56.1191 14.8738C56.1191 14.9178 56.1191 14.8738 56.0751 14.8298Z"
          fill="#0064DC"
        />
        <path
          d="M54.9312 15.0073L54.2712 14.4353H54.2272L54.0952 15.5793C54.0512 15.8433 53.9192 15.8873 53.6992 15.7553C53.5232 15.6233 53.4352 15.3593 53.4792 15.1393L53.6552 13.9073C53.6552 13.9073 53.6552 13.8633 53.6112 13.8633L53.4792 13.7753L52.9072 13.2913L52.4232 12.9393L52.3792 12.8953V12.8073C52.3792 12.6753 52.5112 12.6753 52.6432 12.8073C52.7312 12.8513 52.7752 12.9393 52.8192 12.9833H52.8632L52.9512 12.3673V12.3233C52.9072 12.2793 52.7312 12.1033 52.5552 11.9273C52.0712 11.5753 51.7632 11.6193 51.6752 12.1913L51.3672 14.6553C51.3672 14.6553 51.3672 14.6993 51.4112 14.6993L52.0712 15.2713H52.1152L52.3352 13.7313L52.8192 14.0833L52.7312 14.7873C52.6432 15.3593 52.9512 16.0193 53.3912 16.3713C53.7872 16.6793 54.0072 16.5473 54.0072 16.5473V16.7233C54.0072 16.7233 54.0072 16.7673 54.0512 16.7673L54.7112 17.3393H54.7552L55.0192 15.1393C54.9752 15.0953 54.9752 15.0953 54.9312 15.0073Z"
          fill="#0064DC"
        />
        <path
          d="M76.1257 33.4307C76.8646 33.0095 76.8588 31.607 76.1127 30.298C75.3667 28.989 74.1628 28.2693 73.4239 28.6905C72.685 29.1117 72.6908 30.5142 73.4368 31.8232C74.1829 33.1322 75.3868 33.8519 76.1257 33.4307Z"
          fill="#FFDC1E"
        />
        <path
          d="M40.3677 45.4515C40.2357 45.3635 40.1917 45.1875 40.2797 44.9235C40.4557 44.5715 40.8957 44.2635 41.2917 44.2195C41.5557 44.1755 41.7317 44.3075 41.7317 44.4835C41.9077 45.2315 42.0837 45.9795 42.2597 46.7275C41.6437 46.2875 41.0277 45.8475 40.3677 45.4515Z"
          fill="#E55D4C"
        />
        <path
          d="M42.2597 46.7252C41.6437 46.2852 40.9837 45.8892 40.3677 45.4492C40.4557 45.4932 40.5877 45.5372 40.6757 45.4932L42.2597 46.7252Z"
          fill="#0064DC"
        />
        <path
          d="M41.7757 44.4375C41.9517 45.1855 42.1277 45.9335 42.3037 46.6815L40.7197 45.4495C41.1157 45.4055 41.5557 45.0975 41.7317 44.7455C41.7757 44.6575 41.8197 44.5695 41.7757 44.4375Z"
          fill="#0064DC"
        />
        <path
          d="M40.7196 45.4927C40.3236 45.5367 40.1476 45.2727 40.3236 44.8767C40.4996 44.5247 40.9396 44.2167 41.3356 44.1727C41.7316 44.1287 41.9076 44.3927 41.7316 44.7887C41.5556 45.1407 41.1156 45.4487 40.7196 45.4927Z"
          fill="#E55D4C"
        />
        <path
          d="M40.7196 45.4927C40.3236 45.5367 40.1476 45.2727 40.3236 44.8767C40.4996 44.5247 40.9396 44.2167 41.3356 44.1727C41.7316 44.1287 41.9076 44.3927 41.7316 44.7887C41.5556 45.1407 41.1156 45.4487 40.7196 45.4927Z"
          fill="#FFCA00"
        />
        <path
          d="M37.5072 43.5109C37.1552 43.2469 37.0672 42.8069 37.3312 42.2349C37.7272 41.3549 38.8712 40.5629 39.8392 40.4309C40.4992 40.3429 40.8952 40.6069 41.0272 41.0469C41.2912 42.1469 41.5552 43.2909 41.8192 44.3909C41.7752 44.2149 41.6432 44.1269 41.3792 44.1269C40.9832 44.1709 40.5432 44.4789 40.3672 44.8309C40.2352 45.0509 40.2792 45.2709 40.4552 45.3589C39.3992 44.7869 38.4312 44.1709 37.5072 43.5109Z"
          fill="#FFCA00"
        />
        <path
          d="M40.3673 45.4467C39.3993 44.8307 38.4753 44.1707 37.5073 43.5547C37.6833 43.6867 38.0353 43.7747 38.3433 43.6867L40.7193 45.4907C40.5433 45.5347 40.4553 45.4907 40.3673 45.4467Z"
          fill="#FFDC1E"
        />
        <path
          d="M40.9837 41.0898C41.2477 42.1898 41.5118 43.3338 41.7758 44.4338C41.7758 44.5218 41.7758 44.6098 41.7318 44.7418C41.5558 45.0938 41.1158 45.4018 40.7198 45.4458L38.3438 43.6418C39.3557 43.5538 40.4558 42.7618 40.8518 41.8378C40.9838 41.5738 41.0277 41.3098 40.9837 41.0898Z"
          fill="#FFDC1E"
        />
        <path
          d="M38.3435 43.6858C37.3315 43.7738 36.8915 43.1137 37.2875 42.2337C37.6835 41.3537 38.8275 40.5618 39.7955 40.4298C40.8075 40.3418 41.2475 41.0017 40.8515 41.8817C40.4555 42.8057 39.3115 43.5978 38.3435 43.6858Z"
          fill="#FFCA00"
        />
        <path
          d="M38.3435 43.6936C37.3315 43.7816 36.8915 43.1216 37.2875 42.2416C37.6835 41.3616 38.8275 40.5696 39.7955 40.4376C40.8075 40.3496 41.2475 41.0096 40.8515 41.8896C40.4555 42.8136 39.3115 43.6056 38.3435 43.6936Z"
          fill="#E55D4C"
        />
        <path
          d="M22.5479 22.13C22.3719 21.866 22.3279 21.47 22.5479 21.074C22.9439 20.194 24.0879 19.402 25.0559 19.27C25.5399 19.226 25.9359 19.358 26.1119 19.666L40.8519 40.874C40.6759 40.61 40.2799 40.478 39.7959 40.478C38.7839 40.566 37.6839 41.358 37.2879 42.282C37.0679 42.722 37.1119 43.118 37.2879 43.338C32.8879 36.958 26.9919 28.466 22.5479 22.13Z"
          fill="#E55D4C"
        />
        <path
          d="M37.3314 43.3408L22.5474 22.1328C22.7234 22.3968 23.1194 22.5288 23.6034 22.5288L38.3434 43.7368C37.8594 43.7368 37.4634 43.6048 37.3314 43.3408Z"
          fill="#0064DC"
        />
        <path
          d="M26.156 19.6641L40.896 40.8721C41.072 41.1361 41.116 41.5321 40.896 41.9281C40.5 42.8081 39.356 43.6001 38.388 43.7321L23.604 22.5241C24.616 22.4361 25.716 21.6441 26.112 20.7201C26.332 20.2361 26.332 19.8841 26.156 19.6641Z"
          fill="#0064DC"
        />
        <path
          d="M23.6042 22.4826C22.5922 22.5706 22.1522 21.9106 22.5482 21.0306C22.9442 20.1506 24.0882 19.3586 25.0562 19.2266C26.0682 19.1386 26.5082 19.7986 26.1122 20.6786C25.7162 21.6026 24.6162 22.3946 23.6042 22.4826Z"
          fill="#E55D4C"
        />
        <path
          d="M23.6037 22.4826C22.5917 22.5706 22.1517 21.9106 22.5477 21.0306C22.9437 20.1506 24.0877 19.3586 25.0557 19.2266C26.0677 19.1386 26.5077 19.7986 26.1117 20.6786C25.7157 21.6026 24.6157 22.3946 23.6037 22.4826Z"
          fill="#FFCA00"
        />
        <path
          d="M20.3916 19.005C20.2156 18.741 20.1716 18.345 20.3916 17.949C20.7876 17.069 21.9316 16.277 22.8996 16.145C23.3836 16.101 23.7796 16.233 23.9556 16.541L26.1556 19.665C25.9796 19.401 25.5836 19.269 25.0996 19.269C24.0876 19.357 22.9876 20.149 22.5916 21.073C22.3716 21.513 22.4156 21.909 22.5916 22.129C21.9316 21.205 21.0516 19.929 20.3916 19.005Z"
          fill="#FFCA00"
        />
        <path
          d="M22.5477 22.1318L20.3477 19.0078C20.5237 19.2718 20.9197 19.4038 21.4037 19.4038L23.6037 22.5278C23.1197 22.5278 22.7237 22.3958 22.5477 22.1318Z"
          fill="#ABE0F6"
        />
        <path
          d="M23.9558 16.5L26.1558 19.624C26.3318 19.888 26.3758 20.284 26.1558 20.68C25.7598 21.56 24.6158 22.352 23.6478 22.484L21.4478 19.36C22.4598 19.272 23.5598 18.48 23.9558 17.556C24.1318 17.116 24.1318 16.764 23.9558 16.5Z"
          fill="#ABE0F6"
        />
        <path
          d="M21.4035 19.3576C20.3915 19.4456 19.9515 18.7856 20.3475 17.9056C20.7435 17.0256 21.8875 16.2336 22.8555 16.1016C23.8675 16.0136 24.3075 16.6736 23.9115 17.5536C23.5595 18.4776 22.4155 19.2696 21.4035 19.3576Z"
          fill="#71ADEE"
        />
        <path
          d="M47.6715 23.4961L47.1875 27.0601L70.2875 44.7041L70.7715 41.1401L47.6715 23.4961Z"
          fill="#0064DC"
        />
        <path
          d="M46.6598 31.1094L46.1758 34.6734L65.9318 49.7654L66.4158 46.2014L46.6598 31.1094Z"
          fill="#0064DC"
        />
        <path
          d="M45.6475 38.7188L45.2075 42.2827L67.2075 59.1347L67.6915 55.5267L45.6475 38.7188Z"
          fill="#0064DC"
        />
        <path
          d="M44.6358 46.332L44.1958 49.896L59.3318 61.512L59.8158 57.948L44.6358 46.332Z"
          fill="#0064DC"
        />
        <path
          d="M43.6676 53.9453L43.1836 57.5093L63.8196 73.3053L64.3036 69.7413L43.6676 53.9453Z"
          fill="#0064DC"
        />
        <path
          d="M42.9636 39.512H42.9196C42.8756 39.512 42.8316 39.468 42.7876 39.424L42.4356 38.808C42.3916 38.72 42.3916 38.588 42.4796 38.5C42.5676 38.456 42.6556 38.456 42.7436 38.588L43.0076 38.984L43.7556 38.148C43.8436 38.06 43.9316 38.06 44.0196 38.148C44.1076 38.236 44.1076 38.368 44.0196 38.456L43.1396 39.468C43.0516 39.468 43.0076 39.512 42.9636 39.512Z"
          fill="#0064DC"
        />
        <path
          d="M43.9754 32.0784H43.9314C43.8874 32.0784 43.8433 32.0344 43.7993 31.9904L43.4473 31.3744C43.3593 31.2424 43.3593 31.1104 43.4473 31.0664C43.5353 31.0224 43.6234 31.0224 43.7114 31.1544L43.9754 31.5504L44.7234 30.7144C44.8114 30.6264 44.8994 30.6264 44.9874 30.7144C45.0754 30.8024 45.0754 30.9344 44.9874 31.0224L44.1073 32.0344C44.0633 32.0344 44.0194 32.0784 43.9754 32.0784Z"
          fill="#0064DC"
        />
        <path
          d="M44.9871 24.4612H44.9431C44.8991 24.4612 44.8551 24.4172 44.8111 24.3732L44.4591 23.7572C44.4151 23.6692 44.4151 23.5372 44.5031 23.4492C44.5911 23.4052 44.6791 23.4052 44.7671 23.5372L45.0311 23.9333L45.7791 23.0972C45.8671 23.0093 45.9551 23.0093 46.0431 23.0972C46.1311 23.1852 46.1311 23.3173 46.0431 23.4053L45.1631 24.4172C45.0311 24.4172 45.0311 24.4172 44.9871 24.4612Z"
          fill="#0064DC"
        />
      </g>
      <defs>
        <clipPath id="clip0_8545_74625">
          <rect width="88" height="88" fill="white" transform="translate(13)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

const AssetListIconDisabled = ({ isDesktop }) => {
  return (
    <SvgIcon fill="none" width={isDesktop ? 200 : 100} viewBox="0 0 120 98.9">
      <defs>
        <clipPath id="a" transform="translate(0 0)">
          <rect x="13" width="88" height="88" fill="none" />
        </clipPath>
      </defs>
      <path
        d="M60,98.9c33.1,0,60-17.8,60-39.8S93.1,19.3,60,19.3,0,37.1,0,59.1,26.9,98.9,60,98.9Z"
        transform="translate(0 0)"
        fill="#cacdd4"
        fillRule="evenodd"
      />
      <g clipPath="url(#a)">
        <g>
          <path
            d="M61.1,51.2l31,21.6a2.1,2.1,0,0,1-.1,3.5L74.6,87.1,38.4,59.8Z"
            transform="translate(0 0)"
            fill="#4a4a4a"
          />
          <path
            d="M72.4,88l2.2-.9,7.9-59.4-2.2.9Z"
            transform="translate(0 0)"
            fill="#b2b2b2"
          />
          <path
            d="M80.3,28.5l2.2-.9L46.4,0,44.2,1Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            d="M44.2,1,36.3,60.5,72.4,88l7.9-59.5Z"
            transform="translate(0 0)"
            fill="#ecedef"
          />
          <path
            d="M43.8,24.3l.4-3.2,2.3,1.8L46.2,26Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            d="M44.4,21.4l2,1.5L46,25.8l-2-1.5Zm-.2-.6-.5,3.6,2.6,2,.5-3.6Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M42.8,31.9l.4-3.2,2.3,1.8-.4,3.2Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            d="M43.4,29l2,1.5L45,33.3l-2-1.5Zm-.3-.6L42.7,32l2.6,2,.5-3.6Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M41.9,39.5l.4-3.2,2.3,1.8-.4,3.2Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            d="M42.4,36.7l2,1.5L44,41l-2-1.5Zm-.3-.7-.4,3.6,2.6,2,.5-3.6Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M40.75,46.88l.4-3.2,2.3,1.8-.4,3.2Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            d="M41.4,44.2l2,1.5L43,48.5,41,47Zm-.3-.6-.5,3.6,2.6,2,.5-3.6Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M39.8,54.7l.4-3.2,2.4,1.8-.4,3.2Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            d="M40.3,51.8l2.1,1.6L42,56.2l-2-1.5Zm-.2-.6-.5,3.6,2.6,2,.5-3.6Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M49.4,10.6l-.6-.6h0l-.6-.5h0l-.5.9L47.5,9h0L47,8.6h0l-.5.9L46.4,8h0l-.7-.6h0l.4,2.8h0l.6.4h0l.3-.8.2,1.4h0l.6.4h0l.9-1.5L48.4,12h0l.7.6h0l.3-2Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M49.5,10.2a.82.82,0,0,0,0-.7c-.2-.3-.4-.5-.6-.4a.82.82,0,0,0,0,.7C49.1,10.1,49.4,10.3,49.5,10.2Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M50.6,10.4l-.7-.6h0L49.5,13h0l.7.6h0l.4-3.2Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M56.4,17.8c-.2-.2-.4-.1-.5.1a.91.91,0,0,0,.4.8c.2.2.4.1.5-.1A1.23,1.23,0,0,0,56.4,17.8Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M51.5,11.2l-.7-.6h0l-.4,3.2h0l.7.6h0l.4-3.2Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M56.1,14.8l-.7-.6h0L55,17.5h0l.7.6h0l.4-3.3Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M54.9,15l-.7-.6h0l-.1,1.1c0,.3-.2.3-.4.2a.48.48,0,0,1-.2-.6l.2-1.2h0l-.1-.1-.6-.5-.5-.4h0v-.1q0-.15.3,0c.1,0,.1.1.2.2h0l.1-.6h0l-.4-.4q-.75-.6-.9.3l-.3,2.5h0l.7.6h0l.2-1.5.5.4-.1.7a1.73,1.73,0,0,0,.7,1.6c.4.3.6.2.6.2V17h0l.7.6h0l.3-2.2C55,15.1,55,15.1,54.9,15Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M76.1,33.4c.7-.4.7-1.8,0-3.1s-1.9-2-2.7-1.6-.7,1.8,0,3.1S75.4,33.9,76.1,33.4Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M40.32,45.35a.48.48,0,0,1-.12-.56A1.37,1.37,0,0,1,41.33,44c.34,0,.45.11.45.34a18.48,18.48,0,0,1,.57,2.48A12.16,12.16,0,0,0,40.32,45.35Z"
            transform="translate(0 0)"
            fill="#cacdd4"
          />
          <path
            d="M40.7,45.5c-.4,0-.6-.2-.4-.6a1.2,1.2,0,0,1,1-.7c.4,0,.6.2.4.6A1.53,1.53,0,0,1,40.7,45.5Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M40.7,45.5c-.4,0-.6-.2-.4-.6a1.2,1.2,0,0,1,1-.7c.4,0,.6.2.4.6A1.53,1.53,0,0,1,40.7,45.5Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M41.8,44.3c-.28-1.11-.5-2.1-.8-3.2a2.39,2.39,0,0,0-.4-.63L26.2,19.7c.05.11,0-.08-.26-.26l-.05,0,0,0h0s-.06,0-.09,0l-.11,0A1.36,1.36,0,0,0,25,19.2,3.35,3.35,0,0,0,22.5,21a1.08,1.08,0,0,0,0,1.1c1,1.44,2.11,3,3.23,4.61l1.89,2.71c3.32,4.76,6.8,9.78,9.58,13.86l0,.07,0,0,0,0a1.09,1.09,0,0,0,.17.13s0,0,0,0l.71.45,0,0c.71.46,1.37.93,2.12,1.38C40.8,45.64,42,44.94,41.8,44.3Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M26.2,19.7,24,16.5a1.25,1.25,0,0,0-1.1-.4,3.35,3.35,0,0,0-2.5,1.8,1.08,1.08,0,0,0,0,1.1l.17.23A.75.75,0,0,1,20.3,19l2.2,3.1a1.24,1.24,0,0,0,1.09.4h0a3.33,3.33,0,0,0,2.49-1.8,1.19,1.19,0,0,0,.15-.61A1.34,1.34,0,0,0,26.2,19.7Z"
            transform="translate(0 0)"
            fill="#ecedef"
          />
          <path
            d="M21.4,19.4c-1,.1-1.5-.6-1.1-1.5a3.35,3.35,0,0,1,2.5-1.8c1-.1,1.5.6,1.1,1.5A3.19,3.19,0,0,1,21.4,19.4Z"
            transform="translate(0 0)"
            fill="#cacdd4"
          />
          <path
            d="M47.7,23.5l-.5,3.6L70.3,44.7l.5-3.6Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M46.7,31.1l-.5,3.6L66,49.8l.5-3.6Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M45.6,38.7l-.4,3.6,22,16.9.5-3.6Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M44.6,46.3l-.4,3.6L59.3,61.5l.5-3.6Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M43.7,53.9l-.5,3.6L63.8,73.3l.5-3.6Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M43,39.5h0c-.1,0-.1,0-.2-.1l-.4-.6v-.3a.37.37,0,0,1,.3.1l.3.4.7-.8a.21.21,0,0,1,.3.3l-.9,1Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M44,32.1h0c-.1,0-.1,0-.2-.1l-.4-.6c-.1-.1-.1-.3,0-.3a.37.37,0,0,1,.3.1l.3.4.7-.8a.21.21,0,0,1,.3.3l-.9,1C44.1,32,44,32.1,44,32.1Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
          <path
            d="M45,24.5h0c-.1,0-.1,0-.2-.1l-.4-.6v-.3a.37.37,0,0,1,.3.1l.3.4.7-.8a.21.21,0,0,1,.3.3l-.9,1C45,24.4,45,24.4,45,24.5Z"
            transform="translate(0 0)"
            fill="#70707a"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default AssetListIcon;
