import { combineReducers } from "redux";
import { UPDATE_STATUS_TYPE } from "../actions/status";
import { TOGGLE_PARTNER_BANNER_TYPE } from "../actions/banners";

const shouldPartnerDiscountBannerBeDisplayed = (
  state = true,
  { type, payload },
) => {
  switch (type) {
    case TOGGLE_PARTNER_BANNER_TYPE:
      return payload.visible;
    default:
      return state;
  }
};

const partnerDiscount = (state = 0, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return payload.partnerDiscount;
    default:
      return state;
  }
};

const hasPartnerDiscount = (state = false, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return payload.hasPartnerDiscount;
    default:
      return state;
  }
};

export default combineReducers({
  partnerDiscount,
  hasPartnerDiscount,
  shouldPartnerDiscountBannerBeDisplayed,
});
