import React from "react";

import { useDispatch } from "react-redux";
import { reduxForm } from "redux-form";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { Form } from "../../ui/Form";
import { SecondaryButton, SmallTextButton } from "../../ui/Button";
import { ErrorMessage } from "../../ui/Error";
import SelectInput from "../../ui/inputs/DropdownSelect";
import BinaryRadioField from "../../ui/inputs/BinaryRadioField";

import {
  INHERITANCE_FORM_ID,
  AGE_MILESTONE_FIELD_ID,
} from "../../../constants/forms";
import {
  useValidateInheritanceMemo,
  useValidateSpecificAgeMemo,
  useValidateSpecificPercentageMemo,
} from "../../ui/inputs/validations";
import { validateInheritanceMilestones } from "../utils/inheritance-form-validations";
import NumberInputField from "../../ui/inputs/NumberInputField";
import { TotalPercentageBar } from "../../ui/TotalPercentageBar";
import H2WithUnderlineLink from "../../ui/H2WithUnderlineLink";
import { displayTooltipModal } from "../../../actions/tooltip-modal";

const printOrdinalMilestone = (translations, milestone) => {
  switch (milestone) {
    case 2:
      return translations.label.secondMilestone;
    case 3:
      return translations.label.thirdMilestone;
    default:
      return translations.label.firstMilestone;
  }
};

const Milestone = ({
  translations,
  handleRemove,
  milestoneIndex = 1,
  displayRemoveBtn = false,
  ageOfMajority,
}) => {
  const ordinal = printOrdinalMilestone(translations, milestoneIndex);
  return (
    <Box mb={displayRemoveBtn ? 0.5 : 2}>
      <Box display="flex">
        <Box mr={1} minWidth={0} width="100%">
          <NumberInputField
            name={`age${milestoneIndex}`}
            label={`${ordinal} ${translations.label.age}`}
            placeholder={translations.label.agePlaceholder}
            validate={useValidateSpecificAgeMemo(translations)}
            min={ageOfMajority}
            max="40"
            noMargin
          />
        </Box>
        <Box minWidth={0} width="100%">
          <NumberInputField
            name={`percentage${milestoneIndex}`}
            label={translations.label.percentage}
            placeholder={translations.label.percentagePlaceholder}
            validate={useValidateSpecificPercentageMemo(translations)}
            step="any"
            min="0"
            max="100"
            percentage="true"
            noMargin
          />
        </Box>
      </Box>

      {displayRemoveBtn && (
        <Box display="flex" flexDirection="row-reverse" pt={1}>
          <SmallTextButton
            text={translations.button.removeLabel}
            className="qa-remove"
            onClick={() => handleRemove(milestoneIndex)}
          />
        </Box>
      )}
    </Box>
  );
};

const InheritanceMilestones = ({
  error,
  translations,
  handleRemove,
  handleAddMore,
  total = 0,
  displayMilestone2 = false,
  displayMilestone3 = false,
  ageOfMajority,
}) => {
  return (
    <>
      <Milestone ageOfMajority={ageOfMajority} translations={translations} />

      {displayMilestone2 && (
        <Milestone
          translations={translations}
          milestoneIndex={2}
          displayRemoveBtn
          handleRemove={handleRemove}
          ageOfMajority={ageOfMajority}
        />
      )}

      {displayMilestone3 && (
        <Milestone
          translations={translations}
          milestoneIndex={3}
          displayRemoveBtn
          handleRemove={handleRemove}
          ageOfMajority={ageOfMajority}
        />
      )}

      <Box mb={1}>
        {error &&
          error.map((e, i) => (
            <ErrorMessage key={`error-${i + 1}`} error={e} />
          ))}
      </Box>

      {displayMilestone2 && (
        <Box mb={2}>
          <TotalPercentageBar translations={translations} total={total} />
        </Box>
      )}

      {!displayMilestone3 && (
        <Box mb={2}>
          <SecondaryButton
            type="button"
            fullWidth
            displayPlusIcon
            text={translations.button.addAge}
            onClick={handleAddMore}
          />
        </Box>
      )}
    </>
  );
};

const InheritanceForm = ({
  error,
  handleSubmit,
  handleRemove,
  handleAddMore,
  backLink,
  isInheritanceSpecificAges,
  totalPercentage,
  displayMilestone2,
  displayMilestone3,
  translations,
  isLoading,
  ageOfMajority,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerWithLink = (
    <H2WithUnderlineLink
      text={translations.pageTitle}
      underlineText={translations.pageTitleUnderlineLink}
      onClick={() =>
        dispatch(displayTooltipModal(translations.pageTitleModalKey))
      }
    />
  );

  return (
    <Form
      disableNativeHTMLValidation
      onSubmit={handleSubmit}
      backLink={backLink}
      isLoading={isLoading}
      translations={translations}
      reverseWrap={isTabletDown}
      customHeaderComponent={headerWithLink}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" pt={1}>
        <BinaryRadioField
          name={AGE_MILESTONE_FIELD_ID}
          component={SelectInput}
          label={translations.label.inheritance}
          optionList={translations.baseOptionList}
          validate={useValidateInheritanceMemo(translations)}
        />
        {isInheritanceSpecificAges && (
          <InheritanceMilestones
            error={error}
            handleRemove={handleRemove}
            handleAddMore={handleAddMore}
            translations={translations}
            displayMilestone2={displayMilestone2}
            displayMilestone3={displayMilestone3}
            total={totalPercentage}
            ageOfMajority={ageOfMajority}
          />
        )}
      </Box>
    </Form>
  );
};

export default reduxForm({
  form: INHERITANCE_FORM_ID,
  validate: validateInheritanceMilestones,
})(InheritanceForm);
