import React from "react";

import SvgIcon from "./SvgIcon";

const GoogleRating = ({ isFrench, width = 162, height = 81, className }) => {
  return isFrench ? (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 162 81"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M90.63 10.1877C90.63 9.36828 90.5653 8.77034 90.4254 8.15027H81.3292V11.8486H86.6685C86.5609 12.7677 85.9796 14.1518 84.6878 15.0819L84.6697 15.2057L87.5457 17.4975L87.745 17.518C89.575 15.7795 90.63 13.2216 90.63 10.1877Z"
          fill="#4285F4"
        />
        <path
          d="M81.3292 19.9322C83.945 19.9322 86.141 19.0463 87.745 17.5183L84.6878 15.0822C83.8697 15.669 82.7716 16.0787 81.3292 16.0787C78.7672 16.0787 76.5927 14.3403 75.8176 11.9375L75.704 11.9474L72.7134 14.3281L72.6743 14.4399C74.2675 17.6954 77.54 19.9322 81.3292 19.9322Z"
          fill="#34A853"
        />
        <path
          d="M75.8177 11.9374C75.6131 11.3173 75.4948 10.6529 75.4948 9.96639C75.4948 9.27982 75.6131 8.61547 75.8069 7.99539L75.8015 7.86333L72.7735 5.4444L72.6744 5.49287C72.0178 6.84379 71.641 8.36081 71.641 9.96639C71.641 11.572 72.0178 13.0889 72.6744 14.4398L75.8177 11.9374Z"
          fill="#FBBC05"
        />
        <path
          d="M81.3292 3.85336C83.1484 3.85336 84.3756 4.66168 85.0753 5.33717L87.8095 2.59107C86.1303 0.985496 83.945 0 81.3292 0C77.54 0 74.2675 2.23672 72.6743 5.49214L75.8068 7.99466C76.5927 5.59183 78.7672 3.85336 81.3292 3.85336Z"
          fill="#EB4335"
        />
      </g>
      <path
        d="M0 75H7.92V78H11.52V75H13.44V71.64H11.52V61.2H7.32L0 71.16V75ZM3.72 71.64L7.92 65.88V71.64H3.72Z"
        fill="#4A4A4A"
      />
      <path
        d="M15.0363 76.32C15.0363 77.4 15.8762 78.24 16.9562 78.24C18.0362 78.24 18.8762 77.4 18.8762 76.32C18.8762 75.24 18.0362 74.4 16.9562 74.4C15.8762 74.4 15.0363 75.24 15.0363 76.32Z"
        fill="#4A4A4A"
      />
      <path
        d="M23.9619 78H27.6819L32.3619 70.8C32.3619 70.8 33.8019 69 33.8019 66.72C33.8019 63.48 31.1619 60.84 27.4419 60.84C23.7219 60.84 21.0819 63.48 21.0819 66.72C21.0819 69.96 23.7219 72.6 26.7219 72.6C27.2019 72.6 27.5619 72.48 27.5619 72.48L23.9619 78ZM24.8019 66.72C24.8019 65.16 25.8819 64.08 27.4419 64.08C29.0019 64.08 30.0819 65.16 30.0819 66.72C30.0819 68.28 29.0019 69.36 27.4419 69.36C25.8819 69.36 24.8019 68.28 24.8019 66.72Z"
        fill="#4A4A4A"
      />
      <path
        d="M52.7216 59.1327C53.0686 58.3268 54.2114 58.3268 54.5585 59.1327L56.6497 63.9889C56.7945 64.325 57.1114 64.5553 57.4758 64.5891L62.7405 65.0773C63.6143 65.1584 63.9674 66.2452 63.3082 66.8244L59.3359 70.3139C59.061 70.5555 58.9399 70.928 59.0204 71.285L60.1829 76.4429C60.3758 77.299 59.4513 77.9707 58.6968 77.5226L54.1506 74.8232C53.8359 74.6363 53.4442 74.6363 53.1295 74.8232L48.5832 77.5226C47.8287 77.9707 46.9042 77.299 47.0971 76.4429L48.2596 71.285C48.3401 70.928 48.2191 70.5555 47.9441 70.3139L43.9719 66.8244C43.3126 66.2452 43.6657 65.1584 44.5395 65.0773L49.8042 64.5891C50.1687 64.5553 50.4855 64.325 50.6303 63.9889L52.7216 59.1327Z"
        fill="#E39F3A"
      />
      <path
        d="M76.7216 59.1327C77.0686 58.3268 78.2114 58.3268 78.5585 59.1327L80.6497 63.9889C80.7945 64.325 81.1114 64.5553 81.4758 64.5891L86.7405 65.0773C87.6143 65.1584 87.9674 66.2452 87.3082 66.8244L83.3359 70.3139C83.061 70.5555 82.9399 70.928 83.0204 71.285L84.1829 76.4429C84.3758 77.299 83.4513 77.9707 82.6968 77.5226L78.1506 74.8232C77.8359 74.6363 77.4442 74.6363 77.1295 74.8232L72.5832 77.5226C71.8287 77.9707 70.9042 77.299 71.0971 76.4429L72.2596 71.285C72.3401 70.928 72.2191 70.5555 71.9441 70.3139L67.9719 66.8244C67.3126 66.2452 67.6657 65.1584 68.5395 65.0773L73.8042 64.5891C74.1687 64.5553 74.4855 64.325 74.6303 63.9889L76.7216 59.1327Z"
        fill="#E39F3A"
      />
      <path
        d="M100.722 59.1327C101.069 58.3268 102.211 58.3268 102.558 59.1327L104.65 63.9889C104.795 64.325 105.111 64.5553 105.476 64.5891L110.741 65.0773C111.614 65.1584 111.967 66.2452 111.308 66.8244L107.336 70.3139C107.061 70.5555 106.94 70.928 107.02 71.285L108.183 76.4429C108.376 77.299 107.451 77.9707 106.697 77.5226L102.151 74.8232C101.836 74.6363 101.444 74.6363 101.129 74.8232L96.5832 77.5226C95.8287 77.9707 94.9042 77.299 95.0971 76.4429L96.2596 71.285C96.3401 70.928 96.2191 70.5555 95.9441 70.3139L91.9719 66.8244C91.3126 66.2452 91.6657 65.1584 92.5395 65.0773L97.8042 64.5891C98.1687 64.5553 98.4855 64.325 98.6303 63.9889L100.722 59.1327Z"
        fill="#E39F3A"
      />
      <path
        d="M124.722 59.1327C125.069 58.3268 126.211 58.3268 126.558 59.1327L128.65 63.9889C128.795 64.325 129.111 64.5553 129.476 64.5891L134.741 65.0773C135.614 65.1584 135.967 66.2452 135.308 66.8244L131.336 70.3139C131.061 70.5555 130.94 70.928 131.02 71.285L132.183 76.4429C132.376 77.299 131.451 77.9707 130.697 77.5226L126.151 74.8232C125.836 74.6363 125.444 74.6363 125.129 74.8232L120.583 77.5226C119.829 77.9707 118.904 77.299 119.097 76.4429L120.26 71.285C120.34 70.928 120.219 70.5555 119.944 70.3139L115.972 66.8244C115.313 66.2452 115.666 65.1584 116.54 65.0773L121.804 64.5891C122.169 64.5553 122.486 64.325 122.63 63.9889L124.722 59.1327Z"
        fill="#E39F3A"
      />
      <path
        d="M148.722 59.1327C149.069 58.3268 150.211 58.3268 150.558 59.1327L152.65 63.9889C152.795 64.325 153.111 64.5553 153.476 64.5891L158.741 65.0773C159.614 65.1584 159.967 66.2452 159.308 66.8244L155.336 70.3139C155.061 70.5555 154.94 70.928 155.02 71.285L156.183 76.4429C156.376 77.299 155.451 77.9707 154.697 77.5226L150.151 74.8232C149.836 74.6363 149.444 74.6363 149.129 74.8232L144.583 77.5226C143.829 77.9707 142.904 77.299 143.097 76.4429L144.26 71.285C144.34 70.928 144.219 70.5555 143.944 70.3139L139.972 66.8244C139.313 66.2452 139.666 65.1584 140.54 65.0773L145.804 64.5891C146.169 64.5553 146.486 64.325 146.63 63.9889L148.722 59.1327Z"
        fill="#E39F3A"
      />
      <path
        d="M9.24426 34.55L10.0543 35L12.1243 31.76L10.8643 31.04L9.24426 34.55Z"
        fill="#4A4A4A"
      />
      <path
        d="M20.4936 41.57C20.4936 41.57 19.5216 43.01 17.7036 43.01C16.0836 43.01 14.7336 41.66 14.6436 40.13L21.6636 40.148C21.6636 40.148 21.7536 39.86 21.7536 39.5C21.7536 36.98 19.9536 35.18 17.6136 35.18C15.3636 35.18 13.3836 37.16 13.3836 39.68C13.3836 42.2 15.3636 44.18 17.7036 44.18C20.0436 44.18 21.3036 42.38 21.3036 42.38L20.4936 41.57ZM14.6436 39.05C14.8236 37.61 16.0836 36.35 17.6136 36.35C19.1436 36.35 20.3136 37.52 20.4936 39.05H14.6436ZM17.1636 33.83H18.1536L19.8636 31.4H18.5136L17.1636 33.83Z"
        fill="#4A4A4A"
      />
      <path
        d="M26.3379 44H27.5979L31.1079 35.36H29.7579L26.9679 42.29L24.1779 35.36H22.8279L26.3379 44Z"
        fill="#4A4A4A"
      />
      <path
        d="M38.0422 44H39.3022V38.51C39.3022 36.62 37.8622 35.18 35.8822 35.18C33.8122 35.18 32.6422 36.8 32.6422 36.8L33.4522 37.61C33.4522 37.61 34.3522 36.35 35.8822 36.35C37.1422 36.35 38.0422 37.25 38.0422 38.51L34.7122 39.05C33.0922 39.32 32.1922 40.22 32.1922 41.66C32.1922 42.92 33.4522 44.18 35.2522 44.18C37.1422 44.18 38.0422 42.83 38.0422 42.83V44ZM38.0422 40.13C38.0422 41.84 36.8722 43.01 35.2522 43.01C34.0822 43.01 33.4522 42.38 33.4522 41.66C33.4522 40.85 33.9922 40.328 35.0722 40.13L38.0422 39.59V40.13Z"
        fill="#4A4A4A"
      />
      <path d="M42.2637 44H43.5237V31.4H42.2637V44Z" fill="#4A4A4A" />
      <path
        d="M46.4979 40.4C46.4979 42.65 48.0279 44.18 50.0079 44.18C51.8979 44.18 52.7979 42.83 52.7979 42.83V44H54.0579V35.36H52.7979V40.13C52.7979 41.84 51.6279 43.01 50.1879 43.01C48.8379 43.01 47.7579 41.93 47.7579 40.4V35.36H46.4979V40.4Z"
        fill="#4A4A4A"
      />
      <path
        d="M62.2472 44H63.5072V38.51C63.5072 36.62 62.0672 35.18 60.0872 35.18C58.0172 35.18 56.8472 36.8 56.8472 36.8L57.6572 37.61C57.6572 37.61 58.5572 36.35 60.0872 36.35C61.3472 36.35 62.2472 37.25 62.2472 38.51L58.9172 39.05C57.2972 39.32 56.3972 40.22 56.3972 41.66C56.3972 42.92 57.6572 44.18 59.4572 44.18C61.3472 44.18 62.2472 42.83 62.2472 42.83V44ZM62.2472 40.13C62.2472 41.84 61.0772 43.01 59.4572 43.01C58.2872 43.01 57.6572 42.38 57.6572 41.66C57.6572 40.85 58.1972 40.328 59.2772 40.13L62.2472 39.59V40.13Z"
        fill="#4A4A4A"
      />
      <path
        d="M66.923 41.66C66.923 43.28 67.823 44.18 69.299 44.18C69.983 44.18 70.523 43.91 70.523 43.91V42.74C70.523 42.74 69.983 43.01 69.353 43.01C68.633 43.01 68.183 42.56 68.183 41.66V36.53H70.073V35.36H68.183V32.48H67.013V34.46C67.013 35.09 66.743 35.36 66.293 35.36H65.303V36.53H66.923V41.66Z"
        fill="#4A4A4A"
      />
      <path
        d="M72.5093 32.48C72.5093 32.93 72.8693 33.29 73.3193 33.29C73.7693 33.29 74.1293 32.93 74.1293 32.48C74.1293 32.03 73.7693 31.67 73.3193 31.67C72.8693 31.67 72.5093 32.03 72.5093 32.48ZM72.6893 44H73.9493V35.36H72.6893V44Z"
        fill="#4A4A4A"
      />
      <path
        d="M80.6178 44.18C83.0478 44.18 85.0278 42.2 85.0278 39.68C85.0278 37.16 83.0478 35.18 80.6178 35.18C78.1878 35.18 76.2078 37.16 76.2078 39.68C76.2078 42.2 78.1878 44.18 80.6178 44.18ZM80.6178 43.01C78.9078 43.01 77.4678 41.552 77.4678 39.68C77.4678 37.79 78.9078 36.35 80.6178 36.35C82.3278 36.35 83.7678 37.79 83.7678 39.68C83.7678 41.57 82.3278 43.01 80.6178 43.01Z"
        fill="#4A4A4A"
      />
      <path
        d="M87.2792 44H88.5392V39.23C88.5392 37.52 89.7092 36.35 91.2392 36.35C92.5892 36.35 93.5792 37.34 93.5792 38.87V44H94.8392V38.87C94.8392 36.62 93.3992 35.18 91.3292 35.18C89.4392 35.18 88.5392 36.62 88.5392 36.62V35.36H87.2792V44Z"
        fill="#4A4A4A"
      />
      <path
        d="M108.7 38.78H111.58V41.21C111.58 41.21 110.32 42.92 107.62 42.92C104.74 42.92 102.49 40.67 102.49 37.7C102.49 34.73 104.74 32.48 107.62 32.48C110.5 32.48 111.85 34.64 111.85 34.64L112.75 33.83C112.75 33.83 111.04 31.22 107.62 31.22C104.02 31.22 101.14 34.1 101.14 37.7C101.14 41.3 104.02 44.18 107.62 44.18C111.04 44.18 112.93 41.57 112.93 41.57V37.52H108.7V38.78Z"
        fill="#4A4A4A"
      />
      <path
        d="M119.588 44.18C122.018 44.18 123.998 42.2 123.998 39.68C123.998 37.16 122.018 35.18 119.588 35.18C117.158 35.18 115.178 37.16 115.178 39.68C115.178 42.2 117.158 44.18 119.588 44.18ZM119.588 43.01C117.878 43.01 116.438 41.552 116.438 39.68C116.438 37.79 117.878 36.35 119.588 36.35C121.298 36.35 122.738 37.79 122.738 39.68C122.738 41.57 121.298 43.01 119.588 43.01Z"
        fill="#4A4A4A"
      />
      <path
        d="M130.03 44.18C132.46 44.18 134.44 42.2 134.44 39.68C134.44 37.16 132.46 35.18 130.03 35.18C127.6 35.18 125.62 37.16 125.62 39.68C125.62 42.2 127.6 44.18 130.03 44.18ZM130.03 43.01C128.32 43.01 126.88 41.552 126.88 39.68C126.88 37.79 128.32 36.35 130.03 36.35C131.74 36.35 133.18 37.79 133.18 39.68C133.18 41.57 131.74 43.01 130.03 43.01Z"
        fill="#4A4A4A"
      />
      <path
        d="M136.421 45.98C136.421 45.98 137.591 47.78 140.021 47.78C142.361 47.78 144.251 45.89 144.251 43.46V35.36H142.991V36.53C142.991 36.53 142.181 35.18 140.111 35.18C137.861 35.18 136.061 37.07 136.061 39.5C136.061 41.93 137.861 43.82 140.111 43.82C142.181 43.82 142.991 42.47 142.991 42.47V43.46C142.991 45.26 141.641 46.61 140.021 46.61C138.221 46.61 137.231 45.17 137.231 45.17L136.421 45.98ZM140.111 42.65C138.581 42.65 137.321 41.3 137.321 39.5C137.321 37.7 138.581 36.35 140.111 36.35C141.731 36.35 142.991 37.7 142.991 39.5C142.991 41.3 141.731 42.65 140.111 42.65Z"
        fill="#4A4A4A"
      />
      <path d="M147.223 44H148.483V31.4H147.223V44Z" fill="#4A4A4A" />
      <path
        d="M157.937 41.57C157.937 41.57 156.965 43.01 155.147 43.01C153.527 43.01 152.177 41.66 152.087 40.13L159.107 40.148C159.107 40.148 159.197 39.86 159.197 39.5C159.197 36.98 157.397 35.18 155.057 35.18C152.807 35.18 150.827 37.16 150.827 39.68C150.827 42.2 152.807 44.18 155.147 44.18C157.487 44.18 158.747 42.38 158.747 42.38L157.937 41.57ZM152.087 39.05C152.267 37.61 153.527 36.35 155.057 36.35C156.587 36.35 157.757 37.52 157.937 39.05H152.087Z"
        fill="#4A4A4A"
      />
      <path d="M2 44.1H9.83V42.84H3.35V31.5H2V44.1Z" fill="#4A4A4A" />
      <defs>
        <clipPath id="clip0">
          <rect
            width="19"
            height="20"
            fill="white"
            transform="translate(71.64)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  ) : (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 162 81"
      fill="none"
      className={className}
    >
      <path
        d="M90.9899 10.1877C90.9899 9.3683 90.9253 8.77036 90.7854 8.15028H81.6892V11.8486H87.0285C86.9209 12.7677 86.3396 14.1518 85.0478 15.0819L85.0297 15.2057L87.9057 17.4975L88.105 17.518C89.935 15.7795 90.9899 13.2217 90.9899 10.1877Z"
        fill="#4285F4"
      />
      <path
        d="M81.6892 19.9321C84.305 19.9321 86.5009 19.0463 88.1049 17.5182L85.0477 15.0821C84.2296 15.669 83.1316 16.0787 81.6892 16.0787C79.1272 16.0787 76.9527 14.3403 76.1776 11.9375L76.064 11.9474L73.0734 14.3281L73.0343 14.4399C74.6275 17.6953 77.9 19.9321 81.6892 19.9321Z"
        fill="#34A853"
      />
      <path
        d="M76.1777 11.9374C75.9731 11.3173 75.8548 10.6529 75.8548 9.96639C75.8548 9.27982 75.9731 8.61547 76.1669 7.99539L76.1615 7.86333L73.1334 5.4444L73.0344 5.49287C72.3777 6.84379 72.001 8.36081 72.001 9.96639C72.001 11.572 72.3777 13.0889 73.0344 14.4398L76.1777 11.9374Z"
        fill="#FBBC05"
      />
      <path
        d="M81.6892 3.85338C83.5084 3.85338 84.7355 4.6617 85.4353 5.3372L88.1695 2.59109C86.4903 0.985519 84.305 2.28882e-05 81.6892 2.28882e-05C77.9 2.28882e-05 74.6275 2.23674 73.0343 5.49216L76.1668 7.99468C76.9527 5.59185 79.1272 3.85338 81.6892 3.85338Z"
        fill="#EB4335"
      />
      <path
        d="M29.6798 38.78H32.5598V41.21C32.5598 41.21 31.2998 42.92 28.5998 42.92C25.7198 42.92 23.4698 40.67 23.4698 37.7C23.4698 34.73 25.7198 32.48 28.5998 32.48C31.4798 32.48 32.8298 34.64 32.8298 34.64L33.7298 33.83C33.7298 33.83 32.0198 31.22 28.5998 31.22C24.9998 31.22 22.1198 34.1 22.1198 37.7C22.1198 41.3 24.9998 44.18 28.5998 44.18C32.0198 44.18 33.9098 41.57 33.9098 41.57V37.52H29.6798V38.78Z"
        fill="#4A4A4A"
      />
      <path
        d="M40.8483 44.18C43.2783 44.18 45.2583 42.2 45.2583 39.68C45.2583 37.16 43.2783 35.18 40.8483 35.18C38.4183 35.18 36.4383 37.16 36.4383 39.68C36.4383 42.2 38.4183 44.18 40.8483 44.18ZM40.8483 43.01C39.1383 43.01 37.6983 41.552 37.6983 39.68C37.6983 37.79 39.1383 36.35 40.8483 36.35C42.5583 36.35 43.9983 37.79 43.9983 39.68C43.9983 41.57 42.5583 43.01 40.8483 43.01Z"
        fill="#4A4A4A"
      />
      <path
        d="M51.5698 44.18C53.9998 44.18 55.9797 42.2 55.9797 39.68C55.9797 37.16 53.9998 35.18 51.5698 35.18C49.1398 35.18 47.1597 37.16 47.1597 39.68C47.1597 42.2 49.1398 44.18 51.5698 44.18ZM51.5698 43.01C49.8598 43.01 48.4198 41.552 48.4198 39.68C48.4198 37.79 49.8598 36.35 51.5698 36.35C53.2798 36.35 54.7197 37.79 54.7197 39.68C54.7197 41.57 53.2798 43.01 51.5698 43.01Z"
        fill="#4A4A4A"
      />
      <path
        d="M58.2412 45.98C58.2412 45.98 59.4112 47.78 61.8412 47.78C64.1812 47.78 66.0712 45.89 66.0712 43.46V35.36H64.8112V36.53C64.8112 36.53 64.0012 35.18 61.9312 35.18C59.6812 35.18 57.8812 37.07 57.8812 39.5C57.8812 41.93 59.6812 43.82 61.9312 43.82C64.0012 43.82 64.8112 42.47 64.8112 42.47V43.46C64.8112 45.26 63.4612 46.61 61.8412 46.61C60.0412 46.61 59.0512 45.17 59.0512 45.17L58.2412 45.98ZM61.9312 42.65C60.4012 42.65 59.1412 41.3 59.1412 39.5C59.1412 37.7 60.4012 36.35 61.9312 36.35C63.5512 36.35 64.8112 37.7 64.8112 39.5C64.8112 41.3 63.5512 42.65 61.9312 42.65Z"
        fill="#4A4A4A"
      />
      <path d="M69.3226 44H70.5826V31.4H69.3226V44Z" fill="#4A4A4A" />
      <path
        d="M80.3168 41.57C80.3168 41.57 79.3448 43.01 77.5268 43.01C75.9068 43.01 74.5568 41.66 74.4668 40.13L81.4868 40.148C81.4868 40.148 81.5768 39.86 81.5768 39.5C81.5768 36.98 79.7768 35.18 77.4368 35.18C75.1868 35.18 73.2068 37.16 73.2068 39.68C73.2068 42.2 75.1868 44.18 77.5268 44.18C79.8668 44.18 81.1268 42.38 81.1268 42.38L80.3168 41.57ZM74.4668 39.05C74.6468 37.61 75.9068 36.35 77.4368 36.35C78.9668 36.35 80.1368 37.52 80.3168 39.05H74.4668Z"
        fill="#4A4A4A"
      />
      <path
        d="M88.6105 44H89.9604V38.96H93.5605L96.3504 44H97.8805L94.9105 38.78C94.9105 38.78 97.5205 38.06 97.5205 35.18C97.5205 33.02 95.9005 31.4 93.6505 31.4H88.6105V44ZM89.9604 37.7V32.66H93.6505C95.1805 32.66 96.1704 33.65 96.1704 35.18C96.1704 36.71 95.1805 37.7 93.6505 37.7H89.9604Z"
        fill="#4A4A4A"
      />
      <path
        d="M105.807 44H107.067V38.51C107.067 36.62 105.627 35.18 103.647 35.18C101.577 35.18 100.407 36.8 100.407 36.8L101.217 37.61C101.217 37.61 102.117 36.35 103.647 36.35C104.907 36.35 105.807 37.25 105.807 38.51L102.477 39.05C100.857 39.32 99.9569 40.22 99.9569 41.66C99.9569 42.92 101.217 44.18 103.017 44.18C104.907 44.18 105.807 42.83 105.807 42.83V44ZM105.807 40.13C105.807 41.84 104.637 43.01 103.017 43.01C101.847 43.01 101.217 42.38 101.217 41.66C101.217 40.85 101.757 40.328 102.837 40.13L105.807 39.59V40.13Z"
        fill="#4A4A4A"
      />
      <path
        d="M110.763 41.66C110.763 43.28 111.663 44.18 113.139 44.18C113.823 44.18 114.363 43.91 114.363 43.91V42.74C114.363 42.74 113.823 43.01 113.193 43.01C112.473 43.01 112.023 42.56 112.023 41.66V36.53H113.913V35.36H112.023V32.48H110.853V34.46C110.853 35.09 110.583 35.36 110.133 35.36H109.143V36.53H110.763V41.66Z"
        fill="#4A4A4A"
      />
      <path
        d="M116.629 32.48C116.629 32.93 116.989 33.29 117.439 33.29C117.889 33.29 118.249 32.93 118.249 32.48C118.249 32.03 117.889 31.67 117.439 31.67C116.989 31.67 116.629 32.03 116.629 32.48ZM116.809 44H118.069V35.36H116.809V44Z"
        fill="#4A4A4A"
      />
      <path
        d="M121.237 44H122.497V39.23C122.497 37.52 123.667 36.35 125.197 36.35C126.547 36.35 127.537 37.34 127.537 38.87V44H128.797V38.87C128.797 36.62 127.357 35.18 125.287 35.18C123.397 35.18 122.497 36.62 122.497 36.62V35.36H121.237V44Z"
        fill="#4A4A4A"
      />
      <path
        d="M131.689 45.98C131.689 45.98 132.859 47.78 135.289 47.78C137.629 47.78 139.519 45.89 139.519 43.46V35.36H138.259V36.53C138.259 36.53 137.449 35.18 135.379 35.18C133.129 35.18 131.329 37.07 131.329 39.5C131.329 41.93 133.129 43.82 135.379 43.82C137.449 43.82 138.259 42.47 138.259 42.47V43.46C138.259 45.26 136.909 46.61 135.289 46.61C133.489 46.61 132.499 45.17 132.499 45.17L131.689 45.98ZM135.379 42.65C133.849 42.65 132.589 41.3 132.589 39.5C132.589 37.7 133.849 36.35 135.379 36.35C136.999 36.35 138.259 37.7 138.259 39.5C138.259 41.3 136.999 42.65 135.379 42.65Z"
        fill="#4A4A4A"
      />
      <path
        d="M0.359985 75H8.27999V78H11.88V75H13.8V71.64H11.88V61.2H7.67999L0.359985 71.16V75ZM4.07999 71.64L8.27999 65.88V71.64H4.07999Z"
        fill="#4A4A4A"
      />
      <path
        d="M15.3962 76.32C15.3962 77.4 16.2362 78.24 17.3162 78.24C18.3962 78.24 19.2362 77.4 19.2362 76.32C19.2362 75.24 18.3962 74.4 17.3162 74.4C16.2362 74.4 15.3962 75.24 15.3962 76.32Z"
        fill="#4A4A4A"
      />
      <path
        d="M24.3219 78H28.0419L32.7219 70.8C32.7219 70.8 34.1619 69 34.1619 66.72C34.1619 63.48 31.5219 60.84 27.8019 60.84C24.0819 60.84 21.4419 63.48 21.4419 66.72C21.4419 69.96 24.0819 72.6 27.0819 72.6C27.5619 72.6 27.9219 72.48 27.9219 72.48L24.3219 78ZM25.1619 66.72C25.1619 65.16 26.2419 64.08 27.8019 64.08C29.3619 64.08 30.4419 65.16 30.4419 66.72C30.4419 68.28 29.3619 69.36 27.8019 69.36C26.2419 69.36 25.1619 68.28 25.1619 66.72Z"
        fill="#4A4A4A"
      />
      <path
        d="M53.0815 59.1327C53.4286 58.3268 54.5714 58.3268 54.9185 59.1327L57.0097 63.9889C57.1545 64.325 57.4714 64.5553 57.8358 64.5891L63.1005 65.0773C63.9743 65.1584 64.3274 66.2452 63.6681 66.8244L59.6959 70.3139C59.4209 70.5555 59.2999 70.928 59.3804 71.285L60.5429 76.4429C60.7358 77.299 59.8113 77.9707 59.0568 77.5226L54.5106 74.8232C54.1958 74.6363 53.8042 74.6363 53.4894 74.8232L48.9432 77.5226C48.1887 77.9707 47.2642 77.299 47.4571 76.4429L48.6196 71.285C48.7001 70.928 48.5791 70.5555 48.3041 70.3139L44.3319 66.8244C43.6726 66.2452 44.0257 65.1584 44.8995 65.0773L50.1642 64.5891C50.5286 64.5553 50.8455 64.325 50.9903 63.9889L53.0815 59.1327Z"
        fill="#E39F3A"
      />
      <path
        d="M77.0815 59.1327C77.4286 58.3268 78.5714 58.3268 78.9185 59.1327L81.0097 63.9889C81.1545 64.325 81.4714 64.5553 81.8358 64.5891L87.1005 65.0773C87.9743 65.1584 88.3274 66.2452 87.6681 66.8244L83.6959 70.3139C83.4209 70.5555 83.2999 70.928 83.3804 71.285L84.5429 76.4429C84.7358 77.299 83.8113 77.9707 83.0568 77.5226L78.5106 74.8232C78.1958 74.6363 77.8042 74.6363 77.4894 74.8232L72.9432 77.5226C72.1887 77.9707 71.2642 77.299 71.4571 76.4429L72.6196 71.285C72.7001 70.928 72.5791 70.5555 72.3041 70.3139L68.3319 66.8244C67.6726 66.2452 68.0257 65.1584 68.8995 65.0773L74.1642 64.5891C74.5286 64.5553 74.8455 64.325 74.9903 63.9889L77.0815 59.1327Z"
        fill="#E39F3A"
      />
      <path
        d="M101.082 59.1327C101.429 58.3268 102.571 58.3268 102.918 59.1327L105.01 63.9889C105.154 64.325 105.471 64.5553 105.836 64.5891L111.101 65.0773C111.974 65.1584 112.327 66.2452 111.668 66.8244L107.696 70.3139C107.421 70.5555 107.3 70.928 107.38 71.285L108.543 76.4429C108.736 77.299 107.811 77.9707 107.057 77.5226L102.511 74.8232C102.196 74.6363 101.804 74.6363 101.489 74.8232L96.9432 77.5226C96.1887 77.9707 95.2642 77.299 95.4571 76.4429L96.6196 71.285C96.7001 70.928 96.5791 70.5555 96.3041 70.3139L92.3319 66.8244C91.6726 66.2452 92.0257 65.1584 92.8995 65.0773L98.1642 64.5891C98.5286 64.5553 98.8455 64.325 98.9903 63.9889L101.082 59.1327Z"
        fill="#E39F3A"
      />
      <path
        d="M125.082 59.1327C125.429 58.3268 126.571 58.3268 126.918 59.1327L129.01 63.9889C129.154 64.325 129.471 64.5553 129.836 64.5891L135.101 65.0773C135.974 65.1584 136.327 66.2452 135.668 66.8244L131.696 70.3139C131.421 70.5555 131.3 70.928 131.38 71.285L132.543 76.4429C132.736 77.299 131.811 77.9707 131.057 77.5226L126.511 74.8232C126.196 74.6363 125.804 74.6363 125.489 74.8232L120.943 77.5226C120.189 77.9707 119.264 77.299 119.457 76.4429L120.62 71.285C120.7 70.928 120.579 70.5555 120.304 70.3139L116.332 66.8244C115.673 66.2452 116.026 65.1584 116.899 65.0773L122.164 64.5891C122.529 64.5553 122.846 64.325 122.99 63.9889L125.082 59.1327Z"
        fill="#E39F3A"
      />
      <path
        d="M149.082 59.1327C149.429 58.3268 150.571 58.3268 150.918 59.1327L153.01 63.9889C153.154 64.325 153.471 64.5553 153.836 64.5891L159.101 65.0773C159.974 65.1584 160.327 66.2452 159.668 66.8244L155.696 70.3139C155.421 70.5555 155.3 70.928 155.38 71.285L156.543 76.4429C156.736 77.299 155.811 77.9707 155.057 77.5226L150.511 74.8232C150.196 74.6363 149.804 74.6363 149.489 74.8232L144.943 77.5226C144.189 77.9707 143.264 77.299 143.457 76.4429L144.62 71.285C144.7 70.928 144.579 70.5555 144.304 70.3139L140.332 66.8244C139.673 66.2452 140.026 65.1584 140.899 65.0773L146.164 64.5891C146.529 64.5553 146.846 64.325 146.99 63.9889L149.082 59.1327Z"
        fill="#E39F3A"
      />
    </SvgIcon>
  );
};

export default GoogleRating;
