import R from "ramda";
import { createSelector } from "reselect";

export const selectRequestsLoading = R.pathOr({}, ["requests", "loading"]);
export const selectRequestsHasData = R.pathOr({}, ["requests", "hasData"]);
export const selectHasLoaded = R.pathOr(false, ["requests", "hasLoaded"]);

export const selectHasLoadedDataById = (formId) =>
  createSelector(selectRequestsHasData, (hasDataMap) => {
    const hasData = R.propOr(false, formId)(hasDataMap);
    return hasData;
  });

export const selectIsLoadingById = (formId) =>
  createSelector(selectRequestsLoading, (requestsLoading) =>
    R.propOr(false, formId)(requestsLoading),
  );
