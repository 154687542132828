export const fetchDonations = () => ({
  type: FETCH_DONATIONS_TYPE,
});

export const updateDonations = () => ({
  type: UPDATE_DONATIONS_TYPE,
});

export const FETCH_DONATIONS_TYPE = "FETCH_DONATIONS";
export const UPDATE_DONATIONS_TYPE = "UPDATE_DONATIONS";
