import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { H4, SmallBody } from "../../ui/Typography";
import { SmallTextButton } from "../../ui/Button";
import CheckmarkGrey from "../../ui/icons/CheckmarkGrey";
import CheckmarkGreen from "../../ui/icons/CheckmarkGreen";
import { inviteStatuses } from "../PlanConstants";

const AssignedPlans = ({
  id,
  fullName,
  email,
  status,
  revokeInvite,
  resendInvite,
  isResendLoading,
  isRevokeLoading,
  translations,
}) => {
  const user = { id, email, fullName };
  const { cancelInvite, hasBeenInvited } = translations;
  const resendInviteTranslation = translations.resendInvite; // Avoid a naming collision by explicitly naming this a translation variable
  const isSmallestDevice = useMediaQuery("@media (max-width: 374px)"); // Used for styling devices less than 375px wide

  return (
    <Box
      borderBottom={0}
      borderRight={0}
      borderLeft={0}
      borderColor="border.main"
      border={1}
    >
      <Box display="flex" p={1.5}>
        <Grid container alignItems="flex-start">
          <Grid item sm={11}>
            <H4 align="left" data-hj-suppress>
              {fullName}
            </H4>
            <SmallBody color="secondary" margin="4px 0 0">
              {email} {status === inviteStatuses.invited && hasBeenInvited}
            </SmallBody>
          </Grid>
          <Grid item sm={1}>
            <Box
              display="flex"
              height={1}
              alignItems="center"
              justifyContent="center"
              pt={0.5}
            >
              {status === inviteStatuses.consumed && <CheckmarkGreen />}
              {status === inviteStatuses.invited && <CheckmarkGrey />}
            </Box>
          </Grid>
          {status === inviteStatuses.invited && (
            <Grid item sm={12}>
              <Box display="flex" justifyContent="flex-end" mt={1}>
                <Box mr={isSmallestDevice ? 0.75 : 1.5}>
                  <SmallTextButton
                    onClick={() => resendInvite(user)}
                    text={resendInviteTranslation}
                    isLoading={isResendLoading}
                  />
                </Box>
                <Box>
                  <SmallTextButton
                    onClick={() => revokeInvite(user)}
                    text={cancelInvite}
                    isLoading={isRevokeLoading}
                  />
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default AssignedPlans;
