import React from "react";
import Box from "@material-ui/core/Box";
import { Body } from "../ui/Typography";
import StatusLabel from "../ui/StatusLabel";
import NotarizationGuideCard from "../ui/NotarizationGuideCard";
import YourPlanCard from "./your-plan/YourPlanCard";

const PlanSummary = ({
  translations,
  hasUserPaid,
  analyticsClickExternalLink,
  analyticsDownloadNotarizationGuide,
  yourPlanProps,
  isNotarizationGuideAvailable,
  showPlanStatus = true,
  showCta = true,
}) => {
  return (
    <Box display="flex">
      <Box width={1}>
        {showPlanStatus && (
          <Box pb={1} display="flex" justifyContent="space-between">
            <Body component="span">{translations.yourPlanNavText}</Body>
            <Box alignItems="center" display="flex">
              <StatusLabel
                status={hasUserPaid ? "paid" : "unpaid"}
                translations={translations}
              />
            </Box>
          </Box>
        )}
        <Box mb={2}>
          <YourPlanCard
            {...yourPlanProps}
            analyticsClickExternalLink={analyticsClickExternalLink}
            translations={translations}
            dashboardView
            showCta={showCta}
          />
        </Box>
        <Box width={1}>
          {isNotarizationGuideAvailable && (
            <Box mt={3}>
              <NotarizationGuideCard
                translations={translations}
                isFrenchSelected={yourPlanProps.isFrenchSelected}
                analyticsDownloadNotarizationGuide={
                  analyticsDownloadNotarizationGuide
                }
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PlanSummary;
