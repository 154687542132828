import React, { useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Box, Grid, Hidden, useMediaQuery } from "@material-ui/core";
import { withTheme, useTheme } from "@material-ui/styles";

import { AppointeesPageProps } from "../../selectors/appointees";
import { fetchDashboard } from "../../actions/dashboard";
import { displayEmailPreviewModal } from "../../actions/email-preview-modal";
import { notifyAppointee, toggleAppointeeCard } from "../../actions/appointees";
import { PageLayout } from "../../components/ui/PageLayout";
import { H1, H2, LargeBody } from "../../components/ui/Typography";
import AppointeesIcon from "../../components/ui/icons/AppointeesIcon";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import AppointeeCard from "../../components/ui/AppointeeCard";

const RawAppointeeGroup = ({ children, className }) => (
  <Box mt={4.5} className={className}>
    {children}
  </Box>
);

const AppointeeGroup = styled(RawAppointeeGroup)`
  &:last-child {
    margin-bottom: 0;
  }

  ${({ $isDesktop, $isFirstGroup }) =>
    !$isDesktop && $isFirstGroup && "margin-top: 32px"}
`;

const AppointeeHeading = ({ children }) => (
  <Box mb={2}>
    <H2 align="left">{children}</H2>
  </Box>
);

const AppointeesPage = ({
  translations,
  appointees,
  fetchDashboard,
  hasLoaded,
  displayEmailPreviewModal,
  notifyAppointee,
  toggleAppointeeCard,
}) => {
  // TODO: Remove the dashboard call when we get the appointees call.
  //       This is only for ensuring that we have province data when navigating
  //       directly to this page while developing it.
  useEffect(() => {
    fetchDashboard();
  }, [fetchDashboard, hasLoaded]);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  if (!hasLoaded) {
    return <LoadingSpinner />;
  }

  const {
    executors,
    guardians,
    propertyAttorneys,
    personalAttorneys,
  } = appointees;

  return (
    <PageLayout>
      <Grid container justify={isDesktop ? "space-between" : "center"}>
        <Grid item xl={5} lg={6} md={8} xs={12}>
          <Box>
            <H1 align="left">{translations.title}</H1>
            <LargeBody align="left">{translations.intro}</LargeBody>
            <Hidden lgUp>
              <Box display="flex" justifyContent="flex-end">
                <AppointeesIcon width="100" height="100" />
              </Box>
            </Hidden>
          </Box>
          {executors.length > 0 && (
            <AppointeeGroup $isDesktop={isDesktop} $isFirstGroup>
              <AppointeeHeading>
                {translations.roles.executors}
              </AppointeeHeading>
              {executors.map((appointee) => (
                <AppointeeCard
                  key={appointee.appointeeKey}
                  {...appointee}
                  translations={translations}
                  onPreviewEmail={displayEmailPreviewModal}
                  onNotifyAppointee={notifyAppointee}
                  setIsCardOpen={(shouldDisplay) =>
                    toggleAppointeeCard({
                      key: appointee.appointeeKey,
                      shouldDisplay,
                    })
                  }
                />
              ))}
            </AppointeeGroup>
          )}
          {guardians.length > 0 && (
            <AppointeeGroup>
              <AppointeeHeading align="left">
                {translations.roles.guardians}
              </AppointeeHeading>
              {guardians.map((appointee) => (
                <AppointeeCard
                  key={appointee.appointeeKey}
                  {...appointee}
                  translations={translations}
                  onPreviewEmail={displayEmailPreviewModal}
                  onNotifyAppointee={notifyAppointee}
                  setIsCardOpen={(shouldDisplay) =>
                    toggleAppointeeCard({
                      key: appointee.appointeeKey,
                      shouldDisplay,
                    })
                  }
                />
              ))}
            </AppointeeGroup>
          )}
          {propertyAttorneys.length > 0 && (
            <AppointeeGroup>
              <AppointeeHeading align="left">
                {translations.roles.propertyAttorneys}
              </AppointeeHeading>
              {propertyAttorneys.map((appointee) => (
                <AppointeeCard
                  key={appointee.appointeeKey}
                  {...appointee}
                  translations={translations}
                  onPreviewEmail={displayEmailPreviewModal}
                  onNotifyAppointee={notifyAppointee}
                  setIsCardOpen={(shouldDisplay) =>
                    toggleAppointeeCard({
                      key: appointee.appointeeKey,
                      shouldDisplay,
                    })
                  }
                />
              ))}
            </AppointeeGroup>
          )}
          {personalAttorneys.length > 0 && (
            <AppointeeGroup>
              <AppointeeHeading align="left">
                {translations.roles.personalAttorneys}
              </AppointeeHeading>
              {personalAttorneys.map((appointee) => (
                <AppointeeCard
                  key={appointee.appointeeKey}
                  {...appointee}
                  translations={translations}
                  onPreviewEmail={displayEmailPreviewModal}
                  onNotifyAppointee={notifyAppointee}
                  setIsCardOpen={(shouldDisplay) =>
                    toggleAppointeeCard({
                      key: appointee.appointeeKey,
                      shouldDisplay,
                    })
                  }
                />
              ))}
            </AppointeeGroup>
          )}
        </Grid>
        <Hidden mdDown>
          <Grid item lg={2}>
            <Box display="flex" justifyContent="flex-end">
              <AppointeesIcon />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </PageLayout>
  );
};

export default connect(AppointeesPageProps, {
  fetchDashboard,
  displayEmailPreviewModal,
  notifyAppointee,
  toggleAppointeeCard,
})(withTheme(AppointeesPage));
