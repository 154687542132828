export const updateChildren = () => ({
  type: UPDATE_CHILDREN_TYPE,
});

export const openModalChildForm = (children = null) => ({
  type: OPEN_MODAL_CHILDREN_FORM_TYPE,
  payload: { children },
});

export const removeChild = (child) => ({
  type: REMOVE_CHILD_TYPE,
  payload: { child },
});

export const childAddedSuccessfully = (child) => ({
  type: ADD_CHILD_TYPE,
  payload: { child },
});

export const childrenNextPage = (showZeroChildrenNotice) => ({
  type: CHILDREN_NEXT_PAGE_TYPE,
  payload: { showZeroChildrenNotice },
});

export const ADD_CHILD_TYPE = "ADD_CHILD_TYPE";
export const UPDATE_CHILDREN_TYPE = "UPDATE_CHILDREN";
export const REMOVE_CHILD_TYPE = "REMOVE_CHILD_TYPE";
export const OPEN_MODAL_CHILDREN_FORM_TYPE =
  "OPEN_MODAL_CHILDREN_MINI_FORM_TYPE";
export const CHILDREN_NEXT_PAGE_TYPE = "CHILDREN_NEXT_PAGE";
