import React from "react";
import SvgIcon from "./SvgIcon";

const KraftHeinzLogo = ({ height = 36 }) => {
  return (
    <SvgIcon viewBox="0 0 210 36" fill="none" height={height}>
      <g clipPath="url(#clip0_32650_2241)">
        <path
          d="M93.3459 17.0635C93.3417 15.1064 93.3417 13.1483 93.3459 11.1902C91.6627 11.1787 89.9837 11.1818 88.3006 11.186C88.2964 9.05124 88.2922 6.91541 88.3089 4.78481C86.0843 4.79631 83.8554 4.79213 81.6317 4.789V11.1818H78.8634C78.8592 13.141 78.8634 15.1001 78.8634 17.0635H81.6276V17.0676H81.6317C81.6391 18.8553 81.6297 20.6451 81.6286 22.4339C81.6276 22.5823 81.6276 22.7308 81.6276 22.8792C81.6276 22.9911 81.6265 23.104 81.6265 23.2159H81.6276C81.6276 24.2582 81.6307 25.3005 81.6443 26.3428C81.7007 29.1613 82.7263 32.1805 85.0995 33.8364C87.1882 35.3304 89.7903 35.6095 92.2879 35.5761C90.2336 33.436 89.001 30.3091 88.5055 27.798L88.4909 27.7248L88.4814 27.6506C88.4145 27.0997 88.3309 26.2142 88.3403 25.2795C88.3246 25.0799 88.3131 24.8812 88.3048 24.6857C88.2975 24.1777 88.2975 23.6696 88.2954 23.1625C88.286 21.1292 88.3069 19.0979 88.3006 17.0676C89.9796 17.0718 91.6627 17.0718 93.3459 17.0635Z"
          fill="white"
        />
        <path
          d="M77.6158 6.6217C77.6033 4.65941 77.6033 2.69817 77.6158 0.735889C74.3216 0.182854 70.5277 0.401348 67.888 2.67831C65.5264 4.80054 65.0622 8.17521 65.1469 11.1871C65.1249 11.1871 65.1019 11.1871 65.08 11.1871H62.2615C62.2646 13.1473 62.2646 15.1085 62.2615 17.0698H64.6984C64.8531 17.0687 65.0078 17.0677 65.1626 17.0677V17.0698H65.1762V20.2845H65.1657C65.1709 25.0967 65.173 29.9109 65.1626 34.723H68.2633C69.4886 34.723 70.7149 34.7241 71.9401 34.7199C71.937 29.8356 71.9339 24.9524 71.9412 20.0702V17.0635H76.4616C76.4575 15.1012 76.4575 13.1421 76.4616 11.1829C76.4616 11.1829 72.0269 11.1829 71.9077 11.1829C71.9684 9.85941 71.8157 8.27662 72.9051 7.296C74.2171 6.19411 76.0456 6.5527 77.6158 6.6217Z"
          fill="white"
        />
        <path
          d="M53.0456 34.7225H56.0565C56.6262 34.7225 57.196 34.7214 57.7668 34.7225H59.6695C59.6287 29.6814 59.7092 24.6403 59.6287 19.5981C59.5765 16.6395 58.4537 13.3579 55.6331 11.9696C51.5998 9.96338 46.8734 10.4161 42.5703 11.106C42.5547 13.245 42.5662 15.3882 42.563 17.5323C45.3313 16.9312 48.3506 16.305 51.0917 17.3306C52.3671 17.7697 52.8438 19.1141 53.0414 20.3289C51.1241 20.0874 49.1785 19.6305 47.2445 20.0226C44.9518 20.3048 42.6801 21.4276 41.3838 23.3931C39.2929 26.521 39.8898 31.2516 42.9864 33.5129C45.8927 35.7282 50.1153 35.4898 53.0697 33.4847C53.0655 33.7931 53.054 34.4088 53.0456 34.7225ZM52.7665 29.6291C51.0509 30.1581 48.9569 30.7435 47.3825 29.5444C45.945 28.5314 46.1311 26.0892 47.6888 25.2905C49.2789 24.451 51.1356 24.7448 52.824 25.0804C52.9766 26.5942 52.9641 28.1195 52.7665 29.6291Z"
          fill="white"
        />
        <path
          d="M18.4002 2.80277C21.1005 2.81845 23.8009 2.80695 26.5012 2.80695C22.5453 8.12718 18.6782 13.5154 14.6983 18.8157C18.2423 24.1119 21.7299 29.4394 25.3021 34.7147C22.5014 34.7356 19.6996 34.7074 16.902 34.7314C13.7825 29.9967 10.6545 25.2661 7.54957 20.5229C7.54225 25.2577 7.54225 29.9925 7.55375 34.7273C5.13984 34.7147 2.72592 34.7231 0.312012 34.7231C0.320375 24.0868 0.320375 13.4505 0.312012 2.81427C2.72592 2.80277 5.1367 2.81427 7.54957 2.80277C7.54539 7.824 7.53807 12.8452 7.55375 17.8665C11.1542 12.8337 14.7913 7.82819 18.4002 2.80277Z"
          fill="white"
        />
        <path
          d="M26.9817 11.2593C29.2545 11.2478 31.5304 11.2593 33.8073 11.2478C33.799 12.749 33.7958 14.2503 33.7708 15.7484C34.8737 13.2613 37.2583 11.643 39.9064 11.2154C39.9106 13.4432 39.9388 15.6752 39.8782 17.903C36.9761 18.2501 33.9934 20.4142 33.8878 23.5463C33.8073 27.2681 33.8722 30.9982 33.868 34.7241C31.5712 34.7241 29.2743 34.7241 26.9817 34.7189C26.9859 26.8969 26.9859 19.0781 26.9817 11.2593Z"
          fill="white"
        />
        <path
          d="M148.156 29.9543C147.203 30.3348 147.013 30.5251 145.872 30.9057C144.729 31.2872 143.396 31.2872 142.253 30.9057C138.636 29.3835 140.921 23.8615 140.921 23.8615C155.772 20.2443 153.678 12.2478 149.679 11.8662C147.203 11.8662 138.256 12.2477 134.066 22.5286C133.496 24.0518 129.497 34.9044 138.446 35.6655C140.35 35.6655 142.445 35.4753 144.348 34.5239C146.062 33.5715 147.395 32.4289 148.536 30.9057C149.489 29.1922 148.156 29.9543 148.156 29.9543ZM146.062 14.7223C147.203 15.1028 144.348 20.8151 144.348 20.8151C143.206 21.1956 142.063 21.5772 141.302 21.5772C141.682 20.8151 143.777 13.5807 146.062 14.7223Z"
          fill="white"
        />
        <path
          d="M182.808 32.0483C182.618 31.858 182.237 31.858 182.047 31.6678C180.904 30.9057 181.855 28.8116 182.047 27.669L185.474 15.4844C185.664 14.7223 186.997 10.7245 181.665 11.4856C177.096 12.2477 173.859 15.1028 173.859 15.1028C174.24 13.9612 174.621 12.2477 173.859 11.8662C172.908 11.4856 167.386 13.1991 166.053 14.3418C165.672 14.3418 165.101 14.9126 165.672 15.4844C166.243 16.2455 166.434 16.8163 166.053 18.1492C165.292 20.4346 163.007 28.4311 162.435 29.9543C161.865 31.4775 161.102 34.3336 162.435 35.285C163.388 36.0471 167.386 34.3336 167.957 33.9521C168.528 33.7618 169.099 33.3812 169.289 33.191C170.051 32.4289 168.909 32.0483 170.051 28.0495C170.051 27.8593 171.384 23.8615 172.336 20.4346C173.098 17.5784 175.763 16.2455 176.715 16.0552C178.238 15.865 177.858 17.1979 177.667 18.1492C176.334 22.3383 174.811 28.6214 174.24 30.1446C173.669 31.6678 172.717 34.7142 174.24 35.285C175.572 36.2374 181.665 33.5715 182.237 33.191C183.379 32.4289 183.379 32.0483 182.808 32.0483Z"
          fill="white"
        />
        <path
          d="M159.58 32.0486C159.199 31.8583 159.009 31.8583 158.817 31.668C157.676 30.9059 158.437 28.8119 158.817 27.6692L162.436 15.4847C162.626 14.1517 163.387 12.4383 162.436 11.8664C161.293 11.4859 155.771 13.1993 154.439 14.342C154.248 14.342 153.677 14.9128 154.248 15.4847C154.82 16.2457 154.82 16.8166 154.439 18.1495C153.867 20.4348 151.201 28.6216 150.631 30.1448C150.06 31.668 149.298 34.7144 151.011 35.2852C152.344 36.2376 158.056 33.762 158.817 33.1912C160.15 32.6194 160.15 32.0486 159.58 32.0486Z"
          fill="white"
        />
        <path
          d="M158.437 9.01068C158.818 9.77176 162.245 8.2496 163.959 7.29721C164.149 7.10694 164.91 6.53509 165.101 5.96428C165.482 4.63135 165.672 4.06054 165.863 3.10815C166.053 2.72761 166.053 2.1568 165.482 1.96549C164.72 1.77522 160.15 2.34707 159.008 4.25081C158.627 5.01189 157.294 8.63014 158.437 9.01068Z"
          fill="white"
        />
        <path
          d="M209.463 22.3384C208.892 20.2444 205.464 19.6726 204.132 20.8152C203.37 21.5763 202.99 23.0995 203.18 23.4811C206.417 22.5287 206.988 25.3848 206.417 26.5265C205.274 28.241 204.894 28.8118 203.37 28.8118H193.28C193.09 28.6215 192.898 28.4312 193.28 28.0497C193.851 27.2886 203.751 13.7711 204.132 13.1992C204.323 12.8187 204.894 11.8663 203.561 11.8663H192.518C191.376 11.8663 190.804 12.2468 189.853 13.1992C189.091 14.1516 188.139 17.0067 187.568 18.9115C187.568 18.9115 187.568 19.6726 188.71 18.7212C188.9 18.5299 189.281 17.7688 189.853 17.7688H196.516C198.61 17.7688 187.568 28.241 184.141 33.7619C183.57 34.7143 184.521 34.7143 185.093 34.7143H199.563C203.18 34.7143 202.609 35.2851 204.513 32.6193C204.513 32.6193 210.415 25.0043 209.463 22.3384Z"
          fill="white"
        />
        <path
          d="M129.878 28.8119L133.877 13.3896C136.541 4.06011 138.065 3.67957 139.017 2.72718H132.734C130.45 2.72718 128.735 5.96385 128.355 8.0589L125.689 17.7689H119.977C119.977 17.7689 123.404 5.39304 123.785 4.06011C123.975 3.4893 123.975 2.72718 122.834 2.72718C122.642 2.72718 110.648 2.34664 106.839 3.67957C105.126 4.25038 102.651 5.77358 101.889 8.0589C101.319 9.39183 100.556 11.8664 102.271 16.436C102.461 17.0068 102.842 17.3884 103.032 17.5786C103.603 17.9592 108.553 17.1981 108.363 17.1981C108.744 17.1981 108.935 17.0068 108.553 16.2457C108.172 15.2933 107.031 13.5799 106.839 11.8664C106.078 5.20277 110.838 4.25038 111.599 4.06011C112.742 3.86984 113.694 3.86984 114.836 4.06011C115.218 4.06011 116.169 4.25038 116.359 4.63196C116.741 5.39304 116.169 6.72597 115.788 8.24917C115.408 9.39183 112.171 21.1959 111.219 24.4325C110.648 26.9081 108.172 32.2388 105.698 33.0009C105.126 33.1912 104.365 33.1912 103.603 33.0009C102.842 32.8107 102.08 32.4291 101.509 32.0485C99.9856 30.5253 99.0332 27.4789 98.8429 25.1946C98.6526 22.3385 99.2245 20.2445 98.6526 20.2445C97.6595 20.2445 94.3413 21.5555 92.9958 22.5215C92.9937 22.5236 92.9916 22.5236 92.9895 22.5257C92.4438 22.9156 92.4082 22.9909 92.4082 22.9909C92.3811 23.0181 92.3591 23.0452 92.3445 23.0703C91.9932 23.6694 92.0173 25.7989 92.1793 27.0984C92.7511 29.9545 94.2743 33.1912 96.5586 34.7144C102.651 38.9024 113.314 31.2875 114.455 30.5253C116.169 29.0021 117.312 26.9081 117.692 25.3849C118.264 23.4812 119.215 20.2445 119.215 20.2445H124.928C124.928 20.2445 122.642 28.8119 122.071 30.9059C121.501 32.6193 121.119 35.4755 122.642 35.2852C128.355 35.2852 130.64 33.1912 130.83 33.0009C131.02 32.8107 131.02 32.4291 130.83 32.2388C130.45 32.0485 129.117 30.7156 129.878 28.8119Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_32650_2241">
          <rect
            width="209.255"
            height="35.4837"
            fill="white"
            transform="translate(0.312012 0.480469)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default KraftHeinzLogo;
