import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core";

const useStickyBottomBoxStyleForTheme = makeStyles((boxTheme) => ({
  stickyBottomBox: {
    [boxTheme.breakpoints.down("md")]: {
      // Styles for mobile and tablet
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      marginTop: 0,
      zIndex: 1000, // Ensure it's above other content
    },
  },
}));

export const useStickyBottomBoxStyle = () =>
  useStickyBottomBoxStyleForTheme(useTheme());
