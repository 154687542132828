import {
  ESSENTIALS_PLAN,
  PREMIUM_PLAN,
  COUPLES_PLAN,
  NOTARIAL_PLAN,
} from "./plans";

export const USER_WILL = "userWill";
export const USER_POA_PROPERTY = "userPoaProperty";
export const USER_POA_PERSONAL = "userPoaPersonal";

export const SPOUSE_WILL = "spouseWill";
export const SPOUSE_POA_PROPERTY = "spousePoaProperty";
export const SPOUSE_POA_PERSONAL = "spousePoaPersonal";

export const NOTARIAL_WILL = "notarialWill";

export const UPGRADABLE_POA_PROPERTY = "upgradablePoaProperty";
export const UPGRADABLE_POA_PERSONAL = "upgradablePoaPersonal";

export const ASSET_LIST = "userAssetList";
export const UPGRADABLE_ASSET_LIST = "upgradableAssetList";

export const plansDocumentsKeysMap = {
  [ESSENTIALS_PLAN]: {
    userDocuments: [
      { key: USER_WILL },
      { key: UPGRADABLE_POA_PROPERTY, isUpgradable: true },
      { key: UPGRADABLE_POA_PERSONAL, isUpgradable: true },
    ],
    assetListDocuments: [
      {
        key: UPGRADABLE_ASSET_LIST,
        isUpgradable: true,
      },
    ],
  },
  ESSENTIALS_ONLY: {
    userDocuments: [{ key: USER_WILL }],
  },
  [PREMIUM_PLAN]: {
    userDocuments: [
      { key: USER_WILL },
      { key: USER_POA_PROPERTY },
      { key: USER_POA_PERSONAL },
    ],
    assetListDocuments: [
      {
        key: ASSET_LIST,
      },
    ],
  },
  [COUPLES_PLAN]: {
    userDocuments: [
      { key: USER_WILL },
      { key: USER_POA_PROPERTY },
      { key: USER_POA_PERSONAL },
    ],
    spouseDocuments: [
      { key: SPOUSE_WILL },
      { key: SPOUSE_POA_PROPERTY },
      { key: SPOUSE_POA_PERSONAL },
    ],
  },
  [NOTARIAL_PLAN]: {
    userDocuments: [{ key: NOTARIAL_WILL }],
  },
};
