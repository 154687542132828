import R from "ramda";
import { createSelector } from "reselect";
import { selectTranslations } from "./translations";
import { selectUserEmail } from "./auth";
import { selectResendLoadingUsers, selectRevokeLoadingUsers } from "./invite";

const selectedAssignedUsers = R.pathOr([], ["invites", "assignedUsers"]);

const buildPlanFullName = (firstName, lastName, isCurrentUser, youLabel) => {
  const fullName = `${firstName} ${lastName}`;
  if (isCurrentUser) return `${fullName} ${youLabel}`;
  return fullName;
};

export const selectAssignedPlans = createSelector(
  selectedAssignedUsers,
  selectUserEmail,
  selectResendLoadingUsers,
  selectRevokeLoadingUsers,
  selectTranslations(["global", "yourAccount", "yourPlanTranslations"]),
  (
    assignedSeats,
    userEmail,
    resendLoadingUsers,
    revokeLoadingUsers,
    translations,
  ) => {
    // build shape of the assigned user plan
    return assignedSeats.reduce((assignedUsers, currentPlan) => {
      const user = currentPlan.assignedToUser;
      const isCurrentUser = user.email === userEmail;
      const youLabel = R.pathOr(
        "",
        ["yourPlanTranslations", "youLabel"],
        translations,
      );
      const fullName = buildPlanFullName(
        user.firstName,
        user.lastName,
        isCurrentUser,
        youLabel,
      );

      return [
        ...assignedUsers,
        {
          id: user.userId,
          fullName,
          email: user.email,
          status: user.status,
          isResendLoading: resendLoadingUsers[user.userId],
          isRevokeLoading: revokeLoadingUsers[user.userId],
        },
      ];
    }, []);
  },
);

export const selectNumberOfAvailableInvites = R.pathOr(0, [
  "invites",
  "availableInvites",
]);
