import R from "ramda";
import { select, takeEvery, call, put } from "redux-saga/effects";
import {
  initialize,
  getFormValues,
  actionTypes as reduxFormActionTypes,
  change,
  untouch,
} from "redux-form";

import { selectTranslations } from "../selectors/translations";
import { handleFormError, submitFormRequest, getDataByFormId } from "./forms";
import {
  FETCH_DOB_TYPE,
  FETCH_RESIDENCE_TYPE,
  SUBMIT_ABOUT_YOU_TYPE,
  SUBMIT_PEOPLE_QUANTITY_INTENT_TYPE,
} from "../actions/about-you";

import { fetchSuccess } from "../actions/requests";
import { selectHasLoaded } from "../selectors/requests";
import {
  selectProvincialAgeOfMajority,
  selectFormIsFrenchAvailableInSelectedProvince,
  selectResidenceForm,
  selectLanguageCode,
} from "../selectors/about-you";
import { submitUserRequest } from "../api/user";
import { fetchApiData } from "./requests";
import { submitLanguage } from "../api/language";
import {
  RESIDENCE_FORM_ID,
  LANGUAGE_FORM_ID,
  DOB_FORM_ID,
  PROVINCE_FIELD_ID,
  RESIDENCE_FIELD_ID,
  DOB_FIELD_ID,
  LANGUAGE_FIELD_ID,
  PEOPLE_QUANTITY_INTENT_FORM_ID,
} from "../constants/forms";
import {
  userChangedUnsupportedLanguageAnalytics,
  userChangedLanguageAnalytics,
} from "../actions/analytics";
import { addProvinces } from "../actions/provinces";
import { getProvinces } from "../api/provinces";
import { displayModal } from "../actions/modal";
import { selectIsFrenchSelected } from "../selectors/language";
import {
  selectAllProvinces,
  selectIsFrenchAvailableInSelectedProvince,
} from "../selectors/provinces";
import {
  setLanguageCode,
  VALIDATE_LANGUAGE_CODE_TYPE,
} from "../actions/language";
import { getDashboard } from "../api/dashboard";
import { DOBDefaultDate } from "../utilities/date";

// TODO: Figure out long-term solution for circular-dependency selector issue
export const selectIsLoggedIn = R.pathOr(false, ["auth", "loggedIn"]);

function* fetchResidence() {
  const hasLoaded = yield select(selectHasLoaded);
  const allProvinces = yield select(selectAllProvinces);
  if (!hasLoaded || allProvinces.length === 0) {
    const { provinces } = yield call(fetchApiData, {
      apiCall: getProvinces,
      formId: RESIDENCE_FORM_ID,
    });

    yield put(addProvinces(provinces));
  }
  const formData = yield select(selectResidenceForm);
  yield put(initialize(RESIDENCE_FORM_ID, formData));
  yield put(fetchSuccess(RESIDENCE_FORM_ID));
}

function* fetchDOB() {
  const hasLoaded = yield select(selectHasLoaded);
  if (!hasLoaded) {
    yield call(fetchApiData, {
      apiCall: getDashboard,
      DOB_FORM_ID,
    });
  }
  const formData = yield call(getDataByFormId, DOB_FORM_ID);

  if (!formData.dateOfBirth) {
    const DOBDefaultDateValues = {
      dateOfBirth: DOBDefaultDate,
    };
    return yield put(initialize(DOB_FORM_ID, DOBDefaultDateValues));
  }
  return yield put(initialize(DOB_FORM_ID, formData));
}

/*
 * submitAboutYou - This saga is the entry point for FE validation
 * of data in the About You flow before submitting to the BE
 * submitAboutYou (entry point, calls LanguageValidation)
 * handleLanguageValidation (checks if submitting wrong language)
 * handleFormValidation (form specific validation - can be broken down further eg: DOB + Residence)
 * updateAboutYouState submits request to BE if FE validation passes
 */
function* submitAboutYou({ payload }) {
  return yield call(handleLanguageValidation, payload.formId);
}

function* handleLanguageValidation(formId) {
  const languageCode = yield select(selectLanguageCode);
  const isLanguageStateValid = yield call(validateLanguageState, languageCode);

  if (!isLanguageStateValid) {
    // TODO change API of display modal to toggle destroy color/flag
    yield put(userChangedUnsupportedLanguageAnalytics(languageCode));
    return yield put(displayModal("province.frenchNotAvailable", true));
  }

  if (formId === LANGUAGE_FORM_ID) {
    yield put(userChangedLanguageAnalytics(languageCode));
  }

  return yield call(handleFormValidation, formId);
}

export function* handleFormValidation(formId, shouldUpdate = true) {
  if (formId === RESIDENCE_FORM_ID) {
    return yield call(handleResidenceFormValidation, shouldUpdate);
  }

  if (formId === DOB_FORM_ID) {
    return yield call(handleDOBFormValidation, shouldUpdate);
  }

  return yield call(updateAboutYouState, formId);
}

function* handleResidenceFormValidation(shouldUpdate = true) {
  const languageCode = yield select(selectLanguageCode);
  const isFrenchSelected = yield select(selectIsFrenchSelected);
  const isFrenchAvailable = yield select(
    selectFormIsFrenchAvailableInSelectedProvince,
  );

  if (isFrenchSelected && !isFrenchAvailable) {
    // TODO change API of display modal to toggle destroy color/flag
    yield put(userChangedUnsupportedLanguageAnalytics(languageCode));
    return yield put(displayModal("province.frenchNotAvailable", true));
  }

  if (shouldUpdate) {
    return yield call(updateAboutYouState, RESIDENCE_FORM_ID);
  }
  return null;
}

function* handleDOBFormValidation(shouldUpdate = true) {
  const isAgeOfMajority = yield select(selectProvincialAgeOfMajority);
  const formValues = yield select(getFormValues(DOB_FORM_ID));

  if (!isAgeOfMajority) {
    return yield call(handleAOMErrorValidation, {
      formValues,
      fieldId: DOB_FIELD_ID,
    });
  }

  if (shouldUpdate) {
    return yield call(updateAboutYouState, DOB_FORM_ID);
  }
  return null;
}

export function* handleAOMErrorValidation({ formValues, fieldId }) {
  const errorTranslations = yield select(selectTranslations(["errors"]));
  yield call(handleFormError, {
    errors: [
      {
        field: fieldId,
        detail: errorTranslations["dob.ageOfMajority"],
      },
    ],
    formValues,
    formId: DOB_FORM_ID,
  });
}

export function* updateAboutYouState(formId) {
  return yield call(submitFormRequest, {
    apiCall: submitUserRequest,
    formId,
  });
}

function* handleProvinceChange({ meta }) {
  const { form, field } = meta;
  // when province is updated, clear residence
  if (form === RESIDENCE_FORM_ID && field === PROVINCE_FIELD_ID) {
    yield call(clearFormValue, RESIDENCE_FORM_ID, RESIDENCE_FIELD_ID);
  }
}

export function* validateLanguageState(languageCode) {
  const isFrenchAvailable = yield select(
    selectIsFrenchAvailableInSelectedProvince,
  );
  if (languageCode === "fr" && !isFrenchAvailable) {
    return false;
  }

  return true;
}

export function* handleLanguageChange({ payload }) {
  const { languageCode } = payload;
  const isAuthenticated = yield select(selectIsLoggedIn);
  const isLanguageStateValid = yield call(validateLanguageState, languageCode);

  if (!isLanguageStateValid) {
    // TODO change API of display modal to toggle destroy color/flag
    yield put(userChangedUnsupportedLanguageAnalytics(languageCode));
    return yield put(displayModal("province.frenchNotAvailable", true));
  }

  // Make request to save language on BE if you are logged in and want to change your language
  // unauthenticated users would be claim invite / sign up / login / reset/forgot pw
  if (isAuthenticated) {
    yield call(submitFormRequest, {
      apiCall: submitLanguage,
      values: {
        lang: languageCode,
      },
    });
  }
  yield put(setLanguageCode(languageCode));
  yield put(userChangedLanguageAnalytics(languageCode));
  return yield call(
    clearFormValue,
    LANGUAGE_FORM_ID,
    LANGUAGE_FIELD_ID,
    languageCode,
  );
}

function* clearFormValue(formId, fieldId, defaultValue = "") {
  yield put(change(formId, fieldId, defaultValue));
  yield put(untouch(formId, fieldId));
}

function* handleSubmitPeopleQuantityIntent() {
  yield call(submitFormRequest, {
    apiCall: submitUserRequest,
    formId: PEOPLE_QUANTITY_INTENT_FORM_ID,
  });
}

export function* watchFetchResidence() {
  yield takeEvery(FETCH_RESIDENCE_TYPE, fetchResidence);
}

export function* watchFetchDOB() {
  yield takeEvery(FETCH_DOB_TYPE, fetchDOB);
}

export function* watchSubmitAboutYou() {
  yield takeEvery(SUBMIT_ABOUT_YOU_TYPE, submitAboutYou);
}

export function* watchProvinceChange() {
  yield takeEvery(reduxFormActionTypes.CHANGE, handleProvinceChange);
}

export function* watchLanguageChange() {
  yield takeEvery(VALIDATE_LANGUAGE_CODE_TYPE, handleLanguageChange);
}

export function* watchSubmitPeopleQuantityIntent() {
  yield takeEvery(
    SUBMIT_PEOPLE_QUANTITY_INTENT_TYPE,
    handleSubmitPeopleQuantityIntent,
  );
}
