import R from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm, FieldArray, getFormValues } from "redux-form";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Form } from "../../ui/Form";
import {
  CHILDREN_GUARDIANS_FORM_ID,
  CHILDREN_GUARDIANS_FIELD_ID,
} from "../../../constants/forms";
import { ErrorMessage } from "../../ui/Error";
import { displayTooltipModal } from "../../../actions/tooltip-modal";
import H2WithUnderlineLink from "../../ui/H2WithUnderlineLink";
import { SecondaryButton, SmallTextButton } from "../../ui/Button";
import { Body } from "../../ui/Typography";
import DropdownContactSelect from "../../ui/inputs/DropdownContactSelect";
import {
  selectAllContacts,
  selectSpouseContact,
} from "../../../selectors/contacts";
import {
  openModalContactForContactable,
  removeSecondaryContact,
  selectContactFromDropdown,
} from "../../../actions/contacts";
import { CHILDREN_GUARDIAN_CONTACTABLE_MODEL } from "../../../constants/contacts";
import { removeChildrenGuardian } from "../../../actions/children-guardians";
import AddCoFieldButton from "../../ui/AddCoFieldButton";
import { MODAL_ADD_CONTACT_CHILDREN_GUARDIAN } from "../../../constants/modal";

const getChildrenGuardiansCount = (formData) => {
  if (!formData) {
    return 0;
  }
  return formData[CHILDREN_GUARDIANS_FIELD_ID].filter(
    (field) => field._destroy !== true,
  ).length;
};

const getChildrenGuardianLevelTranslation = (
  appointeeLevelIndexs,
  index = 0,
  translations,
  isCoGuardian = false,
) => {
  const level = R.pathOr(null, [index, "level"])(appointeeLevelIndexs);
  if (level === null) {
    return "";
  }
  const guardianTitleTranslation = isCoGuardian
    ? translations.coGuardian
    : translations.guardian;
  return `${translations.levels[level]} ${guardianTitleTranslation}`;
};

const GuardianDropdownFieldArray = ({
  fields,
  translations,
  handleChange,
  appointeeLevelIndexs,
  isTabletDown,
}) => {
  // isWaitingSelection state is used to determine if the "add more guardians"
  // button should be displayed or not. It is set to true when a new contact
  // is being selected and set to false when the contact is added to the form
  const [isWaitingSelection, setIsWaitingSelection] = useState(false);
  const [displayCoFieldDropdown, setDisplayCoFieldDropdown] = useState({});
  const dispatch = useDispatch();
  const contacts = useSelector(selectAllContacts);
  const spouseContact = R.pathOr({}, "")(useSelector(selectSpouseContact))[0];
  const spouseContactId = spouseContact?.contact?.id;
  
  useEffect(() => {
    // when a new contact is added this will remove the waiting state
    setIsWaitingSelection(false);
  }, [contacts]);

  const hasAppointeeTitle = R.has("total")(appointeeLevelIndexs);
  const allFields = fields.getAll() || [];
  const allSelectedContacts = allFields.reduce((arr, field) => {
    if (field.secondaryContact?.id) {
      return [...arr, field.contact?.id, field.secondaryContact?.id];
    }
    if (field.contact?.id) {
      return [...arr, field.contact?.id];
    }
    return arr;
  }, []);
  const hasSelectedContacts = allSelectedContacts.length > 0;

  const addButtonLabel =
    allFields.filter((field) => field._destroy !== true).length === 0
      ? translations.button.addLabel
      : translations.button.addMoreLabel;
  // if there are no fields yet, adds an empty new field to be
  // displayed when the form is rendered for the first time
  if (fields.length === 0) {
    fields.push({});
  }

  const handleAddMoreFields = () => {
    setIsWaitingSelection(true);
    fields.push({});
  };

  let firstFieldFlag = true;

  return (
    <Box>
      {fields.map((fieldId, fieldIndex) => {
        const isFirstField = firstFieldFlag;
        firstFieldFlag = false;

        const field = fields.get(fieldIndex);
        if (field && field._destroy === true) {
          return null;
        }
        const fieldGuardianId = field?.id;
        const fieldContactId = field?.contact?.id;
        const fieldSecondaryContactId = field?.secondaryContact?.id;
        const filteredContacts = contacts.filter((person) => {
          const contactId = person.contact.id;
          const keepSelectedContact = fieldContactId
            ? contactId === fieldContactId ||
              contactId === fieldSecondaryContactId
            : false;
          return (
            keepSelectedContact || !allSelectedContacts.includes(contactId)
          );
        });

        const childrenGuardianLevelText = getChildrenGuardianLevelTranslation(
          appointeeLevelIndexs,
          fieldIndex,
          translations,
        );
        const fieldLabel = hasAppointeeTitle ? childrenGuardianLevelText : "";

        const coChildrenGuardianLevelText = getChildrenGuardianLevelTranslation(
          appointeeLevelIndexs,
          fieldIndex,
          translations,
          true,
        );
        const secondaryFieldLabel = hasAppointeeTitle
          ? coChildrenGuardianLevelText
          : "";

        const shouldShowCoField =
          fieldContactId &&
          (displayCoFieldDropdown[fieldIndex] || fieldSecondaryContactId);

        const handleAddCoField = (dropdownIndex) => {
          setDisplayCoFieldDropdown((prevState) => ({
            ...prevState,
            [dropdownIndex]: true,
          }));
        };

        const handleAddNewContact = (isSecondaryContact) => {
          dispatch(
            openModalContactForContactable(
              null,
              MODAL_ADD_CONTACT_CHILDREN_GUARDIAN,
              fieldGuardianId,
              isSecondaryContact,
            ),
          );
        };

        const handleSelectContact = (contactId, isSecondaryContact = false) => {
          dispatch(
            selectContactFromDropdown(
              contactId,
              fieldGuardianId,
              CHILDREN_GUARDIAN_CONTACTABLE_MODEL,
              isSecondaryContact,
            ),
          );
          setIsWaitingSelection(false);
        };

        const handleRemoveField = () => {
          if (field.id && fieldContactId) {
            dispatch(removeChildrenGuardian(field));
          } else {
            handleChange(`${fieldId}._destroy`, true);
          }
          return setIsWaitingSelection(false);
        };

        const handleRemoveCoField = (dropdownIndex) => {
          if (fieldSecondaryContactId) {
            dispatch(
              removeSecondaryContact(
                fieldContactId,
                fieldGuardianId,
                CHILDREN_GUARDIAN_CONTACTABLE_MODEL,
              ),
            );
          }
          setDisplayCoFieldDropdown((prevState) => ({
            ...prevState,
            [dropdownIndex]: false,
          }));
        };

        return (
          <Box
            mb={isWaitingSelection ? 1 : 2}
            key={`${fieldIndex + 1}-${fieldId}`}
          >
            <DropdownContactSelect
              id={fieldId}
              fieldId={fieldId}
              label={fieldLabel}
              contacts={filteredContacts.filter(
                (c) => c.contact.id !== fieldSecondaryContactId,
              )}
              disabledContactIds={[spouseContactId]}
              selectedContactId={fieldContactId}
              onSelect={handleSelectContact}
              onAddNew={handleAddNewContact}
            />
            <Box
              mt={hasSelectedContacts && 1}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              {fieldContactId && !shouldShowCoField && (
                <AddCoFieldButton
                  id={`co-${fieldId}`}
                  text={translations.addCoGuardian}
                  onClick={() => handleAddCoField(fieldIndex)}
                  hasMargin={!isFirstField}
                  index={fieldIndex}
                />
              )}
              {!isFirstField && (
                <SmallTextButton
                  onClick={handleRemoveField}
                  text={translations.remove}
                  className={`qa-remove-people-${fieldIndex}`}
                />
              )}
            </Box>
            {shouldShowCoField && (
              <Box>
                <DropdownContactSelect
                  id={`co-${fieldId}`}
                  fieldId={`co-${fieldId}`}
                  label={secondaryFieldLabel}
                  contacts={filteredContacts.filter(
                    (c) => c.contact.id !== fieldContactId,
                  )}
                  disabledContactIds={[spouseContactId]}
                  selectedContactId={fieldSecondaryContactId}
                  onSelect={(contactId) => handleSelectContact(contactId, true)}
                  onAddNew={() => handleAddNewContact(true)}
                />
                <Box
                  mt={1}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <SmallTextButton
                    onClick={() => handleRemoveCoField(fieldIndex)}
                    text={`${translations.remove} ${translations.coGuardian}`}
                    className={`qa-remove-co-field-${fieldIndex}`}
                  />
                </Box>
              </Box>
            )}
            {isFirstField && hasSelectedContacts && (
              <Box my={2}>
                <Body
                  dangerouslySetInnerHTML={{
                    __html: translations.subQuestionOptionalText,
                  }}
                />
              </Box>
            )}
          </Box>
        );
      })}
      {hasSelectedContacts && !isWaitingSelection && (
        <Box mt={2} mb={isTabletDown && 2}>
          <SecondaryButton
            text={addButtonLabel}
            fullWidth
            displayPlusIcon
            className="qa-add"
            type="button"
            onClick={handleAddMoreFields}
          />
        </Box>
      )}
    </Box>
  );
};

const ChildrenGuardiansForm = ({
  error,
  handleSubmit,
  backLink,
  change,
  appointeeLevelIndexs,
  isLoading,
  translations,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));

  const formData = useSelector(getFormValues(CHILDREN_GUARDIANS_FORM_ID));
  const hasNoChildrenGuardians = getChildrenGuardiansCount(formData) === 0;
  const spouseContact = R.pathOr({}, "")(useSelector(selectSpouseContact))[0];
  const hasSpouse = Boolean(spouseContact)
  const { firstName, lastName } = hasSpouse && spouseContact?.contact

  const pageTitle = hasSpouse ? translations.pageTitleWithSpouse.replace("{FIRST_NAME}", firstName).replace("{LAST_NAME}", lastName) : translations.pageTitle

  const headerWithLink = (
    <H2WithUnderlineLink
      text={pageTitle}
      underlineText={translations.pageTitleUnderlineLink}
      onClick={() =>
        dispatch(displayTooltipModal(translations.pageTitleModalKey))
      }
    />
  );

  return (
    <Form
      onSubmit={handleSubmit}
      backLink={backLink}
      translations={translations}
      isLoading={isLoading}
      reverseWrap={isTabletDown}
      customHeaderComponent={headerWithLink}
      disabled={hasNoChildrenGuardians}
    >
      <FieldArray
        handleChange={change}
        name={CHILDREN_GUARDIANS_FIELD_ID}
        rerenderOnEveryChange
        component={GuardianDropdownFieldArray}
        canAddMore={appointeeLevelIndexs.total < 9}
        appointeeLevelIndexs={appointeeLevelIndexs}
        translations={translations}
        isTabletDown={isTabletDown}
      />
      <Box mb={error && 2}>
        <ErrorMessage error={error} />
      </Box>
    </Form>
  );
};

export default reduxForm({
  form: CHILDREN_GUARDIANS_FORM_ID,
})(ChildrenGuardiansForm);
