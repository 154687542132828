import { createStructuredSelector } from "reselect";
import { selectTranslations } from "./translations";

const selectThankYouPageTranslations = selectTranslations([
  "global",
  "thankYou",
]);

export const thankYouPageProps = createStructuredSelector({
  translations: selectThankYouPageTranslations,
});
