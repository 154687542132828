import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";

import { selectTranslations } from "./translations";
import {
  selectFirstName,
  selectLastName,
  selectLanguageCode,
} from "./about-you";
import { selectChildren } from "./children";
import { selectPets } from "./pets";
import { selectProvinceCode } from "./provinces";
import {
  EMAIL_PREVIEW_LEARN_URLS_BY_ROLE_EN_ROC,
  EMAIL_PREVIEW_LEARN_URLS_BY_ROLE_EN_QC,
  EMAIL_PREVIEW_LEARN_URLS_BY_ROLE_FR_QC,
} from "../constants/routes";

export const selectIsModalVisible = R.path(["emailPreviewModal", "visible"]);
export const selectEmailPreviewModalKey = R.path(["emailPreviewModal", "key"]);
const selectEmailPreviewAppointeeFirstName = R.path([
  "emailPreviewModal",
  "appointeeFirstName",
]);
const selectEmailPreviewAppointeeLevel = R.path([
  "emailPreviewModal",
  "appointeeLevel",
]);

const replacePreviewEmailTranslationVariables = (
  stringsToTranslate = {},
  translations,
  userFirstName,
  userFirstLastName,
  appointeeFirstName,
  appointeeLevel,
  usePluralChildren,
  usePluralPets,
) =>
  Object.keys(stringsToTranslate).reduce((allStrings, translationStrKey) => {
    let resultString = stringsToTranslate[translationStrKey];

    if (!resultString) {
      return allStrings;
    }

    const appointeeLevelString = translations.levels[appointeeLevel];

    resultString = resultString
      .replace("USER_FIRST_LAST_NAME", userFirstLastName)
      .replace("APPOINTEE_FIRST_NAME", appointeeFirstName)
      .replace("USER_FIRST_NAME", userFirstName)
      .replace("LEVEL", appointeeLevelString)
      .replace(
        "CHILD",
        usePluralChildren ? translations.children : translations.child,
      )
      .replace("PET", usePluralPets ? translations.pets : translations.pet);

    // eslint-disable-next-line no-param-reassign
    allStrings[translationStrKey] = resultString;

    return allStrings;
  }, {});

const selectEmailPreviewLearnMoreLink = createSelector(
  selectLanguageCode,
  selectProvinceCode,
  selectEmailPreviewModalKey,
  (languageCode, provinceCode, emailPreviewModalKey) => {
    if (provinceCode === "QC" && languageCode === "fr") {
      return EMAIL_PREVIEW_LEARN_URLS_BY_ROLE_FR_QC[emailPreviewModalKey];
    }

    if (provinceCode === "QC") {
      return EMAIL_PREVIEW_LEARN_URLS_BY_ROLE_EN_QC[emailPreviewModalKey];
    }

    // Use ROC links if user is not in QC
    return EMAIL_PREVIEW_LEARN_URLS_BY_ROLE_EN_ROC[emailPreviewModalKey];
  },
);

export const selectEmailPreviewModalTranslations = createSelector(
  selectTranslations(["global", "emailPreview", "appointees"]),
  selectEmailPreviewModalKey,
  selectFirstName,
  selectLastName,
  selectEmailPreviewAppointeeFirstName,
  selectEmailPreviewAppointeeLevel,
  selectChildren,
  selectPets,
  selectEmailPreviewLearnMoreLink,
  (
    translations,
    key,
    firstName,
    lastName,
    appointeeFirstName,
    appointeeLevel,
    children,
    pets,
    learnMoreLink,
  ) => {
    const userFirstLastName = `${firstName} ${lastName}`;
    const usePluralChildren = children.length > 1;
    const usePluralPets = pets.length > 1;

    const stringsWithReplacements = {
      subject: translations.subject,
      whatNextBody: translations.whatNextBody,
      whyReceivingBodyPt1: translations.whyReceivingBodyPt1,
      whyReceivingBodyPt2: translations.whyReceivingBodyPt2,
      roleIntro: translations.roleIntros[key],
    };

    const interpolatedTranslations = replacePreviewEmailTranslationVariables(
      stringsWithReplacements,
      translations,
      firstName,
      userFirstLastName,
      appointeeFirstName,
      appointeeLevel,
      usePluralChildren,
      usePluralPets,
    );

    return {
      learnMoreLink,
      ...translations,
      ...interpolatedTranslations,
    };
  },
);

export const modalProps = createStructuredSelector({
  isVisible: selectIsModalVisible,
  translations: selectEmailPreviewModalTranslations,
  modalKey: selectEmailPreviewModalKey,
});
