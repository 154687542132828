import React from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { SecondaryButton } from "../ui/Button";
import PremiumProductIcon from "../ui/icons/PremiumProductIcon";
import { H2, H4, SmallBody, SmallExternalLink } from "../ui/Typography";
import FlashMessage from "../ui/FlashMessage";

import DocumentCard from "./DocumentCard";
import { WILLFUL_BILL_21_URL } from "../../constants/routes";

const LegalDocumentsSection = ({
  upgradePlan,
  analyticsClickExternalLink,
  downloadDocuments,
  isTablet,
  translations,
  isDocumentsAvailable,
  isNotarialWill,
  isUpgradeLoading,
  isQuebecWill,
  shouldShowBill21Content,
  documentCardCopy,
  isUpgradableUser,
}) => {
  // BC users get a special Bill 21 page warning with a link at the end
  const pageWarningContent = isNotarialWill ? (
    translations.notarialPageWarning
  ) : (
    <>
      {translations.legalDocumentsWarning}
      {shouldShowBill21Content && (
        <>
          {" "}
          <SmallExternalLink
            href={WILLFUL_BILL_21_URL}
            text={translations.button.learnMoreExternal}
            onClick={() => analyticsClickExternalLink(WILLFUL_BILL_21_URL)}
          />
        </>
      )}
    </>
  );

  return (
    <Box display="flex" flexDirection="column" mt={4.5}>
      <Grid container justifyContent={isTablet ? "center" : ""}>
        <Grid item lg={6} md={8} sm={12}>
          <H2 id="legal-documents" align="left">
            {translations.legalDocumentsHeader}
          </H2>
          {isDocumentsAvailable && (
            <Box mt={2} mb={isTablet ? 1 : 0}>
              <FlashMessage type="warning" text={pageWarningContent} />
            </Box>
          )}
        </Grid>
      </Grid>
      {documentCardCopy.userDocuments && (
        <Box mt={2}>
          <Grid container spacing={2}>
            {documentCardCopy.userDocuments.map(
              ({
                key,
                documentName,
                documentInfo,
                upgradeInfo,
                documentLockCopy,
                isUpgradable,
              }) => {
                return (
                  <>
                    {isTablet && <Grid md={3} item />}
                    <Grid sm={12} md={6} lg={4} item key={key}>
                      <DocumentCard
                        documentName={documentName}
                        documentInfo={documentInfo}
                        upgradeInfo={upgradeInfo}
                        documentLockCopy={documentLockCopy}
                        translations={translations}
                        onDownload={downloadDocuments}
                        isTablet={isTablet}
                        isUpgradable={isUpgradable}
                        documentKey={key}
                        upgradePlan={upgradePlan}
                        isUpgradeLoading={isUpgradeLoading}
                        isUpgradableUser={isUpgradableUser}
                      />
                    </Grid>
                    {isTablet && <Grid md={3} item />}
                  </>
                );
              },
            )}
            {isQuebecWill && isUpgradableUser && (
              <>
                {isTablet && <Grid md={3} item />}
                <Grid sm={12} md={6} lg={4} item>
                  <Box
                    bgcolor="willfulBlue.main"
                    borderRadius={8}
                    border={1}
                    margin="0 auto"
                    borderColor="border.main"
                  >
                    <Box m={1.5}>
                      <Box mb={2} display="flex" justifyContent="center">
                        <PremiumProductIcon width={120} height={120} />
                      </Box>
                      <Box mb={0.5}>
                        <H4 align="left" color="white">
                          {translations.notarialUpgradeTitle}
                        </H4>
                      </Box>
                      <Box mb={2}>
                        <SmallBody color="white">
                          {translations.notarialUpgradeDescription}
                        </SmallBody>
                      </Box>
                      <SecondaryButton
                        onClick={() =>
                          upgradePlan({ quantity: 1, previousQuantity: 1 })
                        }
                        secondary
                        fullWidth
                        isLoading={isUpgradeLoading}
                        loadingLabel={translations.button.loadingLabel}
                        text={translations.button.upgradeNotarial}
                      />
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      )}

      {documentCardCopy.spouseDocuments && (
        <Box mt={4.5}>
          <Grid container justifyContent={isTablet ? "center" : ""}>
            <Grid item lg={6} md={8} sm={12}>
              <Box mb={2}>
                <H2 align="left">{translations.spouseDocumentsTitle}</H2>
              </Box>
            </Grid>
          </Grid>
          {documentCardCopy.spouseDocuments && (
            <Box mt={2}>
              <Grid container spacing={2}>
                {documentCardCopy.spouseDocuments.map(
                  ({ key, documentName, documentInfo, documentLockCopy }) => {
                    return (
                      <>
                        {isTablet && <Grid md={3} item />}
                        <Grid sm={12} md={6} lg={4} item key={key}>
                          <DocumentCard
                            documentName={documentName}
                            documentInfo={documentInfo}
                            documentLockCopy={documentLockCopy}
                            translations={translations}
                            onDownload={downloadDocuments}
                            isTablet={isTablet}
                            documentKey={key}
                            upgradePlan={upgradePlan}
                            isUpgradeLoading={isUpgradeLoading}
                            isSpouse
                          />
                        </Grid>
                        {isTablet && <Grid md={3} item />}
                      </>
                    );
                  },
                )}
                {isQuebecWill && isUpgradableUser && (
                  <>
                    {isTablet && <Grid md={3} item />}
                    <Grid sm={12} md={6} lg={4} item>
                      <Box
                        bgcolor="willfulBlue.main"
                        borderRadius={8}
                        border={1}
                        margin="0 auto"
                        borderColor="border.main"
                      >
                        <Box m={1.5}>
                          <Box mb={2} display="flex" justifyContent="center">
                            <PremiumProductIcon width={120} height={120} />
                          </Box>
                          <Box mb={0.5}>
                            <H4 align="left" color="white">
                              {translations.notarialUpgradeTitle}
                            </H4>
                          </Box>
                          <Box mb={2}>
                            <SmallBody color="white">
                              {translations.notarialUpgradeDescription}
                            </SmallBody>
                          </Box>
                          <SecondaryButton
                            onClick={() =>
                              upgradePlan({ quantity: 1, previousQuantity: 1 })
                            }
                            secondary
                            fullWidth
                            isLoading={isUpgradeLoading}
                            loadingLabel={translations.button.loadingLabel}
                            text={translations.button.upgradeNotarial}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default LegalDocumentsSection;
