import React, { useEffect } from "react";
import { connect } from "react-redux";

import InheritanceForm from "../../../components/forms/your-estate/InheritanceForm";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { inheritancePageProps } from "../../../selectors/allocations";
import { BACKUPS_PATH } from "../../../constants/routes";
import {
  fetchInheritance,
  updateInheritance,
  removeAgeMilestone,
  addAgeMilestone,
} from "../../../actions/allocations";

const InheritancePage = ({
  fetchInheritance,
  updateInheritance,
  removeAgeMilestone,
  addAgeMilestone,
  hasLoaded,
  ...restOfProps
}) => {
  useEffect(() => {
    fetchInheritance();
  }, [fetchInheritance]);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <InheritanceForm
      backLink={BACKUPS_PATH}
      onSubmit={() => updateInheritance()}
      handleRemove={removeAgeMilestone}
      handleAddMore={addAgeMilestone}
      {...restOfProps}
    />
  );
};

export default connect(inheritancePageProps, {
  fetchInheritance,
  updateInheritance,
  removeAgeMilestone,
  addAgeMilestone,
})(InheritancePage);
