export const closeToast = () => ({
  type: CLOSE_TOAST_TYPE,
});

export const displayToast = (key) => ({
  type: DISPLAY_TOAST_TYPE,
  payload: {
    key,
  },
});

export const DISPLAY_TOAST_TYPE = "DISPLAY_TOAST";
export const CLOSE_TOAST_TYPE = "CLOSE_TOAST";
