import { takeEvery } from "redux-saga/effects";
import { DISPLAY_CHAT_TYPE } from "../actions/customer-service";

function* displayChat() {
  if (window.zE) {
    yield window.zE("messenger", "open");
  } else {
    console.warn("Zendesk script has not been loaded");
  }
}

export function* watchDisplayChat() {
  yield takeEvery(DISPLAY_CHAT_TYPE, displayChat);
}
