import React from "react";
import { Field } from "redux-form";

import TextInput from "./TextInput";

import { useValidateConfirmPasswordMemo } from "./validations";
import { PASSWORD_CONF_FIELD_ID } from "../../../constants/forms";

const PasswordField = ({ translations }) => (
  <Field
    component={TextInput}
    name={PASSWORD_CONF_FIELD_ID}
    label={translations.label.confirmPassword}
    placeholder={translations.label.confirmPasswordPlaceholder}
    type="password"
    validate={useValidateConfirmPasswordMemo(translations)}
  />
);

export default PasswordField;
