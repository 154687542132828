import React from "react";
import Box from "@material-ui/core/Box";
import { SmallBody } from "../../ui/Typography";

export const OutOfProvince = ({ translations }) => {
  return (
    <Box>
      <Box mb={1}>
        <SmallBody>{translations.paragraph1}</SmallBody>
      </Box>
      <Box mb={1}>
        <SmallBody>{translations.paragraph2}</SmallBody>
      </Box>
      <Box mb={1}>
        <SmallBody>{translations.paragraph3}</SmallBody>
      </Box>
      <Box>
        <SmallBody>{translations.paragraph4}</SmallBody>
      </Box>
    </Box>
  );
};
