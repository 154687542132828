import React from "react";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";

import { PageLayout } from "../../components/ui/PageLayout";
import { resetSuccessfulPageProps } from "../../selectors/auth";
import { ButtonLink } from "../../components/ui/Button";
import {
  SmallBody,
  Body,
  H2,
  SmallTextLink,
} from "../../components/ui/Typography";
import { SIGNUP_PATH, LOGIN_PATH } from "../../constants/routes";

const ResetSuccessfulPage = ({ translations }) => {
  return (
    <PageLayout display="flex" justifyContent="center">
      <Box
        maxWidth={500}
        px={2}
        py={2}
        bgcolor="willfulWhite.main"
        borderRadius={0.2}
        border={1}
        borderColor="border.main"
      >
        <H2>{translations.pageTitle}</H2>
        <Body align="center">{translations.description}</Body>
        <ButtonLink
          text={translations.button.submit}
          className="qa-submit"
          fullWidth
          displayArrowRight
          to={LOGIN_PATH}
        />
        <Box mt={1} display="flex" justifyContent="center">
          <SmallBody>
            {translations.accountMissingText}{" "}
            <SmallTextLink
              to={SIGNUP_PATH}
              text={translations.signupLinkText}
            />
          </SmallBody>
        </Box>
      </Box>
    </PageLayout>
  );
};

export default connect(resetSuccessfulPageProps)(ResetSuccessfulPage);
