import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";
import { selectHasSpouse, selectSpouseFullName } from "./spouse";
import { selectFullName } from "./about-you";
import { selectAppointeeLevelIndexs } from "./utils/form-appointees";
import { selectIsFieldComplete } from "./utils/progress";

import {
  CHILDREN_FORM_ID,
  CHILDREN_FIELD_ID,
  CHILDREN_GUARDIANS_FORM_ID,
  CHILDREN_GUARDIANS_FIELD_ID,
  PARENT_USER_VALUE,
  PARENT_NOT_USER_VALUE,
  PARENT_SPOUSE_VALUE,
  PARENT_NOT_SPOUSE_VALUE,
  NUM_CHILDREN_FIELD_ID,
  NUM_CHILDREN_ZERO,
  PARENT_NOT_APPLICABLE_VALUE,
  FIRST_NAME_FIELD_ID,
  MIDDLE_NAME_FIELD_ID,
  LAST_NAME_FIELD_ID,
} from "../constants/forms";
import { selectTranslations } from "./translations";
import { MARITAL_STATUS_PATH, SPOUSE_PATH } from "../constants/routes";
import { buildFullName } from "../utilities/name";

const selectYourFamilyData = R.pathOr({}, ["yourFamily"]);
export const selectHasChildren = R.path(["yourFamily", "needsChildren"]);

export const selectChildren = R.path(["yourFamily", CHILDREN_FIELD_ID]);
export const selectChildrenForm = createSelector(
  selectChildren,
  (children) => ({
    [CHILDREN_FIELD_ID]: children,
  }),
);
export const selectNumberOfChildren = createSelector(
  selectYourFamilyData,
  R.prop(NUM_CHILDREN_FIELD_ID),
);

export const selectNumberOfChildrenForm = createSelector(
  selectNumberOfChildren,
  (NumberOfChildren) => ({ [NUM_CHILDREN_FIELD_ID]: NumberOfChildren }),
);

export const selectNeedsChildrenGuardians = R.path([
  "yourFamily",
  "hasMinorChildren",
]);

export const selectIsChildrenComplete = createSelector(
  selectNumberOfChildren,
  selectNeedsChildrenGuardians,
  selectIsFieldComplete(selectYourFamilyData, CHILDREN_FIELD_ID),
  selectIsFieldComplete(selectYourFamilyData, CHILDREN_GUARDIANS_FIELD_ID),
  (
    numberOfChildren,
    needsChildrenGuardians,
    isChildrenComplete,
    isGuardiansComplete,
  ) => {
    if (!numberOfChildren) {
      return false;
    }

    if (numberOfChildren === NUM_CHILDREN_ZERO) {
      return true;
    }

    return needsChildrenGuardians
      ? isChildrenComplete && isGuardiansComplete
      : isChildrenComplete;
  },
);

export const selectChildrenCurrentPage = createSelector(
  selectNumberOfChildren,
  selectNeedsChildrenGuardians,
  selectIsFieldComplete(selectYourFamilyData, NUM_CHILDREN_FIELD_ID),
  selectIsFieldComplete(selectYourFamilyData, CHILDREN_FIELD_ID),
  selectIsFieldComplete(selectYourFamilyData, CHILDREN_GUARDIANS_FIELD_ID),
  (
    numberOfChildren,
    needsChildrenGuardians,
    isNumChildrenComplete,
    isChildrenComplete,
    isChildrenGuardiansComplete,
  ) => {
    if (numberOfChildren === NUM_CHILDREN_ZERO) {
      return 1;
    }

    const allValues = needsChildrenGuardians
      ? [isNumChildrenComplete, isChildrenComplete, isChildrenGuardiansComplete]
      : [isNumChildrenComplete, isChildrenComplete];

    return allValues.reduce(
      (stepTotal, pageComplete) => (pageComplete ? stepTotal + 1 : stepTotal),
      0,
    );
  },
);

export const selectChildrenTotalPages = createSelector(
  selectNumberOfChildren,
  selectNeedsChildrenGuardians,
  (numberOfChildren, needsChildrenGuardians) => {
    if (!numberOfChildren || numberOfChildren === NUM_CHILDREN_ZERO) {
      return 1;
    }
    return needsChildrenGuardians ? 3 : 2;
  },
);

export const selectPrimaryDropdownOptions = createSelector(
  selectTranslations(["global", CHILDREN_FORM_ID]),
  selectFullName,
  (translations, userName) => [
    { label: userName, value: PARENT_USER_VALUE },
    { label: translations.anotherPerson, value: PARENT_NOT_USER_VALUE },
  ],
);

export const getSecondaryOptions = (translations) => {
  return [
    {
      label: translations.anotherPerson,
      value: PARENT_NOT_SPOUSE_VALUE,
    },
    {
      label: translations.notApplicable,
      value: PARENT_NOT_APPLICABLE_VALUE,
    },
  ];
};

export const selectSecondaryDropdownOptions = createSelector(
  selectTranslations(["global", CHILDREN_FORM_ID]),
  selectHasSpouse,
  selectSpouseFullName,
  (translations, hasSpouse, spouseName) => {
    if (hasSpouse) {
      return [
        {
          label: spouseName || translations.spouseNameMissing,
          value: PARENT_SPOUSE_VALUE,
        },
        ...getSecondaryOptions(translations),
      ];
    }
    return getSecondaryOptions(translations);
  },
);

const selectChildrenBackLink = createSelector(selectHasSpouse, (hasSpouse) =>
  hasSpouse ? SPOUSE_PATH : MARITAL_STATUS_PATH,
);

export const childrenPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(CHILDREN_FORM_ID),
  isLoading: selectIsLoadingById(CHILDREN_FORM_ID),
  primaryDropdownOptions: selectPrimaryDropdownOptions,
  secondaryDropdownOptions: selectSecondaryDropdownOptions,
  hasSpouse: selectHasSpouse,
  translations: selectTranslations(["global", CHILDREN_FORM_ID, "navigation"]),
  backLink: selectChildrenBackLink,
});

export const selectChildrenGuardians = R.path([
  "yourFamily",
  CHILDREN_GUARDIANS_FIELD_ID,
]);

export const selectChildrenGuardiansForm = createSelector(
  selectChildrenGuardians,
  (childrenGuardians) => ({ [CHILDREN_GUARDIANS_FIELD_ID]: childrenGuardians }),
);

export const selectChildrenGuardiansTranslations = createSelector(
  selectTranslations([
    "global",
    CHILDREN_GUARDIANS_FORM_ID,
    "appointees",
    "navigation",
  ]),
  (translations) => translations,
);

export const childrenGuardiansPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(CHILDREN_GUARDIANS_FORM_ID),
  isLoading: selectIsLoadingById(CHILDREN_GUARDIANS_FORM_ID),
  appointeeLevelIndexs: selectAppointeeLevelIndexs(CHILDREN_GUARDIANS_FORM_ID),
  translations: selectChildrenGuardiansTranslations,
});

export const selectLastSelectedChildData = R.path([
  "formUi",
  "lastSelectedChildData",
]);

export const childFullName = (childData) => {
  const fullName = R.props([
    FIRST_NAME_FIELD_ID,
    MIDDLE_NAME_FIELD_ID,
    LAST_NAME_FIELD_ID,
  ])(childData);
  const [firstName, middleName, lastName] = fullName;
  return buildFullName(firstName, middleName, lastName);
};
