import React from "react";

import SvgIcon from "./SvgIcon";

const PremiumProductIcon = ({ width, height }) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 120 120" fill="none">
      <path
        d="M43.2006 92.2883C38.858 94.8041 38.8206 98.8971 43.1632 101.413C47.5058 103.929 54.5438 103.929 58.8864 101.413C63.229 98.8971 63.2664 94.8041 58.9238 92.2883C54.5812 89.7724 47.5806 89.7724 43.2006 92.2883Z"
        fill="#2F455F"
      />
      <path
        d="M39.9807 89.0198L54.3562 97.3559L68.1701 105.354C71.9138 107.532 77.9784 107.532 81.6846 105.354L109.575 89.0198C110.165 88.6716 110.654 88.1749 110.994 87.5789C111.333 86.9828 111.512 86.3081 111.512 85.6215C111.512 84.9348 111.333 84.2601 110.994 83.6641C110.654 83.068 110.165 82.5713 109.575 82.2232L73.8604 61.3828L41.6653 80.1579L39.9807 81.1342C36.237 83.3121 36.237 86.8794 39.9807 89.0198Z"
        fill="#2F455F"
      />
      <path
        d="M36.8746 49.5933C36.8746 48.0161 37.8854 46.6268 39.0085 45.8758C39.7946 45.3876 61.0584 32.9585 64.3153 31.2312C64.9297 30.8765 65.6281 30.6949 66.3369 30.7055C68.4708 30.7806 71.5405 32.4704 72.6636 34.4981C72.9971 35.1042 73.1159 35.8057 73.0006 36.4882C72.6262 38.8164 71.2785 45.6505 71.2036 46.1011C68.5831 59.8445 70.118 57.1408 62.6681 61.4216C44.7736 71.7854 44.9983 72.0858 42.7521 71.6352C40.2064 71.1095 37.0992 69.0067 36.9495 66.228C36.8746 65.9276 36.8746 49.8561 36.8746 49.5933Z"
        fill="#3989E5"
      />
      <path
        d="M45.8592 50.6447C45.4265 50.9306 45.066 51.3135 44.8061 51.7632C44.5462 52.2128 44.3941 52.7169 44.3618 53.2357C44.3618 58.7931 44.3992 64.313 44.3992 69.8704C44.3992 70.8092 45.0731 71.2222 45.8967 70.734C53.1219 66.5284 60.3845 62.3604 67.6097 58.1547C69.0697 57.2911 69.2194 54.9254 72.7759 36.3381C72.7384 35.8124 72.0646 35.4369 71.241 35.925C62.7804 40.8441 54.3198 45.7256 45.8592 50.6447Z"
        fill="#004EB6"
      />
      <path
        d="M74.4615 87.8965C74.4615 88.272 74.8733 101.527 74.8733 101.527C74.9463 101.799 75.1073 102.039 75.3309 102.208C75.5546 102.378 75.8281 102.469 76.1087 102.466C77.1569 102.466 77.1569 101.752 77.1569 101.752L77.4189 88.7226L74.4615 87.8965Z"
        fill="#ABE0F6"
      />
      <path
        d="M49.7153 71.5615C49.6405 71.8995 45.8594 84.629 45.8594 84.629C45.7189 84.8793 45.4984 85.0747 45.2334 85.1837C44.9685 85.2928 44.6747 85.309 44.3994 85.2298C43.3886 84.9669 43.5758 84.2535 43.5758 84.2535L46.6081 71.5615H49.7153Z"
        fill="#ABE0F6"
      />
      <path
        d="M100.704 75.0518C100.779 75.3897 104.56 88.1192 104.56 88.1192C104.701 88.3695 104.921 88.5649 105.186 88.674C105.451 88.783 105.745 88.7992 106.02 88.72C107.031 88.4572 106.844 87.7437 106.844 87.7437L103.811 75.0518H100.704Z"
        fill="#ABE0F6"
      />
      <path
        d="M45.786 68.1424C45.8234 65.1759 46.0106 63.4486 50.166 60.5948C51.1394 59.9189 70.6062 48.9543 71.617 48.391C72.2246 48.1081 72.8664 47.9061 73.5262 47.7902C73.5262 47.7902 77.5319 47.1894 98.2342 60.5573C99.5819 61.4209 104.224 64.3874 105.048 65.7768C106.582 67.9922 104.823 74.2631 99.5819 78.5814C95.6511 81.8107 86.5915 87.293 82.7356 89.133C79.0668 90.8603 78.4304 91.2733 77.3822 91.1231C73.4888 90.5974 55.3322 79.6703 50.5404 76.2908C46.8342 73.6623 45.786 73.0239 45.786 68.1424Z"
        fill="#004EB6"
      />
      <path
        d="M45.4867 65.7768C45.7113 66.678 45.6365 66.9408 47.5083 68.5179C52.8616 72.8738 68.2479 81.8107 74.5372 84.289C75.9972 84.8898 77.008 85.1151 77.2326 84.8523L77.2701 84.8147C77.0894 84.1993 76.9518 83.5719 76.8583 82.9372C76.6711 81.3976 76.746 79.8205 77.008 79.0695C78.2808 75.3896 81.6127 75.1643 95.3892 67.6918C96.5493 67.1497 97.5443 66.3072 98.2718 65.2511C99.0733 64.1354 99.611 62.8512 99.8441 61.496C101.604 48.9918 101.491 40.6181 105.497 35.7366C106.349 34.7116 107.375 33.847 108.529 33.1832C108.529 31.7939 78.9921 14.4081 77.0829 14.7461H77.0454C75.8543 15.4498 74.791 16.3513 73.9008 17.4121C69.7079 22.5565 70.0823 31.1931 68.3977 42.3455C68.1547 43.9508 67.4524 45.4509 66.3761 46.6637C65.5747 47.6061 64.5782 48.3621 63.4561 48.8792C58.4771 51.2073 46.9093 56.3892 45.4493 60.6699C45.0766 62.3532 45.0894 64.0992 45.4867 65.7768Z"
        fill="#3989E5"
      />
      <path
        d="M73.938 17.488L105.534 35.8125C105.932 35.3257 106.37 34.8735 106.844 34.4607L107.443 34.8737C107.443 34.8737 109.652 33.7097 110.738 33.1089C108.304 29.3539 82.436 14.1836 78.5052 14.1836H78.3554C77.8872 14.306 77.4438 14.5093 77.0452 14.7844H77.0077C75.8438 15.5109 74.8067 16.4243 73.938 17.488Z"
        fill="#71ADEE"
      />
      <path
        d="M45.4869 65.7771C45.7115 66.6783 45.6366 66.9412 47.5084 68.5183C52.8618 72.8741 68.2481 81.811 74.5374 84.2894C75.9974 84.8902 77.0082 85.1155 77.2328 84.8526L77.2702 84.8151C77.0896 84.1996 76.952 83.5722 76.8585 82.9375C76.6713 81.398 76.7461 79.8209 77.0082 79.0699C78.281 75.39 81.6129 75.1647 95.3894 67.6922C96.5495 67.15 97.5445 66.3075 98.272 65.2514L66.3763 46.6641C65.5748 47.6064 64.5784 48.3624 63.4563 48.8795C58.4773 51.2076 46.9095 56.3896 45.4495 60.6703C45.0768 62.3535 45.0896 64.0996 45.4869 65.7771Z"
        fill="#71ADEE"
      />
      <path
        d="M45.4866 65.78C45.7113 66.6812 45.6364 66.9816 47.5082 68.5212C52.8616 72.877 68.2479 81.814 74.5746 84.2923C76.072 84.8931 77.0454 85.1559 77.27 84.8555L77.3074 84.818C77.1051 84.2083 76.9671 83.579 76.8956 82.9405C76.671 81.4009 76.7833 79.8238 77.0454 79.0728C77.2771 78.3604 77.6603 77.707 78.1685 77.1577C77.8718 77.0716 77.5836 76.9585 77.3074 76.8198C70.494 74.1162 53.9098 64.5033 48.1072 59.772C47.665 59.4315 47.2517 59.0547 46.8718 58.6455C46.2844 59.218 45.825 59.9092 45.5241 60.6732C45.4866 60.7483 44.8128 62.6258 45.4866 65.78Z"
        fill="#3989E5"
      />
      <path
        d="M108.565 33.2582C101.378 37.4263 102.014 46.3256 99.8803 61.571C99.5059 64.162 97.5967 66.828 95.5377 67.7292C85.1304 72.2353 78.3545 75.2017 77.0068 79.1445C76.6698 80.1959 76.6324 82.8995 77.2688 84.8521C88.5745 79.3322 92.3556 77.5298 97.6341 74.1879C106.132 68.7431 106.507 65.4011 108.64 50.0431C109.913 41.0311 111.411 37.0132 110.924 33.6337C110.774 32.2443 109.726 32.6198 108.565 33.2582Z"
        fill="#71ADEE"
      />
      <path
        d="M78.3914 64.7248C78.3914 64.7248 79.1027 63.6359 81.7981 64.0489C84.4935 64.462 86.2905 63.2979 87.8253 63.6734C90.2213 64.2742 95.9864 62.3967 98.2326 62.1714C99.3183 62.0588 100.591 62.8098 100.891 62.9224C101.078 62.9975 101.04 62.5093 101.115 61.4955C101.149 60.9275 101.224 60.3628 101.34 59.8057C101.714 57.7029 101.976 53.8353 101.789 52.2206C101.602 50.6059 100.778 48.3905 101.228 46.2126C101.677 44.0347 101.677 41.5939 102.126 40.167C98.607 40.3547 101.302 41.3686 94.7885 40.5049C92.3551 40.2045 89.2479 41.1433 88.312 41.0306C87.2264 40.8804 84.7181 41.5564 83.7074 41.0306C82.8463 40.58 81.4237 40.5425 80.4504 40.6551C80.4504 40.6551 80.6376 43.5841 80.7125 44.26C80.7873 44.9359 79.1401 56.8393 79.1776 57.8531C79.2524 59.2049 78.0545 61.8334 78.3914 64.7248Z"
        fill="#004EB6"
      />
      <path
        d="M85.654 41.1808C87.8734 42.9179 89.6344 45.1742 90.7828 47.7521C91.6064 49.5921 91.6813 50.6811 92.8418 51.6574C94.5639 53.0843 97.8582 53.6851 99.7675 51.5447C101.078 50.0803 101.115 48.015 101.078 47.4142C101.072 47.0086 101.123 46.6043 101.228 46.2126C101.677 43.9971 101.677 41.5939 102.126 40.167C98.607 40.3547 101.302 41.3686 94.7885 40.5049C92.3551 40.2045 89.2479 41.1433 88.312 41.0306C87.8253 40.918 86.7023 41.1057 85.654 41.1808Z"
        fill="#0064DC"
      />
      <path
        d="M57.5013 53.6877C57.5013 53.6877 58.2126 52.5987 60.908 53.0118C63.6034 53.4249 65.4003 52.2608 66.9352 52.6363C69.3311 53.2371 75.0963 51.3596 77.3425 51.1343C78.4281 51.0216 79.701 51.7726 80.0004 51.8853C80.1876 51.9604 80.1502 51.4722 80.2251 50.4584C80.2589 49.8904 80.334 49.3257 80.4497 48.7686C80.824 46.6658 81.0861 42.7981 80.8989 41.1835C80.7117 39.5688 79.8881 37.3534 80.3374 35.1755C80.7866 32.9975 80.7866 30.5568 81.2358 29.1299C77.7168 29.3176 80.4122 30.3315 73.8983 29.4678C71.465 29.1674 68.3578 30.1062 67.4219 29.9935C66.3362 29.8433 63.828 30.5192 62.8172 29.9935C61.9562 29.5429 60.5336 29.5054 59.5603 29.618C59.5603 29.618 59.7474 32.5469 59.8223 33.2229C59.8972 33.8988 58.25 45.8022 58.2874 46.816C58.3623 48.1678 57.1643 50.7963 57.5013 53.6877Z"
        fill="#004EB6"
      />
      <path
        d="M64.7639 30.1437C66.9833 31.8808 68.7442 34.137 69.8927 36.715C70.7163 38.555 70.7911 39.6439 71.9517 40.6202C73.6737 42.0472 76.9681 42.648 78.8774 40.5076C80.1876 39.0431 80.2251 36.9779 80.1876 36.3771C80.182 35.9715 80.2324 35.5671 80.3374 35.1755C80.7866 32.96 80.7866 30.5568 81.2358 29.1299C77.7168 29.3176 80.4122 30.3315 73.8983 29.4678C71.465 29.1674 68.3578 30.1062 67.4219 29.9935C66.9352 29.9184 65.8121 30.0686 64.7639 30.1437Z"
        fill="#0064DC"
      />
      <path
        d="M10.8926 90.5981C2.80635 95.2918 2.76891 102.839 10.7803 107.533C18.7916 112.227 31.8194 112.189 39.9057 107.533C47.9919 102.877 48.0293 95.2918 40.018 90.5981C32.0066 85.9043 18.9788 85.9418 10.8926 90.5981Z"
        fill="#2F455F"
      />
      <path
        d="M11.0791 83.9506L11.154 98.7829C11.154 101.261 12.7638 104.415 17.7053 106.744C23.4331 109.41 31.1824 109.635 37.1722 107.72C40.4291 106.706 43.1994 105.054 44.8841 102.876C45.8438 101.697 46.3839 100.23 46.4189 98.7078L46.3441 83.8755C46.3441 81.2846 44.622 78.6561 41.2153 76.6659C34.3645 72.6856 23.2085 72.6856 16.2828 76.6659C13.5125 78.2806 11.8653 80.2707 11.3038 82.336C11.1102 83.0189 11.0467 83.7323 11.1166 84.4388C11.1166 84.2886 11.0791 84.1384 11.0791 83.9506Z"
        fill="#ABE0F6"
      />
      <path
        d="M11.154 90.7861V91.9502C11.154 94.4285 12.7638 97.5827 17.7054 99.9108C23.4331 102.577 31.1824 102.802 37.1722 100.887C40.4291 99.8733 43.1994 98.2211 44.8841 96.0432C45.8438 94.8638 46.3839 93.397 46.4189 91.8751V90.8612C46.3458 92.3414 45.8087 93.7607 44.8841 94.9166C43.1994 97.0946 40.4666 98.7468 37.1722 99.7606C31.145 101.676 23.4331 101.45 17.7054 98.7843C12.7638 96.4187 11.154 93.2644 11.154 90.7861Z"
        fill="#004EB6"
      />
      <path
        d="M16.28 76.6663C9.35428 80.6842 9.31684 87.1428 16.2051 91.1607C23.0934 95.1786 34.2119 95.141 41.1376 91.1607C48.0633 87.1804 48.0633 80.6842 41.2125 76.6663C34.3617 72.6485 23.2057 72.686 16.28 76.6663Z"
        fill="#71ADEE"
      />
      <path
        d="M26.6506 82.7131C25.5275 83.3891 25.4901 84.4405 26.6506 85.1164C27.2862 85.4493 27.9926 85.6232 28.7096 85.6232C29.4266 85.6232 30.133 85.4493 30.7686 85.1164C31.8917 84.4405 31.9291 83.3891 30.7686 82.7131C30.1276 82.3981 29.4233 82.2344 28.7096 82.2344C27.9958 82.2344 27.2916 82.3981 26.6506 82.7131Z"
        fill="#004EB6"
      />
      <path
        d="M13.9241 80.5346C15.5466 79.3594 17.5549 78.8491 19.5395 79.1077C23.096 79.6334 24.8555 82.4872 25.0801 82.8627C23.4825 81.7213 21.666 80.925 19.7457 80.5243C17.8253 80.1235 15.843 80.1271 13.9241 80.5346V80.5346Z"
        fill="#004EB6"
      />
      <path
        d="M32.38 82.7127C33.5927 81.1063 35.3759 80.0304 37.359 79.7087C38.5002 79.5594 39.66 79.66 40.7587 80.0036C41.8574 80.3471 42.8687 80.9255 43.7231 81.6988C41.6778 81.0032 39.5012 80.7849 37.359 81.0605C35.6184 81.3261 33.9351 81.8847 32.38 82.7127V82.7127Z"
        fill="#004EB6"
      />
      <path
        d="M32.0806 75.1631C31.1639 75.9489 30.3911 76.8893 29.797 77.9418C29.2653 78.8829 28.9101 79.9138 28.7487 80.9833C28.5821 80.4469 28.4815 79.8921 28.4493 79.3311C28.4508 78.7425 28.552 78.1584 28.7487 77.6038C29.46 75.8014 31.8559 75.2006 32.0806 75.1631Z"
        fill="#004EB6"
      />
      <path
        d="M39.0439 89.8459C37.855 88.3743 36.4243 87.1171 34.8137 86.1284C34.0376 85.66 33.2236 85.2581 32.3803 84.9268C32.9677 84.882 33.5583 84.9072 34.1398 85.0019C34.7958 85.1445 35.4268 85.385 36.0116 85.7154C38.0706 86.917 38.9316 89.5079 39.0439 89.8459Z"
        fill="#004EB6"
      />
      <path
        d="M24.8921 85.0384C22.1919 85.8192 19.749 87.3101 17.8167 89.3566C17.9773 88.7929 18.2304 88.26 18.5654 87.7795C18.9571 87.2293 19.4233 86.7364 19.9505 86.3151C21.8223 84.8131 24.5177 85.0384 24.8921 85.0384Z"
        fill="#004EB6"
      />
      <path
        d="M24.7817 73.8153V74.0781C24.6694 75.1671 25.5679 78.6217 25.9422 79.6731C26.1026 80.1982 26.2031 80.7399 26.2417 81.2877C26.2525 81.4639 26.2399 81.6406 26.2043 81.8134C26.1728 82.1679 26.2109 82.5251 26.3166 82.8649L26.354 82.9024C26.953 84.5546 28.4879 84.6673 30.1351 84.1791C31.0733 83.8911 31.9772 83.5007 32.8305 83.0151C33.0339 82.5659 33.1361 82.0772 33.1296 81.5839C33.1231 81.0907 33.0082 80.6049 32.7931 80.1612C32.3438 79.3727 31.6325 78.5466 31.3705 78.4339C31.0263 78.368 30.6967 78.2409 30.3972 78.0584C28.75 77.0821 28.2258 74.1156 28.1884 73.7401C28.1505 73.456 28.075 73.1782 27.9638 72.914V72.914C27.8797 72.6821 27.7796 72.4562 27.6643 72.2381C27.6181 72.1953 27.58 72.1444 27.552 72.0879C27.3908 71.8902 27.1835 71.7354 26.9484 71.6372C26.7133 71.5389 26.4578 71.5003 26.2043 71.5247C25.8299 71.5998 25.6802 71.6749 25.5679 71.8251C25.4348 71.9502 25.3324 72.1044 25.2684 72.2757C25.1602 72.4885 25.0845 72.7163 25.0438 72.9516C25.0438 73.0267 25.0063 73.0267 25.0063 73.1393C24.8566 73.1769 24.8192 73.4397 24.7817 73.8153Z"
        fill="#BBBEC9"
      />
      <path
        d="M24.9302 72.8365C25.0896 73.1619 25.291 73.4649 25.5292 73.7377C25.9784 74.2634 26.5774 74.8642 27.2138 74.714C27.4445 74.6488 27.6517 74.5186 27.811 74.3389C27.9702 74.1592 28.0748 73.9375 28.1123 73.7001C28.0744 73.4159 27.9989 73.1381 27.8877 72.874V72.874C27.8036 72.642 27.7035 72.4162 27.5882 72.1981C27.542 72.1553 27.5039 72.1044 27.4759 72.0479C27.3147 71.8502 27.1074 71.6954 26.8723 71.5971C26.6372 71.4989 26.3817 71.4603 26.1282 71.4846L25.7913 71.5973C25.3046 71.7851 25.0425 72.3483 24.9302 72.8365Z"
        fill="#ED96BC"
      />
      <path
        d="M24.9307 72.8364C25.09 73.1619 25.2914 73.4649 25.5296 73.7376C25.8666 74.1131 26.4281 74.789 27.102 74.7514C27.1394 73.5123 26.9897 72.0854 25.7917 71.785C25.6954 71.7522 25.5935 71.7394 25.4922 71.7474C25.4922 71.7524 25.4912 71.7572 25.4893 71.7618C25.4875 71.7664 25.4847 71.7705 25.4812 71.774C25.4778 71.7775 25.4736 71.7802 25.4691 71.7821C25.4646 71.784 25.4597 71.785 25.4548 71.785C25.1791 72.0749 24.9965 72.4412 24.9307 72.8364V72.8364Z"
        fill="#C96D95"
      />
      <path
        d="M26.2028 82.335C26.2122 82.4997 26.2373 82.6632 26.2777 82.8231L26.3526 83.0109C26.495 83.3347 26.6984 83.6279 26.9516 83.8745C27.7003 84.6255 29.0854 84.4378 30.0962 84.1374C29.9839 83.7619 29.647 83.1611 28.3741 83.086C27.5936 83.0495 26.8399 82.7888 26.2028 82.335Z"
        fill="#004EB6"
      />
      <path
        d="M31.3323 78.3952C32.0688 78.9957 32.6258 79.7883 32.9421 80.6858C33.1682 81.4396 33.1285 82.2485 32.8298 82.9763C33.9428 82.2952 35.1218 81.7291 36.3488 81.2866V81.2866C39.0442 80.3103 42.0765 79.5593 46.1571 78.1324C50.0879 76.743 53.8315 74.9782 57.1259 72.3496C59.5967 72.3872 65.1372 71.2982 67.5706 70.7725C70.5312 70.1156 73.4451 69.2626 76.2932 68.2191C78.3355 67.4184 80.1708 66.1648 81.6618 64.5521C83.1529 62.9395 84.2611 61.0095 84.9036 58.9067C85.2405 57.5924 81.4594 58.0055 74.6086 55.7525C72.9905 55.1483 71.2265 55.0565 69.5547 55.4896H69.5173C66.5598 56.2782 63.5649 58.6438 58.3987 61.8731C56.1151 63.3 54.6177 62.9621 53.3074 63.7131C46.7186 67.4681 46.1196 71.2982 31.3323 78.3952Z"
        fill="#ED96BC"
      />
      <path
        d="M31.3321 78.3929C32.0685 78.9933 32.6255 79.7859 32.9418 80.6834C33.1679 81.4372 33.1283 82.2461 32.8295 82.974C33.9426 82.2929 35.1216 81.7267 36.3485 81.2843V81.2843C39.0439 80.3079 42.0762 79.5569 46.1568 78.13C50.0876 76.7407 53.8312 74.9758 57.1256 72.3473C59.5964 72.3849 65.137 71.2959 67.5703 70.7702C70.5309 70.1133 73.4448 69.2603 76.293 68.2168C78.3352 67.416 80.1705 66.1624 81.6615 64.5498C83.1526 62.9372 84.2608 61.0072 84.9033 58.9043C85.2402 57.5901 81.6089 56.2007 74.7581 53.9102C72.8863 53.2718 71.1642 55.0367 69.5919 55.4497H69.5544C66.597 56.2383 63.6021 58.604 58.4359 61.8333C56.1523 63.2602 54.6548 62.9222 53.3446 63.6732C46.7183 67.4658 46.1194 71.2959 31.3321 78.3929Z"
        fill="#F6F6F6"
      />
      <path
        d="M31.3323 78.3939C32.0688 78.9944 32.6258 79.787 32.9421 80.6845C33.1682 81.4383 33.1285 82.2472 32.8298 82.9751C33.9428 82.2939 35.1218 81.7278 36.3488 81.2853V81.2853C36.4237 81.2853 36.798 81.1351 37.7339 80.8347C39.1191 80.3465 42.7504 79.3327 46.1571 78.1311C48.0453 77.4582 49.895 76.6809 51.6976 75.803C53.6002 74.8409 55.4056 73.6966 57.0884 72.3859C58.4432 72.3556 59.7943 72.2301 61.1316 72.0104C66.2998 71.2799 71.38 70.0217 76.2932 68.2554C80.3363 66.7909 83.743 63.0359 84.9036 58.9429C85.2405 57.6287 81.4594 58.0417 74.6086 55.7887C73.6727 55.4508 72.8491 55.0753 71.9132 54.7373L71.6886 54.8124C71.0522 55.0002 69.5173 55.5259 69.5173 55.5259C66.8308 56.5697 64.3569 58.0967 62.2172 60.0319C64.4086 58.7485 66.8449 57.9425 69.3675 57.6662C70.865 57.516 71.8758 57.4034 73.0363 57.9666C75.4696 59.1682 76.5179 62.3975 75.9937 63.9371C75.6189 64.9636 74.8697 65.8091 73.8973 66.3028C69.405 68.8937 59.4469 69.9827 59.4469 69.9827C58.3454 70.1408 57.2569 70.3792 56.19 70.6961C54.6177 71.1843 52.933 72.9116 51.8474 73.8504C50.5371 74.9769 48.7027 75.6903 45.034 77.1548C41.4437 78.5807 37.7522 79.7355 33.9903 80.6094C32.9795 79.4453 31.8939 78.0935 31.3323 78.3939Z"
        fill="#AAD2F6"
      />
      <path
        d="M24.8931 73.9228C24.8931 73.9979 24.9305 74.073 24.9305 74.1857C25.1888 75.1997 25.5268 76.1917 25.9413 77.1521C26.3676 78.1576 26.8552 79.1357 27.4013 80.081C27.673 80.5591 27.8986 81.0621 28.0752 81.5831C28.1229 81.7559 28.1604 81.9315 28.1875 82.1088C28.2606 82.4465 28.3737 82.7743 28.5244 83.0851L28.5618 83.1226C29.5352 84.5871 31.0701 84.3242 32.5301 83.423C33.3624 82.9166 34.1402 82.3251 34.8511 81.6582C34.9462 81.1743 34.9281 80.6749 34.7982 80.1992C34.6683 79.7236 34.4301 79.2847 34.1024 78.917C33.466 78.2411 32.3803 76.9644 32.1183 76.9268C31.6185 76.6983 31.1421 76.4216 30.6957 76.1007C29.9844 75.6126 28.749 74.7114 28.15 73.1718C28.15 73.1343 28.1126 73.0967 28.1126 73.0592C28.0013 72.7954 27.8633 72.5437 27.7008 72.3082C27.5511 72.1204 27.4013 71.8951 27.2516 71.7449C27.2089 71.6985 27.1582 71.6604 27.1018 71.6323C26.9004 71.4679 26.659 71.3603 26.4023 71.3207C26.1457 71.2811 25.8832 71.3109 25.6418 71.407C25.4266 71.5016 25.2349 71.6426 25.0803 71.82C24.9795 71.979 24.9156 72.1587 24.8931 72.3457C24.8496 72.5812 24.8246 72.8198 24.8182 73.0592V73.2469C24.8215 73.4741 24.8466 73.7005 24.8931 73.9228V73.9228Z"
        fill="white"
      />
      <path
        d="M30.9951 83.4602C30.8079 83.0847 30.6207 82.7843 30.6207 82.7843C30.5237 81.9448 30.3095 81.1232 29.9843 80.3436C29.7972 79.893 29.61 79.5174 29.5351 79.3672C28.8987 78.2032 28.3371 77.7526 27.9253 76.551C27.8879 76.4759 27.8879 76.4008 27.8505 76.3632C27.5884 75.1992 27.1017 71.6695 25.3422 71.6695C25.241 71.6634 25.1394 71.6761 25.0427 71.707C24.6684 72.3078 24.7807 73.209 24.8556 73.8849C24.8556 73.96 24.893 74.0351 24.893 74.1478C25.0427 75.2367 26.7274 79.1044 27.3638 80.0432C27.6355 80.5212 27.8611 81.0242 28.0376 81.5452C28.15 81.8831 28.1125 81.9958 28.1874 82.2586C28.2624 82.5479 28.3757 82.8257 28.5243 83.0847C28.7077 83.3356 28.9215 83.5626 29.1607 83.7606C29.4218 83.9515 29.7255 84.0752 30.0453 84.121C30.3651 84.1668 30.6912 84.1333 30.9951 84.0235C31.07 83.9859 31.1449 83.9859 31.2197 83.9484C31.1548 83.7813 31.0797 83.6182 30.9951 83.4602Z"
        fill="#E6E4E1"
      />
      <path
        d="M24.8179 72.9455C25.0431 73.232 25.3078 73.4849 25.604 73.6965C26.1656 74.0721 26.8769 74.5602 27.4759 74.2598C27.6866 74.1429 27.8589 73.9671 27.9719 73.7538C28.0849 73.5404 28.1337 73.2988 28.1123 73.0582C28.001 72.7944 27.863 72.5427 27.7005 72.3072V72.3072C27.5507 72.1194 27.401 71.8941 27.2512 71.7439C27.2086 71.6976 27.1578 71.6594 27.1015 71.6313C26.9001 71.4669 26.6586 71.3594 26.402 71.3198C26.1454 71.2802 25.8828 71.3099 25.6415 71.406C25.5217 71.4539 25.4084 71.517 25.3046 71.5937C24.9302 71.819 24.8179 72.4198 24.8179 72.9455Z"
        fill="#71ADEE"
      />
      <path
        d="M24.8176 72.9457C25.0429 73.2322 25.3076 73.485 25.6038 73.6967C26.0156 73.9596 26.7643 74.5228 27.3633 74.2975C27.1012 73.0959 26.6146 71.7441 25.3792 71.7066C25.2779 71.7005 25.1763 71.7132 25.0797 71.7441V71.7441C25.0797 71.7541 25.0757 71.7636 25.0687 71.7707C25.0617 71.7777 25.0522 71.7817 25.0422 71.7817C24.8853 72.1491 24.8087 72.5461 24.8176 72.9457V72.9457Z"
        fill="#004EB6"
      />
      <path
        d="M28.3379 82.5977C28.3842 82.7541 28.4469 82.9052 28.5251 83.0483L28.5625 83.0858C28.8117 83.3983 29.1012 83.6761 29.4235 83.9119C30.322 84.4752 31.6323 83.9495 32.5308 83.4238C32.3436 83.0858 31.8569 82.5977 30.6215 82.823C29.8542 82.9615 29.0636 82.8835 28.3379 82.5977V82.5977Z"
        fill="#71ADEE"
      />
      <path
        d="M28.1887 82.0703C28.2437 82.4248 28.3576 82.7675 28.5256 83.0842C28.5599 83.1365 28.5974 83.1867 28.6379 83.2344C28.7955 83.4496 28.9848 83.6396 29.1995 83.7976C29.2743 83.8352 29.3492 83.9103 29.3866 83.9103C29.5925 84.0348 29.8216 84.1157 30.0598 84.148C30.2981 84.1803 30.5404 84.1633 30.7718 84.098C30.9118 84.0913 31.0502 84.066 31.1836 84.0229C31.1836 83.5348 30.622 82.8213 30.622 82.8213C29.7749 82.7086 28.9525 82.4548 28.1887 82.0703V82.0703Z"
        fill="#004EB6"
      />
      <path
        d="M32.1184 76.8931C32.1471 76.8959 32.1738 76.9093 32.1933 76.9307C32.8156 77.4154 33.3808 77.9697 33.8779 78.5829C33.9312 78.6767 33.9939 78.7647 34.0651 78.8458C34.1399 78.9584 34.2148 79.0335 34.2897 79.1461C34.3457 79.2301 34.3957 79.318 34.4394 79.409C34.8509 80.0828 35.0101 80.8813 34.8887 81.662C35.8047 80.7257 36.8217 79.8945 37.921 79.1837H37.9584C38.4415 78.8456 38.9413 78.5323 39.4559 78.2449C39.9426 77.9445 40.6913 77.4939 41.5898 76.9307C41.8892 76.7805 42.1513 76.5927 42.4508 76.405C42.6754 76.2923 42.9 76.1421 43.1246 76.0295C43.6113 75.7291 44.1354 75.4287 44.6221 75.1283L45.2959 74.6777C45.4831 74.565 45.6703 74.4148 45.8949 74.3022C46.3067 74.0393 46.6811 73.814 47.0554 73.5512C49.6011 71.8239 52.1842 69.8337 54.2432 67.5807C54.7673 66.8297 55.2914 66.4542 56.1899 65.3277C56.4519 65.0273 56.714 64.6893 57.0135 64.3889L57.5001 63.9008C58.3622 63.0571 59.2749 62.2671 60.233 61.5351C64.613 58.118 70.2659 55.1891 71.8008 54.7761C70.6777 51.6594 69.7418 49.4815 69.0305 48.5052C68.9556 48.3925 68.8808 48.3174 68.8059 48.2048L68.731 48.1297C68.6936 48.0921 68.6936 48.0546 68.6561 48.0546L68.5813 47.9795C68.3628 48.0509 68.1501 48.1388 67.9448 48.2423V48.2423C65.6669 49.1408 63.453 50.1947 61.3186 51.3966C57.4627 53.5745 53.1575 55.5646 50.0503 58.8315C47.5421 61.3474 43.0123 69.4206 32.1184 76.8931Z"
        fill="#F6F6F6"
      />
      <path
        d="M32.1184 76.8901C32.1471 76.8928 32.1738 76.9062 32.1932 76.9276C32.2307 76.9276 32.2681 76.9652 32.3055 76.9652C32.6799 77.153 33.3538 77.9415 33.8404 78.5799C33.8938 78.6736 33.9565 78.7617 34.0276 78.8427C34.0933 78.9494 34.1685 79.05 34.2522 79.1431C34.3082 79.2271 34.3583 79.3149 34.402 79.406C34.8134 80.0797 34.9726 80.8783 34.8512 81.659C35.7673 80.7226 36.7843 79.8914 37.8836 79.1807H37.921C37.9959 79.1431 38.2579 78.9929 39.1938 78.3546C39.2687 78.317 39.3436 78.2419 39.4184 78.2044C39.9051 77.904 40.6538 77.4533 41.5523 76.8901C41.8518 76.7399 42.1138 76.5521 42.4133 76.3644C42.6379 76.2517 42.8626 76.1015 43.0872 75.9889C43.5739 75.6885 44.0605 75.3881 44.5846 75.0877L45.2585 74.6371C45.4457 74.5244 45.6328 74.3742 45.8575 74.2616C46.2693 73.9987 46.6436 73.7734 47.018 73.5106C49.9755 71.558 52.8955 69.3801 54.2057 67.5401C54.7298 66.7891 55.254 66.4136 56.1524 65.2871C56.4145 64.9867 56.6765 64.6487 56.976 64.3483L57.4627 63.8602C58.3248 63.0166 59.2374 62.2265 60.1955 61.4945C64.5381 58.1526 70.3782 55.0734 72.0254 54.6228C71.4638 53.0457 70.191 50.0042 68.9931 48.4271C68.9182 48.3144 68.8433 48.2393 68.7684 48.1267C68.6936 48.014 68.6561 48.014 68.6187 47.9765L68.5438 47.9014C68.3254 47.9728 68.1127 48.0607 67.9074 48.1642C65.6295 49.0627 63.4156 50.1166 61.2812 51.3184C61.9326 52.4155 62.287 53.6642 62.3092 54.9409C62.3315 56.2176 62.0209 57.478 61.4081 58.5973C60.7953 59.7165 59.9017 60.6558 58.8157 61.322C57.7297 61.9883 56.489 62.3584 55.2165 62.3957C52.6133 66.4842 49.2595 70.0394 45.3334 72.8722C42.1781 75.0966 38.8761 77.1038 35.4502 78.8803C35.1695 78.2426 34.698 77.7085 34.1009 77.3518C33.5039 76.9952 32.811 76.8338 32.1184 76.8901V76.8901Z"
        fill="#AAD2F6"
      />
      <path
        d="M57.0517 47.7879L58.9984 50.9421C58.9984 50.9421 67.197 46.4737 71.577 43.2444C73.0744 41.855 74.5719 40.4281 76.0693 39.0387C74.3098 37.9122 77.0801 28.7124 75.3206 27.5859C74.7591 28.2618 69.6303 37.0486 69.0688 37.762C64.8759 41.7799 57.0517 47.7879 57.0517 47.7879Z"
        fill="#FFDC1E"
      />
      <path
        d="M69.5179 38.7011C69.5928 38.9264 69.6677 39.1141 69.7426 39.3019C69.7639 39.3214 69.7773 39.3481 69.78 39.377V39.4521C71.5395 44.1459 70.8282 45.3099 68.9938 47.225C66.2984 50.1163 71.8764 56.5374 78.4652 58.077C80.0962 58.4336 81.746 58.6969 83.4068 58.8655H83.9683C84.1978 58.8679 84.4259 58.8297 84.6422 58.7529C84.6796 58.7529 84.7171 58.7153 84.7545 58.7153V58.6778C85.0436 58.4169 85.2734 58.0967 85.4283 57.739C85.915 56.7627 85.3535 52.2191 85.5407 51.1677C85.7278 49.7784 86.4766 47.8258 88.9099 45.6854V45.6479C90.4688 44.3012 91.7419 42.6539 92.6535 40.8039C93.1005 40.0427 93.3931 39.2005 93.5146 38.3256C93.8141 36.1477 92.9905 34.4204 92.3915 33.2188C91.6428 31.7543 90.2576 30.5527 88.1612 28.1119C85.8401 26.1218 82.3586 24.2067 79.4385 24.0941C78.3155 24.0565 77.6042 24.019 76.8554 24.3569C74.8713 25.2206 75.4329 27.3234 72.8123 30.1772C70.379 32.9184 67.9831 34.7583 69.5179 38.7011Z"
        fill="#FFDC1E"
      />
      <path
        d="M69.1043 36.9728C69.1664 37.5641 69.3048 38.1447 69.5161 38.7001C71.1258 43.2437 71.7248 44.3702 69.0294 47.224C66.334 50.0778 71.8371 56.4989 78.5008 58.076C80.3091 58.4975 82.1503 58.7614 84.0039 58.8646V58.8646C84.2739 58.8744 84.5426 58.8231 84.7901 58.7144V58.6768C85.0873 58.4234 85.3186 58.1012 85.4639 57.7381C85.9506 56.7618 85.389 52.2182 85.5762 51.1668C85.7634 49.7774 86.5121 47.8248 88.9455 45.6845V45.6469C90.2183 44.3327 92.0153 42.5302 92.6891 40.8029C93.1009 36.5598 92.0527 35.8839 91.7532 35.7712C90.2932 35.0953 86.6619 38.212 84.9398 39.8642C82.7685 41.9294 81.9075 43.8445 80.148 44.2951C78.857 44.6068 77.4991 44.4745 76.292 43.9196C74.4202 43.0935 73.9336 41.8168 72.1366 40.2772C71.2007 39.3385 70.2648 39.2258 69.1043 36.9728Z"
        fill="#E39F3A"
      />
      <path
        d="M86.2147 31.6041C85.6532 31.9045 82.6208 33.4816 80.15 32.1674C76.6685 30.2899 77.0803 26.7601 79.289 25.0328C79.5699 24.8587 79.8332 24.6575 80.0752 24.432C80.5244 24.3569 82.808 24.8075 84.6424 25.3708C85.728 26.2344 87.2629 26.9854 88.2363 28.1119V28.4499C88.3486 29.8768 87.4875 30.8531 86.2147 31.6041Z"
        fill="white"
      />
      <path
        d="M79.7748 28.7477C79.7748 26.4571 79.962 23.7535 80.8604 21.7634C81.7589 19.7732 83.481 17.8206 84.3046 18.0834C85.3528 18.4214 84.4918 20.7495 86.2138 23.7535C86.6654 24.5676 87.0054 25.4391 87.2246 26.3445C87.4867 27.5461 87.2995 28.1093 86.8128 28.8228C84.6789 32.0146 79.7748 31.4889 79.7748 28.7477Z"
        fill="#FFCFCD"
      />
      <path
        d="M79.7748 28.7477C79.7748 26.4571 79.962 23.7535 80.8604 21.7634C81.7589 19.7732 83.481 17.8206 84.3046 18.0834C85.3528 18.4214 84.4918 20.7495 86.2138 23.7535C86.6654 24.5676 87.0054 25.4391 87.2246 26.3445C87.4867 27.5461 87.2995 28.1093 86.8128 28.8228C84.6789 32.0146 79.7748 31.4889 79.7748 28.7477Z"
        fill="#71ADEE"
      />
      <path
        d="M77.1184 25.105C77.6799 26.0438 78.4286 26.2691 79.4768 26.3066C81.4984 26.4193 82.8461 25.856 84.4559 24.6544C85.5415 23.8283 86.9266 22.9647 87.9374 21.1998C89.2477 18.9093 89.959 15.6799 89.959 15.6799L84.1564 11.249L77.6799 12.6008C77.1406 12.951 76.6813 13.4117 76.3322 13.9526C76.0327 14.4408 75.7707 15.0791 75.6209 17.9705C75.546 19.8856 75.2091 21.7631 77.1184 25.105Z"
        fill="#ABE0F6"
      />
      <path
        d="M77.3055 14.4366C77.6546 15.7357 78.426 16.8807 79.4975 17.6902C80.569 18.4996 81.8792 18.9272 83.2204 18.9051C84.2312 22.247 84.8302 23.6364 85.2045 24.1245C85.4291 23.9368 85.6537 23.7866 85.8784 23.5988C86.365 21.7213 86.2527 16.5394 83.4076 14.7745C82.2139 14.0963 80.8438 13.7953 79.4768 13.9109C78.7326 13.9866 78.0021 14.1635 77.3055 14.4366V14.4366Z"
        fill="#2F455F"
      />
      <path
        d="M76.7807 24.434C76.5187 25.7107 76.032 26.1613 75.1335 26.7997C75.0961 26.7246 75.0961 26.687 75.0587 26.6119C75.2814 26.2565 75.4148 25.8521 75.4474 25.4335C75.48 25.0149 75.4108 24.5946 75.2458 24.2087C74.9464 23.5328 74.4971 21.3174 74.6094 18.6513C74.7592 18.2758 74.9089 17.8252 75.1335 17.262C76.1069 14.8587 77.4546 14.7461 77.4546 14.7461C76.5822 15.5941 76.0383 16.7252 75.9197 17.9379C75.6202 19.9656 76.1818 22.6316 76.7807 24.434Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M76.7807 24.434C76.5187 25.7107 76.032 26.1613 75.1335 26.7997C75.0961 26.7246 75.0961 26.687 75.0587 26.6119C75.2814 26.2565 75.4148 25.8521 75.4474 25.4335C75.48 25.0149 75.4108 24.5946 75.2458 24.2087C74.9464 23.5328 74.4971 21.3174 74.6094 18.6513C74.7592 18.2758 74.9089 17.8252 75.1335 17.262C76.1069 14.8587 77.4546 14.7461 77.4546 14.7461C76.5822 15.5941 76.0383 16.7252 75.9197 17.9379C75.6202 19.9656 76.1818 22.6316 76.7807 24.434Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M89.5082 10.5744C89.4787 11.2132 89.2609 11.8288 88.8825 12.3435C88.5041 12.8581 87.9821 13.2486 87.3824 13.4657C86.7827 13.6827 86.1323 13.7165 85.5135 13.5627C84.8947 13.409 84.3352 13.0746 83.9058 12.6019C83.4765 12.1292 83.1965 11.5394 83.1013 10.9071C83.0061 10.2748 83.1 9.62844 83.3711 9.04967C83.6422 8.4709 84.0783 7.98577 84.6243 7.65562C85.1703 7.32548 85.8016 7.16515 86.4385 7.19491C87.2923 7.23482 88.0954 7.61327 88.6711 8.24704C89.2468 8.8808 89.5479 9.71798 89.5082 10.5744Z"
        fill="#004EB6"
      />
      <path
        d="M74.7972 27.0218C75.8829 26.2707 76.4819 25.8577 76.7813 24.4308C76.1824 22.6284 75.6208 19.9623 75.9203 17.9346C76.0389 16.7219 76.5829 15.5909 77.4552 14.7428C78.0135 15.7896 78.8368 16.6704 79.8423 17.2966C80.8478 17.9229 82 18.2724 83.1829 18.3101C82.7711 16.8081 82.7337 16.4326 82.8086 16.4326C85.5789 26.5336 86.6271 26.4585 91.681 31.9033L92.5046 33.1424C92.5994 32.8857 92.6745 32.6221 92.7292 32.3539C93.1784 30.1009 91.8681 28.1858 91.3066 27.2095C88.3117 21.7272 92.3174 16.2073 88.9107 11.1756C88.0871 10.1617 86.5522 8.99766 85.2045 8.6597C85.1296 8.62215 85.0173 8.62216 84.905 8.58461C84.8104 8.54206 84.709 8.51663 84.6055 8.5095C83.7423 8.33753 82.8565 8.31214 81.985 8.4344C78.6532 8.80991 74.9095 11.3258 74.5726 18.6481V18.6856C74.4603 21.3517 74.9095 23.5671 75.209 24.243C75.6583 25.2193 75.3962 26.1205 74.7972 27.0218Z"
        fill="#004EB6"
      />
      <path
        opacity="0.3"
        d="M81.0496 8.62107C85.3173 9.07167 87.0019 10.5737 87.7506 11.7753C89.6599 14.9295 87.264 19.2478 88.724 23.7538C89.7722 26.9456 90.3337 27.8092 90.3337 27.8092C90.7701 28.4114 91.0994 29.0847 91.3071 29.7994C91.5208 30.4786 91.6096 31.1911 91.5691 31.9022C91.8312 32.3152 92.1307 32.7283 92.3927 33.1413C92.4876 32.8847 92.5627 32.621 92.6174 32.3528C93.0666 30.0998 91.7563 28.1847 91.1948 27.2084C88.1999 21.7261 92.2056 16.2062 88.7989 11.1745C87.9753 10.1606 86.4404 8.99657 85.0927 8.65862C85.0178 8.62107 84.9055 8.62107 84.7932 8.58352C84.6985 8.54098 84.5972 8.51555 84.4937 8.50842C83.3521 8.28229 82.1741 8.32082 81.0496 8.62107V8.62107Z"
        fill="#A14C50"
      />
      <path
        d="M43.0891 55.6723C43.0891 55.6723 43.0516 55.2593 43.6506 54.9589L56.2666 48.1247C56.6754 47.943 57.1197 47.8555 57.5666 47.8684C58.0136 47.8814 58.4521 47.9947 58.8497 48.1998L77.3806 59.6902C77.6231 59.8448 77.7972 60.0866 77.8673 60.3661C77.9048 61.1922 78.0171 61.3048 77.3058 61.793C76.2201 62.5815 73.1504 64.1211 64.6898 68.7022C64.3392 68.8641 63.9599 68.9542 63.5742 68.9671C63.1885 68.98 62.8041 68.9155 62.4436 68.7773C62.1815 68.6647 63.4169 69.4157 43.5757 57.0992C42.9393 56.7613 43.0516 56.4233 43.0891 55.6723Z"
        fill="#71ADEE"
      />
      <path
        d="M77.3799 59.6902C78.0912 60.1408 78.0538 60.7791 77.3425 61.1922L64.7265 68.0263C64.3176 68.208 63.8734 68.2956 63.4264 68.2826C62.9794 68.2696 62.541 68.1564 62.1434 67.9512L43.6124 56.4609C42.9011 56.0103 42.9386 55.3719 43.6499 54.9589L56.2659 48.1247C56.6747 47.943 57.1189 47.8555 57.5659 47.8684C58.0129 47.8814 58.4513 47.9947 58.849 48.1998L77.3799 59.6902Z"
        fill="#E2F6FF"
      />
      <path
        d="M48.9305 55.1111C49.3049 54.8858 49.6044 54.7732 49.3423 54.623C48.968 54.3977 48.631 54.0973 48.369 54.2475C47.9946 54.4728 47.6951 54.5854 47.9572 54.7356C48.3315 54.9609 48.7059 55.2613 48.9305 55.1111Z"
        fill="#0064DC"
      />
      <path
        d="M50.1664 55.8621C50.5407 55.6368 50.8402 55.5241 50.5782 55.3739C50.2038 55.1486 49.8669 54.8482 49.6048 54.9984C49.2305 55.2237 48.931 55.3364 49.193 55.4866C49.5674 55.6743 49.9417 55.9747 50.1664 55.8621Z"
        fill="#0064DC"
      />
      <path
        d="M50.5409 54.883C50.9152 54.6577 51.2147 54.545 50.9527 54.3948C50.0542 53.8691 49.6798 53.5311 49.4178 53.6813C49.0434 53.9067 48.7439 54.0193 49.006 54.1695C49.9044 54.6952 50.2788 55.0332 50.5409 54.883Z"
        fill="#0064DC"
      />
      <path
        d="M51.8504 54.4718C52.2248 54.2465 52.5243 54.1339 52.2622 53.9837C50.9894 53.2327 50.6525 52.9323 50.3904 53.0825C50.0161 53.3078 49.7166 53.4204 49.9786 53.5706C51.2515 54.3216 51.5884 54.622 51.8504 54.4718Z"
        fill="#0064DC"
      />
      <path
        d="M51.4393 56.573C51.8137 56.3477 52.1132 56.2351 51.8511 56.0849C51.4767 55.8596 51.1398 55.5592 50.8778 55.7094C50.5034 55.9347 50.2039 56.0473 50.466 56.1975C50.8029 56.4228 51.1772 56.7232 51.4393 56.573Z"
        fill="#0064DC"
      />
      <path
        d="M51.4761 55.598C51.5319 55.6315 51.5944 55.6522 51.6591 55.6587C51.7238 55.6652 51.7891 55.6573 51.8504 55.6355C52.2248 55.4102 52.5243 55.2976 52.2622 55.1474C51.8879 54.9221 51.551 54.6217 51.2889 54.7719C50.5776 55.1849 50.5402 55.0723 51.4761 55.598Z"
        fill="#0064DC"
      />
      <path
        d="M52.7477 55.2236C52.8035 55.2571 52.866 55.2778 52.9307 55.2843C52.9954 55.2908 53.0608 55.2829 53.1221 55.2611C53.8334 54.8481 53.8708 54.9607 52.9349 54.435C52.8791 54.4015 52.8166 54.3808 52.7519 54.3743C52.6872 54.3678 52.6218 54.3757 52.5605 54.3975C51.8492 54.773 51.8492 54.6603 52.7477 55.2236Z"
        fill="#0064DC"
      />
      <path
        d="M53.5731 54.3198C53.9475 54.0945 54.2469 53.9818 53.9849 53.8316C51.2895 52.217 51.7762 52.2921 50.9526 52.7802C50.8777 52.8178 50.8777 52.9304 50.99 53.0055C53.0864 54.2071 53.311 54.47 53.5731 54.3198Z"
        fill="#0064DC"
      />
      <path
        d="M52.9364 52.9699C52.9922 53.0034 53.0547 53.0242 53.1194 53.0307C53.1841 53.0372 53.2494 53.0292 53.3107 53.0075C54.0595 52.5944 54.0969 52.7446 52.8241 51.9936C52.7683 51.9601 52.7058 51.9394 52.6411 51.9329C52.5764 51.9264 52.511 51.9343 52.4497 51.9561C51.701 52.3691 51.701 52.2189 52.9364 52.9699Z"
        fill="#0064DC"
      />
      <path
        d="M52.6737 57.3289C53.0481 57.1036 53.3475 56.9909 53.0855 56.8407C52.7111 56.6154 52.3742 56.315 52.1121 56.4652C51.7378 56.6905 51.4383 56.8032 51.7003 56.9534C52.0747 57.1787 52.4116 57.4791 52.6737 57.3289Z"
        fill="#0064DC"
      />
      <path
        d="M53.0859 56.39C53.7972 55.977 53.8347 56.0896 52.8988 55.5639C52.843 55.5304 52.7805 55.5097 52.7158 55.5032C52.6511 55.4967 52.5857 55.5046 52.5244 55.5264C52.15 55.7517 51.8506 55.8643 52.1126 56.0145C52.487 56.2023 52.8239 56.5027 53.0859 56.39Z"
        fill="#0064DC"
      />
      <path
        d="M54.022 55.9349C54.0778 55.9684 54.1403 55.9892 54.205 55.9957C54.2697 56.0022 54.335 55.9942 54.3963 55.9725C54.7707 55.7472 55.0702 55.6345 54.8081 55.4843C54.4338 55.259 54.0969 54.9586 53.8348 55.1088C53.0861 55.5219 53.0861 55.4092 54.022 55.9349Z"
        fill="#0064DC"
      />
      <path
        d="M54.4351 55.0365C54.4909 55.07 54.5533 55.0907 54.6181 55.0972C54.6828 55.1037 54.7481 55.0958 54.8094 55.074C55.1838 54.8487 55.4833 54.7361 55.2212 54.5859C54.8469 54.3606 54.5099 54.0602 54.2479 54.2104C53.5366 54.6234 53.5366 54.5108 54.4351 55.0365Z"
        fill="#0064DC"
      />
      <path
        d="M53.9095 58.076C54.2839 57.8507 54.5834 57.738 54.3213 57.5878C53.947 57.3625 53.61 57.0621 53.348 57.2123C52.9736 57.4376 52.6741 57.5503 52.9362 57.7005C53.3105 57.8882 53.6475 58.1886 53.9095 58.076Z"
        fill="#0064DC"
      />
      <path
        d="M54.3218 57.098C55.0331 56.685 55.0705 56.7976 54.1346 56.2719C54.0788 56.2384 54.0163 56.2177 53.9516 56.2112C53.8869 56.2047 53.8216 56.2126 53.7602 56.2344C53.3859 56.4597 53.0864 56.5723 53.3484 56.7225C53.7228 56.9478 54.0597 57.2482 54.3218 57.098Z"
        fill="#0064DC"
      />
      <path
        d="M55.6322 56.7273C56.0065 56.502 56.306 56.3894 56.044 56.2392C55.6696 56.0139 55.3327 55.7135 55.0706 55.8637C54.6963 56.089 54.3968 56.2016 54.6588 56.3518C54.9958 56.5396 55.3701 56.84 55.6322 56.7273Z"
        fill="#0064DC"
      />
      <path
        d="M56.0438 55.7888C56.4182 55.5635 56.7176 55.4509 56.4556 55.3007C56.0812 55.0754 55.7443 54.775 55.4823 54.9252C55.1079 55.1505 54.8084 55.2631 55.0705 55.4133C55.4448 55.6386 55.8192 55.939 56.0438 55.7888Z"
        fill="#0064DC"
      />
      <path
        d="M57.0917 55.2244C57.466 54.9991 57.7655 54.8864 57.5035 54.7362C57.1291 54.5109 56.7922 54.2105 56.5301 54.3607C56.1558 54.586 55.8563 54.6987 56.1183 54.8489C56.4552 55.0742 56.8296 55.3746 57.0917 55.2244Z"
        fill="#0064DC"
      />
      <path
        d="M55.1439 58.7908C55.5183 58.5655 55.8177 58.4528 55.5557 58.3026C55.1813 58.0773 54.8444 57.7769 54.5824 57.9271C54.208 58.1524 53.9085 58.2651 54.1706 58.4153C54.5449 58.6406 54.9193 58.941 55.1439 58.7908Z"
        fill="#0064DC"
      />
      <path
        d="M55.1833 57.8154C55.2391 57.8489 55.3015 57.8696 55.3663 57.8761C55.431 57.8826 55.4963 57.8747 55.5576 57.8529C56.2689 57.4399 56.3063 57.5525 55.3704 57.0268C55.3146 56.9933 55.2522 56.9726 55.1874 56.9661C55.1227 56.9596 55.0574 56.9675 54.9961 56.9893C54.2848 57.4023 54.2848 57.2897 55.1833 57.8154Z"
        fill="#0064DC"
      />
      <path
        d="M56.4941 57.4378C56.5499 57.4713 56.6124 57.4921 56.6771 57.4986C56.7419 57.5051 56.8072 57.4971 56.8685 57.4754C57.2429 57.2501 57.5424 57.1374 57.2803 56.9872C56.9059 56.7619 56.569 56.4615 56.307 56.6117C55.5957 56.9872 55.5957 56.8746 56.4941 57.4378Z"
        fill="#0064DC"
      />
      <path
        d="M57.7662 56.2731C57.841 56.2355 57.8411 56.1229 57.7287 56.0478C57.3544 55.8225 57.0175 55.5221 56.7554 55.6723C56.3811 55.8976 56.0816 56.0102 56.3436 56.1604C57.2421 56.6861 57.0549 56.6861 57.7662 56.2731Z"
        fill="#0064DC"
      />
      <path
        d="M56.3807 59.541C56.7551 59.3157 57.0546 59.2031 56.7925 59.0529C55.8566 58.4896 56.0812 58.5272 55.3699 58.9402C55.295 58.9778 55.295 59.0904 55.4074 59.1655C55.7817 59.3908 56.1561 59.6912 56.3807 59.541Z"
        fill="#0064DC"
      />
      <path
        d="M56.7919 58.5633C57.1662 58.338 57.4657 58.2253 57.2036 58.0751C56.8293 57.8498 56.4924 57.5494 56.2303 57.6996C55.8559 57.9249 55.5565 58.0376 55.8185 58.1878C56.1929 58.4131 56.5672 58.7135 56.7919 58.5633Z"
        fill="#0064DC"
      />
      <path
        d="M58.1029 58.1875C58.4773 57.9622 58.7767 57.8495 58.5147 57.6993C57.5788 57.1361 57.8034 57.1736 57.0921 57.5867C57.0172 57.6242 57.0172 57.7369 57.1295 57.812C57.5039 57.9998 57.8408 58.3377 58.1029 58.1875Z"
        fill="#0064DC"
      />
      <path
        d="M58.5521 57.2879C58.9265 57.0626 59.226 56.9499 58.9639 56.7997C58.5895 56.5744 58.2526 56.274 57.9906 56.4242C57.6162 56.6495 57.3167 56.7622 57.5788 56.9124C57.9531 57.1001 58.2901 57.4005 58.5521 57.2879Z"
        fill="#0064DC"
      />
      <path
        d="M57.6167 60.2914C58.328 59.8784 58.3654 59.991 57.4295 59.4653C57.3737 59.4318 57.3112 59.411 57.2465 59.4045C57.1818 59.3981 57.1165 59.406 57.0552 59.4277C56.6808 59.653 56.3813 59.7657 56.6433 59.9159C57.0177 60.1037 57.3921 60.4041 57.6167 60.2914Z"
        fill="#0064DC"
      />
      <path
        d="M58.0648 59.3162C58.4392 59.0909 58.7386 58.9782 58.4766 58.828C58.1022 58.6027 57.7653 58.3023 57.5033 58.4525C57.1289 58.6778 56.8294 58.7905 57.0915 58.9407C57.4284 59.166 57.8027 59.4664 58.0648 59.3162Z"
        fill="#0064DC"
      />
      <path
        d="M59.3387 58.9024C59.7131 58.677 60.0126 58.5644 59.7505 58.4142C58.8146 57.8509 59.0392 57.8885 58.3279 58.3015C58.2531 58.3391 58.2531 58.4517 58.3654 58.5268C58.7397 58.7522 59.1141 59.0526 59.3387 58.9024Z"
        fill="#0064DC"
      />
      <path
        d="M59.787 58.001C60.1613 57.7757 60.4608 57.663 60.1988 57.5128C59.2629 56.9496 59.4875 56.9871 58.7762 57.4002C58.7013 57.4377 58.7013 57.5504 58.8136 57.6255C59.188 57.8508 59.5249 58.1512 59.787 58.001Z"
        fill="#0064DC"
      />
      <path
        d="M58.8897 61.0053C59.6009 60.5922 59.6384 60.7049 58.7025 60.1792C58.6467 60.1456 58.5842 60.1249 58.5195 60.1184C58.4548 60.1119 58.3894 60.1198 58.3281 60.1416C57.9538 60.3669 57.6543 60.4796 57.9163 60.6298C58.2907 60.8551 58.6276 61.1555 58.8897 61.0053Z"
        fill="#0064DC"
      />
      <path
        d="M59.3006 60.0654C59.675 59.8401 59.9745 59.7275 59.7124 59.5773C58.7765 59.014 59.0011 59.0516 58.2899 59.4646C58.215 59.5022 58.215 59.6148 58.3273 59.6899C58.7016 59.8777 59.0386 60.1781 59.3006 60.0654Z"
        fill="#0064DC"
      />
      <path
        d="M60.5731 59.656C60.9475 59.4307 61.2469 59.3181 60.9849 59.1679C60.6105 58.9426 60.2736 58.6422 60.0116 58.7924C59.6372 59.0177 59.3377 59.1303 59.5998 59.2805C59.9741 59.5058 60.3485 59.8062 60.5731 59.656Z"
        fill="#0064DC"
      />
      <path
        d="M61.4722 58.4889C61.5471 58.4513 61.5471 58.3387 61.4348 58.2636C61.0604 58.0383 60.7235 57.7379 60.4615 57.8881C60.0871 58.1134 59.7876 58.226 60.0497 58.3762C60.9856 58.902 60.7609 58.9019 61.4722 58.4889Z"
        fill="#0064DC"
      />
      <path
        d="M60.124 61.7572C60.8353 61.3442 60.8728 61.4568 59.9368 60.9311C59.881 60.8976 59.8186 60.8769 59.7538 60.8704C59.6891 60.8639 59.6238 60.8718 59.5625 60.8936C59.1881 61.1189 58.8886 61.2315 59.1507 61.3817C59.525 61.607 59.862 61.9074 60.124 61.7572Z"
        fill="#0064DC"
      />
      <path
        d="M60.5371 60.7787C61.2484 60.3656 61.2858 60.4783 60.3499 59.9526C60.2941 59.9191 60.2316 59.8983 60.1669 59.8918C60.1022 59.8854 60.0369 59.8933 59.9756 59.9151C59.6012 60.1403 59.3017 60.253 59.5638 60.4032C59.9381 60.6285 60.2751 60.9289 60.5371 60.7787Z"
        fill="#0064DC"
      />
      <path
        d="M61.8447 60.4047C62.556 59.9916 62.5935 60.1043 61.6575 59.5786C61.6018 59.5451 61.5393 59.5243 61.4746 59.5178C61.4098 59.5113 61.3445 59.5193 61.2832 59.541C60.9088 59.7663 60.6093 59.879 60.8714 60.0292C61.2083 60.2169 61.5827 60.5173 61.8447 60.4047Z"
        fill="#0064DC"
      />
      <path
        d="M62.2578 59.4662C62.9691 59.0531 63.0065 59.1658 62.0706 58.6401C62.0148 58.6066 61.9523 58.5858 61.8876 58.5793C61.8229 58.5729 61.7576 58.5808 61.6963 58.6026C61.3219 58.8279 61.0224 58.9405 61.2845 59.0907C61.6588 59.316 62.0332 59.6164 62.2578 59.4662Z"
        fill="#0064DC"
      />
      <path
        d="M61.3597 62.4724C61.7341 62.2471 62.0336 62.1345 61.7715 61.9843C61.3972 61.759 61.0602 61.4586 60.7982 61.6088C60.4238 61.8341 60.1243 61.9467 60.3864 62.0969C60.7607 62.3222 61.0977 62.6226 61.3597 62.4724Z"
        fill="#0064DC"
      />
      <path
        d="M61.7715 61.5331C62.4828 61.12 62.5202 61.2327 61.5843 60.7069C61.3223 60.5567 61.1725 60.7069 60.7607 60.9322C60.6858 60.9698 60.6858 61.0824 60.7982 61.1575C61.1725 61.3829 61.5469 61.6833 61.7715 61.5331Z"
        fill="#0064DC"
      />
      <path
        d="M63.0811 61.1195C63.7923 60.7065 63.8298 60.8191 62.8939 60.2934C62.8381 60.2599 62.7756 60.2392 62.7109 60.2327C62.6462 60.2262 62.5808 60.2341 62.5195 60.2559C62.1451 60.4812 61.8457 60.5938 62.1077 60.744C62.4821 60.9693 62.819 61.2697 63.0811 61.1195Z"
        fill="#0064DC"
      />
      <path
        d="M63.9796 59.9547C64.0544 59.9172 64.0544 59.8045 63.9421 59.7294C63.5678 59.5041 63.2308 59.2037 62.9688 59.3539C62.5944 59.5792 62.2949 59.6919 62.557 59.8421C63.4555 60.4053 63.2683 60.3678 63.9796 59.9547Z"
        fill="#0064DC"
      />
      <path
        d="M64.766 59.7691C65.1403 59.5438 65.4398 59.4311 65.1778 59.2809C57.8777 54.9626 58.0274 54.9251 57.7654 55.0753C57.391 55.3006 57.0915 55.4132 57.3536 55.5634C64.6537 59.9193 64.5039 59.9193 64.766 59.7691Z"
        fill="#0064DC"
      />
      <path
        d="M63.0801 62.9567C63.155 62.9191 63.155 62.8065 63.0427 62.7314C62.6683 62.5061 62.3314 62.2057 62.0694 62.3559C61.695 62.5812 61.3955 62.6938 61.6576 62.844C62.556 63.3697 62.3689 63.3697 63.0801 62.9567Z"
        fill="#0064DC"
      />
      <path
        d="M63.0072 62.281C63.3815 62.0557 63.681 61.9431 63.419 61.7929C63.0446 61.5676 62.7077 61.2672 62.4456 61.4174C62.0713 61.6427 61.7718 61.7553 62.0338 61.9055C62.4082 62.0933 62.7826 62.3937 63.0072 62.281Z"
        fill="#0064DC"
      />
      <path
        d="M63.9439 61.8333C63.9997 61.8669 64.0621 61.8876 64.1269 61.8941C64.1916 61.9006 64.2569 61.8927 64.3182 61.8709C64.6926 61.6456 64.9921 61.5329 64.73 61.3827C64.3557 61.1574 64.0187 60.857 63.7567 61.0072C63.0454 61.4203 63.0454 61.3076 63.9439 61.8333Z"
        fill="#0064DC"
      />
      <path
        d="M64.7671 60.9662C65.4784 60.5532 65.5158 60.6658 64.5799 60.1401C64.5241 60.1066 64.4616 60.0858 64.3969 60.0793C64.3322 60.0729 64.2669 60.0808 64.2056 60.1026C63.8312 60.3279 63.5317 60.4405 63.7938 60.5907C64.1307 60.7785 64.505 61.0789 64.7671 60.9662Z"
        fill="#0064DC"
      />
      <path
        d="M66.0023 60.5144C66.3767 60.2891 66.6761 60.1765 66.4141 60.0263C66.0397 59.801 65.7028 59.5006 65.4408 59.6508C65.0664 59.8761 64.7669 59.9887 65.029 60.1389C65.4033 60.3642 65.7777 60.6646 66.0023 60.5144Z"
        fill="#0064DC"
      />
      <path
        d="M64.3165 63.7086C64.3913 63.6711 64.3913 63.5584 64.279 63.4833C63.9047 63.258 63.5678 62.9576 63.3057 63.1078C62.9313 63.3331 62.6318 63.4458 62.8939 63.596C63.7924 64.1216 63.6052 64.1217 64.3165 63.7086Z"
        fill="#0064DC"
      />
      <path
        d="M64.7276 62.7313C64.8025 62.6937 64.8025 62.5811 64.6902 62.506C63.7543 61.9427 63.9789 61.9803 63.2676 62.3933C63.1927 62.4309 63.1927 62.5435 63.305 62.6186C64.2035 63.1819 64.0163 63.1443 64.7276 62.7313Z"
        fill="#0064DC"
      />
      <path
        d="M65.1769 62.581C65.2327 62.6145 65.2952 62.6353 65.3599 62.6417C65.4246 62.6482 65.49 62.6403 65.5513 62.6185C66.2626 62.2055 66.3 62.3181 65.3641 61.7924C65.3083 61.7589 65.2458 61.7382 65.1811 61.7317C65.1164 61.7252 65.051 61.7331 64.9897 61.7549C64.2784 62.1679 64.2784 62.0553 65.1769 62.581Z"
        fill="#0064DC"
      />
      <path
        d="M66.0029 61.6811C66.7142 61.268 66.7517 61.3807 65.8158 60.8549C65.76 60.8214 65.6975 60.8007 65.6328 60.7942C65.568 60.7877 65.5027 60.7956 65.4414 60.8174C65.067 61.0427 64.7675 61.1553 65.0296 61.3055C65.404 61.5308 65.7409 61.8313 66.0029 61.6811Z"
        fill="#0064DC"
      />
      <path
        d="M66.9004 61.2318C66.9562 61.2653 67.0187 61.2861 67.0834 61.2926C67.1481 61.299 67.2134 61.2911 67.2748 61.2694C67.6491 61.0441 67.9486 60.9314 67.6866 60.7812C67.3122 60.5559 66.9753 60.2555 66.7132 60.4057C66.0019 60.8187 66.0019 60.7061 66.9004 61.2318Z"
        fill="#0064DC"
      />
      <path
        d="M65.7025 65.0636C66.0769 64.8383 66.3763 64.7257 66.1143 64.5755C65.2158 64.0498 64.8415 63.7118 64.5794 63.862C64.205 64.0873 63.9055 64.2 64.1676 64.3502C65.0661 64.8759 65.4404 65.2138 65.7025 65.0636Z"
        fill="#0064DC"
      />
      <path
        d="M65.1408 63.7099C65.1966 63.7434 65.2591 63.7641 65.3238 63.7706C65.3885 63.7771 65.4538 63.7692 65.5151 63.7474C66.2264 63.3344 66.2639 63.447 65.328 62.9213C65.2722 62.8878 65.2097 62.8671 65.145 62.8606C65.0803 62.8541 65.0149 62.862 64.9536 62.8838C64.2423 63.2968 64.2049 63.1842 65.1408 63.7099Z"
        fill="#0064DC"
      />
      <path
        d="M66.3766 64.4579C66.4324 64.4915 66.4949 64.5122 66.5596 64.5187C66.6243 64.5252 66.6897 64.5173 66.751 64.4955C67.4623 64.0824 67.4997 64.1951 66.5638 63.6694C66.508 63.6359 66.4455 63.6151 66.3808 63.6086C66.3161 63.6022 66.2507 63.6101 66.1894 63.6318C65.4781 64.0449 65.4781 63.8947 66.3766 64.4579Z"
        fill="#0064DC"
      />
      <path
        d="M67.7616 63.8949C68.1359 63.6696 68.4354 63.557 68.1734 63.4068C66.8257 62.6182 66.5262 62.3178 66.2641 62.468C65.8898 62.6933 65.5903 62.806 65.8523 62.9562C67.1626 63.7447 67.4995 64.0451 67.7616 63.8949Z"
        fill="#0064DC"
      />
      <path
        d="M68.7713 63.3326C69.1457 63.1073 69.4452 62.9946 69.1831 62.8444C67.199 61.6804 66.937 61.4175 66.6749 61.5677C66.3006 61.793 66.0011 61.9057 66.2631 62.0559C68.2472 63.2199 68.5093 63.4828 68.7713 63.3326Z"
        fill="#0064DC"
      />
      <path
        d="M68.5462 62.0213C68.9206 61.796 69.2201 61.6833 68.958 61.5331C68.5837 61.3078 68.2468 61.0074 67.9847 61.1576C67.6103 61.3829 67.3109 61.4956 67.5729 61.6458C67.9098 61.8711 68.2842 62.1715 68.5462 62.0213Z"
        fill="#0064DC"
      />
      <path
        d="M69.7827 62.7689C70.494 62.3559 70.5314 62.4685 69.5955 61.9428C69.5397 61.9093 69.4773 61.8886 69.4125 61.8821C69.3478 61.8756 69.2825 61.8835 69.2212 61.9053C68.8468 62.1306 68.5473 62.2432 68.8094 62.3934C69.1837 62.5812 69.5581 62.9191 69.7827 62.7689Z"
        fill="#0064DC"
      />
      <path
        d="M54.172 53.6835C54.2278 53.7171 54.2903 53.7378 54.355 53.7443C54.4197 53.7508 54.4851 53.7429 54.5464 53.7211C55.2577 53.308 55.2951 53.4207 54.3592 52.895C54.3034 52.8615 54.2409 52.8407 54.1762 52.8342C54.1115 52.8277 54.0462 52.8357 53.9848 52.8574C53.2736 53.2705 53.2736 53.1578 54.172 53.6835Z"
        fill="#0064DC"
      />
      <path
        d="M56.2686 54.2078C56.3435 54.1703 56.3435 54.0576 56.2312 53.9825C55.2953 53.4193 55.5199 53.4569 54.8086 53.8699C54.7337 53.9074 54.7337 54.0201 54.8461 54.0952C55.7445 54.6209 55.5573 54.6209 56.2686 54.2078Z"
        fill="#0064DC"
      />
      <path
        d="M48.9295 54.9988C49.3039 54.7735 49.6034 54.6609 49.3413 54.5107C48.967 54.2854 48.6301 53.985 48.368 54.1352C47.9936 54.3605 47.6941 54.4731 47.9562 54.6233C48.3306 54.8111 48.7049 55.1115 48.9295 54.9988Z"
        fill="#71ADEE"
      />
      <path
        d="M50.1654 55.7127C50.5397 55.4874 50.8392 55.3747 50.5772 55.2245C50.2028 54.9992 49.8659 54.6988 49.6038 54.849C49.2295 55.0743 48.93 55.187 49.192 55.3372C49.5664 55.5625 49.9408 55.8629 50.1654 55.7127Z"
        fill="#71ADEE"
      />
      <path
        d="M50.5399 54.7345C50.9143 54.5092 51.2137 54.3966 50.9517 54.2464C50.0532 53.7207 49.6789 53.3827 49.4168 53.5329C49.0424 53.7582 48.7429 53.8709 49.005 54.0211C49.9035 54.5843 50.2778 54.8847 50.5399 54.7345Z"
        fill="#71ADEE"
      />
      <path
        d="M51.8495 54.3615C52.2238 54.1362 52.5233 54.0235 52.2613 53.8733C50.9884 53.1223 50.6515 52.8219 50.3895 52.9721C50.0151 53.1974 49.7156 53.3101 49.9777 53.4603C51.2505 54.2113 51.5874 54.5117 51.8495 54.3615Z"
        fill="#71ADEE"
      />
      <path
        d="M51.4383 56.4656C51.8127 56.2403 52.1122 56.1276 51.8501 55.9774C51.4758 55.7522 51.1388 55.4518 50.8768 55.602C50.5024 55.8273 50.2029 55.9399 50.465 56.0901C50.8393 56.3154 51.1763 56.6158 51.4383 56.4656Z"
        fill="#71ADEE"
      />
      <path
        d="M51.4751 55.4886C51.5309 55.5221 51.5934 55.5429 51.6581 55.5494C51.7228 55.5559 51.7882 55.5479 51.8495 55.5262C52.2238 55.3009 52.5233 55.1882 52.2613 55.038C51.8869 54.8127 51.55 54.5123 51.2879 54.6625C50.5766 55.0756 50.5392 54.9254 51.4751 55.4886Z"
        fill="#71ADEE"
      />
      <path
        d="M52.7487 55.0751C52.8045 55.1087 52.867 55.1294 52.9317 55.1359C52.9964 55.1424 53.0617 55.1345 53.1231 55.1127C53.8343 54.6996 53.8718 54.8123 52.9359 54.2866C52.8801 54.2531 52.8176 54.2323 52.7529 54.2258C52.6882 54.2193 52.6228 54.2273 52.5615 54.249C51.8502 54.6621 51.8502 54.5494 52.7487 55.0751Z"
        fill="#71ADEE"
      />
      <path
        d="M53.5721 54.1714C53.9465 53.9461 54.246 53.8334 53.9839 53.6832C51.2885 52.0686 51.7752 52.1437 50.9516 52.6318C50.8767 52.6694 50.8767 52.782 50.989 52.8571C53.0855 54.0963 53.3101 54.3216 53.5721 54.1714Z"
        fill="#71ADEE"
      />
      <path
        d="M52.974 52.8215C53.0298 52.855 53.0923 52.8757 53.157 52.8822C53.2217 52.8887 53.287 52.8808 53.3483 52.859C54.0971 52.446 54.1345 52.5962 52.8617 51.8452C52.8059 51.8117 52.7434 51.7909 52.6787 51.7844C52.614 51.7779 52.5486 51.7859 52.4873 51.8076C51.7012 52.2207 51.7012 52.0705 52.974 52.8215Z"
        fill="#71ADEE"
      />
      <path
        d="M52.6727 57.2136C53.0471 56.9883 53.3466 56.8757 53.0845 56.7255C52.7101 56.5002 52.3732 56.1998 52.1112 56.35C51.7368 56.5753 51.4373 56.6879 51.6994 56.8381C52.0737 57.0259 52.4107 57.3263 52.6727 57.2136Z"
        fill="#71ADEE"
      />
      <path
        d="M53.085 56.2416C53.7963 55.8285 53.8337 55.9412 52.8978 55.4155C52.842 55.382 52.7795 55.3612 52.7148 55.3547C52.6501 55.3482 52.5847 55.3562 52.5234 55.3779C52.1491 55.6032 51.8496 55.7159 52.1116 55.8661C52.486 56.0914 52.8229 56.3918 53.085 56.2416Z"
        fill="#71ADEE"
      />
      <path
        d="M54.021 55.8265C54.0768 55.86 54.1393 55.8808 54.204 55.8872C54.2687 55.8937 54.3341 55.8858 54.3954 55.864C54.7697 55.6387 55.0692 55.5261 54.8072 55.3759C54.4328 55.1506 54.0959 54.8502 53.8338 55.0004C53.1225 55.4134 53.0851 55.2632 54.021 55.8265Z"
        fill="#71ADEE"
      />
      <path
        d="M54.4341 54.9251C54.4899 54.9586 54.5524 54.9794 54.6171 54.9859C54.6818 54.9924 54.7471 54.9844 54.8084 54.9627C55.1828 54.7374 55.4823 54.6247 55.2202 54.4745C54.8459 54.2492 54.509 53.9488 54.2469 54.099C53.5356 54.4745 53.5356 54.3619 54.4341 54.9251Z"
        fill="#71ADEE"
      />
      <path
        d="M53.9085 57.9275C54.2829 57.7022 54.5824 57.5896 54.3203 57.4394C53.946 57.2141 53.6091 56.9137 53.347 57.0639C52.9726 57.2892 52.6731 57.4018 52.9352 57.552C53.3096 57.7773 53.6465 58.0777 53.9085 57.9275Z"
        fill="#71ADEE"
      />
      <path
        d="M54.3208 56.9896C55.0321 56.5766 55.0695 56.6892 54.1336 56.1635C54.0778 56.13 54.0153 56.1093 53.9506 56.1028C53.8859 56.0963 53.8206 56.1042 53.7593 56.126C53.3849 56.3513 53.0854 56.4639 53.3475 56.6141C53.7218 56.8019 54.0588 57.1023 54.3208 56.9896Z"
        fill="#71ADEE"
      />
      <path
        d="M55.6312 56.5779C56.0056 56.3526 56.3051 56.24 56.043 56.0898C55.6686 55.8645 55.3317 55.5641 55.0697 55.7143C54.6953 55.9396 54.3958 56.0522 54.6579 56.2024C55.0322 56.4277 55.3691 56.7281 55.6312 56.5779Z"
        fill="#71ADEE"
      />
      <path
        d="M56.0428 55.6765C56.4172 55.4512 56.7167 55.3386 56.4546 55.1884C56.0803 54.9631 55.7433 54.6627 55.4813 54.8129C55.1069 55.0382 54.8074 55.1508 55.0695 55.301C55.4439 55.5263 55.8182 55.8267 56.0428 55.6765Z"
        fill="#71ADEE"
      />
      <path
        d="M57.0907 55.076C57.465 54.8507 57.7645 54.738 57.5025 54.5878C57.1281 54.3625 56.7912 54.0621 56.5291 54.2123C56.1548 54.4376 55.8553 54.5503 56.1174 54.7005C56.4543 54.9258 56.8286 55.2262 57.0907 55.076Z"
        fill="#71ADEE"
      />
      <path
        d="M55.1449 58.6795C55.5192 58.4542 55.8187 58.3415 55.5567 58.1913C55.1823 57.966 54.8454 57.6656 54.5833 57.8158C54.209 58.0411 53.9095 58.1538 54.1715 58.304C54.5459 58.5293 54.9203 58.8297 55.1449 58.6795Z"
        fill="#71ADEE"
      />
      <path
        d="M55.1823 57.7041C55.2381 57.7376 55.3006 57.7583 55.3653 57.7648C55.43 57.7713 55.4953 57.7634 55.5566 57.7416C56.2679 57.3285 56.3054 57.4412 55.3695 56.9155C55.3137 56.882 55.2512 56.8612 55.1865 56.8547C55.1218 56.8482 55.0564 56.8562 54.9951 56.8779C54.2838 57.2534 54.2838 57.1408 55.1823 57.7041Z"
        fill="#71ADEE"
      />
      <path
        d="M56.4932 57.2894C56.549 57.3229 56.6115 57.3436 56.6762 57.3501C56.7409 57.3566 56.8062 57.3487 56.8675 57.3269C57.2419 57.1016 57.5414 56.989 57.2793 56.8388C56.905 56.6135 56.568 56.3131 56.306 56.4633C55.5947 56.8763 55.5947 56.7637 56.4932 57.2894Z"
        fill="#71ADEE"
      />
      <path
        d="M57.7652 56.1618C57.8401 56.1242 57.8401 56.0115 57.7278 55.9364C57.3534 55.7111 57.0165 55.4107 56.7544 55.5609C56.3801 55.7862 56.0806 55.8989 56.3427 56.0491C57.2411 56.5748 57.0539 56.5373 57.7652 56.1618Z"
        fill="#71ADEE"
      />
      <path
        d="M56.3797 59.3926C56.7541 59.1673 57.0536 59.0546 56.7915 58.9044C55.8556 58.3412 56.0802 58.3787 55.3689 58.7918C55.2941 58.8293 55.2941 58.942 55.4064 59.0171C55.7807 59.2424 56.1551 59.5428 56.3797 59.3926Z"
        fill="#71ADEE"
      />
      <path
        d="M56.7909 58.4558C57.1652 58.2305 57.4647 58.1179 57.2027 57.9677C56.8283 57.7424 56.4914 57.442 56.2293 57.5922C55.855 57.8175 55.5555 57.9301 55.8175 58.0803C56.1919 58.3056 56.5663 58.606 56.7909 58.4558Z"
        fill="#71ADEE"
      />
      <path
        d="M58.1019 58.0391C58.4763 57.8138 58.7758 57.7011 58.5137 57.5509C57.5778 56.9877 57.8024 57.0252 57.0911 57.4383C57.0163 57.4758 57.0163 57.5885 57.1286 57.6636C57.5029 57.8889 57.8773 58.1893 58.1019 58.0391Z"
        fill="#71ADEE"
      />
      <path
        d="M58.5511 57.1394C58.9255 56.9141 59.225 56.8015 58.9629 56.6513C58.5886 56.426 58.2516 56.1256 57.9896 56.2758C57.6152 56.5011 57.3157 56.6137 57.5778 56.7639C57.9521 56.9892 58.2891 57.2896 58.5511 57.1394Z"
        fill="#71ADEE"
      />
      <path
        d="M57.6523 60.143C58.3636 59.7299 58.4011 59.8426 57.4652 59.3169C57.4094 59.2833 57.3469 59.2626 57.2822 59.2561C57.2175 59.2496 57.1521 59.2575 57.0908 59.2793C56.7164 59.5046 56.417 59.6173 56.679 59.7675C57.0159 59.9928 57.3903 60.2932 57.6523 60.143Z"
        fill="#71ADEE"
      />
      <path
        d="M58.0638 59.2039C58.4382 58.9786 58.7377 58.8659 58.4756 58.7157C58.1013 58.4904 57.7643 58.19 57.5023 58.3402C57.1279 58.5655 56.8284 58.6782 57.0905 58.8284C57.4274 59.0161 57.8018 59.3166 58.0638 59.2039Z"
        fill="#71ADEE"
      />
      <path
        d="M59.3377 58.791C59.7121 58.5657 60.0116 58.4531 59.7495 58.3029C58.8136 57.7396 59.0383 57.7772 58.327 58.1902C58.2521 58.2278 58.2521 58.3404 58.3644 58.4155C58.7388 58.6408 59.1131 58.9412 59.3377 58.791Z"
        fill="#71ADEE"
      />
      <path
        d="M59.786 57.8926C60.1604 57.6673 60.4598 57.5546 60.1978 57.4044C59.2619 56.8412 59.4865 56.8787 58.7752 57.2918C58.7003 57.3293 58.7004 57.442 58.8127 57.5171C59.187 57.7048 59.5239 58.0428 59.786 57.8926Z"
        fill="#71ADEE"
      />
      <path
        d="M58.8887 60.8939C59.6 60.4809 59.6374 60.5935 58.7015 60.0678C58.6457 60.0343 58.5832 60.0136 58.5185 60.0071C58.4538 60.0006 58.3884 60.0085 58.3271 60.0303C57.9528 60.2556 57.6533 60.3682 57.9153 60.5184C58.2897 60.7062 58.6266 61.0066 58.8887 60.8939Z"
        fill="#71ADEE"
      />
      <path
        d="M59.2997 59.917C59.674 59.6917 59.9735 59.579 59.7115 59.4288C58.7755 58.8656 59.0002 58.9031 58.2889 59.3162C58.214 59.3537 58.214 59.4664 58.3263 59.5415C58.7007 59.7668 59.0376 60.0672 59.2997 59.917Z"
        fill="#71ADEE"
      />
      <path
        d="M60.6107 59.5447C60.9851 59.3194 61.2845 59.2068 61.0225 59.0566C60.6481 58.8313 60.3112 58.5309 60.0491 58.6811C59.6748 58.9064 59.3753 59.019 59.6373 59.1692C59.9743 59.357 60.3486 59.6574 60.6107 59.5447Z"
        fill="#71ADEE"
      />
      <path
        d="M61.4713 58.3434C61.5461 58.3058 61.5461 58.1932 61.4338 58.1181C61.0595 57.8928 60.7225 57.5924 60.4605 57.7426C60.0861 57.9679 59.7866 58.0805 60.0487 58.2307C60.9846 58.794 60.76 58.7565 61.4713 58.3434Z"
        fill="#71ADEE"
      />
      <path
        d="M60.123 61.6088C60.8343 61.1957 60.8718 61.3084 59.9359 60.7827C59.8801 60.7492 59.8176 60.7284 59.7529 60.7219C59.6882 60.7154 59.6228 60.7234 59.5615 60.7451C59.1871 60.9704 58.8876 61.0831 59.1497 61.2333C59.5241 61.4586 59.861 61.759 60.123 61.6088Z"
        fill="#71ADEE"
      />
      <path
        d="M60.5361 60.6703C61.2474 60.2573 61.2849 60.3699 60.349 59.8442C60.2932 59.8107 60.2307 59.7899 60.166 59.7834C60.1012 59.777 60.0359 59.7849 59.9746 59.8066C59.6002 60.0319 59.3007 60.1446 59.5628 60.2948C59.9372 60.5201 60.2741 60.8205 60.5361 60.6703Z"
        fill="#71ADEE"
      />
      <path
        d="M61.8457 60.2562C62.557 59.8432 62.5944 59.9558 61.6585 59.4301C61.6027 59.3966 61.5402 59.3759 61.4755 59.3694C61.4108 59.3629 61.3455 59.3708 61.2842 59.3926C60.9098 59.6179 60.6103 59.7305 60.8724 59.8807C61.2467 60.106 61.5837 60.4064 61.8457 60.2562Z"
        fill="#71ADEE"
      />
      <path
        d="M62.2573 59.3539C62.9686 58.9408 63.0061 59.0535 62.0701 58.5278C62.0143 58.4943 61.9519 58.4735 61.8871 58.467C61.8224 58.4606 61.7571 58.4685 61.6958 58.4902C61.3214 58.7155 61.0219 58.8282 61.284 58.9784C61.6583 59.2037 62.0327 59.5041 62.2573 59.3539Z"
        fill="#71ADEE"
      />
      <path
        d="M61.3587 62.3601C61.7331 62.1348 62.0326 62.0222 61.7705 61.872C61.3962 61.6467 61.0592 61.3463 60.7972 61.4965C60.4228 61.7218 60.1233 61.8344 60.3854 61.9846C60.7598 62.2099 61.1341 62.5103 61.3587 62.3601Z"
        fill="#71ADEE"
      />
      <path
        d="M61.7705 61.4178C62.4818 61.0048 62.5192 61.1174 61.5833 60.5917C61.3213 60.4415 61.1715 60.5917 60.7597 60.817C60.6849 60.8546 60.6849 60.9672 60.7972 61.0423C61.1715 61.2301 61.5459 61.5305 61.7705 61.4178Z"
        fill="#71ADEE"
      />
      <path
        d="M63.0801 61.0072C63.7914 60.5942 63.8288 60.7068 62.8929 60.1811C62.8371 60.1476 62.7746 60.1269 62.7099 60.1204C62.6452 60.1139 62.5798 60.1218 62.5185 60.1436C62.1442 60.3689 61.8447 60.4815 62.1067 60.6317C62.4811 60.857 62.818 61.1574 63.0801 61.0072Z"
        fill="#71ADEE"
      />
      <path
        d="M63.9786 59.8424C64.0535 59.8049 64.0535 59.6922 63.9411 59.6171C63.5668 59.3918 63.2299 59.0914 62.9678 59.2416C62.5934 59.4669 62.294 59.5796 62.556 59.7298C63.4545 60.2555 63.2673 60.2179 63.9786 59.8424Z"
        fill="#71ADEE"
      />
      <path
        d="M64.765 59.6538C65.1394 59.4285 65.4388 59.3159 65.1768 59.1657C57.8767 54.8474 58.0265 54.8099 57.7644 54.9601C57.39 55.1854 57.0906 55.298 57.3526 55.4482C64.6527 59.804 64.5029 59.804 64.765 59.6538Z"
        fill="#71ADEE"
      />
      <path
        d="M63.0811 62.8483C63.156 62.8107 63.156 62.6981 63.0437 62.623C62.6693 62.3977 62.3324 62.0973 62.0703 62.2475C61.696 62.4728 61.3965 62.5854 61.6585 62.7356C62.557 63.2613 62.3698 63.2238 63.0811 62.8483Z"
        fill="#71ADEE"
      />
      <path
        d="M63.0062 62.1326C63.3806 61.9073 63.6801 61.7947 63.418 61.6444C63.0436 61.4191 62.7067 61.1187 62.4447 61.2689C62.0703 61.4942 61.7708 61.6069 62.0329 61.7571C62.4072 61.9824 62.7816 62.2828 63.0062 62.1326Z"
        fill="#71ADEE"
      />
      <path
        d="M63.9429 61.721C63.9987 61.7545 64.0612 61.7753 64.1259 61.7817C64.1906 61.7882 64.2559 61.7803 64.3172 61.7586C64.6916 61.5333 64.9911 61.4206 64.729 61.2704C64.3547 61.0451 64.0178 60.7447 63.7557 60.8949C63.0444 61.308 63.0444 61.1578 63.9429 61.721Z"
        fill="#71ADEE"
      />
      <path
        d="M64.7661 60.8217C65.4774 60.4086 65.5148 60.5213 64.5789 59.9956C64.5231 59.962 64.4607 59.9413 64.3959 59.9348C64.3312 59.9283 64.2659 59.9363 64.2046 59.958C63.8302 60.1833 63.5307 60.296 63.7928 60.4462C64.1671 60.6715 64.5041 60.9719 64.7661 60.8217Z"
        fill="#71ADEE"
      />
      <path
        d="M66.0013 60.406C66.3757 60.1807 66.6752 60.0681 66.4131 59.9179C66.0388 59.6926 65.7018 59.3922 65.4398 59.5424C65.0654 59.7677 64.7659 59.8803 65.028 60.0305C65.4023 60.2183 65.7767 60.5187 66.0013 60.406Z"
        fill="#71ADEE"
      />
      <path
        d="M64.3155 63.5602C64.3904 63.5226 64.3904 63.41 64.2781 63.3349C63.9037 63.1096 63.5668 62.8092 63.3047 62.9594C62.9304 63.1847 62.6309 63.2973 62.8929 63.4475C63.7914 64.0108 63.6042 63.9732 64.3155 63.5602Z"
        fill="#71ADEE"
      />
      <path
        d="M64.7271 62.6229C64.802 62.5853 64.802 62.4727 64.6897 62.3976C63.7538 61.8343 63.9784 61.8719 63.2671 62.2849C63.1922 62.3225 63.1922 62.4351 63.3045 62.5102C64.203 63.0359 64.0158 63.0359 64.7271 62.6229Z"
        fill="#71ADEE"
      />
      <path
        d="M65.1759 62.4726C65.2317 62.5061 65.2942 62.5268 65.3589 62.5333C65.4236 62.5398 65.489 62.5319 65.5503 62.5101C66.2616 62.0971 66.299 62.2097 65.3631 61.684C65.3073 61.6505 65.2448 61.6298 65.1801 61.6233C65.1154 61.6168 65.0501 61.6247 64.9888 61.6465C64.2775 62.022 64.2775 61.9093 65.1759 62.4726Z"
        fill="#71ADEE"
      />
      <path
        d="M66.002 61.5717C66.7132 61.1586 66.7507 61.2713 65.8148 60.7456C65.759 60.712 65.6965 60.6913 65.6318 60.6848C65.5671 60.6783 65.5017 60.6863 65.4404 60.708C65.066 60.9333 64.7666 61.046 65.0286 61.1962C65.403 61.4215 65.7399 61.7219 66.002 61.5717Z"
        fill="#71ADEE"
      />
      <path
        d="M66.8994 61.1204C66.9552 61.1539 67.0177 61.1747 67.0824 61.1812C67.1471 61.1877 67.2125 61.1798 67.2738 61.158C67.6481 60.9327 67.9476 60.82 67.6856 60.6698C67.3112 60.4445 66.9743 60.1441 66.7122 60.2943C66.0009 60.7074 66.0009 60.5572 66.8994 61.1204Z"
        fill="#71ADEE"
      />
      <path
        d="M65.7015 64.9152C66.0759 64.6899 66.3754 64.5772 66.1133 64.427C65.2148 63.9013 64.8405 63.5634 64.5784 63.7136C64.2041 63.9389 63.9046 64.0515 64.1666 64.2017C65.0651 64.765 65.4395 65.0654 65.7015 64.9152Z"
        fill="#71ADEE"
      />
      <path
        d="M65.1393 63.5986C65.1951 63.6321 65.2576 63.6528 65.3223 63.6593C65.387 63.6658 65.4524 63.6579 65.5137 63.6361C66.225 63.2231 66.2624 63.3357 65.3265 62.81C65.2707 62.7765 65.2082 62.7558 65.1435 62.7493C65.0788 62.7428 65.0134 62.7507 64.9521 62.7725C64.2408 63.148 64.2034 63.0353 65.1393 63.5986Z"
        fill="#71ADEE"
      />
      <path
        d="M66.3756 64.3095C66.4314 64.343 66.4939 64.3638 66.5586 64.3703C66.6233 64.3768 66.6887 64.3688 66.75 64.3471C67.4613 63.934 67.4987 64.0467 66.5628 63.521C66.507 63.4874 66.4445 63.4667 66.3798 63.4602C66.3151 63.4537 66.2498 63.4616 66.1885 63.4834C65.4772 63.8965 65.4772 63.7838 66.3756 64.3095Z"
        fill="#71ADEE"
      />
      <path
        d="M67.7606 63.7875C68.135 63.5622 68.4344 63.4496 68.1724 63.2994C66.8247 62.5108 66.5252 62.2104 66.2632 62.3606C65.8888 62.5859 65.5893 62.6986 65.8514 62.8488C67.1616 63.6373 67.4985 63.9377 67.7606 63.7875Z"
        fill="#71ADEE"
      />
      <path
        d="M68.7718 63.2242C69.1462 62.9989 69.4457 62.8862 69.1836 62.736C67.1995 61.572 66.9375 61.3091 66.6754 61.4593C66.301 61.6846 66.0015 61.7973 66.2636 61.9475C68.2477 63.1115 68.5098 63.3744 68.7718 63.2242Z"
        fill="#71ADEE"
      />
      <path
        d="M68.5453 61.8719C68.9196 61.6466 69.2191 61.5339 68.9571 61.3837C68.5827 61.1584 68.2458 60.858 67.9837 61.0082C67.6094 61.2335 67.3099 61.3461 67.5719 61.4964C67.9463 61.7217 68.2832 62.0221 68.5453 61.8719Z"
        fill="#71ADEE"
      />
      <path
        d="M69.7817 62.6205C70.493 62.2074 70.5305 62.3201 69.5946 61.7944C69.5388 61.7609 69.4763 61.7401 69.4116 61.7336C69.3469 61.7272 69.2815 61.7351 69.2202 61.7568C68.8458 61.9821 68.5463 62.0948 68.8084 62.245C69.1828 62.4703 69.5571 62.7707 69.7817 62.6205Z"
        fill="#71ADEE"
      />
      <path
        d="M54.1711 53.5722C54.2268 53.6057 54.2893 53.6265 54.354 53.6329C54.4188 53.6394 54.4841 53.6315 54.5454 53.6097C55.2567 53.1967 55.2941 53.3094 54.3582 52.7837C54.3024 52.7501 54.2399 52.7294 54.1752 52.7229C54.1105 52.7164 54.0452 52.7243 53.9839 52.7461C53.2726 53.1592 53.2726 53.0465 54.1711 53.5722Z"
        fill="#71ADEE"
      />
      <path
        d="M56.2676 54.0995C56.3425 54.0619 56.3425 53.9493 56.2302 53.8742C55.2943 53.3109 55.5189 53.3485 54.8076 53.7615C54.7328 53.7991 54.7328 53.9117 54.8451 53.9868C55.781 54.5125 55.5564 54.475 56.2676 54.0995Z"
        fill="#71ADEE"
      />
      <path
        d="M62.9684 52.3353L60.1233 53.9499C60.011 54.025 60.011 54.1377 60.1607 54.2128C65.3269 57.2919 65.2895 57.3294 65.589 57.1792L68.4341 55.5646C68.5464 55.4895 68.5464 55.3768 68.3967 55.3017C63.193 52.2226 63.2305 52.1475 62.9684 52.3353Z"
        fill="#71ADEE"
      />
      <path
        d="M65.2124 58.9038C65.1749 59.5797 63.9021 60.1806 63.4529 60.481C63.0525 60.7153 62.6401 60.9284 62.2175 61.1193C61.057 61.8703 61.1693 62.3585 61.7682 62.4336C62.2923 62.5462 64.1642 61.5699 64.7631 61.1193C64.176 61.6227 63.5205 62.0399 62.8165 62.3585C62.5918 62.4711 62.2175 62.3585 62.18 62.7715C62.1426 63.1846 62.7041 63.4099 63.2283 63.147C63.9211 62.8069 64.5959 62.4309 65.2498 62.0205C65.0034 62.3627 64.6836 62.645 64.3139 62.8466C64.0144 62.9593 63.378 63.0719 63.4529 63.3723C63.5277 63.6727 64.1267 63.7103 64.3139 63.6727C65.0252 63.5976 65.3996 63.147 65.9611 62.7715C65.8835 62.9354 65.7826 63.0872 65.6616 63.2221C65.5119 63.3348 65.1375 63.5225 65.1001 63.6352C65.0626 63.7478 65.0252 63.8229 65.1001 63.8229C67.3837 64.0482 68.5442 62.9968 70.0042 61.232C70.0042 61.232 69.9293 61.2695 70.1165 61.1193C70.5313 60.7739 70.9192 60.3973 71.277 59.9928C71.4939 59.6329 71.6816 59.2562 71.8386 58.8663C71.9883 58.4532 71.5765 57.89 71.5017 57.552C71.3519 56.8761 69.7047 56.6133 69.6298 56.6133C67.496 56.9137 65.8488 57.139 64.1267 57.8149C63.6026 58.0402 62.9288 58.0777 62.9662 58.5659C62.9288 59.0916 64.8754 58.9414 65.2124 58.9038Z"
        fill="#ABE0F6"
      />
      <path
        opacity="0.5"
        d="M70.1889 58.7176C70.3358 59.1202 70.3951 59.5496 70.3628 59.9771C70.3305 60.4046 70.2074 60.8202 70.0017 61.1959C70.3386 60.8204 70.9376 60.4074 71.1997 60.0319C71.4312 59.6803 71.6197 59.3021 71.7612 58.9054C71.8735 58.4923 71.6115 57.9291 71.3869 57.5911C71.0125 56.9152 70.0017 56.6523 69.4776 56.6523C69.1577 56.6651 68.8419 56.7285 68.5417 56.8401C68.2797 56.8776 68.5043 56.8025 68.3545 56.8776C68.3545 56.8776 68.392 56.8401 68.3171 56.8776C68.2422 56.9152 68.055 56.9152 67.9802 56.9152C68.4635 57.0207 68.9153 57.2389 69.299 57.552C69.6827 57.8651 69.9876 58.2644 70.1889 58.7176V58.7176Z"
        fill="#FFCFCD"
      />
      <path
        d="M84.9407 52.5577C83.1064 54.285 81.3469 55.3364 79.1756 56.5005C76.5505 57.9034 73.8368 59.1326 71.0519 60.1804C71.0519 59.3168 71.1268 58.8662 70.6027 57.9274C70.5278 57.8148 69.4796 56.6883 68.9929 56.7258L68.8806 56.6882C71.0145 55.4491 72.2499 54.3977 74.3089 52.9708C76.3679 51.5439 78.2771 50.0794 80.4484 48.3897C81.2346 45.9864 87.1121 36.674 88.048 35.8479C89.3582 34.7214 90.6311 34.4961 91.6418 34.9091C93.1393 35.4724 93.4388 37.6878 93.4762 37.9882C93.7383 39.9033 91.1926 43.4706 91.0429 43.771C87.9356 49.2158 87.2618 50.3798 84.9407 52.5577Z"
        fill="#FFDC1E"
      />
      <path
        d="M62.9664 50.6429C63.3559 50.9109 63.6751 51.2695 63.8967 51.6879C64.1183 52.1063 64.2359 52.5723 64.2393 53.0461L63.7526 68.2164C63.7151 69.08 63.1162 69.418 62.3674 68.9674L42.7883 56.8387C42.3988 56.5706 42.0796 56.2121 41.858 55.7937C41.6364 55.3752 41.5189 54.9093 41.5155 54.4355L42.0022 39.2652C42.0396 38.4015 42.6386 38.0636 43.3873 38.5142L62.9664 50.6429Z"
        fill="#E2F6FF"
      />
      <path
        d="M64.6122 52.5962C64.555 52.1606 64.4152 51.74 64.2004 51.3571C63.6763 50.4934 64.6871 51.282 43.7977 38.3272C43.1238 37.9141 43.1238 38.0268 42.4874 38.3647C42.646 38.2999 42.8183 38.2762 42.9885 38.2959C43.1586 38.3156 43.321 38.378 43.4607 38.4774C43.6105 38.5525 63.0399 50.6436 63.1148 50.6812C63.4365 50.9297 63.7051 51.2406 63.9044 51.5954C64.1038 51.9502 64.2299 52.3417 64.2753 52.7464C64.2753 52.9342 64.3127 52.033 63.7886 68.1796C63.7962 68.3464 63.7626 68.5125 63.6907 68.6632C63.6189 68.8139 63.5111 68.9444 63.3768 69.0432L63.7137 68.8555C63.8539 68.7624 63.9662 68.6329 64.0387 68.4808C64.1113 68.3287 64.1413 68.1597 64.1255 67.9918C64.6496 51.8452 64.6122 52.5962 64.6122 52.5962Z"
        fill="#71ADEE"
      />
      <path
        d="M50.4243 52.4429C50.4243 51.767 50.9858 51.5417 51.6596 51.9172C52.3335 52.2927 52.895 53.2315 52.8576 53.9074C52.8202 54.5833 52.2961 54.8461 51.6222 54.4331C51.2849 54.2145 51.0017 53.9216 50.7941 53.5768C50.5866 53.2319 50.4601 52.8441 50.4243 52.4429V52.4429Z"
        fill="#71ADEE"
      />
      <path
        d="M76.6314 69.5718C76.6314 67.9947 77.6422 66.6053 78.7653 65.8543C79.5514 65.3662 100.815 52.937 104.072 51.2097C104.687 50.855 105.385 50.6734 106.094 50.684C108.228 50.7591 111.297 52.4489 112.42 54.4766C112.754 55.0827 112.873 55.7842 112.757 56.4668C112.383 58.7949 111.035 65.629 110.96 66.0796C108.34 79.823 109.875 77.1194 102.425 81.4001C84.5305 91.7639 84.7551 92.0644 82.5089 91.6138C79.9632 91.088 76.856 88.9852 76.7063 86.2065C76.6689 85.9061 76.6314 69.8346 76.6314 69.5718Z"
        fill="#3989E5"
      />
      <path
        d="M85.6146 70.6261C85.1819 70.9121 84.8214 71.295 84.5615 71.7446C84.3016 72.1943 84.1495 72.6984 84.1172 73.2171C84.1172 78.7745 84.1546 84.2944 84.1546 89.8518C84.1546 90.7906 84.8284 91.2037 85.652 90.7155C92.8772 86.5099 100.14 82.3418 107.365 78.1362C108.825 77.2725 108.975 74.9069 112.531 56.3195C112.494 55.7938 111.82 55.4183 110.996 55.9065C102.536 60.8255 94.0752 65.7071 85.6146 70.6261Z"
        fill="#71ADEE"
      />
      <defs>
        <linearGradient
          x1="69.625"
          y1="24.3437"
          x2="73.1862"
          y2="25.3423"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAFAFA" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          x1="69.625"
          y1="24.3437"
          x2="73.1862"
          y2="25.3423"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#280008" />
          <stop offset="1" stopColor="#4D1725" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default PremiumProductIcon;
