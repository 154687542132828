import { putRequest, getRequest } from "./request";
import {
  PLANS_ENDPOINT,
  UPGRADE_PLANS_ENDPOINT,
  CART_ENDPOINT,
} from "../constants/routes";

export const submitPlans = (config) => putRequest(CART_ENDPOINT, config);

export const getPlans = (config) => getRequest(PLANS_ENDPOINT, config);
export const getUpgradePlans = (config) =>
  getRequest(UPGRADE_PLANS_ENDPOINT, config);
