import React, { useState } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { reduxForm, Field } from "redux-form";

import { Collapse } from "@material-ui/core";
import {
  PROMO_CODE_FORM_ID,
  PROMO_CODE_FIELD_ID,
} from "../../../constants/forms";
import { ErrorMessage } from "../../ui/Error";
import { SecondaryButton } from "../../ui/Button";
import { ArrowBlueIcon } from "../../ui/icons/ArrowIcons";
import { SmallBody } from "../../ui/Typography";
import TooltipTrigger from "../../ui/TooltipTrigger";
import TextInput from "../../ui/inputs/TextInput";
import { animationSpeed } from "../../../config/Theme";
import { selectIsCollapsedPromoFieldEnabled } from "../../../selectors/feature-flags";

const PromoCodeCollapsibleForm = ({
  handleSubmit,
  error,
  translations,
  isLoading,
  displayTooltipModal,
}) => {
  const defaultState = useSelector(selectIsCollapsedPromoFieldEnabled);
  const [collapsed, setCollapsed] = useState(defaultState);
  const handleCollapse = (e, isFocusing) => {
    // don't collapse if user is focusing on the
    // collapsible and it is already exapanded
    if (isFocusing && !collapsed) {
      return;
    }
    // prevent triggering the collapsible when clicking on the tooltip icon
    const tooltipTags = ["path", "svg", "button"];
    const targetTagName = e.target.tagName.toLowerCase();
    if (!tooltipTags.includes(targetTagName)) {
      setCollapsed(!collapsed);
    }
  };
  return (
    <Box mt={2}>
      <Box
        tabIndex={0}
        onMouseDown={(e) => e.preventDefault()} // prevent triggering focus on click
        onClick={(e) => handleCollapse(e)}
        onFocus={(e) => handleCollapse(e, true)}
      >
        <label
          htmlFor="promo-code-collapsible"
          aria-label={translations.promoCodeAriaLabel}
        >
          <Box
            id="promo-code-collapsible"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            aria-expanded={!collapsed}
          >
            <SmallBody>
              {`${translations.promoCodeLabel} `}
              {translations.label.optional}

              <TooltipTrigger
                tabIndex={-1}
                translations={translations}
                onClick={() => displayTooltipModal("checkout.PromoCode")}
              />
            </SmallBody>
            <Box style={{ cursor: "pointer" }}>
              <ArrowBlueIcon up={collapsed} />
            </Box>
          </Box>
        </label>
      </Box>

      <Collapse in={!collapsed} timeout={animationSpeed.fast}>
        <form onSubmit={handleSubmit}>
          <Grid container item spacing={1} alignItems="flex-start">
            <Grid item lg={7} md={9} xs={12}>
              <Field
                name={PROMO_CODE_FIELD_ID}
                component={TextInput}
                noMargin
                placeholder={translations.promoPlaceholder}
                translations={translations}
                displayTooltipModal={displayTooltipModal}
              />
            </Grid>
            <Grid item lg={5} md={3}>
              <Box pt={0.5} display="flex" height="100%" maxWidth="100%">
                <SecondaryButton
                  fullWidth
                  displayArrowRight
                  loadingLabel={translations.promoCodeIsLoading}
                  isLoading={isLoading}
                  text={translations.button.apply}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
        {error && <ErrorMessage error={error} />}
      </Collapse>
    </Box>
  );
};

export default reduxForm({ form: PROMO_CODE_FORM_ID })(
  PromoCodeCollapsibleForm,
);
