import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { getFormValues } from "redux-form";

import {
  BACKUPS_FORM_ID,
  BACKUPS_FIELD_ID,
  DONATIONS_FORM_ID,
} from "../constants/forms";
import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";
import { selectTranslations } from "./translations";
import {
  selectIsAddingCharity,
  selectIsAddingPerson,
  selectIsAddingBeneficiary,
  selectShouldDisplayAllocationHelper,
  selectIsCustomCharity,
} from "./beneficiary";
import { selectIsCouplesPlan } from "./plans";
import { selectCharitiesOptionList, selectCharities } from "./donations";
import { selectIsFieldComplete } from "./utils/progress";

const selectYourEstateData = R.pathOr({}, ["yourEstate"]);
export const selectHasSkippedBackup = R.path(["yourEstate", "backupsVisited"]);

export const selectBackup = createSelector(
  selectYourEstateData,
  R.prop(BACKUPS_FIELD_ID),
);

export const selectBackupForm = createSelector(selectBackup, (backup) => ({
  [BACKUPS_FIELD_ID]: backup,
}));

export const selectIsBackupComplete = createSelector(
  selectIsFieldComplete(selectYourEstateData, BACKUPS_FIELD_ID),
  selectHasSkippedBackup,
  (backupComplete, skippedBackup) => backupComplete || skippedBackup,
);

const selectHasSelectedBackupBeneficiariy = createSelector(
  getFormValues(BACKUPS_FORM_ID),
  (backupBeneficiary) => {
    const backup = R.propOr({}, "backup")(backupBeneficiary);

    if (!backup.type) {
      return false;
    }
    return true;
  },
);

export const selectFormBackups = createSelector(
  getFormValues(BACKUPS_FORM_ID),
  R.propOr([], BACKUPS_FIELD_ID),
);

const selectBackupTranslations = createSelector(
  selectTranslations(["global", BACKUPS_FORM_ID]),
  selectFormBackups,
  (translations, backups) => {
    const hasMultipleBackups = backups.length > 1;

    const subCopy = hasMultipleBackups
      ? translations.multipleBackups
      : translations.singleBackup;

    return {
      ...translations,
      ...subCopy,
    };
  },
);

export const backupPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(BACKUPS_FORM_ID),
  isLoading: selectIsLoadingById(BACKUPS_FORM_ID),
  hasSelectedBackup: selectHasSelectedBackupBeneficiariy,
  translations: selectTranslations(["global", BACKUPS_FORM_ID]),
  isAddingBeneficiary: selectIsAddingBeneficiary,
  addBeneficiaryForm: createStructuredSelector({
    isAddingBeneficiary: selectIsAddingBeneficiary,
    isAddingCharity: selectIsAddingCharity,
    isAddingPerson: selectIsAddingPerson,
    isCustomCharity: selectIsCustomCharity,
    charitiesOptionList: selectCharitiesOptionList,
    isCouplesPlan: selectIsCouplesPlan,
    translations: selectTranslations([
      "global",
      DONATIONS_FORM_ID,
      BACKUPS_FORM_ID,
    ]),
  }),
  backupForm: createStructuredSelector({
    shouldDisplayHelp: selectShouldDisplayAllocationHelper,
    charities: selectCharities,
    translations: selectBackupTranslations,
  }),
});
