import R from "ramda";

import { combineReducers } from "redux";
import { UPDATE_STATUS_TYPE } from "../actions/status";
import { ADD_PROVINCES_TYPE } from "../actions/provinces";

const DEFAULT_STATE = {};

const selectedProvince = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return R.pathOr({}, ["province"])(payload);
    default:
      return state;
  }
};

const allProvinces = (state = [], { type, payload }) => {
  switch (type) {
    case ADD_PROVINCES_TYPE:
      return payload.allProvinces;
    default:
      return state;
  }
};

export default combineReducers({
  selectedProvince,
  allProvinces,
});
