import R from "ramda";
import { createSelector } from "reselect";

export const getProgressState = (isComplete) => {
  if (isComplete) return "complete";
  return "incomplete";
};

export const getProgressLabel = (
  isComplete,
  currentPageNumber,
  totalPages,
  translations,
) => {
  if (isComplete) {
    return translations.completeProgressLabel;
  }

  if (currentPageNumber === 0) {
    return translations.duration;
  }

  const PERCENTAGE = Math.round((currentPageNumber / totalPages) * 100);
  return translations.inProgressLabel.replace("PERCENTAGE", PERCENTAGE);
};

export const selectIsFieldComplete = (dataSelector, fieldKey, expectedValue) =>
  createSelector(dataSelector, (allData) => {
    const value = R.propOr("", fieldKey, allData);

    const hasValue = !R.isEmpty(value);

    if (expectedValue) {
      return expectedValue === value;
    }
    return hasValue;
  });
