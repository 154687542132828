import React from "react";
import styled from "styled-components";

import { Box } from "@material-ui/core";
import { H4, SmallBody } from "./Typography";
import { SmallTextButton } from "./Button";
import { colorPalette } from "../../config/Theme";

const StyledColorBar = styled(Box)`
  height: 8px;
  border-top: 1px solid ${colorPalette.willfulGrey2};
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 100%;
`;

const EditAndRemoveButtons = ({ translations, onEdit, onRemove }) => {
  return (
    <Box my={1.5} display="flex" flexDirection="row">
      <Box mr={1.5}>
        <SmallTextButton
          onClick={() => onEdit()}
          text={translations.edit}
          className="qa-edit-people"
        />
      </Box>
      <SmallTextButton
        onClick={() => onRemove()}
        text={translations.remove}
        className="qa-remove-people"
      />
    </Box>
  );
};

const ColoBarFooter = ({ color }) => {
  return <StyledColorBar bgcolor={color} />;
};

const PeopleCard = ({
  translations,
  text = "",
  subText = "",
  color,
  onEdit,
  onRemove,
}) => {
  return (
    <>
      <Box
        borderRadius="4px"
        borderColor={colorPalette.willfulGrey2}
        bgcolor={colorPalette.white}
        border={1}
      >
        <Box px={1.5} pt={1.5}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box>
              <Box mb={0.5} mr={0.5}>
                <H4 align="left" data-hj-suppress>
                  {text}
                </H4>
              </Box>
              <Box>
                <SmallBody
                  component="span"
                  style={{ color: colorPalette.willfulGrey1 }}
                >
                  {subText}
                </SmallBody>
              </Box>
            </Box>
          </Box>

          <EditAndRemoveButtons
            translations={translations}
            onEdit={onEdit}
            onRemove={onRemove}
          />
        </Box>

        <ColoBarFooter color={color} />
      </Box>
    </>
  );
};

export default PeopleCard;
