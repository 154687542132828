enum FlagName {
  ASSOCIATE_CONTACTS = "FEATURE_ASSOCIATE_CONTACTS",
  CONTACTS_PAGE = "FEATURE_CONTACTS_PAGE",
  CONTACTS_EMAIL = "FEATURE_CONTACTS_EMAIL",
}

const ENABLED = "enabled";

declare const window: {
  env: FlagName;
} & Window;

function flag(flagName: FlagName) {
  return window.env[flagName] === ENABLED;
}

/*** Feature Flag Methods ***/
export function featureAssociateContactsEnabled() {
  return flag(FlagName.ASSOCIATE_CONTACTS);
}

export function featureContactsPageEnabled() {
  return flag(FlagName.CONTACTS_PAGE);
}

export function featureContactsEmailEnabled() {
  return flag(FlagName.CONTACTS_EMAIL);
}
