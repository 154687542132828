import React from "react";
import styled from "styled-components";

import SvgIcon from "./SvgIcon";

const StyledSvgIcon = styled(SvgIcon)`
  .st0 {
    fill: #ffffff;
  }
`;

const ArborLogo = ({ height = 46 }) => {
  return (
    <StyledSvgIcon
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 183 68"
      style={{ enableBackground: "new 0 0 183 68" }}
      xmlSpace="preserve"
      height={height}
    >
      <g>
        <path
          className="st0"
          d="M105.9,38.2v-1.1c0-0.2-0.1-0.3-0.4-0.3h-1.5c-1.1,0-2.3-0.5-3.1-2.1c-0.6-1.3-3.5-9.8-4.6-13.4
		c-0.9-2.9-4.4-13.1-5.2-16c-0.1-0.5-0.2-0.8-0.5-0.8c-0.2,0-0.4,0.2-0.6,0.4c-0.5,0.6-2.5,2-3.7,2.3C86.1,7.4,86,7.6,86,7.7
		c0,0.2,0.2,0.4,0.3,0.6c0.3,0.5,0.1,1.1,0,1.5c-0.4,1.2-4,11-4.4,11.9c-1.3,3.7-4.6,12.2-4.9,12.9c-0.6,1.5-1.3,2.1-2.6,2.1h-1.6
		c-0.2,0-0.4,0.1-0.4,0.3v1.1c0,0.3,0.1,0.4,0.3,0.4c0.6,0,1.6-0.1,4.9-0.1c2.6,0,4.5,0.1,5.1,0.1c0.2,0,0.4-0.1,0.4-0.4v-1.1
		c0-0.2-0.1-0.3-0.5-0.3h-2.1c-1,0-1.5-0.4-1.5-1c0-0.2,0.1-0.7,0.1-1c0.2-1,2.7-7.9,3.1-9v0h11c0.6,1.8,2.5,7.7,2.8,9.3
		c0.3,1.3-0.1,1.7-1.3,1.7h-1.1c-0.3,0-0.4,0-0.4,0.3v1.1c0,0.3,0.1,0.4,0.4,0.4c1,0,2.7-0.1,6.3-0.1c3.6,0,4.6,0.1,5.4,0.1
		C105.8,38.5,105.9,38.5,105.9,38.2z M92.9,24.1H83c0.3-1.1,4.6-12.8,4.8-13.4c0.1-0.4,0.3-0.7,0.5-0.7c0.1,0,0.2,0.3,0.4,0.6
		C88.9,11.3,92.5,22.7,92.9,24.1L92.9,24.1z"
        />
        <path
          className="st0"
          d="M107.3,38.5c0.3,0,2.2-0.1,4.9-0.1c2.4,0,4.5,0.1,4.8,0.1c0.2,0,0.2-0.1,0.2-0.4v-0.8c0-0.3,0-0.4-0.3-0.4
		h-2.1c-0.9,0-1.3-0.5-1.3-1.9v-9.6c0-0.1,0.2-1,0.5-1.5c0.8-1.5,1.9-1.9,2.7-1.9c0.6,0,1.1,0.1,1.5,0.4c0.4,0.2,0.8,0.4,1.3,0.4
		c0.8,0,1.9-0.9,1.9-2.2c0-1.1-0.8-2-2.3-2c-1.9,0-4,1.9-5.4,4h-0.2l-0.2-3.1c0-0.3,0-0.5-0.3-0.5c-0.2,0-0.4,0-0.6,0.2l-4.4,2.3
		c-0.3,0.1-0.4,0.2-0.4,0.4V22c0,0.2,0.1,0.2,0.4,0.3l0.9,0.5c1.3,0.7,1.6,1.1,1.6,3.5l0,0v4.3c0,2.4,0,4,0,5.5c0,0.6-0.3,0.9-1,0.9
		h-1.8c-0.4,0-0.5,0.1-0.5,0.4v0.8C107,38.4,107.1,38.5,107.3,38.5z"
        />
        <path
          className="st0"
          d="M122.3,9.5l0.5,0.1c1.6,0.5,2.1,1.2,2.1,4.2v16.8c0,3.3,0,7.1-0.1,7.7c0,0.2,0,0.3,0.2,0.4l0.5,0.1
		c0.3,0,0.4,0,0.6-0.1l1.8-1c1,0.6,2.8,1.2,5.3,1.2c5.1,0,9.3-5,9.3-10.3c0-6.5-4.3-9.7-8.2-9.7c-2.2,0-4.8,0.9-6.2,2.3v-5.3
		c0-4.1,0-9.2,0-9.6c0-0.5,0-0.8-0.4-0.8c-0.3,0-0.6,0.2-0.9,0.4c-1.1,0.7-3.5,1.9-4.8,2.5c-0.3,0.1-0.4,0.2-0.4,0.4v0.4
		C121.8,9.2,121.9,9.3,122.3,9.5z M128.1,22.7c1-1,2.6-1.6,4.3-1.6c4.4,0,6.7,4.2,6.7,8.6c0,6-3.3,7.8-6,7.8c-1.9,0-3.8-1.2-4.3-2.3
		c-0.5-1.5-0.7-2.8-0.7-6.6V22.7z"
        />
        <path
          className="st0"
          d="M165.1,28.9c0-3-1-5.3-3-7.4c-1.3-1.4-3.8-2.6-6.3-2.6c-1.5,0-3.7,0.4-6,2.2c-2.6,2.1-3.9,5-3.9,7.3
		c0,3.2,0.4,4.9,2.5,7.4l0,0c1.9,2.3,4.7,3.1,6.8,3.1C160.7,38.9,165.1,33.9,165.1,28.9z M155.7,37.6c-2.1,0-3.4-0.9-4.4-2.4
		c-1.4-2.1-1.7-3.8-1.7-6.4c0-3.5,0.6-5.3,2.4-7.2c1.1-1.2,2.2-1.5,3.5-1.5c2.3,0,5.9,3.2,5.9,8.2C161.3,33.3,159.1,37.6,155.7,37.6
		z"
        />
        <path
          className="st0"
          d="M179.7,18.6c-1.9,0-4,1.9-5.4,4h-0.2l-0.2-3.1c0-0.3,0-0.5-0.3-0.5c-0.2,0-0.4,0-0.6,0.2l-4.4,2.3
		c-0.3,0.1-0.4,0.2-0.4,0.4V22c0,0.2,0.1,0.2,0.4,0.3l0.9,0.5c1.3,0.7,1.6,1.1,1.6,3.5l0,0v4.3c0,2.4,0,4,0,5.5c0,0.6-0.3,0.9-1,0.9
		h-1.8c-0.4,0-0.5,0.1-0.5,0.4v0.8c0,0.4,0.1,0.5,0.3,0.5c0.3,0,2.2-0.1,4.9-0.1c2.4,0,4.5,0.1,4.8,0.1c0.2,0,0.2-0.1,0.2-0.4v-0.8
		c0-0.3,0-0.4-0.3-0.4h-2.1c-0.9,0-1.3-0.5-1.3-1.9v-9.6c0-0.1,0.2-1,0.4-1.5c0.8-1.5,1.9-1.9,2.7-1.9c0.6,0,1.1,0.1,1.5,0.4
		c0.4,0.2,0.8,0.4,1.3,0.4c0.8,0,1.9-0.9,1.9-2.2C182,19.5,181.2,18.6,179.7,18.6z"
        />
        <path
          className="st0"
          d="M91.3,60.4L91.3,60.4l-5.9-15.1h-2.2h0v17.3h1.5v-15h0.1c0,0,0.5,1.4,0.8,2.3l5,12.7h1.4l4.9-12.7
		c0.4-1,0.8-2.3,0.8-2.3h0.1v15h1.5V45.3H97L91.3,60.4z"
        />
        <path
          className="st0"
          d="M107,49.8c-3.4,0-5.6,2.8-5.6,6.5l0,0c0,3.7,2,6.5,5.7,6.5c2.8,0,4.5-1.6,5-4.1h-1.4c-0.5,1.7-1.5,2.8-3.6,2.8
		c-2.8,0-4.1-2.2-4.2-5h9.4C112.3,53.1,111,49.8,107,49.8z M102.9,55.4c0.3-2.5,1.5-4.4,4.1-4.4c2.5,0,3.7,1.9,3.7,4.4h0H102.9z"
        />
        <path
          className="st0"
          d="M126.7,49.8c-1.7,0-3.1,0.8-4,2.3h0c-0.5-1.5-1.8-2.3-3.3-2.3c-1.8,0-2.9,0.9-3.7,2.1h0v-1.9h-1.5v0v12.5h1.5
		v-7.9c0-2,1.7-3.5,3.5-3.5c1.4,0,2.5,0.9,2.5,2.7v8.7h1.5v-7.9c0-2.2,1.5-3.5,3.3-3.5c1.5,0,2.6,0.9,2.6,2.7v8.7h1.5v-8.9
		C130.4,51.2,128.8,49.8,126.7,49.8z"
        />
        <path
          className="st0"
          d="M137.8,49.8c-3.5,0-5.7,2.8-5.7,6.5c0,3.7,2.2,6.5,5.7,6.5c3.5,0,5.7-2.8,5.7-6.5
		C143.5,52.6,141.4,49.8,137.8,49.8z M137.8,61.6c-2.8,0-4.2-2.4-4.2-5.3c0-2.9,1.4-5.3,4.2-5.3s4.2,2.4,4.2,5.3
		C142,59.2,140.6,61.6,137.8,61.6z"
        />
        <path
          className="st0"
          d="M146.9,52.4L146.9,52.4l0-2.3h-1.5l0,0v12.5h1.5v-7.1c0-1.5,0.6-2.6,1.6-3.4c0.8-0.6,1.7-0.7,2.6-0.6V50
		c-0.1,0-0.2-0.1-0.4-0.1C148.9,50,147.6,50.9,146.9,52.4z"
        />
        <rect x="152.7" y="45.3" className="st0" width="1.5" height="2.1" />
        <rect x="152.7" y="50.1" className="st0" width="1.5" height="12.5" />
        <path
          className="st0"
          d="M166.6,61.5c-0.5,0-0.8-0.3-0.8-1.1v-6.8c0-2.7-1.7-3.9-4.4-3.9c-3.2,0-4.7,1.6-4.9,4h1.5
		c0.1-1.9,1.3-2.8,3.5-2.8c1.9,0,3,0.8,3,2.5v0.2c0,1.3-0.9,1.4-3.3,1.7c-2.8,0.3-5.1,0.9-5.1,3.9c0,2.3,1.7,3.5,4,3.5
		c2.4,0,3.6-1.2,4.3-2.1h0.1v0.3c0.1,1.2,0.7,1.8,1.8,1.8c0.5,0,0.8-0.1,1-0.2v-1.1h-0.1C166.9,61.5,166.8,61.5,166.6,61.5z
		 M164.4,58c0,1.7-1.4,3.6-4.1,3.6c-1.5,0-2.7-0.5-2.7-2.3c0-2.1,1.6-2.5,3.7-2.8c1.2-0.2,2.4-0.4,3.2-0.8V58z"
        />
        <polygon className="st0" points="156,59.4 156,59.4 156,59.4 	" />
        <rect x="168.6" y="45.3" className="st0" width="1.5" height="17.3" />
        <path
          className="st0"
          d="M33.5,3.4C16.9,3.4,3.3,17.1,3.3,34s13.5,30.6,30.2,30.6S63.7,50.9,63.7,34S50.2,3.4,33.5,3.4z M32.6,43.6
		c0,0.2-0.1,0.5-0.2,0.6L32,44.6c-0.1,0.1-0.4,0.1-0.5,0l-0.9-0.9c-0.1-0.1-0.4-0.1-0.5,0l-0.7,0.7c-0.1,0.1-0.4,0.3-0.6,0.3h-0.4
		c-0.2,0-0.5-0.1-0.6-0.2c-0.1-0.1-0.2-0.4-0.2-0.6v-0.9c0-0.2-0.1-0.4-0.3-0.4c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5
		v-0.4c0-0.2-0.1-0.4-0.3-0.4s-0.3,0.2-0.3,0.4v0.3c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.3-0.4,0.4l-0.4,0.4
		c-0.1,0.1-0.4,0.3-0.6,0.3h-0.1c-0.2,0-0.4,0.1-0.4,0.3c0,0.2,0.2,0.3,0.4,0.3h0.6c0.2,0,0.5-0.1,0.6-0.3l0.2-0.3
		c0.1-0.1,0.3-0.3,0.4-0.3s0.1,0.2,0.1,0.4v0.1c0,0.2,0.1,0.4,0.2,0.4s0.2-0.1,0.2-0.3c0-0.1,0.1-0.2,0.2,0l0,0
		c0.1,0.1,0.2,0.4,0.2,0.5c0,0.1-0.1,0.4-0.2,0.5l-0.3,0.3c-0.1,0.1-0.4,0.3-0.6,0.3h-1.7c-0.2,0-0.4-0.1-0.5-0.2
		c-0.1-0.1-0.3-0.2-0.4-0.2s-0.3,0.1-0.4,0.2c-0.1,0.1-0.3,0.2-0.5,0.2H22c-0.2,0-0.5-0.1-0.6-0.2l-0.7-0.7
		c-0.1-0.1-0.4-0.2-0.6-0.2h0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.1-0.1-0.2-0.3-0.2c-0.2,0-0.2-0.1-0.1-0.3
		l0.1-0.2c0.1-0.2,0.3-0.2,0.4,0l0.2,0.2c0.1,0.1,0.1,0.3,0,0.3c-0.1,0-0.2,0.1-0.1,0.1c0.1,0.1,0.3,0.1,0.5,0.1h0.2
		c0.2,0,0.5,0.1,0.6,0.2L21,44c0.1,0.1,0.4,0.2,0.5,0.2c0.1,0,0.3-0.2,0.3-0.4v-0.7c0-0.2,0.2-0.4,0.4-0.4H23c0.2,0,0.2-0.1,0.1-0.3
		l-0.3-0.3c-0.1-0.1-0.1-0.4,0-0.5l0.2-0.2c0.1-0.1,0.4-0.3,0.6-0.3h0.1c0.2,0,0.5,0,0.6,0c0.2,0,0.3-0.1,0.3-0.3
		c0-0.2-0.2-0.3-0.4-0.3h-0.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.2-0.1-0.4-0.2-0.6l-0.4-0.4C23,39.2,22.9,39.1,23,39
		c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.3,0.3,0.5,0.5l0.2,0.2c0.1,0.1,0.4,0.2,0.6,0.2h0.2
		c0.2,0,0.3,0.1,0.3,0.2c0,0.1,0.2,0.2,0.3,0.2s0.3,0,0.2-0.1c-0.1-0.1,0-0.2,0.1-0.4l0.2-0.3c0.1-0.1,0.3-0.2,0.5,0l0.4,0.4
		c0.1,0.1,0.4,0.2,0.6,0.2s0.2,0.1,0.1,0.3l-0.2,0.2c-0.1,0.1-0.3,0.3-0.4,0.3c-0.1,0-0.1,0.1,0.1,0.2l0.1,0.1
		c0.1,0.1,0.1,0.3-0.1,0.3h-0.4c-0.2,0-0.4,0.1-0.4,0.2c0,0.1,0.1,0.3,0.2,0.4l0.7,0.7c0.1,0.1,0.4,0.2,0.6,0.2h0
		c0.2,0,0.5-0.1,0.6-0.3l0.3-0.3c0.1-0.1,0.2-0.4,0.2-0.6c0-0.2-0.2-0.4-0.4-0.4h-0.1c-0.2,0-0.3-0.2-0.3-0.4v0
		c0-0.2,0.1-0.5,0.2-0.6l0.4-0.4c0.1-0.1,0.4-0.1,0.5,0l0.2,0.2c0.1,0.1,0.4,0.2,0.6,0.2h0.4c0.2,0,0.5-0.1,0.6-0.3l0.1-0.1
		c0.1-0.1,0.3-0.2,0.4-0.1l0,0c0.1,0.1,0.2,0.4,0.2,0.6V43.6z M27.9,36.4h0.9c0.2,0,0.5,0.1,0.6,0.2l1.4,1.5
		c0.1,0.1,0.1,0.3-0.1,0.3h-0.2c-0.2,0-0.5,0-0.6,0s-0.4,0-0.6,0h-0.9c-0.2,0-0.2-0.1-0.1-0.3l0.2-0.2c0.1-0.1,0.1-0.4,0-0.5
		l-0.7-0.8l0,0C27.7,36.5,27.7,36.4,27.9,36.4z M27.9,34L27.9,34c0-0.2,0.1-0.4,0.2-0.4s0.3,0.1,0.5,0.2l0.1,0.1
		c0.1,0.1,0.2,0.4,0.2,0.6v0.1c0,0.2-0.1,0.4-0.2,0.4s-0.3-0.1-0.5-0.3h0l-0.2-0.2C28,34.5,27.9,34.2,27.9,34z M31.2,35l-1-1
		c-0.1-0.1-0.1-0.3,0.1-0.3s0.4,0.1,0.6,0.2l0.6,0.7c0.1,0.1,0.2,0.4,0.2,0.6v0.4c0,0.2-0.1,0.4-0.2,0.4c-0.1,0-0.2-0.2-0.2-0.4h0
		C31.5,35.4,31.4,35.1,31.2,35z M34.5,43c0-0.2,0-0.5,0-0.7v-1.4c0-0.2,0.1-0.4,0.2-0.5s0.2-0.3,0.2-0.5v-0.1c0-0.2,0.1-0.4,0.2-0.5
		c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0.1,0.1,0.2,0.2l1,1c0.1,0.1,0.4,0.2,0.6,0.2h0.7c0.2,0,0.4,0.1,0.4,0.3c0,0.1-0.1,0.3-0.2,0.4
		c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.2,0.4,0.2,0.6v0.3c0,0.2-0.1,0.5-0.2,0.6l-0.3,0.3c-0.1,0.1-0.2,0.4-0.2,0.6v0.3
		c0,0.2-0.2,0.4-0.4,0.4h-0.4c-0.2,0-0.3,0.2-0.3,0.4v0.9c0,0.2-0.1,0.5-0.2,0.6l-0.8,0.8c-0.1,0.1-0.2,0.1-0.2-0.1l0,0v-3
		c0-0.2-0.1-0.5-0.2-0.6C34.6,43.4,34.5,43.1,34.5,43z M34.9,29.2c-0.1,0-0.2-0.1-0.2-0.3v-0.3c0-0.2,0-0.5,0-0.7v-0.1
		c0-0.2,0.1-0.4,0.2-0.6l0.7-0.7c0.1-0.1,0.3-0.2,0.4-0.1s0,0.3-0.1,0.4L35.8,27c-0.1,0.1-0.1,0.3,0,0.5l0.1,0.1
		c0.1,0.1,0.3,0.1,0.5,0l1.4-1.4c0.1-0.1,0.2-0.2,0.2-0.1c0,0.1,0.1,0.2,0.2,0.4l0.3,0.3c0.1,0.1,0.1,0.2-0.1,0.2h-0.5
		c-0.2,0-0.2,0.1-0.1,0.2l0.6,0.6c0.1,0.1,0.3,0.1,0.5,0l0.1-0.1c0.1-0.1,0.2-0.1,0.2,0.1c0,0.2,0.1,0.2,0.2,0.1l0.5-0.5
		c0.1-0.1,0.2-0.1,0.2,0.1v0.3c0,0.2,0.1,0.3,0.3,0.3h0.8c0.2,0,0.2,0.1,0.1,0.2l-0.3,0.3c-0.1,0.1-0.1,0.3,0,0.4
		c0.1,0.1,0.3,0.1,0.4,0l0.5-0.5c0.1-0.1,0.3-0.1,0.5,0l0.9,0.9c0.1,0.1,0.3,0.1,0.5,0l0.5-0.5c0.1-0.1,0.3-0.1,0.5,0l0.2,0.2h0
		c0.1,0.1,0.2,0.4,0.2,0.6v0.1c0,0.2-0.1,0.3-0.3,0.3h-2.5c-0.2,0-0.4,0.1-0.5,0.2l-0.1,0.1c-0.1,0.1-0.4,0.2-0.6,0.2h0
		c-0.2,0-0.2-0.1-0.1-0.2l0.1-0.2c0.1-0.1,0.1-0.3,0-0.5l-0.1-0.1c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.3,0.1-0.4,0l-0.3-0.3
		c-0.1-0.1-0.4-0.2-0.6-0.2l-0.9,0c-0.2,0-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.2,0,0.3c0,0.1,0.2,0.4,0.3,0.5l1.5,1.5
		c0.1,0.1,0.1,0.3,0,0.5l-0.6,0.6c-0.1,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.4-0.1-0.5-0.2l-0.3-0.3c-0.1-0.1-0.4-0.2-0.5-0.2h-0.6
		c-0.2,0-0.4,0.1-0.5,0.2l-0.2,0.2c-0.1,0.1-0.2,0.4-0.2,0.5c0,0.2,0.1,0.3,0.3,0.3h0.2c0.2,0,0.2,0.1,0.1,0.2l-0.9,1
		c-0.1,0.1-0.2,0.3-0.2,0.3c0,0.1-0.1,0-0.2-0.1l-0.2-0.2c-0.1-0.1-0.2-0.4-0.2-0.6v-0.4c0-0.2,0-0.5,0-0.7v-0.4
		c0-0.2,0.1-0.4,0.2-0.6l0.1-0.1c0.1-0.1,0.2-0.4,0.2-0.6v-0.5c0-0.2,0-0.5,0-0.7v-0.6C35.1,29.4,35,29.2,34.9,29.2z M44,42.8
		c0.1,0.1,0.3,0.2,0.4,0.1c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0,0.4-0.1,0.5-0.3l0.4-0.4c0.1-0.1,0.2-0.1,0.2,0.1s0.1,0.4,0.2,0.6l0.2,0.3
		c0.1,0.1,0.1,0.3-0.1,0.3h-1.1c-0.2,0-0.5,0-0.7,0h-1.2c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.2-0.6,0.2H42c-0.2,0-0.3-0.1-0.3-0.3
		c0-0.2-0.2-0.3-0.4-0.3h-0.6c-0.2,0-0.5,0.1-0.6,0.3l-0.4,0.4c-0.1,0.1-0.4,0.3-0.6,0.3h-0.5l0,0c-0.2,0-0.2-0.1-0.1-0.3l1-1.1
		c0.1-0.1,0.2-0.4,0.2-0.6c0-0.2,0.1-0.5,0.2-0.6l0.5-0.5c0.1-0.1,0.4-0.3,0.5-0.3c0.2,0,0.3,0.1,0.3,0.3c0,0.2,0.1,0.3,0.3,0.3
		s0.3,0.1,0.3,0.2c0,0.1,0.2,0.2,0.4,0.2h0.4c0.2,0,0.5,0.1,0.6,0.2L44,42.8z M43.9,41.7L43.9,41.7c0-0.2,0.1-0.3,0.2-0.3l0.6,0
		c0.2,0,0.3,0.1,0.3,0.3v0c0,0.2-0.1,0.3-0.3,0.3l-0.4,0C44.1,42.1,43.9,41.9,43.9,41.7L43.9,41.7z M39.4,40.8
		c0.1-0.1,0.2-0.4,0.2-0.6v-0.4c0-0.2-0.2-0.4-0.4-0.4h-0.1c-0.2,0-0.2-0.1-0.1-0.3l0.5-0.5c0.1-0.1,0.4-0.3,0.6-0.3H41
		c0.2,0,0.3,0.1,0.3,0.2c0,0.1,0,0.4,0,0.5v0.3c0,0.2-0.2,0.4-0.3,0.4h-0.4c-0.2,0-0.3,0.2-0.3,0.4v0.2c0,0.2-0.1,0.5-0.2,0.6
		l-0.9,0.9c-0.1,0.1-0.2,0.1-0.2-0.1c0-0.2,0.1-0.5,0.2-0.6v0L39.4,40.8z M41.6,33.5c0.1,0.1,0.2,0.3,0.2,0.5c0,0.1-0.2,0.2-0.3,0.2
		h-0.2c-0.2,0-0.3,0.1-0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3V34c0-0.2-0.1-0.3-0.1-0.3c-0.1,0-0.2-0.1-0.2-0.3
		s0.1-0.3,0.3-0.2c0.2,0,0.4,0.1,0.6,0.1C41.2,33.2,41.4,33.3,41.6,33.5L41.6,33.5L41.6,33.5z M40.9,36.8h0.4c0.2,0,0.5,0.1,0.6,0.3
		l0.1,0.1c0.1,0.1,0.1,0.3-0.1,0.3h-1c-0.2,0-0.3-0.1-0.3-0.3C40.5,37,40.7,36.8,40.9,36.8L40.9,36.8z M36.4,39.1
		c0.1-0.2,0.3-0.2,0.4-0.1l0.5,0.5c0.1,0.1,0.1,0.4,0,0.5l0,0c-0.1,0.1-0.4,0.1-0.5,0l-0.3-0.3C36.3,39.6,36.3,39.3,36.4,39.1
		L36.4,39.1L36.4,39.1z M36.9,62.4c-0.4,0-0.7,0.1-1.1,0.1l-0.5-5.4L35,53.2c0-0.2,0-0.5,0-0.7v-2.7c0-0.2,0.1-0.5,0.1-0.7l0.1-0.1
		c0.1-0.2,0.2-0.5,0.4-0.6l2.3-3.2c0.1-0.2,0.4-0.3,0.6-0.3h0.5c0.2,0,0.5-0.1,0.6-0.3l0.8-0.8c0.1-0.1,0.4-0.1,0.5,0l0.1,0.1
		c0.1,0.1,0.4,0.2,0.5,0.2s0.3,0,0.3-0.1c0,0,0.2-0.1,0.3-0.1h0.6c0.2,0,0.5-0.1,0.7-0.1l0.1,0c0.2-0.1,0.5-0.1,0.7-0.1h1.3
		c0.2,0,0.5-0.1,0.7,0c0.2,0.1,0.1,0.3,0,0.4c-0.1,0.1-0.1,0.4-0.1,0.5c0,0.1,0.2,0.3,0.4,0.3c0.1,0,0.3-0.1,0.5-0.2l0,0
		c0.1-0.1,0.1-0.2,0.6-0.3c0.5,0,0.7-0.4,0.7-0.4c0.3-0.1,0.8-0.5,0.9-0.4c0.2,0.2,0.4,0.1,0.5,0l0.5-0.5c0.1-0.1,0.4-0.3,0.6-0.3
		H51c0.2,0,0.3-0.2,0.3-0.4v0c0-0.2,0.2-0.4,0.4-0.4h1.7c0.2,0,0.2-0.1,0.1-0.2L53,41.6c-0.1-0.1-0.4-0.2-0.6-0.2h-0.6
		c-0.2,0-0.3,0.1-0.3,0.3s-0.1,0.2-0.2,0.1c-0.1-0.1-0.3-0.2-0.5-0.2h-0.3c-0.2,0-0.4,0.1-0.4,0.2c0,0.1-0.2,0.2-0.3,0.2h-1
		c-0.2,0-0.3-0.2-0.3-0.4v-0.1c0-0.2,0.2-0.4,0.3-0.4h0.3c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.3-0.2,0.5-0.2s0.3,0.1,0.3,0.3
		c0,0.2,0.1,0.2,0.2,0.1l0.3-0.3c0.1-0.1,0.2-0.3,0.2-0.3s-0.1-0.2-0.2-0.3l0,0c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.1,0.2-0.2,0.3-0.2h0.7
		c0.2,0,0.3,0.2,0.3,0.4V40c0,0.2,0.2,0.4,0.3,0.4h0.3c0.2,0,0.5,0,0.7,0h0.3c0.2,0,0.3,0.2,0.3,0.4V41c0,0.2,0.2,0.4,0.4,0.4h0.1
		c0.2,0,0.3-0.2,0.3-0.4v-0.4c0-0.2,0.1-0.5,0.2-0.6l0.4-0.4c0.1-0.1,0.1-0.4,0-0.5l-0.2-0.2c-0.1-0.1-0.4-0.1-0.5,0L54.8,39
		c-0.1,0.1-0.3,0.2-0.4,0.1c-0.1-0.1-0.3-0.2-0.5-0.2h-0.1c-0.2,0-0.5-0.1-0.6-0.2l-0.7-0.7c-0.1-0.1-0.4-0.1-0.5,0l0,0
		c-0.1,0.1-0.4,0.1-0.5,0l-1.1-1.1c-0.1-0.1-0.4-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.3,0.3s-0.1,0.3-0.2,0.3s-0.2-0.1-0.2-0.2
		c0-0.1-0.1-0.2-0.3-0.2c-0.2,0-0.3,0.1-0.3,0.3c0,0.2-0.2,0.3-0.3,0.3h-0.6c-0.2,0-0.4-0.2-0.4-0.3c0-0.2-0.2-0.3-0.3-0.3H46
		c-0.2,0-0.3-0.2-0.3-0.4v-0.2c0-0.2-0.1-0.5-0.2-0.6l-0.3-0.3c-0.1-0.1-0.4-0.2-0.6-0.2h-0.2c-0.2,0-0.3,0.2-0.3,0.4v0
		c0,0.2-0.2,0.4-0.3,0.4h0c-0.2,0-0.4-0.2-0.4-0.4V34c0-0.2,0.2-0.4,0.3-0.4s0.4-0.1,0.6-0.3l0.7-0.7c0.1-0.1,0.4-0.1,0.5,0l0.4,0.4
		c0.1,0.1,0.4,0.1,0.5,0l0.1-0.1c0.1-0.1,0.2-0.4,0.2-0.6v-0.4c0-0.2,0.2-0.4,0.3-0.4h1c0.2,0,0.4-0.2,0.4-0.4v0
		c0-0.2,0.1-0.5,0.2-0.6l0,0c0.1-0.1,0.4-0.3,0.6-0.3h0.5c0.2,0,0.4,0.1,0.4,0.3c0,0.2,0.1,0.3,0.3,0.3s0.3-0.2,0.3-0.4v-0.2
		c0-0.2-0.1-0.5-0.2-0.6l-0.1-0.1c-0.1-0.1-0.1-0.4,0-0.5l0.7-0.7c0.1-0.1,0.4-0.3,0.6-0.3h0.5c0.2,0,0.4-0.1,0.4-0.3
		c0-0.1-0.2-0.3-0.3-0.3s-0.2-0.1-0.1-0.2c0.1-0.1,0.2-0.3,0.1-0.4c-0.1-0.1-0.3-0.2-0.5-0.2s-0.4,0.1-0.5,0.3l-0.4,0.4
		c-0.1,0.1-0.4,0.1-0.5,0L49.8,27c-0.1-0.1-0.4-0.1-0.5,0l-0.2,0.2c-0.1,0.1-0.4,0.3-0.6,0.3h-0.7c-0.2,0-0.5-0.1-0.6-0.2l-0.1-0.1
		c-0.1-0.1-0.2-0.4-0.2-0.6V26c0-0.2,0.1-0.2,0.2-0.1l0.3,0.3c0.1,0.1,0.3,0.2,0.4,0.1c0.1-0.1,0-0.3-0.1-0.4l-0.5-0.5
		c-0.1-0.1-0.4-0.1-0.5,0l-0.1,0.1c-0.1,0.1-0.2,0.3-0.2,0.3c0,0-0.1,0.1-0.2,0.3c-0.1,0.1-0.4,0.2-0.6,0.2h-1
		c-0.2,0-0.5,0.1-0.6,0.3L44,26.6c-0.1,0.1-0.4,0.1-0.5,0l-0.1-0.1c-0.1-0.1-0.1-0.4,0-0.5l0.7-0.8c0.1-0.1,0.2-0.2,0-0.2
		s-0.3-0.1-0.5-0.2l0,0c-0.1-0.1-0.2-0.1-0.2,0.1v0.2c0,0.2-0.1,0.4-0.3,0.4c-0.1,0-0.4-0.1-0.5-0.2L42.4,25c-0.1-0.1-0.4-0.1-0.5,0
		l-0.5,0.5c-0.1,0.1-0.4,0.3-0.6,0.3h-0.5c-0.2,0-0.5-0.1-0.6-0.2L39,24.7c-0.1-0.1-0.2-0.1-0.2,0c0,0.2-0.2,0.3-0.3,0.3H38
		c-0.2,0-0.3,0-0.3,0s-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.2s0.1-0.3,0.2-0.3c0.1-0.1,0.1-0.3,0.1-0.5v-0.2c0-0.2-0.2-0.4-0.3-0.4
		h-0.1c-0.2,0-0.5-0.1-0.6-0.2l-0.1-0.1c-0.1-0.1-0.4-0.2-0.6-0.2h-1.3c-0.2,0-0.4-0.2-0.4-0.4v-1c0-0.2,0.2-0.4,0.3-0.4h0.3
		c0.2,0,0.5,0.1,0.6,0.2l0.6,0.6c0.1,0.1,0.4,0.1,0.5,0l1-1c0.1-0.1,0.4-0.1,0.5,0l0.6,0.6c0.1,0.1,0.2,0.3,0,0.5s0,0.2,0.1,0.2h0.4
		c0.2,0,0.5,0.1,0.6,0.2l0.5,0.5c0.1,0.1,0.4,0.1,0.5,0l0.2-0.2c0.1-0.1,0.1-0.4,0-0.5l0,0c-0.1-0.1-0.2-0.4-0.2-0.6v-1.1
		c0-0.2-0.2-0.4-0.4-0.4h-0.6c-0.2,0-0.3-0.2-0.3-0.4v-0.1c0-0.2-0.2-0.4-0.3-0.4s-0.4,0.1-0.6,0.3l0,0c-0.1,0.1-0.3,0.3-0.4,0.3
		c-0.1,0-0.3-0.1-0.4-0.2l-0.1-0.1c-0.1-0.1-0.1-0.4,0-0.5l0.4-0.4c0.1-0.1,0.6-0.5,0.4-0.8c-0.1-0.2-0.6-0.6-0.6-0.6
		c-0.1-0.1-0.1-0.4,0-0.5c0,0,0.4-0.5,0.6-0.5c0.2,0,0.5,0.4,0.7,0.5c0.2,0.1,0.4-0.1,0.5-0.3l0.7-0.6c0.1-0.1,0.3-0.4,0.5-0.5
		c0.1-0.1,0.1-0.2-0.1-0.2l-0.5,0c-0.2,0-0.5,0.1-0.6,0.2l-0.3,0.2c-0.1,0.1-0.4,0.1-0.5,0l0,0c-0.1-0.1-0.4-0.1-0.5,0
		c-0.2,0.2,0,0.3-0.2,0.4c-0.1,0.1-0.7,0.5-0.8,0.4c-0.2-0.1-0.2-0.3-0.2-0.5v-0.1c0-0.2-0.2-0.4-0.3-0.4h0c-0.2,0-0.4,0.2-0.4,0.4
		v0.9c0,0.2-0.1,0.4-0.2,0.4c-0.1,0-0.2-0.2-0.2-0.4v-0.6c0-0.2-0.1-0.4-0.2-0.4c-0.1,0-0.2-0.1-0.2-0.3c0-0.1,0.1-0.4,0.2-0.5
		l0.3-0.3c0.1-0.1,0.1-0.4,0-0.5l-0.2-0.2c-0.1-0.1-0.1-0.4,0-0.5l0,0c0.1-0.1,0.1-0.2-0.1-0.2s-0.3-0.1-0.3-0.2
		c0-0.1-0.2-0.2-0.3-0.2H35c-0.2,0-0.4-0.2-0.4-0.4v-1.1c0-0.2,0.2-0.4,0.4-0.4h0c0.2,0,0.3,0.2,0.3,0.4v0.9c0,0.2,0.2,0.4,0.3,0.4
		h0.7c0.2,0,0.3,0.2,0.3,0.4V13c0,0.2,0.1,0.2,0.2,0.1l0.4-0.4c0.1-0.1,0.1-0.4,0-0.5L37,11.8c-0.1-0.1-0.1-0.4,0-0.5l0.5-0.5
		c0.1-0.1,0.2-0.4,0.2-0.6c0-0.2-0.2-0.3-0.4-0.3h-1.2c-0.2,0-0.4-0.2-0.4-0.4V9c0-0.2-0.2-0.4-0.3-0.4h-0.3c-0.2,0-0.3,0.2-0.3,0.4
		v1c0,0.2-0.2,0.4-0.4,0.4h0c-0.2,0-0.3-0.1-0.3-0.2c0-0.1-0.1-0.3-0.2-0.5l0,0c-0.1-0.1-0.2-0.4-0.2-0.6V8.7c0-0.2,0.1-0.2,0.2-0.1
		L34,8.7c0.1,0.1,0.2,0.1,0.2-0.1V7.7c0-0.2-0.1-0.5-0.2-0.6l-0.1-0.1c-0.1-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.3,0.2-0.3,0.4v0.4
		c0,0.2-0.2,0.4-0.3,0.4h-0.1c-0.2,0-0.5,0.1-0.6,0.3l-0.2,0.2c-0.1,0.1-0.2,0.4-0.2,0.6v0c0,0.2,0.1,0.5,0.2,0.6l0.8,0.9
		c0.1,0.1,0.1,0.3-0.1,0.3h-0.2c-0.2,0-0.3,0.2-0.3,0.4v1.3c0,0.2,0.1,0.4,0.2,0.4c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
		s-0.2,0.1-0.2,0.2c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.1,0.3,0.1,0.5v0.3c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.2,0.4,0.2,0.5v0.1
		c0,0.2-0.2,0.4-0.3,0.4h-0.6c-0.2,0-0.4-0.2-0.4-0.4v-0.9c0-0.2,0.1-0.5,0.2-0.6l0,0c0.1-0.1,0.2-0.4,0.2-0.6v-0.1
		c0-0.2-0.1-0.5-0.2-0.6l-0.7-0.7c-0.1-0.1-0.4-0.1-0.5,0L29.4,13c-0.1,0.1-0.1,0.4,0,0.5l0.4,0.4c0.1,0.1,0.1,0.4,0,0.5l-0.4,0.4
		c-0.1,0.1-0.1,0.4,0,0.5l1.3,1.3c0.1,0.1,0.1,0.4,0,0.5l-0.2,0.2c-0.1,0.1-0.1,0.4,0,0.5l0.1,0.1c0.1,0.1,0.1,0.4,0,0.5l-0.2,0.3
		c-0.1,0.1-0.4,0.1-0.5,0l-0.1-0.1c-0.1-0.1-0.4-0.1-0.5,0l-0.2,0.2c-0.1,0.1-0.4,0.1-0.5,0l-1.2-1.3c-0.1-0.1-0.4-0.1-0.5,0
		l-0.4,0.4c-0.1,0.1-0.1,0.4,0,0.5c0.1,0.1,0.3,0.2,0.4,0c0.1-0.1,0.2,0,0.2,0.2V19c0,0.2-0.2,0.4-0.3,0.4h-1.3
		c-0.2,0-0.5,0.1-0.6,0.3l-0.1,0.1c-0.1,0.1-0.1,0.2,0.1,0.2h2.7c0.2,0,0.3-0.2,0.3-0.4v0c0-0.2,0.1-0.2,0.2-0.1l0.5,0.5
		c0.1,0.1,0.4,0.2,0.6,0.2h1c0.2,0,0.5-0.1,0.6-0.3l0.5-0.5c0.1-0.1,0.2-0.1,0.2,0.1v1.3c0,0.2-0.1,0.5-0.2,0.6l-0.1,0.1
		c-0.1,0.1-0.3,0.3-0.4,0.3c-0.1,0-0.2-0.2-0.2-0.4v-0.2c0-0.2-0.2-0.4-0.3-0.4s-0.4,0.1-0.5,0.2c-0.1,0.1-0.3,0.1-0.5,0l-0.7-0.7
		c-0.1-0.1-0.4-0.1-0.5,0l-0.1,0.1c-0.1,0.1-0.2,0.4-0.2,0.6c0,0.2-0.2,0.3-0.4,0.3h-0.7c-0.2,0-0.2,0.1-0.1,0.3l0.1,0.1
		c0.1,0.1,0.2,0.4,0.2,0.6V23c0,0.2,0.1,0.4,0.3,0.4c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.3-0.1,0.5,0s0.3,0.2,0.4,0.2
		c0.1,0,0.2-0.2,0.2-0.4v-0.7c0-0.2,0.1-0.4,0.2-0.4c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.2,0.5,0.2s0.4,0.1,0.5,0.1
		c0.1,0.1,0.3,0.1,0.5,0.1h0c0.2,0,0.5-0.1,0.6-0.3l0.9-0.9c0.1-0.1,0.2-0.1,0.2,0.1v1.1c0,0.2-0.1,0.5-0.2,0.6l-0.2,0.2
		c-0.1,0.1-0.4,0.3-0.6,0.3H31c-0.2,0-0.5-0.1-0.6-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.1-0.1-0.2-0.3-0.2s-0.3,0.2-0.3,0.4v0
		c0,0.2-0.1,0.5-0.2,0.6l-0.1,0.1C29.1,24,29,24.2,29,24.4c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.3-0.1-0.4-0.2l-0.2-0.2
		C28,24,27.9,24,27.9,24.2c0,0.2-0.2,0.3-0.4,0.3h-1.1c-0.2,0-0.5,0.1-0.6,0.3L25.7,25c-0.1,0.1-0.4,0.3-0.6,0.3h0
		c-0.2,0-0.4-0.2-0.4-0.4v0c0-0.2,0.1-0.5,0.2-0.6l0.2-0.2c0.1-0.1,0.3-0.2,0.4-0.1c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2
		c0.1-0.1,0.2-0.3,0.2-0.4c0-0.1-0.1-0.4-0.2-0.5l-0.6-0.6c-0.1-0.1-0.2-0.3,0-0.3c0.1,0,0.3-0.1,0.3-0.1s0-0.2-0.1-0.4l-0.3-0.3
		c-0.1-0.1-0.4-0.1-0.5,0l-0.4,0.4c-0.1,0.1-0.2,0.4-0.2,0.5s-0.2,0.2-0.3,0.2h-0.8c-0.2,0-0.4,0.1-0.4,0.2s-0.2,0.2-0.3,0.2
		s-0.5,0.1-0.6,0.3l-0.9,0.9C21.1,24,21,24.3,21,24.5v0.4c0,0.2-0.2,0.4-0.3,0.4h-0.1c-0.2,0-0.5,0.1-0.6,0.3L19.4,26
		c-0.1,0.1-0.2,0.2-0.2,0.1c0-0.1-0.1-0.2-0.2-0.2s-0.2,0.1-0.2,0.3c0,0.2-0.2,0.3-0.4,0.3h-1.3c-0.2,0-0.3,0.1-0.3,0.2
		c0,0.1,0.2,0.2,0.3,0.2h0.4c0.2,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.3-0.2,0.4L17.2,28c-0.1,0.1-0.1,0.2,0.1,0.2h1.2
		c0.2,0,0.4,0.2,0.4,0.4v0c0,0.2,0.1,0.5,0.2,0.6l0.5,0.6c0.1,0.1,0.4,0.1,0.5,0l0.5-0.5c0.1-0.1,0.1-0.4,0-0.5l-0.4-0.4
		c-0.1-0.1-0.1-0.4,0-0.5l0.1-0.1c0.1-0.1,0.2-0.3,0.1-0.4c-0.1-0.1-0.1-0.2,0-0.4c0.1-0.1,0.3-0.1,0.4,0.1l0.5,0.5
		c0.1,0.1,0.4,0.1,0.5,0l0,0c0.1-0.1,0.2-0.4,0.2-0.6v0c0-0.2-0.1-0.4-0.3-0.4c-0.2,0-0.2-0.1,0-0.2l0,0c0.1-0.1,0.3-0.3,0.5-0.3
		s0.2-0.2,0.2-0.4v-0.3c0-0.2,0.1-0.4,0.3-0.4c0.1,0,0.3-0.2,0.3-0.4v-0.5c0-0.2,0.2-0.4,0.3-0.4H24c0.2,0,0.3,0.2,0.3,0.4v0.1
		c0,0.2-0.2,0.4-0.3,0.4h-0.2c-0.2,0-0.4,0.2-0.4,0.4v0.5c0,0.2,0.2,0.4,0.4,0.4h0.1c0.2,0,0.5,0.1,0.6,0.2l0.2,0.3
		c0.1,0.1,0.1,0.3-0.1,0.3h-0.1c-0.2,0-0.3,0.1-0.2,0.2c0.1,0.1,0.3,0.3,0.3,0.3c0.1,0.1,0.3,0.2,0.5,0.2h0.1c0.2,0,0.3-0.2,0.3-0.4
		v-0.6c0-0.2,0.1-0.2,0.2-0.1l0.2,0.2c0.1,0.1,0.3,0.2,0.5,0.2s0.2-0.2,0.2-0.4v-0.5c0-0.2,0.1-0.4,0.2-0.4c0.1,0,0.3,0.1,0.4,0.3
		l0.2,0.2c0.1,0.1,0.1,0.4,0,0.5l-0.2,0.2c-0.1,0.1-0.1,0.4,0,0.5l0.4,0.4c0.1,0.1,0.2,0.4,0.2,0.6v0c0,0.2-0.1,0.5-0.2,0.6
		s-0.2,0.3-0.2,0.4c0,0.1,0.2,0.2,0.3,0.2h0.6c0.2,0,0.5,0.1,0.6,0.2l0.2,0.3c0.1,0.1,0.4,0.2,0.6,0.2h0.3c0.2,0,0.5,0.1,0.6,0.2
		l1.8,1.8c0.1,0.1,0.2,0.4,0.2,0.6v1.5c0,0.2-0.1,0.2-0.2,0.1l-0.8-0.8c-0.1-0.1-0.2-0.4-0.2-0.6v-0.5c0-0.2-0.2-0.4-0.3-0.4h-0.8
		c-0.2,0-0.4,0.1-0.5,0.2s-0.3,0.2-0.5,0.2h0c-0.2,0-0.5-0.1-0.6-0.2l-0.6-0.6c-0.1-0.1-0.4-0.2-0.6-0.2h-0.2
		c-0.2,0-0.3-0.2-0.3-0.3c0-0.2-0.2-0.3-0.4-0.3h-0.4c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.4-0.2
		c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.3,0.1-0.4-0.1l-0.5-0.6c-0.1-0.1-0.4-0.2-0.6-0.2h-0.3c-0.2,0-0.4,0.1-0.5,0.2
		c-0.1,0.1-0.3,0.2-0.5,0.2H21c-0.2,0-0.5,0.1-0.6,0.3l-0.1,0.1c-0.1,0.1-0.2,0.3-0.1,0.4c0.1,0.1,0.2,0,0.4-0.1l0.1-0.1
		c0.1-0.1,0.4-0.3,0.6-0.3h0.2c0.2,0,0.3,0.2,0.3,0.4V31c0,0.2,0.2,0.4,0.3,0.4h1.3c0.2,0,0.4,0.1,0.4,0.3c0,0.2-0.1,0.3-0.3,0.3
		c-0.2,0-0.2,0.1,0,0.2l1.1,1.1c0.1,0.1,0.4,0.1,0.5,0l0.5-0.5c0.1-0.1,0.2-0.4,0.2-0.5c0-0.2,0.2-0.3,0.4-0.3h1
		c0.2,0,0.5,0.1,0.6,0.2l0,0c0.1,0.1,0.2,0.4,0.2,0.6v0c0,0.2-0.2,0.4-0.4,0.4h-0.2c-0.2,0-0.5-0.1-0.6-0.2l-0.2-0.2
		c-0.1-0.1-0.3-0.2-0.4-0.1c-0.1,0.1-0.1,0.3,0.1,0.4l0.3,0.3c0.1,0.1,0.1,0.4,0,0.5L26,34.3c-0.1,0.1-0.4,0.3-0.6,0.3h-0.1
		c-0.2,0-0.5-0.1-0.6-0.2c-0.1-0.1-0.3-0.2-0.4-0.1c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.1,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.4,0.2
		s0.2,0.1,0.4,0.2l0,0c0.1,0.1,0.2,0.2,0.1,0.2c-0.1,0-0.2,0.1-0.2,0.1c0,0.1-0.2,0.1-0.3,0.1h-1c-0.2,0-0.5-0.1-0.6-0.2l-0.1-0.1
		c-0.1-0.1-0.4-0.1-0.5,0l-0.5,0.5c-0.1,0.1-0.1,0.4,0,0.5l0.3,0.3c0.1,0.1,0.4,0.2,0.6,0.2h0.2c0.2,0,0.3,0.1,0.3,0.3
		c0,0.2-0.2,0.3-0.3,0.3h-0.1c-0.2,0-0.3,0-0.3,0.1s-0.2,0.1-0.3,0.1h0c-0.2,0-0.3,0.2-0.3,0.3c0,0.2-0.2,0.3-0.3,0.3
		c-0.2,0-0.3,0.2-0.3,0.4v0.1c0,0.2,0.2,0.4,0.4,0.4h0.5c0.2,0,0.3,0.2,0.3,0.4v0.3c0,0.2,0,0.4,0,0.4s-0.1,0.1-0.3,0.3l-0.2,0.3
		c-0.1,0.1-0.2,0.1-0.2,0c0-0.2-0.2-0.3-0.4-0.3h-0.2c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.4,0.2-0.5,0.2H20c-0.2,0-0.3-0.2-0.3-0.4
		v-0.9c0-0.2,0.1-0.2,0.2-0.1l0,0c0.1,0.1,0.4,0.2,0.6,0.2h0.2c0.2,0,0.2-0.1,0.1-0.3l-0.3-0.3c-0.1-0.1-0.2-0.4-0.2-0.6v-0.2
		c0-0.2-0.2-0.4-0.3-0.4l-0.3,0c-0.2,0-0.3,0.1-0.3,0.3v0.3c0,0.2-0.1,0.4-0.2,0.4S19,39.1,19,38.9c0-0.1-0.1-0.1-0.2,0l-0.1,0.1
		c-0.1,0.1-0.4,0.3-0.5,0.3c-0.1,0-0.3-0.1-0.3-0.3c0-0.2-0.1-0.2-0.1-0.2c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.2-0.2,0.3-0.4,0.3H17
		c-0.2,0-0.2,0.1-0.1,0.3l0.3,0.3c0.1,0.1,0.4,0.2,0.6,0.2h0.2c0.2,0,0.3,0.2,0.3,0.4v0c0,0.2-0.2,0.4-0.3,0.4s-0.5,0.1-0.6,0.2
		c-0.1,0.1-0.1,0.2,0.1,0.2h0.5c0.2,0,0.3,0.1,0.3,0.3s-0.2,0.3-0.3,0.3h-0.5c-0.2,0-0.4,0.2-0.4,0.4v0.6c0,0.2-0.2,0.4-0.3,0.4
		h-0.7c-0.2,0-0.4,0.1-0.4,0.3c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3s-0.2,0.2-0.4,0.2h-0.1
		c-0.2,0-0.5,0.1-0.6,0.3L14.1,45c-0.1,0.1-0.2,0.4-0.2,0.6v0.1c0,0.2-0.2,0.4-0.4,0.4h-0.8c-0.2,0-0.2,0.1-0.1,0.3l0,0
		c0.1,0.1,0.4,0.2,0.6,0.2h0.3c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.3,0.1,0.4,0c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.2-0.3-0.2-0.4
		c0-0.1,0.2-0.2,0.3-0.2h0.1c0.2,0,0.3,0.1,0.3,0.2c0,0.1,0.1,0.1,0.2,0c0.1-0.1,0.3-0.1,0.5,0l0.2,0.2c0.1,0.1,0.4,0.2,0.6,0.2
		c0,0,0.9-0.1,1,0c0.1,0.1-0.3,0.3-0.5,0.3l-1.4,0.4c-0.2,0-0.5,0.1-0.6,0.3l-0.4,0.4c-0.1,0.1-0.4,0.3-0.5,0.3
		c-0.2,0-0.4-0.1-0.5-0.2l-0.3-0.3c-0.1-0.1-0.4-0.1-0.5,0l-0.2,0.2c-0.1,0.1-0.2,0.3-0.2,0.5c0,0,0,0.1,0,0.1v0.4
		c0,0.2,0.1,0.4,0.1,0.4c0.1,0,0.2-0.2,0.2-0.4v0c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2h0.5c0.2,0,0.5,0,0.7,0h0.1
		c0.2,0,0.5,0.1,0.6,0.3l0.1,0.1c0.1,0.1,0.2,0.1,0.2-0.1v-0.3c0-0.2,0.2-0.4,0.4-0.4h0.7c0.2,0,0.4-0.1,0.4-0.2
		c0-0.1,0.2-0.2,0.3-0.2h1c0.2,0,0.3-0.1,0.3-0.2c0-0.1,0.2-0.6,0.1-0.7c0,0-0.5-0.4-0.5-0.5c0-0.1,0.2-0.3,0.4-0.4l0.2-0.2
		c0.1-0.1,0.4-0.1,0.5,0l0.1,0.1c0.1,0.1,0.4,0.2,0.6,0.2h0.6c0.2,0,0.5-0.1,0.6-0.3l0.2-0.2c0.1-0.1,0.4-0.1,0.5,0l0.5,0.6
		c0.1,0.1,0.4,0.2,0.6,0.2h0.6c0.2,0,0.2,0.1,0.1,0.3l-0.2,0.2c-0.1,0.1-0.4,0.1-0.5,0c-0.1-0.1-0.4-0.1-0.5,0l-1,1.1
		c-0.1,0.1-0.4,0.3-0.6,0.3h-0.8c-0.2,0-0.1,0.2,0,0.3c0.1,0.1,0,0.3-0.4,0.2l-0.5-0.3c-0.1-0.1-0.4-0.2-0.6-0.2h-0.3
		c-0.2,0-0.5,0.1-0.6,0.2c-0.1,0.1-0.4,0.3-0.5,0.4c-0.2,0.1-0.3,0.3-0.3,0.5v0.1c0,0.2,0.1,0.5,0.2,0.6l0.1,0.1
		c0.1,0.1,0.4,0.2,0.6,0.2s0.3-0.1,0.3-0.2c0-0.1-0.2-0.2-0.3-0.2h0c-0.2,0-0.3-0.2-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3
		c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.2,0.5-0.2c0,0,0.4,0,0.7,0c0.3,0,0.7,0.3,0.9,0.4c0.1,0,0.2,0.1,0.2-0.1
		c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.1,0.6,0,0.6,0c0.2,0,0.5-0.1,0.6-0.3l0.6-0.6c0.1-0.1,0.4-0.1,0.5,0c0.1,0.1,0.3,0.2,0.4,0.1
		c0.1-0.1,0.2-0.2,0.4-0.4l0.6-0.6c0.1-0.1,0.4-0.3,0.6-0.3h1c0.2,0,0.5-0.1,0.6-0.3l0.4-0.4c0.1-0.1,0.4-0.3,0.5-0.3
		c0.2,0,0.4,0,0.6,0c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.4-0.2,0.6-0.2H29c0.2,0,0.5,0.1,0.6,0.2l0,0c0.1,0.1,0.4,0.1,0.5,0l0.1-0.1
		c0.1-0.1,0.3-0.2,0.4-0.1c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0.1,0.4,0.3,0.5l0.4,0.3c0.2,0.1,0.3,0.4,0.3,0.6l0,5.3c0,0.2,0,0.5,0,0.7
		l-0.6,9l0,0.4c-0.1,0-0.2,0-0.3,0C16.5,61,5.4,48.9,5.4,34.1c0,0,0,0,0,0C5.4,18.2,18,5.4,33.5,5.4c6.9,0,13.2,2.5,18.1,6.6v0
		c6.2,5.2,10.1,13.1,10.1,21.9C61.8,48.6,50.9,60.7,36.9,62.4z M48.1,42.5c0.2,0,0.2,0.1,0.1,0.2c0,0-0.2,0-0.4,0h0
		c-0.2,0-0.9-0.5-0.9-0.5c-0.1-0.1-0.2-0.4-0.2-0.6v-1c0-0.2,0.1-0.3,0.3-0.1l0.2,0.2c0.2,0.1,0.4,0.1,0.5,0c0.1-0.1,0.3-0.1,0.4,0
		c0.1,0.1,0.1,0.3,0,0.5l0,0c-0.1,0.1-0.2,0.4-0.2,0.6v0.3C47.8,42.4,47.9,42.5,48.1,42.5z M48.5,43.3c0,0.2-1,0.8-1.2,0.6l0,0
		c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.2,0.4-0.3,0.7-0.3C48,43,48.5,43.1,48.5,43.3z M35.4,17.3L35.4,17.3c0.2,0,0.3,0.1,0.2,0.1
		c-0.1,0.1,0,0.1,0.2,0.1s0.6,0.1,0.7,0.2c0.3,0.2,0.3,0.6,0.3,0.8c0,0.1-0.1,0.2-0.3,0.2s-0.3-0.1-0.3-0.2c0-0.1-0.2-0.2-0.3-0.2h0
		c-0.2,0-0.3,0.1-0.3,0.1c0,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.2-0.3-0.3c0-0.2-0.1-0.4-0.2-0.4c-0.1,0-0.3-0.1-0.3-0.2
		c0-0.1,0-0.4,0-0.6v-1.4c0-0.2,0.2-0.4,0.3-0.4h0.1c0.2,0,0.3,0.2,0.3,0.4v1.2C35.1,17.1,35.2,17.3,35.4,17.3z M32.7,19v0.1
		c0,0.2-0.1,0.2-0.2,0.1l-0.5-0.5c-0.1-0.1-0.2-0.4-0.2-0.6v-0.1c0-0.2,0.1-0.2,0.3-0.1l0.5,0.5C32.6,18.6,32.7,18.9,32.7,19z
		 M31.5,29c-0.1,0-0.3,0.1-0.5,0.3l-0.1,0.1c-0.1,0.1-0.4,0.1-0.5,0l-1.4-1.4c-0.1-0.1-0.2-0.4-0.1-0.5c0.1-0.2,0.3-0.3,0.5-0.3v0
		h0.3c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.1,0.1,0.2,0.2,0.2s0.4-0.1,0.5-0.3l0.6-0.6
		c0.1-0.1,0.4-0.3,0.6-0.3h0.6c0.2,0,0.4,0.2,0.4,0.4v2.3c0,0.2-0.2,0.4-0.3,0.4h-0.2c-0.2,0-0.4-0.2-0.4-0.4v-0.8
		C31.7,29.1,31.6,29,31.5,29z M30.1,26.3V26c0-0.2,0.2-0.4,0.3-0.4h0.1c0.2,0,0.3-0.2,0.3-0.4c0-0.2,0.1-0.2,0.2-0.1l0.2,0.3
		c0.1,0.1,0.3,0.3,0.5,0.3s0.2,0.2,0.2,0.4v0.1c0,0.2-0.2,0.4-0.4,0.4s-0.5,0-0.7,0h-0.5C30.2,26.6,30.1,26.5,30.1,26.3z"
        />
        <path
          className="st0"
          d="M64,21.6L63.7,21c0,0,0,0,0,0l-0.2-0.6l0,0c-1.6-3.7-3.9-7.1-6.8-10c-3-3.1-6.5-5.4-10.4-7.1
		c-4-1.7-8.3-2.6-12.7-2.6c0,0,0,0,0,0c-4.4,0-8.7,0.9-12.8,2.6c-3.9,1.7-7.4,4.1-10.4,7.1c-3,3.1-5.4,6.6-7,10.6
		c-1.7,4.1-2.6,8.5-2.6,13c0,4.5,0.9,8.8,2.6,12.9c1.7,4,4,7.5,7,10.6c3,3.1,6.5,5.4,10.4,7.1c4,1.7,8.3,2.6,12.7,2.6c0,0,0,0,0,0
		c4.4,0,8.7-0.9,12.8-2.6c3.9-1.7,7.4-4.1,10.4-7.2c3-3.1,5.4-6.6,7-10.6c1.7-4.1,2.6-8.5,2.6-13C66.3,29.7,65.5,25.5,64,21.6
		L64,21.6z M65.1,34c0,4.3-0.8,8.5-2.5,12.5c-1.6,3.8-3.8,7.2-6.7,10.2c-2.9,2.9-6.3,5.3-10,6.9c-3.9,1.7-8,2.5-12.3,2.5
		c0,0,0,0,0,0c-4.3,0-8.4-0.8-12.2-2.5c-3.8-1.6-7.1-3.9-10-6.9c-2.9-2.9-5.2-6.4-6.8-10.2C2.8,42.6,2,38.4,2,34
		c0-4.3,0.8-8.5,2.5-12.5c1.6-3.8,3.8-7.2,6.7-10.2c2.9-2.9,6.3-5.3,10-6.9c3.9-1.7,8-2.5,12.3-2.5c0,0,0,0,0,0
		c4.3,0,8.4,0.8,12.2,2.5c3.8,1.6,7.1,3.9,10,6.8c2.8,2.8,4.9,6,6.5,9.6l0,0l0.5,1.2l0,0C64.3,25.9,65.1,29.8,65.1,34z"
        />
        <path
          className="st0"
          d="M22.8,29.1c0.1,0.1,0.4,0.2,0.6,0.2h0.1c0.2,0,0.4-0.1,0.6-0.2c0.1-0.1,0.1-0.3,0-0.5
		c-0.1-0.1-0.2-0.4-0.2-0.6v-0.5c0-0.2-0.1-0.5-0.2-0.6l-0.2-0.2c-0.1-0.1-0.2-0.1-0.2,0.1v0c0,0.2-0.1,0.5-0.2,0.6l-0.3,0.3h0
		c-0.1,0.1-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6L22.8,29.1z"
        />
        <path
          className="st0"
          d="M38.6,12.9l-0.9,0.9c-0.1,0.1-0.3,0.3-0.3,0.5c0,0,0,0.2,0,0.2c0.2,0.1,1.1-0.5,1.1-0.7l0,0
		c0-0.1,0.2-0.4,0.2-0.5V13C38.8,12.8,38.7,12.8,38.6,12.9z"
        />
      </g>
    </StyledSvgIcon>
  );
};

export default ArborLogo;
