import R from "ramda";

import { createStructuredSelector, createSelector } from "reselect";
import { translateOptionList } from "./utils/translations";
import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";
import { selectTranslations } from "./translations";
import { selectIsFieldComplete } from "./utils/progress";
import {
  LIFE_PROLONG_FORM_ID,
  PAIN_MANAGE_FORM_ID,
  LIFE_PROLONG_FIELD_ID,
  PAIN_MANAGE_FIELD_ID,
} from "../constants/forms";
import { selectIsCurrentUserSpouse } from "./spouse";

const selectArrangementsData = R.pathOr({}, ["yourArrangements"]);
export const selectLifeProlong = R.path([
  "yourArrangements",
  LIFE_PROLONG_FIELD_ID,
]);

export const selectHasSkippedHealthcare = R.path([
  "yourArrangements",
  "healthcareVisited",
]);

export const selectSpouseLifeProlong = R.path([
  "spouseArrangements",
  LIFE_PROLONG_FIELD_ID,
]);

export const selectIsLifeProlongComplete = createSelector(
  selectIsFieldComplete(selectArrangementsData, LIFE_PROLONG_FIELD_ID),
  selectHasSkippedHealthcare,
  (lifeProlongComplete, skippedHealthcare) =>
    lifeProlongComplete || skippedHealthcare,
);
export const selectIsPainManageComplete = createSelector(
  selectIsFieldComplete(selectArrangementsData, PAIN_MANAGE_FIELD_ID),
  selectHasSkippedHealthcare,
  (painManageComplete, skippedHealthcare) =>
    painManageComplete || skippedHealthcare,
);

export const selectLifeProlongForm = createSelector(
  selectIsCurrentUserSpouse,
  selectLifeProlong,
  selectSpouseLifeProlong,
  (isSpouse, lifeProlong, spouseLifeProlong) => ({
    [LIFE_PROLONG_FIELD_ID]: isSpouse ? spouseLifeProlong : lifeProlong,
  }),
);

export const selectLifeProlongTranslations = createSelector(
  selectTranslations([
    "global",
    LIFE_PROLONG_FORM_ID,
    "provinces",
    "appointees",
  ]),
  (translations) => {
    return {
      ...translations,
      optionList: translateOptionList(translations),
    };
  },
);

export const selectPainManage = R.path([
  "yourArrangements",
  PAIN_MANAGE_FORM_ID,
]);
export const selectSpousePainManage = R.path([
  "spouseArrangements",
  PAIN_MANAGE_FORM_ID,
]);

export const selectPainManageForm = createSelector(
  selectIsCurrentUserSpouse,
  selectPainManage,
  selectSpousePainManage,
  (isSpouse, painManage, spousePainManage) => ({
    [PAIN_MANAGE_FORM_ID]: isSpouse ? spousePainManage : painManage,
  }),
);

export const selectPainManageTranslations = createSelector(
  selectTranslations([
    "global",
    PAIN_MANAGE_FORM_ID,
    "provinces",
    "appointees",
  ]),
  (translations) => {
    return {
      ...translations,
      optionList: translateOptionList(translations),
    };
  },
);

export const painManagePageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(PAIN_MANAGE_FORM_ID),
  isLoading: selectIsLoadingById(PAIN_MANAGE_FORM_ID),
  translations: selectPainManageTranslations,
});

export const lifeProlongPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(LIFE_PROLONG_FORM_ID),
  isLoading: selectIsLoadingById(LIFE_PROLONG_FORM_ID),
  translations: selectLifeProlongTranslations,
});
