import React from "react";
import { Box } from "@material-ui/core";

import { H4 } from "./Typography";
import ProgressBar from "./ProgressBar";
import { formatPercentage } from "../../utilities/helpers";

const OverallProgressIndicator = ({
  progress,
  translations,
  isFrenchSelected,
}) => (
  <Box>
    <Box mb={1}>
      <H4 align="left">
        {translations.overallProgress}{" "}
        {formatPercentage(progress / 100, 0, isFrenchSelected ? "fr" : "en")}
      </H4>
    </Box>
    <ProgressBar progress={progress} />
  </Box>
);

export default OverallProgressIndicator;
