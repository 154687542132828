import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";
import { selectIsFieldComplete } from "./utils/progress";
import { GIFTS_FORM_ID, GIFTS_FIELD_ID } from "../constants/forms";
import { selectTranslations } from "./translations";
import { selectIsCurrentUserSpouse, selectHasSpouse } from "./spouse";
import {
  selectIsCurrentProvinceSelectedByCode,
  selectIsPremiumProvinceSelected,
} from "./provinces";
import { selectIsCouplesPlan } from "./plans";

const selectYourEstateData = R.pathOr({}, ["yourEstate"]);
export const selectGifts = R.path(["yourEstate", GIFTS_FIELD_ID]);
export const selectSpouseGifts = R.path(["spouseEstate", GIFTS_FIELD_ID]);
export const selectGiftsForm = createSelector(
  selectGifts,
  selectSpouseGifts,
  selectIsCurrentUserSpouse,
  (gifts, spouseGifts, isSpouse) => ({
    [GIFTS_FIELD_ID]: isSpouse ? spouseGifts : gifts,
  }),
);
export const selectHasSkippedGifts = R.path(["yourEstate", "giftsVisited"]);

export const selectIsGiftsComplete = createSelector(
  selectIsFieldComplete(selectYourEstateData, GIFTS_FIELD_ID),
  selectHasSkippedGifts,
  (giftsComplete, skippedGifts) => giftsComplete || skippedGifts,
);

const selectBequestOfRRSP = createSelector(
  selectHasSpouse,
  selectIsCurrentProvinceSelectedByCode("QC"),
  (hasSpouse, isQuebec) => hasSpouse && isQuebec,
);

export const giftsPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(GIFTS_FORM_ID),
  isLoading: selectIsLoadingById(GIFTS_FORM_ID),
  requiresBequestOfRRSP: selectBequestOfRRSP,
  translations: selectTranslations(["global", GIFTS_FORM_ID]),
  isPremiumProvinceSelected: selectIsPremiumProvinceSelected,
  isCouplesPlan: selectIsCouplesPlan,
});

const selectSpouseEstateData = R.pathOr({}, ["spouseEstate"]);
const selectHasSkippedSpouseGifts = R.path(["spouseEstate", "giftsVisited"]);

export const selectIsSpouseGiftsComplete = createSelector(
  selectIsFieldComplete(selectSpouseEstateData, GIFTS_FIELD_ID),
  selectHasSkippedSpouseGifts,
  (giftsComplete, skippedGifts) => giftsComplete || skippedGifts,
);

export const spouseGiftsPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(GIFTS_FORM_ID),
  isLoading: selectIsLoadingById(GIFTS_FORM_ID),
  translations: selectTranslations(["global", GIFTS_FORM_ID, "spouseEstate"]),
});
