import React from "react";
import SvgIcon from "./SvgIcon";

const CloseGreyIcon = () => {
  return (
    <SvgIcon width="25px" height="24px" viewBox="0 0 25 24">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(0.592476, 0.000000)"
          stroke="#4A4A4A"
          strokeWidth="2"
        >
          <path d="M6,17.3810087 L17.2460055,6"></path>
          <path
            d="M6,17.3810087 L17.2460055,6"
            transform="translate(11.623003, 11.690504) scale(-1, 1) translate(-11.623003, -11.690504) "
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
};

export default CloseGreyIcon;
