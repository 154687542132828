export const fetchReceipt = () => ({
  type: FETCH_RECEIPT_TYPE,
});

export const updateReceipt = (receipt) => ({
  type: UPDATE_RECEIPT_TYPE,
  payload: receipt,
});

export const FETCH_RECEIPT_TYPE = "FETCH_RECEIPT";
export const UPDATE_RECEIPT_TYPE = "UPDATE_RECEIPT";
