import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  executorsNextPage,
  openModalAddExecutors,
  openModalEditExecutors,
  fetchExecutors,
  removeExecutors,
} from "../../../actions/executors";
import { executorsPageProps } from "../../../selectors/executors";

import { DASHBOARD_PATH } from "../../../constants/routes";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import ExecutorsFormDeprecated from "../../../components/forms/your-arrangements/ExecutorsFormDeprecated";

const ExecutorsPage = ({
  fetchExecutors,
  executorsNextPage,
  openModalAddExecutors,
  openModalEditExecutors,
  removeExecutors,
  hasLoaded,
  ...restOfProps
}) => {
  useEffect(() => {
    fetchExecutors();
  }, [fetchExecutors]);
  if (!hasLoaded) return <LoadingSpinner />;

  return (
    <ExecutorsFormDeprecated
      backLink={DASHBOARD_PATH}
      onSubmit={() => executorsNextPage()}
      openModalAddExecutors={openModalAddExecutors}
      openModalEditExecutors={openModalEditExecutors}
      removeExecutors={removeExecutors}
      {...restOfProps}
    />
  );
};

export default connect(executorsPageProps, {
  fetchExecutors,
  executorsNextPage,
  openModalAddExecutors,
  openModalEditExecutors,
  removeExecutors,
})(ExecutorsPage);
