import React, { useEffect } from "react";
import { connect } from "react-redux";

import RestingForm from "../../../components/forms/your-arrangements/RestingForm";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { restingPageProps } from "../../../selectors/wishes";
import { EXECUTORS_PATH } from "../../../constants/routes";
import { RESTING_FORM_ID } from "../../../constants/forms";
import { updateResting } from "../../../actions/wishes";
import { skipSection } from "../../../actions/navigation";
import { getFormData } from "../../../actions/forms";
import { skipWishes } from "../../../api/wishes";

const RestingPage = ({
  getFormData,
  updateResting,
  hasLoaded,
  skipSection,
  ...restOfProps
}) => {
  useEffect(() => {
    getFormData(RESTING_FORM_ID);
  }, []);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <RestingForm
      backLink={EXECUTORS_PATH}
      onSubmit={() => updateResting()}
      onSkip={() => skipSection(skipWishes, RESTING_FORM_ID)}
      {...restOfProps}
    />
  );
};

export default connect(restingPageProps, {
  getFormData,
  updateResting,
  skipSection,
})(RestingPage);
