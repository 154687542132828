import React, { useEffect } from "react";
import { connect } from "react-redux";

import DonationsForm from "../../../components/forms/your-estate/DonationsForm";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { donationsPageProps } from "../../../selectors/donations";
import { GIFTS_PATH } from "../../../constants/routes";
import { DONATIONS_FORM_ID } from "../../../constants/forms";
import { updateDonations, fetchDonations } from "../../../actions/donations";
import { skipSection } from "../../../actions/navigation";
import { skipDonations } from "../../../api/donations";

const DonationsPage = ({
  fetchDonations,
  updateDonations,
  skipSection,
  hasLoaded,
  ...restOfProps
}) => {
  useEffect(() => {
    fetchDonations();
  }, []);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <DonationsForm
      backLink={GIFTS_PATH}
      onSubmit={() => updateDonations()}
      onSkip={() => skipSection(skipDonations, DONATIONS_FORM_ID)}
      {...restOfProps}
    />
  );
};

export default connect(donationsPageProps, {
  fetchDonations,
  updateDonations,
  skipSection,
})(DonationsPage);
