import { postRequest, deleteRequest, getRequest, putRequest } from "./request";
import {
  CHECKOUT_ENDPOINT,
  PROMO_CODE_ENDPOINT,
  VALIDATE_CAA_ENDPOINT,
  REFERRAL_ENDPOINT,
  PARTNER_DISCOUNT_ENDPOINT,
  APPLY_PARTNER_DISCOUNT_ENDPOINT,
  UPDATE_PAYMENT_METHOD_ENDPOINT,
  STRIPE_SESSION_STATUS_ENDPOINT,
  PROCESS_STRIPE_CHECKOUT_SESSION_ENDPOINT,
  CREATE_STRIPE_CLIENT_SECRET_ENDPOINT,
  FETCH_CHECKOUT_ENDPOINT,
} from "../constants/routes";

export const submitCheckout = (config) =>
  postRequest(CHECKOUT_ENDPOINT, config);

export const getCheckout = (config) =>
  postRequest(FETCH_CHECKOUT_ENDPOINT, config);

export const removeReferral = (config) =>
  deleteRequest(REFERRAL_ENDPOINT, config);

export const submitPromoCode = (config) =>
  postRequest(PROMO_CODE_ENDPOINT, config);

export const submitValidateCaaMembership = (config) =>
  postRequest(VALIDATE_CAA_ENDPOINT, config);

export const submitPartnerDiscountCheckout = (config) =>
  postRequest(PARTNER_DISCOUNT_ENDPOINT, config);

export const reapplyPartnerDiscount = (config) =>
  postRequest(APPLY_PARTNER_DISCOUNT_ENDPOINT, config);

export const submitCreditCard = (config) =>
  putRequest(UPDATE_PAYMENT_METHOD_ENDPOINT, config);

export const getStripeSessionStatusEndpoint = (config) =>
  postRequest(STRIPE_SESSION_STATUS_ENDPOINT, config);

export const processStripeCheckoutSessionEndpoint = (config) =>
  postRequest(PROCESS_STRIPE_CHECKOUT_SESSION_ENDPOINT, config);

export const createStripeClientSecret = (config) =>
  getRequest(CREATE_STRIPE_CLIENT_SECRET_ENDPOINT, config);
