import React from "react";
import { Field, getFormValues } from "redux-form";
import { useSelector } from "react-redux";
import {
  useValidateFirstNameMemo,
  useValidateMiddleNameMemo,
  useValidateLastNameMemo,
  useValidateSpouseLastNameMemo,
  useValidateSpouseFirstNameMemo,
  useValidateSpouseFirstNameRequiredMemo,
  useValidateSpouseLastNameRequiredMemo,
} from "./validations";

import TextInput from "./TextInput";
import {
  FIRST_NAME_FIELD_ID,
  MIDDLE_NAME_FIELD_ID,
  LAST_NAME_FIELD_ID,
  SPOUSE_FIRST_NAME_FIELD_ID,
  SPOUSE_MIDDLE_NAME_FIELD_ID,
  SPOUSE_LAST_NAME_FIELD_ID,
} from "../../../constants/forms";

const CoupleNameFields = ({
  fieldName,
  autoFocus,
  useTheir,
  translations,
  formId,
}) => {
  const firstFieldName = fieldName
    ? `${fieldName}.${FIRST_NAME_FIELD_ID}`
    : FIRST_NAME_FIELD_ID;
  const middleFieldName = fieldName
    ? `${fieldName}.${MIDDLE_NAME_FIELD_ID}`
    : MIDDLE_NAME_FIELD_ID;
  const lastFieldName = fieldName
    ? `${fieldName}.${LAST_NAME_FIELD_ID}`
    : LAST_NAME_FIELD_ID;
  const spouseFirstFieldName = fieldName
    ? `${fieldName}.${SPOUSE_FIRST_NAME_FIELD_ID}`
    : SPOUSE_FIRST_NAME_FIELD_ID;
  const spouseMiddleFieldName = fieldName
    ? `${fieldName}.${SPOUSE_MIDDLE_NAME_FIELD_ID}`
    : SPOUSE_MIDDLE_NAME_FIELD_ID;
  const spouseLastFieldName = fieldName
    ? `${fieldName}.${SPOUSE_LAST_NAME_FIELD_ID}`
    : SPOUSE_LAST_NAME_FIELD_ID;
  const firstNamePlaceholder = useTheir
    ? translations.label.firstNameTheirPlaceholder
    : translations.label.firstNamePlaceholder;
  const middleNamePlaceholder = useTheir
    ? translations.label.middleNameTheirPlaceholder
    : translations.label.middleNamePlaceholder;
  const lastNamePlaceholder = useTheir
    ? translations.label.lastNameTheirPlaceholder
    : translations.label.lastNamePlaceholder;

  const validateSpouseFirstName = useValidateSpouseFirstNameMemo(translations);
  const validateSpouseLastName = useValidateSpouseLastNameMemo(translations);
  const validateSpouseFirstNameRequired = useValidateSpouseFirstNameRequiredMemo(
    translations,
  );
  const validateSpouseLastNameRequired = useValidateSpouseLastNameRequiredMemo(
    translations,
  );
  let spouseFirstNameValidation = validateSpouseFirstName;
  let spouseLastNameValidation = validateSpouseLastName;

  const formData = useSelector(getFormValues(formId));
  if (formData && formData[fieldName]) {
    const { spouseFirstName, spouseLastName } = formData[fieldName];
    if (spouseFirstName) {
      // Spouse's last name is required if the user provided a First name
      spouseLastNameValidation = validateSpouseLastNameRequired;
    }
    if (spouseLastName) {
      // Spouse's first name is required if the user provided a Last name
      spouseFirstNameValidation = validateSpouseFirstNameRequired;
    }
  }
  return (
    <>
      <Field
        component={TextInput}
        autoFocus={autoFocus}
        validate={useValidateFirstNameMemo(translations)}
        label={translations.label.firstName}
        placeholder={firstNamePlaceholder}
        name={firstFieldName}
      />
      <Field
        component={TextInput}
        label={translations.label.middleName}
        validate={useValidateMiddleNameMemo(translations)}
        placeholder={middleNamePlaceholder}
        name={middleFieldName}
      />
      <Field
        component={TextInput}
        validate={useValidateLastNameMemo(translations)}
        label={translations.label.lastName}
        placeholder={lastNamePlaceholder}
        name={lastFieldName}
      />
      <Field
        component={TextInput}
        validate={spouseFirstNameValidation}
        label={translations.label.spouseFirstName}
        placeholder={firstNamePlaceholder}
        name={spouseFirstFieldName}
      />
      <Field
        component={TextInput}
        label={translations.label.spouseMiddleName}
        validate={useValidateSpouseLastNameMemo(translations)}
        placeholder={middleNamePlaceholder}
        name={spouseMiddleFieldName}
      />
      <Field
        component={TextInput}
        validate={spouseLastNameValidation}
        label={translations.label.spouseLastName}
        placeholder={lastNamePlaceholder}
        name={spouseLastFieldName}
      />
    </>
  );
};

export default CoupleNameFields;
