export const updateLifeProlong = () => ({
  type: UPDATE_LIFE_PROLONG_TYPE,
});

export const updatePainManage = () => ({
  type: UPDATE_PAIN_MANAGE_TYPE,
});

// TODO: deprecated
export const fetchHealthcarePain = () => ({
  type: FETCH_PAIN_MANAGE_TYPE,
});

// TODO: deprecated
export const updateHealthcarePain = () => ({
  type: UPDATE_PAIN_MANAGE_TYPE,
});

export const FETCH_LIFE_PROLONG_TYPE = "FETCH_HEALTHCARE_LIFE";
export const UPDATE_LIFE_PROLONG_TYPE = "UPDATE_HEALTHCARE_LIFE";
export const FETCH_PAIN_MANAGE_TYPE = "FETCH_HEALTHCARE_PAIN";
export const UPDATE_PAIN_MANAGE_TYPE = "UPDATE_HEALTHCARE_PAIN";
