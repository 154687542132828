import React from "react";

import SvgIcon from "./SvgIcon";

const FinancialAssetIcon = ({ isDesktop }) => {
  return (
    <SvgIcon fill="none" width={isDesktop ? 100 : 72} viewBox="0 0 100 69.83">
      <g clipPath="url(#clip0_8353_84685)">
        <path
          d="M50 69.8283C77.6142 69.8283 100 54.9365 100 36.5665C100 18.1965 77.6142 3.30469 50 3.30469C22.3858 3.30469 0 18.1965 0 36.5665C0 54.9365 22.3858 69.8283 50 69.8283Z"
          fill="#F6F6F6"
        />
        <path
          d="M38.8413 39.313C43.9915 30.343 56.5666 23.0469 66.8241 23.0469C77.1245 23.0469 81.2876 30.343 76.1803 39.313C71.0301 48.2829 58.455 55.5791 48.1975 55.5791C37.8971 55.5791 33.6911 48.2829 38.8413 39.313Z"
          fill="#2F455F"
        />
        <path
          d="M29.6996 32.5752C32.4464 37.2962 36.2661 41.4593 40.4292 43.8627L36.6095 46.0945C32.4035 43.6911 28.6267 39.5709 25.8799 34.807C27.1245 34.0773 28.4121 33.3048 29.6996 32.5752Z"
          fill="#FDBD00"
        />
        <path
          d="M40.4291 43.8203C44.6351 46.2237 48.4549 46.4383 51.2016 44.8504L47.3819 47.0821C44.6351 48.713 40.8154 48.4984 36.6094 46.0521C37.7682 45.4083 39.2703 44.507 40.4291 43.8203Z"
          fill="#FDBD00"
        />
        <path
          d="M25.236 17.6829C25.236 22.4898 26.9527 27.8117 29.6995 32.5327C28.412 33.2623 27.1673 34.0348 25.8798 34.7645C23.133 30.0434 21.4163 24.6786 21.4163 19.9147C21.4163 15.1507 23.0901 11.7602 25.8369 10.1722L29.6566 7.94043C26.9098 9.57133 25.236 12.919 25.236 17.6829Z"
          fill="#FDBD00"
        />
        <path
          d="M25.7939 10.2151L29.6137 7.98334C32.3605 6.35243 36.1802 6.56703 40.3862 9.01338L36.5665 11.2451C32.4034 8.79879 28.5836 8.58419 25.7939 10.2151Z"
          fill="#E39F3A"
        />
        <path
          d="M21.9741 3.6482L25.7939 1.41644C29.5278 -0.772399 34.7209 -0.47197 40.4291 2.78983L36.6093 5.02159C30.8582 1.75979 25.708 1.45936 21.9741 3.6482Z"
          fill="#FFF2AD"
        />
        <path
          d="M51.2017 44.8496L47.3819 47.0813C50.0858 45.4933 51.8025 42.1028 51.8025 37.3388C51.8025 27.7251 44.9785 16.0084 36.6094 11.2015L40.4291 8.96973C48.7982 13.7766 55.6223 25.4933 55.6223 35.1071C55.6223 39.9139 53.9484 43.2616 51.2017 44.8496Z"
          fill="#E39F3A"
        />
        <path
          d="M55.0643 51.4166L51.2446 53.6484C54.9356 51.5025 57.2102 46.9102 57.2102 40.4295C57.2102 27.3823 47.9828 11.5454 36.6094 5.0218L40.4291 2.79004C51.8025 9.31364 61.03 25.1506 61.03 38.1978C61.03 44.6784 58.7553 49.2707 55.0643 51.4166Z"
          fill="#FFDC1E"
        />
        <path
          d="M57.2103 40.429C57.2103 46.9097 54.9356 51.4591 51.2446 53.6479L55.0644 51.4161C58.7554 49.2702 61.03 44.678 61.03 38.1973L57.2103 40.429Z"
          fill="#E39F3A"
        />
        <path
          d="M36.6094 5.02175C25.236 -1.50186 16.0085 3.77711 16.0085 16.8243C16.0085 29.8715 25.236 45.7084 36.6094 52.232C47.9828 58.7556 57.2103 53.4767 57.2103 40.4295C57.2103 27.4252 47.9828 11.5454 36.6094 5.02175ZM36.6094 46.0518C28.2403 41.2878 21.4163 29.5282 21.4163 19.9145C21.4163 10.3007 28.2403 6.39514 36.6094 11.202C44.9785 16.0089 51.8025 27.7256 51.8025 37.3393C51.8025 46.9531 44.9785 50.8587 36.6094 46.0518Z"
          fill="#FDBD00"
        />
        <path
          d="M36.6094 46.0518C28.2403 41.2449 21.4163 29.5282 21.4163 19.9145C21.4163 10.3007 28.2403 6.39514 36.6094 11.202V5.02175C25.236 -1.50186 16.0085 3.77711 16.0085 16.8243C16.0085 29.8715 25.236 45.7084 36.6094 52.232V46.0518Z"
          fill="#FFDC1E"
        />
        <path
          d="M36.6094 11.2018C44.9785 16.0086 51.8025 27.7254 51.8025 37.3391C51.8025 46.9528 44.9785 50.8584 36.6094 46.0515C28.2403 41.2876 21.4163 29.5279 21.4163 19.9142C21.4163 10.3434 28.2403 6.39489 36.6094 11.2018Z"
          fill="#E39F3A"
        />
        <path
          opacity="0.3"
          d="M36.2229 40.344V41.8891C36.2229 42.2324 36.18 42.447 36.0513 42.4899L38.5405 41.0307C38.6693 40.9448 38.7122 40.7732 38.7122 40.4298V38.8848L36.2229 40.344Z"
          fill="#FFDC1E"
        />
        <path
          opacity="0.3"
          d="M37.6393 36.9532L40.1286 35.4939C39.5707 35.8373 38.5406 35.5368 37.1243 34.7214C35.2359 33.6484 34.2059 32.1463 33.6479 30.2579C33.4333 29.6141 33.2616 29.2708 32.9183 29.0991C32.7895 29.0132 32.6179 28.9703 32.4462 28.9274L31.2016 28.7557C30.987 28.7128 30.8153 28.7128 30.6865 28.7986L28.1973 30.2579C28.326 30.172 28.4977 30.172 28.7123 30.215L29.9569 30.3866C30.1286 30.4296 30.3003 30.4725 30.429 30.5583C30.7724 30.73 30.944 31.0733 31.1586 31.7171C31.7166 33.6055 32.7466 35.1077 34.635 36.1806C36.0513 36.9961 37.0814 37.2965 37.6393 36.9532Z"
          fill="#FFDC1E"
        />
        <path
          opacity="0.3"
          d="M33.2617 18.8414L35.2789 17.6397L35.4506 17.7255C36.0514 18.0689 36.2231 18.4122 36.2231 19.0989V20.5581C38.6695 22.3607 39.9999 24.5495 40.9441 27.3392C41.1587 27.9401 41.1587 28.2834 40.9441 28.4122L43.4334 26.953C43.6909 26.8242 43.648 26.4809 43.4763 25.88C42.5321 23.0903 41.2017 20.9015 38.7553 19.0989V17.6397C38.7553 16.953 38.5836 16.6096 37.9828 16.2663L36.5235 15.4508C36.2231 15.2792 36.0514 15.2362 35.9227 15.2792L33.4334 16.7384C32.8755 16.7384 32.4034 16.8242 32.0171 17.0388L29.5278 18.498C30.3433 18.0689 31.5879 18.1118 33.2617 18.8414ZM33.7768 16.7813C33.648 16.7813 33.5192 16.7384 33.4334 16.7384C33.5192 16.7384 33.648 16.7384 33.7768 16.7813Z"
          fill="#FFDC1E"
        />
        <path
          opacity="0.3"
          d="M37.6393 26.5236C35.708 25.1932 34.0341 23.9056 34.0341 22.146C34.0341 21.4593 34.2058 21.0301 34.5062 20.8584L31.9741 22.3176C31.6307 22.4893 31.502 22.9614 31.502 23.6052C31.502 25.3219 33.1758 26.6095 35.1071 27.9829C37.9397 29.9571 41.3732 32.4464 41.3732 37.382C41.3732 38.97 40.9011 40.0429 40.0427 40.558L42.532 39.0987C43.3904 38.5837 43.8625 37.5108 43.8625 35.9228C43.9054 30.9872 40.4719 28.455 37.6393 26.5236Z"
          fill="#FFDC1E"
        />
        <path
          d="M35.4505 17.7253C36.0513 18.0687 36.223 18.412 36.223 19.0987V20.5579C38.6694 22.3605 39.9998 24.5493 40.9441 27.339C41.2445 28.1974 41.1157 28.5408 40.429 28.4978L39.1844 28.412C38.9698 28.412 38.7981 28.3691 38.6694 28.2832C38.4119 28.1116 38.1973 27.8111 37.9827 27.2103C37.3389 25.4077 36.5664 24.1202 34.7209 23.0472C32.532 21.8026 31.5449 21.9313 31.5449 23.6481C31.5449 25.3648 33.2187 26.6523 35.1501 28.0257C37.9827 30 41.4162 32.4893 41.4162 37.4249C41.4162 40.6008 39.6136 41.7596 36.2659 40.3863V41.9313C36.2659 42.618 36.0943 42.7468 35.4934 42.4034L34.0771 41.588C33.4762 41.2446 33.3046 40.9013 33.3046 40.2146V38.7124C30.6865 36.824 28.9698 34.1631 28.1973 31.2446C27.9827 30.3863 28.1544 30.1287 28.7981 30.2146L30.0428 30.3863C30.2144 30.4292 30.3861 30.4721 30.5149 30.5579C30.8582 30.7296 31.0299 31.0729 31.2445 31.7167C31.8024 33.6051 32.8325 35.1073 34.7209 36.1802C37.0385 37.5107 38.2402 37.4249 38.2402 35.6223C38.2402 33.7768 36.5664 32.4893 34.5921 31.1588C31.7166 29.1416 28.2831 26.4378 28.2831 21.7167C28.2831 18.5837 29.9569 17.382 33.3046 18.7983V17.339C33.3046 16.6523 33.5192 16.5236 34.0771 16.8669L35.4505 17.7253Z"
          fill="#FFF2AD"
        />
      </g>
      <defs>
        <clipPath id="clip0_8353_84685">
          <rect width="100" height="69.8283" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default FinancialAssetIcon;
