import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";

import { H4, SmallBody } from "../components/ui/Typography";
import ProgressBar from "../components/ui/ProgressBar";

import { navigationProps } from "../selectors/navigation";

const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  padding: ${(props) => (props.isDesktop ? "16px" : "12px")};

  background-color: #ffffff;
`;

const SectionProgress = ({ progress = 44, translations, isDesktop }) => {
  const progressBarHeight = isDesktop ? 10 : 8;
  return (
    <>
      <ProgressBar progress={progress} height={progressBarHeight} />
      <Container isDesktop={isDesktop}>
        <H4 component="span" color="primary">
          {translations.title}
        </H4>
        <Box ml={0.5}>
          <SmallBody>{`(${translations.progressLabel})`}</SmallBody>
        </Box>
      </Container>
    </>
  );
};

const BottomNav = ({ shouldDisplayNav, progress, translations, disabled }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  if (!shouldDisplayNav) return null;
  return (
    <Box
      position="fixed"
      bottom="0"
      bgcolor="willfulWhite.main"
      width="100%"
      zIndex={3}
      display="flex"
      flexDirection="column"
    >
      <SectionProgress
        progress={progress}
        translations={translations}
        disabled={disabled}
        isDesktop={isDesktop}
      />
    </Box>
  );
};

export default connect(navigationProps)(BottomNav);
