import React from "react";

import SvgIcon from "./SvgIcon";

const YourAccountIcon = ({ height }) => {
  return (
    <SvgIcon height={height} viewBox="0 0 133.04 111.98">
      <path
        d="M109.27,140.93c61.38-7,58.26-36.36-3.22-30.33C44.67,117.6,47.79,147,109.27,140.93Z"
        transform="translate(-20.73 -29.76)"
        fill="#2f455f"
      />
      <path
        d="M92.31,129a0,0,0,0,0,0,0c-6.45,2.85-13.89,2.65-20.59.78-.53-.19-1.12-.32-1.64-.54-1.09-.36-2.21-.67-3.27-1.14a.14.14,0,0,1-.08,0c-.54-.21-1.07-.44-1.6-.66,0,0-.08,0-.12,0a55.12,55.12,0,0,1-12-7.45s0,0-.08,0a59.08,59.08,0,0,1-8.07-7.9l11.09-9.28c11.3,12.93,30.36,24,47.6,16.94Z"
        transform="translate(-20.73 -29.76)"
        fill="#0064dc"
      />
      <path
        d="M48.14,91.7c.32.61.69,1.22,1,1.79l-11,9.23c-.9-1.52-1.73-3-2.48-4.63-.49-1.14-1.08-2.28-1.5-3.46,0,0,0,0,0-.08-.43-1-.81-2.11-1.18-3.17-2.38-7.29-3.36-15.24-1.59-22.78a28.38,28.38,0,0,1,2.08-5.81,0,0,0,0,1,0,0,28.51,28.51,0,0,1,1.42-2.48,0,0,0,0,1,0,0A25.16,25.16,0,0,1,36.59,58c1.75-2.22,11.69-10.05,13.95-12.08a25.33,25.33,0,0,0-2.16,2c-.26.33-.63.64-.85,1a24.29,24.29,0,0,0-3.05,4.68,0,0,0,0,1,0,0,33.92,33.92,0,0,0-1.67,4.15c-2.66,9-1.24,18.92,2.32,27.53,0,0,0,0,0,.08A68.69,68.69,0,0,0,48.14,91.7Z"
        transform="translate(-20.73 -29.76)"
        fill="#0064dc"
      />
      <path
        d="M72.21,103.65c-11.15-4.64-27.12-5.88-34-1,2.14-1.52,11.64-10.34,14-11,.59-.26,1.21-.42,1.79-.66a23.41,23.41,0,0,1,7.45-1,59,59,0,0,1,15.6,2c.57.2,1.21.33,1.75.53.18.07.41.13.57.21a7.58,7.58,0,0,0,.77.24c.45.22.94.28,1.38.53a11.81,11.81,0,0,1,1.27.57c.25.09.56.25.85.37,0,0,.08,0,.12,0,.37.16.74.32,1.14.53a0,0,0,0,1,0,0,103.76,103.76,0,0,1,10.67,6c5.7,3.78,11.15,8.2,14.94,14l-11,9.24C93.26,115.25,79.18,106.56,72.21,103.65Z"
        transform="translate(-20.73 -29.76)"
        fill="#71adee"
      />
      <path
        d="M74.13,85.29a1.58,1.58,0,0,1-.53.24h0a1.2,1.2,0,0,1-.57.16c-.08,0-.2,0-.28.08s0,0-.08,0a2.85,2.85,0,0,1-.41,0h-.41a6.27,6.27,0,0,1-2.07-.16,12.84,12.84,0,0,1-1.55-.53c-.25-.13-.55-.25-.77-.36s-.37-.25-.57-.37c0,0-.09,0-.12,0a5.12,5.12,0,0,1-.77-.57,4.15,4.15,0,0,1-.49-.45,0,0,0,0,1,0,0,13.58,13.58,0,0,1-2.44-3,0,0,0,0,0,0,0s0-.08-.08-.12a3.17,3.17,0,0,1-.25-.53s0-.08-.08-.12c-.08-.23-.19-.52-.28-.73A4.18,4.18,0,0,0,62,78c0-.12-.07-.38-.12-.49a11.41,11.41,0,0,1-.08-3.3c0-.08,0-.2.08-.28a2.35,2.35,0,0,1,.16-.49c.06-.07.11-.23.17-.32a4.55,4.55,0,0,1,.32-.49s0,0,0-.08a5.2,5.2,0,0,1,.78-.9l11-9.23a4.68,4.68,0,0,0-1.18,1.63,6.84,6.84,0,0,0-.13,4.76c.09.2.13.44.21.65a.14.14,0,0,0,0,.08,5,5,0,0,0,.32.77,12.37,12.37,0,0,0,4.19,4.8s0,0,.08,0c.21.12.41.24.66.37,0,0,0,0,.08,0a4.35,4.35,0,0,0,.65.32,0,0,0,0,1,0,0,9.44,9.44,0,0,0,1.22.45c.1.06.28,0,.4.12a10.8,10.8,0,0,0,1.1.16,7.45,7.45,0,0,0,1.34,0,3.74,3.74,0,0,0,.66-.16A5.68,5.68,0,0,0,85.19,76a0,0,0,0,0,0,0c.17-.12.33-.2.49-.32l-11,9.23A2.88,2.88,0,0,1,74.13,85.29Z"
        transform="translate(-20.73 -29.76)"
        fill="#bc7f35"
      />
      <path
        d="M69,61.37c-9.35-3.3-16.92,1.58-17,10.89s7.45,19.53,16.8,22.82,16.92-1.59,17-10.9S78.4,64.66,69,61.37ZM69,85.44c-4-1.42-7.2-5.77-7.2-9.76,1.17-10.87,15.13-2.33,14.48,5.08C76.2,84.75,73,86.86,69,85.44Z"
        transform="translate(-20.73 -29.76)"
        fill="#eccc1c"
      />
      <path
        d="M96.56,75.68a16.94,16.94,0,0,0-.29-4c-.17-.76-.34-1.52-.56-2.26a4.69,4.69,0,0,1-.24-.89,22.85,22.85,0,0,0-1.22-3c-.16-.37-.36-.69-.52-1,0,0,0,0,0-.08a21.54,21.54,0,0,0-3.36-4.69,23,23,0,0,0-3.51-3.4s-.08-.08-.12-.08c-.28-.22-.62-.47-.93-.68a0,0,0,0,1,0,0c-.29-.21-.57-.41-.89-.61a0,0,0,0,1,0,0c-.28-.15-.53-.37-.81-.53,0,0,0,0-.08,0-.23-.12-.47-.27-.69-.4a4.74,4.74,0,0,1-.81-.41l-.89-.44L81,52.85s-.12,0-.16-.08c-.26-.11-.57-.2-.85-.33s-.56-.21-.85-.28-.79-.19-1.17-.32a16,16,0,0,0-2.83-.45,13.09,13.09,0,0,0-5.7.89C67.6,52.76,57.7,61.73,56,63c3.82-3.21,9.42-2.9,13.82-1.09,0,0,.12,0,.16.08,9.85,4.31,18.09,15.76,15,26.71a12.13,12.13,0,0,1-.73,1.82c-.16.37-.43.74-.61,1.09a9.57,9.57,0,0,1-1.85,2.11c1.61-1.55,12-9.56,12.89-11.4v0a7.71,7.71,0,0,0,.6-1.09c.12-.29.25-.53.37-.81a.14.14,0,0,1,0-.08s0-.08,0-.12a17.1,17.1,0,0,0,.77-3.4,8.36,8.36,0,0,0,0-1.13Z"
        transform="translate(-20.73 -29.76)"
        fill="#bc7f35"
      />
      <path
        d="M69.32,41.85C96,51.26,117.63,80.53,117.51,107.2s-21.89,40.64-48.59,31.26S20.61,99.74,20.73,73.07,42.62,32.43,69.32,41.85Zm30.14,82.51C120.77,101,96.23,60.94,69.08,51.73c-36.13-12.12-48,24.92-30.9,51C55.8,92.21,86.92,106.84,99.46,124.36Zm-30.7,4.43c8.94,3.17,17.2,3,23.79.08-2-5-14.68-13.74-23.71-17s-21.72-3.42-23.79.2A57,57,0,0,0,68.76,128.79Z"
        transform="translate(-20.73 -29.76)"
        fill="#e1f3f9"
      />
      <path
        d="M128.23,101.05a46.35,46.35,0,0,1-.57,4.86,34.93,34.93,0,0,1-1.89,6.79s0,.13-.08.17v0a35.34,35.34,0,0,1-2,4.25s0,0,0,.08c-.29.5-.62,1-.9,1.48a0,0,0,0,1,0,0c-2.91,4.9-12.5,11.81-16.78,15.62a30.15,30.15,0,0,0,5.73-6.42c.33-.49.61-1,.9-1.47,0,0,0,0,0-.08a37.18,37.18,0,0,0,2.13-4.46c4.57-11.9,3-25.43-1.88-37,0,0,0-.08,0-.17A61.86,61.86,0,0,0,110,78.67s0,0,0-.08a77.72,77.72,0,0,0-7.2-11.38s0,0,0-.08c-8-10.57-18.68-19.3-31-24.21,0-.05-.12-.05-.16-.09-12.71-5.15-28.67-5.67-39.68,3.48C33.71,44.76,43.44,36.07,45.35,35a33.76,33.76,0,0,1,8-3.76A42.92,42.92,0,0,1,76,31.3s.08,0,.08,0c1.43.4,2.83.82,4.22,1.35,0,0,.12,0,.16,0,.61.24,1.27.45,1.88.69,0,0,.1.06.16,0,.74.26,1.44.6,2.13.9,0,0,.08,0,.12,0,.66.28,1.31.61,2,.9,0,0,.12,0,.16.08,2.59,1.24,5.08,2.73,7.53,4.21.85.57,1.7,1.17,2.58,1.8,0,0,0,0,.08,0a74.66,74.66,0,0,1,15.17,14.48,72.67,72.67,0,0,1,5.32,7.45s0,.09,0,.12c1.15,1.83,2.24,3.76,3.28,5.65,0,0,0,0,0,.08.27.54.59,1.14.86,1.68.49,1,.91,2,1.39,3.1,0,0,0,0,0,0,.2.45.37.85.53,1.3,0,.05,0,.09,0,.13.19.43.32.85.49,1.27.27.82.62,1.68.9,2.49s.55,1.64.82,2.46c0,0,0,.08,0,.12.23.74.39,1.51.61,2.25.4,1.53.67,3.18,1,4.7.15.73.21,1.49.32,2.21A53,53,0,0,1,128.23,101.05Z"
        transform="translate(-20.73 -29.76)"
        fill="#71adee"
      />
    </SvgIcon>
  );
};

export default YourAccountIcon;
