import React, { useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import { WILL_REGISTRY_URL } from "../../constants/routes";
import { Body, ExternalLink, H2, H3, SmallBody } from "../ui/Typography";

// No button similar can be found in our Button library
const CopyButton = styled(Box)`
  cursor: pointer;
`;

const CodeArea = styled.textarea`
  resize: none;
  border: 0;
  font-size: 24px;
  height: 40px;
  text-align: center;
  font-weight: 700;
  /* makes sure we don't cut off code */
  width: 90%;
  min-width: 90%;
`;

const SingleCopyCode = ({
  translations,
  willRegistryCouponUser,
  displayIssueDate = false,
  createdAt,
}) => {
  const primaryCodeRef = useRef(null);
  const [primaryCopyText, primarySetCopySuccess] = useState(
    translations.button.copy,
  );
  const primaryHandleCopyButton = (e) => {
    primaryCodeRef.current.select();
    document.execCommand("copy");

    e.target.blur();
    primarySetCopySuccess(translations.button.copied);
  };
  return (
    <Box mx={2} mb={2}>
      <Box
        bgcolor="willfulWhite.main"
        border={1}
        borderColor="border.main"
        borderRadius={2}
        height={62}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box justifyContent="center" display="flex" width={1}>
          <CodeArea
            readOnly
            value={willRegistryCouponUser}
            ref={primaryCodeRef}
          >
            {willRegistryCouponUser}
          </CodeArea>
        </Box>
        <CopyButton
          width="50%"
          border={1}
          borderTop={0}
          borderRight={0}
          borderBottom={0}
          height={1}
          borderColor="border.main"
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Body
            component="span"
            onClick={(e) => primaryHandleCopyButton(e)}
            color="willfulBlue"
          >
            {primaryCopyText}
          </Body>
        </CopyButton>
      </Box>
      {displayIssueDate && (
        <Box mt={0.5}>
          <SmallBody>
            {translations.issuedOn} {createdAt}
          </SmallBody>
        </Box>
      )}
    </Box>
  );
};

const WillRegistryNotice = ({
  isTablet,
  translations,
  isCouplesPlan,
  isPaid = false,
}) => {
  const {
    willRegistryCouponUser,
    willRegistryCouponSpouse,
    userRegistryContentTitle,
    userRegistryContent,
    spouseRegistryContentTitle,
    copyCodeText,
    willRegistryValue,
    registryHeader,
    registryDescription,
  } = translations;

  const secondaryCodeRef = useRef(null);
  const [secondaryCopyText, secondarySetCopySuccess] = useState(
    translations.button.copy,
  );
  const secondaryHandleCopyButton = (e) => {
    secondaryCodeRef.current.select();
    document.execCommand("copy");

    e.target.blur();
    secondarySetCopySuccess(translations.button.copied);
  };

  return (
    <Box mt={4.5}>
      <Grid container>
        {isTablet && <Grid item md={2} />}
        <Grid item lg={6} md={8} sm={12}>
          <Box width={1}>
            <Box mb={1}>
              <H2 id="will-registry" align="left">
                {registryHeader}
              </H2>
            </Box>
            <Body>{registryDescription}</Body>
          </Box>
          <Box width={1} mt={2}>
            <H3 align="left" data-hj-suppress>
              {userRegistryContentTitle}
            </H3>
            {isPaid && (
              <Box mt={2}>
                <Body>{userRegistryContent}</Body>
              </Box>
            )}
            <Box
              mt={2}
              display="flex"
              flexDirection="column"
              width={1}
              minHeight={170}
              borderRadius={5}
              bgcolor="gray.main"
            >
              <Box mx={2} mt={2} mb={1}>
                <Body align="left">
                  {copyCodeText}{" "}
                  <ExternalLink
                    text={translations.willRegistryWebsite}
                    target="_blank"
                    href={WILL_REGISTRY_URL}
                  />{" "}
                  {willRegistryValue}
                </Body>
              </Box>
              <SingleCopyCode
                willRegistryCouponUser={willRegistryCouponUser}
                translations={translations}
              />
            </Box>
          </Box>
          {isCouplesPlan && (
            <Box width={1} mt={4.5}>
              <H3 align="left" data-hj-suppress>
                {spouseRegistryContentTitle}
              </H3>
              <Box
                mt={2}
                display="flex"
                flexDirection="column"
                width={1}
                minHeight={170}
                borderRadius={5}
                bgcolor="gray.main"
              >
                <Box mx={2} my={1}>
                  <Body align="left">
                    {copyCodeText}{" "}
                    <ExternalLink
                      text={translations.willRegistryWebsite}
                      target="_blank"
                      href={WILL_REGISTRY_URL}
                    />{" "}
                    {willRegistryValue}
                  </Body>
                </Box>
                <Box mx={2} mb={1}>
                  <Box
                    bgcolor="willfulWhite.main"
                    border={1}
                    borderColor="border.main"
                    borderRadius={2}
                    maxWidth={300}
                    height={62}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box justifyContent="center" display="flex" width={1}>
                      <CodeArea
                        readOnly
                        value={willRegistryCouponSpouse}
                        ref={secondaryCodeRef}
                      >
                        {willRegistryCouponSpouse}
                      </CodeArea>
                    </Box>
                    <CopyButton
                      width="50%"
                      border={1}
                      borderTop={0}
                      borderRight={0}
                      borderBottom={0}
                      height={1}
                      borderColor="border.main"
                      justifyContent="center"
                      alignItems="center"
                      display="flex"
                    >
                      <Body
                        component="span"
                        onClick={(e) => secondaryHandleCopyButton(e)}
                        color="willfulBlue"
                      >
                        {secondaryCopyText}
                      </Body>
                    </CopyButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default WillRegistryNotice;
