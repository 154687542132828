import R from "ramda";
import { select, takeEvery, call, put } from "redux-saga/effects";

import { selectCurrentRoute } from "../selectors/location";
import {
  FETCH_PLANS_TYPE,
  UPDATE_PLANS_TYPE,
  UPGRADE_PLAN_TYPE,
  addPlans,
  setLoadingPlan,
} from "../actions/plans";
import {
  selectedPlanAnalytics,
  viewPlansAnalytics,
  userPlanQuantityAnalytics,
} from "../actions/analytics";
import { submitFormRequest } from "./forms";
import { fetchApiData } from "./requests";
import { fetchSuccess } from "../actions/requests";
import {
  PLANS_PATH,
  FAMILY_RECOMMENDED_PATH,
  PREMIUM_RECOMMENDED_PATH,
  DOCUMENTS_PATH,
  CHECKOUT_PATH,
} from "../constants/routes";
import { getPlans, getUpgradePlans, submitPlans } from "../api/plans";
import { PRODUCTS_FORM_ID, UPSELL_FORM_ID } from "../constants/forms";
import { selectProvinceCode } from "../selectors/provinces";
import {
  selectAvailableProducts,
  selectActivePlan,
  selectUpgradedPlan,
  selectIsUserUpgrading,
} from "../selectors/plans";
import { PREMIUM_PLAN, PREMIUM_PRICE, BUNDLE_PRICE } from "../constants/plans";
import { updateCart } from "../actions/cart";
import { getDashboard } from "../api/dashboard";
import { selectHasLoaded } from "../selectors/requests";
import { selectShouldDisplayHardPaywallExperiment } from "../selectors/experiments";
import { capitalize } from "../utilities/name";

// TODO: move to constants file
const planKeyMap = {
  [PLANS_PATH]: "plans",
  [FAMILY_RECOMMENDED_PATH]: "recommendation",
  [PREMIUM_RECOMMENDED_PATH]: "recommendation",
  [DOCUMENTS_PATH]: "documents",
  [CHECKOUT_PATH]: "checkout",
};

function* fetchPlans({ payload }) {
  const { formId } = payload;

  // Scenario to handle upgrading users that reload the page
  // they wont have hasPaid/hasUpgrade data yet
  const hasLoaded = yield select(selectHasLoaded);
  if (!hasLoaded) {
    yield call(fetchApiData, {
      apiCall: getDashboard,
      formId: "dashboard",
    });
  }

  const isUpgrading = yield select(selectIsUserUpgrading);
  if (isUpgrading) {
    yield call(fetchUpgradePlans, formId);
  } else {
    const { products } = yield call(fetchApiData, {
      apiCall: getPlans,
      formId,
    });
    yield put(addPlans(products));
    yield put(fetchSuccess(formId));
    if (formId === PRODUCTS_FORM_ID) {
      yield put(viewPlansAnalytics());
    }
  }
}

function* fetchUpgradePlans(formId) {
  const { products } = yield call(fetchApiData, {
    apiCall: getUpgradePlans,
    formId,
  });
  yield put(addPlans(products));
  yield put(fetchSuccess(formId));
  if (formId === PRODUCTS_FORM_ID) {
    yield put(viewPlansAnalytics());
  }
}

// TODO: rename update cart
function* updatePlans({ payload }) {
  const { symbol, quantity, formId } = payload;

  const allPlans = yield select(selectAvailableProducts);
  const activePlan = R.find(R.propEq("symbol", symbol), allPlans);

  // Prepare data to be sent to analytics before submitting API call to update plan
  const previousPlan = yield select(selectActivePlan);
  const currentRoute = yield select(selectCurrentRoute);
  const currentPath = planKeyMap[currentRoute];

  const previousPlanSymbol = R.propOr(null, "symbol")(previousPlan);
  const previousPlanQuantity = R.propOr(null, "quantity")(previousPlan);
  const previousPlanFromState = R.find(
    R.propEq("symbol", previousPlanSymbol),
    allPlans,
  );
  const previousPlanName = R.propOr("", "name")(previousPlanFromState);
  const shouldDisplayHardPaywall = yield select(
    selectShouldDisplayHardPaywallExperiment,
  );
  const { order } = yield call(submitFormRequest, {
    apiCall: submitPlans,
    formId,
    values: {
      currentPath,
      products: [
        {
          productId: activePlan.id,
          quantity: quantity || 1, // quantity > 1 indicates a bundle, only present on bundle field
        },
      ],
      shouldDisplayHardPaywall,
    },
  });

  // Reset the loadingPlan so that the UI is not expecting a plan in loading state
  yield put(setLoadingPlan({}));
  yield put(updateCart(order));

  // TODO: Better way of handling this logic for analytics price
  // eg: price of premium is 189 as quantity 1, then changes to 164.5 if quantity > 1
  // Also, price not included in payload
  let { price } = activePlan;
  if (activePlan.symbol === PREMIUM_PLAN) {
    price = quantity > 1 ? BUNDLE_PRICE : PREMIUM_PRICE;
  }

  // TODO: rip all this analytics code out.
  // No longer is it used on FE and is sent during BE requests?
  const provinceCode = yield select(selectProvinceCode);
  yield put(
    selectedPlanAnalytics({
      plan: capitalize(activePlan.symbol),
      province: provinceCode,
      price,
      quantity,
      previousPlan: previousPlanName || null,
      previousQuantity: previousPlanQuantity || null,
    }),
  );
  yield put(userPlanQuantityAnalytics(quantity));
}

function* upgradePlan({ payload }) {
  const { quantity, previousQuantity } = payload;
  const availableProducts = yield select(selectAvailableProducts);
  const previousPlan = yield select(selectActivePlan);
  const previousPlanSymbol = R.propOr(1, "symbol")(previousPlan);
  const previousPlanQuantity = R.propOr(1, "quantity")(previousPlan);
  const previousPlanFromState = R.find(
    R.propEq("symbol", previousPlanSymbol),
    availableProducts,
  );
  const previousPlanName = R.propOr("", "name")(previousPlanFromState);
  const upgradedPlan = yield select(selectUpgradedPlan);
  const currentRoute = yield select(selectCurrentRoute);
  const currentPath = planKeyMap[currentRoute];

  // This flag checks to see if the user is switching between quantity of bundle plans
  // and not upgrading from a premium plan (quantity 1) => bundle plan (any quantity)
  const quantityChanged =
    quantity !== previousQuantity && previousQuantity !== 1;

  // We'll assume that you're always upgrading to a premium plan if you're upgrading/
  // The only difference in plans is when you're upgrading essentials -> premium.
  // Any other upgrade, including couples -> bundle uses the premium plan but
  // changes the quantity.
  const upgradeProduct = R.find(R.propEq("symbol", upgradedPlan))(
    availableProducts,
  );
  const shouldDisplayHardPaywall = yield select(
    selectShouldDisplayHardPaywallExperiment,
  );
  const { order } = yield call(submitFormRequest, {
    apiCall: submitPlans,
    formId: UPSELL_FORM_ID,
    values: {
      currentPath,
      products: [
        {
          productId: upgradeProduct.id,
          quantity: quantity || 1,
        },
      ],
      skipToast: quantityChanged,
      shouldDisplayHardPaywall,
    },
  });

  yield put(updateCart(order));
  // TODO: Better way of handling this logic for analytics price
  // eg: price of premium is 189 as quantity 1, then changes to 164.5 if quantity > 1
  // Also, price not included in payload
  let { price } = upgradeProduct;
  if (upgradeProduct.symbol === PREMIUM_PLAN) {
    price = quantity > 1 ? BUNDLE_PRICE : PREMIUM_PRICE;
  }

  const provinceCode = yield select(selectProvinceCode);
  yield put(
    selectedPlanAnalytics({
      plan: upgradeProduct.name,
      province: provinceCode,
      price,
      quantity,
      previousPlan: previousPlanName,
      previousQuantity: previousPlanQuantity,
    }),
  );
  yield put(userPlanQuantityAnalytics(quantity));
}

export function* watchFetchPlans() {
  yield takeEvery(FETCH_PLANS_TYPE, fetchPlans);
}
export function* watchUpdatePlans() {
  yield takeEvery(UPDATE_PLANS_TYPE, updatePlans);
}

export function* watchUpgradePlan() {
  yield takeEvery(UPGRADE_PLAN_TYPE, upgradePlan);
}
