import React from "react";

const PremiumPreviewImage = () => {
  return (
    <img
      width="100%"
      src="/images/doc_preview_premium.png"
      alt="document preview"
    />
  );
};

export default PremiumPreviewImage;
