import R from "ramda";
import { combineReducers } from "redux";
import { LOCATION_CHANGE } from "connected-react-router";

import { UPDATE_STATUS_TYPE } from "../actions/status";

const nextSection = (state = "", { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return R.pathOr("", ["nextSection"])(payload);
    default:
      return state;
  }
};

const nextForm = (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return R.pathOr("", ["nextForms"])(payload);
    default:
      return state;
  }
};

const navigationHistory = (
  state = { current: "", previous: "" },
  { type, payload },
) => {
  switch (type) {
    case LOCATION_CHANGE:
      return {
        current: payload.location.pathname,
        previous: state.current,
      };
    default:
      return state;
  }
};

const nextOverviewPath = (state = "", { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return R.pathOr("", ["nextOverviewPath"])(payload);
    default:
      return state;
  }
};

export default combineReducers({
  nextSection,
  nextForm,
  navigationHistory,
  nextOverviewPath,
});
