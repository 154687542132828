import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Box from "@material-ui/core/Box";

import { media } from "../../config/Theme";
import { H5 } from "./Typography";
import CloseWhiteIcon from "./icons/CloseWhiteIcon";
import CheckmarkLightBlue from "./icons/CheckmarkLightBlue";

const StyledToast = styled.div`
  z-index: 999999;
  position: fixed;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 3px;
  background-color: #4a4a4a;
  color: white;
  box-shadow: 4px 4px 8px rgba(74, 74, 74, 0.2);

  transition: transform 240ms ease-out;
  transition: opacity 240ms linear;
  animation-name: ${(props) => (props.animateClose ? "out" : "in")};
  animation-duration: 440ms;

  @keyframes in {
    from {
      transform: scale(0.9);
      transform: translateY(10px);
      opacity: 0;
    }
    to {
      transform: scale(1);
      transform: translateY(0px);
      opacity: 1;
    }
  }

  @keyframes out {
    from {
      transform: scale(1);
      transform: translateY(0px);
      opacity: 1;
    }
    to {
      transform: scale(0.9);
      transform: translateY(10px);
      opacity: 0;
    }
  }

  ${media.mobile} {
    width: calc(100% - 40px);
    padding: 16px;
    bottom: 20px;
  }

  ${media.tablet} {
    top: 32px;
    min-width: 300px;
  }

  ${media.desktop} {
    top: 32px;
  }
`;

const CloseBox = styled(Box)`
  cursor: pointer;
  height: 24px;
  margin-right: 12px;

  ${media.mobile} {
    margin-right: 0px;
  }
`;

const CloseButton = ({ onClick }) => {
  return (
    <CloseBox onClick={onClick}>
      <CloseWhiteIcon />
    </CloseBox>
  );
};

const CheckmarkContainer = styled(Box)`
  margin-left: 12px;

  ${media.phone} {
    margin-left: 0;
  }
`;

const TextContainer = styled(Box)`
  margin-right: 32px;

  ${media.phone} {
    margin-right: 16px;
  }
`;

const Toast = ({ isVisible = true, translations, onClose }) => {
  if (!isVisible) return null;
  const { body } = translations;
  const [animateClose, setAnimateClose] = useState(false);

  const handleClose = () => {
    setAnimateClose(true);
    setTimeout(() => {
      onClose();
    }, 440);
  };
  useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, 4560);
  }, []);

  return (
    <Box display="flex" justifyContent="center">
      <StyledToast animateClose={animateClose}>
        <CheckmarkContainer mt={0.25}>
          <CheckmarkLightBlue />
        </CheckmarkContainer>
        <TextContainer ml={0.5} width={1}>
          <H5 align="left" data-hj-suppress>
            {body}
          </H5>
        </TextContainer>
        <CloseButton onClick={handleClose} />
      </StyledToast>
    </Box>
  );
};

export default Toast;
