import R from "ramda";
import {
  logEvent,
  setUserId,
  logout,
  setUserProperties,
} from "@redux-beacon/amplitude";

import {
  ANALYTICS_LOGIN_TYPE,
  ANALYTICS_LOGOUT_TYPE,
  ANALYTICS_SIGNUP_TYPE,
  ANALYTICS_VIEW_PAGE_TYPE,
  ANALYTICS_CLICKED_EXTERNAL_LINK_TYPE,
  ANALYTICS_CLICKED_NAV_LINK_TYPE,
  ANALYTICS_OPENED_CHAT_TYPE,
  ANALYTICS_DOWNLOAD_NOTARIZATION_GUIDE_TYPE,
  ANALYTICS_USER_CHANGED_UNSUPPORTED_LANGUAGE_TYPE,
  ANALYTICS_CLICKED_SKIP_PAY_LATER_TYPE,
  ANALYTICS_CLICKED_EDIT_TYPE,
  ANALYTICS_RESET_USER_TYPE,
  ANALYTICS_CLICKED_CHECKOUT_NOW_TYPE,
  ANALYTICS_INTERESTED_IN_BNPL,
  ANALYTICS_OPENED_MODAL,
  ANALYTICS_CLOSED_MODAL,
  ANALYTICS_OPENED_CART_WIDGET,
  ANALYTICS_401_ERROR,
  ANALYTICS_GOOGLE_SSO_ATTEMPT,
  ANALYTICS_COPIED_REFERRAL_LINK,
  ANALYTICS_MANUALLY_COPIED_REFERRAL_LINK,
} from "../../actions/analytics";
import { getMultiplePageTranslationsByProvince } from "../../i18n/translations";
import { buildOptionsForAmplitude } from "./transform-utils";
import { selectCurrentRoute } from "../../selectors/location";
import { selectProvinceById } from "../../selectors/provinces";
import { DISPLAY_EMAIL_PREVIEW_MODAL_TYPE } from "../../actions/email-preview-modal";
import { UPDATE_EXPERIMENTS_TYPE } from "../../actions/experiments";

const urlTranslations = getMultiplePageTranslationsByProvince("en", "ON", [
  "urlLabels",
]);

export const AMPLITUDE_CONFIG = {
  [ANALYTICS_VIEW_PAGE_TYPE]: logEvent(({ payload }, prevState, nextState) => {
    return {
      type: "Viewed Page",
      userId: payload.userId || "",
      properties: {
        title: urlTranslations[payload.location.pathname],
        url: payload.location.pathname,
        isLoggedIn: payload.isLoggedIn,
        device: payload.device,
      },
      ...buildOptionsForAmplitude(nextState),
    };
  }),
  [ANALYTICS_LOGIN_TYPE]: setUserId(({ payload }) => payload.userId),
  [ANALYTICS_SIGNUP_TYPE]: setUserId(({ payload }) => payload.userId),
  [ANALYTICS_LOGOUT_TYPE]: logout(),
  [ANALYTICS_RESET_USER_TYPE]: logout(),
  [ANALYTICS_CLICKED_EXTERNAL_LINK_TYPE]: logEvent(
    (action, prevState, nextState) => {
      const url = R.pathOr("", ["payload", "url"])(action);
      const pathname = selectCurrentRoute(nextState);
      return {
        type: "Clicked External Link",
        properties: {
          url,
          title: urlTranslations[pathname] || pathname,
        },
        ...buildOptionsForAmplitude(nextState),
      };
    },
  ),
  [ANALYTICS_CLICKED_NAV_LINK_TYPE]: logEvent(
    (action, prevState, nextState) => {
      const menu = R.pathOr("", ["payload", "menu"])(action);
      const item = R.pathOr("", ["payload", "item"])(action);
      return {
        type: "Clicked Nav Link",
        properties: {
          menu,
          item,
        },
        ...buildOptionsForAmplitude(nextState),
      };
    },
  ),
  [ANALYTICS_OPENED_CHAT_TYPE]: logEvent((action, prevState, nextState) => {
    const pathname = selectCurrentRoute(nextState);
    return {
      type: "Opened Chat",
      properties: {
        title: urlTranslations[pathname] || pathname,
      },
      ...buildOptionsForAmplitude(nextState),
    };
  }),
  [ANALYTICS_DOWNLOAD_NOTARIZATION_GUIDE_TYPE]: logEvent(
    (action, prevState, nextState) => {
      const pathname = selectCurrentRoute(nextState);
      return {
        type: "Downloaded Content",
        properties: {
          content: "Notarization guide",
          url: pathname,
          title: urlTranslations[pathname] || pathname,
        },
        ...buildOptionsForAmplitude(nextState),
      };
    },
  ),
  [ANALYTICS_USER_CHANGED_UNSUPPORTED_LANGUAGE_TYPE]: logEvent(
    (action, prevState, nextState) => {
      // Get the province ID of the province the user is trying to switch to
      const provinceId = R.pathOr("", [
        "form",
        "residence",
        "values",
        "provinceId",
      ])(nextState);
      const province = selectProvinceById(provinceId)(nextState);
      const pathname = selectCurrentRoute(nextState);
      const language = R.pathOr("", ["payload", "lang"])(action);

      return {
        type: "Selected Unavailable Language",
        properties: {
          language,
          province: province.code,
          url: pathname,
        },
        ...buildOptionsForAmplitude(nextState),
      };
    },
  ),
  [ANALYTICS_CLICKED_SKIP_PAY_LATER_TYPE]: logEvent(
    (action, prevState, nextState) => ({
      type: "Clicked Skip and Pay Later link",
      ...buildOptionsForAmplitude(nextState),
    }),
  ),
  [ANALYTICS_CLICKED_EDIT_TYPE]: logEvent((action, prevState, nextState) => {
    const sectionKey = R.pathOr("", ["payload", "section"])(action);
    const sectionMap = {
      yourFamily: "Your family",
      yourEstate: "Your estate",
      yourArrangements: "Your arrangements",
      spouseEstate: "Spouse's Estate",
      spouseArrangements: "Spouse's Arrangements",
    };
    const section = R.propOr("", sectionKey)(sectionMap);
    const page = R.pathOr("", ["payload", "page"])(action);

    return {
      type: "Section Review Edit",
      properties: {
        section,
        page: urlTranslations[page],
      },
      ...buildOptionsForAmplitude(nextState),
    };
  }),
  [DISPLAY_EMAIL_PREVIEW_MODAL_TYPE]: logEvent(
    (action, prevState, nextState) => {
      const { key, appointeeLevel } = action.payload;

      return {
        type: "Previewed Appointee Notification Email",
        properties: {
          appointeeRole: key,
          appointeeLevel: appointeeLevel + 1, // level is zero indexed, we want this to be readable on amplitude
        },
        ...buildOptionsForAmplitude(nextState),
      };
    },
  ),
  [ANALYTICS_CLICKED_CHECKOUT_NOW_TYPE]: logEvent(
    (action, prevState, nextState) => {
      const pathname = selectCurrentRoute(nextState);
      return {
        type: "Clicked Checkout Now",
        properties: {
          title: urlTranslations[pathname],
          url: pathname,
        },
        ...buildOptionsForAmplitude(nextState),
      };
    },
  ),
  [UPDATE_EXPERIMENTS_TYPE]: setUserProperties((action) => {
    const experimentId = R.pathOr(0, ["payload", "experimentId"])(action);
    const variationId = R.pathOr(0, ["payload", "variationId"])(action);
    if (experimentId === 0) {
      return {};
    }
    const label = `Experiment - ${experimentId}`;
    return {
      [label]: variationId,
    };
  }),
  [ANALYTICS_INTERESTED_IN_BNPL]: logEvent((action, prevState, nextState) => {
    return {
      type: "Interested in BNPL",
      ...buildOptionsForAmplitude(nextState),
    };
  }),
  [ANALYTICS_OPENED_MODAL]: logEvent((action, prevState, nextState) => {
    const url = R.pathOr("", ["payload", "url"])(action);
    const key = R.pathOr("", ["payload", "key"])(action);
    const pathname = selectCurrentRoute(nextState);
    return {
      type: "Opened Modal",
      properties: {
        url,
        key,
        title: urlTranslations[pathname] || pathname,
      },
      ...buildOptionsForAmplitude(nextState),
    };
  }),
  [ANALYTICS_CLOSED_MODAL]: logEvent((action, prevState, nextState) => {
    const url = R.pathOr("", ["payload", "url"])(action);
    const key = R.pathOr("", ["payload", "key"])(action);
    const pathname = selectCurrentRoute(nextState);
    return {
      type: "Closed Modal",
      properties: {
        url,
        key,
        title: urlTranslations[pathname] || pathname,
      },
      ...buildOptionsForAmplitude(nextState),
    };
  }),
  [ANALYTICS_OPENED_CART_WIDGET]: logEvent((action, prevState, nextState) => {
    const pathname = selectCurrentRoute(nextState);
    return {
      type: "Opened cart widget",
      properties: {
        title: urlTranslations[pathname] || pathname,
      },
      ...buildOptionsForAmplitude(nextState),
    };
  }),
  [ANALYTICS_401_ERROR]: logEvent((action, prevState, nextState) => {
    const type = R.pathOr("", ["payload", "type"])(action);
    return {
      type: "401 Error",
      properties: {
        type,
      },
      ...buildOptionsForAmplitude(nextState),
    };
  }),
  [ANALYTICS_GOOGLE_SSO_ATTEMPT]: logEvent((action, prevState, nextState) => {
    const type = R.pathOr("", ["payload", "type"])(action);
    const status = R.pathOr("", ["payload", "status"])(action);
    return {
      type: "Google SSO Attempt",
      properties: {
        type,
        status,
      },
      ...buildOptionsForAmplitude(nextState),
    };
  }),
  [ANALYTICS_COPIED_REFERRAL_LINK]: logEvent((action, prevState, nextState) => {
    const url = R.pathOr("", ["payload", "url"])(action);
    const pathname = selectCurrentRoute(nextState);
    return {
      type: "Copied referral link",
      properties: {
        url,
        utm: urlTranslations[pathname] || pathname,
      },
      ...buildOptionsForAmplitude(nextState),
    };
  }),
  [ANALYTICS_MANUALLY_COPIED_REFERRAL_LINK]: logEvent(
    (action, prevState, nextState) => {
      const url = R.pathOr("", ["payload", "url"])(action);
      const pathname = selectCurrentRoute(nextState);
      return {
        type: "Manually copied referral link",
        properties: {
          url,
          utm: urlTranslations[pathname] || pathname,
        },
        ...buildOptionsForAmplitude(nextState),
      };
    },
  ),
};
