export const skipSection = (apiCall, formId, isSpouse) => ({
  type: NAVIGATION_SKIP_SECTION_TYPE,
  payload: { apiCall },
  meta: {
    formId,
    isSpouseValues: isSpouse,
  },
});

export const NAVIGATION_SKIP_SECTION_TYPE = "NAVIGATION_SKIP_SECTION";
