import React, { useState } from "react";
import { Field } from "redux-form";

import styled from "styled-components";
import TextInput from "./TextInput";
import { PASSWORD_FIELD_ID } from "../../../constants/forms";
import {
  PASSWORD_STRENGTH_GOOD,
  PASSWORD_STRENGTH_INVALID,
  PASSWORD_STRENGTH_OKAY,
  PASSWORD_STRENGTH_STRONG,
  validatePasswordStrength,
} from "./validations";
import { colorPalette } from "../../../config/Theme";

const StyledProgress = styled.progress`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;

  &::-webkit-progress-bar {
    background-color: ${colorPalette.white};
    color: ${colorPalette.willfulGreen};
  }

  &::-moz-progress-bar {
    background-color: ${colorPalette.willfulGreen};
  }

  & {
    color: ${colorPalette.willfulGreen};
    background-color: ${colorPalette.white};
    border: none;
  }
`;

const StyledPasswordStrengthLabel = styled.div`
  font-size: 16px;
  color: ${colorPalette.willfulGreen};
`;

const PasswordField = ({
  name = PASSWORD_FIELD_ID,
  validation,
  translations,
  hidden,
  isSignUp = false,
  showPasswordStrength = false,
  ...restOfProps
}) => {
  const [passwordStrength, setPasswordStrength] = useState(null);
  const passwordStrengthLevels = [
    PASSWORD_STRENGTH_OKAY,
    PASSWORD_STRENGTH_GOOD,
    PASSWORD_STRENGTH_STRONG,
  ];
  // Not rendering this component due to its use on YourAccount page and
  // its interaction with React hooks (our validations)
  const placeholder = isSignUp
    ? translations.label.passwordPlaceholder
    : translations.label.enterPasswordPlaceholder;
  if (hidden) return null;

  return (
    <>
      <Field
        component={TextInput}
        name={name}
        label={translations.label.password}
        placeholder={placeholder}
        type="password"
        validate={validation}
        {...(showPasswordStrength && {
          onChange: (e) =>
            setPasswordStrength(
              validatePasswordStrength(e.currentTarget.value),
            ),
          noMargin: true,
        })}
        {...restOfProps}
      />
      {showPasswordStrength &&
        passwordStrength &&
        passwordStrength !== PASSWORD_STRENGTH_INVALID && (
          <>
            <StyledProgress
              value={passwordStrengthLevels.indexOf(passwordStrength) + 1}
              max={passwordStrengthLevels.length}
            />
            <StyledPasswordStrengthLabel>
              {translations.passwordStrength[passwordStrength]}
            </StyledPasswordStrengthLabel>
          </>
        )}
    </>
  );
};

export default PasswordField;
