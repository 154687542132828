import React from "react";
import { Field } from "redux-form";
import Box from "@material-ui/core/Box";
import dayjs from "dayjs";

import { days, years } from "./constants";
import SelectInput from "./DropdownSelect/SelectInput";
import { DOB_FIELD_ID } from "../../../constants/forms";
import { ErrorMessage } from "../Error";
import { useValidateDateOfBirthMemo } from "./validations";
import LoadingSpinner from "../LoadingSpinner";

const DAYS = days.map((day) => ({ label: day, value: `${day}` }));
const buildMonthOptions = (months) =>
  months.map((month, index) => ({
    label: month,
    value: `${index}`,
  }));
const YEARS = years.map((year) => ({ label: year, value: `${year}` }));

const updateDate = (date, key, onChange) => (value) => {
  const newDate = dayjs(date).set(key, value);
  onChange(newDate.format("YYYY-MM-DD"));
};

const DateOfBirthInput = ({
  input: { value, onChange },
  meta: { error },
  hasSeedValue,
  translations,
  marginBottom,
}) => {
  if (hasSeedValue && value === "") {
    return (
      <Box>
        <LoadingSpinner />
      </Box>
    );
  }
  const date = dayjs(value || new Date());
  const shouldDisplayError = error !== null;
  return (
    <Box mb={marginBottom}>
      <Box display="flex">
        <Box width={225} className="qa-day">
          <SelectInput
            value={date.format("D")}
            label={translations.label.dobDay}
            onChange={updateDate(date, "date", onChange)}
            optionList={DAYS}
            marginBottom={0.2}
          />
        </Box>
        <Box width={300} ml={1} className="qa-month">
          <SelectInput
            value={`${date.format("M") - 1}`}
            label={translations.label.dobMonth}
            onChange={updateDate(date, "month", onChange)}
            optionList={buildMonthOptions(translations.constants.months)}
            marginBottom={0.2}
          />
        </Box>
        <Box width={275} ml={1} className="qa-year">
          <SelectInput
            value={date.format("YYYY")}
            label={translations.label.dobYear}
            onChange={updateDate(date, "year", onChange)}
            optionList={YEARS}
            marginBottom={0.2}
          />
        </Box>
      </Box>
      <ErrorMessage shouldDisplayError={shouldDisplayError} error={error} />
    </Box>
  );
};

const DateOfBirthField = ({
  name,
  hasSeedValue = false,
  translations,
  marginBottom = 2,
}) => {
  return (
    <Field
      component={DateOfBirthInput}
      validate={useValidateDateOfBirthMemo(translations)}
      name={name || DOB_FIELD_ID}
      translations={translations}
      hasSeedValue={hasSeedValue}
      marginBottom={marginBottom}
    />
  );
};

export default DateOfBirthField;
