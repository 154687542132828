import React from "react";

import SvgIcon from "./SvgIcon";

const Marriage = () => (
  <svg viewBox="-30 0 319 319">
    <g>
      <ellipse cx="125" cy="172.5" rx="116.5" ry="77.5" fill="#f6f6f6" />
      <g>
        <g>
          <g>
            <path
              d="M189.79,156.46l1.07,16.83c-.44-6.87-5.37-13.45-14.69-18.11-18.76-9.41-48.33-7.6-65.91,4-8.86,5.86-13,13.1-12.57,20l-1.07-16.84c-.45-6.94,3.71-14.19,12.56-20,17.59-11.63,47.16-13.45,65.92-4C184.42,143,189.36,149.6,189.79,156.46Z"
              fill="#0064dc"
              fillRule="evenodd"
            />
            <path
              d="M202.34,172.55c.56,8.66-4.62,17.7-15.66,25-21.92,14.5-58.78,16.75-82.16,5-11.61-5.83-17.75-14-18.31-22.59l-1.07-16.83c.55,8.56,6.69,16.75,18.31,22.58,23.38,11.73,60.22,9.47,82.16-5,11-7.3,16.2-16.32,15.66-25Z"
              fill="#71adee"
              fillRule="evenodd"
            />
            <path
              d="M183,133.16c-23.37-11.73-60.23-9.47-82.16,5S80.08,174,103.45,185.75s60.22,9.47,82.16-5S206.35,144.88,183,133.16Zm-5.74,43.35c-17.58,11.63-47.15,13.45-65.92,4S91.6,154,109.18,142.37s47.16-13.45,65.92-4,19.73,26.5,2.13,38.14Z"
              fill="#aad2f6"
            />
          </g>
          <g>
            <polygon
              points="205.38 190.44 199.13 202.96 186.69 211.46 178.68 205 179.84 193.35 178.68 192.77 184.92 180.27 186.07 180.83 194.7 172.92 204.69 175.41 205.38 190.44"
              fill="#e2f6ff"
              fillRule="evenodd"
            />
            <path
              d="M204.69,175.41a6.22,6.22,0,0,1-1.82.27c-2.29,0-4.53,0-6.72.15s-3.74,1.21-3.69,2.47.13,2.69.29,4.12a2.17,2.17,0,0,1-1.79,2.05c-1.82.48-3.58,1-5.26,1.56a3,3,0,0,0-2,3.76s0,.07,0,.11c.56,1.67,1.2,3.4,1.91,5.14a2.21,2.21,0,0,1-.55,2.67c-1.25.73-2.4,1.48-3.47,2.25s-.93,2.71.24,4.44,2.51,3.62,3.91,5.44a6.27,6.27,0,0,1,.9,1.62l-7.05-3.52a10.12,10.12,0,0,1-5.6-7.76,28.55,28.55,0,0,1,13.72-27.9,10.32,10.32,0,0,1,9.88-.38Z"
              fill="#ffdc1e"
              fillRule="evenodd"
            />
          </g>
        </g>
        <g>
          <path
            d="M120.44,191.45c-1.2-.24-2.38-.53-3.55-.82a95.44,95.44,0,0,1-20.17,7,42.92,42.92,0,0,0,5.18,3.47,102.73,102.73,0,0,0,22.25-9C122.91,191.91,121.67,191.69,120.44,191.45Z"
            fill="#0064dc"
            fillRule="evenodd"
          />
          <path
            d="M126.46,185.41a99.54,99.54,0,0,1-9.57,5.22c1.17.29,2.35.58,3.55.82s2.46.46,3.72.65a102.89,102.89,0,0,0,9.34-5.78C131.11,186.13,128.77,185.82,126.46,185.41Z"
            fill="#71adee"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            d="M137.46,124.45l7.39,15.16c-3-6.18-10.07-10.4-20.46-11.2-20.93-1.59-47.61,11.28-59.47,28.7-6,8.77-7.06,17-4,23.32l-7.38-15.16c-3.06-6.26-2-14.53,4-23.32C69.4,124.53,96.08,111.66,117,113.26,127.39,114,134.46,118.26,137.46,124.45Z"
            fill="#0064dc"
            fillRule="evenodd"
          />
          <path
            d="M155.19,134.58c3.8,7.81,2.43,18.12-5,29-14.78,21.72-48,37.77-74.12,35.77-13-1-21.73-6.24-25.49-14L43.2,170.2c3.75,7.71,12.54,13,25.49,14,26.09,2,59.33-14,74.12-35.77,7.43-10.92,8.81-21.24,5-29Z"
            fill="#71adee"
            fillRule="evenodd"
          />
          <path
            d="M122.29,105.46c-26.08-2-59.33,14.06-74.12,35.78s-5.58,41,20.49,43,59.34-14.05,74.12-35.77S148.38,107.46,122.29,105.46Zm11.16,42.29c-11.86,17.43-38.54,30.3-59.46,28.7s-28.31-17.09-16.45-34.51,38.54-30.3,59.46-28.7S145.31,130.33,133.45,147.75Z"
            fill="#aad2f6"
          />
        </g>
      </g>
    </g>
  </svg>
);
const Home = () => (
  <svg viewBox="0 0 140 140" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60 99.9264C93.1371 99.9264 120 82.8058 120 61.6864C120 40.567 93.1371 23.4463 60 23.4463C26.8629 23.4463 0 40.567 0 61.6864C0 82.8058 26.8629 99.9264 60 99.9264Z"
      fill="#F6F6F6"
    />
    <path
      d="M66.5624 38.4478L70.8857 71.6958L79.96 72.0753L105.9 57.0402L70.6007 36.6455L66.5624 38.4478Z"
      fill="#2F455F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5555 32.1716V49.7426L38.2329 62.4825V45.3736L29.0379 30.5273L16.5555 32.1716Z"
      fill="#E2F6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.4964 11.1084L73.5903 25.8188L46.686 41.9832L38.2328 46.9365L27.5337 31.6486L62.4964 11.1084Z"
      fill="#0064DC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.6854 57.5285L38.2323 62.4818V46.9357L46.6854 41.9824V57.5285Z"
      fill="#71ADEE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.6859 57.5285L70.7862 71.6885V56.1356L46.6859 41.9824V57.5285Z"
      fill="#E2F6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.4786 37.1191L70.7858 54.8667V71.6902L94.8861 57.5302V38.8722L82.4786 37.1191Z"
      fill="#71ADEE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.7862 56.1364L83.6769 37.7977L59.5766 23.6377L46.6859 41.9832L70.7862 56.1364Z"
      fill="#C6E9F8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.391 39.0214L73.0862 40.5366L75.6589 39.0214L73.1202 37.5674L70.391 39.0214Z"
      fill="#0064DC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.6767 37.7977L94.8863 38.8712L73.5901 25.8188L59.5764 23.6377L83.6767 37.7977Z"
      fill="#0064DC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.0861 40.5357V44.7348L75.6588 41.1065V39.0205L73.0861 40.5357Z"
      fill="#71ADEE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.6149 51.5568L51.2794 49.0088V55.0288L55.6149 57.5768V51.5568Z"
      fill="#71ADEE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.6539 56.9044L60.3185 54.3564V60.3697L64.6539 62.9177V56.9044Z"
      fill="#71ADEE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.8855 43.4445L20.55 40.8965V46.9165L24.8855 49.4645V43.4445Z"
      fill="#71ADEE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.9246 48.7843L29.5891 46.2363V52.2564L33.9246 54.8044V48.7843Z"
      fill="#71ADEE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.9386 56.4699L81.2741 53.9219V59.9419L76.9386 62.4899V56.4699Z"
      fill="#0064DC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.2993 60.2266L85.7052 62.9241V51.3732L90.2993 48.6758V60.2266Z"
      fill="#FFDC1E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.5831 71.7431C44.7664 71.7431 44.1062 71.0841 44.1062 70.2687V59.3701H47.0668V70.2687C47.06 71.0841 46.3998 71.7431 45.5831 71.7431Z"
      fill="#0064DC"
    />
    <path
      d="M45.5834 64.4327C48.2635 64.4327 50.4362 61.631 50.4362 58.1748C50.4362 54.7187 48.2635 51.917 45.5834 51.917C42.9033 51.917 40.7307 54.7187 40.7307 58.1748C40.7307 61.631 42.9033 64.4327 45.5834 64.4327Z"
      fill="#AAD2F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5335 31.6486L16.1401 32.2805L51.1029 11.7335L62.4963 11.1084L27.5335 31.6486Z"
      fill="#C6E9F8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.0862 40.536V44.735L70.391 43.1859V39.0615L73.0862 40.536Z"
      fill="#E2F6FF"
    />
  </svg>
);
const Child = () => (
  <svg viewBox="-30 0 319 319">
    <g>
      <ellipse cx="125" cy="172.5" rx="116.5" ry="77.5" fill="#f6f6f6" />
      <g>
        <path
          d="M84.33,30.66a1.15,1.15,0,0,1,1.58-.44l.07,0a2,2,0,0,1,.48.4l8.36,9.11a62.41,62.41,0,0,1,13.54,23.78l7.22,24.1c2.32,7.75,7.71,15,13.73,18.48l5,2.87a2.48,2.48,0,0,1,1.14,2,1.13,1.13,0,0,1-1,1.22h0a1.48,1.48,0,0,1-.68-.19l-5-2.88c-7-4-13.15-12.36-15.82-21.26L105.64,63.8A57.35,57.35,0,0,0,93.22,42l-8.36-9.11A2.07,2.07,0,0,1,84.33,30.66Z"
          fill="#71adee"
          fillRule="evenodd"
        />
        <path
          d="M141.9,159.6c0,13.05-9.25,18.31-20.6,11.76s-20.51-22.45-20.48-35.5,9.26-18.31,20.6-11.76S141.93,146.55,141.9,159.6Z"
          fill="#6985c3"
          fillRule="evenodd"
        />
        <path
          d="M98.3,133.69c0,14.94,10.45,33.14,23.44,40.64s24.86.54,24.9-14.4-10.44-33.14-23.42-40.64S98.34,118.76,98.3,133.69Z"
          fill="#e2f6ff"
          fillRule="evenodd"
        />
        <path
          d="M140.88,159c0,12.42-8.8,17.42-19.58,11.18s-19.5-21.36-19.46-33.76,8.8-17.42,19.58-11.18S140.92,146.6,140.88,159Z"
          fill="#71adee"
          fillRule="evenodd"
        />
        <path
          d="M118.42,142.42a1.68,1.68,0,0,1,1.69.17,5.27,5.27,0,0,1,2.36,4.1,1.67,1.67,0,0,1-.69,1.53l-.74.43a1.65,1.65,0,0,0,.7-1.52,5.22,5.22,0,0,0-2.37-4.1,1.69,1.69,0,0,0-1.68-.17Z"
          fill="#d2d2d2"
          fillRule="evenodd"
        />
        <path
          d="M119.37,143c-1.31-.76-2.37-.15-2.37,1.36a5.18,5.18,0,0,0,2.36,4.1c1.3.77,2.38.16,2.38-1.36A5.15,5.15,0,0,0,119.37,143Z"
          fill="#adadad"
          fillRule="evenodd"
        />
        <path
          d="M149.17,168.52c0,11.91,8.34,26.41,18.68,32.41s19.83.42,19.86-11.48S179.37,163,169,157,149.2,156.61,149.17,168.52Z"
          fill="#e2f6ff"
          fillRule="evenodd"
        />
        <path
          d="M183.14,188.71c0,9.89-7,13.89-15.63,8.92S152,180.6,152,170.7s7-13.89,15.62-8.92S183.15,178.82,183.14,188.71Z"
          fill="#71adee"
          fillRule="evenodd"
        />
        <path
          d="M166,175.66c-1.19-.7-2.15-.14-2.15,1.23A4.71,4.71,0,0,0,166,180.6c1.19.7,2.15.15,2.15-1.23A4.71,4.71,0,0,0,166,175.66Z"
          fill="#e2f6ff"
          fillRule="evenodd"
        />
        <path
          d="M119,144.54a.78.78,0,0,1,.78.07,2.4,2.4,0,0,1,1.09,1.88.77.77,0,0,1-.31.7L82.89,168.71a.73.73,0,0,0,.33-.69,2.41,2.41,0,0,0-1.09-1.89.81.81,0,0,0-.78-.07Z"
          fill="#0064dc"
          fillRule="evenodd"
        />
        <path
          d="M165.29,177.13a.6.6,0,0,1,.63.06,1.9,1.9,0,0,1,.86,1.5.59.59,0,0,1-.26.55l-37.26,21.65a.62.62,0,0,0,.26-.56,1.9,1.9,0,0,0-.86-1.5.64.64,0,0,0-.63-.06Z"
          fill="#0064dc"
          fillRule="evenodd"
        />
        <path
          d="M128.66,198.83c-.49-.28-.88-.06-.88.5a1.9,1.9,0,0,0,.86,1.5c.48.28.88.06.88-.5A1.87,1.87,0,0,0,128.66,198.83Z"
          fill="#020202"
          fillRule="evenodd"
        />
        <path
          d="M76,141.8c0,10.6,7.44,23.56,16.64,28.88l36.57,21a15.43,15.43,0,0,0,7.64,2.32c5.87,0,9.69-4.8,9.7-12.23,0-10.6-7.44-23.55-16.64-28.88l-36.56-21a15.43,15.43,0,0,0-7.64-2.32C79.86,129.57,76.06,134.37,76,141.8Zm53.81,47.52-36.56-21c-8.25-4.78-15-16.39-14.92-25.88,0-6.31,3-10.24,7.89-10.24a13.16,13.16,0,0,1,6.53,2l36.57,21c8.25,4.78,14.94,16.39,14.91,25.88,0,6.31-3,10.23-7.88,10.23h0a13.28,13.28,0,0,1-6.51-2Z"
          fill="#0064dc"
        />
        <path
          d="M97.67,128.31c0,10.6,7.44,23.55,16.64,28.88l36.56,21a15.43,15.43,0,0,0,7.64,2.32c5.88,0,9.7-4.8,9.71-12.23,0-10.6-7.44-23.55-16.64-28.87l-36.57-21a15.34,15.34,0,0,0-7.64-2.33C101.49,116.08,97.68,120.88,97.67,128.31Zm53.81,47.52-36.56-21c-8.25-4.77-15-16.38-14.92-25.88,0-6.3,3-10.23,7.88-10.23a13.2,13.2,0,0,1,6.54,2l36.57,21c8.25,4.78,14.94,16.38,14.91,25.88,0,6.31-3,10.23-7.88,10.23h0A13.2,13.2,0,0,1,151.48,175.83Z"
          fill="#0064dc"
        />
        <path
          d="M85.34,31.61a.81.81,0,0,1,.86.08,3,3,0,0,1,.4.31,2.62,2.62,0,0,1,.58.85,2.32,2.32,0,0,1,.23.94.89.89,0,0,1-.36.78L46.2,58.27a.86.86,0,0,0,.35-.78,2.31,2.31,0,0,0-.22-.94,3.55,3.55,0,0,0-.58-.85,1.72,1.72,0,0,0-.4-.31.86.86,0,0,0-.86-.09Z"
          fill="#0064dc"
          fillRule="evenodd"
        />
        <path
          d="M45.35,55.4c-.67-.38-1.22-.07-1.22.7a2.67,2.67,0,0,0,1.2,2.08c.67.38,1.21.07,1.22-.69A2.68,2.68,0,0,0,45.35,55.4Z"
          fillRule="evenodd"
          fill="url(#a)"
        />
        <path
          d="M43,55a1.15,1.15,0,0,1,1.57-.43l.07,0a2.28,2.28,0,0,1,.48.39l8.36,9.12A62.34,62.34,0,0,1,67,87.86L74.2,112c2.32,7.76,7.72,15,13.73,18.48l5,2.88a2.43,2.43,0,0,1,1.13,2,1.13,1.13,0,0,1-1,1.22h0a1.35,1.35,0,0,1-.68-.2l-5-2.88c-7-4-13.14-12.35-15.82-21.25l-7.22-24.1a56.79,56.79,0,0,0-12.41-21.8l-8.36-9.11A2.11,2.11,0,0,1,43,55Z"
          fill="#71adee"
          fillRule="evenodd"
        />
        <polygon
          points="150.21 145.05 191.5 121.01 99.7 68.06 58.41 92.1 150.21 145.05"
          fill="#e0dbcc"
          fillRule="evenodd"
        />
        <polygon
          points="103.55 118.06 144.84 94.04 143.84 87.99 102.55 112.02 103.55 118.06"
          fill="#a38775"
          fillRule="evenodd"
        />
        <path
          d="M61.61,84.85C58,87,58.4,92.1,58.4,92.1l45.14,26-1-6.06S65.19,82.71,61.61,84.85Z"
          fill="#71adee"
          fillRule="evenodd"
        />
        <path
          d="M102.55,112l41.29-24L103.35,64.72a7.26,7.26,0,0,0-7.27,0l-34,19.8a2.45,2.45,0,0,0,0,4.22Z"
          fill="#71adee"
          fillRule="evenodd"
        />
        <path
          d="M140.5,88l-38,22.11L62.9,87.31a.77.77,0,0,1-.29-1A.81.81,0,0,1,62.9,86l34-19.8a5.7,5.7,0,0,1,5.6,0Z"
          fill="#0064dc"
          fillRule="evenodd"
        />
        <path
          d="M133.62,84c-.62,1.63-2.16,5.63-4,10.29L102.53,110.1,73,93.1c3.28-1.12,1.33-9.75,8.19-11,4.64-.88,5.09,1.57,8.62-.65s4.82-8,11.7-7.8l29,8.62Z"
          fill="#ffdc1e"
          fillRule="evenodd"
        />
        <path
          d="M58.41,92.1l1.43,7.82c2.5,13.68,12,27.85,22.92,34.16l43.48,25c10.86,6.28,20.32,3,22.65-7.9l1.32-6.13Z"
          fill="#e2f6ff"
          fillRule="evenodd"
        />
        <path
          d="M150.21,145.05l41.29-24c-1.36,6.28-2,12.4-7.51,15.63l-41.3,24C148.23,157.45,148.85,151.32,150.21,145.05Z"
          fill="#71adee"
          fillRule="evenodd"
        />
        <path
          d="M99.86,60.26l30.38-17.68c3.28-1.92,7.7-1.92,10.4.76,9.5,9.36,14.83,15.3,30.44,21.73a4.27,4.27,0,0,1,2.46,2.86c2.21,8.35,6.92,19.32,14.68,26.63a4.39,4.39,0,0,1,1.43,3.49c-.63,7-1.84,14,1.85,23l-41.29,24-46.66-27L95.44,69.68A9.19,9.19,0,0,1,99.86,60.26Z"
          fill="#0064dc"
          fillRule="evenodd"
        />
        <path
          d="M95.44,69.68a9.09,9.09,0,0,1,.37-4.45,7.24,7.24,0,0,1,3.54,1.89c9.49,9.36,14.83,15.3,30.44,21.73a4.27,4.27,0,0,1,2.46,2.86c2.21,8.35,6.92,19.32,14.67,26.63a4.28,4.28,0,0,1,1.43,3.49c-.62,7-1.84,14,1.86,23L150,145,103.55,118.1Z"
          fill="#71adee"
          fillRule="evenodd"
        />
        <path
          d="M111.62,190.4c0,11.92,8.33,26.42,18.68,32.41s19.82.43,19.85-11.48-8.33-26.42-18.68-32.4S111.65,178.48,111.62,190.4Z"
          fill="#e2f6ff"
          fillRule="evenodd"
        />
        <path
          d="M146.39,211.06c0,10.4-7.39,14.61-16.43,9.38s-16.36-17.91-16.33-28.31,7.38-14.61,16.43-9.37S146.42,200.66,146.39,211.06Z"
          fill="#71adee"
          fillRule="evenodd"
        />
        <path
          d="M128.43,197.84c-1.05-.61-1.9-.13-1.9,1.08a4.16,4.16,0,0,0,1.89,3.27c1,.61,1.89.13,1.89-1.08A4.22,4.22,0,0,0,128.43,197.84Z"
          fill="#e2f6ff"
          fillRule="evenodd"
        />
        <path
          d="M60.76,155.58c0,14.93,10.44,33.13,23.42,40.63s24.86.54,24.9-14.4-10.44-33.14-23.41-40.63S60.8,140.64,60.76,155.58Z"
          fill="#e2f6ff"
          fillRule="evenodd"
        />
        <path
          d="M82.11,166.13c-.59-.35-1.09-.07-1.09.63a2.4,2.4,0,0,0,1.09,1.88c.61.36,1.09.07,1.09-.62A2.41,2.41,0,0,0,82.11,166.13Z"
          fill="#020202"
          fillRule="evenodd"
        />
        <path
          d="M104.34,181.48c0,13.06-9.25,18.31-20.6,11.77s-20.5-22.45-20.48-35.51S72.53,139.43,83.87,146,104.39,168.43,104.34,181.48Z"
          fill="#71adee"
          fillRule="evenodd"
        />
        <path
          d="M81.83,164.9c-1.32-.76-2.38-.15-2.38,1.36a5.22,5.22,0,0,0,2.37,4.1c1.31.76,2.37.15,2.37-1.36A5.25,5.25,0,0,0,81.83,164.9Z"
          fill="#e2f6ff"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const Media = () => (
  <svg viewBox="0 0 319 319">
    <defs>
      <mask
        id="a"
        x="187.31"
        y="156.79"
        width="72.56"
        height="47.9"
        maskUnits="userSpaceOnUse"
      >
        <polygon
          points="187.31 156.79 259.86 156.79 259.86 204.69 187.31 204.69 187.31 156.79"
          fill="#fff"
          fillRule="evenodd"
        />
      </mask>
      <mask
        id="b"
        x="81.2"
        y="57.38"
        width="58.41"
        height="33.52"
        maskUnits="userSpaceOnUse"
      >
        <polygon
          points="81.2 57.38 139.62 57.38 139.62 90.9 81.2 90.9 81.2 57.38"
          fill="#fff"
          fillRule="evenodd"
        />
      </mask>
      <mask
        id="c"
        x="81"
        y="69.39"
        width="37.77"
        height="93.11"
        maskUnits="userSpaceOnUse"
      >
        <polygon
          points="81 69.39 118.77 69.39 118.77 162.51 81 162.51 81 69.39"
          fill="#fff"
          fillRule="evenodd"
        />
      </mask>
    </defs>
    <ellipse cx="160.5" cy="165.5" rx="116.5" ry="77.5" fill="#f6f6f6" />
    <path
      d="M189.55,142.23s35,7.19,45.45-3.42c5.41,12.16-22.73,29-22.73,29Z"
      fill="#2f455f"
      fillRule="evenodd"
    />
    <g mask="url(#a)">
      <path
        d="M187.31,199.27l36.08,5.42c15.6-8.55,36.47-20.95,36.47-20.95l-45.78-27Z"
        fill="#2f455f"
        fillRule="evenodd"
      />
    </g>
    <polygon
      points="118.6 150.97 139.44 138.96 139.41 150.49 118.56 162.51 118.6 150.97"
      fill="#bc7f35"
      fillRule="evenodd"
    />
    <polygon
      points="118.75 98.95 139.59 86.94 139.44 138.96 118.6 150.97 118.75 98.95"
      fill="#b1b1b1"
      fillRule="evenodd"
    />
    <g mask="url(#b)">
      <polygon
        points="81.2 69.39 102.05 57.38 139.62 78.89 118.77 90.9 81.2 69.39"
        fill="#e2a03b"
        fillRule="evenodd"
      />
    </g>
    <polygon
      points="118.77 90.9 139.62 78.89 139.59 86.94 118.75 98.95 118.77 90.9"
      fill="#bc7f35"
      fillRule="evenodd"
    />
    <path
      d="M155.48,87.16c-7.25-4.15-11.87-2.9-14.16-1.58l-20.84,12c2.29-1.33,6.9-2.58,14.15,1.58,5.41,3.09,12.56,2.09,18.57-1.37l20.85-12c-6,3.46-13.16,4.47-18.57,1.37"
      fill="#abe0f6"
      fillRule="evenodd"
    />
    <path
      d="M216,159.94c.21-2.72,2.28-4.4,2.32-7.19s-2-6.68-2.15-9.38c-.11-1.85,1.66-2.55,1.44-4.3a62.58,62.58,0,0,0-1.19-6.69c-1.23-5.13-7.85-10.1-14.58-13.95a122.61,122.61,0,0,0-14.74-7l-20.84,12a121.31,121.31,0,0,1,14.73,7c6.73,3.86,13.35,8.83,14.58,14a61.94,61.94,0,0,1,1.19,6.68c.22,1.76-1.55,2.46-1.44,4.31.17,2.69,2.18,6.59,2.15,9.38s-2.11,4.46-2.32,7.18c-.24,3.05,1.36,7.12.92,9.88-.49,3-3,4.75-3.71,7.19-.45,1.61,1,4.18.46,5.42-1,2.35-2.09,4-3.28,4.68l20.85-12c1.19-.69,2.3-2.34,3.28-4.68.53-1.25-.91-3.81-.47-5.42.69-2.44,3.23-4.15,3.71-7.19.45-2.76-1.15-6.84-.91-9.88"
      fill="#71adee"
      fillRule="evenodd"
    />
    <path
      d="M187.43,66.34l-20.84,12a2,2,0,0,1,2.11.16l20.85-12a2.06,2.06,0,0,0-2.12-.17"
      fill="#bcd5ed"
      fillRule="evenodd"
    />
    <path
      d="M189.55,66.51l-20.85,12c4.57,2.62,10.74,18,5.79,30.73-3.91,10.08-6.32,13.16-7.68,13.94l20.85-12c1.35-.78,3.77-3.86,7.68-14,4.94-12.74-1.22-28.11-5.79-30.72"
      fill="#71adee"
      fillRule="evenodd"
    />
    <path
      d="M168.7,78.52c4.57,2.62,10.74,18,5.79,30.73-4.52,11.65-7,14-8.23,14.16a121.31,121.31,0,0,1,14.73,7c6.73,3.86,13.35,8.83,14.58,13.95a62.58,62.58,0,0,1,1.19,6.69c.22,1.76-1.55,2.46-1.44,4.31.17,2.69,2.18,6.59,2.15,9.38s-2.11,4.46-2.32,7.18c-.24,3.05,1.36,7.12.92,9.88-.49,3-3,4.75-3.71,7.19-.45,1.61,1,4.18.46,5.42-1.57,3.75-3.46,5.72-5.51,4.83a355.16,355.16,0,0,1-34-16.84c-16.21-9.29-32.45-21-34.75-31.46l.15-52c.26-.29,5.2-5.89,15.88.23s28.23-3.79,30.22-17.06c.55-3.71,2.08-4.61,3.85-3.6"
      fill="#e1f3f9"
      fillRule="evenodd"
    />
    <g mask="url(#c)">
      <polygon
        points="118.77 90.9 118.75 98.95 118.6 150.97 118.56 162.51 81 141 81.2 69.39 118.77 90.9"
        fill="#ffdc1e"
        fillRule="evenodd"
      />
    </g>
  </svg>
);

const Outdate = () => (
  <g>
    <ellipse cx="125" cy="172.5" rx="116.5" ry="77.5" fill="#f6f6f6" />
    <g>
      <path
        d="M161.36,218.81c2.39-1.37,5.29-3.92,5.29-6V144.36L146,147.76l15.34,71.05"
        fill="#0064dc"
        fillRule="evenodd"
      />
      <path
        d="M67.25,45c2.39-1.53,5.63-3.07,7.84-1.7L160,93.41a13.39,13.39,0,0,1,6.65,11.42v39.36l-2.73,1.53-4.09-44.81L131,83.7,72.2,49.29Z"
        fill="#e3a03a"
        fillRule="evenodd"
      />
      <path
        d="M64.69,87.28v-38A5,5,0,0,1,72.37,45l84.9,50.09a13.37,13.37,0,0,1,6.65,11.41v39.19Z"
        fill="#fedb1a"
        fillRule="evenodd"
      />
      <path
        d="M163.75,145.72v68.83a5,5,0,0,1-7.67,4.26L71.17,168.72a13.39,13.39,0,0,1-6.65-11.42v-70Z"
        fill="#e2f6ff"
        fillRule="evenodd"
      />
      <path
        d="M137,95.29l6-.17s3.41,3.07,3.75,3.58c.34.68,2.9,4.26,3.07,4.77s.85,5.11.85,5.45-.68,3.24-.68,3.24l-4.43-.69-6.31-5.11Z"
        fill="#85d3ef"
        fillRule="evenodd"
      />
      <path
        d="M149.77,111.13c-2.73,0-7.16-3.92-8.87-9.37-1-3.06-.68-5.45,0-6.47,0,0-4.09-.34-3.92,0a40,40,0,0,1-.34,4.77l.17,5.45,2.22,3.58,3.92,4.26,3.92,1,3.24-1.36Z"
        fill="#304660"
        fillRule="evenodd"
      />
      <path
        d="M151.81,104.66c-2-6.47-7.67-11.76-12.27-11.76s-6.65,5.29-4.6,11.76,7.67,11.75,12.27,11.75C152,116.41,154,111.13,151.81,104.66Zm-3.41,7.67c-.17.34-.51.51-1.19.51-2.73,0-7.16-3.92-8.86-9.37,0,0,.51-7,1.19-7,2.73,0,7.16,3.92,8.86,9.37C149.43,108.92,149.26,111.3,148.4,112.33Z"
        fill="#0064dc"
      />
      <path
        d="M145.17,108.24c-2.56,1.53-7.17-1.54-7.17-4.43V83.36a12.51,12.51,0,0,1,6.14-10.73l3.58-2c2.56-1.54,7.5.85,7.5,3.74l-7.33,1-6.3,5.28-.52,19.08Z"
        fill="#79abdc"
        fillRule="evenodd"
      />
      <g>
        <path
          d="M153.52,89.5V85.41c0-.34.34-8.52.34-8.69s-.85-2.39-.85-2.39l-2.9.86V87.62Z"
          fill="#79abdc"
          fillRule="evenodd"
        />
        <path
          d="M146.36,101.42l-3.07,1.88-.17,2.21,3.58.68,1.53-1.19A17.43,17.43,0,0,0,146.36,101.42Z"
          fill="#79abdc"
          fillRule="evenodd"
        />
      </g>
      <path
        d="M148.4,105.85a17.37,17.37,0,0,0-1.19-2.55l-2,1.19a1.41,1.41,0,0,1-1.87-.66,1.65,1.65,0,0,1-.13-.53V83.36a6.48,6.48,0,0,1,3.07-5.45L150,75.7a1.4,1.4,0,0,1,2,1.19V88.64l3.41,2V74.84a3.73,3.73,0,0,0-3.71-3.75,3.79,3.79,0,0,0-1.92.52l-3.58,2a12.18,12.18,0,0,0-6.14,10.74v20.44a3.74,3.74,0,0,0,5.63,3.24l2.9-1.71A.59.59,0,0,0,148.4,105.85Z"
        fill="#e1f3f9"
        fillRule="evenodd"
      />
      <path
        d="M79.19,61.21l6-.17s3.41,3.07,3.75,3.58,2.9,4.26,3.07,4.77.85,5.11.85,5.45-.68,3.24-.68,3.24l-4.43-.68L81.4,72.29Z"
        fill="#85d3ef"
        fillRule="evenodd"
      />
      <path
        d="M92,77.06c-2.73,0-7.16-3.92-8.86-9.37-1-3.07-.69-5.45,0-6.48,0,0-4.1-.34-3.92,0A40.5,40.5,0,0,1,78.84,66L79,71.44,81.23,75l3.92,4.26,3.92,1,3.24-1.37Z"
        fill="#304660"
        fillRule="evenodd"
      />
      <path
        d="M94.19,70.59c-2-6.48-7.67-11.76-12.28-11.76s-6.65,5.28-4.6,11.76S85,82.34,89.59,82.34,96.23,77.06,94.19,70.59Zm-3.41,7.66c-.17.34-.51.51-1.19.51-2.73,0-7.17-3.92-8.87-9.37,0,0,.51-7,1.19-7,2.73,0,7.16,3.92,8.87,9.37C91.8,74.84,91.46,77.23,90.78,78.25Z"
        fill="#0064dc"
      />
      <path
        d="M87.37,74.16c-2.56,1.54-7.16-1.53-7.16-4.43V49.29a12.51,12.51,0,0,1,6.14-10.73l3.58-2.05c2.55-1.53,7.5.85,7.5,3.75l-7.33,1-6.31,5.28-.51,19.08Z"
        fill="#79abdc"
        fillRule="evenodd"
      />
      <g>
        <path
          d="M95.72,55.42V51.33c0-.34.34-8.52.34-8.69s-.85-2.38-.85-2.38l-2.9.85V53.55Z"
          fill="#79abdc"
          fillRule="evenodd"
        />
        <path
          d="M88.56,67.35l-3.07,1.87-.17,2.22,3.58.68,1.54-1.19A36.94,36.94,0,0,0,88.56,67.35Z"
          fill="#79abdc"
          fillRule="evenodd"
        />
      </g>
      <path
        d="M90.78,71.78a17.48,17.48,0,0,0-1.19-2.56l-2,1.19a1.39,1.39,0,0,1-1.88-.56,1.41,1.41,0,0,1-.17-.63V49.29a6.51,6.51,0,0,1,3.07-5.45l3.75-2.22a1.41,1.41,0,0,1,1.87.66,1.65,1.65,0,0,1,.13.53V54.74l3.41,2V40.94a3.74,3.74,0,0,0-5.63-3.24l-3.58,2a12.15,12.15,0,0,0-6.14,10.73v20.5a3.73,3.73,0,0,0,3.71,3.75,3.79,3.79,0,0,0,1.92-.52l2.9-1.7A1.25,1.25,0,0,1,90.78,71.78Z"
        fill="#e1f3f9"
        fillRule="evenodd"
      />
      <g>
        <path
          d="M98.79,122.21a18.63,18.63,0,0,1,4.26,3.23,26.88,26.88,0,0,1,3.41,4.26,20.49,20.49,0,0,1,3.07,10.23,9,9,0,0,1-.68,3.91,7.4,7.4,0,0,1-1.7,2.73,9,9,0,0,1-2.39,2,15.35,15.35,0,0,1-2.9,1.71l-9.38,5.62a9.44,9.44,0,0,1,2,.68,6.75,6.75,0,0,1,1.87,1l11.94,6.82a4,4,0,0,1,1.19,1.19,2.58,2.58,0,0,1,.34,1.54v3.06L86,156.45v-1.7a1.92,1.92,0,0,1,.18-1,1.35,1.35,0,0,1,.68-.69l11.42-6.64a17.5,17.5,0,0,0,2.56-1.7,10.8,10.8,0,0,0,2-1.88,6.16,6.16,0,0,0,1.2-2.38,8.22,8.22,0,0,0,.51-3.07A10.93,10.93,0,0,0,104,134a24.63,24.63,0,0,0-1.37-3.06,13.38,13.38,0,0,0-2-2.56,24.66,24.66,0,0,0-2.56-1.87,8.18,8.18,0,0,0-2.56-1,2.82,2.82,0,0,0-2,.17,4.27,4.27,0,0,0-1.54,1,5.63,5.63,0,0,0-.85,2c-.17.51-.51.85-.85.85a1.16,1.16,0,0,1-.86-.34c-.17-.17-.34-.17-.51-.34L86.52,127a8.91,8.91,0,0,1,1.36-4.09,6.39,6.39,0,0,1,2.56-2.22A6.26,6.26,0,0,1,94,120.5,11.46,11.46,0,0,1,98.79,122.21Z"
          fill="#0064dc"
          fillRule="evenodd"
        />
        <path
          d="M131.36,141.29l-.17,37.14,7,4.09v3.92l-19.1-11.08v-3.92l7.67,4.43V145.38l-6.31,2.55a.52.52,0,0,1-.68,0c-.17,0-.17-.17-.34-.17s-.17-.17-.34-.17l-.51-.51c-.17-.17-.34-.34-.34-.51l-1.37-3.07,10.74-4.43Z"
          fill="#0064dc"
          fillRule="evenodd"
        />
      </g>
    </g>
  </g>
);

const Divorce = () => (
  <svg viewBox="0 0 319 319">
    <ellipse cx="160.5" cy="165.5" rx="116.5" ry="77.5" fill="#f6f6f6" />
    <polygon
      points="138.4 99.74 174.45 211.74 180.41 211.17 259.81 164.49 144.36 99.17 138.4 99.74"
      fill="#2f455f"
      fillRule="evenodd"
    />
    <polygon
      points="59 146.42 174.45 211.74 253.86 165.06 138.4 99.74 59 146.42"
      fill="#e2f6ff"
      fillRule="evenodd"
    />
    <path
      d="M140.7,101,61.32,147.7,59,146.42l79.4-46.68Z"
      fill="#0064dc"
      fillRule="evenodd"
    />
    <polygon
      points="117.46 168.23 177.73 133.46 179.65 134.57 119.38 169.33 117.46 168.23"
      fill="#65a7c1"
      fillRule="evenodd"
    />
    <polygon
      points="113.78 166.11 174.06 131.34 175.97 132.45 115.7 167.22 113.78 166.11"
      fill="#65a7c1"
      fillRule="evenodd"
    />
    <path
      d="M122.05,157.11l1.92,1.1-12,6.89-1.89-1.1Z"
      fill="#65a7c1"
      fillRule="evenodd"
    />
    <path
      d="M97.36,140.54l4.92,2.84-13.63,7.88-4.92-2.84Z"
      fill="#0064dc"
      fillRule="evenodd"
    />
    <polygon
      points="128.74 174.73 189.01 139.97 190.93 141.08 130.66 175.84 128.74 174.73"
      fill="#65a7c1"
      fillRule="evenodd"
    />
    <polygon
      points="125.07 172.62 185.34 137.85 187.26 138.96 126.99 173.72 125.07 172.62"
      fill="#65a7c1"
      fillRule="evenodd"
    />
    <polygon
      points="121.39 170.5 181.67 135.73 183.59 136.84 123.31 171.61 121.39 170.5"
      fill="#65a7c1"
      fillRule="evenodd"
    />
    <polygon
      points="172.15 199.59 190.48 189.17 191.06 189.5 172.74 199.92 172.15 199.59"
      fill="#65a7c1"
      fillRule="evenodd"
    />
    <polygon
      points="170.7 198.72 189.02 188.3 189.61 188.63 171.28 199.05 170.7 198.72"
      fill="#65a7c1"
      fillRule="evenodd"
    />
    <path
      d="M176.61,190c1.82-1,1.81-2.69,0-3.7a7.48,7.48,0,0,0-6.59,0c-1.8,1-1.8,2.68,0,3.69a7.39,7.39,0,0,0,6.58,0"
      fill="#0064dc"
      fillRule="evenodd"
    />
    <polygon
      points="200.37 183.09 231.42 165.2 232.14 165.6 201.09 183.49 200.37 183.09"
      fill="#65a7c1"
      fillRule="evenodd"
    />
    <polygon
      points="186.7 176.13 217.75 158.24 218.47 158.64 187.42 176.53 186.7 176.13"
      fill="#65a7c1"
      fillRule="evenodd"
    />
    <path
      d="M204,173.32a4,4,0,0,1-.17.76,2,2,0,0,1-.1.41l-.1.44-.09.4a21,21,0,0,0-.45,2.49,1.87,1.87,0,0,0,.06.88c.2.45.65.44,1,.13a10.48,10.48,0,0,0,2.62-4.17c0-.1.09-.2.14-.34L207,174c.18-.43.33-.77.46-1.06a1.82,1.82,0,0,0,.36,1c.37.42.89.32,1.38-.16a16.53,16.53,0,0,0,2.48-3.11l.07.15a.59.59,0,0,0,.71.36H213a.43.43,0,0,0,.16-.05,15.41,15.41,0,0,0,1.51-.9h0a10.21,10.21,0,0,1,2-1.09,16.71,16.71,0,0,1,2.77-.76h0a20.75,20.75,0,0,0,2.32-.59A5.52,5.52,0,0,0,223,167l.54-.31a.44.44,0,0,0,.2-.58h0a.44.44,0,0,0-.59-.18c-.17.09-.3.16-.58.33a5.11,5.11,0,0,1-1.07.54,21,21,0,0,1-2.24.57h0a19.1,19.1,0,0,0-2.9.79,11.26,11.26,0,0,0-2.12,1.18h0c-.66.42-1,.62-1.35.82h-.31l-.06-.22c0-.06-.07-.28,0-.23s0-.16-.06-.22a.72.72,0,0,0-.19-.34.49.49,0,0,0-.68.05.41.41,0,0,0-.07.11,15.27,15.27,0,0,1-2.78,3.71l-.15.13a2.34,2.34,0,0,1-.14-.58v-.43a1.48,1.48,0,0,0-.19-.71.51.51,0,0,0-.7-.13l0,0a1,1,0,0,0-.28.36c-.27.51-.49,1-.86,1.88l-.14.34-.14.34a11.19,11.19,0,0,1-2,3.48,21.84,21.84,0,0,1,.44-2.28,2.59,2.59,0,0,1,.09-.4,3,3,0,0,1,.09-.43l.1-.42c.08-.33.13-.56.18-.77a19.44,19.44,0,0,0,.41-2.94,4.61,4.61,0,0,0,0-.91.57.57,0,0,0-.07-.22.46.46,0,0,0-.51-.23c-.23,0-.31.17-.41.41a31.28,31.28,0,0,0-2.52,9.44,17.43,17.43,0,0,0,.88,6.33.44.44,0,0,0,.84-.24,16.5,16.5,0,0,1-.85-6,25.59,25.59,0,0,1,1.59-6.84A2.68,2.68,0,0,0,204,173.32Z"
      fill="#ffdc1e"
    />
    <path
      d="M192.36,170a6.3,6.3,0,0,0,2.12.15,2.69,2.69,0,0,1-.15,1.91,1.83,1.83,0,0,1-2.3,1.19,2.17,2.17,0,0,1-.41-.19,2.52,2.52,0,0,1-1-1.49A19.88,19.88,0,0,0,192.36,170Zm.94-1.14.22.14a2.07,2.07,0,0,1,.42.31,5.58,5.58,0,0,1-.91-.07,2.88,2.88,0,0,0,.27-.34Zm.42-.89c.21-.81-.22-1.42-1.17-1.64-1.75-.39-2.86.95-3,3a7,7,0,0,0,.11,1.89,7.8,7.8,0,0,1-1.08.72.44.44,0,0,0-.27.56.42.42,0,0,0,.55.27l.11-.05a7.3,7.3,0,0,0,.95-.61,3.19,3.19,0,0,0,1.31,1.69,2.7,2.7,0,0,0,3.71-.92,2.75,2.75,0,0,0,.23-.49,3.52,3.52,0,0,0,.21-2.41l.46-.13c.43.74,1.07.94,1.69.52a4,4,0,0,0,1.12-1.36c.43.2,1-.16,1.31-.69a2,2,0,0,0,.31-1,29.59,29.59,0,0,1,2.58-2h0c0,.37.23.73.7.55a2.9,2.9,0,0,0,1.25-1.1c.42-.54.62-.73.89-.84a47.74,47.74,0,0,1,6.53-1.88c1.67-.41,2.42-.59,3.34-.85a.44.44,0,0,0-.23-.84c-.92.25-1.65.44-3.32.84a47.57,47.57,0,0,0-6.64,1.92,2.86,2.86,0,0,0-1.25,1.11l-.24.29h0a.53.53,0,0,0-.09-.39.47.47,0,0,0-.65-.12l0,0a33.55,33.55,0,0,0-3,2.28v-.09a.46.46,0,0,0-.5-.28,1,1,0,0,0-1-.76,2.66,2.66,0,0,0-1.62.73,3.44,3.44,0,0,0-1.36,3.13l-.52.14a3,3,0,0,0-1-1l-.26-.16Zm-.85-.31a1.16,1.16,0,0,0-1.56.5,1.15,1.15,0,0,0,.26,1.4c-.35.34-.76.71-1.17,1.05a5.92,5.92,0,0,1,0-1.19c.14-1.61.87-2.49,2-2.24.45.1.57.24.53.49Zm4.76,1.3a3,3,0,0,1-.62.71c-.16.1-.24.1-.39-.14a7.46,7.46,0,0,0,1-.53Zm-.21-2.38a1.94,1.94,0,0,1,1.06-.52c.16,0,.22.06.21.37a1.21,1.21,0,0,1-.36.9,9.72,9.72,0,0,1-2,1.37,2.72,2.72,0,0,1,1.09-2.08Zm-5.31,1.92c.06-.1.16-.12.41,0-.11.15-.22.3-.36.46a.28.28,0,0,1-.07-.39l0,0Z"
      fill="#ffdc1e"
    />
    <polygon
      points="226.46 77.99 225.55 79.96 222.13 80.5 219.63 79.06 220.54 77.08 223.96 76.55 226.46 77.99"
      fill="#0064dc"
      fillRule="evenodd"
    />
    <path
      d="M222.41,78.16a1.44,1.44,0,0,1,1.28,0c.35.21.35.54,0,.74a1.38,1.38,0,0,1-1.28,0,.4.4,0,0,1-.23-.5.37.37,0,0,1,.23-.23"
      fill="#ffdc1e"
      fillRule="evenodd"
    />
    <polygon
      points="225.65 158.09 225.55 79.96 226.47 77.99 226.56 156.12 225.65 158.09"
      fill="#0064dc"
      fillRule="evenodd"
    />
    <polygon
      points="222.23 158.62 222.13 80.5 225.55 79.96 225.65 158.09 222.23 158.62"
      fill="#abe0f6"
      fillRule="evenodd"
    />
    <polygon
      points="219.73 157.18 222.23 158.62 222.13 80.5 219.63 79.06 219.73 157.18"
      fill="#c6e9f8"
      fillRule="evenodd"
    />
    <polygon
      points="225.65 158.09 223.16 166.49 222.23 158.62 225.65 158.09"
      fill="#71adee"
      fillRule="evenodd"
    />
    <polygon
      points="219.73 157.18 223.16 166.49 222.23 158.62 219.73 157.18"
      fill="#abe0f6"
      fillRule="evenodd"
    />
    <polygon
      points="226.56 156.12 223.16 166.49 225.65 158.09 226.56 156.12"
      fill="#71adee"
      fillRule="evenodd"
    />
    <path
      d="M223.16,166.49,224,164l-.11.09h-.06a1.59,1.59,0,0,1-.91.16,1.41,1.41,0,0,1-.49-.16.83.83,0,0,1-.18-.13Z"
      fill="#ffdc1e"
      fillRule="evenodd"
    />
  </svg>
);

const Other = () => (
  <svg viewBox="0 0 319 319">
    <ellipse cx="160.5" cy="165.5" rx="116.5" ry="77.5" fill="#f6f6f6" />
    <path
      d="M108.71,164.33c-15.95-9-16.07-23.88-.28-33.18s41.53-9.51,57.47-.47,16.07,23.88.28,33.18-41.53,9.51-57.47.47M235,197.43l-61.29-32.79h0c7.25-5.1,10.84-11.33,10.79-17.52-.06-7-4.72-13.9-14-19.14-18.5-10.49-48.36-10.24-66.69.55-9.17,5.39-13.72,12.4-13.66,19.37s4.72,13.89,14,19.14c18.5,10.48,48.36,10.23,66.69-.56.53-.31,1-.62,1.54-.94l54.86,36.7a2,2,0,0,0,1.18.32l2.78-.16a6.11,6.11,0,0,0,3.12-1.06l.89-.61a1.93,1.93,0,0,0,.51-2.68,2,2,0,0,0-.69-.62"
      fill="#2f455f"
      fillRule="evenodd"
    />
    <path
      d="M173.7,132.08c-1.69-4.12-5.34-8-10.94-11.18-15.94-9-41.67-8.82-57.47.48-5.55,3.26-9.13,7.21-10.75,11.35a14,14,0,0,0-.14,10.08c1.51-4.36,5.16-8.53,11-11.94,15.79-9.3,41.52-9.51,57.47-.48,5.86,3.32,9.58,7.43,11.17,11.76a14,14,0,0,0-.31-10.07"
      fill="#255793"
      fillRule="evenodd"
    />
    <path
      d="M163.08,158.94c-15.79,9.29-41.53,9.51-57.47.47s-16.07-23.88-.28-33.18,41.53-9.5,57.47-.47,16.07,23.88.28,33.18"
      fill="#e1f3f9"
      fillRule="evenodd"
    />
    <path
      d="M180.65,142.09a22.52,22.52,0,0,1-6.78,10.13,38.46,38.46,0,0,1-6.19,4.49c-18.33,10.79-48.2,11-66.7.55a36.38,36.38,0,0,1-6.27-4.39,22.4,22.4,0,0,1-6.95-10,16.2,16.2,0,0,1-.76-4.74l.08,9.49c.06,7,4.72,13.9,14,19.14,18.51,10.48,48.37,10.24,66.7-.55,9.17-5.39,13.72-12.4,13.66-19.37l-.08-9.49a16.24,16.24,0,0,1-.68,4.75"
      fill="#255793"
      fillRule="evenodd"
    />
    <path
      d="M105.57,154.56c-15.95-9-16.07-23.89-.28-33.18s41.53-9.51,57.47-.48,16.07,23.89.28,33.19-41.53,9.5-57.47.47m75.76-17.22c-.06-7-4.72-13.9-14-19.14-18.5-10.48-48.36-10.23-66.7.55-9.16,5.4-13.71,12.4-13.65,19.37s4.72,13.9,14,19.14c18.5,10.48,48.36,10.24,66.69-.55,9.17-5.39,13.72-12.4,13.66-19.37"
      fill="#6fb8d5"
      fillRule="evenodd"
    />
    <path
      d="M164.75,161.47a6.24,6.24,0,0,0-.41,2.22,2.52,2.52,0,0,0,1.07,2.32l61.25,34.68,1.78-3.15Z"
      fill="#255793"
      fillRule="evenodd"
    />
    <path
      d="M231.67,191.84l-61.24-34.69a2.5,2.5,0,0,0-2.54.28,7.83,7.83,0,0,0-3.14,4l63.69,36.07Z"
      fill="#6fb8d5"
      fillRule="evenodd"
    />
    <path
      d="M232.62,190.17l-43.28-24.52a3.49,3.49,0,0,0-3.51.38,11.08,11.08,0,0,0-4.6,6.46l46.49,26.33Z"
      fill="#ffdc1e"
      fillRule="evenodd"
    />
    <path
      d="M181.23,172.49a8.26,8.26,0,0,0-.28,2.16,3.46,3.46,0,0,0,1.48,3.2l43.28,24.52,2-3.55Z"
      fill="#ffdc1e"
      fillRule="evenodd"
    />
    <path
      d="M232.62,190.17a3.49,3.49,0,0,1,1.48,3.19,11,11,0,0,1-4.89,8.63,3,3,0,0,1-5-2.82,11,11,0,0,1,4.89-8.63,3.47,3.47,0,0,1,3.5-.37"
      fill="#e39f3a"
      fillRule="evenodd"
    />
  </svg>
);

const Birthday = () => (
  <svg viewBox="0 0 319 319">
    <ellipse cx="160.5" cy="165.5" rx="116.5" ry="77.5" fill="#f6f6f6" />
    <path
      d="M128.12,80.83l19.41,11.22L145,72.51ZM161.6,200.44l35,1.3h0l67.11-42.53L147.53,92.05Z"
      fill="#2f455f"
      fillRule="evenodd"
    />
    <polygon
      points="85 156.72 114.24 173.43 121.17 177.43 161.26 200.56 219.25 166.89 219.25 104.84 85 94.67 85 156.72"
      fill="#e1f3f9"
      fillRule="evenodd"
    />
    <polygon
      points="142.99 61 85 94.67 114.24 111.39 121.17 115.38 161.26 138.51 219.25 104.84 179.15 81.71 164.81 73.44 142.99 61"
      fill="#abe0f6"
      fillRule="evenodd"
    />
    <polygon
      points="114.7 111.75 114.7 173.79 129.05 182.07 129.05 120.03 114.7 111.75"
      fill="#71adee"
      fillRule="evenodd"
    />
    <polygon
      points="161.26 138.51 161.27 200.56 219.25 166.89 219.25 104.84 161.26 138.51"
      fill="#71adee"
      fillRule="evenodd"
    />
    <polygon
      points="187.04 86.36 172.69 78.08 165.99 81.97 155.92 87.81 131.47 102.01 121.4 107.86 114.7 111.75 129.05 120.03 135.75 116.13 145.69 110.36 170.39 96.02 180.33 90.25 187.04 86.36"
      fill="#71adee"
      fillRule="evenodd"
    />
    <polygon
      points="197.68 117.37 172.28 103.03 162.15 97.31 137.53 83.39 127.4 77.67 120.65 73.86 106.35 82.28 127.5 94.22 137.51 99.88 162.38 113.93 172.39 119.58 183.28 125.73 197.68 117.37"
      fill="#71adee"
      fillRule="evenodd"
    />
    <polygon
      points="183.28 125.73 183.28 187.78 197.68 179.42 197.68 117.37 183.28 125.73"
      fill="#0064dc"
      fillRule="evenodd"
    />
    <path
      d="M150.87,107.06s22.18-11,29.88-3.07-17.5,11.12-29.88,3.07"
      fill="#bc7f35"
      fillRule="evenodd"
    />
    <path
      d="M197.27,98.19l-15.09,8.23s-2.38-7.48-14.91-4.85c-13,2.71-16.4,5.49-16.4,5.49L166,97.89s17.16-8.83,26.58-5.16c5.41,2.11,4.73,5.46,4.73,5.46"
      fill="#ffdc1e"
      fillRule="evenodd"
    />
    <path
      d="M197.27,98.19l-15.09,8.23s.34,2-2.49,3.44l15.15-7.8s2.67-2.16,2.43-3.87"
      fill="#e2a03b"
      fillRule="evenodd"
    />
    <path
      d="M136.75,99.05s-9.5-28.85-16.67-26.18C109.7,76.73,124,91,136.75,99.05"
      fill="#bc7f35"
      fillRule="evenodd"
    />
    <path
      d="M132.6,65.55l-14.4,8.28s5.44-1.66,11,10.58c5.4,11.9,7.54,14.64,7.54,14.64l14.12-8.21s-2.94-17.19-11-23.35c-4.6-3.54-7.31-1.94-7.31-1.94"
      fill="#ffdc1e"
      fillRule="evenodd"
    />
    <path
      d="M150.87,107S128.69,96,121,103.92s17.49,11.13,29.88,3.08"
      fill="#bc7f35"
      fillRule="evenodd"
    />
    <path
      d="M104.46,98.13l15.09,8.23s2.39-7.48,14.92-4.86c12.95,2.72,16.4,5.5,16.4,5.5l-15.09-9.17S118.62,89,109.2,92.67c-5.41,2.11-4.74,5.46-4.74,5.46"
      fill="#ffdc1e"
      fillRule="evenodd"
    />
    <path
      d="M104.46,98.13l15.09,8.23s-.33,2,2.49,3.44L106.9,102s-2.68-2.16-2.44-3.87"
      fill="#e2a03b"
      fillRule="evenodd"
    />
    <path
      d="M165,99.45s9.49-28.86,16.67-26.18C192,77.13,177.73,91.42,165,99.45"
      fill="#bc7f35"
      fillRule="evenodd"
    />
    <path
      d="M169.14,66l14.39,8.28s-5.44-1.66-11,10.58C167.12,96.7,165,99.45,165,99.45l-14.12-8.21s2.93-17.19,10.95-23.35c4.6-3.54,7.32-1.94,7.32-1.94"
      fill="#ffdc1e"
      fillRule="evenodd"
    />
    <polygon
      points="164.99 99.34 151.05 90.64 136.75 99.05 150.59 107.06 164.99 99.34"
      fill="#e2a03b"
      fillRule="evenodd"
    />
  </svg>
);

const Social = () => (
  <svg viewBox="0 0 319 319">
    <ellipse cx="160.5" cy="165.5" rx="116.5" ry="77.5" fill="#f6f6f6" />
    <path
      d="M192.66,199.56l-79-45.56a1.7,1.7,0,0,1,0-2.91l12.44-7.7,81,45.85a1.7,1.7,0,0,1,0,2.95l-7.93,4.59-4.81,2.78a1.68,1.68,0,0,1-1.68,0"
      fill="#2f455f"
      fillRule="evenodd"
    />
    <polygon
      points="144.19 143.65 158.98 135 139.85 149.63 125.06 158.27 144.19 143.65"
      fill="#0064dc"
      fillRule="evenodd"
    />
    <path
      d="M216.87,111.3l0,17-.08,26c0,3.93-1.38,6.69-3.6,8l-14.78,8.67c2.22-1.31,3.58-4.08,3.6-8l.08-28.59,0-14.35a24.13,24.13,0,0,0-2.13-9.32,30.38,30.38,0,0,0-4.79-7.59,22.1,22.1,0,0,0-5.4-4.56L115.53,55.35c-3.43-2-6.55-2.19-8.8-.86l14.78-8.65c2.25-1.32,5.37-1.12,8.82.86l74.22,43.13A22,22,0,0,1,210,94.4a30.18,30.18,0,0,1,4.78,7.58,23.58,23.58,0,0,1,2.12,9.32"
      fill="#71adee"
      fillRule="evenodd"
    />
    <path
      d="M189.77,98.48c6.83,4,12.34,13.58,12.32,21.48L202,162.88c0,7.9-5.57,11.1-12.4,7.13l-45.38-26.36-19.13,14.62-2.52-27.2-7.22-4.19c-6.83-4-12.34-13.6-12.32-21.5l.12-42.92c0-7.9,5.57-11.08,12.41-7.11Z"
      fill="#abe0f6"
      fillRule="evenodd"
    />
    <path
      d="M216.87,111.3l-.12,42.93c0,3.93-1.38,6.69-3.6,8l-14.78,8.67c2.22-1.31,3.58-4.08,3.6-8l.12-42.94Z"
      fill="#0064dc"
      fillRule="evenodd"
    />
    <path
      d="M130.14,91.15c-3.73-2.17-6.76-.43-6.78,3.88a15,15,0,0,0,6.74,11.74c3.72,2.16,6.76.44,6.77-3.89a14.91,14.91,0,0,0-6.73-11.73"
      fill="#ffdc1e"
      fillRule="evenodd"
    />
    <path
      d="M152,103.84c-3.72-2.16-6.76-.44-6.77,3.88a14.88,14.88,0,0,0,6.73,11.73c3.73,2.18,6.76.44,6.78-3.87A15,15,0,0,0,152,103.84"
      fill="#ffdc1e"
      fillRule="evenodd"
    />
    <path
      d="M172.92,116c-3.73-2.17-6.75-.43-6.78,3.88a15,15,0,0,0,6.73,11.73c3.74,2.17,6.77.44,6.79-3.88A14.94,14.94,0,0,0,172.92,116"
      fill="#ffdc1e"
      fillRule="evenodd"
    />
    <path
      d="M210,94.4,195.17,103a30.38,30.38,0,0,1,4.79,7.59,24.13,24.13,0,0,1,2.13,9.32l14.78-8.65a23.58,23.58,0,0,0-2.12-9.32A30.18,30.18,0,0,0,210,94.4"
      fill="#0064dc"
      fillRule="evenodd"
    />
  </svg>
);

const GridIcon = ({ type, title }) => {
  return (
    <SvgIcon title={title} viewBox="0 0 150 150" width={150}>
      {type === "recently_married" && <Marriage />}
      {type === "home_purchase" && <Home />}
      {type === "birth_of_child" && <Child />}
      {type === "reminded_media" && <Media />}
      {type === "recently_divorced" && <Divorce />}
      {type === "social_pressure" && <Social />}
      {type === "other" && <Other />}
      {type === "birthday" && <Birthday />}
      {type === "outdate" && <Outdate />}
    </SvgIcon>
  );
};

export default GridIcon;
