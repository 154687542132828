import React from "react";
import R from "ramda";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import { withTheme } from "@material-ui/styles";
import { InviteUser } from "./InviteContent";
import { CHECKOUT_PATH, PLANS_PATH } from "../../../constants/routes";
import UpgradePlanIcon from "../../ui/icons/UpgradePlanIcon";
import ChangePlansIcon from "../../ui/icons/ChangePlansIcon";
import { ButtonLink } from "../../ui/Button";
import {
  H4,
  SmallBody,
  SmallExternalLink,
  SmallTextLink,
} from "../../ui/Typography";
import { colorPalette } from "../../../config/Theme";
import CheckmarkLight from "../../ui/icons/CheckmarkLight";
import { couplesPlan, notarialWill } from "../../../constants/plans";
import YourInviteContent from "./YourInviteContent";
import YourNotarialContent from "./YourNotrialContent";
import {
  selectIsNotUpgradablePlan,
  selectPlanQuantity,
} from "../../../selectors/plans";
import { selectNumberOfAvailableInvites } from "../../../selectors/purchases";
import GiftPlanIcon from "../../ui/icons/GiftPlanIcon";
import { analyticsClickExternalLink } from "../../../actions/analytics";

const borderAttributes = {
  "border-right": `1px solid ${colorPalette.willfulGrey2}`,
  "border-left": `1px solid ${colorPalette.willfulGrey2}`,
};

const TopBox = styled(Box)`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top: 1px solid ${colorPalette.willfulGrey2};
  ${{ ...borderAttributes }}
`;
const BottomBox = styled(Box)`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: 1px solid ${colorPalette.willfulGrey2};
  ${{ ...borderAttributes }}
  background-color: white;
`;

const UpgradeOrGiftWill = ({
  translations,
  canUserUpgrade,
  analyticsClickExternalLink,
}) => {
  return canUserUpgrade ? (
    <Box display="flex">
      <UpgradePlanIcon />
      <Box mx={0.5}>
        <SmallTextLink to={PLANS_PATH} text={translations.upgradePlanLink} />
      </Box>
    </Box>
  ) : (
    <Box display="flex">
      <GiftPlanIcon />
      <Box mx={0.5}>
        <SmallExternalLink
          onClick={() =>
            analyticsClickExternalLink(translations.bulkPurchaseGifts)
          }
          href={translations.bulkPurchaseGifts}
          text={translations.giftWill}
        />
      </Box>
    </Box>
  );
};

const YourPlanCard = ({
  translations,
  activePlan,
  isPaid,
  isBundlePlanSelected,
  numberOfAvailablePlans,
  displayModal,
  assignedPlans,
  dashboardView,
  revokeInvite,
  resendInvite,
  isNotarialWill,
  notarizationStatus,
  isFrenchSelected,
  isCompleteAllSections,
  showCta = true,
  showDetails = true,
}) => {
  const activePlanSymbol = R.propOr("", "symbol")(activePlan);
  const symbol = isBundlePlanSelected ? "bundle" : activePlanSymbol;
  const {
    totalInvites,
    totalInvitesSelected,
    availableInvites,
    unlockDocuments,
  } = translations;
  const {
    planName,
    planNameUpdated,
    summary,
    summaryMustDisplay,
    details,
  } = R.propOr({}, symbol)(translations);
  const numberOfAvailablePlansNum = useSelector((state) =>
    selectPlanQuantity(state),
  );

  const numberOfInvitesRemainingNum = useSelector((state) =>
    selectNumberOfAvailableInvites(state),
  );
  const isBundle = symbol === "bundle";
  const planNameText = isBundle
    ? planNameUpdated.replace("TOTAL", numberOfAvailablePlansNum)
    : planName;
  const plansShouldShowSummary = [couplesPlan, notarialWill];

  const planIsEssentialsAndIsQuebec =
    symbol === "essentials" && summaryMustDisplay;
  const shouldShowSummary =
    plansShouldShowSummary.some((item) => item.symbol === symbol) ||
    planIsEssentialsAndIsQuebec;
  const shouldShowDetails = !shouldShowSummary;
  const isUpgradablePlan = !useSelector(selectIsNotUpgradablePlan);

  return (
    <Box id="your-plan-container">
      {/* Open Top Box */}
      <TopBox p={2} bgcolor="willfulWhite.main" data-testid="topbox-background">
        {/* Plan Name */}
        <Box mb={2}>
          <H4 align="left" data-testid="plan-name">
            {planNameText}
          </H4>
        </Box>

        {/* CTA - only shows when not paid */}
        {showCta && !isPaid && (
          <Box mt={1.5}>
            <ButtonLink
              to={CHECKOUT_PATH}
              fullWidth
              text={unlockDocuments}
              displayArrowRight
            />
          </Box>
        )}

        {/* Summary */}
        {shouldShowSummary && (
          <Box mt={2}>
            <SmallBody>{summary}</SmallBody>
          </Box>
        )}

        {/* Details - Essentials, Premium, Family/Bundle/Premium x 2 only */}
        {showDetails && shouldShowDetails && (
          <Box mt={2}>
            {details.map((planDetail, index) => {
              const isLastItem = index + 1 === details.length;
              return (
                <Box mb={!isLastItem && 0.5} key={`${planDetail}`}>
                  <Box component="span" display="flex">
                    <Box mt="2px">
                      <CheckmarkLight width={20} height={20} />
                    </Box>
                    <Box ml={0.5}>
                      {isBundle ? (
                        <SmallBody
                          key={planDetail}
                          align="left"
                          dangerouslySetInnerHTML={{
                            __html: planDetail.replace(
                              "TOTAL",
                              numberOfAvailablePlansNum,
                            ),
                          }}
                        />
                      ) : (
                        <SmallBody
                          key={planDetail}
                          align="left"
                          dangerouslySetInnerHTML={{ __html: planDetail }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}

        {/* Notarial Content - only appears if isPaid === */}
        {isPaid && isNotarialWill && (
          <YourNotarialContent
            isCompleteAllSections={isCompleteAllSections}
            notarizationStatus={notarizationStatus}
            isFrenchSelected={isFrenchSelected}
            dashboardView={dashboardView}
            translations={translations}
          />
        )}

        {/* Invite Content - Family Plan/Bundle/Premium x2 only */}
        {isBundlePlanSelected && isPaid && (
          <YourInviteContent
            isPaid={isPaid}
            totalInvites={totalInvites}
            totalInvitesSelected={totalInvitesSelected}
            availableInvites={availableInvites}
            dashboardView={dashboardView}
            translations={translations}
            numberOfAvailablePlansNum={numberOfAvailablePlansNum}
            numberOfInvitesRemainingNum={numberOfInvitesRemainingNum}
          />
        )}
      </TopBox>
      {/* Close Top Box */}

      {/* Open Bottom Box */}
      <BottomBox>
        {isBundlePlanSelected && isPaid && !dashboardView && showCta && (
          <InviteUser
            assignedPlans={assignedPlans}
            revokeInvite={revokeInvite}
            translations={translations}
            resendInvite={resendInvite}
            numberOfAvailablePlans={numberOfAvailablePlans}
            displayModal={displayModal}
          />
        )}

        <Box
          borderBottom={0}
          borderRight={0}
          borderLeft={0}
          borderColor="border.main"
          border={1}
        >
          <Box py={1} px={1.5}>
            <Box display="flex">
              {isPaid ? (
                <UpgradeOrGiftWill
                  translations={translations}
                  canUserUpgrade={isUpgradablePlan}
                  analyticsClickExternalLink={analyticsClickExternalLink}
                />
              ) : (
                <>
                  <ChangePlansIcon />
                  <SmallTextLink
                    margin="0 8px"
                    to={PLANS_PATH}
                    text={translations.changeYourPlan}
                  />
                </>
              )}
            </Box>
          </Box>
        </Box>
      </BottomBox>
      {/* Close Bottom Box */}
    </Box>
  );
};

export default withTheme(YourPlanCard);
