import R from "ramda";

export const getAppointeeLevelTranslation = (
  appointeeLevelIndexs,
  index = 0,
  spouseIsPrimary,
  translations,
) => {
  let level = R.pathOr(null, [index, "level"])(appointeeLevelIndexs);
  if (level === null) {
    return "";
  }
  if (spouseIsPrimary) {
    // increases appointeeLevel if spouse is selected as primary appointee
    // so that the first backup appointee is declared as the "Secondary" appointee
    level += 1;
  }
  return translations.levels[level];
};
