import React from "react";
import { reduxForm } from "redux-form";
import { useMediaQuery, useTheme } from "@material-ui/core";

import { Form } from "../../ui/Form";
import BinaryRadioField from "../../ui/inputs/BinaryRadioField";
import {
  PEOPLE_QUANTITY_INTENT_FIELD_ID,
  PEOPLE_QUANTITY_INTENT_FORM_ID,
} from "../../../constants/forms";
import { useValidatePeopleQuantityIntentMemo } from "../../ui/inputs/validations";

const IntendedUseForm = ({
  isLoading,
  handleSubmit,
  backLink,
  translations,
}) => {
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Form
      isLoading={isLoading}
      onSubmit={handleSubmit}
      backLink={backLink}
      translations={translations}
      reverseWrap={isTabletDown}
    >
      <BinaryRadioField
        name={PEOPLE_QUANTITY_INTENT_FIELD_ID}
        label={translations.optionListLabel}
        validate={useValidatePeopleQuantityIntentMemo(translations)}
        optionList={translations.optionList}
        translations={translations}
      />
    </Form>
  );
};

export default reduxForm({
  form: PEOPLE_QUANTITY_INTENT_FORM_ID,
})(IntendedUseForm);
