import R from "ramda";
import { createSelector } from "reselect";
import { getAccordionTranslationsByProvince } from "../i18n/translations";

// TODO: fix circular import
const selectLanguageCode = R.pathOr("en", ["aboutYou", "lang"]);
export const selectProvinceCode = R.pathOr("", [
  "provinces",
  "selectedProvince",
  "code",
]);

export const selectQuestions = (listOfQuestionKeys) =>
  createSelector(
    selectProvinceCode,
    selectLanguageCode,
    (provinceCode, language) => {
      return getAccordionTranslationsByProvince(
        language,
        provinceCode,
        listOfQuestionKeys,
      );
    },
  );
