import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";

import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";
import { selectTranslations } from "./translations";
import { selectIsFieldComplete } from "./utils/progress";
import {
  LIFE_PROLONG_FORM_ID,
  PAIN_MANAGE_FORM_ID,
  LIFE_PROLONG_FIELD_ID,
  PAIN_MANAGE_FIELD_ID,
} from "../constants/forms";
import {
  selectPainManageTranslations,
  selectLifeProlongTranslations,
} from "./healthcare";

const selectSpouseArrangementsData = R.pathOr({}, ["spouseArrangements"]);
export const selectSpouseHasSkippedHealthcare = R.pathOr(false, [
  "spouseArrangements",
  "healthcareVisited",
]);

export const selectIsSpouseLifeProlongComplete = createSelector(
  selectIsFieldComplete(selectSpouseArrangementsData, LIFE_PROLONG_FIELD_ID),
  selectSpouseHasSkippedHealthcare,
  (lifeProlongComplete, skippedHealthcare) =>
    lifeProlongComplete || skippedHealthcare,
);
export const selectIsSpousePainManageComplete = createSelector(
  selectIsFieldComplete(selectSpouseArrangementsData, PAIN_MANAGE_FIELD_ID),
  selectSpouseHasSkippedHealthcare,
  (painManageComplete, skippedHealthcare) =>
    painManageComplete || skippedHealthcare,
);

export const spousePainManagePageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(PAIN_MANAGE_FORM_ID),
  isLoading: selectIsLoadingById(PAIN_MANAGE_FORM_ID),
  translations: createSelector(
    selectPainManageTranslations,
    selectTranslations(["global", "spouseArrangements"]),
    (translations, { sectionTitle }) => ({ ...translations, sectionTitle }),
  ),
});

export const spouseLifeProlongPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(LIFE_PROLONG_FORM_ID),
  isLoading: selectIsLoadingById(LIFE_PROLONG_FORM_ID),
  translations: createSelector(
    selectLifeProlongTranslations,
    selectTranslations(["global", "spouseArrangements"]),
    (translations, { sectionTitle }) => ({ ...translations, sectionTitle }),
  ),
});
