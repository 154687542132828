import styled from "styled-components";
import { colorPalette } from "../../../../config/Theme";

const getBorderStyle = (props) => {
  if (props.errorMessage) {
    return `1px solid ${colorPalette.error}`;
  }
  if (props.isChecked) {
    return `1px solid ${colorPalette.willfulBlue}`;
  }
  return `1px solid ${colorPalette.willfulGrey2}`;
};

export const RadioSelectorWrapper = styled.div`
  box-sizing: border-box;
  min-height: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  border: ${(props) => getBorderStyle(props)};
  border-radius: 3px;
  transition: all 0.1s ease-in;
  margin-bottom: 8px;
  cursor: pointer;
  background-color: ${(props) =>
    props.isChecked ? colorPalette.willfulxxxLightBlue : "white"};

  &:hover {
    background-color: ${(props) =>
      props.errorMessage ? "inherit" : colorPalette.willfulxxxLightBlue};
  }

  &:last-of-type {
    margin-bottom: ${(props) => (props.errorState ? "8px" : "0px")};
  }

  :focus {
    border: 1px solid
      ${(props) =>
        props.errorMessage ? colorPalette.error : colorPalette.willfulBlue};
    background-color: ${(props) =>
      props.errorMessage ? "inherit" : colorPalette.willfulxxxLightBlue};
    outline: none;
    box-shadow: none;
  }

  .label-radio-option {
    flex-grow: 1;
    padding-right: 8px;
  }

  .label-radio-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${(props) => (props.withFormField ? "16px" : "0px")};
  }

  .label-radio-container--reverse {
    flex-direction: row-reverse;
  }

  .input-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .input-label {
      font-size: 16px;
      letter-spacing: 0.2px;
      line-height: 24px;
      color: ${() => colorPalette.willfulGrey};
      margin-bottom: 8px;
    }
  }
`;

export const ErrorMessage = styled.span`
  color: ${() => colorPalette.error};
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 24px;
`;
