import { select, takeEvery, call, put } from "redux-saga/effects";
import { getFormValues } from "redux-form";
import { push } from "connected-react-router";

import { submitFormRequest } from "./forms";
import { submitLockedProvinceLead } from "../api/leads";
import { SUBMIT_LOCKED_PROVINCE_LEAD_TYPE } from "../actions/locked-province";
import { selectProvinceById } from "../selectors/provinces";
import { lockedProvinceLeadAnalytics } from "../actions/analytics";
import { SORRY_PATH, THANK_YOU_REDIRECT_PATH } from "../constants/routes";

import { RESIDENCE_FORM_ID } from "../constants/forms";
import { selectUserEmail } from "../selectors/auth";

function* lockedProvinceLeadRequest() {
  const { provinceId, lockedProvinceLead } = yield select(
    getFormValues(RESIDENCE_FORM_ID),
  );

  const email = yield select(selectUserEmail);
  const province = yield select(selectProvinceById(provinceId));

  if (lockedProvinceLead === "consent") {
    yield call(submitFormRequest, {
      apiCall: submitLockedProvinceLead,
      formId: "lockedProvinceLead",
      values: {
        email,
        provinceId,
      },
    });
    yield put(
      lockedProvinceLeadAnalytics({
        email,
        province: province.code,
      }),
    );
    return yield put(push(THANK_YOU_REDIRECT_PATH));
  }

  return yield put(push(SORRY_PATH));
}

export function* watchSubmitLockedProvinceLead() {
  yield takeEvery(SUBMIT_LOCKED_PROVINCE_LEAD_TYPE, lockedProvinceLeadRequest);
}
