import R from "ramda";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { getFormValues } from "redux-form";
import queryString from "query-string";
import { push } from "connected-react-router";

import { closeModal } from "../actions/modal";
import { submitFormRequest } from "./forms";
import {
  INVITE_USER_FORM_ID,
  SIGNUP_FORM_ID,
  VALIDATE_INVITE_TOKEN_ID,
} from "../constants/forms";
import {
  submitInviteUser,
  submitValidateInviteToken,
  revokeInvite,
  submitResendInvite,
} from "../api/invite";
import {
  addInvitedUser,
  VALIDATE_INVITE_TOKEN_TYPE,
  INVITED_USER_GET_STARTED_TYPE,
  REVOKE_INVITE_TYPE,
  RESEND_INVITE_TYPE,
  resendLoadingComplete,
  revokeLoadingComplete,
  updateInvites,
} from "../actions/invites";
import { selectIsInvitedUser } from "../selectors/invite";
import { isInvitedUserAnalytics } from "../actions/analytics";
import { selectQueryParams } from "../selectors/location";
import { addInviteToken } from "../actions/auth";
import { fetchSuccess } from "../actions/requests";
import { RESIDENCE_PATH } from "../constants/routes";
import { getProvinces } from "../api/provinces";
import { fetchApiData } from "./requests";
import { addProvinces } from "../actions/provinces";

export function* inviteUserRequest() {
  const invitedUser = yield select(getFormValues(INVITE_USER_FORM_ID));
  const { assignedUsers, availableInvites, errors } = yield call(
    submitFormRequest,
    {
      apiCall: submitInviteUser,
      formId: INVITE_USER_FORM_ID,
    },
  );
  if (!errors) {
    yield put(updateInvites(assignedUsers, availableInvites));
    yield put(addInvitedUser(invitedUser));
    yield put(closeModal());
  }
}

export function* revokeInviteRequest({ payload }) {
  const { id } = R.propOr({}, "user")(payload);
  const { assignedUsers, availableInvites, errors } = yield call(
    submitFormRequest,
    {
      apiCall: revokeInvite,
      values: {
        id,
      },
    },
  );
  yield put(revokeLoadingComplete(id));
  if (!errors) {
    yield put(updateInvites(assignedUsers, availableInvites));
  }
}

export function* validateInviteToken() {
  const rawQueryParams = yield select(selectQueryParams);
  const { invite_token } = yield call(queryString.parse, rawQueryParams);

  const { status } = yield call(submitFormRequest, {
    apiCall: submitValidateInviteToken,
    formId: VALIDATE_INVITE_TOKEN_ID,
    values: {
      inviteToken: invite_token,
    },
  });
  if (status === 200) {
    const { provinces } = yield call(fetchApiData, {
      apiCall: getProvinces,
      formId: SIGNUP_FORM_ID,
    });
    yield put(addProvinces(provinces));
    yield put(fetchSuccess(VALIDATE_INVITE_TOKEN_ID));
    yield put(addInviteToken(invite_token));

    const isInvitedUser = yield select(selectIsInvitedUser);

    yield put(isInvitedUserAnalytics(isInvitedUser));
  } else {
    yield put(addInviteToken(null));
  }
}

export function* invitedUserGetStarted() {
  yield put(push(RESIDENCE_PATH));
}

export function* resendInviteRequest({ payload }) {
  const id = R.pathOr("", ["user", "id"])(payload);
  yield call(submitFormRequest, {
    apiCall: submitResendInvite,
    formId: INVITE_USER_FORM_ID,
    values: {
      id,
    },
  });
  yield put(resendLoadingComplete(id));

  // TODO: Do we want to fire analytics at this point?
}

export function* watchInvitedUserGetStarted() {
  yield takeEvery(INVITED_USER_GET_STARTED_TYPE, invitedUserGetStarted);
}
export function* watchValidateInviteToken() {
  yield takeEvery(VALIDATE_INVITE_TOKEN_TYPE, validateInviteToken);
}
export function* watchRevokeInviteRequest() {
  yield takeEvery(REVOKE_INVITE_TYPE, revokeInviteRequest);
}

export function* watchResendInviteRequest() {
  yield takeEvery(RESEND_INVITE_TYPE, resendInviteRequest);
}
