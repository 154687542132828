import React from "react";
import { reduxForm } from "redux-form";
import { useMediaQuery, useTheme } from "@material-ui/core";

import { Form } from "../../ui/Form";

import NameFields from "../../ui/inputs/NameFields";

import { FULL_NAME_FORM_ID } from "../../../constants/forms";
import { Body } from "../../ui/Typography";

const FullNameForm = ({
  handleSubmit,
  backLink,
  isLoading,
  translations,
  showFormTitle = false,
  showFormButtons = true,
  isMiniForm = false,
}) => {
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Form
      isLoading={isLoading}
      onSubmit={handleSubmit}
      backLink={backLink}
      translations={translations}
      showFormButtons={showFormButtons}
      reverseWrap={isTabletDown}
      isMiniForm={isMiniForm}
    >
      {showFormTitle && <Body bold>{translations.formTitle}</Body>}
      <NameFields translations={translations} />
    </Form>
  );
};

export default reduxForm({
  form: FULL_NAME_FORM_ID,
})(FullNameForm);
