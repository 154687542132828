import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/styles";
import { connect } from "react-redux";

import { welcomePageProps } from "../../selectors/welcome";
import { updateReasons } from "../../actions/welcome";
import { SectionTitle, H2, Body } from "../../components/ui/Typography";

import { fetchDashboard } from "../../actions/dashboard";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import CheckboxIconGrid from "../../components/ui/inputs/CheckboxIconGrid";
import {
  CANADA_NEWCOMER,
  EXPECTING_OR_HAVING_CHILD,
  FIRST_TIME_WILL,
  MARRIED_OR_COMMON_LAW,
} from "../../constants/reasons";
import { DocumentPaperIcon } from "../../components/ui/icons/CheckboxCard/DocumentPaperIcon";
import { RingsIcon } from "../../components/ui/icons/CheckboxCard/RingsIcon";
import { StarIcon } from "../../components/ui/icons/CheckboxCard/StarIcon";
import { BabyCartIcon } from "../../components/ui/icons/CheckboxCard/BabyCartIcon";
import { PageWrapper } from "../Layout";
import { PageLayout } from "../../components/ui/PageLayout";
import { PrimaryButton, TextButton } from "../../components/ui/Button";
import { useStickyBottomBoxStyle } from "../../components/ui/utils/sticky-bottom-box";

const WelcomeSurvey = ({
  translations,
  isDesktop,
  isTabletDown,
  isMobile,
  updateReasons,
  reasons,
}) => {
  const [checkboxValues, setCheckboxValues] = useState(new Set([...reasons]));
  const confirmButtonStyling = useStickyBottomBoxStyle();

  const handleOnChange = (value, checked) => {
    const newSetValues = new Set(checkboxValues);
    if (checked) {
      newSetValues.add(value);
    } else {
      newSetValues.delete(value);
    }
    setCheckboxValues(newSetValues);
  };

  let sidesPadding = 8;
  if (isDesktop) {
    sidesPadding = 4;
  }
  if (isTabletDown) {
    sidesPadding = 8;
  }
  if (isMobile) {
    sidesPadding = 0;
  }

  const iconDimensions = {
    width: isMobile ? 48 : 140,
    height: isMobile ? 48 : 140,
  };

  return (
    <>
      <Box
        width="100%"
        height="100%"
        flex="1"
        display="flex"
        flexDirection="column"
        alignItems="center"
        flexGrow={1}
      >
        <PageWrapper flexGrow={1}>
          <PageLayout>
            <Box mb={1}>
              <SectionTitle color="willfulBlue">
                {translations.title}
              </SectionTitle>
            </Box>
            <H2 component="p" align="center">
              {translations.welcomeIntro}
            </H2>
            <Box mt={1}>
              <Body align="center">{translations.welcomeDescription}</Body>
            </Box>
            <Box mt={3} px={sidesPadding} width="100%">
              <CheckboxIconGrid
                isTabletDown={isTabletDown}
                onChange={handleOnChange}
                defaultValues={reasons}
                isMobile={isMobile}
                optionList={[
                  {
                    label: translations.cards.firstTimeWill,
                    value: FIRST_TIME_WILL,
                    iconComponent: <DocumentPaperIcon {...iconDimensions} />,
                  },
                  {
                    label: translations.cards.marriedOrCommonLaw,
                    value: MARRIED_OR_COMMON_LAW,
                    iconComponent: <RingsIcon {...iconDimensions} />,
                  },
                  {
                    label: translations.cards.expectingOrHavingChild,
                    value: EXPECTING_OR_HAVING_CHILD,
                    iconComponent: <BabyCartIcon {...iconDimensions} />,
                  },
                  {
                    label: translations.cards.canadaNewcomer,
                    value: CANADA_NEWCOMER,
                    iconComponent: <StarIcon {...iconDimensions} />,
                  },
                ]}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt={4.5}
            >
              <TextButton
                className="qa-cancel"
                text={translations.button.cancel}
                onClick={() => updateReasons([])}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt={isMobile ? 0 : 1}
            >
              <Box
                mt={isDesktop ? 2 : 0}
                className={isMobile && confirmButtonStyling.stickyBottomBox}
                width={isMobile ? "100%" : "385px"}
              >
                <PrimaryButton
                  className="qa-continue"
                  fullWidth
                  displayArrowRight
                  text={translations.button.submit}
                  loadingLabel={translations.button.loadingLabel}
                  onClick={() => updateReasons(Array.from(checkboxValues))}
                />
              </Box>
            </Box>
          </PageLayout>
        </PageWrapper>
      </Box>
    </>
  );
};

const WelcomePage = ({
  translations,
  fetchDashboard,
  hasLoaded,
  nextSectionPath,
  updateReasons,
  reasons,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only("lg"));
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!hasLoaded) {
      fetchDashboard();
    }
  }, [fetchDashboard, hasLoaded]);

  if (!hasLoaded)
    return (
      <Box flexGrow="1">
        <LoadingSpinner />
      </Box>
    );

  return (
    <WelcomeSurvey
      translations={translations.survey}
      isDesktop={isDesktop}
      isTabletDown={isTabletDown}
      isMobile={isMobile}
      updateReasons={updateReasons}
      reasons={reasons}
    />
  );
};

export default connect(welcomePageProps, {
  fetchDashboard,
  updateReasons,
})(WelcomePage);
