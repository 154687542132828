import { UPDATE_CHARITIES_TYPE } from "../actions/charities";

const DEFAULT_STATE = [];

const charities = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_CHARITIES_TYPE:
      return payload.charities;
    default:
      return state;
  }
};

export default charities;
