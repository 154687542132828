import React from "react";
import Box from "@material-ui/core/Box";
import { reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid";

import { Form } from "../../ui/Form";
import { SmallBody } from "../../ui/Typography";
import BinaryRadioField from "../../ui/inputs/BinaryRadioField";
import {
  MARITAL_STATUS_FIELD_ID,
  MARITAL_STATUS_FORM_ID,
} from "../../../constants/forms";
import { useValidateMaritalStatusMemo } from "../../ui/inputs/validations";

const MaritalRegimeInfo = ({ translations }) => {
  return (
    <Grid container justify="center" spacing={2}>
      <Grid xs={12} md={8} lg={6}>
        <Box mt={1.5} textAlign="center">
          <SmallBody align="center">{translations.maritalRegimeInfo}</SmallBody>
        </Box>
      </Grid>
    </Grid>
  );
};

const MaritalStatusForm = ({
  handleSubmit,
  backLink,
  translations,
  isLoading,
  isQuebecSelected,
}) => {
  return (
    <Form
      onSubmit={handleSubmit}
      backLink={backLink}
      HeaderComponent={isQuebecSelected && MaritalRegimeInfo}
      translations={translations}
      isLoading={isLoading}
    >
      <BinaryRadioField
        name={MARITAL_STATUS_FIELD_ID}
        validate={useValidateMaritalStatusMemo(translations)}
        translations={translations}
      />
    </Form>
  );
};

export default reduxForm({
  form: MARITAL_STATUS_FORM_ID,
  destroyOnUnmount: false,
})(MaritalStatusForm);
