import React from "react";

import SvgIcon from "./SvgIcon";

const DocumentsMenuNavIcon = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 12C23 18.075 18.075 23 12 23C5.92499 23 1 18.075 1 12C1 5.92499 5.92499 1 12 1C18.075 1 23 5.92499 23 12ZM12 21C16.9704 21 21 16.9704 21 12C21 7.02956 16.9704 3 12 3C7.02956 3 3 7.02956 3 12C3 16.9704 7.02956 21 12 21ZM12 17.9597L6.74744 12.7071L8.16165 11.2929L11 14.1312V6.54545H13V14.1312L15.8383 11.2929L17.2526 12.7071L12 17.9597Z"
        fill="#0064DC"
      />
    </SvgIcon>
  );
};

export default DocumentsMenuNavIcon;
