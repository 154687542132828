import React from "react";
import styled from "styled-components";
import BounceLoader from "react-spinners/BounceLoader";

const LoaderContainer = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = () => (
  <LoaderContainer className="mt-3">
    <BounceLoader color={"#0064dc"} />
  </LoaderContainer>
);

export default LoadingSpinner;
