import React from "react";

const SelectAPlanIcon = ({ width = 156, height = 136 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 156 136"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78 135.748c43.078 0 78-23.147 78-51.7 0-28.554-34.922-51.702-78-51.702S0 55.494 0 84.047c0 28.554 34.922 51.701 78 51.701Z"
      fill="#ECEDEF"
    />
    <path
      d="M58.885 37.522 93.808 57.7 60.823 77.075 43.385 67.009 25.9 56.897l32.985-19.375Z"
      fill="#2F455F"
    />
    <path
      d="m60.775 77.075 1.937-1.087.19-55.1-1.938 1.086-.19 55.1Z"
      fill="#70707A"
    />
    <path
      d="m60.963 21.974 1.938-1.087L26.75 0l-1.938 1.134 36.151 20.84Z"
      fill="#ECEDEF"
    />
    <path
      d="m24.813 1.134-.142 55.101 36.104 20.84.19-55.1-36.152-20.84Z"
      fill="#CACDD4"
    />
    <path
      d="m65.756 67.405 34.923 20.178-32.985 19.375-17.438-10.065L32.772 86.78l32.984-19.375Z"
      fill="#2F455F"
    />
    <path
      d="m67.646 106.958 1.938-1.087.189-55.1-1.938 1.087-.189 55.1Z"
      fill="#71ADEE"
    />
    <path
      d="m67.834 51.857 1.938-1.087L33.62 29.883l-1.938 1.134 36.151 20.84Z"
      fill="#AAD2F6"
    />
    <path
      d="m31.684 31.018-.142 55.1 36.104 20.84.19-55.1-36.152-20.84Z"
      fill="#0064DC"
    />
    <path
      d="m109.934 67.405 34.922 20.178-32.985 19.375-17.438-10.065L76.95 86.78l32.985-19.375Z"
      fill="#2F455F"
    />
    <path
      d="m111.823 106.958 1.938-1.087.189-55.1-1.938 1.087-.189 55.1Z"
      fill="#4A4A4A"
    />
    <path
      d="m112.011 51.857 1.938-1.087-36.151-20.887-1.938 1.134 36.151 20.84Z"
      fill="#CACDD4"
    />
    <path
      d="m75.862 31.018-.142 55.1 36.103 20.84.189-55.1-36.15-20.84Z"
      fill="#70707A"
    />
    <path
      d="m75.921 63.915-.444 22.237 14.231 8.005L87.93 72.81l-12.008-8.895Z"
      fill="#2F455F"
    />
  </svg>
);

export default SelectAPlanIcon;
