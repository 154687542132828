import { takeEvery, call, put } from "redux-saga/effects";
import { initialize } from "redux-form";

import {
  FETCH_LIFE_PROLONG_TYPE,
  UPDATE_LIFE_PROLONG_TYPE,
  FETCH_PAIN_MANAGE_TYPE,
  UPDATE_PAIN_MANAGE_TYPE,
} from "../actions/healthcare";

import { submitFormRequest } from "./forms";
import { fetchApiData } from "./requests";
import { fetchSuccess } from "../actions/requests";
import { getHealthcare, submitHealthcare } from "../api/healthcare";

import { LIFE_PROLONG_FORM_ID, PAIN_MANAGE_FORM_ID } from "../constants/forms";

function* fetchLifeProlong() {
  const { healthcare } = yield call(fetchApiData, {
    apiCall: getHealthcare,
    formId: LIFE_PROLONG_FORM_ID,
  });
  const { lifeProlong } = healthcare;

  yield put(initialize(LIFE_PROLONG_FORM_ID, { lifeProlong }));
  yield put(fetchSuccess(LIFE_PROLONG_FORM_ID));
}

function* updateLifeProlong() {
  yield call(submitFormRequest, {
    apiCall: submitHealthcare,
    formId: LIFE_PROLONG_FORM_ID,
  });
}

function* fetchPainManage() {
  const { healthcare } = yield call(fetchApiData, {
    apiCall: getHealthcare,
    formId: PAIN_MANAGE_FORM_ID,
  });
  const { painManage } = healthcare;

  yield put(initialize(PAIN_MANAGE_FORM_ID, { painManage }));
  yield put(fetchSuccess(PAIN_MANAGE_FORM_ID));
}

function* updatePainManage() {
  yield call(submitFormRequest, {
    apiCall: submitHealthcare,
    formId: PAIN_MANAGE_FORM_ID,
  });
}

export function* watchFetchLifeProlong() {
  yield takeEvery(FETCH_LIFE_PROLONG_TYPE, fetchLifeProlong);
}
export function* watchUpdateLifeProlong() {
  yield takeEvery(UPDATE_LIFE_PROLONG_TYPE, updateLifeProlong);
}
export function* watchFetchPainManage() {
  yield takeEvery(FETCH_PAIN_MANAGE_TYPE, fetchPainManage);
}
export function* watchUpdatePainManage() {
  yield takeEvery(UPDATE_PAIN_MANAGE_TYPE, updatePainManage);
}
