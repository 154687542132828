import dayjs from "dayjs";

export const required = (fieldName, message) => (value) =>
  value || typeof value === "number" || typeof value === "boolean"
    ? undefined
    : message.replace("FIELD_NAME", fieldName);

export const isChecked = (fieldName, message) => (checked) =>
  checked ? undefined : message.replace("FIELD_NAME", fieldName);

export const notEmptyArray = (message) => (value) =>
  Array.isArray(value) && value.length > 0 ? undefined : message;

export const maxLength = (max, message) => (value) =>
  value && value.length > max ? message.replace("MAX", max) : undefined;

export const minLength = (min, message) => (value) =>
  value && value.length < min ? message.replace("MIN", min) : undefined;

export const maxValue = (max, message) => (value) =>
  value && value > max ? message : undefined;

export const minValue = (min, message) => (value) =>
  value && value < min ? message : undefined;

export const maxDecimals = (max, message) => (value) => {
  const decimalCount = getDecimalCountFromNumber(value);
  if (decimalCount && decimalCount > max) {
    const errorExample = Array.from({ length: max }, () => "5").join("");
    const errorMessage = message
      .replace("DECIMAL_COUNT", max)
      .replace("DECIMAL_EXAMPLE", errorExample);
    return errorMessage;
  }
  return undefined;
};
const getDecimalCountFromNumber = (value) => {
  if (!value) {
    return undefined;
  }
  const valueStr = value.toString();
  const decimalPointIndex = valueStr.indexOf(".");
  if (decimalPointIndex < 0) {
    return undefined;
  }
  const decimalCount = valueStr.substring(decimalPointIndex + 1).length;
  return decimalCount;
};

export const isInteger = (message) => (value) =>
  value && !Number.isInteger(Number(value)) ? message : undefined;

export const exactLength = (length, message) => (value) =>
  value && value.length !== length ? message : undefined;

export const email = (message) => (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? message
    : undefined;

export const isAgeOfMajority = (age, province, message) => (value) => {
  const todaysDate = dayjs(new Date());
  const userDate = dayjs(value);
  const diff = todaysDate.diff(userDate, "y");
  return value && diff < age
    ? message.replace("PROVINCE", province)
    : undefined;
};

export const isDateInFuture = (message) => (value) => {
  const date = dayjs(value);
  const isFuture = date.isAfter(dayjs(new Date()));
  return isFuture ? message : undefined;
};

export const passwordsMustMatch = (message) => (value, allValues) =>
  value !== allValues.password ? message : undefined;

export const emailsMustMatch = (message) => (value, allValues) =>
  value !== allValues.email ? message : undefined;

export const canadianPostalCode = (message) => (value) =>
  value &&
  !/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(
    value,
  )
    ? message
    : undefined;

// Allow special (letter) characters like "Ä" or "é" as well as spaces, dash and fullstop.
export const nameCapitalization = (message) => (value) =>
  value && !/^([\u0041-\u005A\u00C0-\u00DF])/.test(value) ? message : undefined;
