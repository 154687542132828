/*
 * Structure that redux-form expects for errors:
{
  fieldName: 'error message',
  fieldArrayName: [ // nested FieldArray
    {
      nestedFieldName: "error message",
    },
    {
      _error: 'nested form level error'
    }
  ],
  _error: 'form level error',
};
*/

export const transformErrorData = (errors, formValues, errorTranslations) => {
  if (!errors) return {};
  const errorTemplate = clearNestedValues(formValues);

  return errors.reduce((allErrors, currentError) => {
    const { field, type, detail, code, index = null } = currentError;

    // TODO: eliminate need for detail in favor of using code
    const errorMessage =
      errorTranslations[code] || errorTranslations[detail] || detail;

    // nested FieldArray
    if (index !== null) {
      const nestedFormErrorList = allErrors[type];
      const nestedFormError = nestedFormErrorList[index];

      // if an error for field already exists
      if (nestedFormError[field]) {
        return allErrors;
      }

      const updatedNestedFormError = {
        ...nestedFormError,
        [field]: errorMessage,
      };
      nestedFormErrorList.splice(index, 1, updatedNestedFormError);
      return {
        ...allErrors,
        [type]: nestedFormErrorList,
      };
    }

    // root level field
    return {
      ...allErrors,
      [field]: errorMessage,
    };
  }, errorTemplate);
};

/* return structure of
 {
   fieldName: "",
   nestedFieldArray: [
     {}, ..., {}
   ]
 }
*/
const clearNestedValues = (formValues) =>
  Object.keys(formValues).reduce((allErrors, currentFieldName) => {
    const fieldValue = formValues[currentFieldName];
    if (Array.isArray(fieldValue)) {
      const fieldArray = fieldValue.map(() => ({}));
      return {
        ...allErrors,
        [currentFieldName]: fieldArray,
      };
    }
    return {
      ...allErrors,
      [currentFieldName]: "",
    };
  }, {});
