import React from "react";
import Box from "@material-ui/core/Box";

import { SmallBody } from "./Typography";

const YourStatusLabel = ({ status, label, translations }) => {
  const textColor = "willfulBlue.main";
  const backgroundColor = "secondary.main";

  return (
    <Box
      py={0.25}
      px={1}
      borderRadius="4px"
      width="fit-content"
      color={textColor}
      bgcolor={backgroundColor}
    >
      <SmallBody data-testid="status-label">
        {label || translations.statusLabel[status]}
      </SmallBody>
    </Box>
  );
};

export default YourStatusLabel;
