import React from "react";
import styled from "styled-components";

import Box from "@material-ui/core/Box";

const Bar = styled(Box)`
  transition: all 0.3s ease-in;
`;

const ProgressBar = ({ progress, height = 10 }) => (
  <Box bgcolor={"gray.main"} position="relative" height={height} width={"100%"}>
    <Bar
      position="absolute"
      bgcolor={"yellow.main"}
      left={0}
      top={0}
      height={height}
      width={`${progress}%`}
    />
  </Box>
);

export default ProgressBar;
