import React from "react";
import { reduxForm, Field } from "redux-form";
import { Box } from "@material-ui/core";
import { PrimaryButton } from "../../ui/Button";
import { ErrorMessage } from "../../ui/Error";
import {
  FIRST_NAME_FIELD_ID,
  PET_MODAL_FORM_ID,
  SPECIES_FIELD_ID,
} from "../../../constants/forms";
import {
  useValidatePetNameMemo,
  useValidatePetSpeciesMemo,
} from "../../ui/inputs/validations";

import RadioListField from "../../ui/inputs/RadioListField";
import TextInput from "../../ui/inputs/TextInput";

const PetModal = ({
  error,
  invalid,
  handleSubmit,
  isLoading,
  translations,
  submitting,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Box mt={1}>
        <Field
          name={FIRST_NAME_FIELD_ID}
          component={TextInput}
          validate={useValidatePetNameMemo(translations)}
          label={translations.petNameLabel}
        />
        <Box mt={2}>
          <RadioListField
            name={SPECIES_FIELD_ID}
            translations={translations}
            validate={useValidatePetSpeciesMemo(translations)}
            optionList={translations.speciesOptions}
            label={translations.speciesLabel}
          />
        </Box>
        <PrimaryButton
          fullWidth
          isLoading={isLoading}
          text={translations.button.submit}
          opacity={invalid ? 0.5 : 1}
          isDisabled={submitting}
        />
        <ErrorMessage error={error} />
      </Box>
    </form>
  );
};

export default reduxForm({
  form: PET_MODAL_FORM_ID,
})(PetModal);
