import React from "react";
import { connect } from "react-redux";

import { closeEmailPreviewModal } from "../actions/email-preview-modal";
import { analyticsClickExternalLink } from "../actions/analytics";
import { modalProps } from "../selectors/email-preview-modal";
import { GenericEmailPreviewModal } from "../components/ui/modals/Modal";
import EmailPreview from "../components/ui/EmailPreview";

export const EmailPreviewModal = ({
  modalKey,
  isVisible,
  closeEmailPreviewModal,
  analyticsClickExternalLink,
  translations,
  ...restOfProps
}) => {
  if (!isVisible) return null;

  return (
    <GenericEmailPreviewModal
      onClose={closeEmailPreviewModal}
      title={translations.title}
      {...restOfProps}
    >
      <EmailPreview
        translations={translations}
        analyticsClickExternalLink={analyticsClickExternalLink}
      />
    </GenericEmailPreviewModal>
  );
};

export default connect(modalProps, {
  closeEmailPreviewModal,
  analyticsClickExternalLink,
})(EmailPreviewModal);
