import React, { useState } from "react";
import { reduxForm, Field } from "redux-form";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import {
  CHECKOUT_FORM_ID,
  CHECKOUT_CARD_NAME_FIELD_ID,
} from "../../../constants/forms";
import { ErrorMessage } from "../../ui/Error";
import { H3 } from "../../ui/Typography";
import LockIcon from "../../ui/icons/LockIcon";
import { useValidateCardHolderMemo } from "../../ui/inputs/validations";
import TextInput, { displayColor, fontColor } from "../../ui/inputs/TextInput";
import PaymentMethodIcon from "../../ui/icons/PaymentMethodIcon";
import { ColoredButton } from "../../ui/Button";

const useStyles = makeStyles((boxTheme) => ({
  stickyBottomBox: {
    [boxTheme.breakpoints.down("md")]: {
      // Styles for mobile and tablet
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      marginTop: 0,
      zIndex: 1000, // Ensure it's above other content
    },
  },
}));

// Override CSS into stripe's iframe
const StripeWrapper = styled.div`
  .StripeElement {
    box-sizing: border-box;
    background-color: white;

    margin-top: 8px;
    padding: 16px;
    border: 1px solid ${displayColor("#cacdd4")};
    color: ${fontColor("#4a4a4a", "#74747e")};
    font-weight: "normal";
    line-height: 1.5;
    letter-spacing: 0.1px;

    &:hover {
      border: 1px solid ${displayColor("#0064dc")};
    }

    &:focus {
      font-weight: 700;
      color: ${displayColor("#4a4a4a")};
      border: 2px solid ${displayColor("#0064dc")};
      outline: none;
    }
  }
`;

const stripeDesktopStyling = {
  base: {
    fontFamily: "system-ui",
    fontSize: "20px",
  },
};
const stripeMobileTabletStyling = {
  base: {
    fontFamily: "system-ui",
    fontSize: "16px",
  },
};

const CheckoutFormForFreePlan = ({
  error,
  translations,
  isLoading,
  submitPartnerDiscountCheckout,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const confirmButtonStyling = useStyles(theme);
  const stylingProps = {
    display: "flex",
    border: "1px solid #CACDD4",
    bgcolor: "white",
    borderRadius: 4,
    p: 2,
  };
  const desktopStylingProps = isDesktop ? stylingProps : {};
  return (
    <>
      <Box id="checkout-form-container" {...desktopStylingProps}>
        <Box
          display="flex"
          flexDirection="column"
          width={1}
          className={confirmButtonStyling.stickyBottomBox}
        >
          {/* Checkout button */}
          <ColoredButton
            fullWidth
            margin="0px"
            onClick={submitPartnerDiscountCheckout}
            text={translations.button.submit}
            loadingLabel={translations.stripeIsLoading}
            isLoading={isLoading}
            className="qa-submit qa-continue"
          />
          {error && <ErrorMessage error={error} />}
        </Box>
      </Box>
    </>
  );
};

const CheckoutFormForNormalPLans = ({
  error: formError,
  submitCheckoutForm,
  translations,
  isLoading,
}) => {
  const [cardNumberError, setCardNumberError] = useState(null);
  const [cardExpiryError, setCardExpiryError] = useState(null);
  const [cardCVCError, setCardCVCError] = useState(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const confirmButtonStyling = useStyles(theme);

  const handleChangeFor = (type) => ({ error }) => {
    switch (type) {
      case "cardNumber":
        if (error) {
          return setCardNumberError(error.message);
        }
        return setCardNumberError(null);
      case "cardExpiry":
        if (error) {
          return setCardExpiryError(error.message);
        }
        return setCardExpiryError(null);
      case "cardCVC":
        if (error) {
          return setCardCVCError(error.message);
        }
        return setCardCVCError(null);
      default:
        return null;
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    submitCheckoutForm();
  };

  const stripeElementOptions = {
    style: isDesktop ? stripeDesktopStyling : stripeMobileTabletStyling,
  };

  return (
    <Box
      id="checkout-form-container"
      border="1px solid #CACDD4"
      bgcolor="white"
      borderRadius={4}
      display="flex"
      p={2}
    >
      <Box display="flex" flexDirection="column" width={1}>
        <form onSubmit={handleSubmit}>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <Box display="inline-flex" mb={isMobile && 1}>
              <H3 align="left" tabIndex={-1}>
                <Box display="inline-flex" mr={0.5}>
                  <LockIcon />
                </Box>
                {translations.securePaymentLabel}
              </H3>
            </Box>
            <Box ml="auto">
              <Box display="inline-flex" width={160}>
                <PaymentMethodIcon brand="visa" />
                <PaymentMethodIcon brand="mastercard" />
                <PaymentMethodIcon brand="amex" />
                <PaymentMethodIcon brand="jcb" />
              </Box>
            </Box>
          </Box>

          {/* Card Number */}
          <Box mt={1} display="flex">
            <Grid container item>
              <Grid item xs={12}>
                <StripeWrapper>
                  <CardNumberElement
                    options={{
                      ...stripeElementOptions,
                      placeholder: translations.cardNumberPlaceholder,
                    }}
                    onChange={handleChangeFor("cardNumber")}
                  />
                  {cardNumberError && <ErrorMessage error={cardNumberError} />}
                </StripeWrapper>
              </Grid>
            </Grid>
          </Box>

          {/* Date and CVC */}
          <Box mt={1} display="flex">
            <Grid container spacing={1}>
              <Grid item md={6} sm={12} xs={12}>
                <StripeWrapper>
                  <CardExpiryElement
                    options={{
                      ...stripeElementOptions,
                      placeholder: translations.expiryDatePlaceholder,
                    }}
                    onChange={handleChangeFor("cardExpiry")}
                  />
                  {cardExpiryError && <ErrorMessage error={cardExpiryError} />}
                </StripeWrapper>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <StripeWrapper>
                  <CardCvcElement
                    options={{
                      ...stripeElementOptions,
                    }}
                    onChange={handleChangeFor("cardCVC")}
                  />
                  {cardCVCError && <ErrorMessage error={cardCVCError} />}
                </StripeWrapper>
              </Grid>
            </Grid>
          </Box>

          {/* Name on Card */}
          <Box mt={1}>
            <Field
              name={CHECKOUT_CARD_NAME_FIELD_ID}
              placeholder={translations.cardNamePlaceholder}
              component={TextInput}
              validate={useValidateCardHolderMemo(translations)}
              noMargin={!isDesktop}
            />
          </Box>

          {/* Checkout button */}
          <Box
            mt={isDesktop ? 2 : 0}
            className={confirmButtonStyling.stickyBottomBox}
          >
            <ColoredButton
              fullWidth
              text={translations.button.submit}
              loadingLabel={translations.stripeIsLoading}
              isLoading={isLoading}
              className="qa-submit qa-continue"
              onClick={handleSubmit}
            />
          </Box>
        </form>
        {formError && <ErrorMessage error={formError} />}
      </Box>
    </Box>
  );
};

const CheckoutForm = ({
  error,
  onSubmit,
  translations,
  isLoading,
  shouldDisplayPartnerDiscountConfirmCheckoutButton,
  submitPartnerDiscountCheckout,
}) => {
  if (shouldDisplayPartnerDiscountConfirmCheckoutButton) {
    return (
      <CheckoutFormForFreePlan
        error={error}
        translations={translations}
        isLoading={isLoading}
        submitPartnerDiscountCheckout={submitPartnerDiscountCheckout}
      />
    );
  }

  return (
    <CheckoutFormForNormalPLans
      error={error}
      submitCheckoutForm={onSubmit}
      translations={translations}
      isLoading={isLoading}
    />
  );
};

export default reduxForm({ form: CHECKOUT_FORM_ID })(CheckoutForm);
