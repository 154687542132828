import React from "react";
import { colorPalette } from "../../../config/Theme";

const renderStepContent = (rawStep) => {
  const step = rawStep.toString(); // Coerce step into string if it comes in as a number
  let content;

  switch (step) {
    case "1":
      content = (
        <path
          d="M15.4 21h2.4V9.8h-2s-.56 1.28-2.32 1.52v1.52h1.92V21Z"
          fill={colorPalette.white}
        />
      );
      break;
    case "2":
      content = (
        <path
          d="M14.6 18.76c1.28-2 4.8-2.48 4.8-5.68 0-2-1.52-3.52-3.92-3.52-3.12 0-4 2.64-4 2.64l1.92.96s.56-1.36 1.92-1.36c.96 0 1.6.64 1.6 1.44 0 2.48-5.44 2.48-5.44 7.76h7.92v-2.24h-4.8Z"
          fill={colorPalette.white}
        />
      );
      break;
    case "3":
      content = (
        <path
          d="M12.16 19.48s1.44 1.76 4.24 1.76c2.56 0 4.16-1.6 4.16-3.36 0-2.16-2.16-2.8-2.16-2.8s1.76-.56 1.76-2.4c0-1.6-1.52-3.12-3.76-3.12-2.56 0-3.84 1.68-3.84 1.68l1.6 1.6s.8-1.12 2-1.12c.96 0 1.52.56 1.52 1.28s-.56 1.28-1.52 1.28h-1.12v2.08h1.28c1.12 0 1.76.64 1.76 1.36 0 .72-.64 1.36-1.92 1.36-1.44 0-2.4-1.2-2.4-1.2l-1.6 1.6Z"
          fill={colorPalette.white}
        />
      );
      break;
    case "4":
      content = (
        <path
          d="M11.24 19h5.28v2h2.4v-2h1.28v-2.24h-1.28V9.8h-2.8l-4.88 6.64V19Zm2.48-2.24 2.8-3.84v3.84h-2.8Z"
          fill={colorPalette.white}
        />
      );
      break;
    case "5":
      content = (
        <path
          d="M11.56 19.48s1.44 1.76 4.24 1.76c2.56 0 4.24-1.68 4.24-3.84s-1.6-3.76-3.92-3.76c-.96 0-1.6.4-1.6.4l.08-2h4.72V9.8h-6.8l-.4 6.56 1.68.24s.64-.88 1.84-.88c1.2 0 1.92.72 1.92 1.68s-.72 1.68-2 1.68c-1.44 0-2.4-1.2-2.4-1.2l-1.6 1.6Z"
          fill={colorPalette.white}
        />
      );
      break;
    case "6":
      content = (
        <path
          d="M15.8 10.6h2.3l-2.2 3.5s.2-.1.5-.1c1.9 0 3.5 1.7 3.5 3.7s-1.7 3.7-4 3.7-4-1.6-4-3.7c0-1.4.9-2.5.9-2.5l3-4.6zm1.8 7.1c0-1-.7-1.6-1.6-1.6s-1.6.7-1.6 1.6c0 1 .7 1.7 1.6 1.7s1.6-.7 1.6-1.7z"
          fill={colorPalette.white}
        />
      );
      break;
    case "7":
      content = (
        <path
          d="M17.2 13h-4.8v-2.1h7.2v2l-4.1 8.5h-2.3l4-8.4z"
          fill={colorPalette.white}
        />
      );
      break;
    case "complete":
      content = (
        <path
          d="M14.2 21.5c-.4 0-.7-.1-1-.4L9 17.3c-.6-.6-.7-1.5-.1-2.1.6-.6 1.5-.7 2.1-.1l3.2 2.9 7.3-7.3c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-8.3 8.3c-.3.3-.7.4-1.1.4z"
          fill={colorPalette.white}
        />
      );
      break;
    default:
      content = null;
      break;
  }

  return content;
};

const OverviewStepIcon = ({
  step,
  isActive,
  width = "24px",
  height = "24px",
}) => {
  const circleFillColor = isActive
    ? colorPalette.willfulBlue
    : colorPalette.willfulGrey1;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ enableBackground: "new 0 0 32 32" }}
      xmlSpace="preserve"
      viewBox="0 0 32 32"
      width={width}
      height={height}
      aria-hidden="true"
    >
      <circle cx="16" cy="16" r="16" style={{ fill: circleFillColor }} />
      {renderStepContent(step)}
    </svg>
  );
};

export default OverviewStepIcon;
