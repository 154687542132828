import { takeEvery, call, put } from "redux-saga/effects";
import { getCheckout } from "../api/checkout";
import { fetchApiData } from "./requests";
import { FETCH_CART_TYPE, updateCart } from "../actions/cart";

function* fetchCart() {
  const { order } = yield call(fetchApiData, {
    apiCall: getCheckout,
    formId: "cart",
  });

  yield put(updateCart(order));
}

export function* watchFetchCart() {
  yield takeEvery(FETCH_CART_TYPE, fetchCart);
}
