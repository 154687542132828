import { createSelector, createStructuredSelector } from "reselect";

import { selectTranslations } from "./translations";
import { selectHasLoadedDataById } from "./requests";
import { selectNextPathAfterPlansGuidancePages } from "./navigation";
import {
  selectPremiumPrice,
  selectPremiumStrikeThroughPrice,
  selectShowExclusiveOffer,
} from "./plans";
import { RECOMMENDATION_FORM_ID } from "../constants/forms";
import { selectIsFrenchSelected } from "./language";

const premiumPlanGuidancePageTranslations = selectTranslations([
  "global",
  "plansCards",
  "premiumAndEssentialsPlanGuidancePage",
]);

const selectPremiumPlanGuidancePageTranslations = createSelector(
  premiumPlanGuidancePageTranslations,
  (baseTranslations) => {
    return {
      ...baseTranslations,
    };
  },
);

export const premiumPlanGuidanceProps = createStructuredSelector({
  translations: selectPremiumPlanGuidancePageTranslations,
  hasLoaded: selectHasLoadedDataById(RECOMMENDATION_FORM_ID),
  price: selectPremiumPrice,
  strikeThroughPrice: selectPremiumStrikeThroughPrice,
  nextSectionPath: selectNextPathAfterPlansGuidancePages,
  isFrenchSelected: selectIsFrenchSelected,
  showExclusiveOffer: selectShowExclusiveOffer,
});
