export const fetchExecutors = () => ({
  type: FETCH_EXECUTORS_TYPE,
});

export const submitSpouseExecutorsForm = () => ({
  type: SUBMIT_SPOUSE_EXECUTORS_FORM_TYPE,
});

export const openModalAddExecutors = () => ({
  type: OPEN_MODAL_ADD_EXECUTORS_TYPE,
  payload: { executor: null },
});

export const openModalEditExecutors = (executor) => ({
  type: OPEN_MODAL_EDIT_EXECUTORS_TYPE,
  payload: { executor },
});

export const removeExecutors = (executor) => ({
  type: REMOVE_EXECUTORS_TYPE,
  payload: { executor },
});

export const executorAddedSuccessfully = (executor) => ({
  type: ADD_EXECUTOR_TYPE,
  payload: { executor },
});

export const executorsNextPage = () => ({
  type: EXECUTORS_NEXT_PAGE_TYPE,
});

export const FETCH_EXECUTORS_TYPE = "FETCH_EXECUTORS";
export const SUBMIT_SPOUSE_EXECUTORS_FORM_TYPE = "SUBMIT_SPOUSE_EXECUTORS_FORM";
export const OPEN_MODAL_ADD_EXECUTORS_TYPE = "OPEN_MODAL_ADD_EXECUTORS";
export const OPEN_MODAL_EDIT_EXECUTORS_TYPE = "OPEN_MODAL_EDIT_EXECUTORS";
export const REMOVE_EXECUTORS_TYPE = "REMOVE_EXECUTORS";
export const ADD_EXECUTOR_TYPE = "ADD_EXECUTOR";
export const EXECUTORS_NEXT_PAGE_TYPE = "EXECUTORS_NEXT_PAGE";
