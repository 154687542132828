import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import styled from "styled-components";

import { LOGIN_PATH } from "../../constants/routes";
import { Body } from "../ui/Typography";
import NavButtonLink from "./NavButtonLink";
import CartWidget from "../ui/CartWidget";
import CloseWhiteIcon from "../ui/icons/CloseWhiteIcon";
import { MenuIcon } from "../ui/icons/MenuIcon";

const NavButton = styled.button`
  border: 0;
  background: #0064dc;
  outline: none;
  cursor: pointer;
  padding-right: 0;
`;

const MobileNav = ({
  isAuthenticated,
  isMobileNavOpen,
  setIsMobileNavOpen,
  cartProps,
  translations,
  analyticsOpenedCartWidget,
}) => {
  useEffect(() => {
    document.body.style.overflow = isMobileNavOpen ? "hidden" : "initial";
  }, [isMobileNavOpen]);

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      component="nav"
    >
      <Box ml="auto">
        {isAuthenticated ? (
          <Box display="flex">
            {cartProps.itemCount > 0 && (
              <CartWidget
                analyticsOpenedCartWidget={analyticsOpenedCartWidget}
                {...cartProps}
              />
            )}
            <NavButton
              type="button"
              aria-label={
                isMobileNavOpen ? translations.close : translations.menu
              }
              onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
            >
              <Body component="span" color="white">
                {isMobileNavOpen ? translations.close : translations.menu}
              </Body>
            </NavButton>
          </Box>
        ) : (
          <NavButton
            type="button"
            aria-label={
              isMobileNavOpen ? translations.close : translations.menu
            }
            onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
          >
            <Body component="span" color="white">
              {isMobileNavOpen ? <CloseWhiteIcon /> : <MenuIcon />}
            </Body>
          </NavButton>
        )}
      </Box>
    </Box>
  );
};

export default MobileNav;
