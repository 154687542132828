import React from "react";

import SvgIcon from "./SvgIcon";

export const MenuIcon = ({ fill = "white" }) => {
  return (
    <SvgIcon width="24px" height="24px" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5L20 5V7L4 7V5ZM20 11L4 11L4 13L20 13V11ZM20 17L4 17V19L20 19V17Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
