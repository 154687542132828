import React from "react";
import { Field } from "redux-form";
import RadioSelectorList from "./RadioSelectorList";

const RadioListField = ({
  name,
  label,
  translations,
  validate,
  optionList,
  suppress = false,
}) => (
  <Field
    component={RadioSelectorList}
    name={name}
    label={label}
    optionList={optionList || translations.optionList}
    validate={validate}
    suppress={suppress}
  />
);

export default RadioListField;
