import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";

import { spouseEstateForms } from "../constants/navigation";
import {
  selectIsSectionComplete,
  selectSectionCurrentPageNumber,
  selectSectionPercentComplete,
  selectSectionCurrentPath,
  selectSectionTranslations,
  translateInput,
} from "./utils/section-summaries";
import { selectIsAboutYouComplete } from "./about-you";
import { selectIsYourFamilyComplete } from "./your-family";
import { selectIsSpouseGiftsComplete } from "./gifts";
import { selectIsSpouseDonationsComplete } from "./donations";
import { selectTranslations } from "./translations";

import { DONATIONS_FORM_ID, GIFTS_FORM_ID } from "../constants/forms";
import { OVERVIEW_SPOUSE_ESTATE_PATH } from "../constants/routes";

const SPOUSE_ESTATE_NUMBER_OF_PAGES = 2;
const selectSpouseEstateData = R.pathOr({}, ["spouseEstate"]);

const selectSpouseEstateFieldsComplete = createSelector(
  selectIsSpouseGiftsComplete,
  selectIsSpouseDonationsComplete,
  (...isCompleteValues) => isCompleteValues,
);

const selectSpouseEstateIsCompleteMap = createSelector(
  selectIsSpouseGiftsComplete,
  selectIsSpouseDonationsComplete,
  (isSpouseGiftsComplete, isSpouseDonationsComplete) => ({
    [GIFTS_FORM_ID]: isSpouseGiftsComplete,
    [DONATIONS_FORM_ID]: isSpouseDonationsComplete,
  }),
);

export const selectIsSpouseEstateComplete = selectIsSectionComplete(
  selectSpouseEstateFieldsComplete,
);

export const selectSpouseEstateCurrentPageNumber = selectSectionCurrentPageNumber(
  selectSpouseEstateFieldsComplete,
);

export const selectSpouseEstateTotalPages = () => SPOUSE_ESTATE_NUMBER_OF_PAGES;

export const selectSpouseEstatePercentComplete = selectSectionPercentComplete(
  selectSpouseEstateCurrentPageNumber,
  () => SPOUSE_ESTATE_NUMBER_OF_PAGES,
);

export const selectSpouseEstateNextForm = R.path([
  "navigation",
  "nextForm",
  "spouseEstate",
]);

export const selectSpouseEstateCurrentPath = selectSectionCurrentPath(
  selectSpouseEstateNextForm,
);

const selectIsSpouseEstateDisabled = createSelector(
  selectIsAboutYouComplete,
  selectIsYourFamilyComplete,
  (isAboutYouComplete, isYourFamilyComplete) =>
    !(isAboutYouComplete && isYourFamilyComplete),
);

export const selectSpouseEstateFormSummaries = createSelector(
  selectSpouseEstateData,
  selectSpouseEstateIsCompleteMap,
  selectTranslations(["global", "navigation", "yourEstate", "spouseEstate"]),
  (spouseEstateData, isCompleteMap, translations) => {
    const formSummaries = spouseEstateForms.reduce(
      (summaryList, currentForm) => {
        const fields = currentForm.fields.map(({ key }) => ({
          label: translations.summaryLabel[key],
          value: translateInput(key, spouseEstateData, translations),
        }));

        const formSummary = {
          path: currentForm.path,
          isComplete: isCompleteMap[currentForm.id],
          fields,
        };

        return [...summaryList, formSummary];
      },
      [],
    );

    return formSummaries.filter((form) => form.isComplete);
  },
);

export const selectSpouseEstateTranslations = selectSectionTranslations(
  selectIsSpouseEstateComplete,
  selectSpouseEstateCurrentPageNumber,
  ["global", "spouseEstate"],
  () => SPOUSE_ESTATE_NUMBER_OF_PAGES,
  selectIsSpouseEstateDisabled,
);

export const selectSpouseEstateStatus = createStructuredSelector({
  sectionKey: () => "spouseEstate",
  isComplete: selectIsSpouseEstateComplete,
  progress: selectSpouseEstatePercentComplete,
  continuePath: () => OVERVIEW_SPOUSE_ESTATE_PATH,
  formSummaries: selectSpouseEstateFormSummaries,
  disabled: selectIsSpouseEstateDisabled,
  translations: selectSpouseEstateTranslations,
  sectionLastPage: () => "/spouse-donations",
});
