import React, { useEffect } from "react";
import { connect } from "react-redux";

import { submitPeopleQuantityIntent } from "../../../actions/about-you";
import { intendedUsePageProps } from "../../../selectors/about-you";
import IntendedUseForm from "../../../components/forms/about-you/IntendedUseForm";
import { DASHBOARD_PATH } from "../../../constants/routes";
import { getFormData } from "../../../actions/forms";
import { PEOPLE_QUANTITY_INTENT_FIELD_ID } from "../../../constants/forms";

const IntendedUsePage = ({
  getFormData,
  submitPeopleQuantityIntent,
  ...rest
}) => {
  useEffect(() => {
    getFormData(PEOPLE_QUANTITY_INTENT_FIELD_ID);
  }, [getFormData]);

  return (
    <IntendedUseForm
      backLink={DASHBOARD_PATH}
      onSubmit={() => submitPeopleQuantityIntent()}
      {...rest}
    />
  );
};

export default connect(intendedUsePageProps, {
  getFormData,
  submitPeopleQuantityIntent,
})(IntendedUsePage);
