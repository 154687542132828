import R from "ramda";
import { createSelector, createStructuredSelector } from "reselect";
import { getFormValues } from "redux-form";

import { selectTranslations } from "./translations";
import { selectHasLoadedDataById } from "./requests";
import { selectFirstName } from "./about-you";
import {
  ASSET_LIST_FORM_ID,
  ASSET_TYPE_FIELD_ID,
  FINANCIAL_ASSETS_FORM_ID,
  INSURANCE_ASSETS_FORM_ID,
  PHYSICAL_ASSETS_FORM_ID,
  OWNERSHIP_FIELD_ID,
} from "../constants/forms";
import { ASSET_LIST_OVERVIEW_PATH, ASSET_LIST_PATH } from "../constants/routes";
import { selectHasUserPaid } from "./plans";

const selectPhysicalAssets = R.path(["assetList", "physicalAssets"]);
const selectFinancialAssets = R.path(["assetList", "financialAssets"]);
const selectInsuranceAssets = R.path(["assetList", "insuranceAssets"]);
export const selectCurrentAssetId = R.path(["assetList", "currentAssetId"]);
export const selectLastModifiedAsset = R.path([
  "assetList",
  "lastModifiedAsset",
]);
const selectTotalAssets = R.path(["assetList", "totalAssets"]);
export const selectHasAssets = createSelector(
  selectTotalAssets,
  (totalAssets) => totalAssets > 0,
);
const selectAssetListCardExpanded = R.pathOr({}, [
  "formUi",
  "assetListCardExpanded",
]);

export const selectAssetByIdAndCategory = (id, category) =>
  createSelector(
    R.path(["assetList", category]),
    R.find(R.propEq("assetId", id)),
  );
export const selectIsEditingAsset = R.path(["assetList", "isEditingAsset"]);
export const selectIsRemovingAsset = R.path(["assetList", "isRemovingAsset"]);

export const selectAssetsByCategory = (category) =>
  R.pathOr([], ["assetList", category]);

const selectAssetListTranslations = createSelector(
  selectTranslations(["global", "assetList"]),
  selectFirstName,
  (translations, firstName) => {
    return {
      ...translations,
      title: translations.title.replace("FIRST_NAME", firstName),
    };
  },
);

export const selectPhysicalAssetProps = createSelector(
  getFormValues(PHYSICAL_ASSETS_FORM_ID),
  selectIsEditingAsset,
  selectIsRemovingAsset,
  (formValues, isEditingAsset, isRemovingAsset) => {
    const assetType = R.propOr(undefined, ASSET_TYPE_FIELD_ID, formValues);
    const ownership = R.propOr(undefined, OWNERSHIP_FIELD_ID, formValues);
    return { assetType, ownership, isEditingAsset, isRemovingAsset };
  },
);
export const selectFinancialAssetProps = createSelector(
  getFormValues(FINANCIAL_ASSETS_FORM_ID),
  selectIsEditingAsset,
  selectIsRemovingAsset,
  (formValues, isEditingAsset, isRemovingAsset) => {
    const assetType = R.propOr(undefined, ASSET_TYPE_FIELD_ID, formValues);
    const ownership = R.propOr(undefined, OWNERSHIP_FIELD_ID, formValues);
    return { assetType, ownership, isEditingAsset, isRemovingAsset };
  },
);
export const SelectInsuranceAssetProps = createSelector(
  getFormValues(INSURANCE_ASSETS_FORM_ID),
  selectIsEditingAsset,
  selectIsRemovingAsset,
  (formValues, isEditingAsset, isRemovingAsset) => {
    const assetType = R.propOr(undefined, ASSET_TYPE_FIELD_ID, formValues);
    return { assetType, isEditingAsset, isRemovingAsset };
  },
);

const selectAssetList = createSelector(
  selectPhysicalAssets,
  selectFinancialAssets,
  selectInsuranceAssets,
  (physicalAssets, financialAssets, insuranceAssets) => ({
    physicalAssets,
    financialAssets,
    insuranceAssets,
  }),
);

const SelectHasPropertyAssets = createSelector(
  selectPhysicalAssets,
  (assets) => {
    return assets.some((asset) => asset.assetType === "property");
  },
);

export const selectAssetListPageUrl = createSelector(
  selectHasAssets,
  selectHasUserPaid,
  (hasAssets, isPaidUser) => {
    return hasAssets && isPaidUser ? ASSET_LIST_PATH : ASSET_LIST_OVERVIEW_PATH;
  },
);

export const AssetListPageProps = createStructuredSelector({
  translations: selectAssetListTranslations,
  hasLoaded: selectHasLoadedDataById(ASSET_LIST_FORM_ID),
  hasAsset: selectHasAssets,
  assetList: selectAssetList,
  cardExpandedMap: selectAssetListCardExpanded,
  hasPropertyAssets: SelectHasPropertyAssets,
});
