import React from "react";
import { Field } from "redux-form";

import TextInput from "./TextInput";

import {
  useValidateEmailFormatMemo,
  useValidateEmailMemo,
} from "./validations";
import { EMAIL_FIELD_ID } from "../../../constants/forms";

const EmailField = ({
  translations,
  useTheir = false,
  autoFocus = false,
  fieldName = "",
  optional = false,
  showHTMLErrorMessage = false,
  onBlur = null,
}) => {
  const name = !!fieldName ? `${fieldName}.${EMAIL_FIELD_ID}` : EMAIL_FIELD_ID;
  const label = optional
    ? `${translations.label.email} ${translations.label.optional}`
    : translations.label.email;
  const placeholder = useTheir
    ? translations.label.emailTheirPlaceholder
    : translations.label.emailPlaceholder;

  const validate = optional
    ? useValidateEmailFormatMemo(translations)
    : useValidateEmailMemo(translations);

  return (
    <Field
      showHTMLErrorMessage={showHTMLErrorMessage}
      component={TextInput}
      name={name}
      label={label}
      autoFocus={autoFocus}
      placeholder={placeholder}
      type="email"
      validate={validate}
      onBlur={onBlur}
    />
  );
};

export default EmailField;
