import R from "ramda";
import { createSelector } from "reselect";
import { getFormValues } from "redux-form";
import {
  ADD_BENEFICIARY_FORM_ID,
  ENTRY_TYPE_FIELD_ID,
  DONATIONS_CUSTOM_CHARITY,
  ADD_CHARITY_BENEFICIARY_FIELD_ID,
} from "../constants/forms";
import {
  MODAL_ADD_BENEFICIARY_PERSON,
  MODAL_ADD_BENEFICIARY_CHARITY,
  MODAL_ADD_CUSTOM_BENEFICIARY_CHARITY,
  MODAL_ADD_BACKUP_PERSON,
} from "../constants/modal";

// Todo: this selector will be deprecated when mini-forms are released
const selectIsAddingPersonFromRegularForm = R.pathOr(false, [
  "formUi",
  "allocations",
  "addingPerson",
]);

export const selectIsAddingCharityFromRegularForm = R.pathOr(false, [
  "formUi",
  "allocations",
  "addingCharity",
]);

export const selectShouldDisplayAllocationHelper = R.pathOr(false, [
  "formUi",
  "allocations",
  "displayAllocationHelper",
]);

export const selectLastAddedPersonAllocation = R.pathOr({}, [
  "formUi",
  "allocations",
  "lastAddedPerson",
]);

export const selectLastAddedCharityAllocation = R.pathOr({}, [
  "formUi",
  "allocations",
  "lastAddedCharity",
]);

// warning: circular dep
const selectModalKey = R.path(["modal", "key"]);
const selectIsAddingPersonFromMiniForm = createSelector(
  selectModalKey,
  (modalKey) =>
    modalKey === MODAL_ADD_BENEFICIARY_PERSON ||
    modalKey === MODAL_ADD_BACKUP_PERSON,
);

export const selectIsAddingPerson = createSelector(
  selectIsAddingPersonFromRegularForm,
  selectIsAddingPersonFromMiniForm,
  (isAddingPerfsonFromRegularForm, isAddingPersonFromMiniForm) =>
    isAddingPerfsonFromRegularForm || isAddingPersonFromMiniForm,
);

const selectIsAddingCharityFromMiniForm = createSelector(
  selectModalKey,
  (modalKey) =>
    modalKey === MODAL_ADD_BENEFICIARY_CHARITY ||
    modalKey === MODAL_ADD_CUSTOM_BENEFICIARY_CHARITY,
);

export const selectIsAddingCharity = createSelector(
  selectIsAddingCharityFromRegularForm,
  selectIsAddingCharityFromMiniForm,
  (isAddingCharityFromRegularForm, isAddingCharityFromMiniForm) =>
    isAddingCharityFromRegularForm || isAddingCharityFromMiniForm,
);

export const selectIsAddingBeneficiary = createSelector(
  selectIsAddingPerson,
  selectIsAddingCharity,
  (isAddingPerson, isAddingCharity) => isAddingPerson || isAddingCharity,
);

// TODO: This selector will be deprecated since the form is displayed in the new mini-form
export const selectIsCustomCharity = createSelector(
  getFormValues(ADD_BENEFICIARY_FORM_ID),
  (formValues) => {
    const entryType = R.pathOr("", [
      ADD_CHARITY_BENEFICIARY_FIELD_ID,
      ENTRY_TYPE_FIELD_ID,
    ])(formValues);
    return entryType === DONATIONS_CUSTOM_CHARITY;
  },
);

export const selectCurrentAllocationCardIndex = R.pathOr({}, [
  "formUi",
  "allocations",
  "currentAllocationCardIndex",
]);

export const selectUpdatedAllocation = R.pathOr({}, [
  "formUi",
  "allocations",
  "updatedAllocation",
]);

export const selectRemovedAllocation = R.pathOr({}, [
  "formUi",
  "allocations",
  "removedAllocation",
]);
