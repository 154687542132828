import { takeEvery, call, put, select } from "redux-saga/effects";
import { getFormValues, initialize } from "redux-form";
import R from "ramda";
import {
  FETCH_ASSET_LIST_TYPE,
  EDIT_ASSET_TYPE,
  updateAssetList,
  addPhysicalAsset,
  addFinancialAsset,
  addInsuranceAsset,
  updatePhysicalAssets,
  updateFinancialAssets,
  updateInsuranceAssets,
} from "../actions/asset-list";
import { getAssetList, updateAsset, deleteAsset } from "../api/asset-list";
import { fetchApiData } from "./requests";
import { fetchSuccess } from "../actions/requests";
import {
  ASSET_LIST_FORM_ID,
  FINANCIAL_ASSETS_FORM_ID,
  INSURANCE_ASSETS_FORM_ID,
  PHYSICAL_ASSETS_FORM_ID,
} from "../constants/forms";
import { submitFormRequest } from "./forms";
import { closeModal, displayModal } from "../actions/modal";
import {
  MODAL_ADD_FINANCIAL_ASSET,
  MODAL_ADD_INSURANCE_ASSET,
  MODAL_ADD_PHYSICAL_ASSET,
} from "../constants/modal";
import {
  selectAssetByIdAndCategory,
  selectAssetsByCategory,
  selectCurrentAssetId,
  selectIsEditingAsset,
} from "../selectors/asset-list";

function* fetchAssetList() {
  const assetList = yield call(fetchApiData, {
    apiCall: getAssetList,
    formId: ASSET_LIST_FORM_ID,
  });

  yield put(updateAssetList(assetList));
  yield put(fetchSuccess(ASSET_LIST_FORM_ID));
}

const ASSET_LIST_FORM_CATEGORY_MAP = {
  [PHYSICAL_ASSETS_FORM_ID]: "physical_asset",
  [FINANCIAL_ASSETS_FORM_ID]: "financial_asset",
  [INSURANCE_ASSETS_FORM_ID]: "insurance_asset",
};

const ASSET_LIST_MODAL_KEY_CATEGORY_MAP = {
  [PHYSICAL_ASSETS_FORM_ID]: MODAL_ADD_PHYSICAL_ASSET,
  [FINANCIAL_ASSETS_FORM_ID]: MODAL_ADD_FINANCIAL_ASSET,
  [INSURANCE_ASSETS_FORM_ID]: MODAL_ADD_INSURANCE_ASSET,
};

export function* handleAssetListUpdate(formId) {
  const formData = yield select(getFormValues(formId));
  const assetCategory = ASSET_LIST_FORM_CATEGORY_MAP[formId];

  const { asset } = yield call(submitFormRequest, {
    apiCall: updateAsset,
    formId,
    values: { ...formData, assetCategory },
  });

  const isEditing = yield select(selectIsEditingAsset);
  yield put(closeModal());
  if (isEditing) {
    const assets = yield select(selectAssetsByCategory(formId));
    const foundIndex = assets.findIndex(
      (currentAsset) => currentAsset.assetId === asset.assetId,
    );
    const newAssetsList = R.update(foundIndex, asset, assets);
    yield call(updateAssetsByCategory, formId, asset, newAssetsList);
  } else {
    yield call(addAssetByCategory, formId, asset);
  }
}

export function* handleAssetListRemove(formId) {
  const assetCategory = ASSET_LIST_FORM_CATEGORY_MAP[formId];
  const assetId = yield select(selectCurrentAssetId);

  const deletedAsset = yield call(submitFormRequest, {
    apiCall: deleteAsset,
    formId,
    values: {
      assetId,
      assetCategory,
    },
  });

  const assets = yield select(selectAssetsByCategory(formId));
  const newAssetsList = R.filter(
    (currentAsset) => currentAsset.assetId !== assetId,
    assets,
  );
  yield call(updateAssetsByCategory, formId, deletedAsset, newAssetsList);
  yield put(closeModal());
}

// formId is equal to the category
function* updateAssetsByCategory(formId, modifiedAsset, assets) {
  switch (formId) {
    case PHYSICAL_ASSETS_FORM_ID:
      yield put(updatePhysicalAssets(modifiedAsset, assets));
      break;
    case FINANCIAL_ASSETS_FORM_ID:
      yield put(updateFinancialAssets(modifiedAsset, assets));
      break;
    case INSURANCE_ASSETS_FORM_ID:
      yield put(updateInsuranceAssets(modifiedAsset, assets));
      break;
    default:
  }
}

// formId is equal to the category
function* addAssetByCategory(formId, asset) {
  switch (formId) {
    case PHYSICAL_ASSETS_FORM_ID:
      yield put(addPhysicalAsset(asset));
      break;
    case FINANCIAL_ASSETS_FORM_ID:
      yield put(addFinancialAsset(asset));
      break;
    case INSURANCE_ASSETS_FORM_ID:
      yield put(addInsuranceAsset(asset));
      break;
    default:
  }
}

export function* handleEditAsset({ payload }) {
  const { id, category } = payload;

  const formData = yield select(selectAssetByIdAndCategory(id, category));
  const modalKey = ASSET_LIST_MODAL_KEY_CATEGORY_MAP[category];

  yield put(displayModal(modalKey));
  yield put(initialize(category, formData));
}

export function* watchFetchAssetList() {
  yield takeEvery(FETCH_ASSET_LIST_TYPE, fetchAssetList);
}

export function* watchEditAsset() {
  yield takeEvery(EDIT_ASSET_TYPE, handleEditAsset);
}
