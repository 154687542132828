export const initializeLanguage = () => ({
  type: INIT_LANG_TYPE,
});

export const setLanguageCode = (languageCode) => ({
  type: SET_LANGUAGE_CODE_TYPE,
  payload: { languageCode },
});

export const validateLanguageCode = (languageCode) => ({
  type: VALIDATE_LANGUAGE_CODE_TYPE,
  payload: { languageCode },
});

export const INIT_LANG_TYPE = "INIT_LANG";
export const SET_LANGUAGE_CODE_TYPE = "SET_LANGUAGE_CODE";
export const VALIDATE_LANGUAGE_CODE_TYPE = "VALIDATE_LANGUAGE_CODE";
