import React from "react";

import { Field, reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";

import { PrimaryButton, SecondaryButton, TextButton } from "../../ui/Button";
import { ErrorMessage } from "../../ui/Error";

import { CHARITY_FORM_ID, CHARITY_ID_FIELD_ID } from "../../../constants/forms";
import SelectInput from "../../ui/inputs/DropdownSelect";
import { useValidateCharityMemo } from "../../ui/inputs/validations";
import { Body } from "../../ui/Typography";

const CharityAllocationForm = ({
  error,
  handleSubmit,
  handleManualCharityClick,
  closeModal,
  isLoading,
  translations,
  extraProps,
}) => {
  const { charitiesOptionList } = extraProps;
  return (
    <form onSubmit={handleSubmit}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        className="qa-charity"
        pt={2}
      >
        <>
          <Field
            name={CHARITY_ID_FIELD_ID}
            label={translations.selectCharityLabel}
            component={SelectInput}
            validate={useValidateCharityMemo(translations)}
            optionList={charitiesOptionList}
          />
          {handleManualCharityClick && (
            <>
              <Body>{translations.manuallyCharitySuggestion}</Body>
              <Box mt={1} mb={2}>
                <SecondaryButton
                  displayPlusIcon
                  fullWidth
                  text={translations.button.addCustomCharity}
                  onClick={handleManualCharityClick}
                  className="qa-manual-charity"
                  type="button"
                />
              </Box>
            </>
          )}
        </>
        <Box>
          <PrimaryButton
            fullWidth
            isLoading={isLoading}
            text={translations.button.submit}
          />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" mt={1}>
          <TextButton text={translations.button.cancel} onClick={closeModal} />
        </Box>
        <ErrorMessage error={error} />
      </Box>
    </form>
  );
};

export default reduxForm({
  form: CHARITY_FORM_ID,
})(CharityAllocationForm);
