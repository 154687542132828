import { createSelector } from "reselect";
import R from "ramda";

import { DASHBOARD_PATH } from "../../constants/routes";
import { selectTranslations } from "../translations";

import { getProgressLabel, getProgressState } from "./progress";
import {
  buildFullName,
  transformNames,
  getNumberOfLabel,
} from "../../utilities/name";
import {
  MARITAL_STATUS_FIELD_ID,
  NUM_CHILDREN_FIELD_ID,
  PRIMARY_BENEFICIARY_FIELD_ID,
  PREDECEASE_FIELD_ID,
  RESIDENCE_FIELD_ID,
  CEREMONY_FIELD_ID,
  PERSONAL_ATTORNEYS_FIELD_ID,
  NUM_PETS_FIELD_ID,
  FIRST_NAME_FIELD_ID,
  LIFE_PROLONG_FIELD_ID,
  RESTING_FIELD_ID,
  ALLOCATIONS_FIELD_ID,
  ASHES_FIELD_ID,
  PAIN_MANAGE_FIELD_ID,
  PROPERTY_ATTORNEYS_FIELD_ID,
  EXECUTORS_FIELD_ID,
  DOB_FIELD_ID,
  INHERITANCE_FIELD_ID,
  BACKUPS_FIELD_ID,
  GIFTS_FIELD_ID,
  DONATIONS_FIELD_ID,
  GENDER_FIELD_ID,
  MIDDLE_NAME_FIELD_ID,
  LAST_NAME_FIELD_ID,
  CHILDREN_GUARDIANS_FIELD_ID,
  PETS_FIELD_ID,
  PET_GUARDIANS_FIELD_ID,
  PET_TRUST_AMOUNT_FIELD_ID,
  CHILDREN_FIELD_ID,
  LANGUAGE_FIELD_ID,
  INHERITANCE_AGES_FIELD_ID,
} from "../../constants/forms";
import { navigationMap } from "../../constants/navigation";

export const selectSectionCurrentPath = (selectNextForm) =>
  createSelector(selectNextForm, (nextForm) => {
    const form = R.find(R.propEq("id", nextForm))(navigationMap);
    return R.propOr(DASHBOARD_PATH, "path")(form);
  });

export const selectIsSectionComplete = (selectListIsComplete) =>
  createSelector(selectListIsComplete, (isCompleteList) =>
    isCompleteList.every((val) => val === true),
  );

export const selectSectionCurrentPageNumber = (selectListIsComplete) =>
  createSelector(selectListIsComplete, (isCompleteList) => {
    let currentStep = 0;
    for (let i = 0; i < isCompleteList.length; i += 1) {
      const isPageComplete = isCompleteList[i];
      // exit loop early if a page is not complete
      if (!isPageComplete) return currentStep;
      currentStep += 1;
    }
    return currentStep;
  });

export const selectSectionPercentComplete = (
  selectCurrentPage,
  selectTotalPages,
) =>
  createSelector(
    selectCurrentPage,
    selectTotalPages,
    (currentPage, totalPages) => (currentPage / totalPages) * 100,
  );

export const selectSectionTranslations = (
  selectIsSectionComplete, // eslint-disable-line no-shadow
  selectCurrentPage,
  translationKeys,
  selectTotalPages,
  selectIsDisabled,
) =>
  createSelector(
    selectTranslations(["global", "navigation", ...translationKeys]),
    selectIsSectionComplete,
    selectCurrentPage,
    selectTotalPages,
    selectIsDisabled,
    (translations, isComplete, currentPageNumber, totalPages, isDisabled) => ({
      ...translations,
      progressState: getProgressState(isComplete, isDisabled),
      progressLabel: getProgressLabel(
        isComplete,
        currentPageNumber,
        totalPages,
        translations,
        isDisabled,
      ),
      continueLabel:
        currentPageNumber === 0
          ? translations.startLabel
          : translations.continueLabel,
    }),
  );

const finalWishesFields = [RESTING_FIELD_ID, ASHES_FIELD_ID, CEREMONY_FIELD_ID];
const healthcareFields = [LIFE_PROLONG_FIELD_ID, PAIN_MANAGE_FIELD_ID];

export const translateInput = (key, allData, translations, ageOfMajority) => {
  const fieldValue = R.propOr(undefined, key, allData);

  // generic values
  if (fieldValue === false) {
    return translations.no;
  }
  if (fieldValue === true) {
    return translations.yes;
  }

  if (key === "fullName") {
    const [firstName, middleName, lastName] = R.props(
      [FIRST_NAME_FIELD_ID, MIDDLE_NAME_FIELD_ID, LAST_NAME_FIELD_ID],
      allData,
    );
    if (!firstName || !lastName) {
      return undefined;
    }
    return buildFullName(firstName, middleName, lastName);
  }

  // about you
  if (key === RESIDENCE_FIELD_ID) {
    const province = R.propOr("", "selectedProvinceName", translations);
    return `${fieldValue}, ${province}`;
  }

  if (key === LANGUAGE_FIELD_ID) {
    return translations.lang[fieldValue];
  }

  if (key === DOB_FIELD_ID) {
    return fieldValue;
  }

  if (key === GENDER_FIELD_ID) {
    return translations.gender[fieldValue];
  }

  // start of your-family
  if (key === MARITAL_STATUS_FIELD_ID) {
    return translations.maritalStatus[fieldValue];
  }

  if (key === CHILDREN_FIELD_ID) {
    if (R.isEmpty(fieldValue)) {
      return translations.none;
    }
    return transformNames(fieldValue);
  }

  if (key === CHILDREN_GUARDIANS_FIELD_ID) {
    return transformNames(fieldValue);
  }
  if (key === PETS_FIELD_ID) {
    if (R.isEmpty(fieldValue) || !fieldValue) {
      return translations.none;
    }
    const listOfNames = fieldValue.map(({ firstName }) => firstName);
    return R.join(", ", listOfNames);
  }

  if (key === PET_GUARDIANS_FIELD_ID) {
    return transformNames(fieldValue);
  }

  if (key === PET_TRUST_AMOUNT_FIELD_ID) {
    return translations.petTrustAmounts[fieldValue];
  }

  if (key === NUM_CHILDREN_FIELD_ID) {
    return translations.numChildren[fieldValue];
  }

  if (key === NUM_PETS_FIELD_ID) {
    return translations.numPets[fieldValue];
  }
  // End of my-family
  // Start of your-estate
  if (key === GIFTS_FIELD_ID) {
    if (R.isEmpty(fieldValue) && allData.giftsVisited) {
      return translations.skipped;
    }
    const giftsData = R.propOr([], GIFTS_FIELD_ID, allData);
    const numGifts = giftsData.length;
    const { gift, gifts } = translations;
    return getNumberOfLabel(numGifts, gift, gifts, translations);
  }

  if (key === DONATIONS_FIELD_ID) {
    if (R.isEmpty(fieldValue) && allData.donationsVisited) {
      return translations.skipped;
    }
    const donationsData = R.propOr([], DONATIONS_FIELD_ID, allData);
    const numDonations = donationsData.length;
    const { donation, donations } = translations;
    return getNumberOfLabel(numDonations, donation, donations, translations);
  }
  if (key === PRIMARY_BENEFICIARY_FIELD_ID) {
    return translations.primaryBeneficiary[fieldValue];
  }

  if (key === ALLOCATIONS_FIELD_ID) {
    const allocationsData = R.propOr([], ALLOCATIONS_FIELD_ID, allData);
    if (R.isEmpty(allocationsData)) return undefined;
    const numAllocations = allocationsData.length;
    const { allocation, allocations } = translations;
    return getNumberOfLabel(
      numAllocations,
      allocation,
      allocations,
      translations,
    );
  }

  if (key === PREDECEASE_FIELD_ID) {
    return translations.predecease[fieldValue];
  }

  if (key === BACKUPS_FIELD_ID) {
    if (R.isEmpty(fieldValue) && allData.backupsVisited) {
      return translations.skipped;
    }
    const backup = R.propOr({}, BACKUPS_FIELD_ID, allData);
    if (R.isEmpty(backup)) return undefined;
    return translations.backupSelected;
  }

  if (key === INHERITANCE_FIELD_ID) {
    return translations.inheritance[fieldValue];
  }

  if (key === INHERITANCE_AGES_FIELD_ID) {
    const { percentageAge, lastSentence } = translations.inheritanceAges;
    let sentence = "";
    if (fieldValue && fieldValue.length) {
      const [milestone1, milestone2, milestone3] = fieldValue;
      if (milestone1) {
        sentence += percentageAge
          .replace("PERCENTAGE", milestone1.percentage)
          .replace("AGE", milestone1.age);
      }
      if (milestone2) {
        sentence += `, ${percentageAge
          .replace("PERCENTAGE", milestone2.percentage)
          .replace("AGE", milestone2.age)}`;
      }
      if (milestone3) {
        sentence += `, ${lastSentence} ${percentageAge
          .replace("PERCENTAGE", milestone3.percentage)
          .replace("AGE", milestone3.age)}`;
      }
    } else {
      sentence += percentageAge
        .replace("PERCENTAGE", 100)
        .replace("AGE", ageOfMajority);
    }
    return sentence;
  }
  // End of your-estate
  // Start of your-arrangements

  if (
    fieldValue === "" &&
    finalWishesFields.includes(key) &&
    allData.wishesVisited
  ) {
    return translations.skipped;
  }

  if (
    fieldValue === "" &&
    healthcareFields.includes(key) &&
    allData.healthcareVisited
  ) {
    return translations.skipped;
  }

  if (key === EXECUTORS_FIELD_ID) {
    const executors = R.propOr([], EXECUTORS_FIELD_ID, allData);
    return getNumberOfLabel(
      executors.length,
      translations.executor,
      translations.executors,
      translations,
    );
  }

  if (key === RESTING_FIELD_ID) {
    return translations.resting[fieldValue];
  }
  if (key === ASHES_FIELD_ID) {
    return translations.ashes[fieldValue];
  }
  if (key === CEREMONY_FIELD_ID) {
    return translations.ceremony[fieldValue];
  }

  if (key === PROPERTY_ATTORNEYS_FIELD_ID) {
    const attorneys = R.propOr([], PROPERTY_ATTORNEYS_FIELD_ID, allData);
    const {
      propertyAppointeeTitle,
      propertyAppointeeTitlePlural,
    } = translations;
    return getNumberOfLabel(
      attorneys.length,
      propertyAppointeeTitle,
      propertyAppointeeTitlePlural,
      translations,
    );
  }

  if (key === PERSONAL_ATTORNEYS_FIELD_ID) {
    const attorneys = R.propOr([], PERSONAL_ATTORNEYS_FIELD_ID, allData);
    const {
      personalAppointeeTitle,
      personalAppointeeTitlePlural,
    } = translations;

    return getNumberOfLabel(
      attorneys.length,
      personalAppointeeTitle,
      personalAppointeeTitlePlural,
      translations,
    );
  }

  if (key === PAIN_MANAGE_FIELD_ID) {
    const { personalAppointeeTitle } = translations;
    if (
      translations.painManage[fieldValue] &&
      R.contains("APPOINTEE_TITLE", translations.painManage[fieldValue])
    ) {
      return translations.painManage[fieldValue].replace(
        "APPOINTEE_TITLE",
        personalAppointeeTitle,
      );
    }
    return translations.painManage[fieldValue];
  }

  if (key === LIFE_PROLONG_FIELD_ID) {
    const { personalAppointeeTitle } = translations;
    if (
      translations.lifeProlong[fieldValue] &&
      R.contains("APPOINTEE_TITLE", translations.lifeProlong[fieldValue])
    ) {
      return translations.lifeProlong[fieldValue].replace(
        "APPOINTEE_TITLE",
        personalAppointeeTitle,
      );
    }
    return translations.lifeProlong[fieldValue];
  }
  // End of your-arrangements
  return fieldValue;
};

// appointee/provincial specific translations required to be passed in
export const translateLabel = (key, translations) => {
  const label = translations.summaryLabel[key];
  if (key === PERSONAL_ATTORNEYS_FIELD_ID) {
    return label.replace(
      "PERSONAL_APPOINTEE_TITLE",
      translations.personalAppointeeTitle,
    );
  }
  if (key === PROPERTY_ATTORNEYS_FIELD_ID) {
    return label.replace(
      "PROPERTY_APPOINTEE_TITLE",
      translations.propertyAppointeeTitle,
    );
  }
  if (key === "spouseIsPropertyAttorney") {
    return label.replace(
      "PROPERTY_APPOINTEE_TITLE",
      translations.propertyAppointeeTitle,
    );
  }
  if (key === "spouseIsPersonalAttorney") {
    return label.replace(
      "PERSONAL_APPOINTEE_TITLE",
      translations.personalAppointeeTitle,
    );
  }
  return label;
};
