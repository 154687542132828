import R from "ramda";
import { combineReducers } from "redux";
import { UPDATE_STATUS_TYPE } from "../actions/status";

const featuresAccess = (state = null, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return R.pathOr({}, ["features"])(payload);
    default:
      return state;
  }
};

export default combineReducers({
  featuresAccess,
});
