export const closeModal = (key) => ({
  type: CLOSE_MODAL_TYPE,
  payload: {
    key,
  },
});

export const displayModal = (key, prompt = false) => ({
  type: DISPLAY_MODAL_TYPE,
  payload: {
    key,
    prompt,
  },
});

export const submitModal = (modalKey) => ({
  type: SUBMIT_MODAL_TYPE,
  payload: {
    modalKey,
    key: modalKey,
  },
});

export const secondarySubmitModal = (modalKey) => ({
  type: SUBMIT_SECONDARY_MODAL_TYPE,
  payload: {
    modalKey,
    key: modalKey,
  },
});

export const DISPLAY_MODAL_TYPE = "DISPLAY_MODAL";
export const CLOSE_MODAL_TYPE = "CLOSE_MODAL";
export const SUBMIT_MODAL_TYPE = "SUBMIT_MODAL";
export const SUBMIT_SECONDARY_MODAL_TYPE = "SUBMIT_SECONDARY_MODAL_TYPE";
