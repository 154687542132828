import React, { useEffect } from "react";
import { connect } from "react-redux";

import PainManageForm from "../../../components/forms/your-arrangements/PainManageForm";

import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { spousePainManagePageProps } from "../../../selectors/spouse-healthcare";
import { SPOUSE_LIFE_PROLONG_PATH } from "../../../constants/routes";
import { PAIN_MANAGE_FORM_ID } from "../../../constants/forms";
import { updatePainManage } from "../../../actions/healthcare";
import { skipSection } from "../../../actions/navigation";
import { getFormData } from "../../../actions/forms";
import { skipHealthcare } from "../../../api/healthcare";

const SpousePainManagePage = ({
  getFormData,
  updatePainManage,
  hasLoaded,
  skipSection,
  ...restOfProps
}) => {
  useEffect(() => {
    getFormData(PAIN_MANAGE_FORM_ID);
  }, []);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <PainManageForm
      backLink={SPOUSE_LIFE_PROLONG_PATH}
      onSubmit={() => updatePainManage()}
      onSkip={() => skipSection(skipHealthcare, PAIN_MANAGE_FORM_ID, true)}
      {...restOfProps}
    />
  );
};

export default connect(spousePainManagePageProps, {
  getFormData,
  updatePainManage,
  skipSection,
})(SpousePainManagePage);
