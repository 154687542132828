export const updatePetTrust = (formId) => ({
  type: UPDATE_PET_TRUST_TYPE,
  payload: {
    formId,
  },
});

export const openPetGuardianModal = (petGuardian = null) => ({
  type: OPEN_MODAL_PET_GUARDIAN_TYPE,
  payload: { petGuardian },
});

export const removePetGuardian = (petGuardian) => ({
  type: REMOVE_PET_GUARDIAN_TYPE,
  payload: { petGuardian },
});

export const petGuardianUpdatedSuccesfully = (petGuardian) => ({
  type: UPDATE_PET_GUARDIAN_SUCCESSFULLY_TYPE,
  payload: { petGuardian },
});

export const petGuardiansnNextPage = () => ({
  type: PET_GUARDIANS_NEXT_PAGE_TYPE,
});

export const UPDATE_PET_TRUST_TYPE = "UPDATE_PET_TRUST";
export const OPEN_MODAL_PET_GUARDIAN_TYPE = "OPEN_MODAL_PET_GUARDIAN_FORM_TYPE";
export const REMOVE_PET_GUARDIAN_TYPE = "REMOVE_PET_GUARDIAN_TYPE";
export const UPDATE_PET_GUARDIAN_SUCCESSFULLY_TYPE =
  "UPDATE_PET_GUARDIAN_SUCCESSFULLY_TYPE";
export const PET_GUARDIANS_NEXT_PAGE_TYPE = "PET_GUARDIANS_NEXT_PAGE_TYPE";
