import React from "react";
import SvgIcon from "./SvgIcon";

const FamilyPlanGuidanceIcon = ({ isDesktop }) => {
  const width = isDesktop ? 240 : 180;

  return (
    <SvgIcon width={width} viewBox="0 0 240 232">
      <path
        d="M35.49,112.5c-47.11,27.44-47.32,71.56-.65,99s122.56,27.22,169.67,0c47.1-27.22,47.32-71.56,.65-99-46.67-27.44-122.56-27.22-169.67,0Z"
        fill="#c6e9f8"
      />
      <path
        d="M81.05,77.08c.05,1.13-.15,2.25-.58,3.29-.43,1.04-1.08,1.98-1.9,2.74-.1,.1-.22,.2-.35,.32-.24,.22-.51,.41-.79,.56l-15.33,8.82c-8.66,4.97-18.34,10.51-29.25,16.8-2.29,1.18-5.62,1.62-10.98-1.62-1.21-.74-2.26-1.69-3.12-2.82,0-.02-.02-.04-.04-.06-.63-.89-1.05-1.92-1.2-3.01-.04-.56-2.25-30.8-2.25-31.35,0,.02,0,.04,0,.06,.04-1.28,.39-2.53,1.03-3.63,.64-1.11,1.54-2.04,2.63-2.7,.51-.29,3.73-2.16,8.25-4.75l2.78-1.61,27.48-15.8c.63-.38,1.24-.71,1.79-1.03,2.25-1.3,3.81-2.19,4.3-2.47,2.25-1.17,4.64-1.05,6.99-.26h.06c1.77,.65,3.44,1.54,4.97,2.64,2.45,1.98,3.37,4.25,3.37,5.58,0,.06,.56,7.84,1.12,15.5,.16,2.08,.32,4.15,.44,6.07,.35,4.91,.61,8.73,.61,8.73Z"
        fill="#aad2f6"
      />
      <path
        d="M75.4,46.09c1.46-.84,2.75-.1,2.87,1.66,.72,10.11,1.44,20.22,2.17,30.33,.03,.93-.18,1.84-.6,2.67-.42,.82-1.05,1.52-1.82,2.03l-44.6,25.62c-1.47,.85-2.76,.1-2.89-1.65-.72-10.1-1.44-20.21-2.17-30.32-.03-.93,.18-1.85,.6-2.67,.43-.82,1.05-1.52,1.83-2.04l44.6-25.62Z"
        fill="#3989e5"
      />
      <path
        d="M36.88,127.66l5.33-22.38,7.87-.13c-.52,1.99,.25-.56-6.93,23.77-1.78,3.72-7.05,1.68-6.28-1.26Z"
        fill="#abe0f6"
      />
      <path
        d="M156.24,198.8c-.77,.01-1.52-.24-2.13-.71-.61-.47-1.04-1.14-1.21-1.89-.08-1.96-.73-21.7-.73-24.99l7.58,2.12-.49,23.03c0,.96-.8,2.44-3.01,2.44Z"
        fill="#abe0f6"
      />
      <path
        d="M34.63,118.72c1.96,1.81,5.54,3.55,125.91,73.78l.06,.04c2.2,1.18,4.65,1.8,7.15,1.8s4.95-.62,7.15-1.8c.96-.57,26.76-15.42,27.63-16.07,6.82-4.93,8.72-23.91-.96-30.05C70.37,69.84,76.44,72.17,70.28,72.14c-5.65,0-5.06,.56-33.75,17.14-.97,.54-1.84,1.25-2.58,2.08-1.02,1.14-1.84,2.46-2.42,3.88-2.37,5.75-2.81,18.3,3.1,23.48Z"
        fill="#004eb6"
      />
      <path
        d="M71.13,63.02c.15,2.94,.93,3.77,2.18,4.55,.22,.12,.46,.26,.73,.43,38.65,22.4,37.62,22.62,41.18,22.63,1.28,0,2.25-.35,5.32-1.19,1.69-.45,3.31-.88,3.66-.98,.96-.29,1.78-.93,2.3-1.79,5.06-7.18,14.98-51.56,11.18-57.84-.21-.41-.51-.76-.89-1.02,0-.01-.01-.02-.02-.03,0,0-.02-.01-.04,0-.14-.08-.28-.16-.46-.28C97.06,4.66,98.22,4.79,95.5,4.51c-2.01-.33-2.77,.09-10.12,2.07-.1,0-.66,.28-.71,.28-5.73,2.7-14.67,46.07-13.53,56.17Z"
        fill="#aad2f6"
      />
      <path
        d="M126.5,86.68c-.52,.86-1.34,1.5-2.3,1.79-.35,.1-1.26,2.76-2.93,3.22-3.08,.84-4.77-1.04-6.06-1.04-3.56,0-6.32-3.36-44.97-25.75,10.62,1.74,20.85,5.37,30.2,10.72,5.23,2.95,10.18,6.36,14.79,10.21,1.65-20.05,6.31-39.74,13.81-58.41,3.77-.36,6.56-.24,7.23,.09,.31,.15,.59,.34,.84,.56,.22,.22,.41,.47,.56,.75,3.81,6.3-6.1,50.67-11.19,57.86Z"
        fill="#3989e5"
      />
      <path
        d="M85.77,9.02c12.52,7.31,25.02,14.61,37.5,21.9,1.56,.9,4.06,1.33,5.54,.91,2.45-.65,4.9-1.31,7.36-1.98,1.27-.33,1.51-1.22,.62-2.04,0-.01-.01-.02-.02-.03-.01,0-.02-.01-.04,0-.14-.08-.28-.16-.46-.28C97.06,4.66,98.21,4.8,95.5,4.54c-2.02-.18-1.84,0-9.81,2.11-1.51,.41-1.47,1.48,.09,2.38Z"
        fill="#e2f6ff"
      />
      <path
        d="M119.03,91.08c.15,2.93,.93,3.78,2.18,4.55,.39,.21,36.97,21.58,37.5,21.89,1.83,1.03,3.97,1.37,6.03,.96,7.36-2.18,8.13-1.54,9.66-3.78,5.16-7.3,15.5-53.6,10.84-58.32l-.13-.2c-.28-.26-.59-.48-.93-.66-40.53-23.59-37.74-22.51-40.78-22.96,0,0,0,0-.01-.01,0,0-.01,0-.02,0,0,0-.01,0-.02,0,0,0,0,0-.01,.01-1.98-.3-2.81,.11-10.06,2.07-.18,.05-.35,.12-.52,.2-.06,.04-.13,.06-.2,.08-4.86,2.26-9.82,28.13-11,33.36-.1,.51-.2,1.07-.22,1.09-.02,.02-.77,4.16-.77,4.16-2.15,12.45-1.56,17.55-1.56,17.55Z"
        fill="#aad2f6"
      />
      <path
        d="M174.42,114.73c-.28,.38-.6,.74-.95,1.07-1.21,1.26-2.98,3.1-5.52,3.66-1.39,.29-2.83,.2-4.17-.26-.32-.05-.86-.13-1.54-.28-1.23-.25-2.45-.58-3.64-.98-5.39-1.81-20.21-11.53-39.4-25.5,3.98,.98,7.91,2.17,11.76,3.59,4.59,1.69,9.07,3.67,13.4,5.95,6.3,3.31,12.28,7.21,17.85,11.64,9.08-50.68,9.07-51.12,9.3-53.91,.31-3.44,1.34-6.78,3.03-9.8,1.89,1.21,3.49,2.05,4.59,2.7l5.06,2.95c.6,.28,1.09,.75,1.4,1.34,3.8,6.29-6.09,50.65-11.18,57.84Z"
        fill="#3989e5"
      />
      <path
        d="M133.7,37.09c12.5,7.31,25,14.61,37.5,21.9,1.56,.92,4.05,1.32,5.54,.91,2.45-.64,4.91-1.31,7.36-1.97,.97-.25,1.37-.85,1.15-1.51l-.13-.2c-.28-.26-.59-.47-.93-.65-40.53-23.6-37.74-22.52-40.78-22.96h-.06c-.79-.09-1.6-.03-2.37,.15-2.46,.64-4.91,1.31-7.39,1.97-1.49,.4-1.45,1.47,.11,2.36Z"
        fill="#e2f6ff"
      />
      <path
        d="M166.95,119.15c.11,2.07,.52,3.08,1.22,3.77,0,.14,.87,.72,.97,.77,.52,.29,36.89,21.55,37.5,21.9,3.99,2.06,6.19,.98,9.73,0,0,0,3.58-.94,3.66-.98,.96-.29,1.78-.93,2.28-1.79,4.83-6.81,13.97-46.96,11.69-56.53,0-.01,0-.03,0-.04-.09-.43-.24-.84-.46-1.21v-.06s-.12-.2-.17-.28-.32-.4-.32-.4c-.27-.25-.58-.47-.9-.65-1.02-.6-2.03-1.2-3.02-1.75-36.07-21.1-34.76-20.69-37.35-21.15-.37-.06-.69-.12-.99-.14-1.69-.15-2.95,.39-9.56,2.15-.18,.05-.35,.12-.51,.2-.06,.04-.13,.06-.2,.08-4.86,2.26-9.8,28.02-11,33.36-.1,.51-.2,1.07-.22,1.09-.02,.02-.76,4.16-.76,4.16-2.18,12.41-1.59,17.51-1.59,17.51Z"
        fill="#aad2f6"
      />
      <path
        d="M168.18,122.94c.45,.34,.95,.72,1.48,1.08-.18-.11-1.49-.87-1.48-1.08Z"
        fill="#a27b4e"
      />
      <path
        d="M222.32,142.81c-.51,.87-1.32,1.51-2.28,1.8l-3.66,.97c-3.61,.99-5.74,2.07-9.73,.03-37.43-19.13-25.57-15.86-40.43-25.56-.04,0-1.69-1.08-1.47-1.54,13.29,4.6,26.38,9.72,39.3,15.34l13.95-9.87c2.78-11.91,4.39-24.07,4.82-36.29l6.32-5.8c.47,.27,3.49,1.97,3.87,2.35,.22,.24,.41,.51,.56,.8,.08,.16,.24,.56,.45,1.21v.04c2.28,9.57-6.86,49.7-11.69,56.53Z"
        fill="#3989e5"
      />
      <path
        d="M181.62,65.16c12.49,7.31,24.99,14.61,37.49,21.9,1.54,.91,4.03,1.32,5.53,.91,2.46-.64,4.91-1.31,7.37-1.97,.83-.21,1.29-.73,1.31-1.3-.04-.05-.06-.08-.08-.1-.3-.4-.68-.73-1.12-.96-1.02-.6-2.03-1.2-3.02-1.75-36.06-21.09-34.77-20.68-37.35-21.14-.32-.06-.65-.11-.98-.14-.63,0-1.27,.08-1.88,.23-2.45,.64-4.91,1.31-7.36,1.97-1.5,.4-1.47,1.47,.1,2.36Z"
        fill="#e2f6ff"
      />
      <path
        d="M115.7,102.17c-.05,1.12-.37,2.21-.93,3.18-.56,.97-1.35,1.79-2.29,2.38-.12,.06-37.54,21.56-37.69,21.63-6.19,3.21-31.94-12.91-38.74-17.76-3.98-2.87-10-7.12-10.08-10.91,0-4.82-.11-8.87,.15-10.08,.26-1.17,.83-2.24,1.63-3.13,.41-.48,.9-.89,1.45-1.21,0,0,37.65-21.63,37.69-21.65,1.03-.53,2.2-.7,3.34-.49,6.13,1.02,15.84,6.21,20.9,9.22,4.96,2.93,19.35,11.09,23.52,16.98,.64,.86,1.01,1.9,1.06,2.98,.01,.96,0,8.12,0,8.84Z"
        fill="#e2f6ff"
      />
      <path
        d="M115.7,102.9c-.05,1.12-.37,2.21-.93,3.18-.56,.97-1.35,1.79-2.29,2.38-.12,.07-37.54,21.56-37.69,21.63-6.19,3.21-31.94-12.91-38.74-17.76-3.95-2.88-9.97-7.12-10.06-10.91,0-2.91-.03-5.54,0-7.43,0-.24,.07-.47,.21-.67,.13-.2,.32-.35,.53-.45,.22-.1,.46-.13,.69-.09,.23,.04,.45,.14,.63,.3,10.54,9.53,22.71,17.07,35.91,22.27,.82,.31,1.7,.44,2.57,.38,.87-.06,1.72-.32,2.49-.74,37.99-21.08,40.5-23.91,44.62-23.57,.26,0,1.19-.05,1.52,.63,.34,.61,.54,1.29,.56,1.99-.02,.97-.02,8.13-.02,8.85Z"
        fill="#3989e5"
      />
      <path
        d="M161.4,129.14c-.05,1.12-.37,2.21-.93,3.18-.56,.97-1.35,1.79-2.29,2.38-.11,.07-37.54,21.56-37.66,21.63-6.19,3.22-31.94-12.91-38.74-17.75-3.98-2.87-10-7.12-10.09-10.91,0-4.82-.11-8.87,.15-10.08,.2-.89,.58-1.73,1.11-2.48,.53-.74,1.2-1.38,1.97-1.86,0,0,37.65-21.63,37.68-21.66,1.03-.52,2.21-.69,3.34-.48,6.13,1.01,15.85,6.21,20.9,9.22,5.23,3.09,24.6,13.9,24.57,19.97-.01,.95-.02,8.11-.02,8.82Z"
        fill="#e2f6ff"
      />
      <path
        d="M161.4,129.88c-.05,1.12-.37,2.21-.93,3.18-.56,.97-1.35,1.79-2.29,2.38-.12,.07-37.54,21.56-37.66,21.63-6.19,3.21-31.94-12.91-38.74-17.76-3.98-2.87-10-7.12-10.09-10.91,0-2.91-.03-5.54,0-7.44,0-.24,.07-.47,.21-.67,.13-.2,.32-.35,.53-.45s.46-.13,.69-.09c.23,.04,.45,.14,.63,.3,10.54,9.53,22.71,17.07,35.91,22.27,.82,.31,1.7,.44,2.57,.38,.87-.06,1.72-.32,2.49-.74,37.99-21.08,40.5-23.91,44.61-23.57,.26,0,1.19-.04,1.52,.63,.35,.61,.54,1.29,.56,1.99-.01,.98-.02,8.14-.02,8.85Z"
        fill="#3989e5"
      />
      <path
        d="M207.1,156.11c-.05,1.12-.37,2.21-.93,3.18-.56,.97-1.35,1.79-2.29,2.38-.11,.06-37.54,21.56-37.65,21.63-6.19,3.21-31.94-12.91-38.75-17.76-3.98-2.87-10-7.12-10.09-10.91,0-4.82-.11-8.87,.15-10.08,.26-1.17,.83-2.25,1.63-3.13,.41-.48,.9-.89,1.45-1.21,0,0,37.65-21.63,37.68-21.65,1.03-.53,2.21-.7,3.34-.49,6.13,1.01,15.85,6.21,20.9,9.22,5.43,3.21,15.59,9.03,21.09,14.34,1.61,1.51,3.49,3.41,3.48,5.64-.01,.94-.02,8.1-.02,8.82Z"
        fill="#e2f6ff"
      />
      <path
        d="M207.1,156.86c-.05,1.12-.37,2.21-.93,3.18-.56,.97-1.35,1.79-2.29,2.38-.12,.07-37.54,21.56-37.65,21.63-6.19,3.22-31.94-12.91-38.75-17.75-3.98-2.87-10-7.12-10.09-10.91,0-2.91-.03-5.54,0-7.43,0-.24,.08-.47,.21-.67,.13-.2,.32-.35,.53-.45,.22-.1,.46-.13,.69-.09,.23,.04,.45,.14,.63,.3,10.16,9.18,22.81,17.25,35.91,22.27,.82,.31,1.7,.44,2.57,.38,.87-.06,1.72-.32,2.49-.74,37.99-21.07,40.5-23.91,44.61-23.57,.26,0,1.19-.04,1.52,.63,.35,.61,.54,1.29,.56,1.99-.01,.95-.02,8.12-.02,8.85Z"
        fill="#3989e5"
      />
      <path
        d="M204.08,145.67c1.56-.9,2.84-.17,2.81,1.62v8.85c-.05,.98-.34,1.93-.83,2.78-.49,.85-1.17,1.57-1.99,2.11-12.55,7.21-25.1,14.42-37.65,21.63-1.57,.9-2.81,.17-2.81-1.62v-8.86c.05-.98,.33-1.93,.82-2.78,.49-.85,1.17-1.57,1.99-2.1l37.65-21.63Z"
        fill="#004eb6"
      />
      <path
        d="M202.38,96.23c.98,3.66,1.77,9.78,12.08,8.93l-4.32,4.08-8.01-1.79s-.25-1.13-3.56-4.59c-1.92-1.96-3.48-4.25-4.58-6.77l-4.2-5.87c-2.55-4.1-5.1-8.21-7.65-12.35,21.78,4.38,17.55,8.25,20.24,18.36Z"
        fill="#71adee"
      />
      <path
        d="M214.76,127.46c-.51-.25-43.51,8.88-53.57-.94-1.2-1.26-2.13-2.77-2.71-4.41-1.06-2.72-1.69-5.93-2.21-8.86-.43-2.26-.66-4.56-.71-6.86v-2.32c.04-1.3,.62-8.11,.68-8.84,.15-2.01-.16-5.01-2.37-9.03,2.95-1.91,17.12-10.64,33.41-5.96,1.42,.41,3.07,1.04,3.07,1.04,3.64,1.47,7.72,3.11,8.7,6.33,.39,1.25,.09,2,.49,5.17,.12,.92,.28,1.83,.49,2.73,.06,.27,.13,.54,.21,.84,.46,1.78,1.21,3.48,2.21,5.02,2.06,3.1,4.5,4.41,6.15,9.55,.51,1.64,1.05,3.3,1.62,4.99,1.46,4.12,2.98,7.97,4.54,11.56Z"
        fill="#0064dc"
      />
      <path
        d="M210.26,115.91c-4.06,.77-27.48,5.08-47.54,6.18-1.41,.08-2.82,.08-4.24,0-1.06-2.72-1.69-5.93-2.21-8.86-.43-2.26-.66-4.55-.71-6.85v-2.32c.04-1.3,.62-8.11,.68-8.84,.15-2.01-.16-5-2.37-9.03,2.95-1.91,17.12-10.64,33.41-5.96,1.07,.3,2.09,.66,3.07,1.04,2.3,5.6,5.72,18.67,8.63,19.55,1.13,.33,2.3,.52,3.48,.57,2.06,3.1,4.5,4.41,6.15,9.55,.04-.01,.74,2.19,1.66,4.98Z"
        fill="#004eb6"
      />
      <path
        d="M222.57,142.64c-6.23,.47-1.5-1.9-12.67,1.04-4.13,1.1-9.69,.12-11.25,.57-1.86,.53-6.27-.11-7.93,1.06-.41,.27-.85,.49-1.31,.64-.44,.36-.99,.58-1.56,.62-.57,.04-1.14-.1-1.63-.4-.22-.14-.42-.32-.59-.53-.46-.55-.68-1.25-.64-1.96-.01-.14-.05-.27-.12-.4-.6-1.13-3.3-1.91-3.3-2.4-.12-.77-.28-1.54-.48-2.3-.29-1.2,.11-2.89,.48-4.76,.09-.48,.18-.96,.25-1.46,0-.11,.03-.23,.04-.36,.01-.12,.04-.3,.06-.46,.01-.15,.03-.31,.05-.46,.01-.15,0-.31,.03-.47,.05-.88-.04-1.77-.25-2.63-1.29-5-2.52-9.77-2.6-10.55-.25-2.39-2.9-6.63-2.94-11.7,0,0,1.44,1.73,6.04,.36,.24-.08,.49-.14,.74-.17,4.2-1.01,7.31,.42,9.72-.52,4.01-1.55,14.29,.37,18.21,.26,1.9-.06,3.94-1.63,4.41-1.9,.02-.01,.03-.02,.05-.02,.02,0,.04,0,.06,0,.02,0,.04,.01,.05,.03,.01,.01,.03,.03,.04,.04,.25,.76,.42,1.55,.52,2.35,.16,.78,.39,1.74,.74,2.86,1.12,3.52,2.45,10.09,2.47,12.98,.02,2.88-.89,6.77,.39,10.49,.91,2.63,1.43,5.45,2.06,7.71,.22,.84,.51,1.65,.87,2.43Z"
        fill="#0064dc"
      />
      <path
        d="M190.57,124.63c-.66,5.23-3.38,4.89-4.67,10.05-1.09,4.33-.25,7.44-1.03,8.59-.6-1.13-3.3-1.91-3.3-2.4-.12-.77-.28-1.54-.48-2.3,.2-1.5,.16-3.11,.48-4.76,.09-.48,.17-.96,.25-1.45,0-.11,.03-.23,.04-.36,.01-.12,.04-.3,.06-.46,.01-.15,.03-.31,.04-.46,.01-.15,0-.31,.03-.47,.05-.88-.04-1.77-.25-2.63l6.01-13.95c.83-.02,4.24-.63,2.82,10.59Z"
        fill="#004eb6"
      />
      <path
        d="M221.7,140.17c-2.4,.83-4.65,1.59-6.75,2.3-.35,0-4.7,1.13-5.06,1.22-4.12,1.1-9.69,.11-11.25,.56-1.86,.53-6.27-.1-7.93,1.06-.41,.27-.85,.49-1.31,.64-.44,.36-.99,.58-1.56,.62-.57,.04-1.14-.1-1.63-.4-.23-.14-.43-.32-.6-.53,2.74-4.84,12.05-13.6,20.33-18.62,1.49-.82,2.87-1.85,4.07-3.06,5.37-5.54,5.82-13.85,5.29-20.21l.2,.05c.25,.76,.42,1.55,.52,2.35,.16,.78,.39,1.74,.74,2.86,1.12,3.52,2.45,10.09,2.47,12.98,.02,2.88-.9,6.77,.38,10.49,.92,2.61,1.44,5.43,2.07,7.69Z"
        fill="#004eb6"
      />
      <path
        d="M236.09,133.98c-.17-.47-.38-.92-.62-1.34-.8-1.33-1.87-2.48-3.12-3.39-3.16-2.26-6.62-4.51-10.43-2.96,0,0,2.81-1.65-45.14,25.9-.35,.21-.69,.45-1.01,.71-.84,.69-1.54,1.54-2.03,2.52-.5,.97-.78,2.04-.85,3.12-.43,5.43-2.25,28.37-2.25,28.37,.29,4.95,8.29,10.69,13.43,9.72,.57-.13,1.11-.35,1.62-.64l44.6-25.62h.03c1.07-.66,1.98-1.55,2.66-2.61,.68-1.06,1.12-2.26,1.27-3.51,2.24-28.78,2.52-28.32,1.83-30.25Z"
        fill="#aad2f6"
      />
      <path
        d="M233.4,135.98c1.46-.84,2.54-.22,2.42,1.38-.75,9.26-1.49,18.52-2.21,27.77-.14,.9-.46,1.76-.96,2.53-.5,.76-1.15,1.41-1.92,1.9l-44.6,25.62c-1.46,.84-2.55,.23-2.42-1.38,.73-9.27,1.47-18.54,2.22-27.79,.13-.9,.46-1.76,.96-2.53,.5-.76,1.15-1.41,1.92-1.9l44.59-25.59Z"
        fill="#3989e5"
      />
      <path
        d="M108.19,51.65c-.39,1.34-.79,2.71-.97,3.27-.15,.43-.46,3.81-.81,7.94-.56,6.82-1.28,15.68-1.54,16.75-.06,.26-.1,.52-.12,.78-.26,2.65,.52,7.26-1.02,11.79,0,0-.82-2.21-5.84-2.47-.26,0-.53-.03-.79-.08-4.5-.47-7.08-2.96-9.78-2.88-4.5,.14-13.98-5.35-17.89-6.61-1.9-.6-4.47,.24-5.01,.34-.38,.07-.14-.82,.12-2.62,.14-1.03,.23-2.06,.26-3.09,.03-1.2,.15-2.7,.32-4.28,.39-3.51,1.06-7.43,1.73-9.37,1.07-3.36,1.59-6.87,1.54-10.39,0-4.06,2.65-8.42,2.36-11.16,6.28,1.69,.92,2.43,12.87,3.39,4.45,.36,9.61,3.26,11.31,3.36,2.02,.12,6.15,2.29,8.2,1.69,.5-.13,1.01-.19,1.52-.18,.52-.2,1.09-.24,1.64-.11,.54,.13,1.04,.41,1.42,.82,.38,.41,.63,.92,.72,1.48,.09,.55,.01,1.12-.22,1.63Z"
        fill="#0064dc"
      />
      <path
        d="M106.41,62.85c-.56,6.82-1.28,15.68-1.54,16.75-.06,.26-.1,.52-.12,.78-9.61,2.13-11.17,4.48-19.96-1.52-8.32-5.69-15.6-4.71-19.69-8.37,.39-3.51,1.06-7.43,1.73-9.37,1.07-3.36,1.59-6.87,1.55-10.39,0-3.02,2.36-8.09,3.97-10.66,3.62,1.3,.7,1.96,10.12,2.82,.13,.1,.24,.22,.33,.36,1.99,2.88-6.87,9.85-5.46,14.15,.49,1.51,2.46,3.35,9.71,4.01,3.83,1.2,7.56,2.67,11.18,4.41,1.99,.96,3.88,1.98,5.59,1.2,1.71-.78,2.33-2.97,2.58-4.16Z"
        fill="#004eb6"
      />
      <path
        d="M100.2,90.84c-5.27-3.55-11.03-6.92-15.83-9.59-1.51-.84-3.22-1.69-5.42-2.72-2.13-.88-4.04-2.2-5.62-3.87-.87-.94-1.56-2.05-2.01-3.25-.88-2.26-1.04-4.61-2.81-7.67-7.46,4.28-31.14,17.88-39.2,22.52,1.77,2.65,4.45,.82,6.43-.03,3.3-1.41,8.36-1.94,10.79,.21,1.94,3.6,4.49,6.85,7.52,9.59,10.03,9.03,22.26,9.5,26.24,9.49,21.67,11.1,29.86,16.2,31.71,18.31l.1,.11c1.95,2.12,4.3,3.83,6.91,5.02,3.3,1.51,10.43,4.8,14.66,3.58,.7-.18,1.35-.54,1.88-1.04,1.53-1.48,1.31-2.78,2.39-4.34,1.77-2.57,4.61-1.74,5.42-3.69,.17-.43,.56-1.8-1.52-4.77-3.67-5.17-9.35-7.26-10.09-7.53-3.13-1.22-12.59-7.6-31.53-20.32Z"
        fill="#0064dc"
      />
      <path
        d="M74.95,77.75s-1.61-1.61-6.8,.53c-5.19,2.14-8.95,.91-11.81,2.35-4.53,2.26-16.15,1.78-20.59,2.51-2.15,.35-4.46,2.33-5,2.69-.37,.25-.41-.68-.79-2.45-.21-.99-.49-1.98-.82-2.94-1.26-3.58-2.69-10.4-2.69-13.46s1.07-7.39-.35-11.13c-1.42-3.74-1.98-8.07-3.24-10.42,6.9-1.43,1.91,1.76,14.32-3.05,4.65-1.79,10.93-1.61,12.71-2.33,2.09-.85,7.09-.84,8.95-2.34,1.54-1.24,4.32-2,6.26-2.33,0,0,.35,5.38,.35,6.65s6.1,21.9,6.28,23.7c.25,2.52,3.22,6.64,3.22,12.03Z"
        fill="#004eb6"
      />
      <path
        d="M36.69,61.61c-1.5,2.22-4.5,3.95-6.46,3.16-2.25-.89-3.23-4.42-3.58-6.81-.11-.72-.3-1.43-.56-2.12-1.43-3.77-1.97-8.07-3.23-10.42,6.85-1.43,1.97,1.69,14.06-2.97,.14,2.04,.31,4.95,.62,9.18,.47,5.52,.66,7.72-.85,9.97Z"
        fill="#0064dc"
      />
      <path
        d="M145.7,124.13c-.14,.6-.46,1.14-.93,1.55-2.16,1.94-6.3,.89-7.09,.68-.37-.28-.75-.55-1.12-.79-4.29-3.01-7.44-4.02-9.67-4.29-.22-.04-.44-.06-.66-.07-.85-.06-2.81-.09-3.94-1.44-.71-.81-.56-1.47-1.23-3.87v-.06c-.25-.61-.46-1.23-.62-1.86-.08-.31-.56-2.19-.44-3.08,.24-1.76,3.38-3.24,7.4-3.73,1.06,2.22,2.59,4.18,4.5,5.73,1.12,.89,2.51,1.61,5.25,3.06,3.65,1.92,4.26,1.96,5.66,3.18,.31,.28,.79,.68,1.25,1.17,.98,1.03,1.95,2.44,1.65,3.85Z"
        fill="#79b7e4"
      />
      <g opacity=".5">
        <path
          d="M126.91,121.31c-.22-.04-.44-.06-.66-.07-.85-.06-2.81-.09-3.94-1.44-.71-.81-.56-1.47-1.23-3.87,1.19,0,2.71,.56,4.21,2.65,.63,.85,1.18,1.77,1.62,2.74Z"
          fill="#79b7e4"
        />
      </g>
      <g opacity=".5">
        <path
          d="M145.7,124.14c-.14,.6-.46,1.14-.93,1.55-2.16,1.94-6.3,.89-7.09,.68-.38-.29-.75-.55-1.12-.79,1.12-1.13,4.96-4.88,7.46-5.28,1.01,1.03,1.98,2.44,1.68,3.85Z"
          fill="#79b7e4"
        />
      </g>
      <path
        d="M128.74,109.12c-.27,.99-.81,1.88-1.56,2.58-.75,.7-1.68,1.17-2.68,1.37-1.47,.18-2.96-.06-4.29-.71h-.05c-2.21-4.86-4.44-7.9-7.84-12.05-1.5-2.04-8.8-11.72-9.08-12.02-1.51-.11-4.04-1.02-6.89-1.29-18-1.69-32.36-3.01-33.19-7.39-.46-2.42-1.65-5.5,.68-6.96,9.2-5.78,44.38-3.5,48.47,3.73,6.46,13.05,7.58,15.1,16.42,32.75Z"
        fill="#ff923f"
      />
      <path
        d="M128.74,109.12c-.27,.99-.81,1.88-1.56,2.58-.75,.7-1.68,1.17-2.68,1.37-1.47,.18-2.96-.06-4.29-.71h-.05c-2.21-4.86-4.44-7.9-7.84-12.05-1.5-2.04-8.8-11.72-9.08-12.02-1.51-.11-4.04-1.02-6.89-1.29-18-1.69-32.36-3.01-33.19-7.39-.46-2.42-1.65-5.5,.68-6.96,9.2-5.78,44.38-3.5,48.47,3.73,6.46,13.05,7.58,15.1,16.42,32.75Z"
        fill="#71adee"
      />
      <path
        d="M141.69,136.71c-.2,.58-.58,1.08-1.09,1.43-2.36,1.69-6.36,.19-7.12-.1-.7-.66-1.37-1.25-2.01-1.78-3.71-3.05-6.54-4.17-8.58-4.56-.07-.02-.14-.04-.21-.04-.84-.15-2.78-.4-3.75-1.86-.61-.89-.37-1.53-.82-4.05-.1-.64-.25-1.28-.42-1.91-.18-.65-.31-1.31-.38-1.97,0-.56,.42-2.06,7.09-4.29,1.18,2.44,2.81,4.63,4.81,6.46,1.5,1.37,3.13,2.58,4.88,3.62,2.31,1.55,3.33,2.1,4.15,2.73,.41,.31,.79,.66,1.12,1.04,.84,.91,3,3.23,2.32,5.29Z"
        fill="#79b7e4"
      />
      <g opacity=".5">
        <path
          d="M141.69,136.71c-.2,.58-.58,1.08-1.09,1.43-2.36,1.69-6.36,.19-7.12-.1-.7-.66-1.37-1.25-2.01-1.78,.13-.71,.36-1.39,.68-2.04,1.31-2.82,3.94-3.7,6.07-3.87,.41,.31,.79,.66,1.12,1.04,.87,.94,3.03,3.25,2.35,5.31Z"
          fill="#79b7e4"
        />
      </g>
      <g opacity=".5">
        <path
          d="M122.89,131.71c-.07-.02-.14-.04-.21-.05-.84-.15-2.78-.39-3.75-1.86-.61-.89-.37-1.53-.82-4.04,.6,.31,3.11,.98,3.94,3.53,.39,1.19,.7,1.94,.84,2.42Z"
          fill="#79b7e4"
        />
      </g>
      <path
        d="M125.19,118.75c-.97,1.24-2.21,2.25-3.63,2.95-1.55,.79-3.29,1.1-5.01,.9-10.88-15.01-11.4-12.41-26.08-27.41-3.99,2.07-8.3,3.46-12.75,4.12-5.14,.82-13.83,2.19-21.31-2.22-6.7-4.22-8.62-13.54-6.06-15.31,.61-.41,1.12,.03,2.55,.21h.32c3.27,.15,5.48-3.39,13.74-5.72,17.93,4.89,26.66,.71,34.04,6.93,.44,.36,.85,.76,3.49,4.2,5.13,6.73,12.42,16.14,20.7,31.36Z"
        fill="#e2f6ff"
      />
      <path
        d="M125.19,118.77c-.66,.95-3.9,4.38-8.64,3.85-10.88-15.01-11.4-12.41-26.08-27.41-3.99,2.07-8.3,3.45-12.75,4.1-5.14,.82-13.83,2.19-21.31-2.22-6.7-4.22-8.62-13.54-6.06-15.31,.85-.56,1.87,.23,2.87,.23,3.27,.15,5.48-3.39,13.74-5.72,1.46,.39,2.87,.74,4.21,1.03-1.01,1.5-4.87,5.73-6,6.95-1.73,1.85-3.89,3.25-6.28,4.09,5.91,6.77,15.38,9.03,24.46,5.08,3.77-1.63,4.29-2.98,6.49-2.87,2.51,.14,3.85,2.45,6.75,5.84,4.7,5.49,5.03,3.89,9.77,9.22,6.19,6.96,6.27,10.38,10.82,12.36,2.54,1.04,5.33,1.31,8.03,.79Z"
        fill="#71adee"
      />
      <path
        d="M67.57,47.65c.56,3.12,1.69,5.77,2.81,8.2,.35,.78,.65,1.42,.86,1.83,5.14,3.81,11.19,6.07,17.32,7.57,1.75,.43,3.25,1.2,5.06,1.43-.46-1.42-.46-2.95,0-4.36,.4-1.2,.56-1.63,.97-2.09-2.73-1.25-5.1-3.08-8.09-4.75-2.87-1.6-5.53-3.22-7.95-4.8-.79-2.62-1.09-2.09-2.25-7.16-.81,.47-4.95,3.06-8.74,4.12Z"
        fill="#abe0f6"
      />
      <path
        d="M67.39,39.45c-.03,.38-.08,1.29-.13,3.1-.04,1.14,0,2.29,.1,3.42v.1c0,.25,.04,.49,.08,.73,0,.07,0,.15,.03,.22,0,.19,.06,.38,.09,.56v.06c2.71-.85,5.33-1.99,7.81-3.39,.62-.34,1.06-.6,1.27-.73-3.64-8.55-7.76-14.48-9.49-15.67-1.87-1.29-3.12-1.09-3.12-1.09-.38,0-.76,.06-1.12,.16,3.88,7.59,4.61,10.91,4.49,12.52Z"
        fill="#0064dc"
      />
      <path
        d="M67.57,47.65c.56,3.12,1.69,5.77,2.81,8.2,.35,.78,.65,1.42,.86,1.83,8.89,6.55,22.23,9.47,22.39,9.03,0-.06-14.78-5.03-18.43-8.42-.41-.34-.72-.78-.92-1.27-.56-1.62,.96-2.71,1.47-5.85,.13-.82,.18-1.64,.15-2.47,0-1.5-.17-2.99-.51-4.45q-1.07,.58-7.82,3.39Z"
        fill="#71adee"
      />
      <path
        d="M67.36,45.97v.1c0,.25,.05,.49,.08,.73,0,.07,0,.15,.03,.22,0,.19,.06,.38,.09,.56v.06c2.71-.85,5.33-1.99,7.81-3.39l-.11-.25c-.89-2.07-2.55-5.87-4.56-8.71-.43-.62-3.94-5.53-5.33-4.94-.92,.37-1.24,3.34,2,15.61Z"
        fill="#2f455f"
      />
      <path
        d="M48.69,83.21v-.03c-.28-.4-.22-.94-.07-1.66,1.2-7.06-1.09-12.8-.51-19.18-.15-.34-.26-.56-.76-1.83-1.25-3.21-6.13-9.45-8.22-21.75,.94-1.7,2.14-3.24,3.55-4.57,1.05-.98,3.37-2.91,12.94-5.68,7.97-2.3,9.2-1.78,9.79-1.45,2.53,1.41,1.54,3.3,4.62,9.03,2.46,4.55,4.3,5.64,5.11,8.84,.8,3.2-.19,3.95-1.18,7.7-1.3,5-3.09,9.28,.39,13.35,0,.04,.06,.06,.07,.1,.28,.35,.6,.66,.95,.93,.37,.32,.65,.56,.85,.69,.06,.05,.12,.1,.17,.15,4.93,3.7-17.86,25.26-27.7,15.37Z"
        fill="#0064dc"
      />
      <path
        d="M48.69,83.2v-.03c-.14-.21-.21-.46-.2-.71,0-.32,.04-.65,.12-.96,.35-1.04-5.06-32.94-1.37-34.56,.83-.37,2.09,.84,3.37,2.06,4.16,3.99,1.6,15.09,16.61,8.29,2.52-1.14,4.8-2.74,6.75-4.71-.56,1.06-1.33,3.61-1.6,7.02-.13,1.14-.02,2.29,.32,3.38,.34,1.09,.91,2.1,1.67,2.95,0,.04,.06,.06,.07,.1,.56,.91,.91,.71,1.97,1.77,4.89,3.72-17.89,25.29-27.73,15.4Z"
        fill="#2f455f"
      />
      <path
        d="M38.2,56.43c.34,5.77,.13,9.47,1.12,12.89,.6,2.07,1.6,3.99,2.94,5.67,0,0,1.72,1.44,5.2,1.35,.5-.01,1-.05,1.49-.12,14.52-1.35,18.69-2.49,18.69-2.49-.2-.59-.34-1.21-.42-1.83-.05-.48-.07-.96-.04-1.44,.08-1.54,.04-2.26,.38-2.93-3.37,.49-5.53,.07-7.36,0-2.05-.07-3.37-.28-7.31-.51-1.87-.11-3.37-.17-4.36-.2,.22-2.39,.16-4.81,.22-7.3,0-.84,.06-1.69,.12-2.54-1.34,.59-2.75,1-4.2,1.21-2.33,.41-4.67-.8-6.47-1.78Z"
        fill="#abe0f6"
      />
      <path
        d="M39.3,69.33c-.56-8.75,3.68-8.66,6.09-8.89,1.15-.13,2.27-.43,3.34-.9,0-.84,.06-1.69,.12-2.54-1.34,.59-2.75,1-4.2,1.21-2.31,.38-4.65-.82-6.45-1.78,.37,5.77,.13,9.47,1.1,12.89Z"
        fill="#71adee"
      />
      <path
        d="M37.44,53.04c.08,.96,.17,2.07,.26,3.39,1.81,.96,4.64,2.16,6.95,1.78,1.6-.24,3.17-.65,4.69-1.21,.07-.85,.13-1.69,.2-2.57,.09-1.23,.18-2.49,.28-3.78,.14-1.94,.38-5.03-1.12-8.29-.56-1.13-1.55-3.3-3.86-4.57-.82-.45-2.34-1.27-3.86-.77-2.54,.84-3.22,4.95-3.63,7.7-.49,3.19-.23,4.57,.11,8.33Z"
        fill="#0064dc"
      />
      <path
        d="M61.91,27.05c.05,.23,.61,2.53,.84,3.49,.15,.62,.08,1.28-.19,1.86l-2.18,4.57s-5.62-.9-8.92-3.1c-3.29-2.2-3.74-3.78-3.74-3.78,4.74-3.52,3.09-2.42,7.22-4.83l6.96,1.79Z"
        fill="#2f455f"
      />
      <path
        d="M61.75,32.12c-.51,1.01-1.25,2.31-1.83,3.28-7.37-1.22-10.8-5.2-10.8-5.2,0-.39,.67-2.72,.91-3.95,.26-1.37,1.98-10.3,3.43-10.36,.1-.01,.2-.01,.3,0h.05c.39,.07,.75,.22,1.09,.43,1.69,.99,4.3,3.8,5.45,6.84,0,.05,.25,.68,.25,.73,.51,1.45,.71,4.05,1.12,5.55,.25,1.09,.53,1.69,.05,2.69Z"
        fill="#ffcfcd"
      />
      <path
        d="M61.76,32.12c-.51,1.01-1.25,2.31-1.83,3.28-7.37-1.22-10.8-5.2-10.8-5.2,0-.5,.91-5.03,2.45-10.64,.56-1.91,1.12-3.39,1.69-3.59,.16-.07,.34-.09,.51-.07,.03,0,.06,0,.09,0,.36,.08,.71,.22,1.03,.42,1.52,.89,3.37,3.08,3.94,4,.31,.45,.59,.93,.84,1.43,.22,.41,.4,.84,.56,1.2,.79,1.96,.64,2.26,1.5,6.48,.28,1.3,.48,1.75,.02,2.7Z"
        fill="#71adee"
      />
      <path
        d="M61.99,31.45c-.04,.23-.12,.46-.23,.67-.51,1.01-1.25,2.31-1.83,3.28-1.84-.29-3.64-.81-5.35-1.55h4.44s1.56-1.29,2.96-2.4Z"
        fill="#71adee"
      />
      <path
        d="M43.79,11.35c.76,2.6,1.34,4.51,1.88,6.35,.56,2,.93,3.19,2.06,4.16,.82,.71,1.83,1.18,2.91,1.34,1.49,2.14,3.68,3.69,6.19,4.38,.14,0,.26,.07,.4,.08,1.62,.38,4.54,.42,5.89-.61,1.63-1.25,2.81-5.74,3.08-7.79,.08-.78,.1-2.05,.1-4.58,0-1.87-.2-4.7-1.18-6.27-.55-.86-1.29-1.58-2.15-2.12-3.37-.67-6.75-1.34-10.12-2.01l-9.04,7.06Z"
        fill="#abe0f6"
      />
      <path
        d="M46.85,19.46c.17,.94,.63,1.8,1.31,2.47,.68,.67,1.55,1.11,2.49,1.27,1.28,1.82,3.07,3.23,5.14,4.03-1.96-3.39-2.59-7.15-1.32-10.94,2.1,0,4.15-.69,5.81-1.98,1.67-1.29,2.86-3.1,3.38-5.14-3.16-.92-6.55-.69-9.56,.65-3.43,1.57-7.73,6.45-7.25,9.64Z"
        fill="#71adee"
      />
      <path
        d="M66.06,20.19c.29-2.71,.09-6.72-1.36-9.03-.35-.55-.77-1.05-1.25-1.5-3.55,6.65-9.9,5.48-10.26,5.57-1.46,.39-.83,7.48-1.07,7.5-.15,0-.98-2.99-1.24-4.35,0-.17-.03-.33-.1-.48-.07-.15-.17-.29-.29-.4-.22-.24-.51-.42-.83-.5-.98-.24-1.93,.66-2.25,1.69-.76,2.26,1.03,3.7,3.25,4.51,.31,.95,.42,1.96,.32,2.96-.1,1-.4,1.96-.89,2.84-1.43,2.41-3.5,4.8-6.5,4.36,2.64-4.64-.03-13.87-.16-14.73-.44-3.15-.87-6.14,.1-9.4C46.81-1.79,57.53-1.58,63.02,2.88c.28,.23,.56,.47,.81,.72,4.04,3.95,5.14,10.69,2.23,16.59Z"
        fill="#ffdc1e"
      />
      <path
        d="M63.88,3.59c-3.19-2.26-7.28-2.3-11.03,.7-3.37,2.69-4.72,7.64-3.68,12.71-.41,.1-.79,.32-1.1,.61-.31,.3-.53,.67-.65,1.08-.76,2.26,1.03,3.7,3.25,4.51,.31,.95,.42,1.96,.32,2.96-.1,1-.4,1.96-.89,2.84-1.43,2.41-3.5,4.8-6.5,4.36,2.64-4.64-.03-13.87-.16-14.73-.44-3.15-.87-6.14,.1-9.4C46.82-1.8,57.54-1.6,63.04,2.87c.28,.23,.55,.47,.84,.72Z"
        fill="#e39f3a"
      />
      <path
        d="M112.41,70.98s.06-.64-.83-1.16l-19.3-11.26c-.62-.32-1.31-.48-2.01-.48s-1.38,.17-2.01,.48l-29.4,17.05c-.2,.11-.38,.26-.51,.44-.14,.18-.24,.39-.29,.61-.11,1.26-.25,1.45,.8,2.26,1.66,1.26,6.34,3.8,19.3,11.35,.53,.27,1.12,.43,1.71,.48,.6,.04,1.2-.04,1.76-.23,.42-.15-1.53,.95,29.93-17.27,1.01-.6,.83-1.13,.83-2.27Z"
        fill="#2f455f"
      />
      <path
        d="M58.87,75.61c-1.12,.64-1.12,1.69,0,2.33l19.29,11.21c.62,.32,1.31,.48,2.01,.48s1.38-.17,2.01-.48l29.4-17.03c1.12-.64,1.12-1.69,0-2.32l-19.3-11.26c-.62-.32-1.31-.48-2.01-.48s-1.38,.17-2.01,.48l-29.39,17.07Z"
        fill="#0064dc"
      />
      <path
        d="M103.33,69.82c-.6-.37-1.06-.56-.64-.76,.59-.33,1.16-.78,1.54-.56,.6,.37,1.06,.54,.64,.77-.59,.34-1.16,.79-1.54,.56Z"
        fill="#2f455f"
      />
      <path
        d="M101.36,70.91c-.6-.37-1.06-.56-.64-.76,.59-.33,1.16-.78,1.54-.56,.6,.37,1.06,.56,.64,.77-.59,.34-1.16,.79-1.53,.56Z"
        fill="#2f455f"
      />
      <path
        d="M100.86,69.37c-.6-.37-1.05-.56-.64-.77,1.45-.8,2.04-1.29,2.44-1.04,.6,.37,1.06,.53,.64,.76-1.46,.81-2.04,1.29-2.44,1.05Z"
        fill="#2f455f"
      />
      <path
        d="M98.81,68.69c-.6-.37-1.05-.53-.64-.76,2-1.13,2.53-1.56,2.93-1.32,.56,.36,1.06,.53,.63,.76-2,1.11-2.53,1.57-2.93,1.32Z"
        fill="#2f455f"
      />
      <path
        d="M99.39,72c-.6-.37-1.06-.56-.64-.76,.59-.33,1.16-.78,1.54-.56,.6,.37,1.06,.54,.64,.77-.59,.34-1.16,.79-1.54,.56Z"
        fill="#2f455f"
      />
      <path
        d="M99.37,70.47c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-.6-.36-1.06-.53-.64-.76,.59-.33,1.16-.78,1.54-.56,1.09,.71,1.11,.52-.33,1.33Z"
        fill="#2f455f"
      />
      <path
        d="M97.37,69.79c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-1.12-.68-1.12-.5,.32-1.3,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c1.11,.68,1.13,.5-.32,1.3Z"
        fill="#2f455f"
      />
      <path
        d="M96.13,68.36c-.6-.37-1.06-.56-.64-.77,4.27-2.36,3.49-2.26,4.75-1.51,.15,.09,.12,.24-.06,.34-3.27,1.81-3.65,2.18-4.05,1.94Z"
        fill="#2f455f"
      />
      <path
        d="M97.18,66.27c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-1.12-.7-1.19-.46,.81-1.56,.09-.04,.18-.06,.28-.06s.19,.02,.28,.06c1.16,.71,1.19,.46-.81,1.56Z"
        fill="#2f455f"
      />
      <path
        d="M97.42,73.09c-.56-.36-1.06-.53-.64-.76,.56-.33,1.16-.78,1.54-.56,.56,.36,1.06,.54,.64,.77-.59,.33-1.16,.79-1.54,.56Z"
        fill="#2f455f"
      />
      <path
        d="M96.83,71.58c-1.12-.67-1.12-.49,.32-1.29,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c.6,.36,1.06,.53,.64,.76-.57,.31-1.14,.76-1.52,.53Z"
        fill="#2f455f"
      />
      <path
        d="M95.4,70.88c-.09,.04-.18,.06-.28,.06s-.19-.02-.28-.06c-.56-.36-1.06-.56-.64-.77,.59-.33,1.16-.78,1.54-.56,1.09,.72,1.11,.53-.33,1.33Z"
        fill="#2f455f"
      />
      <path
        d="M94.77,69.44c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-.6-.37-1.06-.56-.64-.77,.59-.32,1.16-.78,1.54-.56,1.09,.74,1.11,.54-.33,1.33Z"
        fill="#2f455f"
      />
      <path
        d="M95.45,74.18c-.6-.37-1.06-.54-.64-.77,.56-.32,1.16-.78,1.54-.56,.56,.36,1.06,.54,.64,.77-.59,.34-1.16,.8-1.54,.56Z"
        fill="#2f455f"
      />
      <path
        d="M94.86,72.66c-1.12-.67-1.12-.49,.32-1.29,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c.6,.36,1.06,.53,.64,.76-.57,.31-1.14,.76-1.52,.53Z"
        fill="#2f455f"
      />
      <path
        d="M92.85,71.99c-.6-.37-1.06-.54-.64-.77,.59-.33,1.16-.78,1.54-.56,.6,.37,1.05,.56,.64,.76-.59,.34-1.16,.81-1.54,.57Z"
        fill="#2f455f"
      />
      <path
        d="M92.21,70.54c-.6-.37-1.05-.56-.64-.77,.56-.32,1.16-.78,1.54-.56,.56,.36,1.06,.53,.64,.76-.59,.36-1.16,.81-1.54,.57Z"
        fill="#2f455f"
      />
      <path
        d="M90.66,69.6c-.56-.36-1.06-.53-.64-.77,.59-.32,1.16-.78,1.54-.56,.6,.37,1.05,.56,.64,.77-.59,.34-1.16,.8-1.54,.56Z"
        fill="#2f455f"
      />
      <path
        d="M93.48,75.27c-.6-.37-1.06-.54-.64-.77,.59-.32,1.16-.78,1.54-.56,.6,.37,1.06,.54,.64,.77-.59,.35-1.16,.8-1.54,.56Z"
        fill="#2f455f"
      />
      <path
        d="M93.47,73.74c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-1.12-.67-1.12-.49,.31-1.29,.09-.04,.18-.06,.28-.06s.19,.02,.28,.06c1.11,.67,1.13,.49-.31,1.29Z"
        fill="#2f455f"
      />
      <path
        d="M91.47,73.05c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-.6-.37-1.06-.53-.64-.76,.59-.33,1.16-.78,1.54-.56,1.09,.71,1.11,.5-.33,1.33Z"
        fill="#2f455f"
      />
      <path
        d="M89.54,71.22c-.14-.09-.11-.24,.07-.34,.56-.32,1.16-.78,1.54-.56,.56,.35,1.06,.53,.64,.76-1.47,.83-1.15,.8-2.24,.14Z"
        fill="#2f455f"
      />
      <path
        d="M91.52,76.35c-.6-.37-1.06-.54-.64-.77,1.46-.81,1.15-.78,2.25-.12,.15,.09,.12,.24-.06,.33-.6,.33-1.17,.78-1.55,.55Z"
        fill="#2f455f"
      />
      <path
        d="M90.92,74.84c-.6-.37-1.06-.54-.64-.76,.56-.33,1.16-.78,1.54-.56,.56,.36,1.06,.54,.64,.77-.59,.33-1.16,.77-1.54,.56Z"
        fill="#2f455f"
      />
      <path
        d="M88.92,74.16c-.6-.37-1.06-.53-.64-.76,1.46-.81,1.15-.78,2.25-.12,.14,.08,.11,.24-.06,.33-.6,.31-1.17,.78-1.55,.55Z"
        fill="#2f455f"
      />
      <path
        d="M88.28,72.72c-.56-.36-1.06-.54-.64-.77,.59-.33,1.16-.78,1.54-.56,.6,.37,1.05,.56,.64,.76-.59,.34-1.16,.8-1.54,.57Z"
        fill="#2f455f"
      />
      <path
        d="M89.55,77.44c-1.12-.68-1.12-.5,.31-1.3,.09-.04,.18-.06,.28-.06s.19,.02,.28,.06c.6,.37,1.06,.56,.64,.76-.57,.31-1.14,.76-1.52,.54Z"
        fill="#2f455f"
      />
      <path
        d="M88.95,75.93c-.6-.37-1.06-.54-.64-.76,.56-.33,1.16-.78,1.54-.56,.6,.37,1.06,.56,.64,.77-.59,.34-1.16,.81-1.54,.56Z"
        fill="#2f455f"
      />
      <path
        d="M86.94,75.25c-.56-.35-1.06-.53-.64-.76,1.46-.81,1.15-.78,2.25-.12,.14,.08,.11,.24-.06,.33-.6,.35-1.17,.78-1.55,.55Z"
        fill="#2f455f"
      />
      <path
        d="M86.31,73.82c-.6-.37-1.06-.54-.64-.77,1.46-.81,1.15-.78,2.25-.12,.14,.08,.11,.24-.06,.33-.6,.33-1.17,.78-1.55,.55Z"
        fill="#2f455f"
      />
      <path
        d="M87.58,78.54c-1.12-.68-1.12-.5,.32-1.3,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c.6,.37,1.06,.54,.64,.76-.57,.31-1.14,.76-1.52,.54Z"
        fill="#2f455f"
      />
      <path
        d="M86.98,77.02c-.6-.37-1.06-.56-.64-.76,1.46-.81,1.15-.78,2.25-.12,.14,.09,.11,.24-.06,.34-.6,.32-1.17,.78-1.55,.55Z"
        fill="#2f455f"
      />
      <path
        d="M84.98,76.33c-.56-.35-1.06-.53-.64-.76,.59-.33,1.16-.78,1.54-.56,.56,.36,1.06,.56,.64,.77-.59,.33-1.16,.79-1.54,.56Z"
        fill="#2f455f"
      />
      <path
        d="M83.64,74.49c-.14-.08-.11-.24,.07-.33,.59-.33,1.16-.78,1.54-.56,.6,.37,1.05,.56,.64,.76-1.47,.81-1.15,.78-2.24,.14Z"
        fill="#2f455f"
      />
      <path
        d="M85.61,79.62c-1.12-.68-1.12-.5,.31-1.3,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c.56,.35,1.06,.53,.64,.76-.57,.31-1.14,.76-1.52,.54Z"
        fill="#2f455f"
      />
      <path
        d="M85.01,78.12c-1.12-.68-1.12-.5,.32-1.29,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c.6,.37,1.06,.54,.64,.77-.57,.29-1.14,.76-1.52,.52Z"
        fill="#2f455f"
      />
      <path
        d="M83,77.43c-1.12-.68-1.12-.49,.32-1.29,.09-.04,.18-.06,.28-.06s.19,.02,.28,.06c.6,.37,1.05,.56,.64,.77-.56,.3-1.13,.76-1.52,.52Z"
        fill="#2f455f"
      />
      <path
        d="M82.37,75.98c-1.12-.67-1.12-.49,.32-1.29,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c.56,.35,1.06,.53,.64,.76-.57,.31-1.15,.76-1.52,.53Z"
        fill="#2f455f"
      />
      <path
        d="M83.64,80.69c-.6-.37-1.06-.54-.64-.77,.56-.32,1.16-.78,1.54-.56,.56,.35,1.06,.53,.64,.76-.59,.36-1.16,.81-1.54,.57Z"
        fill="#2f455f"
      />
      <path
        d="M83.05,79.2c-1.12-.68-1.12-.5,.31-1.29,.4-.23,.65,0,1.28,.41,.14,.09,.11,.24-.06,.34-.59,.35-1.16,.78-1.54,.55Z"
        fill="#2f455f"
      />
      <path
        d="M81.04,78.52c-1.12-.68-1.12-.5,.32-1.29,.09-.04,.18-.06,.28-.06s.19,.02,.28,.06c.6,.37,1.05,.54,.64,.77-.57,.3-1.14,.76-1.52,.52Z"
        fill="#2f455f"
      />
      <path
        d="M79.7,76.65c-.14-.08-.11-.24,.07-.33,.56-.33,1.16-.78,1.54-.56,.56,.36,1.06,.56,.64,.77-1.47,.82-1.15,.79-2.24,.13Z"
        fill="#2f455f"
      />
      <path
        d="M78.5,76.32c-.6-.36-1.06-.53-.64-.76,11.56-6.4,11.33-6.42,11.7-6.15,.6,.37,1.05,.56,.64,.76-11.58,6.38-11.31,6.4-11.7,6.15Z"
        fill="#2f455f"
      />
      <path
        d="M80.97,81.37c-.15-.08-.12-.24,.06-.33,.59-.33,1.16-.78,1.54-.56,.6,.37,1.06,.53,.64,.76-1.46,.83-1.15,.8-2.24,.14Z"
        fill="#2f455f"
      />
      <path
        d="M81.08,80.29c-.6-.37-1.06-.54-.64-.77,.56-.32,1.16-.78,1.54-.56,.56,.36,1.06,.56,.64,.77-.59,.34-1.16,.8-1.54,.56Z"
        fill="#2f455f"
      />
      <path
        d="M79.63,79.57c-.09,.04-.18,.06-.28,.06s-.19-.02-.28-.06c-.6-.37-1.05-.54-.64-.76,.59-.33,1.16-.78,1.54-.56,1.11,.73,1.13,.55-.34,1.33Z"
        fill="#2f455f"
      />
      <path
        d="M78.43,78.17c-1.12-.67-1.12-.49,.32-1.29,.09-.04,.18-.06,.28-.06s.19,.02,.28,.06c.6,.37,1.05,.54,.64,.77-.57,.3-1.15,.76-1.52,.52Z"
        fill="#2f455f"
      />
      <path
        d="M76.49,77.43c-.6-.37-1.06-.56-.64-.76,.59-.33,1.16-.78,1.54-.56,.6,.37,1.06,.56,.64,.77-.58,.33-1.16,.79-1.54,.56Z"
        fill="#2f455f"
      />
      <path
        d="M79,82.46c-.15-.08-.12-.24,.06-.33,.59-.33,1.16-.78,1.54-.56,.6,.37,1.06,.53,.64,.76-1.46,.83-1.15,.8-2.24,.14Z"
        fill="#2f455f"
      />
      <path
        d="M78.41,80.95c-.15-.09-.12-.24,.06-.34,1.46-.81,1.15-.78,2.25-.12,.14,.08,.11,.24-.06,.33-1.48,.81-1.16,.78-2.25,.12Z"
        fill="#2f455f"
      />
      <path
        d="M77.69,80.69c-.09,.04-.18,.06-.28,.06s-.19-.02-.28-.06c-1.12-.68-1.12-.5,.32-1.29,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c1.1,.65,1.12,.47-.32,1.29Z"
        fill="#2f455f"
      />
      <path
        d="M76.46,79.25c-1.12-.68-1.12-.49,.32-1.29,.09-.04,.18-.06,.28-.06s.19,.02,.28,.06c.6,.37,1.05,.54,.64,.77-.57,.3-1.15,.76-1.52,.52Z"
        fill="#2f455f"
      />
      <path
        d="M75.08,78.51c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-.6-.37-1.05-.54-.64-.77,.56-.32,1.16-.78,1.54-.56,1.09,.69,1.11,.53-.34,1.33Z"
        fill="#2f455f"
      />
      <path
        d="M76.81,84.5c-.6-.37-1.06-.56-.64-.77,1.46-.8,2.04-1.29,2.44-1.04,.6,.37,1.05,.53,.64,.76-1.47,.8-2.06,1.29-2.44,1.05Z"
        fill="#2f455f"
      />
      <path
        d="M77.72,82.45c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-1.12-.68-1.12-.5,.32-1.3,.09-.04,.18-.06,.28-.06s.19,.02,.28,.06c1.11,.68,1.12,.5-.31,1.3Z"
        fill="#2f455f"
      />
      <path
        d="M75.75,83.52c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-1.12-.68-1.12-.5,.32-1.3,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c1.11,.7,1.13,.52-.32,1.3Z"
        fill="#2f455f"
      />
      <path
        d="M73.63,82.62c-.6-.37-1.05-.53-.64-.76,2.13-1.18,2.64-1.62,3.04-1.38,.6,.37,1.05,.56,.64,.77-2.11,1.18-2.64,1.62-3.04,1.38Z"
        fill="#2f455f"
      />
      <path
        d="M72.09,81.68c-.6-.37-1.06-.56-.64-.77,3.14-1.74,3.54-2.12,3.94-1.88,.6,.37,1.05,.54,.64,.77-3.16,1.74-3.54,2.12-3.94,1.88Z"
        fill="#2f455f"
      />
      <path
        d="M72.5,79.63c-.6-.37-1.05-.56-.64-.76,.59-.33,1.16-.78,1.54-.56,.6,.37,1.05,.56,.64,.77-.6,.34-1.16,.79-1.54,.56Z"
        fill="#2f455f"
      />
      <path
        d="M70.5,80.74c-1.12-.68-1.12-.5,.32-1.29,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c.56,.36,1.06,.54,.64,.77-.57,.3-1.15,.76-1.52,.52Z"
        fill="#2f455f"
      />
      <path
        d="M95.23,67.37c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-1.12-.68-1.12-.5,.31-1.3,.09-.04,.18-.06,.28-.06s.19,.02,.28,.06c1.11,.68,1.13,.52-.32,1.3Z"
        fill="#2f455f"
      />
      <path
        d="M91.95,68.06c-.14-.08-.11-.24,.06-.33,1.47-.81,1.12-.78,2.25-.12,.15,.08,.12,.24-.06,.33-1.47,.81-1.16,.78-2.25,.12Z"
        fill="#2f455f"
      />
      <path
        d="M103.33,69.62c-.6-.37-1.06-.54-.64-.77,.59-.32,1.16-.78,1.54-.56,.6,.37,1.06,.56,.64,.76-.59,.36-1.16,.8-1.54,.57Z"
        fill="#c6e9f8"
      />
      <path
        d="M101.36,70.71c-.6-.37-1.06-.56-.64-.77,.59-.33,1.16-.78,1.54-.56,.6,.37,1.06,.56,.64,.76-.59,.34-1.16,.8-1.53,.57Z"
        fill="#c6e9f8"
      />
      <path
        d="M100.86,69.17c-.6-.37-1.05-.53-.64-.76,1.45-.8,2.04-1.29,2.44-1.05,.6,.37,1.06,.56,.64,.77-1.46,.8-2.04,1.29-2.44,1.04Z"
        fill="#c6e9f8"
      />
      <path
        d="M98.81,68.49c-.6-.37-1.05-.56-.64-.77,2-1.13,2.53-1.56,2.93-1.32,.56,.36,1.06,.56,.63,.77-2,1.11-2.53,1.56-2.93,1.32Z"
        fill="#c6e9f8"
      />
      <path
        d="M99.39,71.8c-.6-.37-1.06-.54-.64-.77,.59-.33,1.16-.78,1.54-.56,.6,.37,1.06,.56,.64,.76-.59,.34-1.16,.8-1.54,.57Z"
        fill="#c6e9f8"
      />
      <path
        d="M99.37,70.27c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-.6-.37-1.06-.56-.64-.77,.59-.32,1.16-.78,1.54-.56,1.09,.72,1.11,.54-.33,1.33Z"
        fill="#c6e9f8"
      />
      <path
        d="M97.37,69.59c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-1.12-.68-1.12-.49,.32-1.29,.09-.04,.18-.06,.28-.06s.19,.02,.28,.06c1.11,.67,1.13,.49-.32,1.29Z"
        fill="#c6e9f8"
      />
      <path
        d="M96.13,68.16c-.6-.37-1.06-.56-.64-.77,4.27-2.36,3.49-2.26,4.75-1.51,.15,.08,.12,.24-.06,.33-3.27,1.81-3.65,2.18-4.05,1.94Z"
        fill="#c6e9f8"
      />
      <path
        d="M97.18,66.06c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-1.12-.71-1.19-.46,.81-1.57,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c1.16,.71,1.19,.47-.81,1.57Z"
        fill="#c6e9f8"
      />
      <path
        d="M97.42,72.88c-.56-.35-1.06-.53-.64-.76,.56-.33,1.16-.78,1.54-.56,.56,.35,1.06,.53,.64,.76-.59,.34-1.16,.8-1.54,.56Z"
        fill="#c6e9f8"
      />
      <path
        d="M96.83,71.38c-1.12-.68-1.12-.5,.32-1.29,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c.6,.37,1.06,.56,.64,.77-.57,.3-1.14,.76-1.52,.52Z"
        fill="#c6e9f8"
      />
      <path
        d="M95.4,70.68c-.09,.04-.18,.06-.28,.06s-.19-.02-.28-.06c-.56-.36-1.06-.53-.64-.76,.59-.33,1.16-.78,1.54-.56,1.09,.71,1.11,.52-.33,1.33Z"
        fill="#c6e9f8"
      />
      <path
        d="M94.77,69.24c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-.6-.37-1.06-.56-.64-.76,.59-.33,1.16-.78,1.54-.56,1.09,.71,1.11,.52-.33,1.33Z"
        fill="#c6e9f8"
      />
      <path
        d="M95.45,73.97c-.6-.36-1.06-.53-.64-.76,.56-.33,1.16-.78,1.54-.56,.56,.35,1.06,.53,.64,.76-.59,.34-1.16,.8-1.54,.56Z"
        fill="#c6e9f8"
      />
      <path
        d="M94.86,72.47c-1.12-.68-1.12-.5,.31-1.29,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c.6,.37,1.06,.56,.64,.76-.57,.29-1.14,.76-1.52,.53Z"
        fill="#c6e9f8"
      />
      <path
        d="M92.85,71.78c-.6-.37-1.06-.54-.64-.76,.59-.33,1.16-.78,1.54-.56,.6,.37,1.05,.56,.64,.77-.59,.33-1.16,.79-1.54,.56Z"
        fill="#c6e9f8"
      />
      <path
        d="M92.21,70.34c-.6-.37-1.05-.56-.64-.76,.56-.33,1.16-.78,1.54-.56,.56,.36,1.06,.56,.64,.77-.59,.34-1.16,.79-1.54,.56Z"
        fill="#c6e9f8"
      />
      <path
        d="M90.66,69.41c-.56-.35-1.06-.53-.64-.76,.59-.33,1.16-.78,1.54-.56,.6,.36,1.05,.53,.64,.76-.59,.33-1.16,.78-1.54,.56Z"
        fill="#c6e9f8"
      />
      <path
        d="M93.48,75.06c-.6-.36-1.06-.53-.64-.76,.59-.33,1.16-.78,1.54-.56,.6,.37,1.06,.53,.64,.76-.59,.36-1.16,.81-1.54,.56Z"
        fill="#c6e9f8"
      />
      <path
        d="M93.47,73.53c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-1.12-.68-1.12-.5,.31-1.3,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c1.11,.68,1.13,.5-.31,1.3Z"
        fill="#c6e9f8"
      />
      <path
        d="M91.47,72.85c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-.6-.37-1.06-.54-.64-.77,.59-.32,1.16-.78,1.54-.56,1.09,.71,1.11,.54-.33,1.33Z"
        fill="#c6e9f8"
      />
      <path
        d="M89.54,71.01c-.14-.08-.11-.24,.07-.33,.56-.33,1.16-.78,1.54-.56,.56,.36,1.06,.54,.64,.77-1.47,.82-1.15,.79-2.24,.13Z"
        fill="#c6e9f8"
      />
      <path
        d="M91.52,76.18c-.6-.36-1.06-.54-.64-.76,1.46-.81,1.15-.78,2.25-.12,.15,.08,.12,.23-.06,.33-.6,.3-1.17,.75-1.55,.55Z"
        fill="#c6e9f8"
      />
      <path
        d="M90.92,74.64c-.6-.37-1.06-.54-.64-.77,.56-.32,1.16-.78,1.54-.56,.56,.35,1.06,.53,.64,.76-.59,.34-1.16,.8-1.54,.57Z"
        fill="#c6e9f8"
      />
      <path
        d="M88.92,73.96c-.6-.37-1.06-.54-.64-.77,1.46-.81,1.15-.78,2.25-.12,.14,.08,.11,.24-.06,.34-.6,.32-1.17,.78-1.55,.55Z"
        fill="#c6e9f8"
      />
      <path
        d="M88.28,72.52c-.56-.36-1.06-.53-.64-.76,.59-.33,1.16-.78,1.54-.56,.6,.37,1.05,.56,.64,.77-.59,.33-1.16,.79-1.54,.56Z"
        fill="#c6e9f8"
      />
      <path
        d="M89.55,77.24c-1.12-.68-1.12-.49,.31-1.29,.09-.04,.18-.06,.28-.06s.19,.02,.28,.06c.6,.37,1.06,.54,.64,.77-.57,.3-1.14,.76-1.52,.52Z"
        fill="#c6e9f8"
      />
      <path
        d="M88.95,75.73c-.6-.37-1.06-.56-.64-.77,.56-.33,1.16-.78,1.54-.56,.6,.37,1.06,.56,.64,.76-.59,.34-1.16,.8-1.54,.57Z"
        fill="#c6e9f8"
      />
      <path
        d="M86.94,75.06c-.56-.36-1.06-.54-.64-.77,1.46-.81,1.15-.78,2.25-.12,.14,.08,.11,.24-.06,.33-.6,.33-1.17,.78-1.55,.55Z"
        fill="#c6e9f8"
      />
      <path
        d="M86.31,73.61c-.6-.37-1.06-.54-.64-.76,1.46-.81,1.15-.78,2.25-.12,.14,.09,.11,.24-.06,.34-.6,.32-1.17,.78-1.55,.55Z"
        fill="#c6e9f8"
      />
      <path
        d="M87.58,78.33c-1.12-.68-1.12-.5,.32-1.29,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c.6,.37,1.06,.56,.64,.77-.57,.3-1.14,.76-1.52,.52Z"
        fill="#c6e9f8"
      />
      <path
        d="M86.98,76.82c-.6-.37-1.06-.54-.64-.77,1.46-.81,1.15-.78,2.25-.12,.14,.08,.11,.24-.06,.33-.6,.33-1.17,.78-1.55,.55Z"
        fill="#c6e9f8"
      />
      <path
        d="M84.98,76.14c-.56-.36-1.06-.56-.64-.77,.59-.33,1.16-.78,1.54-.56,.56,.35,1.06,.53,.64,.76-.59,.34-1.16,.8-1.54,.57Z"
        fill="#c6e9f8"
      />
      <path
        d="M83.64,74.28c-.14-.09-.11-.24,.07-.34,.59-.32,1.16-.78,1.54-.56,.6,.37,1.05,.54,.64,.77-1.47,.82-1.15,.8-2.24,.14Z"
        fill="#c6e9f8"
      />
      <path
        d="M85.61,79.42c-1.12-.68-1.12-.5,.31-1.29,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c.56,.36,1.06,.56,.64,.77-.57,.3-1.14,.76-1.52,.52Z"
        fill="#c6e9f8"
      />
      <path
        d="M85.01,77.9c-1.12-.67-1.12-.49,.32-1.29,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c.6,.36,1.06,.53,.64,.76-.57,.31-1.14,.76-1.52,.53Z"
        fill="#c6e9f8"
      />
      <path
        d="M83,77.23c-1.12-.68-1.12-.5,.32-1.3,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c.6,.37,1.05,.54,.64,.76-.56,.31-1.13,.76-1.52,.54Z"
        fill="#c6e9f8"
      />
      <path
        d="M82.37,75.79c-1.12-.68-1.12-.5,.32-1.29,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c.56,.36,1.06,.56,.64,.76-.57,.3-1.15,.76-1.52,.53Z"
        fill="#c6e9f8"
      />
      <path
        d="M83.64,80.51c-.6-.37-1.06-.54-.64-.77,.56-.32,1.16-.78,1.54-.56,.56,.36,1.06,.54,.64,.77-.59,.34-1.16,.8-1.54,.56Z"
        fill="#c6e9f8"
      />
      <path
        d="M83.05,79c-1.12-.67-1.12-.49,.31-1.29,.4-.23,.65,0,1.28,.41,.14,.08,.11,.24-.06,.33-.59,.32-1.16,.77-1.54,.55Z"
        fill="#c6e9f8"
      />
      <path
        d="M81.04,78.32c-1.12-.68-1.12-.5,.32-1.3,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c.6,.37,1.05,.54,.64,.76-.57,.31-1.14,.76-1.52,.54Z"
        fill="#c6e9f8"
      />
      <path
        d="M79.7,76.45c-.14-.09-.11-.24,.07-.34,.56-.32,1.16-.78,1.54-.56,.56,.36,1.06,.53,.64,.76-1.47,.83-1.15,.8-2.24,.14Z"
        fill="#c6e9f8"
      />
      <path
        d="M78.5,76.12c-.6-.37-1.06-.56-.64-.77,11.57-6.4,11.31-6.42,11.71-6.17,.6,.37,1.05,.56,.64,.77-11.59,6.4-11.32,6.42-11.71,6.17Z"
        fill="#c6e9f8"
      />
      <path
        d="M80.97,81.17c-.15-.09-.12-.24,.06-.34,.59-.32,1.16-.78,1.54-.56,.6,.37,1.06,.54,.64,.77-1.46,.82-1.15,.79-2.24,.14Z"
        fill="#c6e9f8"
      />
      <path
        d="M81.08,80.08c-.6-.36-1.06-.53-.64-.76,.56-.33,1.16-.78,1.54-.56,.56,.35,1.06,.53,.64,.76-.59,.34-1.16,.8-1.54,.56Z"
        fill="#c6e9f8"
      />
      <path
        d="M79.63,79.39c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-.6-.37-1.05-.53-.64-.76,.59-.33,1.16-.78,1.54-.56,1.11,.71,1.13,.52-.34,1.33Z"
        fill="#c6e9f8"
      />
      <path
        d="M78.43,77.97c-1.12-.68-1.12-.5,.32-1.3,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c.6,.37,1.05,.54,.64,.76-.57,.31-1.15,.76-1.52,.54Z"
        fill="#c6e9f8"
      />
      <path
        d="M76.49,77.23c-.6-.36-1.06-.53-.64-.76,.59-.33,1.16-.78,1.54-.56,.6,.37,1.06,.53,.64,.76-.58,.34-1.16,.8-1.54,.56Z"
        fill="#c6e9f8"
      />
      <path
        d="M79,82.25c-.15-.09-.12-.24,.06-.34,.59-.32,1.16-.78,1.54-.56,.6,.37,1.06,.56,.64,.76-1.46,.84-1.15,.8-2.24,.14Z"
        fill="#c6e9f8"
      />
      <path
        d="M78.41,80.74c-.15-.08-.12-.24,.06-.33,1.46-.81,1.15-.78,2.25-.12,.14,.08,.11,.24-.06,.33-1.48,.81-1.16,.78-2.25,.12Z"
        fill="#c6e9f8"
      />
      <path
        d="M77.69,80.47c-.09,.04-.18,.06-.28,.06s-.19-.02-.28-.06c-1.12-.67-1.12-.49,.32-1.29,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c1.1,.67,1.12,.49-.32,1.29Z"
        fill="#c6e9f8"
      />
      <path
        d="M76.46,79.05c-1.12-.68-1.12-.5,.32-1.3,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c.6,.37,1.05,.54,.64,.76-.57,.31-1.15,.76-1.52,.54Z"
        fill="#c6e9f8"
      />
      <path
        d="M75.08,78.31c-.09,.04-.18,.06-.28,.06s-.19-.02-.28-.06c-.6-.37-1.05-.54-.64-.76,.56-.33,1.16-.78,1.54-.56,1.09,.71,1.11,.52-.34,1.33Z"
        fill="#c6e9f8"
      />
      <path
        d="M76.81,84.3c-.6-.37-1.06-.54-.64-.76,1.46-.81,2.04-1.29,2.44-1.05,.6,.37,1.05,.56,.64,.76-1.47,.8-2.06,1.3-2.44,1.05Z"
        fill="#c6e9f8"
      />
      <path
        d="M77.72,82.25c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-1.12-.68-1.12-.5,.32-1.29,.09-.04,.18-.06,.28-.06s.19,.02,.28,.06c1.11,.67,1.12,.49-.31,1.29Z"
        fill="#c6e9f8"
      />
      <path
        d="M75.75,83.33c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-1.12-.68-1.12-.5,.32-1.29,.09-.04,.18-.07,.28-.07s.19,.02,.28,.07c1.11,.67,1.13,.49-.32,1.29Z"
        fill="#c6e9f8"
      />
      <path
        d="M73.63,82.42c-.6-.37-1.05-.56-.64-.77,2.13-1.17,2.64-1.62,3.04-1.38,.6,.36,1.05,.53,.64,.76-2.11,1.18-2.64,1.62-3.04,1.38Z"
        fill="#c6e9f8"
      />
      <path
        d="M72.09,81.47c-.6-.37-1.06-.53-.64-.76,3.14-1.74,3.54-2.13,3.94-1.88,.6,.37,1.05,.56,.64,.76-3.16,1.74-3.54,2.13-3.94,1.88Z"
        fill="#c6e9f8"
      />
      <path
        d="M72.5,79.43c-.6-.37-1.05-.56-.64-.77,.59-.33,1.16-.78,1.54-.56,.6,.37,1.05,.54,.64,.76-.6,.34-1.16,.8-1.54,.57Z"
        fill="#c6e9f8"
      />
      <path
        d="M70.5,80.54c-1.12-.67-1.12-.5,.32-1.29,.09-.04,.18-.06,.28-.06s.19,.02,.28,.06c.56,.36,1.06,.56,.64,.77-.57,.3-1.15,.76-1.52,.52Z"
        fill="#c6e9f8"
      />
      <path
        d="M95.23,67.15c-.09,.04-.18,.07-.28,.07s-.19-.02-.28-.07c-1.12-.68-1.12-.49,.31-1.29,.09-.04,.18-.06,.28-.06s.19,.02,.28,.06c1.11,.69,1.13,.51-.32,1.29Z"
        fill="#c6e9f8"
      />
      <path
        d="M91.95,67.86c-.14-.09-.11-.24,.06-.34,1.47-.81,1.12-.78,2.25-.12,.15,.08,.12,.24-.06,.34-1.47,.81-1.16,.78-2.25,.12Z"
        fill="#c6e9f8"
      />
      <path
        d="M81.63,64.83l4.35,2.65c.17,.11,.14,.29-.08,.41-8.2,4.51-8.11,4.64-8.56,4.36l-4.34-2.65c-.17-.11-.14-.29,.08-.41,8.2-4.54,8.11-4.64,8.56-4.36Z"
        fill="#c6e9f8"
      />
      <path
        d="M59.69,75.04c.65-.04,8.96-1.31,9.61-1.35l.97,.48c.26,.16,.54,.29,.83,.38,1.41,.42,2.97,.75,2.94,.74,3.7,.79,5.47,1.4,5.06,.21-.18-.48-.78-.62-1.37-.88-.59-.27-1.13-.64-1.59-1.09,1.42,.04,1.96,.63,2.81,1.13,.86,.5,1.93,1.2,1.98,.19,0-.32-.17-.56-.78-1.17-.91-.94-1.95-1.74-3.08-2.38,1.34,.5,2.57,1.27,3.61,2.26,.36,.43,.56,.77,.89,.78,.08,0,.15-.01,.22-.04,.07-.03,.13-.07,.19-.13,.26-.32-.03-.94-.17-1.26-.22-.47-.53-.9-.92-1.25-.94-.79-1.95-1.49-3.01-2.09,.46,.16,.9,.37,1.32,.6,.48,.23,.95,.49,1.39,.78,1.28,.89,1.27,1.3,1.69,1.13,.08-.05,.15-.11,.2-.2,.05-.08,.08-.18,.08-.27,0-.18-.07-.35-.17-.5-.12-.23-.2-.38-.3-.63-.56-.92-1.41-1.63-2.42-2-.62-.25-1.22-.54-1.79-.89-.27-.2-.53-.41-.78-.64l-.07-.07c-.57-.43-1.28-.63-2-.56-.97,0-3.72,.52-7.42,1.23-.07-.02-7.18,5.29-7.91,7.46Z"
        fill="#abe0f6"
      />
      <path
        d="M81.69,62.17c-.62,.4-1.13,.94-1.5,1.58-.37,.64-.58,1.36-.62,2.09l.07,23.64c0,1.35,.96,1.9,2.13,1.22l31.04-17.97c.62-.4,1.13-.95,1.5-1.59,.37-.64,.58-1.36,.62-2.09l-.07-23.64c0-1.35-.96-1.9-2.13-1.22l-31.04,17.98Z"
        fill="#0064dc"
      />
      <path
        d="M79.06,65.16c.1-.69,.34-1.34,.73-1.92,.84-1.3-.77-.13,32.4-19.34,1.07-.62,1.04-.43,2.05,.15-.24-.11-.51-.15-.78-.13-.26,.03-.52,.12-.74,.27-.22,.13-31.05,17.98-31.19,18.06-.51,.38-.95,.86-1.27,1.41-.33,.55-.54,1.16-.63,1.79-.03,.3,0-1.13,.04,24.03-.03,.26,.02,.53,.12,.77,.11,.24,.28,.45,.49,.6l-.53-.29c-.21-.15-.39-.36-.49-.6-.11-.24-.15-.5-.13-.77-.07-25.21-.07-24.03-.07-24.03Z"
        fill="#2f455f"
      />
      <path
        d="M101.13,65.56c0-1.06-.85-1.45-1.9-.86-.55,.32-1.01,.76-1.36,1.29-.34,.53-.56,1.14-.63,1.77,0,1.08,.84,1.48,1.91,.87,.55-.32,1.01-.77,1.35-1.3,.34-.54,.56-1.14,.62-1.78Z"
        fill="#2f455f"
      />
      <path
        d="M30.5,164.99l.25,.08c8.31,2.52,13.39,3.55,15.35,3.98l.56,.04c4.03,.31,6.01,1.1,6.01,1.1,2.1,.43,4.17,1.02,6.19,1.76l20.81,5.88c1.44,.33,2.92,.46,4.4,.4,1.08-.1,2.25-.43,2.53-1.47,.28-1.04,.97-3.04,1.31-4.02,.7-2.01,1.48-4.22,2.25-6.4,.61-1.69,1.21-3.34,1.78-4.88-6.19-9.77-12.21-12.77-16.6-13.7-6.5-1.36-12.16,1.25-18.84-.91-10.47-3.42-17.57-8.26-26.73-6.05-.06,0-.12,.02-.17,.04-5.57,1.48-8.8,5.08-9.93,10.23-.22,1.02-.29,1.61-.43,2.75-.1,.79,4.52,5.4,11.27,11.15Z"
        fill="#71adee"
      />
      <path
        d="M30.76,165.07c.1,.43,.26,.84,.48,1.21,1.87,2.95,8.11,2.4,14.19,2.73l.67,.04,.56,.04c2.03,.21,4.04,.58,6.01,1.1,7.62,1.69,9.15,3.05,11.45,5.27,2.49,2.38,9,6.9,14.87,5.64,1.93-.46,3.71-1.45,5.12-2.85,1.19-1.17,2.25-2.48,3.14-3.89,1.59-2.41,2.6-5.15,2.96-8.01,.38-1.9,.49-6.38-2.3-8.71-1.06-.82-2.28-1.4-3.58-1.69-1.48-.35-3.02-.36-4.5-.03-2.08,.45-3.68,.87-3.98,.97-9.24,3.1-17.71,8.26-17.94,8.34,1-1.32,1.55-2.93,1.55-4.59-.99,1.14-2.13,2.15-3.37,3.01-.9,.66-1.9,1.17-2.97,1.5-.75,.2-1.52,.33-2.29,.38-3.1,.27-6.22,0-9.24-.77,.37-.67,2.96-5.47,.85-10.72-1.69-4.22-6.15-8.09-9.06-7.16-4.25,1.34-4.13,12.79-2.64,18.18Z"
        fill="#004eb6"
      />
      <path
        d="M45.43,169.01l.67,.04,.56,.04c2.03,.21,4.04,.58,6.01,1.1,7.16,1.57,8.92,2.88,10.97,4.81,1.69,1,3.84,2.08,6.14,3.55,2.31,1.47,4.02,2.67,6.75,2.82,3.49,.21,7.12-1.36,9.49-3.43,5.39-4.69,7.31-14.64,2.62-19.25-4.69-4.61-14.55-.7-18.11,1.46-5.37,3.25-7.78,6.52-7.78,6.52-1.81,3.2-5.47,3.79-8.62,2.76l1.82-2.26c-1.39,.64-2.85,1.12-4.35,1.44-2.03,.43-4.11,.56-6.18,.37Z"
        fill="#2f455f"
      />
      <path
        d="M88.27,158.68c-1.69-1.73-7.56-2.9-12.16,.21-2.19,1.56-3.83,3.78-4.67,6.34-.13,.36-2.71,7.73,1.79,13.82,1.26,1.69,2.88,3.06,4.74,4.04-2.12-6.6-4.38-7.9-4.66-11.2-.07-1.1,.05-2.21,.34-3.27,2.11-9.5,7.57-9.21,14.62-9.94Z"
        fill="#2f455f"
      />
      <path
        d="M76.53,181.39c3.49,.21,7.12-1.36,9.49-3.43l1.47-18.68c-.45,.2-9.87-1.5-12.99,5.93-3.81,9.09,1.55,11.01,2.02,16.18Z"
        fill="#2f455f"
      />
      <path
        d="M61.4,180.07c2.07,2.26,5.32,2.63,11.2,5.38,2.82,1.31,5.54,2.83,8.14,4.55,.66,1.23,1.52,2.35,2.54,3.31,1.33,1.24,3.04,2.85,5.29,2.62,1.16-.15,2.24-.71,3.04-1.56,1.95-1.92-3.22-6.82-5.32-8.53l-13.61-8.11c-2.72-1.26-3.59-2.68-3.9-3.74-.97-3.38,3.09-6.21,1.8-9.35-.26-.63-.68-1.18-1.21-1.61-.53-.43-1.15-.72-1.82-.85-3.37-.51-7.1,5-8.05,9.37-.61,3.02-.14,6.35,1.89,8.53Z"
        fill="#71adee"
      />
      <path
        d="M61.43,180.12c2.91,3.13,8.44,2.82,19.34,9.93,.66,1.23,1.52,2.35,2.54,3.31,1.33,1.24,3.04,2.85,5.29,2.62,1.16-.15,2.24-.71,3.04-1.56,.22-.24,.37-.54,.42-.86,.06-.32,.02-.65-.11-.95-.69,.67-1.57,1.13-2.51,1.3-1.25,.19-2.53-.1-3.58-.81-1.2-.87-1.12-1.77-2.25-3.38-.82-1.13-1.86-2.09-3.05-2.82,.44,.29,.79,.7,1.02,1.18,.23,.48,.33,1.01,.29,1.53-6.51-5.02-9.31-3.53-16.07-11.04-.28-.31-.44-.51-.52-.6-.27,.56-.34,1.19-.19,1.79,.15,.6,.52,1.13,1.02,1.48-1.64,.11-3.27-.28-4.68-1.12Z"
        fill="#004eb6"
      />
      <path
        d="M95.18,170.48l.76,8.92c1.48,1.87,4.73,5.22,7.11,5.22,2.87,.05,5.06-1.07,5.16-2.78,0-.09,0-.19,0-.28-.16-1.87-5.16-5.51-8.66-8.66-3.5-3.15-4.07-2.45-4.38-2.42Z"
        fill="#004eb6"
      />
      <path
        d="M97.99,181.65c1.45,1.41,3.41,2.97,5.06,3,2.87,.05,5.06-1.07,5.16-2.78-1.28,.96-2.87,1.4-4.46,1.25-3.58-.37-3.99-2.79-3.99-2.79-.62,.41-1.21,.85-1.77,1.32Z"
        fill="#2f455f"
      />
      <path
        d="M22.4,164.88c.88,1.13,2.06,4.09,2.76,4.64,0,0,19.06,5.99,21.5,4.1,.23-.18,.41-.41,.52-.67,.23-.56,.04-1.48-.53-2.4-.76-1.17-1.84-2.08-3.12-2.63-1.28-.55-2.68-.7-4.05-.45l-5.31-2.78c.67-.2,5.47-1.76,6.91-6.07,1.72-5.11-2.25-11.17-7.67-12.93-5.56-1.82-12.18,1.13-14.32,6.01-1.33,3.05-1.23,7.47,3.3,13.16Z"
        fill="#71adee"
      />
      <path
        d="M18.39,155.93c.28,4.51,3.51,8.32,4.01,8.94,.88,1.13,2.06,4.09,2.76,4.64,0,0,19.06,5.99,21.5,4.1,.23-.18,.41-.41,.52-.67,.23-.56,.04-1.48-.53-2.4,.08,.5,.26,2.88-3.66,2.03-4.39-.94-10.19-2.12-10.19-2.12,0,0-2.47-5.22-6.58-6.26-4.11-1.04-6.83-6.66-7.65-8.12-.06-.11-.12-.15-.2-.15Z"
        fill="#004eb6"
      />
      <path
        d="M83.2,179.68c7.58-.39,10.82,7.55,16.96,3.55,1.15-.76,2.12-1.64,2.03-2.69-.18-2.1-1.36-3.79-2.66-5.52-1.77-2.36-3.08-3.08-3.87-6.25-.53-2.18-1.31-4.28-2.31-6.29-1.46-2.38-6.15-6.16-13.59-2.9-8.14,3.57-8.72,20.74,3.45,20.09Z"
        fill="#71adee"
      />
      <path
        d="M87.04,159.48l6.3,3.01s.02-.07,.04-.11c.25-.73,1.61-4.84,1.25-7.95-.39-3.41-1.95-4.51-2.33-4.09,0,0-.12,.16-.33,.49-.59,.97-1.11,1.98-1.56,3.02-.97,1.96-2.09,3.84-3.37,5.62Z"
        fill="#71adee"
      />
      <path
        d="M87.04,159.47l6.3,3.01s.02-.07,.04-.11c0-1.27-2.36-1.99-1.65-4.94,.42-2.18,.51-4.4,.24-6.6-.59,.97-1.11,1.98-1.56,3.02-.97,1.96-2.09,3.84-3.37,5.62Z"
        fill="#004eb6"
      />
      <path
        d="M83.2,179.68c-.39-2.45-.56-2.97,.22-4.1,.83-1.22,1.6-.77,.87-3.21-.73-2.44-1.09-3.04-.63-4.97,.46-1.92,2.02-3.1,4.12-1.69,2.1,1.41,3.88,5.08,5.68,5.43,4.25,.87,0-6.43,.25-7.9-.11-.25-.23-.5-.37-.73-1.46-2.38-6.15-6.16-13.59-2.9-8.15,3.54-8.72,20.71,3.45,20.07Z"
        fill="#004eb6"
      />
      <path
        d="M75.39,168.82c.1,.13,.22,.25,.36,.33,.14,.08,.3,.14,.46,.16,.15,.01,.3-.01,.44-.07,.14-.05,.27-.14,.37-.24,1.12-.97,1.97-3.89,3.53-4.72l.12-.06s.52-.24,.85-.41c.25-.13,.49-.28,.72-.44,.47-.28,.82-.72,.99-1.24,.17-.52,.15-1.08-.06-1.59-.56-1.93-2.12-8.92-2.12-8.92-.67-.56-4.38,2.47-5.62,8.33-1.03,4.65-.92,7.7-.04,8.86Z"
        fill="#004eb6"
      />
      <path
        d="M77.02,168.98c1.12-.97,1.97-3.89,3.53-4.72l.12-.06c.71-.28,1.37-.68,1.96-1.17-.75-2.4-1.33-4.85-1.72-7.34-.06-.37-.16-1.17-.56-1.27-.65-.17-1.66,1.69-2.19,2.67-1.19,2.2-1.97,3.65-2.18,5.87-.17,2.06,.19,4.13,1.05,6.01Z"
        fill="#71adee"
      />
      <path
        d="M98.05,180.9c.37,.42,.75,.83,1.12,1.25,.14,.16,.31,.28,.5,.37,.19,.09,.4,.14,.61,.15,.21,0,.42-.03,.62-.11,.2-.08,.38-.19,.53-.34,.24-.21,.43-.47,.56-.76,.13-.29,.19-.61,.18-.93-.07-.85-.3-1.69-.69-2.46-.08-.17-.16-.34-.26-.51-.88,.28-2.9,.95-3.34,1.56-.55,.79,.17,1.78,.17,1.78Z"
        fill="#2f455f"
      />
      <path
        d="M87.38,170.31c.06,0,.12,0,.17-.02,.06-.02,.11-.06,.14-.1,.04-.04,.07-.1,.08-.15,.02-.06,.02-.12,0-.18-.12-.56-.37-1.48-.7-1.61-1.24-.49-4.34,1.59,.29,2.07Z"
        fill="#2f455f"
      />
      <path
        d="M15.83,170.12c4.23,3.47,6.91,2.94,7.87,5.69,.71,2.01-.08,4.13-.78,5.51,5.95-1.73,9.84-5.42,9.67-8.78-.11-2.07-1.74-3.83-2.37-4.51-1.52-1.64-2.78-1.93-4.09-2.4-1.18-.38-2.26-1.03-3.15-1.89-2.54-2.42-3.54-6.24-3.72-7.8-.85-7.09,4.58-12.23,5.28-12.86h-.03c-19.33,7.28-19.18,18.41-8.68,27.04Z"
        fill="#004eb6"
      />
      <path
        d="M25.8,165.63c.12,.01,.23,.01,.35,0-1.18-.38-2.26-1.02-3.15-1.89-2.54-2.42-3.54-6.24-3.72-7.8-.85-7.09,4.58-12.23,5.28-12.86-.5,.25-8.39,4.28-8.55,10.98-.19,6.93,4.36,10.93,9.79,11.57Z"
        fill="#2f455f"
      />
      <path
        d="M15.83,170.12c4.23,3.47,6.91,2.95,7.87,5.69,.71,2.01-.08,4.13-.78,5.51,1.55-.89,2.76-2.27,3.46-3.93,.69-1.65,.83-3.49,.38-5.23l1.74,2.5c.2-2.45-.44-3.84-1.02-4.65-1.86-2.56-5.06-1.94-9.21-4.44-1.6-.98-3.02-2.22-4.2-3.68-.27,.58-.35,1.24-.22,1.87,.13,.63,.46,1.2,.93,1.63-3.37-2.95-3.97-5.36-4-6.9-.12-7.72,13.83-15.27,13.76-15.39-19.36,7.26-19.21,18.39-8.71,27.02Z"
        fill="#71adee"
      />
      <path
        d="M184.99,143.68c-6.19,7.85-6.75,5.57-11.35,10.53-3.81,4.11-9.38,6.12-16.31,13.78-.1,.56-.2,1.13-.3,1.69-.57-.05-1.13-.18-1.66-.38-8.95-3.49-17.42-8.09-25.23-13.69,0-.8-.07-1.59-.18-2.38-.22-1.41-.6-2.8-1.12-4.12-1.64-5.01,7.91-16.62,19.48-21.37,14.98-6.16,29.73,.65,33.68,2.67l.42,.22c.35,1.28-2.59,3.01-2.25,4.32,.32,1.22,.62,2.43,.92,3.63-.81,.99-.38,1.51,.43,1.95,.03,.11,.04,.23,.05,.34,0,.56,3.32,1.47,3.42,2.81Z"
        fill="#aad2f6"
      />
      <path
        d="M181.85,132.11v-.12c0-.15,.04-.3,.06-.46,.01-.15,.03-.31,.04-.46,.01-.15,0-.31,.03-.47v-.19l.42,.21c.16,.45-.14,.95-.54,1.48Z"
        fill="#0064dc"
      />
      <path
        d="M123.33,193.35c-.02,.28-.06,.57-.11,.85-.08,.57-.23,1.12-.45,1.65-.08,.17-.15,.31-.23,.44-.07,.16-.16,.32-.26,.47-.05,.06-.1,.12-.15,.18-.04,.06-.08,.11-.13,.15l-.12,.12c-.24,.24-.53,.42-.86,.53-.13,.07-.27,.12-.42,.16-.04,0-.1,.03-.16,.03-.15,.04-.3,.07-.46,.1-.2,.04-.39,.06-.6,.09-.21,.03-.38,.05-.56,.07s-.47,.05-.72,.07c-.25,.02-.56,.05-.91,.1-1.07,.11-2.13,.3-3.18,.56-4.12,1.06-5.18,3.38-9.59,4.61-1.85,.51-6.56,1.02-8.32-1.47-.87-1.25-.29-3.03-.22-3.23,.53-1.31,1.54-2.37,2.81-2.97,.41-.21,.83-.4,1.26-.56,1.66-.78,3.26-1.68,4.78-2.7,2.51-1.54,3.79-2.34,4.91-3.39,.58-.53,1.12-1.1,1.63-1.69,.1-.23,.16-.42,.26-.62,1.09,.9,2.3,1.65,3.59,2.22,2.49,1.02,5.24,1.28,7.87,.72v.03c.21,.72,.32,1.47,.35,2.22,.02,.43,.02,.86-.02,1.28Z"
        fill="#fff"
      />
      <path
        d="M102.65,199.46c.52,1.36,.81,2.81,.86,4.27-2.18,.4-5.9,.47-7.42-1.69-.87-1.25-.29-3.03-.22-3.23,.53-1.31,1.54-2.37,2.81-2.97,.52,.07,1.02,.23,1.47,.48,.98,.58,1.75,1.46,2.19,2.52,.04,.06,.19,.35,.31,.63Z"
        fill="#71adee"
      />
      <path
        d="M123.33,193.34c-.02,.28-.06,.57-.11,.85-.09,.73-.32,1.44-.68,2.09-.15,.35-.38,.66-.66,.91-.24,.24-.53,.42-.86,.53-.13,.07-.27,.12-.42,.16-.24,.05-.3,.09-.61,.13s-1.46,.18-2.2,.25c-.1-.54-.09-1.09,.05-1.62,.06-.21,.13-.41,.21-.6,.48-1.05,1.29-1.91,2.31-2.44,.93-.42,1.97-.51,2.96-.25Z"
        fill="#71adee"
      />
      <path
        d="M110.92,187.15c3.9-15.83,3.94-29.34,4.16-42.97,0-2.77,.88-5.47,2.5-7.71,2.29-3.02,15.01-9.73,33.6-14.11,.79-1.52,2.03-2.77,3.55-3.56,1.11,2.09,1.86,4.35,2.19,6.7,.11,1.16,.18,2.26,.19,3.25,1.5,1.49,3.33,2.59,5.34,3.21l-7.96,9.16c-7.6,3.73-15.65,6.49-23.94,8.2,2.32,9.41-1.6,16.08-3.26,21.82-1.02,3.55-4.45,11.76-3.83,18.8-2.67,1.13-4.92,1.61-6.93,1.25-2.01-.36-3.94-1.57-5.61-4.04Z"
        fill="#71adee"
      />
      <path
        d="M154.5,141.14c-7.6,3.74-15.65,6.5-23.94,8.2,2.32,9.41-1.6,16.08-3.26,21.82-1.02,3.55-4.45,11.76-3.83,18.79-5.32,2.22-9,1.99-12.37-2.57,.46,.34,3.69,2.7,6.19,1.46,.67-.33,1.29-.91,2.25-2.96,1.57-3.27,1.47-4.69,2.55-8.53,.73-2.49,1.67-4.91,2.81-7.23,.96-2,2.01-3.76,2.66-6.77,.48-2.38,.61-4.82,.4-7.24,0,0,.49-2.93-1.12-10.56,6.94-1.03,13.37-3.09,18.97-7.22,3.1-2.26,4.65-3.43,5.24-5.3,1.2-3.74-1.6-6.66-.11-10.32,.76-1.68,2.07-3.05,3.71-3.89,1.11,2.09,1.86,4.35,2.19,6.7,.98,9.13-1.46,13.36-2.33,15.61Z"
        fill="#004eb6"
      />
      <path
        d="M148.98,210.22c-.26,1.4-.75,2.74-1.45,3.98-.47,.83-.96,1.56-1.4,2.26-1.18,1.84-2.26,3.75-3.23,5.7-.04,.07-.07,.15-.11,.23-.62,1.25-1.7,2.21-3.01,2.68-5.01,1.76-9.13,1.57-10.29-.26-.56-.9-.48-2.26-.15-3.6,.38-1.48,.95-2.9,1.69-4.23,3.55-6.21,5.01-7.39,6.1-9.92,1.19-2.82,5.23-3.12,8.4-2.47,1.62-.19,2.89,.35,3.43,1.9,.12,.37,.2,.74,.23,1.13,.03,.88-.04,1.75-.19,2.62Z"
        fill="#fff"
      />
      <path
        d="M142.9,222.14c-.04,.07-.07,.15-.11,.23-.62,1.25-1.7,2.21-3.01,2.68-5.01,1.76-9.13,1.57-10.29-.26-.56-.9-.48-2.26-.15-3.61,2.59-1.13,8.36-2.13,12.37,.25,.43,.24,.83,.49,1.19,.71Z"
        fill="#71adee"
      />
      <path
        d="M140.12,169.05s-.62-5.15-.48-6.27c.25-2.03-.12-1.91,.71-4.31,2.58-7.64,8.63-15.4,13.58-19.39,.32-.26,.62-.5,.89-.69-.64-1.29-1.01-2.7-1.09-4.15-.08-1.44,.13-2.88,.62-4.24,.01-.21,.08-.42,.18-.61,1.29-2.07,.16-2.16,1.12-1.98,.03-.07,.07-.14,.12-.21,2.36-.06,4.72,.08,7.07,.4,2.42-.07,3.08-.38,6.46-.56,6.05-.32,11.33-.37,12.16-1.33,.75,3.05,2.57,10.65-.41,17.89-.88,2.24-2.11,4.33-3.64,6.17-2.63,3.09-2.53,2.19-17.54,12.3-.32,.19-4.66,3.24-4.96,3.45v.03c1.37,6.73,1.29,13.67-.24,20.37-.1,.4-.24,.99-.33,1.38-.91,3.84-3.3,13.78-4.23,17.63,0,0,0,.01,0,.02,0,0,0,.02,0,.02-.05,.13-.07,.26-.11,.38-.97,3.41-2.81,4.26-6.28,4.25-2.43-.08-4.79-.79-6.87-2.06,.56-1.77,2.63-9.16,3.27-12.1,0-.09,.37-1.48,.36-1.56,0-.03,.21-1.01,.21-1.03,.43-2.58,.58-5.2,.46-7.82-.56-13.59-.4-10.26-1.02-15.99Z"
        fill="#71adee"
      />
      <path
        d="M136.81,207.56c2.07,1.27,4.44,1.98,6.87,2.06,3.49,0,5.31-.84,6.28-4.25,.05-.11,.07-.25,.11-.38,0,0,0-.01,0-.02s0-.01,0-.02c0,0,4.62-19.19,4.68-19.58,1.4-6.52,1.44-13.26,.11-19.79h.04c.31-.21,4.65-3.26,4.96-3.45,15.01-10.11,14.92-9.21,17.54-12.3,1.54-1.85,2.77-3.93,3.64-6.17,2.98-7.24,1.16-14.84,.41-17.89-.83,.95-6.11,1-12.16,1.33-3.38,.17-4.03,.48-6.46,.56-11.08,.56-6.1-1.79-8.32,1.78-.98,1.56,1.17,9.15,12.44,9.33,4.71,0,5.91-.53,6.61,.21,1.21,1.3-.44,4.95-1.88,7.21-1.94,2.95-4.58,5.38-7.68,7.07-21.61,12.46-10.83,21.25-13.85,37.51-.43,2.36-1.39,6.11-4.32,9.15-3.99,3.89-8.92,7.29-9.03,7.65Z"
        fill="#004eb6"
      />
      <path
        d="M155,96.53c-1.02,.99-1.92,2.11-2.67,3.32,0,0,0,.04-.04,.05-.13,.2-.49,.76-1.49,2.49-.8,1.43-.7,.84-1.27,1.91-1.69,4.24-4.3,12.15-5.98,16.36-.44,1.13-1.63,5.19-2.05,6.29-3.38,.45-5.94-.37-6.41-1.66-.07-.26-.08-.53-.03-.79,.04-.26,.14-.51,.29-.74,.82-5.03,2.64-13.24,4.05-18.7,.22-.85,2.56-8.06,2.93-8.95,.61-1.44,1.24-2.82,1.87-4.07,.42-.82,.81-1.61,1.21-2.35l4.87-1.06,4.57,6.77s.03,.41,.15,1.13Z"
        fill="#abe0f6"
      />
      <path
        d="M155,96.53c-.99,1.03-1.88,2.14-2.67,3.32,0,0,0,.04-.04,.05-.13,.2-.49,.76-1.49,2.49-.8,1.43-.7,.84-1.27,1.91-1.69,4.24-4.3,12.15-5.98,16.36-.38-.14-.75-.33-1.08-.56-.1-.23-.22-.45-.36-.67-1.19-1.87-3.17-2.01-3.14-2.61,0-.7,2.72-.46,4.53-2.4,.49-.65,.9-1.36,1.21-2.11,1.45-2.89,2.96-6.21,2.6-7.37-.48-1.62-2.35-2.13-2.13-2.71,.22-.57,1.78,.14,3.07-.56,1.93-1.05,1.87-3,.56-5.81-1.3-2.82-4.04-3.64-4.63-3.83,.42-.82,.81-1.61,1.21-2.35l4.87-1.06,4.57,6.77s.04,.41,.16,1.13Z"
        fill="#71adee"
      />
      <path
        d="M158.88,77.7c-1.37,1.72-2.5,3.62-3.38,5.64-2.14,5.02-1.69,8.9-3.94,9.55-.5,.13-1.02,.11-1.51-.07l-3.81-1.42c-.65-.25-1.24-.51-1.77-.76,.51-2.27,1.26-4.49,2.25-6.6,1.07-2.2,2.32-4.3,3.76-6.29,.4-.56,.56-.78,.65-.9,2.43-3.15,5.68-5.57,7.46-2.17,.28,.54,.37,1.61,.28,3.01Z"
        fill="#ffdc1e"
      />
      <path
        d="M151.62,81.13l-1.58,11.71c-1.24-.55-2.51-1.03-3.8-1.43l5.38-10.28Z"
        fill="#e39f3a"
      />
      <path
        d="M193.11,96.18c-.61,1.53-1.32,3.01-2.12,4.45l-1.3,2.51c-.46,.88-.94,1.83-1.43,2.78-.33,.64-.65,1.29-.98,1.94-.33,.65-.65,1.3-.96,1.94-.78,1.62-1.52,3.2-2.16,4.64-.54,1.22-1.02,2.47-1.43,3.74-.06,.2-.12,.39-.16,.57-.11,.41-.17,.83-.16,1.26,0,.21,.03,.41,.05,.62,.04,.56,.09,1.13,.16,1.64,.12,1,.26,1.98,.35,2.92,.24,2.16,.25,4.15-.7,5.94,0,.05-.04,.08-.04,.12s0,.04-.03,.06c-.15,.32-.38,.61-.65,.84-.31,.3-.65,.56-1.01,.8l-.09,.03s-.1,.07-.15,.1c-.83,.48-1.7,.89-2.59,1.22l-.77,.25c-.12,.05-.25,.09-.38,.12l-.25,.07-.56,.16-.18,.04c-.1,.03-.2,.05-.31,.07l-.11,.03c-.12,.04-.24,.06-.37,.08-.16,.03-.3,.05-.46,.07-4.16,.9-8.49,.61-12.49-.84-.39-.16-.76-.32-1.12-.5-2.67-1.24-4.98-3.14-6.72-5.52-.06-.09-.14-.17-.19-.26-.74-1.03-1.38-2.12-1.93-3.27-.3-.67-.61-1.33-.87-2.08v-.03c.22-.62,.43-1.17,.61-1.77,.45-1.4,.77-2.83,.97-4.28,.02-.1,.04-.2,.04-.3,.46-3.63,.11-7.13-.56-11.23-1.09-6.62-1.74-6.41-2.36-10.68-.73-5.2-1.26-10.96,1.49-16.64,.31-.81,.73-1.59,1.23-2.3,1.12-1.43,2.72-2.26,4.78-2.86,4-1.17,7.48-.82,11.18-.45,4.75,.46,7.26,1.37,9.13,2.35,.73,.39,1.32,.74,1.32,.74,.25,.15,.52,.3,.76,.47,.25,.17,.56,.35,.88,.53,1.92,1.18,3.73,2.52,5.41,4.03,1.57,1.48,2.97,3.12,4.2,4.9,.44,.65,.87,1.32,1.28,2.05,2.56,4.5,2.12,6.31,1.74,8.9Z"
        fill="#ffdc1e"
      />
      <path
        d="M190.06,85.23c-8.66-5.54-7.87-4.07-12.77-6.36-1.25,1.46-2.68,2.76-4.25,3.85-2.49,1.74-6.64,4.69-9.85,2.59-2.5-1.65-4.09-5.72-2.38-8.94,.62-1.13,1.51-2.08,2.58-2.78,2.54-.7,5.06-1.39,7.6-2.09h.05c5.09,2,11.41,5.64,14.83,8.84,1.57,1.48,2.97,3.12,4.2,4.89Z"
        fill="#e39f3a"
      />
      <path
        d="M193.11,96.18c-.24,1.49-10.96,20.15-10.69,23.83,.23,4.24,1.53,7.98-.12,11.12,0,.05-.04,.08-.04,.12s0,.04-.03,.06c-.53,1.02-1.83,1.81-2.86,2.3-4.11,2.04-17.01,5.38-25.31-5.25-.06-.09-.14-.18-.19-.26-1.87-2.47-2.73-4.72-2.81-5.36v-.03c.22-.62,.43-1.17,.61-1.77,.45-1.4,.77-2.83,.97-4.28,.02-.1,.04-.2,.04-.3,1.64,6.69,7.51,10.07,14.96,7,2.88-1.26,5.29-3.4,6.87-6.13,3.35-5.49,1.37-9.36,3.67-21.39,.41-2.16,.83-3.9,1.12-5.05,1.29,1.4,2.84,2.53,4.57,3.33,2.93,1.24,6.07,1.94,9.25,2.05Z"
        fill="#e39f3a"
      />
      <path
        d="M177.41,74.75c-.13,.1-.27,.2-.4,.31-3.73,2.82-8.12,4.63-12.75,5.27l-.07,.11-1.4,1.95c.24-1.03,.45-1.97,.65-2.99,.08-.53,.17-1.08,.25-1.66,.11-.9,.19-1.89,.25-3.06,.06-1.37,.07-3,0-4.95-.19-.66-.12-1.38,.2-1.99,1.06-1.75,5.06-1.69,10.19,.06l.82,.29c-.12,.48-.19,.97-.2,1.46-.04,1.12,.21,2.24,.71,3.24,.12,.27,.29,.52,.48,.74,.09,.1,.19,.23,.33,.36,.13,.13,.19,.21,.31,.32,.12,.11,.41,.35,.63,.54Z"
        fill="#71adee"
      />
      <path
        d="M177.49,74.56c-.04,.06-.07,.12-.1,.19-.32,.64-.67,1.25-1.06,1.85-1.3,2.02-2.97,3.77-4.93,5.15-1.93,1.35-4.95,3.5-7.44,1.87-1.94-1.28-3.38-4.31-2.06-6.8,.48-.87,1.17-1.62,2.01-2.16l5.9-1.63,4.74-1.31,1.09,1.06,.93,.93c.34,.29,.61,.58,.9,.86Z"
        fill="#abe0f6"
      />
      <path
        d="M177.49,74.57c-.04,.06-.07,.12-.1,.19-.32,.64-.67,1.25-1.06,1.85-.65-.06-1.32-.12-1.98-.19-.29,.27-.74,.66-1.3,1.13-1.85,1.5-6.19,5.08-8.09,3.83-.34-.24-.61-.56-.79-.93-.37-.86-.54-1.78-.5-2.71-.01-1.02,.07-2.05,.25-3.06,2.06-.1,4.08-.66,5.91-1.63,2.1-1.12,3.71-3,4.5-5.25l.82,.29c-.4,1.58-.21,3.25,.52,4.7l.93,.93c.33,.29,.61,.58,.89,.86Z"
        fill="#71adee"
      />
      <path
        d="M161.44,74.18c.61,.55,1.35,.96,2.14,1.19,.79,.23,1.63,.27,2.44,.12,.04-.01,.09-.01,.13,0,.1-.03,.2-.05,.3-.06,.04,0,.09,0,.13,0,1.45-.35,2.82-.99,4.02-1.88,.03-.03,.07-.05,.1-.07,.74-.6,1.03-.88,1.69-1.49,1.11-1.14,2.04-2.44,2.75-3.87,1.28-.55,2.35-1.51,3.03-2.73,1.05-2.03,1.32-4.16-.94-4.18,.03-.12,.24-1.09,.43-2.29,.3-2.01,.56-4.56,.04-5.4-.52-.84-1.03-.52-1.9-.89-4.41-1.75-3.33-4.24-9.43-1.69-1.3,.54-4.68,1-6.63,4.15-3.79,6.17-1.38,16.31,1.69,19.09Z"
        fill="#abe0f6"
      />
      <path
        d="M166.04,75.49s.09-.01,.13,0c.1-.03,.2-.05,.3-.06,.04,0,.09,0,.13,0,1.45-.35,2.82-.99,4.02-1.88,.03-.03,.07-.05,.1-.07,.74-.6,1.03-.88,1.69-1.49,1.11-1.14,2.04-2.44,2.75-3.87,1.28-.55,2.35-1.51,3.03-2.73,1.05-2.03,1.32-4.16-.94-4.18,.03-.12,.24-1.09,.43-2.29-1.37-.74-2.77-1.42-4.21-2.01-5.42-2.26-7.58-2.26-9.44-1.04,3.6,.19,5.21,1.9,7.28,3.95,3.89,3.77-.34,12.35-5.27,15.67Z"
        fill="#71adee"
      />
      <path
        d="M180.99,60.11c-.83,3.79-2.45,7.26-6.04,9.42,0-.35,.03-.7,.04-1.07,.06-.13,.12-.26,.19-.4,1.07-.44,1.98-1.21,2.6-2.19,.62-.99,.91-2.14,.84-3.3-.04-1.13-1.19-1.19-2.11-.6-.75,.46-1.2,1.65-1.52,2.44-.38,.91-1.02,2.12-1.23,2.05-.2-.07,0-1.73,.37-3.16,.28-1.13,.56-1.88,.22-2.26-.22-.24-.44,0-1.04-.25-.5-.27-.95-.63-1.31-1.07l-.19-.21c-3.67-4.3-6.95-4.21-7.35-3.52-.23,.41,.2,1.21,1.08,2.05-.48-.03-.95-.15-1.39-.37-.43-.22-.82-.52-1.13-.89-.71-.94-.37-1.94-.9-2.19-1.17-.56-3.09,4.25-5.88,.53,.71-.09,1.38-.39,1.91-.86,.54-.47,.92-1.1,1.1-1.8,.52-1.64,1.51-3.09,2.86-4.15,8.78-7.08,21.73-.75,18.87,11.79Z"
        fill="#004eb6"
      />
      <path
        d="M180.99,60.11c-.83,3.79-2.45,7.26-6.04,9.42,0-.35,.03-.7,.04-1.07,.06-.13,.12-.26,.18-.4,1.07-.44,1.99-1.21,2.6-2.19,.62-.99,.91-2.14,.84-3.3-.04-1.13-1.19-1.18-2.11-.6-.75,.46-1.2,1.65-1.52,2.44-.38,.91-1.02,2.12-1.23,2.05-.2-.07,0-1.73,.36-3.16,.28-1.13,.56-1.88,.22-2.26-.22-.24-.44,0-1.04-.25,0,0,4.29-5.96,2.05-9.84-1.29-2.22-6.47-4.76-13.22-2.66,8.76-7.04,21.72-.72,18.85,11.83Z"
        fill="#2f455f"
      />
      <path
        d="M114.87,130.44s-.06-.71,.92-1.28l21.44-12.26c.69-.34,1.45-.52,2.22-.51,.77,0,1.53,.2,2.21,.55l32.34,19.08c.22,.12,.41,.29,.56,.49,.15,.2,.26,.43,.32,.68,.11,1.39,.27,1.61-.91,2.49-1.84,1.38-7.04,4.14-21.43,12.37-.59,.29-1.24,.47-1.9,.51s-1.32-.05-1.94-.27c-.47-.17,1.69,1.07-32.92-19.36-1.11-.65-.91-1.24-.9-2.49Z"
        fill="#004eb6"
      />
      <path
        d="M173.98,138.6l-21.44,12.26c-.69,.35-1.45,.53-2.21,.52-.77,0-1.52-.19-2.21-.55l-32.34-19.09c-1.21-.72-1.22-1.88,0-2.57l20.81-11.93,.6-.34c.69-.34,1.45-.52,2.22-.51,.77,0,1.53,.2,2.21,.55l2.68,1.58,29.66,17.49c1.28,.73,1.24,1.88,.02,2.58Z"
        fill="#e2f6ff"
      />
      <path
        d="M124.91,129.25c.66-.4,1.17-.56,.71-.84-.65-.37-1.27-.88-1.69-.62-.67,.4-1.17,.56-.71,.84,.64,.37,1.27,.88,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M127.07,130.46c.66-.4,1.17-.56,.71-.84-.65-.37-1.27-.87-1.69-.62-.67,.4-1.17,.56-.71,.84,.65,.36,1.27,.87,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M127.64,128.77c.66-.4,1.17-.57,.71-.84-1.59-.9-2.25-1.45-2.68-1.18-.66,.39-1.17,.56-.71,.83,1.6,.9,2.24,1.45,2.69,1.18Z"
        fill="#004eb6"
      />
      <path
        d="M129.91,128.04c.67-.4,1.17-.56,.71-.84-2.2-1.24-2.81-1.76-3.23-1.48-.64,.38-1.18,.56-.71,.84,2.2,1.25,2.78,1.75,3.23,1.49Z"
        fill="#004eb6"
      />
      <path
        d="M129.24,131.68c.66-.4,1.17-.57,.71-.84-.65-.36-1.27-.87-1.69-.62-.66,.4-1.17,.56-.71,.84,.65,.37,1.27,.87,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M129.27,129.99c.1,.06,.21,.09,.32,.09,.11,0,.22-.02,.32-.06,.66-.4,1.17-.56,.71-.84-.65-.37-1.28-.87-1.69-.62-1.24,.73-1.25,.54,.33,1.43Z"
        fill="#004eb6"
      />
      <path
        d="M131.5,129.26c.1,.05,.21,.08,.32,.08s.22-.03,.32-.08c1.23-.73,1.25-.54-.34-1.43-.1-.05-.21-.07-.32-.07s-.22,.03-.32,.07c-1.23,.74-1.25,.54,.34,1.43Z"
        fill="#004eb6"
      />
      <path
        d="M132.87,127.69c.66-.4,1.17-.57,.71-.84-4.7-2.65-3.83-2.56-5.24-1.69-.16,.09-.13,.26,.07,.37,3.59,1.99,4.02,2.43,4.46,2.17Z"
        fill="#004eb6"
      />
      <path
        d="M131.74,125.37c.1,.05,.21,.08,.32,.09,.11,0,.22-.01,.32-.06,1.28-.77,1.32-.5-.88-1.74-.1-.05-.21-.08-.32-.08s-.22,.03-.32,.08c-1.29,.74-1.32,.47,.88,1.71Z"
        fill="#004eb6"
      />
      <path
        d="M131.41,132.91c.64-.38,1.18-.56,.71-.83-.65-.37-1.27-.88-1.69-.62-.64,.38-1.18,.56-.72,.83,.65,.37,1.27,.88,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M132.08,131.24c1.23-.74,1.25-.56-.34-1.43-.1-.05-.21-.08-.32-.08s-.22,.03-.32,.08c-.66,.4-1.17,.56-.72,.84,.65,.33,1.28,.84,1.69,.59Z"
        fill="#004eb6"
      />
      <path
        d="M133.66,130.49c.1,.05,.21,.08,.32,.08s.22-.03,.32-.08c.64-.38,1.17-.56,.71-.84-.65-.37-1.28-.88-1.69-.62-1.24,.74-1.26,.56,.33,1.46Z"
        fill="#004eb6"
      />
      <path
        d="M134.38,128.9c.1,.06,.21,.09,.32,.09,.11,0,.22-.02,.32-.06,.66-.4,1.17-.56,.71-.84-.65-.37-1.28-.87-1.69-.62-1.24,.74-1.26,.54,.33,1.43Z"
        fill="#004eb6"
      />
      <path
        d="M133.57,134.13c.66-.4,1.17-.56,.71-.84-.65-.36-1.27-.87-1.69-.62-.64,.38-1.18,.56-.72,.84,.64,.36,1.27,.87,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M134.25,132.47c1.23-.73,1.25-.56-.34-1.43-.1-.05-.21-.07-.32-.07s-.22,.02-.32,.07c-.66,.4-1.17,.56-.71,.84,.65,.34,1.28,.85,1.69,.59Z"
        fill="#004eb6"
      />
      <path
        d="M136.47,131.73c.66-.4,1.17-.56,.71-.84-.65-.37-1.28-.87-1.69-.62-.67,.4-1.18,.57-.71,.84,.64,.36,1.27,.87,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M137.18,130.15c.66-.4,1.17-.56,.71-.84-.65-.37-1.28-.87-1.69-.62-.65,.38-1.18,.57-.71,.84,.64,.37,1.26,.87,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M138.91,129.11c.64-.38,1.18-.56,.71-.84-.65-.37-1.28-.88-1.69-.62-.66,.39-1.17,.56-.71,.83,.64,.37,1.27,.88,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M135.74,135.35c.66-.39,1.17-.56,.71-.84-.65-.36-1.27-.87-1.69-.62-.66,.4-1.17,.56-.71,.84,.64,.37,1.26,.87,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M135.77,133.66c.1,.05,.21,.07,.32,.07s.22-.03,.32-.07c1.23-.73,1.25-.57-.34-1.43-.1-.06-.21-.09-.32-.09-.11,0-.22,.02-.32,.06-1.25,.76-1.25,.56,.34,1.46Z"
        fill="#004eb6"
      />
      <path
        d="M137.99,132.92c.1,.05,.21,.08,.32,.08s.22-.03,.32-.08c.66-.4,1.17-.56,.71-.83-.65-.37-1.28-.88-1.69-.63-1.23,.77-1.26,.57,.33,1.46Z"
        fill="#004eb6"
      />
      <path
        d="M140.13,130.9c.16-.1,.12-.26-.07-.37-.65-.37-1.28-.87-1.69-.62-.65,.38-1.18,.56-.71,.84,1.59,.91,1.26,.87,2.47,.15Z"
        fill="#004eb6"
      />
      <path
        d="M137.9,136.57c.67-.4,1.17-.56,.71-.84-1.61-.91-1.27-.87-2.47-.15-.16,.1-.12,.26,.07,.37,.65,.37,1.28,.87,1.69,.63Z"
        fill="#004eb6"
      />
      <path
        d="M138.58,134.91c.66-.4,1.17-.57,.71-.84-.65-.37-1.28-.87-1.69-.62-.64,.38-1.18,.56-.71,.84,.67,.36,1.27,.87,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M140.8,134.18c.66-.4,1.17-.57,.71-.84-1.61-.91-1.27-.88-2.48-.15-.16,.1-.13,.26,.07,.37,.65,.36,1.26,.87,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M141.5,132.6c.64-.38,1.18-.57,.71-.84-.65-.36-1.28-.87-1.69-.62-.67,.4-1.17,.56-.71,.84,.66,.36,1.28,.86,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M140.07,137.79c1.23-.74,1.25-.56-.34-1.43-.1-.05-.21-.08-.32-.08s-.22,.03-.32,.08c-.66,.4-1.17,.56-.71,.84,.65,.34,1.28,.84,1.69,.59Z"
        fill="#004eb6"
      />
      <path
        d="M140.74,136.13c.66-.4,1.17-.57,.71-.84-.65-.37-1.27-.87-1.69-.62-.66,.4-1.17,.56-.71,.84,.64,.37,1.27,.87,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M142.96,135.42c.64-.38,1.17-.56,.71-.84-1.61-.91-1.27-.87-2.47-.15-.16,.1-.13,.26,.07,.37,.64,.34,1.27,.85,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M143.68,133.81c.66-.4,1.17-.56,.72-.84-1.61-.91-1.27-.88-2.48-.15-.16,.09-.13,.26,.07,.37,.64,.37,1.27,.88,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M142.23,139.02c1.23-.73,1.25-.56-.34-1.43-.1-.05-.21-.08-.32-.08s-.22,.03-.32,.08c-.66,.39-1.17,.56-.71,.83,.65,.35,1.28,.85,1.69,.6Z"
        fill="#004eb6"
      />
      <path
        d="M142.91,137.35c.66-.4,1.17-.57,.71-.84-1.61-.91-1.26-.88-2.48-.15-.16,.1-.13,.26,.07,.37,.65,.38,1.28,.87,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M145.13,136.61c.64-.38,1.17-.56,.71-.84-.65-.37-1.28-.88-1.69-.62-.65,.38-1.18,.56-.71,.83,.63,.37,1.26,.88,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M146.63,134.57c.16-.1,.13-.26-.07-.37-.65-.37-1.28-.87-1.69-.62-.67,.4-1.17,.56-.71,.84,1.6,.91,1.28,.87,2.47,.15Z"
        fill="#004eb6"
      />
      <path
        d="M144.4,140.24c1.23-.74,1.26-.54-.34-1.43-.1-.05-.21-.09-.32-.09-.11,0-.22,.02-.32,.06-.64,.38-1.17,.56-.71,.84,.65,.36,1.28,.88,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M145.07,138.57c1.23-.74,1.25-.57-.34-1.43-.1-.05-.21-.08-.32-.09-.11,0-.22,.02-.32,.06-.66,.4-1.17,.56-.71,.84,.65,.37,1.28,.85,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M147.29,137.84c1.23-.74,1.25-.54-.34-1.43-.1-.05-.21-.09-.32-.09-.11,0-.22,.02-.32,.06-.67,.4-1.17,.56-.71,.84,.65,.36,1.27,.87,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M148.01,136.25c1.23-.73,1.25-.54-.34-1.43-.1-.05-.21-.08-.32-.09-.11,0-.22,.02-.32,.06-.64,.39-1.18,.56-.71,.84,.65,.35,1.28,.87,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M146.56,141.46c.67-.4,1.17-.56,.71-.84-.64-.36-1.27-.87-1.69-.62-.64,.39-1.17,.56-.72,.84,.65,.37,1.28,.87,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M147.24,139.79c1.23-.73,1.25-.54-.34-1.43-.44-.25-.72,0-1.42,.45-.16,.09-.13,.26,.07,.37,.65,.37,1.28,.87,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M149.46,139.05c1.23-.73,1.25-.54-.34-1.43-.1-.05-.21-.07-.32-.07s-.22,.03-.32,.07c-.67,.4-1.17,.57-.71,.84,.65,.34,1.27,.85,1.69,.59Z"
        fill="#004eb6"
      />
      <path
        d="M150.96,137.01c.16-.1,.13-.26-.07-.37-.65-.37-1.28-.87-1.69-.62-.64,.38-1.18,.56-.71,.84,1.6,.9,1.26,.88,2.47,.15Z"
        fill="#004eb6"
      />
      <path
        d="M152.3,136.66c.67-.4,1.18-.57,.71-.84-12.73-7.17-12.44-7.17-12.88-6.93-.66,.4-1.17,.56-.71,.84,12.73,7.17,12.44,7.19,12.88,6.93Z"
        fill="#004eb6"
      />
      <path
        d="M149.51,142.18c.16-.1,.13-.26-.07-.37-.65-.37-1.27-.87-1.69-.62-.66,.4-1.17,.56-.71,.83,1.61,.93,1.26,.9,2.47,.16Z"
        fill="#004eb6"
      />
      <path
        d="M149.4,141.01c.66-.4,1.17-.56,.71-.84-.65-.37-1.27-.87-1.69-.62-.64,.38-1.18,.56-.71,.84,.64,.36,1.27,.87,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M150.98,140.25c.1,.05,.21,.08,.32,.08s.22-.03,.32-.08c.66-.39,1.17-.56,.71-.83-.65-.37-1.28-.88-1.69-.62-1.24,.76-1.28,.57,.33,1.46Z"
        fill="#004eb6"
      />
      <path
        d="M152.34,138.7c1.23-.74,1.25-.54-.34-1.43-.1-.05-.21-.08-.32-.09-.11,0-.22,.02-.32,.06-.67,.4-1.17,.56-.71,.84,.65,.37,1.28,.87,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M154.5,137.89c.66-.4,1.17-.56,.71-.84-.65-.36-1.28-.87-1.69-.62-.67,.4-1.17,.56-.71,.84,.64,.37,1.27,.87,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M151.68,143.43c.16-.1,.13-.26-.07-.37-.65-.37-1.27-.87-1.69-.62-.66,.4-1.17,.56-.71,.84,1.61,.91,1.29,.88,2.47,.15Z"
        fill="#004eb6"
      />
      <path
        d="M152.35,141.76c.16-.1,.13-.26-.07-.37-1.61-.9-1.26-.88-2.47-.15-.16,.1-.13,.26,.07,.37,1.61,.91,1.27,.87,2.47,.15Z"
        fill="#004eb6"
      />
      <path
        d="M153.15,141.47c.1,.05,.21,.09,.32,.09,.11,0,.22-.02,.32-.06,1.23-.74,1.25-.54-.34-1.43-.1-.05-.21-.09-.32-.09-.11,0-.22,.02-.32,.06-1.23,.74-1.25,.54,.34,1.43Z"
        fill="#004eb6"
      />
      <path
        d="M154.51,139.93c1.23-.73,1.25-.54-.34-1.43-.1-.05-.21-.07-.32-.07s-.22,.03-.32,.07c-.67,.4-1.17,.57-.71,.84,.65,.33,1.28,.84,1.69,.6Z"
        fill="#004eb6"
      />
      <path
        d="M156.05,139.11c.1,.05,.21,.08,.32,.08s.22-.03,.32-.08c.67-.4,1.17-.56,.71-.84-.65-.37-1.27-.88-1.69-.62-1.23,.76-1.25,.59,.33,1.46Z"
        fill="#004eb6"
      />
      <path
        d="M154.1,145.71c.66-.4,1.17-.56,.71-.84-1.6-.9-2.25-1.44-2.69-1.18-.66,.4-1.17,.56-.71,.84,1.6,.9,2.24,1.44,2.69,1.18Z"
        fill="#004eb6"
      />
      <path
        d="M153.09,143.43c.1,.05,.21,.08,.32,.08s.22-.03,.32-.08c1.23-.73,1.25-.56-.34-1.43-.1-.06-.21-.09-.32-.09-.11,0-.22,.02-.32,.06-1.23,.78-1.25,.56,.34,1.46Z"
        fill="#004eb6"
      />
      <path
        d="M155.26,144.65c.1,.06,.21,.09,.32,.09,.11,0,.22-.02,.32-.06,1.23-.74,1.25-.56-.34-1.43-.1-.05-.21-.08-.32-.09-.11,0-.22,.02-.32,.06-1.23,.74-1.25,.54,.34,1.43Z"
        fill="#004eb6"
      />
      <path
        d="M157.61,143.65c.67-.39,1.17-.56,.71-.83-2.35-1.32-2.9-1.82-3.35-1.56-.67,.4-1.17,.56-.71,.84,2.35,1.33,2.9,1.82,3.35,1.55Z"
        fill="#004eb6"
      />
      <path
        d="M159.32,142.62c.66-.4,1.17-.56,.71-.84-3.46-1.95-3.89-2.38-4.34-2.11-.67,.4-1.17,.56-.71,.84,3.46,1.95,3.9,2.38,4.34,2.11Z"
        fill="#004eb6"
      />
      <path
        d="M158.89,140.37c.66-.4,1.17-.56,.71-.84-.65-.37-1.28-.87-1.69-.62-.67,.4-1.18,.56-.71,.84,.64,.37,1.27,.87,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M161.08,141.62c1.23-.73,1.25-.54-.34-1.43-.1-.05-.21-.08-.32-.09-.11,0-.22,.02-.32,.06-.64,.39-1.18,.56-.72,.84,.67,.35,1.28,.86,1.69,.62Z"
        fill="#004eb6"
      />
      <path
        d="M133.88,126.61c.1,.05,.21,.08,.32,.09,.11,0,.22-.02,.32-.06,1.23-.74,1.26-.56-.34-1.43-.1-.06-.21-.09-.32-.09-.11,0-.22,.02-.32,.06-1.23,.73-1.25,.54,.34,1.43Z"
        fill="#004eb6"
      />
      <path
        d="M137.5,127.41c.16-.1,.13-.26-.07-.37-1.61-.91-1.26-.87-2.47-.15-.16,.1-.13,.26,.06,.37,1.61,.91,1.27,.88,2.47,.15Z"
        fill="#004eb6"
      />
      <path
        d="M124.91,129.02c.67-.4,1.18-.56,.71-.84-.65-.36-1.27-.87-1.69-.62-.66,.4-1.17,.56-.71,.84,.64,.37,1.26,.87,1.68,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M127.07,130.24c.67-.4,1.17-.56,.71-.84-.65-.37-1.27-.88-1.69-.62-.66,.4-1.17,.56-.71,.83,.65,.37,1.27,.88,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M127.64,128.54c.67-.4,1.17-.56,.71-.83-1.6-.9-2.25-1.45-2.69-1.18-.66,.4-1.17,.56-.71,.84,1.6,.9,2.24,1.44,2.68,1.18Z"
        fill="#aad2f6"
      />
      <path
        d="M129.91,127.8c.67-.4,1.17-.56,.71-.83-2.2-1.24-2.81-1.76-3.23-1.49-.64,.39-1.17,.56-.71,.84,2.2,1.24,2.78,1.76,3.22,1.48Z"
        fill="#aad2f6"
      />
      <path
        d="M129.24,131.46c.67-.4,1.18-.56,.71-.84-.65-.37-1.27-.87-1.69-.62-.66,.4-1.17,.56-.72,.84,.65,.37,1.27,.87,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M129.27,129.77c.1,.06,.21,.09,.32,.09,.11,0,.22-.02,.32-.06,.67-.4,1.17-.57,.71-.84-.65-.36-1.27-.87-1.69-.62-1.23,.74-1.25,.54,.33,1.43Z"
        fill="#aad2f6"
      />
      <path
        d="M131.5,129.03c.1,.05,.21,.08,.32,.09,.11,0,.22-.02,.32-.06,1.23-.74,1.25-.54-.34-1.43-.1-.06-.21-.09-.32-.09-.11,0-.22,.02-.32,.06-1.23,.74-1.25,.54,.34,1.43Z"
        fill="#aad2f6"
      />
      <path
        d="M132.87,127.46c.67-.4,1.18-.56,.71-.84-4.7-2.65-3.83-2.56-5.24-1.69-.16,.1-.13,.26,.07,.37,3.59,2,4.01,2.43,4.46,2.16Z"
        fill="#aad2f6"
      />
      <path
        d="M131.74,125.14c.1,.05,.21,.08,.32,.08s.22-.03,.32-.08c1.28-.77,1.32-.5-.88-1.74-.1-.05-.21-.08-.32-.09-.11,0-.22,.02-.32,.06-1.28,.8-1.31,.53,.88,1.77Z"
        fill="#aad2f6"
      />
      <path
        d="M131.41,132.68c.65-.38,1.18-.56,.71-.84-.64-.36-1.27-.87-1.69-.62-.64,.39-1.17,.57-.71,.84,.64,.37,1.27,.87,1.68,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M132.08,131.01c1.23-.73,1.26-.56-.34-1.43-.1-.06-.21-.09-.32-.09-.11,0-.22,.02-.32,.06-.66,.4-1.17,.57-.71,.84,.65,.37,1.28,.87,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M133.66,130.25c.1,.05,.21,.08,.32,.08s.22-.03,.32-.08c.64-.38,1.18-.56,.71-.84-.65-.36-1.28-.87-1.69-.62-1.24,.77-1.26,.56,.33,1.46Z"
        fill="#aad2f6"
      />
      <path
        d="M134.38,128.67c.1,.05,.21,.08,.32,.09,.11,0,.22-.01,.32-.06,.67-.4,1.17-.57,.71-.84-.65-.37-1.28-.87-1.69-.62-1.23,.73-1.25,.54,.33,1.43Z"
        fill="#aad2f6"
      />
      <path
        d="M133.57,133.9c.67-.4,1.18-.56,.71-.84-.64-.37-1.27-.87-1.69-.62-.64,.38-1.17,.57-.71,.84,.64,.37,1.27,.87,1.68,.63Z"
        fill="#aad2f6"
      />
      <path
        d="M134.25,132.24c1.23-.74,1.26-.54-.34-1.43-.1-.05-.21-.09-.32-.09-.11,0-.22,.02-.32,.06-.66,.4-1.17,.56-.71,.84,.65,.36,1.28,.87,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M136.47,131.5c.67-.4,1.17-.56,.72-.84-.65-.37-1.28-.88-1.69-.62-.66,.4-1.17,.56-.71,.84,.64,.37,1.27,.88,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M137.18,129.92c.67-.4,1.17-.56,.71-.84-.65-.36-1.28-.87-1.69-.62-.64,.38-1.17,.57-.71,.84,.65,.37,1.27,.87,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M138.91,128.88c.64-.38,1.18-.56,.71-.84-.65-.36-1.27-.87-1.69-.62-.66,.4-1.17,.56-.71,.84,.64,.38,1.27,.87,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M135.74,135.13c.66-.4,1.17-.56,.71-.84-.65-.36-1.27-.87-1.69-.62-.66,.4-1.17,.56-.71,.84,.64,.34,1.25,.87,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M135.77,133.43c.1,.05,.21,.08,.32,.08s.22-.03,.32-.08c1.23-.73,1.25-.54-.34-1.43-.1-.05-.21-.08-.32-.09-.11,0-.22,.01-.32,.06-1.25,.77-1.25,.56,.34,1.46Z"
        fill="#aad2f6"
      />
      <path
        d="M137.99,132.7c.1,.05,.21,.09,.32,.09,.11,0,.22-.02,.32-.06,.67-.4,1.17-.56,.71-.84-.65-.37-1.28-.87-1.69-.62-1.23,.76-1.23,.54,.33,1.43Z"
        fill="#aad2f6"
      />
      <path
        d="M140.13,130.66c.16-.09,.13-.26-.07-.37-.65-.37-1.28-.88-1.69-.62-.64,.38-1.17,.56-.71,.84,1.59,.91,1.26,.88,2.47,.15Z"
        fill="#aad2f6"
      />
      <path
        d="M137.91,136.34c.66-.4,1.17-.56,.71-.84-1.61-.91-1.27-.88-2.48-.16-.16,.1-.13,.26,.07,.37,.65,.37,1.27,.88,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M138.58,134.69c.67-.4,1.18-.56,.71-.84-.65-.37-1.27-.87-1.69-.62-.64,.38-1.17,.56-.71,.84,.67,.36,1.27,.87,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M140.8,133.94c.67-.4,1.17-.56,.71-.83-1.61-.91-1.27-.88-2.47-.16-.16,.1-.13,.26,.07,.37,.65,.39,1.26,.88,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M141.5,132.36c.65-.39,1.18-.56,.71-.84-.65-.37-1.28-.87-1.69-.62-.66,.4-1.17,.57-.71,.84,.66,.36,1.29,.85,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M140.07,137.57c1.23-.74,1.25-.54-.34-1.43-.1-.05-.2-.09-.31-.09-.11,0-.22,.02-.32,.06-.67,.4-1.17,.57-.71,.84,.65,.36,1.27,.87,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M140.74,135.9c.67-.4,1.18-.56,.71-.84-.65-.36-1.27-.87-1.69-.62-.66,.4-1.17,.56-.71,.84,.65,.37,1.27,.87,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M142.96,135.16c.64-.39,1.18-.56,.71-.84-1.61-.91-1.27-.87-2.47-.15-.16,.1-.13,.26,.07,.37,.65,.36,1.28,.86,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M143.68,133.58c.67-.4,1.17-.56,.72-.84-1.61-.9-1.27-.87-2.48-.15-.16,.1-.13,.26,.07,.37,.64,.37,1.28,.88,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M142.24,138.8c1.23-.74,1.25-.54-.34-1.43-.1-.05-.2-.08-.31-.09s-.22,.02-.32,.06c-.67,.4-1.17,.56-.71,.84,.65,.36,1.27,.86,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M142.91,137.1c.67-.39,1.18-.56,.71-.83-1.61-.91-1.26-.88-2.47-.15-.16,.09-.13,.26,.06,.37,.65,.38,1.28,.89,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M145.13,136.38c.64-.38,1.18-.56,.71-.84-.65-.36-1.28-.87-1.69-.62-.64,.39-1.18,.56-.71,.84,.64,.36,1.27,.87,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M146.63,134.34c.16-.1,.13-.26-.07-.37-.65-.37-1.27-.88-1.69-.62-.66,.4-1.17,.56-.71,.84,1.61,.91,1.28,.88,2.47,.16Z"
        fill="#aad2f6"
      />
      <path
        d="M144.4,140c1.23-.73,1.25-.54-.34-1.43-.1-.05-.2-.09-.31-.09-.11,0-.22,.02-.32,.06-.65,.38-1.18,.56-.71,.84,.65,.37,1.27,.87,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M145.07,138.35c1.23-.74,1.25-.54-.34-1.43-.1-.05-.21-.08-.32-.08s-.22,.03-.32,.08c-.66,.4-1.17,.56-.71,.84,.65,.33,1.28,.84,1.69,.59Z"
        fill="#aad2f6"
      />
      <path
        d="M147.29,137.6c1.23-.73,1.25-.54-.34-1.43-.1-.06-.21-.09-.32-.09-.11,0-.22,.02-.32,.06-.66,.4-1.17,.57-.71,.84,.65,.37,1.28,.88,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M148.01,136.03c1.23-.74,1.25-.56-.34-1.43-.1-.05-.21-.09-.32-.09-.11,0-.22,.02-.32,.06-.64,.38-1.18,.56-.71,.84,.65,.37,1.28,.87,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M146.56,141.23c.66-.4,1.17-.56,.71-.84-.65-.36-1.28-.87-1.69-.62-.65,.38-1.18,.56-.71,.84,.65,.36,1.28,.87,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M147.24,139.55c1.23-.73,1.25-.56-.34-1.43-.45-.25-.71,0-1.42,.44-.16,.1-.13,.26,.06,.37,.65,.37,1.28,.88,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M149.46,138.83c1.23-.74,1.25-.54-.34-1.43-.1-.06-.21-.09-.32-.09-.11,0-.22,.02-.32,.06-.66,.4-1.17,.56-.71,.84,.65,.36,1.28,.87,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M150.96,136.78c.16-.09,.13-.26-.07-.37-.65-.37-1.27-.88-1.69-.63-.64,.39-1.18,.56-.71,.84,1.61,.91,1.27,.88,2.47,.15Z"
        fill="#aad2f6"
      />
      <path
        d="M152.3,136.42c.66-.4,1.17-.57,.71-.83-12.73-7.18-12.44-7.2-12.88-6.93-.67,.39-1.18,.56-.72,.83,12.73,7.18,12.44,7.2,12.89,6.93Z"
        fill="#aad2f6"
      />
      <path
        d="M149.51,141.98c.16-.1,.13-.26-.06-.37-.65-.36-1.28-.87-1.69-.62-.67,.4-1.17,.56-.71,.84,1.6,.92,1.26,.87,2.46,.15Z"
        fill="#aad2f6"
      />
      <path
        d="M149.4,140.77c.67-.4,1.18-.56,.71-.84-.65-.37-1.27-.87-1.69-.62-.64,.38-1.17,.57-.71,.84,.64,.37,1.27,.89,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M150.99,140.01c.1,.05,.21,.09,.32,.09,.11,0,.22-.02,.32-.06,.67-.4,1.17-.56,.71-.84-.65-.37-1.28-.88-1.69-.63-1.23,.74-1.26,.54,.33,1.43Z"
        fill="#aad2f6"
      />
      <path
        d="M152.34,138.46c1.23-.74,1.26-.54-.34-1.43-.1-.05-.21-.08-.32-.08s-.22,.03-.32,.08c-.66,.4-1.17,.56-.71,.84,.65,.36,1.28,.84,1.69,.59Z"
        fill="#aad2f6"
      />
      <path
        d="M154.49,137.67c.66-.4,1.17-.56,.71-.84-.65-.37-1.27-.87-1.69-.62-.66,.4-1.17,.56-.71,.84,.64,.36,1.27,.87,1.69,.62Z"
        fill="#aad2f6"
      />
      <path
        d="M151.68,143.2c.16-.1,.13-.26-.06-.37-.65-.37-1.28-.88-1.69-.62-.67,.4-1.17,.56-.71,.84,1.6,.91,1.28,.88,2.46,.16Z"
        fill="#aad2f6"
      />
      <path
        d="M152.35,141.54c.16-.1,.13-.26-.06-.37-1.61-.91-1.27-.88-2.47-.15-.16,.1-.13,.26,.06,.37,1.61,.91,1.27,.88,2.48,.15Z"
        fill="#aad2f6"
      />
      <path
        d="M153.15,141.24c.1,.05,.21,.07,.32,.07s.22-.03,.32-.07c1.23-.74,1.25-.54-.34-1.43-.1-.05-.21-.08-.32-.08s-.22,.03-.32,.08c-1.23,.73-1.25,.54,.34,1.43Z"
        fill="#aad2f6"
      />
      <path
        d="M154.51,139.69c1.23-.74,1.25-.56-.34-1.43-.1-.05-.21-.08-.32-.08s-.22,.03-.32,.08c-.66,.4-1.17,.57-.71,.84,.65,.34,1.28,.83,1.69,.59Z"
        fill="#aad2f6"
      />
      <path
        d="M156.05,138.87c.1,.06,.21,.09,.32,.09,.11,0,.22-.02,.32-.06,.66-.4,1.17-.56,.71-.84-.64-.36-1.27-.87-1.69-.62-1.23,.74-1.25,.54,.34,1.43Z"
        fill="#aad2f6"
      />
      <path
        d="M154.1,145.48c.66-.4,1.17-.56,.71-.84-1.6-.9-2.25-1.44-2.68-1.18-.67,.4-1.17,.56-.71,.84,1.59,.9,2.24,1.45,2.68,1.18Z"
        fill="#aad2f6"
      />
      <path
        d="M153.1,143.19c.1,.05,.21,.08,.32,.08s.22-.03,.32-.08c1.23-.73,1.25-.54-.34-1.43-.1-.05-.2-.08-.31-.09-.11,0-.22,.01-.32,.06-1.23,.77-1.25,.57,.34,1.46Z"
        fill="#aad2f6"
      />
      <path
        d="M155.27,144.43c.1,.05,.21,.07,.32,.07s.22-.02,.32-.07c1.23-.74,1.25-.54-.34-1.43-.1-.05-.21-.08-.32-.08s-.22,.03-.32,.08c-1.23,.72-1.25,.52,.34,1.43Z"
        fill="#aad2f6"
      />
      <path
        d="M157.61,143.42c.67-.4,1.17-.56,.71-.83-2.34-1.32-2.9-1.82-3.35-1.56-.66,.4-1.17,.56-.71,.84,2.35,1.33,2.9,1.82,3.35,1.55Z"
        fill="#aad2f6"
      />
      <path
        d="M159.32,142.39c.67-.4,1.17-.57,.71-.84-3.46-1.95-3.9-2.38-4.34-2.11-.66,.4-1.17,.56-.71,.84,3.46,1.95,3.9,2.38,4.34,2.12Z"
        fill="#aad2f6"
      />
      <path
        d="M158.89,140.14c.66-.4,1.17-.56,.72-.84-.65-.37-1.28-.87-1.69-.62-.66,.4-1.17,.56-.71,.84,.64,.37,1.26,.87,1.69,.63Z"
        fill="#aad2f6"
      />
      <path
        d="M161.08,141.38c1.23-.73,1.25-.56-.34-1.43-.1-.05-.21-.08-.32-.08s-.22,.03-.32,.08c-.64,.38-1.18,.56-.72,.84,.67,.33,1.28,.83,1.69,.59Z"
        fill="#aad2f6"
      />
      <path
        d="M133.89,126.38c.1,.05,.21,.08,.32,.08s.22-.03,.32-.08c1.23-.73,1.25-.54-.34-1.43-.1-.05-.21-.08-.32-.08s-.22,.03-.32,.08c-1.23,.73-1.25,.54,.34,1.43Z"
        fill="#aad2f6"
      />
      <path
        d="M137.5,127.18c.16-.1,.12-.26-.07-.37-1.61-.91-1.27-.88-2.47-.15-.16,.1-.13,.26,.07,.37,1.61,.9,1.27,.87,2.48,.15Z"
        fill="#aad2f6"
      />
      <path
        d="M148.93,123.91l-4.83,2.89c-.2,.12-.16,.32,.08,.46,9,5.08,8.92,5.2,9.42,4.9l4.83-2.89c.2-.12,.16-.32-.08-.46-9.02-5.09-8.92-5.2-9.42-4.9Z"
        fill="#aad2f6"
      />
      <path
        d="M154.01,129.73c.2,0,.11,.1,.32,.09,1.4-.08,3.28,.08,4.5-.61,1.22-.69,4.44-2.19,4.9-3.31-.82,0-1.84,0-3.03,.08-.82,.06-7.31,.93-7.42,1.73-.03,.24,.23,.47,.33,.56,.29,.23,.66,.33,1.03,.3,.44,0,.68-.19,.71-.13,.04,.06-.18,.34-1.35,1.29Z"
        fill="#71adee"
      />
      <path
        d="M154.01,129.73c.2,0,.11,.1,.32,.09,1.4-.08,3.28,.08,4.5-.61,1.22-.69,4.44-2.19,4.9-3.31-.82,0-1.84,0-3.03,.08-.82,.06-7.31,.93-7.42,1.73-.03,.24,.23,.47,.33,.56,.29,.23,.66,.33,1.03,.3,.44,0,.68-.19,.71-.13,.04,.06-.18,.34-1.35,1.29Z"
        fill="#abe0f6"
      />
      <path
        d="M182.83,128.59c-4.02,2-10.54,3.75-15.33,5.16-.51,.15-1.05,.3-1.52,.42,.35-1.42,.36-2.9,.04-4.32-.32-1.42-.97-2.76-1.89-3.89,1.7-.45,3.37-1.02,4.99-1.69,3.05-1.29,5.95-2.91,8.66-4.82,1.6-1.07,2.87-2.03,3.74-2.7-.32-2.53-.64-5.06-.96-7.58l12.94-3.48c.07,1.09,.43,1.99,.43,3.06-.11,16.15-1.29,14.96-11.09,19.84Z"
        fill="#abe0f6"
      />
      <path
        d="M193.96,108.76c-6.42-.32-10.53,1.74-12.42,8.16v-.15c-.21-1.48-1.02-6.67-.44-7.52,3.04-4.47,7.99-3.53,12.44-3.54,.15,1.06,.28,2.05,.43,3.05Z"
        fill="#71adee"
      />
      <path
        d="M166.68,133.65c-2.25,1.44-4.46,2.19-6.78,3.16-.24,.1-.3,.11-.52,.21-.23,.1,.33-.16-.16,.07s-3.25,1.28-3.88,.98c-2.01-.96,2.1-1.96,2.71-2.7-.76,.31-2.66,1.36-3.22,1.55-1.65,.56-2.44,0-1.01-1.27,.3-.26,1.86-1.3,2.01-1.96-1.12,.89-2.87,2.54-4.43,2.05-.01,0-.03,0-.04-.01-.01,0-.02-.02-.03-.03,0-.01-.01-.02-.02-.04,0-.01,0-.03,0-.04,.03-1.18,.94-1.69,1.82-2.35,.42-.35,.77-.78,1.01-1.28-.61,.49-1.51,1.5-2.1,1.82-.6,.33-1.29,.62-1.53-.11-.24-.73,1.25-2.15,1.8-2.73,.54-.57,1.2-1.17,1.79-1.69h.03c1.41-.65,7.15-3.72,9.61-3.39,.6,.09,2.95,1.54,3.54,2.82,.59,1.29,.97,3.94-.59,4.94Z"
        fill="#abe0f6"
      />
      <path
        d="M159.68,136.87c.05,.06-.21,.07-.33,.16-.12,.09,.32-.17-.14,.07-.46,.24-3.25,1.27-3.87,.98-2-.97,2.09-1.96,2.71-2.7-.76,.31-2.66,1.36-3.22,1.55-1.65,.56-2.44,0-1.01-1.27,.3-.26,1.86-1.3,2.01-1.96-1.12,.89-2.87,2.54-4.43,2.05-.01,0-.03,0-.04-.01-.01,0-.02-.02-.03-.03,0-.01-.01-.03-.02-.04,0-.01,0-.03,0-.04,.03-1.18,.94-1.69,1.82-2.35,.42-.35,.77-.78,1.01-1.28-.66,.65-1.37,1.26-2.1,1.82-.59,.33-1.29,.62-1.53-.12-.24-.74,1.25-2.15,1.8-2.72,.55-.57,1.19-1.16,1.82-1.69,0,0-.16,0,.08,0s.52,.43,.68,.77c.16,.35,1.05,.2,1.12,.62s-.33,.81-.08,1.3c.25,.49,.84,.57,1.21,.86,.37,.29,.11,.99,.42,1.37,.31,.37,.87,.56,1.16,.91s.14,.99,.35,1.31c.21,.32,.48,.23,.62,.44Z"
        fill="#abe0f6"
      />
      <path
        d="M183.08,128.48c-5.06,2.43-10.28,3.65-15.62,5.29-.13-.85-.23-1.55-.31-2.43-.08-.88-.16-1.35-.22-2.33-.06-.98-.45-2.62-.52-3.66,.89-.28,1.83-.74,2.71-1.06,3.78,4.77,7.97,5.02,13.97,4.19Z"
        fill="#71adee"
      />
      <path
        d="M163.74,125.88c2.14,2.73,2.94,5.08,1.94,8.4,.63,.2,1.31,.23,1.95,.08,.65-.15,1.24-.47,1.72-.93,.92-3.35,0-5.71-2.09-8.46-.59-.28-1.26-.34-1.89-.19-.65,.17-1.23,.56-1.63,1.11Z"
        fill="#535b7b"
      />
      <path
        d="M180.43,112.66c1.31-1.65,1.51-2.31,3.71-3.63,.89-.54,4-1.69,5.82-1.5,1.23,.14,3.19,.33,4.42,1.06-.34-5.82-.37-10.51-1.15-16.85-.07-.53-2.31-4.63-4.89-4.85-.2,0-4.98-.47-6.46,.52-1.03,.68-2.9,3.49-3.03,4.51-.96,7.64,.82,13.51,1.59,20.74Z"
        fill="#ffdc1e"
      />
      <path
        d="M144.34,118.54c1.32,4.05,.75,9.3-.06,9.78-.52,.32-1.48-.12-2.9-1.3-3.38,.44-5.94-.37-6.41-1.66-.07-.26-.08-.53-.03-.79,.04-.26,.14-.51,.29-.74,.14-1.05,1.07-5.21,1.32-6.54,1.5-1.25,3.62-1.13,5.39-.33,.89,.38,1.7,.91,2.4,1.57Z"
        fill="#71adee"
      />
      <path
        d="M144.34,118.54c1.32,4.05,.75,9.3-.06,9.78-.52,.32-1.48-.12-2.9-1.3-3.38,.44-5.94-.37-6.41-1.66-.07-.26-.08-.53-.03-.79,.04-.26,.14-.51,.29-.74,.14-1.05,1.07-5.21,1.32-6.54,1.5-1.25,3.62-1.13,5.39-.33,.89,.38,1.7,.91,2.4,1.57Z"
        fill="#abe0f6"
      />
      <path
        d="M144.47,122.68c1.42,.92,2.43,2.36,2.81,4.01l4.01,24.89c.22,1.42-.67,1.94-2.01,1.13l-32.79-19.33c-1.42-.92-2.43-2.35-2.81-4l-4.01-24.89c-.23-1.42,.67-1.94,2.01-1.13l32.79,19.32Z"
        fill="#e2f6ff"
      />
      <path
        d="M147.72,125.99c-.23-.75-.6-1.46-1.09-2.07-1.1-1.42-.84-1-34.45-20.85-1.21-.72-1.16-.53-2.1,.03,.27-.1,.55-.12,.83-.08,.28,.04,.54,.16,.77,.33,.25,.15,32.82,19.33,32.98,19.44,1.21,.85,2.1,2.07,2.56,3.48,.09,.32-.17-1.2,4.1,25.3,.11,.7-.05,1.19-.41,1.4l.51-.28c.36-.21,.52-.69,.41-1.39-4.27-26.54-4.1-25.3-4.1-25.3Z"
        fill="#004eb6"
      />
      <path
        d="M129.04,129.65c1.21,.71,2.04,.34,1.83-.8-.2-1.15-1.36-2.64-2.59-3.35-1.23-.71-2.02-.34-1.83,.78,.4,1.42,1.32,2.62,2.59,3.37Z"
        fill="#aad2f6"
      />
    </SvgIcon>
  );
};

export default FamilyPlanGuidanceIcon;
