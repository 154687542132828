import React from "react";

import SvgIcon from "./SvgIcon";

const CheckmarkBlue = ({ width = 24, height = 24 }) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 20 20" fill="none">
      <path
        d="M9.99984 3.16634C13.7738 3.16634 16.8332 6.22573 16.8332 9.99967C16.8332 13.7736 13.7738 16.833 9.99984 16.833C6.22589 16.833 3.1665 13.7736 3.1665 9.99967C3.1665 6.22573 6.22589 3.16634 9.99984 3.16634Z"
        fill="white"
        stroke="#C6E9F8"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3332 9.99967C18.3332 5.3973 14.6022 1.66634 9.99984 1.66634C5.39746 1.66634 1.6665 5.3973 1.6665 9.99967C1.6665 14.602 5.39746 18.333 9.99984 18.333C14.6022 18.333 18.3332 14.602 18.3332 9.99967Z"
        fill="#0064DC"
      />
      <path
        d="M13.7498 7.91699L9.16651 12.5003L6.6665 10.0003"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CheckmarkBlue;
