import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";

import { selectTranslations } from "./translations";

import {
  selectHasLoadedDataById,
  selectIsLoadingById,
  selectRequestsHasData,
} from "./requests";
import { navigationMap, ABOUT_YOU_SECTIONS } from "../constants/navigation";
import {
  WISHES_RESTING_PATH,
  EXECUTORS_PATH,
  GIFTS_PATH,
  DOCUMENTS_PATH,
  PROPERTY_ATTORNEYS_PATH,
  PLANS_PATH,
  DASHBOARD_PATH,
  MARITAL_STATUS_PATH,
  REVIEW_ABOUT_YOU_PATH,
  REVIEW_YOUR_ARRANGEMENTS_PATH,
  REVIEW_YOUR_ESTATE_PATH,
  REVIEW_YOUR_FAMILY_PATH,
  REVIEW_SPOUSE_ESTATE_PATH,
  REVIEW_SPOUSE_ARRANGEMENTS_PATH,
  INTENDED_USE_PATH,
  OVERVIEW_HOW_IT_WORKS_PATH,
  OVERVIEW_SELECT_A_PLAN_PATH,
  OVERVIEW_SPOUSE_ESTATE_PATH,
  OVERVIEW_SPOUSE_ARRANGEMENTS_PATH,
  OVERVIEW_CONGRATULATIONS_PATH,
  WELCOME_PATH,
  RESIDENCE_PATH,
  ALLOCATIONS_GUIDANCE_PATH,
  BACKUPS_GUIDANCE_PATH,
  CHECKOUT_PATH,
  PERSONAL_ATTORNEYS_PATH,
} from "../constants/routes";
import {
  PROPERTY_ATTORNEYS_FORM_ID,
  DOCUMENTS_PAGE_ID,
  BACKUPS_FORM_ID,
  PERSONAL_ATTORNEYS_FORM_ID,
} from "../constants/forms";
import { selectCurrentRoute } from "./location";
import { notarizationStatuses } from "../components/plans/PlanConstants";
import {
  selectIsCouplesPlan,
  selectActivePlanSymbol,
  selectIsActivePlanBundle,
  selectPlanQuantity,
  selectIsNotarialWill,
  selectNotarizationStatus,
  selectPlansStatus,
  selectHasUserPaid,
  selectHasActivePlan,
} from "./plans";
import { selectYourPlanProps } from "./your-plan";
import { selectNumberOfAvailableInvites } from "./purchases";
import {
  selectAboutYouStatus,
  selectFirstName,
  selectHasReasonsOrResidence,
  selectIsResidenceComplete,
} from "./about-you";
import { selectYourFamilyStatus } from "./your-family";
import { selectYourEstateStatus } from "./your-estate";
import { selectArrangementsStatus } from "./your-arrangements";
import { selectSpouseEstateStatus } from "./spouse-estate";
import { selectIsRestingComplete } from "./wishes";
import { selectSpouseArrangementsStatus } from "./spouse-arrangements";
import { referralProgramProps } from "./referral-program";
import {
  selectIsCompleteAllSections,
  selectOverallSectionCompletionPercentage,
  selectOverallSectionCompletionPercentageForProgressBar,
} from "./section-completion";
import { selectIsFrenchSelected } from "./language";
import { selectNavigationHistory, selectNextOverviewPath } from "./navigation";
import { selectHasAppointee } from "./appointees";
import { notarialWill } from "../constants/plans";
import { selectIsPrimaryBeneficiaryCompleted } from "./allocations";
import { selectIsBackupComplete } from "./backup";
import { selectUserHasUpdateWillFunctionality } from "./features";

const selectNavigationData = R.propOr({}, ["navigation"]);

const selectShouldShowCheckoutButton = createSelector(
  selectHasUserPaid,
  selectIsCompleteAllSections,
  (hasUserPaid, isCompleteAllSections) => {
    return !hasUserPaid && isCompleteAllSections;
  },
);

// This function pulls nextSection from state, which comes from the BackEnd
// The backend does not fully translate the sections to FrontEnd form IDs
// and must be massaged
const getNextPagePath = (
  navigationData,
  isRestingComplete,
  isPrimaryBeneficiaryCompleted,
  isBackupComplete,
) => {
  const nextPageId = R.propOr("", "nextSection")(navigationData);
  if (nextPageId === "") return null;
  // isPrimaryBeneficiaryCompleted = if the user has a spouse and has not completed primary beneficiary section
  if (nextPageId === "residualBeneficiaries" && isPrimaryBeneficiaryCompleted) {
    return ALLOCATIONS_GUIDANCE_PATH;
  }

  if (nextPageId === BACKUPS_FORM_ID && !isBackupComplete) {
    return BACKUPS_GUIDANCE_PATH;
  }

  // If you have visited the wishes page the BE will respond with wishes being complete and send nextSection to be PoA
  // However that is not the case on the FE as there are multiple pages to complete/view
  if (nextPageId === PROPERTY_ATTORNEYS_FORM_ID) {
    return isRestingComplete ? PROPERTY_ATTORNEYS_PATH : WISHES_RESTING_PATH;
  }
  if (nextPageId === PERSONAL_ATTORNEYS_FORM_ID) {
    return PERSONAL_ATTORNEYS_PATH;
  }
  if (R.any(R.propEq("id", nextPageId))(navigationMap)) {
    return R.find(R.propEq("id", nextPageId))(navigationMap).path;
  }
  return DASHBOARD_PATH;
};

const getNextPageTranslations = (path, translations, planSymbol) => {
  if (!path) return null;
  const page = R.find(R.propEq("path", path))(navigationMap);
  if (!page) return null;

  if (ABOUT_YOU_SECTIONS.includes(path) && path !== INTENDED_USE_PATH) {
    return translations.aboutYouWelcomeDescription;
  }
  if (path === CHECKOUT_PATH) {
    return translations.paymentNextDescription;
  }
  if (path === PLANS_PATH) {
    return translations.pickPlanDescription;
  }
  if (path === INTENDED_USE_PATH) {
    return translations.intendedUseDescription;
  }
  if (path === MARITAL_STATUS_PATH) {
    return translations.welcomeDescription;
  }
  if (path === GIFTS_PATH) {
    return translations.sectionCompletedDescription
      .replace("PREVIOUS_SECTION", translations.yourFamily)
      .replace("NEXT_SECTION", translations[page.section]);
  }
  if (path === EXECUTORS_PATH) {
    return translations.sectionCompletedDescription
      .replace("PREVIOUS_SECTION", translations.yourEstate)
      .replace("NEXT_SECTION", translations[page.section]);
  }
  if (path === DOCUMENTS_PATH) {
    let { documentsNextDescription } = translations;
    if (planSymbol === notarialWill.symbol) {
      documentsNextDescription = translations.documentsNextDescriptionNotarial;
    }
    return documentsNextDescription;
  }
  return translations.halfwayCompletedDescription.replace(
    "SECTION",
    translations[page.section],
  );
};

// Need to use sectionPath here because it is more specific than the FORM_IDs
// in the navigation map inside navigation.js (spouse forms for example)
const selectNextPageTranslations = createSelector(
  selectTranslations(["global", "summary"]),
  selectNavigationData,
  selectIsRestingComplete,
  selectActivePlanSymbol,
  (summaryTranslations, navigationData, isRestingComplete, planSymbol) => {
    const nextPagePath = getNextPagePath(navigationData, isRestingComplete);

    if (!nextPagePath) return null;

    const translation = getNextPageTranslations(
      nextPagePath,
      summaryTranslations,
      planSymbol,
    );
    return translation;
  },
);

const getSectionLinkText = (
  hasUserPaid,
  isCompleteAllSections,
  translations,
  shouldShowCheckoutButton,
) => {
  if (shouldShowCheckoutButton) {
    // User is unpaid but completed all sections
    return translations.button.completedUnpaidSection;
  }
  if (hasUserPaid && isCompleteAllSections) {
    // User has paid and completed all sections
    return translations.button.completedSection;
  }
  return translations.button.nextSection;
};

const selectSummaryTranslations = createSelector(
  selectTranslations([
    "global",
    "plans",
    "yourAccount",
    "summary",
    "links",
    "yourPlanTranslations",
  ]),
  selectActivePlanSymbol,
  selectFirstName,
  selectHasUserPaid,
  selectIsCompleteAllSections,
  selectNextPageTranslations,
  selectPlanQuantity,
  selectNumberOfAvailableInvites,
  selectShouldShowCheckoutButton,
  (
    translations,
    planSymbol,
    firstName,
    hasUserPaid,
    isCompleteAllSections,
    nextPageTranslation,
    planQuantity,
    numberOfAvailablePlans,
    shouldShowCheckoutButton,
  ) => {
    const paidStatus = hasUserPaid ? translations.paid : translations.unpaid;
    const findDocuments = R.propOr(
      "",
      "documentsIncluded",
    )(translations[planSymbol]);
    const splitDocuments = findDocuments.split(",");
    const sectionLinkText = getSectionLinkText(
      hasUserPaid,
      isCompleteAllSections,
      translations,
      shouldShowCheckoutButton,
    );

    const {
      totalInvites,
      totalInvitesSelected,
      availableInvites,
      managePlan,
      assignPlans,
      genericPageTitle,
    } = translations;
    const totalInvitesWithNumber = totalInvites.replace("NUMBER", planQuantity);
    const totalInvitesSelectedWithNumber = totalInvitesSelected.replace(
      "NUMBER",
      planQuantity,
    );
    const availableInvitesWithNumber = availableInvites.replace(
      "NUMBER",
      numberOfAvailablePlans,
    );
    const availableInvitesCopy =
      numberOfAvailablePlans === 0 ? managePlan : assignPlans;
    const pageTitle = firstName
      ? translations.pageTitle.replace("NAME", firstName)
      : genericPageTitle;
    return {
      ...translations,
      pageDescription: nextPageTranslation,
      button: {
        ...translations.button,
        section: sectionLinkText,
      },
      pageTitle,
      paidStatus,
      splitDocuments,
      bulkPurchaseGifts: translations.bulkPurchaseGifts,
      totalInvites: totalInvitesWithNumber,
      availableInvitesCopy,
      availableInvites: availableInvitesWithNumber,
      totalInvitesSelected: totalInvitesSelectedWithNumber,
      numberOfAvailablePlansNum: planQuantity,
      numberOfInvitesRemainingNum: numberOfAvailablePlans,
    };
  },
);

export const selectNextSectionPath = createSelector(
  selectNavigationData,
  selectIsRestingComplete,
  selectIsPrimaryBeneficiaryCompleted,
  selectIsBackupComplete,
  (
    navigationData,
    isRestingComplete,
    isPrimaryBeneficiaryCompleted,
    isBackupComplete,
  ) =>
    getNextPagePath(
      navigationData,
      isRestingComplete,
      isPrimaryBeneficiaryCompleted,
      isBackupComplete,
    ),
);

const selectIsNotarizationGuideAvailable = createSelector(
  selectHasUserPaid,
  selectIsNotarialWill,
  selectNotarizationStatus,
  (hasUserPaid, isNotarialWill, notarizationStatus) =>
    hasUserPaid &&
    isNotarialWill &&
    notarizationStatus !== notarizationStatuses.unavailable,
);

export const selectNextOverviewPathForDashboard = createSelector(
  selectNextOverviewPath,
  selectNavigationData,
  selectShouldShowCheckoutButton,
  selectHasReasonsOrResidence,
  (
    nextOverviewPath,
    navigationData,
    shouldShowCheckoutButton,
    hasReasonsOrResidence,
  ) => {
    if (shouldShowCheckoutButton) {
      return CHECKOUT_PATH;
    }
    const nextPageId = R.propOr("", "nextSection")(navigationData);
    // Skips welcome survey if user already informed the reasons or the residence
    if (
      nextOverviewPath === OVERVIEW_HOW_IT_WORKS_PATH &&
      hasReasonsOrResidence
    ) {
      return getNextPagePath(navigationData);
    }
    if (nextPageId === DOCUMENTS_PAGE_ID) {
      return DOCUMENTS_PATH;
    }

    return nextOverviewPath;
  },
);

export const selectPlanSummaryProps = createStructuredSelector({
  yourPlanProps: selectYourPlanProps,
  isBundlePlanSelected: selectIsActivePlanBundle,
  hasUserPaid: selectHasUserPaid,
  translations: selectSummaryTranslations,
  isNotarizationGuideAvailable: selectIsNotarizationGuideAvailable,
});

export const dashboardPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById("summary"),
  isLoading: selectIsLoadingById("summary"),
  translations: selectSummaryTranslations,
  nextSectionPath: selectNextOverviewPathForDashboard,
  dashboardSummaryProps: createStructuredSelector({
    isCompleteAllSections: selectIsCompleteAllSections,
    isBundlePlanSelected: selectIsActivePlanBundle,
    hasPlan: selectHasActivePlan,
    hasAppointee: selectHasAppointee,
    hasUserPaid: selectHasUserPaid,
    referralProgramProps,
    translations: selectSummaryTranslations,
    showOverallProgressOnDashboard: selectIsResidenceComplete,
    progress: selectOverallSectionCompletionPercentage,
    isFrenchSelected: selectIsFrenchSelected,
    planSummaryProps: selectPlanSummaryProps,
    userHasUpdateWillFunctionality: selectUserHasUpdateWillFunctionality,
  }),
});

export const sectionsSummaryProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById("summary"),
  isLoading: selectIsLoadingById("summary"),
  aboutYou: selectAboutYouStatus,
  yourFamily: selectYourFamilyStatus,
  yourEstate: selectYourEstateStatus,
  yourArrangements: selectArrangementsStatus,
  isCouplesPlan: selectIsCouplesPlan,
  spouseEstate: selectSpouseEstateStatus,
  spouseArrangements: selectSpouseArrangementsStatus,
});

const selectSectionKey = createSelector(selectCurrentRoute, (currentRoute) => {
  const sectionKeyMap = {
    [REVIEW_ABOUT_YOU_PATH]: "aboutYou",
    [REVIEW_YOUR_FAMILY_PATH]: "yourFamily",
    [REVIEW_YOUR_ESTATE_PATH]: "yourEstate",
    [REVIEW_YOUR_ARRANGEMENTS_PATH]: "yourArrangements",
    [REVIEW_SPOUSE_ESTATE_PATH]: "spouseEstate",
    [REVIEW_SPOUSE_ARRANGEMENTS_PATH]: "spouseArrangements",
  };

  return sectionKeyMap[currentRoute] || "";
});

export const selectCurrentOverviewSectionKey = createSelector(
  selectCurrentRoute,
  (currentRoute) => {
    const sectionKeyMap = {
      [WELCOME_PATH]: "aboutYou",
      [OVERVIEW_HOW_IT_WORKS_PATH]: "aboutYou",
      [OVERVIEW_SELECT_A_PLAN_PATH]: "plans",
      [MARITAL_STATUS_PATH]: "maritalStatus",
      [GIFTS_PATH]: "gifts",
      [EXECUTORS_PATH]: "executors",
      [OVERVIEW_SPOUSE_ESTATE_PATH]: "spouseEstate",
      [OVERVIEW_SPOUSE_ARRANGEMENTS_PATH]: "spouseArrangements",
      [OVERVIEW_CONGRATULATIONS_PATH]: "congratulations",
    };

    return sectionKeyMap[currentRoute] || "";
  },
);

// Return a single array with all statuses included
export const selectOverviewStatuses = createSelector(
  selectIsCouplesPlan,
  selectAboutYouStatus,
  selectPlansStatus,
  selectYourFamilyStatus,
  selectYourEstateStatus,
  selectArrangementsStatus,
  selectSpouseEstateStatus,
  selectSpouseArrangementsStatus,
  (
    isCouplesPlan,
    aboutYouStatus,
    plansStatus,
    yourFamilyStatus,
    yourEstateStatus,
    yourArrangementsStatus,
    spouseEstateStatus,
    spouseArrangementsStatus,
  ) => {
    let statuses = [
      aboutYouStatus,
      plansStatus,
      yourFamilyStatus,
      yourEstateStatus,
      yourArrangementsStatus,
    ];

    if (isCouplesPlan) {
      statuses = [...statuses, spouseEstateStatus, spouseArrangementsStatus];
    }

    return statuses;
  },
);

export const selectOverviewSectionProps = createSelector(
  selectCurrentOverviewSectionKey,
  selectOverviewStatuses,
  (currentOverviewSectionKey, overviewStatuses) => {
    // Return an array to ensure that the sections are in the correct order
    return R.reduce(
      (propMap, status) => {
        const { isComplete, progress, translations, sectionKey } = status;

        propMap.push({
          sectionKey,
          isComplete,
          isSelected: currentOverviewSectionKey === sectionKey,
          progress,
          sectionTranslations: translations,
        });

        return propMap;
      },
      [],
      overviewStatuses,
    );
  },
);

export const selectShowCongratulations = createSelector(
  selectIsCompleteAllSections,
  selectCurrentRoute,
  (isCompleteAllSections, currentRoute) =>
    isCompleteAllSections && currentRoute === OVERVIEW_CONGRATULATIONS_PATH,
);

const selectSectionProps = createSelector(
  selectSectionKey,
  selectAboutYouStatus,
  selectYourFamilyStatus,
  selectYourEstateStatus,
  selectArrangementsStatus,
  selectSpouseEstateStatus,
  selectSpouseArrangementsStatus,
  (
    sectionKey,
    aboutYouProps,
    yourFamilyProps,
    yourEstateProps,
    yourArrangementsProps,
    spouseEstateProps,
    spouseArrangementsProps,
  ) => {
    const sectionPropsMap = {
      aboutYou: aboutYouProps,
      yourFamily: yourFamilyProps,
      yourEstate: yourEstateProps,
      yourArrangements: yourArrangementsProps,
      spouseEstate: spouseEstateProps,
      spouseArrangements: spouseArrangementsProps,
    };

    return sectionPropsMap[sectionKey] || {};
  },
);

const selectHasSectionReviewLoaded = createSelector(
  selectSectionKey,
  selectRequestsHasData,
  (sectionKey, hasLoadedDataById) => {
    return R.propOr(false, sectionKey)(hasLoadedDataById);
  },
);

export const selectOverviewPreviousSectionPath = createSelector(
  selectNavigationHistory,
  selectCurrentOverviewSectionKey,
  (navigationHistory, currentSectionKey) => {
    const { previous } = navigationHistory;

    if (currentSectionKey === "aboutYou") {
      return WELCOME_PATH;
    }

    return previous || DASHBOARD_PATH;
  },
);

const selectOverviewTranslations = createSelector(
  selectTranslations(["global", "overview"]),
  selectIsCouplesPlan,
  (translations, isCouplesPlan) => {
    const aboutYouTitle = translations.titles.aboutYou.replace(
      "SECTION_COUNT",
      isCouplesPlan ? 7 : 5,
    );
    return {
      ...translations,
      titles: {
        ...translations.titles,
        aboutYou: aboutYouTitle,
      },
    };
  },
);

export const selectShowCheckoutNowCard = createSelector(
  selectHasUserPaid,
  selectCurrentOverviewSectionKey,
  (hasUserPaid, currentOverviewSectionKey) => {
    return (
      !hasUserPaid &&
      (currentOverviewSectionKey === "yourFamily" ||
        currentOverviewSectionKey === "yourEstate" ||
        currentOverviewSectionKey === "yourArrangements")
    );
  },
);

export const selectNextSectionFromOverviewPath = createSelector(
  selectNextSectionPath,
  selectCurrentOverviewSectionKey,
  selectNavigationData,
  (nextSectionPath, currentOverviewSectionKey, navigationData) => {
    // Only redirect to the help page if the user is hitting the About You section for the first time
    if (
      currentOverviewSectionKey === "aboutYou" &&
      nextSectionPath === RESIDENCE_PATH
    ) {
      return RESIDENCE_PATH;
    }

    const nextSectionPage = R.find(R.propEq("path", nextSectionPath))(
      navigationMap,
    );
    const nextSection = R.pathOr(null, ["section"])(nextSectionPage);
    // If the user has selected Your Arrangements or one of the spouse pages before they've completed
    // Your Estate, we'll need to push them to the right page from the Overview screen which will
    // otherwise just send the user to whatever the nextPath is.
    if (
      [
        "yourEstate",
        "yourArrangements",
        "spouseEstate",
        "spouseArrangements",
      ].includes(currentOverviewSectionKey) &&
      nextSection &&
      nextSection !== currentOverviewSectionKey
    ) {
      const { nextForm } = navigationData;
      const nextSectionIdForCurrentOverview =
        nextForm[currentOverviewSectionKey];
      const correctedNextSection = R.find(
        R.propEq("id", nextSectionIdForCurrentOverview),
      )(navigationMap);
      return correctedNextSection.path || DASHBOARD_PATH;
    }

    return nextSectionPath;
  },
);

export const selectDisplayPreviousLinkInEyeBrow = createSelector(
  selectSectionKey,
  () => {
    return false;
  },
);

export const endOfSectionReviewPageProps = createStructuredSelector({
  translations: selectTranslations(["global", "endOfSectionReview"]),
  sectionProps: selectSectionProps,
  hasLoaded: selectHasSectionReviewLoaded,
  displayPreviousLinkInEyeBrow: selectDisplayPreviousLinkInEyeBrow,
  nextOverviewPath: selectNextOverviewPath,
});

export const overviewPageProps = createStructuredSelector({
  overviewSectionProps: selectOverviewSectionProps,
  currentSectionKey: selectCurrentOverviewSectionKey,
  isFrenchSelected: selectIsFrenchSelected,
  hasLoaded: selectHasLoadedDataById("dashboard"),
  translations: selectOverviewTranslations,
  showCongratulations: selectShowCongratulations,
  hasUserPaid: selectHasUserPaid,
  overallProgress: selectOverallSectionCompletionPercentageForProgressBar,
  nextSectionPath: selectNextSectionFromOverviewPath,
  previousSectionPath: selectOverviewPreviousSectionPath,
  showCheckoutNowCard: selectShowCheckoutNowCard,
});
