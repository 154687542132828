import { select, takeEvery, put, debounce } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import R from "ramda";

import {
  sendEvent,
  analyticsViewPage,
  ANALYTICS_SELECTED_PLAN_TYPE,
  ANALYTICS_CHECKOUT_PURCHASE_TYPE,
  ANALYTICS_VIEW_PLANS_TYPE,
  ANALYTICS_VIEW_CHECKOUT_TYPE,
  ANALYTICS_LOCKED_PROVINCE_NOTIFY_TYPE,
  ANALYTICS_VIEW_SIGNUP_TYPE,
  ANALYTICS_SIGNUP_TYPE,
  ANALYTICS_LOGOUT_TYPE,
  ANALYTICS_TAPLYTICS_IDENTIFY_TYPE,
  analyticsSetSessionId,
  ANALYTICS_HOTJAR_IDENTIFY_TYPE,
  ANALYTICS_LOGIN_TYPE,
} from "../actions/analytics";
import { getFeatureFlags } from "../actions/featureFlags";
import { SIGNUP_PATH, CHECKOUT_PATH, PLANS_PATH } from "../constants/routes";
import {
  isHotjarReady,
  isTaplyticsReady,
  isTikTokReady,
} from "../utilities/helpers";
import { CLEAR_APP_STATE_TYPE } from "../actions/auth";
import { breakpoints } from "../config/Theme";
import {
  isFbPixelEnabled,
  isGA4Enabled,
  isPinterestEnabled,
  isQuoraEnabled,
  isRedditEnabled,
  isStackAdaptEnabled,
  isPodsightsEnabled,
} from "../utilities/analytics";
import { selectIsGoogleSso } from "../selectors/auth";

function* selectedPlanAnalytics({ payload }) {
  const { plan, province: provinceCode, price, quantity } = payload;
  const userId = yield select(R.pathOr(false, ["auth", "id"]));

  if (isGA4Enabled()) {
    const ga4Params = yield select(R.pathOr({}, ["ga4Parameters"]));
    window.gtag("event", "add_to_cart", {
      currency: "CAD",
      value: price,
      user_id: userId,
      page_location: window.location.href,
      items: [
        {
          item_id: `${plan}-${provinceCode}`,
          item_name: plan,
          index: 0,
          item_brand: "Willful",
          item_category: "Online Wills",
          price,
          quantity,
        },
      ],
      ...ga4Params,
    });
  }
  if (isFbPixelEnabled()) {
    window.fbq("track", "AddToCart");
  }
  if (isRedditEnabled()) {
    window.rdt("track", "AddToCart");
  }
  if (isTikTokReady()) {
    window.ttq.track("AddToCart", {
      content_id: plan,
      content_name: plan,
      content_type: "product",
      quantity,
      price,
    });
  }
}

function viewCheckoutAnalytics() {
  if (isFbPixelEnabled()) {
    window.fbq("track", "InitiateCheckout");
  }
  if (isStackAdaptEnabled()) {
    window.saq("ts", window.env.STACK_ADAPT_KEY, {
      eventKey: "Viewed Checkout",
    });
  }
  if (isPinterestEnabled()) {
    window.pintrk("track", "pagevisit", { property: CHECKOUT_PATH });
  }
  if (isQuoraEnabled()) {
    window.qp("track", "Generic");
  }
}

function viewPlansAnalytics() {
  if (isFbPixelEnabled()) {
    window.fbq("track", "ViewContent", { content_name: "Plans" });
  }
  if (isStackAdaptEnabled()) {
    window.saq("ts", window.env.STACK_ADAPT_KEY, { eventKey: "Viewed Plans" });
  }
  if (isPinterestEnabled()) {
    window.pintrk("track", "pagevisit", { property: PLANS_PATH });
  }
  if (isQuoraEnabled()) {
    window.qp("track", "Generic");
  }
}

function* checkoutPurchaseAnalytics({ payload }) {
  const taplyticsReady = isTaplyticsReady();
  const {
    transactionId,
    planName,
    revenue, // subtotal after discount
    planPrice, // price per plan
    tax,
    provinceCode,
    quantity,
    coupon,
    userId,
  } = payload;

  if (isGA4Enabled()) {
    const ga4Params = yield select(R.pathOr({}, ["ga4Parameters"]));
    window.gtag("event", "purchase", {
      transaction_id: transactionId,
      value: revenue,
      tax,
      currency: "CAD",
      coupon,
      user_id: userId,
      items: [
        {
          item_id: `${planName}-${provinceCode}`,
          item_name: planName,
          coupon,
          index: 0,
          item_brand: "Willful",
          item_category: "Online Wills",
          price: planPrice,
          quantity,
        },
      ],
      ...ga4Params,
    });
    window.gtag("event", "conversion", {
      send_to: window.env.GOOGLE_ADS_TAG_ID || "",
      value: revenue,
      currency: "CAD",
      transaction_id: transactionId,
    });
  }
  if (isFbPixelEnabled()) {
    window.fbq("track", "Purchase", {
      currency: "CAD",
      value: revenue,
      province: provinceCode,
      promoCode: coupon,
    });
  }
  if (isStackAdaptEnabled()) {
    window.saq("ts", window.env.STACK_ADAPT_KEY, {
      eventKey: "Checkout Purchase",
      revenue,
    });
  }
  if (isPinterestEnabled()) {
    window.pintrk("track", "checkout", { value: revenue });
  }
  if (isQuoraEnabled()) {
    window.qp("track", "Purchase");
  }
  if (isRedditEnabled()) {
    window.rdt("track", "Purchase");
  }

  if (isPodsightsEnabled()) {
    window.pdst("checkout", {
      value: revenue,
      currency: "CAD",
      discount_code: coupon,
    });
  }

  // Event with value (revenue) and extra attributes
  if (taplyticsReady) {
    window.Taplytics.track("Checkout Purchase", revenue, {
      plan: planName,
      planPrice,
      quantity,
      coupon,
      province: provinceCode,
    });
  }

  if (isTikTokReady()) {
    const tiktokPayments = {
      content_id: planName,
      content_type: "product",
      currency: "CAD",
      value: revenue,
      quantity,
    };
    window.ttq.track("Complete Payment", tiktokPayments);
  }
}

function viewSignupAnalytics() {
  if (isFbPixelEnabled()) {
    window.fbq("track", "ViewContent", { content_name: "Sign Up" });
  }
  if (isStackAdaptEnabled()) {
    window.saq("ts", window.env.STACK_ADAPT_KEY, { eventKey: "Viewed Signup" });
  }
  if (isPinterestEnabled()) {
    window.pintrk("track", "pagevisit", { property: SIGNUP_PATH });
  }
  if (isQuoraEnabled()) {
    window.qp("track", "Generic");
  }
}

function lockedProvinceNotifyAnalytics() {
  if (isFbPixelEnabled()) {
    window.fbq("track", "Lead");
  }
}

function* signupAnalytics({ payload }) {
  const { userId, email } = payload;

  yield put(
    sendEvent({
      category: "Account",
      action: "Create",
      label: "Signup",
    }),
  );
  if (isGA4Enabled()) {
    const isGoogleSsoUser = yield select(selectIsGoogleSso);
    const form_submit_text = isGoogleSsoUser
      ? "Google SSO - app"
      : "Sign up form - app";
    const ga4Params = yield select(R.pathOr({}, ["ga4Parameters"]));
    window.gtag("event", "sign_up", {
      user_id: userId,
      form_submit_text,
      ...ga4Params,
    });
  }
  window.gtag("event", "conversion", {
    send_to: window.env.GOOGLE_ADS_SIGNUP_TAG_ID || "",
  });
  if (isFbPixelEnabled()) {
    window.fbq("track", "CompleteRegistration");
  }
  if (isStackAdaptEnabled()) {
    window.saq("ts", window.env.STACK_ADAPT_KEY, {
      eventKey: "Signed Up",
      email,
    });
  }
  if (isPinterestEnabled()) {
    window.pintrk("track", "signup");
  }
  if (isQuoraEnabled()) {
    window.qp("track", "CompleteRegistration");
  }
  if (isRedditEnabled()) {
    window.rdt("track", "SignUp");
  }
  if (isPodsightsEnabled()) {
    window.pdst("lead", {
      type: "signup",
    });
  }
  if (isTikTokReady()) {
    yield window.ttq.track("CompleteRegistration");
    yield window.ttq.identify(userId);
  }
}

const getDeviceType = (screenWidth) => {
  if (screenWidth < breakpoints.md) {
    return "mobile";
  }

  if (screenWidth < breakpoints.lg) {
    return "tablet";
  }

  return "desktop";
};

function* viewPageAnalytics({ payload }) {
  const isLoggedIn = yield select(R.pathOr(false, ["auth", "loggedIn"]));
  const userId = yield select(R.pathOr(false, ["auth", "id"]));
  const ga4Params = yield select(R.pathOr({}, ["ga4Parameters"]));
  // location payload from react-router action
  const { location } = payload;
  const deviceType = getDeviceType(window.innerWidth);

  if (isGA4Enabled()) {
    let params = {
      page_location: window.location.href,
      ...ga4Params,
    };

    if (isLoggedIn && userId) {
      params = {
        ...params,
        user_id: userId,
      };
    }
    window.gtag("event", "page_view", params);
  }
  if (isPodsightsEnabled()) {
    window.pdst("view");
  }

  if (isTikTokReady()) {
    window.ttq.page();
  }

  return yield put(analyticsViewPage(location, isLoggedIn, deviceType));
}

function logoutAnalytics() {
  const taplyticsReady = isTaplyticsReady();
  if (taplyticsReady) {
    window.Taplytics.reset();
  }
}

function* taplyticsIdentifyAnalytics({ payload }) {
  const { email, userId, provinceCode } = payload;
  const taplyticsReady = isTaplyticsReady();

  if (taplyticsReady) {
    window.Taplytics.identify({
      email,
      user_id: `${window.env.TAPLYTICS_ENV}_${userId}`,
      province_code: provinceCode,
      environment: window.env.TAPLYTICS_ENV,
    });

    yield put(getFeatureFlags());
  }
}

function* hotjarIdentifyAnalytics({ payload }) {
  if (isHotjarReady()) {
    // update Hotjar with userId and email
    const {
      email,
      userId,
      isInvitedUser,
      isPaidUser,
      isSessionPurchaser,
    } = payload;
    yield window.hj("identify", userId, {
      email,
      isInvitedUser,
      isPaidUser,
      isSessionPurchaser,
    });
  }
}
function* loginAnalytics({ payload }) {
  if (isTikTokReady()) {
    const { userId } = payload;
    yield window.ttq.identify(userId);
  }
}

function* clearAppStateAnalytics() {
  const sessionId = Date.now();
  yield put(analyticsSetSessionId(sessionId));
}

export function* watchViewPlansAnalytics() {
  yield takeEvery(ANALYTICS_VIEW_PLANS_TYPE, viewPlansAnalytics);
}

export function* watchSelectedPlanAnalytics() {
  yield takeEvery(ANALYTICS_SELECTED_PLAN_TYPE, selectedPlanAnalytics);
}

export function* watchViewCheckoutAnalytics() {
  yield takeEvery(ANALYTICS_VIEW_CHECKOUT_TYPE, viewCheckoutAnalytics);
}

export function* watchCheckoutPurchaseAnalytics() {
  yield takeEvery(ANALYTICS_CHECKOUT_PURCHASE_TYPE, checkoutPurchaseAnalytics);
}

export function* watchLockedProvinceNotifyAnalytics() {
  yield takeEvery(
    ANALYTICS_LOCKED_PROVINCE_NOTIFY_TYPE,
    lockedProvinceNotifyAnalytics,
  );
}
export function* watchViewSignupAnalytics() {
  yield takeEvery(ANALYTICS_VIEW_SIGNUP_TYPE, viewSignupAnalytics);
}
export function* watchSignupAnalytics() {
  yield takeEvery(ANALYTICS_SIGNUP_TYPE, signupAnalytics);
}

export function* watchLatestLocationChangeAnalytics() {
  yield debounce(100, LOCATION_CHANGE, viewPageAnalytics);
}

export function* watchLogoutAnalytics() {
  yield takeEvery(ANALYTICS_LOGOUT_TYPE, logoutAnalytics);
}

export function* watchTaplyticsIdentifyAnalytics() {
  yield takeEvery(
    ANALYTICS_TAPLYTICS_IDENTIFY_TYPE,
    taplyticsIdentifyAnalytics,
  );
}

export function* watchHotjarIdentifyAnalytics() {
  yield takeEvery(ANALYTICS_HOTJAR_IDENTIFY_TYPE, hotjarIdentifyAnalytics);
}

export function* watchLoginAnalytics() {
  yield takeEvery(ANALYTICS_LOGIN_TYPE, loginAnalytics);
}

export function* watchClearAppStateAnalytics() {
  yield takeEvery(CLEAR_APP_STATE_TYPE, clearAppStateAnalytics);
}
