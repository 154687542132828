import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { Field, reduxForm } from "redux-form";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { GoogleLogin } from "@react-oauth/google";

import EmailField from "../ui/inputs/EmailField";
import PasswordField from "../ui/inputs/PasswordField";
import { PrimaryButton } from "../ui/Button";

import {
  Body,
  H2,
  H5,
  MiceType,
  SmallBody,
  SmallExternalLink,
  SmallTextLink,
  StrikethroughParagraph,
} from "../ui/Typography";
import {
  PASSWORD_FIELD_ID,
  PROVINCE_FIELD_ID,
  SIGNUP_FORM_ID,
} from "../../constants/forms";
import {
  useValidatePasswordMemo,
  useValidateProvinceMemo,
} from "../ui/inputs/validations";
import { ErrorMessage } from "../ui/Error";
import LockSmall from "../ui/icons/LockSmall";
import CheckmarkBlue from "../ui/icons/CheckmarkBlue";
import HalfIconBackground from "../ui/willful-icon-background-half.svg";
import DropdownSelect from "../ui/inputs/DropdownSelect";
import WealthAwesomeReviewStars from "../ui/icons/WealthAwesomeReviewStars";
import FiveStarReviewIcon from "../ui/icons/FiveStarReviewIcon";

import DesktopTrustIconsForSignUp from "../ui/icons/DesktopTrustIconsForSignUp";
import MobileTrustIconsForSignUp from "../ui/icons/MobileTrustIconsForSignUp";
import { selectLanguageCode } from "../../selectors/language";
import { submitGoogleAuthLogin } from "../../actions/auth";
import { NoticeInfoBox } from "../ui/InfoBox";
import { selectUserAuthMethod } from "../../selectors/auth";
import { selectIsGoogleSSOEnabled } from "../../selectors/feature-flags";

const Form = styled("form")`
  height: 100%;
`;

const List = styled("ul")`
  margin: 0;
  padding: 0;
`;
const ListItem = styled("li")`
  display: flex;
  list-style: none;
  margin-bottom: 8px;
`;

const DesktopGridBackgroundIcons = styled(Grid)`
  background-repeat: repeat-x;
  background-position-y: bottom;
  background-image: ${(props) =>
    props.isMawmReferral ? "" : `url(${HalfIconBackground})`};
`;

const MobileTabletBackgroundIcons = styled(Box)`
  background-repeat: repeat-x;
  background-position-y: center;
`;

const SignupBulletPoints = ({ translations, isMobile }) => (
  <List>
    {translations.bulletList.map((item) => (
      <ListItem key={item}>
        <Box mt={isMobile ? 0 : 0.25} mr={0.5} display="flex">
          <CheckmarkBlue />
        </Box>
        <Body as="span">{item}</Body>
      </ListItem>
    ))}
  </List>
);

// TODO: duplicate of ResidenceForm
// consolidate into utils file
const transformProvincesList = (provinces, translations) =>
  provinces.map((province) => {
    return {
      ...province,
      label: translations[province.code],
      value: province.id,
    };
  });

const FormFields = ({
  error,
  provinces,
  isInvitedUser,
  isLoading,
  isMobileTablet,
  loginLink,
  translations,
  isArborReferral,
  checkEmailAvailability,
  invalid,
}) => {
  const dispatch = useDispatch();
  const provincesList = isInvitedUser
    ? transformProvincesList(provinces, translations.constants.provinces)
    : [];
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const googleSSOEnabled =
    useSelector(selectIsGoogleSSOEnabled) && !isInvitedUser;
  const currentLanguage = useSelector(selectLanguageCode);
  const localeSelected = currentLanguage === "en" ? "en-CA" : "fr-CA";
  const userAuthMethod = useSelector(selectUserAuthMethod);
  const { mustAuthViaPassword } = userAuthMethod;

  const handleGoogleLogin = (response) => {
    const data = {
      credential: response.credential,
    };
    dispatch(submitGoogleAuthLogin(data));
  };
  const handleGoogleLoginFailure = (googleError) => {
    console.error({ googleError });
  };
  return (
    <>
      <Box mb={isDesktop ? 2 : 1.5}>
        <H2 component="h1" align="left">
          {isArborReferral ? translations.heroText : translations.pageTitle}
        </H2>
      </Box>
      {googleSSOEnabled && mustAuthViaPassword && (
        <Hidden mdDown>
          <Box mb={2}>
            <NoticeInfoBox
              paragraph={translations.userMustAuthViaPassword}
              padding={1.5}
              border
            />
          </Box>
        </Hidden>
      )}
      {googleSSOEnabled && (
        <Hidden mdDown>
          <Box mb={2}>
            <GoogleLogin
              width="384px"
              useOneTap
              shape="rectangular"
              theme="outline"
              size="large"
              text="continue_with"
              logo_alignment="center"
              locale={localeSelected}
              onSuccess={handleGoogleLogin}
              onError={handleGoogleLoginFailure}
            />
            <Box textAlign="center">
              <StrikethroughParagraph text={translations.or} />
            </Box>
          </Box>
        </Hidden>
      )}
      {isMobileTablet && (
        <>
          {isInvitedUser && (
            <Box mb={0.5}>
              <Body margin={0}>{translations.bulletIntro}</Body>
            </Box>
          )}
          <Box borderRadius={4} mb={1.5}>
            <SignupBulletPoints translations={translations} isMobile />
          </Box>
          {googleSSOEnabled && mustAuthViaPassword && (
            <Hidden lgUp>
              <Box mb={2}>
                <NoticeInfoBox
                  paragraph={translations.userMustAuthViaPassword}
                  padding={1.5}
                  border
                />
              </Box>
            </Hidden>
          )}
          {googleSSOEnabled && (
            <Hidden lgUp>
              <Box mb={2} display="flex" justifyContent="center">
                <GoogleLogin
                  width="304px"
                  useOneTap
                  shape="rectangular"
                  theme="outline"
                  size="large"
                  text="continue_with"
                  logo_alignment="center"
                  locale={localeSelected}
                  onSuccess={handleGoogleLogin}
                  onError={handleGoogleLoginFailure}
                />
              </Box>
              <Box textAlign="center">
                <StrikethroughParagraph
                  text={translations.or}
                  sameBackground={false}
                />
              </Box>
            </Hidden>
          )}
          {isInvitedUser && (
            <Box mt={0.5}>
              <SmallBody>
                {translations.premiumFeatures}
                <SmallExternalLink
                  href="https://willful.co/pricing"
                  text={translations.here}
                />
              </SmallBody>
            </Box>
          )}
        </>
      )}
      <Box className="field-wrapper">
        <EmailField
          showHTMLErrorMessage
          translations={translations}
          onBlur={() => checkEmailAvailability(SIGNUP_FORM_ID)}
        />
      </Box>
      <Box className="field-wrapper">
        <PasswordField
          name={PASSWORD_FIELD_ID}
          validation={useValidatePasswordMemo(translations)}
          translations={translations}
          showPasswordStrength
          isSignUp
        />
      </Box>
      {isInvitedUser && (
        <Box my={1}>
          <Field
            component={DropdownSelect}
            validate={useValidateProvinceMemo(translations)}
            label={translations.label.province}
            name={PROVINCE_FIELD_ID}
            optionList={provincesList}
            placeholder={translations.label.provincePlaceholder}
          />
        </Box>
      )}
      <Box mt={2}>
        <PrimaryButton
          fullWidth
          displayArrowRight
          loadingLabel={translations.createAccountLoading}
          isLoading={isLoading}
          text={translations.button.submit}
          isDisabled={invalid}
        />
      </Box>
      {error && (
        <Box display="flex" justifyContent="center" my={0.5}>
          <ErrorMessage error={error} align="center" />
        </Box>
      )}
      <Box display="flex" flexDirection="row" justifyContent="left">
        <Box alignSelf="center" mt={0.25} mr={0.5}>
          <LockSmall width={20} height={20} />
        </Box>
        <MiceType
          margin="16px 0"
          color="willfulLightGrey.main"
          dangerouslySetInnerHTML={{ __html: translations.security }}
        />
      </Box>
      <Box
        display="flex"
        my={isDesktop && 1}
        justifyContent={!isDesktop && "center"}
        mb={1.5}
      >
        <SmallBody>
          {translations.loginLabel}
          <Box component="span" ml={0.2}>
            <SmallTextLink to={loginLink} text={translations.login} />
          </Box>
        </SmallBody>
      </Box>
    </>
  );
};

const Testimonial = ({ translations, isMobile, isFacebookReferral }) => {
  const StarReviewComponent = isFacebookReferral
    ? WealthAwesomeReviewStars
    : FiveStarReviewIcon;
  return (
    <Box
      maxWidth={isMobile ? "none" : "497px"}
      bgcolor="willfulxxLightBlue.main"
      mt={isMobile ? 1.5 : 1}
      borderRadius="8px"
      padding={1.5}
    >
      <StarReviewComponent
        width={isMobile ? 80 : 120}
        height={isMobile ? 16 : 24}
      />
      {translations.testimonial.title && (
        <Box mt={1}>
          <H5 component="span">{translations.testimonial.title}</H5>
        </Box>
      )}
      {translations.testimonial.content && (
        <Box mt={1}>
          <SmallBody>{translations.testimonial.content}</SmallBody>
        </Box>
      )}
      {translations.testimonial.attribution && (
        <Box mt={1}>
          <H5 component="span">{translations.testimonial.attribution}</H5>
        </Box>
      )}
    </Box>
  );
};

const DesktopForm = ({
  error,
  handleSubmit,
  provinces,
  isFrenchSelected,
  isInvitedUser,
  isLoading,
  loginLink,
  translations,
  isFacebookReferral,
  isMawmReferral,
  checkEmailAvailability,
  invalid,
}) => (
  <Box
    flexGrow={1}
    display="flex"
    width="100%"
    bgcolor="willfulxxxLightBlue.main"
  >
    <Grid container spacing={0} justifyContent="center" alignItems="stretch">
      <Hidden mdDown>
        <DesktopGridBackgroundIcons item lg={7} isMawmReferral={isMawmReferral}>
          <Box display="flex" flexDirection="row-reverse">
            <Box
              pt={7.5}
              px={2}
              display="flex"
              flexDirection="column"
              flexGrow="1"
              maxWidth={912}
            >
              <Box mb={1}>
                <H2
                  component="h1"
                  color="willfulBlue"
                  align="left"
                  dangerouslySetInnerHTML={{ __html: translations.heroText }}
                />
              </Box>
              {isInvitedUser && (
                <Box mb={0.5}>
                  <Body margin={0}>{translations.bulletIntro}</Body>
                </Box>
              )}
              <SignupBulletPoints translations={translations} />
              {translations.miceText && (
                <Box mt={1}>
                  <MiceType
                    color="willfulLightGrey.main"
                    dangerouslySetInnerHTML={{ __html: translations.miceText }}
                  />
                </Box>
              )}

              {translations.testimonial && (
                <Testimonial
                  translations={translations}
                  isFacebookReferral={isFacebookReferral}
                />
              )}
              {isInvitedUser && (
                <Box mt={0.5}>
                  <SmallBody>
                    {translations.premiumFeatures}
                    <SmallExternalLink
                      href="https://willful.co/pricing"
                      text={translations.here}
                    />
                  </SmallBody>
                </Box>
              )}
              <DesktopTrustIconsForSignUp
                isFrench={isFrenchSelected}
                translations={translations}
                shouldIncludeThirtyDay
              />
            </Box>
          </Box>
        </DesktopGridBackgroundIcons>
      </Hidden>
      <Grid item lg={5} md={9} sm={12}>
        <Form onSubmit={handleSubmit}>
          <Box
            display="flex"
            flexDirection="column"
            py={7.5}
            height="100%"
            bgcolor="willfulWhite.main"
          >
            <Box
              maxWidth={528}
              px={2}
              pb={2}
              display="flex"
              justifyContent="center"
            >
              <Box maxWidth={384} width="100%">
                <FormFields
                  error={error}
                  isLoading={isLoading}
                  provinces={provinces}
                  loginLink={loginLink}
                  isInvitedUser={isInvitedUser}
                  translations={translations}
                  checkEmailAvailability={checkEmailAvailability}
                  invalid={invalid}
                />
              </Box>
            </Box>
          </Box>
        </Form>
      </Grid>
    </Grid>
  </Box>
);

const MobileTabletForm = ({
  error,
  handleSubmit,
  provinces,
  checkEmailAvailability,
  isFrenchSelected,
  isInvitedUser,
  isLoading,
  loginLink,
  translations,
  isFacebookReferral,
  isArborReferral,
  invalid,
}) => (
  <MobileTabletBackgroundIcons
    flexGrow={1}
    display="flex"
    width="100%"
    bgcolor="willfulxxxLightBlue.main"
  >
    <Grid container spacing={0} justify="center" alignItems="stretch">
      <Grid item md={9} sm={12}>
        <Form onSubmit={handleSubmit}>
          <Box p={1.25}>
            {isFacebookReferral && (
              <Box mb={1.25}>
                <H2 component="h1" color="willfulBlue" align="left">
                  {translations.heroText}
                </H2>
              </Box>
            )}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Box justifyContent="center">
                <FormFields
                  error={error}
                  isLoading={isLoading}
                  provinces={provinces}
                  isMobileTablet
                  loginLink={loginLink}
                  isInvitedUser={isInvitedUser}
                  translations={translations}
                  isArborReferral={isArborReferral}
                  checkEmailAvailability={checkEmailAvailability}
                  invalid={invalid}
                />
              </Box>
              {translations.miceText && (
                <Box mt={2}>
                  <MiceType
                    color="willfulLightGrey.main"
                    dangerouslySetInnerHTML={{ __html: translations.miceText }}
                  />
                </Box>
              )}
              {translations.testimonial && (
                <Testimonial translations={translations} isMobile />
              )}
              <MobileTrustIconsForSignUp
                isFrench={isFrenchSelected}
                translations={translations}
                shouldIncludeThirtyDay
              />
            </Box>
          </Box>
        </Form>
      </Grid>
    </Grid>
  </MobileTabletBackgroundIcons>
);

const SignupForm = (props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isDesktop) return <DesktopForm {...props} />;
  return <MobileTabletForm {...props} isMobile={isMobile} />;
};

export default reduxForm({ form: SIGNUP_FORM_ID })(SignupForm);
