import { createSelector, createStructuredSelector } from "reselect";

import { selectTranslations } from "./translations";
import { selectFirstName } from "./about-you";
import { selectAssetListPageUrl, selectHasAssets } from "./asset-list";
import { selectActivePlan, selectHasUserPaid } from "./plans";
import { selectHasAppointee } from "./appointees";
import { selectNextOverviewPathForDashboard } from "./dashboard";
import { selectHasLoadedDataById } from "./requests";
import {
  selectOverallSectionCompletionPercentage,
  selectOverallSectionCompletionPercentageForProgressBar,
} from "./section-completion";
import { referralProgramProps } from "./referral-program";

const selectWelcomeBackTranslations = createSelector(
  selectTranslations(["global", "welcomeBackPage", "getStartedCards"]),
  selectFirstName,
  (translations, firstName) => {
    return {
      ...translations,
      title: translations.title.replace("FIRST_NAME", firstName),
    };
  },
);

export const welcomeBackProps = createStructuredSelector({
  translations: selectWelcomeBackTranslations,
  hasAssets: selectHasAssets,
  activePlan: selectActivePlan,
  completionPercentage: selectOverallSectionCompletionPercentage,
  completionPercentageForProgressBar: selectOverallSectionCompletionPercentageForProgressBar,
  hasAppointee: selectHasAppointee,
  nextOverviewPath: selectNextOverviewPathForDashboard,
  assetListPageUrl: selectAssetListPageUrl,
  hasLoaded: selectHasLoadedDataById("welcomeBack"),
  hasPaid: selectHasUserPaid,
  referralProgramProps,
});
