import { getRequest, postRequest, deleteRequest, putRequest } from "./request";
import {
  LEADS_ENDPOINT,
  SIGNUP_ENDPOINT,
  AUTH_LOGIN_ENDPOINT,
  AUTH_SIGNUP_ENDPOINT,
  AUTH_DELETE_ACCOUNT_ENDPOINT,
  AUTH_SIGNOUT_ENDPOINT,
  AUTH_PASSWORD_ENDPOINT,
  AUTH_PASSWORD_UPDATE_ENDPOINT,
  AUTH_VALIDATE_TOKEN_ENDPOINT,
  CHECK_EMAIL_AVAILABILITY_ENDPOINT,
  GOOGLE_OAUTH_ENDPOINT,
  FORGOT_SSO_PASSWORD_ENDPOINT,
  AUTH_DELETE_GOOGLE_SSO_ACCOUNT_ENDPOINT,
} from "../constants/routes";

export const submitLockedProvinceLead = (config) =>
  postRequest(LEADS_ENDPOINT, config);

export const getSignup = (config) => getRequest(SIGNUP_ENDPOINT, config);

export const submitSignup = (config) =>
  postRequest(AUTH_SIGNUP_ENDPOINT, config);

export const submitInvitedUserSignup = (config) =>
  putRequest(AUTH_SIGNUP_ENDPOINT, config);

export const submitDeleteAccount = (config) =>
  deleteRequest(AUTH_DELETE_ACCOUNT_ENDPOINT, config);

export const submitDeleteGoogleSSOAccount = (config) =>
  deleteRequest(AUTH_DELETE_GOOGLE_SSO_ACCOUNT_ENDPOINT, config);

export const submitLogin = (config) => postRequest(AUTH_LOGIN_ENDPOINT, config);

export const submitGoogleAuthLogin = (config) =>
  postRequest(GOOGLE_OAUTH_ENDPOINT, config);

export const submitLogout = (config) =>
  deleteRequest(AUTH_SIGNOUT_ENDPOINT, config);

export const submitForgotPassword = (config) =>
  postRequest(AUTH_PASSWORD_ENDPOINT, config);

export const submitResetPassword = (config) =>
  putRequest(AUTH_PASSWORD_ENDPOINT, config);

export const submitUpdatePassword = (config) =>
  putRequest(AUTH_PASSWORD_UPDATE_ENDPOINT, config);

export const getValidateToken = (config) =>
  getRequest(AUTH_VALIDATE_TOKEN_ENDPOINT, config);

export const postEmailAvailabilityRequest = (config) =>
  postRequest(CHECK_EMAIL_AVAILABILITY_ENDPOINT, config);

export const postForgotSsoPasswordRequest = (config) =>
  postRequest(FORGOT_SSO_PASSWORD_ENDPOINT, config);
