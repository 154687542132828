import { combineReducers } from "redux";

import { ADD_DOCUMENTS_TYPE } from "../actions/documents";

const documentLinks = (state = {}, { type, payload }) => {
  switch (type) {
    case ADD_DOCUMENTS_TYPE:
      return payload.documents;
    default:
      return state;
  }
};

export default combineReducers({
  documentLinks,
});
