import { deleteRequest, getRequest, putRequest } from "./request";
import {
  PET_GUARDIANS_ENDPOINT,
  PET_GUARDIANS_NEXT_PAGE_ENDPOINT,
  PET_GUARDIANS_UPDATE_ENDPOINT,
} from "../constants/routes";

export const submitPetTrust = (config) =>
  putRequest(PET_GUARDIANS_ENDPOINT, config);

export const submitPetGuardians = (config) =>
  putRequest(PET_GUARDIANS_UPDATE_ENDPOINT, config);

export const removePetGuardiansEndpoint = (config) =>
  deleteRequest(PET_GUARDIANS_ENDPOINT, config);

export const getPetGuardiansNextPage = (config) =>
  getRequest(PET_GUARDIANS_NEXT_PAGE_ENDPOINT, config);
