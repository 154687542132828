import R from "ramda";

import { createStructuredSelector, createSelector } from "reselect";
import { getFormValues } from "redux-form";
import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";
import { selectTranslations } from "./translations";
import { selectAppointeeLevelIndexs } from "./utils/form-appointees";
import { selectIsFieldComplete } from "./utils/progress";
import { selectHasSpouse } from "./spouse";
import { selectExecutorsTranslations } from "./executors";
import {
  EXECUTORS_FORM_ID,
  EXECUTORS_FIELD_ID,
  SPOUSE_IS_EXECUTOR_FIELD_ID,
} from "../constants/forms";

const selectSpouseArrangementsData = R.pathOr({}, ["spouseArrangements"]);

export const selectIsSpouseExecutorComplete = createSelector(
  selectIsFieldComplete(selectSpouseArrangementsData, EXECUTORS_FIELD_ID),
  selectIsFieldComplete(
    selectSpouseArrangementsData,
    SPOUSE_IS_EXECUTOR_FIELD_ID,
    true,
  ),
  (executorsComplete, spouseHasExecutorComplete) =>
    spouseHasExecutorComplete || executorsComplete,
);

const selectFormSpouseIsExecutor = createSelector(
  getFormValues(EXECUTORS_FORM_ID),
  (formValues) => R.propOr(false, SPOUSE_IS_EXECUTOR_FIELD_ID)(formValues),
);

export const spouseExecutorsPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(EXECUTORS_FORM_ID),
  isLoading: selectIsLoadingById(EXECUTORS_FORM_ID),
  appointeeLevelIndexs: selectAppointeeLevelIndexs(EXECUTORS_FORM_ID),
  isSpouseExecutor: selectFormSpouseIsExecutor,
  hasSpouse: selectHasSpouse,
  translations: createSelector(
    selectExecutorsTranslations,
    selectTranslations(["global", "spouseArrangements"]),
    (translations, { sectionTitle }) => ({ ...translations, sectionTitle }),
  ),
});
