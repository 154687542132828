import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { selectCurrentRoute } from "./location";
import { headerProps } from "./header";
import { toastProps } from "./toast";
import { selectTranslations } from "./translations";
import {
  footerRoutes,
  hideHeaderRoutes,
  pageWrapperRoutes,
} from "../constants/navigation";
import { isPartnerDiscountBannerOpen } from "./partner-discount";
import { selectPartnerDiscountBannerTranslations } from "./banners";
import { selectLanguageCode } from "./language";
import { PAGES_WITH_STICKY_CTA } from "../constants/mobile";

// TODO: Figure out long-term solution for circular-dependency selector issue
const selectIsLoggedIn = R.pathOr(false, ["auth", "loggedIn"]);

const selectShouldDisplayFooter = createSelector(
  selectCurrentRoute,
  (currentRoute) => {
    return R.includes(currentRoute, footerRoutes);
  },
);

const selectShouldDisplayHeader = createSelector(
  selectCurrentRoute,
  (currentRoute) => {
    const isHidingRoute = R.includes(currentRoute, hideHeaderRoutes);
    // We will hide the Header NavBar Component hidden (on both Desktop and Mobile)
    // when a user reaches one of the "hideHeaderRoutes". This is an attempt to reduce
    // distractions from the user in order to improve CVR
    return !isHidingRoute;
  },
);

const selectShouldUsePageWrapper = createSelector(
  selectCurrentRoute,
  (currentRoute) => {
    return !R.includes(currentRoute, pageWrapperRoutes);
  },
);

const selectTitleName = createSelector(
  selectCurrentRoute,
  selectTranslations(["global", "urlLabels"]),
  (currentRoute, urlLabels) => {
    return `${urlLabels[currentRoute]} | Willful`;
  },
);

const selectIsPageWithStickyCTA = createSelector(
  selectCurrentRoute,
  (currentRoute) => {
    return PAGES_WITH_STICKY_CTA.includes(currentRoute);
  },
);

export const layoutProps = createStructuredSelector({
  isAuthenticated: selectIsLoggedIn,
  currentPath: selectCurrentRoute,
  shouldDisplayFooter: selectShouldDisplayFooter,
  shouldUsePageWrapper: selectShouldUsePageWrapper,
  titleName: selectTitleName,
  hasPartnerDiscount: isPartnerDiscountBannerOpen,
  partnerDiscountBannerTranslations: selectPartnerDiscountBannerTranslations,
  shouldDisplayHeader: selectShouldDisplayHeader,
  languageCode: selectLanguageCode,
  headerProps,
  toastProps,
});

export const footerProps = createStructuredSelector({
  translations: selectTranslations(["global", "footer", "links"]),
  hasStickyButton: selectIsPageWithStickyCTA,
});
