import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { getFormValues } from "redux-form";

import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";

import {
  PERSONAL_ATTORNEYS_FORM_ID,
  PERSONAL_ATTORNEYS_FIELD_ID,
  SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID,
} from "../constants/forms";

import { selectTranslations } from "./translations";
import { selectAppointeeLevelIndexs } from "./utils/form-appointees";
import {
  selectHasSpouse,
  selectSpouseFirstName,
  selectIsCurrentUserSpouse,
  selectSpouseLastName,
} from "./spouse";
import { selectIsFieldComplete } from "./utils/progress";
import { selectFirstName } from "./about-you";
import { buildFullName } from "../utilities/name";

const selectArrangementsData = R.pathOr({}, ["yourArrangements"]);

export const selectFormSpouseIsPersonalAttorney = createSelector(
  getFormValues(PERSONAL_ATTORNEYS_FORM_ID),
  (formValues) =>
    R.propOr(false, SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID)(formValues),
);

export const selectSpouseIsPersonalAttorney = R.path([
  "yourArrangements",
  SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID,
]);

export const selectPersonalAttorneys = R.path([
  "yourArrangements",
  PERSONAL_ATTORNEYS_FIELD_ID,
]);
export const selectSpouseSpouseIsPersonalAttorney = R.path([
  "spouseArrangements",
  SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID,
]);
export const selectSpousePersonalAttorneys = R.path([
  "spouseArrangements",
  PERSONAL_ATTORNEYS_FIELD_ID,
]);

export const selectLastSelectedPersonalAttorneyData = R.path([
  "formUi",
  "lastSelectedPersonalAttorneyData",
]);

export const selectLastSelectedPersonalAttorneyFullNameAndTitle = createSelector(
  selectLastSelectedPersonalAttorneyData,
  selectTranslations(["yourArrangements", "appointees"]),
  (personalAttorneyData, translations) => {
    if (!personalAttorneyData) return "";
    const { firstName, middleName, lastName } = personalAttorneyData;
    return {
      fullName: buildFullName(firstName, middleName, lastName),
      appointeeTitle: translations.personalAppointeeTitle || "",
    };
  },
);

export const selectPersonalAttorneysForm = createSelector(
  selectIsCurrentUserSpouse,
  selectPersonalAttorneys,
  selectSpouseIsPersonalAttorney,
  selectSpousePersonalAttorneys,
  selectSpouseSpouseIsPersonalAttorney,
  (
    isSpouse,
    personalAttorneys,
    spouseIsPersonalAttorney,
    spousePA,
    spouseSpouseIsPA,
  ) => ({
    [PERSONAL_ATTORNEYS_FIELD_ID]: isSpouse ? spousePA : personalAttorneys,
    [SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID]: isSpouse
      ? spouseSpouseIsPA
      : spouseIsPersonalAttorney,
  }),
);

export const selectIsPersonalAttorneysComplete = createSelector(
  selectIsFieldComplete(selectArrangementsData, PERSONAL_ATTORNEYS_FIELD_ID),
  selectIsFieldComplete(
    selectArrangementsData,
    SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID,
    true,
  ),
  selectHasSpouse,
  (personalAttorneyIsComplete, spouseIsPAIsComplete, hasSpouse) => {
    if (hasSpouse) {
      return spouseIsPAIsComplete || personalAttorneyIsComplete;
    }
    return personalAttorneyIsComplete;
  },
);

export const selectPersonalAttorneysTranslations = createSelector(
  selectTranslations([
    "global",
    PERSONAL_ATTORNEYS_FORM_ID,
    "provinces",
    "appointees",
    "navigation",
    "notice",
  ]),
  selectIsCurrentUserSpouse,
  selectHasSpouse,
  selectFirstName,
  selectSpouseFirstName,
  selectSpouseLastName,
  (
    translations,
    isSpouse,
    hasSpouse,
    firstName,
    spouseFirstName,
    spouseLastName,
  ) => {
    const {
      userSpousePersonalAttorneyQuestion,
      personalAppointeeTitle,
      personalAppointeeTitlePlural,
      subQuestionOptional,
    } = translations;

    const userSpousePersonalAttorneyQuestionTranslation = hasSpouse
      ? userSpousePersonalAttorneyQuestion.replace(
          "SPOUSE_NAME",
          isSpouse ? firstName : `${spouseFirstName} ${spouseLastName}`,
        )
      : "";

    const subQuestionOptionalWithTitle = subQuestionOptional.replace(
      "{PERSONAL_APPOINTEE}",
      (personalAppointeeTitlePlural &&
        personalAppointeeTitlePlural.toLowerCase()) ||
        "",
    );

    return {
      ...translations,
      subQuestionOptional: subQuestionOptionalWithTitle,
      userSpousePersonalAttorneyQuestion: userSpousePersonalAttorneyQuestionTranslation,
      appointeeTitle: personalAppointeeTitle,
      button: {
        ...translations.button,
        addLabel: translations.button.addLabel.replace(
          "PERSONAL_APPOINTEE",
          personalAppointeeTitle,
        ),
        addMoreLabel: translations.button.addMoreLabel.replace(
          "PERSONAL_APPOINTEE",
          personalAppointeeTitle,
        ),
        removeLabel: translations.button.removeLabel.replace(
          "PERSONAL_APPOINTEE",
          personalAppointeeTitle,
        ),
      },
    };
  },
);

const selectShouldShowCTAButton = createSelector(
  selectFormSpouseIsPersonalAttorney,
  selectHasSpouse,
  (isSpousePersonalAttorney, hasSpouse) => {
    return !hasSpouse || isSpousePersonalAttorney !== "";
  },
);

export const personalAttorneysPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(PERSONAL_ATTORNEYS_FORM_ID),
  isLoading: selectIsLoadingById(PERSONAL_ATTORNEYS_FORM_ID),
  appointeeLevelIndexs: selectAppointeeLevelIndexs(PERSONAL_ATTORNEYS_FORM_ID),
  spouseIsPersonalAttorney: selectFormSpouseIsPersonalAttorney,
  hasSpouse: selectHasSpouse,
  translations: selectPersonalAttorneysTranslations,
  tooltipKey: () => "personalattorney.sectionPage",
  shouldShowCTAButton: selectShouldShowCTAButton,
});
