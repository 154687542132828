import React from "react";

import Box from "@material-ui/core/Box";

import { H3, Body } from "../Typography";
import { TextButton } from "../Button";
import { getAllocationName } from "../../../utilities/allocations";

const BackupBeneficiary = ({ onRemove, backup, charities, translations }) => {
  if (!backup.type) {
    return null;
  }
  const backupName = getAllocationName(backup, charities);
  return (
    <Box
      display="flex"
      p={1}
      mt={1}
      bgcolor="willfulWhite.main"
      border={1}
      borderColor="border.main"
      borderRadius={0.2}
      justifyContent="space-between"
    >
      <Box pr={1}>
        <Body component="span" align="left" data-hj-suppress>
          {backupName}
        </Body>
      </Box>
      <TextButton
        fullWidth
        type="button"
        text={translations.button.remove}
        onClick={onRemove}
      />
    </Box>
  );
};

const BackupField = ({
  input: { value },
  charities,
  translations,
  removeBackup,
}) => {
  const backups = value;

  if (!backups.length > 0) {
    return null;
  }

  return (
    <>
      <Box mt={4.5}>
        <H3 align="left">{translations.subTitle}</H3>
        <Body>{translations.subBody}</Body>
      </Box>
      <Box mb={2}>
        {backups.map((backup, index) => (
          <BackupBeneficiary
            key={index}
            backup={backup}
            charities={charities}
            onRemove={() => removeBackup(backup)}
            translations={translations}
          />
        ))}
      </Box>
    </>
  );
};

export default BackupField;
