import React from "react";
import { Field } from "redux-form";
import {
  useValidateFirstNameMemo,
  useValidateMiddleNameMemo,
  useValidateLastNameMemo,
} from "./validations";

import TextInput from "./TextInput";
import {
  FIRST_NAME_FIELD_ID,
  MIDDLE_NAME_FIELD_ID,
  LAST_NAME_FIELD_ID,
} from "../../../constants/forms";

const NameFields = ({
  fieldName,
  autoFocus,
  useTheir,
  translations,
  disableMiddleName,
}) => {
  const firstFieldName = fieldName
    ? `${fieldName}.${FIRST_NAME_FIELD_ID}`
    : FIRST_NAME_FIELD_ID;
  const middleFieldName = fieldName
    ? `${fieldName}.${MIDDLE_NAME_FIELD_ID}`
    : MIDDLE_NAME_FIELD_ID;
  const lastFieldName = fieldName
    ? `${fieldName}.${LAST_NAME_FIELD_ID}`
    : LAST_NAME_FIELD_ID;
  const firstNamePlaceholder = useTheir
    ? translations.label.firstNameTheirPlaceholder
    : translations.label.firstNamePlaceholder;
  const middleNamePlaceholder = useTheir
    ? translations.label.middleNameTheirPlaceholder
    : translations.label.middleNamePlaceholder;
  const lastNamePlaceholder = useTheir
    ? translations.label.lastNameTheirPlaceholder
    : translations.label.lastNamePlaceholder;
  return (
    <>
      <Field
        component={TextInput}
        autoFocus={autoFocus}
        validate={useValidateFirstNameMemo(translations)}
        label={translations.label.firstName}
        placeholder={firstNamePlaceholder}
        name={firstFieldName}
      />
      {!disableMiddleName && (
        <Field
          component={TextInput}
          validate={useValidateMiddleNameMemo(translations)}
          label={translations.label.middleName}
          placeholder={middleNamePlaceholder}
          name={middleFieldName}
        />
      )}
      <Field
        component={TextInput}
        validate={useValidateLastNameMemo(translations)}
        label={translations.label.lastName}
        placeholder={lastNamePlaceholder}
        name={lastFieldName}
      />
    </>
  );
};

export default NameFields;
