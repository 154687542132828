import React, { useEffect } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { connect } from "react-redux";

import { FormWrapper } from "../../../components/ui/Form";
import {
  fetchBackup,
  removeBackup,
  updateBackup,
} from "../../../actions/allocations";
import { skipSection } from "../../../actions/navigation";
import { backupPageProps } from "../../../selectors/backup";
import { BACKUPS_GUIDANCE_PATH } from "../../../constants/routes";
import { skipBackups } from "../../../api/allocations";
import {
  addBackupBeneficiary,
  addCharityBeneficiary,
  addPersonBeneficiary,
  cancelAddingBeneficiary,
} from "../../../actions/beneficiary";

import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import BackupsFormDeprecated from "../../../components/forms/your-estate/BackupsFormDeprecated";
import { BACKUPS_FORM_ID } from "../../../constants/forms";
import AddBeneficiaryForm from "../../../components/forms/your-estate/AddBeneficiaryForm";
import { displayModal } from "../../../actions/modal";
import { MODAL_ADD_BACKUP_PERSON } from "../../../constants/modal";

const BackupPage = ({
  addBackupBeneficiary,
  skipSection,
  fetchBackup,
  removeBackup,
  updateBackup,
  hasLoaded,
  isAddingBeneficiary,
  backupForm,
  addBeneficiaryForm,
  hasSelectedBackup,
  ...restOfProps
}) => {
  useEffect(() => {
    fetchBackup();
  }, []);
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));

  if (!hasLoaded) return <LoadingSpinner />;
  const { translations } = restOfProps;
  const handleSkip = () => skipSection(skipBackups, BACKUPS_FORM_ID);

  return (
    <FormWrapper
      includePadding
      translations={translations}
      reverseWrap={isTabletDown}
    >
      <AddBeneficiaryForm
        onSubmit={() => addBackupBeneficiary()}
        hideButtons={hasSelectedBackup}
        modalKey={MODAL_ADD_BACKUP_PERSON}
        {...addBeneficiaryForm}
        {...restOfProps}
      />
      {!isAddingBeneficiary && (
        <BackupsFormDeprecated
          onSubmit={() => updateBackup()}
          onSkip={handleSkip}
          removeBackup={removeBackup}
          backLink={BACKUPS_GUIDANCE_PATH}
          {...restOfProps}
          {...backupForm}
        />
      )}
    </FormWrapper>
  );
};

export default connect(backupPageProps, {
  addBackupBeneficiary,
  fetchBackup,
  removeBackup,
  updateBackup,
  skipSection,
  addCharityBeneficiary,
  addPersonBeneficiary,
  cancelAddingBeneficiary,
  displayModal,
})(BackupPage);
