import R from "ramda";
import { createSelector } from "reselect";
import {
  DOWNLOAD_ASSET_LIST,
  DOWNLOAD_POA,
  DOWNLOAD_WILL,
  UPDATE_ASSETS_LIST,
  UPDATE_POA,
  UPDATE_WILL,
} from "../constants/features";
import { selectIsCurrentProvinceSelectedByCode } from "./provinces";

const BNPLSurveyEnabled = () => window.env.FEATURE_BNPL_OZ === "enabled";

export const selectIsBNPLOZEnabled = createSelector(
  BNPLSurveyEnabled,
  selectIsCurrentProvinceSelectedByCode("QC"),
  (isBNPLSurveyEnabled, isQuebecSelected) =>
    isBNPLSurveyEnabled && !isQuebecSelected,
);

export const selectUserFeatures = R.pathOr(
  {
    custom_feature_access: [],
    product: [],
  },
  ["features", "featuresAccess"],
);

// CUSTOM FEATURE ACCESS
export const selectUserCustomFeatureAccess = createSelector(
  selectUserFeatures,
  (userFeatures) => R.propOr([], "custom_feature_access")(userFeatures),
);

export const selectUserHasCustomFeatureAccess = createSelector(
  selectUserCustomFeatureAccess,
  (userCustomFeatureAccess) => userCustomFeatureAccess.length !== 0,
);

export const selectUserHasUnlimitedWillDownloadsCFA = createSelector(
  selectUserCustomFeatureAccess,
  selectUserHasCustomFeatureAccess,
  (userCustomFeatureAccess, userHasCustomFeatureAccess) => {
    return !userHasCustomFeatureAccess
      ? false
      : userCustomFeatureAccess.includes(DOWNLOAD_WILL);
  },
);

export const selectUserHasUnlimitedAssetListDownloadsCFA = createSelector(
  selectUserCustomFeatureAccess,
  selectUserHasCustomFeatureAccess,
  (userCustomFeatureAccess, userHasCustomFeatureAccess) => {
    return !userHasCustomFeatureAccess
      ? false
      : userCustomFeatureAccess.includes(DOWNLOAD_ASSET_LIST);
  },
);

export const selectUserHasUnlimitedPOADownloadsCFA = createSelector(
  selectUserCustomFeatureAccess,
  selectUserHasCustomFeatureAccess,
  (userCustomFeatureAccess, userHasCustomFeatureAccess) => {
    return !userHasCustomFeatureAccess
      ? false
      : userCustomFeatureAccess.includes(DOWNLOAD_POA);
  },
);

export const selectUserHasUnlimitedWillUpdatesCFA = createSelector(
  selectUserCustomFeatureAccess,
  selectUserHasCustomFeatureAccess,
  (userCustomFeatureAccess, userHasCustomFeatureAccess) => {
    return !userHasCustomFeatureAccess
      ? false
      : userCustomFeatureAccess.includes(UPDATE_WILL);
  },
);

const flattenAllFeatures = (userFeatures) => {
  const { custom_feature_access, product } = userFeatures;
  if (!custom_feature_access && !product) {
    return [];
  }
  return [...custom_feature_access, ...product];
};

export const selectUserHasDownloadWillFunctionality = createSelector(
  selectUserFeatures,
  (userFeatures) => {
    const allFeatures = flattenAllFeatures(userFeatures);
    return allFeatures.includes(DOWNLOAD_WILL);
  },
);

export const selectUserHasDownloadPOAFunctionality = createSelector(
  selectUserFeatures,
  (userFeatures) => {
    const allFeatures = flattenAllFeatures(userFeatures);
    return allFeatures.includes(DOWNLOAD_POA);
  },
);

export const selectUserHasDownloadAssetsListFunctionality = createSelector(
  selectUserFeatures,
  (userFeatures) => {
    const allFeatures = flattenAllFeatures(userFeatures);
    return allFeatures.includes(DOWNLOAD_ASSET_LIST);
  },
);

export const selectUserHasUpdateWillFunctionality = createSelector(
  selectUserFeatures,
  (userFeatures) => {
    const allFeatures = flattenAllFeatures(userFeatures);
    return allFeatures.includes(UPDATE_WILL);
  },
);

export const selectUserHasUpdatePOAFunctionality = createSelector(
  selectUserFeatures,
  (userFeatures) => {
    const allFeatures = flattenAllFeatures(userFeatures);
    return allFeatures.includes(UPDATE_POA);
  },
);

export const selectUserHasUpdateAssetsListFunctionality = createSelector(
  selectUserFeatures,
  (userFeatures) => {
    const allFeatures = flattenAllFeatures(userFeatures);
    return allFeatures.includes(UPDATE_ASSETS_LIST);
  },
);
