import React from "react";
import Box from "@material-ui/core/Box";

import { PrimaryButton } from "../../ui/Button";
import {
  CALENDLY_NOTARIZATION_BOOKING_EN_URL,
  CALENDLY_NOTARIZATION_BOOKING_FR_URL,
} from "../../../constants/routes";
import YourStatusLabel from "../../ui/YourStatusLabel";

const YourNotarialContent = ({
  isCompleteAllSections,
  notarizationStatus,
  isFrenchSelected,
  dashboardView,
  translations,
}) => {
  const { bookAppointment, bookAppointmentUnavailable } = translations;
  const schedulingUrl = isFrenchSelected
    ? CALENDLY_NOTARIZATION_BOOKING_FR_URL
    : CALENDLY_NOTARIZATION_BOOKING_EN_URL;
  const shouldDisplayBookingLink =
    isCompleteAllSections &&
    notarizationStatus !== "scheduled" &&
    notarizationStatus !== "completed" &&
    notarizationStatus !== "unavailable";
  const shouldDisplayStatus =
    isCompleteAllSections &&
    (notarizationStatus === "scheduled" ||
      notarizationStatus === "completed" ||
      notarizationStatus === "unavailable");
  const featureHideNotarizationBookingEnabled = false;
  // window.env.FEATURE_HIDE_NOTARIZATION_BOOKING === "enabled";

  return (
    <>
      {/* Display Docs Incomplete */}
      {!isCompleteAllSections && (
        <Box mt={2}>
          <YourStatusLabel
            status="documentsIncomplete"
            translations={translations}
          />
        </Box>
      )}

      {/* Not Dashboard View - Your Account */}
      {!dashboardView &&
        // Should display booking link - notarization not scheduled and not completed
        shouldDisplayBookingLink &&
        (featureHideNotarizationBookingEnabled ? (
          <Box mt={2}>
            <PrimaryButton
              fullWidth
              align="center"
              secondary
              text={bookAppointmentUnavailable}
              isDisabled
            />
          </Box>
        ) : (
          <Box mt={2}>
            <PrimaryButton
              href={schedulingUrl}
              fullWidth
              align="center"
              secondary
              displayArrowRight
              text={bookAppointment}
            />
          </Box>
        ))}

      {!dashboardView &&
        // Should display status - notarization scheduled or completed
        shouldDisplayStatus &&
        !featureHideNotarizationBookingEnabled && (
          <Box mt={2}>
            <YourStatusLabel
              status={notarizationStatus}
              translations={translations}
            />
          </Box>
        )}
    </>
  );
};

export default YourNotarialContent;
