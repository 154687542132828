import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";
import { selectIsFieldComplete } from "./utils/progress";
import {
  DONATIONS_FORM_ID,
  DONATIONS_FIELD_ID,
  SHARE_DONATION_DETAIL_FIELD_ID,
} from "../constants/forms";
import { selectTranslations } from "./translations";
import { selectIsCurrentUserSpouse } from "./spouse";
import { selectLanguageCode, selectIsFrenchSelected } from "./language";

const selectYourEstateData = R.pathOr({}, ["yourEstate"]);

export const selectIsSharingDonationDetails = createSelector(
  R.pathOr(false, ["yourEstate", SHARE_DONATION_DETAIL_FIELD_ID]),
  (isSharingDonationDetails) => isSharingDonationDetails,
);

export const selectCharities = R.path(["charities"]);
export const selectHasCharities = createSelector(
  selectCharities,
  (charities) => !R.isEmpty(charities),
);

export const selectDonations = R.path(["yourEstate", DONATIONS_FIELD_ID]);
export const selectSpouseDonations = R.path([
  "spouseEstate",
  DONATIONS_FIELD_ID,
]);

export const selectDonationsForm = createSelector(
  selectDonations,
  selectSpouseDonations,
  selectIsCurrentUserSpouse,
  selectIsSharingDonationDetails,
  (donations, spouseDonations, isSpouse, isSharingDonationDetails) => ({
    [DONATIONS_FIELD_ID]: isSpouse ? spouseDonations : donations,
    [SHARE_DONATION_DETAIL_FIELD_ID]: isSharingDonationDetails,
  }),
);

export const selectHasSkippedDonations = R.path([
  "yourEstate",
  "donationsVisited",
]);

export const selectIsDonationsComplete = createSelector(
  selectIsFieldComplete(selectYourEstateData, DONATIONS_FIELD_ID),
  selectHasSkippedDonations,
  (donationsComplete, skippedDonations) =>
    donationsComplete || skippedDonations,
);

export const selectCharitiesOptionList = createSelector(
  selectCharities,
  R.map((charity) => ({
    label: charity.name,
    value: charity.id,
  })),
);

const selectDonationsInForm = R.pathOr(
  [],
  ["form", "donations", "values", "donations"],
);
const selectHasDonations = createSelector(
  selectDonationsInForm,
  (donations) => donations.filter((d) => d._destroy !== true).length > 0,
);

const selectFeturedCharityName = () => {
  const featuredCharityName = window.env.FEATURED_CHARITY_NAME || "";
  return featuredCharityName.trim();
};

export const selectCharitySelectTranslations = selectTranslations([
  "charityDropdown",
]);

export const donationsPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(DONATIONS_FORM_ID),
  isLoading: selectIsLoadingById(DONATIONS_FORM_ID),
  isFrenchSelected: selectIsFrenchSelected,
  languageCode: selectLanguageCode,
  charitiesOptionList: selectCharitiesOptionList,
  translations: selectTranslations(["global", DONATIONS_FORM_ID]),
  hasDonations: selectHasDonations,
  featuredCharityName: selectFeturedCharityName,
});

const selectSpouseEstateData = R.pathOr({}, ["spouseEstate"]);
const selectHasSkippedSpouseDonations = R.path([
  "spouseEstate",
  "donationsVisited",
]);

export const selectIsSpouseDonationsComplete = createSelector(
  selectIsFieldComplete(selectSpouseEstateData, DONATIONS_FIELD_ID),
  selectHasSkippedSpouseDonations,
  (donationsComplete, skippedDonations) =>
    donationsComplete || skippedDonations,
);

export const spouseDonationsPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(DONATIONS_FORM_ID),
  isLoading: selectIsLoadingById(DONATIONS_FORM_ID),
  charitiesOptionList: selectCharitiesOptionList,
  featuredCharityName: selectFeturedCharityName,
  translations: selectTranslations([
    "global",
    DONATIONS_FORM_ID,
    "spouseEstate",
  ]),
});
