import React from "react";
import SvgIcon from "./SvgIcon";

const CheckmarkLightBlue = () => {
  return (
    <SvgIcon viewBox="0 0 24 24" width="16px" height="16px">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-786.000000, -339.000000)">
          <g transform="translate(786.000000, 339.000000)">
            <g>
              <circle
                id="Oval"
                fill="#E2F6FF"
                transform="translate(12.000000, 12.000000) rotate(270.000000) translate(-12.000000, -12.000000)"
                cx="12"
                cy="12"
                r="12"
              ></circle>
              <polyline
                stroke="#0064DC"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="8 12.1556806 10.9682759 15.1140432 16.3793945 9"
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default CheckmarkLightBlue;
