import React from "react";
import Box from "@material-ui/core/Box";

import { H3, SmallBody } from "./Typography";
import { ExternalButtonLink } from "./Button";
import WillfulDocument from "./icons/WillfulDocument";
import {
  NOTARIZATION_GUIDE_PDF_EN_URL,
  NOTARIZATION_GUIDE_PDF_FR_URL,
} from "../../constants/routes";

const NotarizationGuideCard = ({
  translations,
  isFrenchSelected,
  analyticsDownloadNotarizationGuide,
}) => {
  const pdfLink = isFrenchSelected
    ? NOTARIZATION_GUIDE_PDF_FR_URL
    : NOTARIZATION_GUIDE_PDF_EN_URL;
  return (
    <Box
      p={1.5}
      border={1}
      borderRadius={4}
      borderColor="willfulGrey2.main"
      bgcolor="gray.main"
      mb={2}
    >
      <Box display="flex" mb={2}>
        <Box mr="auto">
          <Box mb={0.5}>
            <H3 color="willfulBlue" align="left">
              {translations.guideCardHeading}
            </H3>
          </Box>
          <SmallBody>{translations.guideCardText}</SmallBody>
        </Box>
        <Box ml={2}>
          <WillfulDocument height={74} width={82} />
        </Box>
      </Box>
      <ExternalButtonLink
        secondary
        fullWidth
        onClick={analyticsDownloadNotarizationGuide}
        align="center"
        text={translations.downloadPdf}
        url={pdfLink}
      />
    </Box>
  );
};

export default NotarizationGuideCard;
