import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";

import { Body } from "../ui/Typography";
import { ArrowLightBlueIcon } from "../ui/icons/ArrowIcons";

import { colorPalette } from "../../config/Theme";
import { menuToggleAnimationStyles } from "../ui/utils/animation-styles";

const DropdownButton = styled.button`
  z-index: 10;
  border: 0;
  width: 100%;
  height: 60px;
  background: transparent;
  cursor: pointer;
  outline: none;
  align-items: center;
  display: flex;
  flex-direction: row-reverse;

  color: ${colorPalette.white};
  transition: color 240ms ease-out;

  &:focus-visible {
    outline: ${colorPalette.white} solid 1px;
  }

  &:hover {
    color: ${colorPalette.willfulxxxLightBlue};
  }
`;

export const DropdownList = styled.ul`
  list-style-type: none;
  margin: 0;
  margin-top: 60px;
  padding: 0;
`;

export const DropdownOption = styled.li`
  height: 62px;
  list-style-type: none;
  border-bottom: 2px solid #d8d8d8;
  background-color: ${(props) => (props.$isHighlighted ? "#ecedef" : "white")};

  &:last-child {
    border-bottom: none;
  }

  &:focus,
  &:active,
  &:hover {
    background-color: #e2f6ff;
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  padding: 16px;
  text-decoration: none;
  color: #4a4a4a;

  &:visited {
    color: #4a4a4a;
  }
`;

export const ButtonOption = styled.button`
  display: flex;
  padding: 16px;
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`;

const DropdownMenuText = styled(Body)`
  white-space: nowrap;
  display: flex;
`;

const ArrowContainer = styled(Box)`
  transform: ${(props) => (props.$isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 240ms ease-out;
`;

export const DropdownMenuButton = ({
  isOpen,
  textAlign,
  translations,
  onClick,
  suppress = false,
}) => (
  <DropdownButton
    type="button"
    aria-expanded={isOpen}
    onClick={onClick}
    isOpen={isOpen}
    className="qa-dropdown-your-plan-button"
  >
    <Box component="span" display="flex" alignItems="center">
      <DropdownMenuText
        component="span"
        align={textAlign}
        {...(suppress && { "data-hj-suppress": true })}
        fullWidth
      >
        {translations.menuLabel}
        <ArrowContainer
          component="span"
          ml={0.5}
          display="flex"
          alignItems="center"
          $isOpen={isOpen}
        >
          <ArrowLightBlueIcon up />
        </ArrowContainer>
      </DropdownMenuText>
    </Box>
  </DropdownButton>
);

export const DropdownContainerExpanded = styled.div`
  /* This value should be > than cart widget menu (200) and less than the Nav Menu Button (250) */
  z-index: 8;

  min-width: 284px;
  position: absolute;
  top: 0;
  /* alignment value accounts for padding applied to trigger button when opened */
  left: ${(props) => (props.alignment === "left" ? "-16px" : "")};
  right: ${(props) => (props.alignment === "right" ? "-16px" : "")};
  background-color: #0064dc;
  border: 2px solid #004eb6;
  border-radius: 3px;
  filter: drop-shadow(5px 5px 20px rgba(74, 74, 74, 0.2));

  ${menuToggleAnimationStyles}
`;
