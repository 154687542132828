import React from "react";

import { reduxForm, Field } from "redux-form";
import Box from "@material-ui/core/Box";

import { PrimaryButton, TextButton } from "../../ui/Button";

import TextInput from "../../ui/inputs/TextInput";
import SelectInput from "../../ui/inputs/DropdownSelect";
import { ErrorMessage } from "../../ui/Error";

import {
  ASSET_TYPE_FIELD_ID,
  CONTACT_FIELD_ID,
  DESCRIPTION_FIELD_ID,
  INSURANCE_ASSETS_FORM_ID,
  INSURANCE_COMPANY_FIELD_ID,
} from "../../../constants/forms";
import {
  useValidateAssetTypeMemo,
  useValidateInsuranceCompanyMemo,
  useValidateInsuranceContactMemo,
  useValidateInsuranceDescriptionMemo,
} from "../../ui/inputs/validations";
import FlashMessage from "../../ui/FlashMessage";

const InsuranceFields = ({ translations }) => {
  return (
    <>
      <Box mb={1}>
        <Field
          component={TextInput}
          name={DESCRIPTION_FIELD_ID}
          label={translations.label.insuranceDescription}
          placeholder={translations.label.insuranceDescriptionPlaceholder}
          validate={useValidateInsuranceDescriptionMemo(translations)}
          noMargin
          suppress
        />
      </Box>

      <Box mb={2}>
        <FlashMessage
          type="warning"
          text={translations.warnings.assetList.addInsurance}
        />
      </Box>

      <Field
        component={TextInput}
        name={INSURANCE_COMPANY_FIELD_ID}
        label={translations.label.insuranceCompany}
        placeholder={translations.label.insuranceCompanyPlaceholder}
        validate={useValidateInsuranceCompanyMemo(translations)}
        suppress
      />
      <Field
        component={TextInput}
        name={CONTACT_FIELD_ID}
        label={translations.label.insuranceContact}
        placeholder={translations.label.insuranceContactPlaceholder}
        validate={useValidateInsuranceContactMemo(translations)}
        suppress
      />
    </>
  );
};

const InsuranceAssetForm = ({
  error,
  handleSubmit,
  handleDelete,
  isLoading,
  translations,
  extraProps,
}) => {
  const { assetType, isEditingAsset, isRemovingAsset } = extraProps;
  const ctaCopy = isEditingAsset
    ? translations.button.update
    : translations.button.submit;
  const labelText = isEditingAsset
    ? translations.loadingLabel.update
    : translations.loadingLabel.submit;
  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" justifyContent="center" pt={1}>
        {!isEditingAsset && (
          <Field
            name={ASSET_TYPE_FIELD_ID}
            label={translations.assetType}
            placeholder={translations.assetTypePlaceholder}
            component={SelectInput}
            validate={useValidateAssetTypeMemo(translations)}
            optionList={translations.optionList}
            contentPush
          />
        )}
        {assetType && <InsuranceFields translations={translations} />}

        <Box>
          <PrimaryButton
            fullWidth
            isLoading={isLoading && !isRemovingAsset}
            loadingLabel={labelText}
            isDisabled={isRemovingAsset}
            text={ctaCopy}
          />
        </Box>
        {isEditingAsset && (
          <Box display="flex" flexDirection="column" alignItems="center" mt={1}>
            <TextButton
              text={translations.button.remove}
              isLoading={isRemovingAsset}
              loadingLabel={translations.loadingLabel.remove}
              onClick={handleDelete}
              isDisabled={isLoading}
              color="error"
              loadingColor="loadingError"
              className="qa-remove-asset-btn"
            />
          </Box>
        )}
        <ErrorMessage error={error} />
      </Box>
    </form>
  );
};

export default reduxForm({
  form: INSURANCE_ASSETS_FORM_ID,
})(InsuranceAssetForm);
