import React from "react";
import Box from "@material-ui/core/Box";
import styled from "styled-components";

import ReferralBox from "./ReferralBox";
import { media } from "../../config/Theme";
import OverallProgressIndicator from "./OverallProgressIndicator";
import AppointeesNotificationCard from "./AppointeesNotificationCard";
import PlanSummary from "../plans/PlanSummary";

// TODO: better long term solution for handling this
const SummaryLayout = styled(Box)`
  width: 100%;

  ${media.desktop} {
    margin-left: auto;
    width: 75%;
  }
`;

const DashboardSummary = ({
  analyticsClickExternalLink,
  analyticsDownloadNotarizationGuide,
  planSummaryProps,
  hasPlan,
  hasAppointee,
  hasUserPaid,
  referralProgramProps,
  translations,
  showOverallProgressOnDashboard,
  progress,
  isFrenchSelected,
}) => {
  return (
    <SummaryLayout mt={7}>
      {showOverallProgressOnDashboard && (
        <Box mb={3}>
          <OverallProgressIndicator
            progress={progress}
            translations={translations}
            isFrenchSelected={isFrenchSelected}
          />
        </Box>
      )}
      {hasPlan && (
        <PlanSummary
          {...planSummaryProps}
          analyticsClickExternalLink={analyticsClickExternalLink}
          analyticsDownloadNotarizationGuide={
            analyticsDownloadNotarizationGuide
          }
        />
      )}
      {hasAppointee && (
        <AppointeesNotificationCard
          translations={translations.appointeesNotificationCard}
        />
      )}
      {hasUserPaid && (
        <Box mb={2}>
          <ReferralBox {...referralProgramProps} />
        </Box>
      )}
    </SummaryLayout>
  );
};

export default DashboardSummary;
