import { deleteRequest, putRequest } from "./request";
import {
  CHILDREN_ENDPOINT,
  CHILDREN_NEXT_PAGE_ENDPOINT,
} from "../constants/routes";

export const submitChildren = (config) => putRequest(CHILDREN_ENDPOINT, config);

export const removeChildEndpoint = (config) =>
  deleteRequest(CHILDREN_ENDPOINT, config);

export const getChildrenNextPage = (config) =>
  putRequest(CHILDREN_NEXT_PAGE_ENDPOINT, config);
