import React from "react";
import { Box } from "@material-ui/core";

import DocumentsCompleteIcon from "../../ui/icons/DocumentsCompleteIcon";
import SelectAPlanIcon from "../../ui/icons/SelectAPlanIcon";
import YourArrangementsIcon from "../../ui/icons/YourArrangementsIcon";
import YourEstateIcon from "../../ui/icons/YourEstateIcon";
import YourFamilyIcon from "../../ui/icons/YourFamilyIcon";

const OverviewSectionIcon = ({ currentSectionKey }) => {
  let icon;

  switch (currentSectionKey) {
    case "plans":
      icon = <SelectAPlanIcon />;
      break;
    case "yourFamily":
      icon = <YourFamilyIcon />;
      break;
    case "yourEstate":
    case "spouseEstate":
      icon = <YourEstateIcon />;
      break;
    case "yourArrangements":
    case "spouseArrangements":
      icon = <YourArrangementsIcon />;
      break;
    case "congratulations":
      icon = <DocumentsCompleteIcon />;
      break;
    case "aboutYou":
    default:
      icon = null;
      break;
  }

  return (
    <Box display="flex" justifyContent="center" my={3} mb={3}>
      {icon}
    </Box>
  );
};

export default OverviewSectionIcon;
