import styled from "styled-components";
import { Link } from "react-router-dom";

import { colorPalette } from "../../config/Theme";

const NavButtonLink = styled(Link)`
  border: 1px solid transparent;
  background: none;
  cursor: pointer;
  outline: none;
  color: white;
  padding: 16px;
  text-decoration: none;
  transition: color 240ms ease-out;

  &:focus-visible {
    outline: ${colorPalette.white} solid 1px;
  }

  &:hover {
    color: ${colorPalette.willfulxxxLightBlue};
  }
`;

export default NavButtonLink;
