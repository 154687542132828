export const fetchPersonalAttorneys = () => ({
  type: FETCH_PERSONAL_ATTORNEYS_TYPE,
});

export const personalAttorneysNextPage = () => ({
  type: PERSONAL_ATTORNEYS_NEXT_PAGE_TYPE,
});

export const openModalPersonalAttorney = (personalAttorney = null) => ({
  type: OPEN_MODAL_PERSONAL_ATTORNEY_TYPE,
  payload: { personalAttorney },
});

export const removePersonalAttorney = (personalAttorney) => {
  return {
    type: REMOVE_PERSONAL_ATTORNEY_TYPE,
    payload: { personalAttorney },
  };
};

export const PersonalAttorneyUpdatedSuccessfully = (personalAttorney) => ({
  type: UPDATE_PERSONAL_ATTORNEY_SUCCESSFULLY_TYPE,
  payload: { personalAttorney },
});

export const FETCH_PERSONAL_ATTORNEYS_TYPE = "FETCH_PERSONAL_ATTORNEYS";
export const OPEN_MODAL_PERSONAL_ATTORNEY_TYPE = "OPEN_MODAL_PERSONAL_ATTORNEY";
export const REMOVE_PERSONAL_ATTORNEY_TYPE = "REMOVE_PERSONAL_ATTORNEY";
export const UPDATE_PERSONAL_ATTORNEY_SUCCESSFULLY_TYPE =
  "UPDATE_PERSONAL_ATTORNEY_SUCCESSFULLY";
export const PERSONAL_ATTORNEYS_NEXT_PAGE_TYPE = "PERSONAL_ATTORNEYS_NEXT_PAGE";
