import R from "ramda";
import { takeEvery, call, put, select } from "redux-saga/effects";
import { change, getFormValues, startSubmit, stopSubmit } from "redux-form";

import {
  OPEN_MODAL_PET_TYPE,
  PETS_NEXT_PAGE_TYPE,
  petUpdatedSuccessfully,
  REMOVE_PET_TYPE,
  UPDATE_PETS_TYPE,
} from "../actions/pets";
import { submitFormRequest } from "./forms";
import { getPetsNextPage, removePetEndpoint, submitPets } from "../api/pets";
import {
  FIRST_NAME_FIELD_ID,
  PET_MODAL_FORM_ID,
  PETS_FIELD_ID,
  PETS_FORM_ID,
  SPECIES_FIELD_ID,
} from "../constants/forms";
import { closeToast, displayToast } from "../actions/toast";
import { closeModal, displayModal } from "../actions/modal";
import { MODAL_ADD_PET, MODAL_EDIT_PET } from "../constants/modal";
import {
  ADD_PET_SUCCESS,
  EDIT_PET_SUCCESS,
  REMOVE_PET_SUCCESS,
} from "../constants/toasts";
import { selectLastSelectedPetData } from "../selectors/pets";

function* updatePets(petToUpdate) {
  const { status, pet } = yield call(submitFormRequest, {
    apiCall: submitPets,
    formId: PETS_FORM_ID,
    values: {
      ...petToUpdate,
    },
  });
  if (status !== 200 || !pet) {
    yield put(stopSubmit(PETS_FORM_ID));
    return null;
  }
  return pet.id;
}

function* handleOpenModalPet({ payload }) {
  yield put(closeToast());
  const pet = R.propOr(null, "pet")(payload);
  if (pet) {
    yield put(
      change(PET_MODAL_FORM_ID, FIRST_NAME_FIELD_ID, pet.firstName || null),
    );
    yield put(change(PET_MODAL_FORM_ID, SPECIES_FIELD_ID, pet.species || null));
    return yield put(displayModal(MODAL_EDIT_PET));
  }
  return yield put(displayModal(MODAL_ADD_PET));
}

export function* handleAddOrEditPet(isEditing = false) {
  yield put(startSubmit(PET_MODAL_FORM_ID));
  try {
    const pet = yield select(getFormValues(PET_MODAL_FORM_ID));
    const newPet = {
      [FIRST_NAME_FIELD_ID]: pet.firstName || null,
      [SPECIES_FIELD_ID]: pet.species || null,
    };
    const updatedPets = isEditing
      ? yield call(editPet, newPet)
      : yield call(addPet, newPet);
    yield put(closeModal());
    yield put(change(PETS_FORM_ID, PETS_FIELD_ID, updatedPets));
    yield put(petUpdatedSuccessfully(newPet));
    yield put(displayToast(isEditing ? EDIT_PET_SUCCESS : ADD_PET_SUCCESS));
  } catch (error) {
    yield put(stopSubmit(PET_MODAL_FORM_ID, error.formErrors));
  }
}

function* addPet(newPet) {
  const petsList = yield select(getFormValues(PETS_FORM_ID));
  const id = yield call(updatePets, newPet);
  const updatedPet = { id, ...newPet };
  return [...petsList.pets, updatedPet];
}

function* editPet(newPet) {
  const selectedPet = yield select(selectLastSelectedPetData);
  const { pets } = yield select(getFormValues(PETS_FORM_ID));
  let updatedPet = {};
  const newPetsList = pets.map((pet, index) => {
    if (index === selectedPet.index) {
      updatedPet = {
        ...newPet,
        id: pet.id,
      };
      return updatedPet;
    }
    return pet;
  });
  yield call(updatePets, updatedPet);
  return newPetsList;
}

export function* handleRemovePet({ payload }) {
  const pet = R.propOr(null, "pet")(payload);
  yield put(closeToast());
  yield call(submitFormRequest, {
    apiCall: removePetEndpoint,
    values: {
      ...pet,
    },
  });
  yield put(displayToast(REMOVE_PET_SUCCESS));
}

function* handlePetsNextPage({ payload }) {
  const showZeroPetsNotice = R.propOr(false, "showZeroPetsNotice")(payload);
  return yield call(submitFormRequest, {
    apiCall: getPetsNextPage,
    values: {
      showZeroPetsNotice,
    },
  });
}

export function* watchUpdatePets() {
  yield takeEvery(UPDATE_PETS_TYPE, updatePets);
}

export function* watchOpenModalPet() {
  yield takeEvery(OPEN_MODAL_PET_TYPE, handleOpenModalPet);
}

export function* watchRemovePet() {
  yield takeEvery(REMOVE_PET_TYPE, handleRemovePet);
}

export function* watchPetsNextPage() {
  yield takeEvery(PETS_NEXT_PAGE_TYPE, handlePetsNextPage);
}
