import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";

import { PageLayout } from "../../components/ui/PageLayout";
import { Body, H1 } from "../../components/ui/Typography";
import { invalidInvitePageProps } from "../../selectors/invite";
import InvalidInviteIcon from "../../components/ui/icons/InvalidInviteIcon";
import { ExternalButtonLink } from "../../components/ui/Button";
import { WEBSITE_URL } from "../../constants/routes";

const InvalidInvitePage = ({ translations }) => {
  const { pageTitle, pageDescription } = translations;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <PageLayout>
      <Grid container>
        <Grid md={12} lg={6}>
          <Box maxWidth={600} margin="auto">
            <Box mb={isDesktop ? 2 : 1.5}>
              <H1 align="left">{pageTitle}</H1>
            </Box>
            <Box my={isDesktop ? 2 : 1.5}>
              <Body>{pageDescription}</Body>
            </Box>
            <ExternalButtonLink
              displayArrowRight
              text={translations.button.submit}
              url={WEBSITE_URL}
            />
          </Box>
        </Grid>
        <Grid md={12} lg={6} style={{ width: "100%" }}>
          <Box mt={4} display="flex" width={1} justifyContent="center">
            <InvalidInviteIcon
              width={isMobile ? "285px" : "450px"}
              height={isMobile ? "190px" : "325px"}
            />
          </Box>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default connect(invalidInvitePageProps)(InvalidInvitePage);
