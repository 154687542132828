import R from "ramda";

import { UPDATE_STATUS_TYPE } from "../actions/status";
import { GIFTS_FIELD_ID, DONATIONS_FIELD_ID } from "../constants/forms";

const DEFAULT_STATE = {
  gifts: [],
  donations: [],
  giftsVisited: false,
  donationsVisited: false,
};

const spouseEstate = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return {
        ...state,
        gifts: R.pathOr([], ["spouse", GIFTS_FIELD_ID])(payload),
        giftsVisited: R.pathOr(false, ["spouse", "giftsVisited"])(payload),
        donations: R.pathOr([], ["spouse", DONATIONS_FIELD_ID])(payload),
        donationsVisited: R.pathOr(false, ["spouse", "donationsVisited"])(
          payload,
        ),
      };
    default:
      return state;
  }
};

export default spouseEstate;
