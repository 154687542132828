import R from "ramda";
import { takeEvery, call, put } from "redux-saga/effects";
import { submitFormRequest } from "./forms";
import { externalLoginRequest } from "../api/external-login";
import { EXTERNAL_LOGIN_TYPE } from "../actions/external-login";
import { VALIDATE_TOKEN_EXPIRATION_TYPE } from "../actions/auth";

function* handleExternalLogin({ payload }) {
  try {
    const uid = yield R.propOr(null, "uid")(payload);
    const accessToken = yield R.propOr(null, "accessToken")(payload);
    const client = yield R.propOr(null, "client")(payload);
    const { userId } = yield call(submitFormRequest, {
      apiCall: externalLoginRequest,
      values: {
        uid,
        accessToken,
        client,
      },
    });
    return yield userId;
  } catch (error) {
    return yield put({ type: VALIDATE_TOKEN_EXPIRATION_TYPE });
  }
}

export function* watchHandleExternalLogin() {
  yield takeEvery(EXTERNAL_LOGIN_TYPE, handleExternalLogin);
}
