import React from "react";

import { reduxForm, Field } from "redux-form";
import Box from "@material-ui/core/Box";

import { PrimaryButton, TextButton } from "../../ui/Button";
import TextInput from "../../ui/inputs/TextInput";
import SelectInput from "../../ui/inputs/DropdownSelect";
import BinaryRadioField from "../../ui/inputs/BinaryRadioField";
import { ErrorMessage } from "../../ui/Error";

import {
  ACCOUNT,
  ACCOUNT_TYPE_FIELD_ID,
  ASSET_TYPE_FIELD_ID,
  BUSINESS_INTEREST,
  CONTACT_FIELD_ID,
  CREDIT_CARD,
  CREDIT_CARD_TYPE_FIELD_ID,
  DESCRIPTION_FIELD_ID,
  FINANCIAL_ASSETS_FORM_ID,
  FINANCIAL_INSTITUTION_FIELD_ID,
  ISSUING_COMPANY_FIELD_ID,
  NAME_ON_CARD_FIELD_ID,
  NUMBER_OF_SHARES_FIELD_ID,
  OTHER_OWNER_FIRST_NAME_FIELD_ID,
  OTHER_OWNER_LAST_NAME_FIELD_ID,
  OTHER_OWNER_MIDDLE_NAME_FIELD_ID,
  OWNERSHIP_FIELD_ID,
  PENSION,
} from "../../../constants/forms";
import {
  useValidateAccountDescriptionMemo,
  useValidateAccountOwnershipMemo,
  useValidateAssetTypeMemo,
  useValidateBusinessInterestDescriptionMemo,
  useValidateCreditCardDescriptionMemo,
  useValidateCreditCardTypeMemo,
  useValidateFinancialInstitutionMemo,
  useValidateIssuingCompanyMemo,
  useValidateNameOnCardMemo,
  useValidateNumberOfSharesMemo,
  useValidatePensionContactMemo,
  useValidatePensionDescriptionMemo,
  useValidateOtherOwnerFirstNameMemo,
  useValidateOtherOwnerMiddleNameMemo,
  useValidateOtherOwnerLastNameMemo,
} from "../../ui/inputs/validations";
import FlashMessage from "../../ui/FlashMessage";

const AccountFields = ({ translations, ownership }) => {
  //  React Hooks must be called in the exact same order in every component render.eslintreact-hooks/rules-of-hooks
  // these are hooks used for conditional fields
  // react can't conditionally render a hook, declare here to avoid this
  const validateOtherOwnerFirstName = useValidateOtherOwnerFirstNameMemo(
    translations,
  );
  const validateOtherOwnerMiddleName = useValidateOtherOwnerMiddleNameMemo(
    translations,
  );
  const validateOtherOwnerLastName = useValidateOtherOwnerLastNameMemo(
    translations,
  );
  return (
    <>
      <Box mb={1}>
        <Field
          component={TextInput}
          name={DESCRIPTION_FIELD_ID}
          label={translations.label.accountDescription}
          placeholder={translations.label.accountDescriptionPlaceholder}
          validate={useValidateAccountDescriptionMemo(translations)}
          noMargin
          suppress
        />
      </Box>

      <Box mb={2}>
        <FlashMessage
          type="warning"
          text={translations.warnings.assetList.addAccount}
        />
      </Box>

      <Field
        name={ACCOUNT_TYPE_FIELD_ID}
        component={SelectInput}
        label={translations.label.accountType}
        placeholder={translations.label.accountTypePlaceholder}
        optionList={translations.accountTypeOptionList}
      />

      <BinaryRadioField
        name={OWNERSHIP_FIELD_ID}
        label={translations.label.accountOwnership}
        optionList={translations.ownershipOptionList}
        validate={useValidateAccountOwnershipMemo(translations)}
      />
      {ownership === "myself_and_other" && (
        <>
          <Field
            component={TextInput}
            name={OTHER_OWNER_FIRST_NAME_FIELD_ID}
            label={translations.label.otherOwnerFirstName}
            placeholder={translations.label.firstNameTheirPlaceholder}
            validate={validateOtherOwnerFirstName}
            suppress
          />

          <Field
            component={TextInput}
            name={OTHER_OWNER_MIDDLE_NAME_FIELD_ID}
            label={translations.label.otherOwnerMiddleName}
            placeholder={translations.label.middleNameTheirPlaceholder}
            validate={validateOtherOwnerMiddleName}
            suppress
          />

          <Field
            component={TextInput}
            name={OTHER_OWNER_LAST_NAME_FIELD_ID}
            label={translations.label.otherOwnerLastName}
            placeholder={translations.label.lastNameTheirPlaceholder}
            validate={validateOtherOwnerLastName}
            suppress
          />
        </>
      )}
      <Field
        component={TextInput}
        name={FINANCIAL_INSTITUTION_FIELD_ID}
        label={translations.label.financialInstitution}
        placeholder={translations.label.financialInstitutionPlaceholder}
        validate={useValidateFinancialInstitutionMemo(translations)}
        suppress
      />
    </>
  );
};
const BusinessInterestFields = ({ translations }) => {
  return (
    <>
      <Field
        component={TextInput}
        name={DESCRIPTION_FIELD_ID}
        label={translations.label.businessInterestDescription}
        placeholder={translations.label.businessInterestDescriptionPlaceholder}
        validate={useValidateBusinessInterestDescriptionMemo(translations)}
        suppress
      />
      <Field
        component={TextInput}
        name={ISSUING_COMPANY_FIELD_ID}
        label={translations.label.issuingCompany}
        placeholder={translations.label.issuingCompanyPlaceholder}
        validate={useValidateIssuingCompanyMemo(translations)}
        suppress
      />
      <Field
        component={TextInput}
        name={NUMBER_OF_SHARES_FIELD_ID}
        label={translations.label.numberOfShares}
        placeholder={translations.label.numberOfSharesPlaceholder}
        validate={useValidateNumberOfSharesMemo(translations)}
        suppress
      />
    </>
  );
};
const PensionFields = ({ translations }) => {
  return (
    <>
      <Field
        component={TextInput}
        name={DESCRIPTION_FIELD_ID}
        label={translations.label.pensionDescription}
        placeholder={translations.label.pensionDescriptionPlaceholder}
        validate={useValidatePensionDescriptionMemo(translations)}
        suppress
      />
      <Field
        component={TextInput}
        name={ISSUING_COMPANY_FIELD_ID}
        label={translations.label.issuingCompany}
        placeholder={translations.label.issuingCompanyPlaceholder}
        validate={useValidateIssuingCompanyMemo(translations)}
        suppress
      />
      <Field
        component={TextInput}
        name={CONTACT_FIELD_ID}
        label={translations.label.pensionContact}
        placeholder={translations.label.pensionContactPlaceholder}
        validate={useValidatePensionContactMemo(translations)}
        suppress
      />
    </>
  );
};
const CreditCardFields = ({ translations }) => {
  return (
    <>
      <Field
        name={CREDIT_CARD_TYPE_FIELD_ID}
        component={SelectInput}
        label={translations.label.creditCardType}
        placeholder={translations.label.creditCardTypePlaceholder}
        optionList={translations.creditCardTypeOptionList}
        validate={useValidateCreditCardTypeMemo(translations)}
      />

      <Box mb={1}>
        <Field
          component={TextInput}
          name={DESCRIPTION_FIELD_ID}
          label={translations.label.creditCardDescription}
          placeholder={translations.label.creditCardDescriptionPlaceholder}
          validate={useValidateCreditCardDescriptionMemo(translations)}
          noMargin
          suppress
        />
      </Box>

      <Box mb={2}>
        <FlashMessage
          type="warning"
          text={translations.warnings.assetList.addCreditCard}
        />
      </Box>

      <Field
        component={TextInput}
        name={NAME_ON_CARD_FIELD_ID}
        label={translations.label.nameOnCard}
        placeholder={translations.label.nameOnCardPlaceholder}
        validate={useValidateNameOnCardMemo(translations)}
        suppress
      />
    </>
  );
};

const FinancialAssetForm = ({
  error,
  handleSubmit,
  handleDelete,
  isLoading,
  translations,
  extraProps,
}) => {
  const { assetType, ownership, isEditingAsset, isRemovingAsset } = extraProps;
  const ctaCopy = isEditingAsset
    ? translations.button.update
    : translations.button.submit;
  const labelText = isEditingAsset
    ? translations.loadingLabel.update
    : translations.loadingLabel.submit;
  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" justifyContent="center" pt={1}>
        {!isEditingAsset && (
          <Field
            name={ASSET_TYPE_FIELD_ID}
            label={translations.assetType}
            placeholder={translations.assetTypePlaceholder}
            component={SelectInput}
            validate={useValidateAssetTypeMemo(translations)}
            optionList={translations.optionList}
            contentPush
          />
        )}
        {assetType === ACCOUNT && (
          <AccountFields translations={translations} ownership={ownership} />
        )}
        {assetType === BUSINESS_INTEREST && (
          <BusinessInterestFields translations={translations} />
        )}
        {assetType === PENSION && <PensionFields translations={translations} />}
        {assetType === CREDIT_CARD && (
          <CreditCardFields translations={translations} />
        )}

        <Box>
          <PrimaryButton
            fullWidth
            isLoading={isLoading && !isRemovingAsset}
            loadingLabel={labelText}
            isDisabled={isRemovingAsset}
            text={ctaCopy}
          />
        </Box>
        {isEditingAsset && (
          <Box display="flex" flexDirection="column" alignItems="center" mt={1}>
            <TextButton
              text={translations.button.remove}
              isLoading={isRemovingAsset}
              loadingLabel={translations.loadingLabel.remove}
              onClick={handleDelete}
              isDisabled={isLoading}
              color="error"
              loadingColor="loadingError"
              className="qa-remove-asset-btn"
            />
          </Box>
        )}
        <ErrorMessage error={error} />
      </Box>
    </form>
  );
};

export default reduxForm({
  form: FINANCIAL_ASSETS_FORM_ID,
})(FinancialAssetForm);
