import React from "react";
import R from "ramda";
import { reduxForm, FieldArray, getFormValues } from "redux-form";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import { useDispatch, useSelector } from "react-redux";

import { Form } from "../../ui/Form";

import {
  CHILDREN_GUARDIANS_FORM_ID,
  CHILDREN_GUARDIANS_FIELD_ID,
} from "../../../constants/forms";
import { ErrorMessage } from "../../ui/Error";
import { displayTooltipModal } from "../../../actions/tooltip-modal";
import H2WithUnderlineLink from "../../ui/H2WithUnderlineLink";
import { orderedFillColors } from "../../../utilities/allocations";
import PeopleCard from "../../ui/PeopleCard";
import { SecondaryButton } from "../../ui/Button";
import {
  openModalChildrenGuardians,
  removeChildrenGuardian,
} from "../../../actions/children-guardians";
import { Body } from "../../ui/Typography";

const ChildrenGuardiansFormDeprecated = ({
  error,
  handleSubmit,
  backLink,
  change,
  appointeeLevelIndexs,
  isLoading,
  translations,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));

  const formData = useSelector(getFormValues(CHILDREN_GUARDIANS_FORM_ID));
  const hasNoChildrenGuardians = getChildrenGuardiansCount(formData) === 0;

  const headerWithLink = (
    <H2WithUnderlineLink
      text={translations.pageTitle}
      underlineText={translations.pageTitleUnderlineLink}
      onClick={() =>
        dispatch(displayTooltipModal(translations.pageTitleModalKey))
      }
    />
  );

  return (
    <Form
      onSubmit={handleSubmit}
      backLink={backLink}
      translations={translations}
      isLoading={isLoading}
      reverseWrap={isTabletDown}
      customHeaderComponent={headerWithLink}
      disabled={hasNoChildrenGuardians}
    >
      <FieldArray
        handleChange={change}
        name={CHILDREN_GUARDIANS_FIELD_ID}
        rerenderOnEveryChange
        component={GuardianCardFieldArray}
        canAddMore={appointeeLevelIndexs.total < 9}
        appointeeLevelIndexs={appointeeLevelIndexs}
        translations={translations}
      />
      <Box mb={2}>
        <ErrorMessage error={error} />
      </Box>
    </Form>
  );
};

const getChildrenGuardiansCount = (formData) => {
  if (!formData) {
    return 0;
  }
  return formData[CHILDREN_GUARDIANS_FIELD_ID].filter(
    (field) => field._destroy !== true,
  ).length;
};

const getChildrenGuardianLevelTranslation = (
  appointeeLevelIndexs,
  index = 0,
  translations,
) => {
  const level = R.pathOr(null, [index, "level"])(appointeeLevelIndexs);
  if (level === null) {
    return "";
  }
  return `${translations.levels[level]} ${translations.guardian}`;
};

const GuardianCardFieldArray = ({
  fields,
  translations,
  handleChange,
  appointeeLevelIndexs,
}) => {
  const dispatch = useDispatch();

  let colorIndex = 0;
  const hasAppointeeTitle = R.has("total")(appointeeLevelIndexs);
  const allFields = fields.getAll() || [];
  const addLabel =
    allFields.filter((field) => field._destroy !== true).length === 0
      ? translations.button.addLabel
      : translations.button.addMoreLabel;
  return (
    <Box>
      {fields.map((fieldId, index) => {
        const isFirstCard = colorIndex === 0;
        const field = fields.get(index);
        if (field && field._destroy === true) {
          return null;
        }
        const color = orderedFillColors[colorIndex % orderedFillColors.length];
        colorIndex += 1;
        const guardianHasSpouse = R.propOr(false, "spouseFirstName")(field);
        const childrenGuardianName = guardianHasSpouse
          ? `${field.firstName} ${field.middleName || ""} ${field.lastName} & ${
              field.spouseFirstName
            } ${field.spouseMiddleName || ""} ${field.spouseLastName}`
          : `${field.firstName} ${field.middleName || ""} ${field.lastName}`;
        const childrenGuardianLevelText = getChildrenGuardianLevelTranslation(
          appointeeLevelIndexs,
          index,
          translations,
        );
        const subText = hasAppointeeTitle ? childrenGuardianLevelText : "";
        return (
          <Box mb={1} mt={2} key={`${index + 1}-${fieldId}`}>
            <PeopleCard
              id={fieldId}
              fieldId={fieldId}
              translations={translations}
              text={childrenGuardianName}
              color={color}
              subText={subText}
              onEdit={() =>
                dispatch(openModalChildrenGuardians({ ...field, index }))
              }
              onRemove={() => {
                dispatch(removeChildrenGuardian(field));
                return handleChange(`${fieldId}._destroy`, true);
              }}
              {...field}
            />
            {isFirstCard && (
              <Box my={2}>
                <Body
                  dangerouslySetInnerHTML={{
                    __html: translations.subQuestionOptionalText,
                  }}
                />
              </Box>
            )}
          </Box>
        );
      })}
      <SecondaryButton
        text={addLabel}
        fullWidth
        displayPlusIcon
        className="qa-add"
        type="button"
        onClick={() => dispatch(openModalChildrenGuardians(null))}
      />
    </Box>
  );
};

export default reduxForm({
  form: CHILDREN_GUARDIANS_FORM_ID,
})(ChildrenGuardiansFormDeprecated);
