import R from "ramda";
import { select, takeEvery, call, put } from "redux-saga/effects";
import {
  initialize,
  getFormValues,
  startSubmit,
  stopSubmit,
  change,
} from "redux-form";

import {
  FETCH_PERSONAL_ATTORNEYS_TYPE,
  OPEN_MODAL_PERSONAL_ATTORNEY_TYPE,
  PERSONAL_ATTORNEYS_NEXT_PAGE_TYPE,
  PersonalAttorneyUpdatedSuccessfully,
  REMOVE_PERSONAL_ATTORNEY_TYPE,
} from "../actions/personal-attorneys";
import { submitFormRequest } from "./forms";
import { fetchApiData } from "./requests";
import { fetchSuccess } from "../actions/requests";
import {
  getPersonalAttorneysNextPage,
  removePersonalAttorneyEndpoint,
  submitPersonalAttorneys,
} from "../api/personal-attorneys";

import {
  FIRST_NAME_FIELD_ID,
  LAST_NAME_FIELD_ID,
  MIDDLE_NAME_FIELD_ID,
  PERSONAL_ATTORNEY_MODAL_FORM_ID,
  PERSONAL_ATTORNEYS_FIELD_ID,
  PERSONAL_ATTORNEYS_FORM_ID,
} from "../constants/forms";
import { closeToast, displayToast } from "../actions/toast";
import {
  ADD_PERSONAL_ATTORNEY_SUCCESS,
  EDIT_PERSONAL_ATTORNEY_SUCCESS,
  REMOVE_PERSONAL_ATTORNEY_SUCCESS,
} from "../constants/toasts";
import {
  selectLastSelectedPersonalAttorneyData,
  selectPersonalAttorneysForm,
} from "../selectors/personal-attorneys";
import {
  MODAL_ADD_PERSONAL_ATTORNEY,
  MODAL_EDIT_PERSONAL_ATTORNEY,
} from "../constants/modal";
import { closeModal, displayModal } from "../actions/modal";
import { getDashboard } from "../api/dashboard";
import { DASHBOARD_ENDPOINT } from "../constants/routes";

function* getSpouseIsPersonalAttorneyFormValue() {
  const formData = yield select(getFormValues(PERSONAL_ATTORNEYS_FORM_ID));
  return R.propOr(false, "spouseIsPersonalAttorney")(formData);
}

function* fetchPersonalAttorneys() {
  yield call(fetchApiData, {
    apiCall: getDashboard,
    DASHBOARD_ENDPOINT,
  });

  const formData = yield select(selectPersonalAttorneysForm);
  yield put(initialize(PERSONAL_ATTORNEYS_FORM_ID, formData));
  yield put(fetchSuccess(PERSONAL_ATTORNEYS_FORM_ID));
}

function* handleOpenModalPersonalAttorney({ payload }) {
  yield put(closeToast());
  const personalAttorney = R.propOr(null, "personalAttorney")(payload);
  if (personalAttorney) {
    yield put(
      change(
        PERSONAL_ATTORNEY_MODAL_FORM_ID,
        PERSONAL_ATTORNEYS_FIELD_ID,
        personalAttorney,
      ),
    );
    return yield put(displayModal(MODAL_EDIT_PERSONAL_ATTORNEY));
  }
  return yield put(displayModal(MODAL_ADD_PERSONAL_ATTORNEY));
}

export function* updatePersonalAttorneys(
  personalAttorneyToUpdate,
  contactId = null,
) {
  const spouseIsPersonalAttorney = yield call(
    getSpouseIsPersonalAttorneyFormValue,
  );
  const { status, personalAttorney } = yield call(submitFormRequest, {
    apiCall: submitPersonalAttorneys,
    formId: PERSONAL_ATTORNEYS_FORM_ID,
    values: {
      ...R.mapObjIndexed(R.defaultTo(null), personalAttorneyToUpdate),
      spouseIsPersonalAttorney,
      contactId,
    },
  });
  if (status !== 200 || !personalAttorney) {
    yield put(stopSubmit(PERSONAL_ATTORNEYS_FORM_ID));
    return null;
  }
  return personalAttorney.id;
}

export function* handleAddOrEditPersonalAttorney(isEditing = false) {
  yield put(startSubmit(PERSONAL_ATTORNEY_MODAL_FORM_ID));
  try {
    const formData = yield select(
      getFormValues(PERSONAL_ATTORNEY_MODAL_FORM_ID),
    );
    const { personalAttorneys } = formData;
    const newPersonalAttorney = {
      [FIRST_NAME_FIELD_ID]: personalAttorneys.firstName || null,
      [MIDDLE_NAME_FIELD_ID]: personalAttorneys.middleName || null,
      [LAST_NAME_FIELD_ID]: personalAttorneys.lastName || null,
    };
    const updatedPersonalAttorneys = isEditing
      ? yield call(editPersonalAttorney, newPersonalAttorney)
      : yield call(addPersonalAttorney, newPersonalAttorney);
    yield put(closeModal());
    yield put(
      change(
        PERSONAL_ATTORNEYS_FORM_ID,
        PERSONAL_ATTORNEYS_FIELD_ID,
        updatedPersonalAttorneys,
      ),
    );
    yield put(PersonalAttorneyUpdatedSuccessfully(newPersonalAttorney));
    yield put(
      displayToast(
        isEditing
          ? EDIT_PERSONAL_ATTORNEY_SUCCESS
          : ADD_PERSONAL_ATTORNEY_SUCCESS,
      ),
    );
  } catch (error) {
    yield put(stopSubmit(PERSONAL_ATTORNEY_MODAL_FORM_ID, error.formErrors));
  }
}

function* addPersonalAttorney(newPersonalAttorney) {
  const formData = yield select(getFormValues(PERSONAL_ATTORNEYS_FORM_ID));
  const { personalAttorneys } = formData;

  const id = yield call(updatePersonalAttorneys, newPersonalAttorney);
  const updatedPersonalAttorney = { id, ...newPersonalAttorney };
  return [...personalAttorneys, updatedPersonalAttorney];
}

function* editPersonalAttorney(newPersonalAttorney) {
  const lastSelectedPersonalAttorney = yield select(
    selectLastSelectedPersonalAttorneyData,
  );
  const { personalAttorneys } = yield select(
    getFormValues(PERSONAL_ATTORNEYS_FORM_ID),
  );
  let updatedPersonalAttorney = {};
  const personalAttorneysList = personalAttorneys.map(
    (personalAttorney, index) => {
      if (index === lastSelectedPersonalAttorney.index) {
        updatedPersonalAttorney = {
          ...newPersonalAttorney,
          id: personalAttorney.id,
        };
        return updatedPersonalAttorney;
      }
      return personalAttorney;
    },
  );

  yield call(updatePersonalAttorneys, updatedPersonalAttorney);

  return personalAttorneysList;
}

export function* handleRemovePersonalAttorney({ payload }) {
  yield put(closeToast());
  const personalAttorney = R.propOr(null, "personalAttorney")(payload);
  const spouseIsPersonalAttorney = yield call(
    getSpouseIsPersonalAttorneyFormValue,
  );
  yield call(submitFormRequest, {
    apiCall: removePersonalAttorneyEndpoint,
    formId: PERSONAL_ATTORNEYS_FORM_ID,
    values: {
      ...personalAttorney,
      spouseIsPersonalAttorney,
    },
  });
  // update the form data in the FE
  yield call(fetchPersonalAttorneys);
  yield put(displayToast(REMOVE_PERSONAL_ATTORNEY_SUCCESS));
}

function* handlePersonalAttorneyNextPage() {
  const { spouseIsPersonalAttorney } = yield select(
    getFormValues(PERSONAL_ATTORNEYS_FORM_ID),
  );
  return yield call(submitFormRequest, {
    apiCall: getPersonalAttorneysNextPage,
    formId: PERSONAL_ATTORNEYS_FORM_ID,
    values: { spouseIsPersonalAttorney },
  });
}

export function* watchFetchPersonalAttorneys() {
  yield takeEvery(FETCH_PERSONAL_ATTORNEYS_TYPE, fetchPersonalAttorneys);
}

export function* watchOpenModalPersonalAttorney() {
  yield takeEvery(
    OPEN_MODAL_PERSONAL_ATTORNEY_TYPE,
    handleOpenModalPersonalAttorney,
  );
}

export function* watchRemovePersonalAttorney() {
  yield takeEvery(REMOVE_PERSONAL_ATTORNEY_TYPE, handleRemovePersonalAttorney);
}

export function* watchPersonalAttorneyNextPage() {
  yield takeEvery(
    PERSONAL_ATTORNEYS_NEXT_PAGE_TYPE,
    handlePersonalAttorneyNextPage,
  );
}
