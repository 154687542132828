import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";

import { createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import configureStore, { history } from "./config/configureStore";
import muiTheme from "./config/Theme";
import Routes from "./config/Routes";
import { observeDOMChanges } from "./config/observers";

const { store, persistor } = configureStore();
const stripePromise = loadStripe(window.env.STRIPE_PUBLISHABLE_KEY);
const googleClientId = window.env.GOOGLE_OAUTH_CLIENT_ID;

const theme = createTheme(muiTheme);

const App = () => {
  useEffect(() => {
    // This observer watches for changes to the DOM and
    // updates any links that are added to the DOM and
    // has the class SPA_SAFE_LINK_CLASS, marking that
    // it should be handled by react-router
    const observer = observeDOMChanges(history);
    return () => {
      observer.disconnect(); // Disconnect observer on cleanup
    };
  }, []);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <GoogleOAuthProvider clientId={googleClientId}>
          <Elements stripe={stripePromise}>
            <PersistGate loading={null} persistor={persistor}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Routes />
              </ThemeProvider>
            </PersistGate>
          </Elements>
        </GoogleOAuthProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
