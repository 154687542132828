import React from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { CHECKOUT_PATH } from "../../constants/routes";
import { LargeBody, H1, SmallExternalLink } from "../ui/Typography";
import { ButtonLink } from "../ui/Button";

const DocumentCTAs = ({
  isCompleteAllSections,
  isPaid,
  nextSectionPath,
  translations,
  userHasUpdateWillFunctionality,
}) => {
  return (
    <Box mt={2} display="flex">
      {!isCompleteAllSections && userHasUpdateWillFunctionality && (
        <Box mr={1}>
          <ButtonLink
            to={nextSectionPath}
            text={translations.button.submit}
            displayArrowRight
          />
        </Box>
      )}
      {!isPaid && (
        <Box>
          <ButtonLink
            to={CHECKOUT_PATH}
            text={translations.button.unlockDocuments}
            secondary={!isCompleteAllSections}
            displayArrowRight
          />
        </Box>
      )}
    </Box>
  );
};

const DocumentsHeaderSection = ({
  isTablet,
  translations,
  isPaid,
  isCompleteAllSections,
  shouldDisplayNoticeConnect,
  shouldDisplayCWRCodes,
  documentKeys,
  nextSectionPath,
  userHasCustomFeatureAccess,
  userHasUpdateWillFunctionality,
}) => {
  const shouldDisplayCTAs = !isCompleteAllSections || !isPaid;
  return (
    <Grid container justifyContent={isTablet ? "center" : ""}>
      <Grid item lg={6} md={8} sm={12}>
        <Box>
          <H1
            align="left"
            marginBottom={isTablet ? "24px" : "32px"}
            data-hj-suppress
          >
            {translations.documentsTitle}
          </H1>
          <LargeBody align="left">{translations.pageDescription}</LargeBody>
          <Box display="flex" flexWrap="wrap">
            <Box mr={1.5}>
              <SmallExternalLink
                href="#legal-documents"
                targetBlank={false}
                text={translations.legalDocumentsLink}
              />
            </Box>

            {documentKeys.assetListDocuments && (
              <Box mr={1.5}>
                <SmallExternalLink
                  href="#asset-list"
                  targetBlank={false}
                  text={translations.assetListLink}
                />
              </Box>
            )}
            {shouldDisplayNoticeConnect ||
              (shouldDisplayCWRCodes && (
                <Box>
                  <SmallExternalLink
                    href="#will-registry"
                    targetBlank={false}
                    text={translations.willRegistryLink}
                  />
                </Box>
              ))}
          </Box>
        </Box>
        {shouldDisplayCTAs && (
          <DocumentCTAs
            isCompleteAllSections={isCompleteAllSections}
            isPaid={isPaid}
            nextSectionPath={nextSectionPath}
            translations={translations}
            userHasCustomFeatureAccess={userHasCustomFeatureAccess}
            userHasUpdateWillFunctionality={userHasUpdateWillFunctionality}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default DocumentsHeaderSection;
