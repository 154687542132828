import { createSelector, createStructuredSelector } from "reselect";
import { PLAN_PRICE_MAP } from "../../constants/plans";
import { selectLanguageCode } from "../language";
import { selectIsCurrentProvinceSelectedByCode } from "../provinces";
import { selectTranslations } from "../translations";
import { selectOrderPlanSymbol, selectIsOrderPlanBundle, selectOrderPlanQuantity } from "./order";
import { formatCurrency } from "../../utilities/helpers";
import { selectNumberOfAvailableInvites } from "../purchases";
import { selectHasUserPaid } from "../plans";
import { selectFirstName } from "../about-you";
import { selectIsCompleteAllSections } from "../section-completion";

const selectCartPlanSymbol = selectOrderPlanSymbol("cart");
const selectIsCartPlanBundle = selectIsOrderPlanBundle("cart");

// we don't want to display value of plan on plan card, we want to show "stock" price
const selectCartPlanCardPrice = createSelector(
  selectCartPlanSymbol,
  selectIsCartPlanBundle,
  selectLanguageCode,
  (symbol, isBundle, languageCode) => {
    const planSymbol = isBundle ? "bundle" : symbol;
    return formatCurrency(PLAN_PRICE_MAP[planSymbol], languageCode);
  },
);

const selectCartSummaryTranslations = createSelector(
  selectTranslations([
    "global",
    "plans",
    "cart",
    "summary",
    "links",
    "yourPlanTranslations",
  ]),
  selectFirstName,
  selectHasUserPaid,
  selectIsCompleteAllSections,
  selectOrderPlanQuantity("cart", "items"),
  selectNumberOfAvailableInvites,
  (
    translations,
    firstName,
    hasUserPaid,
    isCompleteAllSections,
    cartPlanQuantity,
    numberOfAvailablePlans,
  ) => {
    const paidStatus = hasUserPaid ? translations.paid : translations.unpaid;
    const sectionLinkText =
      hasUserPaid && isCompleteAllSections
        ? translations.button.completedSection
        : translations.button.nextSection;

    const {
      totalInvites,
      totalInvitesSelected,
      availableInvites,
      managePlan,
      assignPlans,
      genericPageTitle,
    } = translations;

    return {
      ...translations,
      button: {
        ...translations.button,
        section: sectionLinkText,
      },
      pageTitle: firstName
      ? translations.pageTitle.replace("NAME", firstName)
      : genericPageTitle,
      paidStatus,
      bulkPurchaseGifts: translations.bulkPurchaseGifts,
      totalInvites: totalInvites.replace("NUMBER", cartPlanQuantity),
      availableInvitesCopy: numberOfAvailablePlans === 0 ? managePlan : assignPlans,
      availableInvites: availableInvites.replace(
        "NUMBER",
        numberOfAvailablePlans,
      ),
      totalInvitesSelected: totalInvitesSelected.replace(
        "NUMBER",
        cartPlanQuantity,
      ),
    };
  },
);

// This should be used on Checkout pages
export const selectCartPlanCardProps = createStructuredSelector({
  symbol: selectCartPlanSymbol,
  price: selectCartPlanCardPrice,
  isBundle: selectIsCartPlanBundle,
  isQuebecSelected: selectIsCurrentProvinceSelectedByCode("QC"),
  translations: selectTranslations(["global", "plans"]),
  summaryTranslations: selectCartSummaryTranslations,
  numberofAvailablePlans: selectNumberOfAvailableInvites,
  languageCode: selectLanguageCode,
});
