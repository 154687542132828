import React, { useEffect, useRef } from "react";
import { colorPalette } from "../../config/Theme";

const BodyWithUnderlineLink = ({ text, underlineText, onClick }) => {
  const linkRef = useRef(null);

  const linkId = underlineText
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/'/g, "-");

  useEffect(() => {
    linkRef.current
      .querySelector(`#${linkId}`)
      .addEventListener("click", onClick);
  }, [linkId, onClick]);

  const linkTemplate = `
      <span
        id="${linkId}"
        style="cursor: pointer; bottom: -4px; border-bottom: 2px dashed ${colorPalette.willfulBlue};"
        onmouseover="this.style.backgroundColor='${colorPalette.willfulxxxLightBlue}'"
        onmouseout="this.style.backgroundColor='transparent'"
      >${underlineText}</span>`;

  const textWithLink = text.replace(underlineText, linkTemplate);

  return (
    <>
      <span ref={linkRef} dangerouslySetInnerHTML={{ __html: textWithLink }} />
    </>
  );
};

export default BodyWithUnderlineLink;
