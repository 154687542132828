import React from "react";
import SvgIcon from "./SvgIcon";

const UpgradePlanIcon = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M17 12L12 7L7 12M12 18V8V18ZM12 23C18.075 23 23 18.075 23 12C23 5.925 18.075 1 12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23Z"
      stroke="#0064DC"
      strokeWidth="2"
    />
  </SvgIcon>
);

export default UpgradePlanIcon;
