import React, { useEffect } from "react";
import { connect } from "react-redux";

import PetTrustForm from "../../../components/forms/your-family/PetTrustForm";
import { petTrustPageProps } from "../../../selectors/pets";
import { PET_GUARDIANS_PATH } from "../../../constants/routes";
import { getFormData } from "../../../actions/forms";
import { updatePetTrust } from "../../../actions/pet-guardians";
import { PET_TRUST_FORM_ID } from "../../../constants/forms";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";

const PetTrustPage = ({
  getFormData,
  updatePetTrust,
  hasLoaded,
  ...restOfProps
}) => {
  useEffect(() => {
    getFormData(PET_TRUST_FORM_ID);
  }, []);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <PetTrustForm
      backLink={PET_GUARDIANS_PATH}
      onSubmit={() => updatePetTrust(PET_TRUST_FORM_ID)}
      {...restOfProps}
    />
  );
};

export default connect(petTrustPageProps, {
  getFormData,
  updatePetTrust,
})(PetTrustPage);
