import React, { useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { H2 } from "../ui/Typography";
import FlashMessage from "../ui/FlashMessage";

import DocumentCard from "./DocumentCard";

const AssetListSection = ({
  upgradePlan,
  downloadDocuments,
  isTablet,
  translations,
  isDocumentsAvailable,
  isUpgradeLoading,
  hasAssets,
  documentCardCopy,
}) => {
  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash === "asset-list")
      window.scrollTo(0, document.getElementById(hash).offsetTop);
  });

  return (
    <>
      {documentCardCopy.assetListDocuments && (
        <Box display="flex" flexDirection="column" mt={isTablet ? 3 : 4.5}>
          <Grid container justifyContent={isTablet ? "center" : ""}>
            <Grid item lg={6} md={8} sm={12}>
              <Box mb={2}>
                <H2 id="asset-list" align="left">
                  {translations.assetListDocumentHeader}
                </H2>
              </Box>
              {isDocumentsAvailable && hasAssets && (
                <Box mb={isTablet ? 3 : 2}>
                  <FlashMessage
                    type="warning"
                    text={translations.assetDocumentsWarning}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {documentCardCopy.assetListDocuments.map(
              ({
                key,
                documentName,
                documentInfo,
                upgradeInfo,
                documentLockCopy,
                isUpgradable,
              }) => {
                return (
                  <>
                    {isTablet && <Grid md={3} item />}
                    <Grid sm={12} md={6} lg={4} item key={key}>
                      <DocumentCard
                        documentName={documentName}
                        documentInfo={documentInfo}
                        upgradeInfo={upgradeInfo}
                        documentLockCopy={documentLockCopy}
                        translations={translations}
                        onDownload={downloadDocuments}
                        isTablet={isTablet}
                        isUpgradable={isUpgradable}
                        documentKey={key}
                        upgradePlan={upgradePlan}
                        isUpgradeLoading={isUpgradeLoading}
                      />
                    </Grid>
                    {isTablet && <Grid md={3} item />}
                  </>
                );
              },
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default AssetListSection;
