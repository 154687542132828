import React from "react";

import CharitySelectInput from "./CharitySelectInput";

export const CharityDropdownSelect = ({
  input: { value, onChange },
  meta: { touched, error },
  optionList,
  label,
  placeholder,
  onSelect,
  onCustomInputClick,
  disabledCharityIds,
  ...restProps
}) => {
  const shouldDisplayError = error && touched;
  return (
    <CharitySelectInput
      value={value}
      onChange={onChange}
      touched={touched}
      error={shouldDisplayError ? error : null}
      optionList={optionList}
      label={label}
      placeholder={placeholder || label}
      handleCustom={onCustomInputClick}
      disabledCharityIds={disabledCharityIds}
      onSelect={onSelect}
      {...restProps}
    />
  );
};
