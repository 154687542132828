import { putRequest, getRequest, deleteRequest } from "./request";
import {
  EXECUTORS_ENDPOINT,
  EXECUTORS_NEXT_PAGE_ENDPOINT,
} from "../constants/routes";

export const getExecutors = (config) => getRequest(EXECUTORS_ENDPOINT, config);

export const submitUpdateExecutors = (config) =>
  putRequest(EXECUTORS_ENDPOINT, config);

export const removeExecutorEndpoint = (config) =>
  deleteRequest(EXECUTORS_ENDPOINT, config);

export const getExecutorsNextPage = (config) =>
  putRequest(EXECUTORS_NEXT_PAGE_ENDPOINT, config);
