export const PREMIUM_PLAN = "premium";
export const COUPLES_PLAN = "couples";
export const ESSENTIALS_PLAN = "essentials";
export const NOTARIAL_PLAN = "notarial";

// UI only values for displaying price, BE handles actual payment/prices
export const BUNDLE_PRICE = 164.5;
export const BUNDLE_BASE_PRICE = BUNDLE_PRICE * 2;
export const PREMIUM_PRICE = 189;
export const ESSENTIALS_PRICE = 99;
export const NOTARIAL_PRICE = 349;
export const COUPLES_PRICE = 249;
export const PREMIUM_PLAN_ID = 2;
export const ESSENTIALS_PLAN_ID = 1;

// types of discounts
export const REFERRAL_DISCOUNT = "referral";
export const PROMO_DISCOUNT = "promo_code";
export const CAA_DISCOUNT = "caa_membership";
export const UPGRADE_DISCOUNT = "upgrade";
export const PARTNER_DISCOUNT = "partner_discount";

export const essentialsPlan = {
  symbol: "essentials",
  id: ESSENTIALS_PLAN_ID,
  price: ESSENTIALS_PRICE,
};
export const premiumPlan = {
  symbol: "premium",
  id: PREMIUM_PLAN_ID,
  price: PREMIUM_PRICE,
};
export const bundlePlan = {
  symbol: "premium",
  jsxKey: "bundle",
  id: PREMIUM_PLAN_ID,
  price: BUNDLE_BASE_PRICE,
  isBundle: true,
};
export const notarialWill = {
  symbol: "notarial",
  id: "notarial",
  price: NOTARIAL_PRICE,
};
export const couplesPlan = {
  symbol: "couples",
  id: "couples",
  price: COUPLES_PRICE,
};

export const PLAN_PRICE_MAP = {
  essentials: ESSENTIALS_PRICE,
  premium: PREMIUM_PRICE,
  bundle: BUNDLE_BASE_PRICE,
  notarial: NOTARIAL_PRICE,
  couples: COUPLES_PRICE,
};
