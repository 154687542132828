import React from "react";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

import { ButtonLink } from "./Button";
import { H1, H4, SectionTitle, SmallBody } from "./Typography";
import StatusLabel from "./StatusLabel";
import ProgressBar from "./ProgressBar";
import OverviewStepIcon from "./icons/OverviewStepIcon";

const StepBox = styled(Box)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const ProgressBarWrap = styled(Box)`
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
`;

const OverviewProgressLine = ({
  isComplete = false,
  stepNumber,
  isSelected = false,
  topEnabled = true,
  topActive = true,
  bottomEnabled = true,
  bottomActive = true,
  translations,
}) => {
  let topLineColor = topEnabled ? "border.main" : "transparent";
  if (topEnabled && topActive) {
    topLineColor = "primary.main";
  }

  let bottomLineColor = bottomEnabled ? "border.main" : "transparent";
  if (bottomEnabled && bottomActive) {
    bottomLineColor = "primary.main";
  }

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const stepIconSize = isDesktop ? 32 : 24;
  const stepIconSizePx = `${stepIconSize}px`;

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 2px 1fr"
      // Add 16px to step icon size to account for spacing between lines and circle
      gridTemplateRows={`1fr ${stepIconSize + 16}px 1fr`}
      alignSelf="stretch"
      mr={1.5}
      flexShrink={0}
    >
      {/* Empty divs are used here to achieve proper centering of the line, working with the grid */}
      <Box />
      <Box bgcolor={topLineColor} />
      <Box />
      <Box gridColumn="1 / span 3" py={0.5}>
        <OverviewStepIcon
          step={isComplete ? "complete" : stepNumber}
          isActive={isComplete || isSelected}
          width={stepIconSizePx}
          height={stepIconSizePx}
        />
        <Typography variant="srOnly">
          {translations.overviewStepNumber}
        </Typography>
      </Box>
      <Box />
      <Box bgcolor={bottomLineColor} />
      <Box />
    </Box>
  );
};

const OverviewProgressSectionContent = ({
  sectionTranslations,
  translations,
  progress,
  isSelected,
  isComplete,
  isFirstStep,
  nextSectionPath,
  showCongratulations,
  isWelcomePage = false,
}) => {
  const showProgressBar = isSelected || isComplete;

  return (
    <Box
      bgcolor={isComplete || isSelected ? "#ffffff" : "background.main"}
      border={1}
      borderColor={isSelected ? "willfulBlue.main" : "border.main"}
      borderRadius="4px"
      pt={1.5}
      my={0.5}
      mt={isFirstStep ? 0 : 0.5}
      mb={0.5}
      flexGrow="1"
      style={{
        // Top left corner should be square if the Up First tab is displayed
        borderTopLeftRadius:
          isFirstStep && isSelected && !isComplete ? 0 : "4px",
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        px={1.5}
      >
        <Box mr={0.5}>
          <H4 component="span" align="left">
            {sectionTranslations.title}
          </H4>
        </Box>
        <Box flexShrink={0}>
          <StatusLabel
            status={isComplete ? "complete" : "incomplete"}
            translations={translations}
          />
        </Box>
      </Box>
      {isSelected && progress < 100 && (
        <Box px={1.5} mt={1}>
          <SmallBody>{sectionTranslations.description}</SmallBody>
          <Box mt={1} display="flex" justifyContent="right">
            {isWelcomePage ? (
              <ButtonLink
                to={nextSectionPath}
                className="qa-continue"
                displayArrowRight
                text={
                  isFirstStep
                    ? translations.letsGetStarted
                    : translations.button.submit
                }
              />
            ) : (
              <ButtonLink
                to={nextSectionPath}
                className="qa-continue"
                displayArrowRight
                text={
                  showCongratulations && !hasUserPaid
                    ? translations.button.unlockDocuments
                    : translations.button.submit
                }
              />
            )}
          </Box>
        </Box>
      )}
      <ProgressBarWrap mt={1.5} overflow="hidden">
        {showProgressBar && <ProgressBar progress={progress} />}
      </ProgressBarWrap>
    </Box>
  );
};

const OverviewProgressSection = ({
  translations,
  sectionTranslations,
  isSelected,
  isComplete,
  progress,
  stepNumber,
  totalSteps,
  nextSectionPath,
  showCongratulations,
  showFirstStepBanner,
  isWelcomePage = false,
}) => {
  const isFirstStep = stepNumber === 1;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box>
      {/* StepBox is positioned separately from the progress line and section content to ensure the number is centered vertically with the section content */}
      {showFirstStepBanner && isFirstStep && isSelected && !isComplete && (
        <StepBox
          bgcolor="yellow.main"
          display="flex"
          justifyContent="center"
          py={0.5}
          px={2}
          ml={isDesktop ? 3.5 : 3}
          width="fit-content"
        >
          <SectionTitle>{translations.upFirst}</SectionTitle>
        </StepBox>
      )}
      <Box display="flex" flexDirection="row" alignItems="center">
        <OverviewProgressLine
          isComplete={isComplete}
          stepNumber={stepNumber}
          isSelected={isSelected}
          topEnabled={!isFirstStep}
          topActive={isSelected || isComplete}
          bottomEnabled={stepNumber < totalSteps}
          bottomActive={isComplete}
          translations={translations}
          sectionTranslations={sectionTranslations}
        />
        <OverviewProgressSectionContent
          translations={translations}
          sectionTranslations={sectionTranslations}
          progress={progress}
          isSelected={isSelected}
          isComplete={isComplete}
          isFirstStep={isFirstStep}
          nextSectionPath={nextSectionPath}
          showCongratulations={showCongratulations}
          isWelcomePage={isWelcomePage}
        />
      </Box>
    </Box>
  );
};

// sectionStatuses - array of section statuses with a shape of:
// {
//   isComplete: boolean   Flag signifying that the section has been completed
//   isSelected: boolean   Flag signifying that this section is the one that the user is viewing an overview page for
//   progress: integer     integer percentage value of progress out of 100
//   sectionKey: string    identifier for section. Must match the key for the section translations.
//   translations: object  Object of translations for the section
// }
const OverviewProgress = ({
  translations,
  sectionStatuses,
  nextSectionPath,
  showCongratulations,
  showFirstStepBanner = true,
  isWelcomePage = false,
}) => {
  return (
    <Box>
      {sectionStatuses.map((section, index) => {
        const {
          isSelected,
          isComplete,
          progress,
          sectionKey,
          sectionTranslations,
        } = section;

        return (
          <OverviewProgressSection
            translations={translations}
            sectionTranslations={sectionTranslations}
            isSelected={isSelected}
            isComplete={isComplete}
            progress={progress}
            key={sectionKey}
            stepNumber={index + 1}
            totalSteps={Object.keys(sectionStatuses).length}
            nextSectionPath={nextSectionPath}
            showCongratulations={showCongratulations}
            showFirstStepBanner={showFirstStepBanner}
            isWelcomePage={isWelcomePage}
          />
        );
      })}
    </Box>
  );
};

export default OverviewProgress;
