import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";

import { selectTranslations } from "./translations";
import {
  REVSHARE_PARTNER_DISCOUNT_APPLIED,
  REVSHARE_UNLIMITED_UPDATES_PARTNER_DISCOUNT_APPLIED,
  VOLUME_PARTNER_DISCOUNT_APPLIED,
} from "../constants/modal";
import {
  selectPartnerDiscountAmount,
  selectPartnerDiscountPartnerName,
  selectPartnerDiscountType,
  selectPlanNameInVolumePartnerDiscount,
} from "./partner-discount";

export const selectIsModalVisible = R.path(["tooltipModal", "visible"]);
export const selectModalKey = R.path(["tooltipModal", "key"]);

const selectPartnerDiscountTranslations = createSelector(
  selectTranslations(["global", "tooltip"]),
  selectModalKey,
  selectPartnerDiscountPartnerName,
  selectPlanNameInVolumePartnerDiscount,
  selectPartnerDiscountAmount,
  selectPartnerDiscountType,
  (translations, key, partnerName, planName, discountAmount, discountType) => {
    let modalTitle = "";
    let replacedString = "";
    let removedString = "";
    let symbol = "";
    let body = "";
    switch (key) {
      case VOLUME_PARTNER_DISCOUNT_APPLIED: {
        modalTitle = translations[key].modalTitle
          .replace("PARTNER_NAME", partnerName)
          .replace("PLAN_NAME", planName);
        return {
          ...translations[key],
          modalTitle,
        };
      }
      case REVSHARE_PARTNER_DISCOUNT_APPLIED:
      case REVSHARE_UNLIMITED_UPDATES_PARTNER_DISCOUNT_APPLIED:
        modalTitle = translations[key].modalTitle.replace(
          "PARTNER_NAME",
          partnerName,
        );

        replacedString =
          discountType === "dollar" ? "_DOLLAR_SYMBOL_" : "_PERCENTAGE_SYMBOL_";

        removedString =
          discountType === "dollar" ? "_PERCENTAGE_SYMBOL_" : "_DOLLAR_SYMBOL_";

        symbol = discountType === "dollar" ? "$" : "%";

        body = translations[key].body
          .replace("PARTNER_NAME", partnerName)
          .replace("DISCOUNT_AMOUNT", discountAmount)
          .replace(replacedString, symbol)
          .replace(removedString, "");
        return {
          ...translations[key],
          modalTitle,
          body,
        };
      default:
        return {};
    }
  },
);

const selectSpecificTooltipModalTranslations = createSelector(
  selectPartnerDiscountTranslations,
  (partnerDiscountTranslations) => {
    return {
      ...partnerDiscountTranslations,
    };
  },
);

const selectTooltipModalTranslations = createSelector(
  selectTranslations(["global", "tooltip"]),
  selectModalKey,
  selectSpecificTooltipModalTranslations,
  (translations, key, specificTranslations) => {
    const tooltipModalTranslations = R.propOr({}, key, translations);

    return {
      ...translations,
      ...translations.default,
      ...tooltipModalTranslations,
      ...specificTranslations,
    };
  },
);

export const modalProps = createStructuredSelector({
  isVisible: selectIsModalVisible,
  translations: selectTooltipModalTranslations,
  modalKey: selectModalKey,
});
