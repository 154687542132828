import { takeEvery, call, put } from "redux-saga/effects";
import { FETCH_DASHBOARD_TYPE } from "../actions/dashboard";
import { getDashboard } from "../api/dashboard";
import { fetchApiData } from "./requests";
import { fetchSuccess } from "../actions/requests";
import { updateInvites } from "../actions/invites";

function* fetchDashboard() {
  const { assignedUsers, availableInvites } = yield call(fetchApiData, {
    apiCall: getDashboard,
    formId: "dashboard",
  });

  yield put(updateInvites(assignedUsers, availableInvites));
  yield put(fetchSuccess("dashboard"));
}

export function* watchFetchDashboard() {
  yield takeEvery(FETCH_DASHBOARD_TYPE, fetchDashboard);
}
