import { takeEvery, call, put } from "redux-saga/effects";
import { FETCH_WELCOME_BACK_TYPE } from "../actions/welcome-back";
import { fetchApiData } from "./requests";
import { fetchSuccess } from "../actions/requests";
import { getWelcomeBack } from "../api/welcome-back";

function* fetchWelcomeBack() {
  yield call(fetchApiData, {
    apiCall: getWelcomeBack,
    formId: "welcomeBack",
  });

  yield put(fetchSuccess("welcomeBack"));
}

export function* watchFetchWelcomeBack() {
  yield takeEvery(FETCH_WELCOME_BACK_TYPE, fetchWelcomeBack);
}
