import R from "ramda";

const validateAOM = (age, ageOfMajority, translations) => {
  if (age && (age < ageOfMajority || age > 40)) {
    return translations.errors.invalidAge.replace("X", ageOfMajority);
  }
  return "";
};

const ageValidation = (values, ageOfMajority, translations) => {
  const { age1, age2, age3 } = values;
  const numberAge1 = Number(age1);
  const numberAge2 = Number(age2);
  const numberAge3 = Number(age3);

  const errors = {};
  errors.age1 = validateAOM(numberAge1, ageOfMajority, translations);
  errors.age2 = validateAOM(numberAge2, ageOfMajority, translations);
  errors.age3 = validateAOM(numberAge3, ageOfMajority, translations);

  if (numberAge2 && numberAge1 > numberAge2) {
    errors.age2 = " ";
    errors._error = translations.errors.chronological;
  }
  if (numberAge3 && numberAge2 && numberAge2 > numberAge3) {
    errors.age3 = " ";
    errors._error = translations.errors.chronological;
  }
  if (numberAge2 && numberAge1 === numberAge2) {
    errors.age2 = " ";
    errors._error = translations.errors.unique;
  }
  if (
    (numberAge3 && numberAge1 === numberAge3) ||
    (numberAge2 && numberAge3 && numberAge2 === numberAge3)
  ) {
    errors.age3 = " ";
    errors._error = translations.errors.unique;
  }
  return errors;
};

const percentageValidation = (values, displayMilestone2, translations) => {
  const percentage1 = R.propOr(0, "percentage1", values);
  const percentage2 = R.propOr(0, "percentage2", values);
  const percentage3 = R.propOr(0, "percentage3", values);

  // avoid showing errors when the user have yet to type something
  let allocatedPercent = 0;
  if (percentage1 !== 0) {
    allocatedPercent = (100 - percentage1 - percentage2 - percentage3).toFixed(
      2,
    );
  }

  if (Math.abs(allocatedPercent) === 0.0) return {};

  const errorTranslation =
    allocatedPercent < 0
      ? translations.errors.overPercentage
      : translations.errors.underPercentage;
  const errorMessage = errorTranslation.replace(
    "X",
    Math.abs(allocatedPercent),
  );

  // display error on form level only
  // if it has at least 2 milestones
  return displayMilestone2
    ? { _error: errorMessage }
    : { percentage1: errorMessage };
};

export const validateInheritanceMilestones = (values, props) => {
  const {
    isInheritanceSpecificAges,
    ageOfMajority,
    translations,
    displayMilestone2,
  } = props;
  if (isInheritanceSpecificAges) {
    const ageErrors = ageValidation(values, ageOfMajority, translations);
    const percentageErrors = percentageValidation(
      values,
      displayMilestone2,
      translations,
    );

    const errors = {
      ...ageErrors,
      ...percentageErrors,
    };

    if (ageErrors._error || percentageErrors._error) {
      errors._error = [ageErrors._error, percentageErrors._error];
    }

    return errors;
  }
  return {};
};
