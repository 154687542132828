import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { PageLayout } from "../../components/ui/PageLayout";
import { ExternalButtonLink } from "../../components/ui/Button";
import { H1, Body } from "../../components/ui/Typography";
import {
  ACCOUNT_DELETE_SURVERY_EN_URL,
  ACCOUNT_DELETE_SURVERY_FR_URL,
} from "../../constants/routes";
import { accountDeletedPageProps } from "../../selectors/account-deleted";

const AccountDeletedPage = ({ translations, isFrenchSelected }) => {
  const { header, body } = translations;
  return (
    <PageLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <H1 align="left">{header}</H1>
          <Body align="left">{body}</Body>
          <Box width={220} mt={2}>
            <ExternalButtonLink
              url={
                isFrenchSelected
                  ? ACCOUNT_DELETE_SURVERY_FR_URL
                  : ACCOUNT_DELETE_SURVERY_EN_URL
              }
              text={translations.button.submit}
              displayArrowRight
            />
          </Box>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default connect(accountDeletedPageProps)(AccountDeletedPage);
