import React from "react";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { ButtonLink } from "../../ui/Button";
import { SmallBody } from "../../ui/Typography";
import { YOUR_ACCOUNT_PATH } from "../../../constants/routes";
import AssignedPlans from "./AssignedPlans";
import AvailablePlans from "./AvailablePlans";

export const InviteUser = ({
  assignedPlans,
  revokeInvite,
  translations,
  resendInvite,
  numberOfAvailablePlans,
  displayModal,
}) => {
  // Create synthetic Array to map over in JSX to display available plans
  const availablePlans = [...Array(numberOfAvailablePlans)];

  return (
    <>
      {assignedPlans.map(
        ({ id, fullName, email, status, isResendLoading, isRevokeLoading }) => (
          <AssignedPlans
            key={email}
            id={id}
            fullName={fullName}
            email={email}
            status={status}
            revokeInvite={revokeInvite}
            translations={translations}
            resendInvite={resendInvite}
            isResendLoading={isResendLoading}
            isRevokeLoading={isRevokeLoading}
          />
        ),
      )}
      {numberOfAvailablePlans > 0 &&
        availablePlans.map((planNumber) => (
          <AvailablePlans
            key={planNumber}
            displayModal={displayModal}
            translations={translations}
          />
        ))}
    </>
  );
};

const InviteContent = ({
  textColor = "willfulBlue",
  background = "willfulLightGrey.main",
  isPaid,
  totalInvites,
  totalInvitesSelected,
  availableInvites,
  dashboardView,
  translations,
  showCta,
}) => {
  const isTablet = useMediaQuery(
    "@media (min-width:768px) and (max-width:1025px)",
  );
  const { availableInvitesCopy } = translations;
  return (
    <Box alignItems="flex-start">
      <Box display="flex" flexDirection={isTablet ? "column" : "row"}>
        <Box
          borderRadius="4px"
          px={1}
          width="fit-content"
          py={0.5}
          mr={isTablet ? 0 : 1}
          mb={isTablet ? 1 : 0}
          bgcolor={background}
        >
          <SmallBody color={textColor}>
            {isPaid ? totalInvites : totalInvitesSelected}
          </SmallBody>
        </Box>
        {isPaid && (
          <Box
            borderRadius="4px"
            py={0.5}
            width="fit-content"
            px={1}
            bgcolor={background}
          >
            <SmallBody color={textColor}>{availableInvites}</SmallBody>
          </Box>
        )}
      </Box>

      {isPaid && dashboardView && showCta && (
        <Box width={1} mt={1.5}>
          <ButtonLink
            to={YOUR_ACCOUNT_PATH}
            fullWidth
            secondary
            text={availableInvitesCopy}
          />
        </Box>
      )}
    </Box>
  );
};

export default InviteContent;
