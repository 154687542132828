import React from "react";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import SvgIcon from "./SvgIcon";

const StyledSvgIcon = styled(SvgIcon)`
  z-index: 10;
`;

const CVCIcon = ({ height = 24, marginTop = 0, marginRight = 0 }) => {
  return (
    <Box marginTop={marginTop} marginRight={marginRight}>
      <StyledSvgIcon
        width="23"
        height={height}
        viewBox="0 0 23 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.337 1C13.6146 1.50713 13.025 2.18088 12.6182 2.9642C12.2114 3.74753 11.9993 4.61734 12 5.5C12 6.83 12.472 8.05 13.257 9H3C2.73478 9 2.48043 9.10536 2.29289 9.29289C2.10536 9.48043 2 9.73478 2 10V11C2 11.2652 2.10536 11.5196 2.29289 11.7071C2.48043 11.8946 2.73478 12 3 12H19C19.2652 12 19.5196 11.8946 19.7071 11.7071C19.8946 11.5196 20 11.2652 20 11V10.4C20.7976 9.99172 21.484 9.39559 22 8.663V15C22 15.5304 21.7893 16.0391 21.4142 16.4142C21.0391 16.7893 20.5304 17 20 17H2C1.46957 17 0.960859 16.7893 0.585786 16.4142C0.210714 16.0391 0 15.5304 0 15V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H14.337ZM21.044 1.293C21.283 1.495 21.504 1.717 21.706 1.956C21.5406 1.68594 21.3138 1.45877 21.044 1.293Z"
          fill="#77787D"
        />
        <path
          opacity="0.4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6 3C12.1267 3.92493 11.9262 4.96542 12.022 6H0V3H12.6Z"
          fill="#77787D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5 11C16.0413 11 14.6424 10.4205 13.6109 9.38909C12.5795 8.35764 12 6.95869 12 5.5C12 4.04131 12.5795 2.64236 13.6109 1.61091C14.6424 0.579463 16.0413 0 17.5 0C18.9587 0 20.3576 0.579463 21.3891 1.61091C22.4205 2.64236 23 4.04131 23 5.5C23 6.95869 22.4205 8.35764 21.3891 9.38909C20.3576 10.4205 18.9587 11 17.5 11ZM15.316 3.221H14.695L13.179 3.991V4.777L14.381 4.149V7.779H15.324V3.22H15.316V3.221ZM17.123 3.85C17.571 3.85 17.885 4.101 17.885 4.463C17.885 4.856 17.515 5.131 16.981 5.131H16.746V5.799H17.029C17.594 5.799 17.979 6.081 17.979 6.49C17.979 6.883 17.602 7.15 17.068 7.15C16.675 7.15 16.282 7.024 15.874 6.78V7.566C16.314 7.755 16.754 7.857 17.186 7.857C18.215 7.857 18.922 7.331 18.922 6.569C18.922 6.034 18.592 5.602 18.042 5.429C18.514 5.272 18.82 4.856 18.82 4.384C18.82 3.646 18.168 3.143 17.225 3.143C16.7998 3.14762 16.38 3.23845 15.991 3.41V4.18C16.369 3.968 16.754 3.85 17.123 3.85ZM20.517 5.563C21.091 5.563 21.491 5.901 21.491 6.341C21.491 6.804 21.091 7.126 20.517 7.126C20.171 7.126 19.81 7.016 19.441 6.789V7.598C19.826 7.771 20.219 7.858 20.604 7.858C20.808 7.858 20.996 7.826 21.177 7.778C21.5971 7.09822 21.82 6.3151 21.821 5.516L21.806 5.186C21.5138 5.01277 21.1786 4.92543 20.839 4.934C20.6891 4.93346 20.5393 4.94415 20.391 4.966V3.944H21.523C21.4247 3.69245 21.3035 3.45044 21.161 3.221H19.574V5.696C19.8817 5.61331 20.1984 5.56864 20.517 5.563Z"
          fill="#77787D"
        />
      </StyledSvgIcon>
    </Box>
  );
};

export default CVCIcon;
