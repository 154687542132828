import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import { useSelect } from "downshift";
import { DropdownContactSelectWrapper } from "./style";
import { ArrowBlueUpIcon, ArrowBlueDownIcon } from "../../icons/ArrowIcons";
import { ErrorMessage } from "../../Error";
import { SmallBody } from "../../Typography";
import { isValidSelectKey } from "../../../../selectors/utils/validate";
import { selectContactBadgesTranslations } from "../../../../selectors/contacts";
import { getContactBadgeKey } from "../../utils/contacts";
import { buildFullName } from "../../../../utilities/name";
import { CONTACTS_PATH } from "../../../../constants/routes";
import PlusIcon from "../../icons/PlusIcon";
import { colorPalette } from "../../../../config/Theme";
import {
  ADD_NEW_CONTACT,
  MANAGE_MY_CONTACTS,
} from "../../../../constants/contacts";

const navigateToManageContactsPage = (onRedirect) => {
  if (onRedirect) {
    return onRedirect();
  }
  return window.location.assign(CONTACTS_PATH);
};

const onKeyDownHandler = (evt, onSelect, onRedirect, onAddNew) => {
  // This handler makes sure the component is accessible to keybaord users
  // and allows them to select an option by pressing: Enter or Spacebar
  if (isValidSelectKey(evt.key)) {
    const optionElement = document.querySelector(
      "[aria-selected='true'] > span",
    );
    const contactId = optionElement
      ? optionElement.getAttribute("value")
      : null;
    if (contactId !== null) {
      switch (contactId) {
        case ADD_NEW_CONTACT:
          onAddNew();
          break;
        case MANAGE_MY_CONTACTS:
          navigateToManageContactsPage(onRedirect);
          break;
        default:
          if (onSelect) {
            onSelect(Number(contactId));
          }
          break;
      }
    }
  }
};

const ContactOptionLabel = ({ option, badges }) => {
  const { firstName, middleName, lastName } = option.contact;
  const fullName = buildFullName(firstName, middleName, lastName);
  const badgeKey = getContactBadgeKey(option);
  const badge = badges[badgeKey];
  const badgeLabel = badge ? `(${badge.toLowerCase()})` : "";
  return (
    <span value={option.contact.id} className="option-wrapper">
      <span className="option-full-name">{fullName}</span>
      <span>{badgeLabel}</span>
    </span>
  );
};

const DropdownHeaderOption = ({
  isOpen,
  placeholder,
  initialSelectedItem,
  badges,
}) => {
  let displayValue = placeholder;
  if (initialSelectedItem) {
    displayValue = (
      <ContactOptionLabel option={initialSelectedItem} badges={badges} />
    );
  }
  return (
    <span className="dropdown-header">
      {displayValue}
      {isOpen ? <ArrowBlueDownIcon /> : <ArrowBlueUpIcon />}
    </span>
  );
};

const AddNewContactOption = ({ translations, value }) => {
  return (
    <span value={value} className="option-wrapper">
      {translations.addNewContactOption}
      <PlusIcon />
    </span>
  );
};

const ManageMyContactsOption = ({ translations, value }) => {
  return (
    <span value={value} className="option-wrapper">
      {translations.manageMyContactsOption}
    </span>
  );
};

const ContactSelectInput = ({
  input: { onChange },
  meta: { error, touched },
  translations,
  optionList,
  disabledContactIds = [],
  selectedContactId,
  onSelect = () => {},
  onRedirect,
  onAddNew,
  label,
  labelColor = "text.primary",
  marginBottom = 0,
  className,
  includeMinorChildren,
}) => {
  const { placeholder } = translations;
  const badgesFromStore = useSelector(selectContactBadgesTranslations);
  const badges = translations.badges || badgesFromStore;
  const shouldDisplayError = error && touched;

  useEffect(() => {
    if (selectedContactId) {
      onChange(selectedContactId);
    }
  }, [selectedContactId, onChange]);

  let initialSelectedItem = null;
  if (selectedContactId) {
    initialSelectedItem = optionList.find(
      (option) => option.contact.id === selectedContactId,
    );
  }

  // We need to manually add extra options to the dropdown
  // so the library "downshift" can handle them appropriately
  // both with keyboard navigation and mouse
  // More info: https://www.npmjs.com/package/downshift
  const optionListWithExtraOptions = optionList.concat([
    { type: ADD_NEW_CONTACT, contact: { id: ADD_NEW_CONTACT } },
    { type: MANAGE_MY_CONTACTS, contact: { id: MANAGE_MY_CONTACTS } },
  ]);

  const {
    isOpen,
    selectedItem,
    highlightedIndex,
    closeMenu,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
  } = useSelect({ items: optionListWithExtraOptions, initialSelectedItem });

  const filteredOptionsList = optionListWithExtraOptions
    .filter((option) => {
      return option?.contact?.id !== selectedContactId;
    })
    .map((option, index) => {
      const { contact } = option;
      const keyId = contact ? contact.id : option.type;

      // minor children are always disabled
      const isCustomDisableOption = disabledContactIds.includes(contact.id);
      const isDisabledOption =
        (!includeMinorChildren && option.isMinorChild) || isCustomDisableOption;

      const itemProps = getItemProps({
        index,
        item: keyId,
        disabled: isDisabledOption,
        onClick: () => {
          if (onChange) {
            onChange(contact.id);
          }
          return onSelect(contact.id);
        },
      });

      const highlightValueStyle =
        highlightedIndex === index
          ? { backgroundColor: colorPalette.willfulxxxLightBlue }
          : {};
      const enabledOrDisabledStyle = isDisabledOption
        ? "disabled-option"
        : "enabled-option";
      const classList = [
        "qa-dropdown-item",
        `qa-dropdown-item-${index}`,
        enabledOrDisabledStyle,
      ];

      // If ADD_NEW_CONTACT option is selected
      if (option.type === ADD_NEW_CONTACT) {
        itemProps.onClick = () => {
          closeMenu();
          return onAddNew();
        };
        return (
          <li
            key={keyId}
            className={`${className} qa-add-new-contact add-new-contact`}
            style={highlightValueStyle}
            {...itemProps}
          >
            <AddNewContactOption
              value={ADD_NEW_CONTACT}
              translations={translations}
            />
          </li>
        );
      }

      // If MANAGE_MY_CONTACTS option is selected
      if (option.type === MANAGE_MY_CONTACTS) {
        itemProps.onClick = () => navigateToManageContactsPage(onRedirect);
        return (
          <li
            key={keyId}
            className={`${className} qa-manage-my-contacts manage-my-contacts`}
            style={highlightValueStyle}
            {...itemProps}
          >
            <ManageMyContactsOption
              value={MANAGE_MY_CONTACTS}
              translations={translations}
            />
          </li>
        );
      }

      // Render default Contact options
      return (
        <li
          key={keyId}
          className={classList.join(" ")}
          style={highlightValueStyle}
          {...itemProps}
        >
          <ContactOptionLabel option={option} badges={badges} />
        </li>
      );
    });

  return (
    <Box
      onKeyDown={(evt) => onKeyDownHandler(evt, onSelect, onRedirect, onAddNew)}
      mb={marginBottom}
      width="100%"
    >
      <DropdownContactSelectWrapper
        selectedOption={initialSelectedItem || selectedItem}
        isOpen={isOpen}
        className={className}
        shouldDisplayError={shouldDisplayError}
        data-hj-suppress
      >
        {/* Dropdown Label + Header */}
        <label htmlFor={label} className="dropdown-label" {...getLabelProps()}>
          <SmallBody color={labelColor} id={label}>
            {label}
          </SmallBody>
        </label>
        <button
          type="button"
          className="dropdown-toggle qa-dropdown"
          {...getToggleButtonProps()}
        >
          <DropdownHeaderOption
            isOpen={isOpen}
            placeholder={placeholder}
            initialSelectedItem={initialSelectedItem}
            badges={badges}
          />
        </button>
        {/* Dropdown Contacts List */}
        <ul className="dropdown-panel" {...getMenuProps()}>
          {filteredOptionsList}
        </ul>
        {/* Error message */}
        {shouldDisplayError && <ErrorMessage error={error} />}
      </DropdownContactSelectWrapper>
    </Box>
  );
};

export default ContactSelectInput;
