import R from "ramda";
import {
  FIRST_NAME_FIELD_ID,
  LAST_NAME_FIELD_ID,
  MIDDLE_NAME_FIELD_ID,
  SPOUSE_FIRST_NAME_FIELD_ID,
  SPOUSE_LAST_NAME_FIELD_ID,
  SPOUSE_MIDDLE_NAME_FIELD_ID
} from "../constants/forms";
import {buildFullName} from "../utilities/name";

export const selectLastSelectedPetGuardianData = R.path(["formUi", "lastSelectedPetGuardianData"])

export const petGuardianFullName = (petGuardianData) => {
  const fullName = R.props([
    FIRST_NAME_FIELD_ID,
    MIDDLE_NAME_FIELD_ID,
    LAST_NAME_FIELD_ID,
  ])(petGuardianData);
  const [firstName, middleName, lastName] = fullName;
  return buildFullName(firstName, middleName, lastName);
};

export const petGuardianSpouseFullName = (petGuardianData) => {
  const fullName = R.props([
    SPOUSE_FIRST_NAME_FIELD_ID,
    SPOUSE_MIDDLE_NAME_FIELD_ID,
    SPOUSE_LAST_NAME_FIELD_ID,
  ])(petGuardianData);
  const [firstName, middleName, lastName] = fullName;
  return buildFullName(firstName, middleName, lastName);
};
