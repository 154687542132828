import React from "react";

import SvgIcon from "./SvgIcon";

const AssetListOverviewIcon = ({ isDesktop }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0.59 0 232.41 214.65"
      width={isDesktop ? 240 : 180}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.797 214.654C180.974 214.654 233 180.169 233 137.63C233 95.0915 180.974 60.6069 116.797 60.6069C52.62 60.6069 0.594116 95.0915 0.594116 137.63C0.594116 180.169 52.62 214.654 116.797 214.654Z"
        fill="#C6E9F8"
      />
      <path
        d="M117.17 92.0046L172.91 130.803C175.162 132.364 175.06 135.716 172.731 137.149L141.38 156.472L76.4272 107.462L117.17 92.0046Z"
        fill="#2F455F"
      />
      <path
        d="M137.337 158.084L141.38 156.471L155.584 49.5979L151.54 51.2358L137.337 158.084Z"
        fill="#AAD2F6"
      />
      <path
        d="M151.541 51.2357L155.584 49.5978L90.6822 0L86.6387 1.71468L151.541 51.2357Z"
        fill="white"
      />
      <path
        d="M86.6386 1.7146L72.5372 108.588L137.337 158.083L151.541 51.2356L86.6386 1.7146Z"
        fill="#E2F6FF"
      />
      <path
        d="M85.8966 43.7373L86.69 37.5439L91.2198 40.9989L90.4008 47.1922L85.8966 43.7373Z"
        fill="white"
      />
      <path
        d="M86.7666 37.723L91.1173 41.05L90.3239 47.013L85.9732 43.7116L86.7666 37.723ZM86.613 37.3647L85.7941 43.7884L90.4775 47.3713L91.322 40.9733L86.613 37.3647Z"
        fill="#0064DC"
      />
      <path
        d="M84.0792 57.4297L84.8726 51.2363L89.4024 54.6913L88.5835 60.8846L84.0792 57.4297Z"
        fill="white"
      />
      <path
        d="M84.9494 51.4154L89.3001 54.7424L88.5067 60.7054L84.1561 57.404L84.9494 51.4154ZM84.7959 51.0571L83.9769 57.4808L88.6603 61.0637L89.5048 54.6656L84.7959 51.0571Z"
        fill="#0064DC"
      />
      <path
        d="M82.3134 71.0954L83.1067 64.9021L87.6109 68.3315L86.792 74.5248L82.3134 71.0954Z"
        fill="white"
      />
      <path
        d="M83.1834 65.0814L87.5085 68.3828L86.7152 74.3458L82.4157 71.0444L83.1834 65.0814ZM83.0299 64.7231L82.2109 71.1468L86.8687 74.7041L87.7133 68.3061L83.0299 64.7231Z"
        fill="#0064DC"
      />
      <path
        d="M80.4452 84.7356L81.2386 78.5422L85.794 82.0228L84.975 88.2161L80.4452 84.7356Z"
        fill="white"
      />
      <path
        d="M81.3152 78.7218L85.6915 82.0744L84.8981 88.0374L80.5474 84.7104L81.3152 78.7218ZM81.1872 78.3635L80.3683 84.7872L85.0773 88.3957L85.9218 81.9976L81.1872 78.3635Z"
        fill="#0064DC"
      />
      <path
        d="M78.577 98.4023L79.396 92.209L84.0026 95.7151L83.1836 101.908L78.577 98.4023Z"
        fill="white"
      />
      <path
        d="M79.4729 92.3881L83.9003 95.7663L83.107 101.729L78.7051 98.3511L79.4729 92.3881ZM79.3193 92.0298L78.5004 98.4534L83.2605 102.088L84.1051 95.6895L79.3193 92.0298Z"
        fill="#0064DC"
      />
      <path
        d="M96.1587 18.9896L94.9814 17.9915H94.8791L93.8042 17.019H93.7018L92.8573 18.5801L92.5246 16.0209C92.5246 16.0209 92.5246 15.9185 92.4478 15.9185L91.6032 15.2531H91.5009L90.6563 16.8142L90.3236 14.255C90.3236 14.255 90.3236 14.1526 90.2469 14.1526L88.9672 13.1545H88.8649L89.607 18.1962C89.607 18.1962 89.607 18.2986 89.6838 18.2986L90.7075 19.092H90.8099L91.7568 17.6332L92.1919 20.1156C92.1919 20.1156 92.1919 20.218 92.2686 20.218L93.2923 21.0114H93.3947L94.9302 18.4266L94.4696 21.9071C94.4696 21.9071 94.4696 22.0095 94.5464 22.0095L95.7236 23.0076H95.826L96.3378 19.0664C96.2354 19.0919 96.261 18.9896 96.1587 18.9896Z"
        fill="#0064DC"
      />
      <path
        d="M96.2619 18.3033C96.5337 18.1524 96.515 17.5993 96.22 17.0679C95.925 16.5365 95.4654 16.2281 95.1936 16.379C94.9217 16.53 94.9404 17.0831 95.2355 17.6145C95.5305 18.1459 95.99 18.4543 96.2619 18.3033Z"
        fill="#0064DC"
      />
      <path
        d="M98.155 18.6824L96.9777 17.6843H96.8754L96.1332 23.4682C96.1332 23.4682 96.1332 23.5706 96.21 23.5706L97.3872 24.5686H97.4896L98.2317 18.7848C98.2573 18.6824 98.155 18.6824 98.155 18.6824Z"
        fill="#0064DC"
      />
      <path
        d="M108.648 31.9652C108.213 31.6325 107.829 31.7604 107.778 32.1443C107.727 32.605 108.008 33.1936 108.443 33.5263C108.878 33.859 109.262 33.731 109.313 33.3472C109.364 32.9121 109.057 32.2979 108.648 31.9652Z"
        fill="#0064DC"
      />
      <path
        d="M99.921 20.1155L98.7437 19.1174H98.6413L97.8992 24.9013C97.8992 24.9013 97.8992 25.0037 97.9759 25.0037L99.1532 26.0018H99.2556L99.9977 20.2179C100.023 20.1411 99.921 20.1155 99.921 20.1155Z"
        fill="#0064DC"
      />
      <path
        d="M108.034 26.667L106.856 25.6689H106.754L106.012 31.4528C106.012 31.4528 106.012 31.5552 106.088 31.5552L107.266 32.5533H107.368L108.11 26.7694C108.11 26.7694 108.11 26.667 108.034 26.667Z"
        fill="#0064DC"
      />
      <path
        d="M106.037 26.9741L104.86 25.976H104.758L104.502 27.9978C104.45 28.4585 104.143 28.5096 103.811 28.2793C103.478 28.049 103.35 27.5627 103.401 27.2044L103.683 25.0035C103.683 25.0035 103.683 24.9011 103.606 24.9011L103.427 24.7732L102.429 23.903L101.584 23.2376L101.507 23.1353L101.533 22.9561C101.559 22.6746 101.763 22.7002 101.994 22.9305C102.173 23.0585 102.25 23.1609 102.326 23.2632H102.429L102.582 22.1628V22.0604C102.505 21.958 102.173 21.6253 101.84 21.3182C100.996 20.6528 100.433 20.7552 100.279 21.7789L99.7159 26.1808C99.7159 26.1808 99.7159 26.2831 99.7927 26.2831L100.97 27.2812H101.072L101.431 24.5428L102.275 25.2082L102.122 26.4879C101.994 27.486 102.48 28.6888 103.324 29.3542C103.99 29.9172 104.399 29.6869 104.399 29.6869L104.348 30.0452C104.348 30.0452 104.348 30.1475 104.425 30.1475L105.602 31.1456H105.705L106.216 27.2044C106.114 27.0765 106.114 27.0765 106.037 26.9741Z"
        fill="#0064DC"
      />
      <path
        d="M144.067 60.0726C145.393 59.3167 145.385 56.8026 144.048 54.4571C142.711 52.1117 140.552 50.8231 139.226 51.579C137.9 52.335 137.908 54.8491 139.245 57.1946C140.582 59.54 142.741 60.8286 144.067 60.0726Z"
        fill="#FFDC1E"
      />
      <path
        d="M79.8313 81.6139C79.5497 81.4603 79.473 81.1021 79.7033 80.6926C80.0104 80.0528 80.8038 79.5153 81.4947 79.4386C81.9554 79.3618 82.2625 79.5665 82.3137 79.8736C82.672 81.23 82.9279 82.5352 83.2606 83.8916C82.1601 83.175 81.0341 82.3817 79.8313 81.6139Z"
        fill="#E55D4C"
      />
      <path
        d="M83.2607 83.9176C82.1346 83.1242 80.9574 82.4332 79.8313 81.6143C79.9593 81.691 80.1896 81.7678 80.3943 81.7166L83.2607 83.9176Z"
        fill="#0064DC"
      />
      <path
        d="M82.3649 79.8479C82.7231 81.2043 82.9791 82.5095 83.3118 83.8659L80.4454 81.665C81.1364 81.5882 81.9298 81.0507 82.2369 80.4109C82.3904 80.2574 82.4416 80.0782 82.3649 79.8479Z"
        fill="#0064DC"
      />
      <path
        d="M80.4967 81.7415C79.8057 81.8183 79.473 81.3577 79.7545 80.6667C80.0616 80.0269 80.855 79.4894 81.546 79.4126C82.237 79.3359 82.5697 79.7965 82.2881 80.4875C82.0066 81.1273 81.2133 81.6648 80.4967 81.7415Z"
        fill="#E55D4C"
      />
      <path
        d="M80.4966 81.7413C79.8056 81.8181 79.4729 81.3574 79.7544 80.6664C80.0615 80.0266 80.8549 79.4892 81.5459 79.4124C82.2368 79.3356 82.5695 79.7963 82.288 80.4873C82.0065 81.1271 81.2131 81.6645 80.4966 81.7413Z"
        fill="#FFCA00"
      />
      <path
        d="M74.6869 78.1842C74.0215 77.7235 73.8936 76.9046 74.3542 75.9065C75.0708 74.3198 77.1182 72.9122 78.8841 72.6818C80.0357 72.5539 80.8035 73.0145 81.0082 73.8079C81.4945 75.8041 81.9551 77.8259 82.4414 79.8221C82.3902 79.515 82.0831 79.3102 81.6224 79.387C80.9314 79.4638 80.1381 80.0012 79.831 80.641C79.6006 81.0505 79.703 81.4088 79.9589 81.5624C78.1163 80.4875 76.4016 79.3358 74.6869 78.1842Z"
        fill="#FFCA00"
      />
      <path
        d="M79.8312 81.6135C78.1165 80.4618 76.4018 79.3101 74.6871 78.1841C75.0454 78.44 75.6085 78.5424 76.2227 78.4656L80.471 81.6902C80.1895 81.7926 80.0103 81.7414 79.8312 81.6135Z"
        fill="#FFDC1E"
      />
      <path
        d="M80.9315 73.8335C81.4178 75.8297 81.8784 77.8515 82.3647 79.8477C82.3903 80.0012 82.3647 80.1804 82.2623 80.4107C81.9552 81.0505 81.1618 81.5879 80.4709 81.6647L76.2225 78.4401C78.0396 78.261 80.0614 76.8534 80.7524 75.2155C80.9827 74.7292 81.0595 74.243 80.9315 73.8335Z"
        fill="#FFDC1E"
      />
      <path
        d="M76.2227 78.4658C74.4057 78.6449 73.5867 77.4421 74.3289 75.8553C75.0455 74.2686 77.0928 72.8611 78.8587 72.6307C80.6758 72.4516 81.4947 73.6544 80.7525 75.2411C79.9848 76.9302 77.963 78.3378 76.2227 78.4658Z"
        fill="#FFCA00"
      />
      <path
        d="M76.2227 78.466C74.4057 78.6451 73.5867 77.4423 74.3289 75.8556C75.0455 74.2689 77.0928 72.8613 78.8587 72.631C80.6758 72.4518 81.4947 73.6547 80.7525 75.2414C79.9848 76.9305 77.963 78.338 76.2227 78.466Z"
        fill="#E55D4C"
      />
      <path
        d="M47.8661 39.7701C47.559 39.3095 47.4822 38.6185 47.8661 37.8507C48.5827 36.264 50.6301 34.8564 52.396 34.6261C53.2405 34.5237 53.9315 34.8052 54.2898 35.3171L80.7778 73.3728C80.4195 72.8609 79.7797 72.6562 78.8839 72.6818C77.0669 72.8609 75.0451 74.2685 74.3541 75.9064C73.9702 76.6742 74.047 77.3908 74.3541 77.8258C66.3949 66.4117 55.7741 51.1331 47.8661 39.7701Z"
        fill="#E55D4C"
      />
      <path
        d="M74.3545 77.826L47.8665 39.7703C48.2247 40.2821 48.8646 40.4869 49.7603 40.4613L76.2483 78.517C75.3014 78.5937 74.6104 78.3122 74.3545 77.826Z"
        fill="#0064DC"
      />
      <path
        d="M54.29 35.2915L80.778 73.3472C81.0851 73.8079 81.1618 74.4989 80.778 75.2666C80.0614 76.8533 78.014 78.2609 76.2481 78.4913L49.7601 40.4611C51.5772 40.282 53.599 38.8744 54.29 37.2365C54.6227 36.392 54.5971 35.7522 54.29 35.2915Z"
        fill="#0064DC"
      />
      <path
        d="M49.7347 40.3847C47.9176 40.5639 47.0987 39.361 47.8408 37.7743C48.5574 36.1876 50.6048 34.78 52.3707 34.5497C54.1877 34.3705 55.0067 35.5734 54.2645 37.1601C53.5479 38.8236 51.5261 40.2312 49.7347 40.3847Z"
        fill="#E55D4C"
      />
      <path
        d="M49.7347 40.3847C47.9176 40.5639 47.0987 39.361 47.8408 37.7743C48.5574 36.1876 50.6048 34.78 52.3707 34.5497C54.1877 34.3705 55.0067 35.5734 54.2645 37.1601C53.5479 38.8236 51.5261 40.2312 49.7347 40.3847Z"
        fill="#FFCA00"
      />
      <path
        d="M43.9507 34.14C43.6436 33.6793 43.5668 32.9883 43.9507 32.2206C44.6673 30.6339 46.7147 29.2263 48.4805 28.996C49.3251 28.8936 50.0161 29.1751 50.3744 29.6869L54.29 35.3172C53.9317 34.8054 53.2919 34.6007 52.3962 34.6262C50.5791 34.8054 48.5573 36.213 47.8663 37.8509C47.4824 38.6186 47.5592 39.3352 47.8663 39.7703C46.6891 38.0812 45.128 35.8291 43.9507 34.14Z"
        fill="#FFCA00"
      />
      <path
        d="M47.8664 39.7704L43.9508 34.1401C44.3091 34.652 44.9489 34.8567 45.8446 34.8311L49.7603 40.4614C48.8645 40.487 48.1735 40.2055 47.8664 39.7704Z"
        fill="#ABE0F6"
      />
      <path
        d="M50.3744 29.6614L54.2901 35.2917C54.5972 35.7523 54.6739 36.4433 54.2901 37.2111C53.5735 38.7978 51.5261 40.2054 49.7602 40.4357L45.8446 34.8054C47.6617 34.6263 49.6834 33.2187 50.3744 31.5808C50.7071 30.7618 50.6815 30.122 50.3744 29.6614Z"
        fill="#ABE0F6"
      />
      <path
        d="M45.7935 34.7541C43.9765 34.9333 43.1575 33.7304 43.8997 32.1437C44.6163 30.557 46.6636 29.1494 48.4295 28.9191C50.2466 28.7399 51.0655 29.9428 50.3233 31.5295C49.6323 33.193 47.6105 34.6006 45.7935 34.7541Z"
        fill="#71ADEE"
      />
      <path
        d="M92.9854 42.2017L92.1152 48.6253L133.626 80.2574L134.47 73.8593L92.9854 42.2017Z"
        fill="#0064DC"
      />
      <path
        d="M91.1685 55.9194L90.324 62.3175L125.795 89.3941L126.665 82.996L91.1685 55.9194Z"
        fill="#0064DC"
      />
      <path
        d="M89.3515 69.5603L88.507 75.9584L128.098 106.208L128.968 99.7592L89.3515 69.5603Z"
        fill="#0064DC"
      />
      <path
        d="M87.5601 83.2517L86.7156 89.6498L113.92 110.482L114.79 104.058L87.5601 83.2517Z"
        fill="#0064DC"
      />
      <path
        d="M85.7429 96.9429L84.8983 103.341L121.981 131.697L122.852 125.299L85.7429 96.9429Z"
        fill="#0064DC"
      />
      <path
        d="M84.5146 70.9676C84.489 70.9676 84.4378 70.9676 84.4122 70.9676C84.3099 70.942 84.2331 70.8908 84.1819 70.7884L83.5165 69.6624C83.4141 69.4832 83.4397 69.2529 83.5933 69.1249C83.7468 69.0226 83.9516 69.0737 84.0539 69.2529L84.489 69.9951L85.8198 68.5107C85.9478 68.3828 86.1525 68.3828 86.2805 68.5363C86.4084 68.6899 86.4084 68.9458 86.2805 69.0737L84.6937 70.8652C84.617 70.9164 84.5658 70.942 84.5146 70.9676Z"
        fill="#0064DC"
      />
      <path
        d="M86.3316 57.6082C86.306 57.6082 86.2548 57.6082 86.2292 57.6082C86.1269 57.5826 86.0501 57.5314 85.9989 57.429L85.3335 56.303C85.2311 56.1238 85.2567 55.8935 85.4103 55.7656C85.5638 55.6632 85.7686 55.7144 85.871 55.8935L86.306 56.6357L87.6368 55.1513C87.7648 55.0234 87.9695 55.0234 88.0975 55.1769C88.2254 55.3305 88.2254 55.5864 88.0975 55.7144L86.5108 57.5058C86.4596 57.5826 86.3828 57.6082 86.3316 57.6082Z"
        fill="#0064DC"
      />
      <path
        d="M88.1485 43.9166C88.1229 43.9166 88.0717 43.9166 88.0461 43.9166C87.9438 43.891 87.867 43.8398 87.8158 43.7374L87.1504 42.6113C87.048 42.4322 87.0736 42.2019 87.2272 42.0739C87.3807 41.9715 87.5855 42.0227 87.6879 42.2019L88.1229 42.944L89.4537 41.4597C89.5817 41.3317 89.7864 41.3317 89.9144 41.4853C90.0423 41.6388 90.0423 41.8948 89.9144 42.0227L88.3277 43.8142C88.2509 43.8654 88.1997 43.891 88.1485 43.9166Z"
        fill="#0064DC"
      />
      <path
        d="M194.894 147.769C185.911 149.51 178.873 152.325 179.18 154.091C179.308 154.858 180.869 155.293 183.352 155.396L168.304 161.41C167.587 161.717 167.996 162.766 168.713 162.536C168.739 162.536 186.141 156.599 190.927 154.961C192.539 154.756 194.228 154.475 195.994 154.142C204.977 152.402 212.015 149.586 211.708 147.821C211.401 146.055 203.877 146.029 194.894 147.769Z"
        fill="#2F455F"
      />
      <path
        d="M209.404 141.244C214.062 127.936 205.156 112.683 189.468 107.18C196.634 109.688 197.606 125.684 191.823 143.368C195.892 138.71 205.924 139.12 209.404 141.244Z"
        fill="#004EB6"
      />
      <path
        d="M189.468 107.181C173.78 101.679 157.299 108 152.615 121.308C157.529 122.204 163.927 127.604 165.002 133.951C171.528 116.548 182.277 104.673 189.468 107.181Z"
        fill="#004EB6"
      />
      <path
        d="M189.468 107.181C182.302 104.673 171.554 116.548 165.028 133.95C174.011 130.93 188.854 137.201 191.823 143.368C197.607 125.684 196.634 109.689 189.468 107.181Z"
        fill="#2F455F"
      />
      <path
        d="M187.577 110.703L170.14 158.851L171.512 159.348L188.949 111.2L187.577 110.703Z"
        fill="#FFDC1E"
      />
      <path
        d="M186.013 118.135C185.911 118.135 185.808 118.135 185.681 118.083C185.297 117.955 185.117 117.52 185.245 117.162L189.545 105.134C189.673 104.75 190.108 104.571 190.466 104.699C190.85 104.827 191.029 105.262 190.901 105.62L186.602 117.648C186.525 117.93 186.295 118.109 186.013 118.135Z"
        fill="#E39F3A"
      />
      <path
        d="M209.404 141.244C214.062 127.936 205.156 112.683 189.468 107.18C194.433 108.921 196.608 116.905 195.994 128.038C207.971 129.087 210.556 137.917 209.404 141.244Z"
        fill="#3989E5"
      />
      <path
        d="M189.468 107.181C173.78 101.679 157.299 108 152.615 121.308C153.792 117.981 163.21 112.607 171.323 119.389C177.824 110.329 184.503 105.441 189.468 107.181Z"
        fill="#3989E5"
      />
      <path
        d="M189.468 107.18C184.503 105.44 175.981 109.356 171.323 119.388C179.743 116.854 191.464 120.719 195.994 128.038C198.784 114.551 194.407 108.92 189.468 107.18Z"
        fill="#71ADEE"
      />
      <path
        d="M175.367 149.817C175.367 149.817 175.393 149.817 175.367 149.817C175.444 149.612 175.034 149.279 174.446 149.075C173.857 148.87 173.345 148.844 173.268 149.049H173.243L169.737 158.697C169.481 159.363 168.994 159.9 168.355 160.207C167.715 160.514 166.973 160.54 166.307 160.31C165.642 160.054 165.104 159.567 164.797 158.928C164.49 158.288 164.465 157.546 164.695 156.88L165.488 154.731H165.463C165.54 154.526 165.13 154.219 164.541 153.988C163.953 153.784 163.441 153.758 163.364 153.963H163.339L162.571 156.113C161.649 158.697 162.98 161.538 165.54 162.459C166.256 162.715 166.998 162.818 167.74 162.741C168.278 162.69 168.815 162.536 169.327 162.306C170.53 161.743 171.426 160.745 171.886 159.491L175.367 149.817Z"
        fill="#E39F3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.5944 157.699C68.0844 156.676 64.911 157.085 62.5309 158.646C60.5859 159.926 59.8181 161.538 60.33 162.664L56.1073 165.454C55.5442 165.812 55.4674 166.375 55.9281 166.708C56.3888 167.041 57.2077 166.964 57.7708 166.606L61.9935 163.816C63.6826 164.2 66.0882 163.688 68.0332 162.434C70.4389 160.847 71.1555 158.749 69.5944 157.699Z"
        fill="#2F455F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.8465 112.989L71.9488 113.066C73.4332 114.09 72.4607 116.419 70.6692 116.061L70.4645 116.009C68.8266 115.677 68.6474 113.425 70.183 112.836L70.2854 112.81C70.7972 112.606 71.3858 112.682 71.8465 112.989ZM82.2881 162.178L92.8065 162.766C93.0881 162.792 93.344 162.741 93.5999 162.613L116.812 151.89C117.81 151.429 117.938 150.098 117.042 149.458L78.091 121.205C81.3924 118.287 79.8313 119.08 79.8569 120.462L80.7014 160.54C80.727 161.436 81.418 162.152 82.2881 162.178Z"
        fill="#2F455F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.9482 126.502L30.5918 143.444L50.4258 157.392L51.7822 140.936L44.1045 125.888L31.9482 126.502Z"
        fill="#E2F6FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.8095 109.765L87.3298 124.839L60.202 138.301L51.6542 142.421L42.5433 126.835L77.8095 109.765Z"
        fill="#0064DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.9737 153.323L50.4259 157.392L51.6543 142.421L60.2021 138.301L58.9737 153.323Z"
        fill="#71ADEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.9735 153.323L81.0596 168.832L82.2881 153.861L60.2019 138.301L58.9735 153.323Z"
        fill="#E2F6FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.0077 136.407L82.3907 152.607L81.0599 168.832L105.373 157.085L106.831 139.068L95.0077 136.407Z"
        fill="#71ADEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.288 153.861L96.1335 137.175L74.0473 121.64L60.2019 138.301L82.288 153.861Z"
        fill="#ABE0F6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.2607 137.328L85.7432 138.992L88.328 137.738L85.9735 136.125L83.2607 137.328Z"
        fill="#0064DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.1336 137.175L106.831 139.068L87.3299 124.839L74.0475 121.64L96.1336 137.175Z"
        fill="#0064DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.7432 138.992L85.4105 143.061L88.1745 139.734L88.328 137.738L85.7432 138.992Z"
        fill="#71ADEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.0334 148.256L64.0666 145.441L63.5803 151.251L67.5727 154.04L68.0334 148.256Z"
        fill="#ABE0F6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.2995 154.117L72.3327 151.302L71.8464 157.085L75.8388 159.9L76.2995 154.117Z"
        fill="#ABE0F6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.0629 138.019L35.1217 135.204L34.661 141.014L38.6022 143.829L39.0629 138.019Z"
        fill="#ABE0F6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.3803 143.88L43.3879 141.064L42.9272 146.874L46.894 149.689L47.3803 143.88Z"
        fill="#ABE0F6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.1746 154.628L92.5509 152.53L92.0903 158.339L87.714 160.463L88.1746 154.628Z"
        fill="#0064DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.74 159.312L96.108 161.564L97.0038 150.406L101.636 148.154L100.74 159.312Z"
        fill="#FFDC1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.7982 166.939C56.0304 166.888 55.4418 166.171 55.493 165.403L56.3375 154.91L59.1783 155.141L58.3337 165.634C58.2825 166.401 57.5659 166.99 56.7982 166.939Z"
        fill="#0064DC"
      />
      <path
        d="M62.5325 154.211C62.8037 150.872 60.9336 147.996 58.3556 147.787C55.7776 147.577 53.4678 150.114 53.1966 153.453C52.9254 156.791 54.7955 159.668 57.3735 159.877C59.9515 160.087 62.2613 157.55 62.5325 154.211Z"
        fill="#ABE0F6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.5434 126.835L31.5387 126.554L66.8304 109.484L77.8095 109.765L42.5434 126.835Z"
        fill="#ABE0F6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.7431 138.991L85.4104 143.06L82.928 141.346L83.2607 137.353L85.7431 138.991Z"
        fill="#E2F6FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.0863 156.573C66.2673 156.496 65.4996 156.906 64.9365 157.546L69.6967 160.873C69.6967 160.796 69.7223 160.668 69.7223 160.591C69.8502 158.518 68.6986 156.727 67.0863 156.573Z"
        fill="#71ADEE"
      />
      <path
        d="M131.834 172.415C134.778 165.761 143.018 159.747 150.21 159.005C157.401 158.262 160.856 163.048 157.913 169.677C154.97 176.331 146.729 182.345 139.538 183.087C132.372 183.855 128.917 179.069 131.834 172.415Z"
        fill="#2F455F"
      />
      <path
        d="M124.95 168.371C127.228 171.468 130.171 174.078 133.268 175.46L130.76 177.303C127.663 175.921 124.694 173.31 122.442 170.213C123.287 169.599 124.131 168.985 124.95 168.371Z"
        fill="#FDBD00"
      />
      <path
        d="M133.268 175.46C136.39 176.842 139.077 176.714 140.868 175.383L138.36 177.226C136.543 178.557 133.882 178.684 130.76 177.302C131.527 176.765 132.525 176.023 133.268 175.46Z"
        fill="#FDBD00"
      />
      <path
        d="M120.778 158.313C121.137 161.665 122.698 165.274 124.976 168.371C124.131 168.985 123.312 169.599 122.468 170.213C120.19 167.117 118.629 163.508 118.27 160.156C117.912 156.829 118.859 154.346 120.625 153.041L123.133 151.198C121.341 152.503 120.42 154.986 120.778 158.313Z"
        fill="#FDBD00"
      />
      <path
        d="M120.625 153.041L123.133 151.198C124.95 149.868 127.612 149.74 130.734 151.122L128.226 152.964C125.104 151.582 122.442 151.71 120.625 153.041Z"
        fill="#E39F3A"
      />
      <path
        d="M117.477 148.741L119.985 146.899C122.442 145.107 126.076 144.928 130.299 146.796L127.791 148.639C123.543 146.771 119.908 146.95 117.477 148.741Z"
        fill="#FFF2AD"
      />
      <path
        d="M140.894 175.383L138.386 177.226C140.177 175.92 141.099 173.438 140.74 170.111C140.049 163.406 134.419 155.703 128.226 152.964L130.734 151.122C136.927 153.86 142.557 161.563 143.248 168.268C143.607 171.621 142.66 174.078 140.894 175.383Z"
        fill="#E39F3A"
      />
      <path
        d="M144.042 179.708L141.534 181.551C143.94 179.785 145.194 176.433 144.733 171.903C143.786 162.792 136.185 152.376 127.765 148.665L130.273 146.822C138.693 150.559 146.294 160.949 147.241 170.06C147.727 174.564 146.473 177.917 144.042 179.708Z"
        fill="#FFDC1E"
      />
      <path
        d="M144.733 171.876C145.194 176.406 143.939 179.759 141.534 181.524L144.042 179.682C146.448 177.916 147.702 174.563 147.241 170.033L144.733 171.876Z"
        fill="#E39F3A"
      />
      <path
        d="M127.791 148.639C119.371 144.902 113.331 149.278 114.278 158.389C115.225 167.5 122.826 177.891 131.246 181.627C139.666 185.364 145.705 180.987 144.759 171.876C143.786 162.766 136.211 152.375 127.791 148.639ZM130.785 177.302C124.592 174.564 118.962 166.86 118.271 160.155C117.58 153.45 122.033 150.225 128.252 152.964C134.445 155.702 140.075 163.405 140.766 170.111C141.457 176.816 136.979 180.04 130.785 177.302Z"
        fill="#FDBD00"
      />
      <path
        d="M130.76 177.303C124.566 174.564 118.961 166.861 118.245 160.156C117.554 153.451 122.007 150.226 128.226 152.964L127.765 148.639C119.345 144.928 113.306 149.279 114.253 158.39C115.199 167.501 122.8 177.891 131.195 181.628L130.76 177.303Z"
        fill="#FFDC1E"
      />
      <path
        d="M128.226 152.964C134.419 155.703 140.05 163.406 140.741 170.111C141.432 176.816 136.978 180.041 130.76 177.303C124.566 174.564 118.936 166.861 118.245 160.156C117.554 153.451 122.033 150.226 128.226 152.964Z"
        fill="#E39F3A"
      />
      <path
        opacity="0.3"
        d="M130.094 173.336L130.197 174.411C130.222 174.641 130.197 174.795 130.12 174.846L131.758 173.643C131.834 173.592 131.86 173.438 131.834 173.208L131.732 172.133L130.094 173.336Z"
        fill="#FFDC1E"
      />
      <path
        opacity="0.3"
        d="M130.836 170.879L132.474 169.676C132.091 169.958 131.374 169.83 130.325 169.343C128.943 168.729 128.098 167.782 127.586 166.477C127.407 166.042 127.228 165.812 126.998 165.709C126.895 165.658 126.793 165.632 126.639 165.632L125.744 165.581C125.59 165.581 125.462 165.581 125.385 165.658L123.747 166.861C123.824 166.81 123.927 166.784 124.106 166.784L125.001 166.835C125.129 166.835 125.257 166.861 125.36 166.912C125.59 167.014 125.769 167.245 125.948 167.68C126.46 168.959 127.305 169.932 128.687 170.546C129.736 171.007 130.478 171.135 130.836 170.879Z"
        fill="#FFDC1E"
      />
      <path
        opacity="0.3"
        d="M126.46 158.518L127.791 157.545L127.919 157.596C128.354 157.801 128.508 158.006 128.559 158.492L128.661 159.49C130.504 160.565 131.579 161.998 132.449 163.892C132.628 164.302 132.679 164.532 132.5 164.634L134.138 163.432C134.291 163.329 134.266 163.073 134.087 162.689C133.217 160.796 132.142 159.388 130.299 158.287L130.197 157.289C130.146 156.803 129.992 156.598 129.557 156.394L128.508 155.933C128.277 155.831 128.149 155.805 128.073 155.882L126.435 157.085C126.051 157.11 125.718 157.213 125.488 157.392L123.85 158.595C124.362 158.185 125.257 158.16 126.46 158.518ZM126.665 157.059C126.588 157.059 126.486 157.059 126.409 157.059C126.486 157.033 126.563 157.033 126.665 157.059Z"
        fill="#FFDC1E"
      />
      <path
        opacity="0.3"
        d="M130.069 163.559C128.636 162.766 127.356 161.998 127.228 160.77C127.177 160.284 127.254 159.977 127.484 159.823L125.846 161.026C125.641 161.179 125.539 161.487 125.59 161.973C125.718 163.176 126.998 163.969 128.431 164.762C130.555 165.914 133.14 167.424 133.498 170.879C133.626 171.979 133.37 172.773 132.807 173.208L134.445 172.005C135.034 171.57 135.264 170.802 135.136 169.676C134.778 166.247 132.193 164.737 130.069 163.559Z"
        fill="#FFDC1E"
      />
      <path
        d="M127.919 157.596C128.354 157.801 128.508 158.006 128.559 158.492L128.661 159.49C130.504 160.565 131.579 161.998 132.449 163.892C132.73 164.481 132.653 164.711 132.167 164.737L131.297 164.788C131.144 164.788 131.041 164.788 130.939 164.737C130.734 164.634 130.581 164.43 130.376 164.046C129.787 162.843 129.147 161.998 127.791 161.384C126.179 160.668 125.488 160.847 125.616 162.024C125.744 163.227 127.023 164.02 128.456 164.814C130.581 165.965 133.165 167.475 133.524 170.93C133.754 173.157 132.577 174.078 130.12 173.387L130.222 174.462C130.273 174.948 130.145 175.05 129.71 174.846L128.661 174.385C128.226 174.18 128.047 173.95 128.021 173.489L127.919 172.44C125.948 171.314 124.566 169.574 123.799 167.603C123.568 167.04 123.671 166.835 124.131 166.861L125.027 166.912C125.155 166.912 125.283 166.938 125.385 166.989C125.616 167.091 125.795 167.322 125.974 167.757C126.486 169.036 127.33 170.009 128.712 170.623C130.427 171.365 131.272 171.237 131.144 169.983C131.016 168.678 129.762 167.91 128.277 167.117C126.127 165.94 123.543 164.302 123.184 161C122.954 158.825 124.029 157.852 126.486 158.595L126.383 157.596C126.332 157.11 126.46 157.033 126.895 157.213L127.919 157.596Z"
        fill="#FFF2AD"
      />
    </SvgIcon>
  );
};

export default AssetListOverviewIcon;
