import React, { useEffect } from "react";
import { connect } from "react-redux";

import CeremonyForm from "../../../components/forms/your-arrangements/CeremonyForm";

import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { wishesCeremonyPageProps } from "../../../selectors/wishes";
import { WISHES_RESTING_PATH } from "../../../constants/routes";
import { CEREMONY_FORM_ID } from "../../../constants/forms";
import { updateCeremony } from "../../../actions/wishes";
import { skipSection } from "../../../actions/navigation";
import { getFormData } from "../../../actions/forms";
import { skipWishes } from "../../../api/wishes";

const CeremonyPage = ({
  getFormData,
  updateCeremony,
  hasLoaded,
  skipSection,
  ...restOfProps
}) => {
  useEffect(() => {
    getFormData(CEREMONY_FORM_ID);
  }, []);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <CeremonyForm
      backLink={WISHES_RESTING_PATH}
      onSubmit={() => updateCeremony()}
      onSkip={() => skipSection(skipWishes, CEREMONY_FORM_ID)}
      {...restOfProps}
    />
  );
};

export default connect(wishesCeremonyPageProps, {
  getFormData,
  updateCeremony,
  skipSection,
})(CeremonyPage);
