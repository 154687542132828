import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import {
  Body,
  SmallBody,
  SmallTextLink,
  SmallExternalLink,
} from "./Typography";
import { colorPalette } from "../../config/Theme";

const StyledInfoBox = styled(Box)`
  border: 1px solid ${colorPalette.willfulxLightBlue};
  border-radius: 4px;
`;

export const InfoBox = ({
  title,
  paragraph,
  linkUrl,
  linkText,
  externalLinkUrl,
  icon,
  border = false,
  padding = 2,
}) => {
  const infoBox = (
    <Box p={padding} bgcolor="secondary.main">
      <Grid container spacing={2}>
        <Grid item sm={icon ? 9 : 12}>
          {title && (
            <Box display="flex" justifyContent="space-between" mb={0.5}>
              <Body component="span" align="left">
                {title}
              </Body>
            </Box>
          )}
          <SmallBody inline dangerouslySetInnerHTML={{ __html: paragraph }} />
          {linkUrl && linkText && (
            <SmallTextLink to={linkUrl} text={linkText} />
          )}
          {externalLinkUrl && linkText && (
            <SmallExternalLink href={externalLinkUrl} text={linkText} />
          )}
        </Grid>
        {icon && (
          <Grid item sm={3}>
            {icon && icon}
          </Grid>
        )}
      </Grid>
    </Box>
  );

  if (border) {
    return <StyledInfoBox>{infoBox}</StyledInfoBox>;
  }

  return infoBox;
};

const StyledNoticeInfoBox = styled(Box)`
  border: 1px solid ${colorPalette.yellow};
  border-radius: 4px;
`;

export const NoticeInfoBox = ({
  title,
  paragraph,
  linkUrl,
  linkText,
  externalLinkUrl,
  icon,
  border = false,
  padding = 2,
}) => {
  const infoBox = (
    <Box p={padding} bgcolor="yellow.light">
      <Grid container spacing={2}>
        <Grid item sm={icon ? 9 : 12}>
          {title && (
            <Box display="flex" justifyContent="space-between" mb={0.5}>
              <Body component="span" align="left">
                {title}
              </Body>
            </Box>
          )}
          <SmallBody inline dangerouslySetInnerHTML={{ __html: paragraph }} />
          {linkUrl && linkText && (
            <SmallTextLink to={linkUrl} text={linkText} />
          )}
          {externalLinkUrl && linkText && (
            <SmallExternalLink href={externalLinkUrl} text={linkText} />
          )}
        </Grid>
        {icon && (
          <Grid item sm={3}>
            {icon && icon}
          </Grid>
        )}
      </Grid>
    </Box>
  );

  if (border) {
    return <StyledNoticeInfoBox>{infoBox}</StyledNoticeInfoBox>;
  }

  return infoBox;
};

const InfoBoxGroup = ({ infoBox = [], reverseWrap = false }) => {
  return infoBox.map((infoBox) => (
    <Box key={infoBox.title} mb={2} mt={reverseWrap ? 2 : 0}>
      <InfoBox {...infoBox} />
    </Box>
  ));
};

export default InfoBoxGroup;
