export const addInvitedUser = (user) => ({
  type: ADD_INVITED_TYPE,
  payload: { user },
});

export const validateInviteToken = () => ({
  type: VALIDATE_INVITE_TOKEN_TYPE,
});

export const revokeInvite = (user) => ({
  type: REVOKE_INVITE_TYPE,
  payload: { user },
});

export const invitedUserGetStarted = () => ({
  type: INVITED_USER_GET_STARTED_TYPE,
});

export const resendInvite = (user) => ({
  type: RESEND_INVITE_TYPE,
  payload: { user },
});

export const resendLoadingComplete = (userId) => ({
  type: RESEND_LOADING_COMPLETE_TYPE,
  payload: {
    userId,
  },
});

export const revokeLoadingComplete = (userId) => ({
  type: REVOKE_LOADING_COMPLETE_TYPE,
  payload: {
    userId,
  },
});

export const updateInvites = (assignedUsers, availableInvites) => ({
  type: UPDATE_INVITES_TYPE,
  payload: {
    assignedUsers,
    availableInvites,
  },
});

export const UPDATE_INVITES_TYPE = "UPDATE_INVITES";
export const ADD_INVITED_TYPE = "ADD_INVITED";
export const VALIDATE_INVITE_TOKEN_TYPE = "VALIDATE_INVITE_TOKEN";
export const INVITED_USER_GET_STARTED_TYPE = "INVITED_USER_GET_STARTED";
export const REVOKE_INVITE_TYPE = "REVOKE_INVITE";
export const RESEND_INVITE_TYPE = "RESEND_INVITE";
export const RESEND_LOADING_COMPLETE_TYPE = "RESEND_LOADING_COMPLETE";
export const REVOKE_LOADING_COMPLETE_TYPE = "REVOKE_LOADING_COMPLETE";
