import { putRequest, getRequest, deleteRequest } from "./request";
import {
  PERSONAL_ATTORNEYS_ENDPOINT,
  PERSONAL_ATTORNEYS_NEXT_PAGE_ENDPOINT,
} from "../constants/routes";

export const submitPersonalAttorneys = (config) =>
  putRequest(PERSONAL_ATTORNEYS_ENDPOINT, config);

export const getPersonalAttorneys = (config) =>
  getRequest(PERSONAL_ATTORNEYS_ENDPOINT, config);

export const removePersonalAttorneyEndpoint = (config) =>
  deleteRequest(PERSONAL_ATTORNEYS_ENDPOINT, config);

export const getPersonalAttorneysNextPage = (config) =>
  putRequest(PERSONAL_ATTORNEYS_NEXT_PAGE_ENDPOINT, config);
