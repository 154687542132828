import R from "ramda";
import React, { useState } from "react";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";

import {
  SectionTitle,
  H4,
  H3,
  SmallBody,
  SmallExternalLink,
  MiceType,
} from "../ui/Typography";
import { SecondaryButton, ButtonLink } from "../ui/Button";
import SelectInput from "../ui/inputs/DropdownSelect/SelectInput";
import CheckmarkBlue from "../ui/icons/CheckmarkBlue";
import StatusLabel from "../ui/StatusLabel";

import { productConstants } from "./PlanConstants";

import { PLANS_PATH, DASHBOARD_PATH } from "../../constants/routes";
import { transformBundleOptionList } from "../ui/utils/BundleOptionList";

const TopBox = styled(Box)`
  border-top: ${(props) => (props.selected ? "2px" : "1px")} solid
    ${(props) => (props.selected ? "#0064DC" : "#cacdd4")};
  border-right: ${(props) => (props.selected ? "2px" : "1px")} solid
    ${(props) => (props.selected ? "#0064DC" : "#cacdd4")};
  border-left: ${(props) => (props.selected ? "2px" : "1px")} solid
    ${(props) => (props.selected ? "#0064DC" : "#cacdd4")};
  margin: 0 auto;
`;

const YellowBox = styled(Box)`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const BottomBox = styled(Box)`
  border-bottom-left-radius: ${(props) => (props.isBundle ? "0" : "4px")};
  border-bottom-right-radius: ${(props) => (props.isBundle ? "0" : "4px")};
  border-bottom: ${(props) =>
      props.selected && !props.hideBorderBottomHighlight ? "2px" : "1px"}
    solid
    ${(props) =>
      props.selected && !props.hideBorderBottomHighlight
        ? "#0064DC"
        : "#cacdd4"};
  border-right: ${(props) => (props.selected ? "2px" : "1px")} solid
    ${(props) => (props.selected ? "#0064DC" : "#cacdd4")};
  border-left: ${(props) => (props.selected ? "2px" : "1px")} solid
    ${(props) => (props.selected ? "#0064DC" : "#cacdd4")};
`;

// Plans page want essentials + premium to hold same minimum height
const getTopBoxMinHeight = (isDesktop, isDesktopLarge, showExclusiveOffer) => {
  if (isDesktopLarge) {
    return 274 + (showExclusiveOffer ? 56 : 0);
  }
  if (isDesktop) {
    return 274 + (showExclusiveOffer ? 56 : 0);
  }
  return 229 + (showExclusiveOffer ? 50 : 0);
};

const PlanCardCta = ({
  viewOnly,
  activeSymbol,
  ctaText,
  handleSubmit,
  isLoading,
  loadingLabel,
  isUpgrade,
  isPlanSelected,
  isFamilyUpgradeAndQuantityChanged,
}) => {
  if (viewOnly) {
    return (
      <ButtonLink
        fullWidth
        to={PLANS_PATH}
        displayArrowRight
        className={`qa-submit vwo-select-plan ${activeSymbol}`}
        text={ctaText}
      />
    );
  }

  if (isUpgrade && isPlanSelected && !isFamilyUpgradeAndQuantityChanged) {
    return (
      <ButtonLink
        fullWidth
        secondary
        to={DASHBOARD_PATH}
        displayArrowRight
        className={`qa-submit vwo-select-plan ${activeSymbol}`}
        text={ctaText}
      />
    );
  }

  return (
    <SecondaryButton
      fullWidth
      displayArrowRight
      className={`qa-submit vwo-select-plan ${activeSymbol}`}
      isLoading={isLoading}
      loadingLabel={loadingLabel}
      onClick={handleSubmit}
      text={ctaText}
      type="button"
    />
  );
};

const StrikeThroughBody = styled(SmallBody)`
  text-decoration: line-through;
`;

const Plan = ({
  onClick,
  viewOnly,
  defaultQuantity,
  isPlanSelected,
  isLoading,
  isBundle = false,
  isUpgrade = false,
  bundlePrices,
  needsMinHeight = false, // plans page should have premium + essentials same height
  symbol,
  noCta,
  analyticsClickExternalLink = () => {},
  price,
  upgradePrice,
  translations,
  isQuebecSelected,
  strikeThroughPrice,
  showExclusiveOffer = false,
  recommendedForYou,
  noMaxWidth = false,
}) => {
  const [quantity, setQuantity] = useState(defaultQuantity);
  const [quantityError, setQuantityError] = useState("");
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isTabletLarge = useMediaQuery(theme.breakpoints.down("lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isDesktopLarge = useMediaQuery(theme.breakpoints.up("xl"));

  const getIsFamilyUpgradeAndQuantityChanged = () => {
    if (isUpgrade && isBundle && isPlanSelected) {
      return defaultQuantity !== quantity;
    }
    return false;
  };
  const isFamilyUpgradeAndQuantityChanged = getIsFamilyUpgradeAndQuantityChanged();

  const handleSubmit = () => {
    if (isBundle && !isUpgrade && quantity <= 1) {
      setQuantityError(translations.quantityRequired);
    } else {
      onClick(quantity);
    }
  };

  const handleOnChange = (value) => {
    setQuantityError("");
    setQuantity(value);
  };

  // during certain route transitions from a page that has a plan card on it
  // to another one, plan cards are mounted without data.
  // this causes our `const planTranslations = translations[activeSymbol];`
  // line to break many things dependent on it like `planTranslations.details.map`
  if (!symbol) {
    return null;
  }
  const activeSymbol = isBundle ? "bundle" : symbol;
  const isEssentialsPlan = symbol === "essentials";
  const isNotarialWill = symbol === "notarial";

  const planTranslations = translations[activeSymbol];
  const backgroundColor = R.path([activeSymbol, "backgroundColor"])(
    productConstants,
  );
  const textColor = R.path([activeSymbol, "textColor"])(productConstants);
  const secondaryTextColor = R.path([activeSymbol, "secondaryTextColor"])(
    productConstants,
  );

  const bundleOptions = transformBundleOptionList(
    translations.bundleSizeOptions,
  );

  const getPrimaryButtonText = () => {
    if (isUpgrade) {
      let ctaUpgradePrice = upgradePrice;
      if (isPlanSelected && !isFamilyUpgradeAndQuantityChanged) {
        return translations.button.currentPlan;
      }
      if (isBundle) {
        const bundleQuantityPrice =
          R.find(R.propEq("quantity", quantity))(bundlePrices) || {};
        ctaUpgradePrice = bundleQuantityPrice.upgradePrice;
      }
      return translations.button.upgradeFor.replace("PRICE", ctaUpgradePrice);
    }
    if (isPlanSelected) {
      return translations.button.selected;
    }
    if (viewOnly) {
      return translations.button.viewPlans;
    }
    return translations.button.unselected;
  };

  const tabletMobileMarginTop = isTablet ? 0 : 2.5;
  const detailsCopy = planTranslations.detailsDeprecated;

  return (
    <Box>
      <Box mb={2} mt={!recommendedForYou ? tabletMobileMarginTop : 0}>
        {recommendedForYou && (
          <YellowBox
            bgcolor="yellow.main"
            borderColor="yellow.main"
            display="flex"
            border={0}
            justifyContent="center"
            py={0.5}
          >
            <SectionTitle>{planTranslations.recommendedForYou}</SectionTitle>
          </YellowBox>
        )}
        <TopBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          bgcolor={backgroundColor}
          selected={isPlanSelected && !isFamilyUpgradeAndQuantityChanged}
          p={isTablet ? 1.5 : 2}
          minHeight={
            needsMinHeight &&
            getTopBoxMinHeight(isDesktop, isDesktopLarge, showExclusiveOffer)
          }
        >
          <Box>
            {showExclusiveOffer && (
              <Box align="right" paddingBottom={0.5}>
                <StatusLabel
                  status={isEssentialsPlan ? "discountBlue" : "discount"}
                  label={planTranslations.exclusiveOffer}
                />
              </Box>
            )}
            <Box display="flex" width="100%" justifyContent="space-between">
              <H4 align="left" color={textColor} data-testid="plan-name">
                {planTranslations.planName}
              </H4>
              {price && (
                <Box display="flex" flexDirection="column">
                  {showExclusiveOffer && (
                    <StrikeThroughBody
                      color={secondaryTextColor}
                      component="span"
                      align="right"
                    >
                      {strikeThroughPrice}
                    </StrikeThroughBody>
                  )}
                  <H3
                    marginBottom="0"
                    align="right"
                    color={textColor}
                    data-testid="plan-price"
                  >
                    {price}
                  </H3>
                  {isBundle && (
                    <Box
                      textAlign="right"
                      minWidth={isTabletLarge ? "135px" : "155px"}
                    >
                      <MiceType align="right" color={textColor}>
                        {planTranslations.for2}
                      </MiceType>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            <Box mt={0.5} maxWidth={noMaxWidth ? "100%" : "340px"}>
              {isBundle ? (
                <>
                  <SmallBody color={textColor}>
                    {planTranslations.familyDescription1}

                    <SmallBody component="span" inline bold color={textColor}>
                      {planTranslations.familyDescriptionCoverage}
                    </SmallBody>

                    {planTranslations.familyDescription2}
                  </SmallBody>
                </>
              ) : (
                <SmallBody color={textColor}>
                  {planTranslations.description}
                </SmallBody>
              )}
            </Box>
          </Box>
          {isBundle && !viewOnly && (
            <Box mt={2} mb={1}>
              <SelectInput
                value={quantity}
                error={quantityError}
                onChange={handleOnChange}
                label={translations.bundlePlanLabel}
                labelColor={textColor}
                optionList={bundleOptions}
                placeholder={translations.bundlePlanPlaceholder}
                marginBottom={0}
              />
            </Box>
          )}
          {!noCta && (
            <PlanCardCta
              viewOnly={viewOnly}
              activeSymbol={activeSymbol}
              ctaText={getPrimaryButtonText()}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              isUpgrade={isUpgrade}
              isPlanSelected={isPlanSelected}
              isFamilyUpgradeAndQuantityChanged={
                isFamilyUpgradeAndQuantityChanged
              }
              loadingLabel={translations.button.loadingLabel}
            />
          )}
        </TopBox>
        <BottomBox
          display="flex"
          selected={isPlanSelected && !isFamilyUpgradeAndQuantityChanged}
          flexDirection="column"
          bgcolor="willfulWhite.main"
          p={2}
        >
          <Box mb={0.5}>
            <SmallBody>{planTranslations.includedInPlan}</SmallBody>
          </Box>

          {detailsCopy.map((planDetail) => {
            return (
              <Box
                maxWidth={noMaxWidth ? "100%" : "330px"}
                mb={0.5}
                key={`${planDetail}`}
              >
                <Box component="span" display="flex">
                  <Box mt="2px">
                    <CheckmarkBlue width={20} height={20} />
                  </Box>
                  <Box ml={0.5}>
                    <SmallBody key={planDetail} align="left">
                      {planDetail}
                    </SmallBody>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </BottomBox>
        {isPlanSelected && !isFamilyUpgradeAndQuantityChanged && (
          <Box mt={1} display="flex" justifyContent="center">
            <SmallBody color="willfulBlue">
              {translations.selectedPlanLabel}
            </SmallBody>
          </Box>
        )}

        {isNotarialWill && (
          <Box mt={1} display="flex" justifyContent="center">
            <SmallExternalLink
              onClick={() =>
                analyticsClickExternalLink(translations.learnMoreNotarialLink)
              }
              text={translations.learnMoreNotarial}
              href={translations.learnMoreNotarialLink}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Plan;
