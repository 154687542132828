import React from "react";
import { connect } from "react-redux";

import Box from "@material-ui/core/Box";
import { H2, Body } from "../../components/ui/Typography";
import { sorryPageProps } from "../../selectors/sorry";
import { SecondaryButton } from "../../components/ui/Button";
import { displayModal } from "../../actions/modal";
import { MODAL_DELETE_ACCOUNT } from "../../constants/modal";

const SorryPage = ({ translations, displayModal }) => {
  const { pageTitle, pageDescription } = translations;
  return (
    <Box mt={10} mx={"auto"} maxWidth={580}>
      <H2>{pageTitle}</H2>
      <Body align="center">{pageDescription}</Body>
      <Box display="flex" justifyContent="center" mt={2}>
        <SecondaryButton
          type="button"
          text={translations.button.submit}
          onClick={() => displayModal(MODAL_DELETE_ACCOUNT)}
        ></SecondaryButton>
      </Box>
    </Box>
  );
};

export default connect(sorryPageProps, { displayModal })(SorryPage);
