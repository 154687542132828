import { combineReducers } from "redux";
import { DISPLAY_TOAST_TYPE, CLOSE_TOAST_TYPE } from "../actions/toast";

const visible = (state = false, { type }) => {
  switch (type) {
    case DISPLAY_TOAST_TYPE:
      return true;
    case CLOSE_TOAST_TYPE:
      return false;
    default:
      return state;
  }
};

const key = (state = null, { type, payload }) => {
  switch (type) {
    case DISPLAY_TOAST_TYPE:
      return payload.key;
    case CLOSE_TOAST_TYPE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  visible,
  key,
});
