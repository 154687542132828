import React, { useEffect, useRef } from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import { BBB_TRACKING_SCRIPT_URL } from "../../../constants/routes";
import GoogleRating from "./GoogleRating";
import ThirtyDayGuarantee from "./ThirtyDayGuarantee";
import DragonsDenIcon from "./DragonsDenIcon";
import BBBIcon from "./BBBIcon";

const iconSizes = {
  googleRating: {
    sm: { w: 90 },
    md: { w: 110 },
    lg: { w: 110 },
    xl: { w: 110 },
  },
  moneyBackGuarantee: {
    sm: { w: 110.25, h: 50 },
    md: { w: 120.26, h: 50 },
    lg: { w: 140.2, h: 50 },
    xl: { w: 156, h: 60 },
  },
  bbb: {
    sm: { w: 75, h: 66 },
    md: { w: 75, h: 66 },
    lg: { w: 80, h: 66 },
    xl: { w: 85, h: 66 },
  },
  dragonsDen: {
    sm: { w: 100, h: 32 },
    md: { h: 36 },
    lg: { h: 36 },
    xl: { w: 190, h: 36 },
  },
};

const getCurrentBreakpoint = (breakpoints) => {
  // Return the expected icon size for a given breakpoint, with sm being the default sizing.
  // Default sizing is required to ensure the code in the component doesn't break when trying
  // to access a size.
  const currentBreakpoint = Object.keys(breakpoints).reduce(
    (currentSize, name) => {
      const isBreakpoint = breakpoints[name];
      if (isBreakpoint) {
        return name;
      }

      return currentSize;
    },
    "sm",
  );

  return currentBreakpoint;
};

const DesktopTrustIconsForSignUp = ({
  translations,
  isFrench,
  shouldIncludeThirtyDay,
  shouldIncludeDragonsDen,
  shouldIncludeGoogleRating,
  centerLogos = false,
}) => {
  const theme = useTheme();
  const breakpoints = {
    sm: useMediaQuery(theme.breakpoints.down("sm")),
    md: useMediaQuery(theme.breakpoints.only("md")),
    lg: useMediaQuery(theme.breakpoints.only("lg")),
    xl: useMediaQuery(theme.breakpoints.up("xl")),
  };
  const currentBreakpoint = getCurrentBreakpoint(breakpoints);

  // Handle tracking for Better Business Bureau logo
  useEffect(() => {
    const script = document.createElement("script");

    script.src = BBB_TRACKING_SCRIPT_URL;
    script.async = true;

    document.body.appendChild(script);

    window.googletag = window.googletag || { cmd: [] };

    /* eslint-disable no-undef */
    googletag.cmd.push(() => {
      googletag
        .defineSlot("/21848388897/IABBB-Dynamic-Seal", [1, 1], "gpt-passback")
        .addService(googletag.pubads());
      googletag.enableServices();
      googletag.display("gpt-passback");
    });
    /* eslint-enable no-undef */

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const ref = useRef(null);
  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [isFrench]);

  const localeOptions = isFrench
    ? { locale: "fr-FR", reviewLang: "fr" }
    : { locale: "en-CA", reviewLang: "en" };

  return (
    <Box mt={2}>
      <Grid
        container
        alignItems="center"
        justifyContent={centerLogos ? "center" : ""}
      >
        {shouldIncludeDragonsDen && (
          <Grid item lg={centerLogos ? 6 : 0}>
            <Box mr={1} display="flex" mb={centerLogos ? 2 : 1}>
              <DragonsDenIcon
                isFrench={isFrench}
                width={iconSizes.dragonsDen[currentBreakpoint].w}
                height={iconSizes.dragonsDen[currentBreakpoint].h}
              />
            </Box>
          </Grid>
        )}
        {shouldIncludeGoogleRating && (
          <Grid item lg={centerLogos ? 6 : 0}>
            <Box display="flex" mb={centerLogos ? 2 : 1}>
              <Typography variant="srOnly">
                {translations.googleRating}
              </Typography>
              <GoogleRating
                isFrench={isFrench}
                width={iconSizes.googleRating[currentBreakpoint].w}
              />
            </Box>
          </Grid>
        )}
        <Grid item lg={centerLogos ? 6 : 0}>
          <Box mr={isFrench ? 1 : 2} display="flex" mb={centerLogos ? 2 : 1}>
            <a
              target="_blank"
              rel="noreferrer"
              title={translations.bbbImageAlt}
              href={translations.bbbLink}
            >
              <BBBIcon
                isFrench={isFrench}
                width={iconSizes.bbb[currentBreakpoint].w}
                height={iconSizes.bbb[currentBreakpoint].h}
              />
            </a>
          </Box>
        </Grid>
        <Grid item lg={centerLogos ? 6 : 0}>
          <Box mb={centerLogos ? 2 : 1} mr={1.5}>
            <div
              ref={ref}
              className="trustpilot-widget"
              data-locale={localeOptions.locale}
              data-template-id="53aa8807dec7e10d38f59f32"
              data-businessunit-id="5caf4fc758378f0001217890"
              data-style-height="70px"
              data-style-width="95px"
              data-theme="light"
              data-review-languages={localeOptions.reviewLang}
            >
              <a
                href="https://www.trustpilot.com/review/willful.co"
                target="_blank"
                rel="noreferrer"
              >
                Trustpilot
              </a>
            </div>
          </Box>
        </Grid>
        {shouldIncludeThirtyDay && (
          <Grid item lg={centerLogos ? 6 : 0}>
            <Box display="flex" mb={centerLogos ? 2 : 1}>
              <Typography variant="srOnly">
                {translations.moneyBackGuarantee}
              </Typography>
              <ThirtyDayGuarantee
                isFrench={isFrench}
                width={iconSizes.moneyBackGuarantee[currentBreakpoint].w}
                height={iconSizes.moneyBackGuarantee[currentBreakpoint].h}
              />
            </Box>
          </Grid>
        )}
        {/* Required for Better Business Bureau logo */}
        <div id="gpt-passback" />
      </Grid>
    </Box>
  );
};

export default DesktopTrustIconsForSignUp;
