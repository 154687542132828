export const fetchSpouseBasicInfo = () => ({
  type: FETCH_SPOUSE_BASIC_INFO_TYPE,
});

export const updateSpouse = (formId) => ({
  type: UPDATE_SPOUSE_TYPE,
  payload: { formId },
});

export const openModalSpouse = (spouse = null) => ({
  type: OPEN_MODAL_SPOUSE_TYPE,
  payload: { spouse },
});

export const removeSpouse = (spouse) => ({
  type: REMOVE_SPOUSE_TYPE,
  payload: { spouse },
});

export const spouseNextPage = () => ({
  type: SPOUSE_NEXT_PAGE_TYPE,
});

export const FETCH_SPOUSE_BASIC_INFO_TYPE = "FETCH_SPOUSE_BASIC_INFO";
export const UPDATE_SPOUSE_TYPE = "UPDATE_SPOUSE";
export const OPEN_MODAL_SPOUSE_TYPE = "OPEN_MODAL_SPOUSE";
export const REMOVE_SPOUSE_TYPE = "REMOVE_SPOUSE";
export const SPOUSE_NEXT_PAGE_TYPE = "SPOUSE_NEXT_PAGE";
