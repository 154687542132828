import R from "ramda";
import {
  FIRST_NAME_FIELD_ID,
  MIDDLE_NAME_FIELD_ID,
  LAST_NAME_FIELD_ID,
  SPOUSE_FIRST_NAME_FIELD_ID,
  SPOUSE_MIDDLE_NAME_FIELD_ID,
  SPOUSE_LAST_NAME_FIELD_ID,
} from "../constants/forms";

export const buildFullName = (firstName, middleName, lastName) => {
  if (!firstName || !lastName) {
    return "";
  }

  return middleName
    ? `${firstName} ${middleName} ${lastName}`
    : `${firstName} ${lastName}`;
};

export const getNumberOfLabel = (
  num,
  singular,
  plural = singular,
  translations,
) => {
  const { noBackupSelected } = translations;

  if (num === 0) return noBackupSelected.replace("SINGULAR", singular);
  const noun = num === 1 ? singular : plural;
  return `${num} ${noun}`;
};

export const replaceSpouseName = (text, spouseName) =>
  text.replace("SPOUSE_NAME", spouseName);

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const replaceSpouseSubtextOptions = (optionList, spouseName) =>
  optionList.map((option) => {
    if (!option.subText) return { ...option };
    return {
      ...option,
      subText: replaceSpouseName(option.subText, spouseName),
    };
  });

export const transformNames = (array) => {
  if (!array) return undefined;
  const arrayOfNames = array.map((name) => {
    const userName = buildFullName(
      name[FIRST_NAME_FIELD_ID],
      name[MIDDLE_NAME_FIELD_ID],
      name[LAST_NAME_FIELD_ID],
    );
    if (name[SPOUSE_FIRST_NAME_FIELD_ID]) {
      const spouseName = buildFullName(
        name[SPOUSE_FIRST_NAME_FIELD_ID],
        name[SPOUSE_MIDDLE_NAME_FIELD_ID],
        name[SPOUSE_LAST_NAME_FIELD_ID],
      );
      return `${userName} & ${spouseName}`;
    }
    return userName;
  });
  return R.join(", ", arrayOfNames) || undefined;
};
