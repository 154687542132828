import React from "react";

import SvgIcon from "./SvgIcon";

const FiveStarReviewIcon = ({ width = 120, height = 24 }) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 120 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.526 1.416a.5.5 0 0 1 .948 0l2.164 6.46a.5.5 0 0 0 .47.341l6.812.061a.5.5 0 0 1 .293.902l-5.476 4.054a.5.5 0 0 0-.179.552l2.047 6.498a.5.5 0 0 1-.767.558l-5.548-3.955a.5.5 0 0 0-.58 0l-5.548 3.955a.5.5 0 0 1-.767-.558l2.047-6.498a.5.5 0 0 0-.18-.552L1.788 9.18a.5.5 0 0 1 .293-.902l6.813-.061a.5.5 0 0 0 .47-.341l2.163-6.46ZM35.526 1.416a.5.5 0 0 1 .948 0l2.164 6.46a.5.5 0 0 0 .47.341l6.812.061a.5.5 0 0 1 .293.902l-5.476 4.054a.5.5 0 0 0-.179.552l2.047 6.498a.5.5 0 0 1-.767.558l-5.548-3.955a.5.5 0 0 0-.58 0l-5.548 3.955a.5.5 0 0 1-.767-.558l2.047-6.498a.5.5 0 0 0-.18-.552L25.788 9.18a.5.5 0 0 1 .293-.902l6.813-.061a.5.5 0 0 0 .47-.341l2.163-6.46ZM59.526 1.416a.5.5 0 0 1 .948 0l2.164 6.46a.5.5 0 0 0 .47.341l6.812.061a.5.5 0 0 1 .293.902l-5.476 4.054a.5.5 0 0 0-.179.552l2.047 6.498a.5.5 0 0 1-.767.558l-5.548-3.955a.5.5 0 0 0-.58 0l-5.548 3.955a.5.5 0 0 1-.767-.558l2.047-6.498a.5.5 0 0 0-.18-.552L49.788 9.18a.5.5 0 0 1 .293-.902l6.813-.061a.5.5 0 0 0 .47-.341l2.163-6.46ZM83.526 1.416a.5.5 0 0 1 .948 0l2.164 6.46a.5.5 0 0 0 .47.341l6.812.061a.5.5 0 0 1 .293.902l-5.476 4.054a.5.5 0 0 0-.179.552l2.047 6.498a.5.5 0 0 1-.767.558l-5.548-3.955a.5.5 0 0 0-.58 0l-5.548 3.955a.5.5 0 0 1-.767-.558l2.047-6.498a.5.5 0 0 0-.18-.552L73.788 9.18a.5.5 0 0 1 .293-.902l6.813-.061a.5.5 0 0 0 .47-.341l2.163-6.46Z"
      fill="#E39F3A"
    />
    <path
      d="M107.526 1.416a.5.5 0 0 1 .948 0l2.164 6.46c.067.202.256.34.469.341l6.813.061a.5.5 0 0 1 .293.902l-5.476 4.054a.5.5 0 0 0-.179.552l2.047 6.498a.5.5 0 0 1-.767.558l-5.548-3.955a.5.5 0 0 0-.58 0l-5.548 3.955a.5.5 0 0 1-.767-.558l2.047-6.498a.5.5 0 0 0-.179-.552L97.787 9.18a.5.5 0 0 1 .293-.902l6.813-.061a.499.499 0 0 0 .469-.341l2.164-6.46Z"
      fill="#E39F3A"
    />
  </SvgIcon>
);

export default FiveStarReviewIcon;
