import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";

import { selectTranslations } from "./translations";
import {
  yourArrangementsForms,
  premiumForms,
  hasSpouseFields,
} from "../constants/navigation";
import { selectIsExecutorComplete } from "./executors";
import { selectIsPropertyAttorneysComplete } from "./property-attorneys";
import { selectIsPersonalAttorneysComplete } from "./personal-attorneys";
import {
  selectIsRestingComplete,
  selectIsAshesComplete,
  selectIsCeremonyComplete,
  selectHasCremationResting,
} from "./wishes";
import {
  selectIsLifeProlongComplete,
  selectIsPainManageComplete,
} from "./healthcare";
import {
  RESTING_FIELD_ID,
  RESTING_CREMATION,
  ASHES_FORM_ID,
  EXECUTORS_FORM_ID,
  RESTING_FORM_ID,
  CEREMONY_FORM_ID,
  PROPERTY_ATTORNEYS_FORM_ID,
  PERSONAL_ATTORNEYS_FORM_ID,
  PAIN_MANAGE_FORM_ID,
  LIFE_PROLONG_FORM_ID,
} from "../constants/forms";
import { selectHasUserPaid, selectIsWillOnly } from "./plans";
import { selectHasSpouse } from "./spouse";
import { selectIsYourFamilyComplete } from "./your-family";
import { selectIsAboutYouComplete } from "./about-you";
import {
  selectIsSectionComplete,
  selectSectionCurrentPageNumber,
  selectSectionPercentComplete,
  selectSectionCurrentPath,
  selectSectionTranslations,
  translateInput,
  translateLabel,
} from "./utils/section-summaries";
import { LIFE_PROLONG_PATH, WISHES_CEREMONY_PATH } from "../constants/routes";

const YOUR_ARRANGEMENTS_ESSENTIALS_PLAN_NUMBER_OF_PAGES = 3;
const YOUR_ARRANGEMENTS_NUMBER_OF_PAGES = 7;

export const selectArrangementsTotalPages = createSelector(
  selectIsWillOnly,
  selectHasCremationResting,
  selectHasUserPaid,
  (isWillOnly, hasAshesStep, isUserPaid) => {
    const numOfPages =
      isWillOnly || !isUserPaid
        ? YOUR_ARRANGEMENTS_ESSENTIALS_PLAN_NUMBER_OF_PAGES
        : YOUR_ARRANGEMENTS_NUMBER_OF_PAGES;

    if (hasAshesStep) {
      return numOfPages + 1;
    }
    return numOfPages;
  },
);

const selectArrangementsData = R.pathOr({}, ["yourArrangements"]);

const selectArrangementsEssentialFieldsComplete = createSelector(
  selectHasCremationResting,
  selectIsExecutorComplete,
  selectIsRestingComplete,
  selectIsAshesComplete,
  selectIsCeremonyComplete,
  (
    hasAshesStep,
    isExecutorComplete,
    isRestingComplete,
    isAshesComplete,
    isCeremonyComplete,
  ) => {
    if (hasAshesStep) {
      return [
        isExecutorComplete,
        isRestingComplete,
        isAshesComplete,
        isCeremonyComplete,
      ];
    }
    return [isExecutorComplete, isRestingComplete, isCeremonyComplete];
  },
);

const selectArrangementsFieldsComplete = createSelector(
  selectIsWillOnly,
  selectHasUserPaid,
  selectArrangementsEssentialFieldsComplete,
  selectIsPropertyAttorneysComplete,
  selectIsPersonalAttorneysComplete,
  selectIsLifeProlongComplete,
  selectIsPainManageComplete,
  (isWillOnly, isUserPaid, essentialsPlanFields, ...restValues) => {
    return isWillOnly || !isUserPaid
      ? essentialsPlanFields
      : [...essentialsPlanFields, ...restValues];
  },
);

const selectArrangementsIsCompleteMap = createSelector(
  selectIsExecutorComplete,
  selectIsRestingComplete,
  selectIsAshesComplete,
  selectIsCeremonyComplete,
  selectIsPropertyAttorneysComplete,
  selectIsPersonalAttorneysComplete,
  selectIsLifeProlongComplete,
  selectIsPainManageComplete,
  (
    isExecutorComplete,
    isRestingComplete,
    isAshesComplete,
    isCeremonyComplete,
    isPropertyAttorneysComplete,
    isPersonalAttorneysComplete,
    isLifeProlongComplete,
    isPainManageComplete,
  ) => ({
    [EXECUTORS_FORM_ID]: isExecutorComplete,
    [RESTING_FORM_ID]: isRestingComplete,
    [ASHES_FORM_ID]: isAshesComplete,
    [CEREMONY_FORM_ID]: isCeremonyComplete,
    [PROPERTY_ATTORNEYS_FORM_ID]: isPropertyAttorneysComplete,
    [PERSONAL_ATTORNEYS_FORM_ID]: isPersonalAttorneysComplete,
    [LIFE_PROLONG_FORM_ID]: isLifeProlongComplete,
    [PAIN_MANAGE_FORM_ID]: isPainManageComplete,
  }),
);

export const selectIsArrangementsComplete = selectIsSectionComplete(
  selectArrangementsFieldsComplete,
);

export const selectArrangementsCurrentPageNumber = selectSectionCurrentPageNumber(
  selectArrangementsFieldsComplete,
);

export const selectArrangementsPercentComplete = selectSectionPercentComplete(
  selectArrangementsCurrentPageNumber,
  selectArrangementsTotalPages,
);

export const selectArrangementsNextForm = R.path([
  "navigation",
  "nextForm",
  "yourArrangements",
]);

export const selectArrangementsCurrentPath = selectSectionCurrentPath(
  selectArrangementsNextForm,
);

const selectArrangementsFormSummaries = createSelector(
  selectArrangementsData,
  selectArrangementsIsCompleteMap,
  selectIsWillOnly,
  selectHasSpouse,
  selectTranslations([
    "global",
    "navigation",
    "yourArrangements",
    "appointees",
  ]),
  (
    yourArrangementsData,
    isCompleteMap,
    isWillOnly,
    hasSpouse,
    translations,
  ) => {
    const formSummaries = yourArrangementsForms.reduce(
      (summaryList, currentForm) => {
        // exclude certain form summaries if doesn't meet criteria
        if (isWillOnly && premiumForms.includes(currentForm.id)) {
          return summaryList;
        }

        // exclude ashes summary if cremation not selected
        if (
          currentForm.id === ASHES_FORM_ID &&
          yourArrangementsData[RESTING_FIELD_ID] !== RESTING_CREMATION
        ) {
          return summaryList;
        }

        const fields = currentForm.fields.reduce((currentFormFields, field) => {
          // exclude spouse fields if user has no spouse
          if (!hasSpouse && hasSpouseFields.includes(field.key)) {
            return currentFormFields;
          }

          return [
            ...currentFormFields,
            {
              label: translateLabel(field.key, translations),
              value: translateInput(
                field.key,
                yourArrangementsData,
                translations,
              ),
            },
          ];
        }, []);

        const formSummary = {
          path: currentForm.path,
          isComplete: isCompleteMap[currentForm.id],
          fields,
        };
        return [...summaryList, formSummary];
      },
      [],
    );

    return formSummaries.filter((form) => form.isComplete);
  },
);

const selectYourArrangementsLastPage = createSelector(
  selectIsWillOnly,
  selectHasUserPaid,
  (isWillOnly, isUserPaid) => {
    return isWillOnly || !isUserPaid ? WISHES_CEREMONY_PATH : LIFE_PROLONG_PATH;
  },
);

const selectIsArrangementsDisabled = createSelector(
  selectIsAboutYouComplete,
  selectIsYourFamilyComplete,
  (isAboutYouComplete, isYourFamilyComplete) =>
    !(isAboutYouComplete && isYourFamilyComplete),
);

const selectArrangementsTranslations = createSelector(
  selectSectionTranslations(
    selectIsArrangementsComplete,
    selectArrangementsCurrentPageNumber,
    ["global", "yourArrangements"],
    selectArrangementsTotalPages,
    selectIsArrangementsDisabled,
  ),
  selectIsWillOnly,
  (arrangementsTranslations, isWillOnly) => {
    const description = isWillOnly
      ? arrangementsTranslations.descriptions.willOnly
      : arrangementsTranslations.descriptions.premium;

    return {
      ...arrangementsTranslations,
      description,
    };
  },
);

export const selectArrangementsStatus = createStructuredSelector({
  sectionKey: () => "yourArrangements",
  isComplete: selectIsArrangementsComplete,
  progress: selectArrangementsPercentComplete,
  formSummaries: selectArrangementsFormSummaries,
  disabled: selectIsArrangementsDisabled,
  translations: selectArrangementsTranslations,
  sectionLastPage: selectYourArrangementsLastPage,
});
