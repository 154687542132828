export const isGA4Enabled = () =>
  window.env.ANALYTICS_GA4 === "enabled" && Boolean(window.env.ga4_id);
export const isFbPixelEnabled = () =>
  window.env.ANALYTICS_FACEBOOK === "enabled";
export const isStackAdaptEnabled = () =>
  window.env.ANALYTICS_STACK_ADAPT === "enabled" &&
  !!window.env.STACK_ADAPT_KEY;
export const isQuoraEnabled = () => window.env.ANALYTICS_QUORA === "enabled";
export const isPinterestEnabled = () =>
  window.env.ANALYTICS_PINTEREST === "enabled";
export const isRedditEnabled = () => window.env.ANALYTICS_REDDIT === "enabled";
export const isBingEnabled = () =>
  window.env.ANALYTICS_BING === "enabled" && Boolean(window.env.BING_ID);
export const isTaboolaEnabled = () =>
  window.env.ANALYTICS_TABOOLA === "enabled" && Boolean(window.env.TABOOLA_ID);
export const isPodsightsEnabled = () =>
  window.env.ANALYTICS_PODSIGHTS === "enabled";
export const isVWOEnabled = () => window.env.ANALYTICS_VWO === "enabled";
export const getGA4AttributionParameters = () => {
  const paramsString = window.location.search;
  const searchParams = new URLSearchParams(paramsString);

  const paramsObject = {};
  const excludedParams = ["discount"];
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of searchParams.entries()) {
    if (!excludedParams.includes(pair[0])) {
      // eslint-disable-next-line prefer-destructuring
      paramsObject[pair[0]] = pair[1];
      // If the key starts with 'utm_', add a key without 'utm_' prefix with the same value
      if (pair[0].startsWith("utm_")) {
        const newKey = pair[0].replace("utm_", "");
        // eslint-disable-next-line prefer-destructuring
        paramsObject[newKey] = pair[1];
      }
    }
  }

  return { page_referrer: document.referrer, ...paramsObject };
};
