import React from "react";

import SelectInput from "./SelectInput";

// TODO: look into https://24ways.org/2019/making-a-better-custom-select-element

const DropdownSelect = ({
  input: { value, onChange },
  meta: { touched, error },
  optionList,
  label,
  placeholder,
  ...restProps
}) => {
  const shouldDisplayError = error && touched;
  return (
    <SelectInput
      value={value}
      onChange={onChange}
      touched={touched}
      error={shouldDisplayError ? error : null}
      optionList={optionList}
      label={label}
      placeholder={placeholder || label}
      {...restProps}
    />
  );
};

export default DropdownSelect;
