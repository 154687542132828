import {
  select, takeEvery, call, put, delay,
} from "redux-saga/effects";
import { change } from "redux-form";

import {
  UPDATE_RANGE_POSITION_TYPE,
  UPDATE_SLIDER_VALUE_TYPE,
  DISPLAY_ALLOCATION_HELPER_TYPE,
  setDisplayAllocationHelper,
} from "../actions/allocations-slider";
import { convertBreakpointsToAmounts } from "../utilities/allocations";
import { ALLOCATIONS_FORM_ID, ALLOCATIONS_FIELD_ID } from "../constants/forms";
import { selectFormAllocations } from "../selectors/allocations";

function* updateRangePosition({ payload }) {
  const { breakpointPositions } = payload;
  if (!breakpointPositions) {
    return;
  }
  const amounts = yield call(convertBreakpointsToAmounts, breakpointPositions);
  const allocations = yield select(selectFormAllocations);
  const newAllocations = allocations.map((allocation, index) => ({
    ...allocation,
    amount: amounts[index],
  }));
  yield put(change(ALLOCATIONS_FORM_ID, ALLOCATIONS_FIELD_ID, newAllocations));
}

function* updateSliderValue({ payload }) {
  const { firstValue } = payload;
  if (!firstValue) {
    return;
  }
  const amounts = [firstValue, 100 - firstValue];
  const allocations = yield select(selectFormAllocations);
  const newAllocations = allocations.map((allocation, index) => ({
    ...allocation,
    amount: amounts[index],
  }));
  yield put(change(ALLOCATIONS_FORM_ID, ALLOCATIONS_FIELD_ID, newAllocations));
}

function* displayAllocationHelper({ payload }) {
  if (payload.shouldDisplay) {
    yield delay(5000);
    yield put(setDisplayAllocationHelper(false));
  }
}

export function* watchUpdateRangePosition() {
  yield takeEvery(UPDATE_RANGE_POSITION_TYPE, updateRangePosition);
}

export function* watchUpdateSliderValue() {
  yield takeEvery(UPDATE_SLIDER_VALUE_TYPE, updateSliderValue);
}

export function* watchDisplayAllocationHelper() {
  yield takeEvery(DISPLAY_ALLOCATION_HELPER_TYPE, displayAllocationHelper);
}
