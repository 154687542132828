import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Box from "@material-ui/core/Box";

import { SmallBody } from "../ui/Typography";
import { languageSelectorProps } from "../../selectors/language";
import { validateLanguageCode } from "../../actions/language";
import { colorPalette } from "../../config/Theme";

// TODO: Make a version of this that uses our standard components
const LanguageButton = styled.button`
  border: 1px solid transparent;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 8px;

  &:focus-visible {
    outline: ${colorPalette.white} solid 1px;
  }

  &:hover {
    color: ${colorPalette.willfulxxxLightBlue};
  }
`;

const LanguageText = styled(SmallBody)`
  color: white;
  border-bottom: 1px solid
    ${({ selected }) => (selected ? "#fff" : "transparent")};
  transition: color 240ms ease-out;

  &:hover {
    color: ${colorPalette.willfulxxxLightBlue};
  }
`;

const LanguageSelect = ({
  languageCode,
  provinceCode,
  validateLanguageCode,
  translations,
}) => {
  const handleClick = (lang) => {
    if (lang === languageCode) return;

    validateLanguageCode(lang);
  };

  return (
    <Box mr={1} display="flex" alignItems="center">
      <LanguageButton
        onClick={() => handleClick("en")}
        id="lang_en"
        type="button"
        aria-label={
          languageCode === "en"
            ? translations.languageSelect.selected
            : translations.languageSelect.notSelected
        }
      >
        <LanguageText bold component="span" selected={languageCode === "en"}>
          EN
        </LanguageText>
      </LanguageButton>
      <LanguageButton
        onClick={() => handleClick("fr")}
        id="lang_fr"
        type="button"
        aria-label={
          languageCode === "fr"
            ? translations.languageSelect.selected
            : translations.languageSelect.notSelected
        }
      >
        <LanguageText bold component="span" selected={languageCode === "fr"}>
          FR
        </LanguageText>
      </LanguageButton>
    </Box>
  );
};

export default connect(languageSelectorProps, { validateLanguageCode })(
  LanguageSelect,
);
