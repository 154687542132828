import React from "react";

import SvgIcon from "./SvgIcon";

const VisaIcon = () => {
  return (
    <SvgIcon width="64" height="32" viewBox="0 0 64 40" fill="none">
      <svg>
        <g clipPath="url(#clip0_28194_39150)">
          <path d="M60.6 2H4V38H60.6V2Z" fill="white" />
          <path d="M59.7 32.1001H5V37.0001H59.7V32.1001Z" fill="#F7B600" />
          <path d="M59.7 3H5V7.9H59.7V3Z" fill="#1A1F71" />
          <path
            d="M26.9 13.3001L21.2 26.8001H17.5L14.7 16.0001C14.7 15.5001 14.3 15.0001 13.9 14.8001C12.8 14.3001 11.6 13.9001 10.5 13.7001L10.6 13.3001H16.5C17.3 13.3001 18 13.9001 18.1 14.7001L19.6 22.5001L23.2 13.3001H26.9ZM41.3 22.4001C41.3 18.8001 36.4 18.6001 36.4 17.1001C36.4 16.6001 36.9 16.1001 37.9 16.0001C39.1 15.9001 40.3 16.1001 41.3 16.6001L41.9 13.7001C40.9 13.3001 39.7 13.1001 38.6 13.1001C35.2 13.1001 32.7 14.9001 32.7 17.6001C32.7 19.5001 34.4 20.6001 35.8 21.3001C37.2 22.0001 37.6 22.4001 37.6 23.0001C37.6 23.9001 36.5 24.3001 35.5 24.3001C34.3 24.3001 33 24.0001 31.9 23.5001L31.3 26.5001C32.5 27.0001 33.9 27.2001 35.2 27.2001C38.9 27.0001 41.3 25.2001 41.3 22.4001ZM50.4 26.8001H53.6L50.8 13.3001H47.8C47.1 13.3001 46.6 13.7001 46.3 14.3001L41.1 26.8001H44.8L45.5 24.8001H50L50.4 26.8001ZM46.5 22.0001L48.3 16.9001L49.4 22.0001H46.5ZM31.9 13.3001L29 26.8001H25.5L28.4 13.3001H31.9Z"
            fill="#1A1F71"
          />
        </g>
        <defs>
          <clipPath id="clip0_28194_39150">
            <rect width="64" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

const AmexIcon = () => {
  return (
    <SvgIcon width="64" height="32" viewBox="0 0 64 40" fill="none">
      <g clipPath="url(#clip0_28194_38848)">
        <path
          d="M51.8182 39.3857H32.5455H13V20.0135V1H32.2727H51.8182V20.1928V39.3857Z"
          fill="white"
        />
        <path
          d="M51.8182 39.3857V33.287H47.0909L44.6364 30.6861L42.1818 33.287H26.7273V21H21.7273L27.9091 7.18834H34L36.0909 11.9417V7.18834H43.4545L44.7273 10.7758L46 7.18834H51.7273V1H13V39.3857H51.8182ZM47.9091 31.7623H51.8182L46.6364 26.3812L51.8182 21.0897H48L44.8182 24.4978L41.6364 21.0897H37.7273L42.8182 26.4708L37.7273 31.852H41.5455L44.7273 28.3543L47.9091 31.7623ZM48.8182 26.3812L51.8182 29.5202V23.2422L48.8182 26.3812ZM31.4545 29.2511V27.5471H37.6364V25.1256H31.4545V23.4215H37.7273V20.9103H28.5455V31.6726H37.8182V29.1614H31.4545V29.2511ZM48.9091 19.4753H51.8182V8.713H47.2727L44.8182 15.4395L42.3636 8.713H37.7273V19.4753H40.6364V11.9417L43.4545 19.4753H46.0909L48.9091 11.9417V19.4753ZM34.4545 19.4753H37.8182L33 8.713H29.1818L24.3636 19.4753H27.6364L28.5455 17.3229H33.5455L34.4545 19.4753ZM32.4545 14.9013H29.5455L31 11.4036L32.4545 14.9013Z"
          fill="#006FCF"
        />
        <path
          d="M51.9065 40H32.0935H12V19.8131V0H31.8131H51.9065V20V40Z"
          fill="white"
        />
        <path
          d="M51.9065 40V33.6449H47.0467L44.5234 30.9346L42 33.6449H26.1121V20.8411H20.972L27.3271 6.4486H33.5888L35.7383 11.4019V6.4486H43.3084L44.6168 10.1869L45.9252 6.4486H51.8131V0H12V40H51.9065ZM47.8878 32.0561H51.9065L46.5794 26.4486L51.9065 20.9346H47.9813L44.7103 24.486L41.4393 20.9346H37.4206L42.6542 26.5421L37.4206 32.1495H41.3458L44.6168 28.5047L47.8878 32.0561ZM48.8224 26.4486L51.9065 29.7196V23.1776L48.8224 26.4486ZM30.972 29.4392V27.6635H37.3271V25.1402H30.972V23.3645H37.4206V20.7477H27.9813V31.9626H37.514V29.3458H30.972V29.4392ZM48.9159 19.2523H51.9065V8.03738H47.2336L44.7103 15.0467L42.1869 8.03738H37.4206V19.2523H40.4112V11.4019L43.3084 19.2523H46.0187L48.9159 11.4019V19.2523ZM34.0561 19.2523H37.514L32.5607 8.03738H28.6355L23.6822 19.2523H27.0467L27.9813 17.0093H33.1215L34.0561 19.2523ZM32 14.486H29.0093L30.5047 10.8411L32 14.486Z"
          fill="#006FCF"
        />
      </g>
      <defs>
        <clipPath id="clip0_28194_38848">
          <rect width="64" height="40" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

const MastercardIcon = () => {
  return (
    <SvgIcon width="64" height="32" viewBox="0 0 64 40" fill="none">
      <svg>
        <g clipPath="url(#clip0_28194_39149)">
          <path
            d="M39.3002 6.7998H23.7002V32.2998H39.3002V6.7998Z"
            fill="#FF5F00"
          />
          <path
            d="M25.2998 19.5999C25.2998 14.5999 27.5998 9.89986 31.4998 6.89986C24.4998 1.29986 14.2998 2.49986 8.79978 9.59986C3.29978 16.6999 4.49978 26.7999 11.4998 32.2999C17.3998 36.8999 25.5998 36.8999 31.4998 32.2999C27.5998 29.2999 25.2998 24.5999 25.2998 19.5999Z"
            fill="#EB001B"
          />
          <path
            d="M56.2004 29.6V29.1H56.4004V29H55.9004V29.1H56.1004L56.2004 29.6ZM57.2004 29.6V29H57.0004L56.8004 29.4L56.6004 29H56.5004V29.6H56.6004V29.1L56.8004 29.5H56.9004L57.1004 29.1V29.6H57.2004Z"
            fill="#F79E1B"
          />
          <path
            d="M57.7 19.5998C57.7 28.4998 50.4 35.7998 41.5 35.7998C37.9 35.7998 34.3 34.5998 31.5 32.2998C38.5 26.7998 39.8 16.5998 34.2 9.59978C33.4 8.59978 32.5 7.59978 31.5 6.79978C38.5 1.29978 48.7 2.49978 54.2 9.49978C56.5 12.3998 57.7 15.9998 57.7 19.5998Z"
            fill="#F79E1B"
          />
        </g>
        <defs>
          <clipPath id="clip0_28194_39149">
            <rect width="64" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

const UnionpayIcon = () => {
  return (
    <SvgIcon width="64" height="32" viewBox="0 0 64 40" fill="none">
      <svg>
        <path
          d="M14.3596 1.9999H28.8353C30.8561 1.9999 32.1141 3.64745 31.6426 5.6764L24.902 34.6108C24.4258 36.6337 22.4036 38.2812 20.3821 38.2812H5.90574C3.88424 38.2812 2.62758 36.6337 3.09908 34.6108L9.84306 5.6764C10.3132 3.64745 12.334 1.9999 14.3596 1.9999Z"
          fill="#ED171F"
        />
        <path
          d="M27.6316 1.9999H44.2798C46.3 1.9999 45.3888 3.64745 44.9139 5.6764L38.174 34.6108C37.7032 36.6337 37.8508 38.2812 35.8253 38.2812H19.1784C17.1548 38.2812 15.9016 36.6337 16.3771 34.6108L23.1143 5.6764C23.5906 3.64745 25.6107 1.9999 27.6316 1.9999Z"
          fill="#082F67"
        />
        <path
          d="M43.6216 1.9999H58.0953C60.1175 1.9999 61.3741 3.64745 60.9006 5.6764L54.1613 34.6108C53.6878 36.6337 51.6663 38.2812 49.638 38.2812H35.1684C33.1429 38.2812 31.8882 36.6337 32.3631 34.6108L39.1003 5.6764C39.5745 3.64745 41.5954 1.9999 43.6216 1.9999Z"
          fill="#006A65"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.9897 23.3658L44.2256 29.2033H44.6998L44.3333 30.4105H43.8591L43.7487 30.7852H42.07L42.1838 30.4105H38.7715L39.1143 29.2934H39.4591L41.2489 23.3658L41.6066 22.168H43.3192L43.1403 22.7737C43.1403 22.7737 43.5963 22.4444 44.0292 22.332C44.462 22.2182 46.9442 22.1768 46.9442 22.1768L46.5764 23.3658H45.9897ZM42.9717 23.3658L42.5184 24.8575C42.5184 24.8575 43.0252 24.6292 43.3036 24.5526C43.5841 24.4802 44.0054 24.4517 44.0054 24.4517L44.3333 23.3658H42.9717ZM42.2935 25.5993L41.8247 27.1507C41.8247 27.1507 42.343 26.8851 42.6214 26.8024C42.9019 26.7374 43.326 26.6846 43.326 26.6846L43.6586 25.5993H42.2935ZM41.2015 29.2203H42.5665L42.9608 27.9087H41.5958L41.2015 29.2203Z"
          fill="white"
        />
        <path
          d="M46.7471 22.1769H48.5748L48.5951 22.8584C48.5843 22.9729 48.6832 23.0271 48.8946 23.0271H49.2658L48.9264 24.1516H47.9401C47.0838 24.2146 46.7606 23.8468 46.7796 23.4342L46.7471 22.1769Z"
          fill="white"
        />
        <path
          d="M46.9864 27.513H45.244L45.5434 26.5103H47.5345L47.8197 25.5965H45.8524L46.1904 24.4672H51.6635L51.3207 25.5965H49.4855L49.1983 26.5103H51.0375L50.7347 27.513H48.743L48.3907 27.935H49.1983L49.394 29.1971C49.4151 29.3211 49.4191 29.403 49.4584 29.4572C49.4991 29.504 49.7409 29.5257 49.8811 29.5257H50.1264L49.7538 30.7539H49.1326C49.0364 30.7539 48.8941 30.7457 48.6997 30.7356C48.5113 30.7193 48.3779 30.6102 48.2532 30.5506C48.1394 30.4937 47.9687 30.3555 47.9294 30.1205L47.7343 28.8611L46.8306 30.1035C46.5427 30.4937 46.1538 30.7925 45.4981 30.7925H44.2285L44.5611 29.697H45.0455C45.1857 29.697 45.3097 29.6422 45.4005 29.5948C45.494 29.5555 45.576 29.5087 45.6694 29.3705L46.9864 27.513Z"
          fill="white"
        />
        <path
          d="M27.9029 24.7711H32.5164L32.1756 25.8727H30.3302L30.0437 26.8096H31.931L31.5869 27.947H29.7009L29.2436 29.4733C29.1874 29.6427 29.6894 29.6664 29.8709 29.6664L30.8166 29.5356L30.4379 30.793H28.3094C28.1421 30.793 28.0113 30.7693 27.8257 30.7273C27.6462 30.6825 27.5669 30.6026 27.4877 30.4807C27.4104 30.3553 27.2926 30.2537 27.3725 29.9882L27.9829 27.9667H26.9355L27.2831 26.8096H28.3365L28.6163 25.8727H27.5669L27.9029 24.7711Z"
          fill="white"
        />
        <path
          d="M31.0099 22.793H32.902L32.5559 23.9446H29.9707L29.6896 24.1865C29.5703 24.3037 29.5283 24.2542 29.3719 24.3389C29.2262 24.4134 28.918 24.5591 28.5203 24.5591H27.6904L28.0278 23.4515H28.2764C28.4871 23.4515 28.63 23.4338 28.7025 23.3871C28.7852 23.3356 28.8766 23.2184 28.983 23.0274L29.4572 22.1636H31.3398L31.0099 22.793Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.7149 22.1767H35.3272L35.0928 22.9876C35.0928 22.9876 35.6009 22.5818 35.9579 22.4368C36.3129 22.3034 37.117 22.1842 37.117 22.1842L39.7252 22.1706L38.8364 25.1304C38.6873 25.6385 38.5132 25.9663 38.4042 26.1154C38.3046 26.2685 38.1894 26.3965 37.9611 26.5205C37.7416 26.6377 37.5418 26.7054 37.3575 26.7244C37.1848 26.7373 36.9206 26.7441 36.5534 26.7474H34.0421L33.3328 29.0901C33.2657 29.3211 33.2332 29.4308 33.2772 29.4931C33.3145 29.55 33.3999 29.613 33.5177 29.613L34.6254 29.5087L34.248 30.7925H33.0056C32.6113 30.7925 32.3241 30.7844 32.1222 30.7688C31.9312 30.7539 31.7313 30.7688 31.5992 30.6651C31.482 30.5635 31.3066 30.4307 31.3106 30.2932C31.3235 30.1658 31.375 29.9545 31.4569 29.6659L33.7149 22.1767ZM37.138 25.1676H34.4946L34.3334 25.7015H36.6204C36.8907 25.6676 36.9463 25.7062 36.968 25.6933L37.138 25.1676ZM34.6416 24.686C34.6416 24.686 35.1572 24.2145 36.0412 24.0614C36.2424 24.0221 37.4998 24.0349 37.4998 24.0349L37.6901 23.4022H35.0264L34.6416 24.686Z"
          fill="white"
        />
        <path
          d="M38.4147 26.9555L38.267 27.6668C38.2013 27.8877 38.1464 28.0577 37.9784 28.2C37.8043 28.3483 37.5956 28.5048 37.1113 28.5048L36.2184 28.5407L36.2109 29.3462C36.1987 29.5752 36.2604 29.5508 36.297 29.5881C36.3383 29.6267 36.3742 29.645 36.4155 29.6578L36.6967 29.645L37.5516 29.5948L37.1993 30.7695H36.2184C35.5308 30.7695 35.0213 30.7546 34.8533 30.6205C34.6894 30.5168 34.6677 30.3841 34.6724 30.1598L34.7341 27.0266H36.2983L36.2787 27.6668H36.656C36.7847 27.6668 36.8694 27.6533 36.925 27.6201C36.9703 27.5828 37.0063 27.534 37.0313 27.4548L37.1837 26.9555H38.4147Z"
          fill="white"
        />
        <path
          d="M16.0726 11.244C16.0156 11.496 15.0124 16.1325 15.0083 16.1352C14.7908 17.0721 14.637 17.7441 14.1019 18.1729C13.7984 18.4222 13.4434 18.5448 13.0335 18.5448C12.375 18.5448 11.9882 18.2176 11.9225 17.5951L11.9103 17.3823C11.9103 17.3823 12.1108 16.1243 12.1136 16.1196C12.1136 16.1196 13.167 11.8937 13.356 11.3375C13.3641 11.3063 13.3682 11.2907 13.3723 11.2745C11.3196 11.2928 10.9544 11.2745 10.9307 11.244C10.9192 11.2853 10.8643 11.5482 10.8643 11.5482L9.7872 16.3066L9.69371 16.713L9.51758 18.0327C9.51758 18.4222 9.59481 18.7433 9.74723 19.0136C10.2384 19.8726 11.6387 20 12.4286 20C13.4495 20 14.404 19.7819 15.0503 19.3889C16.1715 18.725 16.4668 17.6858 16.729 16.7672L16.8496 16.2964C16.8496 16.2964 17.9369 11.9086 18.1225 11.3375C18.1279 11.3063 18.1313 11.2907 18.1388 11.2745C16.6518 11.2907 16.2141 11.2745 16.0726 11.244Z"
          fill="white"
        />
        <path
          d="M22.0805 19.9526C21.3515 19.9425 21.0961 19.9425 20.2446 19.9858L20.2114 19.9228C20.2866 19.599 20.3672 19.2799 20.439 18.9514L20.5433 18.509C20.7012 17.8187 20.8556 17.0166 20.8739 16.7747C20.8888 16.6271 20.9376 16.2599 20.5183 16.2599C20.3408 16.2599 20.1565 16.3466 19.9709 16.4313C19.8679 16.7985 19.6599 17.8268 19.5644 18.2976C19.3524 19.2867 19.3388 19.3985 19.2447 19.8883L19.1837 19.9526C18.4324 19.9425 18.1729 19.9425 17.3092 19.9858L17.2686 19.914C17.4135 19.326 17.5571 18.7332 17.6953 18.1466C18.0571 16.5539 18.1425 15.9435 18.2434 15.134L18.3111 15.0859C19.1532 14.968 19.3571 14.945 20.2683 14.7573L20.3455 14.8433L20.208 15.3507C20.3618 15.26 20.5074 15.1672 20.6666 15.0859C21.0961 14.8745 21.5744 14.8122 21.8379 14.8122C22.2356 14.8122 22.6719 14.924 22.8548 15.3853C23.0282 15.7965 22.9137 16.3046 22.6861 17.3031L22.5682 17.8106C22.3345 18.9222 22.2966 19.1255 22.1658 19.8883L22.0805 19.9526Z"
          fill="white"
        />
        <path
          d="M25.0378 19.9526C24.6001 19.9506 24.3183 19.9425 24.0419 19.9506C23.7682 19.9526 23.5006 19.9669 23.0935 19.9858L23.0691 19.9506L23.042 19.914C23.1531 19.4927 23.2161 19.345 23.2703 19.1953C23.3258 19.0469 23.38 18.8965 23.4762 18.469C23.6036 17.9122 23.6822 17.522 23.7384 17.1812C23.7967 16.8499 23.8299 16.5695 23.8712 16.2436L23.9051 16.2179L23.9416 16.1867C24.3772 16.1251 24.6557 16.0844 24.9375 16.0411C25.2234 15.9997 25.5113 15.9435 25.9631 15.8582L25.9808 15.8974L25.993 15.9374C25.9089 16.2822 25.8236 16.6271 25.7416 16.978C25.661 17.3269 25.5756 17.6696 25.4998 18.0172C25.3345 18.7509 25.2701 19.0232 25.2308 19.219C25.1949 19.4059 25.1827 19.5076 25.1177 19.8883L25.0784 19.9228L25.0378 19.9526Z"
          fill="white"
        />
        <path
          d="M31.5777 17.4469C31.8331 16.3271 31.6373 15.8034 31.3874 15.483C31.006 14.9993 30.338 14.8428 29.6423 14.8428C29.2256 14.8428 28.2305 14.8855 27.4521 15.6035C26.8966 16.1191 26.6357 16.8223 26.4793 17.4936C26.3228 18.1772 26.1419 19.4142 27.2766 19.8728C27.6289 20.0205 28.1302 20.0625 28.4567 20.0625C29.2879 20.0625 30.1402 19.8328 30.7783 19.154C31.2763 18.6019 31.5005 17.7822 31.5777 17.4469ZM29.6748 17.3656C29.6348 17.5532 29.4709 18.2564 29.2453 18.5552C29.0895 18.7747 28.9045 18.9068 28.6999 18.9068C28.6423 18.9068 28.2826 18.9068 28.2758 18.3729C28.2718 18.1101 28.3267 17.8364 28.3924 17.5451C28.5841 16.6969 28.8103 15.9856 29.3869 15.9856C29.8407 15.9856 29.8706 16.5147 29.6748 17.3656Z"
          fill="white"
        />
        <path
          d="M49.378 20.0288C48.5 20.0206 48.2473 20.0206 47.4357 20.0552L47.3809 19.9895C47.6031 19.1542 47.8253 18.3216 48.021 17.4775C48.2751 16.3821 48.3333 15.9167 48.4187 15.2758L48.4844 15.2216C49.3556 15.0963 49.5981 15.0617 50.5073 14.8937L50.5357 14.9682C50.3691 15.6552 50.2024 16.3428 50.0439 17.0324C49.7086 18.4774 49.5866 19.2118 49.4579 19.9664L49.378 20.0288Z"
          fill="white"
        />
        <path
          d="M49.7953 17.5458C50.0507 16.4314 49.0237 17.4475 48.8591 17.0797C48.6071 16.5066 48.7649 15.3434 47.7528 14.9552C47.3646 14.8041 46.4501 14.9999 45.6697 15.7133C45.1176 16.2268 44.8547 16.9212 44.6996 17.5918C44.5424 18.2659 44.3622 19.5002 45.4908 19.9433C45.8472 20.0943 46.1744 20.1377 46.4995 20.1248C47.6349 20.0632 48.5007 18.3506 49.1396 17.6711C49.6334 17.1346 49.7201 17.873 49.7953 17.5458ZM48.0583 17.4624C48.0157 17.6413 47.8511 18.3526 47.6255 18.6466C47.4764 18.8573 47.1214 18.9908 46.9182 18.9908C46.8606 18.9908 46.5036 18.9908 46.4907 18.4617C46.488 18.2015 46.5429 17.9279 46.6106 17.6338C46.8024 16.804 47.0286 16.0954 47.6051 16.0954C48.0597 16.0954 48.2535 16.6116 48.0583 17.4624Z"
          fill="white"
        />
        <path
          d="M36.5508 19.9526C35.8206 19.9425 35.5733 19.9425 34.715 19.9858L34.6845 19.9228C34.757 19.599 34.8396 19.2799 34.9155 18.9514L35.0178 18.509C35.1763 17.8187 35.3301 17.0166 35.3477 16.7747C35.3619 16.6271 35.4107 16.2599 34.9948 16.2599C34.8146 16.2599 34.6323 16.3466 34.4453 16.4313C34.3437 16.7985 34.1351 17.8268 34.0348 18.2976C33.8268 19.2867 33.8126 19.3985 33.7184 19.8883L33.6561 19.9526C32.9055 19.9425 32.6481 19.9425 31.7864 19.9858L31.7471 19.914C31.8907 19.326 32.0316 18.7332 32.1698 18.1466C32.5309 16.5539 32.6169 15.9435 32.7172 15.134L32.7849 15.0859C33.627 14.968 33.8316 14.945 34.7427 14.7573L34.8146 14.8433L34.6824 15.3507C34.8322 15.26 34.9846 15.1672 35.1397 15.0859C35.5665 14.8745 36.0489 14.8122 36.307 14.8122C36.706 14.8122 37.1429 14.924 37.3306 15.3853C37.502 15.7965 37.3861 16.3046 37.1565 17.3031L37.042 17.8106C36.8035 18.9222 36.767 19.1255 36.6409 19.8883L36.5508 19.9526Z"
          fill="white"
        />
        <path
          d="M43.6482 13.9561C43.5194 14.5333 43.1448 15.0231 42.6604 15.2595C42.2601 15.4607 41.773 15.4756 41.269 15.4756H40.9445L40.9689 15.3462C40.9689 15.3462 41.5697 12.7373 41.567 12.7455L41.5846 12.612L41.5955 12.509L41.8353 12.5341C41.8353 12.5341 43.0696 12.6411 43.1008 12.6445C43.5892 12.8302 43.7884 13.3206 43.6482 13.9561ZM42.8596 11.2354C42.8562 11.2354 42.2546 11.2436 42.2546 11.2436C40.6809 11.2612 40.0502 11.2537 39.7914 11.2226C39.7643 11.3377 39.7251 11.5423 39.7251 11.5423L39.1628 14.1586C39.1628 14.1586 37.8153 19.7157 37.751 19.9765C39.1242 19.9616 39.6878 19.9616 39.9229 19.9854C39.9764 19.7245 40.2975 18.1725 40.2975 18.1725C40.2975 18.1725 40.5719 17.0324 40.5881 16.9904C40.5881 16.9904 40.6728 16.8738 40.7602 16.8271H40.8855C42.069 16.8271 43.409 16.8271 44.4563 16.0541C45.1683 15.525 45.6595 14.7439 45.8756 13.7962C45.9339 13.5632 45.9745 13.284 45.9745 13.0076C45.9745 12.6445 45.9013 12.2848 45.6886 12.003C45.1548 11.2537 44.0926 11.2436 42.8596 11.2354Z"
          fill="white"
        />
        <path
          d="M56.5715 14.8855L56.5018 14.8076C55.6048 14.9871 55.4409 15.0183 54.6144 15.1273L54.5521 15.1869C54.5494 15.1971 54.5466 15.2134 54.5446 15.2262L54.5405 15.2134C53.9254 16.6353 53.9451 16.3278 53.4431 17.4442C53.4397 17.3914 53.439 17.3582 53.4377 17.3053L53.313 14.8855L53.2338 14.8076C52.2948 14.9871 52.2697 15.0183 51.4006 15.1273L51.3308 15.1869C51.3247 15.2188 51.3227 15.2499 51.3179 15.2838L51.3247 15.2967C51.4331 15.8542 51.408 15.7289 51.5157 16.6035C51.5666 17.033 51.6329 17.4679 51.6838 17.8913C51.7705 18.6026 51.8179 18.9542 51.9209 20.0368C51.3362 21.0082 51.1946 21.3747 50.6357 22.227L50.6655 22.3042C51.5157 22.2724 51.7115 22.2724 52.3402 22.2724L52.4791 22.1172C52.954 21.0936 56.5715 14.8855 56.5715 14.8855Z"
          fill="white"
        />
        <path
          d="M25.838 15.4867C26.3176 15.1514 26.3793 14.688 25.9728 14.4475C25.5609 14.2056 24.8442 14.2815 24.3591 14.6175C23.8707 14.9481 23.8172 15.4129 24.2243 15.6554C24.6321 15.8918 25.3522 15.8227 25.838 15.4867Z"
          fill="white"
        />
        <path
          d="M52.4157 22.2023L51.7085 23.4129C51.4849 23.8262 51.0696 24.1371 50.4098 24.1392L49.2832 24.1209L49.6111 23.0295H49.8292C49.9451 23.0295 50.0297 23.0221 50.0948 22.9902C50.1503 22.9726 50.1917 22.9286 50.2377 22.8635L50.653 22.2023H52.4157Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

const JcbIcon = () => {
  return (
    <SvgIcon width="64" height="32" viewBox="0 0 64 40" fill="none">
      <svg>
        <g clipPath="url(#clip0_28194_39123)">
          <path
            d="M57.9417 31.9417C57.9417 36.4078 54.3495 40 49.8835 40H6V8.05825C6 3.59223 9.59223 0 14.0583 0H57.9417V31.9417Z"
            fill="white"
          />
          <path
            d="M43.6699 23.7866H46.9709C47.068 23.7866 47.2622 23.7866 47.3592 23.7866C48.0389 23.6896 48.5243 23.107 48.5243 22.3303C48.5243 21.5536 47.9418 20.9711 47.3592 20.874C47.2622 20.874 47.068 20.874 46.9709 20.874H43.6699V23.7866Z"
            fill="url(#paint0_linear_28194_39123)"
          />
          <path
            d="M46.5821 2.62158C43.3782 2.62158 40.7568 5.24294 40.7568 8.44682V14.4662H48.9122C49.1063 14.4662 49.3005 14.4662 49.4947 14.4662C51.3394 14.5633 52.6986 15.5342 52.6986 17.1847C52.6986 18.4468 51.7277 19.6119 50.0772 19.806V19.9031C51.9219 20.0002 53.3782 21.0682 53.3782 22.7187C53.3782 24.4662 51.8248 25.6313 49.6889 25.6313H40.7568V37.3789H49.2034C52.4073 37.3789 55.0287 34.7575 55.0287 31.5536V2.62158H46.5821Z"
            fill="url(#paint1_linear_28194_39123)"
          />
          <path
            d="M48.1359 17.5726C48.1359 16.7959 47.5534 16.3105 46.9709 16.2134C46.8738 16.2134 46.7767 16.2134 46.6796 16.2134H43.6699V19.0289H46.6796C46.7767 19.0289 46.9709 19.0289 46.9709 19.0289C47.6505 18.8347 48.1359 18.3493 48.1359 17.5726Z"
            fill="url(#paint2_linear_28194_39123)"
          />
          <path
            d="M14.6407 2.62158C11.4368 2.62158 8.81543 5.24294 8.81543 8.44682V22.7187C10.4659 23.4954 12.1164 23.9808 13.7669 23.9808C15.8057 23.9808 16.8737 22.7187 16.8737 21.1653V14.4662H21.8251V21.1653C21.8251 23.7866 20.1747 25.9226 14.7378 25.9226C11.4368 25.9226 8.81543 25.2429 8.81543 25.2429V37.3789H17.262C20.4659 37.3789 23.0873 34.7575 23.0873 31.5536V2.62158H14.6407Z"
            fill="url(#paint3_linear_28194_39123)"
          />
          <path
            d="M30.5635 2.62158C27.3596 2.62158 24.7383 5.24294 24.7383 8.44682V16.0196C26.1946 14.7575 28.7189 13.9808 32.8936 14.175C35.1266 14.2721 37.4567 14.8546 37.4567 14.8546V17.2818C36.2917 16.6993 34.9325 16.1167 33.0878 16.0196C29.981 15.8255 28.0393 17.2818 28.0393 20.0002C28.0393 22.7187 29.8839 24.2721 33.0878 23.9808C34.9325 23.8837 36.2917 23.3012 37.4567 22.7187V25.1459C37.4567 25.1459 35.1266 25.7284 32.8936 25.8255C28.8159 26.0196 26.2917 25.2429 24.7383 23.9808V37.2818H33.1849C36.3888 37.2818 39.0101 34.6604 39.0101 31.4565V2.62158H30.5635Z"
            fill="url(#paint4_linear_28194_39123)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_28194_39123"
            x1="40.8818"
            y1="22.3605"
            x2="55.1745"
            y2="22.3605"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#007F49" />
            <stop offset="0.2883" stopColor="#209248" />
            <stop offset="0.7632" stopColor="#50AF47" />
            <stop offset="1" stopColor="#62BA46" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_28194_39123"
            x1="40.881"
            y1="20.0303"
            x2="55.1742"
            y2="20.0303"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#007F49" />
            <stop offset="0.2883" stopColor="#209248" />
            <stop offset="0.7632" stopColor="#50AF47" />
            <stop offset="1" stopColor="#62BA46" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_28194_39123"
            x1="40.8817"
            y1="17.6513"
            x2="55.1736"
            y2="17.6513"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#007F49" />
            <stop offset="0.2883" stopColor="#209248" />
            <stop offset="0.7632" stopColor="#50AF47" />
            <stop offset="1" stopColor="#62BA46" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_28194_39123"
            x1="8.89804"
            y1="20.0303"
            x2="23.4118"
            y2="20.0303"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#333377" />
            <stop offset="0.0714325" stopColor="#2F397D" />
            <stop offset="0.6913" stopColor="#0D66AD" />
            <stop offset="1" stopColor="#0077BF" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_28194_39123"
            x1="24.8209"
            y1="19.9818"
            x2="38.9168"
            y2="19.9818"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#743237" />
            <stop offset="0.4006" stopColor="#AA263E" />
            <stop offset="0.8011" stopColor="#DA1C44" />
            <stop offset="1" stopColor="#EC1846" />
          </linearGradient>
          <clipPath id="clip0_28194_39123">
            <rect width="64" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

const UnknownIcon = () => (
  <SvgIcon width="64" height="32" fill="none" viewBox="0 0 64 40">
    <svg
      width="64"
      height="40"
      viewBox="0 0 64 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9997 24.5832C21.9997 23.8928 22.5593 23.3332 23.2497 23.3332H25.7497C26.44 23.3332 26.9997 23.8928 26.9997 24.5832V25.4165C26.9997 26.1069 26.44 26.6665 25.7497 26.6665H23.2497C22.5593 26.6665 21.9997 26.1069 21.9997 25.4165V24.5832Z"
        fill="#0064DC"
      />
      <path
        d="M29.9163 23.3332C29.226 23.3332 28.6663 23.8928 28.6663 24.5832V25.4165C28.6663 26.1069 29.226 26.6665 29.9163 26.6665H32.4163C33.1067 26.6665 33.6663 26.1069 33.6663 25.4165V24.5832C33.6663 23.8928 33.1067 23.3332 32.4163 23.3332H29.9163Z"
        fill="#0064DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6663 6.6665C16.8254 6.6665 15.333 8.15889 15.333 9.99984L15.333 29.9998C15.333 31.8408 16.8254 33.3332 18.6663 33.3332H45.333C47.174 33.3332 48.6663 31.8408 48.6663 29.9998V9.99984C48.6663 8.15889 47.174 6.6665 45.333 6.6665L18.6663 6.6665ZM18.6663 16.6665V9.99984L45.333 9.99984V16.6665H18.6663ZM18.6663 19.9998V29.9998L45.333 29.9998V19.9998H18.6663Z"
        fill="#0064DC"
      />
    </svg>
  </SvgIcon>
);

const RenderIcon = ({ brand }) => {
  switch (brand.toLowerCase()) {
    case "visa":
      return <VisaIcon />;
    case "amex":
      return <AmexIcon />;
    case "mastercard":
      return <MastercardIcon />;
    case "unionpay":
      return <UnionpayIcon />;
    case "jcb":
      return <JcbIcon />;
    default:
      return <UnknownIcon />;
  }
};

const PaymentMethodIcon = ({ brand = "" }) => <RenderIcon brand={brand} />;

export default PaymentMethodIcon;
