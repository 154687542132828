import { postRequest, putRequest, deleteRequest } from "./request";
import {
  CONTACTS_ENDPOINT,
  NOTIFICATIONS_CONTACT_ENDPOINT,
} from "../constants/routes";

export const submitContactEndpoint = (config) =>
  putRequest(CONTACTS_ENDPOINT, config);

export const submitContactNotification = (config) =>
  postRequest(NOTIFICATIONS_CONTACT_ENDPOINT, config);

export const removeContactEndpoint = (config) =>
  deleteRequest(CONTACTS_ENDPOINT, config);
