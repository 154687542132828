import R from "ramda";
import { select, take, takeEvery, call, put } from "redux-saga/effects";
import {
  initialize,
  getFormValues,
  startSubmit,
  stopSubmit,
  change,
} from "redux-form";
import {
  getCheckout,
  submitCheckout,
  submitPromoCode,
  submitValidateCaaMembership,
  removeReferral,
  submitPartnerDiscountCheckout,
  reapplyPartnerDiscount,
  getStripeSessionStatusEndpoint,
  processStripeCheckoutSessionEndpoint,
  createStripeClientSecret,
} from "../api/checkout";

import { transformErrorData } from "../utilities/transformJsonData";
import { selectOrderPromoCode } from "../selectors/utils/discounts";

import {
  CHECKOUT_FORM_ID,
  PROMO_CODE_FORM_ID,
  CAA_FORM_ID,
  REFERRAL_FORM_ID,
  PRODUCTS_FORM_ID,
  BNPL_INTEREST_FIELD_ID,
} from "../constants/forms";
import {
  FETCH_CHECKOUT_TYPE,
  SUBMIT_CHECKOUT_TYPE,
  UPDATE_PROMO_CODE_TYPE,
  REMOVE_REFERRAL_TYPE,
  VALIDATE_CAA_MEMBERSHIP_TYPE,
  SUBMIT_PARTNER_DISCOUNT_CHECKOUT_TYPE,
  APPLY_PARTNER_DISCOUNT_TYPE,
  OPEN_MODAL_BNPL_EXPERIMENT,
  RESET_BNPL_EXPERIMENT_FORM,
  resetBNPLExperimentForm,
  CLOSE_MODAL_BNPL_EXPERIMENT,
  STRIPE_SESSION_STATUS,
  updateStripeClientSecret,
  FETCH_STRIPE_CLIENT_SECRET_TYPE,
} from "../actions/checkout";
import { updateCart, clearCart } from "../actions/cart";
import {
  viewCheckoutAnalytics,
  checkoutPurchaseAnalytics,
  ANALYTICS_ADD_PROMO_COMPLETE_TYPE,
  analyticsInterestedInBNPL,
  hotjarIdentifyAnalytics,
} from "../actions/analytics";
import {
  requestStart,
  fetchSuccess,
  requestError,
  clearLoadedData,
} from "../actions/requests";
import { addPlans } from "../actions/plans";
import { submitFormRequest } from "./forms";
import { fetchApiData, putApiData } from "./requests";
import { selectProvinceCode } from "../selectors/provinces";
import { selectAnalyticsCheckoutData } from "../selectors/checkout";
import {
  selectOrderPlanSymbol,
  selectOrderPricePerPlan,
  selectOrderPlanQuantity,
} from "../selectors/utils/order";
import { capitalize } from "../utilities/name";
import { closeModal, displayModal } from "../actions/modal";
import { closeToast } from "../actions/toast";
import {
  MODAL_BNPL_EXPERIMENT,
  MODAl_KLARNA_PAYMENT_FAILED,
  MODAL_KLARNA_PROCESSING_PAYMENT,
} from "../constants/modal";
import { submitResearchParticipationRequest } from "../api/user";
import { selectUserEmail } from "../selectors/auth";
import { selectIsInvitedUser } from "../selectors/invite";
import { selectHasUserPaid } from "../selectors/plans";

export function* fetchStripeClientSecret() {
  const response = yield call(fetchApiData, {
    apiCall: createStripeClientSecret,
    formId: CHECKOUT_FORM_ID,
  });
  if (response) {
    const { stripeClientSecret, errors } = response;
    if (errors) {
      yield put(updateStripeClientSecret(null));
      throw new Error(`Error retrieving stripe client secret ${errors}`);
    } else {
      yield put(updateStripeClientSecret(stripeClientSecret));
    }
  }
}

function* fetchCheckout({ payload }) {
  const sessionId = R.propOr(null, "sessionId")(payload);
  const response = yield call(submitFormRequest, {
    apiCall: getCheckout,
    formId: CHECKOUT_FORM_ID,
    values: {
      sessionId,
    },
  });
  if (response && response.order) {
    const { paymentForm, promoForm, caaForm, order } = response;
    const checkoutFormDefaults = {
      ...paymentForm,
      [BNPL_INTEREST_FIELD_ID]: true,
    };

    yield put(updateCart(order));
    yield put(initialize(PROMO_CODE_FORM_ID, promoForm));
    yield put(initialize(CAA_FORM_ID, caaForm));
    yield put(initialize(CHECKOUT_FORM_ID, checkoutFormDefaults));
    yield put(viewCheckoutAnalytics());
    yield put(fetchSuccess(CAA_FORM_ID));
    yield put(fetchSuccess(PROMO_CODE_FORM_ID));
    yield put(fetchSuccess(CHECKOUT_FORM_ID));
  }
}

export function* submitStripeCheckout({ payload }) {
  const { stripe, elements } = payload;

  const { cardholderName, addressZip } = yield select(
    getFormValues(CHECKOUT_FORM_ID),
  );

  const cardNumberElement = elements.getElement("cardNumber");

  try {
    yield put(startSubmit(CHECKOUT_FORM_ID));
    yield put(requestStart({ type: CHECKOUT_FORM_ID }));

    const { token, error } = yield call(stripe.createToken, cardNumberElement, {
      name: cardholderName,
      address_zip: addressZip,
    });

    if (error && error.length) {
      const stripeError = new Error("Stripe submit error");
      stripeError.formErrors = [
        {
          field: "_error",
          code: error.code,
          detail: error.message,
        },
      ];
      throw stripeError;
    }

    // We send this "null" mock value to the BE because Address Zip is not
    // necessary for Stripe, but is validated on the BE, which expects a String
    const addressZipValue = "null";

    const promoCode = yield select(selectOrderPromoCode("cart"));
    const { transactionId, userId, errors } = yield call(submitFormRequest, {
      apiCall: submitCheckout,
      formId: CHECKOUT_FORM_ID,
      values: {
        stripeToken: token.id,
        addressZip: addressZipValue,
        cardholderName,
        promoCode,
      },
    });
    // if request to rails BE fails
    if (errors) {
      throw new Error("Rails Stripe submit error");
    }
    yield call(updateAnalyticsAfterCheckout, transactionId, userId);
    yield call(clearCartAfterCheckout);
  } catch (error) {
    yield put(
      requestError({
        type: CHECKOUT_FORM_ID,
        meta: {
          error,
        },
      }),
    );

    // only handle errors here if Stripe.createToken fails,
    // submitFormRequest handles errors for rails BE request
    if (error.formErrors) {
      const errorObject = transformErrorData(error.formErrors, {
        cardholderName,
        addressZip,
      });
      yield put(stopSubmit(CHECKOUT_FORM_ID, errorObject));
    }
  }
}

function* updateAnalyticsAfterCheckout(transactionId, userId) {
  const email = yield select(selectUserEmail);
  const isInvitedUser = yield select(selectIsInvitedUser);
  const isPaidUser = yield select(selectHasUserPaid);
  const isSessionPurchaser = true;
  yield put(
    hotjarIdentifyAnalytics({
      email,
      userId,
      isInvitedUser,
      isPaidUser,
      isSessionPurchaser,
    }),
  );
  const checkoutAnalyticsData = yield select(selectAnalyticsCheckoutData);
  yield put(
    checkoutPurchaseAnalytics({
      transactionId,
      userId,
      ...checkoutAnalyticsData,
    }),
  );
}

function* clearCartAfterCheckout() {
  // TODO: should we not receive a new cart from BE response and add to state?
  yield put(clearCart());
  // clear product data for plans page when upgrading
  yield put(addPlans([]));
  yield put(clearLoadedData(PRODUCTS_FORM_ID));
}

function* deleteReferral() {
  const { order } = yield call(submitFormRequest, {
    apiCall: removeReferral,
    formId: REFERRAL_FORM_ID,
  });
  yield put(updateCart(order));
}

export function* updatePromoCode() {
  // Extract promoCode before submitting to remove whitespace
  const { promoCode } = yield select(getFormValues(PROMO_CODE_FORM_ID));
  // Removes any whitespace that may be present
  const promoCodeTrimmed = promoCode.trim();

  const { order, freePromoCode, transactionId, userId, errors } = yield call(
    submitFormRequest,
    {
      apiCall: submitPromoCode,
      formId: PROMO_CODE_FORM_ID,
      values: {
        promoCode: promoCodeTrimmed,
      },
    },
  );

  if (freePromoCode) {
    // We store the analytics values before the cart is clean
    const provinceCode = yield select(selectProvinceCode);
    const planSymbol = yield select(selectOrderPlanSymbol("cart"));
    const planPrice = yield select(selectOrderPricePerPlan("cart"));
    const quantity = yield select(selectOrderPlanQuantity("cart"));

    // TODO: should we not receive a new cart from BE response and add to state?
    yield put(clearCart());
    // clear product data for plans page when upgrading
    yield put(addPlans([]));
    yield put(clearLoadedData(PRODUCTS_FORM_ID));

    yield take(ANALYTICS_ADD_PROMO_COMPLETE_TYPE);
    yield put(
      checkoutPurchaseAnalytics({
        transactionId: `${userId}-${transactionId}`,
        userId,
        revenue: 0,
        quantity,
        coupon: promoCodeTrimmed,
        planName: capitalize(planSymbol),
        planPrice,
        provinceCode,
        totalTax: 0,
      }),
    );
    return yield;
  }

  yield put(updateCart(order));

  // if no errors were received, refresh the checkout values
  // on redux form and update BNPL form with the new price
  if (!errors) {
    yield call(fetchStripeClientSecret);
    yield call(fetchCheckout, {});
  }

  return yield;
}

function* validateCaaMembership() {
  const { order, errors } = yield call(submitFormRequest, {
    apiCall: submitValidateCaaMembership,
    formId: CAA_FORM_ID,
  });
  // TODO: revisit why CAA doesn't need to be updated?
  yield put(updateCart(order));
  // if no errors were received, refresh the checkout values
  // on redux form and update BNPL form with the new price
  if (!errors) {
    yield call(fetchStripeClientSecret);
    yield call(fetchCheckout, {});
  }
}

export function* submitPartnerDiscountCheckoutConfirmation() {
  yield call(submitFormRequest, {
    apiCall: submitPartnerDiscountCheckout,
    formId: "PARTNER_DISCOUNT_CHECKOUT",
  });

  yield put(clearCart());

  // clear product data for plans page when upgrading
  yield put(addPlans([]));

  return yield put(clearLoadedData(PRODUCTS_FORM_ID));
}

export function* applyPartnerDiscount() {
  const { order, errors } = yield call(putApiData, {
    apiCall: reapplyPartnerDiscount,
    formId: "APPLY_PARTNER_DISCOUNT",
  });

  yield put(updateCart(order));

  // if no errors were received, refresh the checkout values
  // on redux form and update BNPL form with the new price
  if (!errors) {
    yield call(fetchStripeClientSecret);
    yield call(fetchCheckout, {});
  }
}

function* handleOpenModalBNPLExperiment() {
  yield put(closeToast());
  yield put(closeModal());
  yield put(displayModal(MODAL_BNPL_EXPERIMENT));
  yield put(analyticsInterestedInBNPL());
}

export function* handleResetBNPLExperimentForm({ payload }) {
  const { key } = payload;
  yield put(change(CHECKOUT_FORM_ID, BNPL_INTEREST_FIELD_ID, true));
  yield put(closeModal(key));
}

export function* handleCloseModalBNPLExperiment() {
  yield call(submitFormRequest, {
    apiCall: submitResearchParticipationRequest,
    values: {
      researchParticipationData: {
        BNPLResearchParticipant: false,
      },
    },
  });
  yield put(resetBNPLExperimentForm());
  yield put(closeModal(MODAL_BNPL_EXPERIMENT));
}

export function* handleBNPLExperiment() {
  yield call(submitFormRequest, {
    apiCall: submitResearchParticipationRequest,
    values: {
      researchParticipationData: {
        BNPLResearchParticipant: true,
      },
    },
  });
  yield put(resetBNPLExperimentForm());
  yield put(closeModal(MODAL_BNPL_EXPERIMENT));
}

function* handleGetStripeSessionStatus({ payload }) {
  const { sessionId } = payload;
  const { sessionStatus } = yield call(submitFormRequest, {
    apiCall: getStripeSessionStatusEndpoint,
    values: {
      sessionId,
    },
  });
  yield put(closeModal());

  try {
    switch (sessionStatus) {
      case "open": {
        yield put(displayModal(MODAl_KLARNA_PAYMENT_FAILED));
        break;
      }
      case "complete":
        yield call(processStripeCheckoutSession, sessionId);
        break;
      default:
        break;
    }
  } catch (error) {
    yield put(
      requestError({
        type: CHECKOUT_FORM_ID,
        meta: {
          error,
        },
      }),
    );
  }
}

function* processStripeCheckoutSession(sessionId) {
  yield put(displayModal(MODAL_KLARNA_PROCESSING_PAYMENT));
  yield put(startSubmit(CHECKOUT_FORM_ID));
  yield put(requestStart({ type: CHECKOUT_FORM_ID }));
  try {
    const { transactionId, userId, errors } = yield call(submitFormRequest, {
      apiCall: processStripeCheckoutSessionEndpoint,
      values: {
        sessionId,
      },
    });
    // if request to rails BE fails
    if (errors) {
      yield put(closeModal());
      yield put(displayModal(MODAl_KLARNA_PAYMENT_FAILED));
      throw new Error("Error when processing stripe checkout session");
    }
    yield call(updateAnalyticsAfterCheckout, transactionId, userId);
    yield call(clearCartAfterCheckout);
    return yield put(closeModal());
  } catch (error) {
    return yield put(
      requestError({
        type: CHECKOUT_FORM_ID,
        meta: {
          error,
        },
      }),
    );
  }
}

export function* watchRemoveReferral() {
  yield takeEvery(REMOVE_REFERRAL_TYPE, deleteReferral);
}

export function* watchFetchCheckout() {
  yield takeEvery(FETCH_CHECKOUT_TYPE, fetchCheckout);
}

export function* watchFetchStripeClientSecret() {
  yield takeEvery(FETCH_STRIPE_CLIENT_SECRET_TYPE, fetchStripeClientSecret);
}

export function* watchSubmitCheckout() {
  yield takeEvery(SUBMIT_CHECKOUT_TYPE, submitStripeCheckout);
}

export function* watchSubmitPartnerDiscountCheckout() {
  yield takeEvery(
    SUBMIT_PARTNER_DISCOUNT_CHECKOUT_TYPE,
    submitPartnerDiscountCheckoutConfirmation,
  );
}

export function* watchApplyPartnerDiscount() {
  yield takeEvery(APPLY_PARTNER_DISCOUNT_TYPE, applyPartnerDiscount);
}

export function* watchUpdatePromoCode() {
  yield takeEvery(UPDATE_PROMO_CODE_TYPE, updatePromoCode);
}

export function* watchValidateCaaMembership() {
  yield takeEvery(VALIDATE_CAA_MEMBERSHIP_TYPE, validateCaaMembership);
}

export function* watchOpenModalBNPLExperiment() {
  yield takeEvery(OPEN_MODAL_BNPL_EXPERIMENT, handleOpenModalBNPLExperiment);
}

export function* watchCloseModalBNPLExperiment() {
  yield takeEvery(CLOSE_MODAL_BNPL_EXPERIMENT, handleCloseModalBNPLExperiment);
}

export function* watchResetBNPLExperimentForm() {
  yield takeEvery(RESET_BNPL_EXPERIMENT_FORM, handleResetBNPLExperimentForm);
}

export function* watchGetStripeSessionStatus() {
  yield takeEvery(STRIPE_SESSION_STATUS, handleGetStripeSessionStatus);
}
