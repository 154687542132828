import { combineReducers } from "redux";
import { DISPLAY_MODAL_TYPE, CLOSE_MODAL_TYPE } from "../actions/modal";

const visible = (state = false, { type }) => {
  switch (type) {
    case DISPLAY_MODAL_TYPE:
      return true;
    case CLOSE_MODAL_TYPE:
      return false;
    default:
      return state;
  }
};

const key = (state = null, { type, payload }) => {
  switch (type) {
    case DISPLAY_MODAL_TYPE:
      return payload.key;
    case CLOSE_MODAL_TYPE:
      return null;
    default:
      return state;
  }
};

const prompt = (state = null, { type, payload }) => {
  switch (type) {
    case DISPLAY_MODAL_TYPE:
      return payload.prompt;
    case CLOSE_MODAL_TYPE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  visible,
  key,
  prompt,
});
