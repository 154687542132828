import { UPDATE_EXPERIMENTS_TYPE } from "../actions/experiments";

const experiments = (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_EXPERIMENTS_TYPE:
      return { ...state, [payload.experimentId]: payload.variationId };
    default:
      return state;
  }
};

export default experiments;
