import React, { useEffect } from "react";

import { connect } from "react-redux";
import { Box, Grid, Hidden, useMediaQuery } from "@material-ui/core";
import { withTheme, useTheme } from "@material-ui/styles";

import { AssetListPageProps } from "../../selectors/asset-list";
import {
  fetchAssetList,
  togglePhysicalAssetCard,
  toggleFinancialAssetCard,
  toggleInsuranceAssetCard,
  editAsset,
  removeAsset,
} from "../../actions/asset-list";
import { displayChat } from "../../actions/customer-service";
import { displayModal } from "../../actions/modal";
import { PageLayout } from "../../components/ui/PageLayout";
import {
  H1,
  SectionTitle,
  LargeBody,
  SmallExternalLink,
} from "../../components/ui/Typography";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import AssetListIcon from "../../components/ui/icons/AssetListIcon";
import AssetListCard from "../../components/ui/AssetListCard";
import {
  MODAL_ADD_FINANCIAL_ASSET,
  MODAL_ADD_INSURANCE_ASSET,
  MODAL_ADD_PHYSICAL_ASSET,
  MODAL_OUT_OF_PROVINCE_WARNING_ASSET,
} from "../../constants/modal";
import {
  FINANCIAL_ASSETS_FORM_ID,
  INSURANCE_ASSETS_FORM_ID,
  PHYSICAL_ASSETS_FORM_ID,
} from "../../constants/forms";
import { DOCUMENTS_ASSETS_PATH } from "../../constants/routes";
import { ButtonLink } from "../../components/ui/Button";
import { displayTooltipModal } from "../../actions/tooltip-modal";
import {
  analyticsClickExternalLink,
  analyticsOpenedChat,
} from "../../actions/analytics";

const AssetList = ({
  assetList,
  editAsset,
  cardExpandedMap,
  hasPropertyAssets,
  displayModal,
  displayTooltipModal,
  togglePhysicalAssetCard,
  toggleFinancialAssetCard,
  toggleInsuranceAssetCard,
  isDesktop,
  translations,
}) => {
  const { physicalAssets, financialAssets, insuranceAssets } = assetList;
  return (
    <Box mt={isDesktop ? 4.5 : 1.5} mb={4.5}>
      <AssetListCard
        translations={translations}
        isDesktop={isDesktop}
        isCardExpanded={cardExpandedMap.physicalAssets}
        setExpanded={(expanded) => togglePhysicalAssetCard(expanded)}
        assets={physicalAssets}
        handleEditAsset={(id) => editAsset(id, PHYSICAL_ASSETS_FORM_ID)}
        handleAddAsset={() => displayModal(MODAL_ADD_PHYSICAL_ASSET)}
        hasPropertyAssets={hasPropertyAssets}
        assetKey={PHYSICAL_ASSETS_FORM_ID}
        onDisplayWarningModal={() =>
          displayTooltipModal(MODAL_OUT_OF_PROVINCE_WARNING_ASSET)
        }
      />
      <AssetListCard
        translations={translations}
        isDesktop={isDesktop}
        isCardExpanded={cardExpandedMap.financialAssets}
        setExpanded={(expanded) => toggleFinancialAssetCard(expanded)}
        assets={financialAssets}
        handleEditAsset={(id) => editAsset(id, FINANCIAL_ASSETS_FORM_ID)}
        handleAddAsset={() => displayModal(MODAL_ADD_FINANCIAL_ASSET)}
        assetKey={FINANCIAL_ASSETS_FORM_ID}
      />
      <AssetListCard
        translations={translations}
        isDesktop={isDesktop}
        isCardExpanded={cardExpandedMap.insuranceAssets}
        setExpanded={(expanded) => toggleInsuranceAssetCard(expanded)}
        handleEditAsset={(id) => editAsset(id, INSURANCE_ASSETS_FORM_ID)}
        handleAddAsset={() => displayModal(MODAL_ADD_INSURANCE_ASSET)}
        assets={insuranceAssets}
        assetKey={INSURANCE_ASSETS_FORM_ID}
      />
    </Box>
  );
};

const AssetListPage = ({
  translations,
  displayModal,
  togglePhysicalAssetCard,
  toggleFinancialAssetCard,
  toggleInsuranceAssetCard,
  displayTooltipModal,
  fetchAssetList,
  editAsset,
  removeAsset,
  assetList,
  cardExpandedMap,
  hasLoaded,
  hasAsset,
  hasPropertyAssets,
  analyticsClickExternalLink,
}) => {
  useEffect(() => {
    fetchAssetList();
  }, [fetchAssetList, hasLoaded]);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const articlesTranslations = translations.assetHelpfulArticlesCard;

  if (!hasLoaded) {
    return <LoadingSpinner />;
  }

  return (
    <PageLayout>
      <Grid container justify={isDesktop ? "space-between" : "center"}>
        <Grid item xl={6} lg={6} md={8} xs={12}>
          <Box>
            <H1 align="left" data-hj-suppress>
              {translations.title}
            </H1>
            <LargeBody align="left">{translations.intro}</LargeBody>
          </Box>
          <AssetsDocumentPageButton
            hasAsset={hasAsset}
            translations={translations}
          />
          <Hidden lgUp>
            <Box
              mb={2}
              display="flex"
              alignItems="flex-end"
              flexDirection="column"
            >
              <AssetListIcon isDesktop={isDesktop} />
            </Box>
          </Hidden>
          <AssetList
            assetList={assetList}
            hasPropertyAssets={hasPropertyAssets}
            editAsset={editAsset}
            removeAsset={removeAsset}
            cardExpandedMap={cardExpandedMap}
            togglePhysicalAssetCard={togglePhysicalAssetCard}
            toggleFinancialAssetCard={toggleFinancialAssetCard}
            toggleInsuranceAssetCard={toggleInsuranceAssetCard}
            isDesktop={isDesktop}
            displayModal={displayModal}
            displayTooltipModal={displayTooltipModal}
            translations={translations}
          />
        </Grid>
        <Grid item xl={2} lg={1} md={0} xs={0} />
        <Grid item xl={4} lg={5} md={8} xs={12}>
          <Hidden mdDown>
            <Box
              mb={3}
              display="flex"
              alignItems="flex-end"
              flexDirection="column"
            >
              <AssetListIcon isDesktop={isDesktop} />
            </Box>
          </Hidden>
          <Box mb={4}>
            <Box mb={2}>
              <SectionTitle component="h3" align="left">
                {articlesTranslations.title}
              </SectionTitle>
            </Box>
            <Box display="flex" height={1} ml="auto" mb={1}>
              <SmallExternalLink
                onClick={() =>
                  analyticsClickExternalLink(
                    articlesTranslations.digitalAssetsUrl,
                  )
                }
                href={articlesTranslations.digitalAssetsUrl}
                text={articlesTranslations.digitalAssetsText}
              />
            </Box>

            <Box display="flex" height={1} ml="auto" mb={1}>
              <SmallExternalLink
                onClick={() =>
                  analyticsClickExternalLink(
                    articlesTranslations.specificGiftsUrl,
                  )
                }
                href={articlesTranslations.specificGiftsUrl}
                text={articlesTranslations.specificGiftsText}
              />
            </Box>

            <Box display="flex" height={1} ml="auto" mb={1}>
              <SmallExternalLink
                onClick={() =>
                  analyticsClickExternalLink(
                    articlesTranslations.howAssetHelpsUrl,
                  )
                }
                href={articlesTranslations.howAssetHelpsUrl}
                text={articlesTranslations.howAssetHelpsText}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

const AssetsDocumentPageButton = ({ hasAsset, translations }) => {
  if (!hasAsset) return null;
  return (
    <Box mt={2}>
      <ButtonLink
        to={DOCUMENTS_ASSETS_PATH}
        displayArrowRight
        text={translations.button.documentsPage}
      />
    </Box>
  );
};

export default connect(AssetListPageProps, {
  fetchAssetList,
  editAsset,
  removeAsset,
  displayModal,
  displayChat,
  togglePhysicalAssetCard,
  toggleFinancialAssetCard,
  toggleInsuranceAssetCard,
  displayTooltipModal,
  analyticsClickExternalLink,
  analyticsOpenedChat,
})(withTheme(AssetListPage));
