import { takeEvery, call } from "redux-saga/effects";
import { NAVIGATION_SKIP_SECTION_TYPE } from "../actions/navigation";
import { submitFormRequest } from "./forms";

function* skipSection({ payload, meta }) {
  const { formId } = meta;
  const { apiCall } = payload;
  yield call(submitFormRequest, { apiCall, formId });
}

export function* watchSkipSection() {
  yield takeEvery(NAVIGATION_SKIP_SECTION_TYPE, skipSection);
}
