import React from "react";
import { reduxForm, FieldArray, getFormValues } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@material-ui/core";
import { Form } from "../../ui/Form";
import {
  SPOUSE_FORM_ID,
  SPOUSE_BASIC_INFO_FIELD_ID,
} from "../../../constants/forms";
import { SecondaryButton } from "../../ui/Button";
import { orderedFillColors } from "../../../utilities/allocations";
import PeopleCard from "../../ui/PeopleCard";
import { openModalSpouse, removeSpouse } from "../../../actions/spouse";
import { ErrorMessage } from "../../ui/Error";

export const SpouseCardFieldArray = ({
  fields,
  translations,
  handleChange,
}) => {
  const dispatch = useDispatch();
  const allFields = fields.getAll() || [];
  const canAddSpouse =
    allFields.filter((field) => field._destroy !== true).length === 0;
  let colorIndex = 0;
  return (
    <Box>
      {fields.map((fieldId, index) => {
        const field = fields.get(index);
        if (field && field._destroy === true) {
          return null;
        }
        const color = orderedFillColors[colorIndex % orderedFillColors.length];
        colorIndex += 1;
        const spouseName = `${field.firstName} ${field.middleName || ""} ${
          field.lastName
        }`;
        return (
          <Box mb={1} mt={2} key={`${index + 1}-${fieldId}`}>
            <PeopleCard
              id={fieldId}
              fieldId={fieldId}
              translations={translations}
              text={spouseName}
              color={color}
              onEdit={() => dispatch(openModalSpouse({ ...field, index }))}
              onRemove={() => {
                dispatch(removeSpouse(field));
                return handleChange(`${fieldId}._destroy`, true);
              }}
              {...field}
            />
          </Box>
        );
      })}
      {canAddSpouse && (
        <SecondaryButton
          text={translations.button.addLabel}
          fullWidth
          displayPlusIcon
          className="qa-add"
          type="button"
          onClick={() => dispatch(openModalSpouse(null))}
        />
      )}
    </Box>
  );
};

const shouldDisableSubmitButton = (formData) => {
  if (!formData) {
    return true;
  }
  return (
    formData[SPOUSE_BASIC_INFO_FIELD_ID].filter(
      (field) => field._destroy !== true,
    ).length === 0
  );
};

const SpouseForm = ({
  error,
  change,
  handleSubmit,
  backLink,
  isLoading,
  translations,
}) => {
  const formData = useSelector(getFormValues(SPOUSE_FORM_ID));
  const disableSubmit = shouldDisableSubmitButton(formData);
  return (
    <Form
      isLoading={isLoading}
      onSubmit={handleSubmit}
      backLink={backLink}
      translations={translations}
      disabled={disableSubmit}
    >
      <FieldArray
        rerenderOnEveryChange
        handleChange={change}
        name={SPOUSE_BASIC_INFO_FIELD_ID}
        component={SpouseCardFieldArray}
        translations={translations}
      />
      <Box mb={2}>
        <ErrorMessage error={error} />
      </Box>
    </Form>
  );
};

export default reduxForm({
  form: SPOUSE_FORM_ID,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(SpouseForm);
