import React from "react";
import SvgIcon from "./SvgIcon";

const EmbarkLogo = ({ isDesktop = true }) => {
  return (
    <SvgIcon
      width={isDesktop ? "156" : "132"}
      height={isDesktop ? "48" : "22"}
      viewBox={isDesktop ? "0 0 148 26" : "0 0 132 26"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_39854_486)">
        <path
          d="M87.0617 14.4397C86.83 12.1894 85.8703 10.2369 84.348 8.8801C83.1236 7.78803 81.5351 7.12617 79.715 6.89451C76.6043 6.4974 73.5928 7.39091 71.4749 9.27721L74.0561 12.1563C75.3136 11.0311 77.2661 10.4686 79.2186 10.7333C80.2445 10.8657 81.138 11.2297 81.7668 11.7923C82.6272 12.5534 83.0905 13.6786 83.1898 14.8368C83.1567 14.8368 83.1567 14.8037 83.1236 14.8037C81.4028 13.9102 79.1855 13.5462 77.1669 13.844C74.718 14.2081 72.7986 15.3994 71.7727 17.2857C70.9123 18.8411 70.8792 20.5288 71.6734 21.9849C72.8648 24.136 75.4791 25.228 77.564 25.3273C77.6963 25.3273 77.8618 25.3273 77.9942 25.3273C80.3107 25.3273 82.5279 24.3345 84.2157 22.5806C84.3811 23.3417 84.5135 24.2022 84.6459 25.0957L88.4847 24.6324C88.2199 22.4813 87.7897 20.1979 86.6976 18.2454C87.0948 17.0541 87.194 15.7634 87.0617 14.4397ZM77.7956 21.4554C76.6705 21.3893 75.4129 20.8267 75.082 20.0986C75.0489 20.0324 74.8503 19.7015 75.1813 19.1389C75.7769 18.08 77.1668 17.5836 78.6229 17.5836C79.5826 17.5836 80.5754 17.7821 81.3697 18.2123C81.7006 18.3778 81.9984 18.6094 82.2963 18.8411C81.2042 20.595 79.5495 21.5547 77.7956 21.4554Z"
          fill="white"
        />
        <path
          d="M19.0878 15.3662C19.9814 13.7446 20.0145 11.9907 19.154 10.4684C18.1282 8.61521 15.8447 7.25839 13.462 7.12602C10.1196 6.92746 7.0089 8.51593 5.25497 11.3288C4.32836 10.2037 4.03053 9.27707 4.03053 9.21088L2.17732 9.74037L0.291016 10.2368C0.390295 10.5346 1.11834 13.0828 3.83197 15.3662C3.73269 16.1604 3.73269 16.9546 3.79888 17.7158C4.03053 19.9661 4.99022 21.9186 6.5125 23.2754C7.73694 24.3675 9.32541 25.0293 11.1455 25.261C11.5426 25.3272 11.9728 25.3272 12.37 25.3272C14.9181 25.3272 17.4332 24.3675 19.3195 22.6797L16.7382 19.8006C15.3483 21.0251 13.4289 21.6539 11.6088 21.4222C10.5829 21.2898 9.68943 20.9258 9.06066 20.3632C8.23334 19.6352 7.80313 18.6093 7.67076 17.5172C9.15994 18.0136 10.6491 18.2453 11.8074 18.3115C16.308 18.6093 18.2274 16.8554 19.0878 15.3662ZM13.2635 10.9648C14.2894 11.031 15.3814 11.5936 15.7455 12.2885C15.8778 12.5533 15.944 13.0497 15.6793 13.4468C14.9843 14.4396 12.9325 14.4727 12.039 14.4065C10.9801 14.3403 9.62325 14.1417 8.36571 13.6123C9.3585 11.8914 11.2117 10.8655 13.2635 10.9648Z"
          fill="white"
        />
        <path
          d="M95.4675 12.5532L95.4344 7.52304L91.5625 7.55614L91.728 24.93H95.5999C95.6329 23.2753 95.5006 19.4365 97.2214 16.7229C99.5048 13.0827 101.921 11.5604 104.601 11.064L103.873 7.2583C100.2 7.95325 97.9164 9.37625 95.4675 12.5532Z"
          fill="white"
        />
        <path
          d="M123.495 20.727C122.933 21.1572 120.98 22.3817 118.829 20.1644C116.976 18.245 115.553 16.2594 114.593 14.7041C114.726 14.5386 114.891 14.3731 115.024 14.2077C117.406 11.825 120.914 10.9646 120.947 10.9315L120.054 7.15886C119.855 7.19196 115.454 8.28403 112.277 11.461C112.045 11.6926 111.814 11.9243 111.615 12.1559L111.648 0.606445H107.776L107.743 23.4406C107.71 24.268 107.743 24.7974 107.743 24.8636L111.615 24.8967V23.6061C111.648 22.4478 111.847 20.5284 112.476 18.5429C113.435 19.8997 114.627 21.3889 116.05 22.845C117.837 24.6651 119.723 25.2938 121.411 25.2938C123.363 25.2938 125.018 24.4665 125.944 23.7054L123.495 20.727Z"
          fill="white"
        />
        <path
          d="M40.8977 7.05957C38.7135 7.05957 36.761 7.98617 35.4042 9.50845C34.0474 8.01927 32.0949 7.05957 29.9108 7.05957C25.8072 7.05957 22.4648 10.402 22.4648 14.5055V24.7974H26.3367V14.5055C26.3367 12.553 27.9252 10.9315 29.9108 10.9315C31.8964 10.9315 33.4848 12.5199 33.4848 14.5055V24.7974H37.3567V14.5055C37.3567 12.553 38.9452 10.9315 40.9307 10.9315C42.8832 10.9315 44.5048 12.5199 44.5048 14.5055V24.7974H48.3767V14.5055C48.3436 10.402 45.0012 7.05957 40.8977 7.05957Z"
          fill="white"
        />
        <path
          d="M60.0908 6.92743C58.4362 6.82815 56.8477 7.15908 55.4247 7.85404V0.672852H51.5859V11.4281C51.5859 11.4281 51.5859 11.4281 51.5859 11.4612V17.5172C51.5859 19.6352 52.4133 21.6207 53.9024 23.143C55.3916 24.6322 57.4103 25.4595 59.5283 25.4595C62.0764 25.4595 64.5253 24.4005 66.2792 22.5473C68.0332 20.661 68.9267 18.2122 68.7612 15.6309C68.4634 10.9317 64.757 7.22527 60.0908 6.92743ZM63.4332 19.8668C62.4074 20.9589 61.0174 21.5545 59.4952 21.5545C58.4031 21.5545 57.3772 21.1243 56.6161 20.3632C55.8549 19.6021 55.4247 18.5762 55.4247 17.4841V12.6525C56.5168 11.395 58.1384 10.7 59.8261 10.7993C62.4735 10.9648 64.6908 13.182 64.8562 15.8295C64.9886 17.3186 64.4591 18.7747 63.4332 19.8668Z"
          fill="white"
        />
        <path
          d="M127.6 0.672852V4.54473H126.805V2.06276L125.78 4.41236H125.283L124.224 2.09585V4.51164H123.496V0.672852H124.257L125.548 3.41957L126.739 0.672852H127.6Z"
          fill="white"
        />
        <path
          d="M130.644 0.672899C130.875 0.739085 131.074 0.838365 131.206 0.937644L130.809 1.56641C130.677 1.50022 130.544 1.43404 130.412 1.36785C130.247 1.33476 130.114 1.30167 129.949 1.30167C129.75 1.30167 129.552 1.33476 129.386 1.43404C129.221 1.53332 129.088 1.66569 128.989 1.86425C128.89 2.06281 128.857 2.29446 128.857 2.59229C128.857 2.98941 128.956 3.32034 129.154 3.5189C129.353 3.71746 129.618 3.84983 129.949 3.84983C130.114 3.84983 130.28 3.81674 130.445 3.75055C130.611 3.68436 130.743 3.61818 130.875 3.48581L131.272 4.08148C131.107 4.24695 130.908 4.34622 130.677 4.4455C130.445 4.54478 130.18 4.57788 129.883 4.57788C129.518 4.57788 129.188 4.51169 128.923 4.34622C128.625 4.18076 128.426 3.94911 128.261 3.65127C128.095 3.35343 128.029 2.98941 128.029 2.5592C128.029 2.12899 128.095 1.76497 128.261 1.46713C128.426 1.16929 128.658 0.937644 128.923 0.772178C129.221 0.606713 129.552 0.540527 129.916 0.540527C130.18 0.57362 130.412 0.606713 130.644 0.672899Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_39854_486">
          <rect
            width="130.982"
            height="25.7143"
            fill="white"
            transform="translate(0.291016 0.143066)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default EmbarkLogo;
