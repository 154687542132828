import React from "react";
import { reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";

import PasswordField from "../ui/inputs/PasswordField";
import PasswordConfirmationField from "../ui/inputs/PasswordConfirmationField";
import { PrimaryButton } from "../ui/Button";
import { PageLayout } from "../ui/PageLayout";
import { H1 } from "../ui/Typography";
import { ErrorMessage } from "../ui/Error";
import {
  PASSWORD_FIELD_ID,
  RESET_PASSWORD_FORM_ID,
} from "../../constants/forms";

import { useValidateNewPasswordMemo } from "../ui/inputs/validations";

const ResetPasswordForm = ({
  error,
  handleSubmit,
  isLoading,
  translations,
}) => {
  return (
    <PageLayout>
      <form onSubmit={handleSubmit}>
        <Box
          maxWidth={500}
          mx="auto"
          py={2}
          px={2}
          bgcolor="willfulWhite.main"
          borderRadius={0.2}
          border={1}
          borderColor="border.main"
        >
          <H1>{translations.pageTitle}</H1>
          <Box mb={2}>
            <PasswordField
              name={PASSWORD_FIELD_ID}
              validation={useValidateNewPasswordMemo(translations)}
              translations={translations}
              showPasswordStrength
            />
          </Box>

          <PasswordConfirmationField translations={translations} />

          <Box mt={2}>
            <PrimaryButton
              fullWidth
              displayArrowRight
              isLoading={isLoading}
              loadingLabel={translations.button.loadingLabel}
              text={translations.button.submit}
            />
          </Box>
          {error && (
            <Box display="flex" justifyContent="center" my={0.5}>
              <ErrorMessage error={error} align="center" />
            </Box>
          )}
        </Box>
      </form>
    </PageLayout>
  );
};

export default reduxForm({ form: RESET_PASSWORD_FORM_ID })(ResetPasswordForm);
