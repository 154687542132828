import React from "react";
import Box from "@material-ui/core/Box";
import SvgIcon from "./SvgIcon";

const KlarnaIconLarge = ({ height = 45 }) => {
  return (
    <SvgIcon
      width="45"
      height={height}
      viewBox="0 0 45 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_38896_26452)">
        <path
          d="M40.679 0H4.321C1.93458 0 0 1.93458 0 4.321V20.679C0 23.0654 1.93458 25 4.321 25H40.679C43.0654 25 45 23.0654 45 20.679V4.321C45 1.93458 43.0654 0 40.679 0Z"
          fill="#FFB3C7"
        />
        <path
          d="M40.7936 14.6463C40.2049 14.6463 39.7276 15.1279 39.7276 15.722C39.7276 16.316 40.2049 16.7977 40.7936 16.7977C41.3824 16.7977 41.8597 16.316 41.8597 15.722C41.8597 15.1278 41.3824 14.6463 40.7936 14.6463ZM37.2863 13.8146C37.2863 13.0012 36.5973 12.342 35.7474 12.342C34.8975 12.342 34.2084 13.0013 34.2084 13.8146C34.2084 14.628 34.8974 15.2874 35.7474 15.2874C36.5974 15.2874 37.2863 14.628 37.2863 13.8146ZM37.292 10.9524H38.9904V16.6766H37.292V16.3108C36.8122 16.641 36.233 16.8351 35.6083 16.8351C33.9552 16.8351 32.615 15.4828 32.615 13.8146C32.615 12.1464 33.9552 10.7942 35.6083 10.7942C36.233 10.7942 36.8122 10.9883 37.292 11.3186V10.9524ZM23.7 11.698V10.9525H21.9611V16.6766H23.7039V14.0041C23.7039 13.1024 24.6724 12.6178 25.3444 12.6178C25.3513 12.6178 25.3577 12.6185 25.3646 12.6186V10.953C24.6749 10.953 24.0405 11.2508 23.7 11.698ZM19.3684 13.8146C19.3684 13.0013 18.6794 12.342 17.8294 12.342C16.9795 12.342 16.2904 13.0013 16.2904 13.8146C16.2904 14.628 16.9795 15.2874 17.8294 15.2874C18.6794 15.2874 19.3684 14.628 19.3684 13.8146ZM19.374 10.9524H21.0725V16.6766H19.374V16.3108C18.8942 16.641 18.315 16.8351 17.6904 16.8351C16.0372 16.8351 14.697 15.4828 14.697 13.8146C14.697 12.1464 16.0372 10.7942 17.6904 10.7942C18.3151 10.7942 18.8942 10.9883 19.374 11.3186V10.9524ZM29.597 10.7985C28.9186 10.7985 28.2764 11.011 27.8471 11.5974V10.9528H26.156V16.6767H27.8679V13.6686C27.8679 12.7981 28.4464 12.3719 29.1429 12.3719C29.8894 12.3719 30.3185 12.8218 30.3185 13.6568V16.6767H32.0149V13.0366C32.0149 11.7045 30.9653 10.7985 29.597 10.7985ZM12.223 16.6767H14.0007V8.40159H12.223V16.6767ZM4.41394 16.6789H6.29634V8.40004H4.41394V16.6789ZM10.9979 8.40004C10.9979 10.1923 10.3058 11.8596 9.07234 13.0989L11.6743 16.6792H9.34934L6.52154 12.7882L7.25135 12.2367C8.46165 11.3219 9.15585 9.92352 9.15585 8.40002L10.9979 8.40004Z"
          fill="#0A0B09"
        />
      </g>
      <defs>
        <clipPath id="clip0_38896_26452">
          <rect width="45" height="25" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

const KlarnaIconSmall = ({ height = 16 }) => {
  return (
    <SvgIcon
      width="16"
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2.5C0 1.83696 0.263392 1.20107 0.732233 0.732233C1.20107 0.263392 1.83696 0 2.5 0L13.5 0C14.163 0 14.7989 0.263392 15.2678 0.732233C15.7366 1.20107 16 1.83696 16 2.5V13.5C16 14.163 15.7366 14.7989 15.2678 15.2678C14.7989 15.7366 14.163 16 13.5 16H2.5C1.83696 16 1.20107 15.7366 0.732233 15.2678C0.263392 14.7989 0 14.163 0 13.5L0 2.5Z"
        fill="#FFB3C7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.815 12H4V4H5.815V12ZM8.49 8.54C9.08304 7.94262 9.55242 7.23413 9.87124 6.45508C10.1901 5.67604 10.3521 4.84175 10.348 4H8.572C8.572 5.472 7.902 6.823 6.736 7.707L6.032 8.24L8.758 12H11L8.491 8.54H8.49ZM12.5 10C12.2348 10 11.9804 10.1054 11.7929 10.2929C11.6054 10.4804 11.5 10.7348 11.5 11C11.5 11.2652 11.6054 11.5196 11.7929 11.7071C11.9804 11.8946 12.2348 12 12.5 12C12.7652 12 13.0196 11.8946 13.2071 11.7071C13.3946 11.5196 13.5 11.2652 13.5 11C13.5 10.7348 13.3946 10.4804 13.2071 10.2929C13.0196 10.1054 12.7652 10 12.5 10Z"
        fill="#0A0B09"
      />
    </SvgIcon>
  );
};

const KlarnaIcon = ({
  height,
  marginLeft = 0,
  marginRight = 0,
  top = 0,
  small = false,
}) => {
  return (
    <Box
      marginLeft={marginLeft}
      marginRight={marginRight}
      style={{ position: "relative", top: `${top}px` }}
    >
      {small ? (
        <KlarnaIconSmall height={height} />
      ) : (
        <KlarnaIconLarge height={height} />
      )}
    </Box>
  );
};

export default KlarnaIcon;
