import React from "react";

import { reduxForm } from "redux-form";
import { useMediaQuery, useTheme } from "@material-ui/core";

import { Form } from "../../ui/Form";

import BinaryRadioField from "../../ui/inputs/BinaryRadioField";
import { useValidateCeremonyMemo } from "../../ui/inputs/validations";
import { CEREMONY_FORM_ID, CEREMONY_FIELD_ID } from "../../../constants/forms";

const CeremonyForm = ({
  handleSubmit,
  backLink,
  onSkip,
  isLoading,
  translations,
}) => {
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Form
      isLoading={isLoading}
      onSubmit={handleSubmit}
      backLink={backLink}
      onSkip={onSkip}
      translations={translations}
      reverseWrap={isTabletDown}
    >
      <BinaryRadioField
        name={CEREMONY_FIELD_ID}
        validate={useValidateCeremonyMemo(translations)}
        translations={translations}
      />
    </Form>
  );
};

export default reduxForm({
  form: CEREMONY_FORM_ID,
})(CeremonyForm);
