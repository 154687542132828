import { SPA_SAFE_LINK_CLASS } from "../constants/navigation";

export const observeDOMChanges = (history) => {
  const handleLinkClick = (event) => {
    event.preventDefault();
    // Get the closes anchor tag with an href attribute. This is to avoid
    // errors when clicking on a child element of the anchor tag
    const closestAnchor =
      event.target.closest("a[spa-href]") || event.target.closest("a[href]");
    const newRoute =
      closestAnchor.getAttribute("spa-href") ||
      closestAnchor.getAttribute("href");
    history.push(newRoute);
  };

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.addedNodes.length) {
        const spaLinks = document.querySelectorAll(`a.${SPA_SAFE_LINK_CLASS}`);
        spaLinks.forEach((link) => {
          // Remove old event listeners to avoid duplicates
          link.removeEventListener("click", handleLinkClick);
          // Add a new event listener
          link.addEventListener("click", handleLinkClick);
        });
      }
    });
  });

  observer.observe(document.body, {
    childList: true, // Observe direct children
    subtree: true, // Observe all descendants
  });

  return observer;
};
