import React from "react";
import SvgIcon from "./SvgIcon";

const CheckmarkGreen = ({ isMobile = false }) => {
  return (
    <SvgIcon width={isMobile ? "20px" : "24px"} viewBox="0 0 24 24">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-786.000000, -395.000000)">
          <g transform="translate(786.000000, 395.000000)">
            <g>
              <circle
                fill="#008400"
                transform="translate(12.000000, 12.000000) rotate(270.000000) translate(-12.000000, -12.000000) "
                cx="12"
                cy="12"
                r="12"
              />
              <polyline
                id="Path"
                stroke="#FFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="8 12.1556806 10.9682759 15.1140432 16.3793945 9"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default CheckmarkGreen;
