import { combineReducers } from "redux";
import { NOTIFY_APPOINTEE_TYPE } from "../actions/appointees";

const lastAppointeeFirstName = (state = null, { type, payload }) => {
  switch (type) {
    case NOTIFY_APPOINTEE_TYPE:
      return payload.firstName;
    default:
      return state;
  }
};

const lastAppointeeLastName = (state = null, { type, payload }) => {
  switch (type) {
    case NOTIFY_APPOINTEE_TYPE:
      return payload.lastName;
    default:
      return state;
  }
};

const lastAppointeeEmail = (state = null, { type, payload }) => {
  switch (type) {
    case NOTIFY_APPOINTEE_TYPE:
      return payload.email;
    default:
      return state;
  }
};

export default combineReducers({
  lastAppointeeFirstName,
  lastAppointeeLastName,
  lastAppointeeEmail,
});
