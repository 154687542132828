import React from "react";
import SvgIcon from "./SvgIcon";

const SunlifeLogo = ({ height = 32, isFrench }) => {
  return isFrench ? (
    <SvgIcon height={height} viewBox="0 0 189 34" fill="none">
      <path
        d="M5.66343 17.8445V25.1676H1.66943V5.13599H9.83805C14.616 5.13599 17.1651 7.62762 17.1651 11.5025C17.1651 15.3529 14.616 17.8404 9.86678 17.8404H5.66343V17.8445ZM5.66343 8.81391V14.1954H9.76827C11.9438 14.1954 13.0726 13.3087 13.0726 11.5067C13.0726 9.71697 11.9438 8.81801 9.76827 8.81801H5.66343V8.81391Z"
        fill="white"
      />
      <path
        d="M28.3467 11.1564L27.7885 14.863C27.1728 14.5922 26.2861 14.4198 25.5268 14.4198C23.7945 14.4198 22.6082 15.4787 22.6082 17.5394V25.0923H18.8728V11.2262H22.4932V12.745C23.3676 11.4437 24.6976 10.8568 26.4011 10.8568C27.2015 10.8568 27.8336 10.9717 28.3467 11.1564Z"
        fill="white"
      />
      <path
        d="M56.1406 15.093C55.0405 14.5512 53.2795 13.9764 51.7197 13.9642C50.3035 13.9642 49.5893 14.4649 49.5893 15.2244C49.5893 16.0248 50.5909 16.2259 51.8511 16.4107L53.0825 16.5953C56.0872 17.0551 57.762 18.3974 57.762 20.7453C57.762 23.6064 55.414 25.4536 51.3953 25.4536C49.5072 25.4536 47.032 25.0964 45.2258 23.824L46.6995 20.9916C47.8858 21.7797 49.3307 22.3503 51.4201 22.3503C53.1522 22.3503 53.9814 21.8618 53.9814 21.0491C53.9814 20.3759 53.2795 20.0065 51.6787 19.7766L50.5498 19.6206C47.3439 19.1773 45.7553 17.7735 45.7553 15.4131C45.7553 12.5644 47.9597 10.8773 51.6088 10.8773C53.8254 10.8773 55.5577 11.2919 57.4048 12.1949L56.1406 15.093Z"
        fill="white"
      />
      <path
        d="M74.5507 18.1552C74.5507 22.3174 71.661 25.4535 67.8393 25.4535C65.9798 25.4535 64.6621 24.7229 63.7591 23.6515V30.8227H60.0402V11.2262H63.6893V12.7573C64.5924 11.6285 65.9223 10.8527 67.8229 10.8527C71.661 10.8568 74.5507 13.9887 74.5507 18.1552ZM63.5744 18.1552C63.5744 20.3431 65.0194 21.9767 67.1374 21.9767C69.3417 21.9767 70.7127 20.2732 70.7127 18.1552C70.7127 16.0371 69.3376 14.3336 67.1374 14.3336C65.0194 14.3336 63.5744 15.9632 63.5744 18.1552Z"
        fill="white"
      />
      <path
        d="M86.4054 11.1564L85.8472 14.863C85.2315 14.5922 84.3448 14.4198 83.5855 14.4198C81.8532 14.4198 80.6669 15.4787 80.6669 17.5394V25.0923H76.9315V11.2262H80.552V12.745C81.4264 11.4437 82.7563 10.8568 84.4598 10.8568C85.2603 10.8568 85.8924 10.9717 86.4054 11.1564Z"
        fill="white"
      />
      <path
        d="M36.5031 14.0749C37.2666 14.0749 37.9849 14.2801 38.6006 14.6372L40.2959 11.7023C39.1794 11.0578 37.8864 10.6843 36.5031 10.6843C35.1197 10.6843 33.8268 11.0578 32.7102 11.7023L34.4055 14.6372C35.0212 14.2801 35.7354 14.0749 36.5031 14.0749Z"
        fill="#FEDD00"
      />
      <path
        d="M32.3079 18.2742C32.3079 16.7225 33.1494 15.368 34.4013 14.6455L32.706 11.7106C30.4401 13.0241 28.9172 15.4706 28.9172 18.2783V18.2823L32.3079 18.2742C32.3079 18.2783 32.3079 18.2783 32.3079 18.2742Z"
        fill="#00A8E5"
      />
      <path
        d="M40.6941 18.2741C40.6941 18.2783 40.6941 18.2783 40.6941 18.2741L44.0846 18.2783V18.2741C44.0846 15.4664 42.5577 13.02 40.296 11.7064L38.6006 14.6414C39.8526 15.3721 40.6941 16.7225 40.6941 18.2741Z"
        fill="#FAA61F"
      />
      <path
        d="M36.5031 22.4692C35.7396 22.4692 35.0253 22.264 34.4096 21.9069L32.7144 24.8418C33.8308 25.4863 35.1238 25.8557 36.5031 25.8557C37.8823 25.8557 39.1795 25.4863 40.2918 24.8418L38.5965 21.9069C37.9808 22.264 37.2666 22.4692 36.5031 22.4692Z"
        fill="#58B947"
      />
      <path
        d="M44.0846 18.2783H40.694C40.694 19.8299 39.8485 21.1845 38.5964 21.9069L40.2918 24.8419C42.5577 23.5324 44.0846 21.086 44.0846 18.2783Z"
        fill="#0B713A"
      />
      <path
        d="M32.3079 18.2783H28.9172C28.9172 21.086 30.4443 23.5324 32.7101 24.8419L34.4054 21.9069C33.1535 21.1845 32.3079 19.8299 32.3079 18.2783Z"
        fill="#00548B"
      />
      <path
        d="M93.1784 15.4829C93.2892 15.4254 93.4206 15.409 93.515 15.4624C93.7572 15.6306 94.0241 15.9878 94.1718 16.3818C94.9024 15.799 95.9 15.3105 97.0451 15.3105C99.5245 15.3105 100.99 16.7389 100.99 20.1952C100.99 23.2 99.5984 25.0594 96.6717 25.0594C95.8261 25.0594 95.0379 24.8542 94.3812 24.5135V29.3778C94.3812 29.4885 94.3072 29.5665 94.1923 29.5665H93.3836C93.2729 29.5665 93.1949 29.4926 93.1949 29.3778V17.8842C93.1949 17.096 93.0635 16.5337 92.6489 16.0247C92.575 15.9508 92.575 15.8564 92.6858 15.799L93.1784 15.4829ZM94.3812 23.4462C95.0215 23.8197 95.7727 24.0866 96.5978 24.0866C98.7569 24.0866 99.7913 22.81 99.7913 20.2157C99.7913 17.3054 98.646 16.3079 96.766 16.3079C95.8096 16.3079 94.9804 16.6855 94.3812 17.1535V23.4462Z"
        fill="white"
      />
      <path
        d="M102.73 16.4393C102.673 16.3449 102.71 16.234 102.825 16.1766C103.822 15.6307 104.947 15.3146 106.339 15.3146C109.043 15.3146 109.757 16.4804 109.757 19.0336V22.4899C109.757 23.278 109.889 23.8403 110.304 24.3493C110.378 24.4232 110.378 24.5176 110.266 24.5751L109.778 24.8952C109.668 24.9527 109.536 24.9691 109.442 24.9157C109.179 24.7475 108.917 24.3904 108.764 23.9963C108.087 24.5956 107.016 25.0676 105.628 25.0676C103.88 25.0676 102.414 24.259 102.414 22.3256C102.414 20.5236 103.749 19.5836 106.416 19.5836C107.094 19.5836 107.899 19.6574 108.576 19.7354V18.9105C108.576 17.0304 107.993 16.2998 106.248 16.2998C105.083 16.2998 104.089 16.6199 103.186 17.1248C103.075 17.1823 102.997 17.1453 102.944 17.051L102.73 16.4393ZM108.572 23.0522V20.6673C107.763 20.5565 107.069 20.499 106.486 20.499C104.421 20.499 103.555 21.1763 103.555 22.2845C103.555 23.5242 104.533 24.1072 105.866 24.1072C106.9 24.103 107.915 23.6926 108.572 23.0522Z"
        fill="white"
      />
      <path
        d="M112.689 17.9417C112.689 17.1535 112.557 16.5912 112.143 16.0822C112.068 16.0083 112.068 15.9138 112.18 15.8564L112.668 15.5362C112.779 15.4787 112.91 15.4624 113.005 15.5362C113.305 15.7251 113.604 16.1931 113.756 16.7184C114.224 16.0042 115.053 15.3105 116.388 15.3105C116.818 15.3105 117.212 15.3844 117.458 15.4419C117.569 15.4787 117.627 15.5732 117.611 15.684L117.443 16.4352C117.422 16.546 117.331 16.6035 117.216 16.5665C116.953 16.4927 116.634 16.4352 116.26 16.4352C115.188 16.4352 114.379 16.8868 113.875 17.6953V24.6818C113.875 24.7927 113.801 24.8707 113.686 24.8707H112.877C112.767 24.8707 112.689 24.7968 112.689 24.6818V17.9417Z"
        fill="white"
      />
      <path
        d="M120.492 23.241C120.529 23.1301 120.623 23.0891 120.718 23.1301C121.9 23.6761 122.955 23.9388 124.4 23.9388C126.859 23.9388 128.082 22.9618 128.082 21.2912C128.082 17.4778 120.664 19.9776 120.664 14.978C120.664 12.5191 122.901 11.4847 125.398 11.4847C126.826 11.4847 128.082 11.7679 128.927 12.084C129.022 12.121 129.079 12.2359 129.039 12.3467L128.812 13.1348C128.776 13.2457 128.681 13.2868 128.586 13.2457C127.725 12.8886 126.633 12.6054 125.414 12.6054C123.385 12.6054 121.92 13.3934 121.92 14.9163C121.92 18.6928 129.358 16.2874 129.358 21.2091C129.358 23.5201 127.725 25.0594 124.211 25.0594C122.746 25.0594 121.49 24.8541 120.398 24.3451C120.303 24.3083 120.246 24.1933 120.286 24.0824L120.492 23.241Z"
        fill="white"
      />
      <path
        d="M138.697 22.4898C138.697 23.278 138.829 23.8403 139.243 24.3493C139.317 24.4232 139.317 24.5177 139.206 24.575L138.717 24.8953C138.606 24.9528 138.475 24.9691 138.381 24.9158C138.06 24.7105 137.782 24.2754 137.65 23.787C136.673 24.4437 135.434 25.0635 133.988 25.0635C131.546 25.0635 130.775 23.5981 130.775 20.6878V15.6881C130.775 15.5773 130.848 15.4993 130.963 15.4993H131.772C131.882 15.4993 131.961 15.5732 131.961 15.6881V20.6468C131.961 23.0891 132.56 24.0086 134.251 24.0086C135.528 24.0086 136.657 23.4832 137.519 22.8429V15.6881C137.519 15.5773 137.592 15.4993 137.708 15.4993H138.517C138.627 15.4993 138.705 15.5732 138.705 15.6881V22.4898H138.697Z"
        fill="white"
      />
      <path
        d="M141.345 15.5403C141.455 15.4829 141.587 15.4664 141.681 15.5198C141.964 15.7251 142.243 16.1191 142.396 16.5912C143.372 15.9344 144.591 15.3146 146.02 15.3146C148.462 15.3146 149.287 16.78 149.287 19.6903V24.6859C149.287 24.7967 149.213 24.8747 149.099 24.8747H148.29C148.179 24.8747 148.102 24.8009 148.102 24.6859V19.7273C148.102 17.2849 147.444 16.3654 145.753 16.3654C144.55 16.3654 143.385 16.8334 142.539 17.4163V24.6859C142.539 24.7967 142.465 24.8747 142.35 24.8747H141.542C141.43 24.8747 141.353 24.8009 141.353 24.6859V17.9417C141.353 17.1536 141.221 16.5912 140.807 16.0822C140.733 16.0083 140.733 15.9139 140.844 15.8564L141.345 15.5403Z"
        fill="white"
      />
      <path
        d="M153.741 11.8583C153.741 11.7476 153.815 11.6696 153.93 11.6696H154.792C154.903 11.6696 154.981 11.7434 154.981 11.8583V23.787H161.647C161.757 23.787 161.836 23.8608 161.836 23.9758V24.69C161.836 24.8009 161.761 24.8789 161.647 24.8789H153.926C153.815 24.8789 153.737 24.805 153.737 24.69V11.8583H153.741Z"
        fill="white"
      />
      <path
        d="M164.188 10.5242C164.602 10.5242 164.918 10.8444 164.918 11.2549C164.918 11.6694 164.598 11.9855 164.188 11.9855C163.774 11.9855 163.457 11.6654 163.457 11.2549C163.453 10.8444 163.774 10.5242 164.188 10.5242ZM163.584 15.6881C163.584 15.5773 163.658 15.4993 163.774 15.4993H164.582C164.693 15.4993 164.771 15.5732 164.771 15.6881V24.6859C164.771 24.7967 164.697 24.8747 164.582 24.8747H163.774C163.662 24.8747 163.584 24.8009 163.584 24.6859V15.6881Z"
        fill="white"
      />
      <path
        d="M166.273 15.6881C166.273 15.5773 166.347 15.4993 166.462 15.4993H167.759V14.3705C167.759 12.1744 168.268 10.3313 170.932 10.3313C171.572 10.3313 172.192 10.4421 172.734 10.6145C172.828 10.6515 172.886 10.7459 172.845 10.8567L172.677 11.5134C172.639 11.6243 172.546 11.6818 172.451 11.6448C171.963 11.4766 171.437 11.3616 170.912 11.3616C169.54 11.3616 168.942 12.1128 168.942 14.2719V15.4911H171.626C171.737 15.4911 171.814 15.5649 171.814 15.6799V16.2423C171.814 16.3531 171.741 16.4311 171.626 16.4311H168.942V24.6776C168.942 24.7885 168.867 24.8665 168.752 24.8665H167.943C167.833 24.8665 167.755 24.7926 167.755 24.6776V16.4393H166.458C166.347 16.4393 166.269 16.3654 166.269 16.2505V15.6881H166.273Z"
        fill="white"
      />
      <path
        d="M179.852 23.9183C179.889 24.0127 179.872 24.144 179.778 24.2015C178.953 24.7639 177.561 25.0636 176.38 25.0636C173.28 25.0636 171.983 22.847 171.983 20.2158C171.983 17.5107 173.411 15.3146 176.342 15.3146C179.216 15.3146 180.267 17.1741 180.267 19.6903V20.2158C180.246 20.3266 180.172 20.4045 180.061 20.4045H173.186C173.297 22.6581 174.368 24.0292 176.531 24.0292C177.508 24.0292 178.501 23.8034 179.347 23.2575C179.442 23.1836 179.535 23.2205 179.572 23.315L179.852 23.9183ZM173.206 19.4646H179.142C179.142 17.8103 178.485 16.3285 176.326 16.3285C174.745 16.3285 173.391 17.1741 173.206 19.4646Z"
        fill="white"
      />
      <path
        d="M184.298 14.272C184.265 14.2761 184.244 14.2556 184.24 14.2227C184.178 13.4674 184.109 12.626 184.039 11.8584C184.018 11.6573 184.002 11.4273 183.989 11.1852H183.956C183.867 11.4314 183.768 11.6531 183.677 11.8501C183.362 12.5562 183.042 13.3114 182.746 14.0175C182.733 14.0421 182.705 14.0668 182.672 14.0668H182.541C182.508 14.0668 182.479 14.0463 182.467 14.0175C182.208 13.3894 181.875 12.6301 181.535 11.8501C181.449 11.6654 181.354 11.4233 181.272 11.1975H181.24C181.235 11.4233 181.202 11.6613 181.186 11.8584C181.112 12.6013 181.043 13.451 180.985 14.2269C180.981 14.2596 180.96 14.2802 180.927 14.2761L180.718 14.2556C180.685 14.2515 180.665 14.231 180.669 14.1981C180.759 13.0323 180.886 11.7229 181.006 10.5941C181.01 10.5612 181.03 10.5407 181.063 10.5407H181.235C181.268 10.5407 181.297 10.5612 181.31 10.59C181.695 11.4479 182.101 12.3674 182.471 13.2129C182.512 13.3114 182.566 13.4387 182.607 13.5947H182.626C182.668 13.4428 182.725 13.2992 182.775 13.1883C183.144 12.3222 183.538 11.4233 183.919 10.5941C183.932 10.5694 183.961 10.5448 183.994 10.5448H184.178C184.211 10.5448 184.232 10.5653 184.236 10.5982C184.351 11.69 184.49 13.0201 184.577 14.2022C184.581 14.235 184.56 14.2556 184.527 14.2596L184.298 14.272Z"
        fill="white"
      />
      <path
        d="M187.734 11.0251C187.721 11.0579 187.701 11.0661 187.676 11.0497C187.368 10.865 187.097 10.7952 186.772 10.7952C185.952 10.7952 185.529 11.3125 185.529 12.392C185.529 13.4265 185.94 13.9806 186.743 13.9806C187.101 13.9806 187.43 13.8862 187.688 13.7178C187.713 13.6973 187.742 13.7056 187.758 13.7343L187.848 13.9149C187.864 13.9477 187.86 13.9764 187.831 13.9929C187.548 14.194 187.097 14.3008 186.739 14.3008C185.607 14.3008 185.185 13.5372 185.185 12.3962C185.185 11.2139 185.685 10.4874 186.801 10.4874C187.233 10.4874 187.523 10.5859 187.767 10.7254C187.798 10.7419 187.807 10.7747 187.798 10.8034L187.734 11.0251Z"
        fill="white"
      />
    </SvgIcon>
  ) : (
    <SvgIcon height={height} viewBox="0 0 182 34" fill="none">
      <path
        d="M93.2247 10.8649C93.2247 10.7541 93.2986 10.6761 93.4136 10.6761H94.2223C94.3332 10.6761 94.4112 10.75 94.4112 10.8649V16.2923C95.2364 15.7462 96.1191 15.4671 97.0387 15.4671C99.9699 15.4671 101.021 17.2325 101.021 20.3115C101.021 23.8627 99.1242 25.2134 96.5501 25.2134C95.142 25.2134 94.1074 24.9876 93.3971 24.5935C93.3027 24.536 93.2289 24.4251 93.2289 24.3102V10.8649H93.2247ZM94.4112 23.8627C95.0516 24.1459 95.651 24.2198 96.4598 24.2198C98.5248 24.2198 99.8221 23.2633 99.8221 20.3156C99.8221 17.799 99.0708 16.4853 96.8909 16.4853C95.9877 16.4853 95.1255 16.8219 94.4112 17.3105V23.8627Z"
        fill="white"
      />
      <path
        d="M102.634 15.581C102.745 15.5605 102.84 15.618 102.877 15.7124C103.57 17.6091 104.453 19.8794 105.299 21.9116C105.541 22.4946 105.767 23.057 106.071 23.9602H106.181C106.518 23.057 106.764 22.5151 107.006 21.9116C107.89 19.826 108.846 17.5927 109.56 15.7124C109.618 15.618 109.712 15.5605 109.823 15.6016L110.553 15.7904C110.665 15.8274 110.722 15.9218 110.665 16.0162C109.539 18.8326 107.528 23.2664 106.702 25.1426C105.877 27.0023 104.581 28.7676 103.361 29.8761C103.287 29.95 103.172 29.95 103.098 29.8761L102.573 29.367C102.499 29.2932 102.499 29.1782 102.573 29.1043C103.661 28.0903 104.79 26.7191 105.541 25.237C104.679 23.3773 102.741 18.7587 101.69 16.0162C101.633 15.9218 101.69 15.8274 101.801 15.8109L102.634 15.581Z"
        fill="white"
      />
      <path
        d="M113.866 23.3937C113.903 23.2828 113.998 23.2418 114.093 23.2828C115.275 23.8289 116.326 24.0917 117.775 24.0917C120.234 24.0917 121.458 23.1146 121.458 21.4437C121.458 17.6297 114.039 20.1299 114.039 15.1337C114.039 12.6745 116.272 11.6399 118.772 11.6399C120.202 11.6399 121.458 11.9232 122.304 12.2393C122.397 12.2763 122.455 12.3912 122.414 12.5021L122.188 13.2903C122.151 13.4012 122.057 13.4422 121.963 13.4012C121.1 13.044 120.009 12.7607 118.789 12.7607C116.761 12.7607 115.296 13.5489 115.296 15.072C115.296 18.845 122.73 16.4433 122.73 21.3616C122.73 23.6729 121.096 25.2125 117.586 25.2125C116.121 25.2125 114.865 25.0072 113.772 24.4981C113.678 24.4612 113.62 24.3462 113.661 24.2354L113.866 23.3937Z"
        fill="white"
      />
      <path
        d="M132.066 22.6424C132.066 23.4307 132.197 23.9932 132.612 24.5022C132.685 24.5761 132.685 24.6706 132.575 24.728L132.087 25.0482C131.975 25.1057 131.845 25.1221 131.75 25.0687C131.429 24.8635 131.15 24.4283 131.019 23.9439C130.042 24.6007 128.803 25.2206 127.357 25.2206C124.915 25.2206 124.147 23.755 124.147 20.8443V15.8439C124.147 15.7331 124.22 15.6551 124.336 15.6551H125.144C125.255 15.6551 125.333 15.729 125.333 15.8439V20.8033C125.333 23.246 125.933 24.1655 127.624 24.1655C128.9 24.1655 130.03 23.6401 130.891 22.9997V15.8439C130.891 15.7331 130.966 15.6551 131.081 15.6551H131.89C132 15.6551 132.078 15.729 132.078 15.8439V22.6424H132.066Z"
        fill="white"
      />
      <path
        d="M134.714 15.692C134.825 15.6346 134.957 15.6181 135.05 15.6715C135.334 15.8767 135.613 16.2709 135.764 16.743C136.742 16.0861 137.961 15.4662 139.39 15.4662C141.833 15.4662 142.658 16.9318 142.658 19.8425V24.8388C142.658 24.9497 142.584 25.0277 142.469 25.0277H141.665C141.554 25.0277 141.475 24.9538 141.475 24.8388V19.8795C141.475 17.4368 140.819 16.5172 139.127 16.5172C137.924 16.5172 136.763 16.9852 135.917 17.5681V24.8347C135.917 24.9456 135.843 25.0236 135.728 25.0236H134.919C134.808 25.0236 134.73 24.9497 134.73 24.8347V18.0978C134.73 17.3096 134.599 16.747 134.185 16.238C134.111 16.1641 134.111 16.0697 134.221 16.0122L134.714 15.692Z"
        fill="white"
      />
      <path
        d="M147.112 12.0094C147.112 11.8986 147.186 11.8206 147.302 11.8206H148.163C148.274 11.8206 148.353 11.8945 148.353 12.0094V23.9356H155.02C155.13 23.9356 155.208 24.0096 155.208 24.1245V24.8388C155.208 24.9497 155.134 25.0277 155.02 25.0277H147.302C147.19 25.0277 147.112 24.9538 147.112 24.8388V12.0094Z"
        fill="white"
      />
      <path
        d="M157.552 10.6793C157.967 10.6793 158.283 10.9995 158.283 11.4101C158.283 11.8246 157.962 12.1408 157.552 12.1408C157.138 12.1408 156.822 11.8206 156.822 11.4101C156.822 10.9954 157.141 10.6793 157.552 10.6793ZM156.953 15.8438C156.953 15.7331 157.027 15.655 157.141 15.655H157.95C158.061 15.655 158.14 15.7289 158.14 15.8438V24.8388C158.14 24.9497 158.066 25.0277 157.95 25.0277H157.141C157.031 25.0277 156.953 24.9538 156.953 24.8388V15.8438Z"
        fill="white"
      />
      <path
        d="M159.638 15.8438C159.638 15.7331 159.711 15.655 159.827 15.655H161.124V14.5301C161.124 12.3338 161.633 10.4945 164.298 10.4945C164.938 10.4945 165.558 10.6054 166.1 10.7778C166.194 10.8147 166.252 10.9092 166.211 11.02L166.043 11.6769C166.005 11.7877 165.911 11.8452 165.817 11.8082C165.328 11.64 164.803 11.525 164.277 11.525C162.906 11.525 162.306 12.2763 162.306 14.4357V15.655H164.992C165.103 15.655 165.18 15.7289 165.18 15.8438V16.4064C165.18 16.5171 165.107 16.5951 164.992 16.5951H162.306V24.8388C162.306 24.9497 162.233 25.0277 162.118 25.0277H161.309C161.198 25.0277 161.12 24.9538 161.12 24.8388V16.5951H159.823C159.712 16.5951 159.634 16.5212 159.634 16.4064V15.8438H159.638Z"
        fill="white"
      />
      <path
        d="M173.214 24.067C173.252 24.1615 173.235 24.2928 173.14 24.3503C172.316 24.9127 170.924 25.2125 169.742 25.2125C166.642 25.2125 165.349 22.9955 165.349 20.3681C165.349 17.6626 166.777 15.4662 169.705 15.4662C172.579 15.4662 173.629 17.326 173.629 19.8425V20.3681C173.609 20.479 173.535 20.5569 173.424 20.5569H166.552C166.663 22.8108 167.733 24.182 169.893 24.182C170.87 24.182 171.864 23.9562 172.71 23.4102C172.804 23.3363 172.898 23.3732 172.935 23.4677L173.214 24.067ZM166.568 19.6168H172.5C172.5 17.9664 171.843 16.4803 169.684 16.4803C168.108 16.4803 166.757 17.326 166.568 19.6168Z"
        fill="white"
      />
      <path
        d="M5.66437 17.7694V25.0934H1.6698V5.05896H9.83958C14.6182 5.05896 17.1677 7.55095 17.1677 11.4265C17.1677 15.2773 14.6182 17.7652 9.86832 17.7652H5.66437V17.7694ZM5.66437 8.73741V14.1196H9.76979C11.9456 14.1196 13.0746 13.2329 13.0746 11.4306C13.0746 9.64061 11.9456 8.74152 9.76979 8.74152H5.66437V8.73741Z"
        fill="white"
      />
      <path
        d="M28.3509 11.1555L27.7926 14.8628C27.1768 14.5917 26.29 14.4193 25.5305 14.4193C23.798 14.4193 22.6115 15.4786 22.6115 17.5395V25.0935H18.8756V11.2253H22.4966V12.7443C23.3711 11.4429 24.7012 10.8558 26.405 10.8558C27.2055 10.8558 27.8377 10.9708 28.3509 11.1555Z"
        fill="white"
      />
      <path
        d="M56.1487 15.0927C55.0485 14.5507 53.2872 13.9759 51.7272 13.9636C50.3108 13.9636 49.5965 14.4645 49.5965 15.2239C49.5965 16.0245 50.5982 16.2257 51.8586 16.4104L53.0902 16.5952C56.0953 17.055 57.7703 18.3974 57.7703 20.7458C57.7703 23.6072 55.422 25.4546 51.4029 25.4546C49.5144 25.4546 47.0388 25.0975 45.2324 23.8249L46.7063 20.9921C47.8928 21.7803 49.3379 22.351 51.4275 22.351C53.16 22.351 53.9893 21.8625 53.9893 21.0495C53.9893 20.3762 53.2872 20.0068 51.6862 19.7769L50.5571 19.6209C47.3508 19.1775 45.762 17.7734 45.762 15.4128C45.762 12.5637 47.9666 10.8763 51.6163 10.8763C53.8333 10.8763 55.5658 11.291 57.4132 12.1942L56.1487 15.0927Z"
        fill="white"
      />
      <path
        d="M74.5613 18.1552C74.5613 22.3181 71.6712 25.4546 67.849 25.4546C65.9892 25.4546 64.6713 24.7238 63.7682 23.6524V30.8246H60.0487V11.2253H63.6984V12.7566C64.6016 11.6276 65.9317 10.8517 67.8326 10.8517C71.6712 10.8558 74.5613 13.9882 74.5613 18.1552ZM63.5835 18.1552C63.5835 20.3434 65.0286 21.9774 67.1469 21.9774C69.3516 21.9774 70.7227 20.2736 70.7227 18.1552C70.7227 16.0369 69.3475 14.3331 67.1469 14.3331C65.0286 14.3331 63.5835 15.9629 63.5835 18.1552Z"
        fill="white"
      />
      <path
        d="M86.4179 11.1555L85.8596 14.8628C85.2438 14.5917 84.357 14.4193 83.5975 14.4193C81.865 14.4193 80.6786 15.4786 80.6786 17.5395V25.0935H76.9426V11.2253H80.5636V12.7443C81.4381 11.4429 82.7682 10.8558 84.472 10.8558C85.2726 10.8558 85.9047 10.9708 86.4179 11.1555Z"
        fill="white"
      />
      <path
        d="M36.5083 14.0745C37.2718 14.0745 37.9903 14.2798 38.6061 14.6369L40.3016 11.7015C39.185 11.057 37.8918 10.6834 36.5083 10.6834C35.1247 10.6834 33.8316 11.057 32.7148 11.7015L34.4104 14.6369C35.0262 14.2798 35.7405 14.0745 36.5083 14.0745Z"
        fill="#FEDD00"
      />
      <path
        d="M32.3126 18.2743C32.3126 16.7224 33.1542 15.3676 34.4063 14.6451L32.7108 11.7097C30.4446 13.0234 28.9215 15.4703 28.9215 18.2784V18.2825L32.3126 18.2743C32.3126 18.2784 32.3126 18.2784 32.3126 18.2743Z"
        fill="#00A8E5"
      />
      <path
        d="M40.7 18.2743C40.7 18.2784 40.7 18.2784 40.7 18.2743L44.091 18.2784V18.2743C44.091 15.4662 42.5638 13.0194 40.3017 11.7056L38.6062 14.641C39.8583 15.3718 40.7 16.7224 40.7 18.2743Z"
        fill="#FAA61F"
      />
      <path
        d="M36.5084 22.47C35.7447 22.47 35.0304 22.2647 34.4146 21.9076L32.7191 24.843C33.8357 25.4875 35.1289 25.857 36.5084 25.857C37.8878 25.857 39.1851 25.4875 40.2977 24.843L38.6021 21.9076C37.9863 22.2647 37.272 22.47 36.5084 22.47Z"
        fill="#58B947"
      />
      <path
        d="M44.0911 18.2784H40.7001C40.7001 19.8303 39.8544 21.185 38.6022 21.9076L40.2978 24.8429C42.564 23.5333 44.0911 21.0865 44.0911 18.2784Z"
        fill="#0B713A"
      />
      <path
        d="M32.3126 18.2784H28.9215C28.9215 21.0865 30.4488 23.5333 32.7149 24.8429L34.4105 21.9076C33.1583 21.185 32.3126 19.8303 32.3126 18.2784Z"
        fill="#00548B"
      />
      <path
        d="M175.308 11.0077H174.098C174.065 11.0077 174.044 10.9871 174.044 10.9543V10.7531C174.044 10.7203 174.065 10.6998 174.098 10.6998H176.868C176.901 10.6998 176.922 10.7203 176.922 10.7531V10.9543C176.922 10.9871 176.901 11.0077 176.868 11.0077H175.662V14.3865C175.662 14.4193 175.641 14.4398 175.608 14.4398H175.362C175.329 14.4398 175.308 14.4193 175.308 14.3865V11.0077Z"
        fill="white"
      />
      <path
        d="M180.806 14.4645C180.773 14.4686 180.752 14.448 180.748 14.4152C180.682 13.6557 180.616 12.8017 180.547 12.034C180.527 11.8329 180.51 11.5989 180.498 11.3526H180.465C180.374 11.603 180.272 11.8246 180.181 12.0218C179.861 12.7361 179.537 13.4955 179.242 14.2058C179.229 14.2304 179.2 14.2551 179.167 14.2551H179.036C179.004 14.2551 178.971 14.2346 178.962 14.2058C178.704 13.5735 178.367 12.8058 178.022 12.0218C177.936 11.837 177.841 11.5906 177.755 11.3607H177.723C177.719 11.5906 177.686 11.8287 177.669 12.0258C177.595 12.7771 177.525 13.631 177.468 14.4152C177.464 14.448 177.439 14.4686 177.411 14.4645L177.197 14.444C177.164 14.4398 177.144 14.4193 177.148 14.3865C177.237 13.2122 177.365 11.8904 177.489 10.7531C177.492 10.7203 177.513 10.6998 177.546 10.6998H177.723C177.755 10.6998 177.788 10.7203 177.796 10.749C178.186 11.6152 178.592 12.5431 178.967 13.3929C179.008 13.4955 179.06 13.6228 179.106 13.7748H179.126C179.167 13.6188 179.229 13.475 179.275 13.3641C179.648 12.4938 180.046 11.5825 180.428 10.749C180.44 10.7244 180.469 10.6998 180.502 10.6998H180.686C180.719 10.6998 180.74 10.7203 180.744 10.7531C180.858 11.8534 180.999 13.1959 181.089 14.3865C181.093 14.4193 181.073 14.4398 181.04 14.444L180.806 14.4645Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default SunlifeLogo;
