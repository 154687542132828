import React from "react";

import Box from "@material-ui/core/Box";

import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
import {
  USER_WILL,
  USER_POA_PROPERTY,
  USER_POA_PERSONAL,
  SPOUSE_WILL,
  SPOUSE_POA_PROPERTY,
  SPOUSE_POA_PERSONAL,
  UPGRADABLE_POA_PERSONAL,
  UPGRADABLE_POA_PROPERTY,
  ASSET_LIST,
  UPGRADABLE_ASSET_LIST,
} from "../../constants/documents";
import GridIcon from "../ui/icons/GridIcon";
import DocumentIcon from "../ui/icons/DocumentIcon";
import AssetsIcon from "../ui/icons/AssetsIcon";
import PoaPropertyUpgradableIcon from "../ui/icons/PoaPropertyUpgradableIcon";
import PoaPersonalUpgradableIcon from "../ui/icons/PoaPersonalUpgradableIcon";
import AssetListIcon from "../ui/icons/AssetListIcon";

const CardIcon = ({ documentKey }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  switch (documentKey) {
    case USER_WILL:
      return (
        <Box position="relative" bottom="-10px" height={150}>
          <DocumentIcon />
        </Box>
      );
    case SPOUSE_WILL:
      return (
        <Box position="relative" bottom="-10px" height={150}>
          <DocumentIcon />
        </Box>
      );
    case USER_POA_PROPERTY:
      return (
        <Box position="relative" bottom="-10px" height={150}>
          <GridIcon type="home_purchase" />
        </Box>
      );
    case SPOUSE_POA_PROPERTY:
      return (
        <Box position="relative" bottom="-10px" height={150}>
          <GridIcon type="home_purchase" />
        </Box>
      );
    case USER_POA_PERSONAL:
      return (
        <Box position="relative" bottom="-20px" height={150}>
          <AssetsIcon />
        </Box>
      );
    case SPOUSE_POA_PERSONAL:
      return (
        <Box position="relative" bottom="-20px" height={150}>
          <AssetsIcon />
        </Box>
      );
    case UPGRADABLE_POA_PROPERTY:
      return (
        <Box position="relative" bottom="-20px" height={150}>
          <PoaPropertyUpgradableIcon />
        </Box>
      );
    case UPGRADABLE_POA_PERSONAL:
      return (
        <Box position="relative" bottom="-20px" height={150}>
          <PoaPersonalUpgradableIcon />
        </Box>
      );
    case ASSET_LIST:
      return (
        <Box position="relative" bottom="-20px" height={150}>
          <AssetListIcon isDesktop={isDesktop} />
        </Box>
      );
    case UPGRADABLE_ASSET_LIST:
      return (
        <Box position="relative" bottom="-20px" height={150}>
          <AssetListIcon isDesktop={isDesktop} disabled />
        </Box>
      );
    default:
      return (
        <Box position="relative" bottom="-10px" height={150}>
          <DocumentIcon />
        </Box>
      );
  }
};

export default CardIcon;
