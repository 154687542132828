import React from "react";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";

import { LOGIN_PATH, SIGNUP_PATH } from "../../constants/routes";
import { Body } from "../ui/Typography";
import LanguageSelect from "./LanguageSelect";
import CanadianFlagIcon from "../ui/icons/CanadianFlagIcon";
import YourAccountMenu from "./YourAccountMenu";
import NavButtonLink from "./NavButtonLink";
import CartWidget from "../ui/CartWidget";
import { useSelector } from "react-redux";
import { selectHeaderAuthModeProps } from "../../selectors/header";

const DesktopNav = ({
  fetchCart,
  cartProps,
  yourAccountMenuProps,
  translations,
  onLogout,
  isFrenchAvailable,
  isAuthenticated,
  analyticsOpenedCartWidget,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const authModeProps = useSelector(selectHeaderAuthModeProps);

  return (
    <Box
      display="flex"
      position="relative"
      component="nav"
      aria-label={translations.navigationAriaLabel}
      width="100%"
      height="100%"
    >
      <Box display="flex" alignItems="center" ml={2} mr="auto" />
      {isFrenchAvailable && <LanguageSelect translations={translations} />}
      <Box
        display="flex"
        alignItems="center"
        alignSelf="center"
        max-height="24px"
      >
        <CanadianFlagIcon
          alt={translations.canadianFlagAltText}
          width={24}
          height={12}
        />
        <Box borderRight={1} mx={isDesktop ? 2 : 1.5} py={0.75} color="white" />
      </Box>
      {cartProps.itemCount > 0 && (
        <CartWidget
          fetchCart={fetchCart}
          analyticsOpenedCartWidget={analyticsOpenedCartWidget}
          {...cartProps}
        />
      )}
      {isAuthenticated ? (
        <YourAccountMenu
          onLogout={onLogout}
          translations={translations}
          {...yourAccountMenuProps}
        />
      ) : (
        <NavButtonLink to={authModeProps.path} color="secondary">
          <Body component="span">{authModeProps.label}</Body>
        </NavButtonLink>
      )}
    </Box>
  );
};

export default DesktopNav;
