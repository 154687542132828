import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { Form } from "../../ui/Form";

import TextInput from "../../ui/inputs/TextInput";
import { ErrorMessage } from "../../ui/Error";
import {
  useValidateContactPresentMemo,
  useValidateGiftDescriptionMemo,
  useValidateGiftDescriptionMemoQC,
} from "../../ui/inputs/validations";
import { Body, SmallBody, SmallExternalLink } from "../../ui/Typography";

import {
  CONTACT_ID_FIELD_ID,
  GIFTS_FIELD_ID,
  GIFTS_FORM_ID,
  GIFT_DESCRIPTION_FIELD_ID,
} from "../../../constants/forms";
import { selectIsCurrentProvinceSelectedByCode } from "../../../selectors/provinces";
import { selectAllContacts } from "../../../selectors/contacts";
import { openModalContactForContactable } from "../../../actions/contacts";
import DropdownContactSelect from "../../ui/inputs/DropdownContactSelect";
import { MODAL_ADD_CONTACT_GIFT } from "../../../constants/modal";
import { SecondaryButton, SmallTextButton } from "../../ui/Button";

export const GiftFieldArrayRenderer = ({
  fields,
  translations,
  handleChange,
  defaultFieldObject = {},
  RenderedComponent,
  canAddMore = true,
  spouseIsPrimary,
  hasSpouse,
  helperText,
}) => {
  const allFields = fields.getAll() || [];
  const addLabel =
    allFields.filter((field) => field._destroy !== true).length === 0
      ? translations.button.addLabel
      : translations.button.addMoreLabel;
  return (
    <Box>
      {fields.map((fieldId, index) => {
        const field = fields.get(index);
        const fieldContactId = field?.contact?.id;
        // _destroy indicates to rails that field is to be deleted
        // requires rerenderOnEveryChange={true} on <FieldArray />
        // performance hit on large lists < 10 should be fine
        if (field && field._destroy === true) {
          return null;
        }
        return (
          <Box key={fieldId}>
            <Gift
              id={fieldId}
              fieldIndex={index}
              translations={translations}
              initialContactId={fieldContactId}
            />
            <Box my={2} width="100%" display="flex" flexDirection="row-reverse">
              <SmallTextButton
                text={translations.button.removeLabel}
                className="qa-remove"
                onClick={() => handleChange(`${fieldId}._destroy`, true)}
              />
            </Box>
          </Box>
        );
      })}
      {helperText && <Body>{helperText}</Body>}
      {canAddMore && (
        <SecondaryButton
          text={addLabel}
          fullWidth
          displayPlusIcon
          className="qa-add"
          type="button" // required else it defaults to submit which triggers form validation right after adding something
          onClick={() => fields.push(defaultFieldObject)}
        />
      )}
    </Box>
  );
};

const Gift = ({ id, fieldIndex, translations, initialContactId = null }) => {
  const [selectedContactId, setSelectedContactId] = useState(initialContactId);

  useEffect(() => {
    // when initial contactId is updated, update selectedContactId
    if (initialContactId) {
      setSelectedContactId(initialContactId);
    }
  }, [initialContactId]);

  const isQuebecSelected = useSelector(
    selectIsCurrentProvinceSelectedByCode("QC"),
  );
  const validationFunctionMemo = isQuebecSelected
    ? useValidateGiftDescriptionMemoQC
    : useValidateGiftDescriptionMemo;
  const dispatch = useDispatch();
  const contacts = useSelector(selectAllContacts);
  const handleSelectContact = (contactId) => {
    setSelectedContactId(contactId);
  };
  const handleAddNewContact = (contactableId) => {
    dispatch(
      openModalContactForContactable(
        null,
        MODAL_ADD_CONTACT_GIFT,
        contactableId,
        false,
        GIFTS_FORM_ID,
        GIFTS_FIELD_ID,
        fieldIndex,
      ),
    );
  };

  return (
    <Box>
      <Box mb={1}>
        <DropdownContactSelect
          name={`${id}.${CONTACT_ID_FIELD_ID}`}
          label={translations.giftRecipientLabel}
          contacts={contacts}
          selectedContactId={selectedContactId}
          onSelect={(contactId) => {
            handleSelectContact(contactId);
          }}
          onAddNew={handleAddNewContact}
          validator={useValidateContactPresentMemo(translations)}
          includeMinorChildren
        />
      </Box>
      <Field
        name={`${id}.${GIFT_DESCRIPTION_FIELD_ID}`}
        component={TextInput}
        validate={validationFunctionMemo(translations)}
        label={translations.giftDescriptionLabel}
        placeholder={translations.giftPlaceholder}
      />
    </Box>
  );
};

const BequestOfRRSP = ({ translations }) => {
  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12} md={8} lg={6}>
        <Box mt={1.5} textAlign="center">
          <SmallBody margin="16px" align="center">
            {translations.bequestOfRRSP}
          </SmallBody>
          <SmallExternalLink
            target="_blank"
            href={translations.bequestLink}
            text={translations.bequestLinkText}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const AssetListNote = ({ translations }) => {
  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12} md={8} lg={6}>
        <Box my={1.5} textAlign="center">
          <SmallBody align="center">{translations.assetListNote}</SmallBody>
        </Box>
      </Grid>
    </Grid>
  );
};

const GiftsHeaderComponent = ({
  requiresBequestOfRRSP,
  translations,
  isPremiumProvinceSelected,
  isCouplesPlan,
}) => {
  if (requiresBequestOfRRSP) {
    return <BequestOfRRSP translations={translations} />;
  }
  if (isPremiumProvinceSelected && !isCouplesPlan) {
    return <AssetListNote translations={translations} />;
  }
  return null;
};

const GiftsForm = ({
  error,
  handleSubmit,
  backLink,
  onSkip,
  change,
  isLoading,
  requiresBequestOfRRSP,
  translations,
  isPremiumProvinceSelected,
  isCouplesPlan,
}) => {
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Form
      qaFormId="gifts"
      isLoading={isLoading}
      onSubmit={handleSubmit}
      HeaderComponent={GiftsHeaderComponent}
      requiresBequestOfRRSP={requiresBequestOfRRSP}
      isPremiumProvinceSelected={isPremiumProvinceSelected}
      isCouplesPlan={isCouplesPlan}
      backLink={backLink}
      onSkip={onSkip}
      translations={translations}
      reverseWrap={isTabletDown}
    >
      <FieldArray
        rerenderOnEveryChange
        handleChange={change}
        name={GIFTS_FIELD_ID}
        component={GiftFieldArrayRenderer}
        translations={translations}
      />
      <Box mb={2}>
        <ErrorMessage error={error} />
      </Box>
    </Form>
  );
};

export default reduxForm({
  form: GIFTS_FORM_ID,
})(GiftsForm);
