import React from "react";
import R from "ramda";

import Box from "@material-ui/core/Box";
import { SecondaryButton, SmallTextButton } from "../Button";
import { Body } from "../Typography";
import { orderedFillColors } from "../../../utilities/allocations";
import { TotalPercentageBar } from "../TotalPercentageBar";
import { ErrorMessage } from "../Error";
import { getAppointeeLevelTranslation } from "../../../utilities/appointees";

export const FieldArrayRenderer = ({
  fields,
  translations,
  handleChange,
  defaultFieldObject = {},
  RenderedComponent,
  canAddMore = true,
  spouseIsPrimary,
  hasSpouse,
  helperText,
  appointeeLevelIndexs = {},
  ...otherProps
}) => {
  const allFields = fields.getAll() || [];
  const addLabel =
    allFields.filter((field) => field._destroy !== true).length === 0
      ? translations.button.addLabel
      : translations.button.addMoreLabel;
  const hasAppointeeTitle = R.has("total")(appointeeLevelIndexs);
  return (
    <Box>
      {fields.map((fieldId, index) => {
        const field = fields.get(index);
        // _destroy indicates to rails that field is to be deleted
        // requires rerenderOnEveryChange={true} on <FieldArray />
        // performance hit on large lists < 10 should be fine
        if (field && field._destroy === true) {
          return null;
        }
        const shouldAutoFocus = index === 0 ? true : false;
        return (
          <Box key={index}>
            {hasAppointeeTitle && (
              <Box mb={1}>
                <Body component="span">{`${getAppointeeLevelTranslation(
                  appointeeLevelIndexs,
                  index,
                  spouseIsPrimary,
                  translations,
                )} ${translations.appointeeTitle}`}</Body>
              </Box>
            )}
            <RenderedComponent
              id={fieldId}
              index={index}
              shouldAutoFocus={shouldAutoFocus}
              translations={translations}
              handleChange={handleChange}
              field={field}
              {...otherProps}
            />
            <Box
              my={2}
              width={"100%"}
              display="flex"
              flexDirection="row-reverse"
            >
              <SmallTextButton
                text={translations.button.removeLabel}
                className="qa-remove"
                onClick={() => handleChange(`${fieldId}._destroy`, true)}
              />
            </Box>
          </Box>
        );
      })}
      {helperText && <Body>{helperText}</Body>}
      {canAddMore && (
        <SecondaryButton
          text={addLabel}
          fullWidth
          displayPlusIcon
          className="qa-add"
          type="button" // required else it defaults to submit which triggers form validation right after adding something
          onClick={() => fields.push(defaultFieldObject)}
        />
      )}
    </Box>
  );
};

export const AllocationsFieldArrayRenderer = ({
  error,
  fields,
  translations,
  RenderedComponent,
  charities,
  onRemove,
  totalPercentage,
  distributeAllocationsEvenly,
  updateAllocationCard,
  isPrimaryBeneficiarySpouse,
}) => {
  const fieldsCount = fields.getAll()?.length;
  const showTotalPercentageBar = fieldsCount > 1;
  return (
    <Box mb={fieldsCount ? 2 : 0}>
      {fields.map((fieldId, index) => {
        const field = fields.get(index);
        if (field && field._destroy === true) {
          return null;
        }
        const color = orderedFillColors[index % orderedFillColors.length];
        return (
          <Box mb={1} key={`${index + 1}-${fieldId}`}>
            <RenderedComponent
              id={field.id}
              fieldId={fieldId}
              index={index}
              allocation={field}
              charities={charities}
              translations={translations}
              onEdit={() => updateAllocationCard(index)}
              onRemove={() => onRemove(index)}
              color={color}
              formError={error}
              isPrimaryBeneficiarySpouse={isPrimaryBeneficiarySpouse}
              {...field}
            />
          </Box>
        );
      })}

      {error && (
        <Box mt={1}>
          <ErrorMessage error={error} />
        </Box>
      )}

      {showTotalPercentageBar && (
        <Box mt={2}>
          <TotalPercentageBar
            distributeAllocationsEvenly={distributeAllocationsEvenly}
            translations={translations}
            total={totalPercentage}
          />
        </Box>
      )}
    </Box>
  );
};

export const ExecutorsFieldArrayRenderer = ({
  fields,
  openModalAddExecutors,
  openModalEditExecutors,
  removeExecutors,
  handleChange,
  translations,
  RenderedComponent,
  spouseIsPrimary,
  appointeeLevelIndexs = {},
  subQuestionText = "",
  subQuestionOptionalText = "",
  isQuebecSelected = false,
  canAddMore = true,
}) => {
  const hasAppointeeTitle = R.has("total")(appointeeLevelIndexs);
  const allFields = fields.getAll() || [];
  const addLabel =
    allFields.filter((field) => field._destroy !== true).length === 0
      ? translations.button.addLabel
      : translations.button.addMoreLabel;
  // keeping track of color index because 'index' is not reset when removing an executor
  let colorIndex = 0;
  return (
    <Box>
      {subQuestionText && (
        <Box mb={2}>
          <Body
            dangerouslySetInnerHTML={{
              __html: subQuestionText,
            }}
          />
        </Box>
      )}

      {fields.map((fieldId, index) => {
        const field = fields.get(index);
        const isFirstCard = colorIndex === 0;
        if (field && field._destroy === true) {
          return null;
        }
        const color = orderedFillColors[colorIndex % orderedFillColors.length];
        colorIndex += 1;

        let text = "";
        let title = translations.executorTitle;

        const hasCoExecutor = Boolean(field?.spouseFirstName);
        const executorName = `${field.firstName} ${field.middleName || ""} ${
          field.lastName
        }`;

        if (!isQuebecSelected && hasCoExecutor) {
          const coExecutorName = `${field.spouseFirstName} ${
            field.spouseMiddleName || ""
          } ${field.spouseLastName}`;
          text = `${executorName} & ${coExecutorName}`;
          title = translations.coExecutorTitle;
        } else {
          text = executorName;
        }

        const executorLevelText = getAppointeeLevelTranslation(
          appointeeLevelIndexs,
          index,
          spouseIsPrimary,
          translations,
        );
        const subText = hasAppointeeTitle
          ? `${executorLevelText} ${title}`
          : "";

        return (
          <Box mb={2.5} key={`${index + 1}-${fieldId}`}>
            <RenderedComponent
              id={field.id}
              fieldId={fieldId}
              translations={translations}
              text={text}
              subText={subText}
              color={color}
              onEdit={() => openModalEditExecutors({ ...field, index })}
              onRemove={() => {
                removeExecutors(field);
                return handleChange(`${fieldId}._destroy`, true);
              }}
              {...field}
            />
            {subQuestionOptionalText && isFirstCard && (
              <Box my={2}>
                <Body
                  dangerouslySetInnerHTML={{
                    __html: subQuestionOptionalText,
                  }}
                />
              </Box>
            )}
          </Box>
        );
      })}

      {canAddMore && (
        <SecondaryButton
          text={addLabel}
          fullWidth
          displayPlusIcon
          className="qa-add"
          type="button" // required else it defaults to submit which triggers form validation right after adding something
          onClick={openModalAddExecutors}
        />
      )}
    </Box>
  );
};
