import { UPDATE_FEATURE_FLAGS_TYPE } from "../actions/featureFlags";

const featureFlags = (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_FEATURE_FLAGS_TYPE:
      return { ...state, ...payload.featureFlags };
    default:
      return state;
  }
};

export default featureFlags;
