import React, { useEffect, useRef, useState } from "react";
import Lottie from "lottie-web";
import { Box, useMediaQuery } from "@material-ui/core";
import { withTheme, useTheme } from "@material-ui/styles";
import styled from "styled-components";

import data from "./confetti.json";
import ConfettiStatic from "../icons/ConfettiStatic";

const AnimationRenderContainer = styled(Box)`
  ${(props) => !props.animationRunning && "opacity: 0;"}
  transition: opacity 500ms ease-out;
`;

// Animation data pulled from https://lottiefiles.com/17252-colorful-confetti

// Configure Lottie. You need to do this only once. Read more here:
// https://developer.helloeko.com/docs/howto/svgs_and_lottie.html#Using-Lottie-Elements
Lottie.setLocationHref(window.location.href);

const Confetti = ({ width = "100%", height = "auto" }) => {
  const animationContainerRef = useRef(null);
  const [animationRunning, setAnimationRunning] = useState(true);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const prefersReducedMotion = window.matchMedia(
    "(prefers-reduced-motion: reduce)",
  ).matches;

  useEffect(() => {
    if (!prefersReducedMotion) {
      const anim = Lottie.loadAnimation({
        container: animationContainerRef.current, // the dom element that will contain the animation
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: data, // the path to the animation json
        rendererSettings: {
          // More info about this property can be found here: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/preserveAspectRatio
          preserveAspectRatio: `xMidYMin ${isDesktop ? "meet" : "slice"}`,
        },
      });

      // Start halfway through the animation, which takes 10 seconds normally
      anim.goToAndPlay(4000);

      // Fade out before the animation completes. The confetti stops abruptly at the end of the animation
      // and does not disappear. By doing this, we can fade out naturally near the end.
      anim.onEnterFrame = (e) => {
        const { currentTime, totalTime } = e;

        // Once we're 90% of the way through the animation, trigger the fade out
        if (currentTime / totalTime > 0.9) {
          setAnimationRunning(false);
        }
      };
    }
  }, [prefersReducedMotion, isDesktop]);

  return (
    <>
      {!prefersReducedMotion && (
        <AnimationRenderContainer
          width={width}
          height={height}
          animationRunning={animationRunning}
          ref={animationContainerRef}
        />
      )}
      {prefersReducedMotion && (
        <Box maxWidth="100%" overflow="hidden">
          <ConfettiStatic />
        </Box>
      )}
    </>
  );
};

export default withTheme(Confetti);
