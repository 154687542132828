import React from "react";

import { reduxForm, Field } from "redux-form";
import Box from "@material-ui/core/Box";

import { PrimaryButton, TextButton } from "../../ui/Button";

import TextInput from "../../ui/inputs/TextInput";
import BinaryRadioField from "../../ui/inputs/BinaryRadioField";
import SelectInput from "../../ui/inputs/DropdownSelect";
import { ErrorMessage } from "../../ui/Error";

import {
  ASSET_TYPE_FIELD_ID,
  COLLECTIBLE,
  COLLECTIBLE_LOCATION_FIELD_ID,
  COLLECTIBLE_TYPE_FIELD_ID,
  DESCRIPTION_FIELD_ID,
  OTHER_OWNER_FIRST_NAME_FIELD_ID,
  OTHER_OWNER_LAST_NAME_FIELD_ID,
  OTHER_OWNER_MIDDLE_NAME_FIELD_ID,
  PHYSICAL_ASSETS_FORM_ID,
  PROPERTY,
  PROPERTY_ADDRESS_FIELD_ID,
  PROPERTY_MORTGAGE_FIELD_ID,
  OWNERSHIP_FIELD_ID,
  VEHICLE,
  VEHICLE_MAKE_FIELD_ID,
  VEHICLE_MODEL_FIELD_ID,
  VEHICLE_YEAR_FIELD_ID,
} from "../../../constants/forms";
import {
  useValidateAssetTypeMemo,
  useValidatePropertyDescriptionMemo,
  useValidatePropertyAddressMemo,
  useValidatePropertyOwnershipMemo,
  useValidateVehicleDescriptionMemo,
  useValidateVehicleYearMemo,
  useValidateVehicleMakeMemo,
  useValidateVehicleModelMemo,
  useValidateCollectibleTypeMemo,
  useValidateCollectibleDescriptionMemo,
  useValidateCollectibleLocationMemo,
  useValidateOtherOwnerFirstNameMemo,
  useValidateOtherOwnerMiddleNameMemo,
  useValidateOtherOwnerLastNameMemo,
} from "../../ui/inputs/validations";

const PropertyFields = ({ translations, ownership }) => {
  // React Hooks must be called in the exact same order in every component render.eslintreact-hooks/rules-of-hooks
  // these are hooks used for conditional fields
  // react can't conditionally render a hook, declare here to avoid this
  const validateOtherOwnerFirstName = useValidateOtherOwnerFirstNameMemo(
    translations,
  );
  const validateOtherOwnerMiddleName = useValidateOtherOwnerMiddleNameMemo(
    translations,
  );
  const validateOtherOwnerLastName = useValidateOtherOwnerLastNameMemo(
    translations,
  );
  return (
    <>
      <Field
        component={TextInput}
        name={DESCRIPTION_FIELD_ID}
        label={translations.label.propertyDescription}
        placeholder={translations.label.propertyDescriptionPlaceholder}
        validate={useValidatePropertyDescriptionMemo(translations)}
        suppress
      />
      <Field
        component={TextInput}
        name={PROPERTY_ADDRESS_FIELD_ID}
        label={translations.label.propertyAddress}
        placeholder={translations.label.propertyAddressPlaceholder}
        validate={useValidatePropertyAddressMemo(translations)}
        suppress
      />
      <BinaryRadioField
        name={OWNERSHIP_FIELD_ID}
        label={translations.label.propertyOwnership}
        optionList={translations.ownershipOptionList}
        validate={useValidatePropertyOwnershipMemo(translations)}
        suppress
      />
      {ownership === "myself_and_other" && (
        <>
          <Field
            component={TextInput}
            name={OTHER_OWNER_FIRST_NAME_FIELD_ID}
            label={translations.label.otherOwnerFirstName}
            placeholder={translations.label.firstNameTheirPlaceholder}
            validate={validateOtherOwnerFirstName}
            suppress
          />

          <Field
            component={TextInput}
            name={OTHER_OWNER_MIDDLE_NAME_FIELD_ID}
            label={translations.label.otherOwnerMiddleName}
            placeholder={translations.label.middleNameTheirPlaceholder}
            validate={validateOtherOwnerMiddleName}
            suppress
          />

          <Field
            component={TextInput}
            name={OTHER_OWNER_LAST_NAME_FIELD_ID}
            label={translations.label.otherOwnerLastName}
            placeholder={translations.label.lastNameTheirPlaceholder}
            validate={validateOtherOwnerLastName}
            suppress
          />
        </>
      )}
      <BinaryRadioField
        name={PROPERTY_MORTGAGE_FIELD_ID}
        label={translations.label.propertyMortgage}
        optionList={translations.mortgageOptionList}
      />
    </>
  );
};
const VehicleFields = ({ translations }) => {
  return (
    <>
      <Field
        component={TextInput}
        name={DESCRIPTION_FIELD_ID}
        label={translations.label.vehicleDescription}
        placeholder={translations.label.vehicleDescriptionPlaceholder}
        validate={useValidateVehicleDescriptionMemo(translations)}
        suppress
      />
      <Field
        component={TextInput}
        name={VEHICLE_YEAR_FIELD_ID}
        label={translations.label.vehicleYear}
        placeholder={translations.label.vehicleYearPlaceholder}
        validate={useValidateVehicleYearMemo(translations)}
        suppress
      />
      <Field
        component={TextInput}
        name={VEHICLE_MAKE_FIELD_ID}
        label={translations.label.vehicleMake}
        placeholder={translations.label.vehicleMakePlaceholder}
        validate={useValidateVehicleMakeMemo(translations)}
        suppress
      />
      <Field
        component={TextInput}
        name={VEHICLE_MODEL_FIELD_ID}
        label={translations.label.vehicleModel}
        placeholder={translations.label.vehicleModelPlaceholder}
        validate={useValidateVehicleModelMemo(translations)}
        suppress
      />
    </>
  );
};
const CollectibleFields = ({ translations }) => {
  return (
    <>
      <Field
        name={COLLECTIBLE_TYPE_FIELD_ID}
        component={SelectInput}
        label={translations.label.collectibleType}
        placeholder={translations.label.collectibleTypePlaceholder}
        optionList={translations.collectibleTypeOptionList}
        validate={useValidateCollectibleTypeMemo(translations)}
        suppress
      />
      <Field
        component={TextInput}
        name={DESCRIPTION_FIELD_ID}
        label={translations.label.collectibleDescription}
        placeholder={translations.label.collectibleDescriptionPlaceholder}
        validate={useValidateCollectibleDescriptionMemo(translations)}
        suppress
      />
      <Field
        component={TextInput}
        name={COLLECTIBLE_LOCATION_FIELD_ID}
        label={translations.label.collectibleLocation}
        placeholder={translations.label.collectibleLocationPlaceholder}
        validate={useValidateCollectibleLocationMemo(translations)}
        suppress
      />
    </>
  );
};

const PhysicalAssetForm = ({
  error,
  handleSubmit,
  handleDelete,
  isLoading,
  translations,
  extraProps,
}) => {
  const { assetType, ownership, isEditingAsset, isRemovingAsset } = extraProps;
  const ctaCopy = isEditingAsset
    ? translations.button.update
    : translations.button.submit;
  const labelText = isEditingAsset
    ? translations.loadingLabel.update
    : translations.loadingLabel.submit;
  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" justifyContent="center" pt={1}>
        {!isEditingAsset && (
          <Field
            name={ASSET_TYPE_FIELD_ID}
            label={translations.assetType}
            placeholder={translations.assetTypePlaceholder}
            component={SelectInput}
            validate={useValidateAssetTypeMemo(translations)}
            optionList={isEditingAsset ? [] : translations.optionList}
            contentPush
          />
        )}
        {assetType === PROPERTY && (
          <PropertyFields translations={translations} ownership={ownership} />
        )}
        {assetType === VEHICLE && <VehicleFields translations={translations} />}
        {assetType === COLLECTIBLE && (
          <CollectibleFields translations={translations} />
        )}

        <Box>
          <PrimaryButton
            fullWidth
            isLoading={isLoading && !isRemovingAsset}
            loadingLabel={labelText}
            isDisabled={isRemovingAsset}
            text={ctaCopy}
          />
        </Box>
        {isEditingAsset && (
          <Box display="flex" flexDirection="column" alignItems="center" mt={1}>
            <TextButton
              text={translations.button.remove}
              isLoading={isRemovingAsset}
              loadingLabel={translations.loadingLabel.remove}
              onClick={handleDelete}
              isDisabled={isLoading}
              color="error"
              loadingColor="loadingError"
              className="qa-remove-asset-btn"
            />
          </Box>
        )}
        <ErrorMessage error={error} />
      </Box>
    </form>
  );
};

export default reduxForm({
  form: PHYSICAL_ASSETS_FORM_ID,
})(PhysicalAssetForm);
