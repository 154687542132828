export const updateFeatureFlags = (featureFlags) => ({
  type: UPDATE_FEATURE_FLAGS_TYPE,
  payload: { featureFlags },
});

export const getFeatureFlags = () => ({
  type: GET_FEATURE_FLAGS_TYPE,
});

export const UPDATE_FEATURE_FLAGS_TYPE = "UPDATE_FEATURE_FLAGS";
export const GET_FEATURE_FLAGS_TYPE = "GET_FEATURE_FLAGS";
