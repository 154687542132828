import React from "react";
import { Field } from "redux-form";
import RadioSelectorList from "./RadioSelectorList";

const BinaryRadioField = ({
  name,
  label,
  translations,
  optionList,
  validate,
  reverseOrder = false,
  onChange = () => {},
}) => (
  <Field
    component={RadioSelectorList}
    name={name}
    label={label}
    optionList={optionList || translations.optionList}
    validate={validate}
    reverseOrder={reverseOrder}
    onChange={onChange}
  />
);

export default BinaryRadioField;
