import R from "ramda";
import { createSelector, createStructuredSelector } from "reselect";

import { selectTranslations } from "./translations";
import { selectHasLoadedDataById } from "./requests";
import { buildFullName } from "../utilities/name";
import {
  CHILD_RELATIONSHIP,
  SPOUSE_OR_PARTNER_RELATIONSHIP,
} from "../constants/contacts";
import {
  MODAL_DELETE_CONTACT,
  MODAL_UNABLE_TO_DELETE_CONTACT,
} from "../constants/modal";
import { selectHasUserPaid } from "./plans";

const selectContactPageTranslations = selectTranslations([
  "global",
  "contactsPage",
]);

export const selectContactDropdownTranslations = createSelector(
  selectTranslations(["global", "contactsDropdown"]),
  selectHasUserPaid,
  (contactsDropdownTranslations, hasUserPaid) => {
    const manageMyContactsOption = hasUserPaid
      ? contactsDropdownTranslations.manageMyContactsOptionPaid
      : contactsDropdownTranslations.manageMyContactsOption;
    return {
      ...contactsDropdownTranslations,
      manageMyContactsOption,
    };
  },
);

export const selectLastSelectedContactData = R.path([
  "formUi",
  "lastSelectedContactData",
]);

export const selectLastContactableData = R.path([
  "formUi",
  "lastContactableData",
]);

export const selectLastSelectedContactFullName = createSelector(
  selectLastSelectedContactData,
  (contactData) => {
    if (!contactData) return "";
    const { firstName, middleName, lastName } = contactData;
    return buildFullName(firstName, middleName, lastName);
  },
);

export const selectContactModalDeleteTranslations = createSelector(
  selectTranslations(["notice"]),
  selectLastSelectedContactData,
  selectLastSelectedContactFullName,
  (translations, contactData, contactFullName) => {
    if (!contactData) return translations;
    const translationKey = contactData.hasRoles
      ? MODAL_UNABLE_TO_DELETE_CONTACT
      : MODAL_DELETE_CONTACT;
    const paragraph1 = translations[translationKey].paragraph1.replace(
      "{CONTACT}",
      contactFullName,
    );
    return {
      ...translations[translationKey],
      paragraph1,
    };
  },
);

export const selectLastSelectedContactEmail = createSelector(
  selectLastSelectedContactData,
  (contactData) => {
    if (!contactData) return "";
    const { email } = contactData;
    return email;
  },
);

export const selectContactRolesTranslations = createSelector(
  selectContactPageTranslations,
  (contactPageTranslations) => {
    return contactPageTranslations.roles;
  },
);

export const selectContactBadgesTranslations = createSelector(
  selectContactPageTranslations,
  (contactPageTranslations) => {
    return contactPageTranslations.badges;
  },
);

export const selectAllContacts = R.pathOr([], ["contacts"]);

export const selectContactById = (contactId) => {
  return createSelector(selectAllContacts, (allContacts) => {
    return (
      allContacts.find((contact) => contact.contact.id === contactId) || {}
    );
  });
};

// My household
export const selectSpouseContact = createSelector(
  selectAllContacts,
  (allContacts) => {
    return allContacts.filter(
      (item) => item.contact.relationship === SPOUSE_OR_PARTNER_RELATIONSHIP,
    );
  },
);

export const selectChildrenContacts = createSelector(
  selectAllContacts,
  (allContacts) => {
    // Get all children
    const children = allContacts.filter(
      (item) => item.contact.relationship === CHILD_RELATIONSHIP,
    );
    // Sort children by oldest to youngest
    const sortedChildren = children
      .sort(
        (a, b) =>
          new Date(b.contact.date_of_birth) - new Date(a.contact.date_of_birth),
      )
      .reverse();
    return sortedChildren;
  },
);

export const selectMyHouseholdContacts = createSelector(
  selectSpouseContact,
  selectChildrenContacts,
  (spouseContact, childrenContacts) => [...spouseContact, ...childrenContacts],
);

// Family and friends
export const selectFamilyAndFriendsContacts = createSelector(
  selectAllContacts,
  (allContacts) => {
    // Get all non-spouse, non-children contacts
    const friendsAndFamilyContacts = allContacts
      .filter((item) => item.contact.relationship !== CHILD_RELATIONSHIP)
      .filter(
        (item) => item.contact.relationship !== SPOUSE_OR_PARTNER_RELATIONSHIP,
      );
    // Sort contacts based on when they were added to DB chronologically
    const sortedFriendsAndFamily = friendsAndFamilyContacts
      .sort(
        (a, b) =>
          new Date(b.contact.created_at) - new Date(a.contact.created_at),
      )
      .reverse();
    return sortedFriendsAndFamily;
  },
);

export const contactPageProps = createStructuredSelector({
  translations: selectContactPageTranslations,
  hasLoaded: selectHasLoadedDataById("dashboard"),
});
