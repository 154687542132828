import React from "react";
import SvgIcon from "./SvgIcon";

const CIBCLogo = ({ height = 36 }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0.08 0.19 126.35 32.39"
      height={height}
    >
      <g clipPath="url(#clip0_20445_71535)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M102.224 31.9571H102.313H104.003C104.27 31.7792 104.537 31.5122 104.804 31.2453C105.071 30.8894 106.05 29.6437 107.206 27.9532L107.118 27.7753L98.8428 16.6533L98.5758 16.2974L98.8428 15.9415L107.118 4.73057L107.206 4.6416C106.05 3.04004 105.071 1.79438 104.804 1.3495C104.626 1.08257 104.359 0.815647 104.003 0.637695H102.224H102.135C101.334 0.637695 100.622 0.993598 100.088 1.61643L89.3224 16.2084L100.088 30.8004C100.533 31.4233 101.334 31.7792 102.135 31.7792C102.135 31.9571 102.135 31.9571 102.224 31.9571Z"
          fill="#B6B6B6"
        />
        <path
          d="M14.5829 25.4619C9.95613 25.4619 6.93096 21.9919 6.93096 16.3864C6.93096 10.692 9.95613 7.22191 14.5829 7.22191C16.0954 7.22191 17.5191 7.57781 18.7647 8.46756L22.2348 3.84083C20.0993 2.41722 18.0529 1.52747 13.96 1.52747C5.06247 1.52747 0.079834 7.48883 0.079834 16.3864C0.079834 25.4619 5.3294 31.1563 13.96 31.1563C19.4765 31.1563 21.523 29.2879 22.5017 28.5761L19.0316 23.9493C17.697 25.017 16.1844 25.4619 14.5829 25.4619Z"
          fill="white"
        />
        <path
          d="M76.5989 25.4619C71.9721 25.4619 68.947 21.9919 68.947 16.3864C68.947 10.692 71.9721 7.22191 76.5989 7.22191C78.1114 7.13293 79.624 7.57781 80.7807 8.46756L84.2508 3.84083C82.1153 2.41722 80.0689 1.52747 75.976 1.52747C67.1674 1.52747 62.0958 7.48883 62.0958 16.3864C62.0958 25.4619 67.3454 31.1563 75.976 31.1563C81.4925 31.1563 83.539 29.2879 84.5177 28.5761L81.0476 23.9493C79.891 24.9281 78.2894 25.4619 76.5989 25.4619Z"
          fill="white"
        />
        <path
          d="M53.6431 15.5857C55.8675 14.607 58.0919 12.8275 58.0919 9.3574C58.0919 4.55272 54.7108 2.0614 48.4826 2.0614H38.1614V30.6226H48.2156C55.2447 30.6226 59.1596 27.6864 59.1596 22.4368C59.1596 17.8991 56.2234 16.2975 53.6431 15.5857ZM44.8345 6.77711H47.5928C50.7959 6.77711 51.7746 8.55662 51.7746 10.1582C51.7746 12.4715 49.9951 13.4503 47.4148 13.4503H44.8345V6.77711V6.77711ZM47.6818 25.6399H44.8345V18.255H47.5928C50.529 18.255 52.4865 19.4116 52.4865 22.0809C52.4865 24.4833 50.529 25.6399 47.6818 25.6399Z"
          fill="white"
        />
        <path
          d="M32.0221 2.0614H25.4379V30.6226H32.0221V2.0614Z"
          fill="white"
        />
        <path
          d="M99.2876 16.3864L107.562 5.17547L107.206 4.73059L98.6648 16.3864L107.206 27.9532L107.562 27.5083L99.2876 16.3864Z"
          fill="white"
        />
        <path
          d="M115.392 1.43853C114.858 0.637749 113.88 0.192871 112.901 0.192871H102.224H102.135C101.156 0.192871 100.266 0.637749 99.7325 1.43853L88.6106 16.3864L99.6436 31.3343C100.177 32.1351 101.156 32.58 102.046 32.58H102.135C102.58 32.58 102.847 32.58 102.847 32.58C102.847 32.58 102.669 32.58 102.224 32.58H112.812C113.791 32.58 114.769 32.1351 115.392 31.3343L126.425 16.3864L115.392 1.43853ZM104.804 31.2454C104.626 31.5123 104.359 31.7792 104.003 31.9572H102.313H102.224H102.135C101.334 31.9572 100.622 31.6013 100.088 30.9784L89.3224 16.3864L100.088 1.79443C100.533 1.1716 101.334 0.8157 102.135 0.8157H102.224H104.003C103.825 0.637749 104.448 0.993652 104.804 1.52751C105.872 2.86214 115.748 16.3864 115.748 16.3864C115.748 16.3864 105.872 29.8218 104.804 31.2454Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_20445_71535">
          <rect
            width="126.345"
            height="32.2981"
            fill="white"
            transform="translate(0.079834 0.192871)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default CIBCLogo;
