import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import ChildrenForm from "../../../components/forms/your-family/ChildrenForm";
import { childrenPageProps } from "../../../selectors/children";
import { getFormData } from "../../../actions/forms";
import {
  childrenNextPage,
  openModalChildForm,
  updateChildren,
} from "../../../actions/children";
import { updateUser } from "../../../actions/user";
import { CHILDREN_FORM_ID } from "../../../constants/forms";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";

const ChildrenPage = ({
  getFormData,
  updateChildren,
  openModalChildForm,
  hasLoaded,
  updateUser,
  backLink,
  ...restOfProps
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    getFormData(CHILDREN_FORM_ID);
  }, [getFormData]);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <ChildrenForm
      backLink={backLink}
      onSubmit={() => dispatch(childrenNextPage())}
      openModalAddChildren={openModalChildForm}
      updateChildren={updateChildren}
      {...restOfProps}
    />
  );
};

export default connect(childrenPageProps, {
  getFormData,
  updateChildren,
  updateUser,
  openModalChildForm,
})(ChildrenPage);
