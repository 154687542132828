import React from "react";
import { reduxForm } from "redux-form";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { Form } from "../../ui/Form";
import RadioListField from "../../ui/inputs/RadioListField";
import { ErrorMessage } from "../../ui/Error";
import { SmallBody, SmallExternalLink } from "../../ui/Typography";
import { useValidatePrimaryBeneficiaryMemo } from "../../ui/inputs/validations";
import {
  PRIMARY_BENEFICIARY_FORM_ID,
  PRIMARY_BENEFICIARY_FIELD_ID,
} from "../../../constants/forms";

const FamilyPatrimonyInfo = ({ text, link, addInfo }) => {
  return (
    <Grid container justify="center" spacing={2}>
      <Grid xs={12} md={8} lg={6}>
        <Box mt={1.5} textAlign="center">
          <SmallBody margin="16px" align="center">
            {text}
          </SmallBody>
          {link && (
            <SmallExternalLink target="_blank" href={link} text={addInfo} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const PrimaryBeneficiaryForm = ({
  error,
  handleSubmit,
  backLink,
  isLoading,
  translations,
  requiresFamilyPatrimonyInfo,
}) => {
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Form
      isLoading={isLoading}
      onSubmit={handleSubmit}
      backLink={backLink}
      HeaderComponent={requiresFamilyPatrimonyInfo && FamilyPatrimonyInfo}
      text={translations.familyPatrimonyInfo}
      addInfo={translations.familyPatrimonyTextLink}
      link={translations.familyPatrimonyLink}
      translations={translations}
      suppress="true"
      reverseWrap={isTabletDown}
    >
      <RadioListField
        name={PRIMARY_BENEFICIARY_FIELD_ID}
        validate={useValidatePrimaryBeneficiaryMemo(translations)}
        label={translations.primaryBeneficiaryLabel}
        optionList={translations.optionList}
        suppress="true"
      />
      <ErrorMessage error={error} />
    </Form>
  );
};

export default reduxForm({
  form: PRIMARY_BENEFICIARY_FORM_ID,
})(PrimaryBeneficiaryForm);
