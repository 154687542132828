import R from "ramda";
import queryString from "query-string";

import { createStructuredSelector, createSelector } from "reselect";
import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";
import { selectTranslations } from "./translations";
import {
  selectIsCouplesPlan,
  selectActivePlanSymbol,
  selectIsNotarialWill,
  selectHasUserPaid,
  selectIsUpgradableUser,
} from "./plans";
import { selectIsCompleteAllSections } from "./section-completion";
import { selectSpouseFirstName } from "./spouse";
import { selectFirstName } from "./about-you";
import { selectAuthHeaders } from "./auth";
import { selectYourPlanProps } from "./your-plan";
import { selectIsFrenchSelected } from "./language";
import {
  selectIsCurrentProvinceSelectedByCode,
  selectSelectedProvince,
} from "./provinces";

import {
  NOTARIAL_WILL,
  USER_POA_PERSONAL,
  USER_POA_PROPERTY,
  USER_WILL,
  plansDocumentsKeysMap,
} from "../constants/documents";
import { UPSELL_FORM_ID } from "../constants/forms";
import { selectHasAssets } from "./asset-list";
import { formatLocaleDateString } from "../utilities/date";
import {
  selectUserHasCustomFeatureAccess,
  selectUserHasUpdateWillFunctionality,
  selectUserHasUpdatePOAFunctionality,
  selectUserHasUpdateAssetsListFunctionality,
} from "./features";
import { selectNextOverviewPathForDashboard } from "./dashboard";

const selectUserNoticeConnectCoupon = R.pathOr("", [
  "documents",
  "documentLinks",
  "noticeConnectCoupon",
]);
const selectSpouseNoticeConnectCoupon = R.pathOr("", [
  "documents",
  "documentLinks",
  "spouseNoticeConnectCoupon",
]);

const selectUserCwrCodes = R.pathOr(
  [],
  ["documents", "documentLinks", "userCwrCodes"],
);
const selectFormattedUserCwrCodes = createSelector(
  selectUserCwrCodes,
  selectIsFrenchSelected,
  (userCwrCodes, isFrenchSelected) => {
    return userCwrCodes.map((cwrCode) => {
      const { code, created_at } = cwrCode;
      const dateFormatOptions = {
        month: "long",
        day: "numeric",
        year: "numeric",
      };
      const formattedDate = formatLocaleDateString(
        new Date(created_at),
        isFrenchSelected,
        dateFormatOptions,
      );
      return {
        code,
        created_at: formattedDate,
      };
    });
  },
);

const getDocumentsPageDescription = (translations, hasUserPaid) => {
  const { pageDescriptionUnpaid, pageDescriptionPaid } = translations;

  return !hasUserPaid ? pageDescriptionUnpaid : pageDescriptionPaid;
};

const selectDocumentsTranslations = createSelector(
  // Can this be removed or modified? The translation key documentNames no longer exists
  selectTranslations(["global", "documents", "links", "yourPlanTranslations"]),
  selectFirstName,
  selectSpouseFirstName,
  selectUserNoticeConnectCoupon,
  selectSpouseNoticeConnectCoupon,
  selectUserCwrCodes,
  selectHasUserPaid,
  (
    translations,
    userName,
    spouseName,
    userNoticeConnectCoupon,
    spouseNoticeConnectCoupon,
    userCwrCodes,
    hasUserPaid,
  ) => {
    const spouseNameWithDefault = spouseName || translations.spouse;
    let documentsTitle = translations.genericDocumentsTitle;

    if (userName) {
      documentsTitle = translations.documentsTitle.replace(
        "USER_NAME",
        userName,
      );
    }
    const firstCoupon =
      userCwrCodes.length > 0 ? userCwrCodes[0].code : userNoticeConnectCoupon;

    return {
      ...translations,
      documentsTitle,
      pageDescription: getDocumentsPageDescription(translations, hasUserPaid),
      bulkPurchaseGifts: translations.bulkPurchaseGifts,
      userDocumentsTitle: translations.userDocumentsTitle.replace(
        "USER_NAME",
        userName,
      ),
      spouseDocumentsTitle: translations.spouseDocumentsTitle.replace(
        "SPOUSE_NAME",
        spouseNameWithDefault,
      ),
      userRegistryContentTitle: translations.userRegistryContentTitle.replace(
        "FIRST_NAME",
        userName,
      ),
      spouseRegistryContentTitle: translations.spouseRegistryContentTitle.replace(
        "SPOUSE_FIRST_NAME",
        spouseNameWithDefault,
      ),
      willRegistryCouponSpouse: spouseNoticeConnectCoupon,
      willRegistryCouponUser: firstCoupon,
      registryDescription: translations.registryDescription,
      userRegistryContent: translations.userRegistryContentInactiveOrIncomplete,
      copyCodeText: translations.copyCodeText,
      willRegistryValue: "",
    };
  },
);

const selectDocumentLinks = R.pathOr({}, ["documents", "documentLinks"]);

export const selectDocumentLink = (key, isDownload, isSpouse) =>
  createSelector(
    selectDocumentLinks,
    selectAuthHeaders,
    (links, authCredentials) => {
      const url = R.propOr("", key, links);
      const params = {
        ...authCredentials,
        download: isDownload || undefined,
        spouse_nav: isSpouse || undefined,
      };
      const paramString = queryString.stringify(params);
      return `${url}?${paramString}`;
    },
  );

export const selectDocumentKeysForPlan = createSelector(
  selectActivePlanSymbol,
  selectSelectedProvince,
  (plan, selectedProvince) => {
    if (
      (selectedProvince.code === "QC" && plan === "essentials") ||
      selectedProvince.code === "NB" ||
      selectedProvince.code === "NL" ||
      selectedProvince.code === "PE"
    ) {
      return plansDocumentsKeysMap.ESSENTIALS_ONLY;
    }

    return plansDocumentsKeysMap[plan];
  },
);

const selectIsQuebecWillSelected = createSelector(
  selectIsCurrentProvinceSelectedByCode("QC"),
  selectYourPlanProps,
  (isQuebecSelected, yourPlanProps) => {
    return yourPlanProps.activePlan.symbol === "essentials" && isQuebecSelected;
  },
);

const selectShouldDisplayNoticeConnect = createSelector(
  selectSelectedProvince,
  selectUserNoticeConnectCoupon,
  (selectedProvince, noticeConnectCoupon) => {
    return !!noticeConnectCoupon && selectedProvince.code !== "QC";
  },
);

const selectShouldDisplayCwrCodes = createSelector(
  selectUserCwrCodes,
  (userCwrCodes) => {
    return !!userCwrCodes && userCwrCodes.length !== 0;
  },
);

const selectIsDocumentsAvailable = createSelector(
  selectHasUserPaid,
  selectIsCompleteAllSections,
  (isPaid, isCompleteAllSections) => {
    return isPaid && isCompleteAllSections;
  },
);

const selectShouldShowBill21Content = createSelector(
  selectIsDocumentsAvailable,
  selectSelectedProvince,
  (isDocumentsAvailable, selectedProvince) =>
    isDocumentsAvailable && selectedProvince.code === "BC",
);

const getLegalDocumentsLockCopy = (
  documentTranslations,
  isPaid,
  isWillDocumentAndHasUploadWillFunctionality,
  isUserPOAAndHasUploadPOAFunctionality,
  isDocumentsAvailable,
) => {
  const { unpaid, paidAndIncomplete } = documentTranslations;

  if (!isPaid) {
    return unpaid;
  }
  if (isDocumentsAvailable) {
    return "";
  }
  if (
    isWillDocumentAndHasUploadWillFunctionality ||
    isUserPOAAndHasUploadPOAFunctionality
  ) {
    return paidAndIncomplete;
  }
  return "";
};

const getAssetListDocumentLockCopy = (
  documentTranslations,
  isPaid,
  userHasUpdateAssetsListFunctionality,
  hasAssets,
) => {
  const { unpaid, paidAndIncomplete } = documentTranslations;

  if (!isPaid) {
    return unpaid;
  }
  if (hasAssets) {
    return "";
  }
  if (userHasUpdateAssetsListFunctionality) {
    return paidAndIncomplete;
  }
  return "";
};

export const selectDocumentCardCopy = createSelector(
  selectActivePlanSymbol,
  selectSelectedProvince,
  selectDocumentsTranslations,
  selectIsDocumentsAvailable,
  selectHasUserPaid,
  selectHasAssets,
  selectUserHasUpdateWillFunctionality,
  selectUserHasUpdatePOAFunctionality,
  selectUserHasUpdateAssetsListFunctionality,
  (
    plan,
    selectedProvince,
    translations,
    isDocumentsAvailable,
    isPaid,
    hasAssets,
    userHasUpdateWillFunctionality,
    userHasUpdatePOAFunctionality,
    userHasUpdateAssetsListFunctionality,
  ) => {
    let activePlan = plan;

    if (
      (selectedProvince.code === "QC" && plan === "essentials") ||
      selectedProvince.code === "NB" ||
      selectedProvince.code === "NL" ||
      selectedProvince.code === "PE"
    ) {
      activePlan = "ESSENTIALS_ONLY";
    }

    const updatedUserDocuments =
      plansDocumentsKeysMap &&
      plansDocumentsKeysMap[activePlan] &&
      plansDocumentsKeysMap[activePlan].userDocuments.map((document) => {
        const documentName = translations[document.key].name;
        const documentInfo = translations[document.key].info;

        const { upgradeInfo } = translations[document.key];

        const isWillDocumentAndHasUploadWillFunctionality =
          (document.key === USER_WILL || document.key === NOTARIAL_WILL) &&
          userHasUpdateWillFunctionality;

        const isUserPOAAndHasUploadPOAFunctionality =
          (document.key === USER_POA_PROPERTY ||
            document.key === USER_POA_PERSONAL) &&
          userHasUpdatePOAFunctionality;

        const documentLockCopy = getLegalDocumentsLockCopy(
          translations[document.key],
          isPaid,
          isWillDocumentAndHasUploadWillFunctionality,
          isUserPOAAndHasUploadPOAFunctionality,
          isDocumentsAvailable,
        );

        return {
          ...document,
          documentName,
          documentInfo,
          documentLockCopy,
          upgradeInfo,
        };
      });

    const updatedSpouseDocuments =
      (plansDocumentsKeysMap &&
        plansDocumentsKeysMap[activePlan] &&
        plansDocumentsKeysMap[activePlan].spouseDocuments &&
        plansDocumentsKeysMap[activePlan].spouseDocuments.map((document) => {
          const documentName = translations[document.key].name;
          const documentInfo = translations[document.key].info;

          const { unpaid } = translations[document.key];

          const documentLockCopy = unpaid;

          return {
            ...document,
            documentName,
            documentInfo,
            documentLockCopy,
          };
        })) ||
      undefined;

    const updatedAssetListDocuments =
      (plansDocumentsKeysMap &&
        plansDocumentsKeysMap[activePlan] &&
        plansDocumentsKeysMap[activePlan].assetListDocuments &&
        plansDocumentsKeysMap[activePlan].assetListDocuments.map((document) => {
          const documentName = translations[document.key].name;
          const documentInfo = translations[document.key].info;

          const { upgradeInfo } = translations[document.key];

          const documentLockCopy = getAssetListDocumentLockCopy(
            translations[document.key],
            isPaid,
            userHasUpdateAssetsListFunctionality,
            hasAssets,
          );

          return {
            ...document,
            documentName,
            documentInfo,
            documentLockCopy,
            upgradeInfo,
          };
        })) ||
      undefined;

    return {
      ...plansDocumentsKeysMap[activePlan],
      userDocuments: updatedUserDocuments,
      spouseDocuments: updatedSpouseDocuments,
      assetListDocuments: updatedAssetListDocuments,
    };
  },
);

export const documentsPageProps = createStructuredSelector({
  headerProps: createStructuredSelector({
    translations: selectDocumentsTranslations,
    isPaid: selectHasUserPaid,
    isCompleteAllSections: selectIsCompleteAllSections,
    shouldDisplayNoticeConnect: selectShouldDisplayNoticeConnect,
    shouldDisplayCWRCodes: selectShouldDisplayCwrCodes,
    documentKeys: selectDocumentKeysForPlan,
    nextSectionPath: selectNextOverviewPathForDashboard,
    userHasCustomFeatureAccess: selectUserHasCustomFeatureAccess,
    userHasUpdateWillFunctionality: selectUserHasUpdateWillFunctionality,
  }),
  legalDocumentsProps: createStructuredSelector({
    translations: selectDocumentsTranslations,
    isNotarialWill: selectIsNotarialWill,
    isUpgradeLoading: selectIsLoadingById(UPSELL_FORM_ID),
    isQuebecWill: selectIsQuebecWillSelected,
    isDocumentsAvailable: selectIsDocumentsAvailable,
    shouldShowBill21Content: selectShouldShowBill21Content,
    documentKeys: selectDocumentKeysForPlan,
    isPaid: selectHasUserPaid,
    documentCardCopy: selectDocumentCardCopy,
    isUpgradableUser: selectIsUpgradableUser,
  }),
  assetListProps: createStructuredSelector({
    translations: selectDocumentsTranslations,
    hasAssets: selectHasAssets,
    isDocumentsAvailable: selectIsDocumentsAvailable,
    isUpgradeLoading: selectIsLoadingById(UPSELL_FORM_ID),
    isPaid: selectHasUserPaid,
    documentKeys: selectDocumentKeysForPlan,
    documentCardCopy: selectDocumentCardCopy,
  }),
  notarialWillProps: createStructuredSelector({
    translations: selectDocumentsTranslations,
    isNotarialWill: selectIsNotarialWill,
    isFrenchSelected: selectIsFrenchSelected,
    yourPlan: selectYourPlanProps,
  }),
  hasLoaded: selectHasLoadedDataById("documents"),
  translations: selectDocumentsTranslations,
  isCouplesPlan: selectIsCouplesPlan,
  shouldDisplayNoticeConnect: selectShouldDisplayNoticeConnect,
  shouldDisplayCWRCodes: selectShouldDisplayCwrCodes,
  userCwrCodes: selectFormattedUserCwrCodes,
  shouldShowBill21Content: selectShouldShowBill21Content,
  isCompleteAllSections: selectIsCompleteAllSections,
  isPaid: selectHasUserPaid,
});
