import React from "react";

import SvgIcon from "./SvgIcon";

const PresentIcon = ({ isDesktop }) => {
  return (
    <SvgIcon width={isDesktop ? 60 : 60} viewBox="0 0 48 45.78">
      <path
        d="M13.07,9.92,19,13.27l-.77-5.84Zm10.14,35.7,4.43.16L48,34.2,30.55,23.82Z"
        fill="#2f455f"
        fillRule="evenodd"
      />
      <g>
        <path
          d="M0,32.57l8.86,5L11,38.75l12.15,6.91,17.57-10V13.09L0,10.05Z"
          fill="#e1f3f9"
          fillRule="evenodd"
        />
        <path
          d="M17.57,0,0,10.05l8.86,5L11,16.23l12.15,6.91,17.57-10L28.53,6.18,24.18,3.71Z"
          fill="#abe0f6"
          fillRule="evenodd"
        />
        <path
          d="M9,15.15V37.67l4.35,2.47V17.62Z"
          fill="#71adee"
          fillRule="evenodd"
        />
        <path
          d="M23.11,23.14V45.65l17.57-10V13.08Z"
          fill="#71adee"
          fillRule="evenodd"
        />
        <path
          d="M30.92,7.57,26.57,5.1l-2,1.16L21.49,8l-7.41,4.24L11,14,9,15.15l4.35,2.47,2-1.16,3-1.73,7.48-4.28,3-1.72Z"
          fill="#71adee"
          fillRule="evenodd"
        />
        <path
          d="M34.14,16.83l-7.69-4.29-3.07-1.7L15.92,6.68,12.85,5l-2-1.14L6.47,6.35l6.41,3.57,3,1.68,7.54,4.2,3,1.68,3.3,1.84Z"
          fill="#71adee"
          fillRule="evenodd"
        />
        <path
          d="M29.78,19.32V41.84l4.36-2.5V16.83Z"
          fill="#0064dc"
          fillRule="evenodd"
        />
        <path
          d="M20,13.75s6.72-3.29,9.05-.92S23.71,16.15,20,13.75Z"
          fill="#bc7f35"
          fillRule="evenodd"
        />
        <path
          d="M34,11.1l-4.57,2.46s-.73-2.23-4.52-1.45-5,1.64-5,1.64L24.53,11s5.2-2.63,8-1.54C34.22,10.1,34,11.1,34,11.1Z"
          fill="#ffdc1e"
          fillRule="evenodd"
        />
        <path
          d="M34,11.1l-4.57,2.46s.1.6-.76,1l4.59-2.32S34.09,11.61,34,11.1Z"
          fill="#e2a03b"
          fillRule="evenodd"
        />
        <path
          d="M15.68,11.36s-2.88-8.61-5-7.82C7.48,4.7,11.82,9,15.68,11.36Z"
          fill="#bc7f35"
          fillRule="evenodd"
        />
        <path
          d="M14.42,1.36,10.06,3.83s1.65-.5,3.33,3.16a27,27,0,0,0,2.29,4.37L20,8.91s-.89-5.13-3.32-7C15.24.88,14.42,1.36,14.42,1.36Z"
          fill="#ffdc1e"
          fillRule="evenodd"
        />
        <path
          d="M20,13.73s-6.72-3.29-9-.92S16.21,16.13,20,13.73Z"
          fill="#bc7f35"
          fillRule="evenodd"
        />
        <path
          d="M5.9,11.08l4.57,2.46s.72-2.23,4.52-1.45,5,1.64,5,1.64L15.39,11s-5.2-2.63-8.06-1.54C5.69,10.08,5.9,11.08,5.9,11.08Z"
          fill="#ffdc1e"
          fillRule="evenodd"
        />
        <path
          d="M5.9,11.08l4.57,2.46s-.1.61.75,1L6.64,12.24S5.83,11.59,5.9,11.08Z"
          fill="#e2a03b"
          fillRule="evenodd"
        />
        <path
          d="M24.24,11.48s2.87-8.62,5.05-7.82C32.43,4.82,28.1,9.08,24.24,11.48Z"
          fill="#bc7f35"
          fillRule="evenodd"
        />
        <path
          d="M25.49,1.48,29.86,4s-1.65-.5-3.34,3.16a27.92,27.92,0,0,1-2.28,4.37L20,9s.89-5.13,3.32-7C24.67,1,25.49,1.48,25.49,1.48Z"
          fill="#ffdc1e"
          fillRule="evenodd"
        />
        <path
          d="M24.24,11.44,20,8.85l-4.33,2.51,4.19,2.39Z"
          fill="#e2a03b"
          fillRule="evenodd"
        />
      </g>
    </SvgIcon>
  );
};

export default PresentIcon;
