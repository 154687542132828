import { INITIALIZE_GA4_PARAMETERS_TYPE } from "../actions/analytics";

const ga4Parameters = (state = {}, { type, payload }) => {
  switch (type) {
    case INITIALIZE_GA4_PARAMETERS_TYPE:
      return payload;
    default:
      return state;
  }
};

export default ga4Parameters;
