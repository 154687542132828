export const updatePets = () => ({
  type: UPDATE_PETS_TYPE,
});

export const openModalPet = (pet = null) => ({
  type: OPEN_MODAL_PET_TYPE,
  payload: { pet },
});

export const removePet = (pet) => ({
  type: REMOVE_PET_TYPE,
  payload: { pet },
});

export const petUpdatedSuccessfully = (pet) => ({
  type: UPDATE_PET_SUCCESSFULLY_TYPE,
  payload: { pet },
});

export const petsNextPage = (showZeroPetsNotice) => ({
  type: PETS_NEXT_PAGE_TYPE,
  payload: { showZeroPetsNotice },
});

export const UPDATE_PETS_TYPE = "UPDATE_PETS";
export const OPEN_MODAL_PET_TYPE = "OPEN_MODAL_PET";
export const REMOVE_PET_TYPE = "REMOVE_PET";
export const UPDATE_PET_SUCCESSFULLY_TYPE = "UPDATE_PET_SUCCESSFULLY";
export const PETS_NEXT_PAGE_TYPE = "PETS_NEXT_PAGE";
