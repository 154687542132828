import React from "react";
import { reduxForm } from "redux-form";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { Form } from "../../ui/Form";
import RadioListField from "../../ui/inputs/RadioListField";
import { ErrorMessage } from "../../ui/Error";
import { useValidatePetTrustAmountMemo } from "../../ui/inputs/validations";

import {
  PET_TRUST_AMOUNT_FIELD_ID,
  PET_TRUST_FORM_ID,
} from "../../../constants/forms";
import H2WithUnderlineLink from "../../ui/H2WithUnderlineLink";
import { displayTooltipModal } from "../../../actions/tooltip-modal";

const PetGuardiansForm = ({
  error,
  handleSubmit,
  backLink,
  translations,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerWithLink = (
    <H2WithUnderlineLink
      text={translations.pageTitle}
      underlineText={translations.pageTitleUnderlineLink}
      onClick={() =>
        dispatch(displayTooltipModal(translations.pageTitleModalKey))
      }
    />
  );

  return (
    <Form
      isLoading={isLoading}
      onSubmit={handleSubmit}
      backLink={backLink}
      translations={translations}
      reverseWrap={isTabletDown}
      customHeaderComponent={headerWithLink}
    >
      <RadioListField
        name={PET_TRUST_AMOUNT_FIELD_ID}
        optionList={translations.petTrustAmountOptions}
        validate={useValidatePetTrustAmountMemo(translations)}
        label={translations.petTrustLabel}
      />
      <ErrorMessage error={error} />
    </Form>
  );
};

export default reduxForm({
  form: PET_TRUST_FORM_ID,
})(PetGuardiansForm);
