import React, { useEffect } from "react";
import { connect } from "react-redux";

import LifeProlongForm from "../../../components/forms/your-arrangements/LifeProlongForm";

import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { lifeProlongPageProps } from "../../../selectors/healthcare";
import { PERSONAL_ATTORNEYS_PATH } from "../../../constants/routes";
import { LIFE_PROLONG_FORM_ID } from "../../../constants/forms";
import { updateLifeProlong } from "../../../actions/healthcare";
import { skipSection } from "../../../actions/navigation";
import { getFormData } from "../../../actions/forms";
import { skipHealthcare } from "../../../api/healthcare";

const LifeProlongPage = ({
  getFormData,
  updateLifeProlong,
  hasLoaded,
  skipSection,
  ...restOfProps
}) => {
  useEffect(() => {
    getFormData(LIFE_PROLONG_FORM_ID);
  }, []);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <LifeProlongForm
      backLink={PERSONAL_ATTORNEYS_PATH}
      onSubmit={() => updateLifeProlong()}
      onSkip={() => skipSection(skipHealthcare, LIFE_PROLONG_FORM_ID)}
      {...restOfProps}
    />
  );
};

export default connect(lifeProlongPageProps, {
  getFormData,
  updateLifeProlong,
  skipSection,
})(LifeProlongPage);
