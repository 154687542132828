import React from "react";

const SvgIcon = ({ children, title, viewBox, ...props }) => (
  <svg
    focusable="false"
    viewBox={viewBox}
    aria-hidden={title ? "false" : "true"}
    role={title ? "img" : "presentation"}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    {children}
  </svg>
);

export default SvgIcon;
