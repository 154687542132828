import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { getFormValues } from "redux-form";

import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";

import {
  PROPERTY_ATTORNEYS_FORM_ID,
  PROPERTY_ATTORNEYS_FIELD_ID,
  SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
} from "../constants/forms";

import { selectTranslations } from "./translations";
import {
  selectHasSpouse,
  selectSpouseFirstName,
  selectIsCurrentUserSpouse,
  selectSpouseLastName,
} from "./spouse";
import { selectAppointeeLevelIndexs } from "./utils/form-appointees";
import { selectIsFieldComplete } from "./utils/progress";
import { selectFirstName } from "./about-you";
import { selectSelectedProvince } from "./provinces";
import { buildFullName } from "../utilities/name";

const selectArrangementsData = R.pathOr({}, ["yourArrangements"]);

export const selectFormSpouseIsPropertyAttorney = createSelector(
  getFormValues(PROPERTY_ATTORNEYS_FORM_ID),
  (formValues) =>
    R.propOr(false, SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID)(formValues),
);

export const selectSpouseIsPropertyAttorney = R.path([
  "yourArrangements",
  SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
]);

export const selectPropertyAttorneys = R.path([
  "yourArrangements",
  PROPERTY_ATTORNEYS_FIELD_ID,
]);

export const selectSpouseSpouseIsPropertyAttorney = R.path([
  "spouseArrangements",
  SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
]);
export const selectSpousePropertyAttorneys = R.path([
  "spouseArrangements",
  PROPERTY_ATTORNEYS_FIELD_ID,
]);

export const selectLastSelectedPropertyAttorneyData = R.path([
  "formUi",
  "lastSelectedPropertyAttorneyData",
]);

export const selectLastSelectedPropertyAttorneyFullNameAndTitle = createSelector(
  selectLastSelectedPropertyAttorneyData,
  selectTranslations(["yourArrangements", "appointees"]),
  (propertyAttorneyData, translations) => {
    if (!propertyAttorneyData) return "";
    const { firstName, middleName, lastName } = propertyAttorneyData;
    return {
      fullName: buildFullName(firstName, middleName, lastName),
      appointeeTitle: translations.propertyAppointeeTitle || "",
    };
  },
);

export const selectPropertyAttorneysForm = createSelector(
  selectIsCurrentUserSpouse,
  selectPropertyAttorneys,
  selectSpouseIsPropertyAttorney,
  selectSpousePropertyAttorneys,
  selectSpouseSpouseIsPropertyAttorney,
  (
    isSpouse,
    propertyAttorneys,
    spouseIsPropertyAttorney,
    spousePA,
    spouseSpouseIsPA,
  ) => ({
    [PROPERTY_ATTORNEYS_FIELD_ID]: isSpouse ? spousePA : propertyAttorneys,
    [SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID]: isSpouse
      ? spouseSpouseIsPA
      : spouseIsPropertyAttorney,
  }),
);

// TODO: Add homestead act validation for provinces that require 2 PA's
export const selectIsPropertyAttorneysComplete = createSelector(
  selectIsFieldComplete(selectArrangementsData, PROPERTY_ATTORNEYS_FIELD_ID),
  selectIsFieldComplete(
    selectArrangementsData,
    SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
    true,
  ),
  selectHasSpouse,
  (propertyAttorneyIsComplete, spouseIsPAIsComplete, hasSpouse) => {
    if (hasSpouse) {
      return spouseIsPAIsComplete || propertyAttorneyIsComplete;
    }
    return propertyAttorneyIsComplete;
  },
);

export const selectPropertyAttorneysTranslations = createSelector(
  selectTranslations([
    "global",
    PROPERTY_ATTORNEYS_FORM_ID,
    "provinces",
    "appointees",
    "navigation",
    "notice",
  ]),
  selectHasSpouse,
  selectIsCurrentUserSpouse,
  selectFirstName,
  selectSpouseFirstName,
  selectSpouseLastName,
  (
    translations,
    hasSpouse,
    isSpouse,
    firstName,
    spouseFirstName,
    spouseLastName,
  ) => {
    const {
      userSpousePropertyAttorneyQuestion,
      propertyAppointeeTitle,
      propertyAppointeeTitlePlural,
      subQuestionOptional,
    } = translations;

    const userSpousePropertyAttorneyQuestionTranslation = hasSpouse
      ? userSpousePropertyAttorneyQuestion.replace(
          "SPOUSE_NAME",
          isSpouse ? firstName : `${spouseFirstName} ${spouseLastName}`,
        )
      : "";

    const subQuestionOptionalWithTitle = subQuestionOptional.replace(
      "{PROPERTY_APPOINTEE}",
      (propertyAppointeeTitlePlural &&
        propertyAppointeeTitlePlural.toLowerCase()) ||
        "",
    );

    return {
      ...translations,
      subQuestionOptional: subQuestionOptionalWithTitle,
      userSpousePropertyAttorneyQuestion: userSpousePropertyAttorneyQuestionTranslation,
      appointeeTitle: propertyAppointeeTitle,
      button: {
        ...translations.button,
        addLabel: translations.button.addLabel.replace(
          "PROPERTY_APPOINTEE",
          propertyAppointeeTitle,
        ),
        addMoreLabel: translations.button.addMoreLabel.replace(
          "PROPERTY_APPOINTEE",
          propertyAppointeeTitle,
        ),
        removeLabel: translations.button.removeLabel.replace(
          "PROPERTY_APPOINTEE",
          propertyAppointeeTitle,
        ),
      },
    };
  },
);

const selectCanAddMorePropertyAttorneys = createSelector(
  selectSelectedProvince,
  selectAppointeeLevelIndexs(PROPERTY_ATTORNEYS_FORM_ID),
  (province, appointeeLevelIndexs) => {
    // Provinces outside BC can have as many property attorneys as they want
    if (province.code !== "BC") return true;
    // We only allow 2 property attorneys for BC because the document
    // signatures are getting truncated after the third backup
    const hasOnePOA = appointeeLevelIndexs.total < 1;
    if (hasOnePOA) return true;
    return false;
  },
);

const selectShouldShowCTAButton = createSelector(
  selectFormSpouseIsPropertyAttorney,
  selectHasSpouse,
  (isSpousePropertyAttorney, hasSpouse) => {
    return !hasSpouse || isSpousePropertyAttorney !== "";
  },
);

export const propertyAttorneysPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(PROPERTY_ATTORNEYS_FORM_ID),
  isLoading: selectIsLoadingById(PROPERTY_ATTORNEYS_FORM_ID),
  appointeeLevelIndexs: selectAppointeeLevelIndexs(PROPERTY_ATTORNEYS_FORM_ID),
  spouseIsPropertyAttorney: selectFormSpouseIsPropertyAttorney,
  hasSpouse: selectHasSpouse,
  translations: selectPropertyAttorneysTranslations,
  tooltipKey: () => "propertyattorney.sectionPage",
  canAddMore: selectCanAddMorePropertyAttorneys,
  shouldShowCTAButton: selectShouldShowCTAButton,
});
