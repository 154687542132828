import React, { useEffect } from "react";
import { connect } from "react-redux";

import ResidenceForm from "../../../components/forms/about-you/ResidenceForm";
import { submitAboutYou, fetchResidence } from "../../../actions/about-you";
import { residencePageProps } from "../../../selectors/about-you";
import { RESIDENCE_FORM_ID } from "../../../constants/forms";
import { submitLockedProvinceLead } from "../../../actions/locked-province";

const ResidencePage = ({
  submitAboutYou,
  submitLockedProvinceLead,
  fetchResidence,
  hasLoaded,
  isProvinceLocked,
  ...restOfProps
}) => {
  useEffect(() => {
    fetchResidence();
  }, [fetchResidence]);
  if (!hasLoaded) return null;
  return (
    <ResidenceForm
      isProvinceLocked={isProvinceLocked}
      onSubmit={
        isProvinceLocked
          ? () => submitLockedProvinceLead()
          : () => submitAboutYou(RESIDENCE_FORM_ID)
      }
      {...restOfProps}
    />
  );
};

export default connect(residencePageProps, {
  submitLockedProvinceLead,
  submitAboutYou,
  fetchResidence,
})(ResidencePage);
