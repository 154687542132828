import React from "react";

import { reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";

import PasswordField from "../../ui/inputs/PasswordField";
import { PrimaryButton } from "../../ui/Button";
import { Body } from "../../ui/Typography";
import { ErrorMessage } from "../../ui/Error";
import { DELETE_ACCOUNT_FORM_ID } from "../../../constants/forms";
import { useValidateLegacyPasswordMemo } from "../../ui/inputs/validations";

const DeleteAccountForm = ({
  error,
  handleSubmit,
  isLoading,
  translations,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Body>{translations.body}</Body>
        <Body>{translations.instructions}</Body>

        <PasswordField
          noMargin
          translations={translations}
          validate={useValidateLegacyPasswordMemo(translations)}
        />
        <Box mt={2}>
          <PrimaryButton
            fullWidth
            destructive
            isLoading={isLoading}
            loadingLabel={translations.loadingLabel}
            text={translations.button.submit}
          />
        </Box>
        <ErrorMessage error={error} />
      </Box>
    </form>
  );
};

export default reduxForm({ form: DELETE_ACCOUNT_FORM_ID })(DeleteAccountForm);
