import { putRequest, deleteRequest } from "./request";
import { PETS_ENDPOINT, PETS_NEXT_PAGE_ENDPOINT } from "../constants/routes";

export const submitPets = (config) => putRequest(PETS_ENDPOINT, config);

export const removePetEndpoint = (config) =>
  deleteRequest(PETS_ENDPOINT, config);

export const getPetsNextPage = (config) =>
  putRequest(PETS_NEXT_PAGE_ENDPOINT, config);
