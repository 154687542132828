import React from "react";

export const BabyCartIcon = ({ width = 140, height = 140 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.852 102.888C112.314 102.888 101.911 107.479 97.6065 113.219C93.3018 118.958 97.3195 124.196 105.857 124.196C114.395 124.196 124.798 119.604 129.102 113.865C133.407 108.125 129.389 102.888 120.852 102.888Z"
        fill="#2F455F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.88 79.7141C84.1901 79.7141 71.1325 85.5254 65.7517 92.6999C60.3709 99.8744 65.393 106.475 76.0829 106.475C86.7729 106.475 99.8304 100.664 105.211 93.4891C110.592 86.3146 105.57 79.7859 94.88 79.7141Z"
        fill="#2F455F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.5888 117.954C85.0512 117.954 74.6482 122.546 70.3435 128.285C66.0388 134.025 70.0565 139.262 78.5941 139.262C87.1317 139.262 97.5347 134.67 101.839 128.931C106.144 123.191 102.126 117.954 93.5888 117.954Z"
        fill="#2F455F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.617 94.7805C56.9271 94.7805 43.8696 100.592 38.4887 107.766C33.1079 114.941 38.13 121.541 48.82 121.541C59.5099 121.541 72.5674 115.73 77.9483 108.555C83.3291 101.381 78.307 94.8522 67.617 94.7805Z"
        fill="#2F455F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.8475 0.436282C39.0627 0.00581472 39.5649 -0.137676 39.9954 0.149303C40.1389 0.221048 40.2106 0.292793 40.3541 0.436282L46.3807 6.96504C50.7571 11.7719 54.2726 17.7985 56.0662 24.0403L61.2318 41.3307C62.8819 46.9268 66.7561 52.0924 71.0608 54.6035L74.648 56.6841C75.1502 56.971 75.4372 57.545 75.4372 58.119C75.4372 58.6929 75.0068 58.9799 74.648 58.9799C74.5045 58.9799 74.2893 58.9081 74.1458 58.8364L70.5586 56.7558C65.6082 53.886 61.16 47.8595 59.2229 41.4742L54.0573 24.1838C52.3355 18.5159 49.1787 12.9198 45.161 8.54342L39.1345 2.01466C38.8475 1.5842 38.6322 0.938495 38.8475 0.436282Z"
        fill="#71ADEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.1725 92.9151C80.1725 102.314 73.5003 106.044 65.3931 101.381C57.286 96.6458 50.6855 85.3102 50.6855 75.9116C50.6855 66.5131 57.3577 62.7824 65.4649 67.4458C73.6438 72.1809 80.1725 83.5166 80.1725 92.9151Z"
        fill="#6985C3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.8922 74.3332C48.8922 85.0232 56.3537 98.0807 65.6805 103.462C75.0072 108.842 83.5449 103.82 83.5449 93.1303C83.5449 82.4403 76.0834 69.3828 66.7566 64.002C57.4298 58.6211 48.8922 63.6433 48.8922 74.3332Z"
        fill="#E2F6FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.4554 92.4846C79.4554 101.381 73.1419 104.968 65.3934 100.52C57.645 96.0718 51.4032 85.1666 51.4032 76.2703C51.4032 67.374 57.7168 63.7867 65.4652 68.2349C73.2136 72.6831 79.4554 83.5883 79.4554 92.4846Z"
        fill="#71ADEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.3124 80.575C63.5994 80.3598 64.0299 80.4316 64.5321 80.7185C65.4647 81.2925 66.2539 82.5839 66.2539 83.6601C66.2539 84.234 66.0387 84.5927 65.7517 84.7362L65.2495 85.0232C65.5365 84.8797 65.7517 84.4493 65.7517 83.947C65.7517 82.8709 64.9625 81.5795 64.0299 81.0055C63.5276 80.7185 63.0972 80.7185 62.8102 80.862L63.3124 80.575Z"
        fill="#D2D2D2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.0301 81.0055C63.0974 80.4315 62.3082 80.862 62.3082 82.0099C62.3082 83.0861 63.0257 84.3775 64.0301 84.9515C64.9628 85.5254 65.7519 85.095 65.7519 83.947C65.6802 82.8709 64.9628 81.5795 64.0301 81.0055Z"
        fill="#ADADAD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.41 99.3003C85.41 107.838 91.3648 118.241 98.8262 122.546C106.288 126.85 113.032 122.833 113.103 114.295C113.103 105.757 107.149 95.3544 99.6872 91.0497C92.2257 86.745 85.41 90.7627 85.41 99.3003Z"
        fill="#E2F6FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.731 113.793C109.731 120.895 104.709 123.765 98.5391 120.178C92.369 116.591 87.3469 107.981 87.4187 100.879C87.4187 93.776 92.4408 90.9062 98.6108 94.4935C104.781 98.0807 109.803 106.69 109.731 113.793Z"
        fill="#71ADEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.4629 104.466C96.6019 103.964 95.8845 104.394 95.8845 105.327C95.8845 106.331 96.5302 107.479 97.3911 107.981C98.2521 108.484 98.9695 108.053 98.9695 107.121C98.9695 106.116 98.3238 104.968 97.4629 104.466Z"
        fill="#E2F6FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.7431 82.0817C63.8866 82.0099 64.1019 82.0099 64.3171 82.1534C64.7476 82.4404 65.1063 83.0143 65.1063 83.5166C65.1063 83.7318 65.0345 83.947 64.891 84.0188L37.8433 99.4439C37.9868 99.3721 38.0586 99.1569 38.0586 98.9417C38.0586 98.4394 37.6998 97.8655 37.2694 97.5785C37.0541 97.435 36.8389 97.435 36.6954 97.5068L63.7431 82.0817Z"
        fill="#0064DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.9609 105.47C97.1044 105.399 97.2479 105.399 97.3914 105.542C97.7501 105.757 98.0371 106.188 98.0371 106.618C98.0371 106.834 97.9653 106.977 97.8218 107.049L71.0611 122.546C71.2046 122.474 71.2763 122.33 71.2763 122.115C71.2763 121.685 70.9893 121.254 70.6306 121.039C70.4871 120.967 70.2719 120.895 70.2002 120.967L96.9609 105.47Z"
        fill="#0064DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.7022 121.039C70.3435 120.824 70.0565 120.967 70.0565 121.398C70.0565 121.828 70.3435 122.259 70.7022 122.474C71.0609 122.689 71.3479 122.546 71.3479 122.115C71.2762 121.756 70.9892 121.254 70.7022 121.039Z"
        fill="#020202"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.803 122.474C108.368 120.322 106.79 118.456 105.068 117.165C100.835 113.936 96.8173 115.371 95.0237 119.748C96.2433 120.824 97.463 121.756 98.7544 122.546C103.059 125.057 107.148 124.77 109.803 122.474Z"
        fill="#2F455F"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.8901 101.955C79.7421 100.592 78.5225 99.4438 77.3028 98.5111C71.7068 94.2782 66.4694 96.4306 64.3888 102.672C64.8193 102.959 65.2497 103.246 65.752 103.533C71.8502 107.049 77.5898 106.044 80.8901 101.955Z"
        fill="#2F455F"
      />
      <path
        d="M32.8925 80.1446C32.8925 87.7495 38.2016 97.0763 44.8022 100.879L71.0607 115.945C72.9978 117.021 74.7914 117.595 76.5133 117.595C80.7462 117.595 83.4725 114.152 83.4725 108.842C83.4725 101.237 78.1634 91.9107 71.5629 88.1082L45.3044 73.0418C43.3673 71.9657 41.5736 71.3917 39.8518 71.3917C35.6906 71.3917 32.9643 74.8355 32.8925 80.1446ZM71.4911 114.223L45.2326 99.1569C39.3496 95.7131 34.5427 87.3908 34.5427 80.575C34.5427 76.0551 36.695 73.2571 40.2105 73.2571C41.6454 73.2571 43.2238 73.7593 44.8739 74.692L71.1324 89.7583C77.0155 93.2021 81.8224 101.524 81.8224 108.34C81.8224 112.86 79.67 115.658 76.1545 115.658C74.7197 115.658 73.213 115.228 71.4911 114.223Z"
        fill="#0064DC"
      />
      <path
        d="M48.4612 70.459C48.4612 78.064 53.7703 87.3907 60.3708 91.1932L86.6294 106.26C88.5665 107.336 90.3601 107.91 92.082 107.91C96.3149 107.91 99.0412 104.466 99.0412 99.1569C99.0412 91.5519 93.7321 82.2251 87.1316 78.4227L60.8731 63.3563C58.9359 62.2801 57.1423 61.7062 55.4205 61.7062C51.1875 61.7062 48.4612 65.1499 48.4612 70.459ZM87.0598 104.538L60.8013 89.4713C54.9183 86.0276 50.1114 77.7052 50.1114 70.8895C50.1114 66.3696 52.2637 63.5715 55.7792 63.5715C57.2141 63.5715 58.7925 64.0737 60.4426 65.0064L86.7011 80.0728C92.5842 83.5165 97.3911 91.8389 97.3911 98.6547C97.3911 103.175 95.2387 105.973 91.7232 105.973C90.2883 105.973 88.71 105.542 87.0598 104.538Z"
        fill="#0064DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.5651 1.08198C39.7086 1.01023 39.9238 1.01023 40.2108 1.15372C40.2826 1.22547 40.426 1.29721 40.4978 1.36896C40.6413 1.51245 40.7848 1.72768 40.9283 2.01466C41 2.22989 41.0718 2.44512 41.0718 2.66036C41.0718 2.94734 41 3.16257 40.7848 3.23431L11.513 20.2378C11.6565 20.166 11.7999 19.9508 11.7999 19.6638C11.7999 19.4486 11.7282 19.2334 11.6565 19.0181C11.5847 18.8029 11.3695 18.5877 11.226 18.3724C11.1542 18.3007 11.0108 18.2289 10.939 18.1572C10.7238 18.0137 10.5085 18.0137 10.2933 18.0855L39.5651 1.08198Z"
        fill="#0064DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8669 18.1572C10.3647 17.8702 10.006 18.0855 10.006 18.6594C10.006 19.2334 10.3647 19.8791 10.8669 20.166C11.3691 20.453 11.7279 20.2378 11.7279 19.6638C11.7996 19.0899 11.3691 18.4442 10.8669 18.1572Z"
        fill="url(#paint0_linear_36925_328)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.21713 17.8702C9.43237 17.4398 9.93458 17.2963 10.365 17.5833C10.5085 17.655 10.5803 17.7268 10.7238 17.8702L16.7503 24.399C21.1267 29.2059 24.6422 35.2324 26.4358 41.4742L31.6015 58.7647C33.2516 64.3608 37.1258 69.5264 41.4305 72.0374L45.0177 74.118C45.5199 74.405 45.8069 74.979 45.8069 75.5529C45.8069 76.1269 45.3764 76.4139 45.0177 76.4139C44.8742 76.4139 44.659 76.3421 44.5155 76.2704L40.9283 74.1898C35.9779 71.32 31.5297 65.2934 29.5926 58.9082L24.427 41.6177C22.7051 35.9499 19.5484 30.3538 15.5307 25.9774L9.57586 19.4486C9.21713 19.0182 8.93015 18.3725 9.21713 17.8702Z"
        fill="#71ADEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.1274 82.4403L115.758 65.2216L49.8964 27.2687L20.2659 44.4874L86.1274 82.4403Z"
        fill="#E0DBCC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.6944 63.1411L82.2532 45.8506L81.5358 41.546L51.9052 58.7647L52.6944 63.1411Z"
        fill="#A38775"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5613 39.3219C19.9785 40.8285 20.2654 44.4875 20.2654 44.4875L52.6223 63.1411L51.9048 58.7647C51.9048 58.7647 25.1441 37.7435 22.5613 39.3219Z"
        fill="#71ADEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.9054 58.7647L81.536 41.5459L52.5511 24.8295C50.9728 23.8968 48.9639 23.8968 47.3138 24.8295L22.9206 39.0349C21.7727 39.6806 21.7727 41.4025 22.9206 42.0482L51.9054 58.7647Z"
        fill="#71ADEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.1681 41.5459L51.9052 57.4015L23.4943 41.0437C23.2791 40.9002 23.2073 40.685 23.2073 40.5415C23.2073 40.398 23.2073 40.1828 23.4943 40.0393L47.8875 25.8339C49.1071 25.1164 50.6855 25.1164 51.9052 25.8339L79.1681 41.5459Z"
        fill="#0064DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.2176 38.6761C73.7872 39.8241 72.6392 42.6938 71.3478 46.0658L51.9051 57.4015L30.7404 45.2049C33.108 44.4157 31.6731 38.1739 36.6235 37.2413C39.9237 36.5956 40.2824 38.3892 42.7935 36.739C45.3046 35.1607 46.2372 30.9995 51.1876 31.143L71.9935 37.313L74.2176 38.6761Z"
        fill="#FFDC1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2659 44.4875L21.2703 50.0836C23.0639 59.9126 29.8796 70.0286 37.6998 74.6202L68.9087 92.5564C76.7289 97.0763 83.4729 94.7087 85.1947 86.8886L86.1274 82.5121L20.2659 44.4875Z"
        fill="#E2F6FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.1271 82.4404L115.758 65.2217C114.753 69.7416 114.323 74.118 110.377 76.4138L80.7462 93.6326C84.6922 91.3367 85.1227 86.9603 86.1271 82.4404Z"
        fill="#71ADEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.0396 21.6727L71.8499 8.97388C74.2175 7.61073 77.3743 7.61073 79.3114 9.54784C86.1271 16.2918 89.9296 20.5248 101.122 25.1164C101.983 25.4751 102.628 26.2643 102.915 27.197C104.494 33.1518 107.866 41.0437 113.462 46.2811C114.179 46.9268 114.538 47.8595 114.466 48.7921C114.036 53.8143 113.175 58.8364 115.829 65.2934L86.1989 82.5121L52.6941 63.1411L46.8828 28.4167C46.3806 25.6904 47.672 23.0358 50.0396 21.6727Z"
        fill="#0064DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.8115 28.4167C46.5962 27.3405 46.7397 26.2643 47.0984 25.1882C48.0311 25.4034 48.8921 25.8339 49.6095 26.5513C56.4252 33.2953 60.2277 37.5282 71.4199 42.1199C72.2808 42.4786 72.9265 43.2678 73.2135 44.2005C74.7919 50.1553 78.1639 58.0472 83.7599 63.2846C84.4774 63.9303 84.8361 64.8629 84.7644 65.7956C84.3339 70.8177 83.473 75.8399 86.1275 82.2969L85.984 82.3686L52.6945 63.0693L46.8115 28.4167Z"
        fill="#71ADEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.4338 115.012C58.4338 123.55 64.3886 133.953 71.85 138.258C79.2397 142.562 86.0555 138.545 86.1272 130.007C86.1272 121.469 80.1724 111.066 72.711 106.762C65.2495 102.457 58.4338 106.475 58.4338 115.012Z"
        fill="#E2F6FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.401 129.864C83.401 137.325 78.0918 140.338 71.6348 136.608C65.1778 132.877 59.8687 123.765 59.9405 116.304C59.9405 108.842 65.2496 105.829 71.7066 109.56C78.1636 113.291 83.401 122.402 83.401 129.864Z"
        fill="#71ADEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.4867 120.322C69.7692 119.891 69.1235 120.25 69.1235 121.111C69.1235 121.972 69.6975 123.048 70.4867 123.478C71.2041 123.909 71.8498 123.55 71.8498 122.689C71.8498 121.828 71.2759 120.752 70.4867 120.322Z"
        fill="#E2F6FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9876 90.0453C21.9876 100.735 29.449 113.793 38.7758 119.174C48.1026 124.554 56.6402 119.532 56.6402 108.842C56.6402 98.1525 49.1788 85.0949 39.852 79.7141C30.5252 74.3332 21.9876 79.2836 21.9876 90.0453Z"
        fill="#E2F6FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2694 97.5785C36.8389 97.2915 36.4802 97.5067 36.4802 98.009C36.4802 98.5112 36.8389 99.0851 37.2694 99.3721C37.6999 99.6591 38.0586 99.4438 38.0586 98.9416C38.0586 98.4394 37.6999 97.8655 37.2694 97.5785Z"
        fill="#020202"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.1963 108.627C53.1963 118.026 46.5241 121.756 38.417 117.093C30.3098 112.358 23.7093 101.022 23.7093 91.6237C23.7093 82.2251 30.3816 78.4944 38.4887 83.1578C46.6676 87.8212 53.2681 99.2286 53.1963 108.627Z"
        fill="#71ADEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0544 96.7175C36.1218 96.1436 35.3326 96.5741 35.3326 97.722C35.3326 98.7981 36.05 100.09 37.0544 100.663C37.9871 101.237 38.7763 100.807 38.7763 99.6591C38.7763 98.5829 37.9871 97.2915 37.0544 96.7175Z"
        fill="#E2F6FF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_36925_328"
          x1="11.3389"
          y1="18.4725"
          x2="7.00521"
          y2="22.5649"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#162B52" />
          <stop offset="0.5084" stopColor="#234973" />
          <stop offset="1" stopColor="#2E628F" />
        </linearGradient>
      </defs>
    </svg>
  );
};
