import { putRequest, getRequest, postRequest } from "./request";
import {
  USER_AGREED_TO_TOS_ENDPOINT,
  USER_ENDPOINT,
  USER_REASONS_ENDPOINT,
  USER_RESEARCH_PARTICIPANTION_ENDPOINT,
} from "../constants/routes";

export const submitUserRequest = (config) => putRequest(USER_ENDPOINT, config);

export const getUser = (config) => getRequest(USER_ENDPOINT, config);

export const submitResearchParticipationRequest = (config) =>
  postRequest(USER_RESEARCH_PARTICIPANTION_ENDPOINT, config);

export const submitUpdateReasonsRequest = (config) =>
  postRequest(USER_REASONS_ENDPOINT, config);

export const submitAgreedToTosRequest = (config) =>
  putRequest(USER_AGREED_TO_TOS_ENDPOINT, config);
