export const fetchDocuments = () => ({
  type: FETCH_DOCUMENTS_TYPE,
});
export const addDocuments = (documents) => ({
  type: ADD_DOCUMENTS_TYPE,
  payload: {
    documents,
  },
});

export const downloadDocuments = (documentKey, isDownload, isSpouse) => ({
  type: DOWNLOAD_DOCUMENTS_TYPE,
  payload: {
    documentKey,
    isSpouse,
    isDownload,
  },
});

export const FETCH_DOCUMENTS_TYPE = "FETCH_DOCUMENTS";
export const ADD_DOCUMENTS_TYPE = "ADD_DOCUMENTS";
export const DOWNLOAD_DOCUMENTS_TYPE = "DOWNLOAD_DOCUMENTS";
