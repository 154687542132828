import { putRequest, getRequest, deleteRequest } from "./request";
import {
  CHILDREN_GUARDIANS_ENDPOINT,
  CHILDREN_GUARDIANS_NEXT_PAGE_ENDPOINT,
} from "../constants/routes";

export const submitChildrenGuardians = (config) =>
  putRequest(CHILDREN_GUARDIANS_ENDPOINT, config);

export const removeChildrenGuardiansEndpoint = (config) =>
  deleteRequest(CHILDREN_GUARDIANS_ENDPOINT, config);

export const getChildrenGuardiansNextPage = (config) =>
  getRequest(CHILDREN_GUARDIANS_NEXT_PAGE_ENDPOINT, config);
