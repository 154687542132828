import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

import { MiceType, MiceTypeLink } from "../ui/Typography";
import { BC_LAW_SOCIETY_INNOVATION_SANDBOX_URL } from "../../constants/routes";

const DisclaimerList = styled.ol`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const DisclaimerListItem = styled.li`
  display: flex;
`;

const ListItemMarker = styled(MiceType)`
  margin-right: 8px;
`;

const Bill21Disclaimer = ({ translations, isTablet, onExternalLinkClick }) => (
  <Grid container justifyContent={isTablet ? "center" : ""}>
    <Grid item lg={6} md={8} sm={12}>
      <Box pt={1} mt={4.5} borderTop={1} borderColor="border.main">
        <Box mb={0.5}>
          <MiceType color="willfulGrey" display="block" margin="0" bold>
            {translations.bill21Disclaimer.intro}
          </MiceType>
        </Box>
        <MiceType color="willfulGrey1" display="block" margin="0">
          {translations.bill21Disclaimer.lawSocietyDisclaimer}
        </MiceType>
        <Box my={0.5}>
          <DisclaimerList>
            <Box mb={0.5}>
              <DisclaimerListItem>
                <ListItemMarker color="willfulGrey1" margin="0">
                  (a)
                </ListItemMarker>
                <MiceType color="willfulGrey1" margin="0">
                  {translations.bill21Disclaimer.lawSocietyDisclaimerItem1}
                </MiceType>
              </DisclaimerListItem>
            </Box>
            <Box mb={0.5}>
              <DisclaimerListItem>
                <ListItemMarker color="willfulGrey1" margin="0">
                  (b)
                </ListItemMarker>
                <MiceType color="willfulGrey1" margin="0">
                  {translations.bill21Disclaimer.lawSocietyDisclaimerItem2}
                </MiceType>
              </DisclaimerListItem>
            </Box>
            <DisclaimerListItem>
              <ListItemMarker color="willfulGrey1" margin="0">
                (c)
              </ListItemMarker>
              <MiceType color="willfulGrey1" margin="0">
                {translations.bill21Disclaimer.lawSocietyDisclaimerItem3}
              </MiceType>
            </DisclaimerListItem>
          </DisclaimerList>
        </Box>
        <MiceType color="willfulGrey1" display="block" margin="0">
          {translations.bill21Disclaimer.moreInfo}
          <br />
          <MiceTypeLink
            onClick={() =>
              onExternalLinkClick(BC_LAW_SOCIETY_INNOVATION_SANDBOX_URL)
            }
            color="willfulBlue"
            text={BC_LAW_SOCIETY_INNOVATION_SANDBOX_URL}
            href={BC_LAW_SOCIETY_INNOVATION_SANDBOX_URL}
          />
        </MiceType>
      </Box>
    </Grid>
  </Grid>
);

export default Bill21Disclaimer;
