import R from "ramda";

import { createStructuredSelector, createSelector } from "reselect";
import { getFormValues } from "redux-form";
import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";
import {
  selectProvinceCode,
  selectAgeOfMajority,
  selectIsCurrentProvinceSelectedByCode,
} from "./provinces";
import { selectTranslations } from "./translations";
import { selectAppointeeLevelIndexs } from "./utils/form-appointees";
import { selectIsFieldComplete } from "./utils/progress";
import {
  EXECUTORS_FORM_ID,
  EXECUTORS_FIELD_ID,
  SPOUSE_IS_EXECUTOR_FIELD_ID,
  ADD_AND_EDIT_EXECUTORS_FORM_ID,
} from "../constants/forms";
import {
  selectHasSpouse,
  selectSpouseFirstName,
  selectSpouseLastName,
  selectIsCurrentUserSpouse,
} from "./spouse";
import { selectFirstName } from "./about-you";

const selectArrangementsData = R.pathOr({}, ["yourArrangements"]);
export const selectSpouseSpouseIsExecutor = R.path([
  "spouseArrangements",
  SPOUSE_IS_EXECUTOR_FIELD_ID,
]);
export const selectSpouseExecutors = R.path([
  "spouseArrangements",
  EXECUTORS_FIELD_ID,
]);

export const selectSpouseIsExecutor = R.path([
  "yourArrangements",
  SPOUSE_IS_EXECUTOR_FIELD_ID,
]);

export const selectLastSelectedExecutorData = R.path([
  "formUi",
  "lastSelectedExecutorData",
]);

export const selectExecutors = R.path(["yourArrangements", EXECUTORS_FIELD_ID]);

export const selectExecutorsForm = createSelector(
  selectIsCurrentUserSpouse,
  selectExecutors,
  selectSpouseIsExecutor,
  selectSpouseExecutors,
  selectSpouseSpouseIsExecutor,
  (
    isSpouse,
    executors,
    spouseIsExecutor,
    spouseExecutors,
    spouseSpouseIsExecutor,
  ) => ({
    [EXECUTORS_FIELD_ID]: isSpouse ? spouseExecutors : executors,
    [SPOUSE_IS_EXECUTOR_FIELD_ID]: isSpouse
      ? spouseSpouseIsExecutor
      : spouseIsExecutor,
  }),
);

export const selectExecutorsModalExtraProps = createSelector(
  selectIsCurrentProvinceSelectedByCode("QC"),
  getFormValues(ADD_AND_EDIT_EXECUTORS_FORM_ID),
  (isQuebecSelected, addEditExecutorsFormValues) => {
    const { coExecutorsCheckbox } = addEditExecutorsFormValues || false;
    const shouldShowCoExecutorFields = Boolean(coExecutorsCheckbox);
    return { isQuebecSelected, shouldShowCoExecutorFields };
  },
);

export const selectIsExecutorComplete = createSelector(
  selectIsFieldComplete(selectArrangementsData, EXECUTORS_FIELD_ID),
  selectIsFieldComplete(
    selectArrangementsData,
    SPOUSE_IS_EXECUTOR_FIELD_ID,
    true,
  ),
  selectHasSpouse,
  (executorsComplete, spouseHasExecutorComplete, hasSpouse) => {
    if (hasSpouse) {
      return spouseHasExecutorComplete || executorsComplete;
    }
    return executorsComplete;
  },
);

const selectFormSpouseIsExecutor = createSelector(
  getFormValues(EXECUTORS_FORM_ID),
  (formValues) => R.propOr(false, SPOUSE_IS_EXECUTOR_FIELD_ID)(formValues),
);

const selectFormHasExecutors = createSelector(
  getFormValues(EXECUTORS_FORM_ID),
  (formValues) => {
    const executors = R.propOr([], EXECUTORS_FIELD_ID, formValues);
    const numOfExecutors = executors.filter(
      (executor) => executor._destroy !== true,
    ).length;
    return numOfExecutors > 0;
  },
);

const selectShouldShowHelperText = createSelector(
  selectFormSpouseIsExecutor,
  selectFormHasExecutors,
  (isSpouseExecutor, hasExecutors) => {
    return isSpouseExecutor || (!isSpouseExecutor && hasExecutors);
  },
);

const selectShouldShowCTAButton = createSelector(
  selectFormSpouseIsExecutor,
  selectHasSpouse,
  (isSpouseExecutor, hasSpouse) => {
    return !hasSpouse || isSpouseExecutor !== "";
  },
);

export const selectExecutorsTranslationsDeprecated = createSelector(
  selectTranslations([
    "global",
    "executorsDeprecated",
    "provinces",
    "appointees",
  ]),
  selectAgeOfMajority,
  selectProvinceCode,
  selectIsCurrentUserSpouse,
  selectFirstName,
  selectSpouseFirstName,
  (
    translations,
    ageOfMajority,
    provinceCode,
    isSpouse,
    firstName,
    spouseFirstName,
  ) => {
    const [firstInfoBox] = translations.infoBox;
    const firstBoxParagraph = firstInfoBox.paragraph
      .replace("PROVINCE_NAME", translations[provinceCode])
      .replace("AGE_OF_MAJORITY", ageOfMajority);

    const spouseExecutorQuestionTranslation = translations.userSpouseExecutorQuestion.replace(
      "SPOUSE_NAME",
      isSpouse ? firstName : spouseFirstName,
    );

    return {
      ...translations,
      userSpouseExecutorQuestion: spouseExecutorQuestionTranslation,
      infoBox: [
        {
          ...firstInfoBox,
          paragraph: firstBoxParagraph,
        },
      ],
    };
  },
);

export const selectExecutorsTranslations = createSelector(
  selectTranslations([
    "global",
    EXECUTORS_FORM_ID,
    "provinces",
    "appointees",
    "navigation",
    "notice",
  ]),
  selectAgeOfMajority,
  selectProvinceCode,
  selectIsCurrentUserSpouse,
  selectFirstName,
  selectSpouseFirstName,
  selectSpouseLastName,
  (
    translations,
    ageOfMajority,
    provinceCode,
    isSpouse,
    firstName,
    spouseFirstName,
    spouseLastName,
  ) => {
    const [firstInfoBox] = translations.infoBox;
    const firstBoxParagraph = firstInfoBox.paragraph
      .replace("PROVINCE_NAME", translations[provinceCode])
      .replace("AGE_OF_MAJORITY", ageOfMajority);

    const spouseExecutorQuestionTranslation = translations.userSpouseExecutorQuestion.replace(
      "SPOUSE_NAME",
      isSpouse ? firstName : `${spouseFirstName} ${spouseLastName}`,
    );

    return {
      ...translations,
      userSpouseExecutorQuestion: spouseExecutorQuestionTranslation,
      infoBox: [
        {
          ...firstInfoBox,
          paragraph: firstBoxParagraph,
        },
      ],
    };
  },
);

export const executorsPagePropsDeprecated = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(EXECUTORS_FORM_ID),
  isLoading: selectIsLoadingById(EXECUTORS_FORM_ID),
  appointeeLevelIndexs: selectAppointeeLevelIndexs(EXECUTORS_FORM_ID),
  isSpouseExecutor: selectFormSpouseIsExecutor,
  hasSpouse: selectHasSpouse,
  shouldShowHelperText: selectShouldShowHelperText,
  translations: selectExecutorsTranslationsDeprecated,
  tooltipKey: () =>
    selectIsCurrentProvinceSelectedByCode("QC")
      ? "executors.sectionPage"
      : "executorsDeprecated.sectionPage",
});

export const executorsPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(EXECUTORS_FORM_ID),
  isLoading: selectIsLoadingById(EXECUTORS_FORM_ID),
  appointeeLevelIndexs: selectAppointeeLevelIndexs(EXECUTORS_FORM_ID),
  isSpouseExecutor: selectFormSpouseIsExecutor,
  hasSpouse: selectHasSpouse,
  translations: selectExecutorsTranslations,
  tooltipKey: () => "executors.sectionPage",
  isQuebecSelected: selectIsCurrentProvinceSelectedByCode("QC"),
  shouldShowCTAButton: selectShouldShowCTAButton,
});
