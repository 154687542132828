import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";

import requests from "./requests";
import charities from "./charities";
import provinces from "./provinces";
import cart from "./cart";
import receipt from "./receipt";
import plans from "./plans";
import documents from "./documents";
import auth from "./auth";
import formUi from "./form-ui";
import aboutYou from "./about-you";
import yourFamily from "./your-family";
import yourEstate from "./your-estate";
import yourArrangements from "./your-arrangements";
import spouseEstate from "./spouse-estate";
import spouseArrangements from "./spouse-arrangements";
import navigation from "./navigation";
import modal from "./modal";
import toast from "./toast";
import invites from "./invites";
import experiments from "./experiments";
import featureFlags from "./featureFlags";
import tooltipModal from "./tooltip-modal";
import emailPreviewModal from "./email-preview-modal";
import appointees from "./appointees";
import assetList from "./asset-list";
import discounts from "./discounts";
import features from "./features";
import checkout from "./checkout";

import { CLEAR_APP_STATE_TYPE } from "../actions/auth";
import ga4Parameters from "./analytics";
import contacts from "./contacts";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    formUi,
    modal,
    toast,
    invites,
    navigation,
    requests,
    plans,
    provinces,
    charities,
    documents,
    cart,
    receipt,
    aboutYou,
    yourFamily,
    yourEstate,
    yourArrangements,
    spouseEstate,
    spouseArrangements,
    auth,
    experiments,
    featureFlags,
    tooltipModal,
    emailPreviewModal,
    appointees,
    assetList,
    discounts,
    ga4Parameters,
    features,
    checkout,
    contacts,
  });

export default (history) => (state, action) =>
  // CLEAR_APP_STATE removes everything in state except language
  action.type === CLEAR_APP_STATE_TYPE
    ? rootReducer(history)({ aboutYou: { lang: state.aboutYou.lang } }, action)
    : rootReducer(history)(state, action);
