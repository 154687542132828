import React from "react";

import SvgIcon from "./SvgIcon";

const InfoIcon = ({ iconColor, width = "32px", height = "32px" }) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 32 32">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1085.000000, -5693.000000)">
          <g transform="translate(774.000000, 5655.000000)">
            <g transform="translate(307.000000, 34.000000)">
              <circle
                stroke={iconColor}
                strokeWidth="3"
                cx="20"
                cy="20"
                r="14"
              />
              <text
                fontFamily="TT Norms"
                fontSize="24"
                fontWeight="700"
                letterSpacing="0.24"
                fill={iconColor}
              >
                <tspan x="16.58" y="28">
                  i
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default InfoIcon;
