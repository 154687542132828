import React from "react";
import { Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { externalLogin } from "../../actions/external-login";
import { SIGNUP_PATH } from "../../constants/routes";
import { selectIsFeatureExternalLoginEnabled } from "../../selectors/feature-flags";
import LoadingSpinner from "../../components/ui/LoadingSpinner";

const ExternalLoginPage = () => {
  const isFeatureExternalLoginEnabled = useSelector(
    selectIsFeatureExternalLoginEnabled,
  );
  const dispatch = useDispatch();

  // Redirect to signup page if external login feature is disabled
  if (!isFeatureExternalLoginEnabled) {
    return (
      <Redirect
        to={{
          pathname: SIGNUP_PATH,
        }}
      />
    );
  }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const uid = urlParams.get("uid");
  const accessToken = urlParams.get("access_token");
  const client = urlParams.get("client");
  if (!uid || !accessToken || !client) {
    return (
      <Redirect
        to={{
          pathname: SIGNUP_PATH,
        }}
      />
    );
  }

  dispatch(externalLogin(uid, accessToken, client));
  return <LoadingSpinner />;
};

export default ExternalLoginPage;
