import React from "react";

export const RingsIcon = ({ width = 140, height = 140 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M116.435 111.403L131.635 106.657L140 94.4323L130.831 89.2849L113.298 106.979L116.435 111.403Z"
        fill="#2F455F"
      />
      <path
        d="M123.754 60.0895C104.933 49.8752 75.3359 51.8054 57.6417 64.5131C40.028 77.1403 40.9931 95.7192 59.7329 105.934C78.553 116.148 108.151 114.218 125.845 101.59C143.539 88.9632 142.574 70.3039 123.754 60.0895ZM119.169 97.8907C105.014 108.025 81.2071 109.633 66.1671 101.43C51.0466 93.2259 50.3228 78.3467 64.4781 68.1323C78.6335 57.9984 102.44 56.3898 117.48 64.5935C132.52 72.8776 133.325 87.7568 119.169 97.8907Z"
        fill="#2F455F"
      />
      <path
        d="M81.4486 50.1969C60.4569 48.8297 33.7548 59.7679 21.8514 74.4862C9.94807 89.2045 17.3474 102.314 38.3392 103.682C59.3309 105.049 86.033 94.1106 97.9363 79.3923C109.84 64.674 102.36 51.5642 81.4486 50.1969ZM85.7113 78.588C77.588 88.6415 59.4113 96.0409 45.1756 95.0758C30.9398 94.1106 25.8728 85.2636 33.996 75.21C42.1193 65.1565 60.296 57.7572 74.5318 58.7223C88.7675 59.6874 93.8345 68.6149 85.7113 78.588Z"
        fill="#2F455F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.617 68.4252L120.502 81.9371C120.18 76.3876 116.159 71.1598 108.679 67.3797C93.5586 59.8194 69.8324 61.2671 55.677 70.5968C48.5189 75.342 45.2214 81.1329 45.5431 86.6824L44.6584 73.2509C44.3367 67.7014 47.6342 61.8301 54.7923 57.1653C68.9477 47.7552 92.6739 46.3075 107.794 53.8678C115.274 57.6479 119.296 62.9561 119.617 68.4252Z"
        fill="#0064DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.671 81.3742C130.154 88.3714 125.971 95.6099 117.044 101.481C99.43 113.143 69.7521 114.993 50.9319 105.503C41.6827 100.838 36.7766 94.2426 36.294 87.3258L35.4093 73.8139C35.8115 80.7307 40.798 87.3258 50.1277 91.9907C68.9478 101.401 98.5453 99.6313 116.24 87.9693C125.087 82.098 129.269 74.8595 128.867 67.8622L129.671 81.3742Z"
        fill="#71ADEE"
      />
      <path
        d="M114.149 49.6855C95.3284 40.2754 65.7309 42.0449 48.0367 53.7069C30.423 65.369 31.3881 82.5806 50.2083 91.9907C69.0284 101.401 98.626 99.6313 116.32 87.9693C133.853 76.3072 132.969 59.0956 114.149 49.6855ZM109.484 84.5913C95.3284 93.9209 71.5217 95.3686 56.4817 87.8084C41.3612 80.2482 40.6373 66.495 54.7927 57.0849C68.948 47.7552 92.6743 46.3075 107.795 53.8678C122.915 61.428 123.639 75.1812 109.484 84.5913Z"
        fill="#AAD2F6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132.174 95.7816L127.107 105.835L117.134 112.671L110.7 107.524L111.584 98.114L110.7 97.6314L115.686 87.5779L116.651 88.0605L123.568 81.7067L131.611 83.7174L132.174 95.7816Z"
        fill="#E2F6FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131.611 83.7174C131.209 83.8782 130.646 83.9587 130.163 83.9587C128.313 83.9587 126.544 83.9587 124.774 84.0391C123.085 84.1195 121.798 85.0042 121.798 86.0498C121.798 87.0954 121.879 88.2213 122.04 89.3473C122.12 89.9908 121.477 90.795 120.592 90.9559C119.144 91.358 117.697 91.7602 116.329 92.2428C115.043 92.6449 114.319 94.0926 114.801 95.3794C115.284 96.7467 115.766 98.114 116.329 99.4813C116.651 100.286 116.49 101.251 115.847 101.653C114.882 102.216 113.917 102.859 113.032 103.503C112.228 104.066 112.308 105.674 113.193 107.042C114.158 108.489 115.203 109.937 116.329 111.385C116.651 111.787 116.892 112.269 117.053 112.671L111.423 109.856C109.01 108.65 107.241 106.318 106.919 103.583C106.598 101.09 106.758 98.5966 107.241 96.1837C108.045 92.3232 109.815 88.7843 112.469 85.8085C113.997 83.9587 115.847 82.4305 118.018 81.1437C120.431 79.696 123.407 79.6155 125.981 80.822L131.611 83.7174Z"
        fill="#FFDC1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.871 96.5643C62.9058 96.4034 61.9407 96.1621 61.056 95.9208C55.7477 98.4141 50.1982 100.344 44.7291 101.551C46.0159 102.516 47.3832 103.481 48.9114 104.366C55.0239 102.677 61.1364 100.184 66.8468 97.1273C65.8012 96.9664 64.8361 96.8055 63.871 96.5643Z"
        fill="#0064DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.6968 91.7386C66.2035 93.2667 63.6298 94.7144 60.9757 95.9208C61.9408 96.1621 62.8256 96.4034 63.7907 96.5643C64.7558 96.8056 65.8014 96.9664 66.7665 97.1273C69.3402 95.6796 71.9139 94.1514 74.2463 92.4624C72.3965 92.3016 70.5466 92.0603 68.6968 91.7386Z"
        fill="#71ADEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.5077 42.7171L83.4594 54.9421C81.0465 49.9556 75.3362 46.5776 66.9716 45.9342C50.1622 44.6474 28.6879 55.0226 19.117 69.0171C14.2913 76.0947 13.4066 82.6898 15.8998 87.7568L10.0286 75.5317C7.53533 70.4648 8.42004 63.8697 13.2457 56.792C22.8167 42.7975 44.2105 32.4223 61.1004 33.7091C69.4649 34.3526 75.0949 37.7305 77.5077 42.7171Z"
        fill="#0064DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.7433 50.8403C94.7996 57.1137 93.6736 65.3978 87.7219 74.1644C75.899 91.6978 49.1165 104.566 28.1248 102.958C17.6691 102.153 10.6719 97.9711 7.61561 91.6978L1.66394 79.5531C4.72021 85.7461 11.7174 90.0088 22.1731 90.813C43.1648 92.4216 69.8669 79.4727 81.7703 62.0198C87.7219 53.2532 88.8479 44.9691 85.7917 38.6957L91.7433 50.8403Z"
        fill="#71ADEE"
      />
      <path
        d="M65.3629 27.4358C44.3712 25.8272 17.5887 38.6957 5.68531 56.229C-6.21804 73.7623 1.18134 89.2045 22.1731 90.8131C43.1648 92.4216 69.8669 79.4727 81.7702 62.0198C93.6736 44.5669 86.2742 29.0443 65.3629 27.4358ZM74.2904 61.4568C64.7195 75.4513 43.3256 85.8265 26.4357 84.5397C9.54585 83.2528 3.67461 70.7865 13.2455 56.792C22.8165 42.7975 44.2103 32.4223 61.1002 33.7092C77.9097 34.996 83.8614 47.4624 74.2904 61.4568Z"
        fill="#AAD2F6"
      />
    </svg>
  );
};
