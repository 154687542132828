import { putRequest, getRequest } from "./request";
import {
  SPOUSE_ENDPOINT,
  SPOUSE_NEXT_PAGE_ENDPOINT,
} from "../constants/routes";

export const submitSpouse = (config) => putRequest(SPOUSE_ENDPOINT, config);

export const getSpouseNextPage = (config) =>
  getRequest(SPOUSE_NEXT_PAGE_ENDPOINT, config);

export const getSpouse = (config) => getRequest(SPOUSE_ENDPOINT, config);
