import React from "react";

import { reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";

import { PrimaryButton } from "../../ui/Button";
import { Body } from "../../ui/Typography";
import { ErrorMessage } from "../../ui/Error";
import { INVITE_USER_FORM_ID } from "../../../constants/forms";
import NameFields from "../../ui/inputs/NameFields";
import EmailField from "../../ui/inputs/EmailField";

const InviteUserForm = ({ error, handleSubmit, isLoading, translations }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Body>{translations.body}</Body>
        <NameFields disableMiddleName useTheir translations={translations} />
        <EmailField useTheir translations={translations} />
        <Box mt={2}>
          <PrimaryButton
            fullWidth
            isLoading={isLoading}
            loadingLabel={translations.loadingLabel}
            text={translations.button.submit}
          />
        </Box>
        <ErrorMessage error={error} />
      </Box>
    </form>
  );
};

export default reduxForm({ form: INVITE_USER_FORM_ID })(InviteUserForm);
