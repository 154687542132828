import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/styles";

import { updatePlans, fetchPlans } from "../../../actions/plans";
import { displayTooltipModal } from "../../../actions/tooltip-modal";
import { RECOMMENDATION_FORM_ID } from "../../../constants/forms";
import { premiumRecommendationPageProps } from "../../../selectors/about-you";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import PremiumRecommendationForm from "../../../components/forms/about-you/PremiumRecommendationForm";
import { analyticsClickExternalLink } from "../../../actions/analytics";
import { useMediaQuery, useTheme } from "@material-ui/core";

const PremiumRecommendationPage = ({
  hasLoaded,
  fetchPlans,
  analyticsClickExternalLink,
  displayTooltipModal,
  ...restOfProps
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchPlans(RECOMMENDATION_FORM_ID);
  }, [fetchPlans]);

  if (!hasLoaded) {
    return <LoadingSpinner />;
  }

  return (
    <PremiumRecommendationForm
      analyticsClickExternalLink={analyticsClickExternalLink}
      isMobile={isMobile}
      displayTooltipModal={displayTooltipModal}
      {...restOfProps}
    />
  );
};

export default connect(premiumRecommendationPageProps, {
  updatePlans,
  fetchPlans,
  analyticsClickExternalLink,
  displayTooltipModal,
})(withTheme(PremiumRecommendationPage));
