import { combineReducers } from "redux";
import {
  UPDATE_ASSET_LIST_TYPE,
  EDIT_ASSET_TYPE,
  ADD_PHYSICAL_ASSET_TYPE,
  ADD_FINANCIAL_ASSET_TYPE,
  ADD_INSURANCE_ASSET_TYPE,
  UPDATE_FINANCIAL_ASSETS_TYPE,
  UPDATE_INSURANCE_ASSETS_TYPE,
  UPDATE_PHYSICAL_ASSETS_TYPE,
} from "../actions/asset-list";
import {
  CLOSE_MODAL_TYPE,
  SUBMIT_SECONDARY_MODAL_TYPE,
} from "../actions/modal";
import { UPDATE_STATUS_TYPE } from "../actions/status";

const totalAssets = (state = 0, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return payload.totalAssets;
    default:
      return state;
  }
};

const physicalAssets = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_ASSET_LIST_TYPE:
      return payload.assetList.physicalAsset;
    case ADD_PHYSICAL_ASSET_TYPE:
      return [...state, payload.asset];
    case UPDATE_PHYSICAL_ASSETS_TYPE:
      return payload.assets;
    default:
      return state;
  }
};

const financialAssets = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_ASSET_LIST_TYPE:
      return payload.assetList.financialAsset;
    case ADD_FINANCIAL_ASSET_TYPE:
      return [...state, payload.asset];
    case UPDATE_FINANCIAL_ASSETS_TYPE:
      return payload.assets;
    default:
      return state;
  }
};

const insuranceAssets = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_ASSET_LIST_TYPE:
      return payload.assetList.insuranceAsset;
    case ADD_INSURANCE_ASSET_TYPE:
      return [...state, payload.asset];
    case UPDATE_INSURANCE_ASSETS_TYPE:
      return payload.assets;
    default:
      return state;
  }
};

const lastModifiedAsset = (state = {}, { type, payload }) => {
  switch (type) {
    case ADD_PHYSICAL_ASSET_TYPE:
    case ADD_FINANCIAL_ASSET_TYPE:
    case ADD_INSURANCE_ASSET_TYPE:
      return payload.asset;
    case UPDATE_PHYSICAL_ASSETS_TYPE:
    case UPDATE_FINANCIAL_ASSETS_TYPE:
    case UPDATE_INSURANCE_ASSETS_TYPE:
      return payload.modifiedAsset;
    default:
      return state;
  }
};

const isEditingAsset = (state = false, { type }) => {
  switch (type) {
    case EDIT_ASSET_TYPE:
      return true;
    case CLOSE_MODAL_TYPE:
      return false;
    default:
      return state;
  }
};

const isRemovingAsset = (state = false, { type }) => {
  switch (type) {
    case SUBMIT_SECONDARY_MODAL_TYPE:
      return true;
    case CLOSE_MODAL_TYPE:
      return false;
    default:
      return state;
  }
};

const currentAssetId = (state = null, { type, payload }) => {
  switch (type) {
    case EDIT_ASSET_TYPE:
      return payload.id;
    case CLOSE_MODAL_TYPE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  physicalAssets,
  financialAssets,
  insuranceAssets,
  lastModifiedAsset,
  isEditingAsset,
  isRemovingAsset,
  currentAssetId,
  totalAssets,
});
