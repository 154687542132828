import React from "react";
import SvgIcon from "./SvgIcon";

const DefaultIcon = ({ title, width = 24, height = 24 }) => {
  return (
    <SvgIcon
      title={title}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
        fill="#E2F6FF"
      />
      <path
        d="M12 7.83331V16.1666"
        stroke="#0064DC"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M7.83331 12H16.1666"
        stroke="#0064DC"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

const BlueIcon = ({ title, width = 24, height = 24 }) => {
  return (
    <SvgIcon
      title={title}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3334 9.99998C18.3334 5.39761 14.6024 1.66665 10 1.66665C5.39765 1.66665 1.66669 5.39761 1.66669 9.99998C1.66669 14.6024 5.39765 18.3333 10 18.3333C14.6024 18.3333 18.3334 14.6024 18.3334 9.99998Z"
        fill="#0064DC"
      />
      <path
        d="M9.99999 6.52777V13.4722"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6.52777 10H13.4722"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

const PlusIcon = ({ title, width = 24, height = 24, color = "" }) => {
  switch (color) {
    case "blue":
      return <BlueIcon title={title} width={width} height={height} />;
    default:
      return <DefaultIcon title={title} width={width} height={height} />;
  }
};

export default PlusIcon;
