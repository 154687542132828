import React from "react";

import SvgIcon from "./SvgIcon";

const PoaPropertyUpgradabledIcon = () => {
  return (
    <SvgIcon width="120" height="100" viewBox="0 0 120 100">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60 99.9264C93.1371 99.9264 120 82.8058 120 61.6864C120 40.567 93.1371 23.4463 60 23.4463C26.8629 23.4463 0 40.567 0 61.6864C0 82.8058 26.8629 99.9264 60 99.9264Z"
        fill="#CACDD4"
      />
      <path
        d="M66.5624 38.4478L70.8857 71.6958L79.96 72.0753L105.9 57.0402L70.6007 36.6455L66.5624 38.4478Z"
        fill="#4A4A4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5555 32.1721V49.743L38.2329 62.483V45.3741L29.0379 30.5278L16.5555 32.1721Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.4964 11.1089L73.5903 25.8193L46.686 41.9837L38.2328 46.937L27.5337 31.649L62.4964 11.1089Z"
        fill="#70707A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.6854 57.5295L38.2323 62.4828V46.9367L46.6854 41.9834V57.5295Z"
        fill="#CACDD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.6859 57.5295L70.7862 71.6895V56.1366L46.6859 41.9834V57.5295Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.4786 37.1191L70.7858 54.8667V71.6902L94.8861 57.5302V38.8722L82.4786 37.1191Z"
        fill="#CACDD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.7862 56.1369L83.6769 37.7982L59.5766 23.6382L46.6859 41.9837L70.7862 56.1369Z"
        fill="#F6F6F6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.391 39.0214L73.0862 40.5366L75.6589 39.0214L73.1202 37.5674L70.391 39.0214Z"
        fill="#70707A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.6767 37.7982L94.8863 38.8717L73.5901 25.8193L59.5764 23.6382L83.6767 37.7982Z"
        fill="#70707A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.0861 40.5367V44.7358L75.6588 41.1074V39.0215L73.0861 40.5367Z"
        fill="#CACDD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.6149 51.5573L51.2794 49.0093V55.0293L55.6149 57.5773V51.5573Z"
        fill="#CACDD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.6539 56.9049L60.3185 54.3569V60.3702L64.6539 62.9182V56.9049Z"
        fill="#CACDD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8855 43.4445L20.55 40.8965V46.9165L24.8855 49.4645V43.4445Z"
        fill="#CACDD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.9246 48.7853L29.5891 46.2373V52.2573L33.9246 54.8053V48.7853Z"
        fill="#CACDD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.9386 56.4704L81.2741 53.9224V59.9424L76.9386 62.4904V56.4704Z"
        fill="#70707A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.2993 60.2271L85.7052 62.9246V51.3737L90.2993 48.6763V60.2271Z"
        fill="#70707A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.5831 71.7441C44.7664 71.7441 44.1062 71.085 44.1062 70.2697V59.3711H47.0668V70.2697C47.06 71.085 46.3998 71.7441 45.5831 71.7441Z"
        fill="#70707A"
      />
      <path
        d="M45.5834 64.4337C48.2635 64.4337 50.4362 61.6319 50.4362 58.1758C50.4362 54.7197 48.2635 51.918 45.5834 51.918C42.9033 51.918 40.7307 54.7197 40.7307 58.1758C40.7307 61.6319 42.9033 64.4337 45.5834 64.4337Z"
        fill="#ECEDEF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5335 31.649L16.1401 32.2809L51.1029 11.734L62.4963 11.1089L27.5335 31.649Z"
        fill="#F6F6F6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.0862 40.5364V44.7355L70.391 43.1864V39.062L73.0862 40.5364Z"
        fill="#ECEDEF"
      />
    </SvgIcon>
  );
};

export default PoaPropertyUpgradabledIcon;
