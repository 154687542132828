import R from "ramda";
import { createSelector, createStructuredSelector } from "reselect";
import { getFormValues } from "redux-form";
import { RESIDENCE_FORM_ID, PROVINCE_FIELD_ID } from "../constants/forms";
import { selectAllProvinces } from "./provinces";

import { getMultiplePageTranslationsByProvince } from "../i18n/translations";

// TODO: fix circular imports!!!
const selectLanguageCode = R.pathOr("en", ["aboutYou", "lang"]);
const selectProvinceCode = R.pathOr("", [
  "provinces",
  "selectedProvince",
  "code",
]);
const selectFormSelectedProvince = createSelector(
  getFormValues(RESIDENCE_FORM_ID),
  selectAllProvinces,
  (formValues, allProvinces) => {
    const provinceId = R.propOr("", PROVINCE_FIELD_ID, formValues);
    const selectedProvince = R.find(R.propEq("id", provinceId), allProvinces);
    return R.propOr(null, "code", selectedProvince);
  },
);

// Situation on Residence page where we want to leverage province specific translations
// when user selects a different province, but we leverage the state value which
// doesnt update until submitted to API
const selectProvinceCodeOverride = createSelector(
  selectFormSelectedProvince,
  selectProvinceCode,
  (provinceCodeForm, provinceCodeState) =>
    provinceCodeForm || provinceCodeState,
);

export const selectTranslations = (formNames) =>
  createSelector(
    selectProvinceCodeOverride,
    selectLanguageCode,
    (provinceCode, language) =>
      getMultiplePageTranslationsByProvince(language, provinceCode, formNames),
  );

export const errorMessageTranslations = createStructuredSelector({
  translations: selectTranslations(["errors"]),
});
