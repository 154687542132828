import React from "react";
import R from "ramda";
import Box from "@material-ui/core/Box";
import { useSelect } from "downshift";
import { useSelector } from "react-redux";
import { DropdownCharitySelectWrapper } from "./style";
import { ArrowBlueDownIcon, ArrowBlueUpIcon } from "../../icons/ArrowIcons";
import { ErrorMessage } from "../../Error";
import { Body, SmallBody } from "../../Typography";
import { isValidSelectKey } from "../../../../selectors/utils/validate";
import { ADD_CUSTOM_CHARITY } from "../../../../constants/donations";
import PlusIcon from "../../icons/PlusIcon";
import { selectCharitySelectTranslations } from "../../../../selectors/donations";

const onKeyDownHandler = (evt, onChange, handleCustom) => {
  if (onChange && isValidSelectKey(evt.key)) {
    const optionElement = document.querySelector(
      "[aria-selected='true'] > span",
    );
    const optionValue = optionElement
      ? optionElement.getAttribute("value").trim()
      : null;
    if (optionValue !== null) {
      switch (optionValue) {
        case ADD_CUSTOM_CHARITY:
          handleCustom();
          break;
        default:
          if (onChange) {
            onChange(Number(optionValue));
          }
          break;
      }
    }
  }
};

const AddNewCharityOption = ({ value, translations }) => {
  const { addCustomOption } =
    translations || useSelector(selectCharitySelectTranslations);
  return (
    <span value={value} className="option-wrapper">
      {addCustomOption}
      <PlusIcon />
    </span>
  );
};

const CharitySelectInput = ({
  value,
  onChange,
  error,
  optionList,
  contentPush = false, // control whether dropdown pushes content or overlaps
  label,
  labelColor = "text.primary",
  placeholder,
  marginBottom = 2,
  className,
  handleCustom,
  translations,
  onSelect = () => {},
  disabledCharityIds = [],
}) => {
  const initialSelectedItem = R.find(R.propEq("value", value), optionList);

  // We need to manually add extra options to the dropdown
  // so the library "downshift" can handle them appropriately
  // both with keyboard navigation and mouse
  // More info: https://www.npmjs.com/package/downshift
  const optionListWithExtraOptions = optionList.concat([
    { value: ADD_CUSTOM_CHARITY },
  ]);
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({ items: optionListWithExtraOptions, initialSelectedItem });
  const selectedValue = initialSelectedItem || selectedItem;
  const shouldDisplayError = !!error;
  let displayValue = placeholder;
  if (selectedValue) {
    displayValue =
      selectedValue.label || selectedValue.name || selectedValue.node;
  }

  // Adding a '.filter' before '.map' will avoid rendering an invisible tabindex
  // option that can only be seen/accessed while scrolling using the keyboard
  const filteredOptionsList = optionListWithExtraOptions
    .filter((option) => option !== selectedValue)
    .map((option, index) => {
      const highlightValueStyle =
        highlightedIndex === index
          ? { backgroundColor: "#bde4ff", cursor: "pointer" }
          : { cursor: "pointer" };

      // If ADD_CUSTOM_CHARITY option is selected
      if (option.value === ADD_CUSTOM_CHARITY) {
        const itemProps = getItemProps({
          item: option,
          index,
          onClick: () => handleCustom(),
        });
        return (
          <li
            key={`${option}${index + 1}`}
            className={`${className} qa-add-custom-charity add-custom-charity`}
            style={highlightValueStyle}
            {...itemProps}
          >
            <AddNewCharityOption
              value={ADD_CUSTOM_CHARITY}
              translations={translations}
            />
          </li>
        );
      }

      const isDisabledOption = disabledCharityIds.includes(option.value);

      const enabledOrDisabledStyle = isDisabledOption
        ? "disabled-option"
        : "enabled-option";

      const classList = [
        "qa-dropdown-item",
        `qa-dropdown-item-${index}`,
        enabledOrDisabledStyle,
      ];

      const itemProps = getItemProps({
        item: option,
        index,
        disabled: isDisabledOption,
        onClick: () => {
          if (onChange) {
            onChange(option.value);
          }
          return onSelect(option.value);
        },
      });
      const optionLabel = option.label || option.name || option.node;
      return (
        <li
          className={classList.join(" ")}
          style={highlightValueStyle}
          key={`${option}${index + 1}`}
          {...itemProps}
        >
          <Body component="span" value={option.value}>
            {optionLabel}
          </Body>
        </li>
      );
    });

  return (
    <Box
      onKeyDown={(evt) => onKeyDownHandler(evt, onChange, handleCustom)}
      mb={marginBottom}
      width="100%"
    >
      <DropdownCharitySelectWrapper
        contentPush={contentPush}
        selectedOption={selectedValue}
        isOpen={isOpen}
        className={className}
        error={error}
        data-hj-suppress
      >
        <label htmlFor={label} className="dropdown-label" {...getLabelProps()}>
          <SmallBody color={labelColor} id={label}>
            {label}
          </SmallBody>
        </label>
        <button
          type="button"
          className="dropdown-toggle qa-dropdown"
          {...getToggleButtonProps()}
        >
          <span className="dropdown-header">
            {displayValue}
            {isOpen ? <ArrowBlueDownIcon /> : <ArrowBlueUpIcon />}
          </span>
        </button>
        <ul {...getMenuProps()} className="dropdown-panel">
          {filteredOptionsList}
        </ul>
        {shouldDisplayError && (
          <Box mt={0.5}>
            <ErrorMessage error={error} />
          </Box>
        )}
      </DropdownCharitySelectWrapper>
    </Box>
  );
};

export default CharitySelectInput;
