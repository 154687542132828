import React from "react";

import { reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";

import EmailField from "../ui/inputs/EmailField";
import { PrimaryButton } from "../ui/Button";
import { SmallBody, H1, SmallTextLink } from "../ui/Typography";
import { FORGOT_PASSWORD_FORM_ID } from "../../constants/forms";
import { PageLayout } from "../ui/PageLayout";
import { ErrorMessage } from "../ui/Error";

const ForgotPasswordForm = ({
  error,
  handleSubmit,
  isLoading,
  loginLink,
  getStartedLink,
  translations,
}) => {
  return (
    <PageLayout>
      <form onSubmit={handleSubmit}>
        <Box
          maxWidth={500}
          mx="auto"
          py={2}
          px={2}
          bgcolor="willfulWhite.main"
          borderRadius={0.2}
          border={1}
          borderColor="border.main"
        >
          <H1>{translations.pageTitle}</H1>

          <EmailField translations={translations} />

          {/* TODO: Align spacing app wide */}
          <Box mt={2}>
            <PrimaryButton
              fullWidth
              displayArrowRight
              isLoading={isLoading}
              loadingLabel={translations.loadingLabel}
              text={translations.button.submit}
            />
          </Box>

          {error && (
            <Box display="flex" justifyContent="center" my={0.5}>
              <ErrorMessage error={error} align="center" />
            </Box>
          )}

          <Box display="flex" justifyContent="center" my={1}>
            <SmallBody>
              {translations.getStartedLabel}
              <Box component="span" ml={0.2}>
                <SmallTextLink
                  to={getStartedLink}
                  text={translations.getStarted}
                />
              </Box>
            </SmallBody>
          </Box>
          <Box display="flex" justifyContent="center" my={1}>
            <SmallBody>
              {translations.loginLabel}
              <Box component="span" ml={0.2}>
                <SmallTextLink to={loginLink} text={translations.login} />
              </Box>
            </SmallBody>
          </Box>
        </Box>
      </form>
    </PageLayout>
  );
};

export default reduxForm({ form: FORGOT_PASSWORD_FORM_ID })(ForgotPasswordForm);
