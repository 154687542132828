import { select, takeEvery, call, put } from "redux-saga/effects";
import queryString from "query-string";
import R from "ramda";
import { INIT_LANG_TYPE, setLanguageCode } from "../actions/language";
import { selectQueryParams } from "../selectors/location";

function* handleInitializeLanguage() {
  const rawQueryParams = yield select(selectQueryParams);
  const { lang } = yield call(queryString.parse, rawQueryParams);

  if (lang === "fr" || lang === "en") {
    return yield put(setLanguageCode(lang));
  }

  const browserLanguage = R.pathOr("en", ["navigator", "language"])(window);
  const isFrenchBrowser = R.test(/.*fr.*/i, browserLanguage);

  if (isFrenchBrowser) {
    return yield put(setLanguageCode("fr"));
  }
  return yield;
}

export function* watchInitalizeLanguage() {
  yield takeEvery(INIT_LANG_TYPE, handleInitializeLanguage);
}
