import { combineReducers } from "redux";
import {
  REQUEST_START_TYPE,
  REQUEST_SUCCESS_TYPE,
  REQUEST_ERROR_TYPE,
  FETCH_SUCCESS_TYPE,
  REQUEST_CLEAR_TYPE,
} from "../actions/requests";

import { UPDATE_STATUS_TYPE } from "../actions/status";

const hasData = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case FETCH_SUCCESS_TYPE:
      return {
        ...state,
        [meta.requestType]: true,
      };
    case REQUEST_CLEAR_TYPE:
      return {
        ...state,
        [payload.type]: false,
      };
    default:
      return state;
  }
};

const hasLoaded = (state = false, { type }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return true;
    default:
      return state;
  }
};

const loading = (state = {}, { type, meta }) => {
  switch (type) {
    case REQUEST_START_TYPE:
      return {
        ...state,
        [meta.requestType]: true,
      };
    case REQUEST_SUCCESS_TYPE:
      return {
        ...state,
        [meta.requestType]: false,
      };
    case REQUEST_ERROR_TYPE:
      return {
        ...state,
        [meta.requestType]: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  hasLoaded,
  hasData,
  loading,
});
