import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { MODAL_INVITE_USER } from "../../../constants/modal";
import { H4 } from "../../ui/Typography";
import { SmallTextButton } from "../../ui/Button";

const AvailablePlans = ({ translations, displayModal }) => {
  const { premiumPlan, sendInvite } = translations;
  return (
    <Box
      borderBottom={0}
      borderRight={0}
      borderLeft={0}
      borderColor="border.main"
      border={1}
    >
      <Box display="flex" p={1.5}>
        <Grid container>
          <Grid item sm={6} lg={7}>
            <H4 align="left">{premiumPlan}</H4>
          </Grid>
          <Grid item sm={6} lg={5}>
            <Box
              display="flex"
              height={1}
              alignItems="center"
              justifyContent="flex-end"
            >
              <SmallTextButton
                onClick={() => displayModal(MODAL_INVITE_USER)}
                text={sendInvite}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AvailablePlans;
