import R from "ramda";
import { createSelector, createStructuredSelector } from "reselect";
import { selectTranslations } from "./translations";
import { selectHasLoadedDataById } from "./requests";
import { selectExecutors, selectSpouseIsExecutor } from "./executors";
import { selectChildrenGuardians, selectChildren } from "./children";
import { selectSpouse } from "./spouse";
import { selectPetGuardians, selectPets } from "./pets";
import {
  selectPropertyAttorneys,
  selectSpouseIsPropertyAttorney,
} from "./property-attorneys";
import {
  selectPersonalAttorneys,
  selectSpouseIsPersonalAttorney,
} from "./personal-attorneys";

export const selectLastAppointeeFirstName = R.pathOr("", [
  "appointees",
  "lastAppointeeFirstName",
]);
export const selectLastAppointeeLastName = R.pathOr("", [
  "appointees",
  "lastAppointeeLastName",
]);
export const selectLastAppointeeEmail = R.pathOr("", [
  "appointees",
  "lastAppointeeEmail",
]);
const selectAppointeeLoadingHash = R.pathOr({}, [
  "formUi",
  "notifyingAppointee",
]);
const selectAppointeeCardsOpen = R.pathOr({}, [
  "formUi",
  "toggleAppointeeCard",
]);

const selectArrangementsData = R.pathOr({}, ["yourArrangements"]);

export const selectHasAppointee = createSelector(
  selectChildrenGuardians,
  selectExecutors,
  selectPersonalAttorneys,
  selectPropertyAttorneys,
  selectPetGuardians,
  selectSpouseIsExecutor,
  selectArrangementsData,
  (
    childrenGuardians,
    executors,
    personalAttorneys,
    propertyAttorneys,
    petGuardians,
    spouseIsExecutor,
    arragementsData,
  ) => {
    return (
      childrenGuardians.length > 0 ||
      executors.length > 0 ||
      personalAttorneys.length > 0 ||
      propertyAttorneys.length > 0 ||
      petGuardians.length > 0 ||
      arragementsData.spouseIsPersonalAttorney === true ||
      arragementsData.spouseIsPropertyAttorney === true ||
      spouseIsExecutor === true
    );
  },
);

const selectAppointeesWithPrimarySpouseBySection = (
  selectSection,
  selectSpouseIsPrimary,
  role,
) =>
  createSelector(
    selectSection,
    selectSpouseIsPrimary,
    selectSpouse,
    selectAppointeeLoadingHash,
    selectAppointeeCardsOpen,
    (
      appointees,
      isSpousePrimary,
      spouse,
      appointeesLoading,
      appointeeCardsOpen,
    ) => {
      // if spouse is primary, fetch spouse data and insert it at top of the list of appointees.
      if (isSpousePrimary) {
        const appointeesIndexed = appointees.map((appointee, index) => {
          const { id } = appointee;
          const appointeeKey = `${role}-${id}-false`;
          return {
            ...appointee,
            appointeeKey,
            index: index + 1,
            role,
            isSpouse: false,
            isLoading: appointeesLoading[appointeeKey],
            isCardOpen: appointeeCardsOpen[appointeeKey],
          };
        });
        const spouseKey = `${role}-spouse-true`;
        return [
          {
            ...spouse,
            appointeeKey: spouseKey,
            id: "spouse",
            index: 0,
            role,
            isSpouse: true,
            isLoading: appointeesLoading[spouseKey],
            isCardOpen: appointeeCardsOpen[spouseKey],
          },
          ...appointeesIndexed,
        ];
      }
      // else map over appointees and build shape UI expects
      return appointees.map((appointee, index) => {
        const { id } = appointee;
        const appointeeKey = `${role}-${id}-false`;
        return {
          appointeeKey,
          index,
          role,
          isLoading: appointeesLoading[appointeeKey],
          isCardOpen: appointeeCardsOpen[appointeeKey],
          ...appointee,
        };
      });
    },
  );
const selectAppointeesWithCouplesBySection = (selectSection, role) =>
  createSelector(
    selectSection,
    selectAppointeeLoadingHash,
    selectAppointeeCardsOpen,
    (appointees, appointeesLoading, appointeeCardsOpen) =>
      appointees.reduce((totalAppointees, currAppointee, index) => {
        const { id } = currAppointee;
        const appointeeKeyPrimary = `${role}-${id}-false`;
        const appointeeKeySpouse = `${role}-${id}-true`;
        // if this appointee is a couple (and has a spouse appointee)
        if (currAppointee.spouseFirstName && currAppointee.spouseLastName) {
          return [
            ...totalAppointees,
            {
              // primary appointee
              ...currAppointee,
              appointeeKey: appointeeKeyPrimary,
              index,
              role,
              isSpouse: false,
              isLoading: appointeesLoading[appointeeKeyPrimary],
              isCardOpen: appointeeCardsOpen[appointeeKeyPrimary],
            },
            {
              // spouse appointee
              appointeeKey: appointeeKeySpouse,
              id: currAppointee.id,
              firstName: currAppointee.spouseFirstName,
              lastName: currAppointee.spouseLastName,
              index,
              role,
              isSpouse: true,
              isLoading: appointeesLoading[appointeeKeySpouse],
              isCardOpen: appointeeCardsOpen[appointeeKeySpouse],
            },
          ];
        }
        return [
          ...totalAppointees,
          {
            ...currAppointee,
            appointeeKey: appointeeKeyPrimary,
            index,
            role,
            isSpouse: false,
            isLoading: appointeesLoading[appointeeKeyPrimary],
            isCardOpen: appointeeCardsOpen[appointeeKeyPrimary],
          },
        ];
      }, []),
  );

const selectAppointedExecutors = selectAppointeesWithPrimarySpouseBySection(
  selectExecutors,
  selectSpouseIsExecutor,
  "executor",
);

const selectAppointedChildrenGuardians = selectAppointeesWithCouplesBySection(
  selectChildrenGuardians,
  "childrenGuardian",
);

const selectAppointedPetGuardians = selectAppointeesWithCouplesBySection(
  selectPetGuardians,
  "petGuardian",
);

const selectAppointedGuardians = createSelector(
  selectAppointedChildrenGuardians,
  selectAppointedPetGuardians,
  (childrenGuardians, petGuardians) => [...childrenGuardians, ...petGuardians],
);

const selectAppointedPropertyAttorneys = selectAppointeesWithPrimarySpouseBySection(
  selectPropertyAttorneys,
  selectSpouseIsPropertyAttorney,
  "propertyAttorneys",
);

const selectAppointedPersonalAttorneys = selectAppointeesWithPrimarySpouseBySection(
  selectPersonalAttorneys,
  selectSpouseIsPersonalAttorney,
  "personalAttorneys",
);

const selectChildrenRoleLabelTranslations = createSelector(
  selectTranslations(["global", "appointeesPage", "appointees"]),
  selectChildren,
  (translations, childrenData) => {
    if (childrenData.length === 1) {
      const childFirstName = R.pathOr("", [0, "firstName"], childrenData);
      const childLastName = R.pathOr("", [0, "lastName"], childrenData);
      const childFullName = `${childFirstName} ${childLastName}`;

      return {
        childrenGuardian: translations.roleLabels.childrenGuardian.replace(
          "CHILD",
          childFullName,
        ),
      };
    }

    if (childrenData.length > 1) {
      const childrenNamesList = childrenData.map(
        (child) => `${child.firstName} ${child.lastName}`,
      );

      const childrenNames = childrenNamesList.join(", ");
      return {
        childrenGuardian: translations.roleLabels.childrenGuardian.replace(
          "CHILD",
          childrenNames,
        ),
      };
    }

    return {
      childrenGuardian: translations.roleLabels.childrenGuardian,
    };
  },
);

const selectPetRoleLabelTranslations = createSelector(
  selectTranslations(["global", "appointeesPage", "appointees"]),
  selectPets,
  (translations, petData) => {
    if (petData.length === 1) {
      const petName = R.pathOr("", [0, "firstName"], petData);

      return {
        petGuardian: translations.roleLabels.petGuardian.replace(
          "PET",
          petName,
        ),
      };
    }

    if (petData.length > 1) {
      const petNamesList = petData.map((pet) => `${pet.firstName}`);
      const petNames = petNamesList.join(", ");

      return {
        petGuardian: translations.roleLabels.petGuardian.replace(
          "PET",
          petNames,
        ),
      };
    }

    return {
      petGuardian: translations.roleLabels.petGuardian,
    };
  },
);

export const selectLastAppointeeFullName = createSelector(
  selectLastAppointeeFirstName,
  selectLastAppointeeLastName,
  (firstName, lastName) => `${firstName} ${lastName}`,
);

const selectAppointeeTranslations = createSelector(
  selectTranslations(["global", "appointeesPage", "appointees"]),
  selectChildrenRoleLabelTranslations,
  selectPetRoleLabelTranslations,
  (translations, childrenLabels, petLabels) => {
    return {
      ...translations,
      roleLabels: {
        ...translations.roleLabels,
        childrenGuardian: childrenLabels.childrenGuardian,
        petGuardian: petLabels.petGuardian,
      },
    };
  },
);

export const AppointeesPageProps = createStructuredSelector({
  translations: selectAppointeeTranslations,
  hasLoaded: selectHasLoadedDataById("dashboard"), // TODO: Change this when we have the appointees endpoint in place
  appointees: createStructuredSelector({
    executors: selectAppointedExecutors,
    guardians: selectAppointedGuardians,
    propertyAttorneys: selectAppointedPropertyAttorneys,
    personalAttorneys: selectAppointedPersonalAttorneys,
  }),
});
