import { CONTACTS_FORMATTED_TYPE } from "../sagas/contacts";

const contacts = (state = [], { type, payload }) => {
  switch (type) {
    case CONTACTS_FORMATTED_TYPE:
      return payload || null;
    default:
      return state;
  }
};

export default contacts;
