import React from "react";

import { connect } from "react-redux";

import { submitResetPassword } from "../../actions/auth";
import { resetPasswordPageProps } from "../../selectors/auth";

import ResetPasswordForm from "../../components/forms/ResetPasswordForm";

const ResetPasswordPage = ({ submitResetPassword, ...restOfProps }) => {
  return <ResetPasswordForm onSubmit={submitResetPassword} {...restOfProps} />;
};

export default connect(resetPasswordPageProps, { submitResetPassword })(
  ResetPasswordPage
);
