import React from "react";

import SvgIcon from "./SvgIcon";

const AssetListNavIcon = () => {
  return (
    <SvgIcon width="22px" height="22px" viewBox="0 0 18 21">
      <path
        d="M15.5,4H18a2,2,0,0,1,2,2V19a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V6A2,2,0,0,1,6,4H8.5"
        transform="translate(-3 -1)"
        fill="none"
        stroke="#0064dc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
      />
      <path
        d="M8.62,3.51a2,2,0,0,1,.71-1.09A2,2,0,0,1,10.56,2h2.88a2,2,0,0,1,1.94,1.51L16,6H8Z"
        transform="translate(-3 -1)"
        fill="none"
        stroke="#0064dc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
      />
      <path
        d="M9,12h6"
        transform="translate(-3 -1)"
        fill="none"
        stroke="#0064dc"
        strokeLinecap="round"
        strokeWidth="2px"
      />
      <path
        d="M9,16h6"
        transform="translate(-3 -1)"
        fill="none"
        stroke="#0064dc"
        strokeLinecap="round"
        strokeWidth="2px"
      />
    </SvgIcon>
  );
};

export default AssetListNavIcon;
