import React from "react";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import PlusIcon from "./icons/PlusIcon";
import { SmallTextButton } from "./Button";

const AddCoFieldWrapper = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    position: relative;
    top: 1px;
  }
`;

const AddCoFieldButton = ({ text, onClick, hasMargin, index }) => {
  const coFieldClass = `qa-add-co-field-${index}`;
  return (
    <AddCoFieldWrapper mr={hasMargin && 1.5} onClick={onClick}>
      <PlusIcon color="blue" width={20} height={20} />
      <Box ml={0.5}>
        <SmallTextButton text={text} className={coFieldClass} />
      </Box>
    </AddCoFieldWrapper>
  );
};

export default AddCoFieldButton;
