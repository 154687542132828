import R from "ramda";
import { createSelector } from "reselect";

import { PROVINCE_FIELD_ID } from "../constants/forms";

// TODO: Figure out long-term solution for circular-dependency selector issue
const selectIsLoggedIn = R.pathOr(false, ["auth", "loggedIn"]);

export const selectProvinceCode = R.pathOr("", [
  "provinces",
  "selectedProvince",
  "code",
]);

export const selectAgeOfMajority = R.pathOr(19, [
  "provinces",
  "selectedProvince",
  "ageOfMajority",
]);

export const selectAllProvinces = R.pathOr([], ["provinces", "allProvinces"]);

export const selectSelectedProvince = createSelector(
  R.pathOr("", ["aboutYou", PROVINCE_FIELD_ID]),
  selectAllProvinces,
  R.pathOr({}, ["provinces", "selectedProvince"]),
  (provinceId, allProvinces, selectedProvinceFromProvinceState) => {
    // On some screens, the full province list may not be populated.
    // Use the selected province from provinces in that case.
    if (!allProvinces.length) {
      return selectedProvinceFromProvinceState;
    }
    return R.find(R.propEq("id", provinceId))(allProvinces);
  },
);

export const selectUnlockedProvinces = createSelector(
  selectAllProvinces,
  R.reject(R.propEq("status", "locked")),
);

export const selectIsEssentialsOnlyProvinceSelected = createSelector(
  selectSelectedProvince,
  (selectedProvince) =>
    !R.propOr(false, "premiumAndCouplesAvailable", selectedProvince),
);

export const selectIsPremiumProvinceSelected = createSelector(
  selectSelectedProvince,
  (selectedProvince) =>
    R.propOr(false, "premiumAndCouplesAvailable", selectedProvince),
);

export const selectIsFrenchAvailableInSelectedProvince = createSelector(
  selectSelectedProvince,
  (selectedProvince) => R.propOr(true, "frenchAvailable", selectedProvince),
);

export const selectAvailableProvinces = createSelector(
  selectAllProvinces,
  selectIsLoggedIn,
  selectUnlockedProvinces,
  (allProvinces, isLoggedIn, availableProvinces) => {
    if (isLoggedIn) {
      return availableProvinces;
    }
    return allProvinces;
  },
);

export const selectIsCurrentProvinceSelectedByCode = (code) =>
  createSelector(
    selectSelectedProvince,
    (currentProvince) => code === R.propOr(false, "code", currentProvince),
  );

export const selectProvinceByCode = (code) =>
  createSelector(selectAllProvinces, R.find(R.propEq("code", code)));

export const selectProvinceById = (id) =>
  createSelector(selectAllProvinces, R.find(R.propEq("id", id)));
