import { put, takeEvery } from "redux-saga/effects";
import { INITIALIZE_APP_TYPE } from "../actions/app-lifecycle";
import { initializeLanguage } from "../actions/language";
import { initializeExperiments } from "../actions/experiments";
import { initializeGA4Parameters } from "../actions/analytics";
import { getGA4AttributionParameters } from "../utilities/analytics";

function* initializeApp() {
  yield put(initializeLanguage());
  yield put(initializeExperiments());
  yield put(initializeGA4Parameters(getGA4AttributionParameters()));
}

export function* watchInitializeApp() {
  yield takeEvery(INITIALIZE_APP_TYPE, initializeApp);
}
