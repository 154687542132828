import React from "react";

import SvgIcon from "./SvgIcon";

const AppointeesNavIcon = () => {
  return (
    <SvgIcon width="22px" height="22px" viewBox="0 0 20.73 22">
      <path
        d="M12,12A5,5,0,1,0,7,7,5,5,0,0,0,12,12Z"
        transform="translate(-2.27 -1)"
        fill="none"
        stroke="#0064dc"
        strokeWidth="2px"
      />
      <path
        d="M17,22H5.27a2,2,0,0,1-.83-.18,1.74,1.74,0,0,1-.67-.5,2,2,0,0,1-.42-.73,2.16,2.16,0,0,1-.07-.84l.39-3.12a3,3,0,0,1,1-1.88,3,3,0,0,1,2-.75H7"
        transform="translate(-2.27 -1)"
        fill="none"
        stroke="#0064dc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
      />
      <path
        d="M16,16l2.25,2L22,14"
        transform="translate(-2.27 -1)"
        fill="none"
        stroke="#0064dc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
      />
    </SvgIcon>
  );
};

export default AppointeesNavIcon;
