import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import { PrivateRouteProps } from "../selectors/auth";
import { DASHBOARD_PATH } from "../constants/routes";

const UnauthenticatedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.isAuthenticated ? (
          <Redirect
            to={{
              pathname: DASHBOARD_PATH,
              search: props.location.search,
              state: {
                from: props.location,
              },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default connect(PrivateRouteProps)(UnauthenticatedRoute);
