import React from "react";
import { reduxForm, Field } from "redux-form";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { ErrorMessage } from "../../ui/Error";
import { SecondaryButton } from "../../ui/Button";
import { CAA_FORM_ID, CAA_FIELD_ID } from "../../../constants/forms";
import TextInput from "../../ui/inputs/TextInput";

const CaaMembershipForm = ({
  handleSubmit,
  displayTooltipModal,
  error,
  isLoading,
  translations,
}) => {
  const gridSizeAndStyle = {
    flexDisplay: "flex",
    top: {
      lg: 7,
      md: 9,
    },
    down: {
      lg: 5,
      md: 3,
    },
  };
  return (
    <Box mt={2}>
      <form onSubmit={handleSubmit}>
        <Grid container item spacing={1}>
          <Grid
            item
            lg={gridSizeAndStyle.top.lg}
            md={gridSizeAndStyle.top.md}
            sm={12}
          >
            <Field
              name={CAA_FIELD_ID}
              optionalLabel={translations.label.optional}
              component={TextInput}
              type="number"
              noMargin
              label={translations.caaLabel}
              applyButtonText={translations.applyButtonText}
              placeholder={translations.caaPlaceholder}
              fieldHasToolTip
              translations={translations}
              displayTooltipModal={displayTooltipModal}
              tooltipKey="checkout.CaaPromo"
            />
          </Grid>
          <Grid
            item
            lg={gridSizeAndStyle.down.lg}
            md={gridSizeAndStyle.down.md}
          >
            <Box
              display={gridSizeAndStyle.flexDisplay}
              maxWidth="100%"
              height="100%"
              alignItems="flex-end"
            >
              <SecondaryButton
                loadingLabel={translations.caaMembershipIsLoading}
                isLoading={isLoading}
                displayArrowRight
                fullWidth
                text={translations.button.validate}
              />
            </Box>
          </Grid>
        </Grid>
      </form>
      {error && <ErrorMessage error={error} />}
    </Box>
  );
};

export default reduxForm({ form: CAA_FORM_ID })(CaaMembershipForm);
