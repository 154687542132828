import React from "react";
import styled from "styled-components";
import { Field } from "redux-form";
import { colorPalette, media } from "../../../config/Theme";

import TextInput from "./TextInput";

const NumberInputFieldContainer = styled.div`
  position: relative;
`;

const Percentage = styled.span`
  position: absolute;
  top: ${(props) => props.percentOffset}px;
  right: 16px;
  ${NumberInputFieldContainer}:focus-within & {
    top: ${(props) => props.percentOffset + 1}px;
    right: 17px;
  }
  color: ${colorPalette.willfulGrey1};
  font-family: "TT Norms";
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.01em;
  transition: all 0.2s ease-in;

  ${media.desktop} {
    top: ${(props) => props.percentOffsetDesktop}px;
    ${NumberInputFieldContainer}:focus-within & {
      top: ${(props) => props.percentOffsetDesktop}px;
    }
    font-size: 20px;
  }

  &:after {
    content: "${(props) => (props.percentage ? "%" : "")}";
  }
`;

const NumberInput = ({
  numberInputName,
  meta: { touched, error },
  onError,
  label,
  placeholder,
  step = 1,
  min,
  max,
  percentage,
  noMargin,
  lineHeight,
  marginTop = 8,
  small,
  hideFieldLevelError,
  highLightFieldOnFormLevelError,
  textAlignEnd = false,
  disabled = false,
}) => {
  const hasLabel = Boolean(label);
  let percentOffsetDesktop = (hasLabel ? 43 : 19) + marginTop;
  let percentOffset = (hasLabel ? 40 : 18) + marginTop;
  if (small) {
    percentOffsetDesktop = (hasLabel ? 33 : 10) + marginTop;
    percentOffset = (hasLabel ? 35 : 14) + marginTop - 2;
  }
  if (touched && onError) {
    onError(error);
  }
  return (
    <>
      <NumberInputFieldContainer>
        <Field
          component={TextInput}
          name={numberInputName}
          label={label}
          placeholder={placeholder}
          step={step}
          min={min}
          max={max}
          percentage={percentage}
          noMargin={noMargin}
          lineHeight={lineHeight}
          marginTop={marginTop}
          height={small ? "46px" : undefined}
          hideFieldLevelError={hideFieldLevelError}
          highLightFieldOnFormLevelError={highLightFieldOnFormLevelError}
          textAlignEnd={textAlignEnd}
          disabled={disabled}
          type="number"
        />
        <Percentage
          percentage={percentage}
          percentOffsetDesktop={percentOffsetDesktop}
          percentOffset={percentOffset}
        />
      </NumberInputFieldContainer>
    </>
  );
};

const NumberInputField = ({ name, validate, ...restOfProps }) => {
  return (
    <Field
      name={name}
      numberInputName={name}
      validate={validate}
      component={NumberInput}
      {...restOfProps}
    />
  );
};

export default NumberInputField;
