import React from "react";

import SvgIcon from "./SvgIcon";

const PoaPersonalUpgradeIcon = () => {
  return (
    <SvgIcon width="120" height="97" viewBox="0 0 120 97" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60 96.9264C93.1371 96.9264 120 79.0992 120 57.1082C120 35.1172 93.1371 17.29 60 17.29C26.8629 17.29 0 35.1172 0 57.1082C0 79.0992 26.8629 96.9264 60 96.9264Z"
        fill="#CACDD4"
      />
      <path
        d="M67.9346 72.6627L80.4848 68.6652L95.7775 61.0828C96.9395 60.3391 96.9395 58.5727 95.731 57.8755L80.8334 49.0903L65.9358 40.3052L35.5364 46.6326L28.4246 50.723L65.9358 72.4303C66.5401 72.8022 67.2838 72.8951 67.9346 72.6627Z"
        fill="#4A4A4A"
      />
      <path
        d="M73.512 31.0146L53.85 19.6729L34.3738 8.42415L34.1879 8.28471C33.7696 7.95933 33.1188 7.91285 32.654 8.14526L25.9141 12.0498C26.4254 11.8174 27.0296 11.8638 27.448 12.1892L27.6339 12.3287L47.11 23.4844L66.772 34.8261C67.5622 35.3374 68.213 36.0811 68.5849 36.9643L75.2783 33.1063C74.9529 32.2231 74.3022 31.5259 73.512 31.0146Z"
        fill="#70707A"
      />
      <path
        d="M68.6309 37.0114C69.0027 37.7551 69.1887 38.5453 69.1887 39.382V69.7814C69.1887 71.1294 68.7703 72.059 68.0266 72.4774L74.7201 68.6658C75.4173 68.294 75.8821 67.3178 75.8821 65.9698V35.5239C75.8821 34.6872 75.6962 33.897 75.3243 33.1533L68.6309 37.0114Z"
        fill="#ECEDEF"
      />
      <path
        d="M66.772 34.8724L47.1099 23.5307L27.6803 12.282L27.4944 12.1425C26.1929 11.3988 25.1238 12.189 25.1238 13.9089V44.4013C25.1238 46.8648 26.6112 49.7003 28.4705 50.8158L65.8423 72.3836C66.4931 72.8485 67.2833 72.8949 68.027 72.5231C68.7242 72.1512 69.189 71.1751 69.189 69.8271V39.3812C69.1426 37.6148 68.2594 35.895 66.772 34.8724Z"
        fill="#70707A"
      />
      <path
        d="M66.7716 34.8716L27.4475 12.1417C27.0292 11.8628 26.3784 11.7699 25.9136 12.0488L25.5417 12.2812C26.0531 12.0488 26.6573 12.0952 27.0757 12.4206L66.4462 35.104C67.8872 36.1731 68.7703 37.8465 68.8168 39.6593V52.3954C68.9098 53.1392 68.5844 53.8364 67.9801 54.3012L68.352 54.0688C68.9563 53.6505 69.2816 52.9067 69.1887 52.163V39.4269C69.1422 37.6141 68.259 35.8942 66.7716 34.8716Z"
        fill="#4A4A4A"
      />
      <path
        d="M67.9338 54.256C67.4225 54.4884 66.8182 54.4419 66.3999 54.1166L27.0294 31.4332C25.5884 30.3641 24.7052 28.6907 24.6588 26.8779V14.1882C24.5658 13.4445 24.8912 12.7473 25.4954 12.2825C26.0067 12.0501 26.611 12.0965 27.0294 12.4219L66.3999 35.1053C67.8409 36.1744 68.724 37.8478 68.7705 39.6606V52.3967C68.8635 53.0475 68.5381 53.7912 67.9338 54.256Z"
        fill="#CACDD4"
      />
      <path
        d="M33.5833 32.5952L37.4413 34.7799V39.7535L33.5833 37.5223V32.5952Z"
        fill="#4A4A4A"
      />
      <path
        d="M57.3362 46.3071L61.2407 48.5383V53.4654L57.3362 51.2343V46.3071Z"
        fill="#4A4A4A"
      />
      <path
        d="M34.0018 32.9207L32.8862 32.3164V37.2435L35.4892 38.731L36.7907 39.5212V34.5476L34.0018 32.9207Z"
        fill="#F6F6F6"
      />
      <path
        d="M57.8009 46.6791L56.6853 46.0283V51.0019L59.1954 52.4429L60.5433 53.1866V48.2595L57.8009 46.6791Z"
        fill="#F6F6F6"
      />
      <path
        d="M56.6853 46.0282L57.0572 45.8423L60.9617 48.027L60.5433 48.2594L56.6853 46.0282Z"
        fill="#70707A"
      />
      <path
        d="M60.5432 53.1874L60.9616 53.0014V48.0278L60.5432 48.2602V53.1874Z"
        fill="#70707A"
      />
      <path
        d="M43.67 16.6978L42.5079 17.395L43.2051 17.8133L42.4614 18.2317L44.2278 18.4176L59.5204 27.2028L58.7767 27.6676L60.5431 27.807L61.7516 27.1563L43.67 16.6978Z"
        fill="#4A4A4A"
      />
      <path
        d="M32.8862 32.3159L33.3046 32.0835L37.1626 34.3147L36.7907 34.5471L32.8862 32.3159Z"
        fill="#70707A"
      />
      <path
        d="M36.7908 39.5215L37.1626 39.2426V34.3154L36.7908 34.5478V39.5215Z"
        fill="#70707A"
      />
      <path
        d="M57.7076 20.0909L44.1348 12.2354C43.67 11.91 43.0657 11.8635 42.5544 12.0959L41.3923 12.7932C41.5783 12.6537 41.8572 12.6537 42.0896 12.6537C42.4149 12.7002 42.6938 12.7932 42.9727 12.9791L56.5921 20.7881C57.8936 21.6713 58.7302 23.0658 58.8232 24.5997V27.621L59.9853 26.9238V23.9489C59.8923 22.3685 59.0091 20.9276 57.7076 20.0909Z"
        fill="#CACDD4"
      />
      <path
        d="M56.5452 20.788L42.9259 12.9325C42.647 12.793 42.3681 12.6536 42.0427 12.6071C41.8103 12.5606 41.5779 12.6536 41.3455 12.7465C40.9272 13.0719 40.6483 13.6297 40.6947 14.1875V17.2088L42.4146 18.185V15.6749C42.4146 15.0707 42.8329 14.8382 43.3442 15.1636L43.5767 15.3031L56.0804 22.5543C56.6382 22.9262 56.9636 23.484 57.01 24.1812V26.6912L58.7764 27.6674V24.646C58.6834 23.0656 57.8467 21.6247 56.5452 20.788Z"
        fill="#F6F6F6"
      />
    </SvgIcon>
  );
};

export default PoaPersonalUpgradeIcon;
