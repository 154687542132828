import R from "ramda";
import { createSelector } from "reselect";
import {
  bundlePlan,
  essentialsPlan,
  notarialWill,
  premiumPlan,
} from "../constants/plans";
import { selectTranslations } from "./translations";

export const selectPartnerCampaignId = R.pathOr(null, [
  "discounts",
  "partnerDiscount",
  "id",
]);

export const selectPartnerDiscountPartnerName = R.pathOr("", [
  "discounts",
  "partnerDiscount",
  "partnerName",
]);

export const selectPartnerDiscountAmount = R.pathOr(0, [
  "discounts",
  "partnerDiscount",
  "discountAmount",
]);

export const selectPartnerDiscountRemainingDays = R.pathOr(0, [
  "discounts",
  "partnerDiscount",
  "remainingDays",
]);

export const selectPartnerDiscountType = R.pathOr("", [
  "discounts",
  "partnerDiscount",
  "discountType",
]);

export const selectPartnershipType = R.pathOr("", [
  "discounts",
  "partnerDiscount",
  "partnershipType",
]);

export const selectHasPartnerDiscount = R.pathOr(false, [
  "discounts",
  "hasPartnerDiscount",
]);

export const selectShouldPartnerDiscountBannerDisplayed = R.pathOr(false, [
  "discounts",
  "shouldPartnerDiscountBannerBeDisplayed",
]);

export const selectHasVolumePartnerDiscount = createSelector(
  selectHasPartnerDiscount,
  selectPartnershipType,
  (hasPartnerDiscount, partnershipType) => {
    return hasPartnerDiscount && partnershipType === "volume";
  },
);

export const selectHasRevsharePartnerDiscount = createSelector(
  selectHasPartnerDiscount,
  selectPartnershipType,
  (hasPartnerDiscount, partnershipType) => {
    return hasPartnerDiscount && partnershipType === "revshare";
  },
);

export const isPartnerDiscountBannerOpen = createSelector(
  selectHasPartnerDiscount,
  selectShouldPartnerDiscountBannerDisplayed,
  (hasPartnerDiscount, shouldBannerBeDisplayed) => {
    return hasPartnerDiscount && shouldBannerBeDisplayed;
  },
);

export const selectPlanNameInVolumePartnerDiscount = createSelector(
  selectPartnerDiscountAmount,
  selectTranslations(["global", "yourPlanTranslations"]),
  (discountAmount, translations) => {
    switch (discountAmount) {
      case notarialWill.price:
        return translations[notarialWill.symbol].planName;
      case essentialsPlan.price:
        return translations[essentialsPlan.symbol].planName;
      case premiumPlan.price:
        return translations[premiumPlan.symbol].planName;
      case bundlePlan.price:
        return translations.bundle.planName;
      default:
        return "";
    }
  },
);

export const selectIsUnlimitedUpdatesPartnerCampaignId = createSelector(
  selectPartnerCampaignId,
  (partnerCampaignId) => {
    const unlimitedUpdatesPartnerCampaignIdsString = R.pathOr("", [
      "env",
      "UNLIMITED_UPDATES_PARTNER_CAMPAIGN_IDS",
    ])(window);

    const unlimitedUpdatesPartnerCampaignIds = unlimitedUpdatesPartnerCampaignIdsString
      .split(",")
      .map((id) => parseInt(id, 10));

    return unlimitedUpdatesPartnerCampaignIds.includes(partnerCampaignId);
  },
);
