import React from "react";

import { Field, reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";

import { PrimaryButton, TextButton } from "../../ui/Button";
import { ErrorMessage } from "../../ui/Error";

import {
  ADD_AND_EDIT_EXECUTORS_FORM_ID,
  ADD_AND_EDIT_EXECUTORS_FIELD_ID,
  EXECUTORS_FIELD_ID,
  CO_EXECUTORS_CHECKBOX_FIELD_ID,
} from "../../../constants/forms";
import NameFields from "../../ui/inputs/NameFields";
import CheckboxInput from "../../ui/inputs/CheckboxInput";

const AddEditExecutorsForm = ({
  error,
  handleSubmit,
  closeModal,
  isLoading,
  translations,
  extraProps,
  submitting,
}) => {
  const { isQuebecSelected, shouldShowCoExecutorFields } = extraProps;

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" justifyContent="center" pt={2}>
        <Box>
          <NameFields
            useTheir
            fieldName={EXECUTORS_FIELD_ID}
            translations={translations}
          />
        </Box>

        {!isQuebecSelected && (
          <>
            <Box mb={1}>
              <Field
                component={CheckboxInput}
                name={CO_EXECUTORS_CHECKBOX_FIELD_ID}
                label={translations.checkboxLabel}
                type="checkbox"
                noMargin
                optional
              />
            </Box>

            {shouldShowCoExecutorFields && (
              <>
                <NameFields
                  useTheir
                  fieldName={ADD_AND_EDIT_EXECUTORS_FIELD_ID}
                  translations={translations}
                />
              </>
            )}
          </>
        )}

        <Box mt={1}>
          <PrimaryButton
            fullWidth
            isLoading={isLoading}
            isDisabled={submitting}
            text={
              shouldShowCoExecutorFields
                ? translations.button.submitCoExecutors
                : translations.button.submit
            }
          />
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center" mt={1}>
          <TextButton text={translations.button.cancel} onClick={closeModal} />
        </Box>
        <ErrorMessage error={error} />
      </Box>
    </form>
  );
};

export default reduxForm({
  form: ADD_AND_EDIT_EXECUTORS_FORM_ID,
})(AddEditExecutorsForm);
