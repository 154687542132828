import React from "react";
import { FieldArray, reduxForm, getFormValues } from "redux-form";

import Box from "@material-ui/core/Box";
import { useMediaQuery, useTheme } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { Form } from "../../ui/Form";
import { ErrorMessage } from "../../ui/Error";

import {
  CHILDREN_FIELD_ID,
  CHILDREN_FORM_ID,
  PARENT_NOT_APPLICABLE_VALUE,
  PARENT_NOT_SPOUSE_VALUE,
  PARENT_SPOUSE_VALUE,
  PARENT_USER_VALUE,
} from "../../../constants/forms";
import { displayTooltipModal } from "../../../actions/tooltip-modal";
import H2WithUnderlineLink from "../../ui/H2WithUnderlineLink";
import { orderedFillColors } from "../../../utilities/allocations";
import { SecondaryButton, TextButton } from "../../ui/Button";
import PeopleCard from "../../ui/PeopleCard";
import {
  childrenNextPage,
  openModalChildForm,
  removeChild,
} from "../../../actions/children";

const ChildrenCardFieldArray = ({ fields, translations, handleChange }) => {
  const dispatch = useDispatch();

  let colorIndex = 0;
  const allFields = fields.getAll() || [];
  const fieldsLength = allFields.filter((f) => f._destroy !== true).length;
  return (
    <Box>
      {fields.map((fieldId, index) => {
        const field = fields.get(index);
        if (field && field._destroy === true) {
          return null;
        }
        const color = orderedFillColors[colorIndex % orderedFillColors.length];
        colorIndex += 1;
        const childName = `${field.firstName} ${field.middleName || ""} ${
          field.lastName
        }`;
        return (
          <Box mb={1} mt={2} key={`${index + 1}-${fieldId}`}>
            <PeopleCard
              id={fieldId}
              fieldId={fieldId}
              translations={translations}
              text={childName}
              color={color}
              onEdit={() => dispatch(openModalChildForm({ ...field, index }))}
              onRemove={() => {
                dispatch(removeChild(field));
                return handleChange(`${fieldId}._destroy`, true);
              }}
              {...field}
            />
          </Box>
        );
      })}
      <SecondaryButton
        text={
          fieldsLength > 0
            ? translations.button.addMoreLabel
            : translations.button.addLabel
        }
        fullWidth
        displayPlusIcon
        className="qa-add"
        type="button"
        onClick={() => dispatch(openModalChildForm(null))}
      />
    </Box>
  );
};

const getChildrenCount = (formData) => {
  if (!formData) {
    return 0;
  }
  return formData[CHILDREN_FORM_ID]?.filter((field) => field._destroy !== true)
    .length;
};

const ChildrenForm = ({
  error,
  handleSubmit,
  backLink,
  change,
  hasSpouse,
  isLoading,
  translations,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isTablet = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const skipButtonOffset = () => {
    if (isDesktop) return 72;
    if (isTablet) return 0;
    if (isMobile) return 104;
    return 0;
  };

  const defaultFieldObject = {
    parent1: PARENT_USER_VALUE,
    parent2: hasSpouse ? PARENT_SPOUSE_VALUE : PARENT_NOT_SPOUSE_VALUE,
    parent3: PARENT_NOT_APPLICABLE_VALUE,
  };
  const formData = useSelector(getFormValues(CHILDREN_FORM_ID));
  const formDataLength = formData ? formData[CHILDREN_FORM_ID].length : 0;
  const showZeroChildrenNotice = formDataLength > 0;
  const hasNoChildren = getChildrenCount(formData) === 0;

  const headerWithLink = (
    <H2WithUnderlineLink
      text={translations.pageTitle}
      underlineText={translations.pageTitleUnderlineLink}
      onClick={() =>
        dispatch(displayTooltipModal(translations.pageTitleModalKey))
      }
    />
  );
  return (
    <>
      <Form
        isLoading={isLoading}
        onSubmit={handleSubmit}
        backLink={backLink}
        translations={translations}
        customHeaderComponent={headerWithLink}
        disabled={hasNoChildren}
      >
        <FieldArray
          rerenderOnEveryChange
          handleChange={change}
          name={CHILDREN_FIELD_ID}
          component={ChildrenCardFieldArray}
          defaultFieldObject={defaultFieldObject}
          translations={translations}
        />
        <Box mb={2}>
          <ErrorMessage error={error} />
        </Box>
      </Form>
      {hasNoChildren && (
        <Box display="flex" flexDirection="column" justify="center" mb={8}>
          <TextButton
            style={{
              position: "relative",
              bottom: skipButtonOffset(),
            }}
            text={translations.button.skip}
            onClick={() => dispatch(childrenNextPage(showZeroChildrenNotice))}
            className="qa-skip-children"
          />
        </Box>
      )}
    </>
  );
};

export default reduxForm({
  form: CHILDREN_FORM_ID,
})(ChildrenForm);
