import R from "ramda";
import { createSelector, createStructuredSelector } from "reselect";

import { selectTranslations } from "./translations";
import { selectHasLoadedDataById } from "./requests";
import { MARRIED_OR_COMMON_LAW } from "../constants/reasons";

const selectWelcomeTranslations = createSelector(
  selectTranslations(["global", "welcome"]),
  (welcomeTranslations) => {
    return {
      ...welcomeTranslations,
    };
  },
);

export const selectReasons = R.pathOr([], ["aboutYou", "reasons"]);

export const selectMarriedInReasons = createSelector(
  selectReasons,
  (reasons) => {
    return reasons.some((reason) => reason === MARRIED_OR_COMMON_LAW);
  },
);

export const welcomePageProps = createStructuredSelector({
  translations: selectWelcomeTranslations,
  hasLoaded: selectHasLoadedDataById("dashboard"),
  reasons: selectReasons,
});
