import R from "ramda";

import { UPDATE_STATUS_TYPE } from "../actions/status";
import {
  RESTING_FIELD_ID,
  ASHES_FIELD_ID,
  CEREMONY_FIELD_ID,
  EXECUTORS_FIELD_ID,
  SPOUSE_IS_EXECUTOR_FIELD_ID,
  PERSONAL_ATTORNEYS_FIELD_ID,
  PROPERTY_ATTORNEYS_FIELD_ID,
  SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
  SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID,
  PAIN_MANAGE_FIELD_ID,
  LIFE_PROLONG_FIELD_ID,
} from "../constants/forms";

const DEFAULT_STATE = {
  [RESTING_FIELD_ID]: "",
  [ASHES_FIELD_ID]: "",
  [CEREMONY_FIELD_ID]: "",
  [EXECUTORS_FIELD_ID]: [],
  [SPOUSE_IS_EXECUTOR_FIELD_ID]: "",
  [LIFE_PROLONG_FIELD_ID]: "",
  [PAIN_MANAGE_FIELD_ID]: "",
  [PROPERTY_ATTORNEYS_FIELD_ID]: [],
  [SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID]: [],
  [PERSONAL_ATTORNEYS_FIELD_ID]: [],
  [SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID]: [],
};

const yourArrangements = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return {
        ...state,
        [RESTING_FIELD_ID]: R.propOr("", RESTING_FIELD_ID)(payload.user),
        [ASHES_FIELD_ID]: R.propOr("", ASHES_FIELD_ID)(payload.user),
        [CEREMONY_FIELD_ID]: R.propOr("", CEREMONY_FIELD_ID)(payload.user),
        [EXECUTORS_FIELD_ID]: R.propOr([], EXECUTORS_FIELD_ID)(payload.user),
        [SPOUSE_IS_EXECUTOR_FIELD_ID]: R.propOr(
          "",
          SPOUSE_IS_EXECUTOR_FIELD_ID,
        )(payload.user),
        [PROPERTY_ATTORNEYS_FIELD_ID]: R.propOr(
          [],
          PROPERTY_ATTORNEYS_FIELD_ID,
        )(payload.user),
        [SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID]: R.propOr(
          "",
          SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
        )(payload.user),
        [PERSONAL_ATTORNEYS_FIELD_ID]: R.propOr(
          [],
          PERSONAL_ATTORNEYS_FIELD_ID,
        )(payload.user),

        [SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID]: R.propOr(
          "",
          SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID,
        )(payload.user),
        wishesVisited: R.pathOr(false, ["user", "wishesVisited"])(payload),
        healthcareVisited: R.pathOr(false, ["user", "healthcareVisited"])(
          payload,
        ),
        [PAIN_MANAGE_FIELD_ID]: R.pathOr("", ["user", PAIN_MANAGE_FIELD_ID])(
          payload,
        ),
        [LIFE_PROLONG_FIELD_ID]: R.pathOr("", ["user", LIFE_PROLONG_FIELD_ID])(
          payload,
        ),
      };
    default:
      return state;
  }
};

export default yourArrangements;
