import React from "react";

import { Field, reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";

import { useSelector } from "react-redux";
import { PrimaryButton } from "../../ui/Button";
import { ErrorMessage } from "../../ui/Error";

import {
  CHILD_MODAL_FORM_ID,
  CHILDREN_NAME_FIELD_ID,
  DOB_FIELD_ID,
  FIRST_PARENT_FIELD_ID,
  SECOND_PARENT_FIELD_ID,
} from "../../../constants/forms";
import NameFields from "../../ui/inputs/NameFields";
import DateOfBirthField from "../../ui/inputs/DateOfBirthField";
import DropdownSelect from "../../ui/inputs/DropdownSelect";
import {
  useValidateFirstParentMemo,
  useValidateSecondParentMemo,
} from "../../ui/inputs/validations";
import {
  selectPrimaryDropdownOptions,
  selectSecondaryDropdownOptions,
} from "../../../selectors/children";

const ChildModal = ({
  error,
  handleSubmit,
  translations,
  shouldAutoFocus,
  invalid,
  submitting,
}) => {
  const primaryDropdownOptions = useSelector(selectPrimaryDropdownOptions);
  const secondaryDropdownOptions = useSelector(selectSecondaryDropdownOptions);

  return (
    <form onSubmit={handleSubmit}>
      <Box mt={1}>
        <NameFields
          useTheir
          fieldName={CHILDREN_NAME_FIELD_ID}
          autoFocus={shouldAutoFocus}
          translations={translations}
        />
        <DateOfBirthField
          name={DOB_FIELD_ID}
          translations={translations}
          marginBottom={1}
        />
        <Field
          name={FIRST_PARENT_FIELD_ID}
          component={DropdownSelect}
          validate={useValidateFirstParentMemo(translations)}
          label={translations.parentLabel}
          placeholder={translations.parentPlaceholder}
          optionList={primaryDropdownOptions}
        />
        <Field
          name={SECOND_PARENT_FIELD_ID}
          component={DropdownSelect}
          validate={useValidateSecondParentMemo(translations)}
          label={translations.parentLabel}
          placeholder={translations.parentPlaceholder}
          optionList={secondaryDropdownOptions}
        />
        <PrimaryButton
          fullWidth
          text={translations.button.submit}
          opacity={invalid ? 0.5 : 1}
          isDisabled={submitting}
        />
        <ErrorMessage error={error} />
      </Box>
    </form>
  );
};

export default reduxForm({
  form: CHILD_MODAL_FORM_ID,
})(ChildModal);
