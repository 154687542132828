import React from "react";
import Box from "@material-ui/core/Box";
import RadioSelector from "./RadioSelector/index";
import { ErrorMessage } from "../Error";
import { SmallBody } from "../Typography";

const RadioSelectorList = ({
  input: { name, value, onChange },
  meta: { touched, error, warning },
  optionList,
  label,
  suppress,
  reverseOrder,
}) => (
  <Box>
    {label && (
      <Box mb={0.5}>
        <label htmlFor={name}>
          <SmallBody>{label}</SmallBody>
        </label>
      </Box>
    )}
    <Box>
      {optionList.map((option, index) => {
        const tabindex =
          (value === "" && index === 0) || value === option.value ? 0 : -1;
        return (
          <RadioSelector
            className={`qa-radio-item-${option.value}`}
            key={option.label}
            label={option.label}
            value={option.value}
            name={name}
            subText={option.subText}
            animatedText={option.animatedText}
            onChange={onChange}
            isChecked={value === option.value}
            tabindex={tabindex}
            suppress={suppress}
            reverseOrder={reverseOrder}
          />
        );
      })}
    </Box>
    {/* TODO: Error handling: */}
    <Box mb={2}>
      <ErrorMessage shouldDisplayError={touched && error} error={error} />
    </Box>
  </Box>
);

export default RadioSelectorList;
