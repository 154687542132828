import React from "react";

import SvgIcon from "./SvgIcon";

const InvalidInviteIcon = ({ width, height }) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 500 375">
      <path
        fill="#ECEDEF"
        d="M249.8,354.9c131.5,0,238.2-71,238.2-158.6S381.4,37.7,249.8,37.7c-131.5,0-238.2,71-238.2,158.6
	S118.3,354.9,249.8,354.9z"
      />
      <path
        fill="#2F455F"
        d="M148.7,188.8l88,53.3c9.8,5.7,9.9,19.9,0.1,25.7l-53,31.6c-12.8,7.6-28.7,7.7-41.5,0.2l-80.6-49
	c-5.9-3.4-5.9-11.8-0.1-15.3l78.1-46.5C142.5,187.1,146,187.1,148.7,188.8z"
      />
      <path
        fill="#2F455F"
        d="M338.2,215.4l83.4,50.8l-12.2,7L326,222.4L338.2,215.4z"
      />
      <path
        fill="#2F455F"
        d="M319.8,283.2l8.9-5.8c1.9-1.3,1.9-4.1-0.1-5.3L114.3,149.5c-2.7-1.6-6.1-1.5-8.8,0.1l-16.9,10
	c-2,1.2-2,4.1,0,5.3l55.5,32.1l11.2-6.4l0.1,0.1l-11,6.4l199.1,113.8l12.2-7L319.8,283.2z"
      />
      <path
        fill="#AAD2F6"
        d="M376.5,128.6l-33,183.4l12.2-7l7.1-39.3l39.7-23l7.1,31.1l12.2-7l-33-145.2L376.5,128.6z M382.6,155.3
	l18.1,79.7l-36.2,20.9L382.6,155.3z"
      />
      <path
        fill="#E2F6FF"
        d="M343.5,311.9l-9.2-5.3l33-183.4l9.2,5.3L343.5,311.9z"
      />
      <path
        fill="#C6E9F8"
        d="M391.5,229.6l9.2,5.3l-36.2,20.9l2.1-11.8L391.5,229.6z"
      />
      <path
        fill="#E2F6FF"
        d="M400.4,268.5l9.2,5.3l-7.1-31.1l-7.1,4.1L400.4,268.5z"
      />
      <path
        fill="#C6E9F8"
        d="M379.5,116.2l9.2,5.3l-12.2,7l-9.2-5.3L379.5,116.2z"
      />
      <path
        fill="#E2F6FF"
        d="M379.1,174.9l3.5-19.6l18.1,79.7l-9.2-5.3L379.1,174.9z"
      />
      <path
        fill="#AAD2F6"
        d="M177.5,13.6l-33,183.4l12.2-7l7.1-39.3l39.7-23l7.1,31.1l12.2-7l-33-145.2L177.5,13.6z M183.5,40.3
	l18.1,79.7l-36.2,20.9L183.5,40.3z"
      />
      <path
        fill="#E2F6FF"
        d="M144.4,197l-9.2-5.3l33-183.4l9.2,5.3L144.4,197z"
      />
      <path
        fill="#C6E9F8"
        d="M192.5,114.7l9.2,5.3l-36.2,20.9l2.1-11.8L192.5,114.7z"
      />
      <path
        fill="#E2F6FF"
        d="M201.3,153.6l9.2,5.3l-7.1-31.1l-7.1,4.1L201.3,153.6z"
      />
      <path fill="#C6E9F8" d="M180.4,1.3l9.2,5.3l-12.2,7l-9.2-5.3L180.4,1.3z" />
      <path
        fill="#E2F6FF"
        d="M180,59.9l3.5-19.6l18.1,79.7l-9.2-5.3L180,59.9z"
      />
      <path
        fill="#3989E5"
        d="M175.7,23.2l10.1-5.8l-20,112.5l-10.4,6.8L175.7,23.2z"
      />
      <path
        fill="#3989E5"
        d="M374.8,138.1l10.1-5.8l-20,112.5l-10.4,6.8L374.8,138.1z"
      />
      <path
        fill="#3989E5"
        d="M353.7,255.5l4-1.1l0.8-2.8l4-2.3l1.3-4.9l-9.5,7.6L353.7,255.5z"
      />
      <path
        fill="#FFDC1E"
        d="M154.1,18.1l-8.5,47.2l221.3,127.8l8.5-47.2L154.1,18.1z M143.3,78l-8.5,47.2l221.3,127.8l8.5-47.2L143.3,78
	z"
      />
      <path fill="#FFF2AD" d="M143.4,78l6.4-3.7L371,202l-6.4,3.7L143.4,78z" />
      <path
        fill="#E39F3A"
        d="M356.1,252.9l8.5-47.2l6.4-3.7l-8.5,47.2L356.1,252.9z"
      />
      <path
        fill="#FFF2AD"
        d="M154.1,18.1l6.4-3.7l221.3,127.8l-6.4,3.7L154.1,18.1z"
      />
      <path
        fill="#E39F3A"
        d="M366.9,193.1l8.5-47.2l6.4-3.7l-8.5,47.2L366.9,193.1z"
      />
      <path
        fill="#3989E5"
        d="M154.6,140.6l4-1.1l0.8-2.8l4-2.3l1.3-4.9l-9.5,7.6L154.6,140.6z"
      />
      <path
        fill="#2F455F"
        d="M161.8,22.5l-9.5,5.5l-5.1,28.4l36.6-21.1L161.8,22.5z M205.8,48l-45.1,26.1l22,12.7l45.1-26.1L205.8,48z
	 M249.9,73.4l-45.1,26.1l22,12.7l45.1-26L249.9,73.4z M148.6,81l-6.5,3.8l-5.1,28.4l33.6-19.4L148.6,81z M293.9,98.8l-45.1,26
	l22,12.7l45.1-26L293.9,98.8z M192.6,106.5l-45.1,26.1l22,12.7l45.1-26.1L192.6,106.5z M338,124.3l-45.1,26l22,12.7l45.1-26
	L338,124.3z M236.7,131.9L191.6,158l22,12.7l45.1-26L236.7,131.9z M373.8,154.4l-36.9,21.3l22,12.7l9.8-5.6L373.8,154.4z
	 M280.7,157.3l-45.1,26l22,12.7l45.1-26L280.7,157.3z M324.7,182.8l-45.1,26l22,12.7l45.1-26L324.7,182.8z M363.6,211.2l-39.9,23
	l22,12.7l12.7-7.4L363.6,211.2z"
      />
      <path
        fill="#0064DC"
        d="M146.1,131.7l-0.7,3.6l9.2,5.3l0.7-3.6L146.1,131.7z"
      />
      <path
        fill="#0064DC"
        d="M345.1,246.6l-0.7,3.6l9.2,5.3l0.7-3.6L345.1,246.6z"
      />
      <path
        fill="#0064DC"
        d="M164.4,209.3c-6.9,0-13.8,1.6-19.2,4.6l-55.6,32.1c-2.5,1.4-4.4,3.1-5.7,4.8l-2.3-1.2v7.2
	c-0.2,4.1,2.5,8.3,7.9,11.4l55.2,31.9c10.6,6.1,27.7,6,38.3-0.1l55.6-32.1c5.3-3.1,8-7.1,8-11.1v-7.2l-2.1,1c-1.4-1.8-3.3-3.5-5.8-5
	l-55.2-31.9C178.3,210.9,171.4,209.3,164.4,209.3z"
      />
      <path
        fill="#3989E5"
        d="M89.6,238.9l55.6-32.1c10.7-6.1,27.8-6.2,38.3-0.1l55.2,31.9c10.6,6.1,10.5,16-0.2,22.1L183,292.9
	c-10.7,6.1-27.8,6.2-38.3,0.1l-55.2-31.9C78.9,255,78.9,245.1,89.6,238.9z"
      />
      <path
        fill="#0064DC"
        d="M152.6,114.8L112,243.9c-3.6,10.3,1.9,20.8,14.6,28.2c21.1,12.2,55.5,12.1,76.8-0.2
	c13.3-7.6,18.8-18.7,14.6-29.2l-40.3-128l-12.6,0.6L152.6,114.8z"
      />
      <path
        fill="#3989E5"
        d="M174.1,110.8c1.9,1.1,3.1,2.5,3.5,4c0.6,2.2-0.6,4.6-3.6,6.4c-4.9,2.9-13,2.9-17.9,0
	c-2.9-1.7-4.1-4.1-3.5-6.3c0.4-1.5,1.6-2.9,3.6-4.1C161.1,108,169.2,107.9,174.1,110.8z"
      />
      <path
        fill="#E2F6FF"
        d="M181.8,128.2c-0.7,1.9-2.2,3.8-4.6,5.3c-6.8,4.3-17.6,4.4-24.3,0.1c-2.3-1.5-3.8-3.3-4.5-5.3l-6.8,21.6
	c0.4,3.4,2.7,6.8,6.8,9.5c9.2,5.9,24.2,5.9,33.4-0.1c4.1-2.6,6.4-6,6.9-9.5L181.8,128.2z M199,182.6c0.6,5.4-2.8,10.9-9.9,15.1
	c-13.3,7.7-34.8,7.7-48,0.1c-7.2-4.1-10.4-9.6-9.8-15l-12.7,40.3c-0.9,7.5,3.6,15.1,13.4,20.8c18.1,10.5,47.7,10.4,66-0.2
	c9.9-5.7,14.5-13.3,13.7-20.8l-6.4-20.5L199,182.6z"
      />
    </SvgIcon>
  );
};

export default InvalidInviteIcon;
