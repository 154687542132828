import R from "ramda";
import React from "react";
import { reduxForm, FieldArray, getFormValues } from "redux-form";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import { useDispatch, useSelector } from "react-redux";
import { Form } from "../../ui/Form";

import { ErrorMessage } from "../../ui/Error";

import {
  PET_GUARDIANS_FORM_ID,
  PET_GUARDIANS_FIELD_ID,
} from "../../../constants/forms";
import H2WithUnderlineLink from "../../ui/H2WithUnderlineLink";
import { displayTooltipModal } from "../../../actions/tooltip-modal";
import { orderedFillColors } from "../../../utilities/allocations";
import PeopleCard from "../../ui/PeopleCard";
import { SecondaryButton } from "../../ui/Button";
import {
  openPetGuardianModal,
  removePetGuardian,
} from "../../../actions/pet-guardians";
import { Body } from "../../ui/Typography";

const PetGuardiansForm = ({
  error,
  handleSubmit,
  backLink,
  change,
  isLoading,
  translations,
  appointeeLevelIndexs,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));

  const formData = useSelector(getFormValues(PET_GUARDIANS_FORM_ID));
  const hasNoPetGuardians = getPetGuardiansCount(formData) === 0;

  const headerWithLink = (
    <H2WithUnderlineLink
      text={translations.pageTitle}
      underlineText={translations.pageTitleUnderlineLink}
      onClick={() =>
        dispatch(displayTooltipModal(translations.pageTitleModalKey))
      }
    />
  );

  return (
    <Form
      qaFormId="petGuardians"
      isLoading={isLoading}
      onSubmit={handleSubmit}
      backLink={backLink}
      translations={translations}
      reverseWrap={isTabletDown}
      customHeaderComponent={headerWithLink}
      disabled={hasNoPetGuardians}
    >
      <FieldArray
        handleChange={change}
        name={PET_GUARDIANS_FIELD_ID}
        rerenderOnEveryChange
        component={PetGuardiansCardFieldArray}
        translations={translations}
        canAddMore={appointeeLevelIndexs.total < 9}
        appointeeLevelIndexs={appointeeLevelIndexs}
      />
      <Box mb={2}>
        <ErrorMessage error={error} />
      </Box>
    </Form>
  );
};

const getPetGuardiansCount = (formData) => {
  if (!formData) {
    return 0;
  }
  return formData[PET_GUARDIANS_FIELD_ID].filter(
    (field) => field._destroy !== true,
  ).length;
};

const getPetGuardianName = (field, guardianHasSpouse) => {
  let guardianName = `${field.firstName} ${field.middleName || ""} ${
    field.lastName
  }`;
  if (guardianHasSpouse) {
    guardianName += ` & ${field.spouseFirstName} ${
      field.spouseMiddleName || ""
    } ${field.spouseLastName}`;
  }
  return guardianName;
};

const getPetGuardianLevelTranslation = (
  appointeeLevelIndexs,
  index = 0,
  translations,
  guardianHasSpouse,
) => {
  const level = R.pathOr(null, [index, "level"])(appointeeLevelIndexs);
  if (level === null) {
    return "";
  }
  const petGuardiansText = guardianHasSpouse
    ? translations.petGuardiansPlural
    : translations.petGuardiansSingular;
  return `${translations.levels[level]} ${petGuardiansText}`;
};

const PetGuardiansCardFieldArray = ({
  fields,
  translations,
  handleChange,
  appointeeLevelIndexs,
}) => {
  const dispatch = useDispatch();
  let colorIndex = 0;
  const hasAppointeeTitle = R.has("total")(appointeeLevelIndexs);
  const allFields = fields.getAll() || [];
  const addLabel =
    allFields.filter((field) => field._destroy !== true).length === 0
      ? translations.button.addLabel
      : translations.button.addMoreLabel;

  return (
    <Box>
      {fields.map((fieldId, index) => {
        const field = fields.get(index);
        if (field && field._destroy === true) {
          return null;
        }
        const isFirstCard = colorIndex === 0;
        const color = orderedFillColors[colorIndex % orderedFillColors.length];
        colorIndex += 1;
        const guardianHasSpouse = R.propOr(false, "spouseFirstName")(field);
        const petGuardianName = getPetGuardianName(field, guardianHasSpouse);
        const petGuardianLevelText = getPetGuardianLevelTranslation(
          appointeeLevelIndexs,
          index,
          translations,
          guardianHasSpouse,
        );
        const subText = hasAppointeeTitle ? petGuardianLevelText : "";
        return (
          <Box mb={1} mt={2} key={`${index + 1}-${fieldId}`}>
            <PeopleCard
              id={fieldId}
              fieldId={fieldId}
              translations={translations}
              text={petGuardianName}
              color={color}
              subText={subText}
              onEdit={() => dispatch(openPetGuardianModal({ ...field, index }))}
              onRemove={() => {
                dispatch(removePetGuardian(field));
                return handleChange(`${fieldId}._destroy`, true);
              }}
              {...field}
            />
            {isFirstCard && (
              <Box my={2}>
                <Body
                  dangerouslySetInnerHTML={{
                    __html: translations.subQuestionOptionalText,
                  }}
                />
              </Box>
            )}
          </Box>
        );
      })}
      <SecondaryButton
        text={addLabel}
        fullWidth
        displayPlusIcon
        className="qa-add"
        type="button"
        onClick={() => dispatch(openPetGuardianModal(null))}
      />
    </Box>
  );
};

export default reduxForm({
  form: PET_GUARDIANS_FORM_ID,
})(PetGuardiansForm);
