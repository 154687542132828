export const externalLogin = (
  uid = null,
  accessToken = null,
  client = null,
) => {
  return {
    type: EXTERNAL_LOGIN_TYPE,
    payload: {
      uid,
      accessToken,
      client,
    },
  };
};

export const EXTERNAL_LOGIN_TYPE = "EXTERNAL_LOGIN";
