import React from "react";

export const DocumentPaperIcon = ({ width = 140, height = 140 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_36925_96)">
        <path
          d="M77.28 81.4803L126.63 115.85C128.59 117.25 128.52 120.19 126.49 121.45L98.7 138.53L41.16 95.1303L77.28 81.4803Z"
          fill="#2F455F"
        />
        <path
          d="M95.13 140L98.7 138.53L111.3 43.9598L107.73 45.3598L95.13 140Z"
          fill="#AAD2F6"
        />
        <path
          d="M107.73 45.36L111.3 43.96L53.83 0L50.26 1.54L107.73 45.36Z"
          fill="white"
        />
        <path
          d="M50.26 1.54016L37.73 96.1802L95.13 140L107.73 45.3602L50.26 1.54016Z"
          fill="#E2F6FF"
        />
        <path
          d="M49.77 38.64L50.47 33.6L54.11 36.4L53.48 41.44L49.77 38.64Z"
          fill="white"
        />
        <path
          d="M50.61 34.0899L53.83 36.5399L53.27 41.0199L50.05 38.5699L50.61 34.0899ZM50.26 33.1099L49.49 38.7799L53.62 41.9299L54.39 36.2599L50.26 33.1099Z"
          fill="#0064DC"
        />
        <path
          d="M48.16 50.8198L48.86 45.7098L52.5 48.5098L51.87 53.6198L48.16 50.8198Z"
          fill="white"
        />
        <path
          d="M49.07 46.1997L52.29 48.6497L51.73 53.1297L48.51 50.6797L49.07 46.1997ZM48.65 45.2197L47.88 50.8897L52.01 54.0397L52.78 48.3697L48.65 45.2197Z"
          fill="#0064DC"
        />
        <path
          d="M46.62 62.8597L47.25 57.8197L50.96 60.6197L50.26 65.6597L46.62 62.8597Z"
          fill="white"
        />
        <path
          d="M47.46 58.3096L50.61 60.7596L50.05 65.2396L46.9 62.7896L47.46 58.3096ZM47.04 57.3296L46.34 62.9996L50.47 66.1496L51.24 60.4796L47.04 57.3296Z"
          fill="#0064DC"
        />
        <path
          d="M44.94 74.9699L45.64 69.8599L49.35 72.7299L48.65 77.7699L44.94 74.9699Z"
          fill="white"
        />
        <path
          d="M45.85 70.3501L49.07 72.8001L48.51 77.2801L45.29 74.8301L45.85 70.3501ZM45.43 69.4401L44.66 75.1101L48.86 78.3301L49.63 72.6601L45.43 69.4401Z"
          fill="#0064DC"
        />
        <path
          d="M43.33 87.0797L43.96 81.9697L47.74 84.8397L47.04 89.9497L43.33 87.0797Z"
          fill="white"
        />
        <path
          d="M44.17 82.4596L47.46 84.9796L46.9 89.4596L43.61 86.9396L44.17 82.4596ZM43.75 81.4796L42.98 87.1496L47.18 90.3696L47.95 84.6996L43.75 81.4796Z"
          fill="#0064DC"
        />
        <path
          d="M58.66 16.7998L57.61 15.8898H57.54L56.56 15.0498H56.49L55.72 16.4498L55.44 14.2097C55.44 14.2097 55.44 14.1398 55.37 14.1398L54.6 13.5798H54.53L53.76 14.9798L53.55 12.5998C53.55 12.5998 53.55 12.5298 53.48 12.5298L52.36 11.6198H52.29L52.92 16.0998C52.92 16.0998 52.92 16.1698 52.99 16.1698L53.9 16.8698H53.97L54.81 15.5398L55.16 17.7097C55.16 17.7097 55.16 17.7797 55.23 17.7797L56.14 18.4797H56.21L57.61 16.1698L57.19 19.2498C57.19 19.2498 57.19 19.3198 57.26 19.3198L58.31 20.2297H58.38L58.8 16.7298C58.73 16.8698 58.8 16.7998 58.66 16.7998Z"
          fill="#0064DC"
        />
        <path
          d="M58.7689 16.1799C59.0055 16.0485 58.9844 15.5584 58.7216 15.0852C58.4589 14.612 58.0541 14.3349 57.8175 14.4663C57.5809 14.5976 57.6021 15.0877 57.8648 15.5609C58.1275 16.0341 58.5323 16.3112 58.7689 16.1799Z"
          fill="#0064DC"
        />
        <path
          d="M60.48 16.5199L59.43 15.6099H59.36L58.73 20.7199C58.73 20.7199 58.73 20.7899 58.8 20.7899L59.85 21.6999H59.92L60.55 16.5899C60.55 16.5199 60.48 16.5199 60.48 16.5199Z"
          fill="#0064DC"
        />
        <path
          d="M69.72 28.2794C69.37 27.9994 69.02 28.0694 68.95 28.4194C68.88 28.8394 69.16 29.3294 69.51 29.6794C69.86 29.9594 70.21 29.8894 70.28 29.5394C70.35 29.1194 70.14 28.6294 69.72 28.2794Z"
          fill="#0064DC"
        />
        <path
          d="M62.02 17.8495L60.97 16.9395H60.9L60.27 22.0495C60.27 22.0495 60.27 22.1195 60.34 22.1195L61.39 23.0295H61.46L62.09 17.9195C62.09 17.8495 62.02 17.8495 62.02 17.8495Z"
          fill="#0064DC"
        />
        <path
          d="M69.23 23.5896L68.18 22.6796H68.11L67.48 27.7896C67.48 27.7896 67.48 27.8596 67.55 27.8596L68.6 28.7696H68.67L69.3 23.6596C69.3 23.7296 69.3 23.6596 69.23 23.5896Z"
          fill="#0064DC"
        />
        <path
          d="M67.41 23.8696L66.36 22.9596H66.29L66.08 24.7796C66.01 25.1996 65.8 25.2696 65.45 25.0596C65.17 24.8496 65.03 24.4296 65.1 24.0796L65.38 22.1196C65.38 22.1196 65.38 22.0496 65.31 22.0496L65.1 21.9096L64.19 21.1396L63.42 20.5796L63.35 20.5096V20.3696C63.35 20.1596 63.56 20.1596 63.77 20.3696C63.91 20.4396 63.98 20.5796 64.05 20.6496H64.12L64.26 19.6696V19.5996C64.19 19.5296 63.91 19.2496 63.63 18.9696C62.86 18.4096 62.37 18.4796 62.23 19.3896L61.74 23.3096C61.74 23.3096 61.74 23.3796 61.81 23.3796L62.86 24.2896H62.93L63.28 21.8396L64.05 22.3996L63.91 23.5196C63.77 24.4296 64.26 25.4796 64.96 26.0396C65.59 26.5296 65.94 26.3196 65.94 26.3196V26.5996C65.94 26.5996 65.94 26.6696 66.01 26.6696L67.06 27.5796H67.13L67.55 24.0796C67.48 24.0096 67.48 24.0096 67.41 23.8696Z"
          fill="#0064DC"
        />
        <path
          d="M101.128 53.189C102.304 52.5189 102.294 50.2876 101.108 48.2051C99.9206 46.1227 98.0054 44.9777 96.8298 45.6478C95.6542 46.3178 95.6634 48.5492 96.8504 50.6316C98.0374 52.7141 99.9526 53.859 101.128 53.189Z"
          fill="#FFDC1E"
        />
        <path
          d="M44.24 72.3099C44.03 72.1699 43.96 71.8899 44.1 71.4699C44.38 70.9099 45.08 70.4199 45.71 70.3499C46.13 70.2799 46.41 70.4899 46.41 70.7699C46.69 71.9599 46.97 73.1499 47.25 74.3399C46.27 73.6399 45.29 72.9399 44.24 72.3099Z"
          fill="#E55D4C"
        />
        <path
          d="M47.25 74.3399C46.2701 73.6399 45.2201 73.0099 44.2401 72.3099C44.3801 72.3799 44.5901 72.4499 44.7301 72.3799L47.25 74.3399Z"
          fill="#0064DC"
        />
        <path
          d="M46.48 70.7001C46.76 71.8901 47.04 73.0801 47.32 74.2701L44.8 72.3101C45.43 72.2401 46.13 71.7501 46.41 71.1901C46.48 71.0501 46.55 70.9101 46.48 70.7001Z"
          fill="#0064DC"
        />
        <path
          d="M44.8 72.3797C44.17 72.4497 43.89 72.0297 44.17 71.3997C44.45 70.8397 45.15 70.3497 45.78 70.2797C46.41 70.2097 46.69 70.6297 46.41 71.2597C46.13 71.8197 45.43 72.3097 44.8 72.3797Z"
          fill="#E55D4C"
        />
        <path
          d="M44.8 72.3797C44.17 72.4497 43.89 72.0297 44.17 71.3997C44.45 70.8397 45.15 70.3497 45.78 70.2797C46.41 70.2097 46.69 70.6297 46.41 71.2597C46.13 71.8197 45.43 72.3097 44.8 72.3797Z"
          fill="#FFCA00"
        />
        <path
          d="M39.69 69.2298C39.13 68.8098 38.99 68.1098 39.41 67.1998C40.04 65.7998 41.86 64.5398 43.4 64.3298C44.45 64.1898 45.08 64.6098 45.29 65.3098C45.71 67.0598 46.13 68.8798 46.55 70.6298C46.48 70.3498 46.27 70.2098 45.85 70.2098C45.22 70.2798 44.52 70.7698 44.24 71.3298C44.03 71.6798 44.1 72.0298 44.38 72.1698C42.7 71.2598 41.16 70.2798 39.69 69.2298Z"
          fill="#FFCA00"
        />
        <path
          d="M44.24 72.31C42.7 71.33 41.23 70.28 39.69 69.3C39.97 69.51 40.53 69.6501 41.02 69.5101L44.8 72.38C44.52 72.45 44.38 72.38 44.24 72.31Z"
          fill="#FFDC1E"
        />
        <path
          d="M45.22 65.3796C45.64 67.1296 46.06 68.9496 46.48 70.6996C46.48 70.8396 46.48 70.9796 46.41 71.1896C46.13 71.7496 45.43 72.2396 44.8 72.3096L41.02 69.4396C42.63 69.2996 44.38 68.0396 45.01 66.5696C45.22 66.1496 45.29 65.7296 45.22 65.3796Z"
          fill="#FFDC1E"
        />
        <path
          d="M41.02 69.5098C39.41 69.6498 38.71 68.5998 39.34 67.1998C39.97 65.7998 41.79 64.5398 43.33 64.3298C44.94 64.1898 45.64 65.2398 45.01 66.6398C44.38 68.1098 42.56 69.3698 41.02 69.5098Z"
          fill="#FFCA00"
        />
        <path
          d="M41.02 69.5099C39.41 69.6499 38.71 68.5999 39.34 67.1999C39.97 65.7999 41.79 64.5399 43.33 64.3299C44.94 64.1899 45.64 65.2398 45.01 66.6398C44.38 68.1099 42.56 69.3699 41.02 69.5099Z"
          fill="#E55D4C"
        />
        <path
          d="M15.89 35.21C15.61 34.79 15.54 34.16 15.89 33.53C16.52 32.13 18.34 30.87 19.88 30.66C20.65 30.59 21.28 30.8 21.56 31.29L45.01 65.03C44.73 64.61 44.1 64.4 43.33 64.4C41.72 64.54 39.97 65.8 39.34 67.27C38.99 67.97 39.06 68.6 39.34 68.95C32.34 58.8 22.96 45.29 15.89 35.21Z"
          fill="#E55D4C"
        />
        <path
          d="M39.41 68.9499L15.89 35.2099C16.17 35.6299 16.8 35.8399 17.57 35.8399L41.02 69.5799C40.25 69.5799 39.62 69.3699 39.41 68.9499Z"
          fill="#0064DC"
        />
        <path
          d="M21.63 31.2895L45.08 65.0295C45.36 65.4495 45.43 66.0795 45.08 66.7095C44.45 68.1095 42.63 69.3695 41.09 69.5795L17.57 35.8395C19.18 35.6995 20.93 34.4395 21.56 32.9695C21.91 32.1995 21.91 31.6395 21.63 31.2895Z"
          fill="#0064DC"
        />
        <path
          d="M17.57 35.7697C15.96 35.9097 15.26 34.8597 15.89 33.4597C16.52 32.0597 18.34 30.7997 19.88 30.5897C21.49 30.4497 22.19 31.4997 21.56 32.8997C20.93 34.3697 19.18 35.6297 17.57 35.7697Z"
          fill="#E55D4C"
        />
        <path
          d="M17.57 35.7697C15.96 35.9097 15.26 34.8597 15.89 33.4597C16.52 32.0597 18.34 30.7997 19.88 30.5897C21.49 30.4497 22.19 31.4997 21.56 32.8997C20.93 34.3697 19.18 35.6297 17.57 35.7697Z"
          fill="#FFCA00"
        />
        <path
          d="M12.46 30.2403C12.18 29.8203 12.11 29.1903 12.46 28.5603C13.09 27.1603 14.91 25.9003 16.45 25.6903C17.22 25.6203 17.85 25.8303 18.13 26.3203L21.63 31.2903C21.35 30.8703 20.72 30.6603 19.95 30.6603C18.34 30.8003 16.59 32.0603 15.96 33.5303C15.61 34.2303 15.68 34.8603 15.96 35.2103C14.91 33.7403 13.51 31.7103 12.46 30.2403Z"
          fill="#FFCA00"
        />
        <path
          d="M15.89 35.2102L12.39 30.2402C12.67 30.6602 13.3 30.8702 14.07 30.8702L17.57 35.8402C16.8 35.8402 16.17 35.6302 15.89 35.2102Z"
          fill="#ABE0F6"
        />
        <path
          d="M18.13 26.25L21.63 31.22C21.91 31.64 21.98 32.27 21.63 32.9C21 34.3 19.18 35.56 17.64 35.77L14.14 30.8C15.75 30.66 17.5 29.4 18.13 27.93C18.41 27.23 18.41 26.67 18.13 26.25Z"
          fill="#ABE0F6"
        />
        <path
          d="M14.07 30.8C12.46 30.94 11.76 29.89 12.39 28.49C13.02 27.09 14.84 25.83 16.38 25.62C17.99 25.48 18.69 26.53 18.06 27.93C17.5 29.4 15.68 30.66 14.07 30.8Z"
          fill="#71ADEE"
        />
        <path
          d="M55.86 37.3802L55.09 43.0502L91.84 71.1202L92.61 65.4502L55.86 37.3802Z"
          fill="#0064DC"
        />
        <path
          d="M54.25 49.4901L53.48 55.1601L84.91 79.1701L85.68 73.5001L54.25 49.4901Z"
          fill="#0064DC"
        />
        <path
          d="M52.64 61.6L51.94 67.27L86.94 94.08L87.71 88.34L52.64 61.6Z"
          fill="#0064DC"
        />
        <path
          d="M51.03 73.7098L50.33 79.3798L74.41 97.8598L75.18 92.1898L51.03 73.7098Z"
          fill="#0064DC"
        />
        <path
          d="M49.49 85.8197L48.72 91.4897L81.55 116.62L82.32 110.95L49.49 85.8197Z"
          fill="#0064DC"
        />
        <path
          d="M48.37 62.8598H48.3C48.23 62.8598 48.16 62.7898 48.09 62.7198L47.53 61.7398C47.46 61.5998 47.46 61.3898 47.6 61.2498C47.74 61.1798 47.88 61.1798 48.02 61.3898L48.44 62.0198L49.63 60.6898C49.77 60.5498 49.91 60.5498 50.05 60.6898C50.19 60.8298 50.19 61.0398 50.05 61.1798L48.65 62.7898C48.51 62.7898 48.44 62.8598 48.37 62.8598Z"
          fill="#0064DC"
        />
        <path
          d="M49.98 51.0303H49.91C49.84 51.0303 49.77 50.9602 49.7 50.8902L49.14 49.9103C49 49.7003 49 49.4902 49.14 49.4202C49.28 49.3502 49.42 49.3502 49.56 49.5602L49.98 50.1902L51.17 48.8602C51.31 48.7202 51.45 48.7202 51.59 48.8602C51.73 49.0002 51.73 49.2102 51.59 49.3502L50.19 50.9603C50.12 50.9603 50.05 51.0303 49.98 51.0303Z"
          fill="#0064DC"
        />
        <path
          d="M51.59 38.9197H51.52C51.45 38.9197 51.38 38.8497 51.31 38.7797L50.75 37.7997C50.68 37.6597 50.68 37.4497 50.82 37.3097C50.96 37.2397 51.1 37.2397 51.24 37.4497L51.66 38.0797L52.85 36.7497C52.99 36.6097 53.13 36.6097 53.27 36.7497C53.41 36.8897 53.41 37.0997 53.27 37.2397L51.87 38.8497C51.66 38.8497 51.66 38.8497 51.59 38.9197Z"
          fill="#0064DC"
        />
      </g>
      <defs>
        <clipPath id="clip0_36925_96">
          <rect
            width="116.2"
            height="140"
            fill="white"
            transform="translate(11.9)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
