import React, { useEffect } from "react";
import { connect } from "react-redux";

import DateOfBirthForm from "../../../components/forms/about-you/DateOfBirthForm";
import { dateOfBirthPageProps } from "../../../selectors/about-you";
import { submitAboutYou, fetchDOB } from "../../../actions/about-you";
import { FULL_NAME_PATH } from "../../../constants/routes";
import { DOB_FORM_ID } from "../../../constants/forms";

const DateOfBirthPage = ({ submitAboutYou, fetchDOB, ...restOfProps }) => {
  useEffect(() => {
    fetchDOB();
  }, []);
  return (
    <DateOfBirthForm
      backLink={FULL_NAME_PATH}
      onSubmit={() => submitAboutYou(DOB_FORM_ID)}
      {...restOfProps}
    />
  );
};

export default connect(dateOfBirthPageProps, {
  submitAboutYou,
  fetchDOB,
})(DateOfBirthPage);
