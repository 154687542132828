import React from "react";
import SvgIcon from "./SvgIcon";

const LockIcon = ({ color = "black", width = 16, height = 18 }) => (
  <>
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7V5C3 2.23858 5.23858 0 8 0C10.7614 0 13 2.23858 13 5V7H14C15.1046 7 16 7.89543 16 9V16C16 17.1046 15.1046 18 14 18H2C0.895431 18 0 17.1046 0 16V9C0 7.89543 0.895431 7 2 7H3ZM11 5V7H5V5C5 3.34315 6.34315 2 8 2C9.65685 2 11 3.34315 11 5ZM2 9V16H14V9H2Z"
        fill={color}
      />
    </SvgIcon>
  </>
);

export default LockIcon;
