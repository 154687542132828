import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import {
  submitSignup,
  initializeSignupForm,
  checkEmailAvailability,
} from "../../actions/auth";
import { signupPageProps } from "../../selectors/auth";
import { LOGIN_PATH, DASHBOARD_PATH } from "../../constants/routes";

import SignupForm from "../../components/forms/SignupForm";

const SignupPage = ({
  submitSignup,
  initializeSignupForm,
  checkEmailAvailability,
  hasLoaded,
  isAuthenticated,
  ...restOfProps
}) => {
  useEffect(() => {
    initializeSignupForm();
  }, [initializeSignupForm]);

  if (isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: DASHBOARD_PATH,
        }}
      />
    );
  }

  return (
    <SignupForm
      onSubmit={submitSignup}
      loginLink={LOGIN_PATH}
      checkEmailAvailability={checkEmailAvailability}
      {...restOfProps}
    />
  );
};

export default connect(signupPageProps, {
  submitSignup,
  initializeSignupForm,
  checkEmailAvailability,
})(SignupPage);
