import React from "react";
import { Field } from "redux-form";

import TextInput from "./TextInput";

import { useValidateConfirmEmailMemo } from "./validations";
import { CONFIRM_EMAIL_FIELD_ID } from "../../../constants/forms";

const ConfirmEmailField = ({ translations }) => (
  <Field
    component={TextInput}
    name={CONFIRM_EMAIL_FIELD_ID}
    label={translations.confirmEmailLabel}
    placeholder={translations.confirmEmailPlaceholder}
    type="email"
    validate={useValidateConfirmEmailMemo(translations)}
  />
);

export default ConfirmEmailField;
