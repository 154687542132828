import React from "react";
import { useSelector } from "react-redux";
import { Field } from "redux-form";
import ContactSelectInput from "./ContactSelectInput";
import {
  selectAllContacts,
  selectContactDropdownTranslations,
} from "../../../../selectors/contacts";
import { CONTACT_DROPDOWN_FIELD_ID } from "../../../../constants/forms";

const DropdownContactSelect = ({
  name = CONTACT_DROPDOWN_FIELD_ID,
  error,
  translations,
  className,
  label,
  contacts = null,
  selectedContactId,
  disabledContactIds = [],
  onSelect,
  onRedirect,
  onAddNew,
  validator = null,
  includeMinorChildren = false,
}) => {
  const translationsFromStore = useSelector(selectContactDropdownTranslations);
  const contactsFromStore = useSelector(selectAllContacts);
  let sortedContacts = [];
  if (contacts === null) {
    sortedContacts = contactsFromStore
      ? contactsFromStore.sort((a, b) => a.contact.id - b.contact.id)
      : [];
  }

  return (
    <>
      <Field
        name={name}
        component={ContactSelectInput}
        translations={translations || translationsFromStore}
        label={label}
        error={error}
        onSelect={onSelect}
        onRedirect={onRedirect}
        onAddNew={onAddNew}
        selectedContactId={selectedContactId}
        optionList={contacts || sortedContacts}
        disabledContactIds={disabledContactIds}
        className={className}
        type="select"
        validate={validator}
        includeMinorChildren={includeMinorChildren}
      />
    </>
  );
};

export default DropdownContactSelect;
