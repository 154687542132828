import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";

import { H2, SmallTextLink } from "../../ui/Typography";
import { RECOMMENDATION_FORM_ID } from "../../../constants/forms";
import { bundlePlan } from "../../../constants/plans";
import { PLANS_PATH } from "../../../constants/routes";
import TrustIcons from "../../ui/icons/TrustIcons";
import { BaseAccordion as Accordion } from "../../ui/Accordion";
import UnlimitedUpdatesInfoBox from "../../ui/UnlimitedUpdatesInfoBox";
import { FamilyBundlePlanCard } from "../../plans/PlanCard";

import { selectMarriedInReasons } from "../../../selectors/welcome";
import BodyWithUnderlineLink from "../../ui/BodyWithUnderlineLink";
import { displayTooltipModal } from "../../../actions/tooltip-modal";

const FamilyRecommendationFormHeading = ({ translations }) => {
  const dispatch = useDispatch();
  const marriedIsSelected = useSelector(selectMarriedInReasons);
  const { text, underlineText, modalKey } = translations.underlines;

  return (
    <>
      {marriedIsSelected ? (
        <H2 component="h1">
          <BodyWithUnderlineLink
            text={text}
            underlineText={underlineText}
            onClick={() => dispatch(displayTooltipModal(modalKey))}
          />
        </H2>
      ) : (
        <H2 component="h1">{translations.pageTitle}</H2>
      )}
    </>
  );
};

const FamilyRecommendationForm = ({
  translations,
  updatePlans,
  analyticsClickExternalLink,
  showExclusiveOffer,
  price,
  strikeThroughPrice,
  isMobile,
  displayTooltipModal,
}) => {
  const seeAllPlansDefaultMarginTop = isMobile ? 2 : 3.5;

  return (
    <Box pb={18}>
      <Grid container spacing={2} justifyContent="center">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Grid item sm={10} md={7}>
            <Box mt={isMobile ? 3 : 4.5}>
              <FamilyRecommendationFormHeading
                translations={translations}
                displayTooltipModal={displayTooltipModal}
              />
            </Box>
          </Grid>

          <Grid item sm={10} md={7}>
            <Box mt={isMobile ? 2 : 3.5}>
              <FamilyBundlePlanCard
                analyticsClickExternalLink={analyticsClickExternalLink}
                price={price}
                strikeThroughPrice={strikeThroughPrice}
                showExclusiveOffer={showExclusiveOffer}
                onClick={(qty) => {
                  updatePlans({
                    isBundle: true,
                    symbol: bundlePlan.symbol,
                    quantity: qty,
                    formId: RECOMMENDATION_FORM_ID,
                  });
                }}
                recommendedForYou
                noMaxWidth
                showMobileStickyCta
              />
            </Box>

            <Box mt={2}>
              <UnlimitedUpdatesInfoBox
                title={translations.unlimitedUpdatesInfoBox.title}
                paragraph={translations.unlimitedUpdatesInfoBox.paragraph}
              />
            </Box>
          </Grid>
        </Box>

        <Grid item sm={12} md={9} lg={6}>
          <Box
            mt={seeAllPlansDefaultMarginTop}
            display="flex"
            justifyContent="center"
          >
            <SmallTextLink
              text={translations.seeAllPlansLinkText}
              to={PLANS_PATH}
              className="qa-skip"
            />
          </Box>

          <Box
            mt={isMobile ? 0 : 1.5}
            mb={isMobile ? 2 : 3.5}
            display="flex"
            justifyContent="center"
          >
            <TrustIcons
              translations={translations}
              shouldIncludeThirtyDay
              shouldIncludeDragonsDen
            />
          </Box>
          <Accordion translations={translations} isMobile={isMobile} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FamilyRecommendationForm;
