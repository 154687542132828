import R from "ramda";
import { CHARITY_ALLOCATION, PERSON_ALLOCATION } from "../constants/forms";
import { colorPalette } from "../config/Theme";

export const pickAmountsFromList = (list) => R.map(R.prop("amount"), list);
export const findById = (list, id) => R.find(R.propEq("id", id), list);

// converts a list of amounts [25, 25, 25, 25] totaling 100
// into breakpoint positions on a slider, offset by the amount before them
// eg: [25, 50, 75]
export const convertAmountsToBreakpoints = (amounts) => {
  const breakpointPositions = amounts.reduce((indexArray, amount, index) => {
    if (index === 0) return [amount];
    const newIndex = indexArray[index - 1] + amount;
    return [...indexArray, newIndex];
  }, []);

  return R.remove(breakpointPositions.length - 1, 1, breakpointPositions);
};

// converts a list of breakpoints on a 0-100 slider [ 20, 30, 65, 90 ]
// into amounts totalling 100
// eg: [20, 10, 35, 25, 10];
export const convertBreakpointsToAmounts = (breakpointPositions) => {
  const amounts = breakpointPositions.map((breakpointPosition, index) =>
    index === 0
      ? breakpointPosition
      : breakpointPosition - breakpointPositions[index - 1],
  );
  const sum = R.reduce(R.add, 0, amounts);
  return [...amounts, 100 - sum];
};

export const distributeAmountsEvenly = (allocations) => {
  const evenAmount = Number((100 / allocations.length).toFixed(2));
  return allocations.map((allocation) => ({
    ...allocation,
    amount: evenAmount,
  }));
};

const nameMatch = (firstName, middleName, lastName) => (allocation) =>
  firstName === allocation.firstName &&
  middleName === allocation.middleName &&
  lastName === allocation.lastName;

export const removeAllocationFromList = (
  { id, type, firstName, middleName, lastName, charityId, registeredName },
  allocationList,
) => {
  if (id) return R.reject(R.propEq("id", id), allocationList);
  if (type === "PersonAllocation" || type === "PersonBackup") {
    return R.reject(nameMatch(firstName, middleName, lastName), allocationList);
  }
  if (type === "CharityAllocation" || type === "CharityBackup") {
    if (charityId)
      return R.reject(R.propEq("charityId", charityId), allocationList);
    if (registeredName) {
      return R.reject(
        R.propEq("registeredName", registeredName),
        allocationList,
      );
    }
  }
  return allocationList;
};

export const getAllocationName = (
  { type, firstName, middleName, lastName, charityId, registeredName },
  charitiesList,
) => {
  if (type === "PersonAllocation" || type === "PersonBackup") {
    return `${firstName} ${middleName || ""} ${lastName}`;
  }
  if (type === "CharityAllocation" || type === "CharityBackup") {
    if (charityId && charitiesList) {
      const charity = findById(charitiesList, charityId);
      return R.prop("name")(charity);
    }
    return registeredName;
  }
  return "";
};

export const getAllocationTypeLabel = (
  allocation,
  translations,
  isPrimaryBeneficiarySpouse,
) => {
  const { type } = allocation;
  switch (type) {
    case PERSON_ALLOCATION:
      return isPrimaryBeneficiarySpouse
        ? translations.label.secondaryBeneficiary
        : translations.label.primaryBeneficiary;
    case CHARITY_ALLOCATION:
      return translations.label.charity;
    default:
      return translations.label.charity;
  }
};

export const getUniqueAllocationId = (allocation, charitiesList) => {
  if (allocation.id) return allocation.id;
  return getAllocationName(allocation, charitiesList);
};

const colors = [
  "#0064DC",
  "#84C7A5",
  "#FAB46D",
  "#EA5758",
  "#FCC04A",
  "#84C7A5",
  "#FAB46D",
  "#EA5758",
  "#FCC04A",
  "#84C7A5",
  "#FAB46D",
  "#EA5758",
  "#FCC04A",
  "#84C7A5",
  "#FAB46D",
  "#EA5758",
  "#FCC04A",
  "#84C7A5",
  "#FAB46D",
  "#EA5758",
  "#FCC04A",
];

export const orderedFillColors = [
  "#C6E9F8", // Willful xx-light blue
  "#FAE6FF", // Willful light violet
  "#D0FFF0", // Willful light teal
  "#FFF2AD", // Willful light yellow
  "#FFBAC3",
  "#E2F6FF", // Willful xxx-light blue
  "#EAC9FF",
  "#7FF4D9",
  "#FFFDCA",
  "#FF90A4",
  "#BBDEFF",
  "#D4C5FF",
  "#A9FFCB",
  "#FDFF85",
  "#FFCBA6",
  "#A1CDFF",
];

export const getAllocationColor = (index, isLast, hasMultipleBeneficiaries) => {
  if (!hasMultipleBeneficiaries) {
    if (index === 0) return "#ea5758;";
    return colors[0];
  }
  if (isLast) {
    return colors[0];
  }
  return colors[index];
};

// TODO: update BE to change `field` value from 'amount' -> 'allocations'
// BE error validation for this returns list of objects
// eg: [{}, {}, {}, {amount: "Amount : You cannot allocate 0% to a beneficiary."}]
export const getFormError = (errorList) => {
  const error = R.find(R.prop("amount"), errorList);
  if (error) {
    return error.amount.replace("Amount : ", "");
  }

  return null;
};

export const buildAllocationsChartData = (
  allocations,
  charities,
  translations,
) => {
  const chartData = allocations.map((allocation, i) => {
    const { unallocated } = allocation;
    let color = orderedFillColors[i % orderedFillColors.length];
    let allocationName = getAllocationName(allocation, charities);

    // if it is an unallocated allocation, we will change the color and name
    if (unallocated) {
      color = colorPalette.gray;
      allocationName = translations.unallocated;
    }

    // value and title are properties expected by react-minimal-pie-chart
    // to render the pie-chart labels and percentages correctly
    const value = Number(allocation.amount);
    const valueStr = Number.isInteger(value) ? value : value.toFixed(2);
    const title = `${allocationName} (${valueStr}%)`;
    const key = `${title}-${i + 1}`;

    // 'allocationName' and 'valueStr' may be used to build the legend short title
    const newDataObj = { key, color, value, title, valueStr, allocationName };
    return newDataObj;
  });
  return chartData.filter((data) => !Number.isNaN(data.value));
};
