import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import styled from "styled-components";

import { animationSpeed } from "../../../../config/Theme";
import DefaultRadioInput from "./DefaultRadioInput";
import ErrorStateRadioInput from "./ErrorStateRadioInput";
import FormField from "../FormField/index";
import { RadioSelectorWrapper, ErrorMessage } from "./style";
import { Body, SmallBody } from "../../Typography";
import { isValidSelectKey } from "../../../../selectors/utils/validate";

const AnimatedSmallBody = styled(SmallBody)`
  height: ${(props) => (props.animateOn ? "fit-content" : "0")};
`;

const AnimatedBox = styled(Box)`
  overflow: hidden;
  max-height: ${(props) => (props.$animateOpen ? "500px" : "0px")};
  transition: max-height 240ms ease-out;
  animation-name: ${(props) => (props.$animateOpen ? "in" : "out")};
  animation-duration: 240ms;

  @keyframes in {
    0% {
      max-height: 0px;
    }
    100% {
      max-height: 500px;
    }
  }

  @keyframes out {
    0% {
      max-height: 500px;
    }
    100% {
      max-height: 0px;
    }
  }
`;

const RadioSelector = ({
  value,
  errorMessage,
  isChecked,
  onChange,
  label,
  name,
  subText,
  animatedText,
  withFormField,
  formFieldLabel,
  placeholder,
  onFormFieldChange,
  tabindex,
  suppress,
  reverseOrder,
}) => {
  const [animateOn, setAnimateOn] = useState(false);
  useEffect(() => {
    // This animateOn prop is responsible for visually hiding animations
    // as the page is rendering. Once the page has rendered and any initial
    // animation has finished firing we then allow the animate to be triggered
    setTimeout(() => setAnimateOn(true), animationSpeed.fast);
  }, []);

  const handleChange = (evt) => {
    if (evt.key && !isValidSelectKey(evt.key)) {
      return;
    }
    onChange(value);
  };

  const radioLabelClasses = reverseOrder
    ? "label-radio-container label-radio-container--reverse qa-radio"
    : "label-radio-container qa-radio";

  return (
    <>
      <RadioSelectorWrapper
        errorMessage={errorMessage}
        withFormField={withFormField}
        isChecked={isChecked}
        onClick={handleChange}
        onKeyPress={handleChange}
      >
        <div className={radioLabelClasses}>
          <span className="label-radio-option">
            <Body component="span">{label}</Body>
          </span>
          {errorMessage ? (
            <ErrorStateRadioInput
              label={label}
              value={value}
              name={name}
              isChecked={isChecked}
              handleOnChange={handleChange}
            />
          ) : (
            <DefaultRadioInput
              label={label}
              value={value}
              name={name}
              isChecked={isChecked}
              handleOnChange={handleChange}
              tabindex={tabindex}
            />
          )}
        </div>
        {/* TODO: Is this even used? */}
        {withFormField && (
          <div className="input-container">
            {formFieldLabel && (
              <span className="input-label">{formFieldLabel}</span>
            )}
            <FormField
              placeholder={placeholder}
              type="text"
              name="field"
              onChange={onFormFieldChange}
            />
          </div>
        )}
        {animatedText && (
          <AnimatedBox mt={isChecked ? 1 : 0} $animateOpen={isChecked}>
            <AnimatedSmallBody animateOn={animateOn}>
              {animatedText}
            </AnimatedSmallBody>
          </AnimatedBox>
        )}
        {subText && (
          <Box mt={1}>
            <SmallBody {...(suppress && { "data-hj-suppress": true })}>
              {subText}
            </SmallBody>
          </Box>
        )}
      </RadioSelectorWrapper>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

RadioSelector.propTypes = {
  errorMessage: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  withFormField: PropTypes.bool,
  formFieldLabel: PropTypes.string,
  placeholder: PropTypes.string,
  onFormFieldChange: PropTypes.func,
};

export default RadioSelector;
