import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import { Collapse } from "@material-ui/core";
import { Body, LargeBody, SmallBody } from "../ui/Typography";
import { TextButton } from "../ui/Button";
import { InfoBox } from "../ui/InfoBox";
import BirthdayIcon from "../ui/icons/BirthdayIcon";
import {
  PARTNER_DISCOUNT,
  PROMO_DISCOUNT,
  REFERRAL_DISCOUNT,
} from "../../constants/plans";
import StatusLabel from "../ui/StatusLabel";
import CheckmarkBlue from "../ui/icons/CheckmarkBlue";
import GiftPlansIcon from "../ui/icons/GiftPlanIcon";
import { animationSpeed } from "../../config/Theme";
import PromoCodeCollapsibleForm from "../forms/checkout/PromoCodeCollapsibleForm";
import CaaMembershipForm from "../forms/checkout/CaaMembershipForm";

const PlanDetails = ({ plansDetails }) => {
  return plansDetails.map((planDetail) => {
    let DetailsIcon = () => <CheckmarkBlue />;
    let isBold = false;

    if (
      planDetail.includes("Lifetime document access") ||
      planDetail.includes("Accès aux documents")
    ) {
      DetailsIcon = () => <GiftPlansIcon />;
      isBold = true;
    }

    return (
      <Box maxWidth="100%" mb={0.5} key={`${planDetail}`}>
        <Box component="span" display="flex">
          <Box mt="2px">
            <DetailsIcon width={20} height={20} />
          </Box>
          <Box ml={0.5} pt={0.25}>
            <SmallBody key={planDetail} bold={isBold} align="left">
              {planDetail}
            </SmallBody>
          </Box>
        </Box>
      </Box>
    );
  });
};

const PlanTotal = ({ translations, total, isReceiptPage }) => {
  const totalPaid = translations.totalPaidToday;
  const totalAmount = translations.totalForToday;
  return (
    <>
      <Box
        my={1}
        border={1}
        borderColor="border.main"
        borderLeft={0}
        borderRight={0}
        borderTop={0}
      />
      <Box tabIndex={0} mt={1} display="flex" justifyContent="space-between">
        <Box>
          <Body component="span">
            {isReceiptPage ? totalPaid : totalAmount}
          </Body>
        </Box>
        <Box>
          <Body component="span" bold={false}>{`${translations.CAD} `}</Body>
          <Body component="span">{total}</Body>
        </Box>
      </Box>
    </>
  );
};

const DiscountsInfo = ({
  translations,
  discountsData,
  isReceiptPage,
  isLoading,
  handleRemoveReferral,
  promoCode,
  hasCaaMembership,
}) => {
  return discountsData.map(({ label, amount, type }) => {
    return (
      <Box key={type} display="flex" justifyContent="space-between">
        <Box py={0.25}>
          {type === PARTNER_DISCOUNT && (
            <Body
              component="span"
              bold={false}
              dangerouslySetInnerHTML={{ __html: label }}
            />
          )}
          {type !== PARTNER_DISCOUNT && (
            <Body component="span" bold={false}>
              {label}
              {type === REFERRAL_DISCOUNT && !isReceiptPage && (
                <Box component="span" ml={0.5}>
                  <TextButton
                    text={
                      isLoading
                        ? translations.referralRemovalIsLoading
                        : translations.button.remove
                    }
                    onClick={handleRemoveReferral}
                  />
                </Box>
              )}
              {promoCode && !hasCaaMembership && type === PROMO_DISCOUNT && (
                <Body component="span">{promoCode}</Body>
              )}
            </Body>
          )}
        </Box>
        <Box width={105} textAlign="right" py={0.25}>
          <Body component="span" bold={false}>
            {amount}
          </Body>
        </Box>
      </Box>
    );
  });
};

const CollapsibleSummary = ({
  translations,
  orderValue,
  tax,
  taxType,
  total,
  hasDiscount,
  hasCaaMembership,
  handleRemoveReferral,
  discountsData,
  isReceiptPage,
  promoCode,
  isLoading,
  plansDetails = [],
  shouldDisplayPromoCodeField,
  displayTooltipModal,
  handlePromoCodeSubmit,
  promoForm,
  shouldDisplayCaaField,
  handleCaaFormSubmit,
  caaForm,
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const handleCollapse = (e, isFocusing = false) => {
    if (isFocusing && !collapsed) {
      // don't collapse if user is focusing on the
      // collapsible and it is already expanded
      return;
    }
    setCollapsed(!collapsed);
  };

  const collapsibleLabel = collapsed
    ? translations.expand
    : translations.collapse;

  return (
    <>
      <Box
        tabIndex={0}
        onMouseDown={(e) => e.preventDefault()} // prevent triggering focus on click
        onClick={(e) => handleCollapse(e)}
        onFocus={(e) => handleCollapse(e, true)}
      >
        <label htmlFor={translations.totals} aria-label={translations.totals}>
          <Box
            id={translations.totals}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            aria-expanded={!collapsed}
          >
            <Box>
              <LargeBody component="span" margin="0">
                <strong>{translations.totals}</strong>
              </LargeBody>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              pt={0.5}
              style={{ cursor: "pointer" }}
            >
              <SmallBody bold color="willfulBlue">
                {collapsibleLabel}
              </SmallBody>
            </Box>
          </Box>
        </label>
      </Box>

      <Collapse in={!collapsed} timeout={animationSpeed.fast}>
        <Box mt={1} display="flex" justifyContent="space-between">
          <Box py={0.25}>
            <Body component="span" bold={false}>
              {translations.planName}
            </Body>
          </Box>
          <Box py={0.25} display="flex" alignItems="center">
            <Body component="span" bold={false}>
              {orderValue}
            </Body>
          </Box>
        </Box>

        <Box mt={0.5} pl={2}>
          <PlanDetails plansDetails={plansDetails} />
        </Box>
        {hasDiscount && (
          <Box tabIndex={0}>
            <DiscountsInfo
              translations={translations}
              discountsData={discountsData}
              isReceiptPage={isReceiptPage}
              isLoading={isLoading}
              handleRemoveReferral={handleRemoveReferral}
              promoCode={promoCode}
              hasCaaMembership={hasCaaMembership}
            />
          </Box>
        )}
        <Box tabIndex={0} display="flex" justifyContent="space-between">
          <Box py={0.25}>
            <Body component="span" bold={false}>
              {translations.tax} ({taxType})
            </Body>
          </Box>
          <Box py={0.25}>
            <Body component="span" bold={false}>
              {tax}
            </Body>
          </Box>
        </Box>

        <PlanTotal
          translations={translations}
          total={total}
          isReceiptPage={isReceiptPage}
        />

        {/* Promo code field */}
        {shouldDisplayPromoCodeField && (
          <PromoCodeCollapsibleForm
            onSubmit={handlePromoCodeSubmit}
            displayTooltipModal={displayTooltipModal}
            {...promoForm}
          />
        )}
        {/* CAA Field */}
        {shouldDisplayCaaField && (
          <CaaMembershipForm
            onSubmit={handleCaaFormSubmit}
            displayTooltipModal={displayTooltipModal}
            {...caaForm}
          />
        )}
      </Collapse>

      {/* When summary is collapsed show only the plan total */}
      {collapsed && (
        <PlanTotal
          translations={translations}
          total={total}
          isReceiptPage={isReceiptPage}
        />
      )}
    </>
  );
};

const StaticSummary = ({
  translations,
  orderValue,
  tax,
  taxType,
  total,
  hasDiscount,
  hasCaaMembership,
  handleRemoveReferral,
  discountsData,
  isReceiptPage,
  promoCode,
  isLoading,
  planSummary,
  plansDetails = [],
  shouldDisplayPromoCodeField,
  displayTooltipModal,
  handlePromoCodeSubmit,
  promoForm,
  shouldDisplayCaaField,
  handleCaaFormSubmit,
  caaForm,
}) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <LargeBody component="span" margin="0">
            <strong>{translations.totals}</strong>
          </LargeBody>
        </Box>
        {isReceiptPage && (
          <Box alignItems="center" display="flex">
            <StatusLabel
              status={planSummary.hasUserPaid ? "paid" : "unpaid"}
              translations={translations}
            />
          </Box>
        )}
      </Box>

      <Box mt={1} display="flex" justifyContent="space-between">
        <Box py={0.25}>
          <Body component="span" bold={false}>
            {translations.planName}
          </Body>
        </Box>
        <Box py={0.25} display="flex" alignItems="center">
          <Body component="span" bold={false}>
            {orderValue}
          </Body>
        </Box>
      </Box>

      <Box mt={0.5} pl={2}>
        <PlanDetails plansDetails={plansDetails} />
      </Box>
      {hasDiscount && (
        <Box>
          <DiscountsInfo
            translations={translations}
            discountsData={discountsData}
            isReceiptPage={isReceiptPage}
            isLoading={isLoading}
            handleRemoveReferral={handleRemoveReferral}
            promoCode={promoCode}
            hasCaaMembership={hasCaaMembership}
          />
        </Box>
      )}
      <Box display="flex" justifyContent="space-between">
        <Box py={0.25}>
          <Body component="span" bold={false}>
            {translations.tax} ({taxType})
          </Body>
        </Box>
        <Box py={0.25}>
          <Body component="span" bold={false}>
            {tax}
          </Body>
        </Box>
      </Box>

      <PlanTotal
        translations={translations}
        total={total}
        isReceiptPage={isReceiptPage}
      />

      {/* Promo code field */}
      {shouldDisplayPromoCodeField && (
        <PromoCodeCollapsibleForm
          onSubmit={handlePromoCodeSubmit}
          displayTooltipModal={displayTooltipModal}
          {...promoForm}
        />
      )}
      {/* CAA Field */}
      {shouldDisplayCaaField && (
        <CaaMembershipForm
          onSubmit={handleCaaFormSubmit}
          displayTooltipModal={displayTooltipModal}
          {...caaForm}
        />
      )}
    </>
  );
};

const PaymentSummary = ({
  isDesktop,
  hasReferral,
  isReceiptPage,
  translations,
  ...checkoutInfo
}) => {
  const showCollapsibleSummary = !isDesktop && !isReceiptPage;
  const summaryProps = {
    translations,
    isReceiptPage,
    ...checkoutInfo,
  };

  return (
    <Box>
      {hasReferral && (
        <Box mb={2}>
          <InfoBox
            title={translations.referralInfoBoxTitle}
            paragraph={translations.referralInfoBoxText}
            icon={<BirthdayIcon />}
          />
        </Box>
      )}
      {showCollapsibleSummary ? (
        <CollapsibleSummary {...summaryProps} />
      ) : (
        <StaticSummary {...summaryProps} />
      )}
    </Box>
  );
};

export default PaymentSummary;
