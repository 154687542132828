import { takeEvery, call } from "redux-saga/effects";
import {
  UPDATE_GIFTS_TYPE,
  UPDATE_GIFTS_WITH_CONTACT_TYPE,
} from "../actions/gifts";
import { submitFormRequest } from "./forms";
import { submitGifts } from "../api/gifts";
import { GIFTS_FORM_ID } from "../constants/forms";

export function* updateGifts() {
  yield call(submitFormRequest, {
    apiCall: submitGifts,
    formId: GIFTS_FORM_ID,
  });
}

export function* updateGiftsWithContactWorker() {
  yield call(submitFormRequest, {
    apiCall: submitGifts,
    formId: GIFTS_FORM_ID,
  });
}

export function* watchUpdateGifts() {
  yield takeEvery(UPDATE_GIFTS_TYPE, updateGifts);
}

export function* watchUpdateGiftsWithContact() {
  yield takeEvery(UPDATE_GIFTS_WITH_CONTACT_TYPE, updateGiftsWithContactWorker);
}
