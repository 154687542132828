import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";

import {
  RESTING_FIELD_ID,
  RESTING_FORM_ID,
  ASHES_FORM_ID,
  ASHES_FIELD_ID,
  CEREMONY_FORM_ID,
  CEREMONY_FIELD_ID,
  RESTING_CREMATION,
} from "../constants/forms";
import { selectIsFieldComplete } from "./utils/progress";
import { selectTranslations } from "./translations";
import { selectSpouseResting } from "./wishes";

const selectSpouseArrangementsData = R.pathOr({}, ["spouseArrangements"]);
export const selectSpouseHasSkippedWishes = R.path([
  "spouseArrangements",
  "wishesVisited",
]);

export const selectIsSpouseRestingComplete = createSelector(
  selectIsFieldComplete(selectSpouseArrangementsData, RESTING_FIELD_ID),
  selectSpouseHasSkippedWishes,
  (restingComplete, skippedWishes) => restingComplete || skippedWishes,
);

export const selectHasSpouseCremationResting = createSelector(
  selectSpouseResting,
  (restingValue) => restingValue === RESTING_CREMATION,
);

export const selectIsSpouseAshesComplete = createSelector(
  selectHasSpouseCremationResting,
  selectIsFieldComplete(selectSpouseArrangementsData, ASHES_FIELD_ID),
  selectSpouseHasSkippedWishes,
  (hasCremation, isAshesComplete, skippedWishes) => {
    if (hasCremation) return isAshesComplete;
    return skippedWishes;
  },
);

export const selectIsSpouseCeremonyComplete = createSelector(
  selectIsFieldComplete(selectSpouseArrangementsData, CEREMONY_FIELD_ID),
  selectSpouseHasSkippedWishes,
  (ceremonyComplete, skippedWishes) => ceremonyComplete || skippedWishes,
);

export const spouseAshesPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(ASHES_FORM_ID),
  isLoading: selectIsLoadingById(ASHES_FORM_ID),
  translations: selectTranslations([
    "global",
    "wishesAshes",
    "spouseArrangements",
  ]),
});
export const spouseCeremonyPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(CEREMONY_FORM_ID),
  isLoading: selectIsLoadingById(CEREMONY_FORM_ID),
  translations: selectTranslations([
    "global",
    "wishesCeremony",
    "spouseArrangements",
  ]),
});
export const spouseRestingPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(RESTING_FORM_ID),
  isLoading: selectIsLoadingById(RESTING_FORM_ID),
  translations: selectTranslations([
    "global",
    "wishesResting",
    "spouseArrangements",
  ]),
});
