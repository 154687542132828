import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { SmallBody, SmallExternalLink } from "./Typography";
import {
  CANADA_HELPS_URL,
  CANADA_HELPS_FR_URL,
  RETHINK_BREAST_CANCER_URL,
} from "../../constants/routes";
import {
  RethinkBreastCancerIcon,
  ParkinsonCanadaIcon,
  SecondHarvestFrench,
  SecondHarvestEnglish,
  OsteoporosisCanadaEnglish,
  OsteoporosisCanadaFrench,
  UniversityHealthNetworkFoundationIcon,
  WaterlooUniversityIcon,
  TorontoHumaneSocietyIcon,
  NatureConservancyCanadaIcon,
  SickKidsFoundationIcon,
  JaysCareFoundationIcon,
  CanadianCancerSocietyIcon,
} from "./icons/CharityIcons";

const NoFeaturedCharity = ({ translations, isFrenchSelected }) => {
  const leftAndRightCols = isFrenchSelected ? 2 : 0;
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
      <Box maxWidth={600}>
        <SmallBody align="center" margin={0}>
          {translations.noCharityHeaderInfo}
        </SmallBody>
      </Box>
      <Grid container>
        <Grid item md={leftAndRightCols} />
        <Grid item md={isFrenchSelected ? 8 : 12} sm={12}>
          <Box mt={1}>
            <SmallBody align="center">
              {translations.noCharityInfo}{" "}
              <SmallExternalLink
                text={translations.canadaHelps}
                target="_blank"
                href={CANADA_HELPS_URL}
              />
            </SmallBody>
          </Box>
        </Grid>
        <Grid item md={leftAndRightCols} />
      </Grid>
    </Box>
  );
};

const FeaturedCharityTemplate = ({
  desktopIcon,
  tabletIcon,
  desktopFrenchIcon,
  tabletFrenchIcon,
  infoText,
  translations,
  isFrenchSelected,
}) => {
  const leftAndRightCols = isFrenchSelected ? 2 : 0;
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={4.5}>
      <Hidden mdDown>
        {/* Desktop */}
        {isFrenchSelected && desktopFrenchIcon
          ? desktopFrenchIcon
          : desktopIcon}
      </Hidden>
      <Hidden lgUp>
        {/* Tablet */}
        {desktopFrenchIcon && tabletFrenchIcon ? tabletFrenchIcon : tabletIcon}
      </Hidden>

      <Box mt={2} maxWidth={600}>
        <SmallBody align="center" margin={0}>
          {infoText}
        </SmallBody>
      </Box>
      <Grid container>
        <Grid item md={leftAndRightCols} />
        <Grid item md={isFrenchSelected ? 8 : 12} sm={12}>
          <Box mt={1}>
            <SmallBody align="center">
              {translations.charityInfo}{" "}
              <SmallExternalLink
                text={translations.canadaHelps}
                target="_blank"
                href={CANADA_HELPS_URL}
              />
            </SmallBody>
          </Box>
        </Grid>
        <Grid item md={leftAndRightCols} />
      </Grid>
    </Box>
  );
};

const SecondHarvest = ({ isFrenchSelected, translations }) => {
  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <Hidden mdDown>
          {/* Desktop */}
          <Box mt={3}>
            {isFrenchSelected ? (
              <SecondHarvestFrench width="370px" height="100px" />
            ) : (
              <SecondHarvestEnglish width="370px" height="100px" />
            )}
          </Box>
        </Hidden>
        <Hidden smDown lgUp>
          {/* Tablet */}
          <Box mb={1} mt={3}>
            {isFrenchSelected ? (
              <SecondHarvestFrench width="320px" height="90px" />
            ) : (
              <SecondHarvestEnglish width="320px" height="90px" />
            )}
          </Box>
        </Hidden>
        <Hidden mdUp lgUp>
          {/* Mobile */}
          <Box mb={1} mt={3}>
            {isFrenchSelected ? (
              <SecondHarvestFrench width="270px" height="70px" />
            ) : (
              <SecondHarvestEnglish width="270px" height="70px" />
            )}
          </Box>
        </Hidden>
      </Box>
      <Grid container justify="center" spacing={2}>
        <Grid xs={12} md={10} lg={7}>
          <Box mt={2}>
            <SmallBody margin="0 0 16px" align="center">
              {translations.featuredCharityDescription}
            </SmallBody>
            <SmallBody align="center">
              {translations.charityInfo}{" "}
              <SmallExternalLink
                text={translations.canadaHelps}
                target="_blank"
                href={isFrenchSelected ? CANADA_HELPS_FR_URL : CANADA_HELPS_URL}
              />
            </SmallBody>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const RethinkBreastCancer = ({ translations }) => (
  <Box display="flex" flexDirection="column" alignItems="center" mt={4.5}>
    <RethinkBreastCancerIcon />
    <Box mt={2} maxWidth={600}>
      <SmallBody align="center" margin={0}>
        {translations.rethinkBreastCancer1}{" "}
        <SmallExternalLink
          text={translations.rethinkBreastCancerLabel}
          target="_blank"
          href={RETHINK_BREAST_CANCER_URL}
        />{" "}
        {translations.rethinkBreastCancer2}
      </SmallBody>
    </Box>
    <Box mt={1}>
      <SmallBody align="center">
        {translations.charityInfo}{" "}
        <SmallExternalLink
          text={translations.canadaHelps}
          target="_blank"
          href={CANADA_HELPS_URL}
        />
      </SmallBody>
    </Box>
  </Box>
);

const ParkinsonCanada = ({ translations }) => (
  <FeaturedCharityTemplate
    desktopIcon={<ParkinsonCanadaIcon width={320} height={48} />}
    tabletIcon={<ParkinsonCanadaIcon width={320} height={40} />}
    infoText={translations.parkinsonCanadaInfo}
    isFrenchSelected={false}
    translations={translations}
  />
);

const OsteoporosisCanada = ({ translations, isFrenchSelected }) => (
  <FeaturedCharityTemplate
    desktopIcon={<OsteoporosisCanadaEnglish width={322} height={32} />}
    tabletIcon={<OsteoporosisCanadaEnglish width={241} height={24} />}
    desktopFrenchIcon={<OsteoporosisCanadaFrench width={293} height={40} />}
    tabletFrenchIcon={<OsteoporosisCanadaFrench width={235} height={32} />}
    infoText={translations.osteoporosisCanadaInfo}
    isFrenchSelected={isFrenchSelected}
    translations={translations}
  />
);

const JaysCareFoundation = ({ translations }) => (
  <FeaturedCharityTemplate
    desktopIcon={<JaysCareFoundationIcon height={56} />}
    tabletIcon={<JaysCareFoundationIcon height={48} />}
    infoText={translations.jayscareFoundationInfo}
    isFrenchSelected={false}
    translations={translations}
  />
);

const UniversityHealthNetworkFoundation = ({
  translations,
  isFrenchSelected,
}) => {
  return (
    <FeaturedCharityTemplate
      desktopIcon={<UniversityHealthNetworkFoundationIcon height={56} />}
      tabletIcon={<UniversityHealthNetworkFoundationIcon height={48} />}
      infoText={translations.universityHealthNetworkInfo}
      isFrenchSelected={isFrenchSelected}
      translations={translations}
    />
  );
};

const WaterlooUniversity = ({ translations, isFrenchSelected }) => {
  return (
    <FeaturedCharityTemplate
      desktopIcon={<WaterlooUniversityIcon height={56} />}
      tabletIcon={<WaterlooUniversityIcon height={48} />}
      infoText={translations.waterlooUniversityInfo}
      isFrenchSelected={isFrenchSelected}
      translations={translations}
    />
  );
};

const TorontoHumaneSociety = ({ translations, isFrenchSelected }) => {
  return (
    <FeaturedCharityTemplate
      desktopIcon={<TorontoHumaneSocietyIcon height={56} />}
      tabletIcon={<TorontoHumaneSocietyIcon height={48} />}
      infoText={translations.torontoHumaneSocietyInfo}
      isFrenchSelected={isFrenchSelected}
      translations={translations}
    />
  );
};

const NatureConservancyCanada = ({ translations, isFrenchSelected }) => {
  return (
    <FeaturedCharityTemplate
      desktopIcon={<NatureConservancyCanadaIcon height={56} />}
      tabletIcon={<NatureConservancyCanadaIcon height={48} />}
      infoText={translations.natureConservancyOfCanadaInfo}
      isFrenchSelected={isFrenchSelected}
      translations={translations}
    />
  );
};

const SickKidsFoundation = ({ translations, isFrenchSelected }) => (
  <FeaturedCharityTemplate
    desktopIcon={<SickKidsFoundationIcon height={120} />}
    tabletIcon={<SickKidsFoundationIcon height={100} />}
    infoText={translations.sickKidsFoundationInfo}
    isFrenchSelected={isFrenchSelected}
    translations={translations}
  />
);

const CanadianCancerSociety = ({ translations, isFrenchSelected }) => (
  <FeaturedCharityTemplate
    desktopIcon={<CanadianCancerSocietyIcon height={80} />}
    tabletIcon={<CanadianCancerSocietyIcon height={70} />}
    infoText={translations.canadianCancerSocietyInfo}
    isFrenchSelected={isFrenchSelected}
    translations={translations}
  />
);

const selectFeaturedCharityTemplate = (featuredCharityName = "") => {
  // To add a new charity:
  // 1- Create a new component and add it to the map below
  // 2- Add the charity name and value in the following Notion document:
  // https://www.notion.so/willful/Featured-Charities-List-b39a1462b09349bb8a397922fa72baa4?pvs=4
  const featuredCharityMap = {
    SecondHarvest,
    RethinkBreastCancer,
    ParkinsonCanada,
    OsteoporosisCanada,
    JaysCareFoundation,
    UniversityHealthNetworkFoundation,
    WaterlooUniversity,
    TorontoHumaneSociety,
    NatureConservancyCanada,
    SickKidsFoundation,
    CanadianCancerSociety,
  };
  return featuredCharityMap[featuredCharityName.trim()] || NoFeaturedCharity;
};

const FeaturedCharity = ({
  featuredCharityName,
  translations,
  isFrenchSelected,
}) => {
  const SelectedFeaturedCharity = selectFeaturedCharityTemplate(
    featuredCharityName,
  );
  return (
    <SelectedFeaturedCharity
      translations={translations}
      isFrenchSelected={isFrenchSelected}
    />
  );
};

export default FeaturedCharity;
