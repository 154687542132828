export const updateResting = () => ({
  type: UPDATE_WISHES_RESTING_TYPE,
});

export const updateAshes = () => ({
  type: UPDATE_WISHES_ASHES_TYPE,
});

export const updateCeremony = () => ({
  type: UPDATE_WISHES_CEREMONY_TYPE,
});

export const UPDATE_WISHES_RESTING_TYPE = "UPDATE_WISHES_RESTING";
export const UPDATE_WISHES_ASHES_TYPE = "UPDATE_WISHES_ASHES";
export const UPDATE_WISHES_CEREMONY_TYPE = "UPDATE_WISHES_CEREMONY";
