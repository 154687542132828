export const openModalChildrenGuardians = (guardian = null) => ({
  type: OPEN_MODAL_CHILDREN_GUARDIANS_TYPE,
  payload: { guardian },
});

export const removeChildrenGuardian = (guardian) => ({
  type: REMOVE_CHILDREN_GUARDIAN_TYPE,
  payload: { guardian },
});

export const childrenGuardianUpdatedSuccesfully = (guardian) => ({
  type: UPDATE_CHILDREN_GUARDIAN_SUCCESSFULLY_TYPE,
  payload: { guardian },
});

export const childrenGuardiansnNextPage = () => ({
  type: CHILDREN_GUARDIANS_NEXT_PAGE_TYPE,
});

export const OPEN_MODAL_CHILDREN_GUARDIANS_TYPE =
  "OPEN_MODAL_CHILDREN_GUARDIANS";
export const REMOVE_CHILDREN_GUARDIAN_TYPE = "REMOVE_CHILDREN_GUARDIAN";
export const UPDATE_CHILDREN_GUARDIAN_SUCCESSFULLY_TYPE =
  "UPDATE_CHILDREN_GUARDIAN_SUCCESSFULLY_TYPE";
export const CHILDREN_GUARDIANS_NEXT_PAGE_TYPE = "GUARDIANS_NEXT_PAGE";
