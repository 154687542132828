import React from "react";

import SvgIcon from "./SvgIcon";

const NotificationsIcon = ({ width = 200, height = 200 }) =>
{
  return (
    <SvgIcon  width={width} height={height} viewBox="0 0 48.3 48" fill="none">
      <path d="M21.4566 21.3917L23.7671 20.043L26.5935 21.6849L43.8103 22.6701L45.4875 32.721L48.3022 34.3747L45.9918 35.7234L46.6016 39.3826L32.3051 47.7447C31.7187 48.0848 31.0033 48.0848 30.4169 47.7447L0.205334 30.305C-0.0761396 30.1409 -0.0644143 29.7421 0.21706 29.5896L4.76756 27.115L15.1821 21.0281L21.4566 21.3917Z" fill="#2F455F"/>
      <path d="M0.486816 29.6959L2.59787 28.4645L14.7716 24.0078L12.6606 25.2275L0.486816 29.6959Z" fill="#774C0D"/>
      <path d="M0.557251 6.75489L2.66831 5.52344L14.7717 24.0069L12.6606 25.2266L0.557251 6.75489Z" fill="#935710"/>
      <path d="M0.557186 6.75586L12.6606 25.2276L0.486816 29.696L0.521999 18.2259L0.557186 6.75586Z" fill="#E2A03B"/>
      <path d="M12.6605 25.2275L14.7716 24.0078L17.6919 28.4528L15.5808 29.6842L12.6605 25.2275Z" fill="#935F10"/>
      <path d="M15.5808 29.6835L17.6919 28.452L20.6239 27.373L18.5128 28.6045L15.5808 29.6835Z" fill="#774C0D"/>
      <path d="M18.5129 28.6045L20.624 27.373L32.7274 45.8565L30.6163 47.0879L18.5129 28.6045Z" fill="#935F10"/>
      <path d="M18.5129 28.6042L30.6163 47.0877L15.5574 38.3855L0.486816 29.695L12.6606 25.2266L15.5809 29.6832L18.5129 28.6042Z" fill="#FFDC1E"/>
      <path d="M0.557251 6.75489L2.66831 5.52344L17.7272 14.2257L15.6161 15.4571L0.557251 6.75489Z" fill="#836100"/>
      <path d="M0.557251 6.75537L2.66831 5.52393L17.7741 0L15.663 1.23145L0.557251 6.75537Z" fill="#BC7F35"/>
      <path d="M30.6866 24.1475L30.6162 47.0876L32.7273 45.8561L32.7976 22.9277L30.6866 24.1475Z" fill="#BC7F35"/>
      <path d="M18.5129 28.6041L20.624 27.3727L32.7977 22.9277L30.6867 24.1475L18.5129 28.6041Z" fill="#77460D"/>
      <path d="M30.6867 24.1486L18.5129 28.6053L15.5809 29.6843L12.6606 25.2276L0.557251 6.75586L15.6161 15.4581L30.6867 24.1486Z" fill="#F5B400"/>
      <path d="M18.5129 28.6051L30.6867 24.1484L30.6515 35.6185L30.6163 47.0886L18.5129 28.6051Z" fill="#E2A03B"/>
      <path d="M15.616 15.4561L17.727 14.2246L32.7976 22.9268L30.6866 24.1466L15.616 15.4561Z" fill="#836100"/>
      <path d="M15.663 1.23145L17.774 0L32.7977 22.9284L30.6866 24.1481L15.663 1.23145Z" fill="#E2A03B"/>
      <path d="M15.663 1.23047L30.6867 24.1471L15.6161 15.4566L0.557251 6.7544L15.663 1.23047Z" fill="#FFDC1E"/>
      <path d="M27.3913 16.596L28.0949 16.1855L28.0715 25.1106L27.3678 25.3804L27.3913 16.596Z" fill="#71ADEE"/>
      <path d="M3.86462 3.014L4.56831 2.60352L28.0949 16.1846L27.3912 16.5951L3.86462 3.014Z" fill="#AAD2F6"/>
      <path d="M27.3912 16.5948L27.3677 25.3791L18.5013 28.6278L15.5693 29.7068L12.6607 25.2501L3.84119 11.798L3.86464 3.01367L27.3912 16.5948Z" fill="#E2F6FF"/>
      <path d="M3.86475 8.84253L27.3795 22.4236L27.3913 16.5948L3.86475 3.01367V8.84253Z" fill="#0064DC"/>
      <path d="M7.18371 7.42487L6.83187 7.20204H6.80841L6.49175 6.97921H6.45657L6.28064 7.42487L6.10472 6.75637C6.10472 6.75637 6.10472 6.73292 6.08127 6.73292L5.83498 6.58045H5.81152L5.6356 7.02612L5.45968 6.35762C5.45968 6.34589 5.44795 6.33416 5.43622 6.33416L5.06092 6.11133H5.04919L5.42449 7.42487C5.42449 7.4366 5.43622 7.44833 5.44795 7.44833L5.74115 7.62425H5.76461L5.96398 7.20204L6.16336 7.84708C6.16336 7.85881 6.17509 7.87054 6.18682 7.87054L6.48002 8.04646H6.50348L6.83187 7.29587V8.24584C6.83187 8.25757 6.84359 8.2693 6.85532 8.2693L7.20716 8.49213H7.25407V7.42487C7.20716 7.44833 7.20717 7.42487 7.18371 7.42487Z" fill="white"/>
      <path d="M7.18405 7.25057C7.25182 7.20059 7.22883 7.05442 7.13271 6.9241C7.03659 6.79378 6.90373 6.72865 6.83596 6.77864C6.7682 6.82862 6.79118 6.97479 6.8873 7.10511C6.98342 7.23543 7.11628 7.30055 7.18405 7.25057Z" fill="white"/>
      <path d="M7.69989 7.28338L7.34804 7.06055H7.32458V8.62038C7.32458 8.63211 7.33631 8.64384 7.34804 8.64384L7.69989 8.86667H7.72334V7.29511C7.73507 7.28338 7.69989 7.28338 7.69989 7.28338Z" fill="white"/>
      <path d="M10.9603 10.4616C10.8313 10.3912 10.7374 10.4382 10.7374 10.532C10.7492 10.6727 10.8313 10.8017 10.9603 10.8838C11.0775 10.9542 11.1831 10.9073 11.1831 10.8135C11.1714 10.661 11.0893 10.532 10.9603 10.4616Z" fill="white"/>
      <path d="M8.22759 7.59979L7.87575 7.37695H7.85229V8.93679C7.85229 8.94852 7.86402 8.96024 7.87575 8.96024L8.22759 9.18308H8.25105V7.62324C8.25105 7.61152 8.23932 7.59979 8.22759 7.59979Z" fill="white"/>
      <path d="M10.6084 9.06463L10.2566 8.8418H10.2332V10.4134C10.2332 10.4251 10.2449 10.4368 10.2566 10.4368L10.6084 10.6596H10.6319V9.08809C10.6319 9.07636 10.6202 9.06463 10.6084 9.06463Z" fill="white"/>
      <path d="M10.0923 9.21948L9.74048 8.99664H9.71702V9.54786C9.71702 9.66514 9.64665 9.70033 9.5411 9.64169C9.44727 9.58305 9.38864 9.47749 9.38864 9.37194V8.78554C9.38864 8.78554 9.38863 8.76208 9.36518 8.76208L9.31827 8.73862L9.02506 8.53925L8.77877 8.38678L8.75532 8.36332V8.29296C8.75532 8.22259 8.80223 8.22259 8.8726 8.2695C8.90779 8.29296 8.94297 8.31641 8.96642 8.33987H8.97815V8.02321C8.9547 7.99975 8.84915 7.92939 8.75532 7.84729C8.50903 7.69482 8.35657 7.74174 8.35657 8.02321V9.21948C8.35657 9.2312 8.36829 9.24293 8.38002 9.24293L8.73186 9.46577H8.75532V8.7269L9.00161 8.87936V9.23121C9.01334 9.52441 9.17753 9.79415 9.42382 9.94662C9.6232 10.0639 9.71702 9.99353 9.71702 9.99353V10.0874C9.71702 10.0991 9.72875 10.1108 9.74048 10.1108L10.0923 10.3336H10.1158V9.26639L10.0923 9.21948Z" fill="white"/>
      <path d="M28.2237 13.2775L27.9539 19.6224L26.7694 19.3644L26.9688 14.7435L26.0188 14.5324L26.054 13.6763C26.9336 13.7349 27.2268 13.1016 27.2385 13.0664L28.2237 13.2775Z" fill="white"/>
      <path d="M27.4732 10.0042C30.4756 10.6727 32.7977 14.0152 32.6453 17.4633C32.4928 20.9113 29.9361 23.1631 26.9337 22.5063C23.9196 21.8378 21.6091 18.4953 21.7499 15.0473C21.9023 11.5875 24.4708 9.3357 27.4732 10.0042ZM27.9423 19.6212L28.212 13.2763L27.2269 13.0535C27.2152 13.0887 26.922 13.722 26.0424 13.6634L26.0072 14.5195L26.9571 14.7306L26.7578 19.3515L27.9423 19.6212Z" fill="#FF2222"/>
      <path d="M27.7077 9.88736C26.5818 9.64107 25.5145 9.79354 24.6115 10.2861C25.4559 9.89909 26.441 9.78181 27.4731 10.0046C30.4755 10.6731 32.7977 14.0156 32.6452 17.4637C32.5514 19.6217 31.5193 21.2988 30.0298 22.108C31.6483 21.3574 32.7859 19.6099 32.8798 17.3464C33.0322 13.8984 30.7101 10.5559 27.7077 9.88736Z" fill="#C80000"/>
    </SvgIcon>
  );
}

export default NotificationsIcon;
