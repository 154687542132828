import R from "ramda";
import { createSelector } from "reselect";

export const selectAllExperiments = R.propOr({}, "experiments");

const LOCAL_STORED_EXPERIMENTS = [];

export const selectLocalStorageExperimentsValues = () => {
  const localStorageExperimentsValues = [];
  LOCAL_STORED_EXPERIMENTS.forEach((experimentName) => {
    const experimentId = R.pathOr(null, ["env", experimentName])(window);
    const variantValue = localStorage.getItem(experimentName);
    if (experimentId && variantValue) {
      localStorageExperimentsValues.push({
        [experimentId]: variantValue,
      });
    }
  });
  return localStorageExperimentsValues;
};

// ----------- Post Purchase Experiment ------------
const getPostPurchaseExperimentId = () =>
  R.pathOr(19, ["env", "EXPERIMENT_POST_PURCHASE_ID"])(window);

const getPostPurchaseExperimentDefaultVariation = () =>
  R.pathOr("1", ["env", "EXPERIMENT_POST_PURCHASE_DEFAULT_VARIATION"])(window);

const selectPostPurchaseExperimentVariation = createSelector(
  selectAllExperiments,
  (experiments) => {
    const experimentId = getPostPurchaseExperimentId();
    const defaultVariation = getPostPurchaseExperimentDefaultVariation();
    const variation = experiments[experimentId];
    return variation || defaultVariation;
  },
);

export const selectShouldDisplayPostPurchaseExperiment = createSelector(
  selectPostPurchaseExperimentVariation,
  (variation) => variation === "2",
);
// ----------- End Post Purchase Experiment ------------

// ----------- Hard Paywall Experiment ------------
const getHardPaywallExperimentId = () =>
  R.pathOr(48, ["env", "EXPERIMENT_HARD_PAYWALL_ID"])(window);

const getHardPaywallExperimentControlVariation = () =>
  R.pathOr("1", ["env", "EXPERIMENT_HARD_PAYWALL_DEFAULT_VARIATION"])(window);

const selectHardPaywallExperimentVariation = createSelector(
  selectAllExperiments,
  (experiments) => {
    const experimentId = getHardPaywallExperimentId();
    const controlVariation = getHardPaywallExperimentControlVariation();
    const variation = experiments[experimentId];
    return variation || controlVariation;
  },
);

export const selectShouldDisplayHardPaywallExperiment = createSelector(
  selectHardPaywallExperimentVariation,
  (variation) => {
    if (window.env.FEATURE_ESS_PREM_DOC_PAGE === "enabled") {
      return variation === "2";
    }
    return false;
  },
);
