import React from "react";

const YourArrangementsIcon = ({ width = 156, height = 117 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 156 117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M78 116.036c43.078 0 78-23.22 78-51.865 0-28.644-34.922-51.864-78-51.864S0 35.527 0 64.17s34.922 51.865 78 51.865Z"
      fill="#ECEDEF"
    />
    <path
      d="M27.828 77.104c-7.99-4.334-12.12-10.157-12.323-16.115-.203-5.958 3.589-11.984 11.24-16.86 15.437-9.546 40.963-10.359 57.01-1.692 7.99 4.333 12.12 10.156 12.323 16.114.203 5.959-3.588 11.985-11.24 16.86-15.437 9.411-40.963 10.224-57.01 1.693Z"
      fill="#2F455F"
    />
    <path
      d="M72.042 35.124c-13.61-7.854-35.683-7.854-49.292 0-6.77 3.995-10.224 9.14-10.224 14.286v6.027c0 5.145 3.453 10.291 10.224 14.286 13.61 7.854 35.682 7.854 49.292 0 6.77-3.995 10.224-9.14 10.224-14.286V49.41c0-5.214-3.453-10.36-10.224-14.287Z"
      fill="#71ADEE"
    />
    <path
      d="M72.042 35.124c13.677 7.855 13.677 20.584 0 28.438-13.677 7.854-35.682 7.854-49.292 0s-13.61-20.583 0-28.438c13.61-7.854 35.682-7.921 49.292 0Z"
      fill="#E2F6FF"
    />
    <path
      d="M71.636 36.275c6.838 6.297 5.484 14.828-4.063 20.313-11.104 6.432-29.182 6.432-40.286 0-9.547-5.485-10.901-14.016-4.063-20.313-12.187 7.516-11.916 19.23.745 26.542 12.932 7.516 33.854 7.516 46.787 0 12.661-7.245 12.932-19.026.88-26.542Z"
      fill="#AAD2F6"
    />
    <path
      d="M67.505 33.364c11.105 6.432 11.105 16.792 0 23.292-11.104 6.5-29.182 6.432-40.286 0-11.104-6.433-11.104-16.792 0-23.292s29.182-6.432 40.286 0Z"
      fill="#FFDC1E"
    />
    <path
      d="M67.505 31.333c11.105 6.432 11.105 16.791 0 23.291-11.104 6.5-29.182 6.432-40.286 0-11.104-6.432-11.104-16.791 0-23.291s29.182-6.433 40.286 0Z"
      fill="#AAD2F6"
    />
    <path
      d="M66.693 31.874c10.63 6.162 10.63 16.183 0 22.344-10.63 6.161-27.964 6.161-38.73 0-10.765-6.162-10.63-16.182 0-22.344 10.63-6.161 28.1-6.297 38.73 0Z"
      fill="#AAD2F6"
    />
    <path
      d="M66.693 30.656c10.63 6.161 10.63 16.182 0 22.343-10.63 6.162-27.964 6.162-38.73 0-10.765-6.16-10.63-16.182 0-22.343 10.63-6.162 28.1-6.162 38.73 0Z"
      fill="#E2F6FF"
    />
    <path
      d="M82.13 48.26v-.27c0-.34-.067-.61-.203-.881l-21.734-10.36-33.042 2.98-12.458 5.62s-1.083 11.104 7.042 16.927c8.125 5.822 16.588 4.942 20.854 2.911a11.786 11.786 0 0 0 5.687-6.432l13.745-7.38 3.589 2.234 1.692 3.182 8.6 4.13.203 5.891c4.062-3.453 6.161-7.38 6.161-11.375v-6.703c-.136-.203-.136-.27-.136-.474Z"
      fill="#004EB6"
    />
    <path
      d="M84.771 27.541c.068-1.896-.203-3.86-.61-5.688a38.332 38.332 0 0 0-1.828-5.348 33.545 33.545 0 0 0-7.041-10.292c-2.234-2.234-4.943-3.995-7.854-5.214-2.302-1.015-4.943-1.286-7.38-.677-2.235.61-4.13 2.167-5.214 4.13a11.634 11.634 0 0 0-1.354 3.995c-.136.542-.136 1.084-.203 1.625v2.37c.067 1.49.27 2.98.609 4.401a22.661 22.661 0 0 0 1.828 5.214 37.87 37.87 0 0 0 5.146 8.328c4.401 5.213 9.953 8.328 14.625 8.328 2.438 0 4.74-.948 6.432-2.708a7.974 7.974 0 0 0 1.287-1.693c.406-.745.745-1.557 1.015-2.37.271-1.422.474-2.911.542-4.401Z"
      fill="#71ADEE"
    />
    <path
      d="M84.77 27.541c.069-1.896-.202-3.86-.609-5.688a38.358 38.358 0 0 0-1.828-5.348 33.547 33.547 0 0 0-7.041-10.292c-2.235-2.234-4.943-3.995-7.855-5.214-2.302-1.015-4.942-1.286-7.38-.677-.203.068-.27.068-.474.203 1.896-.067 3.792.271 5.485 1.016 3.52 1.354 6.161 3.724 7.854 5.214a33.545 33.545 0 0 1 7.041 10.291 38.346 38.346 0 0 1 1.829 5.35c.474 1.827.677 3.79.609 5.687 0 1.422-.203 2.843-.61 4.265a8.091 8.091 0 0 1-1.015 2.37c-.339.61-.745 1.219-1.286 1.693-.948 1.015-2.167 1.76-3.454 2.234 2.167-.135 4.266-1.083 5.756-2.708.474-.542.948-1.084 1.286-1.693.406-.745.745-1.557 1.016-2.37.406-1.421.609-2.911.677-4.333Z"
      fill="#004EB6"
    />
    <path
      d="M54.438 13.322c1.083-.609 2.099-1.286 3.182-1.895 1.557-.88 3.724-.745 6.23.88 4.806 2.979 8.937 9.953 9.208 15.37.067 2.64-.61 4.4-2.1 5.349l-3.182 1.895c1.355-.812 2.167-2.708 2.1-5.348-.272-5.485-4.334-12.391-9.21-15.37-2.504-1.625-4.806-1.76-6.228-.88Z"
      fill="#AAD2F6"
    />
    <path
      d="M48.209 47.108c-.339-7.786-6.23-17.536-13-21.734-6.771-4.198-11.985-1.422-11.646 6.297.338 7.719 6.229 17.536 13 21.734 6.77 4.198 12.12 1.354 11.645-6.297Z"
      fill="#71ADEE"
    />
    <path
      d="m26.406 24.088 22.75-14.083c2.1-1.354 5.214-1.016 8.735 1.15 6.77 4.266 12.661 14.016 13 21.803.203 3.724-.88 6.297-2.912 7.516L45.297 54.489c1.963-1.15 3.047-3.792 2.911-7.515-.338-7.72-6.229-17.47-13-21.735-3.52-2.167-6.635-2.505-8.802-1.15Z"
      fill="#FFDC1E"
    />
    <path
      d="M43.063 52.796c0-1.896-.203-3.792-.61-5.62a36.644 36.644 0 0 0-1.828-5.214c-.948-2.099-2.099-4.13-3.453-5.958-1.151-1.557-2.37-3.047-3.792-4.401-3.453-3.25-5.958-4.537-8.057-5.349a12.133 12.133 0 0 0-7.11-.542c-2.234.61-4.13 2.1-5.213 4.13-.677 1.22-1.083 2.438-1.354 3.792-.135.474-.135.948-.203 1.422-.068.88-.136 1.76-.068 2.64.068 1.49.271 2.912.61 4.334a38.332 38.332 0 0 0 1.828 5.349 32.147 32.147 0 0 0 5.146 8.193c2.03 2.437 4.4 4.536 7.109 6.161 3.453 1.964 5.89 2.235 7.583 2.235a8.974 8.974 0 0 0 6.703-2.98 11.912 11.912 0 0 0 1.964-3.791c.542-1.422.745-2.912.745-4.401Z"
      fill="#AAD2F6"
    />
    <path
      d="M43.063 52.797c0-1.896-.204-3.792-.61-5.62a36.653 36.653 0 0 0-1.828-5.214c-.948-2.099-2.099-4.13-3.453-5.958-1.151-1.557-2.37-3.047-3.792-4.401-3.453-3.25-5.958-4.537-8.057-5.35-2.099-.812-4.469-1.015-6.703-.609 1.76-.067 3.52.204 5.146.813 3.047 1.151 5.755 2.98 8.057 5.349 1.422 1.354 2.64 2.844 3.792 4.401 1.354 1.828 2.505 3.86 3.453 5.958a36.645 36.645 0 0 1 1.828 5.214c.474 1.828.677 3.724.61 5.62 0 1.49-.204 2.911-.61 4.333a10.647 10.647 0 0 1-1.964 3.792c-1.15 1.354-2.776 2.37-4.536 2.776 2.302-.136 4.536-1.219 6.026-2.98a11.914 11.914 0 0 0 1.964-3.791c.473-1.422.677-2.844.677-4.334Z"
      fill="#004EB6"
    />
    <path
      opacity=".2"
      d="m51.73 50.63 11.916-7.313.745-2.64-7.583-2.709L51.73 50.63Z"
      fill="#000"
    />
    <path
      d="M59.11 35.53a2.612 2.612 0 0 1 1.895-.338c2.032.474 3.928 1.286 5.688 2.37.812.474 1.625.948 2.37 1.557.677.474 1.286.948 1.895 1.422.61.474 1.151 1.016 1.693 1.557.542.542.813.88 1.084 1.151-.542-.745-1.422-.812-2.506-.27-1.76 1.083-2.843 2.979-2.979 5.078 0 1.286.542 2.099 1.422 2.166-.542-.067-1.083-.203-1.625-.338-.745-.203-1.49-.407-2.167-.745-.744-.27-1.49-.61-2.234-1.016-1.151-.541-1.625-.812-2.505-1.286a30.897 30.897 0 0 1-2.64-1.828 29.798 29.798 0 0 1-2.167-1.964 3.167 3.167 0 0 1-.61-1.896c.203-2.302 1.49-4.333 3.386-5.62Z"
      fill="#AAD2F6"
    />
    <path
      d="M61.208 46.906a30.896 30.896 0 0 1-2.64-1.828 29.822 29.822 0 0 1-2.167-1.964c-.406-.542-.677-1.219-.61-1.896 0-.61.136-1.15.272-1.693 2.64 2.167 7.854 6.162 12.39 7.313-.135.406-.135.812-.203 1.15 0 1.287.542 2.1 1.422 2.168-.542-.068-1.084-.203-1.625-.339-.745-.203-1.49-.406-2.167-.745-.745-.27-1.557-.61-2.234-1.015-.88-.204-1.693-.61-2.438-1.151Z"
      fill="#71ADEE"
    />
    <path
      d="M68.318 45.28c-1.625 3.724-.61 7.651 2.166 8.938 2.777 1.286 6.5-.61 8.193-4.333 1.693-3.724.61-7.651-2.166-8.938-2.776-1.286-6.568.745-8.193 4.334Z"
      fill="#E2F6FF"
    />
    <path
      d="M67.64 47.583c2.438 2.776 6.975 1.693 6.975 1.693l4.536-.339c-.135.406-.27.745-.474 1.084-1.625 3.724-5.349 5.62-8.192 4.333-2.235-1.084-3.318-3.927-2.844-6.771Z"
      fill="#71ADEE"
    />
    <path
      d="M76.578 46.5c.542-.407 1.287-.475 1.896-.068 1.625 1.083 3.25 2.166 4.875 3.182 5.078 2.437 10.766 5.349 13.474 6.703.61.339 3.047 1.557 7.11 3.589 1.083.541 2.505 1.286 3.52 1.828 1.084.541 2.438 1.286 3.521 1.828 1.964 1.083 4.672 2.437 6.771 3.52 4.943 2.641 7.516 3.996 8.938 4.808a335.072 335.072 0 0 1 6.838 3.724c1.016.542 4.063 2.167 4.604 2.505.61.339 1.151.745 1.625 1.151.542.474 1.016 1.084 1.422 1.693.339.542.61 1.15.813 1.828.135.406.203.745.27 1.151v.813c0 .541-.067 1.083-.27 1.625-.136.474-.339.88-.61 1.286-.271.474-.677.88-1.083 1.151-.474.339-.948.61-1.422.88-.474.271-1.083.406-1.625.474-.271 0-.542.068-.745.068h-1.083a12.462 12.462 0 0 1-1.557-.27 7.912 7.912 0 0 1-1.625-.61 18.632 18.632 0 0 1-1.964-1.151c-2.776-1.693-7.042-4.401-7.042-4.401l-5.145-3.25a704.941 704.941 0 0 1-5.891-3.724c-1.896-1.287-4.537-2.912-6.432-4.063-2.506-1.625-8.396-5.416-13.339-8.666-1.963-1.355-4.062-2.709-6.5-4.334-1.828-1.15-4.13-2.776-5.958-4.062-1.693-.88-3.453-1.828-5.214-2.709-.541-.27-.812-.88-.812-1.828.067-1.963 1.015-3.656 2.64-4.672Z"
      fill="#AAD2F6"
    />
    <path
      d="M105.761 72.5c-2.506-1.625-8.396-5.417-13.339-8.667-1.963-1.354-4.062-2.708-6.5-4.333-1.828-1.151-4.13-2.776-5.958-4.063-1.693-.88-3.453-1.828-5.214-2.708-.541-.271-.812-.88-.812-1.828 0-.407.067-.88.203-1.287.135.271.338.474.61.61 1.692.88 3.452 1.828 5.213 2.708 1.828 1.286 4.13 2.776 5.958 4.063 2.438 1.625 4.537 2.979 6.5 4.333 4.943 3.317 10.833 7.041 13.339 8.666 1.895 1.287 4.536 2.912 6.432 4.063 0 0 1.896 1.286 5.891 3.724l5.145 3.25s4.266 2.708 7.042 4.401c0 0 5.958 3.047 11.849.474 0 .068 0 .068-.068.203-.135.474-.338.88-.609 1.287-.271.474-.677.88-1.083 1.15-.474.34-.948.61-1.422.88-.474.272-1.084.407-1.625.475-.271 0-.542.067-.745.067h-1.083a12.51 12.51 0 0 1-1.558-.27 7.956 7.956 0 0 1-1.625-.61 18.617 18.617 0 0 1-1.963-1.15c-2.776-1.694-7.042-4.402-7.042-4.402l-5.146-3.25c-3.995-2.505-5.89-3.724-5.89-3.724-2.032-1.219-4.537-2.844-6.5-4.062Z"
      fill="#71ADEE"
    />
  </svg>
);

export default YourArrangementsIcon;
