import React from "react";

import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";

import { PrimaryButton, TextButton } from "../../ui/Button";

import { BNPL_INTEREST_FORM_ID } from "../../../constants/forms";
import { Body } from "../../ui/Typography";
import { closeModalBNPLExperiment } from "../../../actions/checkout";

const BNPLFormWrapper = ({
  handleSubmit,
  isLoading,
  translations,
  closeModalBNPLExperiment,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" justifyContent="center" pt={2}>
        {translations.body.map((body) => (
          <Body key={body}>{body}</Body>
        ))}
        <Box>
          <PrimaryButton
            fullWidth
            isLoading={isLoading}
            text={translations.button.submit}
          />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" mt={1}>
          <TextButton
            text={translations.button.cancel}
            onClick={closeModalBNPLExperiment}
          />
        </Box>
      </Box>
    </form>
  );
};

const ConnectedBNPLForm = connect(null, {
  closeModalBNPLExperiment,
})(BNPLFormWrapper);

const BNPLForm = ({ ...restOfProps }) => {
  return <ConnectedBNPLForm {...restOfProps} />;
};

export default reduxForm({
  form: BNPL_INTEREST_FORM_ID,
})(BNPLForm);
