import React from "react";
import { reduxForm } from "redux-form";
import { useMediaQuery, useTheme } from "@material-ui/core";

import { Form } from "../../ui/Form";
import { ALLOCATIONS_PATH } from "../../../constants/routes";
import RadioListField from "../../ui/inputs/RadioListField";
import { ErrorMessage } from "../../ui/Error";
import { Body, TextLink } from "../../ui/Typography";
import { useValidatePredeceaseMemo } from "../../ui/inputs/validations";

import {
  PREDECEASE_FORM_ID,
  PREDECEASE_FIELD_ID,
} from "../../../constants/forms";

const PredeceaseForm = ({
  error,
  handleSubmit,
  backLink,
  hasMultipleAllocations,
  translations,
  isLoading,
}) => {
  const {
    singleBeneficiariesInfoP1,
    singleBeneficiariesInfoLinkText,
    singleBeneficiariesInfoP2,
  } = translations;
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Form
      onSubmit={handleSubmit}
      backLink={backLink}
      translations={translations}
      isLoading={isLoading}
      reverseWrap={isTabletDown}
    >
      <RadioListField
        name={PREDECEASE_FIELD_ID}
        label={translations.predeceaseLabel}
        validate={useValidatePredeceaseMemo(translations)}
        optionList={translations.optionList}
      />
      {!hasMultipleAllocations && (
        <Body>
          {singleBeneficiariesInfoP1}{" "}
          <TextLink
            text={singleBeneficiariesInfoLinkText}
            to={ALLOCATIONS_PATH}
          />
          {singleBeneficiariesInfoP2}
        </Body>
      )}
      <ErrorMessage error={error} />
    </Form>
  );
};

export default reduxForm({
  form: PREDECEASE_FORM_ID,
})(PredeceaseForm);
