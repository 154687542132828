import React from "react";

import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { H4, SmallBody } from "../Typography";
import { ButtonLink } from "../Button";
import AssetListCardIcon from "../icons/AssetListCardIcon";

const AssetList = ({ translations, hasAssets, assetListPageUrl }) => {
  const theme = useTheme();
  const isLargeDesktop = useMediaQuery(theme.breakpoints.only("xl"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const iconWidth = isLargeDesktop || isDesktop ? 100 : 72;

  return (
    <Box
      borderRadius="4px"
      borderColor="border.main"
      border={1}
      bgcolor="white"
      p={1.5}
    >
      <Box display="flex">
        <Box flexGrow={1}>
          <Box mb={1}>
            <H4 align="left">{translations.title}</H4>
          </Box>
          <Box mb={2}>
            <SmallBody>{translations.smallBody}</SmallBody>
          </Box>
          <Box>
            <ButtonLink
              className="qa-submit"
              secondary
              displayArrowRight
              to={assetListPageUrl}
              text={
                hasAssets
                  ? translations.button.started
                  : translations.button.unStarted
              }
            />
          </Box>
        </Box>

        <Box flexShrink flexBasis={iconWidth} align="right">
          <Box ml={1} align="right">
            <AssetListCardIcon isDesktop={isDesktop} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AssetList;
