import React from "react";

import { reduxForm } from "redux-form";

import { Form } from "../../ui/Form";
import { useValidateLifeProlongMemo } from "../../ui/inputs/validations";
import BinaryRadioField from "../../ui/inputs/BinaryRadioField";
import {
  LIFE_PROLONG_FORM_ID,
  LIFE_PROLONG_FIELD_ID,
} from "../../../constants/forms";

const LifeProlongForm = ({
  handleSubmit,
  backLink,
  onSkip,
  isLoading,
  translations,
}) => {
  return (
    <Form
      qaFormId="healthcare"
      onSubmit={handleSubmit}
      backLink={backLink}
      onSkip={onSkip}
      isLoading={isLoading}
      translations={translations}
    >
      <BinaryRadioField
        name={LIFE_PROLONG_FIELD_ID}
        validate={useValidateLifeProlongMemo(translations)}
        translations={translations}
      />
    </Form>
  );
};

export default reduxForm({
  form: LIFE_PROLONG_FORM_ID,
})(LifeProlongForm);
