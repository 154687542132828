export const days = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
];

const generateYears = (stopYear) =>
  Array.from(
    Array(new Date().getFullYear() - (stopYear - 1)),
    (_, i) => i + stopYear,
  ).reverse();
export const years = generateYears(1900);
