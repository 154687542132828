import TTNormsRegularWoff2 from "../../../assets/fonts/TT-Norms-Regular-400.woff2";

const TTNorms = {
  fontFamily: "TT Norms",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `url(${TTNormsRegularWoff2}) format('woff2')`,
};

export const animationSpeed = {
  xfast: 120,
  fast: 240,
  moderate: 440,
  slow: 600,
};

export const colorPalette = {
  yellow: "#FFDC1E",
  gray: "#ECEDEF",
  willfulBlue: "#0064DC",
  error: "#FF2222",
  loadingError: "#FF7878",
  willfulLightBlue: "#71ADEE",
  willfulxLightBlue: "#AAD2F6",
  willfulxxLightBlue: "#C6E9F8",
  willfulxxxLightBlue: "#E2F6FF",
  willfulGrey: "#4A4A4A",
  willfulBlueDark2: "#004EB6",
  willfulGrey1: "#70707A",
  willfulGrey2: "#CACDD4",
  willfulYellow: "#FFDC1E",
  willfulGreen: "#008400",
  white: "#FFFFFF",
};

export const media = {
  mobile: "@media (max-width: 767px)",
  tablet: "@media (min-width: 768px)",
  desktop: "@media (min-width: 1024px)",
  largeDesktop: "@media (min-width: 1200px)",
};

export const breakpoints = {
  xs: 0,
  sm: 320,
  md: 768,
  lg: 1024,
  xl: 1376,
};

const muiTheme = {
  breakpoints: {
    values: {
      ...breakpoints,
    },
  },
  palette: {
    primary: {
      main: "#0064DC", // Dark Blue
    },
    secondary: {
      main: "#E2F6FF", // Light Blue
    },
    text: {
      primary: "#4A4A4A", // Text,
      secondary: "#70707A", // Text Light
      textOffWhite: "#CACDD4",
      main: "#0064DC",
      error: "#FF2222",
      loadingError: "#FF7878",
      white: "#FFF",
      yellow: "#FFDC1E",
      willfulBlue: "#0064DC",
      willfulLightBlue: "#71ADEE",
      willfulxLightBlue: "#AAD2F6",
      willfulxxLightBlue: "#C6E9F8",
      willfulxxxLightBlue: "#E2F6FF",
      willfulLightGrey: "#ECEDEF",
      willfulGreen: "#008400",
    },
    border: {
      main: "#CACDD4",
    },
    background: {
      main: "#F6F6F6",
      default: "#F6F6F6",
    },
    yellow: {
      main: "#FFDC1E",
      light: "#FFFCEF",
    },
    gray: {
      main: "#ECEDEF",
    },
    willfulBlue: {
      main: "#0064DC",
    },
    error: {
      main: "#FF2222",
    },
    willfulLightPurple: {
      main: "#FAE6FF",
    },
    willfulLightTeal: {
      main: "#D0FFF0",
    },
    willfulLightBlue: {
      main: "#71ADEE",
    },
    willfulxLightBlue: {
      main: "#AAD2F6",
    },
    willfulxxLightBlue: {
      main: "#C6E9F8",
    },
    willfulxxxLightBlue: {
      main: "#E2F6FF",
    },
    willfulGrey: {
      main: "#4A4A4A",
    },
    willfulBlueDark2: {
      main: "#004EB6",
    },
    willfulGrey2: {
      main: "#CACDD4",
    },
    willfulYellow: {
      main: "#FFDC1E",
    },
    willfulLightGrey: {
      main: "#70707A",
    },
    willfulWhite: {
      main: "#FFF",
    },
    transparent: {
      main: "#00000000",
    },
  },
  spacing: 16,
  typography: {
    fontFamily: "TT Norms",
  },
  props: {
    // Name of the component
    MuiButtonBase: {
      // The default props to change
      disableRipple: true, // MUI ripple effect
    },
    MuiUseMediaQuery: {
      noSsr: true,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [TTNorms],
      },
    },
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        textTransform: "none",
      },
    },
  },
};
export default muiTheme;
