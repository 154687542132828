import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";

import {
  DASHBOARD_PATH,
  DOCUMENTS_PATH,
  YOUR_ACCOUNT_PATH,
  WILLFUL_BILL_21_URL,
} from "../../constants/routes";
import { H1, H3, Body, SmallExternalLink } from "../ui/Typography";
import { ButtonLink } from "../ui/Button";
import PaymentSummary from "./PaymentSummary";
import FlashMessage from "../ui/FlashMessage";
import Bill21Disclaimer from "../documents/Bill21Disclaimer";
import PlanSummary from "../plans/PlanSummary";

const WhiteCard = ({
  children,
  isDesktop,
  includeBottomMargin = true,
  ...restOfProps
}) => (
  <Box
    bgcolor="willfulWhite.main"
    border={1}
    borderRadius={4}
    borderColor="border.main"
    p={isDesktop ? 2 : 1.5}
    mb={includeBottomMargin && 2}
    {...restOfProps}
  >
    {children}
  </Box>
);

const InviteToPlan = ({ translations, isDesktop }) => {
  return (
    <WhiteCard isDesktop={isDesktop} includeBottomMargin={false}>
      <Box mb={1}>
        <H3 align="left">{translations.inviteHeader}</H3>
      </Box>
      <Body>{translations.inviteBody}</Body>
      <Box mt={2}>
        <ButtonLink
          className="qa-submit qa-submit-invite"
          text={translations.button.invitePeople}
          displayArrowRight
          to={YOUR_ACCOUNT_PATH}
        />
      </Box>
    </WhiteCard>
  );
};

const YourDocuments = ({
  translations,
  isNotarialWill,
  isDesktop,
  displayFlashMessage = true,
  flashMessageType = "warning",
  pageWarningContent,
}) => {
  return (
    <WhiteCard isDesktop={isDesktop}>
      <Box mb={1}>
        <H3 align="left">
          {isNotarialWill
            ? translations.viewYourDocumentsHeader
            : translations.downloadYourDocumentsHeader}
        </H3>
      </Box>
      <Body>
        {isNotarialWill
          ? translations.yourDocumentsNotarialBody
          : translations.yourDocumentsBody}
      </Body>
      <Box mt={2}>
        <ButtonLink
          className="qa-submit qa-submit-documents"
          text={translations.button.viewDocuments}
          displayArrowRight
          to={DOCUMENTS_PATH}
        />
      </Box>
      {displayFlashMessage && (
        <Box mt={2}>
          <FlashMessage type={flashMessageType} text={pageWarningContent} />
        </Box>
      )}
    </WhiteCard>
  );
};

const IncompleteFlow = ({
  translations,
  isDesktop,
  displayFlashMessage = true,
  flashMessageType = "warning",
  pageWarningContent,
}) => {
  return (
    <WhiteCard isDesktop={isDesktop}>
      <Box mb={1}>
        <H3 align="left">{translations.incompleteHeader}</H3>
      </Box>
      <Body>{translations.incompleteBody}</Body>
      <Box mt={2}>
        <ButtonLink
          className="qa-submit qa-submit-documents"
          text={translations.button.incomplete}
          displayArrowRight
          to={DASHBOARD_PATH}
        />
      </Box>
      {displayFlashMessage && (
        <Box mt={2}>
          <FlashMessage type={flashMessageType} text={pageWarningContent} />
        </Box>
      )}
    </WhiteCard>
  );
};

const ReceiptInfo = ({
  translations,
  isCompleteAllSections,
  bundlePlanSelected,
  referralProgramProps,
  documentsTranslations,
  isUserFromBC,
  isNotarialWill,
  analyticsClickExternalLink,
  analyticsDownloadNotarizationGuide,
  hasPlan,
  planSummaryProps,
  ...paymentSummary
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const pageWarningContent = isNotarialWill ? (
    documentsTranslations.notarialPageWarning
  ) : (
    <>
      {documentsTranslations.legalDocumentsWarning}
      {isUserFromBC && (
        <>
          {" "}
          <SmallExternalLink
            href={WILLFUL_BILL_21_URL}
            text={documentsTranslations.button.learnMoreExternal}
            onClick={() => analyticsClickExternalLink(WILLFUL_BILL_21_URL)}
          />
        </>
      )}
    </>
  );

  return (
    <Box pb={6}>
      <Grid container justify="center">
        <Grid item sm={12} md={12} lg={12}>
          <Box mt={isDesktop ? 7.5 : 2.5} mb={isDesktop ? 2.5 : 0}>
            <H1 align="left">{translations.pageTitle}</H1>
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent={!isDesktop && "center"} spacing={2}>
        {/* Left hand column */}
        <Grid item sm={12} md={12} lg={7}>
          <Box mt={isDesktop ? 2 : 0}>
            <H3 align="left">{translations.yourPlanCopy}</H3>
            <Body>{translations.thankYou}</Body>
          </Box>
          <Grid>
            <Box mt={2}>
              {isCompleteAllSections ? (
                <YourDocuments
                  translations={translations}
                  isNotarialWill={isNotarialWill}
                  isDesktop={isDesktop}
                  pageWarningContent={pageWarningContent}
                />
              ) : (
                <>
                  <IncompleteFlow
                    translations={translations}
                    isDesktop={isDesktop}
                    pageWarningContent={pageWarningContent}
                  />
                </>
              )}
              {bundlePlanSelected && (
                <InviteToPlan
                  translations={translations}
                  isDesktop={isDesktop}
                />
              )}
            </Box>
          </Grid>
        </Grid>
        {/* Left hand column close */}
        {/* Right hand column */}
        <Grid item justify="flex-end" sm={12} md={12} lg={5}>
          <Box mt={isDesktop ? 2 : 0}>
            {hasPlan && (
              <PlanSummary
                {...planSummaryProps}
                analyticsClickExternalLink={analyticsClickExternalLink}
                analyticsDownloadNotarizationGuide={
                  analyticsDownloadNotarizationGuide
                }
                showPlanStatus={false}
                showCta={false}
              />
            )}
            <Box p={2} bgcolor="gray.main" borderRadius={4}>
              <PaymentSummary
                isReceiptPage
                translations={translations}
                planSummary={planSummaryProps}
                {...paymentSummary}
              />
            </Box>
          </Box>
        </Grid>
        {/* Right hand column close */}
        {isUserFromBC && (
          <Grid item>
            <Bill21Disclaimer
              onExternalLinkClick={analyticsClickExternalLink}
              translations={documentsTranslations}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ReceiptInfo;
