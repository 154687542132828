import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { HashLink } from "react-router-hash-link";
import { withTheme } from "@material-ui/styles";
import { colorPalette, media } from "../../config/Theme";

export const getColor = (props) => {
  return !props.color
    ? props.theme.palette.text.primary
    : props.theme.palette.text[props.color];
};

const StyledH1 = styled.h1`
  font-family: "TT Norms";
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: -0.01em;
  margin-top: 0;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "32px"};
  text-align: ${(props) => props.align};
  color: ${getColor};

  ${media.tablet} {
    font-size: 48px;
  }

  ${media.desktop} {
    font-size: 56px;
  }
`;

const RawH1 = ({
  color = "textPrimary",
  align = "center",
  children,
  component = "h1",
  ...restOfProps
}) => (
  <StyledH1 as={component} align={align} color={color} {...restOfProps}>
    {children}
  </StyledH1>
);

export const H1 = withTheme(RawH1);

const StyledH2 = styled.h2`
  font-family: "TT Norms";
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: ${(props) => props.align};
  color: ${getColor};

  ${media.tablet} {
    font-size: 28px;
  }

  ${media.desktop} {
    font-size: 32px;
  }
`;

const RawH2 = ({
  color = "textPrimary",
  align = "center",
  children,
  component = "h2",
  ...restOfProps
}) => (
  <StyledH2 as={component} align={align} color={color} {...restOfProps}>
    {children}
  </StyledH2>
);

export const H2 = withTheme(RawH2);

const StyledH3 = styled.h3`
  font-family: "TT Norms";
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: ${(props) => props.align};
  color: ${getColor};

  ${media.desktop} {
    font-size: 24px;
  }
`;

const RawH3 = ({
  color = "textPrimary",
  align = "center",
  component = "h3",
  children,
  ...restOfProps
}) => (
  <StyledH3 as={component} align={align} color={color} {...restOfProps}>
    {children}
  </StyledH3>
);

export const H3 = withTheme(RawH3);

const StyledH4 = styled.h4`
  font-family: "TT Norms";
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: ${(props) => props.align};
  color: ${getColor};

  ${media.desktop} {
    font-size: 20px;
  }
`;

const RawH4 = ({
  color = "textPrimary",
  align = "center",
  children,
  component = "h4",
  ...restOfProps
}) => (
  <StyledH4 as={component} align={align} color={color} {...restOfProps}>
    {children}
  </StyledH4>
);

export const H4 = withTheme(RawH4);

const StyledH5 = styled.h5`
  font-family: "TT Norms";
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-align: ${(props) => props.align};
  color: ${getColor};

  ${media.desktop} {
    font-size: 16px;
  }
`;

const RawH5 = ({
  color = "textPrimary",
  align = "center",
  children,
  component = "h5",
  ...restOfProps
}) => (
  <StyledH5 as={component} align={align} color={color} {...restOfProps}>
    {children}
  </StyledH5>
);

export const H5 = withTheme(RawH5);

const StyledLargeCta = styled.span`
  font-family: "TT Norms";
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: ${(props) => props.align};
  color: ${getColor};
`;

const LargeCtaRaw = ({
  color = "textPrimary",
  align = "center",
  children,
  ...restOfProps
}) => (
  <StyledLargeCta align={align} color={color} {...restOfProps}>
    {children}
  </StyledLargeCta>
);

export const LargeCta = withTheme(LargeCtaRaw);

const StyledSectionTitle = styled.h1`
  font-family: "TT Norms";
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-align: ${(props) => props.align};
  color: ${getColor};

  ${media.desktop} {
    font-size: 16px;
  }
`;

const SectionTitleRaw = ({
  color = "textPrimary",
  align = "center",
  component = "h1",
  children,
  ...restOfProps
}) => (
  <StyledSectionTitle
    as={component}
    align={align}
    color={color}
    {...restOfProps}
  >
    {children}
  </StyledSectionTitle>
);

export const SectionTitle = withTheme(SectionTitleRaw);

const StyledSmallSectionTitle = styled.h1`
  font-family: "TT Norms";
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-align: ${(props) => props.align};
  color: ${getColor};
`;

const SmallSectionTitleRaw = ({
  color = "textPrimary",
  align = "center",
  children,
  ...restOfProps
}) => (
  <StyledSmallSectionTitle align={align} color={color} {...restOfProps}>
    {children}
  </StyledSmallSectionTitle>
);

export const SmallSectionTitle = withTheme(SmallSectionTitleRaw);

const StyledPricing = styled.span`
  font-family: "TT Norms";
  font-size: 80px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -3px;
  color: ${getColor};
  text-align: ${(props) => props.align};
`;

const PricingRaw = ({
  align = "center",
  color = "textPrimary",
  children,
  ...restOfProps
}) => (
  <StyledPricing color={color} align={align} {...restOfProps}>
    {children}
  </StyledPricing>
);

export const Pricing = withTheme(PricingRaw);

export const LargeQuote = ({
  variant = "span",
  color = "textPrimary",
  children,
  ...restOfProps
}) => (
  <Box
    component="span"
    fontSize={40}
    lineHeight={1.5}
    letterSpacing={0.3}
    {...restOfProps}
  >
    <Typography variant={variant} color={color}>
      {children}
    </Typography>
  </Box>
);

const StyledLargeBody = styled.p`
  font-family: "TT Norms";
  font-size: 20px;
  font-weight: ${(props) => (props.bold ? "700" : "normal")};
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: ${getColor};
  margin: ${(props) => (props.margin ? props.margin : "0px")};
  margin-bottom: ${(props) => (props.margin ? props.margin : "24px")};
  text-align: ${(props) => props.align};

  ${media.desktop} {
    font-size: 24px;
    margin-bottom: ${(props) => (props.margin ? props.margin : "24px")};
  }
`;

const LargeBodyRaw = ({
  color = "textPrimary",
  align = "center",
  component = "p",
  children,
  ...restOfProps
}) => (
  <StyledLargeBody as={component} align={align} color={color} {...restOfProps}>
    {children}
  </StyledLargeBody>
);

export const LargeBody = withTheme(LargeBodyRaw);

const StyledBody = styled.p`
  font-family: "TT Norms";
  font-size: 16px;
  font-weight: ${(props) => (props.bold ? "700" : "normal")};
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: ${getColor};
  text-align: ${(props) => props.align};
  margin: ${(props) => props.margin || 0};
  margin-bottom: ${(props) => (props.as === "span" ? 0 : "16px")};

  ${media.desktop} {
    font-size: 20px;
  }
`;

export const BodyRaw = ({
  bold,
  color = "textPrimary",
  align = "left",
  margin,
  component = "p",
  children,
  ...restOfProps
}) => {
  let isBold = bold;
  // if bold is not specified, default to false
  if (bold === undefined) {
    isBold = false;
  }
  // unless component is span, then default to true
  if (component === "span" && bold === undefined) {
    isBold = true;
  }
  return (
    <StyledBody
      as={component}
      align={align}
      color={color}
      margin={component === "span" ? "0" : margin}
      bold={isBold}
      {...restOfProps}
    >
      {children}
    </StyledBody>
  );
};

export const Body = withTheme(BodyRaw);

const StyledMiceType = styled.p`
  display: ${(props) => props.display || "inline"};
  font-family: "TT Norms";
  font-size: 12px;
  font-weight: ${(props) => (props.bold ? "700" : "normal")};
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: ${getColor};
  text-align: ${(props) => props.align};
  margin: ${(props) => props.margin};
`;

const MiceTypeRaw = ({
  color = "willfulLightGrey",
  align = "left",
  children,
  ...restOfProps
}) => {
  return (
    <StyledMiceType align={align} color={color} {...restOfProps}>
      {children}
    </StyledMiceType>
  );
};

export const MiceType = withTheme(MiceTypeRaw);

const StyledMiceTypeLink = styled.a`
  font-family: "TT Norms";
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.02em;
  color: ${getColor};
  text-align: ${(props) => props.align};
  margin: ${(props) => props.margin};
  text-decoration: none;
  cursor: pointer;
`;

const MiceTypeLinkRaw = ({
  color = "willfulLightGrey",
  align = "left",
  text,
  url,
  ...restOfProps
}) => {
  return (
    <StyledMiceTypeLink
      href={url}
      target="_blank"
      align={align}
      color={color}
      {...restOfProps}
    >
      {text}
    </StyledMiceTypeLink>
  );
};

export const MiceTypeLink = withTheme(MiceTypeLinkRaw);

const StyledSmallBody = styled.span`
  display: ${(props) => (props.inline ? "inline" : "block")};
  font-family: "TT Norms";
  font-size: 14px;
  font-weight: ${(props) => (props.bold ? "700" : "normal")};
  line-height: 1.5;
  letter-spacing: 0.02em;
  margin: ${(props) => props.margin};
  text-align: ${(props) => props.align};
  color: ${getColor};
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : "initial")};

  ${media.desktop} {
    font-size: 16px;
  }
`;

export const SmallBodyRaw = ({
  bold = false,
  inline = false,
  color = "textPrimary",
  align = "left",
  margin = "0",
  component = "p",
  children,
  ...restOfProps
}) => (
  <StyledSmallBody
    bold={bold}
    inline={inline}
    as={component}
    align={align}
    color={color}
    margin={component === "span" ? "0" : margin}
    {...restOfProps}
  >
    {children}
  </StyledSmallBody>
);

export const SmallBody = withTheme(SmallBodyRaw);

const StyledTextLink = styled(HashLink)`
  letter-spacing: 0.2px;
  text-align: ${(props) => props.align};
  text-decoration: none;
  color: ${(props) => props.color || props.theme.palette.text.main};
  margin-right: 8px;
  transition: color 240ms linear;

  &:visited {
    color: ${(props) => props.color || props.theme.palette.text.main};
  }

  .arrow {
    transition: margin 240ms ease-out;
  }

  .arrow.right {
    margin-left: 8px;
  }

  .arrow.left {
    margin-left: 8px;
    margin-right: 8px;
  }

  &:hover {
    transition: margin 240ms ease-out;
    transition: color 240ms linear;
    color: #004eb6;

    .text {
      text-decoration: ${(props) => (props.$underline ? "underline" : "none")};
    }

    .arrow.right {
      margin-left: 24px;
    }
    .arrow.left {
      margin-left: 0px;
      margin-right: 16px;
    }
  }
`;

const BaseTextLink = ({
  text,
  displayArrowRight = false,
  displayArrowLeft = false,
  underline = true,
  ...otherProps
}) => {
  return (
    <StyledTextLink $underline={underline} {...otherProps}>
      {displayArrowLeft && (
        <Body component="span" className="arrow left">
          ←
        </Body>
      )}
      <Body component="span" className="text">
        {text}
      </Body>
      {displayArrowRight && (
        <Body component="span" className="arrow right">
          →
        </Body>
      )}
    </StyledTextLink>
  );
};

export const TextLink = withTheme(BaseTextLink);

const StyledSmallTextLink = styled(HashLink)`
  text-align: ${(props) => props.align};
  text-decoration: none;
  margin: ${(props) => props.margin};
  color: ${(props) => props.color || props.theme.palette.text.main};
  transition: color 240ms linear;

  &:visited {
    color: ${(props) => props.color || props.theme.palette.text.main};
  }

  .arrow {
    transition: margin 240ms ease-out;
  }
  .arrow.right {
    margin-left: 8px;
  }
  .arrow.left {
    margin-left: 8px;
    margin-right: 8px;
  }

  &:hover {
    transition: margin 240ms ease-out;
    transition: color 240ms linear;
    color: #004eb6;

    .text {
      text-decoration: ${(props) => (props.$underline ? "underline" : "none")};
    }
    .arrow.right {
      margin-left: 24px;
    }
    .arrow.left {
      margin-left: 0px;
      margin-right: 16px;
    }
  }
`;

const BaseSmallTextLink = ({
  text,
  displayArrowRight = false,
  displayArrowLeft = false,
  underline = true,
  margin,
  ...otherProps
}) => {
  return (
    <StyledSmallTextLink margin={margin} $underline={underline} {...otherProps}>
      {displayArrowLeft && (
        <SmallBody component="span" inline bold className="arrow left">
          ←
        </SmallBody>
      )}
      <SmallBody component="span" inline bold className="text">
        {text}
      </SmallBody>
      {displayArrowRight && (
        <SmallBody component="span" inline bold className="arrow right">
          →
        </SmallBody>
      )}
    </StyledSmallTextLink>
  );
};

export const SmallTextLink = withTheme(BaseSmallTextLink);

const StyledAnchorLink = styled.a`
  text-align: ${(props) => props.align};
  text-decoration: none;
  color: ${(props) => props.color || props.theme.palette.text.main};
  transition: color 240ms linear;

  &:visited {
    color: ${(props) => props.color || props.theme.palette.text.main};
  }

  .arrow {
    transition: margin 240ms ease-out;
  }

  .arrow.right {
    margin-left: 8px;
  }
  .arrow.left {
    margin-right: 8px;
  }

  &:hover {
    .text {
      text-decoration: ${(props) => (props.underline ? "underline" : "none")};
    }

    .arrow {
      transition: margin 240ms ease-out;
      transition: color 240ms linear;
      color: #004eb6;
    }
    .arrow.right {
      margin-left: 24px;
    }

    .arrow.left {
      margin-right: 24px;
    }
  }
`;

const BaseAnchorLink = ({
  text,
  displayArrowRight = false,
  displayArrowLeft = false,
  underline = true,
  openNewPage = true,
  ...otherProps
}) => {
  return (
    <StyledAnchorLink
      target={openNewPage ? "_blank" : "_self"}
      underline={underline}
      {...otherProps}
    >
      {displayArrowLeft && (
        <Body component="span" className="arrow left">
          ←
        </Body>
      )}
      <Body component="span" className="text">
        {text}
      </Body>
      {displayArrowRight && (
        <Body component="span" className="arrow right">
          →
        </Body>
      )}
    </StyledAnchorLink>
  );
};

export const ExternalLink = withTheme(BaseAnchorLink);

const StyledSmallAnchorLink = styled.a`
  font-family: "TT Norms";
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.2px;
  text-align: ${(props) => props.align};
  text-decoration: none;
  color: ${(props) => props.color || props.theme.palette.text.main};
  &:visited {
    color: ${(props) => props.color || props.theme.palette.text.main};
  }

  &:hover {
    text-decoration: underline;
    color: ${(props) => props.hoverColor || props.color};
  }
`;

const BaseSmallAnchorLink = ({
  text,
  underline = true,
  targetBlank = true,
  hoverColor,
  ...otherProps
}) => {
  return (
    <StyledSmallAnchorLink
      target={targetBlank ? "_blank" : ""}
      underline={underline}
      $hoverColor={hoverColor}
      {...otherProps}
    >
      <SmallBody component="span" inline bold className="text">
        {text}
      </SmallBody>
    </StyledSmallAnchorLink>
  );
};

export const SmallExternalLink = withTheme(BaseSmallAnchorLink);

export const BaseLink = styled(HashLink)`
  cursor: pointer;
  outline: none;
  color: ${(props) => props.color || "#fafafa"};
  text-decoration: none;
  background: none;

  &:visited,
  &:active {
    color: ${(props) => props.color || "#fafafa"};
  }
`;

const StyledStrikethroughParagraph = styled.p`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid ${colorPalette.willfulGrey2};
  line-height: 0.1em;
  margin: 16px 0 20px;

  span {
    background: ${colorPalette.white};
    padding: 0 16px;
  }

  ${media.desktop} {
    span {
      background: ${colorPalette.white};
      padding: 0 16px;
    }
  }
`;

const StyledStrikethroughParagraphAltBackgrounds = styled.p`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid ${colorPalette.willfulGrey2};
  line-height: 0.1em;
  margin: 16px 0 20px;

  span {
    background: ${colorPalette.willfulxxxLightBlue};
    padding: 0 16px;
  }

  ${media.desktop} {
    span {
      background: ${colorPalette.white};
      padding: 0 16px;
    }
  }
`;

export const StrikethroughParagraph = ({ text, sameBackground = true }) => {
  if (sameBackground) {
    return (
      <StyledStrikethroughParagraph>
        <span>{text}</span>
      </StyledStrikethroughParagraph>
    );
  }
  return (
    <StyledStrikethroughParagraphAltBackgrounds>
      <span>{text}</span>
    </StyledStrikethroughParagraphAltBackgrounds>
  );
};
