import React from "react";
import { reduxForm, Field } from "redux-form";
import Box from "@material-ui/core/Box";

import { ErrorMessage } from "../../ui/Error";
import { FormButtons } from "../../ui/Form";
import BackupField from "../../ui/inputs/BackupField";
import { BACKUPS_FORM_ID, BACKUPS_FIELD_ID } from "../../../constants/forms";
import { useValidateAllocationBackupsMemo } from "../../ui/inputs/validations";

const Backup = ({
  error,
  handleSubmit,
  backLink,
  charities,
  removeBackup,
  onSkip,
  backupBeneficiaryName,
  translations,
  isLoading,
}) => {
  return (
    <form onSubmit={handleSubmit} className="mt-3">
      <Box mb={2}>
        <Field
          component={BackupField}
          translations={translations}
          name={BACKUPS_FIELD_ID}
          backupBeneficiaryName={backupBeneficiaryName}
          removeBackup={removeBackup}
          validate={useValidateAllocationBackupsMemo(translations)}
          charities={charities}
        />
        {error && <ErrorMessage error={error} />}
      </Box>
      <FormButtons
        qaFormId="backup"
        isLoading={isLoading}
        onSkip={onSkip}
        backLink={backLink}
        translations={translations}
      />
    </form>
  );
};

export default reduxForm({
  form: BACKUPS_FORM_ID,
  destroyOnUnmount: false, // required to preserve redux form state when adding beneficiary
})(Backup);
