import { keyframes, css } from "styled-components";

const scaleInAnimation = keyframes`
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const scaleOutAnimation = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.8);
    opacity: 0;
  }
`;

export const MENU_CLOSING_SPEED = 120; // ms

export const menuToggleAnimationStyles = css`
  transition: ${(props) =>
    props.isClosing
      ? `transform ${MENU_CLOSING_SPEED}ms ease-out`
      : "transform 240ms ease-out"};
  animation-name: ${(props) =>
    props.isClosing ? scaleOutAnimation : scaleInAnimation};
  animation-duration: ${(props) =>
    props.isClosing ? `${MENU_CLOSING_SPEED}ms` : "240ms"};
  transform-origin: ${(props) => `top ${props.alignment}`};
`;
