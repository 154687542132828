import { eventChannel } from "redux-saga";
import { select, takeEvery, put, call, take } from "redux-saga/effects";
import {
  updateExperiments,
  INITIALIZE_EXPERIMENTS_TYPE,
} from "../actions/experiments";
import { isVWOEnabled } from "../utilities/analytics";
import { selectHasUserPaid, selectPlanName } from "../selectors/plans";
import { selectIsInvitedUser } from "../selectors/invite";
import { UPDATE_STATUS_TYPE } from "../actions/status";
import { selectPartnerCampaignId } from "../selectors/partner-discount";
import { selectReasons } from "../selectors/welcome";
import { selectProvinceCode } from "../selectors/provinces";

function createVWOExperimentChannel() {
  return eventChannel((emit) => {
    window.VWO = window.VWO || [];
    window.VWO.push([
      "onEventReceive",
      "vA",
      (data) => {
        const experimentId = data[1]; // To fetch A/B test active variation name
        const variationId = data[2]; // To get A/B test name
        const experimentName = window._vwo_exp[experimentId].name; // To get A/B test active variation name
        const variationName = window._vwo_exp[experimentId].comb_n[variationId];
        if (
          typeof window._vwo_exp[experimentId].comb_n[variationId] !==
            "undefined" &&
          ["VISUAL_AB", "VISUAL", "SPLIT_URL", "SURVEY"].indexOf(
            window._vwo_exp[experimentId].type,
          ) > -1
        ) {
          if (window.env.FE_ENV !== "production") {
            // leave this console to make our QA team's lives a bit easier
            console.info(`
            Experiment - ${experimentId}: ${experimentName}
            Variation - ${variationId}: ${variationName}
            `);
          }
          const experimentVariation = { experimentId, variationId };
          emit(experimentVariation);
        }
      },
    ]);

    // Return a function that will be called when the channel is closed
    return () => {
      // Clean up code here if needed
    };
  });
}

function* initializeExperiments() {
  if (!isVWOEnabled) {
    console.error("VWO is not enabled.");
    return yield;
  }

  const eventChannelInstance = yield call(createVWOExperimentChannel);

  try {
    while (true) {
      const experimentVariation = yield take(eventChannelInstance);
      yield put(updateExperiments(experimentVariation));
    }
  } finally {
    // Close the channel if necessary (e.g., when the saga is canceled)
    eventChannelInstance.close();
  }
}

function* updateStatusInExperiments() {
  if (window.env.SHARE_DATA_WITH_VWO === "enabled") {
    const provinceCode = yield select(selectProvinceCode);
    const sharedData = {
      paid_user: yield select(selectHasUserPaid),
      invited_user: yield select(selectIsInvitedUser),
      partner_campaign_id: yield select(selectPartnerCampaignId),
      reasons: yield select(selectReasons),
      province: provinceCode || null,
      plan: yield select(selectPlanName),
    };
    window._vwo_shared_data = sharedData;
  }
  return yield;
}

export function* watchInitializeExperiments() {
  yield takeEvery(INITIALIZE_EXPERIMENTS_TYPE, initializeExperiments);
}

export function* watchUpdateStatusInExperiments() {
  yield takeEvery(UPDATE_STATUS_TYPE, updateStatusInExperiments);
}
