import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import GiftsForm from "../../../components/forms/your-estate/GiftsForm";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { giftsPageProps } from "../../../selectors/gifts";
import { DASHBOARD_PATH } from "../../../constants/routes";
import { GIFTS_FORM_ID } from "../../../constants/forms";
import { getFormData } from "../../../actions/forms";
import {
  updateGifts,
  updateGiftsWithContactsAction,
} from "../../../actions/gifts";
import { skipSection } from "../../../actions/navigation";
import { skipGifts } from "../../../api/gifts";

const GiftsPage = ({
  getFormData,
  updateGifts,
  skipSection,
  hasLoaded,
  ...restOfProps
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    getFormData(GIFTS_FORM_ID);
  }, []);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <GiftsForm
      backLink={DASHBOARD_PATH}
      onSubmit={({ gifts }) => {
        dispatch(updateGiftsWithContactsAction(gifts));
      }}
      onSkip={() => skipSection(skipGifts, GIFTS_FORM_ID)}
      {...restOfProps}
    />
  );
};

export default connect(giftsPageProps, {
  getFormData,
  updateGifts,
  skipSection,
})(GiftsPage);
