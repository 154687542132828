import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { getFormValues } from "redux-form";
import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";

import {
  PERSONAL_ATTORNEYS_FORM_ID,
  PERSONAL_ATTORNEYS_FIELD_ID,
  SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID,
} from "../constants/forms";

import { selectTranslations } from "./translations";
import { selectAppointeeLevelIndexs } from "./utils/form-appointees";
import { selectHasSpouse } from "./spouse";
import { selectIsFieldComplete } from "./utils/progress";
import { selectPersonalAttorneysTranslations } from "./personal-attorneys";

const selectSpouseArrangementsData = R.pathOr({}, ["spouseArrangements"]);

export const selectFormSpouseIsPersonalAttorney = createSelector(
  getFormValues(PERSONAL_ATTORNEYS_FORM_ID),
  (formValues) =>
    R.propOr(false, SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID)(formValues),
);

export const selectIsSpousePersonalAttorneysComplete = createSelector(
  selectIsFieldComplete(
    selectSpouseArrangementsData,
    PERSONAL_ATTORNEYS_FIELD_ID,
  ),
  selectIsFieldComplete(
    selectSpouseArrangementsData,
    SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID,
    true,
  ),
  (personalAttorneyIsComplete, spouseIsPAIsComplete) =>
    spouseIsPAIsComplete || personalAttorneyIsComplete,
);

const selectShouldShowCTAButton = createSelector(
  selectFormSpouseIsPersonalAttorney,
  selectHasSpouse,
  (isSpousePersonalAttorney, hasSpouse) => {
    return !hasSpouse || isSpousePersonalAttorney !== "";
  },
);

export const spousePersonalAttorneysPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(PERSONAL_ATTORNEYS_FORM_ID),
  isLoading: selectIsLoadingById(PERSONAL_ATTORNEYS_FORM_ID),
  appointeeLevelIndexs: selectAppointeeLevelIndexs(PERSONAL_ATTORNEYS_FORM_ID),
  spouseIsPersonalAttorney: selectFormSpouseIsPersonalAttorney,
  hasSpouse: selectHasSpouse,
  translations: createSelector(
    selectPersonalAttorneysTranslations,
    selectTranslations(["global", "spouseArrangements"]),
    (translations, { sectionTitle }) => ({ ...translations, sectionTitle }),
  ),
  shouldShowCTAButton: selectShouldShowCTAButton,
});
