import React, { useEffect } from "react";
import { connect } from "react-redux";

import Box from "@material-ui/core/Box";

import { useMediaQuery } from "@material-ui/core";

import { fetchDocuments, downloadDocuments } from "../../actions/documents";
import {
  analyticsDownloadNotarizationGuide,
  analyticsClickExternalLink,
} from "../../actions/analytics";
import { upgradePlan } from "../../actions/plans";
import { documentsPageProps } from "../../selectors/documents";

import LoadingSpinner from "../../components/ui/LoadingSpinner";
import DocumentsHeaderSection from "../../components/documents/DocumentsHeader";
import LegalDocumentsSection from "../../components/documents/LegalDocumentsSection";
import AssetListSection from "../../components/documents/AssetListSection";
import NotarialWillSection from "../../components/documents/NotarialWillSection";
import Bill21Disclaimer from "../../components/documents/Bill21Disclaimer";
import WillRegistryNotice from "../../components/documents/WillRegistryNotice";
import { breakpoints } from "../../config/Theme";

const DocumentsPage = ({
  fetchDocuments,
  downloadDocuments,
  analyticsDownloadNotarizationGuide,
  upgradePlan,
  analyticsClickExternalLink,
  headerProps,
  legalDocumentsProps,
  assetListProps,
  notarialWillProps,
  hasLoaded,
  translations,
  isCouplesPlan,
  shouldDisplayNoticeConnect,
  shouldDisplayCWRCodes,
  shouldShowBill21Content,
  isCompleteAllSections,
  isPaid,
}) => {
  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const isTablet = useMediaQuery(
    `@media (min-width:${breakpoints.md}px) and (max-width:${
      breakpoints.lg - 1
    }px)`,
  );
  if (!hasLoaded) return <LoadingSpinner />;
  const shouldDisplayCWRCouponCode =
    shouldDisplayNoticeConnect || shouldDisplayCWRCodes;

  return (
    <Box mt={isTablet ? 4.5 : 7.5} mb={10}>
      <DocumentsHeaderSection isTablet={isTablet} {...headerProps} />

      <LegalDocumentsSection
        upgradePlan={upgradePlan}
        analyticsClickExternalLink={analyticsClickExternalLink}
        downloadDocuments={downloadDocuments}
        isTablet={isTablet}
        {...legalDocumentsProps}
      />

      <AssetListSection
        upgradePlan={upgradePlan}
        downloadDocuments={downloadDocuments}
        isTablet={isTablet}
        {...assetListProps}
      />

      {shouldDisplayCWRCouponCode && (
        <WillRegistryNotice
          isCouplesPlan={isCouplesPlan}
          isTablet={isTablet}
          translations={translations}
          isCompleteAllSections={isCompleteAllSections}
          isPaid={isPaid}
        />
      )}

      <NotarialWillSection
        analyticsDownloadNotarizationGuide={analyticsDownloadNotarizationGuide}
        isTablet={isTablet}
        {...notarialWillProps}
      />

      {shouldShowBill21Content && (
        <Bill21Disclaimer
          onExternalLinkClick={analyticsClickExternalLink}
          isTablet={isTablet}
          translations={translations}
        />
      )}
    </Box>
  );
};

export default connect(documentsPageProps, {
  fetchDocuments,
  downloadDocuments,
  analyticsDownloadNotarizationGuide,
  upgradePlan,
  analyticsClickExternalLink,
})(DocumentsPage);
