export const getFormData = (formId) => ({
  type: GET_FORM_DATA_TYPE,
  payload: { formId },
});

export const getSectionData = (sectionKey) => ({
  type: GET_SECTION_DATA_TYPE,
  payload: { sectionKey },
});

export const GET_FORM_DATA_TYPE = "GET_FORM_DATA";
export const GET_SECTION_DATA_TYPE = "GET_SECTION_DATA";
