import React from "react";

import SvgIcon from "./SvgIcon";

const ConfettiStatic = ({ width = 1214, height = 155 }) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 1214 155"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m179.202 8.136-1.827.377a12.331 12.331 0 0 1 0-4.996l1.827.374c-.29 1.4-.29 2.845 0 4.245ZM415.949 11.705l-1.827.378a12.346 12.346 0 0 1 0-5l1.827.378c-.29 1.4-.29 2.844 0 4.244ZM653.132 21.002l-1.826.377a12.346 12.346 0 0 1 0-5l1.826.378a10.5 10.5 0 0 0 0 4.245ZM790.197 16.554l-1.827.374a12.331 12.331 0 0 1 0-4.996l1.827.374a10.514 10.514 0 0 0 0 4.248ZM666.99 6.582l-1.827.374a12.331 12.331 0 0 1 0-4.997l1.827.378a10.499 10.499 0 0 0 0 4.245ZM921.841 9.246l-1.826.374a12.331 12.331 0 0 1 0-4.996l1.826.374a10.514 10.514 0 0 0 0 4.248ZM1056.17 7.175l2.13-2.674a22.645 22.645 0 0 1 5.89 7.07l-3.02 1.609a19.288 19.288 0 0 0-5-6.005ZM1090.09 17.524l-3.09-.913c.82-2.8 2.2-5.404 4.07-7.65l2.47 2.06a18.113 18.113 0 0 0-3.45 6.503ZM238.643 117.628l-4.643-.85A31.293 31.293 0 0 1 238.768 105l3.924 2.622a26.608 26.608 0 0 0-4.049 10.006ZM255.257 87.222l.673-.84a7.157 7.157 0 0 1 1.854 2.234l-.953.506a6.118 6.118 0 0 0-1.574-1.9ZM154.548 127.865l-3.166-1.546a23.355 23.355 0 0 1 5.809-7.5l2.29 2.679a19.855 19.855 0 0 0-4.933 6.367ZM658.313 101.846c-.402.646-.804 1.288-1.203 1.934a15.082 15.082 0 0 1-4.436-4.224l1.872-1.297a12.795 12.795 0 0 0 3.767 3.587ZM797.357 48.498l-1.772 5.675a39.526 39.526 0 0 1-14.005-7.77l3.882-4.507a33.573 33.573 0 0 0 11.895 6.602ZM1011.51 95.305l-10.25 4.955a75.527 75.527 0 0 1-7.46-29.725l11.38-.475a64.15 64.15 0 0 0 6.33 25.245ZM945.457 145.547l1.748.475a12 12 0 0 1-2.201 4.345l-1.416-1.127a10.232 10.232 0 0 0 1.869-3.693ZM1093.35 74.08l-1.2 3.2a22.671 22.671 0 0 1-7.78-4.903l2.37-2.463a19.148 19.148 0 0 0 6.61 4.166Z"
      fill="#FFDC1E"
    />
    <path
      d="M828.748 80.63 825 78.977A27.177 27.177 0 0 1 831.394 70l2.785 3.005a23.048 23.048 0 0 0-5.431 7.625Z"
      fill="#71ADEE"
    />
    <path
      d="m1131.87 134.528-3.35-.71c.64-3.043 1.9-5.923 3.7-8.462l2.8 1.983a19.374 19.374 0 0 0-3.15 7.189Z"
      fill="#FFDC1E"
    />
    <path
      d="m130.134 103.335-1.562 2.832a21.524 21.524 0 0 1-6.613-5.657l2.558-1.98a18.274 18.274 0 0 0 5.617 4.805Z"
      fill="#71ADEE"
    />
    <path
      d="m104.703 130.096-1.869 4.12A30.043 30.043 0 0 1 93 127.045L96.35 124a25.463 25.463 0 0 0 8.353 6.096ZM81.52 32.083l1.522 2.987a22.248 22.248 0 0 1-8.698 2.381l-.21-3.35a18.876 18.876 0 0 0 7.386-2.018ZM116.317 3.116l2.563.146a17.01 17.01 0 0 1-1.784 6.678l-2.292-1.155a14.48 14.48 0 0 0 1.513-5.67ZM442.785 8.968c.073 1.34.144 2.68.213 4.022a26.766 26.766 0 0 1-10.714-1.635l1.401-3.776a22.767 22.767 0 0 0 9.1 1.389ZM917.035 43.514l-2.494-1.477a19.248 19.248 0 0 1 5.252-5.767l1.702 2.348a16.31 16.31 0 0 0-4.46 4.896ZM1022.18 31.593c-.3 1.37-.6 2.741-.91 4.114a27.91 27.91 0 0 1-10.35-4.638l2.47-3.416a23.71 23.71 0 0 0 8.79 3.94ZM986.061 35.551h-4.427a29.349 29.349 0 0 1 2.378-11.68l4.071 1.747a24.973 24.973 0 0 0-2.022 9.933ZM898.214 109.297l-1.297-.767a10.04 10.04 0 0 1 2.74-3.002c.299.406.595.812.889 1.218a8.485 8.485 0 0 0-2.332 2.551Z"
      fill="#FFDC1E"
    />
    <path
      d="m538.587 54.214-1.826.377a12.346 12.346 0 0 1 0-5l1.826.378a10.5 10.5 0 0 0 0 4.245ZM597.531 82.459l3.915 1.623a28.13 28.13 0 0 1-6.412 9.44l-2.95-3.046c2.344-2.272 4.198-5 5.447-8.017ZM854.384 24.71l-3.78 1.919a28.139 28.139 0 0 1-2.998-11.01l4.232-.26a23.875 23.875 0 0 0 2.546 9.352ZM791.473 90.458l-1.827.377a12.346 12.346 0 0 1 0-5l1.827.378a10.5 10.5 0 0 0 0 4.245ZM687.945 78.16l-1.827.378a12.346 12.346 0 0 1 0-5l1.827.378a10.393 10.393 0 0 0 0 4.244Z"
      fill="#E39F39"
    />
    <path
      d="m219.368 27.777-1.826 2.549a20.847 20.847 0 0 1-5.709-6.227l2.695-1.608a17.718 17.718 0 0 0 4.84 5.286ZM162.255 101.061l-4.04-.231a26.923 26.923 0 0 1 2.813-10.524l3.617 1.827a22.837 22.837 0 0 0-2.39 8.928ZM159.568 41.114c-.226.914-.454 1.854-.682 2.78a18.957 18.957 0 0 1-6.957-3.319l1.729-2.28a16.162 16.162 0 0 0 5.91 2.82ZM47.363 25.876l-1.282 2.193A16.816 16.816 0 0 1 41 23.5L43.049 22a14.37 14.37 0 0 0 4.314 3.876Z"
      fill="#0064DC"
    />
    <path
      d="m426.945 77.115.914 3.045a20.945 20.945 0 0 1-8.489.73l.375-3.142c2.418.283 4.868.068 7.2-.633Z"
      fill="#004EB6"
    />
    <path
      d="m490.522 6.873 1.715 1.489a15.066 15.066 0 0 1-4.872 3.703l-.983-2.046a12.758 12.758 0 0 0 4.14-3.146ZM649.106 36.072l-1.72-.052c.045-1.562.41-3.097 1.071-4.513l1.562.731a9.711 9.711 0 0 0-.913 3.834ZM897.457 10.01l-.174-.134c.12-.156.27-.286.441-.383.037.064.073.127.107.191a1.213 1.213 0 0 0-.374.326ZM728.613 53.702l.682.798a7.041 7.041 0 0 1-2.475 1.364l-.304-1.005a5.934 5.934 0 0 0 2.097-1.157ZM682.865 85.065c0 .037.028.073.04.11a.805.805 0 0 1-.304.049v-.119c.09.006.18-.008.264-.04ZM982.753 60.705l-.234.304a2.457 2.457 0 0 1-.645-.77l.329-.176c.138.249.325.467.55.642Z"
      fill="#0064DC"
    />
    <path
      d="m160.318 26.818-4.567 1.65a32.22 32.22 0 0 1-1.86-12.92l4.841.305a27.351 27.351 0 0 0 1.586 10.965Z"
      fill="#004EB6"
    />
    <path
      d="m102.062 34.744-4.676 1.297a32.221 32.221 0 0 1-.865-13.038l4.81.667a27.417 27.417 0 0 0 .731 11.074ZM130.7 13.84c-.824 1.394-1.647 2.789-2.469 4.183a32.275 32.275 0 0 1-9.679-8.779l3.93-2.859a27.337 27.337 0 0 0 8.218 7.454ZM53.543 78.671l-3.254 2.908a28.995 28.995 0 0 1-5.886-10.161l4.141-1.377a24.607 24.607 0 0 0 5 8.63ZM332.466 47.94l-8.96-3.593a64.052 64.052 0 0 1 14.358-21.656l6.796 6.858a54.375 54.375 0 0 0-12.194 18.391ZM287.305 13.204l1.029 3.102a21.73 21.73 0 0 1-8.735 1.02c.097-1.084.191-2.17.286-3.255a18.408 18.408 0 0 0 7.42-.867ZM263.836 70.961a2432.63 2432.63 0 0 0 3.611 3.533 33.543 33.543 0 0 1-11.266 7.585 3622.35 3622.35 0 0 1-1.911-4.674 28.45 28.45 0 0 0 9.566-6.444ZM656.593 4.678l1.428-4.013a28.275 28.275 0 0 1 9.791 5.956l-2.904 3.112a23.993 23.993 0 0 0-8.315-5.055ZM700.302 10.378l3.282-2.132a25.995 25.995 0 0 1 3.796 9.83l-3.854.642a21.977 21.977 0 0 0-3.224-8.34ZM1109 86.132l3.28-2.132a26.035 26.035 0 0 1 3.8 9.83l-3.86.642a22.02 22.02 0 0 0-3.22-8.34ZM921.834 29.988l-.844-3.995a27.093 27.093 0 0 1 11-.057c-.272 1.333-.54 2.669-.806 4.007a22.996 22.996 0 0 0-9.35.045ZM874.229 63.062l6.201-2.235a43.778 43.778 0 0 1 2.5 17.57l-6.579-.415a37.198 37.198 0 0 0-2.122-14.92ZM541 26.235 547.202 24a43.777 43.777 0 0 1 2.5 17.57l-6.58-.415A37.164 37.164 0 0 0 541 26.235Z"
      fill="#0064DC"
    />
    <path
      d="m454.665 42.154 4.511 4.808a43.774 43.774 0 0 1-15.139 9.26l-2.228-6.204a37.184 37.184 0 0 0 12.856-7.864Z"
      fill="#FFDC1E"
    />
    <path
      d="M983.706 89.35c.238-1.296.473-2.596.706-3.897a26.239 26.239 0 0 1 9.892 3.992l-2.198 3.298a22.336 22.336 0 0 0-8.4-3.392Z"
      fill="#0064DC"
    />
    <path
      d="m1032.48 83.976 5.87-1.69a40.46 40.46 0 0 1 1.24 16.415l-6.06-.785c.6-4.67.25-9.415-1.05-13.94Z"
      fill="#004EB6"
    />
    <path
      d="m991.251 69.84-2.591 2.314a23.014 23.014 0 0 1-4.68-8.1l3.295-1.093a19.565 19.565 0 0 0 3.976 6.879ZM1081.18 133.021l-4.72.913c-.84-4.281-.8-8.688.13-12.95l4.7 1.023a27.063 27.063 0 0 0-.11 11.014ZM745.316 73.303l-1.912-3.157a24.504 24.504 0 0 1 9.365-3.316l.503 3.654a20.843 20.843 0 0 0-7.956 2.82ZM515.378 53.615l3.337-2.933a29.495 29.495 0 0 1 5.905 10.396l-4.228 1.366a25.04 25.04 0 0 0-5.014-8.83Z"
      fill="#0064DC"
    />
    <path
      d="m556.09 58.684-3.172-3.109a29.447 29.447 0 0 1 9.938-6.659c.556 1.37 1.114 2.742 1.674 4.114a25.058 25.058 0 0 0-8.44 5.654Z"
      fill="#FFDC1E"
    />
    <path
      d="m444.938 101.013-.42-4.418c4.005-.383 8.046.06 11.874 1.3-.457 1.407-.913 2.813-1.367 4.22a24.985 24.985 0 0 0-10.087-1.102Z"
      fill="#0064DC"
    />
    <path
      d="m364.597 48.148-3.513 3.021a30.734 30.734 0 0 1-6.041-10.9l4.421-1.38a26.048 26.048 0 0 0 5.133 9.26ZM374.305 82.29l-2.554-2.76a24.973 24.973 0 0 1 8.659-5.225l1.248 3.548a21.128 21.128 0 0 0-7.353 4.436Z"
      fill="#003A80"
    />
    <path
      d="m176.787 78.297-2.04-2.007a18.978 18.978 0 0 1 6.394-4.263c.359.883.717 1.767 1.074 2.653a16.139 16.139 0 0 0-5.428 3.617Z"
      fill="#0064DC"
    />
    <path
      d="m71.848 105.144-2.74-1.559a20.911 20.911 0 0 1 5.599-6.361l1.893 2.515a17.739 17.739 0 0 0-4.752 5.405Z"
      fill="#003A80"
    />
    <path
      d="m1017.2 143.285.55-.368c.33.495.56 1.054.67 1.641l-.65.122a3.73 3.73 0 0 0-.57-1.395ZM925.083 105.582v-1.476a9.789 9.789 0 0 1 3.897.782c-.192.454-.383.914-.578 1.358a8.373 8.373 0 0 0-3.319-.664ZM927.026 75.036l-.987.191a6.71 6.71 0 0 1 .03-2.704l.984.217a5.603 5.603 0 0 0-.027 2.296Z"
      fill="#FFF2AD"
    />
    <path
      d="m975.335 64.287-.932-5.95a39.967 39.967 0 0 1 16.186.791l-1.523 5.829a33.919 33.919 0 0 0-13.731-.67Z"
      fill="#AAD2F6"
    />
    <path
      d="m936.966 125.631-1.051.441a7.57 7.57 0 0 1-.587-3.011h1.141a6.407 6.407 0 0 0 .497 2.57ZM782.736 95.754l-.557 1.33a9.565 9.565 0 0 1-3.203-2.195l1.05-.992a8.163 8.163 0 0 0 2.71 1.857ZM616.036 48.83l.609 1.09a8.327 8.327 0 0 1-3.203 1.005c-.042-.414-.082-.828-.125-1.242a6.998 6.998 0 0 0 2.719-.853ZM273.59 54.455l-1.176.325a8.16 8.16 0 0 1-.213-3.276l1.218.17a6.846 6.846 0 0 0 .171 2.78ZM650.604 48.435l-.152.121a1.324 1.324 0 0 1-.24-.465l.186-.052c.042.144.112.279.206.396ZM526.985 21.574l.667.691a6.44 6.44 0 0 1-2.189 1.38l-.335-.914a5.436 5.436 0 0 0 1.857-1.157ZM500.583 58.011l-1.172 1.014a10.21 10.21 0 0 1-2.028-3.654l1.476-.463a8.708 8.708 0 0 0 1.724 3.103ZM714.545 74.78l-1.406-.914a11.144 11.144 0 0 1 3.212-3.206l.913 1.41a9.566 9.566 0 0 0-2.719 2.71ZM336.27 73.725l-.39.152a2.81 2.81 0 0 1-.191-1.11h.42c-.014.327.041.653.161.958ZM199.338 40.125l-1.057.45a7.656 7.656 0 0 1-.609-3.044h1.151c-.008.89.168 1.773.515 2.594ZM129.165 27.893l-.639 1.523a10.89 10.89 0 0 1-3.654-2.518l1.194-1.14a9.28 9.28 0 0 0 3.099 2.135ZM167.296 42.822l-1.008.304a6.98 6.98 0 0 1-.252-2.822l1.047.118a5.918 5.918 0 0 0 .213 2.4Z"
      fill="#FFF2AD"
    />
    <path
      d="M110.466 69.115c-.165.731-.332 1.462-.496 2.193a14.961 14.961 0 0 1-5.505-2.512l1.33-1.827a12.633 12.633 0 0 0 4.671 2.147Z"
      fill="#FFDC1E"
    />
    <path
      d="m225.999 44.662-.746.503a6 6 0 0 1-.913-2.248l.889-.164c.125.682.387 1.331.77 1.91ZM277.839 98.324l-.216.865a5.855 5.855 0 0 1-2.159-1.045l.542-.706a5.043 5.043 0 0 0 1.833.886ZM269.154 126.921l.353.268c-.249.32-.559.587-.913.785-.073-.131-.146-.259-.216-.389.301-.166.565-.392.776-.664ZM700.826 35.493c-.091.2-.182.402-.271.609a4.398 4.398 0 0 1-1.443-1.048l.491-.444c.344.374.76.675 1.223.883Z"
      fill="#FFF2AD"
    />
    <path
      d="m1030.29 50.354-.75-1.703a12.33 12.33 0 0 1 4.89-1.06l.02 1.861c-1.43.013-2.85.32-4.16.902Z"
      fill="#FFDC1E"
    />
    <path
      d="m764.144 41.647-.74.034a4.838 4.838 0 0 1 .305-1.961l.688.262a4.128 4.128 0 0 0-.253 1.665Z"
      fill="#FFF2AD"
    />
    <path
      d="m900.087 35.934 3.188 2.387a26.42 26.42 0 0 1-8.035 7.104c-.662-1.155-1.322-2.308-1.979-3.459a22.493 22.493 0 0 0 6.826-6.032Z"
      fill="#71ADEE"
    />
    <path
      d="m991.93 18.861-.554 1a7.53 7.53 0 0 1-2.323-2.008l.913-.694a6.395 6.395 0 0 0 1.964 1.702ZM1093.7 20.817l-2.89 1.4c-1.27-2.616-2-5.466-2.13-8.373l3.21-.14c.11 2.47.72 4.89 1.81 7.113Z"
      fill="#FFF2AD"
    />
    <path
      d="m1113.54 71.561.42 1.73c-1.57.38-3.2.433-4.79.155l.3-1.757c1.35.237 2.74.194 4.07-.127Z"
      fill="#C6E9F8"
    />
    <path
      d="m1087.51 124.684-2.93-.825a20.1 20.1 0 0 1 3.78-7.272l2.36 1.922a17.22 17.22 0 0 0-3.21 6.175Z"
      fill="#FFDC1E"
    />
    <path
      d="m1132.74 13.058 1.18.971a10.237 10.237 0 0 1-3.2 2.591l-.7-1.36a8.607 8.607 0 0 0 2.72-2.202ZM1004.34 33.337l.1-1.623c1.47.083 2.91.469 4.23 1.133l-.73 1.453a9.222 9.222 0 0 0-3.6-.963ZM1033.75 100.074l.55.323c-.29.498-.69.931-1.15 1.27l-.38-.515c.4-.289.73-.655.98-1.078ZM1043.44 139.711l.23 1.827c-1.66.205-3.34.069-4.94-.399l.52-1.769c1.36.4 2.79.516 4.19.341ZM612.667 17.129l-.247-1.827a12.218 12.218 0 0 1 4.935.344c-.164.59-.332 1.181-.496 1.772a10.315 10.315 0 0 0-4.192-.29ZM787.97 19.805l-.664 1.605a11.508 11.508 0 0 1-3.861-2.625l1.246-1.218a9.77 9.77 0 0 0 3.279 2.238ZM845.004 13.987l-1.178-.08c.07-1.068.36-2.11.85-3.063l1.05.54a6.656 6.656 0 0 0-.722 2.603ZM422.893 23.758l-3.717-3.03a31.903 31.903 0 0 1 9.998-8.133l2.211 4.263a26.967 26.967 0 0 0-8.492 6.9ZM458.528 38.604l-.423-.271c.247-.38.568-.706.944-.959l.279.414a2.844 2.844 0 0 0-.8.816ZM388.996 97.048l-.055-.716a4.756 4.756 0 0 1 1.915.25c-.079.225-.155.45-.234.679a3.99 3.99 0 0 0-1.626-.213ZM179.502 72.197c-.128.971-.259 1.946-.39 2.917a19.498 19.498 0 0 1-7.477-2.618l1.522-2.525a16.59 16.59 0 0 0 6.345 2.226ZM149.662 130.847l-1.282-1.318a12.222 12.222 0 0 1 4.174-2.655c.216.575.435 1.147.652 1.72a10.267 10.267 0 0 0-3.544 2.253Z"
      fill="#FFF2AD"
    />
    <path
      d="M396.241 97.83c.477 2.075.956 4.148 1.437 6.218a42.347 42.347 0 0 1-17.168.39l1.154-6.276a35.95 35.95 0 0 0 14.577-.331ZM612.882 50.143a2586.517 2586.517 0 0 0-5.053 3.898 42.344 42.344 0 0 1-7.509-15.445c2.061-.52 4.123-1.042 6.185-1.566a35.96 35.96 0 0 0 6.377 13.113Z"
      fill="#71ADEE"
    />
    <path
      d="m291.462 59.96-4.427 2.509a33.804 33.804 0 0 1-4.195-13.03l5.06-.545a28.655 28.655 0 0 0 3.562 11.066ZM529.274 6.985l.365 1.325a9.094 9.094 0 0 1-3.69.237l.192-1.36a7.718 7.718 0 0 0 3.133-.202ZM251.938 4.922V2.525c2.17.01 4.315.463 6.303 1.331l-.956 2.192a13.503 13.503 0 0 0-5.347-1.126ZM221.694 20.938l-.895 2.071c-1.877-.812-3.568-2-4.969-3.49l1.644-1.543a12.78 12.78 0 0 0 4.22 2.962ZM115.416 32.902l-1.745.838a12.891 12.891 0 0 1-1.257-5.052l1.933-.08a10.9 10.9 0 0 0 1.069 4.294ZM861.417 83.075l2.296-1.234a17.301 17.301 0 0 1 2.003 6.727l-2.6.222a14.734 14.734 0 0 0-1.699-5.716ZM978.379 4.27l1.641 1.499a14.758 14.758 0 0 1-4.835 3.507c-.305-.68-.609-1.357-.914-2.03a12.582 12.582 0 0 0 4.108-2.975ZM1007.76 154.168l-2.82-.773a19.515 19.515 0 0 1 3.58-7.003l2.28 1.827a16.525 16.525 0 0 0-3.04 5.949ZM337.624 11.17c.177-.594.35-1.188.527-1.781a12.32 12.32 0 0 1 4.408 2.353l-1.187 1.428a10.478 10.478 0 0 0-3.748-2ZM217.746 33.078l-1.827.378a12.346 12.346 0 0 1 0-5l1.827.378c-.29 1.4-.29 2.844 0 4.244ZM276.751 42.009l-1.826.378a12.346 12.346 0 0 1 0-5l1.826.377a10.393 10.393 0 0 0 0 4.245ZM674.215 53.738l-.795-2.536c2.3-.722 4.724-.966 7.121-.716-.095.884-.187 1.766-.277 2.647a15.011 15.011 0 0 0-6.049.605ZM930.773 25.722l-1.023 2.987a20.979 20.979 0 0 1-7.307-4.32c.709-.78 1.419-1.558 2.132-2.336a17.759 17.759 0 0 0 6.198 3.67ZM981.07 53.233l-1.827.378a12.346 12.346 0 0 1 0-5l1.827.377a10.5 10.5 0 0 0 0 4.245ZM939.844 117.614l-3.553-1.133a24.71 24.71 0 0 1 4.923-8.745c.934.812 1.871 1.624 2.81 2.436a20.993 20.993 0 0 0-4.18 7.442ZM1084.08 36.066l-2.49 3.261a27.295 27.295 0 0 1-7.24-8.355l3.59-2.004a23.126 23.126 0 0 0 6.14 7.098ZM996.447 134.376l-1.827.375a12.332 12.332 0 0 1 0-4.997l1.827.374a10.515 10.515 0 0 0 0 4.248Z"
      fill="#E39F3A"
    />
    <path
      d="m483.97 52.506.642-.183c.165.584.207 1.196.125 1.797l-.664-.091a3.78 3.78 0 0 0-.103-1.523ZM470.583 53.82l-.103.137a1.104 1.104 0 0 1-.304-.346l.149-.083a.997.997 0 0 0 .258.292ZM368.394 55.224l-.819.39a6.044 6.044 0 0 1-.582-2.37l.914-.033c.023.697.189 1.382.487 2.013ZM418.184 26.313c0 .365 0 .73-.018 1.096a7.207 7.207 0 0 1-2.874-.646c.149-.332.304-.663.451-.998.767.35 1.598.536 2.441.548ZM393.18 8.823l1.068-2.177a16.096 16.096 0 0 1 5.158 4.004l-1.845 1.574a13.682 13.682 0 0 0-4.381-3.401ZM193.763 97.474l-.159-1.781a11.87 11.87 0 0 1 4.78.554c-.185.566-.374 1.132-.56 1.699a10.042 10.042 0 0 0-4.061-.472ZM196.948 28.188l-1.854-.721a13.189 13.189 0 0 1 2.917-4.498l1.415 1.4a11.239 11.239 0 0 0-2.478 3.82ZM611.4 14.275l-.091-2.13c1.944-.09 3.886.22 5.705.913l-.767 2.006a12.02 12.02 0 0 0-4.847-.789ZM779.011 8.703l.934-.411c.372.848.567 1.763.573 2.689h-1.023a5.708 5.708 0 0 0-.484-2.278ZM804.127 11.758l-1.586-1.389a14.032 14.032 0 0 1 4.524-3.425l.914 1.903a11.925 11.925 0 0 0-3.852 2.91ZM481.227 17.162l-1.108-.609a8.397 8.397 0 0 1 2.201-2.585l.774.996a7.119 7.119 0 0 0-1.867 2.198ZM1095.45 9.955l-1.99.371c-.34-1.804-.31-3.658.1-5.447l1.98.448a11.28 11.28 0 0 0-.09 4.628ZM1066.52 26.72l-.5-.962a7.247 7.247 0 0 1 2.81-.792c.03.36.06.722.08 1.081-.83.061-1.64.29-2.39.673ZM1134.91 34.69l-2-1.268a15.738 15.738 0 0 1 4.42-4.567l1.33 1.952a13.294 13.294 0 0 0-3.75 3.882ZM1171.19 50.818l-.88-1.142a9.62 9.62 0 0 1 3.49-1.708l.36 1.401c-1.08.277-2.09.77-2.97 1.45ZM1170.35 12.43c-.04-.608-.09-1.217-.13-1.81 1.64-.114 3.29.112 4.84.663-.2.57-.4 1.14-.61 1.708a10.156 10.156 0 0 0-4.1-.56Z"
      fill="#004EB6"
    />
    <path
      d="M1162 70.918 1158.87 74c-2.6-3.035-4.6-6.611-5.87-10.508l4.01-1.492c1.09 3.305 2.78 6.339 4.99 8.918Z"
      fill="#E39F3A"
    />
    <path
      d="M1151.69 94.801c-.25.812-.5 1.616-.75 2.412a16.64 16.64 0 0 1-5.94-3.3l1.65-1.913a14.2 14.2 0 0 0 5.04 2.801Z"
      fill="#004EB6"
    />
    <path
      d="m881.887 48.967 2.435-.633c.576 2.21.692 4.515.341 6.772l-2.49-.387a14.214 14.214 0 0 0-.286-5.752Z"
      fill="#AAD2F6"
    />
    <path
      d="m889.659 82.907-1.827-1.318a14.927 14.927 0 0 1 4.463-4.06l1.142 1.931a12.66 12.66 0 0 0-3.778 3.447ZM1017.51 82.288l-1.46-1.796a15.324 15.324 0 0 1 5.53-2.86c.2.743.41 1.486.63 2.23-1.72.478-3.32 1.304-4.7 2.426ZM940.989 117.826l-1.114-.673a8.593 8.593 0 0 1 2.381-2.57c.253.35.505.706.755 1.06a7.318 7.318 0 0 0-2.022 2.183ZM952.671 75.69l-1.045.055a6.932 6.932 0 0 1 .43-2.78l.98.366a5.824 5.824 0 0 0-.365 2.36ZM859.427 57.303l1.409-2.301a17.91 17.91 0 0 1 5.301 4.975l-2.21 1.553a15.247 15.247 0 0 0-4.5-4.227ZM701.502 10.207c-.158.53-.32 1.06-.478 1.59a11.044 11.044 0 0 1-3.924-2.132l1.068-1.27a9.344 9.344 0 0 0 3.334 1.812ZM920.846 147.241c-.219-.493-.437-.986-.652-1.477a10.68 10.68 0 0 1 4.248-.913v1.613a9.076 9.076 0 0 0-3.596.777Z"
      fill="#004EB6"
    />
    <path
      d="m216.372 85.979-1.827.374a12.331 12.331 0 0 1 0-4.997l1.827.378a10.359 10.359 0 0 0 0 4.245ZM693.942 69.841l-1.827.375a12.331 12.331 0 0 1 0-4.997l1.827.375a10.514 10.514 0 0 0 0 4.248ZM916.287 125.75l-2.223-1.827a19.106 19.106 0 0 1 5.992-4.872c.439.851.878 1.7 1.319 2.549a16.14 16.14 0 0 0-5.088 4.15ZM963.429 100.354l-1.827.378a12.333 12.333 0 0 1 0-4.997l1.827.374a10.499 10.499 0 0 0 0 4.245ZM1131.36 45.203l-1.83.375a12.367 12.367 0 0 1 0-4.997l1.83.375a10.479 10.479 0 0 0 0 4.247ZM546.916 68.826a612.26 612.26 0 0 0 1.55 2.199 17.867 17.867 0 0 1-6.638 2.883c-.186-.877-.37-1.754-.551-2.63a15.152 15.152 0 0 0 5.639-2.452Z"
      fill="#4A4A4A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M590.58 0h4.905a26.53 26.53 0 0 0-1.218 10.502l-4.683.402c-.314-3.67.025-7.361.996-10.904Z"
      fill="#FFDC1E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M814.855 0h4.046c.06.982.183 1.96.37 2.93l-3.958.774A26.938 26.938 0 0 1 814.855 0Z"
      fill="#0064DC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M772.646 0h6.431a22.657 22.657 0 0 0-4.346 2.671c-.695-.892-1.39-1.782-2.085-2.671Z"
      fill="#003A80"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M734.915 0h10.809l.01.358a24.255 24.255 0 0 0-9.584 2.281L734.915 0ZM635.169 0h3.976l.115.51c-1.381.307-2.811.328-4.201.063l.11-.573Z"
      fill="#0064DC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M524.663 0h1.308v2.306a13.255 13.255 0 0 0-5.298 1.075l-.913-2.165A15.62 15.62 0 0 1 524.663 0Z"
      fill="#FFDC1E"
    />
  </SvgIcon>
);

export default ConfettiStatic;
