import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm } from "redux-form";
import { GoogleLogin } from "@react-oauth/google";

import Box from "@material-ui/core/Box";
import { Body } from "../../ui/Typography";
import { DELETE_GOOGLE_SSO_ACCOUNT_FORM_ID } from "../../../constants/forms";
import { TextButton } from "../../ui/Button";
import { selectLanguageCode } from "../../../selectors/language";
import { submitGoogleAuthDelete } from "../../../actions/auth";
import { ErrorMessage } from "../../ui/Error";

const DeleteGoogleSSOAccountForm = ({ error, translations }) => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector(selectLanguageCode);
  const localeSelected = currentLanguage === "en" ? "en-CA" : "fr-CA";
  const { body, instructions, linkText, link } = translations;

  const handleGoogleLogin = (response) => {
    const data = {
      credential: response.credential,
    };
    dispatch(submitGoogleAuthDelete(data));
  };
  const handleGoogleLoginFailure = (googleError) => {
    console.error({ googleError });
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" mt={1}>
      <Body>{body}</Body>
      <Box mb={2} display="flex" justifyContent="center">
        <GoogleLogin
          width="250px"
          useOneTap
          shape="rectangular"
          theme="outline"
          size="large"
          text="continue_with"
          logo_alignment="center"
          locale={localeSelected}
          onSuccess={handleGoogleLogin}
          onError={handleGoogleLoginFailure}
        />
      </Box>
      <Body>
        {instructions}
        <TextButton
          onClick={() => window.open(link, "_blank")}
          text={linkText}
        />
      </Body>
      <ErrorMessage error={error} />
    </Box>
  );
};

export default reduxForm({ form: DELETE_GOOGLE_SSO_ACCOUNT_FORM_ID })(
  DeleteGoogleSSOAccountForm,
);
