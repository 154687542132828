export const fetchAssetList = () => ({
  type: FETCH_ASSET_LIST_TYPE,
});

export const updateAssetList = (assetList) => ({
  type: UPDATE_ASSET_LIST_TYPE,
  payload: {
    assetList,
  },
});

export const addPhysicalAsset = (asset) => ({
  type: ADD_PHYSICAL_ASSET_TYPE,
  payload: {
    asset,
  },
});
export const addFinancialAsset = (asset) => ({
  type: ADD_FINANCIAL_ASSET_TYPE,
  payload: {
    asset,
  },
});
export const addInsuranceAsset = (asset) => ({
  type: ADD_INSURANCE_ASSET_TYPE,
  payload: {
    asset,
  },
});

export const updatePhysicalAssets = (modifiedAsset, assets) => ({
  type: UPDATE_PHYSICAL_ASSETS_TYPE,
  payload: {
    modifiedAsset,
    assets,
  },
});
export const updateFinancialAssets = (modifiedAsset, assets) => ({
  type: UPDATE_FINANCIAL_ASSETS_TYPE,
  payload: {
    modifiedAsset,
    assets,
  },
});
export const updateInsuranceAssets = (modifiedAsset, assets) => ({
  type: UPDATE_INSURANCE_ASSETS_TYPE,
  payload: {
    modifiedAsset,
    assets,
  },
});

export const editAsset = (id, category) => ({
  type: EDIT_ASSET_TYPE,
  payload: {
    id,
    category,
  },
});

export const removeAsset = (id, category) => ({
  type: REMOVE_ASSET_TYPE,
  payload: {
    id,
    category,
  },
});

export const togglePhysicalAssetCard = (expanded) => ({
  type: TOGGLE_PHYSICAL_ASSET_CARD_TYPE,
  payload: {
    expanded,
  },
});
export const toggleFinancialAssetCard = (expanded) => ({
  type: TOGGLE_FINANCIAL_ASSET_CARD_TYPE,
  payload: {
    expanded,
  },
});
export const toggleInsuranceAssetCard = (expanded) => ({
  type: TOGGLE_INSURANCE_ASSET_CARD_TYPE,
  payload: {
    expanded,
  },
});

export const FETCH_ASSET_LIST_TYPE = "FETCH_ASSET_LIST";
export const UPDATE_ASSET_LIST_TYPE = "UPDATE_ASSET_LIST";
export const ADD_PHYSICAL_ASSET_TYPE = "ADD_PHYSICAL_ASSET";
export const ADD_INSURANCE_ASSET_TYPE = "ADD_INSURANCE_ASSET";
export const ADD_FINANCIAL_ASSET_TYPE = "ADD_FINANCIAL_ASSET";
export const UPDATE_PHYSICAL_ASSETS_TYPE = "UPDATE_PHYSICAL_ASSETS";
export const UPDATE_INSURANCE_ASSETS_TYPE = "UPDATE_INSURANCE_ASSETS";
export const UPDATE_FINANCIAL_ASSETS_TYPE = "UPDATE_FINANCIAL_ASSETS";
export const TOGGLE_PHYSICAL_ASSET_CARD_TYPE = "TOGGLE_PHYSICAL_ASSET_CARD";
export const TOGGLE_FINANCIAL_ASSET_CARD_TYPE = "TOGGLE_FINANCIAL_ASSET_CARD";
export const TOGGLE_INSURANCE_ASSET_CARD_TYPE = "TOGGLE_INSURANCE_ASSET_CARD";
export const EDIT_ASSET_TYPE = "EDIT_ASSET";
export const REMOVE_ASSET_TYPE = "REMOVE_ASSET";
