import React from "react";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import SvgIcon from "./SvgIcon";

const StyledSvgIcon = styled(SvgIcon)`
  &:hover {
    cursor: pointer;

    .mainfill {
      fill: ${(props) => props.$hovercolor};
    }
  }

  margin: 0 auto;

  &:focus {
    border: 1px solid ${(props) => props.focuscolor};
  }
`;

const LargeToolTipInfoIcon = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  if (!isDesktop) return <MobileLargeToolTipInfoIcon />;
  return (
    <Box width="32px" height="32px" display="flex" alignItems="center">
      <StyledSvgIcon
        width="24"
        height="24"
        viewBox="0 0 24 24"
        $hovercolor={theme.palette.willfulBlueDark2.main}
        $focuscolor={theme.palette.primary.main}
      >
        <path
          d="M12,24A12,12,0,1,0,0,12,12,12,0,0,0,12,24Z"
          fill="#70707a"
          fillRule="evenodd"
          className="mainfill"
        />
        <path d="M13.33,9.33v9.34H10.67V9.33Z" fill="#fff" />
        <path
          d="M12,8.33a1.67,1.67,0,1,0-1.67-1.66A1.66,1.66,0,0,0,12,8.33Z"
          fill="#fff"
          fillRule="evenodd"
        />
      </StyledSvgIcon>
    </Box>
  );
};

const MobileLargeToolTipInfoIcon = () => {
  const theme = useTheme();
  return (
    <Box width="24px" height="24px" display="flex" alignItems="center">
      <StyledSvgIcon
        width="18"
        height="18"
        viewBox="0 0 24 24"
        $hovercolor={theme.palette.willfulBlueDark2.main}
        $focuscolor={theme.palette.primary.main}
      >
        <path
          d="M12,24A12,12,0,1,0,0,12,12,12,0,0,0,12,24Z"
          fill="#70707a"
          fillRule="evenodd"
          className="mainfill"
        />
        <path d="M13.33,9.33v9.34H10.67V9.33Z" fill="#fff" />
        <path
          d="M12,8.33a1.67,1.67,0,1,0-1.67-1.66A1.66,1.66,0,0,0,12,8.33Z"
          fill="#fff"
          fillRule="evenodd"
        />
      </StyledSvgIcon>
    </Box>
  );
};

const RegularToolTipInfoIcon = () => {
  const theme = useTheme();
  return (
    <StyledSvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      $hovercolor={theme.palette.willfulBlueDark2.main}
      $focuscolor={theme.palette.primary.main}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21a9 9 0 100-18 9 9 0 000 18z"
        fill="#70707A"
        className="mainfill"
      />
      <path d="M13 10v7h-2v-7h2z" fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
        fill="#fff"
      />
    </StyledSvgIcon>
  );
};

const TooltipInfoIcon = ({ size }) => {
  if (size === "Large") {
    return <LargeToolTipInfoIcon />;
  }
  return <RegularToolTipInfoIcon />;
};

export default TooltipInfoIcon;
