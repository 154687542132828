import { createSelector, createStructuredSelector } from "reselect";

import { selectTranslations } from "./translations";
import { selectHasUserPaid } from "./plans";
import { ASSET_LIST_PATH, CHECKOUT_PATH } from "../constants/routes";

const selectAssetListOverviewTranslations = createSelector(
  selectTranslations(["global", "assetListOverviewPage"]),
  selectHasUserPaid,
  (translations, isPaidUser) => {
    const overviewTranslations = isPaidUser
      ? translations.paidUser
      : translations.unpaidUser;
    return { ...translations, ...overviewTranslations };
  },
);

const selectCTAButtonLink = createSelector(selectHasUserPaid, (isPaidUser) =>
  isPaidUser ? ASSET_LIST_PATH : CHECKOUT_PATH,
);

export const assetListOverviewProps = createStructuredSelector({
  translations: selectAssetListOverviewTranslations,
  ctaButtonLink: selectCTAButtonLink,
});
