import React from "react";
import SvgIcon from "./SvgIcon";

const AllocationsGuidanceIcon = ({ isDesktop }) => {
  const width = isDesktop ? 240 : 180;
  const height = isDesktop ? 162 : 122;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 240 162">
      <g clipPath="url(#b)">
        <clipPath id="b">
          <rect width="240" height="161.54" fill="none" />
        </clipPath>
        <g>
          <path
            d="M120,161.1c66.27,0,120-35.74,120-79.83S186.27,1.44,120,1.44,0,37.18,0,81.27s53.73,79.83,120,79.83Z"
            fill="#c6e9f8"
          />
          <path
            d="M127,121.23c48.81,0,88.38-24.17,88.38-53.97S175.81,13.29,127,13.29,38.63,37.45,38.63,67.26s39.57,53.97,88.38,53.97Z"
            fill="#2f455f"
          />
          <path
            d="M59.95,99.91c-29.05-18.44-28.12-47.07,2.68-64.79,30.8-17.82,80.34-18.33,112.28-1.55L59.95,99.91Z"
            fill="#fff"
          />
          <path
            d="M174.9,33.47l-23.28,13.39L59.95,99.81c-18.54-11.74-24.82-27.6-19.06-42.23,1.44-3.71,3.71-7.31,6.7-10.71,3.81-4.33,8.76-8.34,14.94-11.85,24.21-14.01,60.05-17.3,89.82-9.89,8.14,2.06,15.76,4.84,22.56,8.34Z"
            fill="#fff"
          />
          <path
            d="M59.95,78.49v21.32c-13.91-8.76-21.01-19.98-21.12-31.31v-20.39c.1,3.19,.82,6.39,2.06,9.48,3.09,7.62,9.48,14.83,19.06,20.91Z"
            fill="#5ffff7"
          />
          <path
            d="M201.48,70.45c-.93,10.92-8.24,21.22-21.12,29.36v-21.32c12.77-8.14,20.19-18.44,21.12-29.36v21.32Z"
            fill="#b5a9ff"
          />
          <path
            d="M174.9,103c-30.9,16.27-78.49,16.27-109.49,0v-21.32c6.8,3.61,14.42,6.39,22.46,8.34,20.5,5.15,43.98,5.15,64.58,0,8.03-1.96,15.66-4.84,22.45-8.34v21.32Z"
            fill="#f18dff"
          />
          <path
            d="M59.95,78.49v21.32c-13.91-8.76-21.01-19.98-21.12-31.31v-20.39c.1,3.19,.82,6.39,2.06,9.48,3.09,7.62,9.48,14.83,19.06,20.91Z"
            fill="#71adee"
          />
          <path
            d="M174.9,12.15v21.32c-6.8-3.61-14.42-6.39-22.46-8.34-29.67-7.42-65.51-4.12-89.82,9.89-6.18,3.61-11.23,7.62-14.94,11.85-2.99,3.4-5.25,7-6.7,10.71-1.24-3.09-1.96-6.28-2.06-9.48v-2.47c0-.31,0-.72,.1-1.03,.93-11.33,8.86-22.45,23.69-31,30.7-17.61,80.24-18.13,112.17-1.44Z"
            fill="#fff"
          />
          <path
            d="M174.9,12.15l-22.46,12.98L59.95,78.49c-9.58-6.08-15.97-13.29-18.95-20.91-1.24-3.09-1.96-6.28-2.06-9.48v-2.37c0-.31,0-.72,.1-1.03,.93-11.33,8.86-22.45,23.69-31,30.7-17.72,80.24-18.23,112.17-1.55Z"
            fill="#0064dc"
          />
          <path
            d="M59.95,78.49v21.42L174.9,33.47V12.15L59.95,78.49Z"
            fill="#004eb6"
          />
          <path
            d="M201.37,44.7v21.01c-.72-7.52-4.43-14.63-10.82-21.01-2.88-2.88-6.28-5.56-10.2-8.03V15.35c12.77,8.14,20.19,18.44,21.01,29.36Z"
            fill="#fff"
          />
          <path
            d="M129.48,44.7v21.32l50.88-29.36V15.35l-50.88,29.36Z"
            fill="#fff"
          />
          <path
            d="M180.36,36.67c12.77,8.14,20.19,18.44,21.12,29.36h-71.9l50.78-29.36Z"
            fill="#fff"
          />
          <path d="M201.37,44.7h-71.9v21.32h71.9v-21.32Z" fill="#3989e5" />
          <path
            d="M201.48,70.45c-.93,10.92-8.24,21.22-21.12,29.36l-50.88-29.36h72Z"
            fill="#fff"
          />
          <path
            d="M201.48,70.45c-.93,10.92-8.24,21.22-21.12,29.36v-21.32c12.77-8.14,20.19-18.44,21.12-29.36v21.32Z"
            fill="#3989e5"
          />
          <path
            d="M129.48,49.13v21.32l50.88,29.46v-21.42l-50.88-29.36Z"
            fill="#0064dc"
          />
          <path
            d="M120.21,71.38l54.69,31.62c-30.9,16.28-78.49,16.28-109.49,0l54.8-31.62Z"
            fill="#fff"
          />
          <path
            d="M174.9,81.68c-6.8,3.61-14.42,6.39-22.45,8.34-20.5,5.15-43.98,5.15-64.58,0-8.03-1.96-15.66-4.74-22.46-8.34l1.96-1.13,52.74-30.49,52.74,30.49,2.06,1.13Z"
            fill="#ffdc1e"
          />
          <path
            d="M174.9,103c-30.9,16.27-78.49,16.27-109.49,0v-21.32c6.8,3.61,14.42,6.39,22.46,8.34,20.5,5.15,43.98,5.15,64.58,0,8.03-1.96,15.66-4.84,22.45-8.34v21.32Z"
            fill="#e39f3a"
          />
          <path
            d="M180.36,15.35c12.77,8.14,20.19,18.44,21.12,29.36h-71.9l50.78-29.36Z"
            fill="#aad2f6"
          />
          <path
            d="M201.37,49.13c-.93,10.92-8.24,21.22-21.12,29.36l-50.88-29.36h72Z"
            fill="#71adee"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default AllocationsGuidanceIcon;
