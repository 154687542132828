import R from "ramda";
import dayjs from "dayjs";

import {
  ALLOCATIONS_FIELD_ID,
  EXECUTORS_FIELD_ID,
  DOB_FIELD_ID,
  CAA_FIELD_ID,
  DONATIONS_FIELD_ID,
  GENDER_FIELD_ID,
  GIFTS_FIELD_ID,
  INHERITANCE_FIELD_ID,
  LIFE_PROLONG_FIELD_ID,
  MARITAL_STATUS_FIELD_ID,
  CHILDREN_FIELD_ID,
  PAIN_MANAGE_FIELD_ID,
  PETS_FIELD_ID,
  PET_TRUST_AMOUNT_FIELD_ID,
  PROVINCE_FIELD_ID,
  PROMO_CODE_FIELD_ID,
  RESIDENCE_FIELD_ID,
  RESTING_FIELD_ID,
  ASHES_FIELD_ID,
  PRODUCTS_FORM_ID,
  SPOUSE_IS_EXECUTOR_FIELD_ID,
  SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID,
  SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
  BACKUPS_FIELD_ID,
  REFERRAL_FORM_ID,
  PERSONAL_ATTORNEYS_FIELD_ID,
  PROPERTY_ATTORNEYS_FIELD_ID,
  CEREMONY_FIELD_ID,
  PLAN_QUANTITY_FIELD_ID,
  IS_INVITED_USER_ID,
  PAID_ID,
} from "../../constants/forms";
import { selectAvailableProducts } from "../../selectors/plans";
import { selectAllProvinces } from "../../selectors/provinces";
import { selectAnalyticsSessionId } from "../../selectors/auth";

const FIELDS_TO_CAPTURE = [
  RESIDENCE_FIELD_ID,
  PROVINCE_FIELD_ID,
  GENDER_FIELD_ID,
  DOB_FIELD_ID, // age
  PRODUCTS_FORM_ID,

  MARITAL_STATUS_FIELD_ID,
  CHILDREN_FIELD_ID, // num of children
  PETS_FIELD_ID, // num of pets, types of pets (future?)
  PET_TRUST_AMOUNT_FIELD_ID,

  GIFTS_FIELD_ID, // num of gifts
  DONATIONS_FIELD_ID, // yes/no
  ALLOCATIONS_FIELD_ID, // num of allocations, (if any charities - amounts)
  INHERITANCE_FIELD_ID,
  BACKUPS_FIELD_ID, // boolean

  EXECUTORS_FIELD_ID, // num of executors
  SPOUSE_IS_EXECUTOR_FIELD_ID,
  PERSONAL_ATTORNEYS_FIELD_ID, // num of PA
  SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID,
  PROPERTY_ATTORNEYS_FIELD_ID, // num of PA
  SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
  CEREMONY_FIELD_ID,
  ASHES_FIELD_ID,
  RESTING_FIELD_ID,
  LIFE_PROLONG_FIELD_ID,
  PAIN_MANAGE_FIELD_ID,

  CAA_FIELD_ID, // BOOLEAN
  PROMO_CODE_FIELD_ID,
  REFERRAL_FORM_ID,
  PLAN_QUANTITY_FIELD_ID,
  IS_INVITED_USER_ID,
  PAID_ID,
];

const prefixSpouseLabel = (label, isSpouse) =>
  isSpouse ? `Spouse - ${label}` : label;

const transformMap = {
  [REFERRAL_FORM_ID]: (value) => {
    if (value) {
      return {
        Referral: true,
      };
    }
    return {};
  },
  [PROVINCE_FIELD_ID]: (value, state) => {
    const provinces = selectAllProvinces(state);
    const selectedProvince = R.find((province) => province.id === value)(
      provinces,
    );
    return {
      Province: selectedProvince.name,
    };
  },
  [PRODUCTS_FORM_ID]: (value, state) => {
    const products = selectAvailableProducts(state);
    // Select the first product ID in the returned payload
    const payloadProductId = value[0].productId || null;
    const selectedProduct = R.find(
      (product) => product.id === payloadProductId,
    )(products);
    return {
      Plan: selectedProduct.name,
    };
  },
  [GENDER_FIELD_ID]: (value, state, isSpouse) => {
    const label = prefixSpouseLabel("Gender", isSpouse);
    return {
      [label]: value,
    };
  },
  [DOB_FIELD_ID]: (value, state, isSpouse) => {
    const todaysDate = dayjs(new Date());
    const userDate = dayjs(value);
    const age = todaysDate.diff(userDate, "y");
    const label = prefixSpouseLabel("Age", isSpouse);

    return {
      [label]: age,
    };
  },
  [CHILDREN_FIELD_ID]: (value) => {
    return {
      "Number of children": value.length,
    };
  },
  [PETS_FIELD_ID]: (value) => ({
    "Number of pets": value.length,
  }),

  [GIFTS_FIELD_ID]: (value, state, isSpouse) => {
    const label = prefixSpouseLabel("Number of gifts", isSpouse);
    return {
      [label]: value.length,
    };
  },
  [DONATIONS_FIELD_ID]: (value, state, isSpouse) => {
    const label = prefixSpouseLabel("Has charitable donations", isSpouse);
    return {
      [label]: value.length > 0,
      // TODO:
      // "Amount of cash allocated to charity": ...
    };
  },
  [ALLOCATIONS_FIELD_ID]: (value) => ({
    "Number of allocations": value.length,
    // TODO:
    // "% of estate allocated to charity": ...
  }),
  [BACKUPS_FIELD_ID]: (value) =>
    // TODO: this won't be captured
    // Backup API endpoint payload format is different than pattern
    // for other endpoints, need alignment or need to add more logic to transformPayloadToSegment
    // payload that hits that function not in correct format
    ({
      "Has specified a backup": !!value,
    }),
  [SPOUSE_IS_EXECUTOR_FIELD_ID]: (value, state, isSpouse) => {
    const label = prefixSpouseLabel("Spouse is my primary Executor", isSpouse);
    return {
      [label]: value,
    };
  },
  [EXECUTORS_FIELD_ID]: (value, state, isSpouse) => {
    const label = prefixSpouseLabel("Number of Executors", isSpouse);
    return {
      [label]: value.length,
    };
  },
  [RESTING_FIELD_ID]: (value, state, isSpouse) => {
    const label = prefixSpouseLabel("Resting", isSpouse);
    return {
      [label]: value,
    };
  },
  [ASHES_FIELD_ID]: (value, state, isSpouse) => {
    const label = prefixSpouseLabel("Ashes", isSpouse);
    return {
      [label]: value,
    };
  },
  [CEREMONY_FIELD_ID]: (value, state, isSpouse) => {
    const label = prefixSpouseLabel("Ceremony", isSpouse);
    return {
      [label]: value,
    };
  },
  [SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID]: (value, state, isSpouse) => {
    const label = prefixSpouseLabel(
      "Spouse is my primary Personal Attorney",
      isSpouse,
    );
    return {
      [label]: value,
    };
  },
  [PERSONAL_ATTORNEYS_FIELD_ID]: (value, state, isSpouse) => {
    const label = prefixSpouseLabel("Number of Personal Attorneys", isSpouse);
    return {
      [label]: value.length,
    };
  },
  [SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID]: (value, state, isSpouse) => {
    const label = prefixSpouseLabel(
      "Spouse is my primary Property Attorney",
      isSpouse,
    );
    return {
      [label]: value,
    };
  },
  [PROPERTY_ATTORNEYS_FIELD_ID]: (value, state, isSpouse) => {
    const label = prefixSpouseLabel("Number of Property Attorneys", isSpouse);
    return {
      [label]: value.length,
    };
  },
  [PAIN_MANAGE_FIELD_ID]: (value, state, isSpouse) => {
    const label = prefixSpouseLabel("Pain manage", isSpouse);
    return {
      [label]: value,
    };
  },
  [LIFE_PROLONG_FIELD_ID]: (value, state, isSpouse) => {
    const label = prefixSpouseLabel("Life prolong", isSpouse);
    return {
      [label]: value,
    };
  },
  [CAA_FIELD_ID]: (value) => ({
    "Has used CAA membership discount": !!value,
  }),
  [PLAN_QUANTITY_FIELD_ID]: (value) => ({
    "Plan quantity": value,
  }),
  [IS_INVITED_USER_ID]: (value) => ({
    "Is invited user": value,
  }),
  [PAID_ID]: (value) => ({
    Paid: value,
  }),
};

const transformPayloadForSegment = (payload, state, isSpouseValues) => {
  const keys = Object.keys(payload);

  // iterate over each key/value in payload, transforming the values if needed
  return keys.reduce((transformedPayload, currentKey) => {
    const rawValue = payload[currentKey];

    if (!R.has(currentKey, transformMap)) {
      // no transform needed, return raw value
      return {
        ...transformedPayload,
        [currentKey]: rawValue,
      };
    }

    // returns an object incase key rename for amplitude is needed
    const transformedKeyValue = transformMap[currentKey](
      rawValue,
      state,
      isSpouseValues,
    );

    // object contains a key/value pair and is merged with transformed payload
    return {
      ...transformedPayload,
      ...transformedKeyValue,
    };
  }, {});
};

export const transformPayloadToSegment = (payload, state, isSpouseValues) => {
  const filteredPayload = R.pickBy(
    (val, key) =>
      FIELDS_TO_CAPTURE.includes(key) || key.match(/^Experiment: /g),
    payload,
  );

  const userId = R.pathOr("", ["auth", "id"])(state);

  const transformedPayload = transformPayloadForSegment(
    filteredPayload,
    state,
    isSpouseValues,
  );

  // return payload shape for a segment instance
  return {
    userId,
    traits: transformedPayload,
    options: buildOptionsForSegment(state),
  };
};

// We need to manually manage the session ID for Amplitude as Segment will not do that for us
// In order to handle it, we save the current timestamp when the app initializes to the auth
// reducer, and send that as a session ID to the Amplitude integration in Segment.
export const buildOptionsForSegment = (state) => {
  const sessionId = selectAnalyticsSessionId(state);

  return {
    integrations: {
      Amplitude: {
        session_id: sessionId,
      },
    },
  };
};

export const buildOptionsForAmplitude = (state) => {
  const sessionId = selectAnalyticsSessionId(state);

  return {
    session_id: sessionId,
    options: {
      min_id_length: 1,
    },
  };
};
