import { postRequest, deleteRequest, putRequest } from "./request";
import {
  INVITE_USER_ENDPOINT,
  VALIDATE_INVITE_TOKEN_ENDPOINT,
} from "../constants/routes";

export const submitInviteUser = (config) => postRequest(INVITE_USER_ENDPOINT, config);

export const revokeInvite = (config) => deleteRequest(INVITE_USER_ENDPOINT, config);

export const submitValidateInviteToken = (config) => postRequest(VALIDATE_INVITE_TOKEN_ENDPOINT, config);

export const submitResendInvite = (config) => putRequest(INVITE_USER_ENDPOINT, config);
