import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { getFormValues } from "redux-form";
import { selectIsLoadingById, selectHasLoadedDataById } from "./requests";
import {
  selectHasActivePlan,
  selectHasUserPaid,
  selectIsActivePlanBundle,
  selectPlanQuantity,
} from "./plans";

import {
  EMAIL_FIELD_ID,
  UPDATE_EMAIL_FORM_ID,
  PURCHASES_SUMMARY_FORM_ID,
  UPDATE_PASSWORD_FORM_ID,
} from "../constants/forms";
import { selectTranslations } from "./translations";

import { selectUserEmail } from "./auth";
import { selectNumberOfAvailableInvites } from "./purchases";
import { selectYourPlanProps } from "./your-plan";

const selectIsNewPasswordBeingEntered = createSelector(
  getFormValues(UPDATE_PASSWORD_FORM_ID),
  (formValue) => !!R.propOr(false, "password", formValue),
);

export const selectChangeEmailForm = createSelector(
  selectUserEmail,
  (email) => ({ [EMAIL_FIELD_ID]: email }),
);

export const selectIsNewEmailBeingEntered = createSelector(
  getFormValues(UPDATE_EMAIL_FORM_ID),
  selectUserEmail,
  (formValue, currentEmail) => {
    const formEmail = R.path(["email"], formValue);

    return formEmail !== currentEmail;
  },
);

export const selectLastInvitedUser = R.pathOr({}, [
  "invites",
  "lastInvitedUser",
]);
export const selectLastRevokedUser = R.pathOr({}, [
  "invites",
  "lastRevokedUser",
]);
export const selectLastResentUser = R.pathOr({}, ["invites", "lastResentUser"]);

const selectYourAccountTranslations = createSelector(
  selectTranslations([
    "global",
    "yourAccount",
    "yourPlanTranslations",
    "links",
  ]),
  selectIsActivePlanBundle,
  selectPlanQuantity,
  selectNumberOfAvailableInvites,
  (translations, isBundlePlan, planQuantity, numberOfAvailablePlans) => {
    if (isBundlePlan) {
      const {
        availableInvites,
        totalInvites,
        totalInvitesSelected,
        managePlans,
        assignPlans,
      } = translations;
      const availableInvitesWithNumber = availableInvites.replace(
        "NUMBER",
        numberOfAvailablePlans,
      );
      const totalInvitesWithNumber = totalInvites.replace(
        "NUMBER",
        planQuantity,
      );
      const totalInvitesSelectedWithNumber = totalInvitesSelected.replace(
        "NUMBER",
        planQuantity,
      );
      const availableInvitesCopy =
        numberOfAvailablePlans === 0 ? managePlans : assignPlans;
      return {
        ...translations,
        availableInvites: availableInvitesWithNumber,
        totalInvites: totalInvitesWithNumber,
        totalInvitesSelected: totalInvitesSelectedWithNumber,
        availableInvitesCopy,
      };
    }
    return {
      ...translations,
      bulkPurchaseGifts: translations.bulkPurchaseGifts,
    };
  },
);

export const YourAccountPageProps = createStructuredSelector({
  changePasswordProps: createStructuredSelector({
    isNewPasswordBeingEntered: selectIsNewPasswordBeingEntered,
    isLoading: selectIsLoadingById(UPDATE_PASSWORD_FORM_ID),
  }),
  changeEmailProps: createStructuredSelector({
    isNewEmailBeingEntered: selectIsNewEmailBeingEntered,
    isLoading: selectIsLoadingById(UPDATE_EMAIL_FORM_ID),
    currentEmail: selectUserEmail,
  }),
  translations: selectYourAccountTranslations,
  hasLoaded: selectHasLoadedDataById(PURCHASES_SUMMARY_FORM_ID),
  yourPlanProps: selectYourPlanProps,
  hasPlan: selectHasActivePlan,
  hasUserPaid: selectHasUserPaid,
});
