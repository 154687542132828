import { combineReducers } from "redux";
import { UPDATE_STRIPE_CLIENT_SECRET_TYPE } from "../actions/checkout";

const stripeClientSecret = (state = "", { type, payload }) => {
  switch (type) {
    case UPDATE_STRIPE_CLIENT_SECRET_TYPE: {
      return payload.clientSecret;
    }
    default:
      return state;
  }
};

export default combineReducers({
  stripeClientSecret,
});
