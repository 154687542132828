export const submitSignup = () => ({
  type: SUBMIT_SIGNUP_TYPE,
});

export const submitLogin = () => ({
  type: SUBMIT_LOGIN_TYPE,
});

export const submitGoogleAuthLogin = ({ credential, requestType }) => ({
  type: SUBMIT_GOOGLE_AUTH_LOGIN_TYPE,
  payload: {
    credential,
    requestType,
  },
});

export const submitGoogleAuthDelete = ({ credential }) => ({
  type: SUBMIT_GOOGLE_AUTH_DELETE_TYPE,
  payload: {
    credential,
  },
});

export const submitForgotPassword = () => ({
  type: SUBMIT_FORGOT_PASSWORD_TYPE,
});

export const submitResetPassword = () => ({
  type: SUBMIT_RESET_PASSWORD_TYPE,
});

export const submitUpdatePassword = () => ({
  type: SUBMIT_UPDATE_PASSWORD_TYPE,
});

export const authenticated = (authHeaders) => ({
  type: AUTHENTICATED_TYPE,
  payload: {
    ...authHeaders,
  },
});

export const addInviteToken = (inviteToken) => ({
  type: ADD_INVITE_TOKEN_TYPE,
  payload: {
    inviteToken,
  },
});

export const logout = () => ({
  type: SUBMIT_LOGOUT_TYPE,
});

export const clearAuth = () => ({
  type: CLEAR_AUTH_TYPE,
});

export const clearState = (payload = {}) => ({
  type: CLEAR_APP_STATE_TYPE,
  payload: {
    redirectTo: payload.redirectTo,
  },
});

export const validateToken = () => ({
  type: VALIDATE_TOKEN_TYPE,
});

export const validateTokenExpirationTime = () => ({
  type: VALIDATE_TOKEN_EXPIRATION_TYPE,
});

export const initializeSignupForm = () => ({
  type: INITIALIZE_SIGNUP_FORM_TYPE,
});

export const initializeLoginForm = () => ({
  type: INITIALIZE_LOGIN_FORM_TYPE,
});

export const checkEmailAvailability = (formId) => {
  return {
    type: CHECK_EMAIL_AVAILABILITY_TYPE,
    payload: { formId },
  };
};

export const submitForgotSsoPassword = () => {
  return {
    type: FORGOT_SSO_PASSWORD_REQUEST,
  };
};

export const VALIDATE_TOKEN_TYPE = "AUTH_VALIDATE_TOKEN";
export const VALIDATE_TOKEN_EXPIRATION_TYPE = "VALIDATE_TOKEN_EXPIRATION";
export const SUBMIT_SIGNUP_TYPE = "AUTH_SUBMIT_SIGNUP";
export const SUBMIT_LOGIN_TYPE = "AUTH_SUBMIT_LOGIN";
export const SUBMIT_GOOGLE_AUTH_LOGIN_TYPE = "AUTH_SUBMIT_GOOGLE_LOGIN";
export const SUBMIT_GOOGLE_AUTH_DELETE_TYPE = "AUTH_SUBMIT_GOOGLE_DELETE";
export const AUTHENTICATED_TYPE = "AUTH_AUTHENTICATED";
export const SUBMIT_LOGOUT_TYPE = "AUTH_SUBMIT_LOGOUT";
export const CLEAR_AUTH_TYPE = "AUTH_CLEAR";
export const ADD_INVITE_TOKEN_TYPE = "ADD_INVITE_TOKEN";

export const CLEAR_APP_STATE_TYPE = "CLEAR_APP_STATE";
export const SET_AUTH_METHOD = "SET_AUTH_METHOD";

export const SUBMIT_FORGOT_PASSWORD_TYPE = "SUBMIT_FORGOT_PASSWORD";
export const SUBMIT_RESET_PASSWORD_TYPE = "SUBMIT_RESET_PASSWORD";
export const SUBMIT_UPDATE_PASSWORD_TYPE = "SUBMIT_UPDATE_PASSWORD_TYPE";
export const INITIALIZE_SIGNUP_FORM_TYPE = "INITIALIZE_SIGNUP_FORM";
export const INITIALIZE_LOGIN_FORM_TYPE = "INITIALIZE_LOGIN_FORM";
export const CHECK_EMAIL_AVAILABILITY_TYPE = "CHECK_EMAIL_AVAILABILITY";
export const FORGOT_SSO_PASSWORD_REQUEST = "FORGOT_SSO_PASSWORD_REQUEST";
