import React from "react";

import SvgIcon from "./SvgIcon";

const DocumentsNavIcon = () => {
  return (
    <SvgIcon width="22px" height="22px" viewBox="0 0 16 20">
      <path
        d="M14,2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V8Zm2,10H8v2h8Zm0,4H8v2h8ZM6,20H18V9H13V4H6Z"
        transform="translate(-4 -2)"
        fill="#0064dc"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default DocumentsNavIcon;
