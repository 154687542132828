import R from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, FieldArray, getFormValues, reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import {
  ALLOCATIONS_FORM_ID,
  ALLOCATIONS_FIELD_ID,
  ASYNC_BLUR_ALLOCATIONS_FIELD_NAME,
  CONTACT_ID_FIELD_ID,
  REGISTERED_NAME_FIELD_ID,
  BUSINESS_NUMBER_FIELD_ID,
  CHARITY_ID_FIELD_ID,
  ENTRY_TYPE_FIELD_ID,
  DONATIONS_CUSTOM_CHARITY,
  CHARITY_ALLOCATION,
  PERSON_ALLOCATION,
  DONATIONS_LIST_CHARITY,
} from "../../../constants/forms";
import { FormButtons } from "../../ui/Form";
import { asyncValidateAllocations } from "../utils/allocations-form-validations";
import PieChart from "../../ui/pie-chart/PieChart";
import InfoBoxGroup from "../../ui/InfoBox";
import { ErrorMessage } from "../../ui/Error";
import { SecondaryButton, SmallTextButton } from "../../ui/Button";
import DropdownContactSelect from "../../ui/inputs/DropdownContactSelect";
import {
  useValidateCharityMemo,
  useValidateCharityNameMemo,
  useValidateContactPresentMemo,
  useValidateSpecificPercentageMemo,
} from "../../ui/inputs/validations";
import TextInput from "../../ui/inputs/TextInput";
import { CharityDropdownSelect } from "../../ui/inputs/DropdownCharitySelect";
import {
  selectAllContacts,
  selectSpouseContact,
} from "../../../selectors/contacts";
import { openModalContactForContactable } from "../../../actions/contacts";
import { MODAL_ADD_CONTACT_ALLOCATION } from "../../../constants/modal";
import NumberInputField from "../../ui/inputs/NumberInputField";
import { updatePieChartData } from "../../../actions/allocations";

const getAllocationLevelTranslation = (
  appointeeLevelIndexs,
  translations,
  isPrimaryBeneficiarySpouse,
) => {
  let level = 0;
  if (isPrimaryBeneficiarySpouse) {
    // increases appointeeLevel if spouse is selected as primary appointee
    // so that the first backup appointee is declared as the "Secondary" appointee
    level += 1;
  }
  return `${translations.levels[level]} ${translations.appointeeTitle}`;
};

const PersonAllocationField = ({
  field,
  fieldId,
  fieldIndex,
  contacts,
  translations,
  formError,
  dispatch,
  allocationLevelText,
  handleRemoveField,
  triggerUpdatePieChart,
  isPrimaryBeneficiarySpouse,
  validAllocations,
}) => {
  const fieldContactId = field?.contactId || field?.contact?.id;
  const allSelectedContacts = validAllocations.reduce((arr, fieldObj) => {
    const contactId = fieldObj?.contactId || fieldObj?.contact?.id;
    if (contactId) {
      return [...arr, contactId];
    }
    return arr;
  }, []);
  const filteredContacts = contacts.filter((person) => {
    const contactId = person.contact.id;
    const keepSelectedContact = fieldContactId
      ? contactId === fieldContactId
      : false;
    return keepSelectedContact || !allSelectedContacts.includes(contactId);
  });
  const [selectedContactId, setSelectedContactId] = useState(fieldContactId);

  useEffect(() => {
    // when initial contactId is updated, update selectedContactId
    if (fieldContactId) {
      setSelectedContactId(fieldContactId);
    }
  }, [fieldContactId]);

  const { label } = translations;
  const spouseContact = R.pathOr({}, "")(useSelector(selectSpouseContact))[0];
  const spouseContactId = isPrimaryBeneficiarySpouse
    ? spouseContact?.contact?.id
    : null;

  const useValidateContactPresent = useValidateContactPresentMemo(translations);
  const useValidateSpecificPercentage = useValidateSpecificPercentageMemo(
    translations,
  );

  const handleSelectContact = (contactId) => {
    setSelectedContactId(contactId);
    triggerUpdatePieChart();
  };

  const handleAddNewContact = (contactableId) => {
    const isSecondaryContact = false;
    dispatch(
      openModalContactForContactable(
        null,
        MODAL_ADD_CONTACT_ALLOCATION,
        contactableId,
        isSecondaryContact,
        ALLOCATIONS_FORM_ID,
        ALLOCATIONS_FIELD_ID,
        fieldIndex,
      ),
    );
  };

  return (
    <Box mb={2}>
      <Box mb={1}>
        <DropdownContactSelect
          name={`${fieldId}.${CONTACT_ID_FIELD_ID}`}
          label={allocationLevelText}
          contacts={filteredContacts}
          selectedContactId={selectedContactId}
          disabledContactIds={[spouseContactId]}
          onSelect={(contactId) => {
            handleSelectContact(contactId);
          }}
          onAddNew={handleAddNewContact}
          validator={selectedContactId ? null : useValidateContactPresent}
          includeMinorChildren
        />
      </Box>
      {selectedContactId && (
        <Box mt={1}>
          <NumberInputField
            name={`${fieldId}.amount`}
            validate={useValidateSpecificPercentage}
            label={label.estateShare}
            placeholder={label.percentagePlaceholder}
            highLightFieldOnFormLevelError={formError}
            step="any"
            min="0"
            max="100"
            marginTop={0}
            percentage
            textAlignEnd
            noMargin
          />
        </Box>
      )}
      <Box mt={1} display="flex" justifyContent="flex-end" alignItems="center">
        <SmallTextButton
          onClick={handleRemoveField}
          text={translations.remove}
          className={`qa-remove-people-${fieldIndex}`}
        />
      </Box>
    </Box>
  );
};

const CharityAllocationField = ({
  fieldId,
  field = {},
  fieldIndex,
  allocationLevelText,
  charities,
  translations,
  formError,
  handleRemoveField,
  triggerUpdatePieChart,
  validAllocations,
}) => {
  const fieldCharityId = field?.charityId;
  const fieldRegisteredName = field?.registeredName;
  const allSelectedCharities = validAllocations.reduce((arr, fieldObj) => {
    if (fieldObj.charityId) {
      return [...arr, fieldObj.charityId];
    }
    return arr;
  }, []);
  const filteredCharities = charities.filter((charity) => {
    const charityId = charity.id;
    const keepSelectedCharity = fieldCharityId
      ? charityId === fieldCharityId
      : false;
    return keepSelectedCharity || !allSelectedCharities.includes(charityId);
  });

  const [selectedCharityId, setSelectedCharityId] = useState(fieldCharityId);
  const [isCustomCharity, setIsCustomCharity] = useState(false);
  const isCustomInput =
    isCustomCharity || field[ENTRY_TYPE_FIELD_ID] === DONATIONS_CUSTOM_CHARITY;

  const { label } = translations;
  const useValidateCharity = useValidateCharityMemo(translations);
  const useValidateCharityName = useValidateCharityNameMemo(translations);
  const useValidateSpecificPercentage = useValidateSpecificPercentageMemo(
    translations,
  );
  const charitiesForAllocations = filteredCharities.map((charity) => ({
    value: charity.id,
    label: charity.name,
  }));

  const handleSelectCharity = (charityId) => {
    setSelectedCharityId(charityId);
    triggerUpdatePieChart();
  };

  const handleToggleCustomInput = () => {
    field[ENTRY_TYPE_FIELD_ID] = DONATIONS_CUSTOM_CHARITY;
    setIsCustomCharity(true);
  };

  return (
    <Box mb={2}>
      {!isCustomInput && (
        <>
          <Field
            id={fieldId}
            name={`${fieldId}.${CHARITY_ID_FIELD_ID}`}
            label={allocationLevelText}
            placeholder={translations.selectCharityPlaceholder}
            component={CharityDropdownSelect}
            validate={useValidateCharity}
            optionList={charitiesForAllocations}
            selectedCharityId={selectedCharityId}
            onCustomInputClick={handleToggleCustomInput}
            onSelect={(charityId) => {
              handleSelectCharity(charityId);
            }}
            marginBottom={0}
          />
        </>
      )}
      {isCustomInput && (
        <>
          <Box mb={1}>
            <Field
              name={`${fieldId}.${REGISTERED_NAME_FIELD_ID}`}
              label={allocationLevelText}
              placeholder={translations.charityNamePlaceholder}
              component={TextInput}
              validate={useValidateCharityName}
              onBlur={() => triggerUpdatePieChart()}
              noMargin
            />
          </Box>
          <Box mb={1}>
            <Field
              name={`${fieldId}.${BUSINESS_NUMBER_FIELD_ID}`}
              label={label.charityBusinessNumberLabel}
              placeholder={translations.charityBusinessNumberPlaceholder}
              component={TextInput}
              noMargin
            />
          </Box>
        </>
      )}
      {(selectedCharityId || fieldRegisteredName) && (
        <Box mt={1}>
          <NumberInputField
            name={`${fieldId}.amount`}
            validate={useValidateSpecificPercentage}
            label={label.estateShare}
            placeholder={label.percentagePlaceholder}
            highLightFieldOnFormLevelError={formError}
            step="any"
            min="0"
            max="100"
            marginTop={0}
            percentage
            textAlignEnd
            noMargin
          />
        </Box>
      )}
      <Box mt={1} display="flex" justifyContent="flex-end" alignItems="center">
        <SmallTextButton
          onClick={handleRemoveField}
          text={translations.remove}
          className={`qa-remove-people-${fieldIndex}`}
        />
      </Box>
    </Box>
  );
};

export const AllocationsDropdownFieldArray = ({
  validAllocations,
  error,
  fields,
  translations,
  charities,
  contacts,
  isPrimaryBeneficiarySpouse,
  appointeeLevelIndexs,
  handleChange,
}) => {
  const dispatch = useDispatch();

  const triggerUpdatePieChart = (isRemoving = false) => {
    dispatch(
      updatePieChartData(ALLOCATIONS_FORM_ID, ALLOCATIONS_FIELD_ID, isRemoving),
    );
  };

  const handleAddPersonClick = () => {
    fields.push({ type: PERSON_ALLOCATION });
    triggerUpdatePieChart(true);
  };

  const handleAddCharityClick = () => {
    fields.push({
      type: CHARITY_ALLOCATION,
      entryType: DONATIONS_LIST_CHARITY,
    });
    triggerUpdatePieChart(true);
  };

  const fieldsCount = fields.getAll()?.length || 0;
  return (
    <Box mb={fieldsCount ? 2 : 0}>
      {fields.map((fieldId, fieldIndex) => {
        const field = fields.get(fieldIndex);
        if (field && field._destroy === true) {
          return null;
        }
        const fieldType = field?.type;

        const allocationLevelText = getAllocationLevelTranslation(
          appointeeLevelIndexs,
          translations,
          isPrimaryBeneficiarySpouse,
        );

        const handleRemoveField = () => {
          handleChange(`${fieldId}._destroy`, true);
          triggerUpdatePieChart(true);
        };

        return (
          <Box mb={1} key={`${fieldIndex + 1}-${fieldId}`}>
            {fieldType === PERSON_ALLOCATION ? (
              <PersonAllocationField
                field={field}
                fieldId={fieldId}
                fieldIndex={fieldIndex}
                translations={translations}
                contacts={contacts}
                allocationLevelText={allocationLevelText}
                formError={error}
                dispatch={dispatch}
                handleRemoveField={handleRemoveField}
                triggerUpdatePieChart={triggerUpdatePieChart}
                isPrimaryBeneficiarySpouse={isPrimaryBeneficiarySpouse}
                validAllocations={validAllocations}
              />
            ) : (
              <CharityAllocationField
                field={field}
                fieldId={fieldId}
                fieldIndex={fieldIndex}
                allocationLevelText={allocationLevelText}
                translations={translations}
                charities={charities}
                formError={error}
                handleRemoveField={handleRemoveField}
                triggerUpdatePieChart={triggerUpdatePieChart}
                validAllocations={validAllocations}
              />
            )}
          </Box>
        );
      })}
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Box mb={1}>
          <SecondaryButton
            fullWidth
            displayPlusIcon
            text={translations.button.addPerson}
            className="qa-add-person"
            onClick={handleAddPersonClick}
            type="button"
          />
        </Box>
        <Box>
          <SecondaryButton
            fullWidth
            displayPlusIcon
            text={translations.button.addCharity}
            className="qa-add-charity"
            onClick={handleAddCharityClick}
            type="button"
          />
        </Box>
      </Box>
      {error && (
        <Box mt={1}>
          <ErrorMessage error={error} />
        </Box>
      )}
    </Box>
  );
};

const AllocationsForm = ({
  error,
  handleSubmit,
  translations,
  charities,
  change,
  isLoading,
  backLink,
  chartAllocations,
  pieChartAnimationKey,
  isPrimaryBeneficiarySpouse,
  appointeeLevelIndexs,
}) => {
  const minWidth = 280;
  const maxWidth = 348;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const contacts = useSelector(selectAllContacts);

  const formData = useSelector(getFormValues(ALLOCATIONS_FORM_ID));
  const allocations = R.propOr([], ALLOCATIONS_FIELD_ID)(formData);
  const validAllocations = allocations.filter((a) => a._destroy !== true);
  const hasNoAllocations = validAllocations.length === 0;

  let infoBoxMargin = 0;
  if (chartAllocations.length > 0) {
    infoBoxMargin = isDesktop ? 4.5 : 3;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="row" justify="">
        <Grid item xl={6} lg={6} md={12} xs={12}>
          <Box>
            <FieldArray
              name={ALLOCATIONS_FIELD_ID}
              handleChange={change}
              charities={charities}
              contacts={contacts}
              component={AllocationsDropdownFieldArray}
              translations={translations}
              error={error}
              isPrimaryBeneficiarySpouse={isPrimaryBeneficiarySpouse}
              appointeeLevelIndexs={appointeeLevelIndexs}
              validAllocations={validAllocations}
            />
            <Box mt={1}>
              <FormButtons
                isLoading={isLoading}
                backLink={backLink}
                translations={translations}
                disabled={hasNoAllocations}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xl={2} lg={2} md={12} xs={12} />
        <Grid item xl={4} lg={4} md={12} xs={12}>
          {chartAllocations.length > 0 && (
            <Box mt={isDesktop ? 0 : 2}>
              <PieChart
                key={`pie-chart-${pieChartAnimationKey}`}
                chartData={chartAllocations}
                useShortTitle={isDesktop}
                minWidth={minWidth}
                maxWidth={maxWidth}
              />
            </Box>
          )}
          <Box mt={infoBoxMargin} minWidth={minWidth}>
            <InfoBoxGroup infoBox={translations.infoBox} />
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: ALLOCATIONS_FORM_ID,
  destroyOnUnmount: false, // required to preserve redux form state when adding beneficiary
  asyncValidate: asyncValidateAllocations,
  asyncBlurFields: [ASYNC_BLUR_ALLOCATIONS_FIELD_NAME],
})(AllocationsForm);
