import React from "react";
import SvgIcon from "./SvgIcon";

const WillfulLogoSymbol = () => {
  return (
    <SvgIcon width="44" height="24" viewBox="0 0 44 24" fill="none">
      <g>
        <path
          d="M37.6415 0.460103C37.6596 0.40675 37.6644 0.349816 37.6557 0.294176C37.6469 0.238536 37.6248 0.185849 37.5912 0.140625C37.5576 0.0954005 37.5136 0.0589874 37.4629 0.0345033C37.4122 0.0100193 37.3563 -0.00180636 37.3 3.90763e-05H29.6322C29.5542 -0.00507471 29.4767 0.016205 29.4122 0.0604561C29.3477 0.104707 29.3 0.169358 29.2767 0.244012L25.5126 12.0035L21.7763 0.244012C21.7563 0.172876 21.7133 0.110363 21.654 0.0662283C21.5948 0.0220934 21.5226 -0.00118035 21.4487 3.90763e-05H16.1928C16.1192 -2.48668e-05 16.0475 0.0236908 15.9885 0.0676536C15.9295 0.111616 15.8862 0.173474 15.8652 0.244012L12.101 12.0035L8.36473 0.244012C8.34118 0.168116 8.2924 0.102547 8.22647 0.0581741C8.16055 0.0138014 8.08144 -0.00671024 8.00226 3.90763e-05H0.348472C0.291601 -0.00298272 0.234856 0.00797772 0.183201 0.0319615C0.131546 0.0559453 0.0865558 0.0922216 0.0521655 0.137617C0.0177751 0.183012 -0.0049672 0.236143 -0.0140719 0.292362C-0.0231766 0.34858 -0.0183662 0.406174 -6.16685e-05 0.460103L7.93952 23.289C7.96359 23.3571 8.00783 23.4163 8.06636 23.4587C8.12489 23.501 8.19492 23.5245 8.26715 23.526H14.492C14.5644 23.5255 14.6349 23.5024 14.6936 23.4599C14.7524 23.4174 14.7964 23.3577 14.8196 23.289L18.8207 12.0035L22.8219 23.289C22.846 23.3571 22.8902 23.4163 22.9487 23.4587C23.0073 23.501 23.0773 23.5245 23.1495 23.526H29.3743C29.4465 23.5245 29.5166 23.501 29.5751 23.4587C29.6336 23.4163 29.6779 23.3571 29.7019 23.289L37.6415 0.460103Z"
          fill="white"
        />
        <path
          d="M38.8822 14.9381C37.9861 14.9381 37.1101 15.2038 36.365 15.7017C35.6199 16.1995 35.0392 16.9072 34.6962 17.7351C34.3533 18.563 34.2636 19.474 34.4384 20.3529C34.6132 21.2318 35.0447 22.0392 35.6784 22.6728C36.3121 23.3065 37.1194 23.738 37.9983 23.9129C38.8772 24.0877 39.7882 23.998 40.6162 23.655C41.4441 23.3121 42.1517 22.7313 42.6496 21.9862C43.1474 21.2411 43.4132 20.3651 43.4132 19.469C43.4245 18.8709 43.3151 18.2766 43.0914 17.7217C42.8677 17.1669 42.5344 16.6629 42.1114 16.2398C41.6884 15.8168 41.1844 15.4835 40.6295 15.2598C40.0747 15.0361 39.4804 14.9267 38.8822 14.9381Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  );
};

export default WillfulLogoSymbol;
