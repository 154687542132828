import { pathOr } from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, FieldArray, Form, reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";

import { ErrorMessage } from "../../ui/Error";
import {
  BACKUPS_FIELD_ID,
  BACKUPS_FORM_ID,
  BUSINESS_NUMBER_FIELD_ID,
  CHARITY_BACKUP,
  CHARITY_ID_FIELD_ID,
  CONTACT_ID_FIELD_ID,
  DONATIONS_CUSTOM_CHARITY,
  ENTRY_TYPE_FIELD_ID,
  PERSON_BACKUP,
  REGISTERED_NAME_FIELD_ID,
} from "../../../constants/forms";
import {
  useValidateCharityMemo,
  useValidateCharityNameMemo,
  useValidateContactPresentMemo,
} from "../../ui/inputs/validations";
import { SecondaryButton, SmallTextButton } from "../../ui/Button";
import { Body } from "../../ui/Typography";
import {
  selectAllContacts,
  selectSpouseContact,
} from "../../../selectors/contacts";
import { openModalContactForContactable } from "../../../actions/contacts";
import DropdownContactSelect from "../../ui/inputs/DropdownContactSelect";
import { MODAL_ADD_CONTACT_BACKUP } from "../../../constants/modal";
import { CharityDropdownSelect } from "../../ui/inputs/DropdownCharitySelect";
import { FormButtons } from "../../ui/Form";
import { selectCharitiesOptionList } from "../../../selectors/donations";
import { selectFormBackups } from "../../../selectors/backup";
import TextInput from "../../ui/inputs/TextInput";

const BackupContactSelect = ({
  fieldId,
  fieldIndex,
  field,
  contacts,
  translations,
}) => {
  const fieldContactId = field?.contact?.id;
  const [selectedContactId, setSelectedContactId] = useState(fieldContactId);

  useEffect(() => {
    // when initial contactId is updated, update selectedContactId
    if (fieldContactId) {
      setSelectedContactId(fieldContactId);
    }
  }, [fieldContactId]);

  const dispatch = useDispatch();

  const backupFormValues = useSelector(selectFormBackups);
  const currentlyUsedIds = backupFormValues
    .filter(({ type }) => type === PERSON_BACKUP)
    .map(({ contactId }) => contactId);
  const spouseContact = pathOr({}, "")(useSelector(selectSpouseContact))[0];
  const spouseContactId = spouseContact?.contact?.id;
  const disabledContactIds = [spouseContactId, ...currentlyUsedIds];

  const validateContact = useValidateContactPresentMemo(translations);

  const handleAddNewContact = (contactableId) => {
    dispatch(
      openModalContactForContactable(
        null,
        MODAL_ADD_CONTACT_BACKUP,
        contactableId,
        false,
        BACKUPS_FORM_ID,
        BACKUPS_FORM_ID,
        fieldIndex,
      ),
    );
  };

  return (
    <Box>
      <Box mb={1}>
        <DropdownContactSelect
          name={`${fieldId}.${CONTACT_ID_FIELD_ID}`}
          label={translations.backupRecipientLabel}
          contacts={contacts}
          disabledContactIds={disabledContactIds}
          selectedContactId={selectedContactId}
          onSelect={(contactId) => {
            setSelectedContactId(contactId);
          }}
          onAddNew={handleAddNewContact}
          validator={validateContact}
          includeMinorChildren
        />
      </Box>
    </Box>
  );
};

const BackupCharitySelect = ({
  field = {},
  fieldId,
  translations,
  charities,
}) => {
  const [selectedCharityId, setSelectedCharityId] = useState(null);
  const [isCustomCharity, setIsCustomCharity] = useState(false);

  const isCustomInput =
    isCustomCharity || field[ENTRY_TYPE_FIELD_ID] === DONATIONS_CUSTOM_CHARITY;

  const backupFormValues = useSelector(selectFormBackups);
  const currentlyUsedIds = backupFormValues
    .filter(({ type }) => type === CHARITY_BACKUP)
    .map(({ charityId }) => charityId);

  const validateCharity = useValidateCharityMemo(translations);
  const validateCharityName = useValidateCharityNameMemo(translations);

  function handleAddCustom() {
    field[ENTRY_TYPE_FIELD_ID] = DONATIONS_CUSTOM_CHARITY;
    setIsCustomCharity(true);
  }

  return (
    <>
      {!isCustomInput && (
        <Box mb={1}>
          <Field
            id={fieldId}
            name={`${fieldId}.${CHARITY_ID_FIELD_ID}`}
            label={translations.backupRecipientLabel}
            placeholder={translations.selectCharityLabel}
            component={CharityDropdownSelect}
            selectedCharityId={selectedCharityId}
            disabledCharityIds={currentlyUsedIds}
            validate={validateCharity}
            optionList={charities}
            onSelect={(charityId) => setSelectedCharityId(charityId)}
            onCustomInputClick={handleAddCustom}
            marginBottom={1}
          />
        </Box>
      )}
      {isCustomInput && (
        <Box mb={1}>
          <Box mb={1}>
            <Field
              name={`${fieldId}.${REGISTERED_NAME_FIELD_ID}`}
              label={translations.charityNameLabel}
              placeholder={translations.charityNamePlaceholder}
              component={TextInput}
              validate={validateCharityName}
              noMargin
            />
          </Box>
          <Box m={0}>
            <Field
              name={`${fieldId}.${BUSINESS_NUMBER_FIELD_ID}`}
              label={translations.charityBusinessNumberLabel}
              placeholder={translations.charityBusinessNumberPlaceholder}
              component={TextInput}
              noMargin
            />
          </Box>
        </Box>
      )}
    </>
  );
};

const BackupsFieldArrayRenderer = ({
  fields,
  translations,
  handleChange,
  defaultFieldObject = {},
  canAddMore = true,
  helperText,
}) => {
  const contacts = useSelector(selectAllContacts);
  const charities = useSelector(selectCharitiesOptionList);

  return (
    <Box>
      {fields.map((fieldId, index) => {
        const field = fields.get(index);

        // _destroy indicates to rails that field is to be deleted
        // requires rerenderOnEveryChange={true} on <FieldArray />
        // performance hit on large lists < 10 should be fine
        if (field && field._destroy === true) {
          return null;
        }

        const selectTypeField =
          field.type === CHARITY_BACKUP ? (
            <BackupCharitySelect
              fieldId={fieldId}
              field={field}
              translations={translations}
              charities={charities}
            />
          ) : (
            <BackupContactSelect
              fieldId={fieldId}
              fieldIndex={index}
              field={field}
              contacts={contacts}
              translations={translations}
            />
          );

        return (
          <Box key={fieldId}>
            {selectTypeField}
            <Box mb={2} width="100%" display="flex" flexDirection="row-reverse">
              <SmallTextButton
                text={translations.button.remove}
                className="qa-remove"
                onClick={() => handleChange(`${fieldId}._destroy`, true)}
              />
            </Box>
          </Box>
        );
      })}

      {helperText && <Body>{helperText}</Body>}

      {canAddMore && (
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box mb={1}>
            <SecondaryButton
              text={translations.button.addPerson}
              fullWidth
              displayPlusIcon
              className="qa-add-person"
              type="button"
              onClick={() =>
                fields.push({
                  ...defaultFieldObject,
                  type: PERSON_BACKUP,
                })
              }
            />
          </Box>
          <Box>
            <SecondaryButton
              text={translations.button.addCharity}
              fullWidth
              displayPlusIcon
              className="qa-add-charity"
              type="button"
              onClick={() =>
                fields.push({
                  ...defaultFieldObject,
                  type: CHARITY_BACKUP,
                })
              }
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

const BackupsForm = ({
  error,
  change,
  handleSubmit,
  backLink,
  onSkip,
  translations,
  isLoading,
}) => {
  return (
    <Form onSubmit={handleSubmit} className="mt-3">
      <Box mb={2}>
        <FieldArray
          rerenderOnEveryChange
          handleChange={change}
          name={BACKUPS_FIELD_ID}
          component={BackupsFieldArrayRenderer}
          translations={translations}
        />
        {error && <ErrorMessage error={error} />}
      </Box>
      <FormButtons
        qaFormId="backup"
        isLoading={isLoading}
        onSkip={onSkip}
        backLink={backLink}
        translations={translations}
      />
    </Form>
  );
};

export default reduxForm({
  form: BACKUPS_FORM_ID,
  destroyOnUnmount: false, // required to preserve redux form state when adding beneficiary
})(BackupsForm);
