import React from "react";
import Box from "@material-ui/core/Box";
import styled from "styled-components";

import SvgIcon from "./SvgIcon";
import { SmallBody } from "../Typography";

// TODO: Ask Barry for a font
const CartQuantityText = styled(SmallBody)`
  font-size: 10px;
  font-weight: 700;
`;
const badgePositioningMap = {
  mobile: {
    top: "13px",
    right: "-3px",
  },
  desktop: {
    top: "17px",
    right: "-3px",
  },
};
const badgeSizeMap = {
  mobile: {
    width: 24,
    height: 24,
  },
  desktop: {
    width: 30,
    height: 30,
  },
};

const CartIcon = ({ isMobile, cartItemCount = 0 }) => {
  const positioning = isMobile
    ? badgePositioningMap.mobile
    : badgePositioningMap.desktop;
  const size = isMobile ? badgeSizeMap.mobile : badgeSizeMap.desktop;

  return (
    <Box component="span" position="relative">
      <SvgIcon {...size} viewBox="0 0 30 30">
        <path
          d="M8,24H22a2,2,0,0,0,2-2V11a1,1,0,0,0-1-1H20V9A5,5,0,0,0,10,9v1H7a1,1,0,0,0-1,1V22A2,2,0,0,0,8,24ZM12,9a3,3,0,0,1,6,0v1H12ZM8,12h2v2h2V12h6v2h2V12h2V22H8Z"
          fill="#fff"
        />
      </SvgIcon>
      {cartItemCount !== 0 && (
        <Box
          component="span"
          width="16px"
          height="16px"
          borderRadius="8px"
          bgcolor="red"
          position="absolute"
          {...positioning}
        >
          <Box component="span" display="flex" justifyContent="center">
            <CartQuantityText component="span" color="white">
              {cartItemCount}
            </CartQuantityText>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CartIcon;
