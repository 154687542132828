import React from "react";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import TooltipInfoIcon from "./icons/TooltipInfoIcon";
import { BaseButton } from "./Button";

const StyledBaseButton = styled(BaseButton)`
  vertical-align: ${(props) => props.verticalAlign};
  margin-left: ${(props) => props.marginLeft};
  line-height: initial;

  &:focus {
    outline: 1px solid ${(props) => props.outlineColor};
  }
`;

const TooltipTrigger = ({
  translations,
  onClick,
  toolTipSize,
  ...otherProps
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const marginLeft = toolTipSize === "Large" && isDesktop ? "4px" : "0";
  const verticalAlign = toolTipSize === "Large" ? "initial" : "middle";
  return (
    // Trick: adding a non-breaking space before an icon, while wrapped within
    // a nowrap span, will allow the icon to follow the last word of a sentence.
    <span style={{ whiteSpace: "nowrap" }}>
      {"\u00a0"}
      <StyledBaseButton
        type="button"
        outlineColor={theme.palette.primary.main}
        verticalAlign={verticalAlign}
        onClick={onClick}
        marginLeft={marginLeft}
        {...otherProps}
      >
        <TooltipInfoIcon size={toolTipSize} />
        <Typography variant="srOnly">
          {translations.screenReaderText.tooltip}
        </Typography>
      </StyledBaseButton>
    </span>
  );
};

export default TooltipTrigger;
