import React from "react";

import { reduxForm } from "redux-form";

import { Form } from "../../ui/Form";

import BinaryRadioField from "../../ui/inputs/BinaryRadioField";
import { useValidateAshesMemo } from "../../ui/inputs/validations";

import { ASHES_FORM_ID, ASHES_FIELD_ID } from "../../../constants/forms";

const AshesForm = ({
  handleSubmit,
  backLink,
  onSkip,
  isLoading,
  translations,
}) => {
  return (
    <Form
      onSubmit={handleSubmit}
      isLoading={isLoading}
      backLink={backLink}
      onSkip={onSkip}
      translations={translations}
    >
      <BinaryRadioField
        name={ASHES_FIELD_ID}
        validate={useValidateAshesMemo(translations)}
        translations={translations}
      />
    </Form>
  );
};

export default reduxForm({
  form: ASHES_FORM_ID,
})(AshesForm);
