import React from "react";
import { useSelector } from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { Form } from "../../ui/Form";

import { FieldArrayRenderer } from "../../ui/inputs/FieldArrayRenderer";
import NameFields from "../../ui/inputs/NameFields";
import TextInput from "../../ui/inputs/TextInput";
import { ErrorMessage } from "../../ui/Error";
import {
  useValidateGiftDescriptionMemo,
  useValidateGiftDescriptionMemoQC,
} from "../../ui/inputs/validations";
import { SmallBody, SmallExternalLink } from "../../ui/Typography";

import {
  GIFTS_FIELD_ID,
  GIFTS_FORM_ID,
  GIFT_DESCRIPTION_FIELD_ID,
} from "../../../constants/forms";
import { selectIsCurrentProvinceSelectedByCode } from "../../../selectors/provinces";

const Gift = ({ id, shouldAutoFocus, translations }) => {
  const isQuebecSelected = useSelector(
    selectIsCurrentProvinceSelectedByCode("QC"),
  );
  const validationFunctionMemo = isQuebecSelected
    ? useValidateGiftDescriptionMemoQC
    : useValidateGiftDescriptionMemo;
  return (
    <Box>
      <Field
        name={`${id}.${GIFT_DESCRIPTION_FIELD_ID}`}
        autoFocus={shouldAutoFocus}
        component={TextInput}
        validate={validationFunctionMemo(translations)}
        label={translations.giftDescriptionLabel}
        placeholder={translations.giftPlaceholder}
      />
      <NameFields useTheir fieldName={id} translations={translations} />
    </Box>
  );
};

const BequestOfRRSP = ({ translations }) => {
  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12} md={8} lg={6}>
        <Box mt={1.5} textAlign="center">
          <SmallBody margin="16px" align="center">
            {translations.bequestOfRRSP}
          </SmallBody>
          <SmallExternalLink
            target="_blank"
            href={translations.bequestLink}
            text={translations.bequestLinkText}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const AssetListNote = ({ translations }) => {
  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12} md={8} lg={6}>
        <Box my={1.5} textAlign="center">
          <SmallBody align="center">{translations.assetListNote}</SmallBody>
        </Box>
      </Grid>
    </Grid>
  );
};

const GiftsHeaderComponent = ({
  requiresBequestOfRRSP,
  translations,
  isPremiumProvinceSelected,
  isCouplesPlan,
}) => {
  if (requiresBequestOfRRSP) {
    return <BequestOfRRSP translations={translations} />;
  }
  if (isPremiumProvinceSelected && !isCouplesPlan) {
    return <AssetListNote translations={translations} />;
  }
  return null;
};

const GiftsForm = ({
  error,
  handleSubmit,
  backLink,
  onSkip,
  change,
  isLoading,
  requiresBequestOfRRSP,
  translations,
  isPremiumProvinceSelected,
  isCouplesPlan,
}) => {
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Form
      qaFormId="gifts"
      isLoading={isLoading}
      onSubmit={handleSubmit}
      HeaderComponent={GiftsHeaderComponent}
      requiresBequestOfRRSP={requiresBequestOfRRSP}
      isPremiumProvinceSelected={isPremiumProvinceSelected}
      isCouplesPlan={isCouplesPlan}
      backLink={backLink}
      onSkip={onSkip}
      translations={translations}
      reverseWrap={isTabletDown}
    >
      <FieldArray
        rerenderOnEveryChange
        handleChange={change}
        name={GIFTS_FIELD_ID}
        component={FieldArrayRenderer}
        RenderedComponent={Gift}
        translations={translations}
      />
      <Box mb={2}>
        <ErrorMessage error={error} />
      </Box>
    </Form>
  );
};

export default reduxForm({
  form: GIFTS_FORM_ID,
})(GiftsForm);
