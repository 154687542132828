import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const StyledPreviewPDF = styled(Box)`
  height: 980px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
`;

const CACHED_PDF_PATH = "/pdfs/Sample Will 2023.pdf";

const PreviewPDF = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const dateTimeNow = new Date().getTime();
  // if browser is safari we add datetime to the url force it to not
  // cache the pdf, otherwise it will only render the file once
  const pdfPath = isSafari
    ? `${CACHED_PDF_PATH}?${dateTimeNow}`
    : CACHED_PDF_PATH;

  return (
    <StyledPreviewPDF>
      <embed
        src={pdfPath}
        alt={CACHED_PDF_PATH}
        type="application/pdf"
        width="100%"
        height="100%"
      />
    </StyledPreviewPDF>
  );
};

export default PreviewPDF;
