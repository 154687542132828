import React from "react";

import { reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";

import { PrimaryButton, TextButton } from "../../ui/Button";
import { ErrorMessage } from "../../ui/Error";

import {
  PERSON_ALLOCATION_FORM_ID,
  ADD_PERSON_BENEFICIARY_FIELD_ID,
} from "../../../constants/forms";
import NameFields from "../../ui/inputs/NameFields";

const PersonAllocationForm = ({
  error,
  handleSubmit,
  closeModal,
  isLoading,
  translations,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" justifyContent="center" pt={2}>
        <NameFields
          useTheir
          fieldName={ADD_PERSON_BENEFICIARY_FIELD_ID}
          translations={translations}
        />
        <Box>
          <PrimaryButton
            fullWidth
            isLoading={isLoading}
            text={translations.button.submit}
          />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" mt={1}>
          <TextButton text={translations.button.cancel} onClick={closeModal} />
        </Box>
        <ErrorMessage error={error} />
      </Box>
    </form>
  );
};

export default reduxForm({
  form: PERSON_ALLOCATION_FORM_ID,
})(PersonAllocationForm);
