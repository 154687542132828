import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { goBack } from "connected-react-router";

import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { PageLayoutWithMiniForm } from "../../components/ui/PageLayout";
import { Body, H1, H3 } from "../../components/ui/Typography";

import {
  contactPageProps,
  selectFamilyAndFriendsContacts,
  selectMyHouseholdContacts,
} from "../../selectors/contacts";
import { ContactCard } from "../../components/ui/ContactCard";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { fetchDashboard } from "../../actions/dashboard";
import { LAYOUT_MAX_WIDTH_WITH_MARGIN } from "../../constants/layout";

const ContactsPage = ({ translations, hasLoaded }: ContactsPageProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const pageTopPadding = isDesktop ? 4.5 : 1;
  const { pageTitle, description } = translations;

  useEffect(() => {
    dispatch(fetchDashboard());
  }, [fetchDashboard]);

  const myHouseholdContacts: ContactPayload[] = useSelector(
    selectMyHouseholdContacts,
  );
  const shouldDisplayMyHouseholdContacts = myHouseholdContacts.length !== 0;
  const myFamilyAndFriendsContacts: ContactPayload[] = useSelector(
    selectFamilyAndFriendsContacts,
  );
  const shouldDisplayMyFamilyAndFriendsContacts =
    myFamilyAndFriendsContacts.length !== 0;

  if (!hasLoaded)
    return (
      <Box flexGrow="1">
        <LoadingSpinner />
      </Box>
    );

  return (
    <PageLayoutWithMiniForm flexGrow={1}>
      <Box mx={2} maxWidth={LAYOUT_MAX_WIDTH_WITH_MARGIN}>
        {/* Top Box */}
        <Box>
          <H1 align="left">{pageTitle}</H1>
          <Body>{description}</Body>
        </Box>

        {/* My Household */}
        <>
          {shouldDisplayMyHouseholdContacts && (
            <Box pt={pageTopPadding}>
              <H3 align="left">{translations.groups.houseHold}</H3>
              <Box pt={1}>
                <Grid container spacing={1}>
                  {myHouseholdContacts.map((person: ContactPayload) => (
                    <Grid item md={6} sm={12} key={person.contact.id}>
                      <ContactCard person={person} showBadge />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          )}
        </>

        {/* Family and Friends */}
        <>
          {shouldDisplayMyFamilyAndFriendsContacts && (
            <Box mt={2.5}>
              <H3 align="left">{translations.groups.familyAndFriends}</H3>
              <Box pt={1}>
                <Grid container spacing={1}>
                  {myFamilyAndFriendsContacts.map((person: ContactPayload) => (
                    <Grid item md={6} sm={12} key={person.contact.id}>
                      <ContactCard person={person} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          )}
        </>
      </Box>
    </PageLayoutWithMiniForm>
  );
};

export default connect(contactPageProps, {
  goBack,
})(ContactsPage);
