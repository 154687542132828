import React, { useState } from "react";
import styled from "styled-components";

import { Box } from "@material-ui/core";
import { H4, SmallBody } from "./Typography";
import NumberInputField from "./inputs/NumberInputField";
import { SmallTextButton } from "./Button";
import { ArrowBlueIcon } from "./icons/ArrowIcons";
import { colorPalette } from "../../config/Theme";
import {
  getAllocationName,
  getAllocationTypeLabel,
} from "../../utilities/allocations";
import { useValidateSpecificPercentageMemo } from "./inputs/validations";
import { ErrorMessage } from "./Error";

const StyledColorBar = styled(Box)`
  height: 8px;
  border-top: 1px solid ${colorPalette.willfulGrey2};
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 100%;
`;

const EditAndRemoveButtons = ({ translations, onEdit, onRemove }) => {
  return (
    <Box my={1.5} display="flex" flexDirection="row">
      <Box mr={1.5}>
        <SmallTextButton
          onClick={() => onEdit()}
          text={translations.edit}
          className="qa-edit-allocation"
        />
      </Box>
      <SmallTextButton
        onClick={() => onRemove()}
        text={translations.remove}
        className="qa-remove-allocation"
      />
    </Box>
  );
};

const DetailsButton = ({ translations, isCardExpanded, onClick }) => {
  return (
    <Box
      style={{
        cursor: "pointer",
        borderTop: `1px solid ${colorPalette.willfulGrey2}`,
      }}
      onClick={onClick}
      display="flex"
      justifyContent="center"
      alignItems="center"
      py={0.5}
    >
      <Box mr={0.5}>
        <SmallBody bold component="span" color="willfulBlue">
          {translations.details}
        </SmallBody>
      </Box>
      <ArrowBlueIcon small up={!isCardExpanded} />
    </Box>
  );
};

const ColoBarFooter = ({ color }) => {
  return <StyledColorBar bgcolor={color} />;
};

const AllocationCard = ({
  fieldId,
  translations,
  allocation,
  charities = [],
  showDetails = false,
  color,
  onEdit,
  onRemove,
  formError,
  isPrimaryBeneficiarySpouse,
}) => {
  const [isCardExpanded, setIsCardExpanded] = useState(false);
  const [cardError, setCardError] = useState(false);

  const handleCardError = (errorMsg) => {
    setCardError(errorMsg);
  };

  return (
    <>
      <Box
        borderRadius="4px"
        borderColor={colorPalette.willfulGrey2}
        bgcolor={colorPalette.white}
        border={1}
      >
        <Box px={1.5} pt={1.5}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box>
              <Box mb={0.5} mr={0.5}>
                <H4 align="left" data-hj-suppress>
                  {getAllocationName(allocation, charities)}
                </H4>
              </Box>
              <Box>
                <SmallBody
                  component="span"
                  style={{ color: colorPalette.willfulGrey1 }}
                >
                  {getAllocationTypeLabel(
                    allocation,
                    translations,
                    isPrimaryBeneficiarySpouse,
                  )}
                </SmallBody>
              </Box>
            </Box>
            <Box minWidth="120px" width="120px">
              <NumberInputField
                name={`${fieldId}.amount`}
                onError={handleCardError}
                validate={useValidateSpecificPercentageMemo(translations)}
                placeholder={translations.label.percentagePlaceholder}
                highLightFieldOnFormLevelError={formError}
                step="any"
                min="0"
                max="100"
                label=""
                marginTop={0}
                percentage
                noMargin
                small
                hideFieldLevelError
              />
            </Box>
          </Box>

          {isCardExpanded && (
            <Box>
              <SmallBody>Beneficiary expanded information</SmallBody>
            </Box>
          )}

          <EditAndRemoveButtons
            translations={translations}
            onEdit={onEdit}
            onRemove={onRemove}
          />
        </Box>

        {showDetails && (
          <DetailsButton
            translations={translations}
            isCardExpanded={isCardExpanded}
            onClick={() => setIsCardExpanded(!isCardExpanded)}
          />
        )}

        <ColoBarFooter color={color} />
      </Box>

      {cardError && (
        <Box mt={1}>
          <ErrorMessage error={cardError} />
        </Box>
      )}
    </>
  );
};

export default AllocationCard;
