import R from "ramda";
import { select, call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import {
  requestStart,
  requestSuccess,
  requestError,
} from "../actions/requests";

import { updateStatus } from "../actions/status";
import { authenticated, clearState } from "../actions/auth";
import { selectAuthHeaders } from "../selectors/auth";
import { selectIsCurrentUserSpouse } from "../selectors/spouse";
import { displayModal } from "../actions/modal";
import { displayToast } from "../actions/toast";
import {
  FRENCH_NOT_AVAILABLE,
  REVSHARE_PARTNER_DISCOUNT_APPLIED,
  REVSHARE_UNLIMITED_UPDATES_PARTNER_DISCOUNT_APPLIED,
  VOLUME_PARTNER_DISCOUNT_APPLIED,
} from "../constants/modal";
import { AUTH_LOGIN_ENDPOINT, LOGIN_PATH } from "../constants/routes";
import { displayTooltipModal } from "../actions/tooltip-modal";
import {
  selectAllExperiments,
  selectLocalStorageExperimentsValues,
} from "../selectors/experiments";
import { analytics401Error } from "../actions/analytics";

// TODO: Figure out long-term solution for circular-dependency selector issue
const selectIsLoggedIn = R.pathOr(false, ["auth", "loggedIn"]);

export function* requestSequence(apiCall, options, data) {
  try {
    const isLoggedIn = yield select(selectIsLoggedIn);
    const authHeaders = yield select(selectAuthHeaders);
    const isSpouse = yield select(selectIsCurrentUserSpouse);
    const experimentsInReduxStore = yield select(selectAllExperiments);
    const experimentsInLocalStorage = yield select(
      selectLocalStorageExperimentsValues,
    );
    let experiments = experimentsInReduxStore;
    if (experimentsInLocalStorage.length > 0) {
      // if there are experiments in local storage, merge them with the experiments in the redux store
      experimentsInLocalStorage.forEach((experiment) => {
        experiments = {
          ...experiments,
          ...experiment,
        };
      });
    }

    yield put(requestStart(options));

    const requestConfig = {
      data,
      isLoggedIn,
      authHeaders,
      isSpouse,
      experiments,
    };
    const response = yield call(apiCall, requestConfig);
    const responseBody = yield call([response, response.json]);

    if (response.status === 401) {
      yield put(analytics401Error({ type: options.type }));
      // Clear state if you are unauthorized on login.
      // Avoid clearing state if this was a login attempt, so that the username the user entered
      // is preserved between attempts.
      if (!response.url.includes(AUTH_LOGIN_ENDPOINT)) {
        yield put(clearState({ redirectTo: LOGIN_PATH }));
      }

      const { errors } = responseBody;
      const error = new Error("Not authorized");
      error.formErrors = errors;
      throw error;
    }
    yield call(setAuthHeaders, response);

    yield put(
      requestSuccess({
        type: options.type,
        payload: data,
        meta: {
          response,
          isSpouseValues: options.meta.isSpouseValues || isSpouse, // pass spouseValues flag in meta to signify for analytics
        },
      }),
    );
    return { ...responseBody, status: response.status };
  } catch (error) {
    yield put(
      requestError({
        ...options.meta,
        type: options.type,
        meta: {
          error,
        },
      }),
    );
    if (error.formErrors) {
      throw error;
    }
    return null;
  }
}

function* setAuthHeaders({ headers }) {
  const accessToken = headers.get("access-token");
  if (accessToken) {
    const authHeaders = {
      accessToken,
      uid: headers.get("uid"),
      client: headers.get("client"),
    };
    yield put(authenticated(authHeaders));
  }
}

export function* handleMeta({ links, notice, info, toast }) {
  if (links && links.redirectTo) {
    yield put(push(links.redirectTo));
  }
  if (info) {
    yield put(updateStatus(info));
  }
  if (notice) {
    if (
      notice === VOLUME_PARTNER_DISCOUNT_APPLIED ||
      notice === REVSHARE_PARTNER_DISCOUNT_APPLIED ||
      notice === REVSHARE_UNLIMITED_UPDATES_PARTNER_DISCOUNT_APPLIED
    ) {
      yield put(displayTooltipModal(notice));
    } else if (notice === FRENCH_NOT_AVAILABLE) {
      yield put(displayModal(notice, true));
    } else {
      yield put(displayModal(notice));
    }
  }
  if (toast) {
    yield put(displayToast(toast));
  }
}

export function* fetchApiData({ apiCall, formId }) {
  const requestArgs = { apiCall, formId, type: "fetch" };
  return yield call(sendApiData, requestArgs);
}

export function* putApiData({ apiCall, formId }) {
  const requestArgs = { apiCall, formId, type: "put" };
  return yield call(sendApiData, requestArgs);
}

function* sendApiData({ apiCall, formId, type }) {
  const response = yield call(requestSequence, apiCall, {
    type: formId,
    meta: {
      request: type,
    },
  });
  const data = R.propOr(null, "data")(response);
  const meta = R.propOr(null, "meta")(response);
  if (meta) {
    yield call(handleMeta, meta);
  }
  return data;
}
