import React from "react";

import SvgIcon from "./SvgIcon";
import { colorPalette } from "../../../config/Theme";

const LockSmall = ({
  width = 16,
  height = 16,
  stroke = colorPalette.willfulGrey1,
}) => (
  <SvgIcon width={width} height={height} viewBox="0 0 16 16" fill="none">
    <rect
      x="3.41675"
      y="7.41602"
      width="9.16667"
      height="5.83333"
      rx="1.25"
      stroke={stroke}
      strokeWidth="1.5"
    />
    <path
      d="M5.41675 5.33333C5.41675 3.9066 6.57335 2.75 8.00008 2.75C9.42682 2.75 10.5834 3.9066 10.5834 5.33333V7.25H5.41675V5.33333Z"
      stroke="#70707A"
      strokeWidth="1.5"
    />
  </SvgIcon>
);

export default LockSmall;
