import React from "react";
import { reduxForm } from "redux-form";

import Box from "@material-ui/core/Box";

import {
  UPDATE_PASSWORD_FORM_ID,
  OLD_PASSWORD_FIELD_ID,
  PASSWORD_FIELD_ID,
  PASSWORD_CONF_FIELD_ID,
} from "../../../constants/forms";
import PasswordField from "../../ui/inputs/PasswordField";
import { PrimaryButton } from "../../ui/Button";
import {
  useValidateOldPasswordMemo,
  useValidateNewPasswordMemo,
  useValidateConfirmPasswordMemo,
} from "../../ui/inputs/validations";

const ChangeYourPasswordForm = ({
  handleSubmit,
  translations,
  isNewPasswordBeingEntered,
  isLoading,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Box width={1} mb={4.5}>
        <Box mb={2}>
          <PasswordField
            name={OLD_PASSWORD_FIELD_ID}
            validation={useValidateOldPasswordMemo(translations)}
            translations={translations.oldPassword}
          />
          <PasswordField
            name={PASSWORD_FIELD_ID}
            validation={useValidateNewPasswordMemo(translations)}
            translations={translations.newPassword}
            showPasswordStrength
          />
          <PasswordField
            name={PASSWORD_CONF_FIELD_ID}
            validation={useValidateConfirmPasswordMemo(translations)}
            translations={translations.confirmPassword}
            hidden={!isNewPasswordBeingEntered}
            newPasswordMatch={true}
          />
        </Box>
        <PrimaryButton
          loadingLabel={translations.button.loadingLabel}
          text={translations.button.submit}
          isLoading={isLoading}
        />
      </Box>
    </form>
  );
};

export default reduxForm({
  form: UPDATE_PASSWORD_FORM_ID,
  destroyOnUnmount: false, // required to preserve BE errors being overriden on re-renders
})(ChangeYourPasswordForm);
