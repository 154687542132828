import React from "react";
import { reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";

import { ErrorMessage } from "../../ui/Error";

import { ADD_BENEFICIARY_FORM_ID } from "../../../constants/forms";
import { SecondaryButton } from "../../ui/Button";
import { MODAL_ADD_BENEFICIARY_CHARITY } from "../../../constants/modal";

const AddBeneficiaryForm = ({
  error,
  handleSubmit,
  displayModal,
  hideButtons = false,
  translations,
  modalKey,
}) => {
  const handleAddPersonClick = () => {
    displayModal(modalKey);
  };
  const handleAddCharityClick = () => {
    displayModal(MODAL_ADD_BENEFICIARY_CHARITY);
  };
  return (
    <form onSubmit={handleSubmit}>
      {!hideButtons && (
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box mb={1}>
            <SecondaryButton
              fullWidth
              displayPlusIcon
              text={translations.button.choosePerson}
              className="qa-add-person"
              onClick={handleAddPersonClick}
              type="button"
            />
          </Box>
          <Box>
            <SecondaryButton
              fullWidth
              displayPlusIcon
              text={translations.button.chooseCharity}
              className="qa-add-charity"
              onClick={handleAddCharityClick}
              type="button"
            />
          </Box>
        </Box>
      )}
      <Box>
        <ErrorMessage error={error} />
      </Box>
    </form>
  );
};

export default reduxForm({
  form: ADD_BENEFICIARY_FORM_ID,
  initialValues: {
    CharityAllocation: {
      entryType: "select", // todo use constants instead of hardcoded values
    },
    charityBeneficiary: {
      entryType: "select", // todo use constants instead of hardcoded values
    },
  },
})(AddBeneficiaryForm);
