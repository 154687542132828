export const fetchResidence = () => ({
  type: FETCH_RESIDENCE_TYPE,
});

export const fetchDOB = () => ({
  type: FETCH_DOB_TYPE,
});

export const submitAboutYou = (formId) => ({
  type: SUBMIT_ABOUT_YOU_TYPE,
  payload: { formId },
});

export const submitPeopleQuantityIntent = () => ({
  type: SUBMIT_PEOPLE_QUANTITY_INTENT_TYPE,
});

export const SUBMIT_ABOUT_YOU_TYPE = "SUBMIT_ABOUT_YOU";
export const FETCH_RESIDENCE_TYPE = "FETCH_RESIDENCE_TYPE";
export const SUBMIT_PEOPLE_QUANTITY_INTENT_TYPE =
  "SUBMIT_PEOPLE_QUANTITY_INTENT";
export const FETCH_DOB_TYPE = "FETCH_DOB_TYPE";
