import React from "react";
import BodyWithUnderlineLink from "./BodyWithUnderlineLink";
import { H2 } from "./Typography";

interface H2WithUnderlineLinkProps {
  text: string;
  underlineText: string;
  onClick: () => void;
}

const H2WithUnderlineLink = ({
  text,
  underlineText,
  onClick,
}: H2WithUnderlineLinkProps) => {
  if (!text || !underlineText) {
    return null;
  }
  if (!text.includes(underlineText)) {
    return <H2 tabIndex={0}>{text}</H2>;
  }
  return (
    <H2 tabIndex={0}>
      <BodyWithUnderlineLink
        text={text}
        underlineText={underlineText}
        onClick={onClick}
      />
    </H2>
  );
};

export default H2WithUnderlineLink;
