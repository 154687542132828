import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import SpouseForm from "../../../components/forms/your-family/SpouseForm";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { spousePageProps } from "../../../selectors/spouse";
import { MARITAL_STATUS_PATH } from "../../../constants/routes";
import { fetchSpouseBasicInfo, spouseNextPage } from "../../../actions/spouse";

const SpousePage = ({ hasLoaded, ...restOfProps }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSpouseBasicInfo());
  }, [dispatch]);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <SpouseForm
      backLink={MARITAL_STATUS_PATH}
      onSubmit={() => dispatch(spouseNextPage())}
      {...restOfProps}
    />
  );
};

export default connect(spousePageProps)(SpousePage);
