import React from "react";
import { Field } from "redux-form";
import { useValidatePhoneNumberMemo } from "./validations";

import TextInput from "./TextInput";
import { PHONE_NUMBER_FIELD_ID } from "../../../constants/forms";

const PhoneNumberInputField = ({ translations }) => {
  return (
    <Field
      component={TextInput}
      validate={useValidatePhoneNumberMemo(translations)}
      label={translations.label.phoneNumber}
      placeholder={translations.label.phoneNumberPlaceholder}
      name={PHONE_NUMBER_FIELD_ID}
      type="tel"
    />
  );
};

export default PhoneNumberInputField;
