import React, { useEffect } from "react";
import { connect } from "react-redux";

import { useTheme, withTheme } from "@material-ui/styles";
import { Box, Grid, Hidden, useMediaQuery } from "@material-ui/core";
import { PageWrapper } from "../Layout";
import { ButtonLink, PreviousLink } from "../../components/ui/Button";
import { PageLayout } from "../../components/ui/PageLayout";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { Body, H2, SectionTitle } from "../../components/ui/Typography";
import FamilyPlanGuidanceIcon from "../../components/ui/icons/FamilyPlanGuidanceIcon";

import { fetchDashboard } from "../../actions/dashboard";
import { CHECKOUT_PATH, PLANS_PATH } from "../../constants/routes";
import { FamilyPlanGuidanceProps } from "../../selectors/family-plan-guidance";
import { displayChat } from "../../actions/customer-service";
import {
  analyticsClickedCheckoutNow,
  analyticsClickExternalLink,
  analyticsOpenedChat,
} from "../../actions/analytics";

const FamilyPlanGuidancePage = ({
  translations,
  hasLoaded,
  fetchDashboard,
  nextSectionPath,
  analyticsClickedCheckoutNow,
}) => {
  useEffect(() => {
    if (!hasLoaded) {
      fetchDashboard();
    }
  }, [fetchDashboard, hasLoaded]);

  const theme = useTheme();
  const isLargeDesktop = useMediaQuery(theme.breakpoints.only("xl"));
  const isDesktop = useMediaQuery(theme.breakpoints.only("lg"));
  if (!hasLoaded)
    return (
      <Box flexGrow="1">
        <LoadingSpinner />
      </Box>
    );

  return (
    <Box
      width="100%"
      height="100%"
      bgcolor="willfulxxxLightBlue.main"
      flex="1"
      display="flex"
      flexDirection="column"
      alignItems="center"
      flexGrow={1}
    >
      <PageWrapper flexGrow={1}>
        <PageLayout>
          <Grid container>
            <Grid item xl={1} lg={0} md={1} />
            <Grid item xl={10} lg={12} md={10} xs={12}>
              <Grid item container xl={12} lg={12} md={12} xs={12}>
                <Grid item xl={6} lg={6} md={12} xs={12}>
                  <Box mb={isLargeDesktop || isDesktop ? 4.5 : 3}>
                    <Hidden lgUp>
                      <Box align="center" mb={2}>
                        <FamilyPlanGuidanceIcon isDesktop={false} />
                      </Box>
                    </Hidden>
                    <Box mb={1}>
                      <Box mb={1}>
                        <SectionTitle color="willfulBlue" align="left">
                          {translations.importantNote}
                        </SectionTitle>
                      </Box>
                      <H2 align="left" marginBottom="0">
                        {translations.title}
                      </H2>
                    </Box>
                    <Box>
                      <Body>{translations.paragraph1}</Body>
                    </Box>
                    <Box>
                      <Body component="span" bold={false}>
                        {translations.paragraph2}
                      </Body>
                    </Box>
                    <Box mt={2} display="inline-block">
                      <Box display="flex">
                        <Box mr={1}>
                          <ButtonLink
                            className="qa-continue"
                            to={nextSectionPath}
                            text={translations.continueButton}
                            displayArrowRight
                          />
                        </Box>
                        <Box>
                          <ButtonLink
                            onClick={() => analyticsClickedCheckoutNow()}
                            className="qa-secondary"
                            to={CHECKOUT_PATH}
                            text={translations.payNowButton}
                            secondary
                          />
                        </Box>
                      </Box>
                      <Box mt={1.5} textAlign="center">
                        <PreviousLink
                          className="qa-back"
                          to={PLANS_PATH}
                          text={translations.previousButton}
                          displayArrowLeft
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Hidden mdDown>
                  <Grid item xl={1} lg={1} />
                  <Grid item xl={4} lg={4}>
                    <Box align="right" mb={2}>
                      <FamilyPlanGuidanceIcon isDesktop />
                    </Box>
                  </Grid>
                  <Grid item xl={1} lg={1} />
                </Hidden>
              </Grid>
            </Grid>
            <Grid item xl={1} lg={0} md={1} />
          </Grid>
        </PageLayout>
      </PageWrapper>
    </Box>
  );
};

export default connect(FamilyPlanGuidanceProps, {
  fetchDashboard,
  displayChat,
  analyticsOpenedChat,
  analyticsClickExternalLink,
  analyticsClickedCheckoutNow,
})(withTheme(FamilyPlanGuidancePage));
