import React from "react";

import SvgIcon from "./SvgIcon";

const PhysicalAssetIcon = ({ isDesktop }) => {
  return (
    <SvgIcon fill="none" width={isDesktop ? 100 : 72} viewBox="0 0 100 68.67">
      <g clipPath="url(#clip0_8353_84643)">
        <path
          d="M50 68.6691C77.6142 68.6691 100 53.7773 100 35.4073C100 17.0373 77.6142 2.14551 50 2.14551C22.3858 2.14551 0 17.0373 0 35.4073C0 53.7773 22.3858 68.6691 50 68.6691Z"
          fill="#F6F6F6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.2662 42.403C44.8928 41.6304 42.1461 42.1884 40.1718 43.7334C38.5838 44.9781 37.983 46.4373 38.5409 47.3815L35.0645 50.1283C34.5924 50.4716 34.5495 50.9866 34.9787 51.2442C35.4079 51.5017 36.1375 51.3729 36.5667 51.0296L40.0431 48.2828C41.5452 48.4974 43.6053 47.8965 45.1933 46.6519C47.2104 45.1068 47.6825 43.2184 46.2662 42.403Z"
          fill="#2F455F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.0645 3.21911L45.1503 3.26203C46.5237 4.03456 45.837 6.18048 44.249 5.96589L44.0773 5.92297C42.6181 5.7513 42.3177 3.81997 43.6052 3.17619L43.6911 3.13327C44.1203 2.9616 44.6353 3.00452 45.0645 3.21911ZM57.6396 45.4079L66.867 45.1504C67.1245 45.1504 67.3391 45.0646 67.5537 44.9788L90.4293 31.9745C91.4164 31.4165 91.4164 30.0431 90.4722 29.4423L54.8069 7.76847C53.7769 7.12469 52.4894 7.98306 52.6181 9.18478L56.2233 44.0775C56.2662 44.85 56.91 45.4509 57.6396 45.4079Z"
          fill="#2F455F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2017 17.8538V32.7465L29.5278 43.4761V29.0126L21.7596 16.4375L11.2017 17.8538Z"
          fill="#E2F6FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.043 0L59.3992 12.4464L36.6953 26.1373L29.528 30.3433L20.4722 17.382L50.043 0Z"
          fill="#0064DC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.6952 39.3137L29.5278 43.4768V30.3437L36.6952 26.1377V39.3137Z"
          fill="#71ADEE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.6953 39.3137L57.0387 51.2879V38.1549L36.6953 26.1377V39.3137Z"
          fill="#E2F6FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.9098 22.0176L57.0386 37.039V51.288L77.4248 39.3137V23.5197L66.9098 22.0176Z"
          fill="#71ADEE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.0387 38.1542L67.9399 22.6178L47.5966 10.6006L36.6953 26.1371L57.0387 38.1542Z"
          fill="#ABE0F6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.7383 23.648L59.013 24.9355L61.2018 23.648L59.013 22.4033L56.7383 23.648Z"
          fill="#0064DC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.94 22.6178L77.425 23.519L59.3993 12.4461L47.5967 10.6006L67.94 22.6178Z"
          fill="#0064DC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.0129 24.936V28.4982L61.2018 25.4081V23.6484L59.0129 24.936Z"
          fill="#71ADEE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.2489 34.2485L40.5579 32.1025V37.1669L44.2489 39.3558V34.2485Z"
          fill="#ABE0F6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.8456 38.7982L48.1975 36.6094V41.7167L51.8456 43.8626V38.7982Z"
          fill="#ABE0F6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2404 27.3823L14.5923 25.2363V30.3007L18.2404 32.4896V27.3823Z"
          fill="#ABE0F6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.8798 31.931L22.2317 29.7422V34.8495L25.8798 36.9954V31.931Z"
          fill="#ABE0F6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M62.2747 38.4115L65.9227 36.2656V41.33L62.2747 43.5188V38.4115Z"
          fill="#0064DC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.5623 41.5881L69.6567 43.8628V34.0774L73.5623 31.8027V41.5881Z"
          fill="#FFDC1E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.751 51.3735C35.0643 51.3735 34.5063 50.8155 34.5063 50.1288V40.9014H36.9956V50.1288C36.9956 50.7726 36.4377 51.3735 35.751 51.3735Z"
          fill="#0064DC"
        />
        <path
          d="M35.7511 45.1936C38.0029 45.1936 39.8283 42.8109 39.8283 39.8717C39.8283 36.9325 38.0029 34.5498 35.7511 34.5498C33.4993 34.5498 31.6738 36.9325 31.6738 39.8717C31.6738 42.8109 33.4993 45.1936 35.7511 45.1936Z"
          fill="#ABE0F6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4721 17.382L10.8584 17.897L40.4292 0.51502L50.0429 0L20.4721 17.382Z"
          fill="#ABE0F6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.013 24.936V28.4983L56.7383 27.1678V23.6914L59.013 24.936Z"
          fill="#E2F6FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.9915 41.5879C43.2619 41.5879 42.6181 41.9742 42.189 42.575L46.5666 45.1501C46.5666 45.0643 46.5666 44.9785 46.5666 44.8926C46.5666 43.09 45.4078 41.5879 43.9915 41.5879Z"
          fill="#71ADEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_8353_84643">
          <rect width="100" height="68.6695" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default PhysicalAssetIcon;
