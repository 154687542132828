import React from "react";

import SvgIcon from "./SvgIcon";

export const ArrowBlueUpIcon = ({ small }) => {
  const size = small ? "16px" : "24px";
  return (
    <SvgIcon width={size} height={size} viewBox="0 0 24 24">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-218.000000, -395.000000)">
          <g transform="translate(218.000000, 395.000000)">
            <circle fill="#E2F6FF" cx="12" cy="12" r="12"></circle>
            <polyline
              stroke="#0064DC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(12.000000, 12.500000) scale(-1, 1) translate(-12.000000, -12.500000) "
              points="17 10 12 15 7 10"
            ></polyline>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const ArrowBlueDownIcon = ({ small }) => {
  const size = small ? "16px" : "24px";
  return (
    <SvgIcon width={size} height={size} viewBox="0 0 24 24">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-162.000000, -339.000000)">
          <g transform="translate(162.000000, 339.000000)">
            <circle
              fill="#E2F6FF"
              transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) "
              cx="12"
              cy="12"
              r="12"
            ></circle>
            <polyline
              stroke="#0064DC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(12.000000, 11.500000) scale(-1, 1) rotate(-180.000000) translate(-12.000000, -11.500000) "
              points="17 9 12 14 7 9"
            ></polyline>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const ArrowBlueIcon = ({ up = false, down = false, small = false }) => {
  if (up) return <ArrowBlueUpIcon small={small} />;
  if (down) return <ArrowBlueDownIcon small={small} />;
  return <ArrowBlueDownIcon small={small} />;
};

export const ArrowGreyUpIcon = () => {
  return (
    <SvgIcon width="24px" height="24px" viewBox="0 0 24 24">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-218.000000, -395.000000)">
          <g transform="translate(218.000000, 395.000000)">
            <circle fill="#CACDD4" cx="12" cy="12" r="12"></circle>
            <polyline
              stroke="#70707A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(12.000000, 12.500000) scale(-1, 1) translate(-12.000000, -12.500000) "
              points="17 10 12 15 7 10"
            ></polyline>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const ArrowGreyDownIcon = () => {
  return (
    <SvgIcon width="24px" height="24px" viewBox="0 0 24 24">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-162.000000, -339.000000)">
          <g transform="translate(162.000000, 339.000000)">
            <circle
              fill="#CACDD4"
              transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) "
              cx="12"
              cy="12"
              r="12"
            ></circle>
            <polyline
              stroke="#70707A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(12.000000, 11.500000) scale(-1, 1) rotate(-180.000000) translate(-12.000000, -11.500000) "
              points="17 9 12 14 7 9"
            ></polyline>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const ArrowLightBlueIcon = ({ up = false, down = false }) => {
  if (up) {
    return (
      <SvgIcon width="24px" height="24px" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C6.47716 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5229 2 12 2Z"
          fill="#E2F6FF"
        />
        <path
          d="M16.4608 10.1863L11.8775 14.5L7.29413 10.1863"
          stroke="#0064DC"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgIcon>
    );
  }
  if (down) {
    return (
      <SvgIcon width="24px" height="24px" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          fill="#E2F6FF"
        />
        <path
          d="M7.5392 13.8137L12.1225 9.49999L16.7059 13.8137"
          stroke="#0064DC"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgIcon>
    );
  }
  return null;
};
