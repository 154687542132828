import React, { useEffect } from "react";
import { connect } from "react-redux";

import { propertyAttorneysNextPage } from "../../../actions/property-attorneys";
import { spousePropertyAttorneysPageProps } from "../../../selectors/spouse-property-attorneys";

import { SPOUSE_CEREMONY_PATH } from "../../../constants/routes";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { PROPERTY_ATTORNEYS_FORM_ID } from "../../../constants/forms";
import { getFormData } from "../../../actions/forms";
import PropertyAttorneysFormDeprecated from "../../../components/forms/your-arrangements/PropertyAttorneysFormDeprecated";

const SpousePropertyAttorneyPage = ({
  getFormData,
  propertyAttorneysNextPage,
  hasLoaded,
  ...restOfProps
}) => {
  useEffect(() => {
    getFormData(PROPERTY_ATTORNEYS_FORM_ID);
  }, []);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <PropertyAttorneysFormDeprecated
      backLink={SPOUSE_CEREMONY_PATH}
      onSubmit={() => propertyAttorneysNextPage()}
      {...restOfProps}
    />
  );
};

export default connect(spousePropertyAttorneysPageProps, {
  getFormData,
  propertyAttorneysNextPage,
})(SpousePropertyAttorneyPage);
