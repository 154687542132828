import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  fetchPropertyAttorneys,
  propertyAttorneysNextPage,
} from "../../../actions/property-attorneys";
import { propertyAttorneysPageProps } from "../../../selectors/property-attorneys";

import PropertyAttorneysForm from "../../../components/forms/your-arrangements/PropertyAttorneysForm";
import { WISHES_CEREMONY_PATH } from "../../../constants/routes";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";

const PropertyAttorneyPage = ({
  fetchPropertyAttorneys,
  propertyAttorneysNextPage,
  hasLoaded,
  ...restOfProps
}) => {
  useEffect(() => {
    fetchPropertyAttorneys();
  }, [fetchPropertyAttorneys]);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <PropertyAttorneysForm
      backLink={WISHES_CEREMONY_PATH}
      onSubmit={() => propertyAttorneysNextPage()}
      {...restOfProps}
    />
  );
};

export default connect(propertyAttorneysPageProps, {
  fetchPropertyAttorneys,
  propertyAttorneysNextPage,
})(PropertyAttorneyPage);
