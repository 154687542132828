import React from "react";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { H2 } from "../ui/Typography";
import NotarizationGuideCard from "../ui/NotarizationGuideCard";
import YourPlanCard from "../plans/your-plan/YourPlanCard";

const NotarialWillSection = ({
  analyticsDownloadNotarizationGuide,
  isTablet,
  translations,
  isNotarialWill,
  isFrenchSelected,
  yourPlan,
}) => {
  return (
    <>
      {isNotarialWill && (
        <Box display="flex" flexDirection="column" mt={isTablet ? 3 : 4.5}>
          <Grid container justifyContent={isTablet ? "center" : ""}>
            <Grid item lg={6} md={8} sm={12}>
              <Box mb={2}>
                <H2 align="left">{translations.yourNotarialWillPlan}</H2>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {isTablet && <Grid md={3} item />}
            <Grid item sm={12} md={6} lg={4}>
              <YourPlanCard {...yourPlan} translations={translations} />
            </Grid>
            {isTablet && <Grid md={3} item />}
            {isTablet && <Grid md={3} item />}
            <Grid item sm={12} md={6} lg={4}>
              <NotarizationGuideCard
                translations={translations}
                isFrenchSelected={isFrenchSelected}
                analyticsDownloadNotarizationGuide={
                  analyticsDownloadNotarizationGuide
                }
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default NotarialWillSection;
