export const updateRangePosition = (breakpointPositions) => ({
  type: UPDATE_RANGE_POSITION_TYPE,
  payload: {
    breakpointPositions,
  },
});

export const updateSliderValue = (firstValue) => ({
  type: UPDATE_SLIDER_VALUE_TYPE,
  payload: {
    firstValue,
  },
});

export const setDisplayAllocationHelper = (shouldDisplay) => ({
  type: DISPLAY_ALLOCATION_HELPER_TYPE,
  payload: {
    shouldDisplay,
  },
});

export const UPDATE_RANGE_POSITION_TYPE = "UPDATE_RANGE_POSITION";
export const UPDATE_SLIDER_VALUE_TYPE = "UPDATE_SLIDER_VALUE";
export const DISPLAY_ALLOCATION_HELPER_TYPE = "DISPLAY_ALLOCATION_HELPER";
