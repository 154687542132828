import React, { useEffect } from "react";
import { connect } from "react-redux";

import { fetchReceipt } from "../../../actions/receipt";
import { receiptPageProps } from "../../../selectors/receipt";

import ReceiptInfo from "../../../components/checkout/ReceiptInfo";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import {
  analyticsClickExternalLink,
  analyticsDownloadNotarizationGuide,
} from "../../../actions/analytics";

const ReceiptPage = ({ hasLoaded, fetchReceipt, ...restOfProps }) => {
  useEffect(() => {
    fetchReceipt();
  }, [fetchReceipt]);

  if (!hasLoaded) return <LoadingSpinner />;

  return <ReceiptInfo {...restOfProps} />;
};

export default connect(receiptPageProps, {
  fetchReceipt,
  analyticsClickExternalLink,
  analyticsDownloadNotarizationGuide,
})(ReceiptPage);
