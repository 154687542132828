import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Body, SmallBody } from "./Typography";
import DocumentsNavIcon from "./icons/DocumentsNavIcon";
import { colorPalette } from "../../config/Theme";

const StyledInfoBox = styled(Box)`
  max-width: ${(props) => (props.isDesktop ? "900px" : "730px")};
  border: 1px solid ${colorPalette.willfulxLightBlue};
  border-radius: 4px;
`;

const UnlimitedUpdatesInfoBox = ({ title, paragraph, isDesktop = true }) => {
  return (
    <StyledInfoBox p={2} bgcolor="secondary.main" isDesktop={isDesktop}>
      <Grid container spacing={1} alignItems="flex-start">
        <Grid item>
          <Box pt={0.25}>
            <DocumentsNavIcon />
          </Box>
        </Grid>
        <Grid item xs>
          {title && (
            <Box display="flex" justifyContent="space-between" mb={0.5}>
              <Body component="span" align="left">
                {title}
              </Body>
            </Box>
          )}
          <SmallBody inline dangerouslySetInnerHTML={{ __html: paragraph }} />
        </Grid>
      </Grid>
    </StyledInfoBox>
  );
};

export default UnlimitedUpdatesInfoBox;
