import { takeEvery, call, put, select } from "redux-saga/effects";

import R from "ramda";
import { change, getFormValues, startSubmit, stopSubmit } from "redux-form";
import {
  OPEN_MODAL_PET_GUARDIAN_TYPE,
  PET_GUARDIANS_NEXT_PAGE_TYPE,
  petGuardianUpdatedSuccesfully,
  REMOVE_PET_GUARDIAN_TYPE,
  UPDATE_PET_TRUST_TYPE,
} from "../actions/pet-guardians";
import { submitFormRequest } from "./forms";
import {
  getPetGuardiansNextPage,
  removePetGuardiansEndpoint,
  submitPetGuardians,
  submitPetTrust,
} from "../api/pet-guardians";
import { closeToast, displayToast } from "../actions/toast";
import {
  PET_GUARDIAN_MODAL_FORM_ID,
  PET_GUARDIANS_FIELD_ID,
  PET_GUARDIANS_FORM_ID,
  PET_TRUST_FORM_ID,
} from "../constants/forms";
import { closeModal, displayModal } from "../actions/modal";
import {
  MODAL_ADD_PET_GUARDIAN,
  MODAL_EDIT_PET_GUARDIAN,
} from "../constants/modal";
import {
  ADD_PET_GUARDIAN_SUCCESS,
  EDIT_PET_GUARDIAN_SUCCESS,
  REMOVE_PET_GUARDIAN_SUCCESS,
} from "../constants/toasts";
import { selectLastSelectedPetGuardianData } from "../selectors/pet-guardians";
import { getFormData } from "../actions/forms";
import { selectPetGuardiansForm } from "../selectors/pets";

function* updatePetTrust() {
  yield call(submitFormRequest, {
    apiCall: submitPetTrust,
    formId: PET_TRUST_FORM_ID,
  });
}

export function* updatePetGuardians(
  petGuardianToUpdate,
  contactId = null,
  isSecondaryContact = false,
  removeSecondaryContact = false,
) {
  const response = yield call(submitFormRequest, {
    apiCall: submitPetGuardians,
    formId: PET_GUARDIANS_FORM_ID,
    values: {
      ...R.mapObjIndexed(R.defaultTo(null), petGuardianToUpdate),
      contactId,
      isSecondaryContact,
      removeSecondaryContact,
    },
  });
  const { status, petGuardian } = response;
  if (status !== 200 || !petGuardian) {
    yield put(stopSubmit(PET_GUARDIANS_FORM_ID));
    return null;
  }
  return petGuardian.id;
}

function* handleOpenPetGuardianModal({ payload }) {
  yield put(closeToast());
  const petGuardian = R.propOr(null, "petGuardian")(payload);

  if (petGuardian) {
    yield put(
      change(PET_GUARDIAN_MODAL_FORM_ID, PET_GUARDIANS_FIELD_ID, petGuardian),
    );
    return yield put(displayModal(MODAL_EDIT_PET_GUARDIAN));
  }
  return yield put(displayModal(MODAL_ADD_PET_GUARDIAN));
}

export function* handleAddOrEditPetGuardians(isEditing = false) {
  yield put(startSubmit(PET_GUARDIAN_MODAL_FORM_ID));
  try {
    const formData = yield select(getFormValues(PET_GUARDIAN_MODAL_FORM_ID));
    const {
      petGuardians: {
        firstName,
        middleName,
        lastName,
        spouseFirstName,
        spouseMiddleName,
        spouseLastName,
      },
    } = formData;
    const newPetGuardian = {
      firstName,
      middleName,
      lastName,
      spouseFirstName,
      spouseMiddleName,
      spouseLastName,
    };
    const updatedPetGuardian = isEditing
      ? yield call(editPetGuardian, newPetGuardian)
      : yield call(addPetGuardian, newPetGuardian);
    yield put(closeModal());
    yield put(
      change(PET_GUARDIANS_FORM_ID, PET_GUARDIANS_FIELD_ID, updatedPetGuardian),
    );
    yield put(petGuardianUpdatedSuccesfully(newPetGuardian));
    yield put(
      displayToast(
        isEditing ? EDIT_PET_GUARDIAN_SUCCESS : ADD_PET_GUARDIAN_SUCCESS,
      ),
    );
  } catch (error) {
    yield put(stopSubmit(PET_GUARDIAN_MODAL_FORM_ID, error.formErrors));
  }
}

function* addPetGuardian(newPetGuardian) {
  const petGuardiansList = yield select(getFormValues(PET_GUARDIANS_FORM_ID));
  const id = yield call(updatePetGuardians, newPetGuardian);
  const updatedPetGuardian = { id, ...newPetGuardian };
  return [...petGuardiansList.petGuardians, updatedPetGuardian];
}

function* editPetGuardian(newPetGuardian) {
  const selectedPetGuardian = yield select(selectLastSelectedPetGuardianData);
  const { petGuardians } = yield select(getFormValues(PET_GUARDIANS_FORM_ID));
  let updatedPetGuardian = {};
  const newPetGuardianList = petGuardians.map((petGuardian, index) => {
    if (index === selectedPetGuardian.index) {
      updatedPetGuardian = {
        ...newPetGuardian,
        id: petGuardian.id,
      };
      return updatedPetGuardian;
    }
    return petGuardian;
  });
  yield call(updatePetGuardians, updatedPetGuardian);
  return newPetGuardianList;
}

export function* handleRemovePetGuardian({ payload }) {
  const petGuardianToRemove = R.propOr(null, "petGuardian")(payload);
  yield put(closeToast());
  const { petGuardian } = yield call(submitFormRequest, {
    apiCall: removePetGuardiansEndpoint,
    values: {
      ...petGuardianToRemove,
    },
  });
  yield put(displayToast(REMOVE_PET_GUARDIAN_SUCCESS));
  // update the form data in the FE
  yield put(getFormData(PET_GUARDIANS_FORM_ID));
  const { petGuardians } = yield select(selectPetGuardiansForm);
  const newPetGuardiansList = petGuardians.map((obj) => {
    if (obj.id === petGuardian.id) {
      return {
        ...obj,
        _destroy: true,
      };
    }
    return obj;
  });
  yield put(
    change(PET_GUARDIANS_FORM_ID, PET_GUARDIANS_FIELD_ID, newPetGuardiansList),
  );
}

function* handlePetGuardianNextPage() {
  return yield call(submitFormRequest, {
    apiCall: getPetGuardiansNextPage,
  });
}

export function* watchUpdatePetGuardians() {
  yield takeEvery(UPDATE_PET_TRUST_TYPE, updatePetTrust);
}

export function* watchHandleOpenPetGuardianModal() {
  yield takeEvery(OPEN_MODAL_PET_GUARDIAN_TYPE, handleOpenPetGuardianModal);
}

export function* watchHandleRemovePetGuardian() {
  yield takeEvery(REMOVE_PET_GUARDIAN_TYPE, handleRemovePetGuardian);
}

export function* watchPetGuardiansNextPage() {
  yield takeEvery(PET_GUARDIANS_NEXT_PAGE_TYPE, handlePetGuardianNextPage);
}
