import React, { useEffect } from "react";
import { connect } from "react-redux";

import { submitSpouseExecutorsForm } from "../../../actions/executors";
import { spouseExecutorsPageProps } from "../../../selectors/spouse-executors";

import SpouseExecutorsForm from "../../../components/forms/your-arrangements/SpouseExecutorsForm";
import { DASHBOARD_PATH } from "../../../constants/routes";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { EXECUTORS_FORM_ID } from "../../../constants/forms";
import { getFormData } from "../../../actions/forms";

const SpouseExecutorsPage = ({
  getFormData,
  submitExecutorsForm,
  hasLoaded,
  ...restOfProps
}) => {
  useEffect(() => {
    getFormData(EXECUTORS_FORM_ID);
  }, []);
  if (!hasLoaded) return <LoadingSpinner />;
  // TODO: window.env.FEATURE_CO_EXECUTORS === "enabled"
  // right now we are forcing the spouse to render ExecutorsFormDeprecated
  // so we are adding references to FEATURE_CO_EXECUTORS until we figure out
  // what to do for couples plan
  return (
    <SpouseExecutorsForm
      backLink={DASHBOARD_PATH}
      onSubmit={() => submitExecutorsForm()}
      {...restOfProps}
    />
  );
};

export default connect(spouseExecutorsPageProps, {
  getFormData,
  submitExecutorsForm: submitSpouseExecutorsForm,
})(SpouseExecutorsPage);
