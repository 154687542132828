export const translateOptionList = (translations) => {
  if (!translations) return null;

  return translations.optionList.map((option) => ({
    label: option.label.replace(
      "APPOINTEE_TITLE",
      translations.personalAppointeeTitle,
    ),
    value: option.value,
    animatedText: option.animatedText.replace(
      "APPOINTEE_TITLE",
      translations.personalAppointeeTitle,
    ),
  }));
};
