import React from "react";

import SvgIcon from "./SvgIcon";

const AppointeesIcon = ({ width = 200, height = 200 }) => (
  <SvgIcon width={width} height={height} viewBox="0 0 200 200" fill="none">
    <path
      d="m67.133 117.184-59.97 37.918 6.857 3.358-1.231.699-3.526-2.015L0 162.741l34.56 19.757 9.263-5.597-3.778-2.155 3.274-1.875 5.345 2.631h10.662l54.344-33.217-46.537-25.101Z"
      fill="#2F455F"
    />
    <path
      d="M25.493 119.896c0 7.416 2.463 12.649 6.353 14.916l1.93 1.147.196-.252c2.883.812 6.325.28 10.018-1.847 10.215-5.904 18.498-21.715 18.498-35.315 0-5.96-1.26-10.326-3.89-13.069l-2.49-1.874c-3.247-1.875-7.472-1.708-12.09.979-10.242 5.904-18.525 21.715-18.525 35.315Zm3.666.308c0-12.872 7.78-27.816 17.35-33.356 2.21-1.26 2.938-2.211 4.841-2.211 5.373 0 8.871 5.457 8.871 13.908 0 12.872-7.78 27.816-17.35 33.357-2.183 1.259-4.281 1.902-6.184 1.902-2.519 0-4.618-1.119-6.157-3.33-1.819-2.49-1.371-5.848-1.371-10.27Z"
      fill="#2F455F"
    />
    <path
      d="M27.116 120.652c0 4.589.336 8.115 2.183 10.689 1.623 2.295 3.526 3.75 6.156 3.75 1.987 0 4.142-.671 6.437-1.987 9.934-5.736 18.021-21.295 18.021-34.672 0-8.786-3.61-14.467-9.206-14.467-1.987 0-3.358 1.707-5.653 3.022-9.906 5.765-17.938 20.288-17.938 33.665Zm1.511-.028c0-12.397 7.696-27.34 16.847-32.602 1.903-1.119 3.694-1.678 5.26-1.678 4.142 0 6.829 4.729 6.829 12.089 0 12.396-7.696 27.34-16.847 32.601-1.93 1.119-3.694 1.679-5.26 1.679-1.876 0-3.275-.784-4.478-2.463-1.54-2.183-2.35-5.513-2.35-9.626Z"
      fill="#3989E5"
    />
    <path
      d="M26.584 120.819c0 13.601 8.284 19.869 18.498 13.964 10.214-5.904 18.497-21.715 18.497-35.315 0-13.6-8.283-19.869-18.497-13.964-10.214 5.904-18.498 21.687-18.498 35.315Zm2.295 0c0-12.872 7.78-27.815 17.35-33.356 2.21-1.26 4.254-1.68 6.157-1.68 5.372 0 8.898 5.234 8.898 13.685 0 12.872-7.78 27.816-17.35 33.357-2.182 1.259-4.281 1.902-6.184 1.902-2.519 0-4.617-1.119-6.156-3.33-1.791-2.518-2.715-6.156-2.715-10.578Z"
      fill="#0064DC"
    />
    <path
      d="M28.627 121.575c0 4.589.98 8.395 2.799 10.997 1.623 2.295 3.777 3.47 6.408 3.47 1.987 0 4.141-.671 6.436-1.986 9.934-5.737 18.022-21.296 18.022-34.672 0-8.787-3.61-14.468-9.207-14.468-1.987 0-4.142.672-6.436 1.987-9.934 5.737-18.022 21.296-18.022 34.672Zm1.735.196c-1.063-12.369 7.724-28.656 16.874-33.945 1.903-1.119 4.282-1.483 5.85-1.483 4.14 0 6.883 5.68 6.883 13.04 0 12.397-7.696 27.341-16.846 32.602-1.931 1.119-3.694 1.679-5.261 1.679-1.875 0-3.778-.644-4.953-2.323-1.567-2.183-2.127-4.533-2.547-9.57Z"
      fill="#3989E5"
    />
    <path
      d="M28.291 121.268c0 4.59.952 8.395 2.799 10.998 1.623 2.294 3.778 3.47 6.408 3.47 1.987 0 4.142-.672 6.437-1.987 9.934-5.737 18.021-21.296 18.021-34.672 0-8.787-3.61-14.468-9.207-14.468-1.986 0-4.141.672-6.436 1.987-9.934 5.765-18.022 21.296-18.022 34.672Zm2.38 0c0-12.397 7.695-27.34 16.845-32.601 1.903-1.12 3.694-1.679 5.261-1.679 4.142 0 6.828 4.73 6.828 12.089 0 12.397-7.695 27.34-16.846 32.601-1.93 1.12-3.694 1.679-5.26 1.679-1.876 0-3.275-.783-4.478-2.462-1.54-2.183-2.35-5.513-2.35-9.627Z"
      fill="#004EB6"
    />
    <path
      d="M39.513 111.334c0 1.679.784 2.658 1.875 2.602.336 0 .811 0 1.203-.224.784-.447 1.567-1.315 2.099-2.266.588-1.064.98-2.379.98-3.526 0-2.183-1.54-3.218-3.19-2.267-1.652.951-2.967 3.498-2.967 5.681Zm4.981-3.33c-.084-.616-.364-1.036-.783-1.176l-.28-.084c-.168-.056-.224-.335-.112-.615.084-.196.196-.336.308-.42.055-.028.14-.056.195-.028l.28.084c.336.112.616.336.84.672.14.279.252.587.308.979l.056.532c.028.252-.112.587-.308.727l-.056.056c-.168.112-.336.028-.364-.224l-.084-.503Zm-3.526 5.149c.056-.224.196-.42.336-.504.056-.028.084-.028.14-.056l.392-.028c.475-.028 1.007-.392 1.455-.951l.28-.364c.168-.224.363-.252.475-.112.028 0 .028.028.028.028.028.056.028.14.028.196a.906.906 0 0 1-.196.559l-.28.364c-.363.448-.755.812-1.175 1.064-.308.168-.615.279-.895.307l-.392.028c-.056 0-.112 0-.14-.056-.084-.083-.112-.251-.056-.475Zm-1.035-1.427-.056-.252.028-.336a5.36 5.36 0 0 1 .307-1.595c.14-.448.336-.784.588-1.176l.392-.699c.168-.252.168-.532.308-.364.056.056.084.14.084.252a.93.93 0 0 1-.14.448l-.336.559c-.392.644-.644 1.428-.644 2.099v.448c0 .28-.167.588-.391.7-.224.083-.14.167-.14-.084Z"
      fill="#0064DC"
    />
    <path
      d="M42.787 105.822c-1.651.951-2.966 3.498-2.966 5.68 0 2.183 1.343 3.191 2.966 2.239 1.651-.951 2.966-3.498 2.966-5.681.028-2.182-1.315-3.19-2.966-2.238Zm-2.043 5.428c0 .28-.168.588-.392.7-.195.112-.363-.028-.363-.28V111.195c.028-.56.14-1.148.336-1.735.14-.448.363-.868.587-1.26l.336-.559c.168-.252.392-.364.532-.224.056.056.084.14.084.252 0 .139-.056.307-.14.475l-.336.56c-.392.644-.644 1.427-.644 2.099v.447Zm3.498.84-.28.364a4.138 4.138 0 0 1-1.175 1.063c-.308.168-.616.28-.895.308l-.392.028c-.196 0-.28-.224-.224-.504.056-.224.196-.419.336-.503.056-.028.084-.028.14-.056l.392-.028c.475-.028 1.007-.364 1.455-.952l.28-.364c.167-.223.391-.279.503-.084a.52.52 0 0 1 .056.196.955.955 0 0 1-.196.532Zm1.064-3.218c-.028.028-.028.028-.056.028-.168.112-.336.028-.364-.196l-.056-.532c-.084-.615-.364-1.035-.784-1.175l-.28-.084c-.167-.056-.223-.336-.111-.616a1.02 1.02 0 0 1 .307-.419c.056-.028.14-.056.196-.028l.28.084c.336.084.616.335.84.671.14.252.252.588.308.98l.055.531c0 .28-.14.588-.335.756Z"
      fill="#004EB6"
    />
    <path d="M42.647 92.502v38.142h.168v-38.31l-.168.168Z" fill="#004EB6" />
    <path
      d="m36.994 132.966.168.028 5.373-22.164.112-.475v-.112l.028-.112v-.196l.084-.28 5.177-21.212c-.056.028-.14.056-.196.112l-4.925 20.373-.14.559-.028.112-.028.112-.028.14-.028.056V110.271l-.028.056-.028.14-.056.196-.028.139-.168.728-5.26 21.436Z"
      fill="#004EB6"
    />
    <path
      d="M32.74 130.448c.029.056.085.112.113.168l9.346-19.113.336-.672.14-.252.028-.084.056-.111.028-.028.028-.028v-.028l.028-.028v-.056l.028-.056v-.028l.028-.028.056-.112.028-.084.056-.112.224-.476 10.773-22.023-.14-.084-11.081 22.639v.028l-.056.084v.028l-.028.028v.056l-.028.028-.028.084v.056l-.056.028-.056.112-.084.167-.112.224-9.626 19.673Z"
      fill="#004EB6"
    />
    <path
      d="m42.143 110.691-11.277 13.88c0 .084.028.139.028.223l11.501-14.131.084-.112.168-.196v-.028l.028-.056v-.028l.028-.028.028-.028.028-.028.028-.028.028-.028.084-.084.084-.084.196-.224 14.3-17.574-.14-.112-14.104 17.35-.448.532v.028l-.056.056v.028l-.028.028-.028.028-.028.028-.028.028v.028l-.028.028-.028.028h-.028l-.028.028-.028.028-.056.084-.056.084-.224.252Z"
      fill="#004EB6"
    />
    <path
      d="m30.726 119.562 11.418-8.842.307-.224.14-.084.084-.056.056-.028.028-.028.056-.028.028-.028h.028l.028-.028.196-.14.336-.252 15.195-11.81-.112-.139-15.335 11.809-.168.14-.224.168-.056.028-.028.028-.056.028h-.056l-.028.028-.028.028h-.028l-.028.028-.056.056-.252.196-11.417 8.871c-.028.14-.028.196-.028.279Z"
      fill="#004EB6"
    />
    <path
      d="m31.174 115.56 10.97-5.093.28-.112.055-.028.112-.056h.028l.028-.028h.028l.028-.028h.056v-.028h.056v-.028h.028l.084-.056.476-.196 14.915-6.912-.084-.14-14.831 6.857-.42.195-.028.028-.14.056-.056.028h-.028v.028h-.056l-.028.028h-.028l-.028.028h-.028l-.028.028h-.028l-.028.028h-.028l-.224.112-.195.084-10.83 5.009-.028.196Z"
      fill="#004EB6"
    />
    <path
      d="m31.817 112.481 10.215-2.127.391-.084h.056l.14-.028h.028l.084-.028h.14l.14-.028.112-.028 14.355-2.994-.027-.168-14.076 2.91-.308.056-.168.028-.112.028H42.395l-.112.028-.587.112-9.85 2.043c0 .14-.029.196-.029.28Z"
      fill="#004EB6"
    />
    <path
      d="m32.489 109.991 9.207.252.503.028H43.04l.532.028 13.04.336v-.168l-13.516-.364H42.115l-9.626-.251c.056.056.028.084 0 .139Z"
      fill="#004EB6"
    />
    <path
      d="m33.245 107.781 8.927 2.295.14.028.14.028.055.028h.084l.028.028h.112l.056.028.056.028h.028l.084.028.28.084 12.313 3.19.028-.168-12.005-3.106-.588-.14-.084-.028h-.056l-.028-.028h-.168l-.056-.028h-.056l-.14-.028-.42-.112-8.646-2.211c-.056 0-.056.056-.084.084Z"
      fill="#004EB6"
    />
    <path
      d="m34.056 105.738 7.892 4.113.447.224h.028l.084.028.028.028.028.028h.028l.028.028h.14l.056.028.028.028.084.056.112.056.308.168 11.081 5.792.084-.139-11.193-5.849-.196-.084-.224-.112-.028-.028-.028-.028h-.056l-.056-.028h-.056l-.056-.028h-.056l-.084-.056-.307-.168-8.004-4.197c-.056.028-.084.084-.112.14Z"
      fill="#004EB6"
    />
    <path
      d="m35.008 103.64 7.135 6.128.224.196.14.112h.028l.028.028.028.028.028.028h.112l.056.056.028.028h.028l.14.112.28.224L53 118.947l.112-.112-9.738-8.367-.336-.28-.028-.028-.112-.112h-.028l-.028-.028h-.028l-.056-.028v-.028l-.028-.028-.028-.028-.056-.056h-.028l-.028-.028-.28-.252-7.191-6.156c-.084.112-.112.168-.112.224Z"
      fill="#004EB6"
    />
    <path
      d="m36.155 101.373 6.072 8.283.224.28.056.056.028.028.028.028.028.028.028.028.028.028.028.028v.028h.028l.028.028.056.084.056.084.028.028.252.364 8.06 10.969.14-.084-8.06-10.969-.224-.28-.14-.14-.084-.084h-.028l-.028-.028v-.056l-.028-.028-.028-.028-.028-.028-.056-.084-.056-.084-.028-.056-.168-.224-6.128-8.367-.084.168Z"
      fill="#004EB6"
    />
    <path
      d="m37.722 98.714 4.673 10.858.14.336.028.056.028.028v.028l.028.028v.056l.028.056v.028l.028.027v.028l.056.112.084.168.084.196 6.157 14.384.14-.056-6.1-14.216-.14-.336-.057-.112-.028-.084-.028-.084v-.027l-.028-.028v-.028l-.028-.028-.028-.056v-.112l-.084-.196-.056-.028-.14-.336-4.645-10.802c-.056.028-.084.084-.112.14Z"
      fill="#004EB6"
    />
    <path
      d="m40.017 95.496 2.462 13.852.084.448.028.112v.028l.028.084V110.383l.028.168.14.728 2.994 16.902.168-.028-3.05-17.434-.056-.28-.028-.112V109.908l-.084-.392-.028-.14-2.49-14.048c-.113.056-.168.112-.196.168Z"
      fill="#004EB6"
    />
    <path
      d="m46.145 110.187-2.63-1.512c-.028-.028-.056-.056-.112-.056-.168-.083-.364-.055-.588.084-.56.308-.98 1.176-.98 1.903 0 .42.14.7.364.812l2.659 1.539.028-.196c.056-.028.14-.056.196-.084.56-.308.98-1.175.98-1.903 0-.196-.029-.336-.085-.475l.168-.112Z"
      fill="#666"
    />
    <path
      d="M46.425 110.942c0 .728-.448 1.567-.98 1.903-.559.308-.979-.028-.979-.756 0-.727.448-1.567.98-1.903.531-.307.98.028.98.756Z"
      fill="#004EB6"
    />
    <path
      d="M42.144 112.929c0 1.68.783 2.659 1.875 2.603.335 0 .811 0 1.203-.224.783-.448 1.567-1.315 2.099-2.267.587-1.063.979-2.378.979-3.526 0-2.182-1.54-3.218-3.19-2.266-1.651.951-2.966 3.498-2.966 5.68Zm4.98-3.33c-.083-.615-.363-1.035-.783-1.175l-.28-.084c-.168-.056-.224-.336-.112-.616.084-.195.196-.335.308-.419.056-.028.14-.056.196-.028l.28.084c.336.112.616.335.84.671.14.28.251.588.307.98l.056.531c.028.252-.112.588-.308.728l-.056.056c-.168.112-.335.028-.363-.224l-.084-.504Zm-3.525 5.149c.056-.223.196-.419.336-.503.055-.028.084-.028.14-.056l.391-.028c.476-.028 1.008-.392 1.455-.952l.28-.363c.168-.224.364-.252.476-.112.028 0 .028.028.028.028a.472.472 0 0 1 .028.195.91.91 0 0 1-.196.56l-.28.364c-.364.448-.755.811-1.175 1.063-.308.168-.616.28-.896.308l-.391.028c-.056 0-.112 0-.14-.056-.084-.084-.112-.252-.056-.476Zm-1.036-1.427-.056-.252.028-.335c.028-.56.112-1.036.308-1.596.14-.447.336-.783.588-1.175l.392-.699c.167-.252.167-.532.307-.364.056.056.084.14.084.252 0 .14-.056.307-.14.447l-.335.56c-.392.644-.644 1.427-.644 2.099v.448c0 .279-.168.587-.392.699-.224.084-.14.168-.14-.084Z"
      fill="#0064DC"
    />
    <path
      d="M45.418 107.417c-1.651.952-2.967 3.498-2.967 5.681s1.344 3.19 2.967 2.239c1.65-.952 2.966-3.498 2.966-5.681.028-2.183-1.315-3.19-2.966-2.239Zm-2.043 5.429c0 .28-.168.588-.392.7-.196.112-.364-.028-.364-.28V112.79a6.56 6.56 0 0 1 .336-1.735c.14-.448.364-.867.588-1.259l.336-.56c.168-.252.391-.364.531-.224.056.056.084.14.084.252 0 .14-.056.308-.14.476l-.335.56c-.392.643-.644 1.427-.644 2.098v.448Zm3.498.84-.28.363a4.142 4.142 0 0 1-1.175 1.064c-.308.168-.616.28-.896.308l-.392.028c-.195 0-.28-.224-.223-.504.056-.224.196-.42.335-.504.056-.028.084-.028.14-.056l.392-.028c.476-.028 1.008-.363 1.455-.951l.28-.364c.168-.224.392-.28.504-.084a.52.52 0 0 1 .056.196.955.955 0 0 1-.196.532Zm1.063-3.218c-.028.028-.028.028-.056.028-.168.111-.336.028-.364-.196l-.055-.532c-.084-.616-.364-1.035-.784-1.175l-.28-.084c-.168-.056-.224-.336-.112-.616.084-.196.196-.336.308-.42.056-.028.14-.056.196-.028l.28.084c.336.084.615.336.84.672.14.252.251.588.307.979l.056.532c0 .28-.14.588-.336.756Z"
      fill="#004EB6"
    />
    <path
      d="m83.588 57.577-1.483-.952 1.119-.615 1.511.951-1.147.616Z"
      fill="#0064DC"
    />
    <path
      d="M84.735 69.89V56.96l-1.147.617v12.928l1.147-.615Z"
      fill="#2F455F"
    />
    <path d="m83.588 70.505-1.483-.98v-12.9l1.483.952v12.928Z" fill="#0064DC" />
    <path
      d="m86.078 53.322-.14-.923 6.185-3.498h.028l.084-.056c.168-.084.363-.084.587.028.112.056.224.112.336.196l.168.14c.112.112.224.252.308.392.308.447.504 1.007.56 1.539v.028c.055.587-.14 1.035-.448 1.203h-.028l-6.185 3.498-.951-.308 1.091-.672c-.475-.447-.867-1.203-.923-1.958l-.672.391Z"
      fill="#ABE0F6"
    />
    <path
      d="M85.463 53.658c.083.98.7 1.986 1.37 2.266.672.28 1.176-.28 1.092-1.23-.084-.98-.7-1.988-1.371-2.268-.7-.307-1.175.252-1.091 1.232Z"
      fill="#71ADEE"
    />
    <path
      d="m83.588 56.15-1.483-.98 3.917-2.211 1.483.951-3.917 2.24Z"
      fill="#3989E5"
    />
    <path
      d="M87.505 55.337V53.91l-3.917 2.239v1.4l3.917-2.212Z"
      fill="#2F455F"
    />
    <path
      d="m83.588 57.548-1.483-.95V55.17l1.483.98v1.398ZM52.638 40.508l-1.483-.951 1.147-.616 1.483.952-1.147.615Z"
      fill="#0064DC"
    />
    <path
      d="M53.785 52.821V39.893l-1.147.615v12.929l1.147-.616Z"
      fill="#2F455F"
    />
    <path d="m52.638 53.437-1.483-.98v-12.9l1.483.951v12.929Z" fill="#0064DC" />
    <path
      d="m55.156 36.254-.14-.923 6.185-3.498h.028l.084-.056c.168-.084.363-.084.587.028.112.056.224.112.336.196l.168.14c.112.111.224.251.308.391.308.448.504 1.008.56 1.54v.027c.056.588-.14 1.036-.448 1.204h-.028L56.61 38.8l-.951-.308 1.091-.672c-.475-.448-.867-1.203-.923-1.959l-.672.392Z"
      fill="#ABE0F6"
    />
    <path
      d="M54.513 36.59c.084.979.7 1.986 1.37 2.266.672.28 1.176-.28 1.092-1.231-.084-.98-.7-1.987-1.371-2.267-.672-.308-1.175.252-1.091 1.231Z"
      fill="#71ADEE"
    />
    <path
      d="m52.638 39.079-1.483-.98 3.917-2.21 1.483.98-3.917 2.21Z"
      fill="#3989E5"
    />
    <path
      d="M56.555 38.266v-1.399l-3.917 2.21v1.4l3.917-2.21Z"
      fill="#2F455F"
    />
    <path
      d="m52.638 40.478-1.483-.951V38.1l1.483.979v1.4ZM12.985 98.517l-1.428-.868 1.82-.98 1.398.868-1.79.98Z"
      fill="#0064DC"
    />
    <path
      d="M14.776 122.051V97.537l-1.791.98v24.485l1.79-.951Z"
      fill="#2F455F"
    />
    <path
      d="m12.985 123.004-1.428-.84V97.65l1.428.868v24.486Z"
      fill="#0064DC"
    />
    <path
      d="m11.277 100.727-4.113-2.686 41.975-24.066 4.114 2.658-41.976 24.094Z"
      fill="#E2F6FF"
    />
    <path
      d="M53.253 80.103v-3.47l-41.976 24.094v3.47l41.976-24.094Z"
      fill="#71ADEE"
    />
    <path
      d="m11.277 104.197-4.113-2.658v-3.498l4.113 2.686v3.47ZM77.935 73.5l-33.72-18.973.42 53.31 33.692 19.001-.392-53.338Z"
      fill="#ABE0F6"
    />
    <path
      d="m85.127 122.805-6.8 4.03-.392-53.337 6.8-4.002.392 53.309Z"
      fill="#004EB6"
    />
    <path
      d="m84.735 69.497-33.72-19-6.8 4.029 33.72 18.973 6.8-4.002Z"
      fill="#71ADEE"
    />
    <path
      d="M45.502 170.797c0 2.043.671 3.47 1.735 4.114l.531.307.056-.055c.784.223 1.735.083 2.77-.504 2.827-1.623 5.094-5.989 5.094-9.739 0-1.651-.364-2.854-1.064-3.609l-.7-.532c-.895-.504-2.042-.476-3.33.28-2.798 1.623-5.092 5.988-5.092 9.738Zm1.007.084c0-3.554 2.155-7.668 4.785-9.179.616-.363.812-.615 1.344-.615 1.483 0 2.434 1.511 2.434 3.833 0 3.554-2.155 7.668-4.785 9.179-.616.336-1.175.532-1.707.532-.7 0-1.26-.308-1.707-.924-.476-.699-.364-1.623-.364-2.826Z"
      fill="#2F455F"
    />
    <path
      d="M45.95 170.993c0 1.259.083 2.238.587 2.938.448.644.98 1.035 1.707 1.035.56 0 1.148-.195 1.763-.559 2.742-1.595 4.981-5.877 4.981-9.543 0-2.406-1.007-3.973-2.546-3.973-.56 0-.924.475-1.567.839-2.715 1.567-4.925 5.569-4.925 9.263Zm.42 0c0-3.414 2.126-7.528 4.645-8.983.531-.308 1.007-.448 1.455-.448 1.147 0 1.875 1.315 1.875 3.33 0 3.414-2.127 7.528-4.646 8.983-.531.308-1.007.448-1.455.448-.504 0-.895-.224-1.231-.672-.42-.615-.644-1.539-.644-2.658Z"
      fill="#3989E5"
    />
    <path
      d="M45.81 171.05c0 3.75 2.266 5.485 5.093 3.862 2.826-1.623 5.093-5.988 5.093-9.738 0-3.75-2.267-5.485-5.093-3.862-2.827 1.623-5.093 5.989-5.093 9.738Zm.615 0c0-3.554 2.155-7.667 4.786-9.178a3.235 3.235 0 0 1 1.706-.476c1.484 0 2.463 1.455 2.463 3.778 0 3.554-2.155 7.667-4.785 9.178-.616.336-1.175.532-1.707.532-.7 0-1.26-.308-1.707-.923-.476-.7-.756-1.707-.756-2.911Z"
      fill="#71ADEE"
    />
    <path
      d="M46.37 171.245c0 1.259.279 2.322.783 3.022.447.644 1.035.951 1.763.951.56 0 1.147-.195 1.763-.559 2.742-1.595 4.98-5.877 4.98-9.543 0-2.406-1.007-3.973-2.546-3.973-.56 0-1.147.195-1.763.559-2.742 1.567-4.98 5.877-4.98 9.543Zm.475.056c-.28-3.414 2.127-7.892 4.645-9.347.532-.308 1.175-.42 1.623-.42 1.148 0 1.903 1.567 1.903 3.582 0 3.414-2.127 7.528-4.645 8.983-.532.308-1.008.448-1.455.448-.504 0-1.036-.168-1.371-.644-.42-.559-.588-1.203-.7-2.602Z"
      fill="#3989E5"
    />
    <path
      d="M46.285 171.163c0 1.259.28 2.322.784 3.022.448.644 1.035.951 1.763.951.56 0 1.147-.196 1.763-.559 2.742-1.595 4.981-5.877 4.981-9.543 0-2.406-1.007-3.973-2.547-3.973-.56 0-1.147.195-1.763.559-2.77 1.595-4.98 5.877-4.98 9.543Zm.644 0c0-3.414 2.127-7.528 4.645-8.983.532-.308 1.008-.448 1.456-.448 1.147 0 1.874 1.315 1.874 3.33 0 3.414-2.126 7.528-4.645 8.983-.532.308-1.007.448-1.455.448-.504 0-.896-.224-1.231-.672-.42-.588-.644-1.511-.644-2.658Z"
      fill="#004EB6"
    />
    <path
      d="M49.364 168.447c0 .475.223.727.503.727.084 0 .224 0 .336-.056.224-.112.448-.363.588-.615.168-.308.28-.644.28-.98 0-.615-.42-.895-.868-.615-.476.223-.84.923-.84 1.539Zm1.37-.924c-.027-.168-.111-.28-.223-.308l-.084-.028c-.056 0-.056-.084-.028-.168.028-.055.056-.083.084-.111h.056l.084.028a.372.372 0 0 1 .224.195c.028.084.084.168.084.28l.028.14c0 .056-.028.168-.084.196h-.028c-.056.028-.084 0-.112-.056v-.168Zm-.979 1.399c.028-.056.056-.112.084-.14h.14c.14 0 .28-.111.392-.251l.084-.112c.056-.056.112-.084.14-.028v.056a.214.214 0 0 1-.056.14l-.084.112a1.332 1.332 0 0 1-.308.279.451.451 0 0 1-.252.084h-.14v-.14Zm-.28-.391v-.168c0-.168.028-.28.084-.448a.948.948 0 0 1 .168-.336l.112-.196c.056-.084.056-.14.084-.112.028.028.028.028.028.084 0 .028 0 .084-.028.14l-.084.14a1.103 1.103 0 0 0-.168.588v.112a.236.236 0 0 1-.112.196c-.111.056-.084.084-.084 0Z"
      fill="#0064DC"
    />
    <path
      d="M50.26 166.907c-.448.252-.812.952-.812 1.567 0 .616.364.896.812.616.448-.252.811-.951.811-1.567s-.335-.868-.811-.616Zm-.56 1.511a.236.236 0 0 1-.112.196c-.056.028-.084 0-.084-.084v-.112c0-.14.028-.307.084-.475a.762.762 0 0 1 .168-.336l.084-.14c.056-.084.112-.112.14-.056.028.028.028.056.028.084 0 .028 0 .084-.028.14l-.084.14a1.101 1.101 0 0 0-.168.587l-.028.056Zm.98.224-.084.112c-.112.14-.224.224-.308.28a.451.451 0 0 1-.252.084h-.112c-.056 0-.084-.056-.056-.14a.612.612 0 0 1 .084-.14h.14c.14 0 .28-.112.392-.252l.084-.112c.056-.056.111-.084.14-.028v.056c.028.028 0 .084-.029.14Zm.28-.895c-.057.028-.113.028-.113-.056l-.028-.14c-.028-.168-.111-.28-.223-.308l-.084-.028c-.056 0-.056-.084-.028-.168a.387.387 0 0 1 .084-.112c.028 0 .028-.028.056 0l.084.028a.374.374 0 0 1 .223.196c.028.084.084.168.084.28l.028.14c0 .028-.028.14-.084.168Z"
      fill="#004EB6"
    />
    <path
      d="M50.23 163.243v10.522h.057v-10.55c-.028 0-.056 0-.056.028Z"
      fill="#004EB6"
    />
    <path
      d="M48.664 174.381h.056l1.483-6.101.028-.14v-.028l.028-.084 1.4-5.848c-.029 0-.029.028-.057.028l-1.371 5.624-.028.168v.252l-.056.196-1.483 5.933Z"
      fill="#004EB6"
    />
    <path
      d="M47.489 173.709c0 .028.028.028.028.056l2.574-5.261.084-.196.028-.084v-.028l.028-.028v-.056l.028-.028.056-.14 2.966-6.072-.028-.028-3.05 6.24v.084l-.028.056-.028.056-2.658 5.429Z"
      fill="#004EB6"
    />
    <path
      d="m50.091 168.251-3.106 3.834v.056l3.162-3.89.028-.028.056-.056v-.028l.028-.028.028-.028.056-.056 3.946-4.841-.028-.028-3.89 4.785-.112.14-.028.028-.028.028-.028.028-.084.084Z"
      fill="#004EB6"
    />
    <path
      d="m46.957 170.686 3.134-2.435.084-.056.028-.028.028-.028h.028l.056-.028.084-.056 4.197-3.246-.028-.028-4.225 3.274-.056.028-.056.056-.028.028-.084.056-3.162 2.435v.028Z"
      fill="#004EB6"
    />
    <path
      d="m47.069 169.596 3.022-1.4.084-.028v-.028h.056v-.028h.056l.028-.027.112-.056 4.114-1.903v-.028l-4.114 1.875-.112.056v.028h-.056v.028h-.056v.027h-.028l-.056.028-.056.028-2.994 1.372v.056Z"
      fill="#004EB6"
    />
    <path
      d="m47.237 168.757 2.826-.588.112-.028h.14l3.946-.812v-.056l-3.862.812-.084.028h-.14l-.168.028-2.715.56c-.027 0-.055.028-.055.056Z"
      fill="#004EB6"
    />
    <path
      d="m47.432 168.057 2.547.056h.532l3.581.084v-.056l-3.721-.112h-.252l-2.659-.056c-.028.056-.028.084-.028.084Z"
      fill="#004EB6"
    />
    <path
      d="m47.657 167.44 2.462.644h.168l.084.028 3.442.923v-.056l-3.302-.867-.168-.028h-.056l-.112-.028-2.379-.616c-.14-.028-.14 0-.14 0Z"
      fill="#004EB6"
    />
    <path
      d="m47.852 166.881 2.183 1.148.112.056h.056l.028.028.084.056 3.05 1.595.028-.028-3.078-1.623-.056-.028-.056-.028h-.056l-.084-.056-2.21-1.147c.027 0 .027.027 0 .027Z"
      fill="#004EB6"
    />
    <path
      d="m48.132 166.32 1.959 1.679.056.056.028.028.028.028.084.056 2.686 2.295.028-.028-2.686-2.295-.084-.084-.028-.028-.028-.028-.084-.056-1.987-1.707c.028.056.028.056.028.084Z"
      fill="#004EB6"
    />
    <path
      d="m48.44 165.678 1.68 2.295.055.084v.028l.028.028.028.028.084.112 2.21 3.022.029-.028-2.21-3.022-.057-.084-.028-.028-.028-.028-.028-.028-.028-.028v-.028l-.056-.056-1.679-2.295c.028 0 0 .028 0 .028Z"
      fill="#004EB6"
    />
    <path
      d="m48.888 164.95 1.287 2.994.028.084v.056l.028.056.028.056 1.707 3.974.028-.028-1.68-3.918-.027-.084v-.084l-.028-.056-.028-.084-1.287-2.966c-.056-.028-.056 0-.056 0Z"
      fill="#004EB6"
    />
    <path
      d="m49.503 164.055.672 3.805.028.112v.112l.028.196.84 4.646h.056l-.84-4.786v-.168l-.028-.112v-.028l-.7-3.861c-.028.056-.056.084-.056.084Z"
      fill="#004EB6"
    />
    <path
      d="m51.182 168.111-.727-.42s-.028 0-.028-.028c-.056-.028-.112-.028-.168.028-.14.084-.28.308-.28.532 0 .112.028.196.112.224l.728.419v-.056c.028 0 .028 0 .056-.028a.67.67 0 0 0 .28-.531c0-.056 0-.084-.028-.14h.055Z"
      fill="#666"
    />
    <path
      d="M51.267 168.335c0 .196-.113.448-.28.532-.14.084-.28 0-.28-.196s.112-.448.28-.532c.167-.112.28-.028.28.196Z"
      fill="#004EB6"
    />
    <path
      d="M50.091 168.867c0 .475.224.727.504.727.084 0 .224 0 .336-.056.224-.112.448-.364.588-.615.167-.308.28-.644.28-.98 0-.616-.42-.895-.868-.616-.476.224-.84.952-.84 1.54Zm1.371-.924c-.028-.168-.111-.28-.223-.308l-.084-.028c-.056 0-.056-.084-.028-.168a.387.387 0 0 1 .084-.112h.056l.084.028a.375.375 0 0 1 .224.196c.027.084.083.168.083.28l.028.14c0 .056-.028.168-.084.196h-.027c-.056.028-.085 0-.113-.056v-.168Zm-.979 1.427c.028-.056.056-.112.084-.14h.14c.14 0 .28-.112.392-.251l.084-.112c.056-.056.112-.084.14-.028v.056a.209.209 0 0 1-.056.139l-.084.112c-.112.14-.224.224-.308.28a.451.451 0 0 1-.252.084h-.14v-.14Zm-.28-.391v-.168c0-.168.028-.28.084-.448a.946.946 0 0 1 .168-.336l.112-.196c.056-.084.056-.14.084-.112.028.028.028.028.028.084 0 .028 0 .084-.028.14l-.084.14a1.103 1.103 0 0 0-.168.588v.112a.236.236 0 0 1-.112.196c-.112.055-.084.055-.084 0Z"
      fill="#0064DC"
    />
    <path
      d="M50.988 167.357c-.448.252-.812.951-.812 1.567s.364.895.812.616c.447-.252.811-.952.811-1.568.028-.615-.335-.895-.811-.615Zm-.56 1.483a.236.236 0 0 1-.112.196c-.056.028-.084 0-.084-.084v-.112c0-.14.028-.308.084-.476a.763.763 0 0 1 .168-.336l.084-.14c.056-.083.112-.111.14-.055.028.027.028.055.028.083 0 .028 0 .084-.028.14l-.084.14a1.103 1.103 0 0 0-.168.588l-.028.056Zm.98.252-.084.112c-.112.14-.224.224-.308.28a.451.451 0 0 1-.252.084h-.112c-.056 0-.084-.056-.056-.14.028-.056.056-.112.084-.14h.14c.14 0 .28-.112.392-.252l.084-.112c.056-.056.112-.084.14-.028v.056c.028.028 0 .084-.028.14Zm.28-.896c-.057.028-.113.028-.113-.056l-.028-.14c-.027-.167-.111-.279-.223-.307l-.084-.028c-.056 0-.056-.084-.028-.168a.386.386 0 0 1 .084-.112c.028 0 .028-.028.056 0l.084.028a.374.374 0 0 1 .223.196c.028.084.084.168.084.279l.028.14a.308.308 0 0 1-.084.168Z"
      fill="#004EB6"
    />
    <path
      d="m50.763 137.889-.616-.364.784-.42.587.364-.755.42Z"
      fill="#0064DC"
    />
    <path
      d="M51.518 168.811v-31.342l-.755.42v31.341l.755-.419Z"
      fill="#004EB6"
    />
    <path
      d="m50.763 169.231-.616-.364v-31.342l.616.364v31.342Z"
      fill="#71ADEE"
    />
    <path
      d="M10.634 150.957c0 2.043.671 3.47 1.735 4.114l.531.308.056-.056c.784.224 1.735.084 2.77-.504 2.827-1.623 5.094-5.989 5.094-9.738 0-1.651-.364-2.855-1.064-3.61l-.7-.532c-.895-.504-2.042-.476-3.33.28-2.826 1.623-5.092 5.988-5.092 9.738Zm.98.084c0-3.554 2.154-7.667 4.784-9.179.616-.363.812-.615 1.344-.615 1.483 0 2.434 1.511 2.434 3.834 0 3.554-2.154 7.667-4.785 9.178-.616.336-1.175.532-1.707.532-.7 0-1.26-.308-1.707-.923-.476-.672-.364-1.624-.364-2.827Z"
      fill="#2F455F"
    />
    <path
      d="M11.082 151.153c0 1.259.083 2.239.587 2.938.448.644.98 1.036 1.707 1.036.56 0 1.148-.196 1.763-.56 2.743-1.595 4.981-5.877 4.981-9.543 0-2.406-1.007-3.973-2.546-3.973-.56 0-.924.476-1.567.839-2.743 1.567-4.926 5.569-4.926 9.263Zm.391 0c0-3.414 2.127-7.528 4.646-8.983.531-.308 1.007-.448 1.455-.448 1.147 0 1.875 1.316 1.875 3.33 0 3.414-2.127 7.528-4.646 8.983-.531.308-1.007.448-1.455.448-.504 0-.895-.224-1.231-.672-.42-.615-.644-1.539-.644-2.658Z"
      fill="#3989E5"
    />
    <path
      d="M10.914 151.209c0 3.749 2.266 5.484 5.093 3.861 2.826-1.623 5.093-5.988 5.093-9.738 0-3.75-2.267-5.485-5.093-3.862-2.799 1.623-5.093 5.989-5.093 9.739Zm.643 0c0-3.554 2.155-7.668 4.785-9.179a3.235 3.235 0 0 1 1.707-.476c1.484 0 2.463 1.455 2.463 3.778 0 3.554-2.155 7.668-4.785 9.179-.616.335-1.175.531-1.707.531-.7 0-1.26-.307-1.707-.923-.504-.7-.756-1.707-.756-2.91Z"
      fill="#71ADEE"
    />
    <path
      d="M11.473 151.405c0 1.259.28 2.323.784 3.022.448.644 1.035.952 1.763.952.56 0 1.147-.196 1.763-.56 2.742-1.595 4.953-5.877 4.953-9.543 0-2.406-1.007-3.973-2.547-3.973-.56 0-1.147.196-1.762.559-2.715 1.595-4.954 5.877-4.954 9.543Zm.476.056c-.28-3.414 2.127-7.892 4.645-9.347.532-.308 1.176-.42 1.623-.42 1.148 0 1.903 1.568 1.903 3.582 0 3.414-2.126 7.528-4.645 8.983-.532.308-1.007.448-1.455.448-.504 0-1.035-.168-1.371-.644-.42-.559-.56-1.203-.7-2.602Z"
      fill="#3989E5"
    />
    <path
      d="M11.39 151.321c0 1.259.279 2.323.783 3.022.447.644 1.035.952 1.763.952.56 0 1.147-.196 1.763-.56 2.742-1.595 4.98-5.877 4.98-9.543 0-2.406-1.007-3.973-2.546-3.973-.56 0-1.147.196-1.763.559-2.742 1.596-4.98 5.877-4.98 9.543Zm.643 0c0-3.414 2.127-7.528 4.645-8.983.532-.308 1.008-.448 1.455-.448 1.148 0 1.875 1.316 1.875 3.33 0 3.414-2.127 7.528-4.645 8.983-.532.308-1.008.448-1.455.448-.504 0-.896-.224-1.232-.672-.42-.587-.643-1.511-.643-2.658Z"
      fill="#004EB6"
    />
    <path
      d="M14.467 148.605c0 .476.224.727.504.727.084 0 .224 0 .336-.056.224-.111.448-.363.588-.615.168-.308.28-.644.28-.98 0-.615-.42-.895-.868-.615-.448.224-.84.923-.84 1.539Zm1.372-.924c-.028-.167-.112-.279-.224-.307l-.084-.028c-.056 0-.056-.084-.028-.168a.387.387 0 0 1 .084-.112h.056l.084.028a.374.374 0 0 1 .224.196c.028.084.084.168.084.279l.028.14c0 .056-.028.168-.084.196h-.028c-.056.028-.084 0-.112-.056v-.168Zm-.952 1.4c.028-.056.056-.112.084-.14h.14c.14 0 .28-.112.392-.252l.084-.112c.056-.056.112-.084.14-.028v.056a.214.214 0 0 1-.056.14l-.084.112c-.112.14-.224.224-.308.28a.451.451 0 0 1-.252.084h-.14c0-.028-.028-.056 0-.14Zm-.28-.392v-.168c0-.168.028-.28.084-.448a.948.948 0 0 1 .168-.336l.112-.196c.056-.083.056-.139.084-.111.028.028.028.028.028.083 0 .028 0 .084-.028.14l-.084.14a1.103 1.103 0 0 0-.168.588v.112a.236.236 0 0 1-.112.196c-.112.056-.084.084-.084 0Z"
      fill="#0064DC"
    />
    <path
      d="M15.391 147.065c-.448.252-.811.952-.811 1.567 0 .616.363.896.811.616.448-.252.812-.951.812-1.567 0-.588-.364-.867-.812-.616Zm-.56 1.511a.236.236 0 0 1-.111.196c-.056.028-.084 0-.084-.084v-.112c0-.139.028-.307.084-.475a.764.764 0 0 1 .167-.336l.084-.14c.056-.084.112-.112.14-.056.028.028.028.056.028.084 0 .028 0 .084-.028.14l-.084.14a1.102 1.102 0 0 0-.168.587l-.028.056Zm.952.224-.084.112c-.112.14-.224.224-.308.28a.451.451 0 0 1-.252.084h-.112c-.056 0-.084-.056-.056-.14a.609.609 0 0 1 .084-.14h.14c.14 0 .28-.112.392-.252l.084-.112c.056-.056.112-.084.14-.028v.056c.028.028 0 .084-.028.14Zm.28-.895s0 .028 0 0c-.056.028-.112.028-.112-.056l-.028-.14c-.028-.168-.112-.28-.224-.308l-.084-.028c-.056 0-.056-.084-.028-.168.028-.056.056-.084.084-.112.028 0 .028-.028.056 0l.084.028a.375.375 0 0 1 .224.196c.028.084.084.168.084.28l.028.14c.028.028-.028.14-.084.168Z"
      fill="#004EB6"
    />
    <path
      d="M15.335 143.401v10.522h.056v-10.55c-.028 0-.028.028-.056.028Z"
      fill="#004EB6"
    />
    <path
      d="M13.796 154.566h.056l1.483-6.1.028-.14v-.028l.028-.084 1.399-5.849c-.028 0-.028.028-.056.028l-1.371 5.625-.028.168v.252l-.056.196-1.483 5.932Z"
      fill="#004EB6"
    />
    <path
      d="M12.62 153.867c0 .028.028.028.028.056l2.575-5.261.084-.196.028-.084v-.028l.028-.028v-.056l.028-.028.056-.14 2.966-6.072-.028-.028-3.05 6.24v.084l-.028.056-.028.056-2.659 5.429Z"
      fill="#004EB6"
    />
    <path
      d="m15.195 148.409-3.106 3.834v.056l3.162-3.89.028-.028.056-.055v-.028l.028-.028.028-.028.056-.056 3.946-4.842-.028-.028-3.862 4.73-.112.14-.028.028-.028.028-.028.028-.112.139Z"
      fill="#004EB6"
    />
    <path
      d="m12.06 150.844 3.135-2.435.084-.056.028-.028.028-.027h.028l.056-.028.084-.056 4.197-3.247-.028-.028-4.225 3.275-.056.028-.056.056-.028.027-.084.056-3.162 2.435v.028Z"
      fill="#004EB6"
    />
    <path
      d="m12.173 149.754 3.05-1.399.056-.028h.028l.028-.028h.028v-.028h.056l.14-.084 4.114-1.875-.028-.056-4.086 1.903-.112.028-.028.028h-.056v.028h-.028l-.028.028h-.028l-.056.028-.056.028-2.966 1.371-.028.056Z"
      fill="#004EB6"
    />
    <path
      d="m12.369 148.915 2.826-.588.112-.028h.14l3.946-.811v-.056l-3.862.811-.084.028h-.14l-.168.028-2.714.56c-.056.028-.056.028-.056.056Z"
      fill="#004EB6"
    />
    <path
      d="m12.536 148.215 2.547.056h.532l3.582.084v-.056l-3.722-.112h-.252l-2.659-.056c0 .056 0 .084-.028.084Z"
      fill="#004EB6"
    />
    <path
      d="m12.76 147.626 2.463.644h.168l.084.028 3.386.867v-.056l-3.302-.867-.168-.028h-.056l-.112-.028-2.379-.616c-.083.028-.083.028-.083.056Z"
      fill="#004EB6"
    />
    <path
      d="m12.984 147.04 2.183 1.147.112.056h.056l.028.028.084.056 3.05 1.595.028-.028-3.078-1.623-.056-.028-.056-.028h-.056l-.084-.056-2.21-1.147v.028Z"
      fill="#004EB6"
    />
    <path
      d="m13.236 146.478 1.959 1.68.056.055.028.028.028.028.084.056 2.686 2.295.028-.028-2.686-2.295-.084-.084-.028-.028-.028-.027-.084-.056-1.987-1.707c.028.055.028.056.028.083Z"
      fill="#004EB6"
    />
    <path
      d="m13.544 145.837 1.68 2.294.055.084v.028l.028.028.028.028.084.112 2.21 3.022.029-.028-2.21-3.022-.057-.084-.028-.028-.028-.028-.028-.028-.028-.028v-.028l-.056-.056-1.679-2.294c.028 0 .028.028 0 .028Z"
      fill="#004EB6"
    />
    <path
      d="m13.992 145.108 1.287 2.994.028.084v.056l.028.056.028.056 1.707 3.974.028-.028-1.68-3.918-.027-.084v-.084l-.028-.056-.028-.084-1.287-2.966c-.028-.028-.056 0-.056 0Z"
      fill="#004EB6"
    />
    <path
      d="m14.607 144.24.672 3.806.028.112v.112l.028.196.84 4.645h.055l-.839-4.785v-.168l-.028-.112v-.028l-.7-3.862c-.028.028-.028.056-.056.084Z"
      fill="#004EB6"
    />
    <path
      d="m16.314 148.271-.727-.42s-.028 0-.028-.028c-.056-.028-.112-.028-.168.028-.14.084-.28.308-.28.532 0 .112.028.196.112.224l.728.419v-.056c.028 0 .028 0 .056-.028a.668.668 0 0 0 .28-.531c0-.056 0-.084-.029-.14h.056Z"
      fill="#666"
    />
    <path
      d="M16.398 148.494c0 .196-.112.448-.28.532-.14.084-.28 0-.28-.196s.113-.448.28-.532c.14-.112.28 0 .28.196Z"
      fill="#004EB6"
    />
    <path
      d="M15.195 149.025c0 .476.224.727.504.727.084 0 .224 0 .336-.056.224-.111.448-.363.587-.615.168-.308.28-.644.28-.98 0-.615-.42-.895-.867-.615-.476.252-.84.951-.84 1.539Zm1.372-.924c-.029-.168-.112-.28-.224-.307l-.084-.028c-.056 0-.056-.084-.028-.168.028-.056.056-.084.084-.112h.056l.084.028a.375.375 0 0 1 .224.196c.027.084.083.167.083.279l.028.14c0 .056-.028.168-.084.196h-.027c-.056.028-.084 0-.112-.056v-.168Zm-.952 1.428c.028-.056.056-.112.084-.14h.14c.14 0 .28-.112.392-.252l.084-.112c.056-.056.112-.084.14-.028v.056a.214.214 0 0 1-.056.14l-.084.112c-.112.14-.224.224-.308.279a.451.451 0 0 1-.252.084h-.14c0-.028-.028-.084 0-.139Zm-.28-.392v-.168c0-.168.028-.28.084-.448a.946.946 0 0 1 .168-.336l.112-.196c.056-.084.056-.14.084-.112.028.028.028.028.028.084 0 .028 0 .084-.028.14l-.084.14a1.103 1.103 0 0 0-.168.588v.112a.236.236 0 0 1-.112.196c-.112.056-.084.084-.084 0Z"
      fill="#0064DC"
    />
    <path
      d="M16.119 147.515c-.448.251-.812.951-.812 1.567 0 .615.364.895.812.615.448-.252.811-.951.811-1.567 0-.587-.363-.867-.811-.615Zm-.56 1.483a.236.236 0 0 1-.112.196c-.056.028-.084 0-.084-.084v-.112c0-.14.028-.308.084-.476a.763.763 0 0 1 .168-.336l.084-.14c.056-.084.112-.112.14-.056.028.028.028.056.028.084 0 .028 0 .084-.028.14l-.084.14a1.103 1.103 0 0 0-.168.588l-.028.056Zm.952.252-.084.112a1.328 1.328 0 0 1-.308.279.451.451 0 0 1-.252.084h-.112c-.056 0-.084-.056-.056-.14a.612.612 0 0 1 .084-.14h.14c.14 0 .28-.111.392-.251l.084-.112c.056-.056.112-.084.14-.028v.056c.028.028 0 .084-.028.14Zm.28-.896c-.056.028-.112.028-.112-.056l-.028-.14c-.028-.168-.112-.28-.224-.308l-.084-.028c-.056 0-.056-.084-.028-.168.028-.055.056-.083.084-.111.028 0 .028-.028.056 0l.084.028a.372.372 0 0 1 .223.195c.029.084.056.168.084.28l.029.14c.027.028-.029.112-.084.168Z"
      fill="#004EB6"
    />
    <path
      d="m15.867 118.049-.588-.364.756-.419.615.363-.783.42Z"
      fill="#0064DC"
    />
    <path d="M16.65 148.971v-31.342l-.783.42v31.342l.783-.42Z" fill="#2F455F" />
    <path
      d="m15.867 149.391-.588-.364v-31.341l.588.363v31.342Z"
      fill="#0064DC"
    />
    <path
      d="m83.588 130.167.14-.924-20.4-11.725h-.029l-.084-.056c-.167-.084-.363-.084-.587.028-.112.056-.224.112-.336.196l-.168.14a2.113 2.113 0 0 0-.308.392 3.414 3.414 0 0 0-.56 1.539v.028c-.055.588.14 1.035.448 1.203h.028l20.4 11.697.952-.307-1.091-.672a3.152 3.152 0 0 0 .923-1.959l.672.42Z"
      fill="#71ADEE"
    />
    <path
      d="M84.231 130.503c-.084.98-.7 1.987-1.371 2.267-.672.28-1.175-.28-1.091-1.231.084-.98.7-1.987 1.37-2.267.672-.308 1.148.252 1.092 1.231Z"
      fill="#2F455F"
    />
    <path
      d="m45.026 136.464-33.497-19.337 33.273-19.225 33.525 19.337-33.301 19.225Z"
      fill="#71ADEE"
    />
    <path
      d="M78.327 126.865v-9.627l-33.301 19.225v9.627l33.3-19.225Z"
      fill="#004EB6"
    />
    <path
      d="m45.026 146.09-33.497-19.337v-9.626l33.497 19.337v9.626ZM4.254 141.136l-.616-.392.783-.42.588.392-.755.42Z"
      fill="#0064DC"
    />
    <path
      d="M5.009 156.386v-15.671l-.756.42v15.643l.756-.392Z"
      fill="#004EB6"
    />
    <path
      d="m4.254 156.779-.616-.364v-15.671l.616.392v15.643Z"
      fill="#71ADEE"
    />
    <path
      d="M4.254 140.411v.727l-.616-.392.028-.699.588.364ZM15.279 133.357l-11.614 6.689.588.363 11.641-6.688-.615-.364Z"
      fill="#71ADEE"
    />
    <path
      d="m15.895 133.723-.028.727-11.614 6.688v-.727l11.642-6.688Z"
      fill="#004EB6"
    />
    <path
      d="M34.56 173.851 0 154.094l9.263-5.569 34.56 19.757-9.263 5.569Z"
      fill="#E2F6FF"
    />
    <path
      d="M43.823 169.736v-1.455l-9.263 5.569v1.483l9.263-5.597Z"
      fill="#71ADEE"
    />
    <path
      d="M34.56 175.333 0 155.577v-1.483l34.56 19.756v1.483Z"
      fill="#ABE0F6"
    />
    <path
      d="M33.944 162.63a9.02 9.02 0 0 0-1.987.895 10.625 10.625 0 0 0-3.945 4.226c-.056.084-.084.168-.112.224-.476.951-.7 1.986-.924 2.994l-10.913-6.241c.112-.755.28-1.483.476-2.21.28-1.148.671-2.323 1.427-3.246.587-.7 1.37-1.232 2.182-1.679a19.872 19.872 0 0 1 2.687-1.316l11.11 6.353Z"
      fill="#71ADEE"
    />
    <path
      d="M27.228 156.052c-.112.672-.307 1.315-.671 1.903-1.12 1.707-3.414 2.183-4.953 3.526-.644.56-1.148 1.287-1.707 1.959-.756.923-1.651 1.679-2.547 2.434-.7.588-1.4 1.176-2.183 1.595-.811.42-1.707.672-2.602.56a1.637 1.637 0 0 0-.364-.056c-2.099-.56-1.987-3.274-1.4-5.009.42-1.119 1.148-2.155 2.184-2.798.168-.112.363-.224.531-.336 1.148-.644 2.351-1.148 3.302-2.099a12 12 0 0 0 2.575-3.974c.14-.363.252-.727.336-1.063.14-.56.252-1.119.336-1.679.111-.728.42-1.763 1.343-1.763.7 0 1.203.644 1.79 1.063.644.42 1.4.616 2.072.98 1.595.895 2.238 2.938 1.958 4.757Z"
      fill="#E2F6FF"
    />
    <path
      d="M27.228 156.053c-.112.671-.308 1.315-.671 1.903-1.12 1.707-3.414 2.182-4.953 3.526-.644.559-1.148 1.287-1.707 1.958-.756.924-1.652 1.679-2.547 2.435-.7.588-1.4 1.175-2.183 1.595-.811.42-1.707.672-2.602.56a1.636 1.636 0 0 0-.364-.056c.196-.42.392-.84.644-1.232 1.287-2.154 3.274-3.777 5.009-5.568 1.735-1.819 3.33-4.002 3.47-6.493.056-1.091-.868-1.455-1.595-2.014.14-.56.251-1.12.335-1.679.112-.728.42-1.763 1.344-1.763.7 0 1.203.643 1.79 1.063.644.42 1.4.616 2.071.979 1.595.924 2.24 2.967 1.96 4.786Z"
      fill="#AAD2F6"
    />
    <path
      d="M45.194 102.604a24.274 24.274 0 0 1-1.008 3.442c-1.175 3.302-3.078 6.296-5.68 8.563-1.96 1.707-4.254 2.938-6.604 4.058a74.828 74.828 0 0 1-9.459 3.75c-.728.252-1.455.447-2.183.671l.42 1.176c2.127 6.688 2.91 13.712 3.75 20.68.196 1.455.364 2.882.727 4.309.28 1.12.084 2.267-1.007 2.855-.56.307-1.203.391-1.847.391-.196 0-.364-.028-.532-.028-.503-.056-.979-.14-1.427-.336a1.129 1.129 0 0 1-.308-.195c-.447-.392-.531-1.288-.755-1.847-.224-.532-5.233-13.768-6.464-17.798-.616-1.959-1.036-3.974-1.456-5.989-.307-1.511-.643-3.106-.42-4.645.225-1.679 1.316-3.05 2.491-4.225.84-.84 5.933-5.765 7.752-7.332 3.722-3.302 7.444-6.632 11.333-9.739.532-.42 1.064-.84 1.623-1.259 2.183-1.567 4.282-3.386 6.577-4.813 2.042-1.287 4.085 1.371 4.589 3.162.448 1.651.252 3.442-.112 5.149Z"
      fill="#71ADEE"
    />
    <path
      d="M44.187 106.045c-1.176 3.302-3.079 6.297-5.681 8.563-1.959 1.707-4.254 2.939-6.604 4.058a74.685 74.685 0 0 1-9.459 3.75c-.728.252-1.455.448-2.183.672l.42 1.175c2.127 6.688 2.91 13.712 3.75 20.68.196 1.455.364 2.882.728 4.309.28 1.12.083 2.267-1.008 2.855-.56.308-1.203.392-1.847.392-.196-.42-.364-.868-.56-1.288-.839-1.987-1.315-3.945-1.902-6.044-.924-3.386-1.987-6.744-2.91-10.158-.448-1.707-.896-3.414-1.232-5.121-.28-1.372-.728-3.219-.196-4.562 1.343-3.19 4.17-5.429 6.716-7.639 2.743-2.351 5.457-4.73 8.171-7.08 2.043-1.763 4.086-3.526 6.101-5.289 1.035-.896 1.875-1.791 2.323-3.106.363-1.092-.112-2.379.28-3.498.195-.476.671-1.176 1.23-1.204.756-.028 1.12.98 1.26 1.567.196.812.168 1.651.812 2.239.671.616 1.063 1.091 1.343 2.043.336.867.42 1.763.448 2.686Z"
      fill="#004EB6"
    />
    <path
      d="M41.052 86.962c.224.811.196 1.65.112 2.49-.308 2.687-1.539 5.177-2.938 7.472-1.4 2.295-3.022 4.478-4.31 6.856a5.006 5.006 0 0 0-2.742-.755c-.643 0-.98-.308-1.007-.98-.028-.532.363-1.063.56-1.539a33.52 33.52 0 0 0 1.482-3.946c.252-.755.476-1.539.7-2.322a730.646 730.646 0 0 0 2.21-7.36c.365-1.203 1.176-2.239 1.652-3.386a19.18 19.18 0 0 0 1.203-3.778c.364-1.567.532-3.134.616-4.73.056-.783.084-1.538.112-2.322.056.056.112.14.168.196.84.98 2.015 1.707 3.302 1.847.168.7.308 1.427.336 2.155.027.783-.056 1.595-.112 2.406a76.593 76.593 0 0 0-.224 7.612 8.005 8.005 0 0 0-1.316-.616c.056.224.14.448.196.7Z"
      fill="#FFCFB3"
    />
    <path
      d="M33.19 156.836c-.225 1.427-1.232 2.154-2.1 3.134-1.007 1.203-1.51 2.714-2.126 4.141a18.35 18.35 0 0 1-1.371 2.519c-.42.699-.784 1.427-1.4 1.987-1.287 1.231-3.05 1.903-4.785 2.322-1.091.252-2.183.448-3.302.42-.196.028-.392 0-.588 0-.98-.056-1.987-.28-2.77-.895-1.035-.84-1.343-2.267-.392-3.246.896-.952 1.903-1.819 2.827-2.743a27.104 27.104 0 0 0 4.225-5.373 26.137 26.137 0 0 0 1.567-3.05c.14-.336.28-.672.392-1.007.084-.196.14-.42.224-.616.168-.42.42-.644.727-.952.392-.391.84-.755 1.344-.979 1.902-.895 4.337-.644 5.988.672 1.12.839 1.735 2.266 1.54 3.666Z"
      fill="#E2F6FF"
    />
    <path
      d="M33.189 156.836c-.224 1.427-1.231 2.155-2.099 3.134-1.007 1.204-1.51 2.715-2.127 4.142-.391.867-.84 1.707-1.37 2.519-.42.699-.784 1.427-1.4 1.986-1.287 1.232-3.05 1.903-4.785 2.323-1.092.252-2.183.448-3.302.42a18.583 18.583 0 0 0 5.54-4.002c2.463-2.658 4.086-6.156 4.254-9.766.028-.476 0-1.008-.392-1.316-.28-.195-.615-.223-.951-.251-.952-.112-2.35-.336-3.162-1.008.084-.196.14-.42.224-.615.167-.42.42-.644.727-.952.392-.392.84-.755 1.343-.979 1.903-.896 4.338-.644 5.989.671 1.091.868 1.707 2.295 1.511 3.694Z"
      fill="#AAD2F6"
    />
    <path
      d="M65.818 117.547c-1.175 1.372-2.714 2.379-4.281 3.247-2.407 1.315-4.897 2.07-7.444 2.938a169.384 169.384 0 0 1-7.276 2.267c-3.246.895-6.52 1.707-9.794 2.378-1.595.308-3.302.812-4.953.924-.504.028-1.064-.14-1.4.335-.307.448-.083 1.092.057 1.568.363.979.643 1.986.867 3.022.616 2.994.56 6.072.42 9.095-.14 2.686-.392 5.4-.644 8.087-.112 1.175-.224 1.987-1.147 2.826-.448.42-.98.728-1.567.924-.28.112-.588.168-.896.224-.867.111-1.763-.028-2.602-.308-.84-.28-1.54-.448-1.54-1.343 0-.364 0-.7-.055-1.064-.196-1.847-5.065-20.12-5.15-20.512-.475-1.735-.95-3.498-.839-5.261.364-4.309 4.254-6.576 7.584-8.479a51.338 51.338 0 0 1 2.742-1.455c3.526-1.791 7.192-3.274 10.802-4.813.588-.252 1.175-.504 1.763-.784.392-.168.783-.336 1.063-.672.14-.139.224-.307.28-.503.14-.308.168-.672.252-1.008.252-1.679.196-4.085 1.371-5.457 1.427-1.735 3.526-2.434 5.653-1.623.644.252 1.26.56 1.847.924.392.196.783.448 1.203.615 3.806 1.791 8.255.98 12.341.028.28-.055.588-.167.868-.111.14.028.28.307.391.307.196-.028 1.036-.811.952-.979.308.7.56 1.455.783 2.239 1.148 4.057 1.12 9.178-1.65 12.424Z"
      fill="#71ADEE"
    />
    <path
      d="M65.818 117.548c-1.175 1.371-2.714 2.378-4.282 3.246-2.406 1.315-4.897 2.071-7.443 2.938a167.981 167.981 0 0 1-7.276 2.267 151.02 151.02 0 0 1-9.794 2.378c-1.595.308-3.302.812-4.954.924-.503.028-1.063-.14-1.399.336-.308.447-.084 1.091.056 1.567.364.979.644 1.987.868 3.022.615 2.994.56 6.072.42 9.095-.14 2.686-.392 5.401-.644 8.087-.112 1.175-.224 1.987-1.147 2.826-.448.42-.98.728-1.567.924a359.59 359.59 0 0 0-2.043-19.729c-.28-2.238-.588-4.505-.224-6.716.252-1.539.923-3.498 2.183-4.505 1.007-.784 2.154-1.204 3.302-1.679 1.343-.56 2.658-1.092 4.001-1.651.588-.252 1.204-.504 1.68-.924.42-.336.615-.755.559-1.091-.028-.364-.392-.672-1.063-.7-1.148-.028-2.267 0-3.414.028-1.791.084-3.61.336-5.401.224-.252 0-.504-.028-.728-.168-.224-.14-.364-.42-.252-.643.056-.112.14-.196.224-.28.14-.112.308-.252.448-.364.56-.448 1.175-.924 1.483-1.483.308-.588.308-.952.951-1.315 1.092-.588 2.407-.476 3.582-.784 2.77-.7 5.457-1.763 7.92-3.19a30.878 30.878 0 0 0 5.233-3.666c1.315-1.147 2.574-2.407 3.862-3.554.391.196.783.448 1.203.616 3.806 1.791 8.255.979 12.34.028.28-.056.588-.168.868-.112.14.028.28.308.392.308.196-.028 1.035-.812.951-.98.308.7.56 1.455.784 2.239 1.12 4.113 1.091 9.234-1.679 12.481Z"
      fill="#004EB6"
    />
    <path
      d="M66.853 84.19c-.447 2.686-.447 5.429-.252 8.115.196 2.743.588 5.485.952 8.2.196 1.315.392 2.63.532 3.973.084.728.168 1.511-.14 2.211-.504 1.147-1.903 1.651-3.162 1.903-3.387.728-6.857.84-10.299.392a29.814 29.814 0 0 1-8.647-2.491c-1.65-.755-3.274-1.679-4.56-2.966-1.484-1.483-2.24-3.274-2.156-5.373.112-3.022.42-6.072.924-9.067.224-1.399.503-2.882.028-4.225-.532-1.483-1.931-2.687-2.043-4.254-.14-1.875 1.54-3.33 2.239-5.065.755-1.847.755-4.086 1.007-6.016.308-2.211.672-4.394 1.063-6.577.252-1.399.672-2.602 1.68-3.61 1.035-1.035 2.686-1.51 4.057-1.902 1.315-.392 2.715-.7 4.058-.448 1.735.336 3.134 1.539 4.421 2.714 3.694 3.386 7.22 6.996 10.69 10.606.672.7 1.343 1.4 1.959 2.155.168.224.364.504.532.783.503.924.811 2.071.251 2.967-.223.364-.615.615-.895.923-.42.476-.588 1.064-.728 1.651-.14.504-.391.924-.587 1.427-.392 1.288-.7 2.63-.924 3.974Z"
      fill="#FFDC1E"
    />
    <path
      d="M67.945 106.689c-.504 1.147-1.903 1.651-3.162 1.903-3.386.727-6.856.839-10.298.392 0-1.148.028-2.295.112-3.47.112-2.687.308-5.373.643-8.06.56-4.813 1.484-9.626 3.023-14.243a55.581 55.581 0 0 1 2.686-6.689c.364-.727.924-2.518 1.707-2.854 1.092-.448 1.54 1.091 1.623 1.847.868 7.192.112 14.58 2.099 21.548.336 1.147.756 2.294 1.147 3.414.196 1.315.392 2.63.532 3.973.112.756.196 1.539-.112 2.239Z"
      fill="#E39F3A"
    />
    <path
      d="M76.844 109.571c-.224 2.462-.644 5.065-2.155 7.024-1.231 1.595-3.05 2.602-4.701 3.721-2.183 1.456-3.079 3.219-4.562 5.205-.392.532-.811 1.036-1.231 1.568-1.26 1.511-2.603 2.938-4.142 4.225-1.12.952-2.35 1.875-3.721 2.435-.924.391-1.931.643-2.91.951-2.8.924-6.297 2.631-7.5 5.457-.112.28-.196.532-.28.84-.532 2.182-.448 4.449-.56 6.716-.112 2.238-.448 4.561-1.735 6.408-1.287 1.847-3.694 3.05-5.82 2.295-1.652-.588-2.743-2.155-3.946-3.414-.868-.924-2.1-1.819-3.386-1.875-1.316-.056-2.463.363-3.89.168a18.418 18.418 0 0 1-7.836-3.023c-1.595-1.091-2.798-2.07-3.358-3.917-.643-2.071-1.259-4.114-1.987-6.157-1.035-2.938-2.294-5.82-2.994-8.843-1.007-4.141-.98-8.619 1.4-12.285a29.545 29.545 0 0 1 2.098-2.826c2.407-2.91 5.317-5.429 8.2-7.863 3.945-3.275 7.667-6.885 11.78-9.935 1.96-1.455 4.002-2.854 6.017-4.225-.084 1.231-.28 2.49-.056 3.694.224 1.147 1.148 2.098 1.847 3.022 1.008 1.427 2.21 2.742 3.778 3.582 2.323 1.259 5.149 1.315 7.78.839 2.602-.475 5.092-1.455 7.667-2.154.224-.056.448-.112.7-.112.531.028.951.419 1.315.783.98.952 1.93 2.099 2.994 3.05 2.21 1.931 5.597-.028 7.975 0 1.092.028 2.211.476 2.799 1.372.587.979.503 2.182.42 3.274Z"
      fill="#F6F6F6"
    />
    <path
      d="M64.167 127.091c-1.26 1.511-2.602 2.938-4.142 4.225-1.119.952-2.35 1.875-3.721 2.435-.924.392-1.931.643-2.91.951-2.8.924-6.297 2.631-7.5 5.457-.112.28-.196.532-.28.84-.532 2.182-.448 4.449-.56 6.716-.112 2.238-.448 4.561-1.735 6.408-1.287 1.847-3.694 3.05-5.82 2.295-1.652-.588-2.743-2.155-3.946-3.414-.868-.924-2.099-1.819-3.386-1.875-1.316-.056-2.463.364-3.89.168a18.418 18.418 0 0 1-7.835-3.023c-1.596-1.091-2.799-2.07-3.359-3.917-.643-2.071-1.259-4.114-1.986-6.157-1.036-2.938-2.295-5.82-2.995-8.843-1.007-4.141-.98-8.619 1.4-12.285a29.656 29.656 0 0 1 2.098-2.826c-.112 1.651.616 2.994 1.763 4.478 1.651 2.126 3.918 3.749 6.52 4.505 1.652.476 3.387.616 5.01 1.147 3.89 1.232 6.8 4.422 10.438 6.297 4.757 2.462 10.465 2.462 15.67 1.119 3.918-1.007 7.612-2.742 11.166-4.701Z"
      fill="#AAD2F6"
    />
    <path
      d="M38.506 92.334c-.448 1.175-.952 2.294-.924 3.61.056 1.287.588 2.518 1.204 3.665 1.12 2.099 2.518 4.086 4.393 5.513 1.567 1.203 3.414 1.959 5.317 2.519 6.212 1.819 13.096 1.343 19.001-1.316.196-.084.364-.167.504-.307.224-.252.224-.616.224-.952a15.007 15.007 0 0 0-1.036-5.065c-.643.672-1.679.812-2.602.84-2.239.112-4.478.056-6.716-.112-3.414-.224-6.856-.672-9.963-2.1-1.93-.895-3.693-2.182-5.009-3.86-1.427-1.848-2.043-3.947-2.826-6.101-.028-.028-.028-.084-.084-.084-.056-.028-.112.056-.14.112-.252.56-.14 1.203-.448 1.735-.252.643-.615 1.23-.895 1.903Z"
      fill="#3892E5"
    />
    <path
      d="M42.76 94.6c-.448.923-1.008 1.79-1.512 2.658-1.23 2.099-2.294 4.309-3.358 6.492-.867 1.763-1.735 3.554-2.378 5.429-.448 1.287-.812 2.631-1.54 3.806-1.007 1.623-2.742 2.77-4.645 3.078-.671.112-1.455.056-1.875-.448-.14-.168-.224-.335-.363-.475-.308-.224-.728-.112-1.12-.196-1.063-.28-.727-1.399-.951-2.155-.196-.7-1.204-.644-1.68-1.007-.699-.504-.055-1.428.28-1.959.476-.7 1.008-.98 1.82-1.288 1.035-.391 2.098-.811 3.05-1.427 2.714-1.763 4.141-4.897 5.429-7.863.643-1.483 1.315-2.967 1.959-4.45.755-.531 1.51-1.035 2.266-1.567.112-.28.224-.587.336-.895.28-.672.644-1.26.98-1.875.28-.532.195-1.203.447-1.763.028-.056.056-.112.14-.084.028 0 .056.056.056.084.756 2.07 1.343 4.114 2.659 5.905Z"
      fill="#3892E5"
    />
    <path
      d="M34.672 109.88a5.3 5.3 0 0 1-1.539 2.882c-.14.14-.28.252-.476.28-.224.056-.475-.056-.615-.224l-.028-.028c-.168-.196-.224-.448-.252-.699-.168-1.064.224-2.127.252-3.219.028-.251-.056-.559-.28-.699-.196-.084-.392-.028-.56.112-.14.14-.224.336-.308.504-.671 1.818-.308 3.861-.615 5.792-.056.392-.224.84-.588.98-.308.084-.7-.028-.924.168-.167.167-.223.475-.447.559-.168.056-.364-.084-.476-.252-.336-.475-.336-1.119-.532-1.679-.056-.14-.195-.336-.335-.28-.084 0-.168.112-.224.196-.112.224-.168.448-.28.644-.112.224-.308.392-.56.42-.224.028-.448-.14-.588-.336-.14-.196-.167-.42-.223-.672-.14-.643-.28-1.287-.28-1.959 0-.615.056-1.343-.392-1.763-.112.308-.28.588-.476.84-.224.308-.727.532-.98.252-.139-.14-.14-.364-.111-.56.056-.783.42-1.483.784-2.183.307-.559.587-1.119.95-1.595.924-1.203 2.184-2.07 3.359-2.994.56-.392 1.007-.895 1.4-1.427.251-.308.475-.616.699-.952.587-.839 1.063-1.679 1.595-2.546.056-.084.112-.196.168-.28-.056.084-.056.196-.056.252.056.224.308.42.475.532.364.279.644.699.98 1.035.196.196.392.336.644.336.307 0 .56-.28.727-.532 0 1.567-.028 3.106-.056 4.701.028 1.456.448 2.911.168 4.394Z"
      fill="#ABE0F6"
    />
    <path
      d="M34.672 109.879a5.301 5.301 0 0 1-1.54 2.883c-.139.14-.279.252-.475.28-.224.056-.475-.056-.615-.224.028-.056.055-.084.084-.14.335-.644.671-1.287.895-1.959.392-1.231.476-2.547.196-3.806-.14-.672-.42-1.343-.868-1.875-.531-.615-1.315-.951-2.042-1.371-.308-.196-.756-.392-.98-.7.252-.307.476-.615.7-.951.587-.84 1.063-1.679 1.595-2.547.056 0 .084-.028.14-.028.056.224.308.42.475.532.364.28.644.7.98 1.036.196.195.392.335.644.335.307 0 .56-.28.727-.531 0 1.567-.028 3.106-.056 4.701 0 1.427.42 2.882.14 4.365Z"
      fill="#71ADEE"
    />
    <path
      d="M35.344 102.324c-.084.392-.42 2.015-1.092 1.511a4.27 4.27 0 0 0-.448-.308c-.503-.363-1.035-.671-1.678-.811-.672-.14-1.372-.196-2.043-.252-.112-.028-.224-.084-.252-.14-.168-.252.168-.811.252-1.063.084-.196.14-.392.224-.56.28-.756.615-1.483 1.119-2.127.028.196.084.504.252.644.14.14.391 0 .587 0 .28-.028.56.084.728.308.084.14.112.28.196.391.112.168.28.308.448.42.447.308.951.532 1.483.644.56.112.308.895.224 1.343Z"
      fill="#FFDC1E"
    />
    <path
      d="M35.344 102.324c-.084.392-.42 2.015-1.092 1.511a4.26 4.26 0 0 0-.448-.308c.14-.419.084-.895-.111-1.287-.224-.448-.644-.783-1.12-.979-.7-.28-1.539-.168-2.239-.504-.027-.028-.055-.028-.055-.056.28-.756.615-1.483 1.119-2.127.028.196.084.504.252.644.14.14.392 0 .587 0 .28-.028.56.084.728.308.084.14.112.28.196.391.112.168.28.308.448.42.447.308.951.532 1.483.644.587.112.336.895.252 1.343Z"
      fill="#E39F3A"
    />
    <path
      d="M42.703 61.692c-.14.391-.223.811-.307 1.259-.112.643-.224 1.26-.336 1.903-.28 1.567-.504 3.134-.728 4.673-.28 1.959-.252 4.198-1.007 6.045a6.47 6.47 0 0 1-.504.979c-.811 1.343-1.875 2.575-1.735 4.086.056.671.308 1.259.672 1.847.028.028.028.056.056.084.475.755 1.035 1.51 1.315 2.322.476 1.343.28 3.134.224 4.534a20.056 20.056 0 0 1-.7 4.645c-.503 1.93-1.343 3.974-2.462 5.736-.028.056-.084.112-.112.168a10.74 10.74 0 0 1-1.651 2.043c-.056.056-.112.112-.196.112-.14.056-.224-.056-.336-.14-.112-.084-.196-.168-.308-.252-.168-.139-.364-.251-.56-.363-.027-.028-.056-.028-.084-.056-.223-.112-.447-.224-.7-.308-.727-.28-1.51-.42-2.238-.476-.14-.028-.252-.028-.392-.028-.056 0-.084 0-.14-.056l-.055-.056c-.056-.084-.028-.196 0-.28.727-2.742 3.861-11.25 4.449-12.844 1.007-2.827 2.043-5.653 2.686-8.591 1.176-5.317 1.931-10.634 3.386-15.895.112-.392.224-.784.364-1.175.112-.364.308-.812.588-1.12.448.168.643.7.811 1.176-.028-.028-.028 0 0 .028Z"
      fill="#FFDC1E"
    />
    <path
      d="M40.325 89.395a20.055 20.055 0 0 1-.7 4.645c-.504 1.931-1.343 3.974-2.462 5.737-.028.056-.084.112-.112.168a22.9 22.9 0 0 1-1.651 2.043c-.056.056-.112.112-.196.112-.14.056-.224-.056-.336-.14-.112-.084-.196-.168-.308-.252-.168-.14-.364-.252-.56-.364 0-.028.029-.056.029-.084.083-.196.14-.42.223-.615.7-1.987 1.344-4.03 1.903-6.073a102.363 102.363 0 0 0 1.54-6.436c.223-1.092.447-2.21.671-3.302.14-.672.14-1.651.364-2.379.028.028.028.056.056.084.475.756 1.035 1.511 1.315 2.323.476 1.343.28 3.134.224 4.533ZM42.06 64.824c-.28 1.567-.504 3.134-.728 4.674-.28 1.958-.252 4.197-1.007 6.044-.14.336-.308.672-.504.98.336-1.987.672-3.974 1.008-5.933.335-1.875.671-3.778 1.203-5.625 0-.028 0-.084.028-.14Z"
      fill="#E39F3A"
    />
    <path
      d="M58.71 64.574c-.252.755-.755 1.427-1.315 1.987-1.455 1.427-3.61 2.098-5.625 1.875a4.328 4.328 0 0 1-.867-.168c-.952-.252-1.875-.7-2.463-1.484-.14-.195-.28-.42-.308-.643-.056-.28.028-.588.084-.868.224-1.035-.196-2.126.056-3.134.224-.84.924-1.427 1.707-1.707.588-.224 1.203-.392 1.791-.644 2.183-.895 3.442-2.854 4.841-4.673-.14.196.028 1.343.056 1.595.084.448.196.868.252 1.316a9.55 9.55 0 0 0 .392 1.539c.14.447.336.867.56 1.259.28.476.615.895.84 1.4.279.727.251 1.594 0 2.35Z"
      fill="#ABE0F6"
    />
    <path
      d="M58.71 64.573c-.252.755-.755 1.427-1.315 1.987-1.455 1.427-3.61 2.098-5.625 1.874.084-.055.168-.111.224-.14 1.092-.755 1.707-2.126 1.567-3.441-.056-.448-.196-.924-.196-1.372-.028-.42 0-.755-.224-1.147-.14-.224-.307-.364-.391-.588-.28-.755.336-1.399.923-1.735 1.091-.643 2.49-.867 3.638-.475.14.447.336.867.56 1.259.28.476.615.895.84 1.4.28.755.251 1.622 0 2.378Z"
      fill="#71ADEE"
    />
    <path
      d="M58.738 57.242a3.456 3.456 0 0 1-2.126 1.567c-.392.112-.868 0-1.064.336-.252.364-.112 1.091-.252 1.511-.252.812-1.063 1.287-1.819 1.68-2.294 1.258-4.673 2.546-7.303 2.686h-.616c-.476-.028-.895-.14-1.26-.392-.307-.224-.531-.588-.755-.924-.392-.671-.7-1.37-.951-2.07-.168-.448-.336-1.008-.812-1.176-.28-.112-.587-.056-.811-.252-.196-.196-.224-.503-.168-.755.056-.252.224-.476.307-.728.448-1.203-.251-2.518-.111-3.778.028-.391.167-.84.028-1.203-.056-.196-.196-.336-.28-.504-.448-.783-.252-1.763-.028-2.63.308-1.287.727-2.603.895-3.89.14-1.063 1.148-1.679 2.043-2.07 2.658-1.092 5.877-.84 8.311.699.672.42 1.288.923 1.735 1.567.28.392.476.84.616 1.287.392 1.036.615 2.155 1.287 2.994.532.672 1.008.98 1.847 1.064 2.323.168 2.099 3.61 1.287 4.981Z"
      fill="#ABE0F6"
    />
    <path
      d="M58.738 57.243a3.456 3.456 0 0 1-2.127 1.567c-.392.112-.867 0-1.063.336-.252.364-.112 1.091-.252 1.511-.252.812-1.063 1.288-1.819 1.68-2.294 1.258-4.673 2.546-7.304 2.686h-.587c1.79-1.455 3.302-3.162 4.17-5.261.391-.896.615-1.875.559-2.827-.084-1.203-.616-2.35-.84-3.526-.251-1.427-.363-3.078.952-3.973.56-.392 1.231-.616 1.903-.784.112-.028.196-.056.308-.084a8.94 8.94 0 0 1 1.763-.336c.391 1.036.615 2.155 1.287 2.995.532.671 1.007.98 1.847 1.063 2.239.14 2.015 3.582 1.203 4.953Z"
      fill="#71ADEE"
    />
    <path
      d="M64.335 62.643c-.224.951-.615 1.875-1.371 2.462-.476.392-1.091.56-1.707.672-1.231.224-2.519.112-3.694-.252-.784-.252-1.4-.476-1.847-1.231-.476-.784-.112-1.4-.168-2.239-.028-.615-.587-1.091-.7-1.679-.111-.671.392-1.315.952-1.679.588-.364 1.26-.56 1.819-.98 1.175-.867 2.127-4.085.14-4.56a1.546 1.546 0 0 0-.868.083c-.867.308-1.483 1.064-1.874 1.903-.392.812-.56 1.735-.7 2.63-.56-.615-.756-1.483-1.12-2.238a5.398 5.398 0 0 0-1.623-2.015c-.335-.28-.727-.504-.923-.895-.252-.448-.196-.98-.252-1.483a2.765 2.765 0 0 0-.476-1.232A3.32 3.32 0 0 0 49 48.96c-.728-.504-1.623-.728-2.519-.7-.867.056-1.735.364-2.63.364-1.567 0-2.854-1.511-2.603-3.05.084-.448.28-.896.588-1.232.224-.224.504-.447.812-.56.363-.14.727-.167 1.063-.307.98-.42 1.315-1.651 1.987-2.519.811-1.063 2.267-1.595 3.554-1.315 1.455.336 2.267.924 3.638.028.951-.643 2.21-.811 3.302-.476 1.091.336 2.015 1.204 2.434 2.267.112.28.196.616.392.868.28.42.756.643 1.231.84.476.195.98.363 1.372.643.98.727 1.147 2.127 1.063 3.33-.112 1.203-.336 2.49.084 3.638.532 1.343 1.26 2.35.812 3.861-.196.672-.476 1.288-.448 1.96.056 1.035.84 1.902 1.203 2.882.336 1.063.252 2.126 0 3.162Z"
      fill="#F6F6F6"
    />
    <path
      d="M64.335 62.644c-.224.952-.616 1.875-1.371 2.463-.476.391-1.092.56-1.707.671-1.231.224-2.519.112-3.694-.252-.784-.251-1.4-.475-1.847-1.23-.476-.784-.112-1.4-.168-2.24-.028-.615-.588-1.09-.7-1.679-.111-.671.392-1.315.952-1.679.588-.363 1.26-.56 1.819-.98 1.175-.867 2.127-4.085.14-4.56a1.546 1.546 0 0 0-.868.084c-.867.307-1.483 1.063-1.875 1.902-.391.812-.56 1.735-.7 2.63-.559-.615-.755-1.482-1.119-2.238a5.395 5.395 0 0 0-1.623-2.015c-.335-.28-.727-.503-.923-.895-.252-.448-.196-.98-.252-1.483a2.767 2.767 0 0 0-.476-1.232c.784.336 1.456.616 2.351.448.98-.168 1.903-.783 2.407-1.65 1.819-.113 3.582-1.12 4.589-2.631a5.646 5.646 0 0 0 .98-2.883c.475.196.979.364 1.37.644.98.727 1.148 2.127 1.064 3.33-.112 1.203-.336 2.49.084 3.638.532 1.343 1.26 2.35.811 3.862-.196.671-.475 1.287-.447 1.959.056 1.035.84 1.903 1.203 2.882.336 1.035.252 2.099 0 3.134Z"
      fill="#AAD2F6"
    />
    <path
      d="M30.838 119.423c3.526.084 6.996-1.007 10.27-2.407 3.247-1.399 6.38-3.078 9.683-4.337 2.127-.812 4.31-1.427 6.436-2.127 2.49-.839 4.925-1.791 7.276-2.938.476-.224.951-.476 1.371-.812.392-.308.448-.699.952-.923.28-.112.56-.196.783-.392.56-.448.56-1.231.504-1.875a15.98 15.98 0 0 0-.448-2.574 2.033 2.033 0 0 0-.252-.616 2.645 2.645 0 0 1-.98 1.231c-.81.56-1.958.756-2.91.924-.56.084-1.119.279-1.65.447-10.998 3.582-21.212 9.403-32.406 12.313-.615.168-1.26.308-1.79.672-.952.644-1.344 1.791-.309 2.49.952.728 2.267.896 3.47.924Z"
      fill="#3892E5"
    />
    <path
      d="M70.212 87.493c-.392 2.714-.868 5.4-1.456 8.087-.42 1.987-.895 3.945-1.763 5.764-.727 1.512-1.427 2.911-3.022 3.694-1.595.812-3.19 1.763-4.841 2.463-1.763.728-12.453 4.393-13.32 4.673-.224.056-.56.112-.896.196-.616.112-1.26.252-1.567.504-.7.559-1.371 1.119-2.155 1.511-.56.28-1.147.476-1.707.7-1.399.475-2.798.979-4.225 1.371-.896.252-1.82.447-2.715.671a57.88 57.88 0 0 1-4.393.84c-.308.056-.588.084-.868 0s-.503-.28-.56-.56c-.027-.028 0-.056 0-.056-.027-.196.113-.42.252-.587.336-.392.868-.644 1.148-1.092.196-.336.252-.755.532-1.035.111-.112.223-.168.307-.308.056-.112.084-.252.112-.364.112-.504.42-.923.756-1.315 1.063-1.343 2.35-2.575 4.002-3.022 1.007-.252 2.07-.224 3.106-.252.503-.028 1.007-.112 1.51-.168.029 0 .057-.028.085-.028.7-.308 1.79-.812 3.078-1.455 5.653-2.743 15.42-7.696 15.727-7.976 1.65-1.343 1.903-3.274 2.239-5.26.028-.029.028-.056.028-.085.251-1.483.42-2.966.56-4.477.083-.98.14-1.959.363-2.91.168-.728.42-1.735.868-2.35 1.063-1.372 2.798-2.268 4.533-2.352.867-.028 1.763.14 2.518.532.784.42 1.4 1.092 1.708 1.931.307.812.195 1.763.055 2.715Z"
      fill="#ABE0F6"
    />
    <path
      d="M44.914 112.341c-.616.112-1.26.252-1.567.504-.7.56-1.371 1.119-2.155 1.511-.56.28-1.147.476-1.707.7-1.399.475-2.798.979-4.225 1.371-.896.252-1.82.448-2.715.672-1.455.335-2.91.615-4.393.839-.308.056-.588.084-.868 0s-.503-.28-.56-.56c.308-.056.644-.112.952-.168a67.776 67.776 0 0 0 4.757-1.007c1.54-.392 3.078-.839 4.478-1.623.615-.336 1.231-.784 1.483-1.427.252-.644.084-1.399-.028-2.099-.14-.7-.14-1.343.196-1.931.7-.308 1.79-.811 3.078-1.455.783.14 1.567.532 2.07 1.175.448.532.7 1.176.924 1.819.14.532.308 1.12.28 1.679Z"
      fill="#71ADEE"
    />
    <path
      d="M45.95 111.895c-.056.056-.14.112-.224.168-1.036.588-2.071 1.203-3.106 1.819-.14.084-.252.14-.392.14-.168.028-.308-.028-.476-.084a3.249 3.249 0 0 1-.755-.392 4.04 4.04 0 0 1-.616-.587c-.224-.28-.392-.588-.56-.896-.252-.56-.475-1.147-.532-1.735-.055-.476 0-1.007.168-1.455.14-.392.504-.476.868-.672.056 0 .084-.028.14-.056l.84-.419c.643-.28 1.315-.56 2.014-.784.028-.028.056 0 .056 0 .028 0 .028.028.028.056.028.196.084.392.112.588.056.28.112.531.14.811.028.532.084 1.064.308 1.539.196.42.532.756.867 1.008.28.14 1.428.559 1.12.951Z"
      fill="#FFDC1E"
    />
    <path
      d="M45.95 111.895c-.056.056-.14.112-.224.168-1.036.587-2.071 1.203-3.107 1.819-.14.084-.252.14-.391.14-.168.028-.308-.028-.476-.084a3.25 3.25 0 0 1-.756-.392c.112-.084.224-.168.308-.28.392-.392.7-.923.672-1.483-.028-.7-.504-1.287-.672-1.959-.14-.532-.14-1.343.224-1.819.336-.448 1.287-.448 1.763-.476h.252c.056.28.112.532.14.812.028.532.084 1.063.308 1.539.196.42.531.755.867 1.007.252.196 1.4.616 1.092 1.008Z"
      fill="#E39F3A"
    />
    <path
      d="M70.66 90.178c-.42 3.134-.952 6.24-1.988 9.235-.475 1.343-.979 2.854-1.903 3.946-.867 1.035-1.902 1.679-3.134 2.266-5.96 2.855-12.005 5.709-18.469 7.136-.28.056-.588.112-.867.084-.364-.056-.672-.224-.952-.448-.056-.056-.112-.112-.168-.14a5.078 5.078 0 0 1-1.763-3.106c-.056-.503-.056-1.035.224-1.455.224-.336.616-.532.98-.727 4.421-2.211 9.038-4.142 13.18-6.885.84-.56 1.707-1.175 2.295-2.014.56-.784.447-2.015.56-2.939.251-2.406.53-4.84.643-7.275.056-1.512.084-3.023.308-4.534.503-3.47 1.315-8.619 4.337-10.942.7-.531 2.015-.923 3.078-.923.392.028.784.084 1.092.224.783.336 1.343.895 1.735 1.539.783 1.287.98 2.994 1.12 4.45.167 1.65.251 3.358.279 5.037.028 2.518-.252 4.953-.588 7.471Z"
      fill="#FFDC1E"
    />
    <path
      d="M70.66 90.179c-.42 3.134-.952 6.24-1.987 9.234-.476 1.344-.98 2.855-1.903 3.946-.868 1.036-1.903 1.679-3.135 2.267-5.96 2.854-12.005 5.709-18.469 7.136-.28.056-.588.112-.867.084-.364-.056-.672-.224-.952-.448-.056-.056-.112-.112-.168-.14 4.673-1.819 9.347-3.554 13.796-5.877 1.595-.839 3.162-1.735 4.562-2.91.951-.783 3.162-2.099 1.959-3.47-.42-.448-.98-.7-1.568-.951a52.057 52.057 0 0 0-1.847-.784c-.14-.084-.307-.168-.335-.336 0-.168.195-.28.363-.307.728-.14 1.484.111 2.211.335.98.28 1.931.448 2.938.504 1.847.112 2.267-2.519 2.519-3.918 1.12-6.128 1.12-12.397 1.175-18.58 0-1.008 0-2.1-.447-3.023-.364-.7-.896-1.036-1.484-1.427.392.028.784.084 1.092.224.783.335 1.343.895 1.735 1.539.783 1.287.98 2.994 1.12 4.45a60.09 60.09 0 0 1 .279 5.036c.028 2.463-.252 4.897-.588 7.416Z"
      fill="#E39F3A"
    />
    <path
      d="M65.51 141.137c0 7.415 2.463 12.648 6.352 14.915l1.931 1.147.196-.251c2.882.811 6.325.279 10.018-1.847 10.214-5.905 18.498-21.716 18.498-35.316 0-5.96-1.26-10.326-3.89-13.068l-2.49-1.875c-3.247-1.875-7.472-1.707-12.09.979-10.242 5.877-18.525 21.716-18.525 35.316Zm3.638.308c0-12.873 7.78-27.816 17.35-33.357 2.21-1.259 2.938-2.211 4.841-2.211 5.373 0 8.871 5.457 8.871 13.908 0 12.873-7.78 27.816-17.35 33.357-2.183 1.259-4.281 1.903-6.184 1.903-2.519 0-4.618-1.12-6.157-3.33-1.79-2.491-1.371-5.849-1.371-10.27Z"
      fill="#2F455F"
    />
    <path
      d="M67.133 141.865c0 4.589.336 8.115 2.183 10.689 1.623 2.295 3.526 3.75 6.156 3.75 1.987 0 4.142-.671 6.437-1.987 9.934-5.736 18.021-21.295 18.021-34.672 0-8.787-3.61-14.467-9.207-14.467-1.986 0-3.358 1.707-5.652 3.022-9.935 5.765-17.938 20.288-17.938 33.665Zm1.511 0c0-12.397 7.696-27.341 16.846-32.602 1.903-1.119 3.694-1.679 5.261-1.679 4.142 0 6.828 4.73 6.828 12.089 0 12.397-7.695 27.341-16.846 32.602-1.93 1.119-3.694 1.679-5.26 1.679-1.876 0-3.275-.784-4.478-2.463-1.54-2.183-2.35-5.513-2.35-9.626Z"
      fill="#3989E5"
    />
    <path
      d="M66.601 142.03c0 13.601 8.284 19.869 18.498 13.964 10.214-5.904 18.497-21.715 18.497-35.315 0-13.6-8.283-19.869-18.497-13.964C74.884 112.619 66.6 128.43 66.6 142.03Zm2.295 0c0-12.872 7.78-27.816 17.35-33.356 2.21-1.26 4.254-1.679 6.156-1.679 5.373 0 8.899 5.233 8.899 13.684 0 12.872-7.78 27.816-17.35 33.356-2.182 1.26-4.281 1.903-6.184 1.903-2.519 0-4.617-1.119-6.157-3.33-1.79-2.49-2.714-6.128-2.714-10.578Z"
      fill="#71ADEE"
    />
    <path
      d="M68.644 142.815c0 4.589.98 8.395 2.798 10.998 1.624 2.294 3.778 3.47 6.409 3.47 1.987 0 4.141-.672 6.436-1.987 9.934-5.737 18.022-21.296 18.022-34.672 0-8.787-3.61-14.468-9.207-14.468-1.987 0-4.142.672-6.436 1.987-9.935 5.737-18.022 21.296-18.022 34.672Zm1.707.196c-1.063-12.369 7.724-28.655 16.874-33.944 1.903-1.12 4.282-1.484 5.849-1.484 4.142 0 6.884 5.681 6.884 13.041 0 12.397-7.696 27.34-16.846 32.601-1.931 1.119-3.694 1.679-5.261 1.679-1.875 0-3.778-.644-4.953-2.323-1.54-2.182-2.1-4.533-2.547-9.57Z"
      fill="#3989E5"
    />
    <path
      d="M68.28 142.508c0 4.59.98 8.396 2.799 10.998 1.623 2.295 3.778 3.47 6.408 3.47 1.987 0 4.142-.672 6.436-1.987 9.934-5.737 18.022-21.295 18.022-34.672 0-8.787-3.61-14.467-9.207-14.467-1.987 0-4.141.671-6.436 1.986-9.934 5.765-18.022 21.296-18.022 34.672Zm2.379 0c0-12.396 7.695-27.34 16.846-32.601 1.903-1.119 3.694-1.679 5.261-1.679 4.142 0 6.828 4.73 6.828 12.089 0 12.397-7.695 27.34-16.846 32.601-1.931 1.12-3.694 1.679-5.261 1.679-1.875 0-3.274-.783-4.477-2.462-1.54-2.183-2.351-5.513-2.351-9.627Z"
      fill="#004EB6"
    />
    <path
      d="M79.502 132.574c0 1.679.784 2.658 1.875 2.602.336 0 .811 0 1.203-.223.784-.448 1.567-1.316 2.099-2.267.588-1.063.98-2.379.98-3.526 0-2.183-1.54-3.218-3.19-2.267-1.624.952-2.967 3.498-2.967 5.681Zm4.981-3.33c-.084-.616-.364-1.035-.783-1.175l-.28-.084c-.168-.056-.224-.336-.112-.616.084-.196.196-.336.308-.42.056-.028.14-.056.195-.028l.28.084c.336.112.616.336.84.672.14.28.252.587.308.979l.056.532c.028.252-.112.588-.308.728l-.056.056c-.168.111-.336.027-.364-.224l-.084-.504Zm-3.526 5.149c.056-.224.196-.42.336-.504.056-.028.084-.028.14-.056l.392-.028c.475-.028 1.007-.391 1.455-.951l.28-.364c.168-.224.363-.252.475-.112.028 0 .028.028.028.028.028.056.028.14.028.196a.91.91 0 0 1-.196.56l-.28.363c-.363.448-.755.812-1.175 1.064-.308.168-.615.28-.895.308l-.392.028c-.056 0-.112 0-.14-.056-.056-.084-.112-.252-.056-.476Zm-1.035-1.427-.056-.252.028-.336c.028-.56.112-1.035.308-1.595.14-.448.335-.784.587-1.175l.392-.7c.168-.252.168-.532.308-.364.056.056.084.14.084.252a.93.93 0 0 1-.14.448l-.336.56c-.392.643-.644 1.427-.644 2.098v.448c0 .28-.168.588-.391.7-.224.084-.14.168-.14-.084Z"
      fill="#0064DC"
    />
    <path
      d="M82.804 127.062c-1.651.951-2.966 3.498-2.966 5.681 0 2.182 1.343 3.19 2.966 2.238 1.651-.951 2.966-3.498 2.966-5.68 0-2.183-1.315-3.191-2.966-2.239Zm-2.043 5.429c0 .28-.168.587-.392.699-.195.112-.363-.028-.363-.28V132.435a6.54 6.54 0 0 1 .335-1.735c.14-.448.364-.868.588-1.26l.336-.559c.168-.252.392-.364.531-.224.056.056.084.14.084.252 0 .14-.055.308-.14.476l-.335.559c-.392.644-.644 1.427-.644 2.099v.448Zm3.47.839-.28.364a4.124 4.124 0 0 1-1.175 1.063c-.308.168-.616.28-.895.308l-.392.028c-.196 0-.28-.224-.224-.503.056-.224.196-.42.336-.504.056-.028.084-.028.14-.056l.391-.028c.476-.028 1.008-.364 1.456-.952l.28-.363c.167-.224.391-.28.503-.084a.52.52 0 0 1 .056.196c0 .167-.056.391-.196.531Zm1.064-3.218c-.029.028-.029.028-.056.028-.168.112-.336.028-.364-.196l-.056-.531c-.084-.616-.364-1.036-.784-1.176l-.28-.084c-.167-.056-.224-.336-.111-.615.083-.196.195-.336.307-.42.056-.028.14-.056.196-.028l.28.084c.336.084.616.336.84.671.14.252.251.588.307.98l.056.532c0 .279-.14.587-.335.755Z"
      fill="#004EB6"
    />
    <path d="M82.636 113.74v38.142h.168v-38.31l-.168.168Z" fill="#004EB6" />
    <path
      d="m76.983 154.205.168.028 5.373-22.163.112-.476v-.112l.028-.112v-.195l.084-.28 5.121-21.184c-.056.028-.14.056-.196.112l-4.925 20.372-.14.56-.028.112-.028.084-.028.14-.028.056V131.51l-.028.056-.028.14-.056.196-.028.14-.168.728-5.205 21.435Z"
      fill="#004EB6"
    />
    <path
      d="M72.73 151.686c.027.056.083.112.111.168l9.347-19.113.336-.671.14-.252.028-.084.056-.112.028-.028.028-.028v-.028l.028-.028v-.056l.028-.056v-.028l.028-.028.056-.112.028-.084.056-.112.223-.476 10.774-22.023-.14-.084-11.081 22.639v.028l-.056.084v.028l-.028.028v.056l-.028.028-.028.084v.056l-.028.028-.056.112-.084.168-.112.224-9.654 19.672Z"
      fill="#004EB6"
    />
    <path
      d="m82.132 131.929-11.277 13.88c0 .084.028.14.028.224l11.501-14.132.084-.112.168-.196v-.028l.028-.056v-.028l.028-.028.028-.028.028-.028.028-.028.028-.028.084-.084.084-.084.196-.224 14.3-17.573-.14-.112-14.104 17.35-.448.531v.028l-.056.056v.028l-.028.028-.028.028-.028.028-.028.028v.028l-.028.028-.028.028h-.028l-.028.028-.028.028-.056.084-.056.084-.224.252Z"
      fill="#004EB6"
    />
    <path
      d="m70.715 140.801 11.417-8.843.308-.224.14-.084.084-.056.056-.028.028-.028.056-.028.028-.028h.028l.028-.028.196-.14.335-.252 15.196-11.809-.112-.14-15.335 11.893-.168.14-.224.168-.056.028-.028.028-.056.028h-.056l-.028.028-.028.028h-.028l-.028.028-.056.056-.252.196-11.417 8.871c-.028.056-.028.112-.028.196Z"
      fill="#004EB6"
    />
    <path
      d="m71.163 136.798 10.997-5.093.252-.112.056-.028.14-.056.028-.028h.028l.028-.028.056-.028h.056l.028-.027h.028l.084-.056.447-.196 14.916-6.912-.056-.14-14.832 6.856-.42.196-.027.028-.168.056-.056.028h-.028v.028h-.056v.028h-.056l-.028.028-.028.028h-.056l-.056.028-.196.111-.224.084-10.83 5.01-.027.195Z"
      fill="#004EB6"
    />
    <path
      d="m71.806 133.719 10.214-2.127.392-.084h.056l.14-.028h.028l.084-.027h.14l.14-.028.112-.028 14.355-2.995-.028-.168-14.075 2.911-.308.056-.168.028-.112.028H82.384l-.112.028-.588.112-9.85 2.042c0 .14 0 .196-.028.28Z"
      fill="#004EB6"
    />
    <path
      d="m72.506 131.23 9.207.252.503.028H83.056l.531.028 13.04.335v-.168l-13.515-.363H82.132l-9.626-.252c.028.028.028.084 0 .14Z"
      fill="#004EB6"
    />
    <path
      d="m73.261 129.021 8.927 2.294.14.028.14.028.056.028h.084l.028.028h.112l.056.028.056.028h.028l.084.028.28.084 12.313 3.19.028-.168-11.95-3.078-.587-.14-.084-.028h-.112l-.028-.028h-.168l-.056-.028h-.056l-.14-.028-.42-.112-8.647-2.21c-.055-.056-.084 0-.084.056Z"
      fill="#004EB6"
    />
    <path
      d="m74.073 126.976 7.891 4.114.448.223h.028l.084.028.028.028.028.028h.028l.028.028h.14l.056.028.028.028.084.056.112.056.308.168 11.081 5.793.084-.14-11.193-5.849-.196-.084-.224-.112-.028-.028-.028-.028h-.056l-.056-.028h-.056l-.056-.028h-.056l-.084-.056-.308-.167-8.003-4.198c-.084.028-.112.084-.112.14Z"
      fill="#004EB6"
    />
    <path
      d="m74.997 124.878 7.135 6.128.224.196.14.112h.028l.028.028.028.028.028.028h.112l.056.056.028.028h.028l.14.112.28.224 9.738 8.367.112-.112-9.766-8.255-.336-.28-.028-.028-.112-.112h-.028l-.028-.028h-.028l-.056-.028v-.028l-.028-.028-.028-.028-.056-.056h-.028l-.028-.028-.28-.252-7.163-6.156c-.056 0-.084.056-.112.112Z"
      fill="#004EB6"
    />
    <path
      d="m76.172 122.611 6.072 8.283.224.28.056.056.028.028.028.028.028.028.028.028.028.028.028.028v.028h.028l.028.028.056.084.056.084.028.028.252.364 8.059 10.969.14-.083-8.06-10.97-.223-.28-.112-.14-.084-.084h-.028l-.028-.028v-.056l-.028-.028-.028-.028-.028-.028-.056-.084-.056-.084-.028-.056-.168-.223-6.128-8.368c-.056.056-.084.112-.112.168Z"
      fill="#004EB6"
    />
    <path
      d="m77.739 119.953 4.673 10.858.14.336.028.056.028.028v.028l.028.027v.056l.028.056v.028l.028.028v.028l.056.112.084.168.084.196 6.184 14.384.14-.056-6.1-14.216-.14-.336-.056-.112-.028-.084-.028-.084v-.028l-.028-.028v-.028l-.028-.028-.028-.056v-.111l-.168-.196-.028-.056-.14-.336-4.617-10.802c-.056.056-.084.112-.112.168Z"
      fill="#004EB6"
    />
    <path
      d="m80.005 116.734 2.463 13.852.084.447.028.112v.028l.028.084V131.621l.028.168.14.728 2.994 16.902.168-.028-3.106-17.378-.056-.28-.028-.112V131.173l-.084-.391-.028-.14-2.49-14.048a.337.337 0 0 0-.14.14Z"
      fill="#004EB6"
    />
    <path
      d="m86.134 131.425-2.63-1.511c-.028-.028-.056-.056-.112-.056-.168-.084-.364-.056-.588.084-.56.308-.98 1.175-.98 1.903 0 .419.14.699.365.811l2.658 1.539.028-.196c.056-.028.14-.056.196-.084.56-.307.98-1.175.98-1.902 0-.196-.029-.336-.085-.476l.168-.112Z"
      fill="#2F455F"
    />
    <path
      d="M86.442 132.18c0 .728-.448 1.567-.98 1.903-.56.308-.979-.028-.979-.755 0-.728.448-1.568.98-1.903.531-.308.979.028.979.755Z"
      fill="#004EB6"
    />
    <path
      d="M82.132 134.168c0 1.679.784 2.658 1.875 2.602.336 0 .812 0 1.203-.224.784-.447 1.568-1.315 2.1-2.266.587-1.064.979-2.379.979-3.526 0-2.183-1.54-3.218-3.19-2.267-1.624.951-2.967 3.498-2.967 5.681Zm4.981-3.33c-.084-.616-.363-1.036-.783-1.176l-.28-.084c-.168-.056-.224-.335-.112-.615.084-.196.196-.336.308-.42.056-.028.14-.056.196-.028l.28.084c.336.112.615.336.84.672.14.279.251.587.307.979l.056.532c.028.252-.112.587-.308.727l-.056.056c-.168.112-.336.028-.364-.224l-.084-.503Zm-3.525 5.149c.055-.224.195-.42.335-.504.056-.028.084-.028.14-.056l.392-.028c.476-.028 1.007-.392 1.455-.951l.28-.364c.168-.224.364-.252.476-.112.028 0 .028.028.028.028.028.056.028.14.028.196a.908.908 0 0 1-.196.559l-.28.364c-.364.448-.756.812-1.175 1.064-.308.167-.616.279-.896.307l-.392.028c-.056 0-.112 0-.14-.056-.055-.084-.112-.251-.055-.475Zm-1.036-1.455-.056-.252.028-.336a5.36 5.36 0 0 1 .308-1.595c.14-.448.336-.784.588-1.176l.391-.699c.168-.252.168-.504.308-.364.056.056.084.14.084.252a.93.93 0 0 1-.14.448l-.336.559c-.391.644-.643 1.428-.643 2.099v.448c0 .28-.168.588-.392.699-.224.112-.14.196-.14-.083Z"
      fill="#0064DC"
    />
    <path
      d="M85.434 128.656c-1.65.951-2.966 3.498-2.966 5.68 0 2.183 1.343 3.19 2.966 2.239 1.651-.951 2.967-3.498 2.967-5.681 0-2.182-1.316-3.19-2.967-2.238Zm-2.043 5.428c0 .28-.167.588-.391.7-.196.112-.364-.028-.364-.28V134.029c.028-.56.14-1.148.336-1.735.14-.448.364-.868.587-1.26l.336-.559c.168-.252.392-.364.532-.224.056.056.084.14.084.252 0 .139-.056.307-.14.475l-.336.56c-.392.644-.644 1.427-.644 2.099v.447Zm3.47.84-.28.364a4.137 4.137 0 0 1-1.175 1.063c-.308.168-.615.28-.895.308l-.392.028c-.196 0-.28-.224-.224-.504.056-.224.196-.42.336-.503.056-.028.084-.028.14-.056l.392-.028c.475-.028 1.007-.364 1.455-.952l.28-.364c.168-.224.391-.279.503-.084a.52.52 0 0 1 .056.196c.028.168-.056.364-.195.532Zm1.064-3.218c-.028.028-.028.028-.056.028-.168.112-.336.028-.364-.196l-.056-.532c-.084-.615-.364-1.035-.783-1.175l-.28-.084c-.168-.056-.224-.336-.112-.616a1.02 1.02 0 0 1 .308-.419c.056-.028.14-.056.196-.028l.28.084c.335.083.615.335.839.671.14.252.252.588.308.98l.056.531c0 .252-.14.588-.336.756Z"
      fill="#004EB6"
    />
    <path
      d="m39.121 157.675-.615-.392.783-.42.588.392-.756.42Z"
      fill="#0064DC"
    />
    <path
      d="M39.877 172.925v-15.671l-.756.42v15.671l.756-.42Z"
      fill="#004EB6"
    />
    <path
      d="m39.121 173.346-.615-.392v-15.671l.615.392v15.671Z"
      fill="#71ADEE"
    />
    <path
      d="M39.121 156.95v.727l-.615-.391.028-.7.587.364ZM50.146 149.896l-11.613 6.689.588.363 11.64-6.688-.615-.364Z"
      fill="#71ADEE"
    />
    <path
      d="m50.763 150.26-.028.727-11.614 6.688v-.727l11.642-6.688Z"
      fill="#004EB6"
    />
    <path
      d="m50.567 120.064-1.428-.868 1.82-.979 1.398.867-1.79.98Z"
      fill="#0064DC"
    />
    <path
      d="M52.358 143.598v-24.514l-1.791.979v24.514l1.79-.979Z"
      fill="#004EB6"
    />
    <path
      d="m50.567 144.577-1.428-.868v-24.514l1.428.868v24.514Z"
      fill="#71ADEE"
    />
    <path
      d="m49.14 119.898-4.114-2.658 40.1-23.283 4.142 2.687-40.128 23.254Z"
      fill="#E2F6FF"
    />
    <path
      d="M89.269 100.113v-3.47l-40.13 23.254v3.47l40.13-23.254Z"
      fill="#71ADEE"
    />
    <path
      d="m49.14 123.367-4.114-2.659v-3.47l4.114 2.659v3.47Z"
      fill="#ABE0F6"
    />
    <path
      d="M135.582 163.495c-1.959-1.148-4.338-1.735-6.464-2.071-6.801-1.064-13.741-.588-20.513.587-6.632 1.148-13.348 2.771-19.728 4.87-1.008.335-2.043.671-3.022 1.035-.784.28-1.176.728-1.847 1.175-1.567 1.036-3.554 1.4-5.29 2.043-1.622.588-3.273 1.203-4.7 2.239-.588.42-1.372 1.091-1.68 1.763-.195.42-.223.895-.223 1.371 0 1.287.28 2.351 1.287 3.19.951.812 2.322 1.231 3.498 1.511 7.22 1.651 14.72.14 22.135-.391 1.539-.112 2.518.028 2.77 1.651.476 3.022 4.114 3.777 6.633 4.113 5.54.756 11.193-.224 16.482-2.043 3.134-1.063 6.184-2.434 8.983-4.225 2.994-1.903 5.177-4.282 5.792-7.892.336-1.958.112-4.057-.867-5.792-.784-1.343-1.931-2.351-3.246-3.134Z"
      fill="#2F455F"
    />
    <path
      d="M85.071 53.183c-.224 1.343-.756 2.63-1.371 3.834-.252.503-.504.951-.784 1.427-1.231 2.127-2.742 4.141-4.281 6.044-1.036 1.26-2.127 2.519-3.442 3.498-.896.672-1.96 1.231-3.078 1.287-.392.028-.784-.027-1.092-.223-1.007-.672-.951-2.435-.923-3.47.028-1.511.308-3.023.7-4.478.083-.308.167-.643.28-.951.559-1.791 1.314-3.526 2.098-5.233.056-.14.112-.224.168-.364 1.007-2.155 2.043-4.337 3.358-6.352.504-.756 1.12-1.567 2.015-1.791.14-.028.28-.056.391-.028.196.056.336.168.504.252.532.307 1.063.167 1.651.223 1.371.14 2.575 1.064 3.218 2.295.672 1.26.812 2.687.588 4.03Z"
      fill="#FFCFB3"
    />
    <path
      d="M85.07 53.183c-.223 1.343-.755 2.63-1.37 3.834-.252.503-.504.951-.784 1.427-.028 0-.056.028-.056.028-1.231.28-2.518-.14-3.694-.672-1.147-.532-2.21-1.231-3.414-1.679-.531-.196-1.119-.364-1.595-.7-.336-.223-.587-.531-.811-.867 1.007-2.155 2.042-4.337 3.358-6.352.504-.756 1.12-1.567 2.015-1.791.14-.028.28-.056.391-.028.196.056.336.168.504.252.532.307 1.063.167 1.651.223 1.371.14 2.575 1.064 3.218 2.295.672 1.26.812 2.687.588 4.03Z"
      fill="#71ADEE"
    />
    <path
      d="M93.186 30.654c-.224.028-.616.588-.756.784a3.555 3.555 0 0 0-.503 1.035c-.112.336-.084.616-.084.952-.028.42-.196.895-.336 1.287-.224.616-.28 1.287-.448 1.903-.504 1.735-1.287 3.386-1.93 5.093-.952 2.546-1.54 5.205-2.128 7.863-.195.868-.391 1.707-.56 2.575-.027.168-.083.336-.083.532-.056.196-.056.42-.056.615 0 .196-.028.364-.056.56 0 .028-.028.056-.028.056-.028.168-.056.336-.14.532-.028.084-.056.167-.112.195-.028.028-.056.028-.084.056-.056.028-.084.028-.14.028-.671.084-1.371-.028-2.043-.167-.195-.056-.363-.084-.531-.168-1.427-.42-2.77-1.148-3.946-2.071a14.302 14.302 0 0 1-2.686-2.827c-.392-.56-.308-.811-.056-1.37.224-.505.447-.952.671-1.428l.168-.336c.56-1.147 1.147-2.294 1.735-3.442a122.223 122.223 0 0 1 3.834-6.716c.504-.811 1.175-1.65 1.93-2.267.56-.475 1.036-.895 1.708-1.23.448-.225.923-.42 1.371-.645.224-.083.504-.195.728-.28a23.12 23.12 0 0 1 4.561-1.119Z"
      fill="#ABE0F6"
    />
    <path
      d="M83.7 51.475c-1.4-.391-2.63-1.091-3.638-1.875.308-1.119.56-2.266.755-3.414.644-3.246 1.008-6.688 2.939-9.346 0 4.869.028 9.766-.056 14.635Z"
      fill="#71ADEE"
    />
    <path
      d="M86.246 53.855c0 .028-.028.056-.028.056a5.986 5.986 0 0 1-.336.783c-.056.028-.084.028-.14.028-.671.084-1.37-.028-2.042-.168-2.91-.671-5.43-2.63-7.192-5.037-.392-.56-.308-.811-.056-1.371.224-.504.448-.952.671-1.427 1.204 1.567 3.918 4.505 7.78 5.037.867.112 1.287.448 1.427.951.112.308.084.7-.084 1.148Z"
      fill="#FFDC1E"
    />
    <path
      d="M83.7 51.476v.224c-.028.923-.084 1.903-.532 2.686a7.097 7.097 0 0 1-1.007-.336c-.056 0-.084-.028-.112-.028-.308-.14-.644-.28-.952-.447h-.028l-.84-.504a.435.435 0 0 1-.223-.168c-.224-.14-.448-.308-.644-.448-.056-.028-.084-.056-.14-.112.028-.028.028-.056.028-.084.308-.867.56-1.735.812-2.63 1.007.756 2.239 1.455 3.638 1.847Z"
      fill="#E39F3A"
    />
    <path
      d="M104.352 185.715c1.371.476 2.882.308 4.309-.056 3.078-.755 6.101-2.406 7.612-5.177 1.035-1.93 1.287-4.253 2.63-5.96.364-.448.784-.868 1.008-1.371.224-.504.279-1.092.279-1.651 0-.952-.111-1.987-.727-2.715-1.931 3.554-5.317 6.045-8.591 8.395-2.155 1.539-4.366 3.134-6.856 4.03-.896.336-2.295.867-2.071 2.043.224 1.147 1.315 2.071 2.407 2.462Z"
      fill="#004EB6"
    />
    <path
      d="M119.491 172.141c-.559 1.232-1.791 2.043-2.462 3.219-.56.951-.7 2.098-1.064 3.162-.699 2.071-2.238 3.833-4.141 4.925-1.847 1.063-3.974 1.539-6.129 1.511h-.196c-.783-.028-1.595-.14-2.294-.504-.7-.364-1.288-1.007-1.428-1.791-.167-1.035.504-1.959 1.064-2.742.671-.924 1.399-1.791 2.043-2.743a42.925 42.925 0 0 0 3.414-5.932c.531-1.091 1.007-2.211 1.371-3.358.084-.196.14-.392.196-.56.559-1.819 1.427-4.309 3.834-4.197.895.056 1.735.503 2.462 1.007a9.804 9.804 0 0 1 3.078 3.834c.616 1.287.868 2.854.252 4.169Z"
      fill="#FAFAFA"
    />
    <path
      d="M119.491 172.141c-.56 1.232-1.791 2.043-2.462 3.219-.56.951-.7 2.098-1.064 3.162-.699 2.071-2.239 3.833-4.141 4.925-1.847 1.063-3.974 1.539-6.129 1.511a12.23 12.23 0 0 0 3.162-1.819c2.099-1.595 3.694-3.834 4.422-6.352.335-1.176.503-2.407.895-3.582.168-.56.42-1.12.504-1.679.084-.56 0-1.232-.42-1.651-.588-.616-1.539-.588-2.379-.728-.811-.14-1.735-.56-2.21-1.259.084-.196.14-.392.196-.56.559-1.819 1.427-4.309 3.833-4.197.896.056 1.735.503 2.463 1.007a9.804 9.804 0 0 1 3.078 3.834c.616 1.287.868 2.854.252 4.169Z"
      fill="#AAD2F6"
    />
    <path
      d="M73.821 179.529c.532.308 1.148.42 1.735.448 1.96.112 3.834-.727 5.541-1.679 1.707-.951 3.358-2.071 5.233-2.602 1.54-.42 3.134-.476 4.73-.672 1.203-.14 2.434-.42 3.413-1.147 1.651-1.232 2.183-3.778 1.12-5.541-.812.895-1.82 1.595-2.883 2.183-2.63 1.483-5.568 2.406-8.59 2.742-2.183.252-4.366.168-6.549.056-1.007-.056-2.043-.112-2.994.196-.98.308-2.35 1.371-2.49 2.463-.14 1.343.587 2.91 1.734 3.553Z"
      fill="#004EB6"
    />
    <path
      d="M95.76 171.582c-.335.7-.923 1.259-1.595 1.679-2.35 1.483-4.7.56-7.247.896-1.623.195-3.079 1.035-4.45 1.902-1.37.896-2.742 1.847-4.281 2.407-1.54.56-3.33.672-4.757-.14-.084-.056-.168-.084-.224-.168-1.595-1.119-1.26-3.526-.42-5.093.671-1.231 1.79-2.378 3.05-2.994 1.4-.7 2.826-1.035 4.366-1.399a16.835 16.835 0 0 0 4.281-1.595c.084-.056.168-.084.224-.14.336-.196.7-.448 1.035-.7 1.148-.867 2.211-1.847 3.582-2.266.896-.28 1.707-.14 2.575.111.028 0 .056.028.084.028.475.14.895.224 1.399.084.168-.056 1.12-.475 1.12-.727.391.811.42 1.735.67 2.602.505 1.847 1.568 3.554.589 5.513Z"
      fill="#FAFAFA"
    />
    <path
      d="M95.76 171.583c-.335.699-.923 1.259-1.594 1.679-2.351 1.483-4.702.559-7.248.895-1.623.196-3.078 1.036-4.45 1.903-1.37.896-2.742 1.847-4.281 2.407-1.54.559-3.33.671-4.757-.14-.084-.056-.168-.084-.224-.168 2.994-.084 5.932-.952 8.255-2.826.756-.588 1.427-1.288 2.239-1.791 1.875-1.232 4.31-1.428 6.1-2.799.504-.392.952-.923.84-1.539-.084-.504-.504-.867-.952-1.035-.448-.196-.951-.224-1.427-.308-1.147-.224-2.015-.812-2.518-1.651 1.147-.868 2.21-1.847 3.582-2.267.895-.28 1.706-.14 2.574.112.028 0 .056.028.084.028.476.14.895.224 1.4.084.167-.056 1.118-.476 1.118-.728.392.812.42 1.735.672 2.603.504 1.875 1.567 3.582.588 5.541Z"
      fill="#AAD2F6"
    />
    <path
      d="M100.574 120.875c-.308 2.63-.7 5.261-1.147 7.835-.224 1.456-.504 2.827-.532 4.282-.056 1.483.112 2.938.28 4.393.56 5.038-.084 9.655-1.511 14.496l-.756 2.519c-.756 2.49-1.483 5.009-1.791 7.583-.056.644-.14 1.288-.14 1.931-.028.56.168.896-.252 1.343-.671.672-1.595 1.036-2.518 1.26-.14.028-.308.056-.448.084-1.455.279-3.022.279-4.338-.392-.28-.14-.531-.308-.727-.56-.252-.308-.196-.56-.14-.951.112-.7.028-1.511.028-2.239l-.168-4.421a477.955 477.955 0 0 0-.308-6.325c-.14-2.574-.252-5.149-.392-7.751l-.42-8.675a999.734 999.734 0 0 1-.447-9.123c-.14-3.05-.28-6.045-.42-9.067-.14-2.854-.252-5.709-.336-8.563a645.404 645.404 0 0 1-.28-7.36v-.14c-.055-2.014-.083-4.03-.111-6.044-.028-1.343-.028-2.658.056-4.002 0 1.344 2.042 2.715 3.05 3.218 1.483.756 2.994 1.651 3.694 3.247.671 1.567 1.287 2.882 3.19 3.246 1.539.307 3.246-.364 4.17-1.651.111-.168.223-.336.391-.392.392-.084.644.392.7.783.587 2.995 1.147 6.073 1.483 9.123.196 1.735.28 3.47.28 5.233-.112 2.379.112 4.813-.14 7.08Z"
      fill="#ABE0F6"
    />
    <path
      d="M100.574 120.875c-.308 2.631-.7 5.261-1.147 7.836-.224 1.455-.504 2.826-.532 4.281-.056 1.483.112 2.938.28 4.393.56 5.038-.084 9.655-1.511 14.496l-.756 2.519c-.755 2.49-1.483 5.009-1.79 7.583-.057.644-.14 1.288-.14 1.931-.029.56.167.896-.253 1.343-.671.672-1.595 1.036-2.518 1.26.308-15.14 2.63-30.223 2.49-45.39-.028-3.274-.168-6.604-1.399-9.627-1.399-3.47-4.058-6.212-6.912-8.535-.811-.643-1.735-1.175-2.602-1.763v-.168c-.056-2.014-.084-4.03-.112-6.044-.028-1.343-.028-2.658.056-4.002 0 1.344 2.043 2.715 3.05 3.218 1.483.756 2.994 1.651 3.694 3.247.671 1.567 1.287 2.882 3.19 3.246 1.54.307 3.246-.364 4.17-1.651.112-.168.223-.336.391-.392.392-.084.644.392.7.783.588 2.995 1.147 6.073 1.483 9.123.196 1.735.28 3.47.28 5.233-.084 2.379.14 4.813-.112 7.08Z"
      fill="#6095BF"
    />
    <path
      d="M95.593 165.285c-.14.336-.448.587-.756.783-.755.476-1.595.756-2.462.924-.7.14-1.4.168-2.1.168-1.342 0-2.77-.168-3.833-.98a1.323 1.323 0 0 1-.364-.419c-.056-.14-.056-.308-.084-.448-.028-.392-.056-.784-.028-1.204.028-.335.084-.671.28-.951.028-.056.084-.14.168-.168.028 0 .028 0 .056.028.028 0 .028.028.028.028.028.084.056.168.084.224.056.196.252.308.42.42.895.503 1.903.699 2.938.699a9.5 9.5 0 0 0 2.35-.308c.393-.083.756-.195 1.12-.307a7.085 7.085 0 0 0 1.008-.336c.14-.056.28-.14.42-.224.055-.028.14-.084.167-.14.084-.112.056-.252.084-.392 0-.056.028-.084.056-.112.28-.14.392.7.42.868.056.28.112.56.14.867 0 .336 0 .672-.112.98Z"
      fill="#FFDC1E"
    />
    <path
      d="M95.593 165.286c-.14.336-.448.588-.756.784-.755.475-1.595.755-2.462.923.14-.392.224-.783.224-1.203 0-.392-.056-.756-.14-1.12a3.36 3.36 0 0 0-.224-.559c.392-.084.755-.196 1.12-.308a7.09 7.09 0 0 0 1.007-.336c.14-.056.28-.14.42-.224.055-.028.14-.084.167-.14.084-.112.056-.251.084-.391 0-.056.028-.084.056-.112.28-.14.392.699.42.867.056.28.112.56.14.868.056.307.056.643-.056.951Z"
      fill="#E39F3A"
    />
    <path
      d="M120.358 151.379c0 1.063-.391 2.21-.531 3.302-.168 1.175-.308 2.35-.476 3.554-.336 2.406-.644 4.813-.979 7.219-.028.168-.056.336-.14.504-.056.168-.196.28-.336.392a4.563 4.563 0 0 1-1.791.923c-.756.224-1.539.308-2.323.336-.895.028-1.819-.028-2.686-.28-.392-.112-.784-.28-.98-.643-.14-.28-.14-.588-.14-.868.028-1.707.224-3.414.28-5.121.028-2.406-.14-4.813-.419-7.192-.504-3.722-1.372-7.415-2.659-10.969-1.147-3.135-1.931-6.381-3.078-9.543-1.763-4.813-2.826-9.85-4.561-14.663-.168-.42-.336-.868-.504-1.288-.896-2.322-2.127-4.309-3.442-6.38-1.204-1.875-2.575-3.554-3.582-5.541-.14-.252-.252-.531-.392-.783-1.007-2.295-1.567-4.813-1.483-7.304.056-.952.196-1.959.644-2.799-.28.504 1.23 2.043 1.539 2.351.7.588 1.399 1.12 2.266 1.4 2.855 1.007 5.989.923 8.927.28 2.939-.672 5.737-1.904 8.423-3.275.672-.336 1.372-.671 2.127-.784.588-.083 1.259.169 1.791-.14.308-.167.56-.447.784-.699l1.063-1.147c.42 1.819.504 3.722.504 5.596.056 5.653-.28 11.334-.588 16.987-.252 4.617-.811 9.318-.7 13.964.056 2.826-.391 5.289.896 7.975 1.399 3.022 2.854 6.128 3.05 9.431.056 1.679-.196 3.47-.504 5.205Z"
      fill="#ABE0F6"
    />
    <path
      d="M120.359 151.378c0 1.063-.392 2.211-.532 3.302-.168 1.175-.308 2.351-.476 3.554-.336 2.407-.643 4.813-.979 7.22-.028.168-.056.336-.14.503-.056.168-.196.28-.336.392a4.566 4.566 0 0 1-1.791.924c-.196-6.521.056-13.125-.532-19.645-.559-6.548-2.518-12.873-3.498-19.337a65.82 65.82 0 0 1-.671-9.374c0-3.051-.14-6.241-1.651-8.983-.308-.56-.672-1.064-1.176-1.455-.895-.644-2.07-.672-3.19-.7-2.154-.056-4.281-.14-6.408-.476-2.21-.335-4.87-.979-6.996-2.154-.14-.252-.252-.532-.392-.784-1.007-2.295-1.567-4.813-1.483-7.304.056-.951.196-1.959.644-2.798-.28.504 1.231 2.043 1.539 2.35.7.588 1.399 1.12 2.267 1.4 2.854 1.007 5.988.923 8.926.28 2.939-.672 5.737-1.903 8.424-3.275.671-.335 1.371-.671 2.126-.783.588-.084 1.26.168 1.791-.14.308-.168.56-.448.784-.7.364-.391.699-.755 1.063-1.147.42 1.819.504 3.722.504 5.597.056 5.653-.28 11.333-.588 16.986-.252 4.617-.811 9.319-.699 13.964.056 2.826-.392 5.289.895 7.975 1.399 3.023 2.854 6.129 3.05 9.431.084 1.651-.168 3.442-.475 5.177Z"
      fill="#6095BF"
    />
    <path
      d="M118.875 166.6c-.028.028-.028.056-.056.084-.364.42-.951.644-1.483.812-.42.14-.867.224-1.287.308-.14.028-.308.056-.448.084-.588.083-1.203.139-1.791.139-.252 0-.532-.028-.783-.028-.7-.056-1.428-.167-2.099-.419-.336-.14-.672-.28-.952-.476-.168-.084-.251-.196-.335-.308a.67.67 0 0 1-.084-.476c.028-.335.056-.699.112-1.063.028-.196.056-.392.056-.588 0-.196.028-.335.083-.531.028-.056.056-.084.084-.112.028-.028.084-.028.112-.028.056.028.056.084.084.168.112.335.7.391 1.008.447 1.651.364 3.358.42 5.009.168.308-.056.643-.112.951-.168.28-.056.56-.112.812-.196.168-.055.308-.083.448-.167.223-.112.195-.224.251-.448 0-.056.028-.084.056-.112.28-.168.224.476.252.56.028.363.084.755.084 1.119.028.392.112.951-.084 1.231Z"
      fill="#FFDC1E"
    />
    <path
      d="M118.875 166.601c-.028.028-.028.056-.056.084-.363.42-.951.644-1.483.811-.419.14-.867.224-1.287.308l.168-.587a4.559 4.559 0 0 0-.14-2.379c.308-.056.644-.112.952-.168.279-.056.559-.112.811-.196.168-.056.308-.084.448-.168.224-.112.196-.224.252-.447 0-.056.028-.084.056-.112.279-.168.223.475.251.559.028.364.084.756.084 1.12.056.335.14.895-.056 1.175Z"
      fill="#E39F3A"
    />
    <path
      d="M122.625 41.681c-.168.42-.531.7-.867 1.008-.588.56-.896 1.23-1.287 1.903-.56.979-.7 2.238-.952 3.33a83.097 83.097 0 0 0-.756 3.805 59.188 59.188 0 0 0-.419 2.967 89.075 89.075 0 0 0-.476 4.7c-.084 1.036-.084 2.1-.056 3.163.028 7.5.7 23.115.951 29.075.056 1.26.084 2.071.084 2.323.028 1.427-.028 2.015-1.119 3.05-1.791 1.68-4.058 2.715-6.352 3.526-1.008.364-2.043.7-3.078.98-4.45 1.259-9.095 1.791-13.684 1.371-2.995-.28-5.99-1.008-8.508-2.631-.727-.447-1.399-1.007-1.902-1.707-1.12-1.567-1.092-3.274-1.036-5.009.028-.56.028-1.12.028-1.679-.056-1.987-.224-11.613-.448-19.812-.028-1.4-.084-2.77-.112-4.03-.056-2.323-.14-4.337-.195-5.82-.14-2.939-.364-5.85-.28-8.76.112-3.19.168-6.352.14-9.542-.028-1.427-.028-2.883.084-4.31.195-2.518 1.539-4.84 3.553-6.38.924-.7 2.043-1.147 3.163-1.511.028 0 .056-.028.084-.028a62.075 62.075 0 0 1 4.953-1.4c1.12-.28 2.238-.53 3.386-.699 1.427-.224 2.882-.503 4.337-.56.504-.027.98.084 1.483.084.728-.027 1.428-.056 2.155.056 2.407.392 4.729 1.791 6.716 3.135.14.083.28.195.448.307 2.323 1.596 8.059 5.849 9.235 6.968.643.616 1.063 1.26.727 2.127Z"
      fill="#3989E5"
    />
    <path
      d="M118.792 91.885v.027c-.14.532-.42.952-1.036 1.54-1.791 1.679-4.058 2.714-6.352 3.526a43.873 43.873 0 0 1-3.246 1.035c-.392-4.59-1.483-9.123-3.274-13.376-.252-.588-.532-1.204-1.008-1.651-.56-.56-1.315-.84-2.071-1.064-3.694-1.231-7.583-2.35-10.466-4.953-1.595-1.427-2.854-3.33-4.925-4.113-.643-.224-2.267-.42-3.694-.812-.028-1.4-.084-2.77-.112-4.03 3.079-1.483 7.332.028 10.215 1.036 3.274 1.147 6.94 2.294 10.13.923 1.623-.7 2.938-1.987 4.141-3.302 2.323-2.602 4.31-5.513 6.045-8.563.643-1.12 1.287-2.267 2.322-2.994.84-.588 2.015-.812 2.883-.42a89.071 89.071 0 0 0-.476 4.701c-.084 1.036-.084 2.1-.056 3.162.028 7.5.7 23.115.952 29.076.028.084.028.168.028.252Z"
      fill="#004EB6"
    />
    <path
      d="M118.791 91.883c-.112.532-.391.98-1.035 1.595-1.791 1.679-4.058 2.714-6.352 3.526-5.373 1.93-11.11 2.882-16.763 2.35-2.994-.28-5.988-1.007-8.507-2.63-.727-.448-1.399-1.007-1.903-1.707-.7-.98-.951-2.043-1.035-3.106.028.56 0 1.091-.028 1.65-.056 1.736-.056 3.443 1.035 5.01.504.7 1.176 1.26 1.903 1.707 2.519 1.623 5.513 2.351 8.507 2.63 5.653.532 11.418-.419 16.763-2.35 2.294-.812 4.561-1.847 6.352-3.526 1.091-1.036 1.147-1.623 1.119-3.05.028-.252 0-.98-.056-2.1Z"
      fill="#FFDC1E"
    />
    <path
      d="M117.784 97.032c-1.791 1.68-4.058 2.715-6.352 3.526-1.008.364-2.043.7-3.079.98 0-1.175-.056-2.35-.167-3.498a43.873 43.873 0 0 0 3.246-1.036c2.294-.811 4.561-1.846 6.352-3.525.616-.588.895-1.036 1.035-1.54.056 1.092.084 1.82.084 2.043 0 1.427-.028 1.987-1.119 3.05Z"
      fill="#E39F3A"
    />
    <path
      d="M112.691 32.587c-.084.756-.448 1.483-1.148 2.183-1.259 1.343-2.826 2.35-4.169 3.19-2.966 1.791-5.373 2.855-7.808 3.414a7.293 7.293 0 0 1-1.903.252c-.251 0-.503-.028-.783-.056-1.231-.168-2.267-.812-2.854-1.735-.672-1.063-.756-2.462-.252-3.946.643-1.847 1.763-3.554 3.274-4.813a10.08 10.08 0 0 1 2.49-1.567c1.008-.42 1.847-.588 2.939-.504.28.028.559.084.839.084.728-.028 1.427-.056 2.155.056 2.407.392 4.729 1.791 6.716 3.134.168.056.336.168.504.308Z"
      fill="#FFDC1E"
    />
    <path
      d="M110.424 33.677c-1.119 1.175-2.462 2.07-3.834 2.91-1.903 1.147-3.861 2.183-5.988 2.854-.448.14-.896.28-1.371.392-.672.168-1.372.28-2.071.168-.7-.084-1.4-.448-1.763-1.035-.476-.756-.392-1.735-.084-2.575.28-.84.755-1.623 1.007-2.462.196-.672.224-1.372.14-2.071-.14-.84-.755-1.511-.811-2.35-.084-1.036.615-2.128 1.427-2.715 1.007-.728 2.239-1.036 3.386-1.483 3.358-1.26 6.156-3.61 8.843-6.017.112.756.168 1.511.196 2.267.056 1.287 0 2.602.028 3.89.028 2.042.279 4.169 1.287 5.96.14.196.252.42.308.672.112.587-.28 1.175-.7 1.595Z"
      fill="#ABE0F6"
    />
    <path
      d="M110.424 33.678c-1.119 1.175-2.462 2.07-3.834 2.91-1.903 1.147-3.861 2.183-5.988 2.854.448-.308.867-.643 1.287-.951 1.007-.784 1.959-1.623 2.603-2.687.811-1.315 1.119-2.882 1.119-4.421.028-2.043-.868-4.114-.532-6.129.112-.7.392-1.399.812-1.959.559-.727 1.791-1.315 2.658-1.567.28-.084.588-.14.924-.168.056 1.288 0 2.603.028 3.89.028 2.043.279 4.17 1.287 5.96.14.197.252.42.308.673.14.587-.252 1.175-.672 1.595Z"
      fill="#71ADEE"
    />
    <path
      d="M112.775 18.986c-.112 1.763-.952 3.442-2.239 4.645-.56.532-1.231.98-1.987 1.204-.252.056-.531.112-.727.252-.196.111-.336.307-.476.475-.42.56-.784 1.176-1.343 1.623-.504.392-1.12.644-1.679.896-2.911 1.175-5.96 2.015-9.067 2.407-.812.083-1.707.167-2.463-.224-.028-.028-.056-.028-.056-.056a2.153 2.153 0 0 1-1.007-1.316c-.14-.531-.056-1.091 0-1.623.14-1.063.252-2.126.336-3.218.056-.671.056-1.427-.42-1.903-.252-.252-.644-.392-.812-.7-.195-.28-.167-.67-.055-.979.111-.308.335-.587.56-.867.307-.392.615-.756.923-1.148.224-.307.643-.643.727-1.007.224-.784-.224-1.623-.391-2.35-.308-1.344 0-2.743.503-4.03.896-2.267 1.847-5.037 4.338-5.96 1.12-.42 2.35-.449 3.554-.309 1.567.196 3.162.756 4.169 1.931.056.056.112.084.14.14 1.203 1.567 1.147 3.778 2.099 5.513.504.923 1.231 1.679 2.015 2.379.587.531 1.175 1.035 1.651 1.65.392.532.923.784 1.371 1.232.308.364.364.867.336 1.343Z"
      fill="#ABE0F6"
    />
    <path
      d="M112.775 18.987c-.112 1.763-.952 3.442-2.239 4.646-.559.531-1.231.979-1.987 1.203-.251.056-.531.112-.727.252-.196.112-.336.307-.476.475-.42.56-.783 1.176-1.343 1.623-.504.392-1.119.644-1.679.896-2.91 1.175-5.96 2.015-9.067 2.407-.812.084-1.707.168-2.463-.224-.028-.028-.055-.028-.055-.056 2.042-1.204 3.973-2.547 5.68-4.198 1.064-1.007 2.043-2.155 2.603-3.498.475-1.147.643-2.406.643-3.666.028-2.238-.447-4.45-.391-6.688.056-1.707.363-4.701 2.294-5.317.504-.168 1.064-.168 1.595-.084.056.056.112.084.14.14 1.204 1.567 1.148 3.778 2.099 5.513.504.924 1.231 1.679 2.015 2.379.588.531 1.175 1.035 1.651 1.65.392.532.923.784 1.371 1.232.308.336.364.84.336 1.315Z"
      fill="#71ADEE"
    />
    <path
      d="M117.196 14.957c-.224 1.455-.587 2.994-1.203 4.337-.196.448-.448.924-.7 1.343-.951 1.595-2.35 3.358-4.197 3.89-.392.112-.784.14-1.175 0-.448-.168.251-.56.419-.7 1.371-1.175 2.743-4.001 1.12-5.484-.616-.56-1.567-.588-2.379-.42a5.36 5.36 0 0 0-2.435 1.26c-.727.67-1.063 1.538-1.623 2.35-.14.168-.252.336-.419.447-.168.084-.42.112-.56-.056-.14-.14-.14-.335-.14-.531.028-.672.028-1.315.028-1.987-.028-.672-.056-1.343-.308-1.987-.28-.671-.839-1.26-1.007-2.015-.336-1.455.951-2.854.811-4.365-.056-.644-.392-1.231-.867-1.68a3.088 3.088 0 0 0-.7-.53c-1.259-.756-2.77-.589-4.253-.645-.924-.027-1.847-.083-2.715-.335-.755-.224-1.343-.532-1.847-1.204a3.67 3.67 0 0 1-.783-2.126c-.028-1.036.42-2.1 1.315-2.603-.308.672-.168 1.54.336 2.07 0-.419.196-.81.504-1.062.055.391.223.783.56.979.923.588 2.378-.7 3.133-1.147 2.267-1.4 4.953-2.043 7.64-1.903 1.763.084 3.554.503 5.149 1.259 1.119.532 2.099 1.26 2.966 2.099 1.483 1.483 2.519 3.386 3.078 5.4a13.19 13.19 0 0 1 .252 5.346Z"
      fill="#71ADEE"
    />
    <path
      d="M117.196 14.957c-.224 1.455-.587 2.994-1.203 4.337-.196.448-.448.924-.7 1.343-.951 1.596-2.35 3.358-4.197 3.89-.392.112-.784.14-1.176 0-.447-.168.252-.56.42-.7 1.371-1.175 2.743-4.001 1.12-5.484-.616-.56-1.568-.588-2.379-.42a5.354 5.354 0 0 0-2.435 1.26c-.727.67-1.063 1.538-1.623 2.35-.14.168-.252.336-.42.448-.167.084-.419.112-.559-.056-.14-.14-.14-.336-.14-.532.028-.672.028-1.315.028-1.987-.028-.671-.056-1.343-.308-1.987-.28-.671-.839-1.259-1.007-2.014-.336-1.456.951-2.855.811-4.366-.056-.644-.392-1.231-.867-1.679a3.088 3.088 0 0 0-.7-.532.253.253 0 0 1 .14-.028c.7.028 1.399.252 2.099.28.867.028 1.763-.056 2.602-.224a10.703 10.703 0 0 0 2.743-.98c1.707-.895 3.19-2.182 4.449-3.665 1.483 1.483 2.519 3.386 3.078 5.4.42 1.708.504 3.555.224 5.346Z"
      fill="#004EB6"
    />
    <path
      d="M116.945 66.921c-1.484 2.35-2.967 4.673-4.45 7.024-.811 1.26-1.623 2.49-2.546 3.666-.532.672-1.064 1.511-1.819 1.93-.868.505-1.959.757-2.939.477-1.399-.392-2.602-1.008-3.917-1.623-1.651-.784-3.47-1.092-5.205-1.651-4.086-1.26-7.668-3.834-10.522-6.94l-.42-.42-.14-.14c-1.035-1.147-1.987-2.323-3.05-3.414-.504-.504-1.008-1.035-1.567-1.455-.644-.476-1.371-.84-2.099-1.204-1.315-.643-2.603-1.287-3.918-1.93-1.063-.532-1.763-1.372-2.602-2.211-.224-.224-.448-.448-.588-.756a1.08 1.08 0 0 1-.084-.923l.028-.028c.14-.308.504-.532.84-.504a1.052 1.052 0 0 1-.504-.42.744.744 0 0 1 .028-.643.965.965 0 0 1 .224-.252c.28-.196.587-.308.923-.28.056 0 .14 0 .168-.028.14-.14-.168-.252-.224-.28a1.2 1.2 0 0 1-.447-.392c-.168-.28-.168-.643.056-.895.195-.196.503-.224.755-.224.392-.028.812.028 1.203.084.392.056.7.196 1.036.336.727.308 1.399.755 2.126 1.091.085.056.196.084.28.056-.028-.168-.14-.28-.251-.392-.616-.56-1.428-.867-2.1-1.37a3.2 3.2 0 0 1-.699-.672c-.168-.196-.448-.532-.42-.812.028-.252.308-.42.56-.476 1.26-.364 2.575.224 3.694.868.644.392 1.26.755 1.79 1.287.505.504 1.008 1.008 1.54 1.511.308.28 1.175 1.315 1.595 1.148.14-.056.28-.14.392-.14.168 0 .308.14.42.224.56.643.867 1.483 1.035 2.35.168.84.084 1.735.588 2.435.643.923 2.042 1.399 3.022 1.903.308.168.588.307.895.447.868.448 1.735.868 2.603 1.26 1.063.503 2.099.979 3.162 1.483 1.287.587 2.575 1.175 3.834 1.79.392.197.783.392 1.147.588.448.196.868.42 1.315.644.392-.672.756-1.315 1.148-1.987a32.536 32.536 0 0 0 1.483-2.742c.252-.532.532-1.064.783-1.595a16.202 16.202 0 0 1 2.155-3.218c1.176-1.316 3.75-1.903 5.429-2.015 1.987-.112 4.086.56 5.373 2.127.308.391.588.811.728 1.287.839 2.238-.728 4.225-1.847 6.016Z"
      fill="#ABE0F6"
    />
    <path
      d="M95.48 66.082a3.603 3.603 0 0 1-.53 1.092c-.7.951-1.876 1.455-2.995 1.79a18.917 18.917 0 0 1-6.38.868l-.42-.42a92.263 92.263 0 0 0-.14-.14c-1.035-1.147-1.987-2.322-3.05-3.413-.504-.504-1.008-1.036-1.567-1.456-.644-.475-1.372-.839-2.1-1.203-1.314-.643-2.602-1.287-3.917-1.93-1.063-.532-1.763-1.372-2.602-2.211-.224-.224-.448-.448-.588-.756a1.08 1.08 0 0 1-.084-.923l.028-.028c.112.14.196.28.336.363.42.392.951.56 1.483.784 2.323.951 4.59 1.903 6.912 2.854 1.063.448 2.155.896 3.274 1.231 2.127.644 4.393.84 6.604.728.868.448 1.735.868 2.603 1.26 1.035.531 2.07 1.007 3.134 1.51Z"
      fill="#71ADEE"
    />
    <path
      d="M116.945 66.922c-1.484 2.35-2.967 4.673-4.45 7.024-.811 1.259-1.623 2.49-2.546 3.666-.532.671-1.064 1.51-1.819 1.93-.868.504-1.959.756-2.939.476-1.399-.392-2.602-1.007-3.917-1.623-1.651-.783-3.47-1.091-5.205-1.651-4.282-1.315-7.976-4.058-10.942-7.36l-.14-.14c.644-.056 1.287.028 1.903.112 1.79.252 3.582.644 5.4.812 2.323.224 4.758.112 6.829-.952.448-.223.895-.503 1.315-.783.784-.532 1.595-1.064 2.463-1.343.224-.084.447-.14.671-.196 2.323-.448 4.506 1.035 6.828 1.37.448.057.924.085 1.344-.083 1.035-.42 1.651-1.959.783-2.77a2.483 2.483 0 0 0-.951-.448c-1.204-.336-2.463-.532-3.666-.896-.952-.28-2.015-.615-2.743-1.287a16.205 16.205 0 0 1 2.155-3.218c1.175-1.315 3.75-1.903 5.429-2.015 1.987-.112 4.086.56 5.373 2.127.308.392.588.811.727 1.287.784 2.183-.783 4.17-1.902 5.96Z"
      fill="#71ADEE"
    />
    <path
      d="M123.241 52.203c-.448 3.106-1.176 6.128-2.099 9.122-.224.812-.504 1.624-.756 2.407-.055.196-.139.392-.279.504-.196.196-.504.224-.756.224a25.798 25.798 0 0 1-4.645-.168 29.853 29.853 0 0 1-9.543-2.715c-.14-.056-.28-.14-.336-.28-.084-.14-.056-.307-.028-.475.168-.896.364-1.791.616-2.687.588-2.098 1.399-4.17 2.267-6.184a39.96 39.96 0 0 1 1.791-3.666 18.716 18.716 0 0 1 2.49-3.414c2.015-2.21 4.898-4.561 8.032-5.037a9.099 9.099 0 0 1 1.231-.084c1.791.028 2.463 2.547 2.547 3.974.223 2.882-.168 5.708-.532 8.479Z"
      fill="#ABE0F6"
    />
    <path
      d="M123.241 52.203c-.448 3.106-1.175 6.128-2.099 9.123a23.197 23.197 0 0 1-4.785.14c2.49-4.646 4.086-9.739 4.421-15 .14-2.155.028-4.421-.727-6.492 0-.028-.028-.056-.028-.056a9.11 9.11 0 0 1 1.231-.084c1.791.028 2.463 2.546 2.547 3.974.195 2.798-.196 5.624-.56 8.395Z"
      fill="#71ADEE"
    />
    <path
      d="M121.142 61.326c-.224.812-.504 1.623-.756 2.407-.056.196-.139.392-.279.504-.196.195-.504.223-.756.223-4.869.224-9.794-.755-14.188-2.854-.14-.056-.28-.14-.336-.28-.084-.14-.056-.308-.028-.476.168-.895.364-1.79.616-2.686 2.295 1.315 8.255 4.17 15.727 3.162Z"
      fill="#FFDC1E"
    />
    <path
      d="M121.142 61.326c-.224.812-.504 1.623-.756 2.407-.056.196-.14.392-.28.504-.195.195-.503.223-.755.223a25.816 25.816 0 0 1-4.645-.167 36.323 36.323 0 0 0 1.651-2.855c1.483.14 3.106.112 4.785-.112Z"
      fill="#E39F3A"
    />
    <path
      d="M110.76 72.883c-.14.14-.308.196-.504.224-.308.028-.671 0-.951-.056-.28-.056-.504-.252-.756-.364-.587-.28-1.259-.447-1.875-.7-.335-.139-1.007-.503-1.371-.363-.42.168.084.42.28.504.308.168.588.308.923.42.616.251 1.26.503 1.847.755.532.224 1.484.868 1.008 1.54a.972.972 0 0 1-.532.335c-.616.196-1.287.028-1.903-.168a32.773 32.773 0 0 1-3.61-1.315c-.447-.196-.923-.42-1.399-.588-.168-.056-1.175-.448-1.175-.028 0 .084.084.14.196.168.196.14.419.252.643.336.42.196.868.392 1.316.56.895.335 1.791.671 2.574 1.259.364.252.728.672.56 1.063-.056.112-.14.196-.224.28-.056.056-.084.084-.168.112-.588.308-1.287.112-1.903-.084-3.61-1.12-7.276-2.239-10.494-4.225-.671-.42-1.343-.896-2.07-1.204-1.372-.615-2.799-.643-4.282-.531-1.26.084-2.519.28-3.806.391-2.267.224-4.561.28-6.856-.028a13.63 13.63 0 0 1-3.33-.895c-.923-.336-1.735-.756-2.295-1.651a2.192 2.192 0 0 1-.252-.588c-.14-.42-.224-.923-.307-1.315a8.663 8.663 0 0 1-.196-2.239 9.018 9.018 0 0 1 .531-2.826c.28-.756.7-1.82 1.623-1.987.42-.056.84.112 1.232.28 2.127.895 4.31 1.623 6.52 2.35 1.847.588 3.722 1.204 5.625 1.484 1.063.168 8.563.755 9.682.783 2.379.084 4.73.224 7.08.476 1.539.168 3.078.336 4.561.755 1.288.364 2.547.924 3.582 1.791.336.308.7.812.448 1.232-.196.308-.644.336-1.007.308-1.735-.168-3.302-1.288-5.009-1.204-.224.028-.56.224-.42.42.056.056.112.084.168.112 1.091.42 2.238.7 3.302 1.175 1.063.448 2.099 1.12 2.826 2.015.252.42.448.924.168 1.231Z"
      fill="#ABE0F6"
    />
    <path
      d="M105.779 76.8a.357.357 0 0 1-.168.113c-.588.307-1.287.112-1.903-.084-3.61-1.12-7.276-2.24-10.494-4.226-.671-.42-1.343-.895-2.07-1.203-1.372-.616-2.799-.644-4.282-.532-1.26.084-2.519.28-3.806.392-2.267.224-4.561.28-6.856-.028a13.63 13.63 0 0 1-3.33-.896c-.924-.335-1.735-.755-2.295-1.65a2.196 2.196 0 0 1-.252-.588c.42.363.896.671 1.484.923 2.182.924 4.589 1.12 6.94.952 2.378-.14 4.729-.644 7.108-.784 3.498-.196 6.94 1.175 10.074 2.826 3.134 1.623 6.128 3.554 9.486 4.646.14.056.252.112.364.14Z"
      fill="#71ADEE"
    />
    <path
      d="m90.835 68.155 1.316-3.247c.056-.168.056-.28 0-.363l-.532-.672c.056.084.084.224 0 .392l-1.315 3.246c-.112.252-.364.504-.616.588-.028 0-.056.028-.112.028l-3.47.475c-.112.028-.196 0-.252-.084l.504.644c.056.056.14.112.28.084l3.47-.476h.028l.056-.028c.28-.084.531-.336.643-.587Z"
      fill="#71ADEE"
    />
    <path
      d="m90.22 68.742-.532-.672c-.028 0-.056.028-.112.028l-3.47.476c-.112.028-.196 0-.252-.084l.504.644c.056.056.14.112.28.084l3.47-.476h.112Z"
      fill="#FFF7F2"
    />
    <path d="m88.989 68.379.979.392-.98.14v-.532Z" fill="#3989E5" />
    <path
      d="m90.835 68.156-.503-.672c-.112.252-.364.504-.616.588l.532.672c.224-.084.475-.336.587-.588Z"
      fill="#ABE0F6"
    />
    <path
      d="m90.332 67.482 1.315-3.246c.056-.168.056-.308 0-.392-.056-.084-.14-.111-.28-.084l-3.47.476c-.28.028-.615.308-.727.616l-1.316 3.246c-.084.168-.056.308.028.392.056.056.14.084.252.084l3.47-.476c.28-.056.588-.336.728-.616Z"
      fill="#ABE0F6"
    />
    <path
      d="M91.48 63.901c-.029 0-.057-.028-.113 0l-3.47.476c-.224.028-.504.28-.616.532l-1.315 3.246c-.056.112-.056.251.028.28.028 0 .056.028.112 0l3.47-.476c.224-.028.504-.28.616-.532l1.315-3.246c.056-.112.084-.252-.028-.28Z"
      fill="#E2F6FF"
    />
    <path
      d="M91.031 67.427h-.196v-.084h.084l-.084-.112c-.027-.028-.027-.084 0-.14l.28-.7-.084-.055h.196s.028 0 .028.028l.112.14c.028.028.028.084 0 .14l-.308.727c0 .028 0 .028-.028.056Z"
      fill="#999"
    />
    <path
      d="M91.087 66.363h-.028a.06.06 0 0 0-.055.056l-.308.728c-.028.056-.028.14.028.168l.111.14.029.028h.027c.028 0 .056-.028.084-.056l.308-.728c.028-.056.028-.14-.028-.168l-.112-.14c-.028-.028-.028-.028-.056-.028Z"
      fill="#3989E5"
    />
    <path
      d="m90.724 67.148.307-.727c0-.028.028-.028.028-.028h.084c-.028 0-.028.028-.028.028l-.307.727c0 .056 0 .084.028.14l.112.14.028.028h-.085s-.027 0-.027-.028l-.112-.14c-.028-.028-.028-.084-.028-.14Z"
      fill="#ABE0F6"
    />
    <path
      d="M90.752 67.287h.084l.112.14.028.028h-.084s-.028 0-.028-.028l-.112-.14Z"
      fill="#ABE0F6"
    />
    <path
      d="M90.724 67.148h.084c0 .056 0 .084.028.14h-.084c-.028-.028-.028-.084-.028-.14Z"
      fill="#ABE0F6"
    />
    <path
      d="m91.003 67.371.308-.727c.028-.056 0-.112 0-.14l-.112-.14-.028-.028c-.028 0-.028.028-.056.028l-.307.727c-.029.056 0 .112.027.14l.112.14.028.028c0 .028.028 0 .028-.028Z"
      fill="#3989E5"
    />
    <path
      d="m90.948 67.427.196-1.064c-.029 0-.029.028-.056.028l-.308.728c-.028.056 0 .112.028.14l.14.168Z"
      fill="#3989E5"
    />
    <path
      d="M91.507 63.228h-.084l-1.875.252c-.335.056-.671.252-.951.56l-.084.084 1.93-.252.085-.084c.028-.028.056-.056.056-.084 0-.028.028-.028.028-.028l.028-.028c.056-.056.084-.084.14-.112.056-.028.084-.056.14-.084.028-.028.055-.028.084-.056.027-.028.055-.028.111-.056.028 0 .056-.028.084-.028h.028c.028 0 .056-.027.084-.027h.084c.084-.056.084-.056.112-.056Z"
      fill="#E2F6FF"
    />
    <path
      d="m90.556 63.79-1.931.25-.084.085 1.93-.252.085-.084Z"
      fill="#ABE0F6"
    />
    <path
      d="M91.2 71.904h-.281c-.028 0-.084 0-.111-.028h-.112c-.028 0-.028 0-.056-.028-.056-.028-.14-.056-.196-.084-.028 0-.056-.028-.084-.028-.028-.028-.056-.028-.084-.056-.028-.028-.056-.056-.084-.056-.028-.028-.084-.056-.112-.084l-.084-.084a33.183 33.183 0 0 1-.084-.084c-.028-.028-.056-.056-.084-.112-.112-.168-.224-.336-.336-.532 0-.028-.028-.028-.028-.056-.028-.056-.056-.084-.056-.14-.056-.112-.084-.224-.14-.335-.028-.056-.028-.112-.055-.168-.028-.056-.028-.112-.056-.168-.028-.056-.028-.112-.056-.196a1.657 1.657 0 0 1-.056-.392c0-.056-.028-.14-.028-.196v-.643l-1.931.28c0 .167 0 .363.028.531.084.896.364 1.735.811 2.295.392.503.896.755 1.371.7l1.903-.252v-.084Z"
      fill="#E2F6FF"
    />
    <path
      d="M92.878 63.9c-.224-.279-.475-.503-.755-.587-.196-.084-.42-.112-.644-.084h-.056s-.028 0-.028.028h-.028c-.028 0-.056.028-.084.028l-.028.028c-.028.028-.056.028-.084.028-.056.028-.084.028-.112.056-.028.028-.056.028-.084.056-.055.028-.084.056-.14.084-.055.056-.111.084-.167.14 0 .028-.028.028-.028.028-.028 0-.028.028-.056.028-.028.028-.056.056-.056.084 0 0-.028 0-.028.028l-.084.084.14-.028.084-.028.168-.168c.083-.056.14-.084.195-.14l.168-.084c.028-.028.056-.028.056-.028a.527.527 0 0 1 .224-.056c.168-.028.364 0 .532.056.252.112.476.308.671.56.029.028.029.028.029.056.055.056.083.112.14.168.055.084.083.168.14.224 0 .028.027.028.027.055.056 0 .112.028.168.028 0-.251-.14-.447-.28-.643Zm-1.399 7.752c-.056.028-.084.056-.14.056-.056.028-.112.028-.168.028a.894.894 0 0 1-.531-.084 2.09 2.09 0 0 1-.336-.195c-.028-.028-.056-.028-.056-.056-.028-.028-.056-.028-.056-.056-.028 0-.028-.028-.028-.028-.056-.056-.14-.14-.196-.224-.028-.056-.056-.084-.084-.14-.392-.56-.615-1.288-.671-2.099-.028-.084-.028-.196-.028-.308l-.168-.196v.056c0 .056 0 .14.028.196v.168c0 .028.028.084.028.14v.112c.028.056.028.14.028.196.028.14.055.252.055.392.028.055.028.14.056.195.028.056.028.112.056.168.028.056.028.14.056.168.028.14.084.224.14.336.028.056.028.084.056.14 0 .028.028.028.028.056.056.084.084.196.14.28.056.084.112.196.168.28.028.028.056.084.084.112l.168.168c.028.027.056.055.112.083l.028.028c.028.028.056.028.056.056.028.028.056.056.084.056.028.028.056.028.084.056.056.028.14.056.196.084.028 0 .028.028.056.028.028.028.028.028.056.028s.028.028.055.028c.056 0 .085.028.113.028H91.255a1.08 1.08 0 0 0 .56-.224l-.168-.084c-.112-.056-.14-.056-.168-.028Z"
      fill="#ABE0F6"
    />
    <path d="m90.612 63.873-.056-.084-.084.084h.14Z" fill="#3989E5" />
    <path
      d="M91.171 71.4c.14.056.28.14.392.196-.028.028-.056.028-.084.056a.564.564 0 0 1-.308.084.893.893 0 0 1-.531-.084c-.112-.056-.224-.112-.336-.196-.028-.028-.056-.028-.056-.056-.028-.028-.056-.028-.056-.056.056-.084.14-.168.168-.28.252.112.531.196.811.336ZM93.074 64.488a10.911 10.911 0 0 0-1.007-.056l-.448-.588c-.056-.084-.168-.112-.28-.084l-.643.084.168-.168c.055-.056.14-.084.195-.14l.168-.084a.54.54 0 0 1 .28-.084c.168-.028.364 0 .532.056.252.084.475.28.671.56.028.028.028.028.028.056.056.056.084.112.14.168.056.084.084.168.14.224l.056.056Z"
      fill="#71ADEE"
    />
    <path
      d="M131.412 188.261a15.92 15.92 0 0 0 8.283 1.987c.868-.028 1.875-.168 2.743.028.755.168.923 1.343 1.063 1.987.308 1.315.84 2.63 1.791 3.61 1.847 1.903 4.673 2.77 7.248 3.022 2.518.252 5.065-.084 7.555-.56 2.435-.476 4.898-1.091 7.248-1.875a34.2 34.2 0 0 0 1.791-.615c5.177-1.875 10.158-4.254 15.055-6.772 3.722-1.931 7.444-3.946 10.69-6.605 4.058-3.33 6.912-8.982 3.806-13.852-1.679-2.658-4.477-4.421-7.332-5.68-3.973-1.735-8.255-2.659-12.564-3.135-7.892-.867-16.343-.056-22.863 4.478-1.623 1.147-3.33 2.099-4.925 3.246-2.519 1.763-4.981 3.61-7.556 5.317-4.337 2.91-8.955 5.457-12.761 8.983-2.266 2.154-2.126 4.841.728 6.436Z"
      fill="#2F455F"
    />
    <path
      d="M158.556 195.762c4.226-2.127 7.528-5.653 10.578-9.263 2.043-2.406 4.086-4.953 6.968-6.268.868-.392 1.791-.672 2.603-1.147.811-.476 1.539-1.26 1.679-2.183.168-1.231-.812-2.435-1.987-2.854-1.175-.42-2.491-.196-3.666.223-4.505 1.624-7.332 5.961-10.83 9.235a27.003 27.003 0 0 1-8.367 5.289c-2.434.952-4.981 1.567-7.527 2.211-1.176.28-2.519.699-3.023 1.819-.363.811-.252 2.294.196 3.05.448.756 1.959 1.091 2.743 1.287 2.266.588 4.701.504 6.968-.056a16.904 16.904 0 0 0 3.665-1.343ZM131.216 188.038a6.8 6.8 0 0 0 1.344.392c.951.196 1.93.28 2.91.252 1.511-.056 3.022-.364 4.505-.784 2.547-.727 4.981-1.735 7.332-2.966 1.175-.616 2.323-1.315 3.218-2.295-.28.308-1.035.476-1.399.588-.532.168-1.063.308-1.595.392-.476.084-.952.14-1.427.196-.476.055-.952-.14-1.399-.252-2.099-.56-4.17-1.288-6.045-2.407-.812-.476-1.595-1.035-2.463-1.399-1.371-.56-2.994-.588-4.365.028-1.091.504-1.707 1.315-2.351 2.295-.28.447-.559.727-.671 1.259a4.1 4.1 0 0 0 .028 1.931 4.104 4.104 0 0 0 1.679 2.35c.168.168.419.308.699.42Z"
      fill="#ABE0F6"
    />
    <path
      d="M159.228 172.814c-1.091 2.491-3.302 4.282-5.485 5.905-2.882 2.155-5.876 4.225-9.095 5.848-2.462 1.232-5.121 2.155-7.863 2.603-.84.14-1.679.224-2.491.28-1.175.056-2.406 0-3.526-.42-1.147-.42-1.679-1.007-2.042-2.127-.532-1.763.084-3.694.867-5.261a22.963 22.963 0 0 1 2.826-4.253c1.708-2.099 3.694-3.974 5.653-5.877 3.134-2.994 6.437-5.932 9.907-8.535.279-.224.587-.42.867-.644.084-.084.196-.139.308-.195 1.539-1.008 3.61-1.372 5.513-1.036.028 0 .056 0 .084.028.447.084.895.196 1.343.392.196.056.392.168.56.252a.614.614 0 0 1 .223.14c.112.056.196.112.308.196.084.056.196.111.28.195.168.112.308.224.448.364.168.14.308.308.448.476 1.203 1.399 1.567 3.442 1.735 5.233.195 2.043-.028 4.505-.868 6.436Z"
      fill="#FFDC1E"
    />
    <path
      d="M159.228 172.816c-1.092 2.49-3.302 4.281-5.485 5.904-2.883 2.155-5.877 4.226-9.095 5.849-2.462 1.231-5.121 2.155-7.863 2.602a62.9 62.9 0 0 0 3.386-2.154c2.35-1.596 4.561-3.442 6.352-5.653a52.283 52.283 0 0 0 3.302-4.562c1.539-2.378 3.582-5.4 2.966-8.423-.279-1.315-1.231-2.378-2.014-3.498-.784-1.091-1.456-2.49-1.036-3.778 0-.028.028-.083.056-.083s.056 0 .084.028c.336.139.7.363 1.035.335.364-.028.756-.224 1.148-.28.895-.139 1.707.028 2.574.028.028 0 .056 0 .084.028 1.288.224 2.743.56 3.666 1.54.364.391.616.811.868 1.287a9.834 9.834 0 0 1 1.063 5.429c-.14 1.791-.364 3.722-1.091 5.401Z"
      fill="#E39F3A"
    />
    <path
      d="M179.432 164.026c-.112 2.882-.336 5.792-1.035 8.619-.644 2.658-1.735 5.205-3.358 7.387-.476.812-1.148 1.512-1.931 2.211-2.631 2.351-5.485 4.45-8.311 6.548-2.239 1.651-4.506 3.246-6.856 4.73-2.715 1.679-5.065 3.33-8.368 3.554a8.344 8.344 0 0 1-3.805-.644c-.056 0-.14-.056-.196-.084-1.064-.504-1.987-1.679-2.603-2.658-1.539-2.351-.699-5.345.364-7.724.308-.672.868-1.259 1.315-1.819.504-.672 1.036-1.343 1.539-2.015 1.036-1.343 2.043-2.686 3.079-4.029 2.462-3.219 4.925-6.437 7.387-9.683 1.232-1.595 2.435-3.19 3.638-4.757.504-.672 1.064-1.371 1.819-1.707.616-.252 1.092-.196 1.511.028.504.28.924.811 1.4 1.231l.139.14a25.746 25.746 0 0 0 6.633 3.862c1.175.476 2.406.895 3.665 1.147 1.008.224 2.015-.811 2.603-1.595.588-.867 1.007-1.791 1.371-2.742Z"
      fill="#FFDC1E"
    />
    <path
      d="M179.432 164.029c-.112 2.882-.335 5.793-1.035 8.619-.644 2.658-1.735 5.205-3.358 7.388-.476.811-1.147 1.511-1.931 2.21-2.63 2.351-5.485 4.45-8.311 6.549-2.239 1.651-4.506 3.246-6.856 4.729-2.715 1.679-5.065 3.33-8.367 3.554a8.346 8.346 0 0 1-3.806-.644 57.305 57.305 0 0 0 2.77-1.119c3.05-1.315 6.213-2.799 8.255-5.429.728-.924 1.316-2.043 1.232-3.218-.056-1.008-.56-2.043-.252-3.05.336-1.176.783-1.204 1.903-1.064 1.007.112 2.183-.476 2.546-1.399a65.084 65.084 0 0 0 1.092-3.302c.363-1.119 1.091-2.687 1.063-3.862 0-1.007-.867-1.287-1.623-1.595a15.515 15.515 0 0 1-1.567-.672 18.227 18.227 0 0 1-3.162-2.098 24.826 24.826 0 0 1-1.343-1.176c1.231-1.595 2.434-3.19 3.637-4.757.504-.672 1.064-1.371 1.819-1.707.616-.252 1.092-.196 1.511.028.448.42.924.84 1.4 1.231l.14.14a25.695 25.695 0 0 0 6.632 3.862c1.175.476 2.406.896 3.666 1.147 1.007.224 2.014-.811 2.602-1.595.56-.895.98-1.819 1.343-2.77Z"
      fill="#E39F3A"
    />
    <path
      d="m97.831 136.883-3.19 1.875.056.112c-.028.056-.028.14-.028.224 0 .476.308 1.036.7 1.287.084.056.14.084.224.084l.055.112 3.247-1.903c.112-.084.196-.223.196-.447 0-.476-.308-1.036-.7-1.288-.224-.139-.42-.139-.56-.056Z"
      fill="#AAD2F6"
    />
    <path
      d="M95.173 138.815c.392.252.7.811.7 1.287 0 .476-.336.644-.728.42-.392-.252-.7-.812-.7-1.287 0-.476.308-.644.728-.42Z"
      fill="#D9D9D9"
    />
    <path
      d="m94.613 138.786-.195.112.055.112c-.028.056-.028.14-.028.224 0 .475.308 1.035.7 1.287.084.056.14.084.224.084l.056.112.252-.14c.112-.084.196-.224.196-.448 0-.476-.308-1.035-.7-1.287-.224-.14-.42-.168-.56-.056Z"
      fill="#71ADEE"
    />
    <path
      d="M94.92 138.954c.393.252.7.812.7 1.287 0 .476-.335.644-.727.42-.392-.252-.7-.811-.7-1.287 0-.476.336-.672.728-.42Z"
      fill="#004EB6"
    />
    <path
      d="M95.229 140.634c.196 0 .308-.168.308-.42 0-.419-.28-.951-.644-1.175a.54.54 0 0 0-.28-.084c-.196 0-.308.168-.308.42 0 .42.28.951.644 1.175a.626.626 0 0 0 .28.084Z"
      fill="#71ADEE"
    />
    <path
      d="m95.593 135.485-3.19 1.875.056.112c-.028.056-.028.14-.028.224 0 .476.307 1.035.7 1.287.083.056.14.084.223.084l.056.112 3.246-1.903c.112-.084.196-.224.196-.448 0-.475-.308-1.035-.7-1.287-.223-.14-.42-.168-.56-.056Z"
      fill="#AAD2F6"
    />
    <path
      d="M92.906 137.415c.392.252.7.812.7 1.287 0 .476-.336.644-.728.42-.391-.252-.7-.812-.7-1.287 0-.476.337-.672.728-.42Z"
      fill="#D9D9D9"
    />
    <path
      d="m92.375 137.36-.196.112.056.112c-.028.056-.028.14-.028.224 0 .476.308 1.035.7 1.287.083.056.14.084.223.084l.056.112.252-.14c.112-.084.196-.224.196-.448 0-.475-.308-1.035-.7-1.287-.224-.14-.42-.14-.56-.056Z"
      fill="#71ADEE"
    />
    <path
      d="M92.682 137.528c.392.252.7.811.7 1.287 0 .476-.336.644-.728.42-.392-.252-.7-.812-.7-1.288 0-.475.336-.643.728-.419Z"
      fill="#004EB6"
    />
    <path
      d="M92.99 139.234c.196 0 .308-.168.308-.42 0-.42-.28-.951-.644-1.175a.54.54 0 0 0-.28-.084c-.195 0-.307.168-.307.419 0 .42.28.952.643 1.176.084.028.196.084.28.084Z"
      fill="#71ADEE"
    />
    <path
      d="m106.339 141.782-3.19 1.875.056.112c-.028.056-.028.14-.028.224 0 .475.307 1.035.699 1.287.084.056.14.084.224.084l.056.112 3.246-1.903c.112-.084.196-.224.196-.448 0-.476-.308-1.035-.699-1.287-.252-.14-.448-.14-.56-.056Z"
      fill="#AAD2F6"
    />
    <path
      d="M103.652 143.712c.392.252.7.811.7 1.287 0 .476-.336.644-.728.42-.392-.252-.699-.812-.699-1.287 0-.476.335-.672.727-.42Z"
      fill="#D9D9D9"
    />
    <path
      d="m103.092 143.683-.196.112.056.112c-.028.056-.028.14-.028.224 0 .476.308 1.036.7 1.287.084.056.14.084.224.084l.056.112.252-.14c.111-.084.195-.223.195-.447 0-.476-.307-1.036-.699-1.288-.224-.167-.42-.167-.56-.056Z"
      fill="#71ADEE"
    />
    <path
      d="M103.4 143.853c.392.251.7.811.7 1.287 0 .476-.336.643-.728.42-.392-.252-.699-.812-.699-1.288.028-.475.335-.671.727-.419Z"
      fill="#004EB6"
    />
    <path
      d="M103.708 145.533c.196 0 .308-.168.308-.42 0-.42-.28-.952-.644-1.176a.539.539 0 0 0-.28-.083c-.195 0-.307.167-.307.419 0 .42.28.952.643 1.176a.626.626 0 0 0 .28.084Z"
      fill="#71ADEE"
    />
    <path
      d="m108.773 143.096-3.19 1.875.056.112c-.028.056-.028.14-.028.224 0 .476.308 1.035.7 1.287.084.056.14.084.223.084l.056.112 3.247-1.903c.111-.084.195-.224.195-.447 0-.476-.307-1.036-.699-1.288-.224-.14-.42-.168-.56-.056Z"
      fill="#AAD2F6"
    />
    <path
      d="M106.086 145.026c.392.252.7.812.7 1.288 0 .475-.336.643-.728.419-.391-.251-.699-.811-.699-1.287 0-.476.336-.672.727-.42Z"
      fill="#D9D9D9"
    />
    <path
      d="m105.555 144.973-.196.112.056.112c-.028.056-.028.14-.028.224 0 .476.308 1.035.699 1.287.084.056.14.084.224.084l.056.112.252-.14c.112-.084.196-.224.196-.447 0-.476-.308-1.036-.7-1.288-.251-.14-.419-.14-.559-.056Z"
      fill="#71ADEE"
    />
    <path
      d="M105.863 145.141c.391.252.699.812.699 1.287 0 .476-.336.644-.727.42-.392-.252-.7-.812-.7-1.287 0-.476.336-.644.728-.42Z"
      fill="#004EB6"
    />
    <path
      d="M106.17 146.847c.196 0 .308-.168.308-.42 0-.42-.28-.951-.644-1.175a.534.534 0 0 0-.279-.084c-.196 0-.308.168-.308.42 0 .419.28.951.643 1.175.084.028.196.084.28.084Z"
      fill="#71ADEE"
    />
    <path
      d="m106.702 136.492 2.295-1.259-4.841-1.987-.868.532 3.414 2.714Z"
      fill="#E2F6FF"
    />
    <path
      d="M103.288 133.777v.252l3.414 2.715v-.252l-3.414-2.715Z"
      fill="#71ADEE"
    />
    <path
      d="m107.486 132.155 1.931-1.12v7.976l-1.931 1.119-.924-1.595v-1.511l.924-4.869Z"
      fill="#71ADEE"
    />
    <path
      d="m107.598 132.238 1.931-1.119v7.948l-1.931 1.119-.896-1.595v-1.511l.896-4.842Z"
      fill="#004EB6"
    />
    <path
      d="m109.529 131.119-.112-.084-1.931 1.12.112.083 1.931-1.119Z"
      fill="#E2F6FF"
    />
    <path
      d="m107.486 140.13.112.056-.896-1.595-.14-.056.924 1.595Z"
      fill="#71ADEE"
    />
    <path
      d="M91.367 148.777c.028.028.084.028.112.056h.056c.028 0 .056 0 .084.028h-.028c-.084-.028-.14-.056-.224-.084Zm.28.084c.056 0 .112.028.168.028h.336c.224-.028.448-.084.671-.14.784-.196 1.567-.504 2.323-.784.924-.363 1.847-.839 2.63-1.483a49.776 49.776 0 0 0 1.875-1.539c.588-.476 5.933-4.981 6.437-5.569.252-.28.475-.587.671-.895.084-.14.168-.308.196-.476.028-.112.056-.196.084-.308 0-.056.028-.112.028-.168v-.084a.52.52 0 0 1-.056.196c-.084.196-1.371 1.092-1.875 1.399-.643.364-1.231.84-1.847 1.26l-5.037 3.526c-.448.308-.951.531-1.427.783-.532.28-1.063.616-1.567.952a16.29 16.29 0 0 0-2.77 2.406c-.196.224-.364.476-.56.7l-.056.056-.056.056c-.028.028-.056.028-.056.056 0 0-.056.028-.112.028Z"
      fill="#71ADEE"
    />
    <path
      d="m91.843 148.748.056-.056.056-.056c.056-.056.084-.112.14-.168h.056c.224-.028.447-.084.671-.14.784-.196 1.567-.503 2.323-.783.923-.364 1.847-.84 2.63-1.483a50.771 50.771 0 0 0 1.875-1.539c.588-.476 5.933-4.982 6.437-5.569a6.88 6.88 0 0 0 .671-.896c.056-.112.112-.224.168-.363.056-.056.084-.084.084-.112.028-.056.056-.112.056-.196v.084c0 .056 0 .112-.028.168-.028.111-.056.223-.084.307a3.416 3.416 0 0 1-.196.476c-.196.336-.42.616-.671.896-.476.559-5.849 5.093-6.437 5.568-.615.504-1.259 1.036-1.875 1.539a10.603 10.603 0 0 1-2.63 1.484c-.756.279-1.54.587-2.323.783-.224.056-.448.112-.671.14h-.336c-.056 0-.112 0-.168-.028.056 0 .084-.028.14-.056.028.028.056.028.056 0Z"
      fill="#004EB6"
    />
    <path
      d="M91.619 148.859c-.028 0-.056 0-.084-.028h-.056a.212.212 0 0 1-.112-.056c.084.028.14.056.252.084-.028 0-.028 0 0 0Z"
      fill="#588C73"
    />
    <path
      d="M91.06 148.19c0 .168.027.336.111.448a.502.502 0 0 0 .196.14.52.52 0 0 0 .196.056h.056c.056 0 .084-.028.14-.056.028-.028.056-.028.056-.056l.056-.056.056-.056c.196-.224.364-.476.56-.7.28-.307.56-.587.895-.839.056-.056.112-.084.168-.14a11.6 11.6 0 0 1 1.203-.84c.224-.139.7-.391.728-.419.392-.224 10.718-7.276 11.165-7.668.14-.14.308-.252.392-.42.028-.056.056-.112.056-.196 0-.111-.028-.251-.168-.335-.196-.14-.476-.084-.727-.028-1.204.335-6.185 3.134-9.487 5.065-.84.475-1.567.923-2.099 1.231-.475.28-.783.476-.895.56h-.028c-.588.419-1.036.951-1.455 1.511a8.044 8.044 0 0 0-.896 1.567c0 .028-.028.056-.028.084-.028.056-.028.084-.056.14-.028.028-.028.084-.028.112-.028.056-.028.084-.056.14v.028a2.698 2.698 0 0 0-.112.727Z"
      fill="#E2F6FF"
    />
    <path
      d="m90.919 148.47.112.195.056.112.056.112H91.562c.112 0 .168-.084.196-.196v-.056a.614.614 0 0 0-.112-.363.82.82 0 0 0-.252-.28c-.168-.112-.307-.056-.363.112l-.112.364Z"
      fill="#2F455F"
    />
    <path
      d="m91.087 148.777.056.112H91.563c.112 0 .168-.084.196-.196l-.672.084ZM90.919 148.47l.112.196.615-.42c-.028-.056-.056-.112-.112-.14l-.056-.056-.056-.056-.028-.028c-.168-.111-.307-.056-.363.112 0 .028-.112.392-.112.392Z"
      fill="#2F455F"
    />
    <path
      d="M91.255 148.777c0 .14-.14.14-.224.112-.028 0-.056-.028-.056-.028-.028 0-.028-.028-.028-.028a.398.398 0 0 1-.056-.168c0-.056.028-.112.056-.168.028-.056.112-.028.14 0 .084.028.168.168.168.28Z"
      fill="#004EB6"
    />
    <path
      d="m109.277 135.428-2.323 1.231 4.925 2.351.868-.532-3.47-3.05Z"
      fill="#E2F6FF"
    />
    <path d="M111.879 139.008v.196l.868-.504v-.223l-.868.531Z" fill="#004EB6" />
    <path
      d="M106.954 136.658v.196l4.925 2.351v-.196l-4.925-2.351Z"
      fill="#71ADEE"
    />
    <path
      d="M113.922 147.321v.504c.448.252 1.399.112 2.099-.308l1.343-.755v-.504l-1.343.755c-.728.42-1.651.56-2.099.308Z"
      fill="#004EB6"
    />
    <path
      d="m101.609 140.746 12.285 6.576c.448.252 1.399.112 2.099-.308l1.343-.755-12.033-7.64-3.694 2.127Z"
      fill="#E2F6FF"
    />
    <path
      d="M101.609 140.746v.476l12.313 6.604v-.504l-12.313-6.576Z"
      fill="#71ADEE"
    />
    <path
      d="m89.8 131.791-1.343.755 11.305 7.08 3.666-2.127-11.557-6.044c-.448-.196-1.371-.084-2.071.336Z"
      fill="#E2F6FF"
    />
    <path
      d="M88.485 132.547v.504l11.305 7.079v-.475l-11.305-7.108Z"
      fill="#71ADEE"
    />
    <path
      d="M99.79 139.652v.476l3.666-2.127v-.476l-3.666 2.127Z"
      fill="#D96459"
    />
    <path
      d="m101.637 140.746-1.315 1.371-1.679-1.064 1.147-1.399 1.847 1.092Z"
      fill="#71ADEE"
    />
    <path
      d="m101.637 140.745 3.666-2.126-1.847-1.092-3.666 2.127 1.847 1.091Z"
      fill="#E2F6FF"
    />
    <path
      d="m100.322 142.117 1.287-.895.028-.476-1.315 1.371Z"
      fill="#004EB6"
    />
    <path
      d="m106.534 145.448 1.735-1.147-.811-.42-1.483 1.035.559.532Z"
      fill="#71ADEE"
    />
    <path
      d="m106.534 145.448 2.267-.364-.532-.307v-.476l-1.735 1.147Z"
      fill="#004EB6"
    />
    <path d="m108.269 144.775-1.735.672 2.267-.364-.532-.308Z" fill="#E2F6FF" />
    <path
      d="m104.1 144.132 1.707-1.148-.784-.42-1.483 1.064.56.504Z"
      fill="#71ADEE"
    />
    <path
      d="m104.1 144.132 2.239-.364-.532-.28v-.504l-1.707 1.148Z"
      fill="#004EB6"
    />
    <path d="m105.807 143.488-1.707.644 2.239-.364-.532-.28Z" fill="#E2F6FF" />
    <path
      d="m95.62 139.263 2.211-.364-.503-.307v-.504l-1.707 1.175Z"
      fill="#004EB6"
    />
    <path d="m97.328 138.592-1.707.671 2.21-.363-.503-.308Z" fill="#E2F6FF" />
    <path
      d="m93.354 137.835 2.21-.364-.475-.308v-.475l-1.735 1.147Z"
      fill="#004EB6"
    />
    <path d="m95.089 137.164-1.735.672 2.21-.364-.475-.308Z" fill="#E2F6FF" />
    <path
      d="m95.62 139.264 1.707-1.176-.755-.447-1.511 1.091.56.532ZM93.354 137.835l1.735-1.147-.756-.448-1.539 1.064.56.531Z"
      fill="#71ADEE"
    />
    <path
      d="M91.815 146.399c0 .028.028.056.028.084l.028.027c.028.056.084.084.14.14.168.14.364.252.56.364.028.028.055.028.112.056.14.084.335.14.475.084a.546.546 0 0 0 .28-.28c.028-.028.028-.056.028-.084.056-.14.028-.252-.056-.363-.028-.056-.084-.112-.14-.168a.85.85 0 0 0-.196-.14c-.112-.084-.224-.14-.364-.224-.027-.028-.055-.028-.084-.056-.14-.084-.307-.14-.475-.084a.397.397 0 0 0-.252.224s0 .028-.028.028c-.028.056-.084.14-.112.224.056.056.056.112.056.168Z"
      fill="#2F455F"
    />
    <path
      d="M93.102 146.202c-.14-.112-.28-.168-.448-.252-.14-.084-.307-.14-.475-.084a.397.397 0 0 0-.252.224 2.22 2.22 0 0 0-.112.252v.056-.14c.028-.084.084-.196.112-.252a.397.397 0 0 1 .252-.224c.168-.056.335.028.475.084.14.084.308.168.448.252.168.14.448.336.42.588 0-.224-.252-.392-.42-.504ZM99.93 140.69c-.084.084-.084.196 0 .252.084.056.196.028.28-.084.084-.084.084-.196 0-.251-.084-.056-.196 0-.28.083ZM96.432 144.523l-.951.531c-.056.028-.084.084-.056.168.14.308.532 1.288.42 2.267 0 .056.055.084.084.056l.979-.532c.028-.028.056-.056.056-.112.14-1.035-.28-2.015-.42-2.322 0-.056-.056-.084-.112-.056Z"
      fill="#004EB6"
    />
    <path
      d="M137.429 129.02c-.224.783-.896 1.959-1.595 2.406-.364.224-.812.28-1.316.252-.475 0-.951-.112-1.343-.196a3.372 3.372 0 0 1-.504-.111c-.643-.168-1.315-.336-1.93-.532-1.568-.476-3.079-1.064-4.562-1.679-.727-.28-1.455-.644-2.183-.896-.783-.252-1.399 0-2.154.084-1.12.14-2.267.056-3.386.028-.56-.028-1.148-.028-1.707-.028-.448 0-1.148-.112-1.539.112-.728.42-1.176 1.539-1.428 2.295-.14.448-.112.951-.28 1.399-.139.364-.363.672-.559.979-.448.644-.756 1.344-1.231 1.959-.7.868-1.652 1.483-2.631 1.987-.224.112-.42.196-.672.252h-.111c-.252 0-.56-.14-.644-.42-.056-.168 0-.336.056-.531.28-.868.895-1.567 1.539-2.239.14-.14.224-.308.28-.476.056-.196.056-.42.056-.643.028-.476.028-.952.028-1.4 0-.14 0-.363-.112-.475-.196-.168-.504.056-.672.168-.252.139-.476.279-.727.419-.224.112-.476.28-.588.504-.112.168-.112.392-.14.588-.056.363-.14.727-.196 1.091a9.345 9.345 0 0 1-.392 1.959c-.056.224-.14.448-.279.644a.8.8 0 0 1-.28.251c-.112.028-.224.112-.308.084-.224-.056-.336-.252-.42-.475-.112-.364-.084-.784 0-1.176.14-.895.392-1.763.448-2.658 0-.196 0-.364-.028-.532-.028-.056-.056-.196-.14-.224-.056-.056-.196-.056-.252-.084-.448-.196-.532-.783-.504-1.287.056-.7.224-1.371.392-2.071.196-.727.364-1.483.812-2.099.168-.223.363-.419.615-.531.392-.168.84-.14 1.26-.112.671.028 3.861-.588 4.645-1.036.895-.503 5.373-2.658 6.772-3.05a14.72 14.72 0 0 1 3.106-.532c1.092-.083 2.015.084 3.078.364 1.036.28 2.099.588 3.135.896 1.287.364 2.546.755 3.805 1.119.924.28 1.847.532 2.771.812.392.112.783.252 1.091.475.028.028.084.056.112.112.336.252.588.644.756 1.064.307 1.035.363 2.182.056 3.19Z"
      fill="#ABE0F6"
    />
    <path
      d="M136.197 128.909c-.42 1.007-.951 1.931-1.651 2.77-.476 0-.951-.112-1.343-.196a3.256 3.256 0 0 1-.504-.112c-.643-.167-1.315-.335-1.931-.531-1.567-.476-3.078-1.064-4.561-1.679-.728-.28-1.455-.644-2.183-.896-.783-.252-1.399 0-2.155.084-1.119.14-2.266.056-3.386.028-.559-.028-1.147-.028-1.707-.028-.447 0-1.147-.112-1.539.112-.727.42-1.175 1.539-1.427 2.295-.14.448-.112.951-.28 1.399-.14.364-.363.672-.559.979-.448.644-.756 1.344-1.232 1.959-.699.868-1.651 1.483-2.63 1.987-.224.112-.42.196-.672.252.476-.364.924-.756 1.288-1.203 1.651-1.987 1.958-4.73 3.19-6.996.363-.672.839-1.287 1.483-1.707.587-.364 1.287-.532 1.987-.644 1.343-.252 2.686-.42 4.057-.448.644 0 1.316 0 1.987.028.476.028.98.336 1.399 0 .392-.307.42-.923.448-1.371 0-.56-.084-1.147-.224-1.707-.028-.14-.084-.308-.084-.448.028-.167.112-.307.252-.363.168-.056.336.028.448.168.112.139.14.307.168.475.196 1.092 0 2.295.42 3.33.167.336.419.504.783.616.924.28 1.847.672 2.798.784.98.083 2.043-.14 2.631-.896.672-.839.56-2.463 1.091-3.414.924.28 1.847.532 2.771.812.391.111.783.251 1.091.475.028.028.084.056.112.112.476 1.231.196 2.687-.336 3.974Z"
      fill="#71ADEE"
    />
    <path
      d="M146.999 137.946c-.42 2.715-.7 5.485-.084 8.143-.42-.867-.84-1.735-1.315-2.574a4.456 4.456 0 0 0-.364-.616c-2.239-3.526-5.821-5.932-9.403-8.031-.028-.028-.056-.028-.112-.056-.951-.532-1.931-1.147-2.49-2.099-.392-.643-.532-1.427-.616-2.155a10.805 10.805 0 0 1-.056-.951c-.028-.923.056-1.847.252-2.742.168-.728.42-1.456.84-2.071.195-.28.503-.728.811-.896.364-.196.924-.028 1.315 0 .196.028.42.056.616.084 1.903.252 3.806.7 5.681 1.148 1.259.307 2.518.587 3.75.895.951.224 1.567.392 1.763 1.483.587 3.526-.056 6.968-.588 10.438Z"
      fill="#004EB6"
    />
    <path
      d="M145.6 143.514a4.456 4.456 0 0 0-.364-.616c-2.239-3.526-5.821-5.932-9.403-8.031-.028-.028-.056-.028-.111-.056-.196-.42-.448-.84-.56-1.175-.28-.784-.504-1.567-.616-2.379-.028-.168-.028-.308-.056-.476.336.168.7.336 1.036.504 1.651.728 3.386 1.287 4.953 2.183a11.626 11.626 0 0 1 2.77 2.182c.504.532.98 1.148 1.315 1.791.364.728.448 1.372.532 2.155.112.84.42 1.623.504 2.491 0 .475.028.951 0 1.427Z"
      fill="#2F455F"
    />
    <path
      d="M135.162 133.637c.112.307.364.755.559 1.175-.951-.532-1.93-1.147-2.49-2.099-.392-.643-.532-1.427-.616-2.155a10.91 10.91 0 0 1 .196-3.693c.168-.728.42-1.456.84-2.071.196-.28.503-.728.811-.896.364-.196.924-.028 1.315 0 .196.028.42.056.616.084a17.06 17.06 0 0 0-1.399 2.379c-.728 1.539-.756 3.19-.476 4.841.14.84.336 1.651.644 2.435Z"
      fill="#004EB6"
    />
    <path
      d="M135.721 134.812c-.951-.531-1.93-1.147-2.49-2.098-.392-.644-.532-1.428-.616-2.155-.028-.224-.056-.42-.056-.644v-.308c.588.476 1.26.868 1.931 1.204 0 .168.028.308.056.475.14.812.336 1.624.616 2.379.112.28.364.7.559 1.147Z"
      fill="#2F455F"
    />
    <path
      d="M178.565 160.584c-.056.867-.14 1.819-.224 2.854-.196 2.407-1.035 4.394-3.442 5.233a19.4 19.4 0 0 1-5.793 1.036c-2.994.112-6.016-.392-8.871-1.316-2.854-.923-5.624-2.294-7.863-4.337-.979-.868-2.239-2.071-2.574-3.358-.42-1.623-.728-3.33-1.176-4.953a248.305 248.305 0 0 0-2.322-7.78c-1.679-5.149-3.582-10.326-3.778-15.699-.056-1.567.028-3.19.671-4.617.644-1.427 1.931-2.631 3.47-2.854 1.008-.14 2.099.112 2.995.559.895.448 1.819.616 2.77.98 2.379.895 4.533 2.322 6.688 3.694 5.009 3.274 10.046 6.548 14.664 10.382.783.671 1.595 1.343 2.154 2.238.336.532.56 1.092.644 1.707.084.588-.196 1.176 0 1.735.364 1.176.728 2.323 1.035 3.526 1.12 4.534 1.204 7.108.952 10.97Z"
      fill="#004EB6"
    />
    <path
      d="M178.565 160.585c-.056.868-.14 1.819-.224 2.855-.196 2.406-1.035 4.393-3.442 5.233a19.43 19.43 0 0 1-5.793 1.035c-.14 0-.643-2.882-.671-3.162a65.19 65.19 0 0 1-.504-3.246c-.28-2.183-.42-4.366-.364-6.549.084-3.721.616-7.611 2.099-11.053.56-1.287 1.315-2.519 2.351-3.526-.112.112.195.699.279.811.168.252.392.448.588.644.448.392 1.035.588 1.483.979.84.756 1.427 1.735 1.875 2.743 1.847 4.114 2.211 8.703 2.323 13.236Z"
      fill="#2F455F"
    />
    <path
      d="M154.442 144.383c-.223 0-.475 0-.699-.028-.504-.056-1.259-.168-1.931-.616-.951-.699-1.231-1.735-1.427-2.826-.28-1.455-.028-2.938.28-4.338.196-.867.419-1.735.811-2.546.364-.756.896-1.651 1.623-2.127.784-.504 2.099-.588 2.995-.588.279 0 .587.028.867.056a7.55 7.55 0 0 1 3.134 1.036c.42.252.728.532 1.008.811l.223.224c.14.14.252.196.588.308l.14.056c1.567.644 1.987 2.77 1.679 4.058-.224.923-.755 1.679-1.203 2.238-1.12 1.4-2.407 2.463-3.806 3.219-1.315.671-2.826 1.063-4.282 1.063Z"
      fill="#004EB6"
    />
    <path
      d="M162.53 137.5c-.168.644-.532 1.204-.951 1.707-.952 1.148-2.099 2.183-3.386 2.883a8.386 8.386 0 0 1-1.148.503 7.616 7.616 0 0 1-3.162.448c-.448-.028-.895-.112-1.259-.364-.644-.475-.812-1.287-.924-2.015-.112-.699-1.035-.895-1.427-1.455-.476-.727-.476-1.511-.224-2.294.448-1.372 1.427-2.575 2.659-3.33 1.259-.756 2.742-1.036 4.169-.896.112 0 .224.028.308.056a6.39 6.39 0 0 1 2.295.784c.392.223.699.559 1.035.867.364.336.7.448 1.12.616.811.336 1.063 1.735.895 2.49Z"
      fill="#ABE0F6"
    />
    <path
      d="M162.53 137.499c-.168.644-.532 1.204-.952 1.707-.951 1.148-2.098 2.183-3.386 2.883a8.201 8.201 0 0 1-1.147.503c.14-.559.196-1.147.14-1.735-.112-1.147-.644-1.679-1.455-2.406-.784-.728-1.204-2.267-.952-3.302.168-.812.756-1.483 1.428-1.931.307-.224.643-.364.979-.476a6.397 6.397 0 0 1 2.295.784c.391.224.699.559 1.035.867.364.336.7.448 1.119.616.812.336 1.064 1.735.896 2.49Z"
      fill="#71ADEE"
    />
    <path
      d="M158.053 139.235c-.588.42-1.372.532-2.099.588-.336 0-.7 0-1.036.084-.307.056-.615.196-.923.28-1.567.559-3.274.727-4.953.699-1.008 0-2.015-.056-2.967-.336a8.474 8.474 0 0 1-.895-.308c-1.203-.531-2.267-1.511-2.603-2.77-.363-1.371-.055-2.91-.951-4.141a1.082 1.082 0 0 1-.14-1.12c.168-.392.7-.448 1.036-.643.783-.42 1.091-1.4 1.147-2.295.056-1.147-.084-2.239.392-3.302.447-1.008 1.063-2.267 2.042-2.883 1.512-.979 3.191-1.035 4.758-.223.503.251.979.559 1.399.923.308.252.588.504.867.784 2.239 2.21 3.582 5.289 3.666 8.423.028.839-.028 1.763.448 2.462.448.7 1.315 1.092 1.567 1.847.224.7-.168 1.483-.755 1.931Z"
      fill="#ABE0F6"
    />
    <path
      d="M158.053 139.235c-.588.42-1.371.532-2.099.588-.336 0-.7 0-1.035.083-.308.056-.616.196-.924.28-1.567.56-3.274.728-4.953.7-1.007 0-2.015-.056-2.966-.336.196-.084.363-.168.531-.252 1.232-.671 2.267-1.819 2.463-3.218.224-1.623-.728-3.33-.28-4.925.196-.728.672-1.343.868-2.071.475-1.651-.728-3.582.251-5.121.504-.755 1.4-1.091 2.351-1.203.308.252.588.503.868.783 2.238 2.211 3.582 5.289 3.665 8.423.028.84-.028 1.763.448 2.463.448.7 1.315 1.091 1.567 1.847.224.728-.168 1.511-.755 1.959Z"
      fill="#71ADEE"
    />
    <path
      d="M161.523 137.583c-.616.307-1.316.559-1.959.419-.672-.14-1.232-.643-1.819-.979-.952-.532-1.819-.868-2.939-.784-.951.084-1.819.42-2.658.868-.504-.98-.84-2.015-1.035-3.078a9.963 9.963 0 0 0-.532 1.539c-.364-.812-.728-1.651-.728-2.519 0-.559.14-1.119.224-1.679a8.195 8.195 0 0 0-.28-3.498v-.056a10.16 10.16 0 0 1-.363 2.239 13.166 13.166 0 0 0-2.183-2.938c-.196-.224-.42-.448-.504-.7-.028-.112-.056-.224-.028-.336-.112.504-.28.98-.448 1.455 0-.139-1.035-1.399-1.175-1.595-.308-.559-.42-1.063-.504-1.707-.252-2.462 1.12-4.365 3.079-5.68 3.162-2.127 7.331-2.603 10.913-1.372a13.12 13.12 0 0 1 4.729 2.911 10.333 10.333 0 0 1 1.708 2.127c.531.839.951 1.79 1.231 2.77 1.287 4.645-.336 10.242-4.729 12.593Z"
      fill="#FFDC1E"
    />
    <path
      d="M161.523 137.583c-.616.308-1.316.56-1.959.42-.672-.14-1.232-.644-1.819-.98-.952-.531-1.819-.867-2.939-.783-.951.084-1.818.42-2.658.867a11.045 11.045 0 0 1-1.035-3.078 10.036 10.036 0 0 0-.532 1.539c-.364-.811-.728-1.651-.728-2.518 0-.56.14-1.12.224-1.679a8.192 8.192 0 0 0-.28-3.498v-.224c3.246.168 6.577-.14 9.627-1.343.364-.14.727-.308 1.063-.476 1.763-.868 3.358-2.099 4.534-3.582.531.84.951 1.791 1.231 2.77 1.287 4.618-.336 10.214-4.729 12.565Z"
      fill="#E39F3A"
    />
    <path
      d="M185.029 164.195c-.392.504-2.435 2.351-2.994 2.743-5.261 3.722-8.144 5.681-14.02 8.395-.784.364-1.427.616-1.679 1.511-.14.588-.308 1.176-.504 1.763-.42 1.287-.896 2.575-1.035 3.918-.056.727-.028 1.455-.196 2.155-.14.643-.588 1.343-1.232 1.343-.14 0-.307-.028-.447 0-.364.112-.392.644-.672.867-.308.252-.812.056-1.035-.279-.364.279-.532.923-1.008.951-.448 0-.671-.532-.699-.979-.112-1.707.699-3.442.363-5.121-.335.251-.363.727-.419 1.147a4.842 4.842 0 0 1-1.288 2.686c-.112.112-.251.252-.391.252-.336 0-.448-.448-.42-.783.028-.924.168-1.819.42-2.715.475-1.651 1.287-3.246 1.259-4.981 0-.42-.084-.867-.42-1.091-.476-.364-1.147-.084-1.679.112a8.112 8.112 0 0 1-1.791.475c-.168 0-.336.028-.476-.028a.43.43 0 0 1-.251-.363.445.445 0 0 1 .195-.252c.308-.28.644-.56.952-.868.168-.168.364-.308.56-.448.279-.167.615-.251.951-.307 1.203-.252 2.407-.504 3.582-.896.532-.196 1.091-.42 1.595-.699 1.091-.588 2.099-1.568 3.106-2.323a761.429 761.429 0 0 0 5.737-4.422c1.091-.839 2.295-1.595 3.358-2.49.42-.364 1.371-1.119 1.847-1.931.28-.448.392-.895.196-1.259-.196-.336-.672-.952-.7-1.343 0-.14.084-.28.14-.42.084-.28.056-.56.112-.84a4.104 4.104 0 0 1 1.791-2.658 4.562 4.562 0 0 1 1.903-.672c.28-.028.588.056.867.028.308-.028.784-.252.98-.476.14-.167.252-.671.112-.895.168.224.308.448.448.672 1.091 1.679 2.042 3.526 2.714 5.428.644 1.568 1.343 3.582.168 5.093Z"
      fill="#ABE0F6"
    />
    <path
      d="M185.029 164.195c-.392.504-2.435 2.35-2.994 2.742-5.261 3.722-8.144 5.681-14.02 8.395-.784.364-1.428.616-1.679 1.512a20.93 20.93 0 0 1-.504 1.763c-.42 1.287-.896 2.574-1.035 3.917-.056.728-.028 1.455-.196 2.155-.14.644-.588 1.343-1.232 1.343-.14 0-.307-.028-.447 0v-.056c.14-3.162.727-6.296 1.763-9.29.307-.952.671-1.539 1.371-2.239a17.023 17.023 0 0 1 2.183-1.819c1.539-1.091 3.19-1.987 4.841-2.91a297.227 297.227 0 0 0 4.029-2.323c1.707-1.007 3.386-2.071 4.422-3.806.336-.559.783-1.735.308-2.35-.42-.504-1.484-.532-2.015-.364-.56.168-1.148.447-1.679.531-.56.084-1.204.168-1.819.084.28-.447.392-.895.196-1.259-.196-.336-.672-.951-.7-1.343 0-.14.084-.28.14-.42.084-.28.056-.56.112-.839a4.102 4.102 0 0 1 1.791-2.659 4.547 4.547 0 0 1 1.903-.671c.28-.028.588.056.867.028.308-.028.784-.252.98-.476.14-.168.252-.672.112-.896.168.224.308.448.447.672 1.092 1.679 2.043 3.526 2.715 5.429.616 1.623 1.315 3.638.14 5.149Z"
      fill="#71ADEE"
    />
    <path
      d="M183.098 154.681c.028.784-.112 1.512-.476 2.211-.391.756-.979 1.399-1.595 1.987l-.056.056c-.727.672-1.511 1.259-2.406 1.623-.98.392-1.819.588-2.743.084a9.77 9.77 0 0 1-.895-.56c-.56-.391-1.064-.839-1.595-1.287-1.12-.951-2.323-1.903-3.134-3.134-.868-1.343-1.232-2.994-1.288-4.617-.084-2.855.7-5.793 2.407-8.088l.028-.028c.728-.951 1.567-1.903 2.826-1.903.644 0 1.148.672 1.511 1.148 1.092 1.455 2.043 3.022 2.939 4.589.811 1.399 1.651 2.77 2.462 4.17.364.615.728 1.231 1.092 1.819.056.084.112.195.168.279.335.56.727.98.755 1.651Z"
      fill="#004EB6"
    />
    <path
      d="M182.174 152.723a9.485 9.485 0 0 1-1.707 3.75c-.056.056-.111.14-.167.196-.672-1.847-1.54-3.638-2.435-5.373-.951-1.847-1.931-3.722-3.218-5.345-.924-1.175-2.043-2.239-3.302-3.05.727-.952 1.567-1.903 2.826-1.903.644 0 1.147.672 1.511 1.147 1.092 1.456 2.043 3.023 2.939 4.59.811 1.399 1.651 2.77 2.462 4.169.364.616.7 1.204 1.091 1.819Z"
      fill="#2F455F"
    />
    <path
      d="M183.098 154.682c.028.784-.112 1.511-.476 2.211-.419.783-1.007 1.427-1.651 2.043-.727.671-1.511 1.259-2.406 1.623-.98.392-1.819.587-2.743.084a9.635 9.635 0 0 1-.895-.56c.364-.196.867-.252 1.259-.364a9.348 9.348 0 0 0 2.966-1.763 10.64 10.64 0 0 0 1.344-1.427 9.962 9.962 0 0 0 1.707-3.75c.056.084.111.196.167.28.308.532.7.952.728 1.623Z"
      fill="#004EB6"
    />
    <path
      d="M183.098 154.681c.028.783-.112 1.511-.476 2.211-.392.755-.979 1.399-1.595 1.986-.224-.755-.448-1.483-.727-2.182.056-.056.111-.14.167-.196a9.945 9.945 0 0 0 1.707-3.75c.056.084.112.196.168.28.336.56.728.979.756 1.651Z"
      fill="#2F455F"
    />
  </SvgIcon>
);

export default AppointeesIcon;
