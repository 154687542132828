import React from "react";
import SvgIcon from "./SvgIcon";

const CloseWhiteIcon = () => {
  return (
    <SvgIcon width="25px" height="24px" viewBox="0 0 25 24">
      <path
        d="M6 17.381L17.246 6"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.246 17.381L6 6"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CloseWhiteIcon;
