import R from "ramda";
import { select, takeEvery, call, put } from "redux-saga/effects";
import { initialize, getFormValues } from "redux-form";

import { updateCharities } from "../actions/charities";
import {
  FETCH_DONATIONS_TYPE,
  UPDATE_DONATIONS_TYPE,
} from "../actions/donations";
import { submitFormRequest } from "./forms";
import { fetchApiData } from "./requests";
import { fetchSuccess } from "../actions/requests";
import { getDonations, submitDonations } from "../api/donations";
import { selectHasLoaded } from "../selectors/requests";
import {
  selectDonationsForm,
  selectHasCharities,
} from "../selectors/donations";

import {
  DONATIONS_FORM_ID,
  DONATIONS_CUSTOM_CHARITY,
  DONATIONS_LIST_CHARITY,
  DONATIONS_FIELD_ID,
  REGISTERED_NAME_FIELD_ID,
  BUSINESS_NUMBER_FIELD_ID,
  CHARITY_ID_FIELD_ID,
  SHARE_DONATION_DETAIL_FIELD_ID,
} from "../constants/forms";

function* fetchDonations() {
  const hasLoaded = yield select(selectHasLoaded);
  const hasCharities = yield select(selectHasCharities);

  if (!hasLoaded || !hasCharities) {
    const { charities } = yield call(fetchApiData, {
      apiCall: getDonations,
      formId: DONATIONS_FORM_ID,
    });
    yield put(updateCharities(charities));
  }

  const donationsFormData = yield select(selectDonationsForm);
  yield put(initialize(DONATIONS_FORM_ID, donationsFormData));
  yield put(fetchSuccess(DONATIONS_FORM_ID));
}

const filterDonationsPayload = (donation) => {
  const { entryType } = donation;
  if (entryType === DONATIONS_LIST_CHARITY) {
    return R.omit(
      [REGISTERED_NAME_FIELD_ID, BUSINESS_NUMBER_FIELD_ID],
      donation,
    );
  }
  if (entryType === DONATIONS_CUSTOM_CHARITY) {
    return R.omit([CHARITY_ID_FIELD_ID], donation);
  }
  return donation;
};

function* updateDonations() {
  const formValues = yield select(getFormValues(DONATIONS_FORM_ID));
  const filteredDonations = formValues[DONATIONS_FIELD_ID].map(
    filterDonationsPayload,
  );
  const hasAnyDonations =
    filteredDonations.filter((d) => d._destroy !== true).length > 0;
  const isSharingDonationDetails =
    hasAnyDonations && formValues[SHARE_DONATION_DETAIL_FIELD_ID] === true;

  yield call(submitFormRequest, {
    apiCall: submitDonations,
    formId: DONATIONS_FORM_ID,
    values: {
      [DONATIONS_FIELD_ID]: filteredDonations,
      [SHARE_DONATION_DETAIL_FIELD_ID]: isSharingDonationDetails,
    },
  });
}

export function* watchFetchDonations() {
  yield takeEvery(FETCH_DONATIONS_TYPE, fetchDonations);
}

export function* watchUpdateDonations() {
  yield takeEvery(UPDATE_DONATIONS_TYPE, updateDonations);
}
