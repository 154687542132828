import React from "react";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import { errorMessageTranslations } from "../../selectors/translations";

import { SmallBody } from "./Typography";

export const ErrorMessageComponent = ({
  error,
  translations,
  shouldDisplayError = true,
  align = "left",
  color = "error",
  showHTMLErrorMessage = false,
}) => {
  if (shouldDisplayError && error) {
    return (
      <Box mt={0.5}>
        {showHTMLErrorMessage ? (
          <SmallBody
            align={align}
            color={color}
            dangerouslySetInnerHTML={{ __html: error }}
          />
        ) : (
          <SmallBody align={align} color={color}>
            {translations[error] || error}
          </SmallBody>
        )}
      </Box>
    );
  }
  return null;
};

export const ErrorMessage = connect(errorMessageTranslations)(
  ErrorMessageComponent,
);
