export const ADD_PERSON_ALLOCATION_SUCCESS = "allocations.addPersonSuccess";
export const ADD_CHARITY_ALLOCATION_SUCCESS = "allocations.addCharitySuccess";
export const ADD_PERSON_BACKUP_SUCCESS = "backups.addPersonSuccess";
export const ADD_CHARITY_BACKUP_SUCCESS = "backups.addCharitySuccess";
export const UPDATED_ALLOCATION_SUCCESS = "allocations.updatedSuccess";
export const REMOVED_ALLOCATION_SUCCESS = "allocations.removedSuccess";
export const ADD_EXECUTOR_SUCCESS = "executors.addExecutorSuccess";
export const EDIT_EXECUTOR_SUCCESS = "executors.editExecutorSuccess";
export const REMOVE_EXECUTOR_SUCCESS = "executors.removeExecutorSuccess";
export const REMOVE_CHILD_SUCCESS = "yourFamily.removeChildSuccess";
export const ADD_CHILD_SUCCESS = "yourFamily.addChildSuccess";
export const EDIT_CHILD_SUCCESS = "yourFamily.editChildSuccess";
export const ADD_SPOUSE_SUCCESS = "spouse.addSpouseSuccess";
export const EDIT_SPOUSE_SUCCESS = "spouse.editSpouseSuccess";
export const REMOVE_SPOUSE_SUCCESS = "spouse.removeSpouseSuccess";
export const ADD_PET_SUCCESS = "pets.addPetSuccess";
export const EDIT_PET_SUCCESS = "pets.editPetSuccess";
export const REMOVE_PET_SUCCESS = "pets.removePetSuccess";
export const ADD_CHILDREN_GUARDIAN_SUCCESS =
  "guardian.addChildrenGuardianSuccess";
export const EDIT_CHILDREN_GUARDIAN_SUCCESS =
  "guardian.editChildrenGuardianSuccess";
export const REMOVE_CHILDREN_GUARDIAN_SUCCESS =
  "guardian.removeChildrenGuardianSuccess";
export const ADD_CHILDREN_GUARDIAN_AND_SPOUSE_SUCCESS =
  "guardian.addChildrenGuardianAndSpouseSuccess";
export const EDIT_CHILDREN_GUARDIAN_AND_SPOUSE_SUCCESS =
  "guardian.editChildrenGuardianAndSpouseSuccess";
export const REMOVE_CHILDREN_GUARDIAN_AND_SPOUSE_SUCCESS =
  "guardian.removeChildrenGuardianAndSpouseSuccess";
export const ADD_PET_GUARDIAN_SUCCESS = "petGuardians.addPetGuardianSuccess";
export const EDIT_PET_GUARDIAN_SUCCESS = "petGuardians.editPetGuardianSuccess";
export const REMOVE_PET_GUARDIAN_SUCCESS =
  "petGuardians.removePetGuardianSuccess";
export const ADD_PROPERTY_ATTORNEY_SUCCESS =
  "propertyAttorneys.addPropertyAttorneySuccess";
export const EDIT_PROPERTY_ATTORNEY_SUCCESS =
  "propertyAttorneys.editPropertyAttorneySuccess";
export const REMOVE_PROPERTY_ATTORNEY_SUCCESS =
  "propertyAttorneys.removePropertyAttorneySuccess";
export const ADD_PERSONAL_ATTORNEY_SUCCESS =
  "personalAttorneys.addPersonalAttorneySuccess";
export const EDIT_PERSONAL_ATTORNEY_SUCCESS =
  "personalAttorneys.editPersonalAttorneySuccess";
export const REMOVE_PERSONAL_ATTORNEY_SUCCESS =
  "personalAttorneys.removePersonalAttorneySuccess";
export const ADD_CONTACT_SUCCESS = "contacts.addContactSuccess";
export const EDIT_CONTACT_SUCCESS = "contacts.editContactSuccess";
export const REMOVE_CONTACT_SUCCESS = "contacts.removeContactSuccess";
export const SEND_NOTIFY_CONTACT_SUCCESS = "contacts.sendNotifyContactSuccess";
