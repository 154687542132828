import React from "react";
import { reduxForm, FieldArray, getFormValues } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Form } from "../../ui/Form";
import { ErrorMessage } from "../../ui/Error";
import { PETS_FORM_ID, PETS_FIELD_ID } from "../../../constants/forms";
import PeopleCard from "../../ui/PeopleCard";
import { orderedFillColors } from "../../../utilities/allocations";
import { SecondaryButton, TextButton } from "../../ui/Button";
import { openModalPet, petsNextPage, removePet } from "../../../actions/pets";
import { displayTooltipModal } from "../../../actions/tooltip-modal";
import H2WithUnderlineLink from "../../ui/H2WithUnderlineLink";

export const PetCardFieldArray = ({ fields, translations, handleChange }) => {
  const dispatch = useDispatch();
  let colorIndex = 0;
  const allFields = fields.getAll() || [];
  const fieldsLength = allFields.filter((f) => f._destroy !== true).length;
  return (
    <Box>
      {fields.map((fieldId, index) => {
        const field = fields.get(index);
        if (field && field._destroy === true) {
          return null;
        }
        const speciesTranslations = translations.speciesOptions.find(
          (t) => t.value === field.species,
        );
        const color = orderedFillColors[colorIndex % orderedFillColors.length];
        colorIndex += 1;
        return (
          <Box mb={1} mt={2} key={`${index + 1}-${fieldId}`}>
            <PeopleCard
              id={fieldId}
              fieldId={fieldId}
              translations={translations}
              text={field.firstName}
              subText={speciesTranslations.label}
              color={color}
              onEdit={() => dispatch(openModalPet({ ...field, index }))}
              onRemove={() => {
                dispatch(removePet(field));
                return handleChange(`${fieldId}._destroy`, true);
              }}
              {...field}
            />
          </Box>
        );
      })}
      <SecondaryButton
        text={
          fieldsLength > 0
            ? translations.button.addMoreLabel
            : translations.button.addLabel
        }
        fullWidth
        displayPlusIcon
        className="qa-add"
        type="button"
        onClick={() => dispatch(openModalPet(null))}
      />
    </Box>
  );
};

const getPetsCount = (formData) => {
  if (!formData) {
    return 0;
  }
  return formData[PETS_FIELD_ID].filter((field) => field._destroy !== true)
    .length;
};

const PetsForm = ({
  error,
  handleSubmit,
  backLink,
  change,
  isLoading,
  translations,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isTablet = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const skipButtonOffset = () => {
    if (isDesktop) return 72;
    if (isTablet) return 0;
    if (isMobile) return 104;
    return 0;
  };

  const formData = useSelector(getFormValues(PETS_FORM_ID));
  const formDataLength = formData ? formData[PETS_FORM_ID].length : 0;
  const showZeroPetsNotice = formDataLength > 0;
  const hasNoPets = getPetsCount(formData) === 0;

  const headerWithLink = (
    <H2WithUnderlineLink
      text={translations.pageTitle}
      underlineText={translations.pageTitleUnderlineLink}
      onClick={() =>
        dispatch(displayTooltipModal(translations.pageTitleModalKey))
      }
    />
  );
  return (
    <>
      <Form
        qaFormId="pets"
        onSubmit={handleSubmit}
        backLink={backLink}
        isLoading={isLoading}
        translations={translations}
        disabled={hasNoPets}
        customHeaderComponent={headerWithLink}
      >
        <FieldArray
          rerenderOnEveryChange
          handleChange={change}
          name={PETS_FIELD_ID}
          component={PetCardFieldArray}
          translations={translations}
        />
        <Box mb={2}>
          <ErrorMessage error={error} />
        </Box>
      </Form>
      {hasNoPets && (
        <Box display="flex" flexDirection="column" justify="center" mb={8}>
          <TextButton
            style={{
              position: "relative",
              bottom: skipButtonOffset(),
            }}
            text={translations.noPetslink}
            onClick={() => dispatch(petsNextPage(showZeroPetsNotice))}
            className="qa-skip-pets"
          />
        </Box>
      )}
    </>
  );
};

export default reduxForm({
  form: PETS_FORM_ID,
})(PetsForm);
