import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";

import { selectTranslations } from "./translations";
import {
  SPOUSE_FORM_ID,
  CHILDREN_FORM_ID,
  CHILDREN_GUARDIANS_FORM_ID,
  PETS_FORM_ID,
  PET_GUARDIANS_FORM_ID,
  PET_TRUST_FORM_ID,
  MARITAL_STATUS_FIELD_ID,
  EMAIL_FIELD_ID,
  NUM_PETS_FIELD_ID,
  PET_GUARDIANS_FIELD_ID,
  PET_TRUST_AMOUNT_FIELD_ID,
  NUM_CHILDREN_FIELD_ID,
  CHILDREN_FIELD_ID,
  CHILDREN_GUARDIANS_FIELD_ID,
  MARITAL_STATUS_FORM_ID,
} from "../constants/forms";
import { yourFamilyForms } from "../constants/navigation";
import {
  selectIsSectionComplete,
  selectSectionPercentComplete,
  selectSectionCurrentPath,
  selectSectionTranslations,
  translateInput,
} from "./utils/section-summaries";
import {
  selectHasSpouse,
  selectIsSpouseComplete,
  selectSpouseCurrentPage,
  selectSpouseTotalPages,
  selectIsSpouseBasicInfoComplete,
} from "./spouse";
import {
  selectNeedsChildrenGuardians,
  selectIsChildrenComplete,
  selectChildrenCurrentPage,
  selectChildrenTotalPages,
} from "./children";
import {
  selectHasPets,
  selectPets,
  selectIsPetsComplete,
  selectPetsCurrentPage,
  selectPetsTotalPages,
  selectNeedsPetGuardian,
} from "./pets";
import { selectIsAboutYouComplete } from "./about-you";
import { selectIsFieldComplete } from "./utils/progress";
import { selectHasActivePlan } from "./plans";
import {
  IMPORTANT_NOTE_PATH,
  MARITAL_STATUS_PATH,
  PETS_PATH,
  PETS_TRUST_PATH,
} from "../constants/routes";
import { selectHasUserAgreedToTos } from "./auth";

// TODO: Figure out long-term solution for circular-dependency selector issue
const selectIsLoggedIn = R.pathOr(false, ["auth", "loggedIn"]);

const selectYourFamilyData = R.pathOr({}, ["yourFamily"]);
export const selectSpouse = R.path(["yourFamily", "spouse"]);

export const selectYourFamilyFieldsComplete = createSelector(
  selectIsSpouseComplete,
  selectIsChildrenComplete,
  selectIsPetsComplete,
  (...isCompleteValues) => isCompleteValues,
);

const selectYourFamilyIsCompleteMap = createSelector(
  selectIsFieldComplete(selectYourFamilyData, MARITAL_STATUS_FIELD_ID),
  selectIsSpouseBasicInfoComplete,
  selectIsFieldComplete(selectYourFamilyData, NUM_CHILDREN_FIELD_ID),
  selectIsFieldComplete(selectYourFamilyData, CHILDREN_FIELD_ID),
  selectIsFieldComplete(selectYourFamilyData, CHILDREN_GUARDIANS_FIELD_ID),
  selectIsFieldComplete(selectYourFamilyData, NUM_PETS_FIELD_ID),
  selectIsPetsComplete,
  selectIsFieldComplete(selectYourFamilyData, PET_GUARDIANS_FIELD_ID),
  selectIsFieldComplete(selectYourFamilyData, PET_TRUST_AMOUNT_FIELD_ID),
  (
    isMaritalStatusComplete,
    isSpouseBasicInfoComplete,
    isNumChildrenComplete,
    isChildrenComplete,
    isChildrenGuardiansComplete,
    isNumPetsComplete,
    isPetsComplete,
    isPetGuardiansComplete,
    isPetTrustComplete,
  ) => ({
    [MARITAL_STATUS_FORM_ID]: isMaritalStatusComplete,
    [SPOUSE_FORM_ID]: isSpouseBasicInfoComplete,
    [CHILDREN_FORM_ID]: isNumChildrenComplete || isChildrenComplete,
    [CHILDREN_GUARDIANS_FORM_ID]: isChildrenGuardiansComplete,
    [PETS_FORM_ID]: isNumPetsComplete || isPetsComplete,
    [PET_GUARDIANS_FORM_ID]: isPetGuardiansComplete,
    [PET_TRUST_FORM_ID]: isPetTrustComplete,
  }),
);

export const selectIsYourFamilyComplete = selectIsSectionComplete(
  selectYourFamilyFieldsComplete,
);

export const selectYourFamilyCurrentPageNumber = createSelector(
  selectSpouseCurrentPage,
  selectIsSpouseComplete,
  selectChildrenCurrentPage,
  selectIsChildrenComplete,
  selectPetsCurrentPage,
  (
    spousePagesComplete,
    spouseComplete,
    childrenPagesComplete,
    childrenComplete,
    petsPagesComplete,
  ) => {
    if (!spouseComplete) {
      // still in spouse section
      return spousePagesComplete;
    }
    // completed spouse section
    if (!childrenComplete) {
      // in children section
      return spousePagesComplete + childrenPagesComplete;
    }
    // completed spouse + children, so current page is total
    return spousePagesComplete + childrenPagesComplete + petsPagesComplete;
  },
);

export const selectYourFamilyTotalPages = createSelector(
  selectSpouseTotalPages,
  selectChildrenTotalPages,
  selectPetsTotalPages,
  (...totals) =>
    totals.reduce(
      (sectionTotal, summaryTotal) => sectionTotal + summaryTotal,
      0,
    ),
);

export const selectYourFamilyPercentComplete = selectSectionPercentComplete(
  selectYourFamilyCurrentPageNumber,
  selectYourFamilyTotalPages,
);

export const selectYourFamilyNextForm = R.path([
  "navigation",
  "nextForm",
  "yourFamily",
]);

export const selectYourFamilyCurrentPath = selectSectionCurrentPath(
  selectYourFamilyNextForm,
);

const spouseForms = [SPOUSE_FORM_ID];
const spouseFields = ["fullName", EMAIL_FIELD_ID];
const petGuardianForms = [PET_GUARDIANS_FORM_ID, PET_TRUST_FORM_ID];

const selectYourFamilyFormSummaries = createSelector(
  selectYourFamilyData,
  selectYourFamilyIsCompleteMap,
  selectHasSpouse,
  selectNeedsPetGuardian,
  selectNeedsChildrenGuardians,
  selectTranslations(["global", "navigation", "yourFamily"]),
  (
    yourFamilyData,
    isCompleteMap,
    hasSpouse,
    hasPetGuardian,
    needsChildrenGuardians,
    translations,
  ) => {
    const spouseData = R.propOr({}, "spouse", yourFamilyData);
    const formSummaries = yourFamilyForms.reduce((summaryList, currentForm) => {
      // Exclude certain form summaries if doesn't meet criteria
      if (spouseForms.includes(currentForm.id) && !hasSpouse) {
        return summaryList;
      }
      if (
        currentForm.id === CHILDREN_GUARDIANS_FORM_ID &&
        !needsChildrenGuardians
      ) {
        return summaryList;
      }

      if (petGuardianForms.includes(currentForm.id) && !hasPetGuardian) {
        return summaryList;
      }

      const fields = currentForm.fields.map(({ key }) => ({
        label: translations.summaryLabel[key],
        value: translateInput(
          key,
          spouseFields.includes(key) ? spouseData : yourFamilyData,
          translations,
        ),
      }));

      const formSummary = {
        path: currentForm.path,
        isComplete: isCompleteMap[currentForm.id],
        fields,
      };
      return [...summaryList, formSummary];
    }, []);
    return formSummaries.filter((form) => form.isComplete);
  },
);

const selectYourFamilyLastPage = createSelector(
  selectHasPets,
  selectPets,
  (hasPets, pets) => {
    const skippedPets = hasPets && pets.length === 0;

    if (skippedPets) {
      return PETS_PATH;
    }

    if (hasPets) {
      return PETS_TRUST_PATH;
    }

    return PETS_PATH;
  },
);

const selectIsYourFamilyDisabled = createSelector(
  selectIsAboutYouComplete,
  selectIsLoggedIn,
  selectHasActivePlan,
  (isAboutYouComplete, isLoggedIn, hasPlan) =>
    !(isAboutYouComplete && isLoggedIn && hasPlan),
);

const selectYourFamilyTranslations = selectSectionTranslations(
  selectIsYourFamilyComplete,
  selectYourFamilyCurrentPageNumber,
  ["global", "yourFamily"],
  selectYourFamilyTotalPages,
  selectIsYourFamilyDisabled,
);

const selectYourFamilyContinuePath = createSelector(
  selectHasUserAgreedToTos,
  (hasUserAgreedToTos) =>
    hasUserAgreedToTos ? MARITAL_STATUS_PATH : IMPORTANT_NOTE_PATH,
);

export const selectYourFamilyStatus = createStructuredSelector({
  sectionKey: () => "yourFamily",
  isComplete: selectIsYourFamilyComplete,
  progress: selectYourFamilyPercentComplete,
  continuePath: selectYourFamilyContinuePath,
  formSummaries: selectYourFamilyFormSummaries,
  disabled: selectIsYourFamilyDisabled,
  translations: selectYourFamilyTranslations,
  sectionLastPage: selectYourFamilyLastPage,
});
