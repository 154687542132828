import React, { useEffect } from "react";
import { connect } from "react-redux";

import PetGuardiansForm from "../../../components/forms/your-family/PetGuardiansForm";
import { petGuardiansPageProps } from "../../../selectors/pets";
import { PETS_PATH } from "../../../constants/routes";
import { getFormData } from "../../../actions/forms";
import { petGuardiansnNextPage } from "../../../actions/pet-guardians";
import { PET_GUARDIANS_FORM_ID } from "../../../constants/forms";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";

const PetGuardiansPage = ({
  getFormData,
  petGuardiansnNextPage,
  hasLoaded,
  ...restOfProps
}) => {
  useEffect(() => {
    getFormData(PET_GUARDIANS_FORM_ID);
  }, [getFormData]);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <PetGuardiansForm
      backLink={PETS_PATH}
      onSubmit={() => petGuardiansnNextPage()}
      {...restOfProps}
    />
  );
};

export default connect(petGuardiansPageProps, {
  getFormData,
  petGuardiansnNextPage,
})(PetGuardiansPage);
