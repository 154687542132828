import R from "ramda";
import { combineReducers } from "redux";

import {
  REVOKE_INVITE_TYPE,
  ADD_INVITED_TYPE,
  RESEND_INVITE_TYPE,
  RESEND_LOADING_COMPLETE_TYPE,
  REVOKE_LOADING_COMPLETE_TYPE,
  UPDATE_INVITES_TYPE,
} from "../actions/invites";
import { CLOSE_TOAST_TYPE } from "../actions/toast";
import { UPDATE_STATUS_TYPE } from "../actions/status";

const assignedUsers = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_INVITES_TYPE:
      return payload.assignedUsers;
    default:
      return state;
  }
};

const availableInvites = (state = 0, { type, payload }) => {
  switch (type) {
    case UPDATE_INVITES_TYPE:
      return payload.availableInvites;
    default:
      return state;
  }
};

const lastRevokedUser = (state = {}, { type, payload }) => {
  switch (type) {
    case REVOKE_INVITE_TYPE:
      return payload.user;
    case CLOSE_TOAST_TYPE:
      return {};
    default:
      return state;
  }
};

const lastInvitedUser = (state = {}, { type, payload }) => {
  switch (type) {
    case ADD_INVITED_TYPE:
      return payload.user;
    case CLOSE_TOAST_TYPE:
      return {};
    default:
      return state;
  }
};

const lastResentUser = (state = {}, { type, payload }) => {
  switch (type) {
    case RESEND_INVITE_TYPE:
      return payload.user;
    case CLOSE_TOAST_TYPE:
      return {};
    default:
      return state;
  }
};

const getUserId = R.pathOr("", ["user", "id"]);

const resendInviteLoading = (state = {}, { type, payload }) => {
  switch (type) {
    case RESEND_INVITE_TYPE:
      return {
        ...state,
        [getUserId(payload)]: true,
      };
    case RESEND_LOADING_COMPLETE_TYPE:
      return {
        ...state,
        [payload.userId]: false,
      };
    default:
      return state;
  }
};

const revokeInviteLoading = (state = {}, { type, payload }) => {
  switch (type) {
    case REVOKE_INVITE_TYPE:
      return {
        ...state,
        [getUserId(payload)]: true,
      };
    case REVOKE_LOADING_COMPLETE_TYPE:
      return {
        ...state,
        [payload.userId]: false,
      };
    default:
      return state;
  }
};

const userIsInvitee = (state = null, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return R.pathOr({}, ["user", "userIsInvitee"])(payload);
    default:
      return state;
  }
};

export default combineReducers({
  assignedUsers,
  availableInvites,
  lastResentUser,
  lastRevokedUser,
  lastInvitedUser,
  resendInviteLoading,
  revokeInviteLoading,
  userIsInvitee,
});
