import React from "react";

const YourEstateIcon = ({ width = 156, height = 105 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 156 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M78 104.712c43.078 0 78-23.23 78-51.888C156 24.167 121.078.936 78 .936S0 24.166 0 52.824c0 28.657 34.922 51.888 78 51.888Z"
      fill="#ECEDEF"
    />
    <path
      d="M82.553 78.802c31.726 0 57.445-15.708 57.445-35.084 0-19.375-25.719-35.083-57.445-35.083S25.107 24.343 25.107 43.72c0 19.375 25.72 35.083 57.446 35.083Z"
      fill="#2F455F"
    />
    <path
      d="M38.967 64.943c-18.881-11.985-18.279-30.598 1.74-42.114 20.02-11.582 52.223-11.917 72.979-1.004l-74.72 43.118Z"
      fill="#fff"
    />
    <path
      d="m113.686 21.758-15.132 8.704-59.588 34.414c-12.051-7.633-16.135-17.944-12.386-27.45.937-2.411 2.41-4.755 4.352-6.964 2.477-2.812 5.69-5.423 9.708-7.7 15.734-9.105 39.034-11.248 58.383-6.427 5.289 1.34 10.244 3.147 14.663 5.423Z"
      fill="#fff"
    />
    <path
      d="M38.966 51.017v13.859c-9.039-5.691-13.658-12.989-13.725-20.354V31.266c.067 2.075.535 4.15 1.339 6.16 2.008 4.954 6.16 9.64 12.386 13.59Z"
      fill="#5FFFF7"
    />
    <path
      d="M130.959 45.794c-.602 7.097-5.356 13.793-13.725 19.082v-13.86c8.302-5.289 13.123-11.984 13.725-19.08v13.858Z"
      fill="#B5A9FF"
    />
    <path
      d="M113.685 66.951c-20.085 10.58-51.018 10.58-71.17 0V53.092c4.419 2.344 9.373 4.151 14.595 5.424 13.324 3.347 28.59 3.347 41.98 0 5.222-1.273 10.177-3.147 14.595-5.424v13.86Z"
      fill="#F18DFF"
    />
    <path
      d="M38.966 51.017v13.859c-9.039-5.691-13.658-12.989-13.725-20.354V31.266c.067 2.075.535 4.15 1.339 6.16 2.008 4.954 6.16 9.64 12.386 13.59Z"
      fill="#71ADEE"
    />
    <path
      d="M113.685 7.899v13.86c-4.419-2.344-9.373-4.152-14.595-5.424-19.283-4.82-42.582-2.678-58.383 6.427-4.017 2.344-7.298 4.955-9.708 7.7-1.942 2.21-3.415 4.553-4.352 6.963-.804-2.008-1.272-4.084-1.34-6.16V29.66c0-.201 0-.47.068-.67.602-7.365 5.758-14.596 15.399-20.153C60.726-2.613 92.93-2.947 113.685 7.9Z"
      fill="#fff"
    />
    <path
      d="M113.685 7.899 99.09 16.335 38.966 51.016c-6.227-3.95-10.378-8.637-12.32-13.591-.803-2.009-1.271-4.084-1.338-6.16v-1.54c0-.2 0-.469.067-.67.602-7.364 5.758-14.595 15.399-20.152C60.726-2.613 92.93-2.947 113.685 7.899Z"
      fill="#0064DC"
    />
    <path
      d="M38.966 51.017v13.926l74.719-43.185V7.898l-74.72 43.118Z"
      fill="#004EB6"
    />
    <path
      d="M130.892 29.056v13.658c-.468-4.887-2.879-9.507-7.03-13.658-1.874-1.875-4.084-3.616-6.628-5.223V9.974c8.302 5.29 13.123 11.985 13.658 19.082ZM84.16 29.056v13.859l33.074-19.082V9.974L84.159 29.056Z"
      fill="#fff"
    />
    <path
      d="M117.234 23.834c8.302 5.289 13.123 11.984 13.725 19.081H84.226l33.008-19.081Z"
      fill="#fff"
    />
    <path d="M130.892 29.056H84.159v13.859h46.733v-13.86Z" fill="#3989E5" />
    <path
      d="M130.959 45.794c-.602 7.097-5.356 13.793-13.725 19.082L84.159 45.794h46.8Z"
      fill="#fff"
    />
    <path
      d="M130.959 45.794c-.602 7.097-5.356 13.793-13.725 19.082v-13.86c8.302-5.289 13.123-11.984 13.725-19.08v13.858Z"
      fill="#3989E5"
    />
    <path
      d="M84.16 31.935v13.86l33.074 19.148V51.017L84.159 31.935Z"
      fill="#0064DC"
    />
    <path
      d="m78.133 46.397 35.552 20.554c-20.085 10.579-51.018 10.579-71.17 0l35.618-20.554Z"
      fill="#fff"
    />
    <path
      d="M113.685 53.092c-4.418 2.343-9.373 4.151-14.595 5.423-13.324 3.348-28.59 3.348-41.98 0-5.222-1.272-10.176-3.08-14.595-5.423l1.272-.736 34.28-19.818 34.279 19.818 1.339.736Z"
      fill="#FFDC1E"
    />
    <path
      d="M113.685 66.951c-20.085 10.58-51.018 10.58-71.17 0V53.092c4.419 2.344 9.373 4.151 14.595 5.424 13.324 3.347 28.59 3.347 41.98 0 5.222-1.273 10.177-3.147 14.595-5.424v13.86Z"
      fill="#E39F3A"
    />
    <path
      d="M117.234 9.974c8.302 5.29 13.123 11.985 13.725 19.082H84.226l33.008-19.082Z"
      fill="#AAD2F6"
    />
    <path
      d="M130.892 31.935c-.602 7.097-5.356 13.792-13.725 19.082L84.092 31.935h46.8Z"
      fill="#71ADEE"
    />
  </svg>
);

export default YourEstateIcon;
