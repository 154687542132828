import React from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { submitLogin } from "../../actions/auth";
import {
  loginPageProps,
  selectEmailFromQueryParams,
} from "../../selectors/auth";

import LoginForm from "../../components/forms/LoginForm";

import {
  SIGNUP_PATH,
  FORGOT_PASSWORD_PATH,
  DASHBOARD_PATH,
} from "../../constants/routes";

const LoginPage = ({ submitLogin, isAuthenticated, ...restOfProps }) => {
  const email = useSelector(selectEmailFromQueryParams);

  if (isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: DASHBOARD_PATH,
        }}
      />
    );
  }

  return (
    <LoginForm
      onSubmit={submitLogin}
      getStartedLink={SIGNUP_PATH}
      forgotPasswordLink={FORGOT_PASSWORD_PATH}
      initialValues={{ email }}
      {...restOfProps}
    />
  );
};

export default connect(loginPageProps, { submitLogin })(LoginPage);
