import React from "react";
import { reduxForm, FieldArray, Field } from "redux-form";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import { Form } from "../../ui/Form";

import { FieldArrayRenderer } from "../../ui/inputs/FieldArrayRenderer";
import DonationFields from "../../ui/inputs/DonationFields";
import { ErrorMessage } from "../../ui/Error";
import FeaturedCharity from "../../ui/FeaturedCharities";

import {
  DONATIONS_FORM_ID,
  DONATIONS_FIELD_ID,
  ENTRY_TYPE_FIELD_ID,
  DONATIONS_LIST_CHARITY,
  SHARE_DONATION_DETAIL_FIELD_ID,
} from "../../../constants/forms";
import CheckboxInput from "../../ui/inputs/CheckboxInput";

const Donation = ({
  id,
  languageCode,
  charitiesOptionList,
  field,
  translations,
}) => (
  <DonationFields
    fieldName={id}
    showAmountField
    charitiesOptionList={charitiesOptionList}
    field={field}
    languageCode={languageCode}
    translations={translations}
  />
);

const DonationsForm = ({
  error,
  handleSubmit,
  backLink,
  onSkip,
  change,
  isFrenchSelected,
  languageCode,
  isLoading,
  charitiesOptionList,
  translations,
  hasDonations,
  featuredCharityName,
}) => {
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Form
      qaFormId="donations"
      onSubmit={handleSubmit}
      backLink={backLink}
      onSkip={onSkip}
      isLoading={isLoading}
      isFrenchSelected={isFrenchSelected}
      HeaderComponent={FeaturedCharity}
      featuredCharityName={featuredCharityName}
      translations={translations}
      reverseWrap={isTabletDown}
    >
      <FieldArray
        handleChange={change}
        name={DONATIONS_FIELD_ID}
        rerenderOnEveryChange
        component={FieldArrayRenderer}
        RenderedComponent={Donation}
        languageCode={languageCode}
        charitiesOptionList={charitiesOptionList}
        translations={translations}
        defaultFieldObject={{ [ENTRY_TYPE_FIELD_ID]: DONATIONS_LIST_CHARITY }}
      />
      <Box mb={2}>
        <ErrorMessage error={error} />
      </Box>
      {hasDonations && (
        <Box mb={2}>
          <Field
            component={CheckboxInput}
            name={SHARE_DONATION_DETAIL_FIELD_ID}
            label={translations.consentCheckboxLabel}
            type="checkbox"
            noMargin
            displayFlex
          />
        </Box>
      )}
    </Form>
  );
};

export default reduxForm({
  form: DONATIONS_FORM_ID,
})(DonationsForm);
