import React, { useEffect } from "react";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";

import { Box, Grid, Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import { PageLayoutWithPrevious } from "../../components/ui/PageLayout";
import { ButtonLink, TextButton } from "../../components/ui/Button";
import { Body, H1 } from "../../components/ui/Typography";
import { analyticsClickedCheckoutNow } from "../../actions/analytics";
import { fetchPlans } from "../../actions/plans";

import LoadingSpinner from "../../components/ui/LoadingSpinner";
import Plan from "../../components/plans/Plan";
import { premiumPlan } from "../../constants/plans";
import { CHECKOUT_PATH } from "../../constants/routes";
import { premiumPlanGuidanceProps } from "../../selectors/premium-plan-guidance";
import { RECOMMENDATION_FORM_ID } from "../../constants/forms";
import PreviewPDF from "../../components/ui/PreviewPDF";
import TrustIcons from "../../components/ui/icons/TrustIcons";

const PremiumPlanDocPreviewPage = ({
  fetchPlans,
  analyticsClickedCheckoutNow,
  goBack,
  hasLoaded,
  translations,
  price,
  strikeThroughPrice,
  isFrenchSelected,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    fetchPlans(RECOMMENDATION_FORM_ID);
  }, [fetchPlans]);

  if (!hasLoaded) {
    return <LoadingSpinner />;
  }

  return (
    <PageLayoutWithPrevious>
      <Box mx={2}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Grid item xl={8}>
            <TextButton
              text={translations.button.previous}
              onClick={goBack}
              displayArrowLeft
            />
            <H1>{translations.pageTitle}</H1>
            <Body align="center">{translations.description}</Body>
          </Grid>
        </Box>

        <Box
          display="flex"
          flexDirection={isDesktop ? "row" : "column-reverse"}
          justifyContent="center"
        >
          <Box mt={isDesktop ? 2.5 : 4.5} width={isDesktop ? "700px" : "100%"}>
            <PreviewPDF />
          </Box>
          <Box mt={isDesktop ? 0 : 3} ml={isDesktop ? 8.5 : 0}>
            <Plan
              analyticsClickedCheckoutNow={analyticsClickedCheckoutNow}
              symbol={premiumPlan.symbol}
              price={price}
              strikeThroughPrice={strikeThroughPrice}
              translations={translations}
              noMaxWidth={!isDesktop}
              noCta
            />
            <Box>
              <ButtonLink
                onClick={() => analyticsClickedCheckoutNow()}
                className="qa-checkout"
                to={CHECKOUT_PATH}
                text={translations.secureCheckoutButton}
                displayArrowRight
                fullWidth
              />
            </Box>
            <Box mt={1.5} textAlign="center">
              <Hidden mdDown>
                {/* Desktop */}
                <TrustIcons
                  isFrench={isFrenchSelected}
                  translations={translations}
                  shouldIncludeThirtyDay
                  shouldIncludeDragonsDen
                  centerLogos
                />
              </Hidden>
            </Box>
          </Box>
        </Box>
      </Box>
    </PageLayoutWithPrevious>
  );
};

export default connect(premiumPlanGuidanceProps, {
  fetchPlans,
  analyticsClickedCheckoutNow,
  goBack,
})(PremiumPlanDocPreviewPage);
