import { takeEvery, call, put } from "redux-saga/effects";

import {
  FETCH_RECEIPT_TYPE,
  UPDATE_RECEIPT_TYPE,
  updateReceipt,
} from "../actions/receipt";
import { getReceipt } from "../api/receipt";

import { fetchApiData } from "./requests";
import { fetchSuccess } from "../actions/requests";
import { CONFIRMATION_PAGE_ID } from "../constants/forms";
import { updateInvites } from "../actions/invites";

function* fetchReceipt() {
  const { order, assignedUsers, availableInvites } = yield call(fetchApiData, {
    apiCall: getReceipt,
    formId: CONFIRMATION_PAGE_ID,
  });

  yield put(updateReceipt(order));
  yield put(updateInvites(assignedUsers, availableInvites));
  yield put(fetchSuccess(CONFIRMATION_PAGE_ID));
}

export function* watchFetchReceipt() {
  yield takeEvery(FETCH_RECEIPT_TYPE, fetchReceipt);
}
export function* watchAddReceipt() {
  yield takeEvery(UPDATE_RECEIPT_TYPE, updateReceipt);
}
