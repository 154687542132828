import R from "ramda";
import React, { useEffect, useState } from "react";
import { reduxForm, FieldArray } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import { Form } from "../../ui/Form";
import BinaryRadioField from "../../ui/inputs/BinaryRadioField";
import { ErrorMessage } from "../../ui/Error";
import { Body } from "../../ui/Typography";
import { useValidateSpouseIsPropertyAttorneyMemo } from "../../ui/inputs/validations";
import {
  PROPERTY_ATTORNEYS_FORM_ID,
  PROPERTY_ATTORNEYS_FIELD_ID,
  SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
} from "../../../constants/forms";
import { removePropertyAttorney } from "../../../actions/property-attorneys";
import { SecondaryButton, SmallTextButton } from "../../ui/Button";
import H2WithUnderlineLink from "../../ui/H2WithUnderlineLink";
import { displayTooltipModal } from "../../../actions/tooltip-modal";
import { selectPropertyAttorneys } from "../../../selectors/property-attorneys";
import {
  selectAllContacts,
  selectSpouseContact,
} from "../../../selectors/contacts";
import {
  openModalContactForContactable,
  selectContactFromDropdown,
} from "../../../actions/contacts";
import { MODAL_ADD_CONTACT_PROPERTY_ATTORNEY } from "../../../constants/modal";
import { PROPERTY_ATTORNEY_CONTACTABLE_MODEL } from "../../../constants/contacts";
import DropdownContactSelect from "../../ui/inputs/DropdownContactSelect";
import { getAppointeeLevelTranslation } from "../../../utilities/appointees";

const PropertyAttorneyFields = ({
  translations,
  fields,
  contacts,
  allSelectedContacts,
  forceHidingRemoveButton,
  hasAppointeeTitle,
  subQuestionOptionalText,
  spouseContactId,
  handleChange,
  appointeeLevelIndexs,
  setIsWaitingSelection,
  isSpousePropertyAttorney,
  hasNoSelectedContacts,
}) => {
  const dispatch = useDispatch();
  let firstFieldFlag = true;

  return fields.map((fieldId, fieldIndex) => {
    const isFirstField = firstFieldFlag;
    firstFieldFlag = false;

    const field = fields.get(fieldIndex);
    if (field && field._destroy === true) {
      return null;
    }
    const fieldPropertyAttorneyId = field?.id;
    const fieldContactId = field?.contact?.id;
    const filteredContacts = contacts.filter((person) => {
      const contactId = person.contact.id;
      const keepSelectedContact = fieldContactId
        ? contactId === fieldContactId
        : false;
      return keepSelectedContact || !allSelectedContacts.includes(contactId);
    });

    const propertyAttorneyLevelText = getAppointeeLevelTranslation(
      appointeeLevelIndexs,
      fieldIndex,
      isSpousePropertyAttorney,
      translations,
    );
    const fieldLabel = hasAppointeeTitle
      ? `${propertyAttorneyLevelText} ${translations.appointeeTitle.toLowerCase()}`
      : "";

    const shouldShowSubQuestionOptional =
      isFirstField && !hasNoSelectedContacts && subQuestionOptionalText;

    const handleSelectContact = (contactId) => {
      dispatch(
        selectContactFromDropdown(
          contactId,
          fieldPropertyAttorneyId,
          PROPERTY_ATTORNEY_CONTACTABLE_MODEL,
        ),
      );
      setIsWaitingSelection(false);
    };

    const handleAddNewContact = () => {
      dispatch(
        openModalContactForContactable(
          null,
          MODAL_ADD_CONTACT_PROPERTY_ATTORNEY,
          fieldPropertyAttorneyId,
        ),
      );
    };

    const handleRemoveField = () => {
      if (field.id && fieldContactId) {
        dispatch(removePropertyAttorney(field));
      }
      handleChange(`${fieldId}._destroy`, true);
      return setIsWaitingSelection(false);
    };

    return (
      <Box mb={1} key={`${fieldIndex + 1}-${fieldId}`}>
        <DropdownContactSelect
          id={fieldId}
          fieldId={fieldId}
          label={fieldLabel}
          contacts={filteredContacts}
          disabledContactIds={[spouseContactId]}
          selectedContactId={fieldContactId}
          onSelect={handleSelectContact}
          onAddNew={handleAddNewContact}
        />
        <Box
          mt={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          {!forceHidingRemoveButton &&
            (isSpousePropertyAttorney || !isFirstField) && (
              <SmallTextButton
                onClick={handleRemoveField}
                text={translations.remove}
                className={`qa-remove-people-${fieldIndex}`}
              />
            )}
        </Box>
        {shouldShowSubQuestionOptional && (
          <Box my={2}>
            <Body
              dangerouslySetInnerHTML={{
                __html: subQuestionOptionalText,
              }}
            />
          </Box>
        )}
      </Box>
    );
  });
};

export const PropertyAttorneysDropdownFieldArray = ({
  fields,
  translations,
  handleChange,
  hasSpouse,
  isSpousePropertyAttorney,
  appointeeLevelIndexs,
  canAddMore,
  shouldShowCTAButton,
  hasNoSelectedContacts,
  isTabletDown,
  spouseContactId = null,
}) => {
  const [isWaitingSelection, setIsWaitingSelection] = useState(false);
  const contacts = useSelector(selectAllContacts);

  useEffect(() => {
    // when a new contact is added this will remove the waiting state
    setIsWaitingSelection(false);
  }, [contacts]);

  const allFields = fields.getAll() || [];
  const allSelectedContacts = allFields.reduce((arr, field) => {
    if (field.contact?.id) {
      return [...arr, field.contact?.id];
    }
    return arr;
  }, []);
  const validFields = allFields.filter((field) => field._destroy !== true);
  const hasValidFields = validFields.length > 0;

  const hasUserDecidedIfSpouseIsPOA =
    !hasSpouse || isSpousePropertyAttorney !== "";
  const hasNoAttorneysAndSpouseIsNotAttorney =
    hasNoSelectedContacts && !isSpousePropertyAttorney;
  const forceHidingAddMoreButton =
    (!hasSpouse && hasNoSelectedContacts) ||
    (hasValidFields && hasNoSelectedContacts) ||
    hasNoAttorneysAndSpouseIsNotAttorney;
  const forceHidingRemoveButton =
    hasNoSelectedContacts && hasValidFields && !isSpousePropertyAttorney;

  const shouldShowAddMoreButton =
    !forceHidingAddMoreButton &&
    canAddMore &&
    hasUserDecidedIfSpouseIsPOA &&
    !isWaitingSelection;

  const hasAppointeeTitle = R.has("total")(appointeeLevelIndexs);
  const addButtonLabel = hasValidFields
    ? translations.button.addMoreLabel
    : translations.button.addLabel;

  let subQuestionText = "";
  if (hasSpouse && shouldShowCTAButton) {
    subQuestionText = isSpousePropertyAttorney
      ? translations.subQuestionOptional
      : translations.subQuestion;
  }
  let subQuestionOptionalText = "";
  if (!hasSpouse || subQuestionText === translations.subQuestion) {
    subQuestionOptionalText = translations.subQuestionOptional;
  }

  // if user has no spouse and there are no fields yet, adds an empty new field
  if (!hasSpouse && fields.length === 0) {
    fields.push({});
  }

  if (hasSpouse && hasUserDecidedIfSpouseIsPOA) {
    // if the user has a spouse but the spouse is not selected as attorney,
    // adds an empty new field and hides the add more button
    if (!hasValidFields && hasNoAttorneysAndSpouseIsNotAttorney) {
      fields.push({});
    }
  }

  const handleAddMoreFields = () => {
    setIsWaitingSelection(true);
    fields.push({});
  };

  return (
    <Box>
      {subQuestionText && (
        <Box mb={2}>
          <Body
            dangerouslySetInnerHTML={{
              __html: subQuestionText,
            }}
          />
        </Box>
      )}
      <PropertyAttorneyFields
        translations={translations}
        fields={fields}
        contacts={contacts}
        allSelectedContacts={allSelectedContacts}
        forceHidingRemoveButton={forceHidingRemoveButton}
        hasAppointeeTitle={hasAppointeeTitle}
        subQuestionOptionalText={subQuestionOptionalText}
        spouseContactId={spouseContactId}
        handleChange={handleChange}
        appointeeLevelIndexs={appointeeLevelIndexs}
        setIsWaitingSelection={setIsWaitingSelection}
        isSpousePropertyAttorney={isSpousePropertyAttorney}
        hasNoSelectedContacts={hasNoSelectedContacts}
      />
      {shouldShowAddMoreButton && (
        <Box mt={2} mb={isTabletDown && 2}>
          <SecondaryButton
            text={addButtonLabel}
            fullWidth
            displayPlusIcon
            className="qa-add"
            type="button"
            onClick={handleAddMoreFields}
          />
        </Box>
      )}
    </Box>
  );
};

const PropertyAttorneysForm = ({
  error,
  handleSubmit,
  backLink,
  change,
  hasSpouse,
  appointeeLevelIndexs,
  spouseIsPropertyAttorney,
  isLoading,
  translations,
  tooltipKey,
  canAddMore,
  shouldShowCTAButton,
}) => {
  const dispatch = useDispatch();
  const {
    userSpousePropertyAttorneyQuestion,
    pageTitle,
    pageTitleUnderlineLink,
    pageTitleModalKey,
  } = translations;
  const validateSpouseIsPropertyAttorney = useValidateSpouseIsPropertyAttorneyMemo(
    translations,
  );

  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));
  const savedPropertyAttorneys = useSelector(selectPropertyAttorneys);
  const hasNoSelectedContacts =
    savedPropertyAttorneys.filter((e) => e.contact !== null).length === 0;
  const hasNoValidPOA = !spouseIsPropertyAttorney && hasNoSelectedContacts;

  const spouseContact = R.pathOr({}, "")(useSelector(selectSpouseContact))[0];
  const spouseContactId = spouseIsPropertyAttorney
    ? spouseContact?.contact?.id
    : null;
  let duplicatedSpouseError = "";
  if (spouseIsPropertyAttorney) {
    const contactIds = savedPropertyAttorneys.map((field) => field.contact?.id);
    // if user said that spouse is primary attorney but also selected spouse as attorney,
    // we will show an error message to guide the user to change their selection
    if (contactIds.includes(spouseContactId)) {
      duplicatedSpouseError = translations.duplicatedSpouseError;
    }
  }
  const shouldDislayError = error || duplicatedSpouseError;

  const shouldShowUnderlineLink = pageTitle.includes(pageTitleUnderlineLink);
  const headerWithLink = shouldShowUnderlineLink ? (
    <H2WithUnderlineLink
      text={pageTitle}
      underlineText={pageTitleUnderlineLink}
      onClick={() => dispatch(displayTooltipModal(pageTitleModalKey))}
    />
  ) : null;

  return (
    <Form
      isLoading={isLoading}
      onSubmit={handleSubmit}
      backLink={backLink}
      translations={translations}
      tooltipKey={tooltipKey}
      reverseWrap={isTabletDown}
      customHeaderComponent={headerWithLink}
      disabled={hasNoValidPOA || shouldDislayError}
    >
      {hasSpouse && (
        <>
          <Body data-hj-suppress>{userSpousePropertyAttorneyQuestion}</Body>
          <BinaryRadioField
            name={SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID}
            validate={validateSpouseIsPropertyAttorney}
            translations={translations}
          />
        </>
      )}
      <FieldArray
        rerenderOnEveryChange
        name={PROPERTY_ATTORNEYS_FIELD_ID}
        handleChange={change}
        component={PropertyAttorneysDropdownFieldArray}
        hasSpouse={hasSpouse}
        isSpousePropertyAttorney={spouseIsPropertyAttorney}
        appointeeLevelIndexs={appointeeLevelIndexs}
        translations={translations}
        canAddMore={canAddMore}
        shouldShowCTAButton={shouldShowCTAButton}
        isTabletDown={isTabletDown}
        hasNoSelectedContacts={hasNoSelectedContacts}
        spouseContactId={spouseContactId}
      />
      <Box mb={shouldDislayError && 2}>
        <ErrorMessage error={error || duplicatedSpouseError} />
      </Box>
    </Form>
  );
};

export default reduxForm({
  form: PROPERTY_ATTORNEYS_FORM_ID,
})(PropertyAttorneysForm);
