import R from "ramda";
import { select, takeEvery, call, put } from "redux-saga/effects";
import {
  initialize,
  change,
  getFormValues,
  startSubmit,
  stopSubmit,
} from "redux-form";

import {
  FETCH_PROPERTY_ATTORNEYS_TYPE,
  OPEN_MODAL_PROPERTY_ATTORNEY_TYPE,
  PROPERTY_ATTORNEYS_NEXT_PAGE_TYPE,
  propertyAttorneyUpdatedSuccessfully,
  REMOVE_PROPERTY_ATTORNEY_TYPE,
} from "../actions/property-attorneys";
import { submitFormRequest } from "./forms";
import { fetchApiData } from "./requests";
import { fetchSuccess } from "../actions/requests";
import {
  getPropertyAttorneysNextPage,
  removePropertyAttorneyEndpoint,
  submitPropertyAttorneys,
} from "../api/property-attorneys";
import {
  FIRST_NAME_FIELD_ID,
  LAST_NAME_FIELD_ID,
  MIDDLE_NAME_FIELD_ID,
  PROPERTY_ATTORNEY_MODAL_FORM_ID,
  PROPERTY_ATTORNEYS_FIELD_ID,
  PROPERTY_ATTORNEYS_FORM_ID,
} from "../constants/forms";
import { closeToast, displayToast } from "../actions/toast";
import {
  ADD_PROPERTY_ATTORNEY_SUCCESS,
  EDIT_PROPERTY_ATTORNEY_SUCCESS,
  REMOVE_PROPERTY_ATTORNEY_SUCCESS,
} from "../constants/toasts";
import { closeModal, displayModal } from "../actions/modal";
import {
  MODAL_ADD_PROPERTY_ATTORNEY,
  MODAL_EDIT_PROPERTY_ATTORNEY,
} from "../constants/modal";
import {
  selectLastSelectedPropertyAttorneyData,
  selectPropertyAttorneysForm,
} from "../selectors/property-attorneys";
import { DASHBOARD_ENDPOINT } from "../constants/routes";
import { getDashboard } from "../api/dashboard";

function* getSpouseIsPropertyAttorneyFormValue() {
  const formData = yield select(getFormValues(PROPERTY_ATTORNEYS_FORM_ID));
  return R.propOr(false, "spouseIsPropertyAttorney")(formData);
}

function* fetchPropertyAttorneys() {
  yield call(fetchApiData, {
    apiCall: getDashboard,
    DASHBOARD_ENDPOINT,
  });

  const formData = yield select(selectPropertyAttorneysForm);
  yield put(initialize(PROPERTY_ATTORNEYS_FORM_ID, formData));
  yield put(fetchSuccess(PROPERTY_ATTORNEYS_FORM_ID));
}

function* handleOpenModalPropertyAttorney({ payload }) {
  yield put(closeToast());
  const propertyAttorney = R.propOr(null, "propertyAttorney")(payload);
  if (propertyAttorney) {
    yield put(
      change(
        PROPERTY_ATTORNEY_MODAL_FORM_ID,
        PROPERTY_ATTORNEYS_FIELD_ID,
        propertyAttorney,
      ),
    );
    return yield put(displayModal(MODAL_EDIT_PROPERTY_ATTORNEY));
  }
  return yield put(displayModal(MODAL_ADD_PROPERTY_ATTORNEY));
}

export function* updatePropertyAttorneys(
  propertyAttorneyToUpdate,
  contactId = null,
) {
  const spouseIsPropertyAttorney = yield call(
    getSpouseIsPropertyAttorneyFormValue,
  );
  const { status, propertyAttorney } = yield call(submitFormRequest, {
    apiCall: submitPropertyAttorneys,
    formId: PROPERTY_ATTORNEYS_FORM_ID,
    values: {
      ...R.mapObjIndexed(R.defaultTo(null), propertyAttorneyToUpdate),
      spouseIsPropertyAttorney,
      contactId,
    },
  });
  if (status !== 200 || !propertyAttorney) {
    yield put(stopSubmit(PROPERTY_ATTORNEYS_FORM_ID));
    return null;
  }
  return propertyAttorney.id;
}

export function* handleAddOrEditPropertyAttorney(isEditing = false) {
  yield put(startSubmit(PROPERTY_ATTORNEY_MODAL_FORM_ID));
  try {
    const formData = yield select(
      getFormValues(PROPERTY_ATTORNEY_MODAL_FORM_ID),
    );
    const { propertyAttorneys } = formData;
    const newPropertyAttorney = {
      [FIRST_NAME_FIELD_ID]: propertyAttorneys.firstName || null,
      [MIDDLE_NAME_FIELD_ID]: propertyAttorneys.middleName || null,
      [LAST_NAME_FIELD_ID]: propertyAttorneys.lastName || null,
    };
    const updatedPropertyAttorneys = isEditing
      ? yield call(editPropertyAttorney, newPropertyAttorney)
      : yield call(addPropertyAttorney, newPropertyAttorney);
    yield put(
      change(
        PROPERTY_ATTORNEYS_FORM_ID,
        PROPERTY_ATTORNEYS_FIELD_ID,
        updatedPropertyAttorneys,
      ),
    );
    yield put(propertyAttorneyUpdatedSuccessfully(newPropertyAttorney));
    yield put(closeModal());
    yield put(
      displayToast(
        isEditing
          ? EDIT_PROPERTY_ATTORNEY_SUCCESS
          : ADD_PROPERTY_ATTORNEY_SUCCESS,
      ),
    );
  } catch (error) {
    yield put(stopSubmit(PROPERTY_ATTORNEY_MODAL_FORM_ID, error.formErrors));
  }
}

function* addPropertyAttorney(newPropertyAttorney) {
  const formData = yield select(getFormValues(PROPERTY_ATTORNEYS_FORM_ID));
  const { propertyAttorneys } = formData;

  const id = yield call(updatePropertyAttorneys, newPropertyAttorney);
  const updatedPropertyAttorney = { id, ...newPropertyAttorney };
  return [...propertyAttorneys, updatedPropertyAttorney];
}

function* editPropertyAttorney(newPropertyAttorney) {
  const lastSelectedPropertyAttorney = yield select(
    selectLastSelectedPropertyAttorneyData,
  );
  const { propertyAttorneys } = yield select(
    getFormValues(PROPERTY_ATTORNEYS_FORM_ID),
  );
  let updatedPropertyAttorney = {};
  const propertyAttorneysList = propertyAttorneys.map(
    (propertyAttorney, index) => {
      if (index === lastSelectedPropertyAttorney.index) {
        updatedPropertyAttorney = {
          ...newPropertyAttorney,
          id: propertyAttorney.id,
        };
        return updatedPropertyAttorney;
      }
      return propertyAttorney;
    },
  );
  yield call(updatePropertyAttorneys, updatedPropertyAttorney);

  return propertyAttorneysList;
}

export function* handleRemovePropertyAttorney({ payload }) {
  yield put(closeToast());
  const propertyAttorneyToRemove = R.propOr(null, "propertyAttorney")(payload);
  const spouseIsPropertyAttorney = yield call(
    getSpouseIsPropertyAttorneyFormValue,
  );
  yield call(submitFormRequest, {
    apiCall: removePropertyAttorneyEndpoint,
    values: {
      ...propertyAttorneyToRemove,
      spouseIsPropertyAttorney,
    },
  });
  // update the form data in the FE
  yield call(fetchPropertyAttorneys);
  yield put(displayToast(REMOVE_PROPERTY_ATTORNEY_SUCCESS));
}

function* handlePropertyAttorneyNextPage() {
  const { spouseIsPropertyAttorney } = yield select(
    getFormValues(PROPERTY_ATTORNEYS_FORM_ID),
  );
  return yield call(submitFormRequest, {
    apiCall: getPropertyAttorneysNextPage,
    formId: PROPERTY_ATTORNEYS_FORM_ID,
    values: { spouseIsPropertyAttorney },
  });
}

export function* watchFetchPropertyAttorneys() {
  yield takeEvery(FETCH_PROPERTY_ATTORNEYS_TYPE, fetchPropertyAttorneys);
}

export function* watchOpenModalPropertyAttorney() {
  yield takeEvery(
    OPEN_MODAL_PROPERTY_ATTORNEY_TYPE,
    handleOpenModalPropertyAttorney,
  );
}

export function* watchRemovePropertyAttorney() {
  yield takeEvery(REMOVE_PROPERTY_ATTORNEY_TYPE, handleRemovePropertyAttorney);
}

export function* watchPropertylAttorneyNextPage() {
  yield takeEvery(
    PROPERTY_ATTORNEYS_NEXT_PAGE_TYPE,
    handlePropertyAttorneyNextPage,
  );
}
