import { createSelector } from "reselect";
import R from "ramda";
import {
  selectIsUnlimitedUpdatesPartnerCampaignId,
  selectPartnerDiscountAmount,
  selectPartnerDiscountPartnerName,
  selectPartnerDiscountRemainingDays,
  selectPartnerDiscountType,
  selectPartnershipType,
  selectPlanNameInVolumePartnerDiscount,
} from "./partner-discount";
import { selectTranslations } from "./translations";
import { replaceDiscountAmountByDiscountType } from "./utils/discounts";

export const selectPartnerDiscountBannerTranslations = createSelector(
  selectTranslations(["banners"]),
  selectPlanNameInVolumePartnerDiscount,
  selectPartnerDiscountAmount,
  selectPartnerDiscountPartnerName,
  selectPartnershipType,
  selectPartnerDiscountType,
  selectPartnerDiscountRemainingDays,
  selectIsUnlimitedUpdatesPartnerCampaignId,
  (
    translations,
    planName,
    discountAmount,
    partnerName,
    partnershipType,
    discountType,
    remainingDays,
    isUnlimitedUpdatesPartnerCampaignId,
  ) => {
    const bannerTranslations = R.propOr(
      {},
      `${partnershipType}PartnerDiscount`,
      translations,
    );
    let body = "";
    switch (partnershipType) {
      case "volume":
        body = bannerTranslations.body
          .replace("PLAN_NAME", planName)
          .replace("PARTNER_NAME", partnerName)
          .replace("DISCOUNT_AMOUNT", discountAmount);

        return {
          ...bannerTranslations,
          body,
        };
      case "revshare":
        // if the partner campaign is in a special list of unlimited updates partners,
        // the banner will have an extra copy referencing the unlimited updates
        body = replaceDiscountAmountByDiscountType(
          discountType,
          discountAmount,
          isUnlimitedUpdatesPartnerCampaignId
            ? bannerTranslations.unlimitedUpdatesBody
            : bannerTranslations.body,
        )
          .replace("PARTNER_NAME", partnerName)
          .replace("REMAINING_DAYS", remainingDays);

        return {
          ...bannerTranslations,
          body,
        };
      default:
        return {};
    }
  },
);

export const selectLegacyUserBannerTranslations = createSelector(
  selectTranslations(["banners"]),
  (translations) => {
    return translations.legacyUserBanner;
  },
);
