import R from "ramda";
import { createSelector, createStructuredSelector } from "reselect";
import { selectPartnerCampaignId } from "./partner-discount";

const selectShouldDisplayRecommendationPage = createSelector(
  selectPartnerCampaignId,
  (currentPartnerCampaignId) => {
    // We show the recommendation page if:
    // 1- FEATURE_PLANS_RECO_PAGE is enabled
    // 2- Users with an associated partner campaign
    //    and that is not in the list of campaigns to hide
    if (window.env.FEATURE_PLANS_RECO_PAGE !== "enabled") {
      return false;
    }

    let shouldDisplayRecommendationPage = true;

    // If user has an associated partner campaign, we
    // check if it is in the list of campaigns to hide
    if (currentPartnerCampaignId) {
      const partnerCampaignList = R.pathOr("", [
        "env",
        "HIDE_RECO_PAGE_PARTNER_CAMPAIGN_IDS",
      ])(window);

      const formattedPartnerCampaignList = partnerCampaignList
        .split(",")
        .map((id) => parseInt(id, 10));
      shouldDisplayRecommendationPage = !formattedPartnerCampaignList.includes(
        currentPartnerCampaignId,
      );
    }

    return shouldDisplayRecommendationPage;
  },
);

export const routesProps = createStructuredSelector({
  shouldDisplayRecommendationPage: selectShouldDisplayRecommendationPage,
});
