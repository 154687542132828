import React, { useRef } from "react";
import { Box, Grid } from "@material-ui/core";
import styled from "styled-components";
import { SmallBody } from "../ui/Typography";
import CloseBlueIcon from "../ui/icons/CloseBlueIcon";
import { useKeyPress } from "../../utilities/hooks";

const StyledBanner = styled(Box)`
  background-color: #ffffff;
  width: 100%;
  border-bottom: 1px solid #0064dc;
  padding: 8px 8px 8px 32px;
`;

const CloseBox = styled(Box)`
  cursor: pointer;
  color: #0064dc;
  display: flex;
  align-content: center;
`;

const PartnerDiscountBanner = ({ isVisible, toggle, translations }) => {
  const handleOnClose = () => {
    toggle(false);
  };

  if (!isVisible) return null;

  const closeRef = useRef(null);

  if (useKeyPress("Enter")) {
    closeRef.current.click();
  }
  return (
    <StyledBanner role="banner">
      <Grid container>
        <Grid item xs="11">
          <Box role="note">
            <SmallBody
              align="left"
              component="span"
              color="willfulBlue"
              role="note"
              aria-label={translations.body.replace(/(<([^>]+)>)/gi, "")}
              tabIndex="0"
              dangerouslySetInnerHTML={{ __html: translations.body }}
            />
          </Box>
        </Grid>
        <Grid item xs="1">
          <Box
            justifyContent="center"
            display="flex"
            height="100%"
            alignItems="center"
          >
            <CloseBox
              textAlign="center"
              onClick={handleOnClose}
              role="button"
              aria-label="Close"
              tabIndex="0"
              ref={closeRef}
            >
              <CloseBlueIcon />
            </CloseBox>
          </Box>
        </Grid>
      </Grid>
    </StyledBanner>
  );
};
export default PartnerDiscountBanner;
