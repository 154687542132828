import React, { useEffect } from "react";
import { connect } from "react-redux";

import AshesForm from "../../../components/forms/your-arrangements/AshesForm";

import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { spouseAshesPageProps } from "../../../selectors/spouse-wishes";
import { SPOUSE_RESTING_PATH } from "../../../constants/routes";
import { ASHES_FORM_ID } from "../../../constants/forms";
import { updateAshes } from "../../../actions/wishes";
import { skipSection } from "../../../actions/navigation";
import { getFormData } from "../../../actions/forms";
import { skipWishes } from "../../../api/wishes";

const SpouseAshesPage = ({
  getFormData,
  updateAshes,
  hasLoaded,
  skipSection,
  ...restOfProps
}) => {
  useEffect(() => {
    getFormData(ASHES_FORM_ID);
  }, []);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <AshesForm
      backLink={SPOUSE_RESTING_PATH}
      onSubmit={() => updateAshes()}
      onSkip={() => skipSection(skipWishes, ASHES_FORM_ID, true)}
      {...restOfProps}
    />
  );
};

export default connect(spouseAshesPageProps, {
  getFormData,
  updateAshes,
  skipSection,
})(SpouseAshesPage);
