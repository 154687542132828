import React from "react";
import Box from "@material-ui/core/Box";

import { MiceType, SmallBody } from "./Typography";

const getStatusColors = (status) => {
  switch (status) {
    case "discount":
      return {
        paddingX: 0.5,
        textColor: "willfulGrey.main",
        backgroundColor: "willfulxxxLightBlue.main",
      };
    case "discountBlue":
      return {
        paddingX: 0.5,
        textColor: "white",
        backgroundColor: "willfulBlue.main",
      };
    case "incomplete":
    case "disabled":
    case "unpaid":
      return { textColor: "willfulGrey.main", backgroundColor: "gray.main" };
    case "complete":
    case "paid":
      return {
        textColor: "willfulBlue.main",
        backgroundColor: "willfulxxxLightBlue.main",
      };
    case "unscheduled":
    case "scheduled":
    case "completed":
    case "documentsIncomplete":
    default:
      return {
        textColor: "white",
        backgroundColor: "willfulBlueDark2.main",
      };
  }
};

const StatusLabel = ({ status, label, translations }) => {
  const { paddingX = 1, textColor, backgroundColor } = getStatusColors(status);
  return (
    <Box
      py={0.25}
      borderRadius="4px"
      width="fit-content"
      px={paddingX}
      color={textColor}
      bgcolor={backgroundColor}
    >
      {status === "discount" || status === "discountBlue" ? (
        <MiceType color={textColor} data-testid="status-label">
          {label || translations.statusLabel[status]}
        </MiceType>
      ) : (
        <SmallBody data-testid="status-label">
          {label || translations.statusLabel[status]}
        </SmallBody>
      )}
    </Box>
  );
};

export default StatusLabel;
