import R from "ramda";
import { createSelector, createStructuredSelector } from "reselect";

// TODO: Duplicated code due to circular dependency issue with provinces selector
export const selectLanguageCode = R.pathOr("en", ["aboutYou", "lang"]);
const selectProvinceCode = R.pathOr("", [
  "provinces",
  "selectedProvince",
  "code",
]);

export const selectIsFrenchSelected = createSelector(
  selectLanguageCode,
  (languageCode) => languageCode === "fr",
);

export const languageSelectorProps = createStructuredSelector({
  languageCode: selectLanguageCode,
  provinceCode: selectProvinceCode,
});
