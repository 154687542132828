import React, { useEffect } from "react";
import { connect } from "react-redux";

import PredeceaseForm from "../../../components/forms/your-estate/PredeceaseForm";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { predeceasePageProps } from "../../../selectors/allocations";
import { ALLOCATIONS_PATH } from "../../../constants/routes";
import { PREDECEASE_FORM_ID } from "../../../constants/forms";
import { updatePredecease } from "../../../actions/allocations";
import { getFormData } from "../../../actions/forms";

const PredeceasePage = ({
  getFormData,
  updatePredecease,
  hasLoaded,
  ...restOfProps
}) => {
  useEffect(() => {
    getFormData(PREDECEASE_FORM_ID);
  }, []);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <PredeceaseForm
      backLink={ALLOCATIONS_PATH}
      onSubmit={() => updatePredecease()}
      {...restOfProps}
    />
  );
};

export default connect(predeceasePageProps, {
  getFormData,
  updatePredecease,
})(PredeceasePage);
