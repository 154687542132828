export const getExecutorNameAndTitle = (translations, executor) => {
  if (!executor) {
    return null;
  }

  const { body, title, titleCoExecutor = "" } = translations;

  let newBody = body;
  let titleText = "";
  let namesText = "";

  const hasCoExecutor = Boolean(executor?.spouseFirstName);
  const executorName = `${executor.firstName} ${executor.middleName || ""} ${
    executor.lastName
  }`;

  if (hasCoExecutor) {
    const coExecutorName = `${executor.spouseFirstName} ${
      executor.spouseMiddleName || ""
    } ${executor.spouseLastName}`;
    namesText = `${executorName} & ${coExecutorName}`;
    titleText = titleCoExecutor;
  } else {
    namesText = executorName;
    titleText = title;
  }
  const isFromQuebec = body.includes("QUEBEC_TITLE");
  const isUsingFrench = body.includes("LIQUIDATOR_NAME");

  newBody = newBody.replace(
    isUsingFrench ? "LIQUIDATOR_NAME" : "EXECUTORS_NAMES",
    namesText,
  );

  if (!isUsingFrench) {
    newBody = newBody.replace(
      isFromQuebec ? "QUEBEC_TITLE" : "TITLE",
      titleText,
    );
  }

  return newBody;
};
