export const notifyAppointee = ({
  email,
  ccUser,
  role,
  id,
  isSpouse,
  firstName,
  lastName,
}) => ({
  type: NOTIFY_APPOINTEE_TYPE,
  payload: {
    email,
    ccUser,
    role,
    id,
    key: `${role}-${id}-${isSpouse}`,
    isSpouse,
    firstName,
    lastName,
  },
});

export const notifiedAppointee = ({ key, shouldClose }) => ({
  type: NOTIFIED_APPOINTEE_TYPE,
  payload: {
    key,
    shouldClose,
  },
});

export const toggleAppointeeCard = ({ key, shouldDisplay }) => ({
  type: TOGGLE_APPOINTEE_CARD_TYPE,
  payload: {
    key,
    shouldDisplay,
  },
});

export const NOTIFY_APPOINTEE_TYPE = "NOTIFY_APPOINTEE";
export const NOTIFIED_APPOINTEE_TYPE = "NOTIFIED_APPOINTEE";
export const TOGGLE_APPOINTEE_CARD_TYPE = "TOGGLE_APPOINTEE_CARD";
