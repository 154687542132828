export const requestStart = ({ type, meta }) => ({
  type: REQUEST_START_TYPE,
  meta: {
    requestType: type,
    ...meta,
  },
});
export const requestSuccess = ({ type, payload, meta }) => ({
  type: REQUEST_SUCCESS_TYPE,
  payload,
  meta: {
    requestType: type,
    ...meta,
  },
});
export const requestError = ({ type, payload, meta }) => ({
  type: REQUEST_ERROR_TYPE,
  payload,
  meta: {
    requestType: type,
    ...meta,
  },
});

export const clearLoadedData = (type) => ({
  type: REQUEST_CLEAR_TYPE,
  payload: {
    type,
  },
});

export const fetchSuccess = (type) => ({
  type: FETCH_SUCCESS_TYPE,
  meta: { requestType: type },
});

export const REQUEST_START_TYPE = "REQUEST_START";
export const REQUEST_SUCCESS_TYPE = "REQUEST_SUCCESS";
export const REQUEST_ERROR_TYPE = "REQUEST_ERROR";
export const REQUEST_CLEAR_TYPE = "REQUEST_CLEAR_TYPE";
export const FETCH_SUCCESS_TYPE = "FETCH_SUCCESS";
