export const addBeneficiary = () => ({
  type: ADD_BENEFICIARY_TYPE,
});

export const addBackupBeneficiary = () => ({
  type: ADD_BACKUP_BENEFICIARY_TYPE,
});

export const addPersonBeneficiary = () => ({
  type: BENEFICIARY_ADD_PERSON_TYPE,
});

export const addCharityBeneficiary = () => ({
  type: BENEFICIARY_ADD_CHARITY_TYPE,
});

export const addPersonAllocation = (person) => ({
  type: ADD_PERSON_ALLOCATION_TYPE,
  payload: {
    person,
  },
});

export const addPersonBackup = (person) => ({
  type: ADD_PERSON_BACKUP_TYPE,
  payload: {
    person,
  },
});

export const addCharityAllocationAction = (charity) => ({
  type: ADD_CHARITY_ALLOCATION_TYPE,
  payload: {
    charity,
  },
});

export const addCharityBackupAction = (charity) => ({
  type: ADD_CHARITY_BACKUP_TYPE,
  payload: {
    charity,
  },
});

export const cancelAddingBeneficiary = () => ({
  type: BENEFICIARY_CANCEL_ADDING_TYPE,
});

export const allocationUpdatedSuccessfully = (allocationName) => ({
  type: ALLOCATION_UPDATED_SUCCESSFULLY_TYPE,
  payload: {
    allocationName,
  },
});

export const allocationRemovedSuccessfully = (allocationName) => ({
  type: ALLOCATION_REMOVED_SUCCESSFULLY_TYPE,
  payload: {
    allocationName,
  },
});

export const ADD_BACKUP_BENEFICIARY_TYPE = "ADD_BACKUP_BENEFICIARY";
export const ADD_BENEFICIARY_TYPE = "ADD_BENEFICIARY";
export const BENEFICIARY_ADD_PERSON_TYPE = "BENEFICIARY_ADD_PERSON";
export const BENEFICIARY_ADD_CHARITY_TYPE = "BENEFICIARY_ADD_CHARITY";
export const BENEFICIARY_CANCEL_ADDING_TYPE = "BENEFICIARY_CANCEL_ADDING";
export const ADD_PERSON_ALLOCATION_TYPE = "ADD_PERSON_ALLOCATION";
export const ADD_PERSON_BACKUP_TYPE = "ADD_PERSON_BACKUP";
export const ADD_CHARITY_ALLOCATION_TYPE = "ADD_CHARITY_ALLOCATION";
export const ADD_CHARITY_BACKUP_TYPE = "ADD_CHARITY_BACKUP";
export const ALLOCATION_UPDATED_SUCCESSFULLY_TYPE =
  "ALLOCATION_UPDATED_SUCCESSFULLY";
export const ALLOCATION_REMOVED_SUCCESSFULLY_TYPE =
  "ALLOCATION_REMOVED_SUCCESSFULLY";
