import React from "react";
import SvgIcon from "./SvgIcon";

const CloseBlueIcon = () => {
  return (
    <SvgIcon width="20px" height="20px" viewBox="0 0 20 20">
      <path
        d="M5 14.4842L14.3717 5"
        stroke="#0064DC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3721 14.4842L5.0004 5"
        stroke="#0064DC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CloseBlueIcon;
