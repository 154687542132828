import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { selectTranslations } from "./translations";
import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";
import { SIGNUP_FORM_ID, VALIDATE_INVITE_TOKEN_ID } from "../constants/forms";
import { selectInviteToken } from "./auth";
import { selectAvailableProvinces } from "./provinces";

export const claimInvitePageProps = createStructuredSelector({
  isLoading: selectIsLoadingById(SIGNUP_FORM_ID),
  hasLoaded: selectHasLoadedDataById(VALIDATE_INVITE_TOKEN_ID),
  provinces: selectAvailableProvinces,
  translations: selectTranslations(["global", "signup", "claimInvite"]),
});

export const selectIsInvitedUser = createSelector(
  selectInviteToken,
  (token) => !!token,
);

export const selectUserIsInvitee = R.pathOr(false, [
  "invites",
  "userIsInvitee",
]);

export const invalidInvitePageProps = createStructuredSelector({
  translations: selectTranslations(["global", "invalidInvite"]),
});

export const selectResendLoadingUsers = R.pathOr(false, [
  "invites",
  "resendInviteLoading",
]);

export const selectRevokeLoadingUsers = R.pathOr(false, [
  "invites",
  "revokeInviteLoading",
]);
