import React, { useState } from "react";

import { Box } from "@material-ui/core";
import { PrimaryButton, SecondaryButton, TextButton } from "./Button";
import { H3, Body } from "./Typography";
import TextInput from "./inputs/TextInput";
import CheckboxInput from "./inputs/CheckboxInput";
import { validateEmail } from "./inputs/validations";

const getAppointeeTitle = (levels, roleLabels, role, index) => {
  const appointeeLevel = levels[index];
  const appointeeDescription = roleLabels[role].replace(
    "LEVEL",
    appointeeLevel,
  );

  return appointeeDescription;
};

const AppointeeCard = ({
  appointeeKey,
  firstName,
  lastName,
  id,
  index,
  isSpouse = false,
  isLoading,
  isCardOpen,
  setIsCardOpen,
  role,
  translations,
  onPreviewEmail,
  onNotifyAppointee,
}) => {
  return (
    <Box
      borderRadius="2px"
      borderColor="border.main"
      border={1}
      bgcolor="white"
      p={1.5}
      mb={1.5}
    >
      <Box mb={0.5} display="flex" justifyContent="space-between">
        <H3 align="left" data-hj-suppress>
          {firstName} {lastName}
        </H3>
      </Box>
      <Box mb={2}>
        <Body data-hj-suppress>
          {getAppointeeTitle(
            translations.levels,
            translations.roleLabels,
            role,
            index,
          )}
        </Body>
      </Box>

      {isCardOpen ? (
        <Box>
          <NotificationDetails
            translations={translations}
            setIsCardOpen={setIsCardOpen}
            isLoading={isLoading}
            appointeeKey={appointeeKey}
            handlePreviewEmail={() =>
              onPreviewEmail({
                key: role,
                appointeeFirstName: firstName,
                appointeeLevel: index,
              })
            }
            handleNotifyAppointee={(email, ccUser) => {
              onNotifyAppointee({
                email,
                ccUser,
                role,
                id,
                isSpouse,
                firstName,
                lastName,
              });
            }}
          />
        </Box>
      ) : (
        <SecondaryButton
          fullWidth
          text={translations.button.notify}
          onClick={() => setIsCardOpen(!isCardOpen)}
        />
      )}
    </Box>
  );
};

const emailValidations = (validations, value) => {
  return validations.reduce((lastError, validationRule) => {
    const validationError = validationRule(value);
    if (validationError) {
      return validationError;
    }
    return lastError;
  }, "");
};

const NotificationDetails = ({
  appointeeKey,
  translations,
  setIsCardOpen,
  isLoading,
  handlePreviewEmail,
  handleNotifyAppointee,
}) => {
  const [email, setEmail] = useState("");
  const [checked, setChecked] = useState(false);
  const [touched, setTouched] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const validations = validateEmail(translations);

  const handleEmailOnChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  };

  const handleCheckboxOnChange = () => {
    setChecked(!checked);
  };

  const handleEmailOnBlur = (event) => {
    const emailValue = event.target.value;
    const error = emailValidations(validations, emailValue);

    setTouched(true);
    if (error) {
      return setErrorMessage(error);
    }
    return setErrorMessage("");
  };

  const handleSubmit = (e) => {
    // Prevent the form from submitting and reloading the page
    e.preventDefault();

    const error = emailValidations(validations, email);
    setTouched(true);
    if (error) {
      return setErrorMessage(error);
    }
    return handleNotifyAppointee(email, checked);
    // TODO: set loading /disabled state ?
  };

  const emailFieldProps = {
    name: `email-${appointeeKey}`,
    input: {
      value: email,
      onChange: handleEmailOnChange,
      onBlur: handleEmailOnBlur,
    },
    meta: {
      touched,
      dirty: false,
      active: false,
      error: errorMessage,
    },
  };
  const checkboxFieldProps = {
    input: {
      name: `checkbox-${appointeeKey}`,
      value: checked,
      onChange: handleCheckboxOnChange,
    },
    meta: { dirty: false },
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column">
        <Box mb={1}>
          <TextInput
            {...emailFieldProps}
            type="email"
            label={translations.label.email}
            placeholder={translations.label.emailTheirPlaceholder}
            noMargin
          />
        </Box>
        <Box mb={2}>
          <CheckboxInput
            {...checkboxFieldProps}
            label={translations.label.appointeeCCLabel}
            noMargin
          />
        </Box>
        <Box display="flex" justifyContent="center" mb={1}>
          <TextButton
            align="center"
            text={translations.button.previewEmail}
            onClick={handlePreviewEmail}
          />
        </Box>
        <PrimaryButton
          text={translations.button.submit}
          isLoading={isLoading}
          loadingLabel={translations.button.loadingLabel}
          type="submit"
          fullWidth
        />
        <Box display="flex" justifyContent="center" mt={1}>
          <TextButton
            align="center"
            text={translations.button.cancel}
            onClick={() => setIsCardOpen(false)}
          />
        </Box>
      </Box>
    </form>
  );
};

export default AppointeeCard;
