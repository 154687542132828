import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  fetchPersonalAttorneys,
  personalAttorneysNextPage,
} from "../../../actions/personal-attorneys";
import { personalAttorneysPageProps } from "../../../selectors/personal-attorneys";

import PersonalAttorneysForm from "../../../components/forms/your-arrangements/PersonalAttorneysForm";
import { PROPERTY_ATTORNEYS_PATH } from "../../../constants/routes";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";

const PersonalAttorneysPage = ({
  fetchPersonalAttorneys,
  hasLoaded,
  personalAttorneysNextPage,
  ...restOfProps
}) => {
  useEffect(() => {
    fetchPersonalAttorneys();
  }, [fetchPersonalAttorneys]);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <PersonalAttorneysForm
      backLink={PROPERTY_ATTORNEYS_PATH}
      onSubmit={() => personalAttorneysNextPage()}
      {...restOfProps}
    />
  );
};

export default connect(personalAttorneysPageProps, {
  fetchPersonalAttorneys,
  personalAttorneysNextPage,
})(PersonalAttorneysPage);
