import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";

import { spouseArrangementsForms } from "../constants/navigation";
import {
  selectIsSectionComplete,
  selectSectionCurrentPageNumber,
  selectSectionPercentComplete,
  selectSectionCurrentPath,
  selectSectionTranslations,
  translateInput,
  translateLabel,
} from "./utils/section-summaries";
import { selectIsAboutYouComplete } from "./about-you";
import { selectIsYourFamilyComplete } from "./your-family";
import { selectTranslations } from "./translations";
import {
  selectHasSpouseCremationResting,
  selectIsSpouseRestingComplete,
  selectIsSpouseAshesComplete,
  selectIsSpouseCeremonyComplete,
} from "./spouse-wishes";
import { selectIsSpouseExecutorComplete } from "./spouse-executors";
import { selectIsSpousePropertyAttorneysComplete } from "./spouse-property-attorneys";
import { selectIsSpousePersonalAttorneysComplete } from "./spouse-personal-attorneys";
import {
  selectIsSpouseLifeProlongComplete,
  selectIsSpousePainManageComplete,
} from "./spouse-healthcare";
import {
  ASHES_FORM_ID,
  EXECUTORS_FORM_ID,
  RESTING_FORM_ID,
  CEREMONY_FORM_ID,
  PROPERTY_ATTORNEYS_FORM_ID,
  PERSONAL_ATTORNEYS_FORM_ID,
  PAIN_MANAGE_FORM_ID,
  LIFE_PROLONG_FORM_ID,
} from "../constants/forms";
import { OVERVIEW_SPOUSE_ARRANGEMENTS_PATH } from "../constants/routes";

const selectSpouseArrangementsData = R.pathOr({}, ["spouseArrangements"]);

export const selectSpouseArrangementsTotalPages = createSelector(
  selectHasSpouseCremationResting,
  (hasAshesStep) => {
    if (hasAshesStep) {
      return 8;
    }
    return 7;
  },
);

const selectSpouseArrangementsFieldsComplete = createSelector(
  selectHasSpouseCremationResting,
  selectIsSpouseExecutorComplete,
  selectIsSpouseRestingComplete,
  selectIsSpouseAshesComplete,
  selectIsSpouseCeremonyComplete,
  selectIsSpousePropertyAttorneysComplete,
  selectIsSpousePersonalAttorneysComplete,
  selectIsSpouseLifeProlongComplete,
  selectIsSpousePainManageComplete,
  (
    hasAshesStep,
    isExecutorComplete,
    isRestingComplete,
    isAshesComplete,
    ...restValues
  ) => {
    if (hasAshesStep) {
      return [
        isExecutorComplete,
        isRestingComplete,
        isAshesComplete,
        ...restValues,
      ];
    }
    return [isExecutorComplete, isRestingComplete, ...restValues];
  },
);

const selectSpouseArrangementsIsCompleteMap = createSelector(
  selectIsSpouseExecutorComplete,
  selectIsSpouseRestingComplete,
  selectIsSpouseAshesComplete,
  selectIsSpouseCeremonyComplete,
  selectIsSpousePropertyAttorneysComplete,
  selectIsSpousePersonalAttorneysComplete,
  selectIsSpouseLifeProlongComplete,
  selectIsSpousePainManageComplete,
  (
    isSpouseExecutorComplete,
    isSpouseRestingComplete,
    isSpouseAshesComplete,
    isSpouseCeremonyComplete,
    isSpousePropertyAttorneysComplete,
    isSpousePersonalAttorneysComplete,
    isSpouseLifeProlongComplete,
    isSpousePainManageComplete,
  ) => ({
    [EXECUTORS_FORM_ID]: isSpouseExecutorComplete,
    [RESTING_FORM_ID]: isSpouseRestingComplete,
    [ASHES_FORM_ID]: isSpouseAshesComplete,
    [CEREMONY_FORM_ID]: isSpouseCeremonyComplete,
    [PROPERTY_ATTORNEYS_FORM_ID]: isSpousePropertyAttorneysComplete,
    [PERSONAL_ATTORNEYS_FORM_ID]: isSpousePersonalAttorneysComplete,
    [LIFE_PROLONG_FORM_ID]: isSpouseLifeProlongComplete,
    [PAIN_MANAGE_FORM_ID]: isSpousePainManageComplete,
  }),
);

export const selectIsSpouseArrangementsComplete = selectIsSectionComplete(
  selectSpouseArrangementsFieldsComplete,
);

export const selectSpouseArrangementsCurrentPageNumber = selectSectionCurrentPageNumber(
  selectSpouseArrangementsFieldsComplete,
);

export const selectSpouseArrangementsPercentComplete = selectSectionPercentComplete(
  selectSpouseArrangementsCurrentPageNumber,
  selectSpouseArrangementsTotalPages,
);

export const selectSpouseArrangementsNextForm = R.path([
  "navigation",
  "nextForm",
  "spouseArrangements",
]);

export const selectSpouseArrangementsCurrentPath = selectSectionCurrentPath(
  selectSpouseArrangementsNextForm,
);

const selectIsSpouseArrangementsDisabled = createSelector(
  selectIsAboutYouComplete,
  selectIsYourFamilyComplete,
  (isAboutYouComplete, isYourFamilyComplete) =>
    !(isAboutYouComplete && isYourFamilyComplete),
);

export const selectSpouseArrangementsFormSummaries = createSelector(
  selectSpouseArrangementsData,
  selectSpouseArrangementsIsCompleteMap,
  selectHasSpouseCremationResting,
  selectTranslations([
    "global",
    "navigation",
    "yourArrangements",
    "spouseArrangements",
    "appointees",
  ]),
  (spouseArrangementsData, isCompleteMap, hasAshesForm, translations) => {
    const formSummaries = spouseArrangementsForms.reduce(
      (summaryList, currentForm) => {
        // if cremation ashes not selected then skip form
        if (currentForm.id === ASHES_FORM_ID && !hasAshesForm) {
          return summaryList;
        }

        const fields = currentForm.fields.reduce(
          (currentFormFields, field) => [
            ...currentFormFields,
            {
              label: translateLabel(field.key, translations),
              value: translateInput(
                field.key,
                spouseArrangementsData,
                translations,
              ),
            },
          ],
          [],
        );

        const formSummary = {
          path: currentForm.path,
          isComplete: isCompleteMap[currentForm.id],
          fields,
        };

        return [...summaryList, formSummary];
      },
      [],
    );

    return formSummaries.filter((form) => form.isComplete);
  },
);

export const selectSpouseArrangementsTranslations = selectSectionTranslations(
  selectIsSpouseArrangementsComplete,
  selectSpouseArrangementsCurrentPageNumber,
  ["global", "spouseArrangements"],
  selectSpouseArrangementsTotalPages,
  selectIsSpouseArrangementsDisabled,
);

export const selectSpouseArrangementsStatus = createStructuredSelector({
  sectionKey: () => "spouseArrangements",
  isComplete: selectIsSpouseArrangementsComplete,
  progress: selectSpouseArrangementsPercentComplete,
  continuePath: () => OVERVIEW_SPOUSE_ARRANGEMENTS_PATH,
  formSummaries: selectSpouseArrangementsFormSummaries,
  disabled: selectIsSpouseArrangementsDisabled,
  translations: selectSpouseArrangementsTranslations,
  sectionLastPage: () => "/spouse-pain-manage",
});
