import React from "react";
import SvgIcon from "./SvgIcon";

const WillfulLogo = () => (
  <SvgIcon viewBox="0 0 153 62" fill="white">
    <g>
      <path
        d="M53,23.9h-7.7v0h-8.2c-0.4,0-0.7,0.2-0.8,0.6l-3.8,13.9l-3.8-14c-0.1-0.3-0.4-0.5-0.7-0.5h-4.8c-0.3,0-0.6,0.2-0.7,0.5
		l-3.8,14l-3.8-13.9c-0.1-0.4-0.4-0.6-0.8-0.6H6.8c-0.3,0-0.6,0.3-0.5,0.7l7.8,24.5c0.1,0.3,0.4,0.6,0.8,0.6h6.4
		c0.4,0,0.7-0.3,0.8-0.6l3.6-13.9l3.6,13.9c0.1,0.4,0.4,0.6,0.8,0.6h6.4c0.4,0,0.7-0.2,0.8-0.6l6.9-21.6c0.1-0.2,0.4-0.2,0.4,0.1
		v21.7c0,0.3,0.2,0.5,0.5,0.5h8c0.3,0,0.5-0.2,0.5-0.5V24.4C53.5,24.1,53.2,23.9,53,23.9z"
      />
      <path
        d="M65.7,12.4h-8c-0.3,0-0.5,0.2-0.5,0.5v36.3c0,0.3,0.2,0.5,0.5,0.5h8c0.3,0,0.5-0.2,0.5-0.5V12.9
		C66.2,12.6,65.9,12.4,65.7,12.4z"
      />
      <path
        d="M78.4,12.4h-8c-0.3,0-0.5,0.2-0.5,0.5v36.3c0,0.3,0.2,0.5,0.5,0.5h8c0.3,0,0.5-0.2,0.5-0.5V12.9
		C78.9,12.6,78.6,12.4,78.4,12.4z"
      />
      <path
        d="M134.1,12.4h-8c-0.3,0-0.5,0.2-0.5,0.5v36.3c0,0.3,0.2,0.5,0.5,0.5h8c0.3,0,0.5-0.2,0.5-0.5V12.9
		C134.6,12.6,134.4,12.4,134.1,12.4z"
      />
      <path
        d="M121.4,23.9h-8c-0.3,0-0.5,0.2-0.5,0.5v14.3c0,1.9-1.4,3.5-3.3,3.6c-2.1,0.1-3.8-1.5-3.8-3.5V24.4c0-0.3-0.2-0.5-0.5-0.5
		h-8.1h-0.4h-5.4v-1.6c0-1.7,1.4-3.2,3.1-3.2c0.5,0,1,0.1,1.5,0.3c0.3,0.1,0.6,0,0.6-0.4v-5.8c0-0.3-0.2-0.6-0.5-0.7
		c-1.2-0.5-2.4-0.7-3.8-0.7c-5.5,0-9.9,4.7-9.9,10.2l0,27.2c0,0.3,0.2,0.5,0.5,0.5h8c0.3,0,0.5-0.2,0.5-0.5V30.9h5.3c0,0,0,0,0.1,0
		v9.8c0,5.1,4.2,9.5,9.3,9.5c2.7,0,5-1.1,6.7-2.9v1.9c0,0.3,0.2,0.5,0.5,0.5h8c0.3,0,0.5-0.2,0.5-0.5V24.4
		C121.9,24.1,121.7,23.9,121.4,23.9z"
      />
      <circle cx="49" cy="16.5" r="4.7" />
      <circle cx="142.1" cy="45.2" r="4.7" />
    </g>
  </SvgIcon>
);

export default WillfulLogo;
