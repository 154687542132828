export const updateUser = (formId, values = null) => ({
  type: UPDATE_USER_TYPE,
  payload: { formId, values },
});

export const updateAgreedToTos = (continueLink) => ({
  type: USER_AGREED_TO_TOS_TYPE,
  payload: { continueLink },
});

export const UPDATE_USER_TYPE = "UPDATE_USER";
export const USER_AGREED_TO_TOS_TYPE = "USER_AGREED_TO_TOS";
