import React from "react";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { Body } from "../Typography";

const StrikeThroughBody = styled(Body)`
  text-decoration: line-through;
`;

const OptionListItem = styled("span")`
  width: 100%;
`;

export const transformBundleOptionList = (optionList = []) => {
  return optionList.map((option) => {
    const node = (
      <OptionListItem>
        <Body component="span" bold>
          {option.label}
        </Body>
        <Box component="span" pl={0.25}>
          <StrikeThroughBody color="secondary" component="span">
            <Typography variant="srOnly">{option.srOnlyCopy}</Typography>
            {option.labelStrikeThrough}
          </StrikeThroughBody>
        </Box>
      </OptionListItem>
    );
    return { ...option, node };
  });
};
