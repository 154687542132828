import R from "ramda";
import { identifyUser, reset, trackEvent } from "@redux-beacon/segment";
import ensure from "@redux-beacon/ensure";

import {
  ANALYTICS_LOGIN_TYPE,
  ANALYTICS_LOGOUT_TYPE,
  ANALYTICS_SIGNUP_TYPE,
  ANALYTICS_VIEW_PAGE_TYPE,
  ANALYTICS_REFERRAL_VISIT_TYPE,
  ANALYTICS_CLICKED_EXTERNAL_LINK_TYPE,
  ANALYTICS_CLICKED_NAV_LINK_TYPE,
  ANALYTICS_OPENED_CHAT_TYPE,
  ANALYTICS_DOWNLOAD_NOTARIZATION_GUIDE_TYPE,
  ANALYTICS_USER_CHANGED_UNSUPPORTED_LANGUAGE_TYPE,
  ANALYTICS_CLICKED_SKIP_PAY_LATER_TYPE,
  ANALYTICS_CLICKED_EDIT_TYPE,
  ANALYTICS_RESET_USER_TYPE,
  ANALYTICS_CLICKED_CHECKOUT_NOW_TYPE,
} from "../../actions/analytics";
import { REQUEST_SUCCESS_TYPE } from "../../actions/requests";
import { getMultiplePageTranslationsByProvince } from "../../i18n/translations";
import {
  transformPayloadToSegment,
  buildOptionsForSegment,
} from "./transform-utils";
import { selectCurrentRoute } from "../../selectors/location";
import { selectProvinceById } from "../../selectors/provinces";
import { UPDATE_EXPERIMENTS_TYPE } from "../../actions/experiments";
import { DISPLAY_EMAIL_PREVIEW_MODAL_TYPE } from "../../actions/email-preview-modal";

const urlTranslations = getMultiplePageTranslationsByProvince("en", "ON", [
  "urlLabels",
]);

const isIdentifyPayloadEmpty = (event) => {
  return Object.keys(event.traits).length > 0;
};

export const SEGMENT_CONFIG = {
  [ANALYTICS_VIEW_PAGE_TYPE]: trackEvent(
    ({ payload }, prevState, nextState) => ({
      name: "Viewed Page",
      properties: {
        title: urlTranslations[payload.location.pathname],
        url: payload.location.pathname,
        isLoggedIn: payload.isLoggedIn,
        device: payload.device,
      },
      options: buildOptionsForSegment(nextState),
    }),
  ),
  [ANALYTICS_LOGIN_TYPE]: identifyUser(({ payload }, prevState, nextState) => {
    const { userId, email } = payload;

    return {
      userId,
      traits: {
        email,
      },
      options: buildOptionsForSegment(nextState),
    };
  }),
  [ANALYTICS_SIGNUP_TYPE]: identifyUser(({ payload }, prevState, nextState) => {
    const { userId, email } = payload;

    return {
      userId,
      traits: {
        email,
      },
      options: buildOptionsForSegment(nextState),
    };
  }),
  [ANALYTICS_LOGOUT_TYPE]: reset(),
  [ANALYTICS_RESET_USER_TYPE]: reset(),
  // We don't need to hit identify every time a request comes back, Segment suggests we should only
  // do so when we have actual user traits to change. REQUEST_SUCCESS_TYPE only has data in its payload
  // if the data property on the response was set. Therefore, we should only identify if the payload
  // on this event is actually populated.
  // ensure helps us doing that by blocking the event unless our validation method returns true.
  [REQUEST_SUCCESS_TYPE]: ensure(
    isIdentifyPayloadEmpty,
    identifyUser((action, prevState, nextState) => {
      const payload = R.propOr({}, "payload")(action);
      const isSpouseValues = R.pathOr(false, ["meta", "isSpouseValues"])(
        action,
      );

      return transformPayloadToSegment(payload, nextState, isSpouseValues);
    }),
  ),
  [ANALYTICS_REFERRAL_VISIT_TYPE]: identifyUser(
    (action, prevState, nextState) => {
      const payload = R.propOr({}, "payload")(action);
      return transformPayloadToSegment(payload, nextState, false);
    },
  ),
  [ANALYTICS_CLICKED_EXTERNAL_LINK_TYPE]: trackEvent(
    (action, prevState, nextState) => {
      const url = R.pathOr("", ["payload", "url"])(action);
      const pathname = selectCurrentRoute(nextState);
      return {
        name: "Clicked External Link",
        properties: {
          url,
          title: urlTranslations[pathname] || pathname,
        },
        options: buildOptionsForSegment(nextState),
      };
    },
  ),
  [ANALYTICS_CLICKED_NAV_LINK_TYPE]: trackEvent(
    (action, prevState, nextState) => {
      const menu = R.pathOr("", ["payload", "menu"])(action);
      const item = R.pathOr("", ["payload", "item"])(action);
      return {
        name: "Clicked Nav Link",
        properties: {
          menu,
          item,
        },
        options: buildOptionsForSegment(nextState),
      };
    },
  ),
  [ANALYTICS_OPENED_CHAT_TYPE]: trackEvent((action, prevState, nextState) => {
    const pathname = selectCurrentRoute(nextState);
    return {
      name: "Opened Chat",
      properties: {
        title: urlTranslations[pathname] || pathname,
      },
      options: buildOptionsForSegment(nextState),
    };
  }),
  [ANALYTICS_DOWNLOAD_NOTARIZATION_GUIDE_TYPE]: trackEvent(
    (action, prevState, nextState) => {
      const pathname = selectCurrentRoute(nextState);
      return {
        name: "Downloaded Content",
        properties: {
          content: "Notarization guide",
          url: pathname,
          title: urlTranslations[pathname] || pathname,
        },
        options: buildOptionsForSegment(nextState),
      };
    },
  ),
  [ANALYTICS_USER_CHANGED_UNSUPPORTED_LANGUAGE_TYPE]: trackEvent(
    (action, prevState, nextState) => {
      // Get the province ID of the province the user is trying to switch to
      const provinceId = R.pathOr("", [
        "form",
        "residence",
        "values",
        "provinceId",
      ])(nextState);
      const province = selectProvinceById(provinceId)(nextState);
      const pathname = selectCurrentRoute(nextState);
      const language = R.pathOr("", ["payload", "lang"])(action);

      return {
        name: "Selected Unavailable Language",
        properties: {
          language,
          province: province.code,
          url: pathname,
        },
        options: buildOptionsForSegment(nextState),
      };
    },
  ),
  [UPDATE_EXPERIMENTS_TYPE]: identifyUser((action, prevState, nextState) => {
    const experiments = R.pathOr({}, ["payload", "experiments"])(action);
    const amplitudeExperiments = Object.keys(experiments).reduce(
      (accumulatedExperiments, current) => {
        const propertyName = `Experiment: ${current}`;
        const propertyValue = experiments[current];

        return {
          ...accumulatedExperiments,
          [propertyName]: propertyValue,
        };
      },
      {},
    );

    return transformPayloadToSegment(amplitudeExperiments, nextState, false);
  }),
  [ANALYTICS_CLICKED_SKIP_PAY_LATER_TYPE]: trackEvent(
    (action, prevState, nextState) => ({
      name: "Clicked Skip and Pay Later link",
      options: buildOptionsForSegment(nextState),
    }),
  ),
  [ANALYTICS_CLICKED_EDIT_TYPE]: trackEvent((action, prevState, nextState) => {
    const sectionKey = R.pathOr("", ["payload", "section"])(action);
    const sectionMap = {
      yourFamily: "Your family",
      yourEstate: "Your estate",
      yourArrangements: "Your arrangements",
      spouseEstate: "Spouse's Estate",
      spouseArrangements: "Spouse's Arrangements",
    };
    const section = R.propOr("", sectionKey)(sectionMap);
    const page = R.pathOr("", ["payload", "page"])(action);

    return {
      name: "Section Review Edit",
      properties: {
        section,
        page: urlTranslations[page],
      },
      options: buildOptionsForSegment(nextState),
    };
  }),
  [DISPLAY_EMAIL_PREVIEW_MODAL_TYPE]: trackEvent(
    (action, prevState, nextState) => {
      const { key, appointeeLevel } = action.payload;

      return {
        name: "Previewed Appointee Notification Email",
        properties: {
          appointeeRole: key,
          appointeeLevel: appointeeLevel + 1, // level is zero indexed, we want this to be readable on amplitude
        },
        options: buildOptionsForSegment(nextState),
      };
    },
  ),
  [ANALYTICS_CLICKED_CHECKOUT_NOW_TYPE]: trackEvent(
    (action, prevState, nextState) => {
      const pathname = selectCurrentRoute(nextState);
      return {
        name: "Clicked Checkout Now",
        properties: {
          title: urlTranslations[pathname],
          url: pathname,
        },
        options: buildOptionsForSegment(nextState),
      };
    },
  ),
};
