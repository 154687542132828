import React, { useEffect } from "react";
import { connect } from "react-redux";

import { claimInvitePageProps } from "../../selectors/invite";
import { validateInviteToken } from "../../actions/invites";
import {
  checkEmailAvailability,
  clearState,
  submitSignup,
} from "../../actions/auth";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import SignupForm from "../../components/forms/SignupForm";
import { LOGIN_PATH } from "../../constants/routes";

const ClaimInvitePage = ({
  validateInviteToken,
  clearState,
  submitSignup,
  hasLoaded,
  checkEmailAvailability,
  ...restOfProps
}) => {
  useEffect(() => {
    clearState({});
    validateInviteToken();
  }, [validateInviteToken, clearState]);
  if (!hasLoaded) return <LoadingSpinner />;

  return (
    <SignupForm
      onSubmit={submitSignup}
      loginLink={LOGIN_PATH}
      isInvitedUser
      checkEmailAvailability={checkEmailAvailability}
      {...restOfProps}
    />
  );
};

export default connect(claimInvitePageProps, {
  submitSignup,
  validateInviteToken,
  clearState,
  checkEmailAvailability,
})(ClaimInvitePage);
