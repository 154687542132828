import React from "react";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { ButtonLink } from "../../ui/Button";
import { SmallBody } from "../../ui/Typography";
import { YOUR_ACCOUNT_PATH } from "../../../constants/routes";
import AssignedPlans from "./AssignedPlans";
import AvailablePlans from "./AvailablePlans";

export const InviteUser = ({
  assignedPlans,
  revokeInvite,
  translations,
  resendInvite,
  numberOfAvailablePlans,
  displayModal,
}) => {
  // Create synthetic Array to map over in JSX to display available plans
  const availablePlans = [...Array(numberOfAvailablePlans)];

  return (
    <>
      {assignedPlans.map(
        ({ id, fullName, email, status, isResendLoading, isRevokeLoading }) => (
          <AssignedPlans
            key={email}
            id={id}
            fullName={fullName}
            email={email}
            status={status}
            revokeInvite={revokeInvite}
            translations={translations}
            resendInvite={resendInvite}
            isResendLoading={isResendLoading}
            isRevokeLoading={isRevokeLoading}
          />
        ),
      )}
      {numberOfAvailablePlans > 0 &&
        availablePlans.map((planNumber) => (
          <AvailablePlans
            key={planNumber}
            displayModal={displayModal}
            translations={translations}
          />
        ))}
    </>
  );
};

const YourInviteContent = ({
  textColor = "willfulBlue",
  isPaid,
  dashboardView,
  translations,
  numberOfInvitesRemainingNum,
  numberOfAvailablePlansNum,
}) => {
  const isTablet = useMediaQuery(
    "@media (min-width:768px) and (max-width:1025px)",
  );
  const { availableInvitesCopy, numberOfPlansAvailable } = translations;
  const numberOfPlansAvailableCopy = numberOfPlansAvailable
    .replace("REMAINING", numberOfInvitesRemainingNum)
    .replace("TOTAL", numberOfAvailablePlansNum);
  const allInvitesConsumed = numberOfInvitesRemainingNum === 0;

  return (
    <>
      {isPaid && dashboardView && (
        <Box width={1} mt={2}>
          <ButtonLink
            to={YOUR_ACCOUNT_PATH}
            fullWidth
            secondary
            displayArrowRight
            text={availableInvitesCopy}
          />
        </Box>
      )}

      {isPaid && !allInvitesConsumed && (
        <Box mt={2} display="flex" flexDirection={isTablet ? "column" : "row"}>
          <Box
            borderRadius="4px"
            py={0.5}
            width="fit-content"
            px={1}
            bgcolor="secondary.main"
          >
            <SmallBody color={textColor}>
              {numberOfPlansAvailableCopy}
            </SmallBody>
          </Box>
        </Box>
      )}
    </>
  );
};

export default YourInviteContent;
