export const fetchCheckout = (sessionId = null) => {
  return {
    type: FETCH_CHECKOUT_TYPE,
    payload: {
      sessionId,
    },
  };
};

export const fetchStripeClientSecret = () => ({
  type: FETCH_STRIPE_CLIENT_SECRET_TYPE,
});

export const submitCheckout = ({ stripe, elements }) => ({
  type: SUBMIT_CHECKOUT_TYPE,
  payload: {
    stripe,
    elements,
  },
});

export const updatePromoCode = () => ({
  type: UPDATE_PROMO_CODE_TYPE,
});

export const validateCaaMembership = () => ({
  type: VALIDATE_CAA_MEMBERSHIP_TYPE,
});

export const removeReferral = () => ({
  type: REMOVE_REFERRAL_TYPE,
});

export const submitPartnerDiscountCheckout = () => ({
  type: SUBMIT_PARTNER_DISCOUNT_CHECKOUT_TYPE,
});

export const applyPartnerDiscount = () => ({
  type: APPLY_PARTNER_DISCOUNT_TYPE,
});

export const openModalBNPLExperiment = () => ({
  type: OPEN_MODAL_BNPL_EXPERIMENT,
});

export const closeModalBNPLExperiment = () => ({
  type: CLOSE_MODAL_BNPL_EXPERIMENT,
});

export const resetBNPLExperimentForm = (key) => {
  return {
    type: RESET_BNPL_EXPERIMENT_FORM,
    payload: {
      key,
    },
  };
};

export const getStripeSessionStatus = (sessionId) => {
  return {
    type: STRIPE_SESSION_STATUS,
    payload: {
      sessionId,
    },
  };
};

export const updateStripeClientSecret = (clientSecret) => ({
  type: UPDATE_STRIPE_CLIENT_SECRET_TYPE,
  payload: { clientSecret },
});

export const REMOVE_REFERRAL_TYPE = "REMOVE_REFERRAL";
export const FETCH_CHECKOUT_TYPE = "FETCH_CHECKOUT";
export const SUBMIT_CHECKOUT_TYPE = "SUBMIT_CHECKOUT";
export const UPDATE_PROMO_CODE_TYPE = "UPDATE_PROMO_CODE";
export const VALIDATE_CAA_MEMBERSHIP_TYPE = "VALIDATE_CAA_MEMBERSHIP";
export const SUBMIT_PARTNER_DISCOUNT_CHECKOUT_TYPE =
  "SUBMIT_PARTNER_DISCOUNT_CHECKOUT";
export const APPLY_PARTNER_DISCOUNT_TYPE = "APPLY_PARTNER_DISCOUNT";
export const OPEN_MODAL_BNPL_EXPERIMENT = "OPEN_MODAL_BNPL_EXPERIMENT";
export const CLOSE_MODAL_BNPL_EXPERIMENT = "CLOSE_MODAL_BNPL_EXPERIMENT";
export const RESET_BNPL_EXPERIMENT_FORM = "RESET_BNPL_EXPERIMENT_FORM";
export const STRIPE_SESSION_STATUS = "STRIPE_SESSION_STATUS";
export const FETCH_STRIPE_CLIENT_SECRET_TYPE = "FETCH_STRIPE_CLIENT_SECRET";
export const UPDATE_STRIPE_CLIENT_SECRET_TYPE = "UPDATE_STRIPE_CLIENT_SECRET";
