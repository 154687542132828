import R from "ramda";
import { UPDATE_STATUS_TYPE } from "../actions/status";
import { SET_LANGUAGE_CODE_TYPE } from "../actions/language";
import {
  FIRST_NAME_FIELD_ID,
  MIDDLE_NAME_FIELD_ID,
  LAST_NAME_FIELD_ID,
  DOB_FIELD_ID,
  GENDER_FIELD_ID,
  RESIDENCE_FIELD_ID,
  LANGUAGE_FIELD_ID,
  PROVINCE_FIELD_ID,
  PEOPLE_QUANTITY_INTENT_FIELD_ID,
  PHONE_NUMBER_FIELD_ID,
  REASONS_ID,
} from "../constants/forms";

const getValue = (key, state) => R.pathOr(R.prop(key, state), ["user", key]);

// uses province.id from meta, where all other about you values come from user.<field>
const getProvinceId = (key, state) =>
  R.pathOr(R.prop(key, state), ["province", "id"]);

const DEFAULT_STATE = {
  [FIRST_NAME_FIELD_ID]: "",
  [MIDDLE_NAME_FIELD_ID]: "",
  [LAST_NAME_FIELD_ID]: "",
  [DOB_FIELD_ID]: "",
  [GENDER_FIELD_ID]: "",
  [RESIDENCE_FIELD_ID]: "",
  [LANGUAGE_FIELD_ID]: null,
  [PROVINCE_FIELD_ID]: "",
  [PHONE_NUMBER_FIELD_ID]: "",
  [REASONS_ID]: [],
};
const aboutYou = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return {
        ...state,
        [FIRST_NAME_FIELD_ID]: getValue(FIRST_NAME_FIELD_ID, state)(payload),
        [MIDDLE_NAME_FIELD_ID]: getValue(MIDDLE_NAME_FIELD_ID, state)(payload),
        [LAST_NAME_FIELD_ID]: getValue(LAST_NAME_FIELD_ID, state)(payload),
        [DOB_FIELD_ID]: getValue(DOB_FIELD_ID, state)(payload),
        [GENDER_FIELD_ID]: getValue(GENDER_FIELD_ID, state)(payload),
        [RESIDENCE_FIELD_ID]: getValue(RESIDENCE_FIELD_ID, state)(payload),
        [LANGUAGE_FIELD_ID]: getValue(LANGUAGE_FIELD_ID, state)(payload),
        [PROVINCE_FIELD_ID]: getProvinceId(PROVINCE_FIELD_ID, state)(payload),
        [PEOPLE_QUANTITY_INTENT_FIELD_ID]: getValue(
          PEOPLE_QUANTITY_INTENT_FIELD_ID,
          state,
        )(payload),
        [PHONE_NUMBER_FIELD_ID]: getValue(
          PHONE_NUMBER_FIELD_ID,
          state,
        )(payload),
        [REASONS_ID]: getValue(REASONS_ID, state)(payload),
      };
    case SET_LANGUAGE_CODE_TYPE:
      return {
        ...state,
        [LANGUAGE_FIELD_ID]: payload.languageCode,
      };
    default:
      return state;
  }
};

export default aboutYou;
