import { takeEvery, put } from "redux-saga/effects";

import {
  updateFeatureFlags,
  GET_FEATURE_FLAGS_TYPE,
} from "../actions/featureFlags";

function* getFeatureFlags() {
  const showTimestamp = ["development", "qa", "staging"].includes(
    window.env.TAPLYTICS_ENV,
  );

  if (showTimestamp) {
    console.time("Getting feature flags");
  }

  // Taplytics uses callbacks for async actions through their SDK.
  // In order to make that compatible with redux-saga, we need to wrap
  // the SDK calls in a Promise, then call that Promise as a generator
  const taplyticsFeatureFlagsPromiseWrapper = new Promise((resolve) => {
    window.Taplytics.runningFeatureFlags((flags) => {
      if (showTimestamp) {
        console.timeEnd("Getting feature flags");
      }
      resolve(flags);
    });
  });

  const featureFlags = yield taplyticsFeatureFlagsPromiseWrapper;
  yield put(updateFeatureFlags(featureFlags));
}

export function* watchGetFeatureFlags() {
  yield takeEvery(GET_FEATURE_FLAGS_TYPE, getFeatureFlags);
}
