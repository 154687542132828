import React from "react";
import styled from "styled-components";

import Box from "@material-ui/core/Box";

import CheckedBox from "../icons/checkbox-fill.svg";
import UncheckedBox from "../icons/checkbox-empty.svg";
import CheckboxError from "../icons/checkbox-error.svg";
import { ErrorMessage } from "../Error";

import { SmallBody, SmallExternalLink } from "../Typography";

export const Checkbox = styled.input`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  + label {
    cursor: pointer;

    &.label-flex {
      display: flex;
    }
  }

  + label > span {
    vertical-align: super;
  }

  + label::before {
    margin-right: 12px;
    content: url(${(props) => (props.hasError ? CheckboxError : UncheckedBox)});
  }

  &:checked + label::before {
    content: url(${CheckedBox});
  }
`;

const CheckboxInput = ({
  input,
  meta: { touched, error, warning },
  autoFocus,
  disabled,
  label,
  labelLinkUrl,
  labelLinkText,
  optional,
  placeholder,
  noMargin,
  displayFlex = false,
}) => {
  const { name, value } = input;
  const shouldDisplayError = error && touched;
  const hasValue = value !== "";
  const flexLabel = displayFlex && "label-flex";

  return (
    <Box my={noMargin ? 0 : 2}>
      <Box display="flex">
        <Checkbox
          {...input}
          hasError={shouldDisplayError}
          hasValue={hasValue}
          autoFocus={autoFocus}
          disabled={disabled}
          placeholder={placeholder}
          type={"checkbox"}
          id={name}
        />

        {/* qa className on label so that click triggers input change.
          can't put qa class on input because its visibly hidden, selenium won't click it  */}
        <label htmlFor={name} className={`qa-checkbox-${name} ${flexLabel}`}>
          <SmallBody inline component="span">
            {`${label} ${optional ? "(optional)" : ""}`}
            {labelLinkUrl && labelLinkText && (
              <Box component="span" ml={0.1}>
                <SmallExternalLink href={labelLinkUrl} text={labelLinkText} />
              </Box>
            )}
          </SmallBody>
        </label>
      </Box>
      {shouldDisplayError && (
        <Box mb={0.5}>
          <ErrorMessage error={error} />
        </Box>
      )}
    </Box>
  );
};
export default CheckboxInput;
