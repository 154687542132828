import React from "react";

import SvgIcon from "./SvgIcon";

const AssetsIcon = () => {
  return (
    <SvgIcon width="120" height="97" viewBox="0 0 120 97" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60 96.9264C93.1371 96.9264 120 79.0992 120 57.1082C120 35.1172 93.1371 17.29 60 17.29C26.8629 17.29 0 35.1172 0 57.1082C0 79.0992 26.8629 96.9264 60 96.9264Z"
        fill="#F6F6F6"
      />
      <path
        d="M67.9346 72.6622L80.4848 68.6647L95.7775 61.0823C96.9395 60.3386 96.9395 58.5723 95.731 57.875L80.8334 49.0899L65.9358 40.3047L35.5364 46.6321L28.4246 50.7226L65.9358 72.4298C66.5401 72.8017 67.2838 72.8946 67.9346 72.6622Z"
        fill="#2F455F"
      />
      <path
        d="M73.5119 31.0146L53.8498 19.6729L34.3737 8.42415L34.1878 8.28471C33.7695 7.95933 33.1187 7.91285 32.6539 8.14526L25.9139 12.0498C26.4252 11.8174 27.0295 11.8638 27.4479 12.1892L27.6338 12.3287L47.1099 23.4844L66.7719 34.8261C67.5621 35.3374 68.2129 36.0811 68.5847 36.9643L75.2782 33.1063C74.9528 32.2231 74.3021 31.5259 73.5119 31.0146Z"
        fill="#255793"
      />
      <path
        d="M68.6309 37.0114C69.0027 37.7551 69.1887 38.5453 69.1887 39.382V69.7814C69.1887 71.1294 68.7703 72.059 68.0266 72.4774L74.7201 68.6658C75.4173 68.294 75.8821 67.3178 75.8821 65.9698V35.5239C75.8821 34.6872 75.6962 33.897 75.3243 33.1533L68.6309 37.0114Z"
        fill="#0064DC"
      />
      <path
        d="M66.772 34.8719L47.1099 23.5302L27.6803 12.2815L27.4944 12.142C26.1929 11.3983 25.1238 12.1885 25.1238 13.9084V44.4008C25.1238 46.8643 26.6112 49.6998 28.4705 50.8153L65.8423 72.3832C66.4931 72.848 67.2833 72.8945 68.027 72.5226C68.7242 72.1507 69.189 71.1746 69.189 69.8266V39.3807C69.1426 37.6144 68.2594 35.8945 66.772 34.8719Z"
        fill="#255793"
      />
      <path
        d="M66.7716 34.8711L27.4475 12.1412C27.0292 11.8623 26.3784 11.7694 25.9136 12.0483L25.5417 12.2807C26.0531 12.0483 26.6573 12.0948 27.0757 12.4201L66.4462 35.1035C67.8872 36.1726 68.7703 37.846 68.8168 39.6588V52.3949C68.9098 53.1387 68.5844 53.8359 67.9801 54.3007L68.352 54.0683C68.9563 53.65 69.2816 52.9063 69.1887 52.1625V39.4264C69.1422 37.6136 68.259 35.8937 66.7716 34.8711Z"
        fill="#255793"
      />
      <path
        d="M67.9338 54.2555C67.4225 54.4879 66.8182 54.4415 66.3999 54.1161L27.0294 31.4327C25.5884 30.3636 24.7052 28.6902 24.6588 26.8774V14.1878C24.5658 13.444 24.8912 12.7468 25.4954 12.282C26.0067 12.0496 26.611 12.096 27.0294 12.4214L66.3999 35.1048C67.8409 36.1739 68.724 37.8473 68.7705 39.6601V52.3962C68.8635 53.047 68.5381 53.7907 67.9338 54.2555Z"
        fill="#0064DC"
      />
      <path
        d="M33.5833 32.5957L37.4413 34.7804V39.754L33.5833 37.5228V32.5957Z"
        fill="#2F455F"
      />
      <path
        d="M57.3361 46.3066L61.2406 48.5378V53.4649L57.3361 51.2338V46.3066Z"
        fill="#2F455F"
      />
      <path
        d="M34.0019 32.9207L32.8864 32.3164V37.2435L35.4894 38.731L36.7909 39.5212V34.5476L34.0019 32.9207Z"
        fill="#FFDC1E"
      />
      <path
        d="M57.8009 46.6791L56.6853 46.0283V51.0019L59.1954 52.4429L60.5433 53.1866V48.2595L57.8009 46.6791Z"
        fill="#FFDC1E"
      />
      <path
        d="M56.6853 46.0277L57.0572 45.8418L60.9617 48.0265L60.5433 48.2589L56.6853 46.0277Z"
        fill="#E39F3A"
      />
      <path
        d="M60.5432 53.1879L60.9616 53.0019V48.0283L60.5432 48.2607V53.1879Z"
        fill="#CE9134"
      />
      <path
        d="M43.6701 16.6973L42.508 17.3945L43.2053 17.8128L42.4615 18.2312L44.2279 18.4171L59.5206 27.2023L58.7768 27.6671L60.5432 27.8065L61.7517 27.1558L43.6701 16.6973Z"
        fill="#2F455F"
      />
      <path
        d="M32.8864 32.3164L33.3047 32.084L37.1627 34.3151L36.7909 34.5475L32.8864 32.3164Z"
        fill="#E39F3A"
      />
      <path
        d="M36.7909 39.5215L37.1628 39.2426V34.3154L36.7909 34.5478V39.5215Z"
        fill="#CE9134"
      />
      <path
        d="M57.7076 20.0904L44.1348 12.2349C43.67 11.9095 43.0657 11.863 42.5544 12.0955L41.3923 12.7927C41.5783 12.6532 41.8572 12.6532 42.0896 12.6532C42.4149 12.6997 42.6938 12.7927 42.9727 12.9786L56.5921 20.7876C57.8936 21.6708 58.7302 23.0653 58.8232 24.5992V27.6206L59.9853 26.9233V23.9484C59.8923 22.368 59.0091 20.9271 57.7076 20.0904Z"
        fill="#AAD2F6"
      />
      <path
        d="M56.5452 20.788L42.9259 12.9325C42.647 12.793 42.3681 12.6536 42.0427 12.6071C41.8103 12.5606 41.5779 12.6536 41.3455 12.7465C40.9272 13.0719 40.6483 13.6297 40.6947 14.1875V17.2088L42.4146 18.185V15.6749C42.4146 15.0707 42.8329 14.8382 43.3442 15.1636L43.5767 15.3031L56.0804 22.5543C56.6382 22.9262 56.9636 23.484 57.01 24.1812V26.6912L58.7764 27.6674V24.646C58.6834 23.0656 57.8467 21.6247 56.5452 20.788Z"
        fill="#E2F6FF"
      />
    </SvgIcon>
  );
};

export default AssetsIcon;
