import React, { useEffect } from "react";
import { connect } from "react-redux";

import GenderForm from "../../../components/forms/about-you/GenderForm";
import { genderPageProps } from "../../../selectors/about-you";
import { submitAboutYou } from "../../../actions/about-you";
import { DOB_PATH } from "../../../constants/routes";
import { GENDER_FORM_ID } from "../../../constants/forms";
import { getFormData } from "../../../actions/forms";

const GenderPage = ({ submitAboutYou, getFormData, ...restOfProps }) => {
  useEffect(() => {
    getFormData(GENDER_FORM_ID);
  }, [getFormData]);
  return (
    <GenderForm
      backLink={DOB_PATH}
      onSubmit={() => submitAboutYou(GENDER_FORM_ID)}
      {...restOfProps}
    />
  );
};

export default connect(genderPageProps, { submitAboutYou, getFormData })(
  GenderPage,
);
