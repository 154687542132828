import { createSelector } from "reselect";
import R from "ramda";

import { formatCurrency } from "../../utilities/helpers";
import { selectTranslations } from "../translations";
import { FIRST_NAME_FIELD_ID } from "../../constants/forms";
import { selectCurrentRoute } from "../location";
import { CHECKOUT_PATH } from "../../constants/routes";

// This already exists in selectors/about-you.js but will cause a dependency cycle if imported
const selectFirstName = R.pathOr(undefined, ["aboutYou", FIRST_NAME_FIELD_ID]);

const selectLanguageCode = R.pathOr("en", ["aboutYou", "lang"]);

const formatPrice = (number) =>
  !!number && typeof number === "number" ? `$${number.toFixed(2)}` : number;

export const selectOrderSubtotal = (reducerKey) =>
  R.pathOr(0, [reducerKey, "subtotal"]);

// tax amount from cart(subtotal * tax_rate)
export const selectOrderTax = (reducerKey) => R.pathOr(0, [reducerKey, "tax"]);

// cart subtotal + tax
export const selectOrderTotal = (reducerKey) =>
  R.pathOr(0, [reducerKey, "total"]);
export const selectOrderTaxType = (reducerKey) =>
  R.pathOr("", [reducerKey, "province", "taxLine"]);

export const selectOrderSubtotalBeforeDiscounts = (reducerKey) =>
  R.pathOr(0, [reducerKey, "subtotalBeforeDiscounts"]);

export const selectOrderItems = (reducerKey) =>
  R.pathOr([], [reducerKey, "items"]);

export const selectOrderPlan = (reducerKey) =>
  createSelector(
    selectOrderItems(reducerKey),
    R.find(R.pathEq(["product", "type"], "plan")),
  );

export const selectOrderPricePerPlan = (reducerKey) =>
  createSelector(selectOrderPlan(reducerKey), R.propOr(0, "price"));

export const selectOrderPlanSymbol = (reducerKey) =>
  createSelector(
    selectOrderPlan(reducerKey),
    R.pathOr("", ["product", "symbol"]),
  );

export const selectOrderPlanQuantity = (reducerKey) =>
  createSelector(selectOrderPlan(reducerKey), R.propOr(0, "quantity"));

export const selectIsOrderPlanBundle = (reducerKey) =>
  createSelector(
    selectOrderPlan(reducerKey),
    R.pathOr(false, ["product", "isFamily"]),
  );

// subtotalBeforeDiscounts
export const selectOrderValueFormatted = (reducerKey) =>
  createSelector(
    selectOrderSubtotalBeforeDiscounts(reducerKey),
    selectLanguageCode,
    (cartValue, languageCode) => formatCurrency(cartValue, languageCode, 2),
  );

export const selectOrderSubtotalFormatted = (reducerKey) =>
  createSelector(
    selectOrderSubtotal(reducerKey),
    selectLanguageCode,
    (subtotal, languageCode) => formatCurrency(subtotal, languageCode, 2),
  );

export const selectOrderTaxFormatted = (reducerKey) =>
  createSelector(
    selectOrderTax(reducerKey),
    selectLanguageCode,
    (tax, languageCode) => formatCurrency(tax, languageCode, 2),
  );

export const selectOrderTotalFormatted = (reducerKey) =>
  createSelector(
    selectOrderTotal(reducerKey),
    selectLanguageCode,
    (total, languageCode) => formatCurrency(total, languageCode, 2),
  );

export const selectOrderPricePerPlanFormatted = (reducerKey) =>
  createSelector(selectOrderPricePerPlan(reducerKey), formatPrice);

export const selectOrderPlanNameTranslated = (reducerKey) =>
  createSelector(
    selectTranslations(["global", "plans"]),
    selectOrderPlanSymbol(reducerKey),
    selectIsOrderPlanBundle(reducerKey),
    selectCurrentRoute,
    (translations, planSymbol, isBundle, currentRoute) => {
      if (!planSymbol) return "";
      if (isBundle) {
        return currentRoute === CHECKOUT_PATH
          ? translations.bundle.planNameCheckoutPage
          : translations.bundle.planName;
      }
      return translations[planSymbol].planName;
    },
  );

export const selectOrderPlanLineItemFormatted = (reducerKey) =>
  createSelector(
    selectTranslations(["global", "plans"]),
    selectOrderPlanNameTranslated(reducerKey),
    selectOrderPlanQuantity(reducerKey),
    selectIsOrderPlanBundle(reducerKey),
    selectFirstName,
    (translations, planName, planQuantity, isBundlePlanSelected, firstName) => {
      if (isBundlePlanSelected) {
        const plaNameFormatted = planName.replace("{USER_NAME}", firstName);
        return `${plaNameFormatted} (x ${planQuantity} ${translations.bundle.planAdultsText})`;
      }
      return planName;
    },
  );
