import R from "ramda";
import { select, takeEvery, call, put } from "redux-saga/effects";
import {
  change,
  getFormValues,
  initialize,
  startSubmit,
  stopSubmit,
} from "redux-form";

import {
  FETCH_EXECUTORS_TYPE,
  SUBMIT_SPOUSE_EXECUTORS_FORM_TYPE,
  OPEN_MODAL_EDIT_EXECUTORS_TYPE,
  OPEN_MODAL_ADD_EXECUTORS_TYPE,
  REMOVE_EXECUTORS_TYPE,
  executorAddedSuccessfully,
  EXECUTORS_NEXT_PAGE_TYPE,
} from "../actions/executors";
import { submitFormRequest } from "./forms";
import { fetchApiData } from "./requests";
import { fetchSuccess } from "../actions/requests";
import {
  getExecutorsNextPage,
  removeExecutorEndpoint,
  submitUpdateExecutors,
} from "../api/executors";
import {
  EXECUTORS_FORM_ID,
  ADD_AND_EDIT_EXECUTORS_FORM_ID,
  ADD_AND_EDIT_EXECUTORS_FIELD_ID,
  EXECUTORS_FIELD_ID,
  CO_EXECUTORS_CHECKBOX_FIELD_ID,
} from "../constants/forms";
import { MODAL_ADD_EXECUTORS, MODAL_EDIT_EXECUTORS } from "../constants/modal";
import { closeModal, displayModal } from "../actions/modal";
import {
  ADD_EXECUTOR_SUCCESS,
  EDIT_EXECUTOR_SUCCESS,
  REMOVE_EXECUTOR_SUCCESS,
} from "../constants/toasts";
import { closeToast, displayToast } from "../actions/toast";
import {
  selectLastSelectedExecutorData,
  selectExecutorsForm,
} from "../selectors/executors";
import { DASHBOARD_ENDPOINT } from "../constants/routes";
import { getDashboard } from "../api/dashboard";
import { getFormData } from "../actions/forms";

function* getSpouseIsExecutorFormValue() {
  const formData = yield select(getFormValues(EXECUTORS_FORM_ID));
  return R.propOr(false, "spouseIsExecutor")(formData);
}

function* fetchExecutors() {
  yield call(fetchApiData, {
    apiCall: getDashboard,
    DASHBOARD_ENDPOINT,
  });

  const formData = yield select(selectExecutorsForm);
  yield put(initialize(EXECUTORS_FORM_ID, formData));
  yield put(fetchSuccess(EXECUTORS_FORM_ID));
}

function* submitSpouseExecutorsForm() {
  yield put(closeToast());
  yield call(submitFormRequest, {
    apiCall: submitUpdateExecutors,
    formId: EXECUTORS_FORM_ID,
  });
}

export function* updateExecutors(
  executorToUpdate,
  contactId = null,
  isSecondaryContact = false,
  removeSecondaryContact = false,
) {
  const spouseIsExecutor = yield call(getSpouseIsExecutorFormValue);
  const response = yield call(submitFormRequest, {
    apiCall: submitUpdateExecutors,
    formId: EXECUTORS_FORM_ID,
    values: {
      ...R.mapObjIndexed(R.defaultTo(null), executorToUpdate),
      spouseIsExecutor,
      contactId,
      isSecondaryContact,
      removeSecondaryContact,
    },
  });
  const { status, executor } = response;
  if (status !== 200 || !executor) {
    yield put(stopSubmit(EXECUTORS_FORM_ID));
    return null;
  }
  return executor.id;
}

function* handleOpenModalAddExecutors() {
  yield put(closeToast());
  yield put(displayModal(MODAL_ADD_EXECUTORS));
}

function* handleOpenModalEditExecutors({ payload }) {
  yield put(closeToast());
  const { executor } = payload;
  const hasCoExecutor = Boolean(executor?.spouseFirstName);
  yield put(
    change(ADD_AND_EDIT_EXECUTORS_FORM_ID, EXECUTORS_FIELD_ID, executor),
  );
  if (hasCoExecutor) {
    yield put(
      change(
        ADD_AND_EDIT_EXECUTORS_FORM_ID,
        CO_EXECUTORS_CHECKBOX_FIELD_ID,
        true,
      ),
    );
    yield put(
      change(ADD_AND_EDIT_EXECUTORS_FORM_ID, ADD_AND_EDIT_EXECUTORS_FIELD_ID, {
        firstName: executor.spouseFirstName,
        middleName: executor.spouseMiddleName,
        lastName: executor.spouseLastName,
      }),
    );
  }
  yield put(displayModal(MODAL_EDIT_EXECUTORS));
}

export function* handleAddOrEditExecutors(isEditing = false) {
  yield put(startSubmit(ADD_AND_EDIT_EXECUTORS_FORM_ID));
  try {
    const {
      executors,
      addAndEditExecutors = {},
      coExecutorsCheckbox = null,
    } = yield select(getFormValues(ADD_AND_EDIT_EXECUTORS_FORM_ID));
    let coExecutor = {
      spouseFirstName: addAndEditExecutors.firstName || null,
      spouseMiddleName: addAndEditExecutors.middleName || null,
      spouseLastName: addAndEditExecutors.lastName || null,
    };
    if (coExecutorsCheckbox === false) {
      // if the user activelly marked the checkbox as
      // false, the co-executor data will be erased
      coExecutor = {
        spouseFirstName: null,
        spouseMiddleName: null,
        spouseLastName: null,
      };
    }
    const newExecutor = {
      firstName: executors.firstName || null,
      middleName: executors.middleName || null,
      lastName: executors.lastName || null,
      ...coExecutor,
    };

    // adds or edits an executor
    const updatedExecutors = isEditing
      ? yield call(editExecutor, newExecutor)
      : yield call(addExecutor, newExecutor);

    yield put(closeModal());
    yield put(change(EXECUTORS_FORM_ID, EXECUTORS_FIELD_ID, updatedExecutors));
    yield put(executorAddedSuccessfully(newExecutor));
    yield put(
      displayToast(isEditing ? EDIT_EXECUTOR_SUCCESS : ADD_EXECUTOR_SUCCESS),
    );
  } catch (error) {
    yield put(stopSubmit(ADD_AND_EDIT_EXECUTORS_FORM_ID, error.formErrors));
  }
}

function* addExecutor(newExecutor) {
  const executorsList = yield select(getFormValues(EXECUTORS_FORM_ID));
  const id = yield call(updateExecutors, newExecutor);
  const updatedExecutor = { id, ...newExecutor };
  return [...executorsList.executors, updatedExecutor];
}

function* editExecutor(newExecutor) {
  const selectedExecutor = yield select(selectLastSelectedExecutorData);
  const { executors } = yield select(getFormValues(EXECUTORS_FORM_ID));
  let updatedExecutor = {};
  const newExecutorList = executors.map((executor, index) => {
    if (index === selectedExecutor.index) {
      updatedExecutor = {
        ...newExecutor,
        id: executor.id,
      };
      return updatedExecutor;
    }
    return executor;
  });
  yield call(updateExecutors, updatedExecutor);
  return newExecutorList;
}

export function* handleRemoveExecutors({ payload }) {
  const executorToRemove = R.propOr(null, "executor")(payload);
  const spouseIsExecutor = yield call(getSpouseIsExecutorFormValue);
  yield put(closeToast());
  const { executor } = yield call(submitFormRequest, {
    apiCall: removeExecutorEndpoint,
    values: {
      ...executorToRemove,
      spouseIsExecutor,
    },
  });
  yield put(displayToast(REMOVE_EXECUTOR_SUCCESS));
  // update the form data in the FE
  yield put(getFormData(EXECUTORS_FORM_ID));
  const { executors } = yield select(selectExecutorsForm);
  const newExecutorsList = executors.map((obj) => {
    if (obj.id === executor.id) {
      return {
        ...obj,
        _destroy: true,
      };
    }
    return obj;
  });
  yield put(change(EXECUTORS_FORM_ID, EXECUTORS_FIELD_ID, newExecutorsList));
}

function* handleExecutorsNextPage() {
  const spouseIsExecutor = yield call(getSpouseIsExecutorFormValue);
  return yield call(submitFormRequest, {
    apiCall: getExecutorsNextPage,
    formId: EXECUTORS_FORM_ID,
    values: { spouseIsExecutor },
  });
}

export function* watchFetchExecutors() {
  yield takeEvery(FETCH_EXECUTORS_TYPE, fetchExecutors);
}

export function* watchSubmitSpouseExecutorsForm() {
  yield takeEvery(SUBMIT_SPOUSE_EXECUTORS_FORM_TYPE, submitSpouseExecutorsForm);
}

export function* watchOpenModalAddExecutors() {
  yield takeEvery(OPEN_MODAL_ADD_EXECUTORS_TYPE, handleOpenModalAddExecutors);
}

export function* watchOpenModalEditExecutors() {
  yield takeEvery(OPEN_MODAL_EDIT_EXECUTORS_TYPE, handleOpenModalEditExecutors);
}

export function* watchRemoveExecutors() {
  yield takeEvery(REMOVE_EXECUTORS_TYPE, handleRemoveExecutors);
}

export function* watchExecutorsNextPage() {
  yield takeEvery(EXECUTORS_NEXT_PAGE_TYPE, handleExecutorsNextPage);
}
