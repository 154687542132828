import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";
import { selectTranslations } from "./translations";
import { selectAppointeeLevelIndexs } from "./utils/form-appointees";
import { selectHasChildren, selectNeedsChildrenGuardians } from "./children";
import { selectIsFieldComplete } from "./utils/progress";
import { CHILDREN_GUARDIANS_PATH, CHILDREN_PATH } from "../constants/routes";
import {
  PETS_FORM_ID,
  PETS_FIELD_ID,
  PET_GUARDIANS_FORM_ID,
  PET_GUARDIANS_FIELD_ID,
  PET_TRUST_FORM_ID,
  PET_TRUST_AMOUNT_FIELD_ID,
  NUM_PETS_FIELD_ID,
  NUM_PETS_ZERO,
} from "../constants/forms";

const selectYourFamilyData = R.pathOr({}, ["yourFamily"]);
export const selectHasPets = R.path(["yourFamily", "needsPets"]);
export const selectNeedsPetGuardian = R.path([
  "yourFamily",
  "needsPetGuardian",
]);
export const selectLastSelectedPetData = R.path([
  "formUi",
  "lastSelectedPetData",
]);

export const selectNumberOfPets = createSelector(
  selectYourFamilyData,
  R.prop(NUM_PETS_FIELD_ID),
);

export const selectNumberOfPetsForm = createSelector(
  selectNumberOfPets,
  (NumberOfPets) => ({ [NUM_PETS_FIELD_ID]: NumberOfPets }),
);

export const selectPets = R.path(["yourFamily", PETS_FIELD_ID]);
export const selectHasSkippedPets = R.path(["yourFamily", "petsVisited"]);

export const selectPetsForm = createSelector(selectPets, (pets) => ({
  [PETS_FIELD_ID]: pets,
}));

export const selectIsPetsComplete = createSelector(
  selectNumberOfPets,
  selectHasSkippedPets,
  selectNeedsPetGuardian,
  selectIsFieldComplete(selectYourFamilyData, NUM_PETS_FIELD_ID),
  selectIsFieldComplete(selectYourFamilyData, PETS_FIELD_ID),
  selectIsFieldComplete(selectYourFamilyData, PET_GUARDIANS_FIELD_ID),
  selectIsFieldComplete(selectYourFamilyData, PET_TRUST_AMOUNT_FIELD_ID),
  (numberOfPets, skippedPets, needsPetGuardian, ...petFieldsComplete) => {
    if (numberOfPets === NUM_PETS_ZERO) {
      return true;
    }

    // hasn't inputted any pets and skipped section
    if (!needsPetGuardian && skippedPets) {
      return true;
    }

    return petFieldsComplete.every((isComplete) => isComplete === true);
  },
);

export const selectPetsCurrentPage = createSelector(
  selectNumberOfPets,
  selectHasSkippedPets,
  selectNeedsPetGuardian,
  selectIsFieldComplete(selectYourFamilyData, NUM_PETS_FIELD_ID),
  selectIsFieldComplete(selectYourFamilyData, PETS_FIELD_ID),
  selectIsFieldComplete(selectYourFamilyData, PET_GUARDIANS_FIELD_ID),
  selectIsFieldComplete(selectYourFamilyData, PET_TRUST_AMOUNT_FIELD_ID),
  (numberOfPets, skippedPets, needsPetGuardian, ...petFieldsComplete) => {
    if (numberOfPets === NUM_PETS_ZERO) {
      return 1;
    }
    // if hasn't inputted any pets and skipped section
    if (!needsPetGuardian && skippedPets) {
      return 2;
    }

    return petFieldsComplete.reduce(
      (stepTotal, isComplete) => (isComplete ? stepTotal + 1 : stepTotal),
      0,
    );
  },
);

export const selectPetsTotalPages = createSelector(
  selectNumberOfPets,
  selectHasSkippedPets,
  selectNeedsPetGuardian,
  (numberOfPets, skippedPets, needsPetGuardian) => {
    if (!numberOfPets || numberOfPets === NUM_PETS_ZERO) {
      return 1;
    }
    return skippedPets && !needsPetGuardian ? 2 : 4;
  },
);

export const selectPetGuardians = R.path([
  "yourFamily",
  PET_GUARDIANS_FIELD_ID,
]);
export const selectPetTrustAmount = R.path([
  "yourFamily",
  PET_TRUST_AMOUNT_FIELD_ID,
]);

export const selectPetGuardiansForm = createSelector(
  selectPetGuardians,
  (petGuardians) => ({
    [PET_GUARDIANS_FIELD_ID]: petGuardians,
  }),
);

export const selectPetTrustForm = createSelector(
  selectPetTrustAmount,
  (petTrustAmount) => ({
    [PET_TRUST_AMOUNT_FIELD_ID]: petTrustAmount,
  }),
);

const selectPetBackLink = createSelector(
  selectHasChildren,
  selectNeedsChildrenGuardians,
  (hasChildren, needsChildrenGuardians) => {
    if (hasChildren && needsChildrenGuardians) {
      return CHILDREN_GUARDIANS_PATH;
    }
    return CHILDREN_PATH;
  },
);

export const petsPageProps = createStructuredSelector({
  translations: selectTranslations(["global", "pets", "navigation", "notice"]),
  hasLoaded: selectHasLoadedDataById(PETS_FORM_ID),
  isLoading: selectIsLoadingById(PETS_FORM_ID),
  backLink: selectPetBackLink,
});

export const selectPetGuardiansTranslations = createSelector(
  selectTranslations([
    "global",
    PET_GUARDIANS_FORM_ID,
    "appointees",
    "navigation",
  ]),
  (translations) => translations,
);

export const petGuardiansPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(PET_GUARDIANS_FORM_ID),
  isLoading: selectIsLoadingById(PET_GUARDIANS_FORM_ID),
  appointeeLevelIndexs: selectAppointeeLevelIndexs(PET_GUARDIANS_FORM_ID),
  translations: selectPetGuardiansTranslations,
});

export const petTrustPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(PET_TRUST_FORM_ID),
  isLoading: selectIsLoadingById(PET_TRUST_FORM_ID),
  appointeeLevelIndexs: selectAppointeeLevelIndexs(PET_TRUST_FORM_ID),
  translations: selectTranslations(["global", PET_TRUST_FORM_ID]),
});
