import { takeEvery, call } from "redux-saga/effects";
import { UPDATE_REASONS_TYPE } from "../actions/welcome";
import { submitFormRequest } from "./forms";
import { submitUpdateReasonsRequest } from "../api/user";

function* handleUpdateReasons({ payload }) {
  const { reasons } = payload;
  yield call(submitFormRequest, {
    apiCall: submitUpdateReasonsRequest,
    values: {
      reasons,
    },
  });
}

export function* watchUpdateReasons() {
  yield takeEvery(UPDATE_REASONS_TYPE, handleUpdateReasons);
}
