import React from "react";

import styled from "styled-components";
import SvgIcon from "./SvgIcon";

const StyledSvgIcon = styled(SvgIcon)`
  .isolation {
    isolation: isolate;
  }
`;

const AssetListCardIcon = ({ isDesktop }) => {
  return (
    <StyledSvgIcon
      fill="none"
      width={isDesktop ? 100 : 72}
      viewBox="0 0 165.57 200"
    >
      <g>
        <path
          d="M109.4,116.4l70.5,49.1a4.81,4.81,0,0,1-.2,8L140,197.9l-82.2-62Z"
          transform="translate(-16.37 0)"
          fill="#2f455f"
        />
        <g>
          <g className="isolation">
            <polygon
              points="118.53 200 123.63 197.9 141.63 62.8 136.53 64.8 118.53 200"
              fill="#aad2f6"
            />
            <polygon
              points="136.53 64.8 141.63 62.8 59.53 0 54.43 2.2 136.53 64.8"
              fill="#fff"
            />
            <polygon
              points="54.43 2.2 36.53 137.4 118.53 200 136.53 64.8 54.43 2.2"
              fill="#e2f6ff"
            />
            <polygon
              points="132.93 91.5 136.53 64.8 54.43 2.2 50.83 28.8 132.93 91.5"
              fill="none"
            />
          </g>
          <g>
            <g>
              <polygon
                points="53.73 55.2 54.73 48 59.93 52 59.03 59.2 53.73 55.2"
                fill="#fff"
              />
              <path
                d="M71.3,48.7l4.6,3.5-.8,6.4-4.6-3.5.8-6.4m-.5-1.4-1.1,8.1,5.9,4.5,1.1-8.1-5.9-4.5Z"
                transform="translate(-16.37 0)"
                fill="#0064dc"
              />
            </g>
            <g>
              <polygon
                points="51.43 72.6 52.43 65.3 57.63 69.3 56.73 76.6 51.43 72.6"
                fill="#fff"
              />
              <path
                d="M69.1,66l4.6,3.5-.8,6.4-4.6-3.5.8-6.4m-.6-1.4-1.1,8.1,5.9,4.5,1.1-8.1-5.9-4.5Z"
                transform="translate(-16.37 0)"
                fill="#0064dc"
              />
            </g>
            <g>
              <polygon
                points="49.23 89.8 50.13 82.6 55.43 86.6 54.43 93.8 49.23 89.8"
                fill="#fff"
              />
              <path
                d="M66.8,83.3l4.5,3.5-.8,6.4L66,89.7l.8-6.4m-.6-1.4-1,8.1,5.9,4.5,1.1-8.1-6-4.5Z"
                transform="translate(-16.37 0)"
                fill="#0064dc"
              />
            </g>
            <g>
              <polygon
                points="46.83 107.1 47.83 99.8 53.13 103.9 52.13 111.1 46.83 107.1"
                fill="#fff"
              />
              <path
                d="M64.5,100.5l4.6,3.5-.8,6.4-4.6-3.5.8-6.4m-.6-1.3-1.1,8.1,6,4.6,1.1-8.1-6-4.6Z"
                transform="translate(-16.37 0)"
                fill="#0064dc"
              />
            </g>
            <g>
              <polygon
                points="44.53 124.4 45.43 117.1 50.83 121.2 49.83 128.5 44.53 124.4"
                fill="#fff"
              />
              <path
                d="M62.1,117.8l4.7,3.6-.8,6.4-4.7-3.6.8-6.4m-.6-1.4-1.1,8.1,6,4.6,1.1-8.1-6-4.6Z"
                transform="translate(-16.37 0)"
                fill="#0064dc"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            d="M82.8,24l-1.5-1.3h-.1l-1.4-1.2h-.1l-1.1,2-.4-3.2a.1.1,0,0,0-.1-.1L77,19.4h-.1l-1.1,2L75.5,18a.1.1,0,0,0-.1-.1l-1.6-1.3h-.1l.9,6.4a.1.1,0,0,0,.1.1l1.3,1h.1l1.2-1.9.5,3.1a.1.1,0,0,0,.1.1l1.3,1h.1l2-3.3-.6,4.4a.1.1,0,0,0,.1.1l1.5,1.3h.1l.6-5C82.9,24.1,83,24,82.8,24Z"
            transform="translate(-16.37 0)"
            fill="#0064dc"
          />
          <ellipse
            cx="82.28"
            cy="21.89"
            rx="0.7"
            ry="1.4"
            transform="translate(-16.65 42.69) rotate(-29.04)"
            fill="#0064dc"
          />
          <path
            d="M85.4,23.6l-1.5-1.3h-.1l-.9,7.3a.1.1,0,0,0,.1.1L84.5,31h.1l.9-7.3A.1.1,0,0,0,85.4,23.6Z"
            transform="translate(-16.37 0)"
            fill="#0064dc"
          />
          <path
            d="M98.6,40.4c-.5-.4-1-.3-1.1.2a2.3,2.3,0,0,0,.8,1.8c.5.4,1,.3,1.1-.2A2,2,0,0,0,98.6,40.4Z"
            transform="translate(-16.37 0)"
            fill="#0064dc"
          />
          <path
            d="M87.6,25.5l-1.5-1.3H86l-.9,7.3a.1.1,0,0,0,.1.1l1.5,1.3h.1l.9-7.3A.1.1,0,0,0,87.6,25.5Z"
            transform="translate(-16.37 0)"
            fill="#0064dc"
          />
          <path
            d="M97.9,33.7l-1.5-1.3h-.1l-.9,7.3a.1.1,0,0,0,.1.1L97,41.1h.1l.9-7.3C98,33.9,98,33.8,97.9,33.7Z"
            transform="translate(-16.37 0)"
            fill="#0064dc"
          />
          <path
            d="M95.3,34.1l-1.5-1.3h-.1l-.3,2.6c-.1.6-.4.7-.9.4a1.47,1.47,0,0,1-.5-1.4l.4-2.8a.1.1,0,0,0-.1-.1l-.3-.2-1.3-1.1-1.1-.8-.1-.1v-.2c0-.3.3-.3.6,0,.2.1.3.3.4.4h.1l.2-1.4V28l-.9-.9c-1.1-.8-1.8-.7-2,.6l-.7,5.6a.1.1,0,0,0,.1.1l1.5,1.3h.1l.5-3.5,1.1.8-.2,1.6a4.14,4.14,0,0,0,1.5,3.6c.9.7,1.4.4,1.4.4V38a.1.1,0,0,0,.1.1l1.5,1.3h.1l.6-5A.77.77,0,0,1,95.3,34.1Z"
            transform="translate(-16.37 0)"
            fill="#0064dc"
          />
        </g>
        <ellipse
          cx="140.4"
          cy="70.6"
          rx="3.5"
          ry="6.2"
          transform="translate(-32.91 78.79) rotate(-29.68)"
          fill="#ffdc1e"
        />
        <g>
          <g className="isolation">
            <path
              d="M62.2,103.3c-.3-.2-.4-.6-.2-1.2a3.2,3.2,0,0,1,2.3-1.6c.6-.1,1,.2,1,.6l1.2,5.1C65.1,105.2,63.7,104.2,62.2,103.3Z"
              transform="translate(-16.37 0)"
              fill="#e55d4c"
            />
            <path
              d="M66.5,106.2c-1.4-1-2.9-1.9-4.3-2.9a.88.88,0,0,0,.7.1Z"
              transform="translate(-16.37 0)"
              fill="#0064dc"
            />
            <path
              d="M65.4,101l1.2,5.1L63,103.3a3.2,3.2,0,0,0,2.3-1.6A.82.82,0,0,0,65.4,101Z"
              transform="translate(-16.37 0)"
              fill="#0064dc"
            />
            <path
              d="M63,103.4c-.9.1-1.3-.5-.9-1.4a3.2,3.2,0,0,1,2.3-1.6c.9-.1,1.3.5.9,1.4A3.2,3.2,0,0,1,63,103.4Z"
              transform="translate(-16.37 0)"
              fill="#e55d4c"
            />
          </g>
          <g className="isolation">
            <path
              d="M63,103.4c-.9.1-1.3-.5-.9-1.4a3.2,3.2,0,0,1,2.3-1.6c.9-.1,1.3.5.9,1.4A3.2,3.2,0,0,1,63,103.4Z"
              transform="translate(-16.37 0)"
              fill="#ffca00"
            />
            <path
              d="M55.7,98.9c-.8-.6-1-1.6-.4-2.9A8.08,8.08,0,0,1,61,91.9c1.5-.2,2.4.4,2.7,1.4.6,2.5,1.2,5.1,1.8,7.6-.1-.4-.4-.6-1-.6a3.2,3.2,0,0,0-2.3,1.6.8.8,0,0,0,.2,1.2A63.44,63.44,0,0,1,55.7,98.9Z"
              transform="translate(-16.37 0)"
              fill="#ffca00"
            />
            <path
              d="M62.2,103.3c-2.2-1.4-4.3-2.9-6.5-4.3a2.37,2.37,0,0,0,1.9.3l5.4,4.1A1,1,0,0,1,62.2,103.3Z"
              transform="translate(-16.37 0)"
              fill="#ffdc1e"
            />
            <path
              d="M63.6,93.4c.6,2.5,1.2,5.1,1.8,7.6a2,2,0,0,1-.1.7,3.2,3.2,0,0,1-2.3,1.6l-5.4-4.1a7.41,7.41,0,0,0,5.7-4.1A2.7,2.7,0,0,0,63.6,93.4Z"
              transform="translate(-16.37 0)"
              fill="#ffdc1e"
            />
            <path
              d="M57.6,99.3c-2.3.2-3.3-1.3-2.4-3.3a8.08,8.08,0,0,1,5.7-4.1c2.3-.2,3.3,1.3,2.4,3.3A7.57,7.57,0,0,1,57.6,99.3Z"
              transform="translate(-16.37 0)"
              fill="#ffca00"
            />
          </g>
          <g className="isolation">
            <path
              d="M57.6,99.3c-2.3.2-3.3-1.3-2.4-3.3a8.08,8.08,0,0,1,5.7-4.1c2.3-.2,3.3,1.3,2.4,3.3A7.57,7.57,0,0,1,57.6,99.3Z"
              transform="translate(-16.37 0)"
              fill="#e55d4c"
            />
            <path
              d="M21.7,50.3a2.29,2.29,0,0,1,0-2.4,8.08,8.08,0,0,1,5.7-4.1,2.37,2.37,0,0,1,2.4.9L63.3,92.9a2.82,2.82,0,0,0-2.4-.9,7.41,7.41,0,0,0-5.7,4.1,2.26,2.26,0,0,0,0,2.4C45.2,84,31.8,64.7,21.7,50.3Z"
              transform="translate(-16.37 0)"
              fill="#e55d4c"
            />
            <path
              d="M55.3,98.5,21.7,50.3a2.82,2.82,0,0,0,2.4.9L57.6,99.4C56.5,99.4,55.6,99.1,55.3,98.5Z"
              transform="translate(-16.37 0)"
              fill="#0064dc"
            />
            <path
              d="M29.9,44.7,63.4,92.9a2.29,2.29,0,0,1,0,2.4,8.08,8.08,0,0,1-5.7,4.1L24.1,51.2a7.41,7.41,0,0,0,5.7-4.1C30.3,46,30.3,45.2,29.9,44.7Z"
              transform="translate(-16.37 0)"
              fill="#0064dc"
            />
            <path
              d="M24.1,51.1c-2.3.2-3.3-1.3-2.4-3.3a8.08,8.08,0,0,1,5.7-4.1c2.3-.2,3.3,1.3,2.4,3.3A7.41,7.41,0,0,1,24.1,51.1Z"
              transform="translate(-16.37 0)"
              fill="#e55d4c"
            />
          </g>
          <g className="isolation">
            <path
              d="M24.1,51.1c-2.3.2-3.3-1.3-2.4-3.3a8.08,8.08,0,0,1,5.7-4.1c2.3-.2,3.3,1.3,2.4,3.3A7.41,7.41,0,0,1,24.1,51.1Z"
              transform="translate(-16.37 0)"
              fill="#ffca00"
            />
            <path
              d="M16.8,43.2a2.29,2.29,0,0,1,0-2.4,8.08,8.08,0,0,1,5.7-4.1,2.37,2.37,0,0,1,2.4.9l5,7.1a2.82,2.82,0,0,0-2.4-.9,7.41,7.41,0,0,0-5.7,4.1,2.26,2.26,0,0,0,0,2.4C20.3,48.2,18.3,45.3,16.8,43.2Z"
              transform="translate(-16.37 0)"
              fill="#ffca00"
            />
            <path
              d="M21.7,50.3l-5-7.1a2.82,2.82,0,0,0,2.4.9l5,7.1A2.82,2.82,0,0,1,21.7,50.3Z"
              transform="translate(-16.37 0)"
              fill="#abe0f6"
            />
            <path
              d="M24.9,37.5l5,7.1a2.29,2.29,0,0,1,0,2.4,8.08,8.08,0,0,1-5.7,4.1l-5-7.1a7.41,7.41,0,0,0,5.7-4.1A2.52,2.52,0,0,0,24.9,37.5Z"
              transform="translate(-16.37 0)"
              fill="#abe0f6"
            />
            <path
              d="M19.1,44c-2.3.2-3.3-1.3-2.4-3.3a8.08,8.08,0,0,1,5.7-4.1c2.3-.2,3.3,1.3,2.4,3.3A7.25,7.25,0,0,1,19.1,44Z"
              transform="translate(-16.37 0)"
              fill="#71adee"
            />
          </g>
        </g>
        <polygon
          points="62.43 53.4 61.33 61.5 113.83 101.6 114.93 93.5 62.43 53.4"
          fill="#0064dc"
        />
        <polygon
          points="60.13 70.7 59.03 78.8 103.93 113.1 105.03 105 60.13 70.7"
          fill="#0064dc"
        />
        <polygon
          points="57.83 88 56.83 96.1 106.83 134.4 107.93 126.2 57.83 88"
          fill="#0064dc"
        />
        <polygon
          points="55.53 105.3 54.53 113.4 88.93 139.8 90.03 131.7 55.53 105.3"
          fill="#0064dc"
        />
        <polygon
          points="53.33 122.6 52.23 130.7 99.13 166.6 100.23 158.5 53.33 122.6"
          fill="#0064dc"
        />
        <path
          d="M68.1,89.8H68c-.1,0-.2-.1-.3-.2l-.8-1.4a.62.62,0,0,1,.1-.7c.2-.1.4-.1.6.2l.6.9,1.7-1.9a.37.37,0,0,1,.6,0,.48.48,0,0,1,0,.7l-2,2.3C68.3,89.7,68.2,89.8,68.1,89.8Z"
          transform="translate(-16.37 0)"
          fill="#0064dc"
        />
        <path
          d="M70.4,72.9h-.1c-.1,0-.2-.1-.3-.2l-.8-1.4c-.2-.3-.2-.6,0-.7s.4-.1.6.2l.6.9,1.7-1.9a.37.37,0,0,1,.6,0,.48.48,0,0,1,0,.7l-2,2.3C70.6,72.8,70.5,72.9,70.4,72.9Z"
          transform="translate(-16.37 0)"
          fill="#0064dc"
        />
        <path
          d="M72.7,55.6h-.1c-.1,0-.2-.1-.3-.2L71.5,54a.62.62,0,0,1,.1-.7c.2-.1.4-.1.6.2l.6.9,1.7-1.9a.38.38,0,0,1,.6,0,.48.48,0,0,1,0,.7l-2,2.3C72.8,55.5,72.8,55.5,72.7,55.6Z"
          transform="translate(-16.37 0)"
          fill="#0064dc"
        />
      </g>
    </StyledSvgIcon>
  );
};

export default AssetListCardIcon;
