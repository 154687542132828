import { createSelector } from "reselect";
import R from "ramda";

import {
  selectIsCouplesPlan,
  selectPlansCurrentPageNumber,
  selectPlansTotalPages,
  selectHasUserPaid,
} from "./plans";
import {
  selectIsAboutYouComplete,
  selectAboutYouTotalPages,
  selectAboutYouCurrentPageNumber,
} from "./about-you";
import {
  selectIsYourFamilyComplete,
  selectYourFamilyCurrentPageNumber,
  selectYourFamilyTotalPages,
} from "./your-family";
import {
  selectIsYourEstateComplete,
  selectYourEstateCurrentPageNumber,
  selectYourEstateTotalPages,
} from "./your-estate";
import {
  selectIsArrangementsComplete,
  selectArrangementsTotalPages,
  selectArrangementsCurrentPageNumber,
} from "./your-arrangements";
import {
  selectIsSpouseEstateComplete,
  selectSpouseEstateCurrentPageNumber,
  selectSpouseEstateTotalPages,
} from "./spouse-estate";
import {
  selectIsSpouseArrangementsComplete,
  selectSpouseArrangementsTotalPages,
  selectSpouseArrangementsCurrentPageNumber,
} from "./spouse-arrangements";
import { selectIsExecutorComplete } from "./executors";

// This logic was split out from the dashboard selectors to break up a depencency cycle.
// selectIsCompleteAllSections needed to be used in more places, necessitating the change.

const selectIsCompleteUserSections = createSelector(
  selectIsAboutYouComplete,
  selectIsYourFamilyComplete,
  selectIsYourEstateComplete,
  selectIsArrangementsComplete,
  (...allValues) => allValues.every((val) => val === true),
);

export const selectIsCompleteWillSections = createSelector(
  selectIsAboutYouComplete,
  selectIsYourFamilyComplete,
  selectIsYourEstateComplete,
  selectIsExecutorComplete,
  (...allValues) => allValues.every((val) => val === true),
);

const selectIsCompleteSpouseSections = createSelector(
  selectIsSpouseEstateComplete,
  selectIsSpouseArrangementsComplete,
  (...allValues) => allValues.every((val) => val === true),
);

export const selectIsCompleteAllSections = createSelector(
  selectIsCouplesPlan,
  selectIsCompleteUserSections,
  selectIsCompleteSpouseSections,
  (isCouplesPlan, isUserComplete, isSpouseComplete) => {
    if (isCouplesPlan) {
      return isUserComplete && isSpouseComplete;
    }
    return isUserComplete;
  },
);

export const selectTotalNumberOfPages = createSelector(
  selectIsCouplesPlan,
  selectAboutYouTotalPages,
  selectPlansTotalPages,
  selectYourFamilyTotalPages,
  selectYourEstateTotalPages,
  selectArrangementsTotalPages,
  selectSpouseEstateTotalPages,
  selectSpouseArrangementsTotalPages,
  (
    isCouplesPlan,
    aboutYouCount,
    plansCount,
    yourFamilyCount,
    yourEstateCount,
    yourArrangementsCount,
    spouseEstateCount,
    spouseArrangementsCount,
  ) => {
    let sectionPageCounts = [
      aboutYouCount,
      plansCount,
      yourFamilyCount,
      yourEstateCount,
      yourArrangementsCount,
    ];

    if (isCouplesPlan) {
      sectionPageCounts = [
        ...sectionPageCounts,
        spouseEstateCount,
        spouseArrangementsCount,
      ];
    }

    return R.sum(sectionPageCounts);
  },
);

export const selectOverallSectionCompletionPercentage = createSelector(
  selectIsCouplesPlan,
  selectTotalNumberOfPages,
  selectAboutYouCurrentPageNumber,
  selectPlansCurrentPageNumber,
  selectYourFamilyCurrentPageNumber,
  selectYourEstateCurrentPageNumber,
  selectArrangementsCurrentPageNumber,
  selectSpouseEstateCurrentPageNumber,
  selectSpouseArrangementsCurrentPageNumber,
  (
    isCouplesPlan,
    totalNumberOfPages,
    aboutYouPage,
    plansPage,
    yourFamilyPage,
    yourEstatePage,
    yourArrangementsPage,
    spouseEstatePage,
    spouseArrangementsPage,
  ) => {
    let pagesComplete = [
      aboutYouPage,
      plansPage,
      yourFamilyPage,
      yourEstatePage,
      yourArrangementsPage,
    ];

    if (isCouplesPlan) {
      pagesComplete = [
        ...pagesComplete,
        spouseEstatePage,
        spouseArrangementsPage,
      ];
    }

    const sumPagesComplete = R.sum(pagesComplete);

    return (sumPagesComplete / totalNumberOfPages) * 100;
  },
);

export const selectOverallSectionCompletionPercentageForProgressBar = createSelector(
  selectOverallSectionCompletionPercentage,
  selectHasUserPaid,
  (percentage, hasUserPaid) => {
    return !hasUserPaid && percentage === 100 ? 99 : percentage;
  },
);
