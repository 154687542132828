import dayjs from "dayjs";
import { createSelector } from "reselect";
import { selectTranslations } from "../translations";

export const isAgeOfMajority = (dob, ageOfMajority) => {
  const todaysDate = dayjs(new Date());
  const dateOfBirth = dayjs(dob);
  const userAge = todaysDate.diff(dateOfBirth, "y");

  return userAge >= ageOfMajority;
};

export const isValidSelectKey = (whichKey) =>
  whichKey &&
  (whichKey === "Enter" || whichKey === " " || whichKey === "Spacebar");

export const selectValidationErrorsTranslations = createSelector(
  selectTranslations(["global", "errors"]),
  (translations) => {
    return translations;
  },
);
