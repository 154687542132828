import { takeEvery, call } from "redux-saga/effects";

import {
  UPDATE_WISHES_RESTING_TYPE,
  UPDATE_WISHES_ASHES_TYPE,
  UPDATE_WISHES_CEREMONY_TYPE,
} from "../actions/wishes";
import { submitFormRequest } from "./forms";
import { submitWishes } from "../api/wishes";

import {
  RESTING_FORM_ID,
  ASHES_FORM_ID,
  CEREMONY_FORM_ID,
} from "../constants/forms";

function* updateWishesResting() {
  yield call(submitFormRequest, {
    apiCall: submitWishes,
    formId: RESTING_FORM_ID,
  });
}

function* updateAshes() {
  yield call(submitFormRequest, {
    apiCall: submitWishes,
    formId: ASHES_FORM_ID,
  });
}

function* updateCeremony() {
  yield call(submitFormRequest, {
    apiCall: submitWishes,
    formId: CEREMONY_FORM_ID,
  });
}

export function* watchUpdateWishesResting() {
  yield takeEvery(UPDATE_WISHES_RESTING_TYPE, updateWishesResting);
}

export function* watchUpdateWishesAshes() {
  yield takeEvery(UPDATE_WISHES_ASHES_TYPE, updateAshes);
}

export function* watchUpdateWishesCeremony() {
  yield takeEvery(UPDATE_WISHES_CEREMONY_TYPE, updateCeremony);
}
