import React from "react";

const YourFamilyIcon = ({ width = 156, height = 152 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 156 152"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.07 73.7c-30.618 17.793-30.76 46.404-.425 64.197 30.336 17.793 79.666 17.651 110.284 0 30.619-17.651 30.761-46.404.425-64.197-30.335-17.793-79.665-17.65-110.283 0Z"
      fill="#ECEDEF"
    />
    <path
      d="M54.665 80.074 13.393 106.17l4.718 2.311-.847.482-2.427-1.387-6.375 3.852 23.786 13.597 6.374-3.852-2.6-1.483 2.254-1.29 3.678 1.81h7.338l37.401-22.86-32.028-17.276Z"
      fill="#2F455F"
    />
    <path
      d="M26.007 81.942c0 5.104 1.695 8.705 4.372 10.265l1.33.79.134-.173c1.984.558 4.353.192 6.895-1.272 7.03-4.063 12.73-14.945 12.73-24.305 0-4.102-.867-7.106-2.677-8.994l-1.714-1.29c-2.234-1.29-5.142-1.175-8.32.674-7.049 4.064-12.75 14.945-12.75 24.305Zm2.523.212c0-8.86 5.354-19.144 11.941-22.957 1.522-.867 2.022-1.522 3.332-1.522 3.698 0 6.105 3.756 6.105 9.572 0 8.86-5.354 19.144-11.94 22.957-1.503.867-2.947 1.31-4.257 1.31-1.733 0-3.178-.77-4.237-2.292-1.252-1.714-.944-4.025-.944-7.068Z"
      fill="#2F455F"
    />
    <path
      d="M27.124 82.462c0 3.159.231 5.585 1.502 7.357 1.117 1.58 2.427 2.581 4.237 2.581 1.368 0 2.85-.462 4.43-1.367 6.837-3.948 12.403-14.657 12.403-23.863 0-6.047-2.485-9.957-6.336-9.957-1.368 0-2.312 1.175-3.89 2.08-6.819 3.968-12.346 13.963-12.346 23.17Zm1.04-.019c0-8.532 5.296-18.816 11.594-22.437 1.31-.77 2.542-1.156 3.62-1.156 2.851 0 4.7 3.255 4.7 8.32 0 8.532-5.296 18.817-11.594 22.438-1.329.77-2.542 1.155-3.62 1.155-1.291 0-2.254-.54-3.082-1.695-1.06-1.502-1.618-3.794-1.618-6.625Z"
      fill="#3989E5"
    />
    <path
      d="M26.758 82.578c0 9.36 5.701 13.674 12.73 9.61 7.03-4.064 12.731-14.945 12.731-24.305s-5.7-13.674-12.73-9.61c-7.03 4.063-12.73 14.925-12.73 24.305Zm1.58 0c0-8.86 5.354-19.144 11.94-22.958 1.522-.866 2.928-1.155 4.237-1.155 3.698 0 6.125 3.601 6.125 9.418 0 8.859-5.354 19.143-11.941 22.957-1.502.866-2.947 1.31-4.256 1.31-1.734 0-3.178-.771-4.237-2.293-1.233-1.733-1.868-4.236-1.868-7.28Z"
      fill="#0064DC"
    />
    <path
      d="M28.164 83.098c0 3.158.674 5.778 1.926 7.569 1.117 1.579 2.6 2.388 4.41 2.388 1.368 0 2.85-.462 4.43-1.368 6.837-3.948 12.403-14.656 12.403-23.862 0-6.047-2.484-9.957-6.336-9.957-1.368 0-2.85.462-4.43 1.368-6.837 3.948-12.403 14.656-12.403 23.862Zm1.194.135c-.732-8.513 5.316-19.722 11.613-23.362 1.31-.77 2.947-1.02 4.026-1.02 2.85 0 4.737 3.909 4.737 8.974 0 8.532-5.296 18.816-11.594 22.437-1.328.77-2.542 1.156-3.62 1.156-1.29 0-2.6-.443-3.41-1.599-1.078-1.502-1.463-3.12-1.752-6.586Z"
      fill="#3989E5"
    />
    <path
      d="M27.933 82.886c0 3.158.655 5.778 1.926 7.569 1.117 1.579 2.6 2.388 4.41 2.388 1.368 0 2.85-.462 4.43-1.368 6.837-3.948 12.403-14.656 12.403-23.862 0-6.047-2.484-9.957-6.336-9.957-1.368 0-2.85.462-4.43 1.368C33.5 62.99 27.933 73.68 27.933 82.886Zm1.637 0c0-8.532 5.296-18.816 11.594-22.437 1.31-.77 2.542-1.156 3.621-1.156 2.85 0 4.7 3.255 4.7 8.32 0 8.532-5.297 18.817-11.595 22.437-1.329.77-2.542 1.156-3.62 1.156-1.291 0-2.254-.54-3.082-1.695-1.06-1.502-1.618-3.794-1.618-6.625Z"
      fill="#004EB6"
    />
    <path
      d="M35.656 76.049c0 1.155.539 1.83 1.29 1.791.231 0 .559 0 .828-.154.54-.308 1.079-.905 1.445-1.56.404-.732.674-1.637.674-2.427 0-1.502-1.06-2.215-2.196-1.56-1.136.655-2.041 2.408-2.041 3.91Zm3.428-2.292c-.058-.424-.25-.713-.54-.809l-.192-.058c-.115-.038-.154-.23-.077-.423a.704.704 0 0 1 .212-.29c.038-.018.096-.038.135-.019l.192.058c.231.077.424.231.578.463.096.192.173.404.212.674l.038.366c.02.173-.077.404-.211.5l-.039.039c-.116.077-.231.02-.25-.154l-.058-.347Zm-2.427 3.544a.586.586 0 0 1 .231-.347c.039-.02.058-.02.097-.038l.27-.02c.327-.019.693-.27 1-.654l.193-.25c.116-.155.25-.174.328-.078.019 0 .019.02.019.02.02.038.02.096.02.134 0 .116-.04.27-.136.385l-.192.25c-.25.309-.52.56-.809.733a1.68 1.68 0 0 1-.616.211l-.27.02c-.038 0-.077 0-.096-.039-.058-.058-.077-.173-.039-.327Zm-.712-.983-.039-.173.02-.23c.019-.386.076-.714.211-1.099.096-.308.231-.539.405-.809l.27-.481c.115-.173.115-.366.211-.25.039.038.058.096.058.173a.64.64 0 0 1-.096.308l-.231.385c-.27.443-.443.983-.443 1.445v.308c0 .192-.116.404-.27.481-.154.058-.096.116-.096-.058Z"
      fill="#0064DC"
    />
    <path
      d="M37.91 72.255c-1.137.654-2.042 2.407-2.042 3.91 0 1.502.925 2.195 2.042 1.54 1.136-.655 2.041-2.407 2.041-3.91.02-1.502-.905-2.195-2.041-1.54Zm-1.406 3.736c0 .193-.116.404-.27.481-.135.078-.25-.019-.25-.192v-.328a4.457 4.457 0 0 1 .636-2.06l.23-.385c.116-.174.27-.25.366-.154.039.038.058.096.058.173a.78.78 0 0 1-.096.327l-.231.385c-.27.443-.443.983-.443 1.445v.308Zm2.407.578-.193.25c-.25.328-.52.559-.808.732a1.68 1.68 0 0 1-.617.212l-.27.02c-.134 0-.192-.155-.153-.348a.586.586 0 0 1 .23-.346c.04-.02.058-.02.097-.039l.27-.019c.327-.02.693-.25 1.001-.655l.193-.25c.115-.154.27-.193.346-.058.02.039.039.096.039.135 0 .115-.058.27-.135.366Zm.732-2.215c-.02.02-.02.02-.038.02-.116.076-.232.019-.25-.136l-.04-.365c-.057-.424-.25-.713-.539-.81l-.192-.057c-.116-.039-.154-.231-.077-.424a.705.705 0 0 1 .212-.289c.038-.019.096-.038.134-.019l.193.058c.231.058.424.23.578.462.096.173.173.404.212.674l.038.366a.728.728 0 0 1-.231.52Z"
      fill="#004EB6"
    />
    <path d="M37.813 63.087v26.25h.115V62.973l-.115.115Z" fill="#004EB6" />
    <path
      d="m33.922 90.936.115.02 3.698-15.254.077-.328v-.077l.02-.077v-.135l.057-.192 3.563-14.599c-.038.02-.096.039-.135.078l-3.39 14.02-.096.385-.019.077-.019.078-.02.096-.019.038V75.317l-.019.038-.019.096-.039.135-.019.097-.115.5-3.621 14.753Z"
      fill="#004EB6"
    />
    <path
      d="M30.995 89.203c.019.038.057.077.077.115l6.432-13.154.231-.462.097-.173.019-.058.038-.077.02-.02.019-.019v-.019l.02-.02v-.038l.019-.038v-.02l.019-.019.038-.077.02-.057.038-.077.154-.328 7.415-15.157-.096-.058-7.627 15.581v.02l-.039.057v.02l-.019.019v.039l-.02.018-.018.058v.039l-.039.019-.038.077-.058.115-.077.155-6.625 13.539Z"
      fill="#004EB6"
    />
    <path
      d="m37.466 75.606-7.762 9.552c0 .058.02.097.02.154l7.915-9.726.058-.077.115-.134v-.02l.02-.038v-.02l.019-.019.019-.019.02-.02.018-.019.02-.019.058-.057.057-.058.135-.154 9.842-12.095-.097-.077-9.706 11.94-.309.366v.02l-.038.038v.02l-.02.019-.019.019-.019.02-.019.019v.019l-.02.019-.019.02h-.019l-.019.019-.02.019-.038.058-.038.057-.154.174Z"
      fill="#004EB6"
    />
    <path
      d="m29.608 81.711 7.858-6.086.212-.154.096-.058.058-.038.038-.02.02-.019.038-.019.02-.02h.018l.02-.019.135-.096.23-.173 10.458-8.127-.077-.097-10.554 8.128-.115.096-.154.115-.039.02-.02.019-.038.02h-.039l-.018.018-.02.02h-.019l-.02.019-.038.038-.173.135-7.858 6.106c-.02.096-.02.134-.02.192Z"
      fill="#004EB6"
    />
    <path
      d="m29.916 78.957 7.55-3.505.192-.077.039-.02.077-.038h.02l.018-.02h.02l.019-.018h.038v-.02h.039v-.019h.02l.057-.038.327-.135 10.265-4.757-.057-.097-10.208 4.719-.289.135-.019.019-.096.038-.039.02h-.019v.019h-.038l-.02.02h-.019l-.02.018h-.019l-.019.02h-.019l-.02.019h-.018l-.155.077-.134.058-7.454 3.447-.019.135Z"
      fill="#004EB6"
    />
    <path
      d="m30.359 76.838 7.03-1.463.27-.058h.038l.096-.02h.02l.057-.019h.097l.096-.019.077-.02 9.88-2.06-.02-.115-9.687 2.002-.212.039-.115.02-.078.019h-.27l-.076.019-.405.077-6.779 1.406c0 .096-.02.135-.02.192Z"
      fill="#004EB6"
    />
    <path
      d="m30.821 75.124 6.337.174.346.019h.578l.366.019 8.975.231v-.115l-9.302-.25h-.674l-6.626-.174c.039.038.02.058 0 .096Z"
      fill="#004EB6"
    />
    <path
      d="m31.341 73.603 6.144 1.579.096.02.097.019.038.019H37.774l.02.019h.077l.038.02.038.019h.02l.057.019.193.058 8.474 2.195.02-.115-8.263-2.138-.404-.096-.058-.02h-.039l-.019-.019h-.116l-.038-.02h-.039l-.096-.019-.289-.077-5.95-1.521c-.04 0-.04.038-.059.058Z"
      fill="#004EB6"
    />
    <path
      d="m31.9 72.197 5.431 2.831.308.154h.02l.057.02.02.019.019.019h.019l.02.02h.095l.04.018.018.02.058.038.077.039.212.115 7.627 3.987.057-.096-7.703-4.026-.135-.057-.154-.077-.02-.02-.019-.019h-.039l-.038-.02h-.039l-.038-.019h-.039l-.057-.038-.212-.115-5.508-2.89c-.039.02-.058.058-.077.097Z"
      fill="#004EB6"
    />
    <path
      d="m32.555 70.752 4.91 4.218.155.135.096.077h.02l.019.02.019.018.02.02h.077l.038.038.019.02h.02l.096.076.192.154 6.702 5.759.077-.077-6.702-5.758-.23-.193-.02-.02-.077-.076h-.02l-.019-.02h-.019l-.038-.019v-.02l-.02-.018-.019-.02-.038-.038h-.02l-.02-.02-.192-.173-4.95-4.237c-.057.077-.076.116-.076.154Z"
      fill="#004EB6"
    />
    <path
      d="m33.344 69.192 4.18 5.701.154.192.038.04.02.018.019.02.019.019.02.02.018.018.02.02v.019h.019l.02.02.038.057.038.058.02.019.173.25 5.547 7.55.096-.058-5.547-7.55-.154-.192-.096-.096-.058-.058h-.02l-.019-.02v-.038l-.019-.02-.02-.018-.018-.02-.039-.058-.038-.057-.02-.039-.115-.154-4.218-5.758-.058.115Z"
      fill="#004EB6"
    />
    <path
      d="m34.423 67.363 3.216 7.472.096.232.02.038.019.02v.019l.02.019v.038l.019.039v.02l.019.018v.02l.038.077.058.115.058.135 4.237 9.9.096-.04-4.198-9.783-.097-.231-.038-.077-.02-.058-.019-.058v-.019l-.019-.02v-.018l-.02-.02-.019-.038v-.078l-.057-.134-.039-.02-.096-.23-3.197-7.434c-.039.019-.058.057-.077.096Z"
      fill="#004EB6"
    />
    <path
      d="m36.002 65.148 1.695 9.533.057.308.02.077v.02l.02.057V75.394l.018.115.097.501 2.06 11.633.116-.02-2.1-11.998-.038-.193-.02-.077v-.289l-.057-.27-.02-.096-1.713-9.668c-.077.039-.116.077-.135.116Z"
      fill="#004EB6"
    />
    <path
      d="m40.22 75.259-1.81-1.04c-.02-.02-.039-.039-.077-.039-.116-.057-.25-.038-.404.058-.386.212-.675.81-.675 1.31 0 .289.097.481.25.558l1.83 1.06.02-.135c.038-.02.096-.039.135-.058.385-.212.674-.809.674-1.31a.83.83 0 0 0-.058-.327l.115-.077Z"
      fill="#666"
    />
    <path
      d="M40.413 75.78c0 .5-.308 1.078-.674 1.309-.386.212-.674-.02-.674-.52s.308-1.079.674-1.31c.366-.212.674.02.674.52Z"
      fill="#004EB6"
    />
    <path
      d="M37.466 77.147c0 1.155.54 1.83 1.29 1.79.232 0 .56 0 .829-.153.54-.309 1.078-.906 1.444-1.56.405-.732.674-1.637.674-2.427 0-1.502-1.059-2.215-2.195-1.56-1.137.655-2.042 2.407-2.042 3.91Zm3.428-2.292c-.057-.424-.25-.713-.539-.81l-.192-.057c-.116-.038-.154-.231-.077-.424a.703.703 0 0 1 .211-.288c.039-.02.097-.04.135-.02l.193.058c.23.077.423.231.578.462.096.193.173.405.211.674l.039.366c.02.174-.077.405-.212.5l-.038.04c-.116.077-.232.019-.25-.155l-.059-.346Zm-2.426 3.543a.585.585 0 0 1 .23-.346c.04-.02.059-.02.097-.039l.27-.019c.327-.02.693-.27 1.001-.655l.193-.25c.115-.154.25-.174.327-.077.02 0 .02.019.02.019.019.038.019.096.019.135 0 .115-.039.27-.135.385l-.193.25a3.17 3.17 0 0 1-.808.732 1.68 1.68 0 0 1-.617.212l-.27.02c-.038 0-.076 0-.096-.04-.057-.057-.077-.172-.038-.327Zm-.713-.982-.038-.173.019-.231c.02-.385.077-.713.212-1.098.096-.308.23-.54.404-.809l.27-.481c.115-.174.115-.366.212-.25.038.038.057.096.057.173a.639.639 0 0 1-.096.308l-.23.385c-.27.443-.444.982-.444 1.444v.309c0 .192-.115.404-.27.481-.154.058-.096.115-.096-.058Z"
      fill="#0064DC"
    />
    <path
      d="M39.72 73.352c-1.137.655-2.042 2.408-2.042 3.91 0 1.502.925 2.195 2.042 1.54 1.136-.654 2.041-2.407 2.041-3.909.02-1.502-.905-2.195-2.041-1.54Zm-1.406 3.737c0 .192-.116.404-.27.481-.135.077-.25-.02-.25-.192v-.328a4.457 4.457 0 0 1 .636-2.06l.23-.386c.116-.173.27-.25.366-.154.039.039.058.096.058.174a.783.783 0 0 1-.096.327l-.231.385c-.27.443-.443.982-.443 1.445v.308Zm2.407.577-.192.25c-.25.328-.52.56-.81.733a1.68 1.68 0 0 1-.616.212l-.27.019c-.134 0-.192-.154-.153-.347a.585.585 0 0 1 .23-.347c.04-.019.059-.019.097-.038l.27-.02c.327-.019.693-.25 1.001-.654l.193-.25c.115-.155.27-.193.347-.058.019.038.038.096.038.135 0 .115-.058.27-.135.365Zm.732-2.214c-.02.019-.02.019-.039.019-.115.077-.23.02-.25-.135l-.038-.366c-.058-.424-.25-.713-.54-.809l-.192-.058c-.116-.038-.154-.23-.077-.423a.705.705 0 0 1 .212-.29c.038-.018.096-.038.134-.018l.193.057c.231.058.424.231.578.463.096.173.173.404.212.674l.038.366a.727.727 0 0 1-.231.52Z"
      fill="#004EB6"
    />
    <path
      d="m65.99 39.052-1.021-.655.77-.424 1.04.655-.79.424Z"
      fill="#0064DC"
    />
    <path d="M66.78 47.526v-8.898l-.79.424v8.897l.79-.423Z" fill="#2F455F" />
    <path d="m65.99 47.95-1.021-.675v-8.878l1.02.655v8.898Z" fill="#0064DC" />
    <path
      d="m67.703 36.124-.096-.635 4.256-2.408h.02l.057-.038c.116-.058.25-.058.405.019.077.038.154.077.23.135l.116.096c.077.077.154.173.212.27.212.308.347.693.385 1.059v.02c.039.404-.096.712-.308.827h-.019l-4.256 2.408-.655-.212.751-.462a2.17 2.17 0 0 1-.636-1.349l-.462.27Z"
      fill="#ABE0F6"
    />
    <path
      d="M67.28 36.355c.057.674.481 1.368.943 1.56.463.193.81-.192.751-.847-.057-.674-.481-1.367-.943-1.56-.482-.212-.81.173-.751.847Z"
      fill="#71ADEE"
    />
    <path
      d="m65.99 38.07-1.021-.675 2.696-1.522 1.02.655-2.695 1.541Z"
      fill="#3989E5"
    />
    <path
      d="M68.686 37.51v-.982L65.99 38.07v.963l2.696-1.521Z"
      fill="#2F455F"
    />
    <path
      d="m65.99 39.032-1.021-.655v-.982l1.02.674v.963ZM44.69 27.304l-1.021-.655.79-.424 1.02.655-.79.424Z"
      fill="#0064DC"
    />
    <path d="M45.48 35.778V26.88l-.79.424V36.2l.79-.423Z" fill="#2F455F" />
    <path d="m44.69 36.202-1.021-.675V26.65l1.02.655v8.898Z" fill="#0064DC" />
    <path
      d="m46.423 24.376-.096-.636 4.256-2.407h.02l.057-.038c.116-.058.25-.058.404.019.078.038.154.077.232.135l.115.096c.077.077.154.173.212.27.212.308.347.693.385 1.059v.02c.039.404-.096.712-.308.827h-.02l-4.256 2.408-.654-.212.75-.462a2.169 2.169 0 0 1-.635-1.348l-.462.27Z"
      fill="#ABE0F6"
    />
    <path
      d="M45.98 24.607c.058.674.481 1.368.944 1.56.462.193.809-.192.75-.847-.057-.674-.48-1.367-.943-1.56-.462-.212-.809.173-.751.847Z"
      fill="#71ADEE"
    />
    <path
      d="m44.69 26.321-1.021-.674 2.696-1.521 1.02.674-2.696 1.521Z"
      fill="#3989E5"
    />
    <path
      d="M47.386 25.763V24.8l-2.697 1.521v.963l2.697-1.521Z"
      fill="#2F455F"
    />
    <path
      d="m44.69 27.284-1.021-.655v-.982l1.02.674v.963ZM17.398 67.228l-.982-.597 1.252-.674.963.597-1.233.674Z"
      fill="#0064DC"
    />
    <path
      d="M18.631 83.425v-16.87l-1.233.673V84.08l1.233-.655Z"
      fill="#2F455F"
    />
    <path d="m17.398 84.08-.982-.578v-16.87l.982.596V84.08Z" fill="#0064DC" />
    <path
      d="m16.224 68.75-2.831-1.85 28.889-16.563 2.83 1.83-28.888 16.582Z"
      fill="#E2F6FF"
    />
    <path
      d="M45.112 54.555v-2.388L16.224 68.749v2.388l28.888-16.582Z"
      fill="#71ADEE"
    />
    <path
      d="m16.224 71.137-2.831-1.83V66.9l2.83 1.85v2.387ZM62.1 50.01 38.892 36.953l.289 36.688L62.369 86.72 62.1 50.01Z"
      fill="#ABE0F6"
    />
    <path
      d="m67.05 83.945-4.68 2.774-.27-36.709 4.68-2.754.27 36.69Z"
      fill="#004EB6"
    />
    <path
      d="M66.78 47.256 43.572 34.18l-4.68 2.773L62.1 50.01l4.68-2.754Z"
      fill="#71ADEE"
    />
    <path
      d="M39.778 116.975c0 1.406.462 2.388 1.194 2.831l.366.212.039-.039c.54.154 1.194.058 1.906-.347 1.946-1.117 3.506-4.121 3.506-6.702 0-1.136-.25-1.964-.732-2.484l-.482-.366c-.616-.347-1.406-.328-2.292.192-1.925 1.117-3.505 4.122-3.505 6.703Zm.694.057c0-2.446 1.483-5.277 3.293-6.317.424-.25.559-.423.924-.423 1.021 0 1.676 1.04 1.676 2.638 0 2.446-1.483 5.277-3.293 6.317-.424.231-.81.366-1.175.366-.482 0-.867-.212-1.175-.635-.327-.482-.25-1.117-.25-1.946Z"
      fill="#2F455F"
    />
    <path
      d="M40.087 117.109c0 .867.058 1.541.404 2.023.309.443.674.712 1.175.712.385 0 .79-.135 1.214-.385 1.887-1.098 3.428-4.044 3.428-6.567 0-1.657-.694-2.735-1.753-2.735-.385 0-.636.327-1.078.578-1.869 1.078-3.39 3.832-3.39 6.374Zm.289 0c0-2.349 1.464-5.18 3.197-6.182.366-.212.693-.308 1.001-.308.79 0 1.29.905 1.29 2.292 0 2.349-1.463 5.181-3.196 6.182-.366.212-.694.308-1.002.308-.346 0-.616-.154-.847-.462-.29-.424-.443-1.059-.443-1.83Z"
      fill="#3989E5"
    />
    <path
      d="M39.99 117.148c0 2.581 1.56 3.775 3.506 2.658 1.945-1.117 3.505-4.122 3.505-6.702 0-2.581-1.56-3.775-3.505-2.658-1.945 1.117-3.505 4.121-3.505 6.702Zm.424 0c0-2.446 1.483-5.277 3.294-6.317a2.23 2.23 0 0 1 1.175-.327c1.02 0 1.694 1.001 1.694 2.6 0 2.446-1.483 5.277-3.293 6.317-.424.231-.809.366-1.175.366-.481 0-.866-.212-1.175-.636-.327-.481-.52-1.175-.52-2.003Z"
      fill="#71ADEE"
    />
    <path
      d="M40.376 117.283c0 .866.193 1.598.54 2.08.307.443.712.654 1.213.654.385 0 .79-.134 1.213-.385 1.887-1.097 3.428-4.044 3.428-6.567 0-1.656-.693-2.735-1.753-2.735-.385 0-.79.135-1.213.385-1.887 1.079-3.428 4.045-3.428 6.568Zm.327.038c-.192-2.349 1.464-5.431 3.197-6.432a2.35 2.35 0 0 1 1.117-.289c.79 0 1.31 1.078 1.31 2.465 0 2.35-1.464 5.181-3.197 6.182-.366.212-.693.308-1.001.308-.347 0-.713-.115-.944-.443-.289-.385-.405-.828-.482-1.791Z"
      fill="#3989E5"
    />
    <path
      d="M40.318 117.225c0 .867.193 1.599.54 2.08.308.443.712.655 1.213.655.385 0 .79-.135 1.213-.385 1.888-1.098 3.428-4.045 3.428-6.568 0-1.656-.693-2.735-1.752-2.735-.385 0-.79.135-1.214.386-1.906 1.097-3.428 4.044-3.428 6.567Zm.443 0c0-2.35 1.464-5.181 3.197-6.182.366-.212.694-.308 1.002-.308.79 0 1.29.905 1.29 2.292 0 2.349-1.464 5.18-3.197 6.182-.366.212-.693.308-1.001.308-.347 0-.617-.154-.848-.462-.289-.405-.443-1.04-.443-1.83Z"
      fill="#004EB6"
    />
    <path
      d="M42.437 115.357c0 .327.154.5.346.5.058 0 .154 0 .231-.038.154-.077.308-.25.405-.424.115-.212.192-.443.192-.674 0-.424-.289-.616-.597-.424-.327.155-.578.636-.578 1.06Zm.943-.636c-.019-.115-.077-.192-.154-.212l-.058-.019c-.038 0-.038-.058-.019-.115.02-.039.039-.058.058-.078h.038l.058.02a.257.257 0 0 1 .154.135c.02.057.058.115.058.192l.02.096c0 .039-.02.116-.058.135h-.02c-.038.02-.057 0-.077-.038v-.116Zm-.674.963c.02-.038.039-.077.058-.096h.096c.096 0 .193-.077.27-.173l.058-.078c.038-.038.077-.057.096-.019v.039c0 .038-.02.077-.039.096l-.057.077a.925.925 0 0 1-.212.193.314.314 0 0 1-.174.057h-.096v-.096Zm-.192-.269v-.116c0-.116.019-.193.057-.308a.65.65 0 0 1 .116-.231l.077-.135c.038-.058.038-.096.058-.077.019.019.019.019.019.058 0 .019 0 .057-.02.096l-.057.096a.76.76 0 0 0-.116.405v.077a.165.165 0 0 1-.077.135c-.077.038-.057.057-.057 0Z"
      fill="#0064DC"
    />
    <path
      d="M43.053 114.298c-.308.173-.559.654-.559 1.078 0 .424.25.616.559.424.308-.174.558-.655.558-1.079 0-.423-.23-.597-.558-.423Zm-.386 1.04a.161.161 0 0 1-.077.134c-.038.02-.057 0-.057-.057v-.077c0-.097.019-.212.057-.328a.526.526 0 0 1 .116-.231l.058-.096c.038-.058.077-.077.096-.039.02.019.02.039.02.058 0 .019 0 .058-.02.096l-.058.097a.755.755 0 0 0-.115.404l-.02.039Zm.675.154-.058.077a.896.896 0 0 1-.212.192.307.307 0 0 1-.173.058h-.077c-.039 0-.058-.038-.039-.096a.438.438 0 0 1 .058-.097h.096c.096 0 .193-.077.27-.173l.057-.077c.039-.038.078-.058.097-.019v.038c.02.02 0 .058-.02.097Zm.192-.617c-.038.02-.077.02-.077-.038l-.02-.096c-.018-.116-.076-.193-.153-.212l-.058-.02c-.038 0-.038-.057-.02-.115.02-.039.04-.058.058-.077.02 0 .02-.019.039 0l.058.019a.257.257 0 0 1 .154.135c.02.058.058.115.058.192l.019.097c0 .019-.02.096-.058.115Z"
      fill="#004EB6"
    />
    <path
      d="M43.034 111.775v7.241h.038v-7.261c-.02 0-.038 0-.038.02Z"
      fill="#004EB6"
    />
    <path
      d="M41.955 119.44h.039l1.02-4.199.02-.096v-.02l.019-.057.963-4.026c-.02 0-.02.02-.039.02l-.943 3.871-.02.115v.174l-.038.135-1.02 4.083Z"
      fill="#004EB6"
    />
    <path
      d="M41.146 118.977c0 .02.02.02.02.039l1.771-3.621.058-.135.02-.057v-.02l.018-.019v-.038l.02-.02.038-.096 2.042-4.179-.02-.019-2.099 4.294V115.164l-.02.039-.018.038-1.83 3.736Z"
      fill="#004EB6"
    />
    <path
      d="m42.937 115.222-2.138 2.639v.038l2.177-2.677.019-.019.038-.039v-.019l.02-.019.019-.02.038-.038 2.716-3.332-.02-.019-2.676 3.293-.077.096-.02.02-.019.019-.02.019-.057.058Z"
      fill="#004EB6"
    />
    <path
      d="m40.78 116.898 2.157-1.676.058-.038.02-.02.019-.019h.019l.038-.019.058-.039 2.889-2.234-.02-.019-2.907 2.253-.039.02-.038.038-.02.019-.057.039-2.177 1.675v.02Z"
      fill="#004EB6"
    />
    <path
      d="m40.857 116.146 2.08-.963.058-.019v-.019h.039v-.02h.039l.019-.019.077-.038 2.83-1.31v-.019l-2.83 1.29-.077.039v.019h-.039v.019h-.039v.019h-.019l-.038.02-.039.019-2.06.944v.038Z"
      fill="#004EB6"
    />
    <path
      d="m40.973 115.569 1.945-.405.077-.019h.097l2.715-.559v-.038l-2.658.558-.058.02h-.097l-.115.019-1.868.385c-.02 0-.038.019-.038.039Z"
      fill="#004EB6"
    />
    <path
      d="m41.108 115.087 1.753.039h.365l2.466.057v-.038l-2.562-.077h-.173l-1.83-.039c-.02.039-.02.058-.02.058Z"
      fill="#004EB6"
    />
    <path
      d="m41.262 114.663 1.694.443h.117l.057.02 2.369.635v-.038l-2.273-.597-.115-.02h-.039l-.077-.019-1.637-.424c-.096-.019-.096 0-.096 0Z"
      fill="#004EB6"
    />
    <path
      d="m41.397 114.278 1.502.79.077.038h.039l.018.019.058.039 2.1 1.098.019-.02-2.119-1.117-.038-.019-.039-.019h-.039l-.057-.039-1.521-.789c.019 0 .019.019 0 .019Z"
      fill="#004EB6"
    />
    <path
      d="m41.59 113.893 1.347 1.156.039.038.019.02.02.019.057.038 1.849 1.58.02-.02-1.85-1.579-.058-.058-.019-.019-.019-.019-.058-.039-1.367-1.175c.02.039.02.039.02.058Z"
      fill="#004EB6"
    />
    <path
      d="m41.801 113.45 1.156 1.579.038.058v.019l.02.019.019.02.058.077 1.521 2.08.02-.019-1.522-2.08-.039-.058-.019-.02-.02-.019-.018-.019-.02-.019v-.02l-.038-.038-1.156-1.579c.02 0 0 .019 0 .019Z"
      fill="#004EB6"
    />
    <path
      d="m42.11 112.949.885 2.061.02.058v.039l.019.038.02.039 1.174 2.734.02-.019-1.156-2.696-.02-.058V115.087l-.019-.038-.02-.058-.885-2.042c-.039-.019-.039 0-.039 0Z"
      fill="#004EB6"
    />
    <path
      d="m42.533 112.333.462 2.619.02.077v.077l.019.135.578 3.197h.038l-.578-3.293v-.116l-.019-.077v-.019l-.481-2.658c-.02.039-.039.058-.039.058Z"
      fill="#004EB6"
    />
    <path
      d="m43.688 115.125-.5-.288s-.02 0-.02-.02c-.038-.019-.076-.019-.115.02a.458.458 0 0 0-.193.366c0 .077.02.134.077.154l.501.289v-.039c.02 0 .02 0 .039-.019a.461.461 0 0 0 .192-.366c0-.039 0-.058-.019-.097h.038Z"
      fill="#666"
    />
    <path
      d="M43.746 115.28c0 .134-.077.308-.192.366-.096.057-.193 0-.193-.135s.077-.308.193-.366c.115-.077.192-.019.192.135Z"
      fill="#004EB6"
    />
    <path
      d="M42.937 115.646c0 .327.154.501.347.501.058 0 .154 0 .23-.039.155-.077.309-.25.405-.424.116-.212.193-.443.193-.674 0-.423-.29-.616-.597-.423-.328.154-.578.654-.578 1.059Zm.944-.636c-.02-.115-.077-.192-.154-.212l-.058-.019c-.039 0-.039-.058-.02-.115a.256.256 0 0 1 .058-.077h.039l.058.019a.257.257 0 0 1 .154.135c.019.057.058.115.058.192l.019.097c0 .038-.02.115-.058.134h-.02c-.038.02-.057 0-.076-.038v-.116Zm-.674.982c.019-.038.038-.077.057-.096h.097c.096 0 .192-.077.27-.173l.057-.077c.039-.039.077-.058.096-.019v.038c0 .039-.019.077-.038.096l-.058.077a.904.904 0 0 1-.212.193.307.307 0 0 1-.173.058h-.097v-.097Zm-.193-.269v-.116c0-.115.02-.192.058-.308a.65.65 0 0 1 .115-.231l.077-.135c.039-.058.039-.096.058-.077.02.019.02.019.02.058 0 .019 0 .058-.02.096l-.058.097a.755.755 0 0 0-.115.404v.077a.162.162 0 0 1-.077.135c-.077.038-.058.038-.058 0Z"
      fill="#0064DC"
    />
    <path
      d="M43.553 114.606c-.308.173-.558.654-.558 1.078 0 .424.25.616.558.424.308-.174.559-.655.559-1.079.019-.423-.231-.616-.559-.423Zm-.385 1.02a.162.162 0 0 1-.077.135c-.039.019-.058 0-.058-.058v-.077c0-.096.02-.212.058-.327a.526.526 0 0 1 .115-.231l.058-.096c.039-.058.077-.078.097-.039.019.019.019.039.019.058 0 .019 0 .058-.02.096l-.057.096a.76.76 0 0 0-.116.405l-.019.038Zm.674.174-.058.077a.896.896 0 0 1-.212.192.307.307 0 0 1-.173.058h-.077c-.039 0-.058-.038-.039-.096a.434.434 0 0 1 .058-.097h.097c.096 0 .192-.077.27-.173l.057-.077c.038-.038.077-.058.096-.019v.038c.02.02 0 .058-.019.097Zm.193-.617c-.039.02-.077.02-.077-.038l-.02-.096c-.019-.116-.077-.193-.154-.212l-.057-.02c-.039 0-.039-.057-.02-.115a.256.256 0 0 1 .058-.077c.02 0 .02-.019.038 0l.058.019a.257.257 0 0 1 .154.135c.02.058.058.115.058.193l.02.096a.21.21 0 0 1-.058.115Z"
      fill="#004EB6"
    />
    <path d="m43.4 94.326-.424-.25.539-.29.404.251-.52.289Z" fill="#0064DC" />
    <path d="M43.92 115.607v-21.57l-.52.289v21.57l.52-.289Z" fill="#004EB6" />
    <path d="m43.4 115.896-.424-.25v-21.57l.424.25v21.57Z" fill="#71ADEE" />
    <path
      d="M15.781 103.32c0 1.406.463 2.388 1.194 2.831l.366.212.039-.039c.539.154 1.194.058 1.906-.346 1.946-1.117 3.506-4.122 3.506-6.703 0-1.136-.25-1.964-.732-2.484l-.482-.366c-.616-.347-1.406-.327-2.292.193-1.945 1.117-3.505 4.121-3.505 6.702Zm.674.058c0-2.446 1.483-5.277 3.294-6.317.423-.25.558-.424.924-.424 1.02 0 1.676 1.04 1.676 2.638 0 2.446-1.483 5.277-3.294 6.317-.423.232-.809.366-1.174.366-.482 0-.867-.212-1.175-.635-.328-.462-.25-1.117-.25-1.945Z"
      fill="#2F455F"
    />
    <path
      d="M16.09 103.455c0 .866.058 1.54.404 2.022.308.443.674.712 1.175.712.385 0 .79-.134 1.213-.385 1.888-1.098 3.429-4.044 3.429-6.567 0-1.656-.694-2.735-1.753-2.735-.385 0-.636.327-1.078.578-1.888 1.078-3.39 3.832-3.39 6.375Zm.27 0c0-2.35 1.463-5.181 3.197-6.183.365-.212.693-.308 1.001-.308.79 0 1.29.905 1.29 2.292 0 2.35-1.463 5.181-3.197 6.182-.366.212-.693.308-1.001.308-.347 0-.616-.154-.848-.462-.288-.424-.442-1.059-.442-1.829Z"
      fill="#3989E5"
    />
    <path
      d="M15.974 103.493c0 2.581 1.56 3.775 3.505 2.658 1.945-1.117 3.506-4.121 3.506-6.702 0-2.58-1.56-3.775-3.506-2.658-1.926 1.117-3.505 4.122-3.505 6.702Zm.443 0c0-2.446 1.483-5.277 3.293-6.317.424-.25.81-.327 1.175-.327 1.021 0 1.695 1.001 1.695 2.6 0 2.446-1.483 5.277-3.293 6.317-.424.231-.81.366-1.175.366-.482 0-.867-.212-1.175-.636-.347-.481-.52-1.175-.52-2.003Z"
      fill="#71ADEE"
    />
    <path
      d="M16.36 103.628c0 .867.192 1.598.539 2.08.308.443.712.655 1.213.655.385 0 .79-.135 1.213-.385 1.888-1.098 3.41-4.045 3.41-6.568 0-1.656-.694-2.735-1.753-2.735-.386 0-.79.135-1.214.386-1.868 1.097-3.409 4.044-3.409 6.567Zm.327.038c-.193-2.349 1.463-5.43 3.197-6.432a2.353 2.353 0 0 1 1.117-.29c.79 0 1.31 1.08 1.31 2.466 0 2.35-1.464 5.181-3.198 6.182-.366.212-.693.308-1.001.308-.347 0-.713-.115-.944-.442-.289-.386-.385-.829-.481-1.792Z"
      fill="#3989E5"
    />
    <path
      d="M16.301 103.57c0 .867.193 1.599.54 2.08.308.443.712.655 1.213.655.385 0 .79-.135 1.213-.385 1.888-1.098 3.428-4.045 3.428-6.567 0-1.657-.693-2.735-1.752-2.735-.385 0-.79.135-1.214.385-1.887 1.098-3.428 4.044-3.428 6.567Zm.443 0c0-2.349 1.464-5.18 3.197-6.182.366-.212.694-.308 1.002-.308.79 0 1.29.905 1.29 2.292 0 2.349-1.464 5.18-3.197 6.182-.366.212-.693.308-1.001.308-.347 0-.617-.154-.848-.462-.289-.405-.443-1.04-.443-1.83Z"
      fill="#004EB6"
    />
    <path
      d="M18.42 101.702c0 .327.154.501.347.501.058 0 .154 0 .231-.039.154-.077.308-.25.405-.424.115-.211.192-.442.192-.674 0-.423-.289-.616-.597-.423-.308.154-.578.635-.578 1.059Zm.944-.636c-.02-.115-.077-.192-.154-.211l-.058-.02c-.038 0-.038-.057-.019-.115a.256.256 0 0 1 .058-.077h.038l.058.019a.257.257 0 0 1 .154.135c.02.058.058.115.058.192l.02.097c0 .038-.02.115-.059.134h-.019c-.038.02-.058 0-.077-.038v-.116Zm-.655.963c.02-.038.039-.077.058-.096h.096c.097 0 .193-.077.27-.173l.058-.077c.038-.039.077-.058.096-.02v.039c0 .038-.02.077-.038.096l-.058.077a.904.904 0 0 1-.212.193.315.315 0 0 1-.173.058h-.097c0-.02-.019-.039 0-.097Zm-.192-.269v-.116c0-.115.019-.192.058-.308a.65.65 0 0 1 .115-.231l.077-.135c.039-.058.039-.096.058-.077.02.019.02.019.02.058 0 .019 0 .058-.02.096l-.058.096a.76.76 0 0 0-.116.405v.077a.163.163 0 0 1-.076.135c-.078.038-.058.058-.058 0Z"
      fill="#0064DC"
    />
    <path
      d="M19.056 100.643c-.308.173-.558.655-.558 1.078 0 .424.25.617.558.424.308-.173.559-.655.559-1.078 0-.405-.25-.597-.559-.424Zm-.385 1.04a.163.163 0 0 1-.077.135c-.039.019-.058 0-.058-.058v-.077c0-.096.02-.212.058-.328a.525.525 0 0 1 .115-.231l.058-.096c.039-.058.077-.077.096-.038.02.019.02.038.02.057 0 .02 0 .058-.02.097l-.057.096a.757.757 0 0 0-.116.404l-.02.039Zm.655.154-.058.077a.925.925 0 0 1-.212.193.314.314 0 0 1-.173.057h-.077c-.039 0-.058-.038-.039-.096.02-.038.039-.077.058-.096h.096c.097 0 .193-.077.27-.174l.058-.077c.038-.038.077-.057.096-.019v.039c.02.019 0 .057-.02.096Zm.192-.616s0 .019 0 0c-.038.019-.077.019-.077-.039l-.019-.096c-.02-.116-.077-.193-.154-.212l-.058-.019c-.038 0-.038-.058-.02-.116a.27.27 0 0 1 .059-.077c.019 0 .019-.019.038 0l.058.019a.26.26 0 0 1 .154.135c.02.058.058.116.058.193l.02.096c.018.019-.02.096-.059.116Z"
      fill="#004EB6"
    />
    <path
      d="M19.018 98.12v7.241h.038v-7.26c-.02 0-.02.019-.038.019Z"
      fill="#004EB6"
    />
    <path
      d="M17.958 105.804h.039l1.02-4.198.02-.097v-.019l.019-.058.963-4.025c-.02 0-.02.02-.039.02l-.944 3.871-.019.115v.173l-.038.135-1.021 4.083Z"
      fill="#004EB6"
    />
    <path
      d="M17.15 105.323c0 .019.019.019.019.038l1.771-3.621.058-.134.02-.058v-.019l.019-.02v-.038l.02-.019.038-.097 2.041-4.179-.02-.02-2.098 4.296V101.509l-.02.039-.019.038-1.83 3.737Z"
      fill="#004EB6"
    />
    <path
      d="m18.921 101.567-2.138 2.639v.038l2.176-2.677.02-.019.038-.039v-.019l.02-.019.019-.019.038-.039 2.716-3.332-.02-.019-2.657 3.255-.077.096-.02.019-.019.02-.019.019-.077.096Z"
      fill="#004EB6"
    />
    <path
      d="m16.764 103.243 2.157-1.676.058-.038.02-.019.018-.02h.02l.038-.019.058-.038 2.889-2.235-.02-.019-2.907 2.254-.039.019-.039.038-.019.02-.058.038-2.176 1.676v.019Z"
      fill="#004EB6"
    />
    <path
      d="m16.84 102.491 2.1-.963.039-.019h.019l.02-.019h.018v-.019h.039l.096-.058 2.831-1.29-.019-.039-2.812 1.31-.077.019-.02.019h-.038v.019h-.019l-.02.02h-.018l-.039.019-.038.019-2.042.944-.02.038Z"
      fill="#004EB6"
    />
    <path
      d="m16.976 101.914 1.945-.405.077-.019h.097l2.715-.558v-.039l-2.658.559-.057.019h-.097l-.115.019-1.868.385c-.039.02-.039.02-.039.039Z"
      fill="#004EB6"
    />
    <path
      d="m17.091 101.432 1.753.039h.366l2.465.058v-.039l-2.562-.077h-.173l-1.83-.038c0 .038 0 .057-.019.057Z"
      fill="#004EB6"
    />
    <path
      d="m17.246 101.028 1.694.443h.116l.058.019 2.33.597v-.038l-2.272-.598-.116-.019h-.039l-.077-.019-1.637-.424c-.057.02-.057.02-.057.039Z"
      fill="#004EB6"
    />
    <path
      d="m17.4 100.623 1.502.79.077.038h.039l.018.02.058.038 2.1 1.098.019-.019-2.119-1.117-.038-.02-.039-.019h-.038l-.058-.038-1.522-.79v.019Z"
      fill="#004EB6"
    />
    <path
      d="m17.573 100.238 1.348 1.156.039.039.019.019.02.019.057.039 1.849 1.579.02-.019-1.85-1.58-.058-.057-.019-.02-.02-.019-.057-.039-1.367-1.174c.019.038.019.038.019.057Z"
      fill="#004EB6"
    />
    <path
      d="m17.785 99.795 1.156 1.579.038.058v.019l.02.02.019.019.058.077 1.521 2.08.02-.019-1.522-2.08-.039-.058-.019-.019-.02-.02-.018-.019-.02-.019v-.019l-.038-.039-1.156-1.58c.02 0 .02.02 0 .02Z"
      fill="#004EB6"
    />
    <path
      d="m18.093 99.295.886 2.06.02.058v.039l.019.038.019.039 1.175 2.735.019-.02-1.155-2.696-.02-.058V101.432l-.019-.038-.02-.058-.885-2.041c-.02-.02-.039 0-.039 0Z"
      fill="#004EB6"
    />
    <path
      d="m18.517 98.697.462 2.62.02.077v.077l.018.135.578 3.197h.039l-.578-3.294v-.115l-.02-.077v-.02l-.48-2.657c-.02.019-.02.038-.04.057Z"
      fill="#004EB6"
    />
    <path
      d="m19.691 101.471-.5-.289s-.02 0-.02-.019c-.038-.02-.077-.02-.115.019a.461.461 0 0 0-.193.366c0 .077.02.135.077.154l.501.289v-.039c.02 0 .02 0 .039-.019a.461.461 0 0 0 .192-.366c0-.039 0-.058-.02-.096h.04Z"
      fill="#666"
    />
    <path
      d="M19.749 101.625c0 .135-.077.308-.193.366-.096.058-.192 0-.192-.135s.077-.308.192-.366c.097-.077.193 0 .193.135Z"
      fill="#004EB6"
    />
    <path
      d="M18.92 101.991c0 .327.155.501.348.501.057 0 .154 0 .23-.039.155-.077.309-.25.405-.423a1.43 1.43 0 0 0 .193-.675c0-.423-.29-.616-.597-.423-.328.173-.578.655-.578 1.059Zm.945-.636c-.02-.115-.077-.192-.154-.211l-.058-.02c-.039 0-.039-.057-.02-.115a.256.256 0 0 1 .058-.077h.039l.058.019a.257.257 0 0 1 .154.135c.019.058.057.115.057.192l.02.097c0 .038-.02.115-.058.135h-.02c-.038.019-.057 0-.076-.039v-.116Zm-.655.983a.43.43 0 0 1 .058-.097h.096c.096 0 .192-.077.27-.173l.057-.077c.039-.038.077-.058.097-.019v.038a.15.15 0 0 1-.039.097l-.058.077a.896.896 0 0 1-.212.192.307.307 0 0 1-.173.058h-.097c0-.019-.018-.058 0-.096Zm-.193-.27v-.115c0-.116.02-.193.058-.309a.649.649 0 0 1 .116-.231l.077-.135c.038-.057.038-.096.057-.077.02.02.02.02.02.058 0 .019 0 .058-.02.096l-.057.097a.754.754 0 0 0-.116.404v.077a.162.162 0 0 1-.077.135c-.077.039-.058.058-.058 0Z"
      fill="#0064DC"
    />
    <path
      d="M19.556 100.951c-.308.173-.558.655-.558 1.079 0 .423.25.616.558.423.308-.173.559-.655.559-1.078 0-.405-.25-.597-.559-.424Zm-.385 1.021a.163.163 0 0 1-.077.135c-.039.019-.058 0-.058-.058v-.077c0-.097.02-.212.058-.328a.525.525 0 0 1 .115-.231l.058-.096c.039-.058.077-.077.096-.039.02.02.02.039.02.058 0 .019 0 .058-.02.096l-.057.097a.755.755 0 0 0-.116.404l-.02.039Zm.655.173-.058.077a.925.925 0 0 1-.212.193.314.314 0 0 1-.173.057h-.077c-.039 0-.058-.038-.039-.096.02-.038.039-.077.058-.096h.096c.097 0 .193-.077.27-.173l.058-.077c.038-.039.077-.058.096-.02v.039c.02.019 0 .058-.02.096Zm.192-.616c-.038.019-.077.019-.077-.039l-.019-.096c-.02-.116-.077-.193-.154-.212l-.058-.019c-.038 0-.038-.058-.02-.116.02-.038.04-.057.059-.077.019 0 .019-.019.038 0l.058.02a.255.255 0 0 1 .154.134c.02.058.039.116.058.193l.02.096c.018.019-.02.077-.059.116Z"
      fill="#004EB6"
    />
    <path d="m19.383 80.671-.404-.25.52-.29.423.251-.539.29Z" fill="#0064DC" />
    <path d="M19.923 101.953v-21.57l-.54.288v21.571l.54-.289Z" fill="#2F455F" />
    <path d="m19.383 102.242-.404-.251v-21.57l.404.25v21.571Z" fill="#0064DC" />
    <path
      d="m65.99 89.01.096-.636-14.04-8.07h-.02l-.057-.038c-.116-.058-.25-.058-.404.02-.078.038-.155.077-.232.134l-.115.097a1.451 1.451 0 0 0-.212.27 2.352 2.352 0 0 0-.385 1.058v.02c-.039.404.096.712.308.828h.02l14.04 8.05.654-.212-.751-.462a2.17 2.17 0 0 0 .636-1.348l.462.289Z"
      fill="#71ADEE"
    />
    <path
      d="M66.432 89.241c-.058.674-.481 1.368-.944 1.56-.462.193-.808-.192-.75-.847.057-.674.48-1.368.943-1.56.462-.212.79.173.751.847Z"
      fill="#2F455F"
    />
    <path
      d="M39.45 93.343 16.397 80.035l22.9-13.23 23.072 13.307L39.45 93.343Z"
      fill="#71ADEE"
    />
    <path
      d="M62.37 86.737v-6.625L39.45 93.343v6.626l22.92-13.231Z"
      fill="#004EB6"
    />
    <path
      d="M39.45 99.969 16.398 86.66v-6.625l23.054 13.308v6.626ZM11.39 96.56l-.424-.27.54-.289.403.27-.52.289Z"
      fill="#0064DC"
    />
    <path d="M11.91 107.056V96.271l-.52.289v10.766l.52-.27Z" fill="#004EB6" />
    <path
      d="m11.39 107.326-.424-.25V96.291l.424.27v10.765ZM11.39 96.059v.5l-.424-.269.02-.481.403.25ZM18.977 91.206l-7.992 4.602.404.25 8.012-4.602-.424-.25Z"
      fill="#71ADEE"
    />
    <path
      d="m19.401 91.456-.019.5-7.992 4.604v-.501l8.011-4.603Z"
      fill="#004EB6"
    />
    <path
      d="M32.248 119.074 8.462 105.477l6.375-3.832 23.785 13.597-6.374 3.832Z"
      fill="#E2F6FF"
    />
    <path
      d="M38.622 116.243v-1.001l-6.374 3.832v1.021l6.374-3.852Z"
      fill="#71ADEE"
    />
    <path
      d="M32.248 120.095 8.462 106.498v-1.021l23.786 13.597v1.021Z"
      fill="#ABE0F6"
    />
    <path
      d="M31.824 111.351a6.194 6.194 0 0 0-1.368.617 7.307 7.307 0 0 0-2.715 2.908c-.039.058-.058.115-.077.154-.328.655-.482 1.367-.636 2.061l-7.51-4.295c.076-.52.192-1.021.326-1.522.193-.789.463-1.598.983-2.234.404-.481.943-.847 1.502-1.155a13.725 13.725 0 0 1 1.849-.906l7.646 4.372Z"
      fill="#71ADEE"
    />
    <path
      d="M27.201 106.825c-.077.462-.212.905-.462 1.31-.77 1.174-2.35 1.502-3.409 2.426-.443.385-.79.886-1.175 1.348-.52.636-1.136 1.156-1.752 1.676-.482.404-.963.809-1.502 1.098-.559.289-1.175.462-1.792.385a1.143 1.143 0 0 0-.25-.039c-1.444-.385-1.367-2.253-.963-3.447.289-.77.79-1.483 1.502-1.926.116-.077.25-.154.366-.231.79-.443 1.618-.79 2.273-1.444a8.262 8.262 0 0 0 1.772-2.735 6.4 6.4 0 0 0 .23-.732c.097-.385.174-.77.232-1.156.077-.5.289-1.213.924-1.213.482 0 .828.443 1.233.732.443.289.963.424 1.425.674 1.098.616 1.54 2.022 1.348 3.274Z"
      fill="#E2F6FF"
    />
    <path
      d="M27.202 106.825c-.077.462-.212.905-.463 1.31-.77 1.175-2.35 1.502-3.409 2.427-.442.385-.79.885-1.174 1.348-.52.635-1.137 1.155-1.753 1.675-.481.405-.963.809-1.502 1.098-.559.289-1.175.462-1.791.385a1.14 1.14 0 0 0-.25-.038c.134-.289.269-.578.442-.848.886-1.483 2.254-2.6 3.448-3.832 1.194-1.252 2.292-2.754 2.388-4.468.038-.752-.597-1.002-1.098-1.387.096-.385.173-.771.231-1.156.077-.5.29-1.213.925-1.213.481 0 .828.443 1.232.732.443.289.963.424 1.425.674 1.098.635 1.541 2.041 1.349 3.293Z"
      fill="#AAD2F6"
    />
    <path
      d="M39.566 70.04a16.701 16.701 0 0 1-.694 2.369c-.808 2.272-2.118 4.333-3.91 5.893-1.347 1.175-2.927 2.022-4.544 2.793a51.421 51.421 0 0 1-6.51 2.58c-.5.174-1.001.309-1.502.463l.289.808c1.463 4.603 2.003 9.438 2.58 14.233.135 1.002.25 1.984.501 2.966.193.77.058 1.56-.693 1.964-.385.212-.828.27-1.271.27-.135 0-.25-.019-.366-.019-.347-.039-.674-.096-.982-.231-.077-.039-.154-.077-.212-.135-.308-.27-.366-.886-.52-1.271-.154-.366-3.602-9.476-4.45-12.25-.423-1.347-.712-2.734-1-4.12-.212-1.04-.444-2.138-.29-3.198.155-1.155.906-2.099 1.715-2.908.577-.578 4.082-3.967 5.334-5.046 2.562-2.272 5.123-4.564 7.8-6.702.366-.289.732-.578 1.117-.867 1.503-1.078 2.947-2.33 4.526-3.312 1.406-.886 2.812.944 3.159 2.176.308 1.136.173 2.369-.077 3.544Z"
      fill="#71ADEE"
    />
    <path
      d="M38.873 72.409c-.81 2.272-2.119 4.333-3.91 5.893-1.348 1.175-2.927 2.022-4.545 2.793a51.42 51.42 0 0 1-6.51 2.58c-.5.174-1.001.309-1.502.463l.289.808c1.464 4.603 2.003 9.438 2.58 14.233.136 1.002.251 1.984.501 2.966.193.77.058 1.56-.693 1.964-.385.212-.828.27-1.271.27-.135-.289-.25-.597-.385-.886-.578-1.367-.905-2.715-1.31-4.16-.635-2.33-1.367-4.641-2.003-6.991-.308-1.175-.616-2.35-.847-3.525-.193-.943-.501-2.214-.135-3.139.924-2.195 2.87-3.736 4.622-5.257 1.888-1.618 3.756-3.255 5.624-4.873a818.33 818.33 0 0 0 4.198-3.64c.713-.616 1.29-1.233 1.599-2.138.25-.751-.077-1.637.193-2.407.134-.328.462-.81.847-.828.52-.02.77.674.867 1.078.134.559.115 1.136.558 1.54.462.424.732.752.925 1.407.23.597.288 1.213.308 1.849Z"
      fill="#004EB6"
    />
    <path
      d="M36.715 59.274c.155.559.135 1.136.077 1.714-.211 1.85-1.059 3.563-2.022 5.142-.963 1.58-2.08 3.082-2.966 4.719a3.447 3.447 0 0 0-1.887-.52c-.443 0-.674-.212-.693-.674-.02-.366.25-.732.385-1.06.404-.885.732-1.79 1.02-2.715.174-.52.328-1.06.482-1.599.52-1.694 1.02-3.37 1.521-5.065.25-.828.81-1.54 1.137-2.33a13.2 13.2 0 0 0 .828-2.6c.25-1.079.366-2.157.424-3.255.038-.54.057-1.06.077-1.598.038.038.077.096.115.134.578.675 1.387 1.175 2.273 1.272.115.481.212.982.231 1.483.02.539-.038 1.097-.077 1.656a52.681 52.681 0 0 0-.154 5.238 5.524 5.524 0 0 0-.905-.423c.038.154.096.308.134.481Z"
      fill="#FFCFB3"
    />
    <path
      d="M31.304 107.365c-.154.982-.848 1.483-1.445 2.157-.693.828-1.04 1.868-1.463 2.85-.27.597-.578 1.175-.944 1.733-.289.482-.54.983-.963 1.368-.886.847-2.1 1.309-3.293 1.598-.751.174-1.503.308-2.273.289-.135.019-.27 0-.404 0-.674-.038-1.368-.192-1.907-.616-.713-.578-.924-1.56-.27-2.234.617-.655 1.31-1.252 1.946-1.888a18.632 18.632 0 0 0 2.908-3.697c.404-.674.77-1.387 1.078-2.1.096-.231.193-.462.27-.693.058-.135.096-.289.154-.424.116-.289.289-.443.5-.655.27-.269.578-.52.925-.674 1.31-.616 2.985-.443 4.122.463a2.721 2.721 0 0 1 1.059 2.523Z"
      fill="#E2F6FF"
    />
    <path
      d="M31.304 107.364c-.154.983-.848 1.483-1.445 2.157-.693.828-1.04 1.868-1.464 2.851-.27.597-.577 1.175-.943 1.733-.29.482-.54.982-.963 1.367-.886.848-2.1 1.31-3.294 1.599-.75.173-1.502.308-2.272.289a12.79 12.79 0 0 0 3.813-2.754c1.695-1.83 2.812-4.237 2.928-6.722.019-.327 0-.693-.27-.905-.193-.135-.424-.154-.655-.173-.655-.077-1.618-.231-2.176-.694.058-.134.096-.288.154-.423.116-.289.289-.443.5-.655.27-.27.578-.52.925-.674 1.31-.616 2.985-.443 4.122.462.75.597 1.174 1.579 1.04 2.542Z"
      fill="#AAD2F6"
    />
    <path
      d="M53.76 80.324c-.809.944-1.868 1.637-2.946 2.234-1.657.905-3.37 1.425-5.123 2.023a115.707 115.707 0 0 1-5.008 1.56 103.99 103.99 0 0 1-6.74 1.637c-1.098.212-2.273.558-3.41.635-.346.02-.731-.096-.962.231-.212.308-.058.751.038 1.079.25.674.443 1.367.597 2.08.424 2.06.385 4.179.29 6.259-.097 1.849-.27 3.717-.444 5.566-.077.809-.154 1.367-.79 1.945a2.912 2.912 0 0 1-1.078.636c-.193.077-.405.115-.616.154-.597.077-1.214-.019-1.792-.212-.577-.193-1.059-.308-1.059-.925 0-.25 0-.481-.038-.731-.135-1.271-3.486-13.848-3.544-14.117-.327-1.195-.655-2.408-.578-3.621.25-2.966 2.928-4.526 5.22-5.836a35.391 35.391 0 0 1 1.887-1.001c2.427-1.233 4.95-2.254 7.434-3.313.404-.173.809-.346 1.213-.539.27-.116.54-.231.732-.462a.77.77 0 0 0 .193-.347c.096-.212.115-.462.173-.693.174-1.156.135-2.812.944-3.756.982-1.194 2.426-1.675 3.89-1.117.443.174.867.385 1.271.636.27.135.54.308.828.423 2.62 1.233 5.682.675 8.494.02.192-.039.404-.116.597-.077.096.019.192.212.27.212.134-.02.712-.559.654-.675.212.482.386 1.002.54 1.541.79 2.793.77 6.317-1.137 8.551Z"
      fill="#71ADEE"
    />
    <path
      d="M53.76 80.324c-.81.944-1.868 1.637-2.947 2.234-1.656.905-3.37 1.425-5.123 2.022a115.707 115.707 0 0 1-5.007 1.56 103.99 103.99 0 0 1-6.741 1.638c-1.098.211-2.273.558-3.409.635-.347.02-.732-.096-.963.231-.212.308-.058.751.039 1.079.25.674.443 1.367.597 2.08.423 2.06.385 4.179.289 6.259-.097 1.849-.27 3.717-.443 5.566-.077.809-.154 1.367-.79 1.945a2.913 2.913 0 0 1-1.079.636 247.457 247.457 0 0 0-1.405-13.578c-.193-1.54-.405-3.1-.154-4.622.173-1.06.635-2.408 1.502-3.101.693-.54 1.483-.828 2.272-1.156.925-.385 1.83-.75 2.754-1.136.405-.173.828-.347 1.156-.635.289-.232.424-.52.385-.752-.02-.25-.27-.462-.732-.481-.79-.02-1.56 0-2.35.02-1.232.057-2.484.23-3.716.153-.174 0-.347-.019-.501-.115-.154-.097-.25-.29-.173-.443a.754.754 0 0 1 .154-.193c.096-.077.211-.173.308-.25.385-.308.809-.636 1.02-1.021.212-.404.212-.655.655-.905.751-.405 1.657-.328 2.465-.54a21.27 21.27 0 0 0 5.45-2.195 21.224 21.224 0 0 0 3.602-2.523c.906-.79 1.772-1.656 2.658-2.446.27.135.54.308.828.424 2.62 1.232 5.682.674 8.494.02.192-.04.404-.116.597-.078.096.02.192.212.27.212.134-.02.712-.559.654-.674.212.481.385 1.001.54 1.54.77 2.832.75 6.356-1.156 8.59Z"
      fill="#004EB6"
    />
    <path
      d="M54.473 57.367c-.309 1.85-.309 3.737-.174 5.586.135 1.887.405 3.774.655 5.642.135.906.27 1.81.366 2.735.058.501.116 1.04-.096 1.522-.347.79-1.31 1.136-2.177 1.31-2.33.5-4.718.577-7.087.27a20.508 20.508 0 0 1-5.951-1.715c-1.136-.52-2.253-1.156-3.14-2.041-1.02-1.021-1.54-2.254-1.482-3.698.077-2.08.288-4.18.635-6.24.154-.963.347-1.984.02-2.908-.366-1.021-1.33-1.85-1.406-2.928-.097-1.29 1.059-2.292 1.54-3.486.52-1.27.52-2.812.694-4.14.212-1.522.462-3.024.732-4.526.173-.963.462-1.791 1.155-2.485.713-.712 1.849-1.04 2.793-1.31.905-.27 1.868-.481 2.792-.308 1.194.232 2.157 1.06 3.043 1.869 2.542 2.33 4.97 4.814 7.357 7.299.462.481.925.963 1.348 1.483.116.154.25.346.366.539.347.636.559 1.425.174 2.041-.155.25-.424.424-.617.636-.289.327-.404.732-.5 1.136-.097.347-.27.636-.405.983-.27.886-.481 1.81-.635 2.734Z"
      fill="#FFDC1E"
    />
    <path
      d="M55.224 72.852c-.347.79-1.31 1.136-2.177 1.31-2.33.5-4.718.577-7.087.27 0-.79.02-1.58.077-2.389a67.21 67.21 0 0 1 .443-5.547c.385-3.312 1.02-6.625 2.08-9.803.52-1.579 1.136-3.12 1.849-4.603.25-.5.635-1.733 1.175-1.964.75-.308 1.059.751 1.117 1.271.597 4.95.077 10.034 1.444 14.83.231.79.52 1.579.79 2.35.135.904.27 1.81.366 2.734.077.52.134 1.06-.077 1.54Z"
      fill="#E39F3A"
    />
    <path
      d="M61.348 74.836c-.154 1.695-.443 3.486-1.483 4.834-.848 1.098-2.1 1.791-3.236 2.561-1.502 1.002-2.118 2.215-3.139 3.583-.27.366-.558.712-.847 1.078-.867 1.04-1.791 2.022-2.85 2.908-.771.655-1.618 1.29-2.562 1.676-.636.27-1.329.443-2.003.655-1.926.635-4.333 1.81-5.162 3.755a5.095 5.095 0 0 0-.192.578c-.366 1.502-.308 3.062-.385 4.622-.077 1.541-.308 3.139-1.194 4.411-.886 1.271-2.543 2.099-4.006 1.579-1.137-.405-1.888-1.483-2.716-2.35-.597-.635-1.444-1.252-2.33-1.29-.905-.039-1.695.25-2.677.115a12.66 12.66 0 0 1-5.393-2.08c-1.098-.751-1.926-1.425-2.311-2.696-.443-1.425-.867-2.831-1.367-4.237-.713-2.022-1.58-4.006-2.061-6.086-.693-2.85-.674-5.932.963-8.455.443-.674.924-1.329 1.444-1.945 1.656-2.003 3.66-3.736 5.643-5.412 2.716-2.253 5.277-4.738 8.108-6.837 1.349-1.001 2.754-1.964 4.141-2.908-.058.847-.192 1.714-.038 2.542.154.79.79 1.445 1.27 2.08.694.983 1.522 1.888 2.6 2.465 1.6.867 3.544.906 5.355.578 1.79-.327 3.505-1.001 5.277-1.483.154-.038.308-.077.481-.077.366.02.655.29.905.54.675.654 1.33 1.444 2.061 2.099 1.522 1.329 3.852-.02 5.49 0 .75.019 1.52.327 1.925.943.404.675.347 1.503.289 2.254Z"
      fill="#F6F6F6"
    />
    <path
      d="M52.624 86.892c-.867 1.04-1.791 2.022-2.85 2.908-.771.655-1.618 1.29-2.562 1.675-.636.27-1.33.443-2.003.655-1.926.636-4.334 1.81-5.162 3.756a5.075 5.075 0 0 0-.192.578c-.366 1.502-.308 3.062-.385 4.622-.077 1.541-.309 3.139-1.194 4.41-.886 1.271-2.543 2.099-4.006 1.579-1.137-.404-1.888-1.482-2.716-2.349-.597-.636-1.444-1.252-2.33-1.291-.905-.038-1.695.251-2.677.116a12.674 12.674 0 0 1-5.393-2.08c-1.098-.751-1.926-1.425-2.311-2.696-.443-1.425-.867-2.831-1.367-4.237-.713-2.022-1.58-4.006-2.061-6.086-.694-2.85-.674-5.932.963-8.455.443-.674.924-1.329 1.444-1.945-.077 1.136.424 2.06 1.213 3.081 1.137 1.464 2.697 2.581 4.488 3.101 1.136.327 2.33.424 3.447.79 2.677.847 4.68 3.043 7.184 4.333 3.274 1.695 7.203 1.695 10.785.77 2.697-.693 5.239-1.887 7.685-3.235Z"
      fill="#AAD2F6"
    />
    <path
      d="M34.963 62.972c-.308.809-.655 1.579-.636 2.484.039.886.405 1.734.829 2.523.77 1.445 1.733 2.812 3.023 3.794 1.079.828 2.35 1.348 3.66 1.734 4.275 1.251 9.013.924 13.077-.906.134-.057.25-.115.346-.211.154-.174.154-.424.154-.655a10.327 10.327 0 0 0-.712-3.486c-.443.462-1.156.558-1.791.578-1.541.077-3.082.038-4.623-.077-2.35-.154-4.718-.463-6.856-1.445-1.329-.616-2.542-1.502-3.447-2.658-.983-1.27-1.406-2.715-1.946-4.198-.019-.02-.019-.058-.057-.058-.039-.02-.077.039-.097.077-.173.385-.096.828-.308 1.194-.173.443-.423.848-.616 1.31Z"
      fill="#3892E5"
    />
    <path
      d="M37.89 64.532c-.308.635-.693 1.233-1.04 1.83-.847 1.444-1.579 2.965-2.31 4.468-.598 1.213-1.195 2.446-1.638 3.736-.308.886-.558 1.81-1.059 2.62a4.683 4.683 0 0 1-3.197 2.118c-.462.077-1.001.038-1.29-.308-.097-.116-.154-.231-.25-.328-.213-.154-.501-.077-.771-.135-.732-.192-.5-.963-.655-1.483-.135-.481-.828-.443-1.155-.693-.482-.347-.039-.982.192-1.348.328-.482.694-.674 1.252-.886.713-.27 1.444-.559 2.1-.982 1.867-1.214 2.85-3.37 3.736-5.412.443-1.02.905-2.042 1.348-3.062.52-.366 1.04-.713 1.56-1.079.077-.192.154-.404.23-.616.194-.462.444-.867.675-1.29.192-.366.135-.829.308-1.214.02-.038.039-.077.096-.058.02 0 .039.039.039.058.52 1.425.924 2.831 1.83 4.064Z"
      fill="#3892E5"
    />
    <path
      d="M32.324 75.047a3.649 3.649 0 0 1-1.059 1.984c-.096.096-.193.173-.328.192-.154.039-.327-.038-.423-.154l-.02-.019c-.115-.135-.154-.308-.173-.481-.115-.732.154-1.464.174-2.215.019-.174-.039-.385-.193-.482-.135-.057-.27-.019-.385.077-.097.097-.154.231-.212.347-.462 1.252-.212 2.658-.424 3.987-.038.27-.154.578-.404.674-.212.058-.482-.02-.636.115-.115.116-.154.328-.308.386-.116.038-.25-.058-.327-.174-.231-.327-.231-.77-.366-1.155-.039-.097-.135-.231-.231-.193-.058 0-.116.077-.154.135-.078.154-.116.308-.193.443a.498.498 0 0 1-.385.289c-.154.019-.308-.096-.405-.231-.096-.135-.115-.29-.154-.463-.096-.442-.192-.885-.192-1.348 0-.423.038-.924-.27-1.213a2.136 2.136 0 0 1-.327.578c-.154.212-.501.366-.674.173-.097-.096-.097-.25-.078-.385.04-.54.29-1.02.54-1.502.212-.386.404-.77.655-1.098.635-.828 1.502-1.425 2.31-2.06.386-.27.694-.617.964-.983.173-.212.327-.424.481-.655.405-.578.732-1.156 1.098-1.752.038-.058.077-.135.115-.193-.038.058-.038.135-.038.173.038.154.212.29.327.366.25.193.443.482.674.713.135.135.27.23.443.23.212 0 .386-.192.501-.365 0 1.079-.02 2.138-.038 3.236.019 1.001.308 2.002.115 3.023Z"
      fill="#ABE0F6"
    />
    <path
      d="M32.324 75.047a3.649 3.649 0 0 1-1.06 1.984c-.095.096-.192.173-.327.193-.154.038-.327-.039-.423-.154.019-.039.038-.058.057-.097.231-.443.463-.885.617-1.348.27-.847.327-1.752.135-2.619-.097-.462-.29-.924-.598-1.29-.365-.424-.905-.655-1.405-.944-.212-.135-.52-.27-.675-.482a8.19 8.19 0 0 0 .482-.654c.404-.578.732-1.156 1.098-1.753.038 0 .057-.02.096-.02.039.155.212.29.327.367.25.192.443.481.675.712.134.135.27.231.442.231.212 0 .386-.192.501-.365 0 1.078-.019 2.137-.038 3.235 0 .982.289 1.984.096 3.004Z"
      fill="#71ADEE"
    />
    <path
      d="M32.786 69.847c-.057.27-.288 1.387-.75 1.04a2.947 2.947 0 0 0-.309-.212c-.346-.25-.712-.462-1.155-.558-.463-.096-.944-.135-1.406-.173-.077-.02-.154-.058-.174-.097-.115-.173.116-.558.174-.731.058-.135.096-.27.154-.386.192-.52.424-1.02.77-1.463.02.134.058.346.174.443.096.096.27 0 .404 0a.555.555 0 0 1 .5.211c.058.097.078.193.136.27.077.116.192.212.308.289a2.84 2.84 0 0 0 1.02.443c.386.077.212.616.154.924Z"
      fill="#FFDC1E"
    />
    <path
      d="M32.786 69.847c-.058.27-.289 1.387-.75 1.04a2.947 2.947 0 0 0-.309-.212 1.18 1.18 0 0 0-.077-.885c-.154-.309-.443-.54-.77-.675-.482-.192-1.06-.115-1.541-.346-.02-.02-.039-.02-.039-.039.193-.52.424-1.02.77-1.463.02.134.058.346.174.443.096.096.27 0 .404 0a.555.555 0 0 1 .501.211c.058.097.077.193.135.27.077.116.193.212.308.289a2.84 2.84 0 0 0 1.02.443c.405.077.232.616.174.924Z"
      fill="#E39F3A"
    />
    <path
      d="M37.852 41.883a5.71 5.71 0 0 0-.212.866c-.077.444-.154.867-.231 1.31-.193 1.079-.347 2.157-.501 3.216-.193 1.349-.174 2.89-.694 4.16a4.46 4.46 0 0 1-.346.674c-.559.925-1.29 1.772-1.194 2.812.038.463.212.867.462 1.272.02.019.02.038.038.057.328.52.713 1.04.906 1.599.327.924.192 2.157.154 3.12a13.8 13.8 0 0 1-.482 3.197c-.346 1.329-.924 2.735-1.695 3.948-.019.038-.057.077-.077.115a7.408 7.408 0 0 1-1.136 1.406c-.038.039-.077.078-.135.078-.096.038-.154-.04-.23-.097-.078-.058-.136-.115-.213-.173a2.645 2.645 0 0 0-.385-.25c-.02-.02-.038-.02-.058-.04-.154-.076-.308-.153-.481-.21a5.513 5.513 0 0 0-1.54-.328c-.097-.02-.174-.02-.27-.02-.039 0-.058 0-.097-.038l-.038-.039c-.039-.057-.02-.134 0-.192.5-1.888 2.657-7.742 3.062-8.84.693-1.945 1.406-3.89 1.849-5.913.809-3.66 1.329-7.318 2.33-10.94.077-.269.154-.538.25-.808.078-.25.212-.559.405-.77.308.115.443.481.559.809-.02-.02-.02 0 0 .019Z"
      fill="#FFDC1E"
    />
    <path
      d="M36.215 60.95a13.8 13.8 0 0 1-.482 3.197c-.346 1.329-.924 2.734-1.694 3.948-.02.038-.058.077-.077.115-.347.482-.732.963-1.137 1.406-.038.039-.077.077-.135.077-.096.039-.154-.038-.23-.096-.078-.058-.136-.115-.212-.173-.116-.097-.25-.174-.386-.25 0-.02.02-.04.02-.058.057-.135.096-.29.154-.424a61.85 61.85 0 0 0 1.31-4.18 70.484 70.484 0 0 0 1.059-4.429c.154-.751.308-1.521.462-2.273.096-.462.096-1.136.25-1.637.02.02.02.039.039.058.327.52.712 1.04.905 1.599.327.924.192 2.157.154 3.12ZM37.408 44.04c-.192 1.079-.346 2.157-.5 3.216-.193 1.349-.174 2.89-.694 4.16a4.45 4.45 0 0 1-.346.674c.23-1.367.462-2.734.693-4.083.231-1.29.462-2.6.828-3.87 0-.02 0-.058.02-.097Z"
      fill="#E39F3A"
    />
    <path
      d="M48.868 43.867c-.173.52-.52.982-.905 1.367-1.001.982-2.484 1.444-3.87 1.29a2.971 2.971 0 0 1-.598-.115c-.655-.173-1.29-.482-1.695-1.021-.096-.135-.192-.289-.212-.443-.038-.193.02-.404.058-.597.154-.712-.135-1.464.039-2.157.154-.578.635-.982 1.174-1.175.405-.154.829-.27 1.233-.443 1.502-.616 2.369-1.964 3.332-3.216-.096.135.02.924.038 1.098.058.308.135.597.174.905.058.366.154.712.27 1.06.096.307.23.596.385.866.192.327.423.616.577.963.193.5.174 1.098 0 1.618Z"
      fill="#ABE0F6"
    />
    <path
      d="M48.868 43.867c-.173.52-.52.982-.905 1.367-1.002.982-2.485 1.444-3.871 1.29.058-.038.115-.077.154-.096a2.6 2.6 0 0 0 1.078-2.369c-.038-.308-.134-.635-.134-.943-.02-.29 0-.52-.154-.79-.097-.154-.212-.25-.27-.404-.193-.52.231-.963.635-1.195.752-.443 1.714-.597 2.504-.327.096.308.231.597.385.867.193.327.424.616.578.963.193.52.173 1.117 0 1.637Z"
      fill="#71ADEE"
    />
    <path
      d="M48.887 38.82c-.327.54-.847.925-1.464 1.08-.27.076-.597 0-.731.23-.174.25-.078.752-.174 1.04-.173.559-.732.886-1.252 1.156-1.579.867-3.216 1.753-5.026 1.849H39.816c-.327-.02-.616-.096-.867-.27-.212-.154-.366-.404-.52-.635a8.59 8.59 0 0 1-.654-1.426c-.116-.308-.232-.693-.559-.808-.193-.077-.404-.039-.559-.174-.134-.135-.154-.346-.115-.52.038-.173.154-.327.212-.5.308-.829-.173-1.734-.077-2.6.019-.27.115-.578.019-.829-.038-.134-.135-.23-.193-.346-.308-.54-.173-1.214-.019-1.81.212-.886.5-1.792.616-2.678.097-.732.79-1.155 1.406-1.425 1.83-.75 4.045-.578 5.72.482.463.288.886.635 1.194 1.078.193.27.328.578.424.886.27.713.424 1.483.886 2.06.366.463.693.675 1.271.733 1.599.115 1.445 2.484.886 3.428Z"
      fill="#ABE0F6"
    />
    <path
      d="M48.887 38.82c-.327.54-.847.925-1.463 1.08-.27.076-.597 0-.732.23-.174.25-.077.751-.174 1.04-.173.559-.731.886-1.251 1.156-1.58.867-3.217 1.752-5.027 1.849h-.405c1.233-1.002 2.273-2.177 2.87-3.621.27-.616.424-1.29.385-1.945-.058-.828-.423-1.618-.578-2.427-.173-.982-.25-2.118.655-2.735.386-.27.848-.423 1.31-.539.077-.02.135-.038.212-.058.385-.115.79-.192 1.213-.23.27.712.424 1.482.886 2.06.366.462.693.674 1.271.732 1.54.096 1.387 2.465.828 3.409Z"
      fill="#71ADEE"
    />
    <path
      d="M52.74 42.538c-.155.655-.424 1.29-.944 1.695-.328.27-.752.385-1.175.462a5.406 5.406 0 0 1-2.543-.173c-.539-.174-.962-.328-1.27-.848-.328-.54-.078-.963-.116-1.54-.02-.424-.405-.752-.482-1.156-.077-.462.27-.905.655-1.156.405-.25.867-.385 1.252-.674.809-.597 1.464-2.812.096-3.14a1.064 1.064 0 0 0-.597.059c-.597.212-1.02.731-1.29 1.31-.27.558-.385 1.193-.482 1.81-.385-.424-.52-1.021-.77-1.541a3.716 3.716 0 0 0-1.117-1.387c-.231-.192-.5-.346-.636-.616-.173-.308-.134-.674-.173-1.02a1.906 1.906 0 0 0-.327-.848 2.283 2.283 0 0 0-.636-.655c-.5-.347-1.117-.5-1.733-.481-.597.038-1.194.25-1.81.25-1.079 0-1.965-1.04-1.792-2.1a1.74 1.74 0 0 1 .405-.847c.154-.154.346-.308.558-.385.25-.096.501-.115.732-.212.674-.289.905-1.136 1.368-1.733.558-.732 1.56-1.098 2.446-.905 1.001.23 1.56.635 2.503.019a2.709 2.709 0 0 1 2.273-.328 2.692 2.692 0 0 1 1.675 1.56c.077.193.135.424.27.598.193.288.52.442.847.577.328.135.675.25.944.443.674.501.79 1.464.732 2.292-.077.828-.231 1.714.058 2.504.366.924.866 1.618.558 2.658-.135.462-.327.886-.308 1.348.039.712.578 1.31.828 1.983.231.732.174 1.464 0 2.177Z"
      fill="#F6F6F6"
    />
    <path
      d="M52.74 42.538c-.155.654-.424 1.29-.944 1.694-.328.27-.752.386-1.175.463a5.406 5.406 0 0 1-2.542-.174c-.54-.173-.963-.327-1.272-.847-.327-.54-.077-.963-.115-1.54-.02-.425-.404-.752-.482-1.157-.077-.462.27-.905.655-1.155.405-.25.867-.385 1.252-.674.809-.597 1.464-2.812.096-3.14a1.064 1.064 0 0 0-.597.058c-.597.212-1.02.732-1.29 1.31-.27.559-.385 1.194-.482 1.81-.385-.423-.52-1.02-.77-1.54a3.713 3.713 0 0 0-1.117-1.387c-.231-.193-.5-.347-.636-.616-.173-.308-.134-.674-.173-1.021a1.904 1.904 0 0 0-.327-.847c.54.23 1.001.423 1.618.308a2.419 2.419 0 0 0 1.656-1.137 4.177 4.177 0 0 0 3.158-1.81 3.887 3.887 0 0 0 .675-1.984c.327.135.674.25.943.443.674.501.79 1.464.732 2.292-.077.828-.231 1.714.058 2.504.366.924.867 1.618.558 2.658-.135.462-.327.886-.308 1.348.039.712.578 1.31.828 1.983.231.713.174 1.445 0 2.157Z"
      fill="#AAD2F6"
    />
    <path
      d="M29.686 81.615c2.427.057 4.815-.694 7.068-1.657 2.234-.963 4.391-2.118 6.664-2.985 1.464-.558 2.966-.982 4.43-1.463 1.714-.578 3.39-1.233 5.007-2.023a4.68 4.68 0 0 0 .944-.558c.27-.212.308-.482.654-.636.193-.077.386-.135.54-.27.385-.308.385-.847.346-1.29a10.989 10.989 0 0 0-.308-1.772 1.399 1.399 0 0 0-.173-.423 1.822 1.822 0 0 1-.674.847c-.559.385-1.348.52-2.003.636-.385.057-.77.192-1.136.308-7.57 2.465-14.599 6.47-22.303 8.474-.423.115-.866.212-1.232.462-.655.443-.925 1.233-.212 1.714.655.5 1.56.616 2.388.636Z"
      fill="#3892E5"
    />
    <path
      d="M56.784 59.64a78.363 78.363 0 0 1-1.002 5.566c-.289 1.367-.616 2.716-1.213 3.968-.5 1.04-.982 2.002-2.08 2.542-1.098.558-2.196 1.213-3.332 1.695-1.213.5-8.57 3.023-9.167 3.216-.154.038-.386.077-.617.135-.423.077-.866.173-1.078.346-.482.385-.944.77-1.483 1.04-.385.193-.79.328-1.175.482-.963.327-1.926.674-2.908.944-.617.173-1.252.308-1.868.462a39.832 39.832 0 0 1-3.024.578c-.212.038-.404.057-.597 0-.193-.058-.347-.193-.385-.386-.02-.019 0-.038 0-.038-.02-.135.077-.289.173-.405.231-.27.597-.443.79-.75.134-.232.173-.52.366-.713.077-.077.154-.116.211-.212.039-.077.058-.174.078-.25.077-.347.288-.636.52-.906.731-.924 1.617-1.772 2.754-2.08.693-.173 1.425-.154 2.137-.173.347-.02.694-.077 1.04-.116.02 0 .039-.019.058-.019a49.688 49.688 0 0 0 2.119-1.001c3.89-1.888 10.611-5.297 10.823-5.49 1.137-.924 1.31-2.253 1.541-3.62.02-.02.02-.038.02-.058.173-1.02.288-2.041.385-3.081.057-.674.096-1.348.25-2.003.116-.5.289-1.194.597-1.618a4.307 4.307 0 0 1 3.12-1.618c.597-.019 1.213.096 1.733.366.54.29.963.751 1.175 1.33.212.558.135 1.212.039 1.867Z"
      fill="#ABE0F6"
    />
    <path
      d="M39.373 76.742c-.423.077-.866.173-1.078.347-.482.385-.944.77-1.483 1.04-.385.192-.79.327-1.175.481-.963.328-1.926.674-2.908.944-.616.173-1.252.308-1.868.462a39.832 39.832 0 0 1-3.024.578c-.212.038-.404.058-.597 0-.192-.058-.346-.193-.385-.385.212-.039.443-.077.655-.116a47.09 47.09 0 0 0 3.274-.693c1.06-.27 2.118-.578 3.081-1.117.424-.231.848-.54 1.021-.982.173-.443.058-.963-.02-1.445-.096-.481-.096-.924.136-1.329a49.773 49.773 0 0 0 2.118-1.001c.54.096 1.079.366 1.425.809.308.366.482.809.636 1.252.096.365.212.77.193 1.155Z"
      fill="#71ADEE"
    />
    <path
      d="M40.086 76.434c-.039.039-.096.077-.154.116-.713.404-1.425.828-2.138 1.252a.512.512 0 0 1-.27.096c-.115.019-.211-.02-.327-.058a2.236 2.236 0 0 1-.52-.27 2.785 2.785 0 0 1-.424-.404 4.114 4.114 0 0 1-.385-.616c-.173-.386-.327-.79-.366-1.194a2.21 2.21 0 0 1 .116-1.002c.096-.27.346-.327.597-.462.038 0 .058-.02.096-.039l.578-.289c.443-.192.905-.385 1.387-.539.019-.02.038 0 .038 0 .02 0 .02.02.02.039.019.135.057.27.076.404.039.193.077.366.097.559.019.366.058.731.212 1.059.134.289.365.52.597.693.192.097.982.385.77.655Z"
      fill="#FFDC1E"
    />
    <path
      d="M40.086 76.434a1.065 1.065 0 0 1-.154.116c-.713.404-1.426.828-2.138 1.252a.51.51 0 0 1-.27.096c-.115.02-.212-.02-.327-.058a2.23 2.23 0 0 1-.52-.27c.077-.057.154-.115.212-.192.27-.27.481-.636.462-1.02-.02-.482-.347-.887-.462-1.35-.097-.365-.097-.923.154-1.25.23-.31.886-.31 1.213-.328h.173c.039.192.077.366.097.558.019.366.057.732.211 1.06.135.288.366.52.597.693.174.135.963.424.752.693Z"
      fill="#E39F3A"
    />
    <path
      d="M57.092 61.489c-.289 2.157-.655 4.295-1.367 6.355-.328.925-.675 1.965-1.31 2.716-.597.713-1.31 1.156-2.157 1.56-4.102 1.965-8.262 3.929-12.711 4.911-.193.039-.405.077-.597.058a1.376 1.376 0 0 1-.655-.308c-.039-.039-.077-.077-.116-.097a3.497 3.497 0 0 1-1.213-2.137c-.039-.347-.039-.713.154-1.002.154-.23.424-.366.674-.5 3.043-1.522 6.22-2.85 9.071-4.738.578-.386 1.175-.81 1.58-1.387.385-.54.308-1.387.385-2.022.173-1.656.366-3.332.443-5.008.038-1.04.057-2.08.211-3.12.347-2.388.906-5.931 2.986-7.53.481-.366 1.386-.636 2.118-.636.27.02.54.058.751.155.54.23.925.616 1.194 1.059.54.886.674 2.06.77 3.062.116 1.136.174 2.311.193 3.467.02 1.733-.173 3.409-.404 5.142Z"
      fill="#FFDC1E"
    />
    <path
      d="M57.092 61.489c-.29 2.157-.655 4.295-1.368 6.355-.327.925-.674 1.965-1.31 2.716-.596.713-1.309 1.156-2.156 1.56-4.102 1.965-8.263 3.929-12.711 4.911-.193.039-.405.077-.597.058a1.375 1.375 0 0 1-.655-.308c-.039-.039-.077-.077-.116-.097 3.216-1.251 6.433-2.445 9.495-4.044 1.098-.578 2.176-1.194 3.14-2.003.654-.54 2.176-1.444 1.347-2.388-.288-.308-.674-.481-1.078-.655a35.772 35.772 0 0 0-1.271-.54c-.096-.057-.212-.115-.231-.23 0-.116.134-.193.25-.212.5-.096 1.02.077 1.521.231a9.074 9.074 0 0 0 2.023.347c1.27.077 1.56-1.734 1.733-2.697.77-4.217.77-8.531.809-12.788 0-.693 0-1.444-.308-2.08-.25-.481-.617-.712-1.02-.982.269.02.538.058.75.154.54.231.925.616 1.194 1.06.54.885.674 2.06.77 3.062.116 1.136.174 2.31.193 3.466.02 1.695-.173 3.37-.404 5.104Z"
      fill="#E39F3A"
    />
    <path
      d="M53.548 96.56c0 5.104 1.695 8.705 4.372 10.265l1.33.79.134-.174c1.984.559 4.352.193 6.895-1.271 7.03-4.064 12.73-14.945 12.73-24.305 0-4.102-.867-7.107-2.677-8.994l-1.714-1.29c-2.234-1.29-5.142-1.175-8.32.674-7.049 4.044-12.75 14.945-12.75 24.305Zm2.504.212c0-8.86 5.354-19.144 11.94-22.957 1.522-.867 2.023-1.522 3.333-1.522 3.697 0 6.105 3.756 6.105 9.572 0 8.86-5.354 19.144-11.94 22.957-1.503.867-2.948 1.31-4.257 1.31-1.733 0-3.178-.771-4.237-2.292-1.233-1.714-.944-4.025-.944-7.068Z"
      fill="#2F455F"
    />
    <path
      d="M54.666 97.06c0 3.159.231 5.586 1.502 7.357 1.117 1.58 2.427 2.581 4.237 2.581 1.368 0 2.85-.462 4.43-1.367 6.837-3.948 12.403-14.656 12.403-23.862 0-6.048-2.485-9.958-6.336-9.958-1.368 0-2.311 1.175-3.89 2.08-6.838 3.968-12.346 13.963-12.346 23.17Zm1.04 0c0-8.531 5.296-18.816 11.594-22.437 1.31-.77 2.542-1.155 3.62-1.155 2.851 0 4.7 3.255 4.7 8.32 0 8.532-5.296 18.816-11.594 22.437-1.329.77-2.542 1.155-3.62 1.155-1.291 0-2.254-.539-3.082-1.694-1.06-1.503-1.618-3.795-1.618-6.626Z"
      fill="#3989E5"
    />
    <path
      d="M54.3 97.176c0 9.36 5.7 13.674 12.73 9.611 7.03-4.064 12.73-14.946 12.73-24.306 0-9.36-5.7-13.674-12.73-9.61S54.3 87.816 54.3 97.176Zm1.58 0c0-8.86 5.353-19.144 11.94-22.957 1.521-.866 2.927-1.155 4.237-1.155 3.698 0 6.124 3.601 6.124 9.417 0 8.86-5.354 19.144-11.94 22.957-1.503.867-2.947 1.31-4.257 1.31-1.733 0-3.178-.77-4.237-2.292-1.232-1.714-1.868-4.218-1.868-7.28Z"
      fill="#71ADEE"
    />
    <path
      d="M55.706 97.716c0 3.158.674 5.777 1.925 7.568 1.117 1.58 2.6 2.389 4.41 2.389 1.368 0 2.851-.463 4.43-1.368 6.837-3.948 12.403-14.656 12.403-23.862 0-6.047-2.484-9.957-6.336-9.957-1.367 0-2.85.462-4.43 1.367-6.837 3.948-12.402 14.657-12.402 23.863Zm1.174.134c-.732-8.512 5.316-19.721 11.614-23.361 1.31-.77 2.946-1.02 4.025-1.02 2.85 0 4.738 3.909 4.738 8.974 0 8.532-5.297 18.816-11.594 22.437-1.33.77-2.543 1.155-3.621 1.155-1.29 0-2.6-.442-3.409-1.598-1.06-1.502-1.445-3.12-1.753-6.587Z"
      fill="#3989E5"
    />
    <path
      d="M55.456 97.504c0 3.158.674 5.777 1.925 7.568 1.117 1.58 2.6 2.389 4.41 2.389 1.368 0 2.851-.463 4.43-1.368 6.837-3.948 12.403-14.656 12.403-23.862 0-6.047-2.484-9.957-6.336-9.957-1.367 0-2.85.462-4.43 1.367-6.837 3.968-12.402 14.657-12.402 23.863Zm1.637 0c0-8.532 5.296-18.817 11.594-22.438 1.31-.77 2.542-1.155 3.62-1.155 2.85 0 4.7 3.255 4.7 8.32 0 8.532-5.297 18.816-11.594 22.437-1.33.77-2.543 1.156-3.621 1.156-1.29 0-2.254-.54-3.082-1.695-1.059-1.502-1.617-3.794-1.617-6.625Z"
      fill="#004EB6"
    />
    <path
      d="M63.178 90.667c0 1.155.54 1.83 1.29 1.79.232 0 .56 0 .829-.153.539-.308 1.078-.906 1.444-1.56.405-.732.674-1.637.674-2.427 0-1.502-1.059-2.215-2.195-1.56-1.117.655-2.042 2.407-2.042 3.91Zm3.428-2.292c-.057-.424-.25-.713-.539-.81l-.192-.057c-.116-.038-.155-.231-.078-.424a.704.704 0 0 1 .212-.288c.039-.02.097-.039.135-.02l.193.058c.23.077.423.231.578.462.096.193.173.405.211.674l.039.366c.02.174-.077.405-.212.501l-.038.038c-.116.078-.232.02-.25-.154l-.059-.346Zm-2.426 3.543a.585.585 0 0 1 .23-.346c.04-.02.059-.02.097-.039l.27-.019c.327-.02.693-.27 1.001-.655l.193-.25c.115-.154.25-.173.327-.077.02 0 .02.019.02.019.019.039.019.096.019.135 0 .115-.039.27-.135.385l-.193.25a3.17 3.17 0 0 1-.809.732 1.68 1.68 0 0 1-.616.212l-.27.02c-.038 0-.076 0-.096-.04-.038-.057-.077-.172-.038-.327Zm-.713-.982-.038-.173.019-.231c.02-.385.077-.713.212-1.098.096-.308.23-.54.404-.809l.27-.481c.115-.174.115-.366.212-.25.038.038.057.096.057.173a.639.639 0 0 1-.096.308l-.231.385c-.27.443-.443.982-.443 1.444v.309c0 .192-.115.404-.27.481-.154.058-.096.116-.096-.058Z"
      fill="#0064DC"
    />
    <path
      d="M65.45 86.872c-1.136.655-2.04 2.408-2.04 3.91 0 1.502.924 2.196 2.04 1.54 1.137-.654 2.042-2.407 2.042-3.909 0-1.502-.905-2.195-2.041-1.54Zm-1.405 3.737c0 .192-.116.404-.27.481-.135.077-.25-.02-.25-.192v-.328a4.451 4.451 0 0 1 .636-2.06l.23-.386c.116-.173.27-.25.366-.154.039.039.058.096.058.174a.781.781 0 0 1-.096.327l-.231.385c-.27.443-.443.982-.443 1.445v.308Zm2.388.578-.193.25c-.25.327-.52.558-.809.732a1.68 1.68 0 0 1-.616.212l-.27.019c-.134 0-.192-.154-.154-.347a.585.585 0 0 1 .231-.347c.039-.019.058-.019.097-.038l.27-.02c.327-.019.693-.25 1-.654l.194-.25c.115-.155.27-.193.346-.058.02.038.039.096.039.134a.54.54 0 0 1-.135.367Zm.732-2.215c-.02.019-.02.019-.039.019-.115.077-.23.02-.25-.135l-.039-.366c-.057-.423-.25-.712-.539-.809l-.192-.057c-.116-.039-.155-.232-.078-.424a.705.705 0 0 1 .212-.29c.039-.018.097-.038.135-.018l.193.057c.23.058.423.231.578.463.096.173.173.404.211.674l.039.366a.727.727 0 0 1-.231.52Z"
      fill="#004EB6"
    />
    <path d="M65.336 77.705v26.25h.115V77.589l-.115.116Z" fill="#004EB6" />
    <path
      d="m61.445 105.554.116.019 3.698-15.253.077-.328v-.077l.019-.077v-.134l.058-.193 3.524-14.58c-.038.02-.096.04-.135.078l-3.39 14.02-.096.386-.019.077-.02.057-.018.097-.02.038V89.935l-.019.038-.02.096-.038.135-.019.097-.115.5-3.583 14.753Z"
      fill="#004EB6"
    />
    <path
      d="M58.518 103.821c.02.038.058.077.077.115l6.433-13.154.23-.462.097-.174.02-.057.038-.077.02-.02.018-.019v-.02l.02-.018v-.039l.019-.038v-.02l.02-.019.038-.077.019-.058.039-.077.154-.327 7.414-15.157-.096-.058-7.627 15.58v.02l-.038.058v.019l-.02.02v.038l-.019.019-.019.058v.038l-.02.02-.038.077-.057.115-.078.154-6.644 13.54Z"
      fill="#004EB6"
    />
    <path
      d="m64.989 90.223-7.762 9.553c0 .058.02.096.02.154l7.915-9.726.058-.077.116-.135v-.019l.019-.038v-.02l.02-.019.019-.02.019-.019.019-.019.02-.02.057-.057.058-.058.135-.154 9.841-12.095-.096-.077-9.707 11.941-.308.366v.02l-.038.038v.02l-.02.018-.02.02-.018.019-.02.02v.018l-.019.02-.02.019h-.018l-.02.02-.019.019-.038.057-.039.058-.154.173Z"
      fill="#004EB6"
    />
    <path
      d="m57.131 96.329 7.858-6.086.212-.154.096-.058.058-.038.039-.02.019-.02.038-.018.02-.02h.019l.02-.019.134-.096.231-.174L76.333 81.5l-.077-.096-10.554 8.185-.116.096-.154.116-.038.02-.02.018-.038.02h-.039l-.019.019-.02.02h-.018l-.02.018-.038.039-.174.135-7.857 6.105c-.02.038-.02.077-.02.135Z"
      fill="#004EB6"
    />
    <path
      d="m57.44 93.575 7.568-3.505.174-.077.038-.02.097-.038.019-.02h.019l.02-.019.038-.019h.038l.02-.02h.019l.058-.038.308-.135 10.265-4.757-.039-.096-10.207 4.719-.289.134-.02.02-.115.038-.038.02h-.02v.019h-.038v.019h-.038l-.02.02-.019.019h-.038l-.04.019-.134.077-.154.058-7.453 3.447-.02.135Z"
      fill="#004EB6"
    />
    <path
      d="m57.882 91.456 7.03-1.464.27-.058h.038l.096-.019h.02l.057-.019h.097l.096-.02.077-.019 9.88-2.06-.02-.116-9.687 2.003-.212.039-.115.019-.077.02h-.27l-.077.018-.404.077-6.78 1.406c0 .097 0 .135-.019.193Z"
      fill="#004EB6"
    />
    <path
      d="m58.364 89.742 6.336.173.347.02h.577l.366.019 8.975.23v-.115l-9.302-.25h-.674l-6.625-.174c.019.02.019.058 0 .097Z"
      fill="#004EB6"
    />
    <path
      d="m58.884 88.22 6.144 1.58.096.019.096.02.039.019H65.316l.02.019h.077l.038.02.039.018h.019l.058.02.192.057 8.474 2.196.02-.116-8.224-2.118-.404-.096-.058-.02h-.078l-.019-.019h-.116l-.038-.02h-.039l-.095-.019-.29-.076-5.95-1.522c-.039-.038-.058 0-.058.038Z"
      fill="#004EB6"
    />
    <path
      d="m59.442 86.815 5.432 2.83.308.155h.019l.058.019.019.02.02.019h.019l.019.019h.096l.038.019.02.02.058.038.077.038.212.116 7.626 3.987.058-.097-7.704-4.025-.135-.058-.154-.077-.019-.019-.02-.02h-.038l-.038-.019h-.039l-.038-.019h-.039l-.058-.038-.211-.116-5.509-2.889c-.057.02-.077.058-.077.097Z"
      fill="#004EB6"
    />
    <path
      d="m60.078 85.37 4.911 4.218.154.135.097.077h.019l.02.019.018.02.02.018h.077l.038.039.02.02h.019l.096.076.193.154 6.702 5.759.077-.077-6.722-5.682-.23-.192-.02-.02-.077-.077h-.02l-.019-.019h-.019l-.038-.02V89.8l-.02-.02-.019-.019-.038-.038h-.02l-.019-.02-.193-.173-4.93-4.237c-.038 0-.058.038-.077.077Z"
      fill="#004EB6"
    />
    <path
      d="m60.887 83.81 4.179 5.7.154.193.039.039.019.019.02.02.018.018.02.02.019.019.02.02v.019h.018l.02.019.038.058.039.057.019.02.173.25 5.547 7.55.096-.058-5.546-7.55-.154-.192-.078-.097-.057-.057h-.02l-.019-.02v-.039l-.02-.019-.018-.019-.02-.019-.038-.058-.039-.058-.019-.038-.115-.154-4.218-5.759c-.039.039-.058.077-.077.116Z"
      fill="#004EB6"
    />
    <path
      d="m61.966 81.98 3.216 7.473.096.231.02.038.019.02v.019l.02.02v.038l.019.038v.02l.019.019v.02l.038.076.058.116.058.135 4.256 9.899.096-.039-4.198-9.783-.096-.231-.039-.078-.02-.057-.019-.058v-.02l-.019-.019v-.019l-.019-.02-.02-.038v-.077l-.115-.135-.02-.038-.095-.231-3.178-7.434c-.039.038-.058.077-.077.115Z"
      fill="#004EB6"
    />
    <path
      d="m63.525 79.766 1.695 9.533.058.308.02.077v.02l.019.057V90.012l.019.115.096.501 2.06 11.633.116-.02-2.137-11.96-.039-.192-.02-.077v-.308l-.057-.27-.02-.096-1.713-9.669a.232.232 0 0 0-.097.097Z"
      fill="#004EB6"
    />
    <path
      d="m67.743 89.877-1.81-1.04c-.02-.02-.04-.039-.078-.039-.115-.058-.25-.038-.404.058-.385.212-.674.809-.674 1.31 0 .288.096.481.25.558l1.83 1.06.02-.136c.038-.019.095-.038.134-.057.385-.212.674-.81.674-1.31a.83.83 0 0 0-.058-.327l.116-.077Z"
      fill="#2F455F"
    />
    <path
      d="M67.955 90.396c0 .501-.309 1.079-.674 1.31-.386.212-.675-.02-.675-.52s.309-1.079.675-1.31c.365-.212.674.02.674.52Z"
      fill="#004EB6"
    />
    <path
      d="M64.988 91.764c0 1.156.54 1.83 1.29 1.791.232 0 .56 0 .829-.154.54-.308 1.078-.905 1.444-1.56.405-.732.674-1.637.674-2.426 0-1.503-1.059-2.215-2.195-1.56-1.117.654-2.042 2.407-2.042 3.91Zm3.428-2.292c-.057-.423-.25-.712-.539-.808l-.192-.058c-.116-.039-.154-.231-.078-.424a.704.704 0 0 1 .212-.289c.039-.02.097-.038.135-.02l.193.059c.23.077.423.23.578.462.096.192.173.404.211.674l.039.366c.02.173-.077.404-.212.5l-.038.04c-.116.076-.231.018-.25-.155l-.059-.347Zm-2.426 3.544a.585.585 0 0 1 .23-.347c.04-.019.059-.019.097-.038l.27-.02c.327-.019.693-.269 1.001-.654l.193-.25c.115-.155.25-.174.327-.078.02 0 .02.02.02.02.019.038.019.096.019.135 0 .115-.039.27-.135.385l-.193.25a3.17 3.17 0 0 1-.809.732 1.68 1.68 0 0 1-.616.212l-.27.019c-.038 0-.076 0-.096-.038a.414.414 0 0 1-.038-.328Zm-.713-1.001-.038-.174.019-.23c.02-.386.077-.713.212-1.099.096-.308.23-.539.404-.808l.27-.482c.115-.173.115-.347.212-.25.038.038.058.096.058.173a.64.64 0 0 1-.097.308l-.23.385c-.27.443-.444.983-.444 1.445v.308c0 .193-.115.404-.27.481-.154.077-.096.135-.096-.057Z"
      fill="#0064DC"
    />
    <path
      d="M67.26 87.97c-1.136.655-2.041 2.407-2.041 3.91 0 1.502.924 2.195 2.041 1.54 1.136-.654 2.042-2.407 2.042-3.91 0-1.501-.906-2.195-2.042-1.54Zm-1.406 3.736c0 .193-.115.405-.27.482-.134.077-.25-.02-.25-.193v-.327a4.453 4.453 0 0 1 .636-2.06l.231-.386c.116-.173.27-.25.366-.154.038.038.058.096.058.173a.78.78 0 0 1-.097.328l-.23.385c-.27.443-.444.982-.444 1.444v.308Zm2.389.578-.193.25a2.85 2.85 0 0 1-.809.732 1.68 1.68 0 0 1-.616.212l-.27.02c-.135 0-.193-.155-.154-.347a.585.585 0 0 1 .231-.347c.039-.02.058-.02.096-.038l.27-.02c.327-.019.693-.25 1.001-.654l.193-.25c.116-.155.27-.194.347-.059.019.039.038.097.038.135.02.116-.038.25-.134.366Zm.731-2.215c-.019.02-.019.02-.038.02-.116.077-.231.019-.25-.135l-.04-.366c-.057-.424-.25-.713-.538-.81l-.193-.057c-.116-.038-.154-.231-.077-.424a.703.703 0 0 1 .212-.288c.038-.02.096-.039.135-.02l.192.058c.231.058.424.231.578.462.096.174.173.405.212.674l.038.366a.764.764 0 0 1-.23.52Z"
      fill="#004EB6"
    />
    <path d="m35.387 107.942-.424-.27.54-.289.404.27-.52.289Z" fill="#0064DC" />
    <path
      d="M35.907 118.438v-10.785l-.52.289v10.785l.52-.289Z"
      fill="#004EB6"
    />
    <path
      d="m35.387 118.727-.424-.269v-10.786l.424.27v10.785Z"
      fill="#71ADEE"
    />
    <path d="M35.387 107.441v.501l-.424-.27.02-.481.404.25Z" fill="#71ADEE" />
    <path
      d="m42.974 102.588-7.992 4.603.404.25 8.012-4.603-.424-.25Z"
      fill="#71ADEE"
    />
    <path
      d="m43.399 102.838-.02.501-7.992 4.603v-.501l8.012-4.603Z"
      fill="#004EB6"
    />
    <path
      d="m43.264 82.058-.983-.597 1.252-.674.963.597-1.233.674Z"
      fill="#0064DC"
    />
    <path
      d="M44.496 98.255V81.384l-1.232.674v16.871l1.232-.674Z"
      fill="#004EB6"
    />
    <path d="m43.264 98.93-.983-.598V81.461l.983.597v16.871Z" fill="#71ADEE" />
    <path
      d="m42.281 81.942-2.83-1.83L67.048 64.09l2.85 1.849-27.618 16.004Z"
      fill="#E2F6FF"
    />
    <path
      d="M69.899 68.326v-2.388L42.281 81.942v2.389L69.9 68.326Z"
      fill="#71ADEE"
    />
    <path d="m42.281 84.33-2.83-1.83v-2.387l2.83 1.83v2.388Z" fill="#ABE0F6" />
    <path
      d="M101.774 111.948c-1.348-.79-2.985-1.194-4.45-1.426-4.68-.731-9.455-.404-14.116.405-4.565.79-9.187 1.907-13.578 3.351-.693.231-1.406.462-2.08.713-.54.192-.809.5-1.271.809-1.079.712-2.446.962-3.64 1.405-1.117.405-2.253.829-3.236 1.541-.404.289-.943.751-1.155 1.214-.135.288-.154.616-.154.943 0 .886.192 1.618.886 2.196.655.558 1.598.847 2.407 1.04 4.969 1.136 10.13.096 15.234-.27 1.06-.077 1.734.019 1.907 1.136.327 2.08 2.83 2.6 4.564 2.832 3.814.52 7.704-.155 11.344-1.406 2.157-.732 4.256-1.676 6.182-2.909 2.061-1.309 3.563-2.946 3.987-5.431.231-1.348.077-2.792-.597-3.986-.539-.925-1.329-1.618-2.234-2.157Z"
      fill="#2F455F"
    />
    <path
      d="M67.01 36.028c-.154.924-.52 1.81-.943 2.638-.174.347-.347.655-.54.983-.847 1.463-1.887 2.85-2.946 4.16-.713.866-1.464 1.733-2.37 2.407-.615.462-1.347.847-2.118.886-.27.02-.539-.02-.75-.154-.694-.462-.656-1.676-.636-2.388.019-1.04.212-2.08.481-3.082.058-.212.116-.443.193-.655.385-1.232.905-2.426 1.444-3.601.039-.096.077-.154.116-.25.693-1.483 1.406-2.986 2.31-4.372.348-.52.771-1.079 1.388-1.233a.662.662 0 0 1 .27-.02c.134.04.23.116.346.174.366.212.732.116 1.136.154.944.096 1.772.732 2.215 1.58.462.866.558 1.848.404 2.773Z"
      fill="#FFCFB3"
    />
    <path
      d="M67.01 36.028c-.154.924-.52 1.81-.943 2.638-.173.347-.347.655-.54.983-.019 0-.038.019-.038.019-.847.192-1.733-.096-2.542-.462-.79-.366-1.522-.848-2.35-1.156-.366-.135-.77-.25-1.098-.481-.23-.155-.404-.366-.558-.598.693-1.482 1.406-2.985 2.311-4.371.347-.52.77-1.079 1.387-1.233a.662.662 0 0 1 .27-.02c.134.04.23.116.346.174.366.212.732.116 1.136.154.944.096 1.772.732 2.215 1.58.462.866.559 1.848.405 2.773Z"
      fill="#71ADEE"
    />
    <path
      d="M72.596 20.524c-.154.02-.424.404-.52.54-.154.211-.27.461-.347.712-.077.23-.058.424-.058.655-.019.288-.134.616-.23.886-.155.423-.193.885-.309 1.31-.347 1.193-.886 2.33-1.329 3.504-.654 1.753-1.059 3.583-1.463 5.412-.135.597-.27 1.175-.386 1.772-.019.116-.057.231-.057.366-.039.135-.039.289-.039.424s-.02.25-.038.385c0 .02-.02.038-.02.038-.019.116-.038.232-.096.366-.02.058-.038.116-.077.135-.02.02-.038.02-.058.039-.038.019-.058.019-.096.019-.462.058-.944-.02-1.406-.116-.135-.038-.25-.057-.366-.115a8.162 8.162 0 0 1-2.715-1.425 9.842 9.842 0 0 1-1.85-1.946c-.269-.385-.211-.558-.038-.943.154-.347.308-.655.462-.983l.116-.23c.385-.79.79-1.58 1.194-2.37a84.045 84.045 0 0 1 2.639-4.622c.346-.558.808-1.136 1.328-1.56.385-.327.713-.616 1.175-.847.308-.154.636-.289.944-.443.154-.058.347-.135.5-.193 1.021-.366 2.061-.616 3.14-.77Z"
      fill="#ABE0F6"
    />
    <path
      d="M66.067 34.853c-.963-.27-1.81-.751-2.503-1.29.211-.77.385-1.56.52-2.35.442-2.234.693-4.603 2.022-6.433 0 3.351.019 6.722-.039 10.073Z"
      fill="#71ADEE"
    />
    <path
      d="M67.82 36.49c0 .019-.02.038-.02.038a4.123 4.123 0 0 1-.231.54c-.039.019-.058.019-.096.019-.463.057-.944-.02-1.406-.116-2.003-.462-3.737-1.81-4.95-3.466-.27-.386-.212-.559-.038-.944.154-.347.308-.655.462-.982.828 1.078 2.696 3.1 5.354 3.466.597.077.886.308.982.655.077.212.058.482-.058.79Z"
      fill="#FFDC1E"
    />
    <path
      d="M66.067 34.853v.154c-.02.636-.058 1.31-.366 1.85a4.88 4.88 0 0 1-.693-.232c-.039 0-.058-.02-.078-.02-.211-.096-.442-.192-.654-.307h-.02l-.577-.347a.3.3 0 0 1-.154-.116c-.154-.096-.308-.212-.443-.308-.039-.019-.058-.038-.097-.077.02-.02.02-.038.02-.058.212-.597.385-1.194.558-1.81.694.52 1.54 1.002 2.504 1.271Z"
      fill="#E39F3A"
    />
    <path
      d="M80.28 127.24c.944.327 1.984.212 2.966-.039 2.119-.52 4.199-1.656 5.239-3.563.712-1.329.886-2.927 1.81-4.102.25-.308.54-.597.693-.944.154-.346.193-.751.193-1.136 0-.655-.077-1.367-.5-1.868-1.33 2.446-3.66 4.16-5.913 5.778-1.483 1.059-3.005 2.157-4.719 2.773-.616.231-1.58.597-1.425 1.406.154.79.905 1.425 1.656 1.695Z"
      fill="#004EB6"
    />
    <path
      d="M90.7 117.899c-.386.847-1.233 1.406-1.695 2.215-.386.654-.482 1.444-.732 2.176-.482 1.425-1.541 2.638-2.85 3.39-1.272.731-2.736 1.059-4.218 1.04h-.135c-.54-.02-1.098-.097-1.58-.347-.481-.251-.885-.693-.982-1.233-.115-.712.347-1.348.732-1.887.462-.636.963-1.233 1.406-1.888a29.518 29.518 0 0 0 2.35-4.082 17.16 17.16 0 0 0 .943-2.312c.058-.134.097-.269.135-.385.385-1.252.982-2.966 2.639-2.889.616.039 1.194.347 1.695.694a6.75 6.75 0 0 1 2.118 2.638c.424.886.597 1.965.173 2.87Z"
      fill="#FAFAFA"
    />
    <path
      d="M90.7 117.899c-.386.847-1.233 1.406-1.695 2.215-.386.654-.482 1.444-.732 2.176-.482 1.425-1.541 2.638-2.85 3.39-1.272.731-2.735 1.059-4.218 1.04a8.448 8.448 0 0 0 2.176-1.252c1.444-1.098 2.542-2.639 3.043-4.372.231-.809.346-1.656.616-2.465.116-.386.289-.771.347-1.156.058-.385 0-.847-.29-1.136-.403-.424-1.058-.405-1.636-.501-.559-.096-1.194-.385-1.522-.867.058-.134.097-.269.135-.385.385-1.252.982-2.966 2.639-2.889.616.039 1.194.347 1.695.694a6.75 6.75 0 0 1 2.118 2.638c.424.886.597 1.965.173 2.87Z"
      fill="#AAD2F6"
    />
    <path
      d="M59.268 122.983c.366.212.79.289 1.194.309 1.349.077 2.639-.501 3.814-1.156 1.175-.655 2.31-1.425 3.601-1.791 1.06-.289 2.157-.327 3.255-.462.828-.097 1.676-.289 2.35-.79 1.136-.847 1.502-2.6.77-3.813-.558.616-1.252 1.098-1.984 1.502a15.541 15.541 0 0 1-5.912 1.887c-1.502.174-3.005.116-4.507.039-.693-.039-1.406-.077-2.06.135-.675.212-1.618.943-1.715 1.695-.096.924.405 2.003 1.194 2.445Z"
      fill="#004EB6"
    />
    <path
      d="M74.368 117.513c-.231.482-.636.867-1.098 1.156-1.618 1.021-3.236.385-4.988.616-1.117.135-2.119.713-3.062 1.31-.944.616-1.888 1.271-2.947 1.656-1.06.385-2.292.462-3.274-.096-.058-.039-.116-.058-.154-.116-1.098-.77-.867-2.426-.29-3.505.463-.847 1.233-1.637 2.1-2.061.963-.481 1.945-.712 3.005-.963a11.588 11.588 0 0 0 2.946-1.097c.058-.039.116-.058.154-.097.231-.134.482-.308.713-.481.79-.597 1.521-1.271 2.465-1.56.616-.193 1.175-.096 1.772.077.02 0 .039.019.058.019.327.096.616.154.963.058.115-.039.77-.327.77-.501.27.559.289 1.194.462 1.791.347 1.271 1.079 2.446.405 3.794Z"
      fill="#FAFAFA"
    />
    <path
      d="M74.368 117.514c-.231.481-.636.866-1.098 1.155-1.618 1.021-3.236.385-4.988.616-1.117.135-2.119.713-3.063 1.31-.943.616-1.887 1.271-2.946 1.656-1.06.386-2.292.463-3.274-.096-.058-.038-.116-.058-.154-.115 2.06-.058 4.083-.655 5.681-1.946.52-.404.982-.886 1.54-1.232 1.291-.848 2.967-.982 4.2-1.926.346-.27.654-.636.577-1.059-.058-.347-.346-.597-.655-.713-.308-.135-.654-.154-.982-.212-.79-.154-1.386-.558-1.733-1.136.79-.597 1.521-1.271 2.465-1.56.616-.193 1.175-.096 1.772.077.02 0 .038.019.058.019.327.096.616.154.963.058.115-.039.77-.327.77-.501.27.559.289 1.194.462 1.791.347 1.291 1.079 2.465.405 3.814Z"
      fill="#AAD2F6"
    />
    <path
      d="M77.68 82.616a98.184 98.184 0 0 1-.79 5.392c-.154 1.002-.346 1.946-.365 2.947-.039 1.02.076 2.022.192 3.024.385 3.466-.058 6.644-1.04 9.976l-.52 1.733c-.52 1.714-1.02 3.448-1.233 5.22-.038.443-.096.886-.096 1.329-.02.385.116.616-.173.924-.462.462-1.098.713-1.734.867-.096.019-.211.038-.308.057-1.001.193-2.08.193-2.985-.269a1.412 1.412 0 0 1-.5-.385c-.174-.212-.135-.386-.097-.655.077-.482.02-1.04.02-1.541-.04-1.021-.078-2.022-.116-3.043a329.26 329.26 0 0 0-.212-4.353c-.096-1.771-.173-3.543-.27-5.334l-.288-5.97c-.116-2.1-.212-4.18-.309-6.28-.096-2.099-.192-4.16-.289-6.24a301.412 301.412 0 0 1-.23-5.893c-.078-1.694-.136-3.37-.193-5.065v-.096c-.039-1.387-.058-2.773-.077-4.16-.02-.925-.02-1.83.038-2.754 0 .924 1.406 1.868 2.1 2.215 1.02.52 2.06 1.136 2.542 2.234.462 1.078.886 1.984 2.195 2.234 1.06.212 2.234-.25 2.87-1.136.077-.116.154-.232.27-.27.27-.058.443.27.481.54.404 2.06.79 4.178 1.02 6.278.136 1.194.193 2.388.193 3.601-.077 1.637.077 3.313-.096 4.873Z"
      fill="#ABE0F6"
    />
    <path
      d="M77.68 82.616a98.088 98.088 0 0 1-.79 5.392c-.153 1.002-.346 1.946-.365 2.947-.039 1.02.077 2.022.192 3.024.386 3.466-.058 6.644-1.04 9.976l-.52 1.733c-.52 1.714-1.02 3.448-1.232 5.22-.039.443-.097.886-.097 1.329-.019.385.116.616-.173.924-.462.462-1.098.713-1.733.867.212-10.42 1.81-20.8 1.714-31.239-.02-2.253-.116-4.545-.963-6.625-.963-2.388-2.793-4.276-4.757-5.874-.559-.443-1.194-.809-1.791-1.214v-.115a299.074 299.074 0 0 1-.077-4.16c-.02-.925-.02-1.83.038-2.754 0 .924 1.406 1.868 2.1 2.215 1.02.52 2.06 1.136 2.542 2.234.462 1.078.886 1.984 2.195 2.234 1.06.212 2.234-.25 2.87-1.136.077-.116.154-.232.27-.27.27-.058.442.27.481.54.404 2.06.79 4.178 1.02 6.278.136 1.194.193 2.388.193 3.601-.058 1.637.097 3.313-.077 4.873Z"
      fill="#6095BF"
    />
    <path
      d="M74.252 113.18c-.096.231-.308.405-.52.539-.52.328-1.097.52-1.695.636-.481.096-.963.116-1.444.116-.924 0-1.907-.116-2.639-.675a.908.908 0 0 1-.25-.288c-.038-.097-.038-.212-.058-.309-.019-.269-.038-.539-.019-.828.02-.231.058-.462.193-.655.019-.038.058-.096.115-.115.02 0 .02 0 .039.019.019 0 .019.019.019.019.02.058.039.116.058.155.038.134.173.211.289.288.616.347 1.31.482 2.022.482.54 0 1.098-.077 1.618-.212.27-.058.52-.135.77-.212.231-.058.462-.135.693-.231.097-.038.193-.096.29-.154a.285.285 0 0 0 .115-.096c.058-.077.038-.174.058-.27 0-.038.019-.058.038-.077.193-.096.27.482.29.597.038.193.076.385.095.597 0 .231 0 .462-.077.674Z"
      fill="#FFDC1E"
    />
    <path
      d="M74.252 113.18c-.096.232-.308.405-.52.54-.52.327-1.098.52-1.695.635.096-.269.154-.539.154-.828 0-.27-.038-.52-.096-.77a2.265 2.265 0 0 0-.154-.385c.27-.058.52-.135.77-.212.231-.058.463-.135.694-.231.096-.039.192-.097.288-.154.039-.02.097-.058.116-.097.058-.077.039-.173.058-.269 0-.039.02-.058.038-.077.193-.097.27.481.29.597.038.192.076.385.096.597.038.211.038.443-.039.654Z"
      fill="#E39F3A"
    />
    <path
      d="M91.296 103.609c0 .731-.27 1.521-.366 2.272-.115.809-.211 1.618-.327 2.446-.231 1.656-.443 3.313-.674 4.969-.02.116-.038.231-.096.347-.039.115-.135.192-.231.269-.366.308-.79.52-1.233.636-.52.154-1.06.212-1.599.231-.616.019-1.251-.019-1.848-.193-.27-.077-.54-.192-.674-.443-.097-.192-.097-.404-.097-.597.02-1.174.154-2.349.193-3.524.02-1.656-.096-3.313-.29-4.95a36.59 36.59 0 0 0-1.829-7.55c-.79-2.156-1.329-4.39-2.118-6.567-1.213-3.312-1.945-6.779-3.14-10.092-.115-.288-.23-.597-.346-.885-.616-1.599-1.464-2.966-2.369-4.392-.828-1.29-1.772-2.445-2.465-3.813-.097-.173-.174-.366-.27-.54-.693-1.579-1.078-3.312-1.02-5.026.038-.655.134-1.348.442-1.926-.192.347.848 1.406 1.06 1.618.481.404.963.77 1.56.963 1.964.693 4.121.636 6.143.193 2.023-.463 3.948-1.31 5.797-2.254.463-.23.944-.462 1.464-.54.405-.057.867.117 1.233-.095.212-.116.385-.308.539-.482l.732-.79c.289 1.252.346 2.562.346 3.852.039 3.89-.192 7.8-.404 11.69-.173 3.178-.559 6.414-.482 9.611.039 1.945-.27 3.64.617 5.489.963 2.08 1.964 4.218 2.1 6.49.038 1.156-.136 2.389-.348 3.583Z"
      fill="#ABE0F6"
    />
    <path
      d="M91.297 103.608c0 .732-.27 1.522-.366 2.273-.116.809-.212 1.618-.328 2.446-.23 1.656-.443 3.313-.674 4.969-.019.115-.038.231-.096.346-.039.116-.135.193-.231.27-.366.308-.79.52-1.233.636-.135-4.488.039-9.033-.366-13.52-.385-4.507-1.733-8.86-2.407-13.309a45.224 45.224 0 0 1-.462-6.451c0-2.1-.097-4.295-1.137-6.183-.212-.385-.462-.731-.808-1.001-.617-.443-1.426-.462-2.196-.481-1.483-.04-2.947-.097-4.41-.328-1.522-.231-3.352-.674-4.815-1.483-.097-.173-.174-.366-.27-.54-.693-1.578-1.078-3.312-1.02-5.026.038-.655.134-1.348.442-1.926-.192.347.848 1.406 1.06 1.618.481.404.962.77 1.56.963 1.964.693 4.121.635 6.143.193 2.022-.463 3.948-1.31 5.797-2.254.463-.23.944-.462 1.464-.539.404-.058.867.115 1.233-.096.211-.116.385-.309.539-.482l.732-.79c.289 1.252.346 2.562.346 3.852.039 3.89-.192 7.8-.404 11.69-.173 3.179-.558 6.414-.481 9.611.038 1.945-.27 3.64.616 5.49.963 2.08 1.964 4.217 2.099 6.489.058 1.137-.115 2.369-.327 3.563Z"
      fill="#6095BF"
    />
    <path
      d="M90.275 114.086c-.019.019-.019.038-.038.057-.25.289-.655.443-1.02.559-.29.096-.598.154-.887.212-.096.019-.212.038-.308.058a8.975 8.975 0 0 1-1.232.096c-.174 0-.366-.019-.54-.019-.481-.039-.982-.116-1.444-.289-.231-.097-.462-.193-.655-.328-.115-.058-.173-.135-.231-.212a.459.459 0 0 1-.058-.327c.02-.231.039-.481.077-.732.02-.135.039-.269.039-.404s.019-.231.058-.366c.019-.039.038-.058.057-.077.02-.02.058-.02.077-.02.039.02.039.058.058.116.077.231.482.27.694.308a9.438 9.438 0 0 0 3.447.116c.212-.039.443-.077.655-.116.192-.038.385-.077.558-.135.116-.038.212-.057.308-.115.154-.077.135-.154.174-.308 0-.039.019-.058.038-.077.193-.116.154.327.173.385.02.25.058.52.058.77.02.27.077.655-.058.848Z"
      fill="#FFDC1E"
    />
    <path
      d="M90.276 114.086c-.02.019-.02.038-.039.057-.25.289-.655.443-1.02.559-.29.096-.597.154-.886.212l.115-.405a3.137 3.137 0 0 0-.096-1.637l.655-.115c.192-.039.385-.077.558-.135.116-.039.212-.058.308-.116.154-.077.135-.154.174-.308 0-.038.019-.058.038-.077.193-.115.154.328.174.385.019.251.057.52.057.771.039.231.097.616-.038.809Z"
      fill="#E39F3A"
    />
    <path
      d="M92.857 28.112c-.116.29-.366.482-.597.694-.405.385-.617.847-.886 1.31-.385.673-.482 1.54-.655 2.291a57.392 57.392 0 0 0-.52 2.62 39.998 39.998 0 0 0-.289 2.04 61.206 61.206 0 0 0-.327 3.236 20.436 20.436 0 0 0-.039 2.177c.02 5.161.481 15.908.655 20.01.038.867.058 1.425.058 1.599.019.982-.02 1.386-.77 2.099-1.233 1.155-2.793 1.868-4.373 2.427a27.05 27.05 0 0 1-2.118.674c-3.062.866-6.26 1.232-9.418.943-2.06-.192-4.121-.693-5.855-1.81-.5-.308-.963-.693-1.31-1.175-.77-1.078-.75-2.253-.712-3.447.02-.386.02-.77.02-1.156-.04-1.367-.155-7.992-.309-13.635-.02-.963-.058-1.907-.077-2.774-.038-1.598-.096-2.985-.135-4.006-.096-2.022-.25-4.025-.192-6.028.077-2.195.115-4.372.096-6.567-.02-.982-.02-1.984.058-2.966.135-1.733 1.06-3.332 2.446-4.391.635-.482 1.406-.79 2.176-1.04.02 0 .038-.02.058-.02a42.775 42.775 0 0 1 3.409-.962c.77-.193 1.54-.366 2.33-.482.982-.154 1.984-.347 2.985-.385.347-.02.674.058 1.021.058.5-.02.982-.039 1.483.038 1.656.27 3.255 1.233 4.622 2.157.097.058.193.135.308.212 1.599 1.098 5.547 4.025 6.356 4.796.443.423.732.866.5 1.463Z"
      fill="#3989E5"
    />
    <path
      d="M90.218 62.663v.02c-.096.365-.289.654-.713 1.059-1.232 1.155-2.792 1.868-4.371 2.426-.732.27-1.483.501-2.234.713a30.42 30.42 0 0 0-2.254-9.206c-.173-.404-.366-.828-.693-1.136-.385-.386-.905-.578-1.425-.732-2.543-.848-5.22-1.618-7.203-3.41-1.098-.981-1.965-2.291-3.39-2.83-.443-.154-1.56-.29-2.542-.559-.02-.963-.058-1.906-.077-2.773 2.118-1.02 5.046.02 7.03.712 2.253.79 4.776 1.58 6.971.636 1.117-.481 2.023-1.367 2.85-2.273 1.6-1.79 2.967-3.794 4.16-5.893.444-.77.887-1.56 1.6-2.06.577-.405 1.386-.56 1.983-.29a61.316 61.316 0 0 0-.327 3.236 20.41 20.41 0 0 0-.039 2.176c.02 5.162.481 15.909.655 20.01.02.059.02.116.02.174Z"
      fill="#004EB6"
    />
    <path
      d="M90.218 62.663c-.077.366-.27.674-.713 1.098-1.232 1.155-2.792 1.868-4.371 2.426-3.698 1.33-7.646 1.984-11.537 1.618-2.06-.192-4.121-.693-5.855-1.81-.5-.308-.963-.693-1.31-1.175-.48-.674-.654-1.406-.712-2.138.02.386 0 .752-.02 1.137-.038 1.194-.038 2.368.713 3.447.347.481.81.867 1.31 1.175 1.734 1.117 3.794 1.618 5.855 1.81 3.89.366 7.858-.289 11.536-1.618 1.58-.558 3.14-1.27 4.372-2.426.751-.713.79-1.117.77-2.1.02-.173 0-.673-.038-1.444Z"
      fill="#FFDC1E"
    />
    <path
      d="M89.525 66.207c-1.233 1.155-2.793 1.868-4.372 2.427a27.05 27.05 0 0 1-2.119.674c0-.81-.038-1.618-.115-2.408a30.126 30.126 0 0 0 2.234-.712c1.58-.559 3.14-1.272 4.372-2.427.424-.405.616-.713.712-1.06.039.752.058 1.252.058 1.407 0 .982-.019 1.367-.77 2.099Z"
      fill="#E39F3A"
    />
    <path
      d="M86.02 21.853c-.058.52-.309 1.02-.79 1.502-.867.924-1.945 1.618-2.87 2.195-2.041 1.233-3.698 1.965-5.373 2.35a5.02 5.02 0 0 1-1.31.173c-.173 0-.346-.019-.539-.038-.847-.116-1.56-.559-1.965-1.194-.462-.732-.52-1.695-.173-2.716.443-1.27 1.213-2.446 2.254-3.312a6.94 6.94 0 0 1 1.714-1.079c.693-.289 1.27-.404 2.022-.347.192.02.385.058.578.058.5-.019.982-.038 1.483.039 1.656.27 3.254 1.232 4.622 2.157.115.038.23.115.346.212Z"
      fill="#FFDC1E"
    />
    <path
      d="M84.46 22.604c-.771.809-1.696 1.425-2.64 2.003-1.31.79-2.657 1.502-4.12 1.964-.31.097-.617.193-.945.27-.462.115-.943.192-1.425.115-.481-.057-.963-.308-1.213-.712-.328-.52-.27-1.194-.058-1.772.193-.578.52-1.117.693-1.695.135-.462.154-.944.097-1.425-.097-.578-.52-1.04-.559-1.618-.058-.712.424-1.464.982-1.868.694-.5 1.541-.712 2.33-1.02 2.312-.867 4.238-2.485 6.087-4.141.077.52.115 1.04.135 1.56.038.885 0 1.79.019 2.677.019 1.406.192 2.87.886 4.102.096.135.173.289.212.462.077.404-.193.809-.482 1.098Z"
      fill="#ABE0F6"
    />
    <path
      d="M84.46 22.604c-.771.809-1.696 1.425-2.64 2.003-1.309.79-2.657 1.502-4.12 1.964.307-.212.596-.443.885-.655.694-.539 1.348-1.117 1.791-1.848.559-.906.77-1.984.77-3.043.02-1.406-.596-2.831-.365-4.218.077-.482.27-.963.558-1.348.385-.501 1.233-.905 1.83-1.079.192-.058.404-.096.635-.115.039.886 0 1.79.02 2.677.019 1.406.192 2.87.886 4.102.096.135.173.289.211.462.097.405-.173.809-.462 1.098Z"
      fill="#71ADEE"
    />
    <path
      d="M86.077 12.493c-.077 1.213-.655 2.369-1.54 3.197-.386.366-.848.674-1.368.828-.173.038-.366.077-.5.173-.136.077-.232.212-.328.328-.29.385-.54.809-.925 1.117-.346.27-.77.443-1.155.616-2.003.809-4.102 1.387-6.24 1.656-.559.058-1.175.116-1.695-.154-.02-.019-.039-.019-.039-.038a1.482 1.482 0 0 1-.693-.905c-.096-.366-.038-.752 0-1.117.096-.732.173-1.464.231-2.215.039-.462.039-.982-.289-1.31-.173-.173-.443-.27-.558-.481-.135-.193-.116-.463-.039-.675.077-.211.231-.404.385-.597.212-.27.424-.52.636-.79.154-.211.443-.442.5-.692.155-.54-.153-1.117-.269-1.618-.212-.925 0-1.888.347-2.774.616-1.56 1.27-3.466 2.985-4.102.77-.289 1.618-.308 2.446-.212 1.078.135 2.176.52 2.87 1.33.038.038.077.057.096.096.828 1.078.79 2.6 1.444 3.794.347.635.848 1.155 1.387 1.637.404.366.809.712 1.136 1.136.27.366.636.54.944.847.212.25.25.597.231.925Z"
      fill="#ABE0F6"
    />
    <path
      d="M86.077 12.493c-.077 1.213-.655 2.369-1.54 3.197-.386.366-.848.674-1.368.828-.173.038-.366.077-.5.173-.135.077-.232.212-.328.328-.289.385-.54.809-.924 1.117-.347.27-.77.443-1.156.616-2.003.809-4.102 1.387-6.24 1.656-.559.058-1.175.116-1.695-.154-.02-.019-.038-.019-.038-.038 1.406-.828 2.734-1.753 3.91-2.89.731-.692 1.405-1.482 1.79-2.407.328-.79.443-1.656.443-2.523.02-1.54-.308-3.062-.27-4.602.04-1.175.251-3.236 1.58-3.66.347-.115.732-.115 1.098-.057.038.038.077.057.096.096.828 1.078.79 2.6 1.444 3.794.347.635.848 1.155 1.387 1.637.405.366.809.713 1.136 1.136.27.366.636.54.944.848.212.23.25.577.231.905Z"
      fill="#71ADEE"
    />
    <path
      d="M89.12 9.72c-.154 1.001-.404 2.06-.828 2.985a8.426 8.426 0 0 1-.481.924c-.655 1.098-1.618 2.311-2.89 2.677-.269.077-.539.096-.808 0-.308-.115.173-.385.289-.481.944-.81 1.887-2.754.77-3.775-.423-.385-1.078-.405-1.637-.289a3.684 3.684 0 0 0-1.675.867c-.501.462-.732 1.059-1.117 1.617-.097.116-.174.232-.29.309-.115.057-.288.077-.385-.039-.096-.096-.096-.231-.096-.366.02-.462.02-.905.02-1.367-.02-.462-.04-.925-.212-1.368-.193-.462-.578-.866-.694-1.386-.23-1.002.655-1.965.559-3.005-.039-.443-.27-.847-.597-1.155a2.121 2.121 0 0 0-.482-.366c-.866-.52-1.906-.405-2.927-.443-.636-.02-1.271-.058-1.868-.231-.52-.154-.925-.366-1.271-.828a2.527 2.527 0 0 1-.54-1.464c-.019-.713.29-1.445.906-1.791a1.333 1.333 0 0 0 .23 1.425c0-.289.135-.559.347-.732.039.27.154.54.386.674.635.405 1.637-.481 2.157-.79 1.56-.962 3.408-1.405 5.257-1.31a9.4 9.4 0 0 1 3.544.868c.77.366 1.444.866 2.041 1.444 1.021 1.02 1.734 2.33 2.119 3.717a9.083 9.083 0 0 1 .173 3.679Z"
      fill="#71ADEE"
    />
    <path
      d="M89.12 9.72c-.154 1.001-.404 2.06-.828 2.985a8.444 8.444 0 0 1-.482.924c-.654 1.098-1.617 2.312-2.889 2.677-.27.078-.539.097-.808 0-.309-.115.173-.385.288-.481.944-.809 1.888-2.754.77-3.775-.423-.385-1.078-.404-1.636-.289a3.685 3.685 0 0 0-1.676.867c-.5.462-.732 1.06-1.117 1.618-.096.115-.173.23-.289.308-.115.058-.289.077-.385-.039-.096-.096-.096-.23-.096-.366.02-.462.02-.905.02-1.367-.02-.462-.04-.924-.213-1.367-.192-.463-.578-.867-.693-1.387-.231-1.002.655-1.965.558-3.005-.038-.442-.27-.847-.597-1.155a2.123 2.123 0 0 0-.481-.366c.038-.02.058-.02.096-.02.482.02.963.174 1.445.193a7.949 7.949 0 0 0 1.79-.154 7.365 7.365 0 0 0 1.888-.674c1.175-.616 2.196-1.502 3.062-2.523 1.021 1.021 1.734 2.33 2.119 3.717a9.435 9.435 0 0 1 .154 3.679Z"
      fill="#004EB6"
    />
    <path
      d="M88.947 45.484c-1.021 1.618-2.042 3.216-3.063 4.834-.558.867-1.117 1.714-1.752 2.523-.366.462-.732 1.04-1.252 1.329-.597.347-1.348.52-2.022.327-.963-.27-1.791-.693-2.697-1.117-1.136-.539-2.388-.75-3.582-1.136-2.812-.867-5.277-2.639-7.241-4.776l-.29-.29-.096-.095c-.712-.79-1.367-1.599-2.099-2.35-.346-.347-.693-.713-1.078-1.002-.443-.327-.944-.577-1.445-.828-.905-.443-1.79-.886-2.696-1.329-.732-.365-1.213-.943-1.791-1.521-.154-.154-.308-.308-.405-.52a.744.744 0 0 1-.057-.636l.019-.019a.616.616 0 0 1 .578-.346.724.724 0 0 1-.347-.29.512.512 0 0 1 .02-.442.665.665 0 0 1 .153-.174.956.956 0 0 1 .636-.192c.038 0 .096 0 .116-.02.096-.096-.116-.173-.155-.192a.824.824 0 0 1-.308-.27.52.52 0 0 1 .039-.616c.135-.135.346-.154.52-.154.27-.02.558.02.828.058.27.038.481.134.713.23.5.213.963.52 1.463.752.058.038.135.058.193.038-.02-.115-.096-.192-.173-.27-.424-.384-.983-.596-1.445-.943a2.202 2.202 0 0 1-.481-.462c-.116-.135-.309-.366-.29-.559.02-.173.212-.289.386-.327.867-.25 1.772.154 2.542.597.443.27.867.52 1.233.886.346.346.693.693 1.059 1.04.212.192.809.905 1.098.79.096-.039.192-.097.27-.097.115 0 .211.096.288.154.385.443.597 1.021.713 1.618.115.578.058 1.194.404 1.676.443.635 1.406.962 2.08 1.31.212.115.405.211.617.307.597.309 1.194.597 1.79.867.733.347 1.445.674 2.177 1.02.886.405 1.772.81 2.638 1.233.27.135.54.27.79.405.308.135.597.289.905.443.27-.462.52-.905.79-1.368.366-.616.713-1.232 1.02-1.887.174-.366.367-.732.54-1.098.404-.79.886-1.521 1.483-2.215.809-.905 2.58-1.31 3.736-1.386 1.368-.077 2.812.385 3.698 1.463.212.27.404.559.5.886.579 1.541-.5 2.908-1.27 4.141Z"
      fill="#ABE0F6"
    />
    <path
      d="M74.175 44.906c-.077.27-.212.54-.366.752-.481.654-1.29 1-2.06 1.232a13.02 13.02 0 0 1-4.392.597l-.289-.289-.096-.096c-.712-.79-1.367-1.599-2.1-2.35-.346-.346-.692-.712-1.078-1.001-.443-.328-.943-.578-1.444-.828-.905-.443-1.791-.886-2.696-1.33-.732-.365-1.214-.943-1.792-1.52-.154-.155-.308-.309-.404-.52a.744.744 0 0 1-.058-.636l.02-.02c.076.097.134.193.23.25.29.27.655.386 1.021.54 1.599.655 3.159 1.31 4.757 1.965.732.308 1.483.616 2.254.847 1.463.443 3.023.578 4.545.5.597.309 1.194.598 1.791.867.713.366 1.425.694 2.157 1.04Z"
      fill="#71ADEE"
    />
    <path
      d="M88.947 45.484c-1.021 1.618-2.042 3.216-3.063 4.834-.558.867-1.117 1.714-1.752 2.523-.366.462-.732 1.04-1.252 1.329-.597.347-1.348.52-2.022.327-.963-.27-1.791-.693-2.697-1.117-1.136-.539-2.388-.75-3.582-1.136-2.946-.905-5.489-2.793-7.53-5.065l-.096-.096c.443-.039.886.019 1.31.077 1.232.173 2.465.443 3.716.558 1.599.154 3.274.077 4.7-.655.308-.154.616-.346.905-.539.539-.366 1.097-.732 1.695-.924.154-.058.308-.097.462-.135 1.598-.308 3.1.712 4.699.943.308.039.636.058.925-.057.712-.29 1.136-1.349.539-1.907a1.703 1.703 0 0 0-.655-.308c-.828-.231-1.695-.366-2.523-.616-.655-.193-1.387-.424-1.887-.886.404-.79.886-1.522 1.483-2.215.808-.905 2.58-1.31 3.736-1.387 1.367-.077 2.812.385 3.698 1.464.212.27.404.558.5.886.54 1.502-.539 2.87-1.31 4.102Z"
      fill="#71ADEE"
    />
    <path
      d="M93.28 35.354c-.308 2.137-.809 4.217-1.445 6.278-.154.559-.346 1.117-.52 1.656-.038.135-.096.27-.192.347-.135.135-.347.154-.52.154a17.76 17.76 0 0 1-3.197-.115 20.54 20.54 0 0 1-6.568-1.869c-.096-.038-.192-.096-.23-.192-.058-.096-.04-.212-.02-.328.116-.616.25-1.232.424-1.848.404-1.445.963-2.87 1.56-4.257.366-.847.77-1.695 1.232-2.523a12.89 12.89 0 0 1 1.715-2.35c1.386-1.52 3.37-3.139 5.527-3.466.289-.039.558-.058.847-.058 1.233.02 1.695 1.753 1.753 2.735.154 1.984-.116 3.929-.366 5.836Z"
      fill="#ABE0F6"
    />
    <path
      d="M93.28 35.353c-.308 2.138-.809 4.218-1.444 6.279a15.955 15.955 0 0 1-3.293.096c1.713-3.197 2.811-6.702 3.042-10.323.097-1.483.02-3.043-.5-4.468 0-.02-.02-.038-.02-.038.29-.039.559-.058.848-.058 1.232.02 1.695 1.752 1.752 2.735.135 1.926-.134 3.87-.385 5.777Z"
      fill="#71ADEE"
    />
    <path
      d="M91.835 41.632c-.154.559-.346 1.117-.52 1.657-.038.134-.096.27-.192.346-.135.135-.347.154-.52.154a20.57 20.57 0 0 1-9.764-1.964c-.097-.039-.193-.096-.232-.193-.057-.096-.038-.212-.019-.327.116-.616.25-1.233.424-1.849 1.58.905 5.681 2.87 10.823 2.176Z"
      fill="#FFDC1E"
    />
    <path
      d="M91.836 41.632c-.154.559-.347 1.117-.52 1.657-.039.134-.096.27-.193.346-.135.135-.346.154-.52.154a17.77 17.77 0 0 1-3.197-.115c.405-.636.79-1.29 1.137-1.965 1.02.097 2.137.077 3.293-.077Z"
      fill="#E39F3A"
    />
    <path
      d="M84.69 49.586a.574.574 0 0 1-.346.154c-.212.02-.462 0-.655-.038-.192-.039-.347-.174-.52-.25-.404-.193-.867-.309-1.29-.482-.231-.096-.694-.347-.944-.25-.289.115.058.288.193.346.212.116.404.212.635.29.424.172.867.346 1.271.52.366.153 1.021.596.694 1.058a.67.67 0 0 1-.366.232c-.424.134-.886.019-1.31-.116a22.502 22.502 0 0 1-2.484-.905c-.308-.135-.636-.29-.963-.405-.116-.038-.81-.308-.81-.019 0 .058.058.096.136.116.134.096.288.173.443.23.288.136.597.27.905.386.616.231 1.232.462 1.772.866.25.174.5.463.385.732-.039.077-.097.135-.154.193-.039.038-.058.058-.116.077-.404.212-.886.077-1.31-.058-2.484-.77-5.007-1.54-7.222-2.908-.462-.289-.924-.616-1.425-.828-.944-.424-1.926-.443-2.947-.366-.866.058-1.733.193-2.619.27-1.56.154-3.14.192-4.718-.02a9.383 9.383 0 0 1-2.292-.616c-.636-.231-1.194-.52-1.58-1.136a1.51 1.51 0 0 1-.173-.405c-.096-.288-.154-.635-.212-.905a5.963 5.963 0 0 1-.135-1.54c.02-.655.135-1.31.367-1.946.192-.52.48-1.252 1.117-1.367.288-.039.577.077.847.192 1.464.617 2.966 1.117 4.487 1.618 1.271.405 2.562.828 3.871 1.02.732.117 5.894.52 6.664.54a68.24 68.24 0 0 1 4.873.328c1.059.115 2.118.23 3.139.52.886.25 1.753.635 2.465 1.232.231.212.482.559.308.848-.135.212-.443.23-.693.212-1.194-.116-2.273-.886-3.447-.829-.155.02-.386.154-.29.29a.402.402 0 0 0 .116.076c.751.29 1.541.482 2.273.81.732.307 1.444.77 1.945 1.386.173.289.308.635.116.847Z"
      fill="#ABE0F6"
    />
    <path
      d="M81.262 52.283a.245.245 0 0 1-.115.077c-.405.212-.886.077-1.31-.058-2.484-.77-5.007-1.54-7.222-2.908-.462-.29-.924-.617-1.425-.828-.944-.424-1.926-.443-2.947-.366-.867.057-1.733.192-2.62.27-1.56.153-3.139.192-4.718-.02a9.374 9.374 0 0 1-2.292-.616c-.635-.231-1.194-.52-1.579-1.137a1.51 1.51 0 0 1-.173-.404c.289.25.616.462 1.02.636 1.503.635 3.16.77 4.777.654 1.637-.096 3.255-.443 4.892-.539 2.407-.135 4.776.809 6.933 1.945 2.157 1.117 4.218 2.446 6.529 3.197.096.039.173.077.25.097Z"
      fill="#71ADEE"
    />
    <path
      d="m70.978 46.331.906-2.234c.038-.115.038-.192 0-.25l-.366-.462c.038.058.058.154 0 .27l-.906 2.233a.79.79 0 0 1-.423.405c-.02 0-.039.02-.077.02l-2.388.327c-.077.019-.135 0-.174-.058l.347.443a.197.197 0 0 0 .192.058l2.389-.328h.019l.038-.02a.765.765 0 0 0 .443-.404Z"
      fill="#71ADEE"
    />
    <path
      d="m70.555 46.736-.366-.463c-.02 0-.039.02-.077.02l-2.388.327c-.077.02-.135 0-.174-.058l.347.443a.198.198 0 0 0 .193.058l2.388-.327h.077Z"
      fill="#FFF7F2"
    />
    <path d="m69.707 46.485.674.27-.674.096v-.366Z" fill="#3989E5" />
    <path
      d="m70.978 46.331-.347-.462a.79.79 0 0 1-.424.405l.367.462a.826.826 0 0 0 .404-.405Z"
      fill="#ABE0F6"
    />
    <path
      d="m70.632 45.869.905-2.234c.038-.116.038-.212 0-.27-.039-.058-.097-.077-.193-.058l-2.388.328c-.193.02-.424.212-.5.424l-.906 2.234c-.058.115-.038.212.02.27.038.038.096.057.173.057l2.388-.327c.192-.039.404-.231.5-.424Z"
      fill="#ABE0F6"
    />
    <path
      d="M71.421 43.404c-.019 0-.038-.02-.077 0l-2.388.327c-.154.02-.346.193-.423.366l-.906 2.234c-.038.077-.038.174.02.193.019 0 .038.02.077 0l2.388-.327c.154-.02.347-.193.424-.366l.905-2.234c.038-.077.058-.174-.02-.193Z"
      fill="#E2F6FF"
    />
    <path
      d="M71.113 45.83h-.135v-.057h.058l-.058-.077c-.019-.02-.019-.058 0-.096l.193-.482-.058-.038h.135s.02 0 .02.019l.076.096c.02.02.02.058 0 .096l-.212.501c0 .02 0 .02-.019.039Z"
      fill="#999"
    />
    <path
      d="M71.151 45.099h-.019c-.02 0-.038.019-.038.038l-.212.5c-.02.04-.02.097.02.116l.076.097.02.019h.019c.019 0 .038-.02.057-.038l.212-.501c.02-.039.02-.097-.019-.116l-.077-.096c-.02-.02-.02-.02-.039-.02Z"
      fill="#3989E5"
    />
    <path
      d="m70.901 45.638.212-.5c0-.02.02-.02.02-.02h.057c-.019 0-.019.02-.019.02l-.212.5c0 .039 0 .058.02.096l.076.097.02.019h-.058s-.02 0-.02-.02l-.076-.096c-.02-.019-.02-.057-.02-.096Z"
      fill="#ABE0F6"
    />
    <path
      d="M70.92 45.734h.058l.077.097.02.019h-.058s-.02 0-.02-.02l-.077-.096ZM70.901 45.638h.058c0 .039 0 .058.02.096h-.058c-.02-.019-.02-.057-.02-.096Z"
      fill="#ABE0F6"
    />
    <path
      d="m71.094 45.792.212-.5c.02-.04 0-.078 0-.097l-.077-.096-.02-.02c-.019 0-.019.02-.038.02l-.212.5c-.02.04 0 .078.02.097l.076.096.02.02c0 .019.019 0 .019-.02Z"
      fill="#3989E5"
    />
    <path
      d="m71.056 45.83.135-.731c-.02 0-.02.019-.039.019l-.212.5c-.02.04 0 .078.02.097l.096.115Z"
      fill="#3989E5"
    />
    <path
      d="M71.441 42.942H71.383l-1.29.173a1.168 1.168 0 0 0-.655.385l-.058.058 1.33-.173.057-.058c.02-.02.039-.038.039-.058 0-.019.019-.019.019-.019l.02-.02a.34.34 0 0 1 .095-.076c.039-.02.058-.039.097-.058.019-.02.038-.02.057-.038.02-.02.039-.02.078-.04.019 0 .038-.018.057-.018h.02c.019 0 .038-.02.057-.02h.059c.057-.038.057-.038.076-.038Z"
      fill="#E2F6FF"
    />
    <path
      d="m70.786 43.327-1.329.174-.058.057 1.33-.173.057-.058Z"
      fill="#ABE0F6"
    />
    <path
      d="M71.23 48.912H71.036c-.02 0-.058 0-.078-.02h-.077c-.019 0-.019 0-.038-.018-.039-.02-.096-.039-.135-.058-.02 0-.038-.02-.058-.02-.019-.019-.038-.019-.057-.038-.02-.02-.039-.039-.058-.039-.02-.019-.058-.038-.077-.057l-.058-.058-.058-.058a.265.265 0 0 1-.058-.077 6.054 6.054 0 0 1-.23-.366c0-.02-.02-.02-.02-.038-.02-.039-.038-.058-.038-.097-.039-.077-.058-.154-.097-.23-.02-.04-.02-.078-.038-.116-.02-.039-.02-.077-.039-.116-.02-.038-.02-.077-.038-.135a1.14 1.14 0 0 1-.039-.27c0-.038-.02-.096-.02-.134v-.443l-1.328.193c0 .115 0 .25.02.365.057.617.25 1.194.558 1.58.27.346.616.52.943.481l1.31-.173v-.058Z"
      fill="#E2F6FF"
    />
    <path
      d="M72.385 43.404c-.154-.193-.328-.347-.52-.404-.135-.058-.29-.078-.443-.058h-.039s-.019 0-.019.02h-.02c-.018 0-.038.018-.057.018l-.02.02c-.018.019-.038.019-.057.019-.038.02-.058.02-.077.038-.02.02-.038.02-.058.039-.038.02-.058.038-.096.058-.039.038-.077.057-.116.096 0 .02-.019.02-.019.02-.02 0-.02.018-.038.018-.02.02-.039.039-.039.058 0 0-.02 0-.02.02l-.057.057.096-.019.058-.02.116-.115c.057-.038.096-.058.135-.096l.115-.058c.02-.02.039-.02.039-.02a.362.362 0 0 1 .154-.038c.115-.019.25 0 .365.039.174.077.328.212.463.385.019.02.019.02.019.039.038.038.058.077.096.115.039.058.058.116.097.154 0 .02.019.02.019.039.038 0 .077.019.115.019 0-.173-.096-.308-.192-.443Zm-.963 5.335c-.039.02-.058.038-.096.038-.039.02-.077.02-.116.02-.115.019-.25 0-.366-.058-.077-.039-.154-.077-.231-.135-.02-.02-.038-.02-.038-.038-.02-.02-.04-.02-.04-.039-.018 0-.018-.02-.018-.02a1.099 1.099 0 0 1-.135-.153c-.02-.039-.039-.058-.058-.097-.27-.385-.424-.886-.462-1.444-.02-.058-.02-.135-.02-.212l-.115-.135v.039c0 .038 0 .096.02.135v.115c0 .02.019.058.019.096v.078c.019.038.019.096.019.134.02.097.038.174.038.27.02.038.02.096.039.135.02.038.02.077.038.115.02.039.02.097.039.116.02.096.058.154.096.23.02.04.02.059.039.097 0 .02.019.02.019.039.038.058.058.135.096.192.039.058.077.135.116.193.02.02.038.058.058.077l.115.115c.02.02.039.04.077.058l.02.02c.019.019.038.019.038.038.02.02.038.039.058.039.019.019.038.019.057.038.039.02.097.039.135.058.02 0 .02.02.039.02.02.018.02.018.038.018.02 0 .02.02.039.02.038 0 .058.019.077.019H71.268a.743.743 0 0 0 .385-.154l-.116-.058c-.077-.038-.096-.038-.115-.02Z"
      fill="#ABE0F6"
    />
    <path d="m70.825 43.385-.038-.058-.058.058h.096Z" fill="#3989E5" />
    <path
      d="M71.21 48.566c.097.038.193.096.27.134-.02.02-.039.02-.058.039a.389.389 0 0 1-.212.058c-.115.019-.25 0-.366-.058a1.429 1.429 0 0 1-.23-.135c-.02-.02-.04-.02-.04-.038-.019-.02-.038-.02-.038-.039.039-.058.096-.116.116-.193.173.077.366.135.558.232ZM72.52 43.809a7.498 7.498 0 0 0-.694-.039l-.308-.404c-.038-.058-.115-.077-.192-.058l-.443.058.115-.116c.039-.038.097-.058.135-.096l.116-.058a.372.372 0 0 1 .192-.058c.116-.019.25 0 .366.039.174.058.328.192.462.385.02.02.02.02.02.038.038.04.058.078.096.116.039.058.058.116.096.154l.039.039Z"
      fill="#71ADEE"
    />
    <path
      d="M98.904 128.992a10.954 10.954 0 0 0 5.701 1.367c.597-.019 1.29-.115 1.887.02.52.115.636.924.732 1.367.212.905.578 1.81 1.232 2.485 1.272 1.309 3.217 1.906 4.989 2.08 1.733.173 3.485-.058 5.2-.386a40.348 40.348 0 0 0 4.988-1.29c.423-.135.828-.27 1.232-.424 3.563-1.29 6.991-2.927 10.362-4.66 2.561-1.329 5.123-2.716 7.357-4.546 2.792-2.292 4.757-6.182 2.619-9.533-1.155-1.83-3.081-3.043-5.046-3.91-2.735-1.194-5.681-1.829-8.647-2.157-5.431-.597-11.248-.038-15.735 3.082-1.117.789-2.292 1.444-3.39 2.234-1.733 1.213-3.428 2.484-5.2 3.659-2.985 2.003-6.163 3.756-8.782 6.182-1.56 1.483-1.464 3.332.5 4.43Z"
      fill="#2F455F"
    />
    <path
      d="M117.585 134.154c2.908-1.464 5.181-3.891 7.28-6.375 1.406-1.656 2.812-3.409 4.796-4.314.597-.27 1.232-.462 1.791-.79.558-.327 1.059-.866 1.155-1.502.116-.847-.558-1.676-1.367-1.964-.809-.289-1.714-.135-2.523.154-3.101 1.117-5.046 4.102-7.454 6.355a18.585 18.585 0 0 1-5.758 3.64c-1.676.655-3.428 1.079-5.181 1.522-.809.192-1.733.481-2.08 1.252-.25.558-.173 1.579.135 2.099.308.52 1.348.751 1.887.886 1.56.404 3.236.346 4.796-.039.886-.212 1.714-.52 2.523-.924ZM98.769 128.838c.289.116.597.212.924.27a8.715 8.715 0 0 0 2.003.173c1.04-.038 2.08-.25 3.101-.539a26.6 26.6 0 0 0 5.046-2.042c.809-.423 1.598-.905 2.215-1.579-.193.212-.713.328-.963.405a7.704 7.704 0 0 1-1.098.269c-.327.058-.655.097-.982.135-.328.039-.655-.096-.963-.173-1.445-.385-2.87-.886-4.16-1.657-.559-.327-1.098-.712-1.695-.963-.944-.385-2.061-.404-3.005.02-.75.346-1.174.905-1.617 1.579-.193.308-.386.501-.463.867-.096.423-.096.886.02 1.329a2.828 2.828 0 0 0 1.155 1.617c.116.116.29.212.482.289Z"
      fill="#ABE0F6"
    />
    <path
      d="M118.047 118.361c-.751 1.714-2.272 2.947-3.775 4.064-1.983 1.483-4.044 2.908-6.259 4.025-1.695.847-3.524 1.483-5.412 1.791-.577.096-1.155.154-1.714.193-.809.038-1.656 0-2.427-.289-.79-.289-1.155-.694-1.405-1.464-.366-1.213.057-2.542.597-3.621a15.785 15.785 0 0 1 1.945-2.927c1.175-1.444 2.542-2.735 3.89-4.044 2.157-2.061 4.43-4.083 6.818-5.875.193-.154.404-.288.597-.443a.805.805 0 0 1 .212-.134c1.059-.694 2.484-.944 3.794-.713.019 0 .038 0 .058.019.308.058.616.135.924.27.135.038.27.116.385.173.058.02.116.058.154.097.077.038.135.077.212.134.058.039.135.077.193.135.115.077.212.154.308.251.116.096.212.211.308.327.828.963 1.079 2.369 1.194 3.601.135 1.406-.019 3.101-.597 4.43Z"
      fill="#FFDC1E"
    />
    <path
      d="M118.047 118.361c-.751 1.714-2.272 2.947-3.774 4.064-1.984 1.483-4.045 2.908-6.26 4.025-1.694.847-3.524 1.483-5.411 1.791.789-.462 1.56-.963 2.33-1.483 1.618-1.098 3.139-2.369 4.372-3.89a36.019 36.019 0 0 0 2.272-3.14c1.06-1.637 2.466-3.717 2.042-5.797-.193-.905-.848-1.637-1.387-2.407-.539-.751-1.001-1.714-.712-2.6 0-.019.019-.058.038-.058s.039 0 .058.019c.231.097.481.251.712.232.251-.02.52-.154.79-.193.616-.096 1.175.019 1.772.019.019 0 .038 0 .058.02.886.154 1.887.385 2.523 1.059.25.269.423.558.597.886a6.77 6.77 0 0 1 .732 3.736c-.097 1.233-.251 2.561-.752 3.717Z"
      fill="#E39F3A"
    />
    <path
      d="M131.953 112.314c-.078 1.983-.232 3.986-.713 5.932-.443 1.829-1.194 3.582-2.311 5.084-.328.559-.79 1.04-1.329 1.521-1.81 1.618-3.775 3.063-5.72 4.507-1.541 1.136-3.101 2.234-4.719 3.255-1.868 1.156-3.486 2.292-5.758 2.446a5.74 5.74 0 0 1-2.619-.443c-.039 0-.097-.039-.135-.058-.732-.347-1.368-1.155-1.791-1.829-1.06-1.618-.482-3.679.25-5.316.212-.462.597-.867.905-1.252.347-.462.713-.924 1.06-1.387.712-.924 1.405-1.848 2.118-2.773 1.695-2.215 3.39-4.43 5.084-6.664.848-1.097 1.676-2.195 2.504-3.274.347-.462.732-.943 1.252-1.174.424-.174.751-.135 1.04.019.347.192.636.558.963.847l.096.096a17.707 17.707 0 0 0 4.565 2.658c.809.328 1.656.617 2.523.79.693.154 1.386-.559 1.791-1.098.404-.597.693-1.232.944-1.887Z"
      fill="#FFDC1E"
    />
    <path
      d="M131.953 112.313c-.077 1.984-.232 3.987-.713 5.932-.443 1.83-1.194 3.583-2.311 5.085-.328.558-.79 1.04-1.329 1.521-1.81 1.618-3.775 3.062-5.72 4.507-1.541 1.136-3.101 2.234-4.719 3.255-1.868 1.155-3.485 2.292-5.758 2.446a5.767 5.767 0 0 1-2.619-.443 38.98 38.98 0 0 0 1.906-.771c2.1-.905 4.276-1.926 5.682-3.736.501-.636.905-1.406.847-2.215-.038-.693-.385-1.406-.173-2.099.231-.809.539-.828 1.31-.732.693.077 1.502-.327 1.752-.963.27-.751.52-1.502.751-2.272.251-.771.751-1.849.732-2.658 0-.694-.597-.886-1.117-1.098a10.72 10.72 0 0 1-1.078-.462 12.542 12.542 0 0 1-2.177-1.445c-.308-.25-.616-.52-.924-.809.847-1.097 1.675-2.195 2.504-3.274.346-.462.731-.943 1.251-1.174.424-.174.751-.135 1.04.019.309.289.636.578.963.847l.097.097a17.726 17.726 0 0 0 4.564 2.657c.809.328 1.656.617 2.523.79.693.154 1.387-.559 1.791-1.098.385-.616.674-1.252.925-1.907Z"
      fill="#E39F3A"
    />
    <path
      d="m75.793 93.632-2.196 1.29.039.078c-.02.038-.02.096-.02.154 0 .327.212.712.482.886.058.038.096.057.154.057l.038.077 2.235-1.31c.077-.057.134-.153.134-.307a1.15 1.15 0 0 0-.481-.886c-.154-.097-.289-.097-.385-.039Z"
      fill="#AAD2F6"
    />
    <path
      d="M73.963 94.961c.27.173.482.558.482.886 0 .327-.231.443-.501.289a1.15 1.15 0 0 1-.482-.886c0-.328.212-.443.501-.289Z"
      fill="#D9D9D9"
    />
    <path
      d="m73.578 94.942-.135.077.038.077c-.019.039-.019.096-.019.154 0 .328.212.713.482.886.057.039.096.058.154.058l.038.077.174-.096c.077-.058.134-.154.134-.308a1.15 1.15 0 0 0-.481-.886c-.154-.097-.289-.116-.385-.039Z"
      fill="#71ADEE"
    />
    <path
      d="M73.79 95.058c.269.173.48.558.48.886 0 .327-.23.443-.5.289a1.15 1.15 0 0 1-.481-.886c0-.328.23-.462.5-.29Z"
      fill="#004EB6"
    />
    <path
      d="M74.001 96.213c.135 0 .212-.115.212-.289 0-.289-.192-.655-.443-.809a.372.372 0 0 0-.192-.057c-.135 0-.212.115-.212.288 0 .29.192.655.443.81a.43.43 0 0 0 .192.057Z"
      fill="#71ADEE"
    />
    <path
      d="m74.252 92.67-2.195 1.29.038.077c-.02.038-.02.096-.02.154 0 .327.213.712.482.885.058.04.097.058.154.058l.039.077 2.234-1.31c.077-.057.135-.153.135-.307a1.15 1.15 0 0 0-.482-.886c-.154-.097-.288-.116-.385-.039Z"
      fill="#AAD2F6"
    />
    <path
      d="M72.403 93.998c.27.173.482.558.482.886 0 .327-.232.443-.501.289a1.15 1.15 0 0 1-.482-.886c0-.328.231-.463.501-.29Z"
      fill="#D9D9D9"
    />
    <path
      d="m72.038 93.96-.135.076.038.077c-.019.039-.019.097-.019.154 0 .328.212.713.482.886.057.039.096.058.154.058l.038.077.173-.096c.078-.058.135-.154.135-.308a1.15 1.15 0 0 0-.481-.886c-.154-.096-.29-.096-.385-.039Z"
      fill="#71ADEE"
    />
    <path
      d="M72.25 94.075c.269.173.48.558.48.886 0 .327-.23.443-.5.289a1.15 1.15 0 0 1-.481-.886c0-.328.23-.443.5-.29Z"
      fill="#004EB6"
    />
    <path
      d="M72.46 95.25c.136 0 .213-.116.213-.29 0-.288-.193-.654-.443-.808a.372.372 0 0 0-.193-.058c-.135 0-.212.116-.212.29 0 .288.193.654.443.808.058.02.135.058.193.058Z"
      fill="#71ADEE"
    />
    <path
      d="m81.648 97.002-2.196 1.29.039.078c-.02.038-.02.096-.02.154 0 .327.212.712.482.886.058.038.096.058.154.058l.038.076 2.235-1.31c.077-.057.134-.153.134-.307a1.15 1.15 0 0 0-.481-.886c-.173-.096-.308-.096-.385-.039Z"
      fill="#AAD2F6"
    />
    <path
      d="M79.799 98.331c.27.174.481.559.481.886 0 .328-.231.443-.5.29a1.15 1.15 0 0 1-.482-.887c0-.327.231-.462.5-.289Z"
      fill="#D9D9D9"
    />
    <path
      d="m79.413 98.312-.135.077.039.077c-.02.039-.02.096-.02.154 0 .328.212.713.482.886.058.039.096.058.154.058l.039.077.173-.096c.077-.058.135-.154.135-.309a1.15 1.15 0 0 0-.482-.886c-.154-.115-.289-.115-.385-.038Z"
      fill="#71ADEE"
    />
    <path
      d="M79.625 98.427c.27.174.481.559.481.886 0 .328-.23.443-.5.29a1.15 1.15 0 0 1-.482-.887c.02-.327.231-.462.5-.288Z"
      fill="#004EB6"
    />
    <path
      d="M79.837 99.583c.135 0 .212-.115.212-.289 0-.288-.193-.654-.443-.808a.372.372 0 0 0-.193-.058c-.135 0-.212.115-.212.289 0 .288.193.654.443.808a.431.431 0 0 0 .193.058Z"
      fill="#71ADEE"
    />
    <path
      d="m83.323 97.908-2.196 1.29.039.077c-.02.039-.02.097-.02.154 0 .328.213.713.482.886.058.039.097.058.154.058l.039.077 2.234-1.31c.077-.057.135-.154.135-.308a1.15 1.15 0 0 0-.482-.886c-.154-.096-.289-.115-.385-.038Z"
      fill="#AAD2F6"
    />
    <path
      d="M81.474 99.237c.27.173.481.558.481.885 0 .328-.23.443-.5.289a1.149 1.149 0 0 1-.482-.886c0-.327.231-.462.5-.288Z"
      fill="#D9D9D9"
    />
    <path
      d="m81.109 99.198-.135.077.038.077c-.02.039-.02.096-.02.154 0 .328.213.713.482.886.058.039.097.058.154.058l.039.077.173-.096c.077-.058.135-.154.135-.308a1.15 1.15 0 0 0-.481-.886c-.174-.097-.29-.097-.385-.039Z"
      fill="#71ADEE"
    />
    <path
      d="M81.32 99.314c.27.173.482.558.482.885 0 .328-.232.443-.501.289a1.149 1.149 0 0 1-.482-.886c0-.327.231-.443.501-.288Z"
      fill="#004EB6"
    />
    <path
      d="M81.532 100.489c.135 0 .212-.116.212-.289 0-.29-.193-.655-.443-.81a.372.372 0 0 0-.193-.057c-.135 0-.212.116-.212.289 0 .289.193.655.443.809.058.019.135.058.193.058Z"
      fill="#71ADEE"
    />
    <path
      d="m81.898 93.362 1.58-.866-3.333-1.368-.597.366 2.35 1.868Z"
      fill="#E2F6FF"
    />
    <path
      d="M79.549 91.494v.174l2.35 1.868v-.174l-2.35-1.868Z"
      fill="#71ADEE"
    />
    <path
      d="m82.437 90.377 1.33-.77v5.489l-1.33.77-.635-1.098v-1.04l.635-3.35Z"
      fill="#71ADEE"
    />
    <path
      d="m82.514 90.435 1.33-.77v5.47l-1.33.77-.616-1.098v-1.04l.616-3.332Z"
      fill="#004EB6"
    />
    <path
      d="m83.843 89.665-.078-.058-1.329.77.078.058 1.329-.77Z"
      fill="#E2F6FF"
    />
    <path
      d="m82.437 95.866.077.039-.616-1.098-.097-.038.636 1.097Z"
      fill="#71ADEE"
    />
    <path
      d="M71.344 101.817c.019.019.058.019.077.039h.038c.02 0 .039 0 .058.019h-.02c-.057-.019-.096-.039-.153-.058Zm.192.058c.039 0 .077.019.116.019h.231c.154-.019.308-.058.462-.096.54-.135 1.079-.347 1.599-.539.635-.251 1.27-.578 1.81-1.021.443-.347.867-.694 1.29-1.06.405-.327 4.083-3.428 4.43-3.832.173-.193.328-.404.462-.616a.98.98 0 0 0 .135-.328c.02-.077.039-.135.058-.212 0-.038.02-.077.02-.115v-.058c0 .039-.02.096-.04.135-.057.135-.943.751-1.29.963-.443.25-.847.578-1.27.867l-3.468 2.426c-.308.212-.654.366-.982.54a11.193 11.193 0 0 0-2.985 2.311c-.135.154-.25.327-.385.481l-.039.039-.038.038c-.02.019-.039.019-.039.039 0 0-.038.019-.077.019Z"
      fill="#71ADEE"
    />
    <path
      d="m71.67 101.798.04-.038.038-.039c.038-.038.058-.077.096-.115h.039c.154-.02.308-.058.462-.097.54-.134 1.079-.346 1.599-.539.635-.25 1.27-.578 1.81-1.02.443-.347.867-.694 1.29-1.06.405-.327 4.083-3.428 4.43-3.832.173-.193.327-.405.462-.617.039-.077.077-.154.116-.25.038-.039.057-.058.057-.077a.278.278 0 0 0 .039-.135v.058c0 .038 0 .077-.02.115-.019.077-.038.154-.057.212a2.35 2.35 0 0 1-.135.328 3.68 3.68 0 0 1-.462.616c-.328.385-4.025 3.505-4.43 3.832-.423.347-.866.713-1.29 1.06-.54.423-1.175.77-1.81 1.02-.52.193-1.06.405-1.599.54-.154.038-.308.077-.462.096H71.652c-.039 0-.077 0-.116-.019.039 0 .058-.019.096-.039.02.02.039.02.039 0Z"
      fill="#004EB6"
    />
    <path
      d="M71.517 101.875c-.02 0-.038 0-.058-.019h-.038a.141.141 0 0 1-.077-.039c.058.02.096.039.173.058-.02 0-.02 0 0 0Z"
      fill="#588C73"
    />
    <path
      d="M71.132 101.413c0 .115.02.231.077.308.038.038.077.077.135.096a.363.363 0 0 0 .135.039h.038c.038 0 .058-.02.096-.039.02-.019.039-.019.039-.038l.038-.039.039-.038c.135-.155.25-.328.385-.482.193-.212.385-.404.616-.578.039-.038.077-.058.116-.096.27-.212.54-.404.828-.578.154-.096.481-.27.5-.289.27-.154 7.377-5.007 7.685-5.277.097-.096.212-.173.27-.289a.278.278 0 0 0 .038-.134c0-.078-.019-.174-.115-.231-.135-.097-.328-.058-.5-.02-.829.231-4.257 2.157-6.53 3.486-.578.328-1.078.636-1.444.847a8.75 8.75 0 0 0-.617.386h-.019c-.404.289-.712.654-1.001 1.04-.25.346-.463.712-.617 1.078 0 .019-.019.039-.019.058-.02.039-.02.058-.038.096-.02.02-.02.058-.02.077-.019.039-.019.058-.038.097v.019a1.865 1.865 0 0 0-.077.501Z"
      fill="#E2F6FF"
    />
    <path
      d="m71.035 101.605.077.135.039.077.038.077H71.478c.077 0 .116-.058.135-.135v-.038a.425.425 0 0 0-.077-.251.559.559 0 0 0-.173-.192c-.116-.077-.212-.039-.25.077l-.078.25Z"
      fill="#2F455F"
    />
    <path
      d="m71.15 101.818.04.077H71.477c.077 0 .116-.058.135-.135l-.462.058ZM71.035 101.605l.077.135.424-.289c-.02-.038-.039-.077-.077-.096l-.039-.038-.038-.039-.02-.019c-.115-.077-.211-.039-.25.077 0 .019-.077.269-.077.269Z"
      fill="#2F455F"
    />
    <path
      d="M71.266 101.817c0 .097-.096.097-.154.077-.019 0-.038-.019-.038-.019-.02 0-.02-.019-.02-.019-.019-.039-.038-.077-.038-.116 0-.038.02-.077.039-.115.019-.039.077-.019.096 0 .058.019.115.115.115.192Z"
      fill="#004EB6"
    />
    <path
      d="m83.67 92.63-1.6.848 3.39 1.618.598-.366-2.389-2.1Z"
      fill="#E2F6FF"
    />
    <path d="M85.46 95.096v.135l.598-.347v-.154l-.598.366Z" fill="#004EB6" />
    <path d="M82.07 93.478v.135l3.39 1.618v-.135l-3.39-1.618Z" fill="#71ADEE" />
    <path
      d="M86.867 100.816v.347c.308.173.963.077 1.444-.212l.925-.52v-.347l-.925.52c-.5.289-1.136.385-1.444.212Z"
      fill="#004EB6"
    />
    <path
      d="m78.393 96.29 8.454 4.526c.309.173.963.077 1.445-.212l.924-.52-8.281-5.258-2.542 1.464Z"
      fill="#E2F6FF"
    />
    <path
      d="M78.393 96.29v.327l8.474 4.546v-.347l-8.474-4.526Z"
      fill="#71ADEE"
    />
    <path
      d="m70.265 90.127-.924.52 7.78 4.873 2.523-1.464-7.954-4.16c-.308-.135-.943-.058-1.425.231Z"
      fill="#E2F6FF"
    />
    <path d="M69.36 90.647v.347l7.78 4.872v-.327l-7.78-4.892Z" fill="#71ADEE" />
    <path
      d="M77.14 95.539v.327l2.524-1.463v-.328L77.14 95.54Z"
      fill="#D96459"
    />
    <path
      d="m78.412 96.29-.905.943-1.156-.732.79-.962 1.27.75Z"
      fill="#71ADEE"
    />
    <path
      d="m78.412 96.29 2.523-1.464-1.271-.75-2.523 1.463 1.27.751Z"
      fill="#E2F6FF"
    />
    <path d="m77.507 97.234.886-.617.019-.327-.905.944Z" fill="#004EB6" />
    <path
      d="m81.782 99.525 1.194-.79-.558-.288-1.021.712.385.366Z"
      fill="#71ADEE"
    />
    <path
      d="m81.782 99.525 1.56-.25-.366-.212v-.327l-1.194.79Z"
      fill="#004EB6"
    />
    <path d="m82.976 99.063-1.194.462 1.56-.25-.366-.212Z" fill="#E2F6FF" />
    <path
      d="m80.106 98.62 1.175-.79-.54-.288-1.02.732.385.347Z"
      fill="#71ADEE"
    />
    <path
      d="m80.107 98.62 1.54-.25-.365-.193v-.346l-1.175.79Z"
      fill="#004EB6"
    />
    <path d="m81.282 98.177-1.175.443 1.54-.25-.365-.193Z" fill="#E2F6FF" />
    <path
      d="m74.271 95.27 1.522-.251-.347-.212v-.347l-1.175.81Z"
      fill="#004EB6"
    />
    <path d="m75.446 94.807-1.175.462 1.522-.25-.347-.212Z" fill="#E2F6FF" />
    <path
      d="m72.711 94.287 1.522-.25-.328-.213v-.327l-1.194.79Z"
      fill="#004EB6"
    />
    <path d="m73.906 93.825-1.195.462 1.522-.25-.328-.212Z" fill="#E2F6FF" />
    <path
      d="m74.271 95.27 1.175-.81-.52-.308-1.04.751.385.366ZM72.711 94.287l1.194-.79-.52-.308-1.059.732.385.366Z"
      fill="#71ADEE"
    />
    <path
      d="M71.652 100.18c0 .019.02.039.02.058l.018.019c.02.039.058.058.097.096.115.097.25.174.385.251.02.019.038.019.077.038.096.058.23.097.327.058a.373.373 0 0 0 .193-.192c.02-.02.02-.039.02-.058.038-.097.018-.174-.04-.251a.51.51 0 0 0-.096-.115.576.576 0 0 0-.135-.096c-.077-.058-.154-.097-.25-.154-.02-.02-.038-.02-.058-.039-.096-.058-.211-.096-.327-.058a.273.273 0 0 0-.173.154s0 .02-.02.02c-.019.038-.058.096-.077.154.039.038.039.077.039.115Z"
      fill="#2F455F"
    />
    <path
      d="M72.538 100.045c-.096-.077-.193-.115-.308-.173-.097-.058-.212-.096-.328-.058a.274.274 0 0 0-.173.154c-.02.039-.058.116-.077.174v.038-.096c.02-.058.058-.135.077-.174a.273.273 0 0 1 .173-.154c.116-.038.231.02.328.058.096.058.212.116.308.174.115.096.308.231.289.404 0-.154-.174-.27-.29-.347ZM77.237 96.251c-.058.058-.058.135 0 .174.058.038.135.02.193-.058.058-.058.058-.135 0-.173-.058-.039-.135 0-.193.057ZM74.83 98.89l-.655.366c-.038.02-.058.058-.038.115.096.212.365.886.288 1.56 0 .039.039.058.058.039l.674-.366c.02-.019.039-.039.039-.077.096-.713-.193-1.387-.29-1.599 0-.038-.038-.057-.076-.038Z"
      fill="#004EB6"
    />
    <path
      d="M103.045 88.22c-.154.54-.616 1.348-1.098 1.656-.25.154-.558.193-.905.174-.328 0-.655-.077-.925-.135-.115-.02-.23-.039-.346-.077-.443-.116-.905-.231-1.33-.366-1.078-.328-2.118-.732-3.138-1.156-.501-.192-1.002-.443-1.503-.616-.539-.173-.963 0-1.483.058-.77.096-1.56.038-2.33.02-.385-.02-.79-.02-1.175-.02-.308 0-.79-.077-1.059.077-.5.289-.809 1.06-.982 1.58-.097.307-.077.654-.193.962-.096.25-.25.462-.385.674-.308.443-.52.925-.847 1.348-.482.597-1.137 1.021-1.81 1.368a1.902 1.902 0 0 1-.463.173h-.077c-.173 0-.385-.096-.443-.289-.039-.115 0-.23.038-.366.193-.597.617-1.078 1.06-1.54a.818.818 0 0 0 .192-.328c.039-.135.039-.289.039-.443.02-.327.02-.655.02-.963 0-.096 0-.25-.078-.327-.135-.116-.347.038-.462.115-.173.097-.328.193-.5.29-.155.076-.328.192-.405.346-.077.115-.077.27-.097.404-.038.25-.096.501-.134.751a6.44 6.44 0 0 1-.27 1.349 1.293 1.293 0 0 1-.193.443.546.546 0 0 1-.192.173c-.077.02-.154.077-.212.058-.154-.039-.231-.174-.289-.328-.077-.25-.058-.539 0-.809.096-.616.27-1.213.308-1.83 0-.134 0-.25-.02-.365-.018-.039-.038-.135-.095-.154-.039-.039-.135-.039-.174-.058-.308-.135-.366-.54-.346-.886.038-.481.154-.944.27-1.425.134-.5.25-1.02.558-1.445.115-.154.25-.288.423-.365.27-.116.578-.097.867-.078.462.02 2.658-.404 3.197-.712.616-.347 3.698-1.83 4.66-2.1a10.136 10.136 0 0 1 2.139-.365c.75-.058 1.386.057 2.118.25.713.193 1.445.404 2.157.616.886.25 1.753.52 2.62.77.635.193 1.271.367 1.906.56.27.076.539.173.751.327.02.019.058.038.077.077.231.173.405.443.52.731.212.713.251 1.503.039 2.196Z"
      fill="#ABE0F6"
    />
    <path
      d="M102.197 88.143a7.369 7.369 0 0 1-1.136 1.907c-.328 0-.655-.077-.925-.135-.115-.02-.23-.038-.346-.077-.443-.115-.906-.23-1.33-.366-1.078-.327-2.118-.732-3.139-1.155-.5-.193-1.001-.443-1.502-.617-.54-.173-.963 0-1.483.058-.77.097-1.56.039-2.33.02-.385-.02-.79-.02-1.175-.02-.308 0-.79-.077-1.06.077-.5.29-.808 1.06-.981 1.58-.097.308-.078.654-.193.962-.096.25-.25.463-.385.675-.308.442-.52.924-.848 1.348-.481.597-1.136 1.02-1.81 1.367a1.902 1.902 0 0 1-.462.173c.327-.25.635-.52.886-.828 1.136-1.367 1.348-3.255 2.195-4.815.25-.462.578-.885 1.021-1.174.404-.25.886-.366 1.367-.443a16.974 16.974 0 0 1 2.793-.309c.443 0 .905 0 1.367.02.328.019.674.23.963 0 .27-.212.29-.636.309-.944 0-.385-.058-.79-.154-1.175-.02-.096-.058-.212-.058-.308.019-.115.077-.212.173-.25.116-.039.231.019.308.115.077.097.097.212.116.328.135.75 0 1.579.289 2.291.115.232.288.347.539.424.635.193 1.271.463 1.926.54.674.057 1.406-.097 1.81-.617.462-.578.385-1.695.751-2.35.636.193 1.271.367 1.907.56.27.076.539.172.751.327.019.019.058.038.077.077.327.847.135 1.848-.231 2.734Z"
      fill="#71ADEE"
    />
    <path
      d="M109.631 94.364c-.289 1.868-.481 3.775-.057 5.604a30.35 30.35 0 0 0-.906-1.772 3.087 3.087 0 0 0-.25-.423c-1.541-2.427-4.006-4.083-6.471-5.528-.019-.019-.039-.019-.077-.038-.655-.366-1.329-.79-1.714-1.445-.27-.442-.366-.982-.424-1.483a7.45 7.45 0 0 1-.038-.654 7.744 7.744 0 0 1 .173-1.888c.115-.5.289-1.001.578-1.425.134-.192.346-.5.558-.616.251-.135.636-.02.905 0 .135.019.289.038.424.058 1.31.173 2.619.481 3.91.79.866.211 1.733.404 2.58.615.655.155 1.079.27 1.214 1.021.404 2.427-.039 4.796-.405 7.184Z"
      fill="#004EB6"
    />
    <path
      d="M108.668 98.197a3.087 3.087 0 0 0-.25-.424c-1.541-2.427-4.006-4.083-6.471-5.527-.019-.02-.039-.02-.077-.04-.135-.288-.308-.577-.385-.808a8.16 8.16 0 0 1-.424-1.637c-.019-.116-.019-.212-.039-.327.232.115.482.23.713.346 1.136.501 2.33.886 3.409 1.502a8.013 8.013 0 0 1 1.907 1.503c.346.366.674.79.905 1.232.25.501.308.944.366 1.483.077.578.289 1.117.346 1.714 0 .328.02.655 0 .983Z"
      fill="#2F455F"
    />
    <path
      d="M101.485 91.398c.077.212.25.52.385.809-.655-.366-1.329-.79-1.714-1.444-.27-.444-.366-.983-.424-1.484a7.516 7.516 0 0 1 .135-2.542c.115-.5.289-1.001.577-1.425.135-.192.347-.5.559-.616.25-.135.636-.02.905 0 .135.019.289.038.424.058-.366.52-.693 1.078-.963 1.637-.501 1.059-.52 2.195-.327 3.331.096.578.231 1.137.443 1.676Z"
      fill="#004EB6"
    />
    <path
      d="M101.87 92.207c-.655-.366-1.329-.79-1.714-1.445-.27-.443-.366-.982-.424-1.483-.02-.154-.039-.289-.039-.443v-.212a7.31 7.31 0 0 0 1.329.829c0 .115.02.212.039.327.096.559.231 1.117.423 1.637.078.193.251.482.386.79Z"
      fill="#2F455F"
    />
    <path
      d="M131.355 109.945c-.038.597-.096 1.252-.154 1.964-.134 1.657-.712 3.024-2.369 3.602-1.29.443-2.619.674-3.986.712-2.061.077-4.141-.269-6.105-.905-1.965-.635-3.872-1.579-5.412-2.985-.674-.597-1.541-1.425-1.772-2.311-.289-1.117-.501-2.292-.809-3.409a170.592 170.592 0 0 0-1.599-5.354c-1.155-3.544-2.465-7.107-2.6-10.805-.038-1.078.02-2.195.463-3.177.443-.983 1.329-1.81 2.388-1.965.693-.096 1.444.077 2.061.385.616.309 1.251.424 1.906.674 1.637.617 3.12 1.599 4.603 2.543 3.448 2.253 6.914 4.506 10.092 7.145.539.462 1.098.924 1.483 1.54.231.367.385.752.443 1.175.058.405-.135.81 0 1.195.25.808.501 1.598.713 2.426.77 3.12.828 4.892.654 7.55Z"
      fill="#004EB6"
    />
    <path
      d="M131.356 109.945c-.039.597-.097 1.251-.154 1.964-.135 1.656-.713 3.024-2.369 3.601-1.291.443-2.62.675-3.987.713-.096 0-.443-1.984-.462-2.176a44.216 44.216 0 0 1-.347-2.234 29.37 29.37 0 0 1-.25-4.507c.057-2.561.423-5.238 1.444-7.607.385-.886.905-1.734 1.618-2.427-.077.077.135.481.193.558.115.174.269.309.404.444.308.27.713.404 1.021.674.577.52.982 1.194 1.29 1.887 1.271 2.831 1.522 5.99 1.599 9.11Z"
      fill="#2F455F"
    />
    <path
      d="M114.754 98.793c-.154 0-.327 0-.482-.019-.346-.038-.866-.115-1.328-.424-.655-.481-.848-1.194-.983-1.945-.192-1.001-.019-2.022.193-2.985.135-.597.289-1.194.558-1.752.251-.52.617-1.137 1.117-1.464.54-.347 1.445-.405 2.061-.405.193 0 .405.02.597.039a5.194 5.194 0 0 1 2.157.712c.289.174.501.366.694.559l.154.154c.096.096.173.135.404.212l.096.038c1.079.443 1.368 1.907 1.156 2.793-.154.636-.52 1.156-.828 1.54a8.563 8.563 0 0 1-2.619 2.215c-.906.463-1.946.732-2.947.732Z"
      fill="#004EB6"
    />
    <path
      d="M120.32 94.056c-.115.443-.366.828-.655 1.175-.655.79-1.444 1.502-2.33 1.983-.25.135-.52.25-.79.347a5.243 5.243 0 0 1-2.176.308c-.308-.02-.616-.077-.867-.25-.443-.328-.558-.886-.635-1.387-.077-.481-.713-.616-.982-1.001-.328-.501-.328-1.04-.155-1.58a4.315 4.315 0 0 1 1.83-2.292c.867-.52 1.888-.712 2.87-.616.077 0 .154.02.212.039a4.4 4.4 0 0 1 1.579.539c.269.154.481.385.712.597.251.231.482.308.771.424.558.23.732 1.194.616 1.714Z"
      fill="#ABE0F6"
    />
    <path
      d="M120.32 94.056c-.115.443-.366.828-.655 1.175-.655.79-1.444 1.502-2.33 1.983-.25.135-.52.25-.79.347.097-.385.135-.79.097-1.194-.077-.79-.443-1.156-1.002-1.656-.539-.501-.828-1.56-.655-2.273.116-.559.52-1.02.983-1.329.211-.154.442-.25.674-.327.539.077 1.078.25 1.579.539.269.154.481.385.712.597.251.231.482.308.771.424.558.23.732 1.194.616 1.714Z"
      fill="#71ADEE"
    />
    <path
      d="M117.239 95.25c-.405.289-.944.366-1.445.404-.231 0-.481 0-.713.058-.211.039-.423.135-.635.193-1.079.385-2.253.5-3.409.481-.693 0-1.387-.038-2.041-.23a5.834 5.834 0 0 1-.617-.213c-.828-.366-1.56-1.04-1.791-1.906-.25-.944-.038-2.003-.655-2.85a.744.744 0 0 1-.096-.771c.116-.27.482-.308.713-.443.539-.289.751-.963.789-1.58.039-.789-.058-1.54.27-2.272.308-.693.732-1.56 1.406-1.984 1.04-.674 2.195-.712 3.274-.154.347.174.674.385.963.636a8.56 8.56 0 0 1 3.12 6.336c.019.578-.019 1.213.308 1.695.308.481.905.751 1.079 1.271.154.481-.116 1.02-.52 1.329Z"
      fill="#ABE0F6"
    />
    <path
      d="M117.239 95.25c-.405.289-.944.366-1.445.404-.231 0-.481 0-.712.058-.212.039-.424.135-.636.193-1.079.385-2.253.5-3.409.481-.693 0-1.387-.038-2.041-.23.134-.059.25-.116.366-.174.847-.462 1.56-1.252 1.694-2.215.154-1.117-.5-2.292-.192-3.39.135-.5.462-.924.597-1.425.327-1.136-.501-2.465.173-3.524.347-.52.963-.751 1.618-.828a8.56 8.56 0 0 1 3.12 6.336c.019.578-.019 1.213.308 1.695.308.481.905.75 1.079 1.27.154.502-.116 1.04-.52 1.349Z"
      fill="#71ADEE"
    />
    <path
      d="M119.626 94.114c-.423.211-.905.385-1.348.288-.462-.096-.847-.442-1.252-.674-.654-.366-1.251-.597-2.022-.539-.655.058-1.252.289-1.829.597a7.632 7.632 0 0 1-.713-2.118c-.154.346-.27.693-.366 1.059-.25-.559-.501-1.136-.501-1.733 0-.386.097-.77.154-1.156a5.637 5.637 0 0 0-.192-2.407v-.039a6.93 6.93 0 0 1-.251 1.54 9.032 9.032 0 0 0-1.502-2.021c-.135-.154-.289-.309-.346-.482a.469.469 0 0 1-.02-.231 8.528 8.528 0 0 1-.308 1.002c0-.097-.712-.963-.809-1.098-.212-.386-.289-.732-.346-1.175-.174-1.695.77-3.005 2.118-3.91 2.176-1.463 5.046-1.79 7.511-.944a9.026 9.026 0 0 1 3.255 2.004 7.16 7.16 0 0 1 1.175 1.463 7.36 7.36 0 0 1 .847 1.907c.886 3.197-.231 7.049-3.255 8.666Z"
      fill="#FFDC1E"
    />
    <path
      d="M119.627 94.114c-.424.212-.905.385-1.348.288-.463-.096-.848-.442-1.252-.674-.655-.365-1.252-.597-2.022-.539-.655.058-1.252.29-1.83.597a7.632 7.632 0 0 1-.713-2.118 6.969 6.969 0 0 0-.366 1.059c-.25-.559-.5-1.136-.5-1.733 0-.385.096-.77.154-1.156a5.653 5.653 0 0 0-.193-2.407v-.154c2.234.115 4.526-.097 6.625-.925.251-.096.501-.212.732-.327 1.214-.597 2.311-1.445 3.12-2.465.366.577.655 1.232.848 1.906.885 3.178-.232 7.03-3.255 8.648Z"
      fill="#E39F3A"
    />
    <path
      d="M135.805 112.429c-.27.347-1.676 1.618-2.061 1.888-3.621 2.561-5.605 3.909-9.649 5.777-.539.251-.982.424-1.156 1.04-.096.405-.212.809-.346 1.214-.289.886-.617 1.772-.713 2.696-.038.501-.019 1.002-.135 1.483-.096.443-.404.925-.847.925-.096 0-.212-.02-.308 0-.251.077-.27.442-.463.597-.211.173-.558.038-.712-.193-.251.193-.366.636-.693.655-.309 0-.463-.366-.482-.674-.077-1.175.482-2.369.25-3.525-.231.174-.25.501-.288.79a3.345 3.345 0 0 1-.886 1.849c-.077.077-.174.173-.27.173-.231 0-.308-.308-.289-.539a7.7 7.7 0 0 1 .289-1.868c.327-1.137.886-2.234.867-3.428 0-.289-.058-.597-.289-.752-.328-.25-.79-.057-1.156.077-.404.155-.809.27-1.232.328-.116 0-.232.019-.328-.019a.298.298 0 0 1-.173-.251.303.303 0 0 1 .135-.173c.212-.193.443-.385.654-.597.116-.116.251-.212.386-.308.192-.116.423-.174.654-.212.829-.173 1.657-.347 2.466-.616a7.838 7.838 0 0 0 1.097-.482c.752-.404 1.445-1.078 2.138-1.598a557.083 557.083 0 0 0 3.948-3.043c.751-.578 1.58-1.098 2.311-1.714.289-.251.944-.771 1.272-1.329.192-.308.269-.617.134-.867-.134-.231-.462-.655-.481-.924 0-.097.058-.193.096-.289.058-.193.039-.386.077-.578a2.825 2.825 0 0 1 1.233-1.83c.385-.25.847-.404 1.309-.462.193-.019.405.039.598.019.211-.019.539-.173.674-.327.096-.116.173-.462.077-.616.115.154.211.308.308.462.751 1.155 1.406 2.426 1.868 3.736.443 1.079.924 2.465.116 3.505Z"
      fill="#ABE0F6"
    />
    <path
      d="M135.805 112.429c-.27.347-1.676 1.618-2.061 1.888-3.621 2.561-5.605 3.909-9.649 5.777-.539.251-.982.424-1.156 1.04-.096.405-.211.809-.346 1.214-.289.886-.616 1.772-.713 2.696-.038.501-.019 1.002-.135 1.483-.096.443-.404.924-.847.924-.096 0-.212-.019-.308 0v-.038a22.647 22.647 0 0 1 1.213-6.394c.212-.655.462-1.059.944-1.541.462-.462.963-.867 1.502-1.252 1.059-.751 2.196-1.367 3.332-2.003a207.41 207.41 0 0 0 2.773-1.598c1.175-.694 2.331-1.425 3.043-2.619.231-.386.54-1.195.212-1.618-.289-.347-1.021-.366-1.387-.251-.385.116-.789.309-1.155.366-.385.058-.828.116-1.252.058.193-.308.27-.616.135-.867-.135-.231-.462-.654-.482-.924 0-.096.058-.193.097-.289.057-.193.038-.385.077-.578a2.823 2.823 0 0 1 1.232-1.829 3.138 3.138 0 0 1 1.31-.463c.192-.019.404.039.597.02.212-.02.539-.174.674-.328.096-.115.173-.462.077-.616.116.154.212.308.308.462.751 1.156 1.406 2.427 1.868 3.737.424 1.117.906 2.503.097 3.543Z"
      fill="#71ADEE"
    />
    <path
      d="M134.476 105.881a2.974 2.974 0 0 1-.328 1.521c-.269.52-.674.963-1.098 1.368l-.038.038c-.501.463-1.04.867-1.656 1.117-.674.27-1.252.405-1.888.058a6.512 6.512 0 0 1-.616-.385c-.385-.27-.732-.578-1.098-.886-.77-.655-1.598-1.31-2.157-2.157-.597-.924-.847-2.061-.886-3.178-.058-1.964.482-3.986 1.657-5.566l.019-.019c.501-.655 1.078-1.31 1.945-1.31.443 0 .79.463 1.04.79.751 1.001 1.406 2.08 2.022 3.159.559.963 1.136 1.906 1.695 2.869.25.424.501.848.751 1.252.039.058.077.135.116.193.231.385.5.674.52 1.136Z"
      fill="#004EB6"
    />
    <path
      d="M133.84 104.533a6.556 6.556 0 0 1-1.175 2.581c-.038.038-.077.096-.115.135-.463-1.272-1.06-2.504-1.676-3.698-.655-1.271-1.329-2.562-2.215-3.679a9.427 9.427 0 0 0-2.272-2.099c.5-.655 1.078-1.31 1.945-1.31.443 0 .79.463 1.04.79.751 1.001 1.406 2.08 2.022 3.159.559.962 1.136 1.906 1.695 2.869.25.424.481.828.751 1.252Z"
      fill="#2F455F"
    />
    <path
      d="M134.476 105.881a2.974 2.974 0 0 1-.328 1.521c-.289.54-.693.983-1.136 1.406-.501.463-1.04.867-1.656 1.117-.674.27-1.252.405-1.888.058a6.512 6.512 0 0 1-.616-.385c.25-.135.597-.173.867-.25a6.458 6.458 0 0 0 2.041-1.214c.347-.308.655-.635.925-.982a6.845 6.845 0 0 0 1.174-2.581c.039.058.077.135.116.193.212.366.481.655.501 1.117Z"
      fill="#004EB6"
    />
    <path
      d="M134.476 105.881a2.98 2.98 0 0 1-.328 1.522c-.269.52-.674.963-1.097 1.367a17.622 17.622 0 0 0-.501-1.502c.038-.039.077-.096.115-.135a6.847 6.847 0 0 0 1.175-2.581c.039.058.077.135.116.193.231.385.501.674.52 1.136Z"
      fill="#2F455F"
    />
  </svg>
);

export default YourFamilyIcon;
