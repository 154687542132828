export const closeEmailPreviewModal = () => ({
  type: CLOSE_EMAIL_PREVIEW_MODAL_TYPE,
});

export const displayEmailPreviewModal = ({
  key,
  appointeeFirstName,
  appointeeLevel,
}) => ({
  type: DISPLAY_EMAIL_PREVIEW_MODAL_TYPE,
  payload: {
    key,
    appointeeFirstName,
    appointeeLevel,
  },
});

export const DISPLAY_EMAIL_PREVIEW_MODAL_TYPE = "DISPLAY_EMAIL_PREVIEW_MODAL";
export const CLOSE_EMAIL_PREVIEW_MODAL_TYPE = "CLOSE_EMAIL_PREVIEW_MODAL";
