import { createSelector } from "reselect";

const googleSSOEnabled = () => window.env.FEATURE_GOOGLE_SSO === "enabled";
export const selectIsGoogleSSOEnabled = createSelector(
  googleSSOEnabled,
  (isGoogleSSOEnabled) => isGoogleSSOEnabled,
);

const featureExternalLoginEnabled = () =>
  window.env.FEATURE_EXTERNAL_LOGIN === "enabled";
export const selectIsFeatureExternalLoginEnabled = createSelector(
  featureExternalLoginEnabled,
  (isFeatureExternalLoginEnabled) => isFeatureExternalLoginEnabled,
);

const collapsedPromoFieldEnabled = () =>
  window.env.FEATURE_COLLAPSED_PROMO_FIELD === "enabled";
export const selectIsCollapsedPromoFieldEnabled = createSelector(
  collapsedPromoFieldEnabled,
  (isCollapsedPromoFieldEnabled) => isCollapsedPromoFieldEnabled,
);
