import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";

import {
  RESTING_FIELD_ID,
  RESTING_FORM_ID,
  ASHES_FORM_ID,
  ASHES_FIELD_ID,
  CEREMONY_FORM_ID,
  CEREMONY_FIELD_ID,
  RESTING_CREMATION,
} from "../constants/forms";
import { selectIsFieldComplete } from "./utils/progress";
import { selectTranslations } from "./translations";
import { selectIsCurrentUserSpouse } from "./spouse";

const selectArrangementsData = R.pathOr({}, ["yourArrangements"]);
export const selectResting = R.path(["yourArrangements", RESTING_FIELD_ID]);
export const selectAshes = R.path(["yourArrangements", ASHES_FIELD_ID]);
export const selectCeremony = R.path(["yourArrangements", CEREMONY_FIELD_ID]);
export const selectHasSkippedWishes = R.path([
  "yourArrangements",
  "wishesVisited",
]);
export const selectSpouseResting = R.path([
  "spouseArrangements",
  RESTING_FIELD_ID,
]);
export const selectSpouseAshes = R.path(["spouseArrangements", ASHES_FIELD_ID]);
export const selectSpouseCeremony = R.path([
  "spouseArrangements",
  CEREMONY_FIELD_ID,
]);

export const selectIsRestingComplete = createSelector(
  selectIsFieldComplete(selectArrangementsData, RESTING_FIELD_ID),
  selectHasSkippedWishes,
  (restingComplete, skippedWishes) => restingComplete || skippedWishes,
);

export const selectHasCremationResting = createSelector(
  selectResting,
  (restingValue) => restingValue === RESTING_CREMATION,
);

export const selectIsAshesComplete = createSelector(
  selectIsFieldComplete(selectArrangementsData, ASHES_FIELD_ID),
  selectHasSkippedWishes,
  (isAshesComplete, skippedWishes) => isAshesComplete || skippedWishes,
);

export const selectIsCeremonyComplete = createSelector(
  selectIsFieldComplete(selectArrangementsData, CEREMONY_FIELD_ID),
  selectHasSkippedWishes,
  (ceremonyComplete, skippedWishes) => ceremonyComplete || skippedWishes,
);

export const selectRestingForm = createSelector(
  selectResting,
  selectSpouseResting,
  selectIsCurrentUserSpouse,
  (resting, spouseResting, isSpouse) => ({
    [RESTING_FIELD_ID]: isSpouse ? spouseResting : resting,
  }),
);
export const selectAshesForm = createSelector(
  selectAshes,
  selectSpouseAshes,
  selectIsCurrentUserSpouse,
  (ashes, spouseAshes, isSpouse) => ({
    [ASHES_FIELD_ID]: isSpouse ? spouseAshes : ashes,
  }),
);
export const selectCeremonyForm = createSelector(
  selectCeremony,
  selectSpouseCeremony,
  selectIsCurrentUserSpouse,
  (ceremony, spouseCeremony, isSpouse) => ({
    [CEREMONY_FIELD_ID]: isSpouse ? spouseCeremony : ceremony,
  }),
);

export const wishesAshesPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(ASHES_FORM_ID),
  isLoading: selectIsLoadingById(ASHES_FORM_ID),
  translations: selectTranslations(["global", ASHES_FORM_ID]),
});
export const wishesCeremonyPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(CEREMONY_FORM_ID),
  isLoading: selectIsLoadingById(CEREMONY_FORM_ID),
  translations: selectTranslations(["global", CEREMONY_FORM_ID]),
});
export const restingPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(RESTING_FORM_ID),
  isLoading: selectIsLoadingById(RESTING_FORM_ID),
  translations: selectTranslations(["global", RESTING_FORM_ID]),
});
