import React from "react";
import { reduxForm } from "redux-form";

import Box from "@material-ui/core/Box";

import {
  UPDATE_EMAIL_FORM_ID,
  CONFIRM_CURRENT_PASSWORD_FIELD_ID,
} from "../../../constants/forms";
import { PrimaryButton } from "../../ui/Button";
import EmailField from "../../ui/inputs/EmailField";
import ConfirmEmailField from "../../ui/inputs/ConfirmEmailField";
import PasswordField from "../../ui/inputs/PasswordField";
import { useValidateConfirmCurrentPasswordMemo } from "../../ui/inputs/validations";

const UpdateYourEmailForm = ({
  translations,
  handleSubmit,
  isLoading,
  isNewEmailBeingEntered,
}) => {
  const validateConfirmCurrentPasswordMemo = useValidateConfirmCurrentPasswordMemo(
    translations,
  );

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={4.5}>
        <Box mb={2}>
          <EmailField translations={translations} />
          {isNewEmailBeingEntered && (
            <Box md={2}>
              <ConfirmEmailField translations={translations} />
              <PasswordField
                name={CONFIRM_CURRENT_PASSWORD_FIELD_ID}
                validation={validateConfirmCurrentPasswordMemo}
                translations={translations.oldPassword}
              />
            </Box>
          )}
        </Box>
        <PrimaryButton
          loadingLabel={translations.loadingLabel}
          text={translations.button.submit}
          isLoading={isLoading}
        />
      </Box>
    </form>
  );
};

export default reduxForm({
  form: UPDATE_EMAIL_FORM_ID,
  destroyOnUnmount: false,
})(UpdateYourEmailForm);
