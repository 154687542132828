import React from "react";

import SvgIcon from "./SvgIcon";

const CheckmarkLight = ({ width = 20, height = 20 }) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 20 20" fill="none">
      <path
        d="M9.99996 3.16658C13.7739 3.16658 16.8333 6.22597 16.8333 9.99992C16.8333 13.7739 13.7739 16.8333 9.99996 16.8333C6.22601 16.8333 3.16663 13.7739 3.16663 9.99992C3.16663 6.22597 6.22601 3.16658 9.99996 3.16658Z"
        fill="white"
        stroke="#C6E9F8"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3333 9.99992C18.3333 5.39754 14.6023 1.66658 9.99996 1.66658C5.39759 1.66658 1.66663 5.39754 1.66663 9.99992C1.66663 14.6023 5.39759 18.3333 9.99996 18.3333C14.6023 18.3333 18.3333 14.6023 18.3333 9.99992Z"
        fill="#E2F6FF"
      />
      <path
        d="M13.75 7.91675L9.16663 12.5001L6.66663 10.0001"
        stroke="#0064DC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CheckmarkLight;
