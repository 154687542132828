import { select, takeEvery, call, put } from "redux-saga/effects";

import {
  FETCH_DOCUMENTS_TYPE,
  DOWNLOAD_DOCUMENTS_TYPE,
  addDocuments,
} from "../actions/documents";
import { getDocuments } from "../api/documents";
import { DOCUMENTS_PAGE_ID } from "../constants/forms";
import { fetchApiData } from "./requests";
import { fetchSuccess } from "../actions/requests";
import { validateToken } from "../actions/auth";
import { selectDocumentLink } from "../selectors/documents";
import { selectHasUserPaid, selectIsUpgradableUser } from "../selectors/plans";
import { getPlans } from "../api/plans";
import { addPlans } from "../actions/plans";
import { selectIsAboutYouComplete } from "../selectors/about-you";

function* fetchDocuments() {
  const { documents } = yield call(fetchApiData, {
    apiCall: getDocuments,
    formId: DOCUMENTS_PAGE_ID,
  });

  const isUpgradeable = yield select(selectIsUpgradableUser);
  const hasUserPaid = yield select(selectHasUserPaid);
  const hasCompletedAboutYou = yield select(selectIsAboutYouComplete);
  // ensure we have plans data populated
  // if user is able to upgrade
  // or if unpaid and wants to switch plans
  // needs to complete about you else BE policy fails
  if ((isUpgradeable || !hasUserPaid) && hasCompletedAboutYou) {
    const { products } = yield call(fetchApiData, {
      apiCall: getPlans,
      formId: DOCUMENTS_PAGE_ID,
    });
    yield put(addPlans(products));
  }
  yield put(addDocuments(documents));
  yield put(fetchSuccess(DOCUMENTS_PAGE_ID));
}

function* downloadDocument({ payload }) {
  // This is necessary since we're unable to parse the auth credentials
  // from download requests. So we refresh token prior to the download
  // so that the download request's response auth credentials will be
  // included in the batch request, thus having the same auth credentials
  // as the result of the validate token request
  // https://devise-token-auth.gitbook.io/devise-token-auth/conceptual#about-batch-requests
  yield put(validateToken());

  const { documentKey, isSpouse, isDownload } = payload;
  const downloadUrl = yield select(
    selectDocumentLink(documentKey, isDownload, isSpouse),
  );

  yield call(anchorTagDownload, downloadUrl);
}

function anchorTagDownload(downloadUrl) {
  // Not pretty but allows us to programmatically trigger a download request
  const anchorTag = document.createElement("a");
  anchorTag.href = downloadUrl;
  anchorTag.target = "_blank";
  document.body.appendChild(anchorTag);
  anchorTag.click();
  anchorTag.remove();
}

export function* watchFetchDocuments() {
  yield takeEvery(FETCH_DOCUMENTS_TYPE, fetchDocuments);
}

export function* watchDownloadDocuments() {
  yield takeEvery(DOWNLOAD_DOCUMENTS_TYPE, downloadDocument);
}
