import React, { useEffect } from "react";
import { connect } from "react-redux";

import GiftsForm from "../../../components/forms/your-estate/GiftsForm";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { giftsPageProps } from "../../../selectors/gifts";
import { DASHBOARD_PATH } from "../../../constants/routes";
import { GIFTS_FORM_ID } from "../../../constants/forms";
import { getFormData } from "../../../actions/forms";
import { updateGifts } from "../../../actions/gifts";
import { skipSection } from "../../../actions/navigation";
import { skipGifts } from "../../../api/gifts";
import GiftsFormDeprecated from "../../../components/forms/your-estate/GiftsFormDeprecated";

const GiftsPage = ({
  getFormData,
  updateGifts,
  skipSection,
  hasLoaded,
  ...restOfProps
}) => {
  useEffect(() => {
    getFormData(GIFTS_FORM_ID);
  }, []);
  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <GiftsFormDeprecated
      backLink={DASHBOARD_PATH}
      onSubmit={() => updateGifts()}
      onSkip={() => skipSection(skipGifts, GIFTS_FORM_ID)}
      {...restOfProps}
    />
  );
};

export default connect(giftsPageProps, {
  getFormData,
  updateGifts,
  skipSection,
})(GiftsPage);
