import React from "react";
import { Box } from "@material-ui/core";
import { H5, SmallBody } from "./Typography";
import { SmallTextButton } from "./Button";

export const TotalPercentageBar = ({
  translations,
  total,
  distributeAllocationsEvenly,
}) => {
  return (
    <Box bgcolor="gray.main">
      <Box py={1} px={1.5} display="flex" justifyContent="space-between">
        <Box>
          {distributeAllocationsEvenly && (
            <SmallTextButton
              text={translations.label.distributeEqually}
              onClick={() => distributeAllocationsEvenly()}
              component="span"
              color="willfulBlue"
              bold
            />
          )}
        </Box>

        <Box display="flex" alignItems="right">
          <Box mr={0.5}>
            <SmallBody>{translations.label.total}</SmallBody>
          </Box>
          <H5>{`${total}%`}</H5>
        </Box>
      </Box>
    </Box>
  );
};
