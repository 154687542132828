import React from "react";
import { connect } from "react-redux";

import { sectionsSummaryProps } from "../selectors/dashboard";
import SectionCard from "../components/ui/SectionCard";

const SectionsSummary = ({
  hasLoaded,
  isLoading,
  onClose,
  isWithinNav,
  aboutYou,
  yourFamily,
  yourEstate,
  yourArrangements,
  isCouplesPlan,
  spouseEstate,
  spouseArrangements,
  nextSectionPath,
}) => {
  // if (!hasLoaded) return <LoadingSpinner />;
  return (
    <>
      <SectionCard
        onClose={onClose}
        isWithinNav={isWithinNav}
        qaLabel="about-you"
        showStepLabel
        showProgressBar
        allowAccordion
        continuePath={nextSectionPath}
        {...aboutYou}
      />
      <SectionCard
        onClose={onClose}
        isWithinNav={isWithinNav}
        qaLabel="your-family"
        showStepLabel
        showProgressBar
        allowAccordion
        continuePath={nextSectionPath}
        {...yourFamily}
      />
      <SectionCard
        onClose={onClose}
        isWithinNav={isWithinNav}
        qaLabel="your-estate"
        showStepLabel
        showProgressBar
        allowAccordion
        continuePath={nextSectionPath}
        {...yourEstate}
      />
      <SectionCard
        onClose={onClose}
        isWithinNav={isWithinNav}
        qaLabel="your-arrangements"
        showStepLabel
        showProgressBar
        allowAccordion
        continuePath={nextSectionPath}
        {...yourArrangements}
      />
      {isCouplesPlan && (
        <>
          {/* TODO Copy here about "Your Spouse's documents" */}
          <SectionCard
            onClose={onClose}
            isWithinNav={isWithinNav}
            qaLabel="spouse-estate"
            showStepLabel
            showProgressBar
            allowAccordion
            continuePath={nextSectionPath}
            {...spouseEstate}
          />
          <SectionCard
            onClose={onClose}
            isWithinNav={isWithinNav}
            qaLabel="spouse-arrangements"
            showStepLabel
            showProgressBar
            allowAccordion
            continuePath={nextSectionPath}
            {...spouseArrangements}
          />
        </>
      )}
    </>
  );
};

export default connect(sectionsSummaryProps)(SectionsSummary);
