import { takeEvery, select } from "redux-saga/effects";
import { UPDATE_STATUS_TYPE } from "../actions/status";
import { selectFirstName } from "../selectors/about-you";
import {
  selectHasUserPaid,
  selectPlanName,
  selectDateOfPurchase,
  selectIsEligibleForRefund,
} from "../selectors/plans";
import {
  selectInitialUtmSource,
  selectUserEmail,
  selectUtmSource,
  selectRailsUserId,
} from "../selectors/auth";
import { selectProvinceCode } from "../selectors/provinces";

function* updateUser() {
  if (!window.adaEmbed) {
    return yield null;
  }
  const hasUserPaid = yield select(selectHasUserPaid);
  const addIfValid = (obj, key, value) => {
    if (value !== null && value !== undefined && `${value}`.length > 0) {
      // eslint-disable-next-line no-param-reassign
      obj[key] = value;
    }
  };

  const metaFields = {};
  addIfValid(metaFields, "user_id", yield select(selectRailsUserId));
  addIfValid(metaFields, "utm_source", yield select(selectUtmSource));
  addIfValid(
    metaFields,
    "initial_utm_source",
    yield select(selectInitialUtmSource),
  );
  addIfValid(metaFields, "first_name", yield select(selectFirstName));
  addIfValid(metaFields, "email", yield select(selectUserEmail));
  addIfValid(metaFields, "province", yield select(selectProvinceCode));
  addIfValid(metaFields, "plan", yield select(selectPlanName));
  addIfValid(metaFields, "paid_status", hasUserPaid ? "paid" : "unpaid");
  addIfValid(
    metaFields,
    "date_of_purchase",
    yield select(selectDateOfPurchase),
  );
  addIfValid(
    metaFields,
    "is_eligible_for_refund",
    yield select(selectIsEligibleForRefund),
  );

  window.adaEmbed.setMetaFields(metaFields);
  return yield null;
}

export function* watchUpdateUserForAda() {
  yield takeEvery(UPDATE_STATUS_TYPE, updateUser);
}
