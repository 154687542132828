import React from "react";
import { reduxForm, InjectedFormProps } from "redux-form";
import { useSelector, TypedUseSelectorHook, RootStateOrAny } from "react-redux";

import { Box } from "@material-ui/core";

import NameFields from "../../ui/inputs/NameFields";
import EmailField from "../../ui/inputs/EmailField";
import { PrimaryButton } from "../../ui/Button";
import { SmallBody } from "../../ui/Typography";

import {
  CONTACT_FIELD_ID,
  CONTACT_MODAL_FORM_ID,
} from "../../../constants/forms";

import { selectLastSelectedContactData } from "../../../selectors/contacts";

const useTypedSelector: TypedUseSelectorHook<RootStateOrAny> = useSelector;

import { featureContactsEmailEnabled } from "../../../utilities/featureFlags";

const ContactModal = ({
  invalid,
  handleSubmit,
  isLoading,
  translations,
  submitting,
}: InjectedFormProps & ContactModalProps) => {
  const contactData: ContactModalData = useTypedSelector(
    selectLastSelectedContactData,
  );

  const hasRoles = contactData?.hasRoles;
  const isAdult = !contactData?.isMinorChild;

  return (
    <form onSubmit={handleSubmit}>
      <Box mt={1}>
        <NameFields
          useTheir
          fieldName={CONTACT_FIELD_ID}
          translations={translations}
          autoFocus={false}
          disableMiddleName={false}
        />
        {featureContactsEmailEnabled() && isAdult && (
          <EmailField
            useTheir
            autoFocus={false}
            translations={translations}
            optional
            fieldName={CONTACT_FIELD_ID}
          />
        )}
        {hasRoles && (
          <Box mb={2}>
            <SmallBody>{translations.rolesInfo}</SmallBody>
          </Box>
        )}
        <PrimaryButton
          fullWidth
          isLoading={isLoading}
          text={translations.button.submit}
          opacity={invalid ? 0.5 : 1}
          isDisabled={submitting}
        />
      </Box>
    </form>
  );
};

export default reduxForm<{}, ContactModalProps>({
  form: CONTACT_MODAL_FORM_ID,
})(ContactModal);
