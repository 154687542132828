export const closeTooltipModal = (key) => ({
  type: CLOSE_TOOLTIP_MODAL_TYPE,
  payload: {
    key,
  },
});

export const displayTooltipModal = (key) => ({
  type: DISPLAY_TOOLTIP_MODAL_TYPE,
  payload: {
    key,
  },
});

export const DISPLAY_TOOLTIP_MODAL_TYPE = "DISPLAY_TOOLTIP_MODAL";
export const CLOSE_TOOLTIP_MODAL_TYPE = "CLOSE_TOOLTIP_MODAL";
