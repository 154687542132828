import React from "react";
import SvgIcon from "./SvgIcon";

const EducationIcon = ({ width = 72, height = 62 }) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 72 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36 61.185c19.882 0 36-10.723 36-23.949S55.882 13.288 36 13.288 0 24.01 0 37.236c0 13.227 16.118 23.949 36 23.949Z"
      fill="#F6F6F6"
    />
    <path
      d="m30.56 20.83 10.198 27.805-3.122.063L8 31.714 28.242 19.61l2.318 1.22Z"
      fill="#2F455F"
    />
    <path
      d="M58.682 31.465v6.661L40.758 48.604v-6.662l17.924-10.477Z"
      fill="#F7F7FB"
    />
    <path
      d="M58.682 31.465v2.314L40.758 44.257v-2.315l17.924-10.477Z"
      fill="#ABE0F6"
    />
    <path
      d="M40.758 48.604 12.512 32.09v-6.662l28.246 16.514v6.662ZM59.609 31.808v-.844l-18.14 10.603v-.032l-.711.407V48.604l.71-.407L59.61 37.595v-.845l-18.14 10.603V42.38l18.14-10.572Z"
      fill="#255793"
    />
    <path
      d="M40.758 41.973 12.512 25.46l18.82-11.01 28.277 16.515-18.851 11.01ZM59.61 36.75h-.032.031l-.742-.438v.032l-.185-.126v.376l-17.213 10.07V47.354l17.213-10.071.927-.532Z"
      fill="#71ADEE"
    />
    <path
      d="m44.404 42.005-31.46-14.388 15.668-11.979 31.46 14.388-15.668 11.979Z"
      fill="#67A7D2"
    />
    <path
      d="M60.072 24.083v-.844L44.404 35.186v6.819l15.668-11.98v-.813L45.115 40.754v-5.13l14.957-11.54ZM12.944 20.799l31.46 14.387v6.819l-31.46-14.388V20.8Z"
      fill="#255793"
    />
    <path
      d="m45.115 35.624 14.03-10.822v4.379l-14.03 10.822v-4.379Z"
      fill="#E1F3F9"
    />
    <path
      d="m60.073 29.212-.928-.406v.375l-14.03 10.822v.75l14.958-11.54Z"
      fill="#71ADEE"
    />
    <path
      d="m45.115 35.624 14.03-10.822v1.877L45.115 37.5v-1.877Z"
      fill="#ABE0F6"
    />
    <path
      d="M44.404 35.186 12.944 20.8 28.612 8.82l31.46 14.387-15.668 11.98Z"
      fill="#71ADEE"
    />
    <path
      d="M60.32 17.671v6.662L42.395 34.811v-6.662L60.32 17.671Z"
      fill="#E1F3F9"
    />
    <path
      d="M60.32 17.671v2.346L42.395 30.495v-2.346L60.32 17.671Z"
      fill="#ABE0F6"
    />
    <path
      d="M42.396 34.811 14.119 18.328v-6.662L42.396 28.15v6.662ZM61.216 18.015v-.844l-18.11 10.603v-.032l-.71.407v6.662l.71-.407 18.11-10.603v-.844L43.106 33.56v-4.942l18.11-10.603Z"
      fill="#255793"
    />
    <path
      d="M42.396 28.18 14.119 11.698 32.97.688l28.246 16.483-18.82 11.01ZM61.216 22.957l-.71-.407-.186-.093v.344L43.106 32.87v.689L60.32 23.489l.896-.532Z"
      fill="#71ADEE"
    />
    <path
      d="M44.343 16.983 19.402 9.54 27.747 0l24.94 7.444-8.344 9.54Z"
      fill="#FFDC1E"
    />
    <path
      d="M52.687 11.541v.594l-8.344 9.54v-4.692l8.344-9.54v.564l-8.004 9.195v3.535l8.004-9.196Z"
      fill="#CE9134"
    />
    <path
      d="m44.683 20.017 7.355-8.445V8.757l-7.355 8.445v2.815Z"
      fill="#E1F3F9"
    />
    <path
      d="m52.687 11.541-.65-.188v.22l-7.354 8.444v.72l8.004-9.196Z"
      fill="#FFDC1E"
    />
    <path
      d="M19.403 14.23V9.54l24.94 7.443v4.692l-24.94-7.444Z"
      fill="#E39F3A"
    />
    <path
      d="m44.683 18.297 7.355-8.476V8.757l-7.355 8.445v1.095Z"
      fill="#ABE0F6"
    />
    <path
      d="m44.466 29.306 2.256-1.345 2.008 1.189-1.915.188-.34 1.125-2.01-1.157Z"
      fill="#71ADEE"
    />
    <path
      d="m44.342 21.675 8.313-9.509 1.05.626-7.88 9.008-1.483-.125Z"
      fill="#2F455F"
    />
  </SvgIcon>
);

export default EducationIcon;
