import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import {
  submitForgotSsoPassword,
  submitUpdatePassword,
} from "../../actions/auth";
import { updateUser } from "../../actions/user";
import { displayModal } from "../../actions/modal";
import { resendInvite, revokeInvite } from "../../actions/invites";
import {
  UPDATE_EMAIL_FORM_ID,
  UPDATE_PASSWORD_FORM_ID,
} from "../../constants/forms";
import {
  MODAL_DELETE_ACCOUNT,
  MODAL_DELETE_ACCOUNT_GOOGLE_SSO,
} from "../../constants/modal";
import { YourAccountPageProps } from "../../selectors/your-account";
import UpdateYourEmailForm from "../../components/forms/your-account/UpdateYourEmailForm";
import ChangeYourPasswordForm from "../../components/forms/your-account/ChangeYourPasswordForm";
import { PageLayout } from "../../components/ui/PageLayout";
import { H1, H3, Body, ExternalLink } from "../../components/ui/Typography";
import YourAccountIcon from "../../components/ui/icons/YourAccountIcon";
import { SecondaryButton, TextButton } from "../../components/ui/Button";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { fetchYourAccount } from "../../actions/your-account";
import { analyticsClickExternalLink } from "../../actions/analytics";
import YourPlanCard from "../../components/plans/your-plan/YourPlanCard";
import { selectIsGoogleSso, selectIsPasswordSet } from "../../selectors/auth";
import { displayTooltipModal } from "../../actions/tooltip-modal";

const YourAccountNavLinks = ({ translations, hasPlan }) => {
  const {
    emailNavText,
    passwordNavText,
    deleteNavText,
    yourPlanNavText,
  } = translations;
  return (
    <Box mb={4.5}>
      {hasPlan && (
        <Box mb={1}>
          <ExternalLink
            openNewPage={false}
            href="#yourplan"
            text={yourPlanNavText}
          />
        </Box>
      )}
      <Box mb={1}>
        <ExternalLink openNewPage={false} href="#email" text={emailNavText} />
      </Box>
      <Box mb={1}>
        <ExternalLink
          openNewPage={false}
          href="#password"
          text={passwordNavText}
        />
      </Box>
      <Box mb={1}>
        <ExternalLink openNewPage={false} href="#delete" text={deleteNavText} />
      </Box>
    </Box>
  );
};

// TODO: Break out into separate component files
const YourAccountFormsContainer = ({
  translations,
  changePasswordProps,
  submitUpdatePassword,
  updateUser,
  changeEmailProps,
  yourPlanProps,
  displayModal,
  revokeInvite,
  analyticsClickExternalLink,
  resendInvite,
  hasPlan,
  submitForgotSsoPassword,
}) => {
  const {
    emailNavText,
    yourPlanNavText,
    passwordNavText,
    deleteAccount,
    changePassword,
  } = translations;

  const { ssoBody, ssoBodyUnderlineText } = changePassword;

  const isGoogleSso = useSelector(selectIsGoogleSso);
  const isPasswordSet = useSelector(selectIsPasswordSet);
  const isGoogleSSOWithoutPassword = isGoogleSso && !isPasswordSet;

  return (
    <Grid container justify="center" spacing={2}>
      <Grid item sm={12} md={8} lg={4}>
        <YourAccountNavLinks translations={translations} hasPlan={hasPlan} />
      </Grid>

      <Grid item sm={12} md={8} lg={4}>
        {hasPlan && (
          <>
            <Box id="yourplan" mb={3}>
              <H3 align="left">{yourPlanNavText}</H3>
            </Box>
            <Box mb={4}>
              <YourPlanCard
                displayModal={displayModal}
                translations={translations}
                revokeInvite={revokeInvite}
                resendInvite={resendInvite}
                analyticsClickExternalLink={analyticsClickExternalLink}
                {...yourPlanProps}
              />
            </Box>
          </>
        )}
        <Box id="email" mb={3}>
          <H3 align="left">{emailNavText}</H3>
        </Box>
        <UpdateYourEmailForm
          translations={{
            ...translations,
            ...translations.updateEmail,
            ...translations.changePassword,
          }}
          onSubmit={() => updateUser(UPDATE_EMAIL_FORM_ID)}
          {...changeEmailProps}
        />
        <Box id="password" mb={3}>
          <H3 align="left">{passwordNavText}</H3>
          {isGoogleSso && !isPasswordSet ? (
            <Body align="left" component="span" bold={false}>
              {ssoBody}
              <TextButton
                onClick={submitForgotSsoPassword}
                text={ssoBodyUnderlineText}
              />
            </Body>
          ) : (
            <>
              <ChangeYourPasswordForm
                translations={{
                  ...translations,
                  ...translations.changePassword,
                }}
                onSubmit={() => submitUpdatePassword(UPDATE_PASSWORD_FORM_ID)}
                {...changePasswordProps}
              />
            </>
          )}
        </Box>
        <Box id="delete">
          <H3 align="left">{deleteAccount.header}</H3>
          <Body>{deleteAccount.body}</Body>
          <SecondaryButton
            type="button"
            text={deleteAccount.button.submit}
            onClick={() =>
              displayModal(
                isGoogleSSOWithoutPassword
                  ? MODAL_DELETE_ACCOUNT_GOOGLE_SSO
                  : MODAL_DELETE_ACCOUNT,
              )
            }
          />
        </Box>
      </Grid>

      <Grid item sm={12} md={8} lg={4} />
    </Grid>
  );
};

const HeaderRow = ({ header }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  let logoSize = "142px";
  if (isMobile) {
    logoSize = "70px";
  } else if (isTablet) {
    logoSize = "108px";
  }

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <H1 align="left" display="flex">
        {header}
      </H1>
      <Box display="flex" justifyContent="flex-end">
        <YourAccountIcon height={logoSize} />
      </Box>
    </Box>
  );
};

const YourAccountPage = ({
  fetchYourAccount,
  fetchPaymentMethods,
  translations,
  hasLoaded,
  ...restOfProps
}) => {
  useEffect(() => {
    fetchYourAccount();
  }, [fetchYourAccount]);
  if (!hasLoaded) return <LoadingSpinner />;

  const { header } = translations;

  return (
    <PageLayout>
      <Grid container justify="center">
        <Grid item sm={12} md={8} lg={12}>
          <Box>
            <HeaderRow header={header} />
          </Box>
        </Grid>
        <Grid item sm={12}>
          <YourAccountFormsContainer
            translations={translations}
            {...restOfProps}
          />
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default connect(YourAccountPageProps, {
  submitUpdatePassword,
  fetchYourAccount,
  updateUser,
  displayModal,
  revokeInvite,
  analyticsClickExternalLink,
  resendInvite,
  submitForgotSsoPassword,
})(YourAccountPage);
