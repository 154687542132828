import React from "react";
import SvgIcon from "./SvgIcon";

const ImportantNotePageIcon = ({ isDesktop }) => {
  const width = isDesktop ? 240 : 180;
  const height = isDesktop ? 192 : 143;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 240 191.18">
      <g clipPath="url(#clip0_22479_72311)">
        <path
          d="M120 191.176C186.274 191.176 240 155.436 240 111.348C240 67.2599 186.274 31.5195 120 31.5195C53.7258 31.5195 0 67.2599 0 111.348C0 155.436 53.7258 191.176 120 191.176Z"
          fill="#C6E9F8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M149.974 87.3479C149.974 87.3479 186.026 94.7642 196.738 83.8457C202.3 96.4122 173.356 113.717 173.356 113.717L149.974 87.3479Z"
          fill="#2F455F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M147.605 146.164L184.79 151.726C200.858 142.867 222.386 130.198 222.386 130.198L175.21 102.387L147.605 146.164Z"
          fill="#2F455F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76.8412 96.4123L98.2661 84.0518V95.8973L76.8412 108.258V96.4123Z"
          fill="#BC7F35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.0472 42.7472L98.4721 30.3867L98.2661 84.052L76.8412 96.4125L77.0472 42.7472Z"
          fill="#B1B1B1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.3176 12.3605L59.8455 0L98.4721 22.1459L77.0472 34.5064L38.3176 12.3605Z"
          fill="#E2A03B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.0472 34.506L98.4721 22.1455V30.3858L77.0472 42.7464V34.506Z"
          fill="#BC7F35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.85 30.6952C107.433 26.369 102.592 27.7081 100.223 29.0471L78.7983 41.4077C81.1674 40.0686 85.9056 38.7295 93.3219 43.0557C98.8841 46.2489 106.3 45.2188 112.481 41.6137L134.009 29.2531C127.725 32.7553 120.412 33.8883 114.85 30.6952Z"
          fill="#ABE0F6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M177.167 105.579C177.373 102.798 179.536 101.047 179.536 98.1628C179.536 95.2787 177.476 91.2616 177.373 88.4804C177.27 86.5234 179.124 85.8023 178.815 84.0513C178.506 81.7852 178.094 79.4161 177.579 77.15C176.343 71.8967 169.442 66.7465 162.541 62.8324C157.597 60.0513 152.549 57.6822 147.399 55.6221L125.974 67.9826C131.227 70.0427 136.275 72.4118 141.116 75.1929C148.017 79.2101 154.918 84.2573 156.155 89.6135C156.67 91.8796 157.082 94.1457 157.391 96.5148C157.597 98.3688 155.743 99.0899 155.949 100.944C156.155 103.725 158.215 107.742 158.112 110.626C158.009 113.51 155.949 115.262 155.743 118.043C155.536 121.236 157.185 125.356 156.67 128.24C156.155 131.33 153.579 133.184 152.858 135.656C152.446 137.304 153.888 139.983 153.373 141.219C152.343 143.691 151.21 145.339 149.974 146.06L171.502 133.699C172.738 132.978 173.871 131.33 174.901 128.858C175.416 127.622 173.974 124.944 174.386 123.296C175.107 120.824 177.682 119.073 178.197 115.88C178.609 112.995 176.961 108.772 177.167 105.579Z"
          fill="#71ADEE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M147.708 9.16698L126.283 21.5275C127.004 21.1155 127.828 21.2185 128.446 21.7335L149.974 9.37299C149.253 8.96097 148.429 8.85797 147.708 9.16698Z"
          fill="#BCD5ED"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M149.974 9.37305L128.446 21.7336C133.185 24.4117 139.468 40.2743 134.421 53.3559C130.403 63.7593 127.931 66.9525 126.489 67.6735L148.017 55.313C149.459 54.4889 151.931 51.2958 155.948 40.8924C160.996 27.8108 154.609 12.0512 149.974 9.37305Z"
          fill="#71ADEE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M128.446 21.7335C133.185 24.4116 139.468 40.2743 134.421 53.3559C129.785 65.4074 127.21 67.7765 125.974 67.9825C131.228 70.0426 136.275 72.4117 141.116 75.1928C148.017 79.2099 154.919 84.2572 156.155 89.5104C156.67 91.7765 157.082 94.0426 157.391 96.4117C157.597 98.2657 155.743 98.9868 155.949 100.841C156.155 103.622 158.215 107.639 158.112 110.523C158.009 113.407 155.949 115.158 155.743 117.94C155.537 121.133 157.185 125.253 156.67 128.137C156.155 131.227 153.579 133.081 152.858 135.553C152.446 137.201 153.888 139.879 153.373 141.116C151.725 145.03 149.768 146.987 147.708 146.06C135.76 140.91 124.017 135.141 112.687 128.755C96 119.176 79.3133 107.124 76.8412 96.3087L77.0472 42.7464C77.3562 42.4374 82.4034 36.6692 93.4249 42.9524C104.446 49.2357 122.472 39.0382 124.532 25.3387C125.047 21.6305 126.592 20.7035 128.446 21.7335Z"
          fill="#E1F3F9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.0472 34.5063V42.7466L76.8412 96.4119V108.257L38.1116 86.1114L38.3176 12.3604L77.0472 34.5063Z"
          fill="#FFDC1E"
        />
      </g>
      <defs>
        <clipPath id="clip0_22479_72311">
          <rect width="240" height="191.176" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default ImportantNotePageIcon;
