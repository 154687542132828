import React from "react";
import { connect } from "react-redux";

import Box from "@material-ui/core/Box";
import { ExternalButtonLink } from "../../components/ui/Button";
import { H2, Body } from "../../components/ui/Typography";
import { WEBSITE_URL } from "../../constants/routes";
import { thankYouPageProps } from "../../selectors/thank-you";

const ThankYouPage = ({ translations }) => {
  const { pageTitle, pageDescription } = translations;
  return (
    <Box mt={10}>
      <H2>{pageTitle}</H2>
      <Body align="center">{pageDescription}</Body>
      <Box display="flex" justifyContent="center" mt={2}>
        <ExternalButtonLink
          align="center"
          url={WEBSITE_URL}
          text={translations.button.submit}
        />
      </Box>
    </Box>
  );
};

export default connect(thankYouPageProps)(ThankYouPage);
