import { combineReducers } from "redux";
import {
  DISPLAY_TOOLTIP_MODAL_TYPE,
  CLOSE_TOOLTIP_MODAL_TYPE,
} from "../actions/tooltip-modal";

const visible = (state = false, { type }) => {
  switch (type) {
    case DISPLAY_TOOLTIP_MODAL_TYPE:
      return true;
    case CLOSE_TOOLTIP_MODAL_TYPE:
      return false;
    default:
      return state;
  }
};

const key = (state = null, { type, payload }) => {
  switch (type) {
    case DISPLAY_TOOLTIP_MODAL_TYPE:
      return payload.key;
    case CLOSE_TOOLTIP_MODAL_TYPE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  visible,
  key,
});
