import R from "ramda";

import { UPDATE_STATUS_TYPE } from "../actions/status";
import {
  RESTING_FIELD_ID,
  ASHES_FIELD_ID,
  CEREMONY_FIELD_ID,
  EXECUTORS_FIELD_ID,
  SPOUSE_IS_EXECUTOR_FIELD_ID,
  PERSONAL_ATTORNEYS_FIELD_ID,
  PROPERTY_ATTORNEYS_FIELD_ID,
  SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
  SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID,
  PAIN_MANAGE_FIELD_ID,
  LIFE_PROLONG_FIELD_ID,
} from "../constants/forms";

const DEFAULT_STATE = {
  resting: "",
  ashes: "",
  ceremony: "",
  executors: [],
  lifeProlong: "",
  painManage: "",
  wishesVisited: false,
  healthcareVisited: false,
};

const yourArrangements = (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return {
        ...state,
        [RESTING_FIELD_ID]: R.pathOr("", ["spouse", RESTING_FIELD_ID])(payload),
        [ASHES_FIELD_ID]: R.pathOr("", ["spouse", ASHES_FIELD_ID])(payload),
        [CEREMONY_FIELD_ID]: R.pathOr("", ["spouse", CEREMONY_FIELD_ID])(
          payload,
        ),
        [EXECUTORS_FIELD_ID]: R.pathOr(
          [],
          ["spouse", EXECUTORS_FIELD_ID],
        )(payload),
        [SPOUSE_IS_EXECUTOR_FIELD_ID]: R.pathOr("", [
          "spouse",
          SPOUSE_IS_EXECUTOR_FIELD_ID,
        ])(payload),
        [PROPERTY_ATTORNEYS_FIELD_ID]: R.pathOr(
          [],
          ["spouse", PROPERTY_ATTORNEYS_FIELD_ID],
        )(payload),
        [SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID]: R.pathOr("", [
          "spouse",
          SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID,
        ])(payload),
        [PERSONAL_ATTORNEYS_FIELD_ID]: R.pathOr(
          [],
          ["spouse", PERSONAL_ATTORNEYS_FIELD_ID],
        )(payload),

        [SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID]: R.pathOr("", [
          "spouse",
          SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID,
        ])(payload),
        wishesVisited: R.pathOr(false, ["spouse", "wishesVisited"])(payload),
        healthcareVisited: R.pathOr(false, ["spouse", "healthcareVisited"])(
          payload,
        ),
        [PAIN_MANAGE_FIELD_ID]: R.pathOr("", ["spouse", PAIN_MANAGE_FIELD_ID])(
          payload,
        ),
        [LIFE_PROLONG_FIELD_ID]: R.pathOr("", [
          "spouse",
          LIFE_PROLONG_FIELD_ID,
        ])(payload),
      };
    default:
      return state;
  }
};

export default yourArrangements;
