import {
  ANALYTICS_SIGNUP_TYPE,
  ANALYTICS_CHECKOUT_PURCHASE_TYPE,
  ANALYTICS_VIEW_PAGE_TYPE,
} from "../../actions/analytics";
import { isBingEnabled } from "../../utilities/analytics";

export const BingAnalytics = () => {
  if (typeof window === "undefined") {
    return () => {};
  }

  if (typeof window.uetq === "undefined") {
    console.warn(
      "Bing analytics are not being tracked. window.uetq is not found",
    );
    return () => {};
  }

  if (isBingEnabled() !== true) {
    console.warn("Bing analytics are not being tracked.");
    return () => {};
  }

  window.uetq = window.uetq || [];

  return function target(events) {
    events.forEach((event) => {
      switch (event.type) {
        case "pageview":
          window.uetq.push("event", "page_view", { page_path: event.path });
          break;
        case "signup":
          window.uetq.push("event", "create", {
            event_category: "account",
            event_label: "signup",
            // event_value: 0,
          });
          break;
        case "purchase":
          window.uetq.push("event", "purchase", {
            event_category: "commerce",
            // event_label: "",
            // event_value: 0 ,
            revenue_value: event.revenue,
            currency: "CAD",
          });
          break;
        default:
          break;
      }
    });
  };
};

export const BING_CONFIG = {
  [ANALYTICS_VIEW_PAGE_TYPE]: ({ payload }) => ({
    type: "pageview",
    path: payload.location.pathname,
  }),
  [ANALYTICS_SIGNUP_TYPE]: () => ({
    type: "signup",
  }),
  [ANALYTICS_CHECKOUT_PURCHASE_TYPE]: ({ payload }) => {
    const { revenue } = payload;
    return {
      type: "purchase",
      revenue,
    };
  },
};
