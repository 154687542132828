import React from "react";
import styled from "styled-components";

// original file? https://medium.com/itsoktomakemistakes/react-styled-component-checkbox-radio-button-checked-onchange-7dc11f281d4e

const ErrorStateRadioInput = ({
  isChecked,
  name,
  label,
  value,
  handleOnChange
}) => (
  <Wrapper>
    <Item>
      <RadioButton
        type="radio"
        name={name}
        value={value}
        checked={isChecked}
        onChange={handleOnChange}
      />
      <RadioButtonLabel htmlFor={label} />
    </Item>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 32px;
  box-sizing: border-box;
  display: inline-block;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: #ffffff;
  border: 3px solid ${props => props.theme.error};
  cursor: pointer;
`;
const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  &:checked + ${RadioButtonLabel} {
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 11px;
      height: 11px;
      margin: 4px;
      background: #ffffff;
      cursor: pointer;
    }
  }
`;

export default ErrorStateRadioInput;
