export const fetchCart = () => ({
  type: FETCH_CART_TYPE,
});

export const updateCart = (cart) => ({
  type: UPDATE_CART_TYPE,
  payload: cart,
});

export const clearCart = () => ({
  type: CLEAR_CART_TYPE,
});

export const FETCH_CART_TYPE = "FETCH_CART";
export const UPDATE_CART_TYPE = "UPDATE_CART";
export const CLEAR_CART_TYPE = "CLEAR_CART";
