import React, { useEffect } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { getSectionData } from "../../actions/forms";
import { H2, SmallBody, SectionTitle } from "../../components/ui/Typography";
import { PageLayout } from "../../components/ui/PageLayout";
import SectionCard from "../../components/ui/SectionCard";
import { endOfSectionReviewPageProps } from "../../selectors/dashboard";
import { ButtonLink, PreviousLink } from "../../components/ui/Button";
import { analyticsClickedEdit } from "../../actions/analytics";
import { useStickyBottomBoxStyle } from "../../components/ui/utils/sticky-bottom-box";
import { useMediaQuery, useTheme } from "@material-ui/core";

const EndOfSectionReviewPage = ({
  hasLoaded,
  sectionProps,
  getSectionData,
  translations,
  analyticsClickedEdit,
  displayPreviousLinkInEyeBrow,
  nextOverviewPath,
}) => {
  const confirmButtonStyling = useStickyBottomBoxStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    getSectionData(sectionProps.sectionKey);
  }, [getSectionData, sectionProps.sectionKey]);

  if (!hasLoaded) return <LoadingSpinner />;

  const { title } = sectionProps.translations;
  const buttonContentSpacing = !displayPreviousLinkInEyeBrow
    ? "space-between"
    : "center";

  return (
    <PageLayout pb={10}>
      {displayPreviousLinkInEyeBrow && (
        <Box display="inline-flex">
          <PreviousLink
            text={translations.button.previous}
            displayArrowLeft
            to={sectionProps.sectionLastPage}
            underline
          />
        </Box>
      )}
      <Grid container justify="center">
        <Grid item xs={12} md={6}>
          <Box mb={2.5}>
            <Box my={1}>
              <SectionTitle>{title}</SectionTitle>
            </Box>
            <Box mb={1.5}>
              <H2>{translations.pageTitle}</H2>
            </Box>
            <SmallBody align="center">{translations.pageDescription}</SmallBody>
          </Box>
          <SectionCard
            {...sectionProps}
            analyticsClickedEdit={analyticsClickedEdit}
            showStepLabel={false}
            showProgressBar={false}
            allowAccordion={false}
            fullWidth
          />
          <Box
            maxWidth={400}
            display="flex"
            margin="0 auto"
            justifyContent={buttonContentSpacing}
            mt={2.5}
          >
            {!displayPreviousLinkInEyeBrow && (
              <Box
                mt={isDesktop ? 1 : 0}
                mb={isMobile ? 2.5 : 0}
                display="flex"
                alignItems="center"
              >
                <PreviousLink
                  text={translations.button.previous}
                  displayArrowLeft
                  to={sectionProps.sectionLastPage}
                  underline
                />
              </Box>
            )}
            <Box
              display="flex"
              mt={isDesktop ? 2 : 0}
              className={isMobile && confirmButtonStyling.stickyBottomBox}
            >
              <ButtonLink
                className="qa-submit"
                fullWidth={isMobile}
                to={nextOverviewPath}
                text={translations.button.looksgood}
                displayArrowRight
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default connect(endOfSectionReviewPageProps, {
  getSectionData,
  analyticsClickedEdit,
})(EndOfSectionReviewPage);
