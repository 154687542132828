import React from "react";

import { reduxForm } from "redux-form";
import { useMediaQuery, useTheme } from "@material-ui/core";

import { Form } from "../../ui/Form";

import BinaryRadioField from "../../ui/inputs/BinaryRadioField";
import { useValidateRestingMemo } from "../../ui/inputs/validations";
import { RESTING_FORM_ID, RESTING_FIELD_ID } from "../../../constants/forms";

const RestingForm = ({
  handleSubmit,
  backLink,
  onSkip,
  isLoading,
  translations,
}) => {
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Form
      qaFormId="wishes"
      onSubmit={handleSubmit}
      backLink={backLink}
      onSkip={onSkip}
      translations={translations}
      isLoading={isLoading}
      reverseWrap={isTabletDown}
    >
      <BinaryRadioField
        validate={useValidateRestingMemo(translations)}
        name={RESTING_FIELD_ID}
        translations={translations}
      />
    </Form>
  );
};

export default reduxForm({
  form: RESTING_FORM_ID,
})(RestingForm);
