import { createSelector, createStructuredSelector } from "reselect";

import { selectTranslations } from "./translations";
import { selectHasLoadedDataById } from "./requests";
import { selectIsFrenchSelected } from "./language";
import { TOOLTIP_MODAL_IMPORTANT_NOTE } from "../constants/modal";
import {
  DASHBOARD_PATH,
  INTENDED_USE_PATH,
  PLANS_PATH,
  REVIEW_ABOUT_YOU_PATH,
} from "../constants/routes";
import { selectIsEssentialsOnlyProvinceSelected } from "./provinces";
import { selectIsAboutYouComplete } from "./about-you";

const selectImportantNoteContinueLink = createSelector(
  selectIsEssentialsOnlyProvinceSelected,
  (isEssentialsOnlyProvinceSelected) => {
    // Provinces like QC, PEI, NB and NL do not have premium plans and thus need
    // to be redirected to the plans page to avoid an unauthorized error
    return isEssentialsOnlyProvinceSelected ? PLANS_PATH : INTENDED_USE_PATH;
  },
);

const selectImportantNotePreviousLink = createSelector(
  selectIsAboutYouComplete,
  (isAboutYouComplete) => {
    return isAboutYouComplete ? REVIEW_ABOUT_YOU_PATH : DASHBOARD_PATH;
  },
);

export const importantNoteProps = createStructuredSelector({
  translations: selectTranslations([
    "global",
    "help",
    TOOLTIP_MODAL_IMPORTANT_NOTE,
  ]),
  hasLoaded: selectHasLoadedDataById("dashboard"),
  isFrenchSelected: selectIsFrenchSelected,
  continueLink: selectImportantNoteContinueLink,
  previousLink: selectImportantNotePreviousLink,
});
