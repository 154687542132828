import React, { useEffect } from "react";
import { connect } from "react-redux";

import FullNameForm from "../../../components/forms/about-you/FullNameForm";
import { fullNamePageProps } from "../../../selectors/about-you";
import { submitAboutYou } from "../../../actions/about-you";
import { FULL_NAME_FORM_ID } from "../../../constants/forms";
import { getFormData } from "../../../actions/forms";
import { RESIDENCE_PATH } from "../../../constants/routes";

const FullNamePage = ({ submitAboutYou, getFormData, ...restOfProps }) => {
  useEffect(() => {
    getFormData(FULL_NAME_FORM_ID);
  }, [getFormData]);
  return (
    <FullNameForm
      backLink={RESIDENCE_PATH}
      onSubmit={() => submitAboutYou(FULL_NAME_FORM_ID)}
      {...restOfProps}
    />
  );
};

export default connect(fullNamePageProps, { submitAboutYou, getFormData })(
  FullNamePage,
);
