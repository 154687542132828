import R from "ramda";
import React from "react";
import styled from "styled-components";
import { Box, Grid } from "@material-ui/core";
import { colorPalette } from "../../../config/Theme";
import KlarnaIcon from "../../../components/ui/icons/KlarnaIcon";
import PaymentMethodIcon_BNPL from "../../../components/ui/icons/PaymentMethodIcon_BNPL";

const CardBox = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 80px;
  align-items: left;
  border-radius: 5px;
  padding: 12px;
  cursor: pointer;
  border: ${(props) =>
    props.selected
      ? `2px solid ${colorPalette.willfulBlue}`
      : `1px solid ${colorPalette.willfulGrey2}`};
`;

const CardText = styled(Box)`
  margin-top: 4px;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.2px;
  color: ${(props) =>
    props.selected ? colorPalette.willfulBlue : colorPalette.willfulGrey1};
`;

const PaymentChoicesCards = ({
  translations,
  handleClick,
  selectedCardIndex,
}) => {
  const paymentMethods = R.propOr({}, "paymentMethods")(translations);
  return (
    <Grid container spacing={1}>
      <Grid item sm={12} md={6} key="Card">
        <CardBox
          selected={selectedCardIndex === 0}
          onClick={() => handleClick(0, false)}
        >
          <PaymentMethodIcon_BNPL />
          <CardText selected={selectedCardIndex === 0}>
            {paymentMethods.card}
          </CardText>
        </CardBox>
      </Grid>
      <Grid item sm={12} md={6} key="Klarna">
        <CardBox
          selected={selectedCardIndex === 1}
          onClick={() => handleClick(1, true)}
        >
          <KlarnaIcon small />
          <CardText selected={selectedCardIndex === 1}>
            {paymentMethods.bnpl}
          </CardText>
        </CardBox>
      </Grid>
    </Grid>
  );
};

export default PaymentChoicesCards;
