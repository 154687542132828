import React from "react";
import SvgIcon from "./SvgIcon";

const ChangePlansIcon = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M6.99 11L3 15L6.99 19V16H14V14H6.99V11ZM21 9L17.01 5V8H10V10H17.01V13L21 9Z"
      fill="#0064DC"
    />
  </SvgIcon>
);

export default ChangePlansIcon;
