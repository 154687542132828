import { takeEvery, call, put } from "redux-saga/effects";

import { FETCH_YOUR_ACCOUNT_TYPE } from "../actions/your-account";
import { updateInvites } from "../actions/invites";
import { getFormData } from "../actions/forms";
import {
  UPDATE_EMAIL_FORM_ID,
  PURCHASES_SUMMARY_FORM_ID,
} from "../constants/forms";
import { getPurchases } from "../api/purchases";
import { submitFormRequest } from "./forms";
import { fetchSuccess } from "../actions/requests";

function* fetchYourAccount() {
  const { assignedUsers, availableInvites } = yield call(submitFormRequest, {
    apiCall: getPurchases,
    formId: PURCHASES_SUMMARY_FORM_ID,
  });
  yield put(updateInvites(assignedUsers, availableInvites));
  yield put(getFormData(UPDATE_EMAIL_FORM_ID));
  yield put(fetchSuccess(PURCHASES_SUMMARY_FORM_ID));
}

export function* watchFetchYourAccount() {
  yield takeEvery(FETCH_YOUR_ACCOUNT_TYPE, fetchYourAccount);
}
