import React, { Fragment } from "react";
import R from "ramda";

import { useMediaQuery, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import { connect } from "react-redux";
import { PageLayout } from "./PageLayout";
import { PrimaryButton, PreviousLink, SmallTextButton } from "./Button";
import { H2, SectionTitle, Body } from "./Typography";

import InfoBoxGroup from "./InfoBox";
import TooltipTrigger from "./TooltipTrigger";
import { displayTooltipModal } from "../../actions/tooltip-modal";
import { useStickyBottomBoxStyle } from "./utils/sticky-bottom-box";

export const FormWrapper = ({
  customGrid,
  qaFormId,
  onSubmit,
  backLink,
  isLoading,
  onSkip,
  includePadding = false,
  children,
  translations,
  HeaderComponent,
  tooltipKey,
  displayTooltipModal,
  hideInfoBox = false,
  suppress = false,
  showFormButtons,
  showSectionHeading = true,
  reverseWrap = false,
  isMiniForm,
  showInfoBoxAboveButtons = false,
  displayPreviousLinkInEyebrow,
  customHeaderComponent,
  disabled = false,
  ...otherProps
}) => {
  const hasInfoBox =
    !hideInfoBox && !showInfoBoxAboveButtons && R.has("infoBox")(translations);
  const wrapDirection = reverseWrap && "wrap-reverse";
  const Container = includePadding ? PageLayout : Box;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const containerDefaultMargin = includePadding ? 2 : 0;
  const containerMargin = isMobile ? 7 : containerDefaultMargin;

  const h2 = (
    <H2 tabIndex={0} {...(suppress && { "data-hj-suppress": true })}>
      {translations.pageTitle}
      {tooltipKey && (
        <TooltipTrigger
          onClick={() => {
            displayTooltipModal(tooltipKey);
          }}
          translations={translations}
          toolTipSize="Large"
        />
      )}
    </H2>
  );

  const headerComponentToUse = customHeaderComponent || h2;

  return (
    <Container mb={containerMargin}>
      {showSectionHeading && (
        <>
          <SectionTitle>{translations.sectionTitle}</SectionTitle>
          <Box
            mt={1}
            mb={isMobile ? 2 : 4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box maxWidth={900}>{headerComponentToUse}</Box>
            {/* TODO: make responsive not static width */}
            <Box>
              <Body align="center">{translations.pageDescription}</Body>
              {HeaderComponent && (
                <HeaderComponent translations={translations} {...otherProps} />
              )}
            </Box>
          </Box>
        </>
      )}
      <Grid container justify="center" wrap={wrapDirection}>
        {hasInfoBox && (
          <>
            <Hidden mdDown>
              <Grid item lg={4} />
            </Hidden>
            <Hidden lgUp>
              <Grid item xs={12} md={8} lg={4}>
                <InfoBoxGroup
                  infoBox={translations.infoBox}
                  reverseWrap={reverseWrap}
                />
              </Grid>
            </Hidden>
          </>
        )}
        <Grid item xs={12} md={8} lg={customGrid ? 8 : 4}>
          <Box display="flex" flexDirection="column" justify="center">
            {children}
          </Box>
          {onSubmit && showFormButtons && (
            <>
              {showInfoBoxAboveButtons && (
                <Hidden lgUp>
                  <Grid item xl={1} lg={1} />
                  <Grid item xl={3} lg={3}>
                    <InfoBoxGroup infoBox={translations.infoBox} />
                  </Grid>
                </Hidden>
              )}
              <FormButtons
                qaFormId={qaFormId}
                onSkip={onSkip}
                backLink={backLink}
                isLoading={isLoading}
                translations={translations}
                displayPreviousLinkInEyebrow={displayPreviousLinkInEyebrow}
                disabled={disabled}
              />
            </>
          )}
        </Grid>
        {hasInfoBox && (
          <Hidden mdDown>
            <Grid item xl={1} lg={1} />
            <Grid item xl={3} lg={3}>
              <InfoBoxGroup infoBox={translations.infoBox} />
            </Grid>
          </Hidden>
        )}
      </Grid>
    </Container>
  );
};

export const FormButtons = ({
  qaFormId,
  onSkip,
  backLink,
  isLoading,
  displayPreviousLinkInEyebrow,
  translations = {},
  disabled = false,
}) => {
  const theme = useTheme();
  const confirmButtonStyling = useStickyBottomBoxStyle();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const contentSpacing =
    backLink && !displayPreviousLinkInEyebrow ? "space-between" : "center";

  return (
    <>
      <Box
        display="flex"
        justifyContent={contentSpacing}
        flexDirection={backLink ? "row" : "row-reverse"}
      >
        {backLink && !displayPreviousLinkInEyebrow && (
          <Box display="flex" alignItems="center">
            <PreviousLink
              to={backLink}
              text={translations.button.previous}
              displayArrowLeft
              underline
            />
          </Box>
        )}
        <Box
          display="flex"
          mt={isDesktop ? 2 : 0}
          className={isMobile && confirmButtonStyling.stickyBottomBox}
        >
          <PrimaryButton
            displayArrowRight
            text={translations.button.submit}
            loadingLabel={translations.button.loadingLabel}
            isLoading={isLoading}
            fullWidth={isMobile}
            isDisabled={disabled}
          />
        </Box>
      </Box>
      {onSkip && (
        <Box display="flex" justifyContent="center" my={2}>
          <SmallTextButton
            onClick={onSkip}
            text={translations.button.skip}
            className={`qa-skip-${qaFormId}`}
          />
        </Box>
      )}
    </>
  );
};

const FormComponent = ({
  children,
  translations,
  onSubmit,
  customGrid,
  displayTooltipModal,
  showFormButtons = true,
  showSectionHeading = true,
  reverseWrap = false,
  showInfoBoxAboveButtons = false,
  isMiniForm,
  displayPreviousLinkInEyebrow = false,
  disabled = false,
  disableNativeHTMLValidation = false, // this property disables the native HTML form validation
  ...otherProps
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const Container = isMiniForm ? React.Fragment : PageLayout;
  const { backLink } = otherProps;
  return (
    <form onSubmit={onSubmit} noValidate={disableNativeHTMLValidation}>
      <Container>
        {backLink && displayPreviousLinkInEyebrow && (
          <Box mb={!isDesktop && 1} display="inline-flex">
            <PreviousLink
              to={backLink}
              text={translations.button.previous}
              displayArrowLeft
              underline
            />
          </Box>
        )}
        <FormWrapper
          customGrid={customGrid}
          translations={translations}
          onSubmit={onSubmit}
          displayTooltipModal={displayTooltipModal}
          showFormButtons={showFormButtons}
          showSectionHeading={showSectionHeading}
          reverseWrap={reverseWrap}
          showInfoBoxAboveButtons={showInfoBoxAboveButtons}
          displayPreviousLinkInEyebrow={displayPreviousLinkInEyebrow}
          disabled={disabled}
          {...otherProps}
        >
          {children}
        </FormWrapper>
      </Container>
    </form>
  );
};

export const Form = connect(null, { displayTooltipModal })(FormComponent);

export const FormContainer = ({ children }) => (
  <PageLayout px={2}>
    <Grid container justify="center" spacing={1}>
      <Grid item md={8}>
        {children}
      </Grid>
    </Grid>
  </PageLayout>
);
