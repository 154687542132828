import React, { useRef } from "react";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";

import {
  APPOINTEES_PATH,
  DASHBOARD_PATH,
  DOCUMENTS_PATH,
  YOUR_ACCOUNT_PATH,
} from "../../constants/routes";
import { Body } from "../ui/Typography";
import { ProfileIcon, LogoutIcon } from "../ui/icons/DropdownMenuIcons";
import GiftPlansIcon from "../ui/icons/GiftPlanIcon";
import { MENU_CLOSING_SPEED } from "../ui/utils/animation-styles";
import { useBackgroundClickClose, useMenuToggle } from "../../utilities/hooks";

import {
  DropdownList,
  DropdownOption,
  StyledLink,
  ButtonOption,
  DropdownMenuButton,
  DropdownContainerExpanded,
} from "./NavMenuDropdown";
import { analyticsClickedNavLink } from "../../actions/analytics";
import DocumentsNavIcon from "../ui/icons/DocumentsNavIcon";
import AssetListNavIcon from "../ui/icons/AssetListNavIcon";
import DocumentsMenuNavIcon from "../ui/icons/DocumentsMenuNavIcon";
import AppointeesNavIcon from "../ui/icons/AppointeesNavIcon";

const DropdownMenuItems = ({
  translations,
  handleLogout,
  onLinkClick,
  analyticsClickedNavLink,
  isPremium,
  assetListLink,
  hasSelectedPlan,
  hasAppointees,
  hasUserPaid,
}) => {
  const handleOnClick = (item) => {
    onLinkClick();
    analyticsClickedNavLink("Account", item);
  };
  return (
    <DropdownList>
      {hasSelectedPlan && (
        <DropdownOption>
          <StyledLink
            to={DASHBOARD_PATH}
            onClick={() => handleOnClick("Dashboard")}
          >
            <Box component="span" mr={0.5} display="flex" alignItems="center">
              <DocumentsNavIcon />
            </Box>
            <Box component="span" width={1}>
              <Body component="span" align="left">
                {translations.yourDocuments}
              </Body>
            </Box>
          </StyledLink>
        </DropdownOption>
      )}
      {isPremium && (
        <DropdownOption>
          <StyledLink
            className="qa-asset-list-menu-option"
            to={assetListLink}
            onClick={() => handleOnClick("Asset-list")}
          >
            <Box component="span" mr={0.5} display="flex" alignItems="center">
              <AssetListNavIcon />
            </Box>
            <Box component="span" width={1}>
              <Body component="span" align="left">
                {translations.assetList}
              </Body>
            </Box>
          </StyledLink>
        </DropdownOption>
      )}
      {hasSelectedPlan && (
        <DropdownOption>
          <StyledLink
            className="qa-documents-list-menu-option"
            to={DOCUMENTS_PATH}
            onClick={() => handleOnClick("Documents")}
          >
            <Box component="span" mr={0.5} display="flex" alignItems="center">
              <DocumentsMenuNavIcon />
            </Box>
            <Box component="span" width={1}>
              <Body component="span" align="left">
                {translations.documents}
              </Body>
            </Box>
          </StyledLink>
        </DropdownOption>
      )}
      {hasAppointees && (
        <DropdownOption>
          <StyledLink
            to={APPOINTEES_PATH}
            onClick={() => handleOnClick("Appointees")}
          >
            <Box component="span" mr={0.5} display="flex" alignItems="center">
              <AppointeesNavIcon />
            </Box>
            <Box component="span" width={1}>
              <Body component="span" align="left">
                {translations.appointees}
              </Body>
            </Box>
          </StyledLink>
        </DropdownOption>
      )}
      <DropdownOption>
        <StyledLink
          to={YOUR_ACCOUNT_PATH}
          onClick={() => handleOnClick("Your account")}
        >
          <Box component="span" mr={0.5} display="flex" alignItems="center">
            <ProfileIcon />
          </Box>
          <Box component="span" width={1}>
            <Body component="span" align="left">
              {translations.yourAccount}
            </Body>
          </Box>
        </StyledLink>
      </DropdownOption>
      {hasUserPaid && (
        <DropdownOption>
          <StyledLink
            to={{ pathname: translations.bulkPurchaseGifts }}
            target="_blank"
            onClick={() => analyticsClickedNavLink("Account", "Gift a will")}
          >
            <Box component="span" mr={0.5} display="flex" alignItems="center">
              <GiftPlansIcon />
            </Box>
            <Box component="span" width={1}>
              <Body component="span" align="left">
                {translations.giftWill}
              </Body>
            </Box>
          </StyledLink>
        </DropdownOption>
      )}
      <DropdownOption $isHighlighted>
        <ButtonOption type="button" onClick={handleLogout}>
          <Box
            component="span"
            pt="3px"
            mr="10px"
            display="flex"
            alignItems="center"
          >
            <LogoutIcon />
          </Box>
          <Body component="span">{translations.logout}</Body>
        </ButtonOption>
      </DropdownOption>
    </DropdownList>
  );
};

const YourAccountMenu = ({
  onLogout,
  translations,
  analyticsClickedNavLink,
  ...otherProps
}) => {
  const { handleToggle, isOpen, isClosing } = useMenuToggle(MENU_CLOSING_SPEED);
  const menuRef = useRef(null);
  useBackgroundClickClose(menuRef, isOpen, () => handleToggle(false));
  const handleLogout = () => {
    onLogout();
    handleToggle(false);
    analyticsClickedNavLink("Account", "Log out");
  };

  return (
    <Box ref={menuRef} display="flex" position="relative" alignItems="center">
      {isOpen && (
        <DropdownContainerExpanded
          isOpen={isOpen}
          isClosing={isClosing}
          translations={translations}
          alignment="right"
        >
          <DropdownMenuItems
            onLinkClick={() => handleToggle(false)}
            handleLogout={handleLogout}
            translations={translations}
            analyticsClickedNavLink={analyticsClickedNavLink}
            {...otherProps}
          />
        </DropdownContainerExpanded>
      )}
      <DropdownMenuButton
        isOpen={isOpen}
        translations={translations}
        type="button"
        textAlign="left" //TODO: this does nothing :(
        onClick={() => handleToggle(!isOpen)}
        suppress
      />
    </Box>
  );
};

export default connect(null, {
  analyticsClickedNavLink,
})(YourAccountMenu);
