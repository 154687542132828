import React from "react";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, withTheme } from "@material-ui/styles";
import { connect } from "react-redux";

import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import { assetListOverviewProps } from "../../selectors/asset-list-overview";
import {
  SectionTitle,
  H2,
  Body,
  SmallBody,
} from "../../components/ui/Typography";
import { PageLayout } from "../../components/ui/PageLayout";
import { PageWrapper } from "../Layout";
import AssetListOverviewIcon from "../../components/ui/icons/AssetListOverviewIcon";
import { ButtonLink } from "../../components/ui/Button";

const AssetListOverviewPage = ({ translations, ctaButtonLink }) => {
  const theme = useTheme();
  const isLargeDesktop = useMediaQuery(theme.breakpoints.only("xl"));
  const isDesktop = useMediaQuery(theme.breakpoints.only("lg"));

  return (
    <Box
      width="100%"
      bgcolor="willfulxxxLightBlue.main"
      flex="1"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <PageWrapper flexGrow={1}>
        <PageLayout>
          <Grid container>
            <Grid item xl={0} lg={0} md={12} xs={12}>
              <Hidden lgUp>
                <Box
                  mb={2}
                  display="flex"
                  alignItems={isDesktop ? "end" : "center"}
                  flexDirection="column"
                >
                  <AssetListOverviewIcon
                    isDesktop={isDesktop || isLargeDesktop}
                  />
                </Box>
              </Hidden>
            </Grid>
            <Grid item xl={1} lg={0} md={0} xs={0} />
            <Grid item container xl={6} lg={6} md={12} xs={12}>
              <Grid item xl={0} lg={0} md={1} xs={0} />
              <Grid item xl={12} lg={12} md={10} xs={0}>
                <Box mb={isDesktop ? 4.5 : 1.5}>
                  <Box mb={1}>
                    <SectionTitle color="willfulBlue" align="left" mb={1}>
                      {translations.sectionTitle}
                    </SectionTitle>
                  </Box>
                  <Box mb={1}>
                    <H2 align="left">{translations.title}</H2>
                  </Box>
                  <Box mb={2}>
                    <Body
                      align="left"
                      dangerouslySetInnerHTML={{ __html: translations.body }}
                    />
                  </Box>
                  <Box mb={2}>
                    <ButtonLink
                      className="qa-continue"
                      to={ctaButtonLink}
                      text={translations.continueButton}
                      displayArrowRight
                    />
                  </Box>
                  <Box>
                    <SmallBody align="left">{translations.smallBody}</SmallBody>
                  </Box>
                </Box>
              </Grid>
              <Grid item xl={0} lg={0} md={1} xs={0} />
            </Grid>
            <Grid item xl={1} lg={2} md={0} xs={0} />
            <Grid item xl={3} lg={4} md={0} xs={0}>
              <Hidden mdDown>
                <Box
                  display="flex"
                  alignItems={isDesktop ? "end" : "center"}
                  flexDirection="column"
                >
                  <AssetListOverviewIcon
                    isDesktop={isDesktop || isLargeDesktop}
                  />
                </Box>
              </Hidden>
            </Grid>
            <Grid item xl={1} lg={0} md={0} xs={0} />
          </Grid>
        </PageLayout>
      </PageWrapper>
    </Box>
  );
};

export default connect(assetListOverviewProps)(
  withTheme(AssetListOverviewPage),
);
