import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";

import { overviewPageProps } from "../../selectors/dashboard";
import { fetchDashboard } from "../../actions/dashboard";
import { displayChat } from "../../actions/customer-service";
import OverviewForm from "../../components/forms/overview/OverviewForm";
import LoadingSpinner from "../../components/ui/LoadingSpinner";

const OverviewPage = ({ fetchDashboard, displayChat, hasLoaded, ...rest }) => {
  useEffect(() => {
    if (!hasLoaded) {
      fetchDashboard();
    }
  }, [fetchDashboard, hasLoaded]);

  if (!hasLoaded)
    return (
      <Box flexGrow="1">
        <LoadingSpinner />
      </Box>
    );

  return <OverviewForm displayChat={displayChat} {...rest} />;
};

export default connect(overviewPageProps, {
  fetchDashboard,
  displayChat,
})(OverviewPage);
