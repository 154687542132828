import React, { useState } from "react";
import { Field } from "redux-form";
import Box from "@material-ui/core/Box";

import TextInput from "./TextInput";
import AmountField from "./AmountField";
import {
  CHARITY_ID_FIELD_ID,
  CASH_AMOUNT_FIELD_ID,
  ENTRY_TYPE_FIELD_ID,
  DONATIONS_CUSTOM_CHARITY,
  REGISTERED_NAME_FIELD_ID,
  BUSINESS_NUMBER_FIELD_ID,
} from "../../../constants/forms";
import {
  useValidateCharityNameMemo,
  useValidateCharityMemo,
  useValidateCharityAmountMemo,
} from "./validations";
import { CharityDropdownSelect } from "./DropdownCharitySelect/index";

const getFieldName = (fieldName, fieldId) =>
  fieldName ? `${fieldName}.${fieldId}` : fieldId;

const DonationFields = ({
  field = {},
  fieldName,
  charitiesOptionList,
  languageCode,
  showAmountField = false,
  translations,
}) => {
  const [isCustomCharity, setIsCustomCharity] = useState(false);
  const isCustomInput =
    isCustomCharity || field[ENTRY_TYPE_FIELD_ID] === DONATIONS_CUSTOM_CHARITY;

  const handleToggleCustomInput = (state) => {
    field[ENTRY_TYPE_FIELD_ID] = DONATIONS_CUSTOM_CHARITY;
    setIsCustomCharity(!state);
  };
  return (
    <Box>
      {!isCustomInput && (
        <>
          <Field
            name={getFieldName(fieldName, CHARITY_ID_FIELD_ID)}
            label={translations.selectCharityLabel}
            placeholder={translations.selectCharityPlaceholder}
            component={CharityDropdownSelect}
            validate={useValidateCharityMemo(translations)}
            optionList={charitiesOptionList}
            onCustomInputClick={handleToggleCustomInput}
          />
        </>
      )}
      {isCustomInput && (
        <>
          <Field
            name={getFieldName(fieldName, REGISTERED_NAME_FIELD_ID)}
            label={translations.charityNameLabel}
            placeholder={translations.charityNamePlaceholder}
            component={TextInput}
            validate={useValidateCharityNameMemo(translations)}
          />
          <Field
            name={getFieldName(fieldName, BUSINESS_NUMBER_FIELD_ID)}
            label={translations.charityBusinessNumberLabel}
            placeholder={translations.charityBusinessNumberPlaceholder}
            component={TextInput}
          />
        </>
      )}
      {showAmountField && (
        <Box mt={2}>
          <AmountField
            fieldName={getFieldName(fieldName, CASH_AMOUNT_FIELD_ID)}
            label={translations.amountLabel}
            placeholder={translations.amountPlaceholder}
            languageCode={languageCode}
            validate={useValidateCharityAmountMemo(translations)}
          />
        </Box>
      )}
    </Box>
  );
};

export default DonationFields;
