import { createStructuredSelector } from "reselect";

import { selectTranslations } from "./translations";
import { selectHasLoadedDataById } from "./requests";
import { selectNextPathAfterPlansGuidancePages } from "./navigation";

export const FamilyPlanGuidanceProps = createStructuredSelector({
  translations: selectTranslations(["global", "familyPlanGuidancePage"]),
  hasLoaded: selectHasLoadedDataById("dashboard"),
  nextSectionPath: selectNextPathAfterPlansGuidancePages,
});
