import R from "ramda";
import { select, takeEvery, call, put } from "redux-saga/effects";
import { initialize, getFormValues, startSubmit, stopSubmit } from "redux-form";

import { transformErrorData } from "../utilities/transformJsonData";

import { requestSequence, handleMeta, fetchApiData } from "./requests";
import { GET_FORM_DATA_TYPE, GET_SECTION_DATA_TYPE } from "../actions/forms";
import { fetchSuccess } from "../actions/requests";
import { getDashboard } from "../api/dashboard";
import { selectTranslations } from "../selectors/translations";

import {
  FULL_NAME_FORM_ID,
  DOB_FORM_ID,
  GENDER_FORM_ID,
  SPOUSE_FORM_ID,
  MARITAL_STATUS_FORM_ID,
  NUM_PETS_FORM_ID,
  NUM_CHILDREN_FORM_ID,
  CHILDREN_FORM_ID,
  CHILDREN_GUARDIANS_FORM_ID,
  PETS_FORM_ID,
  PET_GUARDIANS_FORM_ID,
  PET_TRUST_FORM_ID,
  GIFTS_FORM_ID,
  PRIMARY_BENEFICIARY_FORM_ID,
  PREDECEASE_FORM_ID,
  INHERITANCE_FORM_ID,
  RESTING_FORM_ID,
  ASHES_FORM_ID,
  CEREMONY_FORM_ID,
  EXECUTORS_FORM_ID,
  PROPERTY_ATTORNEYS_FORM_ID,
  PERSONAL_ATTORNEYS_FORM_ID,
  LIFE_PROLONG_FORM_ID,
  PAIN_MANAGE_FORM_ID,
  UPDATE_EMAIL_FORM_ID,
  RESIDENCE_FORM_ID,
  LANGUAGE_FORM_ID,
} from "../constants/forms";

import { selectHasLoaded } from "../selectors/requests";
import {
  selectMaritalStatusForm,
  selectSpouseBasicInfoForm,
} from "../selectors/spouse";

import {
  selectNumberOfChildrenForm,
  selectChildrenForm,
  selectChildrenGuardiansForm,
} from "../selectors/children";
import {
  selectPainManageForm,
  selectLifeProlongForm,
} from "../selectors/healthcare";
import {
  selectPrimaryBeneficiaryForm,
  selectPredeceaseForm,
  selectInheritanceForm,
} from "../selectors/allocations";

import {
  selectNumberOfPetsForm,
  selectPetsForm,
  selectPetGuardiansForm,
  selectPetTrustForm,
} from "../selectors/pets";
import { selectGiftsForm } from "../selectors/gifts";
import { selectExecutorsForm } from "../selectors/executors";
import {
  selectRestingForm,
  selectAshesForm,
  selectCeremonyForm,
} from "../selectors/wishes";
import { selectPersonalAttorneysForm } from "../selectors/personal-attorneys";
import { selectPropertyAttorneysForm } from "../selectors/property-attorneys";
import { selectChangeEmailForm } from "../selectors/your-account";
import {
  selectResidenceForm,
  selectFullNameForm,
  selectDobForm,
  selectGenderForm,
  selectLanguageForm,
} from "../selectors/about-you";

export function* submitFormRequest({
  apiCall,
  formId,
  values,
  isSpouseValues = false, // signify to analytics
}) {
  const formValues = values || (yield select(getFormValues(formId)));
  yield put(startSubmit(formId));
  try {
    const response = yield call(
      requestSequence,
      apiCall,
      {
        type: formId,
        meta: {
          request: "submit",
          isSpouseValues,
        },
        payload: formValues,
      },
      formValues,
    );
    const data = R.propOr(null, "data")(response);
    const errors = R.propOr(null, "errors")(response);
    const meta = R.propOr(null, "meta")(response);
    const status = R.propOr(null, "status")(response);

    if (errors && errors.length) {
      const baseErrors = yield call(handleFormError, {
        errors,
        formValues,
        formId,
      });
      if (meta) {
        yield call(handleMeta, meta);
      }
      return {
        ...data,
        status,
        errors: baseErrors,
      };
    }
    yield put(stopSubmit(formId));

    if (meta) {
      yield call(handleMeta, meta);
    }
    return {
      ...data,
      status,
    };
  } catch (error) {
    const errorTranslations = yield select(selectTranslations(["errors"]));
    const errorObject = transformErrorData(
      error.formErrors,
      formValues,
      errorTranslations,
    );
    return yield put(stopSubmit(formId, errorObject));
  }
}

export function* handleFormError({ errors, formValues, formId }) {
  try {
    const errorTranslations = yield select(selectTranslations(["errors"]));
    const errorObject = transformErrorData(
      errors,
      formValues,
      errorTranslations,
    );
    yield put(stopSubmit(formId, errorObject));
    // returned incase need to handle _base errors downstream (eg. Login request)
    return errorObject;
  } catch (e) {
    const errorMessage = `An error occurred handling form errors in ${formId}`;
    if (window.env.FE_ENV !== "production") {
      console.error(errorMessage, e, e.stack, errors);
    }
    if (window.Rollbar) {
      yield call(window.Rollbar.error, errorMessage, e, e.stack, errors);
    }
    return null;
  }
}

export function* getDataByFormId(formId) {
  switch (formId) {
    case RESIDENCE_FORM_ID:
      return yield select(selectResidenceForm);
    case LANGUAGE_FORM_ID:
      return yield select(selectLanguageForm);
    case FULL_NAME_FORM_ID:
      return yield select(selectFullNameForm);
    case DOB_FORM_ID:
      return yield select(selectDobForm);
    case GENDER_FORM_ID:
      return yield select(selectGenderForm);
    case UPDATE_EMAIL_FORM_ID:
      return yield select(selectChangeEmailForm);
    case MARITAL_STATUS_FORM_ID:
      return yield select(selectMaritalStatusForm);
    case SPOUSE_FORM_ID:
      return yield select(selectSpouseBasicInfoForm);
    case NUM_CHILDREN_FORM_ID:
      return yield select(selectNumberOfChildrenForm);
    case CHILDREN_FORM_ID:
      return yield select(selectChildrenForm);
    case CHILDREN_GUARDIANS_FORM_ID:
      return yield select(selectChildrenGuardiansForm);
    case NUM_PETS_FORM_ID:
      return yield select(selectNumberOfPetsForm);
    case PETS_FORM_ID:
      return yield select(selectPetsForm);
    case PET_GUARDIANS_FORM_ID:
      return yield select(selectPetGuardiansForm);
    case PET_TRUST_FORM_ID:
      return yield select(selectPetTrustForm);
    case GIFTS_FORM_ID:
      return yield select(selectGiftsForm);
    case PRIMARY_BENEFICIARY_FORM_ID:
      return yield select(selectPrimaryBeneficiaryForm);
    case PREDECEASE_FORM_ID:
      return yield select(selectPredeceaseForm);
    case INHERITANCE_FORM_ID:
      return yield select(selectInheritanceForm);
    case RESTING_FORM_ID:
      return yield select(selectRestingForm);
    case ASHES_FORM_ID:
      return yield select(selectAshesForm);
    case CEREMONY_FORM_ID:
      return yield select(selectCeremonyForm);
    case EXECUTORS_FORM_ID:
      return yield select(selectExecutorsForm);
    case PROPERTY_ATTORNEYS_FORM_ID:
      return yield select(selectPropertyAttorneysForm);
    case PERSONAL_ATTORNEYS_FORM_ID:
      return yield select(selectPersonalAttorneysForm);
    case PAIN_MANAGE_FORM_ID:
      return yield select(selectPainManageForm);
    case LIFE_PROLONG_FORM_ID:
      return yield select(selectLifeProlongForm);
    default:
      return null;
  }
}

function* getSectionData({ payload }) {
  const { sectionKey } = payload;
  const hasLoaded = yield select(selectHasLoaded);

  if (!hasLoaded) {
    yield call(fetchApiData, {
      apiCall: getDashboard,
      formId: sectionKey,
    });
  }

  yield put(fetchSuccess(sectionKey));
}

function* getFormData({ payload }) {
  const { formId } = payload;

  const hasLoaded = yield select(selectHasLoaded);
  if (!hasLoaded) {
    yield call(fetchApiData, {
      apiCall: getDashboard,
      formId,
    });
  }

  const formData = yield call(getDataByFormId, formId);
  yield put(initialize(formId, formData));
  yield put(fetchSuccess(formId));
}

export function* watchGetFormData() {
  yield takeEvery(GET_FORM_DATA_TYPE, getFormData);
}

export function* watchGetSectionData() {
  yield takeEvery(GET_SECTION_DATA_TYPE, getSectionData);
}
