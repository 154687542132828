import {
  ADULT_CHILD_BADGE,
  CHILD_RELATIONSHIP,
  MINOR_CHILD_BADGE,
  SPOUSE_OR_PARTNER_RELATIONSHIP,
} from "../../../constants/contacts";

export const getContactBadgeKey = (person) => {
  const { contact, isMinorChild } = person;
  if (!contact) return "";

  const { relationship } = contact;
  if (!relationship) return "";

  if (relationship === SPOUSE_OR_PARTNER_RELATIONSHIP) {
    return SPOUSE_OR_PARTNER_RELATIONSHIP;
  }

  if (relationship === CHILD_RELATIONSHIP) {
    return isMinorChild ? MINOR_CHILD_BADGE : ADULT_CHILD_BADGE;
  }

  return "";
};
